import BaseService from "../BaseService";
import { API_KEY } from "../../config";
// import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";

class ServiceChangePassword {
    postChangePassword(param, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            // "Authorization": "Bearer " +HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).accessToken
        };
        
        BaseService.doPostAccessToken(API.CHANGE_PASSWORD, param, headers, callback);
    }
}

const API = {
    CHANGE_PASSWORD: "/account/security/change_password"
}

export default new ServiceChangePassword()