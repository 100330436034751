import React, { useEffect, useState } from "react";
import "./Utm.scss";
import { TabContent, Nav, NavItem } from 'reactstrap';
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import HelperQueryString from "../../../helper/HelperQueryString";
import UTMTab1 from "./utmTab1/UTMTab1";
import UTMTab2 from "./utmTab2/UTMTab2";

let Utm = (props) => {
    let TABS = {
        fromURL: {
            id: "fromURL",
            tab: "From URL",
            component: <UTMTab1 {...props} />,
        },
        utm: {
            id: "utm",
            tab: "UTM",
            component: <UTMTab2 {...props} />
        },
    }

    let [activeTab, setActiveTab] = useState(TABS.fromURL.id);
    // let [orgID, setOrgID] = useState(false);

    let toggle = tab => {
        props.history.replace({
            search: "",
        })
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        if(props.location.search !== "") {
            let qs = HelperQueryString.parseQuerystring();
            let tab = qs["tab"];

            if(tab === "utm") {
                setActiveTab(tab);
            }
        }
    }, [])

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.tab) {
                setActiveTab(props.history.location.state.tab);
            }
        }
    }, [props.history])

    return (
        <React.Fragment>
            <SectionWrap
                {...props}
                orgID={""}
                withHeader
                withSideMenu
            >
                <div className="utm-page-wrapper">
                    <Nav tabs>
                        {Object.keys(TABS).map((value, index) => {
                            return (
                                <NavItem key={`tab-setup-${index}`} className={`${activeTab === TABS[value].id ? 'active-setup-tab' : ''}`}>
                                    <div
                                        className="setup-tab-wrapper"
                                        onClick={() => { toggle(TABS[value].id); }}
                                    >
                                        <b>{TABS[value].tab}</b>
                                    </div>
                                </NavItem>
                            )
                        })}
                    </Nav>

                    <TabContent>
                        {TABS[activeTab].component}
                    </TabContent>
                </div>
            </SectionWrap>
        </React.Fragment>
    )
}

export default Utm;