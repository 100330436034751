import React, { useState, useEffect } from "react";
import "./ChannelDetail.scss";
import { NavLink } from "react-router-dom";
import { FiEdit, FiCopy, FiClock } from 'react-icons/fi';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IntegrationGoogleServices from "../../../../../services/integration/IntegrationGoogleServices";
import AutoResolveServices from '../../../../../services/newServices/AutoResolveServices';
import AutoUnassignServices from '../../../../../services/newServices/AutoUnassignServices';
import { doToast } from '../../../../../helper/HelperGeneral';
import iconDelete from '../../../../../assets/img/integration/icon-delete.svg';
import iconXgrey from '../../../../../assets/img/integration/icon-x-grey.svg';
// import iconCheckGreen from '../../../../../assets/img/icon-check-green.svg';
import iconCloseRed from '../../../../../assets/img/close-red-icon.svg';
import statusConnected from '../../../../../assets/img/integration/status-connected.svg';
import PopupRemove from '../../../../reuseableComponent/popupRemove/PopupRemove';
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import IntegrationAutoMessageDetail from "../../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";

let ChannelDetail = (props) => {
    let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true)
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [accountDetail, setAccountDetail] = useState(null);
    let [googleChatbot, setGoogleChatbot] = useState(null);
    // let [googleChannelLink, setGoogleChannelLink] = useState(null);
    // let [googleChannelLinkNeverSet, setGoogleChannelLinkNeverSet] = useState(false);
    let [isShowModalRemoveChatbot, setIsShowModalRemoveChatbot] = useState(false);
    let [waitForResponseRemoveChatbot, setWaitForResponseRemoveChatbot] = useState(false);
    let [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(false);
    let [welcomeMessageData, setWelcomeMessageData] = useState(false);
    let [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
    let [awayMessageData, setAwayMessageData] = useState(false);
    let [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
    let [closingMessageData, setClosingMessageData] = useState(false);
    let [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
    let [ratingMessageData, setRatingMessageData] = useState(false);
    let [errorWelcome, setErrorWelcome] = useState(false);
    let [errorAway, setErrorAway] = useState(false);
    let [loadingAutoResolve, setLoadingAutoResolve] = useState(false);
    let [autoResolveData, setAutoResolveData] = useState(false);
    let [errorClosing, setErrorClosing] = useState(false);
    let [errorRating, setErrorRating] = useState(false);
    let [errorChatbot, setErrorChatbot] = useState(false);
    let [errorAutoResolve, setErrorAutoResolve] = useState(false);
    let [loadingAutoUnassign, setLoadingAutoUnassign] = useState(false);
    let [autoUnassignData, setAutoUnassignData] = useState(false);
    let [errorAutoUnassign, setErrorAutoUnassign] = useState(false);

    useEffect(() => {
        if (props.match.params.orgID) {
            getAccountDetail();
        }
    }, [props.match.params.orgID]);

    useEffect(() => {
        if(accountDetail !== null) {
        //   getGoogleChannelLink()
          getChatBot();
          runGetWelcomeMessage()
          runGetAwayMessage()
          runGetClosingMessage()
          runGetRatingMessage()
          runGetAutoResolve()
          runGetAutoUnassign()
        }
    }, [accountDetail])

    // useEffect(() => {
    //     if(googleChannelLink !== null || googleChannelLinkNeverSet) {
    //         getChatBot();
    //     }
    // }, [googleChannelLink, googleChannelLinkNeverSet])

    let runGetWelcomeMessage = () => {
        setLoadingGetWelcomeMessage(true);
    
        IntegrationGoogleServices.getWelcomeMessage(props.match.params.orgID, props.match.params.id, (response) => {
            // let _retriveDataError = {...retriveDataError};
            
            if (response.dataResult.error.message === "") {
                setWelcomeMessageData(response.dataResult.data.welcomeMessage);
            } else {
                // _retriveDataError.message = response.dataResult.error.message;
                // _retriveDataError.code = response.dataResult.error.code;
                // setRetrieveDataError(_retriveDataError);
                setErrorWelcome({
                    errorCode: response.dataResult.error.code,
                    errorMessage: response.dataResult.error.message
                });
                // doToast(response.dataResult.error.message, 'fail');
            }
    
            setLoadingGetWelcomeMessage(false);
        });
    }

    let runGetAwayMessage = () => {
        setLoadingGetAwayMessage(true);
    
        IntegrationGoogleServices.getAwayMessage(props.match.params.orgID, props.match.params.id, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setAwayMessageData(response.dataResult.data.awayMessage);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorAway({
                errorCode: response.dataResult.error.code,
                errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
    
          setLoadingGetAwayMessage(false);
        });
    }

    let runGetClosingMessage = () => {
        setLoadingGetClosingMessage(true);
    
        IntegrationGoogleServices.getClosingMessage(props.match.params.orgID, props.match.params.id, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setClosingMessageData(response.dataResult.data.closingMessage);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorClosing({
                errorCode: response.dataResult.error.code,
                errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
    
          setLoadingGetClosingMessage(false);
        });
    }

    let runGetRatingMessage = () => {
        setLoadingGetRatingMessage(true);
    
        IntegrationGoogleServices.getRatingMessage(props.match.params.orgID, props.match.params.id, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setRatingMessageData(response.dataResult.data.userRating);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorRating({
                errorCode: response.dataResult.error.code,
                errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
    
          setLoadingGetRatingMessage(false);
        });
      }

    let copyToClip = (selector, text) => {
        document.getElementById(selector).select();
        document.execCommand("copy");
        doToast(text);
    };

    // let goToEdit = (id) => {
    //     props.history.push(`/o/${props.match.params.orgID}/integration/google-business-messages/detail/${id}/edit-topic`)
    // }

    let toggleModalRemoveChatbot = () => {
        setIsShowModalRemoveChatbot(!isShowModalRemoveChatbot);
    };

    let removeChatbot = () => {
        setWaitForResponseRemoveChatbot(true);
    
        let data = {
          connectionID: googleChatbot.item.id,
          channelID: parseInt(props.match.params.id),
          chatbotID: googleChatbot.item.chatbotID,
        };
        
        IntegrationGoogleServices.removeChatBot(
          props.match.params.orgID,
          data,
          (response) => {
            if (response.dataResult.status === 200) {
              if (!response.dataResult.data.message) {
                doToast(response.dataResult.data.message);
                setWaitForResponseRemoveChatbot(false);
              } else {
                doToast(response.dataResult.data.message);
                toggleModalRemoveChatbot();
                setIsLoadingGetDetailData(true);
                // setGoogleChannelLink(null);
                setGoogleChatbot(null);
                // setGoogleChannelLinkNeverSet(false);
                getAccountDetail();
              }
            } else {
              doToast(response.dataResult.data.message, 'fail');
              setWaitForResponseRemoveChatbot(false);
            }
          }
        );
      }

    const getAccountDetail = () => {
        setIsLoadingGetDetailData(true);

        const data = {
            id: Number(props.match.params.id),
        }

        IntegrationGoogleServices.getDetail(props.match.params.orgID, data, (response) => {
            let _retriveDataError = { ...retriveDataError };
            
            if (response.dataResult.error.message === "") {
                setAccountDetail(response.dataResult.data);
            } else {
                _retriveDataError.message = response.dataResult.error.message;
                _retriveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setIsLoadingGetDetailData(false);
            }
        });
    };

    const getChatBot = () => {
        IntegrationGoogleServices.getChatBot(props.match.params.orgID, {channelID: parseInt(props.match.params.id)}, (response) => {
        //    let _retriveDataError = {...retriveDataError};
    
          if (response.dataResult.error.message === "") {
            setGoogleChatbot(response.dataResult.data);
          } else {
            // setGoogleChatbot(null);
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorChatbot({
                errorCode: response.dataResult.error.code,
                errorMessage: response.dataResult.error.message
            })
    
            // doToast(response.dataResult.error.message, 'fail');
            setIsLoadingGetDetailData(false);
          }
    
          setIsLoadingGetDetailData(false);
        });
    };

    // let getGoogleChannelLink = () => {
    //     let data = {
    //       id: parseInt(props.match.params.id)
    //     }
    
    //     IntegrationGoogleServices.getChannelLink(props.match.params.orgID, data, (response) => {
    //       let _retriveDataError = {...retriveDataError};
    //       let dataResult = response.dataResult;
    
    //       if (dataResult.status === 200) {
    //         setGoogleChannelLink(dataResult.data.channelLink);
    //       } else {
    //         if(dataResult.error.code === "40401") {
    //           setGoogleChannelLinkNeverSet(true);
    //         }else {
    //           setGoogleChannelLink(null);
    //           _retriveDataError.message = dataResult.error.message;
    //           _retriveDataError.code = dataResult.error.code;
    //           setRetrieveDataError(_retriveDataError); 
    //         }
    //       }
    
    //     //   setIsLoadingGetDetailData(false);
    //     });
    // }

    let renderTopics = () => {
        let text = "";
        
        accountDetail.channel.topics.map((v, i) => {
          text += `
            <div class="topic-list-content">
                <b>${v.name}</b>
            </div>
          `

          return null;
        })
    
        return text;
    }

    let runGetAutoResolve = () => {
        setLoadingAutoResolve(true);
      
        AutoResolveServices.getAutoResolve(props.match.params.orgID, "google_business", {id: parseInt(props.match.params.id)}, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setAutoResolveData(response.dataResult.data.autoResolveConfig);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorAutoResolve({
              errorCode: response.dataResult.error.code,
              errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
      
          setLoadingAutoResolve(false);
        });
    }

    let runGetAutoUnassign = () => {
        setLoadingAutoUnassign(true);
    
        AutoUnassignServices.getAutoUnassign(props.match.params.orgID, "google_business", {id: parseInt(props.match.params.id)}, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setAutoUnassignData(response.dataResult.data.autoUnassignAgentConfig);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorAutoUnassign({
              errorCode: response.dataResult.error.code,
              errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
    
          setLoadingAutoUnassign(false);
        });
    }
      
    return (
        <div>
            <div className="integration-main-container integration-google-detail-account text-left">
                {isLoadingGetDetailData || retriveDataError.code ? (
                    <div className="channel-detail-loading-wrapper">
                        <RetrieveDataLoading
                            isLoading={isLoadingGetDetailData}
                            errorMessage={retriveDataError.message}
                        />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="integration-google-detail-account-box-1">
                                <div className="integration-google-detail-account-box-1-top">
                                        <b>Channel Details</b>

                                        <div className="integration-google-detail-account-box-1-top-button">
                                            <NavLink to={`/o/${props.match.params.orgID}/integration/google-business-messages/detail/${accountDetail.channel.id}/edit-topic`}>
                                                <ButtonWithLoadingOrIcon 
                                                    className="no-fill-button main-button-40"
                                                    text="Edit Topic"
                                                    position="left"
                                                    icon={{
                                                        type: "svg",
                                                        src: FiEdit
                                                    }}
                                                />
                                            </NavLink>
                                        </div>
                                </div>

                                <div className="integration-google-detail-account-box-1-bottom">
                                    <div className="form-content-wrapper" style={{marginBottom: "16px"}}>
                                        <label>
                                            <b>Channel ID</b>
                                        </label>
                                        <div className="disabled-form" style={{marginTop: "8px"}}> 
                                            <input type="text"
                                                value={accountDetail.channel.stringID}
                                                id="copy-string-id"
                                                readOnly
                                                style={{paddingRight: "40px"}}
                                            /> 
                                    
                                            <FiCopy 
                                                onClick={() => {
                                                    copyToClip("copy-string-id", "Channel ID copied")
                                                }}
                                            />
                                        </div>

                                        <label>
                                            <b>Channel Name</b>
                                        </label>
                                        <p className="value-detail">
                                            <b>{accountDetail.channel.displayName}</b>
                                        </p>
                                        
                                        {accountDetail.channel.topics.length > 0 &&
                                            <>
                                                <label>
                                                    <b>Topic</b>
                                                </label>

                                                <p className="topic-val">
                                                    <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                                                </p>
                                            </>
                                        }

                                        <label>
                                            <b>Status</b>
                                        </label>
                                        
                                        <p 
                                            className={`
                                                value-detail-status
                                                value-detail 
                                            `}
                                        >
                                            {accountDetail.channel.status === "verified" ? 
                                                <>
                                                    <img src={statusConnected} alt="" />
                                                    <b className="status-value green-text">{accountDetail.channel.statusText}</b>
                                                </>
                                                :
                                                (accountDetail.channel.status === "in_review" ?
                                                    <>
                                                        <b className="status-value font-yellow">   
                                                            <FiClock />    
                                                            {accountDetail.channel.statusText}
                                                        </b>
                                                    </>
                                                    :
                                                    <>
                                                        <img src={iconCloseRed} alt="" />
                                                        <b className="status-value red-text">{accountDetail.channel.statusText}</b>
                                                    </>
                                                )
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* chatbot */}
                            <div className="integration-google-detail-account-box-1 box-channel-button">
                              <div className="integration-google-detail-account-box-1-top padding-bottom24">
                                <b>Chatbot</b>
                                {/* <p className='bot-name'>
                                    Setup Your Chatbot
                                </p> */}

                                {!errorChatbot &&
                                    <div className="integration-google-detail-account-box-1-top-button">
                                        {googleChatbot.item.id !== 0 &&
                                        <ButtonWithLoadingOrIcon 
                                            icon={{
                                                type: "img",
                                                src: iconDelete
                                            }}
                                            className="red-button main-button-40 button-channel-detail"
                                            text="Remove Chatbot"
                                            position="left"
                                            onClickAction={() => toggleModalRemoveChatbot()}
                                        />
                                        }

                                        <ButtonWithLoadingOrIcon 
                                            icon={{
                                                type: "svg",
                                                src: FiEdit
                                            }}
                                            className="no-fill-button main-button-40 button-channel-detail"
                                            text="Set Chatbot"
                                            position="left"
                                            onClickAction={() => {
                                                props.history.push({
                                                    pathname: `/o/${props.match.params.orgID}/integration/google-business-messages/edit-chatbot/${props.match.params.id}`,
                                                    state: {
                                                        accountDetail: accountDetail,
                                                        chatbot: googleChatbot.item
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            
                            {errorChatbot ?
                                <RetrieveDataLoading 
                                    isLoading={false}
                                    errorCode={errorChatbot ? errorChatbot.errorCode : false}
                                    errorMessage={errorChatbot ? errorChatbot.errorMessage : false}
                                />
                                :
                                <div className="telegram-detail-topic">
                                    <div className="channel-button-wrapper margin-top24">
                                        <b className="channel-button-title">Chatbot Name</b>
                                        <br />
                                        <b>{googleChatbot.item.id === 0 ? "Not Set" : googleChatbot.item.chatbotName}</b>
                                    </div>

                                    <div className="channel-button-wrapper margin-top24">
                                        <b className="channel-button-title">Chatbot Type</b>
                                        <br />
                                        <b>{googleChatbot.item.id === 0 ? "Not Set" : googleChatbot.item.chatbotTypeName}</b>
                                    </div>

                                    <div className="channel-button-wrapper status-wrapper margin-top24">
                                        <b className="topic-title">Status</b>
                                        <br />
                                        
                                        {googleChatbot.item.id !== 0 ? 
                                            <>
                                                <img src={statusConnected} alt="" />
                                                <b className="status-value text-green">Enabled</b>
                                            </>
                                            :
                                            <>
                                                <img src={iconXgrey} alt="" />
                                                <b className="status-value">Disabled</b>
                                            </>
                                        }
                                    </div>

                                    {/* <ButtonWithLoadingOrIcon

                                    /> */}
                                </div>
                            }
                            
                            </div>

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/google-business-messages/auto-message/setup/${props.match.params.id}`,
                                        state: {
                                            type: type
                                        }
                                    })
                                }}
                                data={welcomeMessageData}
                                isLoading={loadingGetWelcomeMessage}
                                type="welcome"
                                error={errorWelcome}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/google-business-messages/auto-message/setup/${props.match.params.id}`,
                                        state: {
                                            type: type
                                        }
                                    })
                                }}
                                data={awayMessageData}
                                isLoading={loadingGetAwayMessage}
                                type="away"
                                error={errorAway}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/google-business-messages/auto-message/setup/${props.match.params.id}`,
                                        state: {
                                            type: type
                                        }
                                    })
                                }}
                                data={closingMessageData}
                                isLoading={loadingGetClosingMessage}
                                type="closing"
                                error={errorClosing}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/google-business-messages/auto-message/setup/${props.match.params.id}`,
                                        state: {
                                            type: type
                                        }
                                    })
                                }}
                                data={ratingMessageData}
                                isLoading={loadingGetRatingMessage}
                                type="rating"
                                error={errorRating}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/google-business-messages/auto-resolve-setup/${props.match.params.id}`,
                                        state: {
                                            type: type,
                                            medium: "google_business",
                                            backToLink: `/o/${props.match.params.orgID}/integration/google-business-messages/detail/${props.match.params.id}`
                                        }
                                    })
                                }}
                                data={autoResolveData}
                                isLoading={loadingAutoResolve}
                                type="autoResolve"
                                error={errorAutoResolve}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/google-business-messages/auto-unassign-setup/${props.match.params.id}`,
                                        state: {
                                            type: type,
                                            medium: "google_business",
                                            backToLink: `/o/${props.match.params.orgID}/integration/google-business-messages/detail/${props.match.params.id}`
                                        }
                                    })
                                }}
                                data={autoUnassignData}
                                isLoading={loadingAutoUnassign}
                                type="autoUnassign"
                                error={errorAutoUnassign}
                            />

                            <PopupRemove
                                removeTitle={`Remove Chatbot?`}
                                removeDescription={`This chatbot will be removed from this channel.`}
                                onClickCancel={toggleModalRemoveChatbot}
                                onClickRemove={removeChatbot}
                                waitForResponse={waitForResponseRemoveChatbot}
                                showModalProps={isShowModalRemoveChatbot}
                                submitText="Delete"
                                colorButton="red-button"
                                />
                            {/* chatbot */}
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default ChannelDetail;