import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const CaseLabelService = {
  getCaseLabelList(idOrTime, search, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      search
    };

    BaseService.doPostAccessToken(API.GET_CASE_LABEL_LIST, data, headers, callback);
  },
  getCaseLabelDetail(idOrTime, id, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      id: Number(id)
    };

    BaseService.doPostAccessToken(API.GET_CASE_LABEL_DETAIL, data, headers, callback);
  },
  editCaseLabel(idOrTime, dataLabel, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      id: Number(dataLabel.id),
      name: dataLabel.name,
      description: dataLabel.description,
      backgroundColor: dataLabel.backgroundColor
    };

    BaseService.doPostAccessToken(API.EDIT_CASE_LABEL, data, headers, callback);
  },
  createCaseLabel(idOrTime, dataLabel, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      name: dataLabel.name,
      description: dataLabel.description,
      backgroundColor: dataLabel.backgroundColor
    };

    BaseService.doPostAccessToken(API.CREATE_CASE_LABEL, data, headers, callback);
  },
  deleteCaseLabel(idOrTime, dataLabel, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      id: Number(dataLabel.id),
      createdTime: Number(dataLabel.createdTime)
    };

    BaseService.doPostAccessToken(API.DELETE_CASE_LABEL, data, headers, callback);
  },
  setConfigs(idOrTime, dataConfigs, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      allowCreateInInbox: dataConfigs.allowCreateInInbox,
    };

    BaseService.doPostAccessToken(API.SET_CONFIGS, data, headers, callback);
  },

};

const API = {
  GET_CASE_LABEL_LIST: "/organization/case_label/get_list",
  GET_CASE_LABEL_DETAIL: "/organization/case_label/get_details",
  EDIT_CASE_LABEL: "/organization/case_label/edit",
  CREATE_CASE_LABEL: "/organization/case_label/create",
  DELETE_CASE_LABEL: "/organization/case_label/delete",
  SET_CONFIGS: "/organization/case_label/set_configs"
}

export default CaseLabelService;
