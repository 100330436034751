import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationWebhookServices = {
  getWebhook(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET, {}, headers, callback);
  },

  setWebhook(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET, data, headers, callback);
  },

  getWebhookEvent(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_EVENT, {}, headers, callback);
  },
};

const API = {
  GET: '/organization/webhook_configs/get',
  SET: '/organization/webhook_configs/set',
  GET_EVENT: '/organization/get_webhook_event_types'
};

export default IntegrationWebhookServices;
