import React, { useEffect, useState } from "react";
import "./IntegrationWhatsappBusinessDetailAccount.scss";
import { TabContent, Nav, NavItem } from 'reactstrap';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
// import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import { ToastContainer } from "react-toastify";
import ChannelDetail from "./channelDetail/ChannelDetail";
import AccountProfile from "./accountProfile/AccountProfile";
import BusinessProfile from "./businessProfile/BusinessProfile";
import MessageTemplate from "./messageTemplate/MessageTemplate";
import RequestMessageTemplate from "./requestMessageTemplate/RequestMessageTemplate";
import PhoneNumberList from "./phoneNumberList/PhoneNumberList";

const IntegrationWhatsappBusinessDetailAccount = props => {
    const WHATSAPP_TAB = {
        // {
        //     tab: "Timezone",
        //     component: ""
        // },
        channelDetails: {
            id: "channelDetails",
            tab: "Channel Details",
            component: <ChannelDetail {...props} />
        },
        accountProfile: {
            id: "accountProfile",
            tab: "Account Profile",
            component: <AccountProfile {...props} />
        },
        businessProfile: {
            id: "businessProfile",
            tab: "Business Profile",
            component: <BusinessProfile {...props} />
        },
        messageTemplate: {
            id: "messageTemplate",
            tab: "Message Templates",
            component: <MessageTemplate {...props} />
        },
        requestMessageTemplate: {
            id: "requestMessageTemplate",
            tab: "Message Template Requests",
            component: <RequestMessageTemplate {...props} />
        },
        phoneNumberList: {
            id: "phoneNumberList",
            tab: "Phone Number List",
            component: <PhoneNumberList {...props} />
        },
    }

    let [activeTab, setActiveTab] = useState(WHATSAPP_TAB.channelDetails.id);
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    const back = () => history.push(`/o/${orgID}/integration/whatsapp-business-api`);

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration/whatsapp-business-api`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if (orgID) {
        }
    }, [orgID])

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.whichTab) {
                setActiveTab(WHATSAPP_TAB[props.history.location.state.whichTab].id);
            }
            if (props.history.location.state.photoChanged) {
                doToast("Account photo updated");
            }
            window.history.pushState(null, '');
        }
    }, [props.history])

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <div className="sectionWrap integration-main-container integration-whatsapp-business-api-detail-account text-left">
                    <BackButton text="Back to WhatsApp Business API" onClick={back} />

                    <Nav tabs>
                        {Object.keys(WHATSAPP_TAB).map((value, index) => {
                            return (
                                <NavItem key={`tab-setup-${index}`} className={`${activeTab === WHATSAPP_TAB[value].id ? 'active-setup-tab' : ''}`}>
                                    <div className="setup-tab-wrapper"
                                        onClick={() => { toggle(WHATSAPP_TAB[value].id); }}
                                    >
                                        <b>{WHATSAPP_TAB[value].tab}</b>
                                    </div>
                                </NavItem>
                            )
                        })}
                    </Nav>

                    <TabContent>
                        {WHATSAPP_TAB[activeTab].component}
                    </TabContent>
                </div>
            </SectionWrap>

            <ToastContainer className="ToastContainer" />
        </div>
    )
}

export default IntegrationWhatsappBusinessDetailAccount;
