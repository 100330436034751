import React, { useState, useEffect } from 'react';
import './AddPrimaryCard.scss';
import NewOrganizationHeader from "../../newOrganizationHeader/NewOrganizationHeader";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { MdInfoOutline } from "react-icons/md";
// import Loading from "../../../reuseableComponent/loading/Loading";
import iconVisa from "../../../../assets/img/payment-card/visa.png";
import iconJsb from "../../../../assets/img/payment-card/jcb.png";
import iconMastercard from "../../../../assets/img/payment-card/mastercard.png";
import iconAmex from "../../../../assets/img/payment-card/amex.png";
import { FiAlertCircle } from "react-icons/fi";
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { setSelectedTier, clearSelectedTier } from "../../../../redux/actions/reduxActionSelectedTier";

const CARD_BRAND = {
  visa: {
      label: "Visa",
      img: iconVisa
  },
  mastercard: {
      label: "Mastercard",
      img: iconMastercard
  },
  jcb: {
      label: "JCB",
      img: iconJsb
  },
  american_express: {
      label: "American Express",
      img: iconAmex
  },
}

let getCardBrandId = (cardNumber) => {
  let _value = false;

  let regs = {
      visa: /^4[0-9]\d+$/,
      mastercard: /^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9][0-9]|27[0-1][0-9]|2720)\d+$/,
      american_express: /^(34|37)\d+$/,
      jcb: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/,
  };

  Object.keys(regs).map((value) => {
      if(regs[value].test(cardNumber)) {
          _value = value;
      }

      return null;
  })

  return _value;
}

const AddPrimaryCard = (props) => {
  const [isShowTooltipCvc, setIsShowTooltipCvc] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  let [cardFormData, setCardFormData] = useState({
      cardName: "",
      cardNumber: "",
      cardCvvCvc: "",
      cardExpiry: ""
  })
  let [errorAddCard, setErrorAddCard] = useState({
      name: "",
      number: "",
      cvvCvc: "",
      expiry: ""
  })

  const back = () => {
    props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods`)
  }

  const toggleTooltip = () => {
    setIsShowTooltipCvc(!isShowTooltipCvc);
  }

  // const modalOnError = (e) => {
  //   e.preventDefault()
  //   setShowModalError(true);
  // }

  const closeModalError = () => {
    setShowModalError(false);
  }

  let onChangeFormCard = (e) => {
    let _cardFormData = {...cardFormData};
    let _errorAddCard = {...errorAddCard};

    if(e.target.id === "cardExpiry") {
        let value = e.target.value;
            
        if(value.length === 2) {
            value = value + "/";
        }
            
        _cardFormData[e.target.id] = value;        
    }else if(e.target.id === "cardNumber") {
        let value = e.target.value;
        
        if(value.length > 0) {
            value = value.split(" ").join("");
            value = value.match(new RegExp('.{1,4}', 'g')).join(" ");
        }
        
        if(_errorAddCard.number !== "" && window.Xendit.card.validateCardNumber(value.replace(/\s/g, ''))) {
            _errorAddCard.number = "";
        }

        _cardFormData[e.target.id] = value;
    }else {
        _cardFormData[e.target.id] = e.target.value;
    }

    setCardFormData(_cardFormData);

    if(e.target.id === "cardName" && e.target.value.length === 0) {
        _errorAddCard.name = "";
    }

    if(e.target.id === "cardNumber"  && e.target.value.length === 0) {
        _errorAddCard.number = "";
    }

    if(e.target.id === "cardExpiry" && e.target.value.length === 0) {
        _errorAddCard.expiry = "";
    }

    if(e.target.id === "cardCvvCvc" && e.target.value.length === 0) {
        _errorAddCard.cvvCvc = "";
    }
    
    setErrorAddCard(_errorAddCard);
}

  let onKeyPressNumberOnly = (e) => {
      if(e.keyCode !== 8) {
          if(e.keyCode > 57 || e.keycode < 47 || e.shiftKey) {
              e.preventDefault();
          }
      }
  }

  let onKeyPressExpiry = (e) => {
      let data = {...cardFormData};
      let value = e.target.value;
      
      if(e.keyCode !== 8) {
          if(e.keyCode > 57 || e.keycode < 47 || e.shiftKey) {
              e.preventDefault();
          }
      }else {
          if(e.keyCode === 8 && (data[e.target.id].includes("/") && data[e.target.id].length === 3)) {
              data[e.target.id] = value[0];    
              setCardFormData(data);
              e.preventDefault();
          }
      }
  }

  let checkExpiry = (month, year) => {
      let monthNow = new Date().getMonth() + 1;
      let yearNow = new Date().getFullYear().toString().substr(-2);
      let result = false;
      let _year = parseInt(year);
      let _month = month.length === 2 && month[0] === "0" ? parseInt(month[1]) : parseInt(month);

      if(_year > yearNow) {
          result = true;
      }else if(_year === yearNow) {
          if(_month >= monthNow) {
              result = true;
          }else {
              result = false;
          }
      }else {
          result = false;
      }

      return result
  }

  let disabledVerifyCard = () => {
      let data = {...cardFormData};
      let regNumber = /\d/;

      return (
          data.cardName.length < 1 ||
          regNumber.test(data.cardName) ||
          !window.Xendit.card.validateCardNumber(data.cardNumber.replace(/\s/g, '')) ||
          // !window.Xendit.card.validateExpiry(data.cardExpiry.split("/")[0], data.cardExpiry.split("/")[1]) ||
          !window.Xendit.card.validateCvn(data.cardCvvCvc) ||
          !checkExpiry(data.cardExpiry.split("/")[0], data.cardExpiry.split("/")[1]) ||
          data.cardCvvCvc.length !== 3
      )
  }

  let onBlurInput = (e) => {
      let data = {...cardFormData};
      let id = e.target.id;
      let _errorAddCard = {...errorAddCard};
      let regNumber = /\d/;
      let errorText = {
          name: "Name on card cannot be numbers",
          number: "Card number is invalid",
          expiry: "Expiry is invalid",
          cvvCvc: "CVV/CVC is invalid"
      }

      if(id === "cardName") {
          if(regNumber.test(data.cardName)) {
              _errorAddCard.name = errorText.name;
          }else {
              _errorAddCard.name = "";
          }
      }

      if(id === "cardNumber") {
          if(!window.Xendit.card.validateCardNumber(data.cardNumber.replace(/\s/g, ''))) {
              _errorAddCard.number = errorText.number;
          }else {
              _errorAddCard.number = "";
          }
      }

      if(id === "cardExpiry") {
          if(
            // !window.Xendit.card.validateExpiry(data.cardExpiry.split("/")[0], data.cardExpiry.split("/")[1])
            !checkExpiry(data.cardExpiry.split("/")[0], data.cardExpiry.split("/")[1])
          ) {
              _errorAddCard.expiry = errorText.expiry;
          }else {
              _errorAddCard.expiry = "";
          }
      }

      if(id === "cardCvvCvc") {
          if(!window.Xendit.card.validateCvn(data.cardCvvCvc) || data.cardCvvCvc.length !== 3) {
              _errorAddCard.cvvCvc = errorText.cvvCvc;
          }else {
              _errorAddCard.cvvCvc = "";
          }
      }

      setErrorAddCard(_errorAddCard);
  }

  let submitCard = () => {
    let _selectedTier = {...props.selectedTier};
    _selectedTier.cardDetail = cardFormData;
    props.setSelectedTier(_selectedTier);
    props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction`)
  }

  useEffect(() => {
    if(!props.selectedTier) {
      props.history.push(`/o/${props.match.params.orgID}/home`);
    }else {
      if(!props.selectedTier.paymentSetting.isAutomaticPayment) {
        props.history.push(`/o/${props.match.params.orgID}/home`);
      }

      if(props.selectedTier.cardDetail) {
        setCardFormData(props.selectedTier.cardDetail)
      }
    }
  }, [props.selectedTier])

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.failed) {
        setShowModalError(true);
      } 
      
      window.history.pushState(null, '');
    }
  }, [props.history]);

  const renderModal = () => {
    return (
      <Modal isOpen={showModalError} className={"add-card-modal"} toggle={closeModalError}>
        <ModalBody>
          <div className="add-card-modal-wrapper">
            <div className="error-title-wrapper">
              <FiAlertCircle />
              <b className="error-title">We Couldn’t Add Your Card</b>
            </div>
            <p className="error-description">Please make sure all the details you have entered were correct.</p>

            <button className="orange-button dismiss" onClick={closeModalError}>Dismiss</button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <>
      <NewOrganizationHeader
        titleText="Start Subscription"
        hideLogout
        // topRightContent={renderStep}
      />

      <div className="add-card-main-wrapper">
        <SectionWrap
            {...props}
            orgID={props.match.params.orgID}
            _className="section-width-100 pos-relative"
            barVerifyClass="margin-top-0"
        >
          {renderModal()}
          <div className="add-card-content">
            <div className="add-card-header">
              <BackButton text="Back to Select How You Pay" onClick={back} />
            </div>

            <div className="add-card-container">
              <h1>Add Primary Card</h1>
              <div className="form-content-wrapper">
                {/* <form > */}
                  <label><b>Name on Card</b></label>
                  <input type="text"
                    placeholder="Insert name on card"
                    id="cardName"
                    value={cardFormData.cardName}
                    onChange={(e) => onChangeFormCard(e)}
                    onBlur={(e) => onBlurInput(e)}
                    maxLength="50"
                  />
                  {cardFormData.cardName.length > 0 && errorAddCard.name !== "" &&
                    <p className="warning-error-red">
                      <FiAlertCircle />
                      {errorAddCard.name}
                    </p>
                  }

                  <label><b>Card Number</b></label>
                  <div className="card-number">
                    <input type="text"
                      maxLength="24"
                      autoComplete="false"
                      placeholder="xxxxxxxxxxxxxxxxx"
                      id="cardNumber"
                      onBlur={(e) => onBlurInput(e)}
                      value={cardFormData.cardNumber}
                      onChange={(e) => onChangeFormCard(e)}
                    />
                    {cardFormData.cardNumber.length > 0 && errorAddCard.number !== "" &&
                      <p className="warning-error-red">
                        <FiAlertCircle />
                        {errorAddCard.number}
                      </p>
                    }

                    {getCardBrandId(cardFormData.cardNumber.replace(/\s/g, '')) &&
                      <img src={CARD_BRAND[getCardBrandId(cardFormData.cardNumber.replace(/\s/g, ''))].img} alt="" />
                    }
                  </div>

                  <div className="payment-div-50">
                    <label><b>Expiry</b></label>
                    <input type="text"
                      autoComplete="false"
                      id="cardExpiry"
                      value={cardFormData.cardExpiry}
                      placeholder="mm/yy"
                      maxLength="5"
                      onKeyDown={(e) => onKeyPressExpiry(e)}
                      onChange={(e) => onChangeFormCard(e)}
                      onBlur={(e) => onBlurInput(e)}
                    />
                    {cardFormData.cardExpiry.length > 0 && errorAddCard.expiry !== "" &&
                      <p className="warning-error-red">
                        <FiAlertCircle />
                        {errorAddCard.expiry}
                      </p>
                    }
                  </div>

                  <div className="payment-div-50 no-margin">
                    <label>
                      <b>CVV/CVC </b>
                      <MdInfoOutline onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} />

                      <div className="custom-tooltip" style={{ display: isShowTooltipCvc ? "block" : "none" }}>
                        CVV/CVC is a 3 digit number that is
                        located at the back of your card.
                                          </div>
                    </label>
                    <input type="password" autoComplete="false"
                      id="cardCvvCvc"
                      value={cardFormData.cardCvvCvc}
                      placeholder="3 digits"
                      maxLength="3"
                      onKeyDown={(e) => onKeyPressNumberOnly(e)}
                      onChange={(e) => onChangeFormCard(e)}
                      onBlur={(e) => onBlurInput(e)}
                    />
                    {cardFormData.cardCvvCvc.length > 0 && errorAddCard.cvvCvc !== "" &&
                      <p className="warning-error-red">
                        <FiAlertCircle />
                        {errorAddCard.cvvCvc}
                      </p>
                    }
                  </div>

                  <button className="orange-button submit-card" onClick={submitCard} disabled={disabledVerifyCard()}>
                    Review Tier & Payment Method
                  </button>
                  {/* {!isWaitCardAuthentication &&
                    <button className="orange-button submit-card" disabled={disabledVerifyCard()}>
                      Verify Card
                    </button>
                  } */}
                {/* </form> */}

                {/* {isWaitCardAuthentication &&
                  <button className="orange-button submit-card">
                    <Loading />
                  </button>
                } */}
              </div>
            </div>
          </div>
        </SectionWrap>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  setSelectedTier,
  clearSelectedTier
};

const mapStateToProps = state => ({
  selectedTier: state.selectedTier
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPrimaryCard);