import React from 'react';
import './RoomList.scss';
// import RoomListHeader from './roomListHeader/RoomListHeader';
import RoomListConnectionBar from './roomListConnectionBar/RoomListConnectionBar';
import AccountPanel from '../../components/chatRoom/accountPanel/accountPanel';
import RoomListGlobalSearch from './roomListGlobalSearch/RoomListGlobalSearch';
// import RoomListChat from './roomListChat/RoomListChat';
import RoomListTab from './roomListTab/RoomListTab';
import { connect } from 'react-redux';

// const CONNECTING_STATUS = {
//   disconnected: 1,
//   loading: 2,
//   connected: 3
// };

var RoomList = (props) => {
  // let [myAccountModal, setMyAccountModal] = useState(false);
  // let [onSearching, setOnSearching] = useState(false);

  // let toggleMyAccountModal = () => {
  //   setMyAccountModal(!myAccountModal);
  // }

  // let [newChatModal, setNewChatModal] = useState(false);

  // let toggleNewChatModal = () => {
  //   setNewChatModal(!newChatModal);
  // }

  // let onChangeSearchRoomList = (e) => {
  //   if(e.target.value.length > 0) {
  //     setOnSearching(true);
  //   }else {
  //     setOnSearching(false);
  //   }
  // }

  return (
    <React.Fragment>
      <div className={`room-list-container`}>
        <RoomListGlobalSearch 
          {...props} 
          onClickRoomListProps={props.onClickRoomListProps}
          topicListProps={props.topicListProps}
          messageListenerNewMessageProps={props.messageListenerNewMessageProps}
          messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
          listenerStartTypingProps={props.listenerStartTypingProps}
          listenerStopTypingProps={props.listenerStopTypingProps}
          newEmitMessageProps={props.newEmitMessageProps}
          connectingStatusProps={props.connectingStatusProps}
          _changeContactInfo={props._changeContactInfo}
        />
        {/* <RoomListHeader toggleMyAccountModal={toggleMyAccountModal}
                        toggleNewChatModal={toggleNewChatModal}
                        onChangeSearchRoomListProps={onChangeSearchRoomList}
                        caseCountProps={caseCount}
                        // activeCasePanelProps={props.activeCasePanelProps}
        /> */}

        {/* {props.connectingStatusProps !== CONNECTING_STATUS.connected && */}
        {!props.globalSearch.show &&
          <RoomListConnectionBar connectingStatusProps={props.connectingStatusProps} />
        }
        {/* } */}
        
        {/* <RoomListChat onClickRoomListProps={props.onClickRoomListProps} 
                      style={onSearching ? {display: 'none'} : {display: 'block'}}
                      messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                      messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                      forceRefreshRoomProps={props.forceRefreshRoomProps}
                      undoForceRefreshRoomProps={props.undoForceRefreshRoomProps}
                      parentProps={props.parentProps}
                      listenerStartTypingProps={props.listenerStartTypingProps}
                      listenerStopTypingProps={props.listenerStopTypingProps}
                      newEmitMessageProps={props.newEmitMessageProps}
        /> */}
        <AccountPanel 
            {...props}
            agentDetailProps={props.agentDetailProps}
            currentOrganizationProps={props.currentOrganizationProps}
            // startClockInActionProps={startClockInAction}
        />

        <RoomListTab 
          {...props}
          parentProps={props.parentProps} 
          onClickRoomListProps={props.onClickRoomListProps}
          topicListProps={props.topicListProps}
          messageListenerNewMessageProps={props.messageListenerNewMessageProps}
          messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
          listenerStartTypingProps={props.listenerStartTypingProps}
          listenerStopTypingProps={props.listenerStopTypingProps}
          newEmitMessageProps={props.newEmitMessageProps}
          connectingStatusProps={props.connectingStatusProps}
          _changeContactInfo={props._changeContactInfo}
          setNewEmitMessageProps={props.setNewEmitMessageProps}
        />
      </div>

      {/* <RoomListSetupModal setupModal={props.setupModal} /> */}
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  appData: state.appData,
  activeCasePanel: state.activeCasePanel,
  globalSearch: state.globalSearch
});

export default connect(mapStateToProps, null)(RoomList);