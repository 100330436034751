import React, { useState, useEffect } from 'react';
import './IntegrationTelegramDetailAccount.scss';
import { NavLink } from "react-router-dom";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import AutoResolveServices from '../../../../services/newServices/AutoResolveServices';
import IntegrationTelegramServices from '../../../../services/integration/IntegrationTelegramServices';
import AutoUnassignServices from '../../../../services/newServices/AutoUnassignServices';
import PopupRemove from '../../../reuseableComponent/popupRemove/PopupRemove';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IntegrationAutoMessageDetail from "../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import { FiEdit, FiCopy, FiLink2 } from 'react-icons/fi';
import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
import statusDisconnect from '../../../../assets/img/integration/icon-disconnect.svg';
import iconXgrey from '../../../../assets/img/integration/icon-x-grey.svg';
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import { connect } from 'react-redux';

const IntegrationTelegramDetailAccount = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  const [telegramAccountDetail, setTelegramAccountDetail] = useState(null);
  const [telegramChannelLink, setTelegramChannelLink] = useState(null);
  const [telegramChannelLinkNeverSet, setTelegramChannelLinkNeverSet] = useState(false);
  const [telegramChatbot, setTelegramChatbot] = useState(null);
  const [isShowModalDisconnectChannel, setIsShowModalDisconnectChannel] = useState(false);
  const [waitForResponseDisconnect, setWaitForResponseDisconnect] = useState(false);
  const [isShowModalDeleteChannel, setIsShowModalDeleteChannel] = useState(false);
  const [waitForResponseDeleteChannel, setWaitForResponseDeleteChannel] = useState(false);
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [isShowModalRemoveChatbot, setIsShowModalRemoveChatbot] = useState(false);
  const [waitForResponseRemoveChatbot, setWaitForResponseRemoveChatbot] = useState(false);
  const [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(false);
  const [welcomeMessageData, setWelcomeMessageData] = useState(false);
  const [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
  const [awayMessageData, setAwayMessageData] = useState(false);
  const [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
  const [closingMessageData, setClosingMessageData] = useState(false);
  const [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
  const [ratingMessageData, setRatingMessageData] = useState(false);
  let [loadingAutoResolve, setLoadingAutoResolve] = useState(false);
  let [autoResolveData, setAutoResolveData] = useState(false);
  let [errorWelcome, setErrorWelcome] = useState(false);
  let [errorAway, setErrorAway] = useState(false);
  let [errorClosing, setErrorClosing] = useState(false);
  let [errorRating, setErrorRating] = useState(false);
  let [errorChatbot, setErrorChatbot] = useState(false);
  let [errorAutoResolve, setErrorAutoResolve] = useState(false);
  let [loadingAutoUnassign, setLoadingAutoUnassign] = useState(false);
  let [autoUnassignData, setAutoUnassignData] = useState(false);
  let [errorAutoUnassign, setErrorAutoUnassign] = useState(false);

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if(telegramAccountDetail !== null) {
      getTelegramChannelLink();
      runGetWelcomeMessage();
      runGetAwayMessage();
      runGetClosingMessage();
      runGetRatingMessage();
      runGetAutoResolve();
      runGetAutoUnassign();
    }
  }, [telegramAccountDetail])

  useEffect(() => {
    if(telegramChannelLink !== null || telegramChannelLinkNeverSet) {
      getChatBot();
    }
  }, [telegramChannelLink, telegramChannelLinkNeverSet])

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getTelegramAccountDetail(paramID, match.params.accountID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };
  
  let toggleModalRemoveChatbot = () => {
    setIsShowModalRemoveChatbot(!isShowModalRemoveChatbot);
  };

  let copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  const getChatBot = () => {
    IntegrationTelegramServices.getChatBot(orgID, {channelID: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setTelegramChatbot(response.dataResult.data);
      } else {
        // setTelegramAccountDetail(null);
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorChatbot({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        })

        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingGetDetailData(false);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  const getTelegramAccountDetail = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);

    IntegrationTelegramServices.getDetail(orgID, accountID, (response) => {
       let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setTelegramAccountDetail(response.dataResult.data);
      } else {
        setTelegramAccountDetail(null);
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingGetDetailData(false);
      }

      // setIsLoadingGetDetailData(false);
    });
  };

  const getTelegramChannelLink = () => {
    let data = {
      id: parseInt(match.params.accountID)
    }

    IntegrationTelegramServices.getChannelLink(orgID, data, (response) => {
      let _retriveDataError = {...retriveDataError};
      let dataResult = response.dataResult;

      if (dataResult.status === 200) {
        setTelegramChannelLink(dataResult.data.channelLink);
      } else {
        if(dataResult.error.code === "40401") {
          setTelegramChannelLinkNeverSet(true);
        }else {
          setTelegramChannelLink(null);
          _retriveDataError.message = dataResult.error.message;
          _retriveDataError.code = dataResult.error.code;
          setRetrieveDataError(_retriveDataError); 
        }
      }

      // setIsLoadingGetDetailData(false);
    });
  }

  const runGetWelcomeMessage = () => {
    setLoadingGetWelcomeMessage(true);

    IntegrationTelegramServices.getWelcomeMessage(orgID, match.params.accountID, (response) => {
        // let _retriveDataError = {...retriveDataError};
        
        if (response.dataResult.error.message === "") {
            setWelcomeMessageData(response.dataResult.data.welcomeMessage);
        } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorWelcome({
              errorCode: response.dataResult.error.code,
              errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
        }

        setLoadingGetWelcomeMessage(false);
    });
  }

  const runGetAwayMessage = () => {
    setLoadingGetAwayMessage(true);

    IntegrationTelegramServices.getAwayMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAwayMessageData(response.dataResult.data.awayMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAway({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetAwayMessage(false);
    });
  }

  const runGetClosingMessage = () => {
    setLoadingGetClosingMessage(true);

    IntegrationTelegramServices.getClosingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setClosingMessageData(response.dataResult.data.closingMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorClosing({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetClosingMessage(false);
    });
  }

  const runGetRatingMessage = () => {
    setLoadingGetRatingMessage(true);

    IntegrationTelegramServices.getRatingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setRatingMessageData(response.dataResult.data.userRating);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorRating({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetRatingMessage(false);
    });
  }

  const toggleModalDeleteChannel = () => {
    setIsShowModalDeleteChannel(!isShowModalDeleteChannel);
  };

  const deleteChannel = () => {
    setWaitForResponseDeleteChannel(true);

    IntegrationTelegramServices.delete(
      orgID,
      telegramAccountDetail.channel.id,
      telegramAccountDetail.channel.createdTime,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message);
            setWaitForResponseDeleteChannel(false);
          } else {
            doToast(response.dataResult.data.message);
            runGetOrganizationDetail(response.dataResult);
          }
        } else {
          doToast(response.dataResult.data.message, 'fail');
          setWaitForResponseDeleteChannel(false);
        }
      }
    );
  };

  const removeChatbot = () => {
    setWaitForResponseRemoveChatbot(true);

    let data = {
      connectionID: telegramChatbot.item.id,
      channelID: parseInt(match.params.accountID),
      chatbotID: telegramChatbot.item.chatbotID,
    };
    
    IntegrationTelegramServices.removeChatBot(
      orgID,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message);
            setWaitForResponseRemoveChatbot(false);
          } else {
            doToast(response.dataResult.data.message);
            toggleModalRemoveChatbot();
            setIsLoadingGetDetailData(true);
            setTelegramChannelLink(null);
            setTelegramChatbot(null);
            setTelegramChannelLinkNeverSet(false);
            getTelegramAccountDetail(orgID, match.params.accountID);
          }
        } else {
          doToast(response.dataResult.data.message, 'fail');
          setWaitForResponseRemoveChatbot(false);
        }
      }
    );
  }

  let runGetOrganizationDetail = (responseResult) => {
    ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {
      if(response.dataResult.status === 200) {
        props.setAllOrganizationDetail(response.dataResult.data);

        setTimeout(() => {
          toggleModalDeleteChannel();
          
          history.push(`/o/${orgID}/integration/telegram`, {
            state: 'create',
            message: responseResult.data.message,
          });
        }, 2000)
      }else {
        doToast(response.dataResult.error.message, "fail")
      }
    })
  }

  const toggleModalDisconnectChannel = () => {
    setIsShowModalDisconnectChannel(!isShowModalDisconnectChannel);
  };

  const disconnectChannel = () => {
    setWaitForResponseDisconnect(true);

    IntegrationTelegramServices.disconnect(
      orgID,
      telegramAccountDetail.channel.id,
      telegramAccountDetail.channel.createdTime,
      (response) => {
        setWaitForResponseDisconnect(false);
        toggleModalDisconnectChannel();

        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
          } else {
            doToast(response.dataResult.data.message);
          }
        } else {
          doToast(response.dataResult.data.message, 'fail');
        }
      }
    );
  };

  const back = () => history.push(`/o/${orgID}/integration/telegram`);

  // const editTopic = (id) => history.push(`/o/${orgID}/integration/telegram/edit-topic/${id}`);
  // const editToken = (id) => history.push(`/o/${orgID}/integration/telegram/edit-token/${id}`);
  
  // let goToEditChannelButton = (id) => {
  //   history.push(`/o/${orgID}/integration/telegram/edit-channel-button/${id}`);
  // }

  let getChannelLink = () => {
    let link = telegramChannelLink.url;
    const CURRENT_LOCATION = encodeURIComponent(window.location.href);

    link = `${link}${telegramChannelLink.sendPageURL ? `%0A%0AFrom URL: ${CURRENT_LOCATION}` : ""}`;
    
    return link;
  }

  let renderTopics = () => {
    let text = "";
    
    telegramAccountDetail.channel.topics.map((v, i) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  let runGetAutoResolve = () => {
    setLoadingAutoResolve(true);
  
    AutoResolveServices.getAutoResolve(orgID, "telegram", {id: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAutoResolveData(response.dataResult.data.autoResolveConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoResolve({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }
  
      setLoadingAutoResolve(false);
    });
  }
  
  let runGetAutoUnassign = () => {
    setLoadingAutoUnassign(true);

    AutoUnassignServices.getAutoUnassign(orgID, "telegram", {id: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAutoUnassignData(response.dataResult.data.autoUnassignAgentConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoUnassign({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingAutoUnassign(false);
    });
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-telegram-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading 
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message} 
              />
            </div>
          ) : (
            <>
              <BackButton text="Back to Telegram" onClick={back} />

              <div className="integration-telegram-detail-account-box-1">
                <div className="integration-telegram-detail-account-box-1-top">
                  <b>{telegramAccountDetail.channel.botName}</b>
                  <p className="bot-name">{`@${telegramAccountDetail.channel.botUsername}`}</p>

                  <div className="integration-telegram-detail-account-box-1-top-button">
                    <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "img",
                          src: iconDelete
                        }}
                        className="red-button main-button-40"
                        text="Delete"
                        position="left"
                        onClickAction={() => toggleModalDeleteChannel()}
                    />
                  </div>
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Channel ID</b>
                  </label>
                  <div className="disabled-form"> 
                    <input type="text"
                      value={telegramAccountDetail.channel.stringID}
                      // onClick={copyToClip}
                      id="copy-string-id"
                      readOnly
                      style={{paddingRight: "40px"}}
                    /> 
            
                    <FiCopy 
                      onClick={() => {
                        copyToClip("copy-string-id", "Channel ID copied")
                      }}
                    />
                  </div>
                </div>

                <div className="telegram-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title">Topic</b>
                      {telegramAccountDetail.channel.topics.length > 0 ?
                          <>
                            <p className="topic-val">
                                <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                            </p>
                          </>
                          :
                          <b>No Topic</b>
                      }
                  </div>
                  
                  <NavLink to={`/o/${orgID}/integration/telegram/edit-topic/${telegramAccountDetail.channel.id}`}>
                    <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40"
                        text="Edit Topic"
                        position="left"
                    />
                  </NavLink>
                </div>
                
                <div className="telegram-bot-token-status">
                  <div className="bot-status-wrapper">
                    <b className="bot-status-title">Bot Token Status</b>
                    <div className="status-wrapper">
                      {telegramAccountDetail.channel.isConnected ? (
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-text text-green">Connected</b>
                        </>
                      ) : (
                        <>
                          <img src={statusDisconnect} alt="" />
                          <b className="status-text text-red">Not Connected</b>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="button-wrapper">
                    {/* <ButtonWithLoadingOrIcon 
                      className="red-button main-button-40"
                      text="Disconnect"
                      onClickAction={() => toggleModalDisconnectChannel()}
                    /> */}

                    <NavLink to={`/o/${orgID}/integration/telegram/edit-token/${telegramAccountDetail.channel.id}`}>
                      <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40"
                        text="Edit Bot Token"
                        position="left"
                      />
                    </NavLink>
                  </div>
                </div>

                <PopupRemove
                  removeTitle={`Disconnect Channel?`}
                  removeDescription={`This channel will be disconnected, your customers will not be able to reach you on this channel.`}
                  onClickCancel={toggleModalDisconnectChannel}
                  onClickRemove={disconnectChannel}
                  waitForResponse={waitForResponseDisconnect}
                  showModalProps={isShowModalDisconnectChannel}
                  submitText="Disconnect"
                  colorButton="red-button"
                />

                <PopupRemove
                  removeTitle={`Delete Channel?`}
                  removeDescription={`This channel will be deleted and your will not be able to recieve and send messages from this channel anymore.`}
                  onClickCancel={toggleModalDeleteChannel}
                  onClickRemove={deleteChannel}
                  waitForResponse={waitForResponseDeleteChannel}
                  showModalProps={isShowModalDeleteChannel}
                  submitText="Delete"
                  colorButton="red-button"
                />

                <PopupRemove
                  removeTitle={`Remove Chatbot?`}
                  removeDescription={`This chatbot will be removed from this channel.`}
                  onClickCancel={toggleModalRemoveChatbot}
                  onClickRemove={removeChatbot}
                  waitForResponse={waitForResponseRemoveChatbot}
                  showModalProps={isShowModalRemoveChatbot}
                  submitText="Delete"
                  colorButton="red-button"
                />
              </div>

              {/* channel button */}
              <div className="integration-telegram-detail-account-box-1 box-channel-button">
                <div className="integration-telegram-detail-account-box-1-top">
                  <b>Channel Button for Live Chat</b>
                  <p className="bot-name">
                    For more details, <a rel="noopener noreferrer" className="link-orange" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/social-channel-button" target="_blank">please read the documentation.</a>
                  </p>

                  <div className="integration-telegram-detail-account-box-1-top-button">
                    {!telegramChannelLinkNeverSet && telegramChannelLink.isEnabled &&
                      <ButtonWithLoadingOrIcon 
                          icon={{
                          type: "svg",
                          src: FiLink2
                          }}
                          className="no-fill-button main-button-40 button-channel-detail button-channel-detail"
                          text="Preview Link"
                          position="left"
                          onClickAction={() => {
                              window.open(getChannelLink(), "_blank");
                          }}
                      />
                    }

                    <NavLink to={`/o/${orgID}/integration/telegram/edit-channel-button/${telegramAccountDetail.channel.id}`}>
                      <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Edit"
                          position="left"
                      />
                    </NavLink>
                  </div>
                </div>

                <div className="telegram-detail-topic">
                  <div className="channel-button-wrapper">
                      <b className="channel-button-title">Title</b>
                      <br />
                      <b>{telegramChannelLinkNeverSet ? "Not Set" : (telegramChannelLink.title === "" ? "Not Set" : telegramChannelLink.title)}</b>
                  </div>

                  <div className="channel-button-wrapper">
                      <b className="channel-button-title">Username</b>
                      <br />
                      <b>{telegramChannelLinkNeverSet ? "Not Set" : (telegramChannelLink.targetID === "" ? "Not Set" : telegramChannelLink.targetID)}</b>
                  </div>

                  <div className="topic-wrapper status-wrapper">
                    <b className="topic-title">Status</b>
                    <br />
                    
                    {telegramChannelLinkNeverSet ? 
                      <>
                        <img src={iconXgrey} alt="" />
                        <b className="status-value">Disabled</b>
                      </>
                      :
                      telegramChannelLink.isEnabled ?
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-value status-enabled">Enabled</b>
                        </>
                        :
                        <>
                          <img src={iconXgrey} alt="" />
                          <b className="status-value">Disabled</b>
                        </>
                    }
                  </div>
                </div>
              </div>
              {/* channel button */}

              {/* chatbot */}
              <div className="integration-telegram-detail-account-box-1 box-channel-button">
                <div className="integration-telegram-detail-account-box-1-top">
                  <b>Chatbot</b>
                  <p className='bot-name'>
                    Setup Your Chatbot
                  </p>

                  {!errorChatbot &&
                    <div className="integration-telegram-detail-account-box-1-top-button">
                      {telegramChatbot.item.id !== 0 &&
                        <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "img",
                            src: iconDelete
                          }}
                          className="red-button main-button-40 button-channel-detail"
                          text="Remove Chatbot"
                          position="left"
                          onClickAction={() => toggleModalRemoveChatbot()}
                        />
                      }

                      <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Set Chatbot"
                          position="left"
                          onClickAction={() => {
                            history.push({
                              pathname: `/o/${orgID}/integration/telegram/edit-chatbot/${match.params.accountID}`,
                              state: {
                                accountDetail: telegramAccountDetail,
                                chatbot: telegramChatbot.item
                              }
                            })
                          }}
                      />
                    </div>
                  }
                </div>
                
                {errorChatbot ?
                  <RetrieveDataLoading 
                    isLoading={false}
                    errorCode={errorChatbot ? errorChatbot.errorCode : false}
                    errorMessage={errorChatbot ? errorChatbot.errorMessage : false}
                  />
                  :
                  <div className="telegram-detail-topic">
                    <div className="channel-button-wrapper">
                        <b className="channel-button-title">Chatbot Name</b>
                        <br />
                        <b>{telegramChatbot.item.id === 0 ? "Not Set" : telegramChatbot.item.chatbotName}</b>
                    </div>

                    <div className="channel-button-wrapper">
                        <b className="channel-button-title">Chatbot Type</b>
                        <br />
                        <b>{telegramChatbot.item.id === 0 ? "Not Set" : telegramChatbot.item.chatbotTypeName}</b>
                    </div>

                    {/* <ButtonWithLoadingOrIcon

                    /> */}
                  </div>
                }
              </div>
              {/* chatbot */}

              <IntegrationAutoMessageDetail 
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                      pathname: `/o/${orgID}/integration/telegram/auto-message/setup/${match.params.accountID}`,
                      state: {
                          type: type
                      }
                  })
                }}
                data={welcomeMessageData}
                isLoading={loadingGetWelcomeMessage}
                type="welcome"
                error={errorWelcome}
              />

              <IntegrationAutoMessageDetail 
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                      pathname: `/o/${orgID}/integration/telegram/auto-message/setup/${match.params.accountID}`,
                      state: {
                          type: type
                      }
                  })
                }}
                data={awayMessageData}
                isLoading={loadingGetAwayMessage}
                type="away"
                error={errorAway}
              />

              <IntegrationAutoMessageDetail 
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                      pathname: `/o/${orgID}/integration/telegram/auto-message/setup/${match.params.accountID}`,
                      state: {
                          type: type
                      }
                  })
                }}
                data={closingMessageData}
                isLoading={loadingGetClosingMessage}
                type="closing"
                error={errorClosing}
              />

              <IntegrationAutoMessageDetail 
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                      pathname: `/o/${orgID}/integration/telegram/auto-message/setup/${match.params.accountID}`,
                      state: {
                          type: type
                      }
                  })
                }}
                data={ratingMessageData}
                isLoading={loadingGetRatingMessage}
                type="rating"
                error={errorRating}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/telegram/auto-resolve-setup/${match.params.accountID}`,
                          state: {
                              type: type,
                              medium: "telegram",
                              backToLink: `/o/${orgID}/integration/telegram/detail/${match.params.accountID}`
                          }
                      })
                  }}
                  data={autoResolveData}
                  isLoading={loadingAutoResolve}
                  type="autoResolve"
                  error={errorAutoResolve}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/telegram/auto-unassign-setup/${match.params.accountID}`,
                          state: {
                              type: type,
                              medium: "telegram",
                              backToLink: `/o/${orgID}/integration/telegram/detail/${match.params.accountID}`
                          }
                      })
                  }}
                  data={autoUnassignData}
                  isLoading={loadingAutoUnassign}
                  type="autoUnassign"
                  error={errorAutoUnassign}
              />
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

const mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

const mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTelegramDetailAccount);
