import React, { useEffect, useState } from "react";
import "./IntegrationEmailEditMailConfig.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo, FiEye, FiEyeOff } from "react-icons/fi";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import HelperInput from "../../../../helper/HelperInput";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import IntegrationEmailServices from '../../../../services/integration/IntegrationEmailServices';

const IntegrationEmailEditMailConfig = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isShowPassword, setIsShowPassword] = useState(false);
    let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
    let [loadingSubmit, setLoadingSubmit] = useState(false);
    let [data, setData] = useState({
        outgoing: "",
        incoming: "",
        protocolOut: {
            value: "",
            label: ""
        },
        portOut: "",
        portIn: "",
        protocolIn: "ssl",
    });
    let [dataOrigin, setDataOrigin] = useState({
        outgoing: "",
        incoming: "",
        protocolOut: {
            value: "",
            label: ""
        },
        portOut: "",
        portIn: "",
        protocolIn: "ssl",
    });
    let [errorMessageData, setErrorMessageData] = useState({
        topic: "",
        provider: "",
        outgoing: "",
        incoming: "",
        protocolOut: "",
        email: "",
        password: "",
        portOut: "",
        portIn: "",
        protocolIn: "",
        sender: ""
    });
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });

    const EMAIL_PROVIDER = [
        {
            value: "custom",
            label: "Custom",
        },
        {
            value: "1",
            label: "Gmail (TLS)",
            "smtpServerHost": "smtp.gmail.com",
            "smtpServerPort": 587,
            "smtpServerProtocol": "starttls",
            "imapServerHost": "imap.gmail.com",
            "imapServerPort": 993,
            "imapServerProtocol": "ssl"
        },
        {
            value: "2",
            label: "Gmail (SSL)",
            "smtpServerHost": "smtp.gmail.com",
            "smtpServerPort": 465,
            "smtpServerProtocol": "ssl",
            "imapServerHost": "imap.gmail.com",
            "imapServerPort": 993,
            "imapServerProtocol": "ssl"
        },
        {
            value: "3",
            label: "Yahoo Mail (TLS)",
            "smtpServerHost": "smtp.gmail.com",
            "smtpServerPort": 587,
            "smtpServerProtocol": "starttls",
            "imapServerHost": "imap.mail.yahoo.com",
            "imapServerPort": 993,
            "imapServerProtocol": "ssl"
        },
        {
            value: "4",
            label: "Yahoo Mail (SSL)",
            "smtpServerHost": "smtp.mail.yahoo.com",
            "smtpServerPort": 465,
            "smtpServerProtocol": "ssl",
            "imapServerHost": "imap.mail.yahoo.com",
            "imapServerPort": 993,
            "imapServerProtocol": "ssl"
        },
        {
            value: "5",
            label: "Outlook.com/Hotmail (TLS)",
            "smtpServerHost": "smtp-mail.outlook.com",
            "smtpServerPort": 587,
            "smtpServerProtocol": "starttls",
            "imapServerHost": "outlook.office365.com",
            "imapServerPort": 993,
            "imapServerProtocol": "tls",
        }
    ];

    const PROTOCOL = [
        {
            value: "starttls",
            label: "StartTLS",
        },
        {
            value: "ssl",
            label: "SSL",
        },
    ]

    let back = () => {
        if (!loadingSubmit) {
            history.push(`/o/${orgID}/integration/email/detail/${props.match.params.accountID}`);
        }
    }

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
        getAccountDetail();
    }, []);

    let onSelectDropdown = (id, value) => {
        let _errorMessageData = { ...errorMessageData };
        let _data = { ...data };
        _errorMessageData[id] = "";
        _data[id] = value;

        if (id === "provider") {
            _data.outgoing = value.smtpServerHost;
            _data.portOut = value.smtpServerPort;
            _data.protocolOut = {
                value: value.smtpServerProtocol,
                label: value.smtpServerProtocol === "ssl" ? "SSL" : "StartTLS"
            };
            _data.incoming = value.imapServerHost;
            _data.portIn = value.imapServerPort;
        }

        if (id === "protocolOut") {
            _data.provider = EMAIL_PROVIDER[0];
        }

        setErrorMessageData(_errorMessageData);
        setData(_data);
    }

    let onChangeText = (id, e) => {
        let _errorMessageData = { ...errorMessageData };
        let _data = { ...data };

        _errorMessageData[id] = "";

        if (id === "sender" && e.target.value.length > 50) {
            _errorMessageData[id] = "Characters exceed limit";
        }

        if (id !== "sender" && id !== "email" && id !== "password") {
            _data.provider = EMAIL_PROVIDER[0];
        }

        _data[id] = e.target.value;
        setErrorMessageData(_errorMessageData);
        setData(_data);
    }

    let onSubmit = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = { ...errorMessageData };

        if (!HelperInput.validURLNoProtocol(data.outgoing)) {
            _errorMessageData.outgoing = "Invalid server address";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-outgoing" }
        }

        if (data.outgoing === "") {
            _errorMessageData.outgoing = "This field is required";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-outgoing" }
        }

        if (!HelperInput.numberOnly(data.portOut)) {
            _errorMessageData.portOut = "Invalid Port, numeric value only";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-portOut" }
        }

        if (data.portOut === "") {
            _errorMessageData.portOut = "This field is required";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-portOut" }
        }

        if (data.protocolOut.value === "") {
            _errorMessageData.protocolOut = "Please select email provider";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-protocolOut" }
        }

        if (!HelperInput.validURLNoProtocol(data.incoming)) {
            _errorMessageData.incoming = "Invalid server address";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-incoming" }
        }

        if (data.incoming === "") {
            _errorMessageData.incoming = "This field is required";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-incoming" }
        }

        if (!HelperInput.numberOnly(data.portIn)) {
            _errorMessageData.portIn = "Invalid Port, numeric value only";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-portIn" }
        }

        if (data.portIn === "") {
            _errorMessageData.portIn = "This field is required";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-portIn" }
        }

        if (data.protocolIn === "") {
            _errorMessageData.protocolIn = "This field is required";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-protocolIn" }
        }

        if (errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        } else {
            setLoadingSubmit(true);

            let _data = {
                id: parseInt(props.match.params.accountID),
                smtpServerHost: data.outgoing,
                smtpServerPort: parseInt(data.portOut),
                smtpServerProtocol: data.protocolOut.value,
                imapServerHost: data.incoming,
                imapServerPort: parseInt(data.portIn),
                imapServerProtocol: data.protocolIn,
                emailAddress: data.email,
                password: data.password,
                senderName: data.sender
            }

            IntegrationEmailServices.editConfig(props.match.params.orgID, _data, (response) => {
                let _retriveDataError = { ...retriveDataError };
                if (response.dataResult.error.message === "") {
                    if (response.dataResult.data.success) {
                        setDataOrigin({ ...data });

                        setTimeout(() => {
                            back();
                        }, 3000)
                    } else {
                        doToast(response.dataResult.data.message, "fail");
                        setLoadingSubmit(false);
                    }
                } else {
                    _retriveDataError.message = response.dataResult.error.message;
                    _retriveDataError.code = response.dataResult.error.code;
                    setRetrieveDataError(_retriveDataError);

                    doToast(response.dataResult.error.message, 'fail');
                    setLoadingSubmit(false);
                }
            });
        }
    }

    let getAccountDetail = () => {
        setIsLoadingGetDetailData(true);

        let _data = {
            id: parseInt(props.match.params.accountID)
        }

        IntegrationEmailServices.getDetail(props.match.params.orgID, _data, (response) => {
            let _retriveDataError = { ...retriveDataError };
            if (response.dataResult.error.message === "") {
                let newData = {};

                newData = {
                    password: "",
                    outgoing: response.dataResult.data.channel.smtpServerHost,
                    incoming: response.dataResult.data.channel.imapServerHost,
                    protocolOut: {
                        value: response.dataResult.data.channel.smtpServerProtocol,
                        label: response.dataResult.data.channel.smtpServerProtocol === "ssl" ? "SSL" : "StartTLS"
                    },
                    portOut: response.dataResult.data.channel.smtpServerPort,
                    portIn: response.dataResult.data.channel.imapServerPort,
                    protocolIn: response.dataResult.data.channel.imapServerProtocol
                }

                setData({ ...newData });
                setDataOrigin({ ...newData });
            } else {
                _retriveDataError.message = response.dataResult.error.message;
                _retriveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);

                // doToast(response.dataResult.error.message, 'fail');
                // setIsLoadingGetDetailData(false);
            }

            setIsLoadingGetDetailData(false);
        });
    };

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={JSON.stringify(dataOrigin)}
                dataAfterChange={JSON.stringify(data)}
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

                {isLoadingGetDetailData ?
                    <RetrieveDataLoading
                        isLoading={isLoadingGetDetailData}
                        errorMessage={retriveDataError.message}
                    />
                    :
                    <div className="sectionWrap integration-main-container integration-email-edit-account text-left">
                        <BackButton text="Back to Channel Details" onClick={back} />
                        <div className="integration-email-edit-account-box">
                            <div className="integration-email-edit-account-box-top">
                                <b>Mail Server Config</b>

                                {loadingSubmit ?
                                    <ButtonWithLoadingOrIcon
                                        // isDisabled={!isFormValid()} 
                                        isDisabled
                                        loadingColor={"gray"}
                                        isLoading
                                        className="orange-button main-button-40"
                                        text="Save Changes"
                                        position="left"
                                    />
                                    :
                                    <ButtonWithLoadingOrIcon
                                        // isDisabled={!isFormValid()} 
                                        onClickAction={onSubmit}
                                        className="orange-button main-button-40"
                                        text="Save Changes"
                                        position="left"
                                    />
                                }
                            </div>

                            <div className="integration-email-edit-account-box-form">
                                <div className="integration-email-edit-account-box-form-inner">
                                    <label>
                                        <b>Password</b>

                                        <span className="grey-text"> (Optional)</span>
                                    </label>
                                    <div className="password-wrapper">
                                        <input
                                            type={!isShowPassword ? "password" : "text"}
                                            placeholder="**********"
                                            onChange={(e) => { onChangeText("password", e) }}
                                            value={data.password}
                                            className={`input-password ${errorMessageData.password !== "" ? "border-red" : ""}`}
                                            disabled={loadingSubmit}
                                            autocomplete="off"
                                        />
                                        {isShowPassword ?
                                            <FiEye onClick={() => setIsShowPassword(false)} />
                                            :
                                            <FiEyeOff onClick={() => setIsShowPassword(true)} />
                                        }
                                    </div>
                                    <ErrorOrInfoComp
                                        text={errorMessageData.password === "" ? "Leave empty to keep your current password" : ""}
                                        _className={errorMessageData.password !== "" ? "font-red" : ""}
                                        icon={<FiInfo />}
                                    />

                                    <div className="line-border" />

                                    <label className="no-margin">
                                        <b className="font16">Incoming Email (IMAP)</b>
                                    </label>
                                    <label>
                                        <b>Email Server</b>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="e.g. imap.gmail.com"
                                        onChange={(e) => { onChangeText("incoming", e) }}
                                        value={data.incoming}
                                        className={`input-incoming ${errorMessageData.incoming !== "" ? "border-red" : ""}`}
                                        disabled={loadingSubmit}
                                    />
                                    {errorMessageData.outgoing !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.incoming === "" ? "" : errorMessageData.incoming}
                                            _className={errorMessageData.incoming !== "" ? "font-red" : ""}
                                            icon={<FiInfo />}
                                        />
                                    }

                                    <label>
                                        <b>Port</b>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="123"
                                        onChange={(e) => { onChangeText("portIn", e) }}
                                        value={data.portIn}
                                        className={`input-port-in ${errorMessageData.portIn !== "" ? "border-red" : ""}`}
                                        disabled={loadingSubmit}
                                    />
                                    {errorMessageData.portIn !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.portIn === "" ? "" : errorMessageData.portIn}
                                            _className={errorMessageData.portIn !== "" ? "font-red" : ""}
                                            icon={<FiInfo />}
                                        />
                                    }

                                    <label><b>Protocol</b></label>
                                    <input
                                        type="text"
                                        placeholder="123"
                                        disabled
                                        onChange={(e) => { onChangeText("protocolIn", e) }}
                                        value={data.protocolIn}
                                        className={`input-protocol-in ${errorMessageData.protocolIn !== "" ? "border-red text-uppercase" : "text-uppercase"}`}
                                    />

                                    {errorMessageData.protocolIn !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.protocolIn}
                                            _className={errorMessageData.protocolIn !== "" ? "font-red " : ""}
                                            icon={<FiInfo />}
                                        />
                                    }

                                    <div className="line-border" />

                                    <label className="no-margin">
                                        <b className="font16">Outgoing Email (SMTP)</b>
                                    </label>

                                    <label>
                                        <b>Email Server</b>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="e.g. smtp.gmail.com"
                                        onChange={(e) => { onChangeText("outgoing", e) }}
                                        value={data.outgoing}
                                        className={`input-outgoing ${errorMessageData.outgoing !== "" ? "border-red" : ""}`}
                                        disabled={loadingSubmit}
                                    />
                                    {errorMessageData.outgoing !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.outgoing}
                                            _className={errorMessageData.outgoing !== "" ? "font-red" : ""}
                                            icon={<FiInfo />}
                                        />
                                    }

                                    <label>
                                        <b>Port</b>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="123"
                                        onChange={(e) => { onChangeText("portOut", e) }}
                                        value={data.portOut}
                                        className={`input-port-out ${errorMessageData.portOut !== "" ? "border-red" : ""}`}
                                        disabled={loadingSubmit}
                                    />
                                    {errorMessageData.portOut !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.portOut === "" ? "" : errorMessageData.portOut}
                                            _className={errorMessageData.portOut !== "" ? "font-red" : ""}
                                            icon={<FiInfo />}
                                        />
                                    }

                                    <label><b>Protocol</b></label>
                                    <CustomSelectOption
                                        optionListProps={PROTOCOL}
                                        valueDropdownProps={data.protocolOut}
                                        placeholderProps={"Select protocol"}
                                        onClickDropDownListOptionProps={(e) => onSelectDropdown("protocolOut", e)}
                                        specificClassNameProps="custom-select-email-provider"
                                        _className={`input-protocol-out ${errorMessageData.protocolOut !== "" ? "border-red" : ""}`}
                                        isDisabled={loadingSubmit}
                                    />
                                    {errorMessageData.protocolOut !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.protocolOut}
                                            _className={errorMessageData.protocolOut !== "" ? "font-red " : ""}
                                            icon={<FiInfo />}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </SectionWrap>
        </div>
    )
}

export default IntegrationEmailEditMailConfig;