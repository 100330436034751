import React, { useState, useEffect } from "react";
import "./FaqAdd.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo, FiLink, FiDownload, FiPlay, FiMessageSquare, FiUser } from "react-icons/fi";
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white-real.svg';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from '../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization'
import topicServices from "../../../../services/newServices/TopicServices";
import FaqServices from '../../../../services/newServices/FaqServices'
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperInput from '../../../../helper/HelperInput';
import Helper from '../../../../helper/HelperChat';
import { TAPLIVE_FAQ_TYPE } from '../../../../constants/taplive';
import HelperDate from '../../../../helper/HelperDate';

let FaqAdd = props => {
  let DEFAULT_PATH_VAL = {
    title: "",
    content: "",
    type: "",
    topicIDs: [],
    showMessageAfterTopicSelected: false,
    messageAfterTopicSelected: "",
    apiURL: "",
    responseAPI: {
      type: "",
      text: {
        body: ""
      }
    },
    contentOnAPIError: ""
  }
  let { match } = props;
  let [orgID, setOrgID] = useState(false);
  let [topicList, setTopicList] = useState([]);
  let [topicListOrigin, setTopicListOrigin] = useState([]);
  let [isCheckAll, setIsCheckAll] = useState(false);
  let [selectedTopic, setSelectedTopic] = useState([]);
  let [searchTopic, setSearchTopic] = useState("");
  let [loadingValidateContent, setLoadingValidateContent] = useState(false)
  let [isLoadingCreate, setIsLoadingCreate] = useState(false)
  let [createVal, setCreateVal] = useState(DEFAULT_PATH_VAL)
  let [createValOrigin, setCreateValOrigin] = useState(DEFAULT_PATH_VAL)
  let [errorMessageData, setErrorMessageData] = useState({
    title: "",
    content: "",
    type: "",
    topic: "",
    showMessageAfterTopicSelected: "",
    messageAfterTopicSelected: "",
    apiURL: "",
    contentOnAPIError: ""
  })

  let back = () => {
    if(props.match.params.pathID) {
      props.history.push(`/o/${orgID}/setup/qna/detail/${props.match.params.pathID}/${props.match.params.itemID}`)
    }else {
      props.history.push(`/o/${orgID}/setup/qna`)
    }
  }

  useEffect(() => {
    if(orgID) {
      getTopic();
    }
  }, [orgID])

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      // getFaqList(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/setup/scf`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    if (props.history.location.state) {
      if(props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.topic = "";
    setErrorMessageData(_errorMessageData);
  }, [JSON.stringify(selectedTopic)])

  let closeModalCreatePath = () => {
    if(!isLoadingCreate) {
      // setCreateVal(DEFAULT_PATH_VAL);
      // setSearchTopic("");
      // setTopicList(topicListOrigin);
      // setIsCheckAll(false);
      // setSelectedTopic([]);
    }
  }

  let getTopic = () => {
    topicServices.getTopic(orgID, "list", null, (response) => {
      let success = response.dataResult.status === 200;
      let hasError = response.dataResult.error && response.dataResult.error.code && response.dataResult.error.message;
      if (success) {
        setTopicList(response.dataResult.data.topics)
        setTopicListOrigin(response.dataResult.data.topics)
      }else if(hasError) {
        doToast(response.dataResult.error.message, "fail");
      }
    });
  };

  let validateURL = () => {
    let _errorMessageData = {...errorMessageData};
    let errorClass = "";
    let errorCount = 0;

    if(createVal.apiURL === "") {
      _errorMessageData.apiURL = "This field is required"
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-url-message" }
    }

    if(!HelperInput.validURL(createVal.apiURL)) {
      _errorMessageData.apiURL = "Invalid URL format"
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-url-message" }
    }

    
    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      setLoadingValidateContent(true);
  
      FaqServices.validate(orgID, {apiURL: createVal.apiURL}, (response) => {
        if (response.dataResult.error.message === "") {
          if(response.dataResult.data.statusCode !== 200) {
            doToast(response.dataResult.data.message, "fail")
          }else {
            let _createVal = {...createVal};
            _createVal.responseAPI = response.dataResult.data.apiResponse;
            setCreateVal(_createVal);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }

        setLoadingValidateContent(false);
      });
    }
  }

  let createPath = async () => {
    let _createVal = {...createVal};
    let _errorMessageData = {...errorMessageData};
    let errorCount = 0;
    let errorClass = "";

    if(_createVal.title === "") {
      _errorMessageData.title = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    if(_createVal.title.length > 200) {
      _errorMessageData.title = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    if(_createVal.type === TAPLIVE_FAQ_TYPE.qna.val || _createVal.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val) {
      if(_createVal.content === "") {
        _errorMessageData.content = "This field is required";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-content" }
      }
  
      if(_createVal.content.length > 1000) {
        _errorMessageData.content = "Characters exceed limit";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-content" }
      }
    }

    if(_createVal.type === "") {
      _errorMessageData.type = "Please select type";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-type" }
    }

    if(_createVal.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val) {
      if(selectedTopic.length === 0) {
        _errorMessageData.topic = "Please Choose Related Departments";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-topic" }
      }

      if(_createVal.showMessageAfterTopicSelected && _createVal.messageAfterTopicSelected === "") {
        _errorMessageData.messageAfterTopicSelected = "This field is required"
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-message-after-topic" }
      }

      if(_createVal.showMessageAfterTopicSelected && _createVal.messageAfterTopicSelected.length > 120) {
        _errorMessageData.messageAfterTopicSelected = "Characters exceed limit"
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-message-after-topic" }
      }
    }

    if(_createVal.type === TAPLIVE_FAQ_TYPE.qna_via_api.val) {
      if(!HelperInput.validURL(_createVal.apiURL)) {
        _errorMessageData.apiURL = "Invalid URL format"
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-url-message" }
      }

      if(_createVal.apiURL === "") {
        _errorMessageData.apiURL = "This field is required"
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-url-message" }
      }

      if(_createVal.contentOnAPIError.length > 1000) {
        _errorMessageData.contentOnAPIError = "Characters exceed limit"
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-reply-message" }
      }

      // if(_createVal.contentOnAPIError === "") {
      //   _errorMessageData.contentOnAPIError = "This field is required"
      //   errorCount = errorCount + 1;
      //   if(errorClass === "") { errorClass = "input-reply-message" }
      // }
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      // let _retriveDataError = {...retriveDataError}
      setIsLoadingCreate(true)
      
      _createVal.title = Helper.removeHTMLTag(_createVal.title);
      _createVal.content =  Helper.removeHTMLTag(_createVal.content);
      
      selectedTopic.map((val) => {
        let findIndex = _createVal.topicIDs.findIndex(v => v === val.id);

        if(findIndex === -1) {
          _createVal.topicIDs.push(val.id);
        }

        return null;
      })
      
      if(props.match.params.pathID) {
        _createVal.pathID = parseInt(props.match.params.pathID);
        _createVal.parentItemID = parseInt(props.match.params.itemID);

        FaqServices.addChild(orgID, _createVal, (response) => {
          if (response.dataResult.error.message === "") {
            if(response.dataResult.data.success) {
              // let data = response.dataResult.data;
              setCreateVal(_createVal);
              setCreateValOrigin(_createVal);
              doToast(response.dataResult.data.message);
              setTimeout(() => {
                closeModalCreatePath();
                back();
                // goToDetail(props.match.params.pathID, data.item.itemID, response.dataResult.data.message);
              }, 3000)
            }else {
              doToast(response.dataResult.data.message, "fail");
              setIsLoadingCreate(false);
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setIsLoadingCreate(false);
          }
        });
      }else {
        FaqServices.add(orgID, _createVal, (response) => {
          if (response.dataResult.error.message === "") {
            if(response.dataResult.data.success) {
              // let data = response.dataResult.data;
              setCreateVal(_createVal);
              setCreateValOrigin(_createVal);
              doToast(response.dataResult.data.message);
              setTimeout(() => {
                closeModalCreatePath();
                back();
                // goToDetail(data.path.id, data.path.rootItemID, response.dataResult.data.message);
              }, 3000)
            }else {
              doToast(response.dataResult.data.message, "fail");
              setIsLoadingCreate(false);
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setIsLoadingCreate(false);
          }
        });
      }
    }
  };

  let onChange = (e) => {
    let _errorMessageData = {...errorMessageData};
    let id = e.target.id;
    let val = e.target.value;
    let _createVal = {...createVal};
    _errorMessageData[id] = "";
    _createVal[id] = val;
    setErrorMessageData(_errorMessageData);
    setCreateVal(_createVal);
  }

  let onChangeType = (val) => {
    if(!isLoadingCreate) {
      let _errorMessageData = {...errorMessageData};
      let _createVal = {...createVal};
      let _title = {..._createVal}.title;
      // _createVal.topicIDs = [];
      // _createVal.showMessageAfterTopicSelected = false;
      // _createVal.messageAfterTopicSelected = "";
      // _createVal.apiURL = "";
      // _createVal.contentOnAPIError = ""
      _createVal = {...DEFAULT_PATH_VAL};
      _createVal.type = val;
      _createVal.title = _title;
      _errorMessageData.title = "";
      _errorMessageData.type = "";
      _errorMessageData.topic = "";
      _errorMessageData.messageAfterTopicSelected = "";
      _errorMessageData.messageAfterTopicSelected = "";
      _errorMessageData.contentOnAPIError = "";
      _errorMessageData.apiURL = "";
      _errorMessageData.messageAfterTopicSelected = "";
      setErrorMessageData(_errorMessageData);
      setCreateVal(_createVal);
    }
  }

  // let onChangeTopic = (e, id) => {
  //   let _createVal = {...createVal};
  //   let _index = findIndexTopic(id);

  //   if(_index === -1) {
  //     _createVal.topicIDs.push(id);
  //   }else {
  //     _createVal.topicIDs.splice(_index, 1);
  //   }
  //   // _createVal.topicIDs = [id];
  //   setCreateVal(_createVal);
  // }

  // let findIndexTopic = (id) => {
  //   let _idx = createVal.topicIDs.findIndex(val => val === id);
  //   return _idx;
  // }

  // let isAllChecked = () => {
  //   let _createVal = {...createVal};

  //   return _createVal.topicIDs.length === topicList.length;
  // }

  // let onSelectAll = (e) => {
  //   let _checked = e.target.checked;
  //   let _createVal = {...createVal};

  //   if(_checked) {
  //     topicList.map((val) => {
  //       if(findIndexTopic(val.id) === -1) {
  //         _createVal.topicIDs.push(val.id);
  //       }

  //       return null;
  //     })
  //   }else {
  //     _createVal.topicIDs = [];
  //   }

  //   setCreateVal(_createVal);
  // }

  let onChangeSingleSelect = (e, val) => {
    let _selectedTopic = [...selectedTopic]

    if (e.target.checked) {
      _selectedTopic = [..._selectedTopic, val]
    } else {
      let _dataSelectedTopic = [..._selectedTopic]
      _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
      setIsCheckAll(false);
    }

    setSelectedTopic(_selectedTopic);
  }

  let onClearAll = () => {
    setIsCheckAll(false);
    setSelectedTopic([]);
  }

  let onCheckAll = (e) => {
    if (e.target.checked) {
      setSelectedTopic(topicList);
    } else {
      setSelectedTopic([]);
    }

    setIsCheckAll(e.target.checked);
  }

  let isChecked = (id) => {
    let _data = [...selectedTopic]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let removeSingleTopic = (val) => {
    setIsCheckAll(false);
    let _selectedTopic = [...selectedTopic];
    let _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    setSelectedTopic(_selectedTopic);
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
      for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
          if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
          }
        }
      }
    }

    setTopicList(result);
  }

  // let goToDetail = (id, rootItemID, message) => {
  //   props.history.push({
  //     pathname: `/o/${orgID}/setup/qna/detail/${id}/${rootItemID}`,
  //     state: {
  //       message: message
  //     }
  //   })
  // }

  let onChangeMessageAfterTopic = (e) => {
    let _createVal = {...createVal};
    let _errorMessageData = {...errorMessageData};
    _createVal.showMessageAfterTopicSelected = e.target.checked;
    _errorMessageData.messageAfterTopicSelected = "";
    setCreateVal(_createVal);
    setErrorMessageData(_errorMessageData);
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
      dataOrigin={JSON.stringify(createValOrigin)}
      dataAfterChange={JSON.stringify(createVal)}
    >
      <BackButton text="Back to QnA Path" onClick={back} />

      <div className="sectionWrap scf-path-create-main-wrapper">
        <div className="topContent scf-path-create-header">
          <p className="scf-path-create-title"><b>Create QnA Path</b></p>

          <div className="topContent-button-wrapper">
            {(isLoadingCreate) ?
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                text="Create"
                position="left"
                loadingColor="gray"
                isLoading
                isDisabled
              />
              :
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                text="Create"
                onClickAction={createPath}
              />
            }
          </div>
        </div>

        <>
          <div className="create-path-add-wrapper">
            <div className="form-content-wrapper">
              <label><b>Title</b> <span className={`input-counter grey-font ${errorMessageData.title === "Characters exceed limit" ? "red-text" : ""}`}>{createVal.title.length}/200</span></label>
              <input disabled={isLoadingCreate} type="text" id="title" onChange={(e) => onChange(e)} value={createVal.title} placeholder=" Insert title here" className={`create-qna-first-input input-title ${errorMessageData.title !== "" ? "border-red" : ""}`} />
              {errorMessageData.title !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageData.title}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }

              <label className={`input-type`}><b>Type</b></label>
              <div className="create-path-add-type-wrapper">
                <div className={`create-path-add-type-select ${isLoadingCreate ? "disabled-type-select" : ""} ${createVal.type === TAPLIVE_FAQ_TYPE.qna.val ? "create-path-add-type-active" : ""}`} onClick={() => onChangeType(TAPLIVE_FAQ_TYPE.qna.val)}>
                  <FiMessageSquare />
                  {TAPLIVE_FAQ_TYPE.qna.text}
                </div>

                <div className={`create-path-add-type-select ${isLoadingCreate ? "disabled-type-select" : ""} ${createVal.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val ? "create-path-add-type-active" : ""}`} onClick={() => onChangeType(TAPLIVE_FAQ_TYPE.talk_to_agent.val)}>
                  <FiUser />
                  {TAPLIVE_FAQ_TYPE.talk_to_agent.text}
                </div>  

                <div className={`create-path-add-type-select ${isLoadingCreate ? "disabled-type-select" : ""} ${createVal.type === TAPLIVE_FAQ_TYPE.qna_via_api.val ? "create-path-add-type-active" : ""}`} onClick={() => onChangeType(TAPLIVE_FAQ_TYPE.qna_via_api.val)}>
                  <FiLink />
                  {TAPLIVE_FAQ_TYPE.qna_via_api.text}
                </div>
              </div>
              {errorMessageData.type !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageData.type}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }

              {(createVal.type === TAPLIVE_FAQ_TYPE.qna.val || createVal.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val) && 
                <>
                  <label><b>Content</b><span className={`input-counter grey-font ${errorMessageData.content === "Characters exceed limit" ? "red-text" : ""}`}>{createVal.content.length}/1000</span></label>
                  <textarea disabled={isLoadingCreate} rows={4} id="content"  onChange={(e) => onChange(e)} value={createVal.content} placeholder={`Insert your description here`} className={`${errorMessageData.content !== "" ? "border-red" : ""}`} />
                  {errorMessageData.content !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.content}
                        _className={"font-red margin-top-0"}
                        icon={<FiInfo />}
                      />
                  }
                </>
              }
              
              {createVal.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val &&
                <>
                  <SelectTopic 
                    onChangeSearchTopic={onChangeSearchTopic}
                    onCheckAll={onCheckAll}
                    onClearAll={onClearAll}
                    topicList={topicList}
                    onChangeSingleSelect={onChangeSingleSelect}
                    isChecked={isChecked}
                    isCheckAll={isCheckAll}
                    searchTopic={searchTopic}
                    title={"Choose Related Departments"}
                    selectedTopic={selectedTopic}
                    removeSingleTopic={removeSingleTopic}
                    _className={`input-topic ${errorMessageData.topic !== "" ? "error-select-topic" : ""}`}
                    isDisabled={isLoadingCreate}
                  />

                  {errorMessageData.topic !== "" &&
                      <ErrorOrInfoComp
                      text={errorMessageData.topic}
                      _className={"font-red"}
                      icon={<FiInfo />}
                      />
                  }

                  <label className="label-left">
                    <label className="custom-switch-wrapper">
                        <input 
                          type="checkbox" 
                          checked={createVal.showMessageAfterTopicSelected} 
                          onClick={(e) => onChangeMessageAfterTopic(e)}
                        />
                        <span className="custom-switch round"></span>
                    </label>

                    <b>Message After Topic is Selected</b>
                    {!createVal.showMessageAfterTopicSelected &&
                      <span className="grey-font text-optional"> (optional)</span>
                    }
                    <span className={`input-counter grey-font ${errorMessageData.messageAfterTopicSelected === "Characters exceed limit" ? "red-text" : ""}`}>{createVal.messageAfterTopicSelected.length}/120</span>
                  </label>
                  
                  {createVal.showMessageAfterTopicSelected &&
                    <>
                      <textarea disabled={isLoadingCreate} rows={4} id="messageAfterTopicSelected" onChange={(e) => onChange(e)} value={createVal.messageAfterTopicSelected} placeholder={`Insert your description here`} className={`input-message-after-topic ${errorMessageData.messageAfterTopicSelected !== "" ? "border-red" : ""}`} />
                      {errorMessageData.messageAfterTopicSelected !== "" &&
                          <ErrorOrInfoComp
                            text={errorMessageData.messageAfterTopicSelected}
                            _className={"font-red margin-top-0"}
                            icon={<FiInfo />}
                          />
                      }
                    </>
                  }
                </>
              }

              {createVal.type === TAPLIVE_FAQ_TYPE.qna_via_api.val &&
                <>
                  <label className="">
                    <b>URL</b>
                  </label>
                  
                  <div className="testurl-wrap">
                    <input type="text" disabled={(loadingValidateContent || isLoadingCreate)} rows={4} id="apiURL" onChange={(e) => onChange(e)} value={createVal.apiURL} placeholder={`Insert URL`} className={`input-url-message ${errorMessageData.apiURL !== "" ? "border-red" : ""}`} />

                    <div className={`testurl ${(loadingValidateContent || isLoadingCreate) ? "testurl-disable" : ""}`} onClick={validateURL}>
                      {(loadingValidateContent) &&
                        <div className="lds-ring">
                          <div /><div /><div /><div />
                        </div>
                      }
                      <b>Test URL</b>
                    </div>
                  </div>
                  <ErrorOrInfoComp
                    text={errorMessageData.apiURL === "" ? "For more information, <a href='https://docs.taptalk.io/onetalk-omnichannel-documentation/qna-via-api' target='_blank'>please read documentation.</a>" : errorMessageData.apiURL}
                    _className={`${errorMessageData.apiURL === "" ? "" : "font-red"}`}
                    icon={<FiInfo />}
                  />

                  <label className="">
                    <b>Response API</b>
                  </label>

                  <div className={`response-api-bubble`}>
                    {createVal.responseAPI.type === "" ?
                      <>
                        Response API will be displayed when your Test URL succeed

                        <div className="message-info">
                          {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} • {HelperDate.formatToString(new Date(), "HH:mm")} <img src={CheckMarkDoubleDark} alt="" />
                        </div>
                      </>
                      :
                      (createVal.responseAPI.type === "image" ?
                        <>
                          <div className="faq-image-video">
                            <img src={createVal.responseAPI.image.url} alt="" />

                            {createVal.responseAPI.image.caption === "" &&
                              <div className="message-info">
                                {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} • {HelperDate.formatToString(new Date(), "HH:mm")} <img src={CheckMarkDoubleWhite} alt="" />
                              </div>
                            }
                          </div>

                          <p className="faq-caption">
                            {createVal.responseAPI.image.caption}
                          </p>

                          {createVal.responseAPI.image.caption !== "" &&
                            <div className="message-info">
                              {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} • {HelperDate.formatToString(new Date(), "HH:mm")} <img src={CheckMarkDoubleDark} alt="" />
                            </div>
                          }
                        </>
                        :
                        (createVal.responseAPI.type === "video" ?
                          <>
                            <div className="faq-image-video">
                              <video src={createVal.responseAPI.video.url} />

                              <div className="black-layer">
                                <FiPlay />
                              </div>

                              {createVal.responseAPI.video.caption === "" &&
                                <div className="message-info">
                                  {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} • {HelperDate.formatToString(new Date(), "HH:mm")} <img src={CheckMarkDoubleWhite} alt="" />
                                </div>
                              }
                            </div>

                            <p className="faq-caption">
                              {createVal.responseAPI.video.caption}
                            </p>

                            {createVal.responseAPI.video.caption !== "" &&
                              <div className="message-info">
                                {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} • {HelperDate.formatToString(new Date(), "HH:mm")} <img src={CheckMarkDoubleDark} alt="" />
                              </div>
                            }
                          </>
                          :
                          (createVal.responseAPI.type === "document" ?
                            <div className="faq-document">
                              <div className="faq-document-outer">
                                <div className="faq-document-inner">
                                  <FiDownload />
                                </div>

                                <p className="faq-filename">
                                  <b>{createVal.responseAPI.document.filename}</b>
                                </p>
                              </div>

                              <p>
                                {createVal.responseAPI.document.caption}
                              </p>

                              <div className="message-info">
                                {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} • {HelperDate.formatToString(new Date(), "HH:mm")} <img src={CheckMarkDoubleDark} alt="" />
                              </div>
                            </div>
                            :
                            <>
                              {createVal.responseAPI.text.body}

                              <div className="message-info">
                                {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} • {HelperDate.formatToString(new Date(), "HH:mm")} <img src={CheckMarkDoubleDark} alt="" />
                              </div>
                            </>
                          )
                        )
                      )
                    }
                  </div>

                  <label className="">
                    <b>Reply message on API error</b>
                    <span className="grey-font text-optional"> (optional)</span>
                    <span className={`input-counter grey-font ${errorMessageData.contentOnAPIError === "Characters exceed limit" ? "red-text" : ""}`}>{createVal.contentOnAPIError.length}/1000</span>
                  </label>
                  <textarea disabled={isLoadingCreate} rows={4} id="contentOnAPIError" onChange={(e) => onChange(e)} value={createVal.contentOnAPIError} placeholder={`Insert your reply message here`} className={`input-reply-message ${errorMessageData.contentOnAPIError !== "" ? "border-red" : ""}`} />
                  {errorMessageData.contentOnAPIError !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.contentOnAPIError}
                        _className={"font-red margin-top-0"}
                        icon={<FiInfo />}
                      />
                  }
                </>
              }
            </div>
          </div>
        </>
      </div>
    </SectionWrap >
  );
};

export default FaqAdd;