import React, { useEffect, useState } from "react";
import "./OnBoardingAssignedAgent.scss";
import { FiExternalLink, FiCheck, FiXCircle } from "react-icons/fi";
import IconAssignAgent from "../../../../assets/img/onboarding/icon-assign-agent.svg";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import OnboardingServices from "../../../../services/onboarding/OnboardingServices";
import { doToast } from "../../../../helper/HelperGeneral";

// const ON_BOARDING = "assignedAgent";

let OnBoardingAssignedAgent = (props) => {
    let [onBoardingInviteMemberDone, setOnBoardingInviteMemberDone] = useState(false);
    let [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false)

    useEffect(() => {
        setOnBoardingInviteMemberDone(props.status)
    }, [props.status])

    let onClickMarkAsDone = () => {
        let _orgID = props.match.params.orgID.split("-")[0];
        
        let data = {
            itemID: props.id,
            isDone: true
        }
        updateStatus(data, _orgID)
    }

    let onClickMarkAsUndone = () => {
        let _orgID = props.match.params.orgID.split("-")[0];
        
        let data = {
            itemID: props.id,
            isDone: false
        }
        updateStatus(data, _orgID)
    }

    let updateStatus = (data, orgID) => {
        setLoadingUpdateStatus(true);
        OnboardingServices.postUpdateStatus(orgID, data, (response) => {
            let dataResult = response.dataResult;

            if(dataResult.error.code === "") {
                setOnBoardingInviteMemberDone(data.isDone);
                props.runLastChangeOnBoarding();
                if(data.isDone) props.completeCount + 1 === 5 && props.handleComplete()
            } else {
                doToast(dataResult.error.message, 'fail');
            }
            setLoadingUpdateStatus(false);
        })
    }

    const onClickReadGuide = () => {
        window.open("https://docs.taptalk.io/onetalk-omnichannel-documentation/getting-started/assign-agent-to-topic", "_blank", "noopener noreferrer")
    }

    return (
        <div className={`onboarding-child-wrapper onboarding-invite-member`}>
            <img src={IconAssignAgent} alt="Onetalk assign agent" className="onboarding-child-image" />

            <p className="onboarding-description">
                Assign specific agents to topics so that they can reply to incoming messages accordingly.
            </p>

            <div className="button-wrapper">
                <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    icon={{
                        type: "svg",
                        src: FiExternalLink
                    }}
                    text="Read Guide"
                    position="left"
                    onClickAction={onClickReadGuide}
                />

                {loadingUpdateStatus ? 
                 <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40 mark-as-undone"
                    text={onBoardingInviteMemberDone ? "Unmark as Done" : "Mark as Done"}
                    position="left"
                    onClickAction={onClickMarkAsUndone}
                    isLoading
                    loadingColor="orange"
                />
                :
                 (onBoardingInviteMemberDone ?
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40 mark-as-undone"
                        icon={{
                            type: "svg",
                            src: FiXCircle
                        }}
                        text="Unmark as Done"
                        position="left"
                        onClickAction={onClickMarkAsUndone}
                        loadingColor="gray"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40"
                        icon={{
                            type: "svg",
                            src: FiCheck
                        }}
                        text="Mark as Done"
                        position="left"
                        onClickAction={onClickMarkAsDone}
                        loadingColor="gray"
                    />)
                }
            </div>
        </div>
    )
}

export default OnBoardingAssignedAgent;