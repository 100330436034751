import React from "react";
import './SalesTalkProductComp.scss';
import { setSalesTalkInbox } from "../../../../../redux/actions/reduxActionSalesTalkInbox";
import { setSalestalkProductDetail } from "../../../../../redux/actions/reduxActionSalestalkProductDetail";
import { rupiahFormat } from "../../../../../helper/HelperGeneral"
import { setSalestalkSelectedProduct } from "../../../../../redux/actions/reduxActionSalestalkSelectedProduct";
import { PRODUCT_PREFIX } from "../../../../../constants/taplive"
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

let SalesTalkProductComp = (props) => {
    let { productData, salesTalkProductFilter, setSalestalkSelectedProduct, salesTalkSelectedProduct } = props;

    let style = {
        scrollStyle: {
            position: "relative",
            backgroundColor: "#ff7d00",
            right: "-4px",
            borderRadius: "8px",
            width: "4px"
        }
    };

    let clickProductDetail = (_data) => {
        let _salesTalkProductDetail = {...props.salesTalkProductDetail};
        _salesTalkProductDetail.isShow = true;
        _salesTalkProductDetail.data = _data;

        props.setSalestalkProductDetail(_salesTalkProductDetail);
    }

    let onSelectProduct = (_data) => {
        let _salesTalkSelectedProduct = {...salesTalkSelectedProduct};

        if(_salesTalkSelectedProduct.data[PRODUCT_PREFIX + _data.id]) {
            delete _salesTalkSelectedProduct.data[PRODUCT_PREFIX + _data.id]
        }else {
            _salesTalkSelectedProduct.data[PRODUCT_PREFIX + _data.id] = _data;
        }

        setSalestalkSelectedProduct(_salesTalkSelectedProduct);
    }
    
    return (
        <div 
            className={`
                sales-talk-product-comp
                ${productData.totalItems === 0 ? "no-product-wrapper" : ""}
            `}
        >   
            {productData.items && productData.items.length === 0 &&
                <div className="sales-talk-product-comp-no-result">
                    <p>
                        <b>{salesTalkProductFilter.search !== "" ? "No Results Were Found" : "Oops!"}</b>
                    </p>
                    
                    {salesTalkProductFilter.search !== "" ?
                        <p>
                            Your search <b>"{salesTalkProductFilter.search}"</b> did not match any results.
                        </p>
                        :
                        <p>
                            You don’t seem to have any products to show at the moment.
                        </p>
                    }
                </div>
            }

            <Scrollbars 
                autoHide 
                autoHideTimeout={500}
                renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
            >   
                <div className="sales-talk-product-comp-inner">
                    {(productData.items && productData.items.length > 0) &&
                        productData.items.map((v, k) => {
                            return (
                                <div className="sales-talk-product-comp-box" key={`sales-talk-item-${k}`}>
                                    <div className="custom-checkbox custom-checkbox-circle">
                                        <input 
                                            type="checkbox" 
                                            id={`product-${k}`} 
                                            checked={salesTalkSelectedProduct.data[PRODUCT_PREFIX + v.id]} 
                                            onClick={() => onSelectProduct(v)} 
                                        />
                                        
                                        <label htmlFor={`product-${k}`} />
                                    </div>    
    
                                    <div className="sales-talk-product-comp-image-wrapper" onClick={() => onSelectProduct(v)}>
                                        <img 
                                            src={v.imageURL}
                                            alt=""
                                        />
                                    </div>
    
                                    <p className="product-title" onClick={() => clickProductDetail(v)}><b >{v.name}</b></p>
                                    <p className="product-price">{rupiahFormat(v.prices[0].unitPrice)}</p>
                                    <p className="product-stock">STOCK: {v.stock}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </Scrollbars>
        </div>
    )
}

let mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    salesTalkInbox: state.salesTalkInbox,
    salesTalkProductDetail: state.salesTalkProductDetail,
    salesTalkProductFilter: state.salesTalkProductFilter,
    salesTalkSelectedProduct: state.salesTalkSelectedProduct
})

let mapDispatchToProps = {
    setSalesTalkInbox,
    setSalestalkProductDetail,
    setSalestalkSelectedProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTalkProductComp);