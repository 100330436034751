import React, { useEffect, useState } from 'react';
import './UTMTab2Show.scss';
import { Modal, ModalBody } from "reactstrap";
import ReportService from '../../../../../services/newServices/ReportService';
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import HelperDate from '../../../../../helper/HelperDate';
import { doToast, checkID } from '../../../../../helper/HelperGeneral';
import { FiDownload, FiFilter, FiInfo } from 'react-icons/fi';
import NoReportFound from "../../../../../assets/img/no-report-found.svg";
import AscendingDescendingTableHeader from '../../../../reuseableComponent/AscendingDescendingTableHeader/AscendingDescendingTableHeader';
import ErrorOrInfoComp from '../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import AdvancedDateFilter from '../../../../reuseableComponent/advancedDateFilter/AdvancedDateFilter';
import Pagination from "../../../../reuseableComponent/pagination/Pagination";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

let UTMTab2Show = (props) => {
    let TABLE_HEADER = [
        {
            title: "Source",
            value: "source",
            class: "col_1"
        },
        {
            title: "Medium",
            value: "medium",
            class: "col_1"
        },
        {
            title: "Campaign",
            value: "campaign",
            class: "col_1"
        },
        {
            title: "Term",
            value: "term",
            class: "col_1"
        },
        {
            title: "Content",
            value: "content",
            class: "col_1"
        },
        {
            title: "Total Cases",
            value: "totalCases",
            class: "col_2"
        },
    ];

    let [orgID, setOrgID] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [printedData, setPrintedData] = useState([]);
    let [isLoadingExportReport, setIsLoadingExportReport] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [dateVal, setDateVal] = useState({
        from: new Date(new Date().setDate(new Date().getDate() - 30)),
        to: new Date()
    });
    let [filterDateForCalender, setFilterDateForCalender] = useState({
        from: undefined,
        to: undefined
    });
    let [filterBy, setFilterBy] = useState({
        text: "Last 30 Days",
        val: "last_30"
    });
    let [filterBySubmit, setFilterBySubmit] = useState({
        text: "Last 30 Days",
        val: "last_30"
    });
    let [sort, setSort] = useState({
        by: TABLE_HEADER[0].source,
        order: "ASC"
    })
    let [pageDisplay, setPageDisplay] = useState(1);
    // let [listPage, setListPage] = useState(50);
    let [totalItems, setTotalItems] = useState(0);
    let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);
    let [showCustomFilter, setShowCustomFilter] = useState(false);
    let [filterGroupBy, setFilterGroupBy] = useState([]);
    let [filterGroupBySubmit, setFilterGroupBySubmit] = useState([]);
    let [errorGroupBy, setErrorGroupBy] = useState("");

    let groupBy = [
        {
            label: "Source",
            value: "source"
        },
        {
            label: "Medium",
            value: "medium"
        },
        {
            label: "Campaign",
            value: "campaign"
        },
        {
            label: "Term",
            value: "term"
        },
        {
            label: "Content",
            value: "content"
        },
    ]

    let changeCheck = (e, _valCheck) => {
        setErrorGroupBy("");
        let val = e.target.checked;
        let _filterGroupBy = filterGroupBy.slice();

        if (val) {
            _filterGroupBy.push(_valCheck);
        } else {
            let _find = _filterGroupBy.findIndex(_v => _v === _valCheck);

            if (_find !== -1) {
                _filterGroupBy.splice(_find, 1);
            }
        }

        setFilterGroupBy(_filterGroupBy)
    }


    let changeSelectAll = (e) => {
        setErrorGroupBy("");
        let val = e.target.checked;
        let _filterGroupBy = filterGroupBy.slice();

        if (val) {
            let _arr = [];

            groupBy.map(v => {
                _arr.push(v.value);

                return null;
            })

            _filterGroupBy = _arr;
        } else {
            _filterGroupBy = [];
        }

        setFilterGroupBy(_filterGroupBy)
    }

    useEffect(() => {
        if (orgID) {
            // getData();
        }
    }, [dateVal])

    useEffect(() => {
        if (orgID) {
            getData();
        }
    }, [orgID])

    useEffect(() => {
        doMount();
        setFilterGroupBy(props._filterGroupBy.slice());
        setFilterGroupBySubmit(props._filterGroupBy.slice());
    }, []);

    let doMount = async () => {
        //filter
        setDateVal(props.filterData.dateVal);
        setFilterDateForCalender(props.filterData.filterDateForCalender);
        setFilterBy(props.filterData.filterBy);
        //filter

        let paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    let { id, createdTime } = responseData.memberships[0].organization;
                    let urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let downloadReport = (data) => {
        // let id = orgID.split('-');
        // let timestamp = + new Date();
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `referrer-utm-from-${HelperDate.formatToString(new Date(dateVal.from), "dd-MM-yyyy")}-to-${HelperDate.formatToString(new Date(dateVal.to), "dd-MM-yyyy")}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    let exportData = () => {
        let data = {
            startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
            endDate: dateVal.to ? HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
            groupBy: filterGroupBySubmit,
            sortBy: sort.by,
            sortOrder: sort.order
        };

        setIsLoadingExportReport(true);

        ReportService.exportUTM(orgID, data,
            (response) => {
                setIsLoadingExportReport(false);
                if (!response) {
                    // setShowModalFailed(true);
                } else {
                    downloadReport(response.dataResult)
                }
            }
        )
    }

    let getData = (_sort = false, page = false, _filter = false) => {
        setShowCustomFilter(false);
        setIsWaitingFetch(true);
        let _retriveDataError = { ...retriveDataError };
        let data = {
            startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
            endDate: dateVal.to ? HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
            pageNumber: !page ? pageDisplay : page,
            pageSize: 20,
            sortBy: !_sort ? sort.by : _sort.by,
            sortOrder: !_sort ? sort.order : _sort.order,
            groupBy: _filter ? _filter : filterGroupBySubmit
        }

        ReportService.getUTM(orgID, data, (response) => {
            setIsWaitingFetch(false);
            let dataResult = response.dataResult;

            if (dataResult.error.message === '') {
                setPrintedData(dataResult.data.items);
                setTotalItems(dataResult.data.totalItems);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
        });
    };

    let handleChangePage = (page) => {
        setPageDisplay(page)

        getData(false, page);
    }

    let printText = (val) => {
        return val !== "" ? val : <span className='grey-font'>(not set)</span>;
    }

    let onSort = (_sort) => {
        let _s = {
            by: _sort,
            order: _sort === sort.by ? (sort.order === "ASC" ? "DESC" : "ASC") : "ASC"
        }

        setSort(_s);
        getData(_s, false);
    }

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.success) {
                doToast(props.history.location.state.message);
            } else {
                doToast(props.history.location.state.message, 'fail');
            }

            window.history.pushState(null, '');
        }
    }, [props.history]);

    let isShow = (key) => {
        let show = true;
        let _idx = filterGroupBySubmit.findIndex(v => v === key);

        if (_idx === -1) {
            show = false;
        }

        return show
    }

    let toggleCustomFilter = () => {
        setShowCustomFilter(!showCustomFilter);
    }

    return (
        <>
            <Modal
                className="modal-custom-filter"
                isOpen={showCustomFilter}
                toggle={() => {
                    setErrorGroupBy("");
                    setShowCustomFilter(false);
                    setFilterDateForCalender(dateVal);
                    setFilterGroupBy(filterGroupBySubmit);
                    setFilterBy(filterBySubmit);
                }}
            >
                <ModalBody>
                    <FiFilter className='custom-filter-title-icon' />

                    <b className='custom-filter-title'>
                        Custom Filter
                    </b>

                    <div className='custom-filter-wrapper'>
                        {!retriveDataError.code &&
                            <AdvancedDateFilter
                                toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
                                isOpenDropdown={isShowDropdownDate}
                                dateVal={dateVal}
                                filterDateForCalender={filterDateForCalender}
                                filterBy={filterBy}
                                onClickDate={(date) => setDateVal(date)}
                                onClickFilterBy={(filter) => {
                                    setFilterBy(filter)
                                }}
                                onClickCalendar={(date) => {
                                    setFilterDateForCalender(date)
                                }}
                                ignoreAllTime
                                isDisabled={isWaitingFetch || isLoadingExportReport}
                                disabledDays={{
                                    after: new Date()
                                }}
                                maxRange={365}
                            />
                        }

                        <div className="group-by-wrapper">
                            <b>Group By</b>

                            <div className={`checkbox-wrapper`}>
                                <div className={`${groupBy.length === filterGroupBy.length ? "custom-checkbox" : "custom-checkbox-stripe"} select-all-group`}>
                                    <input type="checkbox" id="select-all-group" checked={filterGroupBy.length > 0} onChange={(e) => changeSelectAll(e)} />
                                    <label htmlFor="select-all-group">
                                        Select All
                                    </label>
                                </div>

                                {groupBy.map((v, i) => {
                                    return (
                                        <div className={`custom-checkbox`} key={`select-all-group-${i}`}>
                                            <input type="checkbox" id={`select-all-group-${v.value}`} checked={filterGroupBy.findIndex(_v => _v === v.value) !== -1} onChange={(e) => changeCheck(e, v.value)} />
                                            <label htmlFor={`select-all-group-${v.value}`}>
                                                {v.label}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {errorGroupBy !== "" &&
                            <ErrorOrInfoComp
                                _className={"font-red"}
                                icon={<FiInfo />}
                                text={errorGroupBy}
                            />
                        }
                    </div>

                    <ButtonWithLoadingOrIcon
                        text="Search"
                        className="main-button-40 orange-button"
                        onClickAction={() => {
                            if (filterGroupBy.length === 0) {
                                setErrorGroupBy("This field is required");
                            } else {
                                props.history.replace({
                                    search: "",
                                })
                                setFilterGroupBySubmit(filterGroupBy.slice());
                                getData(false, false, filterGroupBy.slice());
                                setFilterBySubmit(filterBy);
                            }
                        }}
                    />
                </ModalBody>
            </Modal>
            <div className="utm-wrapper sectionWrap">
                {/* tier blocking */}
                {retriveDataError.code === "49901" &&
                    <RetrieveDataLoading
                        isLoading={isWaitingFetch}
                        errorMessage={retriveDataError.message}
                        errorCode={retriveDataError.code}
                        loadingText={"Loading Report"}
                    />
                }
                {/* tier blocking */}

                <React.Fragment>
                    <p className="utm-title">
                        <b>UTM Report</b>
                    </p>

                    {!retriveDataError.code &&
                        <>
                            {isLoadingExportReport ?
                                <ButtonWithLoadingOrIcon
                                    className="orange-button main-button-40 add-utm-button"
                                    text="Download Report"
                                    position="left"
                                    isLoading
                                    loadingColor="gray"
                                    isDisabled
                                />
                                :
                                <ButtonWithLoadingOrIcon
                                    className="orange-button main-button-40 add-utm-button"
                                    onClickAction={exportData}
                                    text="Download Report"
                                    position="left"
                                    icon={{
                                        type: "svg",
                                        src: FiDownload
                                    }}
                                />
                            }
                        </>
                    }

                    <div className="utm-data-outer-wrapper">
                        {!retriveDataError.code &&
                            <div className='custom-filter' onClick={toggleCustomFilter}>
                                <FiFilter />
                                Custom Filter
                            </div>
                        }

                        {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ?
                            <RetrieveDataLoading
                                isLoading={isWaitingFetch}
                                errorMessage={retriveDataError.message}
                                errorCode={retriveDataError.code}
                                loadingText={"Loading Report"}
                            />
                            :
                            <React.Fragment>
                                <div className="utm-data-wrapper">
                                    {printedData.length === 0 ?
                                        <div className="no-utm">
                                            <img src={NoReportFound} alt="No Report Found" />

                                            <p>
                                                <b>No report found.</b>
                                            </p>
                                        </div>
                                        :
                                        <table className='no-hover'>
                                            <thead>
                                                <tr>
                                                    <React.Fragment>
                                                        {TABLE_HEADER.map((val, idx) => {
                                                            return (
                                                                (val.value === "totalCases" ?
                                                                    <React.Fragment key={`table-head-${idx}`}>
                                                                        <AscendingDescendingTableHeader
                                                                            className={val.class}
                                                                            fieldName={val.value}
                                                                            title={val.title}
                                                                            onClick={() => onSort(val.value)}
                                                                            sortByVal={sort}
                                                                        />
                                                                    </React.Fragment>
                                                                    :
                                                                    (isShow(val.value) ?
                                                                        <React.Fragment key={`table-head-${idx}`}>
                                                                            <AscendingDescendingTableHeader
                                                                                className={val.class}
                                                                                fieldName={val.value}
                                                                                title={val.title}
                                                                                onClick={() => onSort(val.value)}
                                                                                sortByVal={sort}
                                                                            />
                                                                        </React.Fragment>
                                                                        :
                                                                        ""
                                                                    )
                                                                )
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {printedData.map((value, key) => {
                                                    return (
                                                        <tr key={`utm-row-${key}`}>
                                                            {isShow("source") &&
                                                                <td className="col_1">
                                                                    {printText(value.source)}
                                                                </td>
                                                            }

                                                            {isShow("medium") &&
                                                                <td userFullName="col_2">
                                                                    {printText(value.medium)}
                                                                </td>
                                                            }

                                                            {isShow("campaign") &&
                                                                <td className="col_3">
                                                                    {printText(value.campaign)}
                                                                </td>
                                                            }

                                                            {isShow("term") &&
                                                                <td userFullName="col_4">
                                                                    {printText(value.term)}
                                                                </td>
                                                            }

                                                            {isShow("content") &&
                                                                <td className="col_5">
                                                                    {printText(value.content)}
                                                                </td>
                                                            }

                                                            <td userFullName="col_6">
                                                                {printText(value.totalCases)}
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>

                                <div className="pagination-wrapper">
                                    {
                                        totalItems > 0 &&
                                        <Pagination
                                            total={totalItems}
                                            page={pageDisplay}
                                            listPerPage={20}
                                            step={1}
                                            onChangePage={handleChangePage}
                                        />
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </React.Fragment>
            </div>
        </>
    );
};

export default UTMTab2Show;
