import React, { useEffect, useState } from "react";
import "./AssignmentRules.scss";
import { doToast, checkID } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import AssignmentRulesServices from "../../../services/newServices/AssignmentRulesServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

const RULES = {
    unassigned: "unassigned",
    round_robin: "round_robin"
}

const AssignmentRules = (props) => {
    let [val, setVal] = useState({
        rule: RULES["unassigned"],
        updatedTime: 0
    });
    let [valOrigin, setValOrigin] = useState({
        rule: RULES["unassigned"],
        updatedTime: 0
    });
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [isWaitingSet, setIsWaitingSet] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [orgID, setOrgID] = React.useState(false);

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Assignment Rules Setting",
                {
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    useStateorganizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if (orgID) {
            getRules();
        }
    }, [orgID])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/general-assignments`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    }

    let getRules = () => {
        let _retriveDataError = { ...retriveDataError };
        setIsWaitingFetch(true);

        AssignmentRulesServices.get(orgID, (response) => {
            setIsWaitingFetch(false);
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setVal(dataResult.data.config);
                setValOrigin(dataResult.data.config);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
        })
    }

    let setSetting = () => {
        setIsWaitingSet(true);

        AssignmentRulesServices.set(orgID, val, (response) => {
            setIsWaitingFetch(false);
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                if (dataResult.data.success) {
                    setValOrigin(val);
                }

                doToast(dataResult.data.message);
            } else {
                doToast(dataResult.error.message, "fail");
            }

            setIsWaitingSet(false);
        })
    }

    // let isDisabledSubmitButton = () => {
    //     return JSON.stringify(val) === JSON.stringify(valOrigin);
    // }

    let changeVal = (e) => {
        let _val = { ...val };
        _val = {
            rule: e.target.value,
            ignoreInboxConnection: _val.ignoreInboxConnection,
            updatedTime: new Date().valueOf()
        }

        setVal(_val);
    }

    let changeValIgnore = (e) => {
        let _val = { ...val };
        _val.ignoreInboxConnection = !_val.ignoreInboxConnection;
        setVal(_val);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={JSON.stringify(valOrigin)}
            dataAfterChange={JSON.stringify(val)}
            noWrap
        >
            <div className="assignment-settings sectionWrap">
                {/* tier blocking */}
                {/* {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                        isLoading={isWaitingFetch}
                        errorMessage={retriveDataError.message}
                        errorCode={retriveDataError.code}
                    />
                } */}
                {/* tier blocking */}

                <React.Fragment>
                    <div className="assignment-settings-header">
                        <b>Assignment Rules</b>

                        {!retriveDataError.code &&
                            <>
                                {!isWaitingSet ?
                                    <ButtonWithLoadingOrIcon
                                        // isDisabled={isDisabledSubmitButton()} 
                                        onClickAction={setSetting}
                                        className="orange-button main-button-40 button-save"
                                        text="Save Changes"
                                    />
                                    :
                                    <ButtonWithLoadingOrIcon
                                        isLoading
                                        loadingColor="gray"
                                        isDisabled
                                        className="orange-button main-button-40 button-save"
                                        text="Save Changes"
                                        position="left"
                                    />
                                }
                            </>
                        }
                    </div>

                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ?
                        <RetrieveDataLoading
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <div className="assignment-settings-form">
                            <div className={`custom-radio ${isWaitingSet ? "custom-radio-disabled" : ""}`}>
                                <input type="radio"
                                    id="rule-admin"
                                    name="rule-radio"
                                    value={RULES.unassigned}
                                    onChange={(e) => changeVal(e)}
                                    checked={val.rule === RULES.unassigned}
                                    disabled={isWaitingSet}
                                />
                                <label htmlFor="rule-admin">
                                    <div className="rule-radio-label">
                                        <b>Unassigned</b>
                                        <span>New cases will not be automatically assigned to agents. Agents will have to assign their own cases.</span>
                                    </div>
                                </label>

                                <div className="change-rule-gap" />

                                <input type="radio"
                                    id="rule-agent"
                                    name="rule-radio"
                                    value={RULES.round_robin}
                                    onChange={(e) => changeVal(e)}
                                    checked={val.rule === RULES.round_robin}
                                    disabled={isWaitingSet}
                                />
                                <label htmlFor="rule-agent">
                                    <div className="rule-radio-label">
                                        <b>Round Robin</b>
                                        <span>New cases will be evenly distributed and assigned to each available agent based on a certain sequence. The case will be unassigned if there is no available agent.</span>
                                    </div>
                                </label>

                                {val.rule === RULES.round_robin &&
                                    <div className="ignore-inbox-connection">
                                        <div className="with-case-wrapper">
                                            <label><b>Ignore Inbox Connection</b></label>
                                            <p>
                                                When this setting is enabled, case will be assign to Agents that are currently online, either inbox is connected or disconnected.
                                            </p>

                                            <label className="custom-switch-wrapper">
                                                <input type="checkbox" onChange={(e) => changeValIgnore(e)} checked={val.ignoreInboxConnection} />
                                                <span className="custom-switch round"></span>
                                            </label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </React.Fragment>
            </div>
        </SectionWrap>
    )
}

export default AssignmentRules;