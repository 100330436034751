import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Labels.scss";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast } from "../../helper/HelperGeneral";
// import iconEditOrange from "../../assets/img/icon-edit.svg";
// import IconRemoveRed from "../../assets/img/icon-remove-red.svg";
import PopupRemove from "../reuseableComponent/popupRemove/PopupRemove";
// import CustomSearchBox from "../reuseableComponent/customSearchBox/CustomSearchBox";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import { FiPlus, FiEdit } from "react-icons/fi";
// import Loading from "../reuseableComponent/loading/Loading";
import CaseLabelService from "../../services/newServices/CaseLabelService";
import SearchBox from "../reuseableComponent/searchBox/SearchBox";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

let Label = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [caseLabelList, setCaseLabelList] = useState([])
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [waitForResponseFetchLabelList, setWaitForResponseFetchLabelList] = useState(false)
  let [showPopupSingleRemove, setShowPopupSingleRemove] = useState(false);
  let [singleRemoveLabel, setSingleRemoveLabel] = useState(null);
  let [querySearchLabel, setQuerySearchLabel] = useState("")
  let [submitQuerySearchLabel, setSubmitQuerySearchLabel] = useState("")
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  // let goToAdd = () => history.push(`/o/${orgID}/organization/labels/add`);

  let doMount = async () => {
    setSingleRemoveLabel(null);
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/label`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if (orgID) {
      fetchLabelList();
    }
  }, [orgID, submitQuerySearchLabel]);

  let fetchLabelList = (query = submitQuerySearchLabel) => {
    setWaitForResponseFetchLabelList(true);

    CaseLabelService.getCaseLabelList(orgID, query, (response) => {
      setWaitForResponseFetchLabelList(false);

      if (response.dataResult.error.message === "") {
        setCaseLabelList(response.dataResult.data.caseLabels)
        setIsAllowAgent(response.dataResult.data.configs.allowCreateInInbox)
        // console.log(response.dataResult.data.configs)
      } else {
        let _retriveDataError = { ...retriveDataError };
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    })
  }

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  let toggleModalRemoveLabel = () => {
    setShowPopupSingleRemove(!showPopupSingleRemove);
  }

  let deleteLabel = () => {
    setWaitForResponseDelete(true);

    let dataLabel = {
      id: singleRemoveLabel.id,
      createdTime: singleRemoveLabel.createdTime
    }

    CaseLabelService.deleteCaseLabel(orgID, dataLabel, (response) => {
      let dataResult = response.dataResult;
      setWaitForResponseDelete(false);
      toggleModalRemoveLabel()

      if (dataResult.status === 200) {
        if (dataResult.data.success) {
          fetchLabelList()
          doToast(dataResult.data.message);
        } else {
          doToast(dataResult.error.message, "fail");
        }
      } else {
        doToast(dataResult.error.message, "fail");
      }
    });
  }

  // let onClickSingleDelete = (item) => {
  //   setSingleRemoveLabel(item);
  //   setShowPopupSingleRemove(true);
  // }

  let onChangeSearch = (val) => {
    setQuerySearchLabel(val)
  }

  let handleOnSubmitSearch = () => {
    setSubmitQuerySearchLabel(querySearchLabel)
  }

  let handleClearSearch = () => {
    setSubmitQuerySearchLabel("");
    // fetchLabelList("")
  }

  let [isWaitResponseSetConfigs, setIsWaitResponseSetConfigs] = useState(false)

  let setConfigs = (isAllow) => {
    setIsWaitResponseSetConfigs(true);

    let dataConfigs = {
      allowCreateInInbox: isAllow
    }

    CaseLabelService.setConfigs(orgID, dataConfigs, (response) => {
      let dataResult = response.dataResult;
      setIsWaitResponseSetConfigs(false);

      if (dataResult.status === 200) {
        if (dataResult.data.success) {
          doToast(dataResult.data.message)
        } else {
          doToast(dataResult.data.message, "fail");
        }
      } else {
        doToast(dataResult.error.message, "fail");
      }
    });
  }

  let handleAllowAgent = (e) => {
    setIsAllowAgent(!isAllowAgent)
    setConfigs(!isAllowAgent)
  }

  let [isAllowAgent, setIsAllowAgent] = useState(true)

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap labels-container">
          {/* tier blocking */}
          {retriveDataError.code  === "49901" &&
            <RetrieveDataLoading 
              isLoading={waitForResponseFetchLabelList}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
          }
          {/* tier blocking */}

          <div className="topContent">
            <div className="title">Case Labels</div>
          </div>
          {
            (waitForResponseFetchLabelList || retriveDataError.code) ?
              <RetrieveDataLoading
                isLoading={waitForResponseFetchLabelList}
                errorMessage={retriveDataError.message}
              />
              :
              <div className="labels-switch-wrapper">
                <p className="label-switch-action-title">
                  <b>Allow Agents to Create Case Labels in Inbox</b>
                </p>

                <p className="label-switch-action-desscription">
                  This enables agents to create case label names in OneTalk Inbox on top of adding the case label names provided below.
                </p>

                <label className="custom-switch-wrapper">
                  <input type="checkbox" onChange={(e) => handleAllowAgent(e)} checked={isAllowAgent} disabled={isWaitResponseSetConfigs} style={{ cursor: isWaitResponseSetConfigs ? 'not-allowed' : 'default' }} />
                  <span className="custom-switch round"></span>
                </label>
              </div>
          }
        </div>

        <div className="sectionWrap labels-container">
          {/* tier blocking */}
          {retriveDataError.code  === "49901" &&
            <RetrieveDataLoading 
              isLoading={waitForResponseFetchLabelList}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
          }
          {/* tier blocking */}

          <div className="topContent header-labels">
            <div className="title title-label-list">Case Label List</div>

            {!retriveDataError.code &&
              <NavLink to={`/o/${orgID}/organization/labels/add`}>
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40"
                  text="Create Label"
                  icon={{
                    type: "svg",
                    src: FiPlus
                  }}
                  position="left"
                />
              </NavLink>
            }
          </div>

          {!retriveDataError.code &&
            <div className="labels-top-action-wrapper">
              <SearchBox
                placeholder={"Search for label name"}
                onChangeInputSearch={onChangeSearch}
                searchValue={querySearchLabel}
                isWaitResponse={waitForResponseFetchLabelList}
                onSubmitSearch={handleOnSubmitSearch}
                onClearSearch={handleClearSearch}
              />
            </div>
          }

          {
            (waitForResponseFetchLabelList || retriveDataError.code) ?
              <RetrieveDataLoading
                isLoading={waitForResponseFetchLabelList}
                errorMessage={retriveDataError.message}
              />
              :
              <>
                <div className="labels-outer-wrapper">
                  <div className="custom-table">
                    <table className="custom-table-content table-labels">
                      <thead>
                        <tr>
                          <td className="col_label">Label Name</td>
                          <td className="col_description">Description</td>
                          <td className="col_action"></td>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          caseLabelList.length > 0 ?
                            caseLabelList.map((label) => {
                              return (
                                <tr key={`case-label-${label.id}`}>
                                  <td className="col_label">
                                    <p className="label-badge-wrapper" style={{ backgroundColor: label.backgroundColor }}><b>{label.name}</b></p>
                                  </td>
                                  <td className="col_description">
                                    {label.description}
                                  </td>
                                  <td className={`col_action`}>
                                    <div className="col-action-button-wrapper">
                                      <Link to={{
                                        pathname: `/o/${orgID}/organization/labels/edit/${label.id}`,
                                        state: {
                                          label: label.name,
                                          description: label.description,
                                          id: label.id
                                        }
                                      }}>
                                        <FiEdit />
                                        <b className="view-agent">Edit</b>
                                      </Link>
                                      {/* <button className="single-remove-button no-background-and-border-button" onClick={() => onClickSingleDelete(label)}>
                                        <FiTrash2 />
                                        <b>Delete</b>
                                      </button> */}
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                            :
                            <tr>
                              <td colSpan="3" className="no-data-column"><b className="no-result-found">No case label names found, start with ‘+ Create Label Name’</b></td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <PopupRemove removeTitle={`Delete Label`}
                    removeDescription={`This label will be deleted permanently and will be removed from all the cases in your inbox. Are you sure you want to delete this label?`}
                    onClickCancel={() => toggleModalRemoveLabel()}
                    onClickRemove={deleteLabel}
                    waitForResponse={waitForResponseDelete}
                    showModalProps={showPopupSingleRemove}
                    submitText="Delete Label"
                    colorButton="red-button"
                  />
                </div>
              </>
          }

        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default Label;
