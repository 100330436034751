import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./ViewPending.scss";
import Blocking401 from "../../../../reuseableComponent/blocking401/Blocking401";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { checkID, rupiahFormat } from "../../../../../helper/HelperGeneral";
import HelperDate from "../../../../../helper/HelperDate";
import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import BillingServices from '../../../../../services/newServices/BillingServices';
import { FiEye } from "react-icons/fi";
import mixpanel from "mixpanel-browser";

const ViewPending = (props) => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [loadingUnpaidList, setLoadingUnpaidList] = useState(true);
    let [unpaidList, setUnpaidList] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    
    const back = () => history.push({
        pathname: `/o/${orgID}/organization/billing`,
        state: {
            whichTab: "subscriptions"
        }
    })

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if(response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              const { id, createdTime } = responseData.memberships[0].organization;
              const urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/billing`);
            }else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getUnpaidList = () => {
        let _retriveDataError = {...retriveDataError};

        setLoadingUnpaidList(true);
        
        BillingServices.getUnpaidList(orgID, (response) => {
            if (response.dataResult.status === 200) {
                if(response.dataResult.data.invoices.length === 0) {
                    history.push(`/o/${orgID}/organization/billing`)
                }else {
                    setUnpaidList(response.dataResult.data.invoices);
                    setLoadingUnpaidList(false);
                }
            } else {
                // setTimeout(() => {
                //     history.push(`/o/${orgID}/organization/billing`)
                // }, 1000);
                            
                _retriveDataError.message = response.dataResult.error.message;
                _retriveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setLoadingUnpaidList(false);
            }
        });
    }

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
        if(orgID) {
            if(process.env.REACT_APP_HIDE_BILLING === "false") {
                getUnpaidList();
    
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                        "[Page] Unpaid Invoice", 
                        {
                            userFullName : myAgentData.account.fullName,
                            userID : myAgentData.account.id,
                            organizationID : orgID.split("-")[0]
                        }
                    );
                }
            }
        }
    }, [orgID])
    
    useEffect(() => {
       doMount();
    }, [location.pathname]);

    return (
        <React.Fragment>
            {process.env.REACT_APP_HIDE_BILLING === "true" ?
                <SectionWrap
                    {...props}
                    orgID={orgID}
                    withHeader
                    withSideMenu
                >
                    <Blocking401 {...props} />
                </SectionWrap>
                :
                <SectionWrap
                    {...props}
                    orgID={orgID}
                    withHeader
                    withSideMenu
                >   
                    <BackButton text="Back to Subscriptions" onClick={back} />

                    <div className="sectionWrap view-pending-payment-wrapper">
                        {/* {loadingUnpaidList ? 
                            <div className="lds-ring-wrapper">
                                <div className="lds-ring">
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                </div>
                            </div> */}
                        {loadingUnpaidList || retriveDataError.code ? 
                            <RetrieveDataLoading 
                            isLoading={loadingUnpaidList}
                            errorMessage={retriveDataError.message} 
                            /> 
                            : 
                            <>
                                <p className="view-pending-payment-title">
                                    <b>Unpaid Invoices</b>
                                </p>

                                <table>
                                    <thead>
                                        <tr>
                                            <td className="col_invoice"><b>Invoice No.</b></td> 
                                            <td className="col_issue"><b>Issue Date</b></td>
                                            <td className="col_due"><b>Due Date</b></td>
                                            <td className="col_amount"><b>Amount Due</b></td>
                                            <td className="col_action"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {unpaidList.map((value, index) => {
                                            return (
                                                <tr key={`unpaid-list-${index}`}>
                                                    <td className="col_invoice">{value.invoiceNo}</td>
                                                    <td className="col_issue">{HelperDate.formatToString(new Date(value.issueTime), "d MMM yyyy")}</td>
                                                    <td className="col_due">{HelperDate.formatToString(new Date(value.dueTime), "d MMM yyyy")}</td>
                                                    <td className="col_amount">{value.currency === "IDR" ? rupiahFormat(value.totalAmount) : value.currency + " " + value.totalAmount}</td>
                                                    <td className="col_action">
                                                        <NavLink to={`/o/${orgID}/organization/billing/unpaid-invoices-detail/${value.id}`}>
                                                            <span className="subscription-label-view">
                                                                <FiEye />
                                                                <b>View</b>
                                                            </span>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </>
                        }
                    </div>
                </SectionWrap>
            }
        </React.Fragment>
    )
}

export default ViewPending;