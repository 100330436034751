import React, { useEffect, useState } from "react";
import "./IntegrationEmailAddAccount.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo, FiEye, FiEyeOff } from "react-icons/fi";
// import Integrations from "../../Integrations";
import HelperInput from "../../../../helper/HelperInput";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { checkID, scrollToClass, doToast } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import IntegrationEmailServices from "../../../../services/integration/IntegrationEmailServices";
import topicServices from "../../../../services/newServices/TopicServices";

const IntegrationEmailAddAccount = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [topicList, setTopicList] = useState([]);
    let [isShowPassword, setIsShowPassword] = useState(false);
    let [loadingSubmit, setLoadingSubmit] = useState(false);
    let [data, setData] = useState({
        topic: {
            value: "",
            label: ""
        },
        provider: {
            value: "",
            label: "",
        },
        outgoing: "",
        incoming: "",
        protocolOut: {
            value: "",
            label: ""
        },
        email: "",
        password: "",
        passwordOrigin: "",
        portOut: "",
        portIn: "",
        protocolIn: "ssl",
        sender: ""
    });
    let [dataOrigin, setDataOrigin] = useState({
        topic: {
            value: "",
            label: ""
        },
        provider: {
            value: "",
            label: "",
        },
        outgoing: "",
        incoming: "",
        protocolOut: {
            value: "",
            label: ""
        },
        email: "",
        password: "",
        passwordOrigin: "",
        portOut: "",
        portIn: "",
        protocolIn: "ssl",
        sender: ""
    });
    let [errorMessageData, setErrorMessageData] = useState({
        topic: "",
        provider: "",
        outgoing: "",
        incoming: "",
        protocolOut: "",
        email: "",
        password: "",
        portOut: "",
        portIn: "",
        protocolIn: "",
        sender: ""
    })

    const EMAIL_PROVIDER = [
        {
            value: "custom",
            label: "Custom",
            "imapServerProtocol": "ssl"
        },
        {
            value: "1",
            label: "Gmail (TLS)",
            "smtpServerHost": "smtp.gmail.com",
            "smtpServerPort": 587,
            "smtpServerProtocol": "starttls",
            "imapServerHost": "imap.gmail.com",
            "imapServerPort": 993,
            "imapServerProtocol": "ssl"
        },
        {
            value: "2",
            label: "Gmail (SSL)",
            "smtpServerHost": "smtp.gmail.com",
            "smtpServerPort": 465,
            "smtpServerProtocol": "ssl",
            "imapServerHost": "imap.gmail.com",
            "imapServerPort": 993,
            "imapServerProtocol": "ssl"
        },
        {
            value: "3",
            label: "Yahoo Mail (TLS)",
            "smtpServerHost": "smtp.mail.yahoo.com",
            "smtpServerPort": 587,
            "smtpServerProtocol": "starttls",
            "imapServerHost": "imap.mail.yahoo.com",
            "imapServerPort": 993,
            "imapServerProtocol": "ssl"
        },
        {
            value: "4",
            label: "Yahoo Mail (SSL)",
            "smtpServerHost": "smtp.mail.yahoo.com",
            "smtpServerPort": 465,
            "smtpServerProtocol": "ssl",
            "imapServerHost": "imap.mail.yahoo.com",
            "imapServerPort": 993,
            "imapServerProtocol": "ssl"
        },
        {
            value: "5",
            label: "Outlook.com/Hotmail (TLS)",
            "smtpServerHost": "smtp-mail.outlook.com",
            "smtpServerPort": 587,
            "smtpServerProtocol": "starttls",
            "imapServerHost": "outlook.office365.com",
            "imapServerPort": 993,
            "imapServerProtocol": "tls",
        }
    ];

    const PROTOCOL = [
        {
            value: "starttls",
            label: "StartTLS",
        },
        {
            value: "ssl",
            label: "SSL",
        },
    ]

    let back = () => {
        if (!loadingSubmit) {
            history.push(`/o/${orgID}/integration/email`);
        }
    }

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        topicServices.getTopic(orgID, "list", null, (response) => {
            const data = response.dataResult.data.topics;
            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.value = val.id;
                    _data.label = val.name;
                    newData.push(_data);
                    return null;
                });
                setTopicList(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if (orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    let onSelectDropdown = (id, value) => {
        let _errorMessageData = { ...errorMessageData };
        let _data = { ...data };
        _errorMessageData[id] = "";
        _data[id] = value;

        if (id === "provider") {
            _data.outgoing = value.smtpServerHost;
            _data.portOut = value.smtpServerPort;

            if (value.value !== "custom") {
                _data.protocolOut = {
                    value: value.smtpServerProtocol,
                    label: value.smtpServerProtocol === "ssl" ? "SSL" : "StartTLS"
                };
            }

            _data.protocolIn = value.imapServerProtocol;
            _data.incoming = value.imapServerHost;
            _data.portIn = value.imapServerPort;
        }

        if (id === "protocolOut") {
            _data.provider = EMAIL_PROVIDER[0];
        }

        setErrorMessageData(_errorMessageData);
        setData(_data);
    }

    let onChangeText = (id, e) => {
        let _errorMessageData = { ...errorMessageData };
        let _data = { ...data };

        _errorMessageData[id] = "";

        if (id === "sender" && e.target.value.length > 50) {
            _errorMessageData[id] = "Characters exceed limit";
        }

        if (id !== "sender" && id !== "email" && id !== "password") {
            _data.provider = EMAIL_PROVIDER[0];
        }

        _data[id] = e.target.value;
        setErrorMessageData(_errorMessageData);
        setData(_data);
    }

    let onSubmit = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = { ...errorMessageData };

        if (data.topic.value === "") {
            _errorMessageData.topic = "Please select topic";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-topic" }
        }

        if (data.provider.value === "") {
            _errorMessageData.provider = "Please select email provider";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-provider" }
        }

        if (data.provider.value !== "") {

            if (!HelperInput.validURLNoProtocol(data.outgoing)) {
                _errorMessageData.outgoing = "Invalid server address";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-outgoing" }
            }

            if (data.outgoing === "") {
                _errorMessageData.outgoing = "This field is required";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-outgoing" }
            }

            if (!HelperInput.numberOnly(data.portOut)) {
                _errorMessageData.portOut = "Invalid Port, numeric value only";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-portOut" }
            }

            if (data.portOut === "") {
                _errorMessageData.portOut = "This field is required";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-portOut" }
            }

            if (data.protocolOut.value === "") {
                _errorMessageData.protocolOut = "Please select email provider";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-protocolOut" }
            }

            if (!HelperInput.validURLNoProtocol(data.incoming)) {
                _errorMessageData.incoming = "Invalid server address";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-incoming" }
            }

            if (data.incoming === "") {
                _errorMessageData.incoming = "This field is required";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-incoming" }
            }

            if (!HelperInput.numberOnly(data.portIn)) {
                _errorMessageData.portIn = "Invalid Port, numeric value only";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-portIn" }
            }

            if (data.portIn === "") {
                _errorMessageData.portIn = "This field is required";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-portIn" }
            }

            if (data.protocolIn === "") {
                _errorMessageData.protocolIn = "This field is required";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-protocolIn" }
            }

            if (data.email === "") {
                _errorMessageData.email = "This field is required";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-email" }
            } else {
                if (!HelperInput.emailFormatValid(data.email)) {
                    _errorMessageData.email = "Invalid email address format";
                    errorCount = errorCount + 1;

                    if (errorClass === "") { errorClass = "input-email" }
                }
            }

            if (data.password === "") {
                _errorMessageData.password = "This field is required";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-password" }
            }

            // if (data.sender === "") {
            //     _errorMessageData.sender = "This field is required";
            //     errorCount = errorCount + 1;

            //     if (errorClass === "") { errorClass = "input-sender" }
            // }
        }

        if (errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        } else {
            setLoadingSubmit(true);

            let _data = {
                topicID: data.topic.value,
                smtpServerHost: data.outgoing,
                smtpServerPort: parseInt(data.portOut),
                smtpServerProtocol: data.protocolOut.value,
                imapServerHost: data.incoming,
                imapServerPort: parseInt(data.portIn),
                imapServerProtocol: data.protocolIn,
                emailAddress: data.email,
                password: data.password,
                senderName: data.sender
            }

            IntegrationEmailServices.create(props.match.params.orgID, _data, (res) => {
                let dataRes = res.dataResult;

                if (dataRes.error.code !== "") {
                    doToast(dataRes.error.message, "fail");
                    setLoadingSubmit(false);
                } else {
                    if (dataRes.data.success) {
                        doToast(dataRes.data.message);
                        setDataOrigin(data);

                        setTimeout(() => {
                            back();
                        }, 3000)
                    } else {
                        doToast(dataRes.data.message, "fail");
                        setLoadingSubmit(false);
                    }
                }
            })
        }
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={JSON.stringify(dataOrigin)}
                dataAfterChange={JSON.stringify(data)}
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

                <div className="sectionWrap integration-main-container integration-email-add-account text-left">
                    <BackButton text="Back to Email" onClick={back} />

                    <div className="integration-email-add-account-box">
                        <div className="integration-email-add-account-box-top">
                            <b>Add Channel</b>

                            {loadingSubmit ?
                                <ButtonWithLoadingOrIcon
                                    // isDisabled={!isFormValid()} 
                                    isDisabled
                                    isLoading
                                    loadingColor="gray"
                                    className="orange-button main-button-40"
                                    text="Create Channel"
                                    position="left"
                                />
                                :
                                <ButtonWithLoadingOrIcon
                                    // isDisabled={!isFormValid()} 
                                    onClickAction={onSubmit}
                                    className="orange-button main-button-40"
                                    text="Create Channel"
                                    position="left"
                                />
                            }
                        </div>

                        <div className="integration-email-add-account-box-form">
                            <div className="integration-email-add-account-box-form-inner">
                                <label><b>Topic</b></label>
                                <CustomSelectOption
                                    optionListProps={topicList}
                                    valueDropdownProps={data.topic}
                                    placeholderProps={"Select topic"}
                                    onClickDropDownListOptionProps={(e) => onSelectDropdown("topic", e)}
                                    specificClassNameProps="custom-select-email-topic"
                                    _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                                    isDisabled={loadingSubmit}
                                />
                                <ErrorOrInfoComp
                                    text={errorMessageData.topic === "" ? "Incoming messages on this channel will be assigned to this topic." : errorMessageData.topic}
                                    _className={errorMessageData.topic !== "" ? "font-red " : ""}
                                    icon={<FiInfo />}
                                />

                                <label><b>Email Provider</b></label>
                                <CustomSelectOption
                                    optionListProps={EMAIL_PROVIDER}
                                    valueDropdownProps={data.provider}
                                    placeholderProps={"Please select"}
                                    onClickDropDownListOptionProps={(e) => onSelectDropdown("provider", e)}
                                    specificClassNameProps="custom-select-email-provider"
                                    _className={`input-provider ${errorMessageData.provider !== "" ? "border-red" : ""}`}
                                    isDisabled={loadingSubmit}
                                />
                                {errorMessageData.provider !== "" &&
                                    <ErrorOrInfoComp
                                        text={errorMessageData.provider}
                                        _className={errorMessageData.provider !== "" ? "font-red " : ""}
                                        icon={<FiInfo />}
                                    />
                                }
                            </div>

                            {data.provider.value !== "" &&
                                <>
                                    <div className="line-border" />
                                    <div className="integration-email-add-account-box-form-inner">
                                        <p className="mail-server-config">
                                            <b>Mail Server Config</b>
                                        </p>

                                        <label>
                                            <b>Outgoing Server Address (SMTP)</b>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="e.g. smtp.gmail.com"
                                            onChange={(e) => { onChangeText("outgoing", e) }}
                                            value={data.outgoing}
                                            className={`input-outgoing ${errorMessageData.outgoing !== "" ? "border-red" : ""}`}
                                            disabled={loadingSubmit}
                                        />
                                        <ErrorOrInfoComp
                                            text={errorMessageData.outgoing === "" ? "To send messages using the registered email" : errorMessageData.outgoing}
                                            _className={errorMessageData.outgoing !== "" ? "font-red" : ""}
                                            icon={<FiInfo />}
                                        />

                                        <label>
                                            <b>Port</b>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="123"
                                            onChange={(e) => { onChangeText("portOut", e) }}
                                            value={data.portOut}
                                            className={`input-port-out ${errorMessageData.portOut !== "" ? "border-red" : ""}`}
                                            disabled={loadingSubmit}
                                        />
                                        {errorMessageData.portOut !== "" &&
                                            <ErrorOrInfoComp
                                                text={errorMessageData.portOut === "" ? "" : errorMessageData.portOut}
                                                _className={errorMessageData.portOut !== "" ? "font-red" : ""}
                                                icon={<FiInfo />}
                                            />
                                        }

                                        <label><b>Protocol</b></label>
                                        <CustomSelectOption
                                            optionListProps={PROTOCOL}
                                            valueDropdownProps={data.protocolOut}
                                            placeholderProps={"Select protocol"}
                                            onClickDropDownListOptionProps={(e) => onSelectDropdown("protocolOut", e)}
                                            specificClassNameProps="custom-select-email-provider"
                                            _className={`input-protocol-out ${errorMessageData.protocolOut !== "" ? "border-red" : ""}`}
                                            isDisabled={loadingSubmit}
                                        />
                                        {errorMessageData.protocolOut !== "" &&
                                            <ErrorOrInfoComp
                                                text={errorMessageData.protocolOut}
                                                _className={errorMessageData.protocolOut !== "" ? "font-red " : ""}
                                                icon={<FiInfo />}
                                            />
                                        }

                                        <label>
                                            <b>Incoming Server Address (IMAP)</b>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="e.g. imap.gmail.com"
                                            onChange={(e) => { onChangeText("incoming", e) }}
                                            value={data.incoming}
                                            className={`input-incoming ${errorMessageData.incoming !== "" ? "border-red" : ""}`}
                                            disabled={loadingSubmit}
                                        />
                                        <ErrorOrInfoComp
                                            text={errorMessageData.incoming === "" ? "To access the contents of registered email" : errorMessageData.incoming}
                                            _className={errorMessageData.incoming !== "" ? "font-red" : ""}
                                            icon={<FiInfo />}
                                        />

                                        <label>
                                            <b>Port</b>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="123"
                                            onChange={(e) => { onChangeText("portIn", e) }}
                                            value={data.portIn}
                                            className={`input-port-in ${errorMessageData.portIn !== "" ? "border-red" : ""}`}
                                            disabled={loadingSubmit}
                                        />
                                        {errorMessageData.portIn !== "" &&
                                            <ErrorOrInfoComp
                                                text={errorMessageData.portIn === "" ? "" : errorMessageData.portIn}
                                                _className={errorMessageData.portIn !== "" ? "font-red" : ""}
                                                icon={<FiInfo />}
                                            />
                                        }

                                        <label><b>Protocol</b></label>
                                        <input
                                            type="text"
                                            placeholder="123"
                                            disabled
                                            onChange={(e) => { onChangeText("protocolIn", e) }}
                                            value={data.protocolIn}
                                            className={`input-protocol-in ${errorMessageData.protocolIn !== "" ? "border-red text-uppercase" : "text-uppercase"}`}
                                        />

                                        {errorMessageData.protocolIn !== "" &&
                                            <ErrorOrInfoComp
                                                text={errorMessageData.protocolIn}
                                                _className={errorMessageData.protocolIn !== "" ? "font-red " : ""}
                                                icon={<FiInfo />}
                                            />
                                        }
                                    </div>

                                    <div className="line-border" />

                                    <div className="integration-email-add-account-box-form-inner">
                                        <label>
                                            <b>Email</b>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="name@email.com"
                                            onChange={(e) => { onChangeText("email", e) }}
                                            value={data.email}
                                            className={`input-email ${errorMessageData.email !== "" ? "border-red" : ""}`}
                                            disabled={loadingSubmit}
                                            autocomplete="off"
                                        />
                                        {errorMessageData.email !== "" &&
                                            <ErrorOrInfoComp
                                                text={errorMessageData.email}
                                                _className={errorMessageData.email !== "" ? "font-red" : ""}
                                                icon={<FiInfo />}
                                            />
                                        }

                                        <label>
                                            <b>Password</b>
                                        </label>
                                        <div className="password-wrapper">
                                            <input
                                                type={!isShowPassword ? "password" : "text"}
                                                placeholder="**********"
                                                onChange={(e) => { onChangeText("password", e) }}
                                                value={data.password}
                                                className={`input-password ${errorMessageData.password !== "" ? "border-red" : ""}`}
                                                disabled={loadingSubmit}
                                                autocomplete="off"
                                            />
                                            {isShowPassword ?
                                                <FiEye onClick={() => setIsShowPassword(false)} />
                                                :
                                                <FiEyeOff onClick={() => setIsShowPassword(true)} />
                                            }
                                        </div>
                                        {errorMessageData.password !== "" &&
                                            <ErrorOrInfoComp
                                                text={errorMessageData.password}
                                                _className={errorMessageData.password !== "" ? "font-red" : ""}
                                                icon={<FiInfo />}
                                            />
                                        }

                                        <label>
                                            <span className={errorMessageData.sender === "Characters exceed limit" ? "red-text" : "grey-font"}>({data.sender.length}/50 character)</span>
                                            <b>Sender Name</b>
                                            <span className="grey-font optional-text"> (Optional)</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="e.g. TapTalk.io Support"
                                            onChange={(e) => { onChangeText("sender", e) }}
                                            value={data.sender}
                                            className={`input-sender ${errorMessageData.sender !== "" ? "border-red" : ""}`}
                                            disabled={loadingSubmit}
                                        />
                                        {errorMessageData.sender !== "" &&
                                            <ErrorOrInfoComp
                                                text={errorMessageData.sender}
                                                _className={errorMessageData.sender !== "" ? "font-red" : ""}
                                                icon={<FiInfo />}
                                            />
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationEmailAddAccount;