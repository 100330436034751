import React, { useState } from "react";
import "./IntegrationSalestalkCustomConfigEdit.scss";
import { FiInfo } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import {checkID, doToast, scrollToClass} from "../../../../helper/HelperGeneral";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceSalesTalkDefault from "../../../../services/salesTalk/default/SalesTalkDefault";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const IntegrationSalestalkCustomConfigEdit = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = React.useState(false);
  let [customProviderData, setCustomProviderData] = React.useState("");
  let [customProviderDataOrigin, setCustomProviderDataOrigin] = React.useState("");
  let [isWaitResponseSet, setIsWaitResponseSet] = React.useState(false);
  let [errorMessageData, setErrorMessageData] = useState({
    url: ""
  }) 


  const back = (orgID) => {
    history.push(`/o/${orgID}/integration/salestalk`);
  };

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // const { id, createdTime } = responseData.memberships[0].organization;
          // const urlOrgID = `${id}-${createdTime}`;
          back(paramID);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  React.useEffect(() => {
    doMount();
  }, [location.pathname]);

  React.useEffect(() => {
    if(location.state) {
      if(location.state.data) {
        setCustomProviderData(location.state.data.custom.apiURL);
        setCustomProviderDataOrigin(location.state.data.custom.apiURL);
      }
    }else {
      back(props.match.params.orgID);
    }
  }, [location.pathname]);

  const onChangeCustomProvider = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.url = "";
    setErrorMessageData(_errorMessageData);
    setCustomProviderData(e.target.value);
  };

  const onSubmitSet = (e) => {
    e.preventDefault();

    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(customProviderData === "") {
        _errorMessageData.url = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-url" }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      setIsWaitResponseSet(true);

      ServiceSalesTalkDefault.postEditCustomProvider(orgID, {id: props.location.state.data.salesTalk.id, apiURL: customProviderData}, (response) => {
        let dataResult = response.dataResult;
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);


        if(dataResult.error.message === "") {
          if(dataResult.data.success) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
              mixpanel.track(
                "[Action] Edit SalesTalk Custom Provider",
                {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
                }
              )
            }
            
            doToast(dataResult.data.message);
            setCustomProviderDataOrigin(customProviderData);

            setTimeout(() => {
            back(orgID);
            }, 3000)
          }else {
            doToast(dataResult.error.message, "fail"); 
            setIsWaitResponseSet(false); 
          }
        }else {
          doToast(dataResult.error.message, "fail");
          setIsWaitResponseSet(false);
        }
      });
    }
  }
  
  // const isButtonSubmitDisabled = () => {
  //   return customProviderData.length === 0 || !HelperInput.validURL(customProviderData) || (customProviderData === customProviderDataOrigin);
  // }

  return (
    <React.Fragment>
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={customProviderDataOrigin}
            dataAfterChange={customProviderData}
        >   
            <BackButton text="Back to SalesTalk" onClick={() => back(orgID)} />

            <div className="sectionWrap form-custom-provider-edit">
                <form onSubmit={(e) => {
                      onSubmitSet(e);
                }}>
                    <div className="form-title-wrapper">
                        <b>Edit Custom Provider Configs</b>

                        {!isWaitResponseSet ?
                            <ButtonWithLoadingOrIcon 
                              className="orange-button main-button-40"
                              text="Save Changes"
                              // isDisabled={isButtonSubmitDisabled()}
                            />
                            :
                            <ButtonWithLoadingOrIcon 
                              isLoading
                              loadingColor="gray"
                              isDisabled
                              className="orange-button main-button-40"
                              text="Save Changes"
                              position="left"
                            />
                        }
                    </div>
                
                    <div className="form-content-wrapper">
                        <label><b>API URL</b></label>
                        <input 
                            type="text" 
                            placeholder="Insert store URL" 
                            value={customProviderData} 
                            onChange={(e) => {
                                onChangeCustomProvider(e);
                            }}
                            className={`input-url ${errorMessageData.url !== "" ? "border-red" : ""}`}
                            disabled={isWaitResponseSet}
                        />

                        {errorMessageData.url !== "" &&
                            <ErrorOrInfoComp
                                text={errorMessageData.url}
                                _className={"font-red margin-top-0"}
                                icon={<FiInfo />}
                            />
                        }
                    </div>
                </form>
            </div>
        </SectionWrap>
    </React.Fragment>
  );
};

export default IntegrationSalestalkCustomConfigEdit;