import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationTaptalkServices = {
  editTopic(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_TOPIC, data, headers, callback);
  },
  getConfig(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id),
    };

    BaseService.doPostAccessToken(API.GET_CONFIG, data, headers, callback);
  },
  saveConfig(idOrTime, id, color, scfPathID, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id),
      hexColor: color,
      scfPathID: scfPathID
    };

    BaseService.doPostAccessToken(API.SAVE_CONFIG, data, headers, callback);
  },
  getWelcomeMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_WELCOME, data, headers, callback);
  },
  getAwayMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_AWAY, data, headers, callback);
  },
  getClosingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_CLOSING, data, headers, callback);
  },
  getRatingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_USER_RATING, data, headers, callback);
  },
  setWelcomeMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);
  },
  setAwayMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);
  },
  setClosingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);
  },
  setRatingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_USER_RATING, data, headers, callback);
  },
  getChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHAT_BOT, data, headers, callback);
  },
  setChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHAT_BOT, data, headers, callback);
  },
  removeChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REMOVE_CHAT_BOT, data, headers, callback);
  },
};

const API = {
  EDIT_TOPIC: '/organization/channel/launcher/edit_topic',
  GET_CONFIG: '/organization/channel/launcher/get_configs',
  SAVE_CONFIG: '/organization/channel/launcher/set_configs',
  GET_WELCOME: "/organization/channel/launcher/get_welcome_message",
  SET_WELCOME: "/organization/channel/launcher/set_welcome_message",
  GET_AWAY: "/organization/channel/launcher/get_away_message",
  SET_AWAY: "/organization/channel/launcher/set_away_message",
  GET_CLOSING: "/organization/channel/launcher/get_closing_message",
  SET_CLOSING: "/organization/channel/launcher/set_closing_message",
  GET_USER_RATING: "/organization/channel/launcher/get_user_rating",
  SET_USER_RATING: "/organization/channel/launcher/set_user_rating",
  GET_CHAT_BOT: "/organization/channel/launcher/get_chatbot",
  SET_CHAT_BOT: "/organization/channel/launcher/set_chatbot",
  REMOVE_CHAT_BOT: "/organization/channel/launcher/remove_chatbot"
};

export default IntegrationTaptalkServices;
