const INITIAL_STATE = {
    allowHandoverCase: false,
    allowReplyOnAway: false,
    showContactPhone: false,
    showTabClosed: false,
    showTabOthers: false,
};

let reduxReducerInboxConfig = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_INBOX_CONFIGS':
            return action.inboxConfigsRedux;
        case 'CLEAR_INBOX_CONFIGS':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default reduxReducerInboxConfig;