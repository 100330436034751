import React, { useState, useEffect } from "react";
import "../../../auth/Auth.css";
import "./IntegrationApiKeysAdd.scss";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { FiInfo } from "react-icons/fi";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperDate from "../../../../helper/HelperDate";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceApiKey from "../../../../services/newServices/ApiKeysServices";
// import HelperInput from "../../../helper/HelperInput";
import mixpanel from "mixpanel-browser";

const IngrationApiKeysAdd = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [addApiKeyData, setAddApiKeyData] = useState({
      description: "",
      validityMonths: "" 
  });
  let [addApiKeyDataOrigin, setAddApiKeyDataOrigin] = useState({
      description: "",
      validityMonths: "" 
  });
  let [isWaitResponseAddApiKey, setIsWaitResponseAddApiKey] = useState(false);
  let [errorMessageData, setErrorMessageData] = useState({
    validityMonths: "",
    description: ""
  })

  const back = () => history.push(`/o/${orgID}/integration/api-keys`);

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/api-key/general`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  const onChangeAddApiKeyInput = (e) => {
    let _addApiKeyData = {...addApiKeyData};
    let regxNumber = /^[0-9]*$/;
    let _errorMessageData = {...errorMessageData};
    
    if(e.target.id === "validityMonths") {
      let setVal = true;

      if(e.target.value[0] === "0") {
        setVal = false;
      }

      if(!regxNumber.test(e.target.value)) {
        setVal = false;
      }

      if(setVal) {
        _errorMessageData[e.target.id] = "";
        _addApiKeyData[e.target.id] = e.target.value;
      }      
    }else {
      if(e.target.id === "description") {
        _errorMessageData[e.target.id] = "";
        _addApiKeyData[e.target.id] = e.target.value;
      }
    }

    setErrorMessageData(_errorMessageData);
    setAddApiKeyData(_addApiKeyData);
  };

  const onSubmitCreateApiKey = (e) => {
    e.preventDefault();
    let _addApiKeyData = {...addApiKeyData};
    let _errorMessageData = {...errorMessageData};
    let errorCount = 0;
    let errorClass = "";

    if(_addApiKeyData.description === "") {
      _errorMessageData.description = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-description" }
    }

    if(_addApiKeyData.description.length > 250) {
      _errorMessageData.description = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    // if(_addApiKeyData.validityMonths === "") {
    //   _errorMessageData.validityMonths = "This field is required";
    //   errorCount = errorCount + 1;
    //   if(errorClass === "") { errorClass = "input-month" }
    // }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      _addApiKeyData.validityMonths = _addApiKeyData.validityMonths === "" ? 0 : parseInt(_addApiKeyData.validityMonths);

      setIsWaitResponseAddApiKey(true);
      
      ServiceApiKey.addApiKey(orgID, _addApiKeyData, (response) => {
        let dataResult = response.dataResult;
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        setIsWaitResponseAddApiKey(false);

        if(dataResult.error.message === "") {
          if(dataResult.data.success) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
              mixpanel.track(
                "[Action] Create API Key",
                {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
                }
              )
            }
            setAddApiKeyDataOrigin(addApiKeyData);
            setTimeout(() => {
              history.push({
                pathname: `/o/${orgID}/integration/api-keys`,
                state: {
                  message: response.dataResult.data.message,
                  success: response.dataResult.data.success
                }
              })
            }, 0)
          }else {
            doToast(response.dataResult.data.message, "fail");  
          }
        }else {
          doToast(dataResult.error.message, "fail");
        }
      });
    }
  }
  
  // const isButtonSubmitDisabled = () => {
  //   let _addApiKeyData = {...addApiKeyData};
    
  //   return (
  //     _addApiKeyData.description === ""
  //   )
  // }

  let generateExpiriy = () => {
    let _addApiKeyData = {...addApiKeyData};
    let _validityMonths = _addApiKeyData.validityMonths;
    let _today = new Date();
    let _date = _today.getDate();
    let _month = _today.getMonth();
    let _year = _today.getFullYear();

    for(let i = 0; i <= _validityMonths; i++) {
      _month = _month + 1;

      if(_month > 12) {
        _month = 1;
        _year = _year + 1;
      }
    }

    if(_date > HelperDate.getLastDayOfCurrentTimestamp(_year, _month)) {
      _date = HelperDate.getLastDayOfCurrentTimestamp(_year, _month);
    }
    
    return HelperDate.formatToString(new Date(_year, _month - 1, _date), "dd MMM yyyy");
  }

  return (
    <React.Fragment>
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={addApiKeyDataOrigin}
            dataAfterChange={addApiKeyData}
        >   
            <BackButton text="Back to API Keys" onClick={back} />

            <div className="sectionWrap form-add-api-key">
                <form onSubmit={(e) => {
                      onSubmitCreateApiKey(e);
                }}>
                    <div className="form-title-wrapper">
                        <b>Create API Key</b>
                    </div>
                
                    <div className="form-content-wrapper">
                        <label><b>Description</b></label>

                        <div className="description-wrapper">
                          <span className={errorMessageData.description === "Characters exceed limit" ? "red-text" : ""}>{addApiKeyData.description.length}/250</span>
                          <textarea className={errorMessageData.description !== "" ? "border-red" : ""} disabled={isWaitResponseAddApiKey} rows="6" type="text" value={addApiKeyData.description} placeholder="Add description here" id="description" onChange={(e) => {
                              onChangeAddApiKeyInput(e);
                          }}/>
                        </div>
                        {errorMessageData.description !== "" &&
                          <ErrorOrInfoComp
                            text={errorMessageData.description}
                            _className={"font-red"}
                            icon={<FiInfo />}
                          />
                        }

                        <label><b>Valid Duration</b> <span className="input-label-optional">(optional)</span></label>
                        
                        <input className={errorMessageData.validityMonths !== "" ? "border-red" : ""} disabled={isWaitResponseAddApiKey} type="text" autoComplete={"off"} value={addApiKeyData.validityMonths} placeholder="e.g 12" id="validityMonths" maxLength="7" onChange={(e) => {
                            onChangeAddApiKeyInput(e);
                        }}/>

                        <ErrorOrInfoComp
                          text={errorMessageData.validityMonths === "" ? "Valid duration in months." : errorMessageData.validityMonths}
                          _className={errorMessageData.validityMonths === "" ? `` : `font-red`}
                          icon={<FiInfo />}
                        />

                        <label><b>Expiry Date</b></label>
                        <p className="input-label-optional no-margin">
                          {addApiKeyData.validityMonths === "" ? 
                            "No Expiry" 
                            : 
                            generateExpiriy()
                          }
                        </p>

                        {!isWaitResponseAddApiKey &&
                            <ButtonWithLoadingOrIcon 
                              className="orange-button main-button-40"
                              text="Create API Key"
                              // isDisabled={isButtonSubmitDisabled()}
                            />
                        }
                    </div>
                </form>

                {isWaitResponseAddApiKey &&
                  <ButtonWithLoadingOrIcon 
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    className="orange-button main-button-40"
                    text="Create API Key"
                    position="left"
                    style={{width: "300px"}}
                  />
                }
            </div>
        </SectionWrap>
    </React.Fragment>
  );
};

export default IngrationApiKeysAdd;