import React from 'react';
import './ChatRoomMessageLinkPreview.scss';
import { connect } from 'react-redux';

var ChatRoomMessageLinkPreview = (props) => {
    let { 
        isOut,
        message,
    } = props;

    let hasLinkPreview = true;
    let messageData = message.data;
    let linkPreviewTitle = messageData.title;
    let linkPreviewDescription = messageData.description;
    let linkPreviewImage = messageData.image;
    if (!linkPreviewTitle && !linkPreviewDescription && !linkPreviewImage) {
        hasLinkPreview = false;
    }
    
    return (
        hasLinkPreview &&
        <div className="link-preview-container">
            <div className={isOut ? "link-preview-side-decoration-out" : "link-preview-side-decoration-in"}></div>
            {linkPreviewTitle && 
                <p className={isOut ? "link-preview-title-out": "link-preview-title-in"}><b>{linkPreviewTitle}</b></p>
            }
            {linkPreviewDescription &&
                <p className={isOut ? "link-preview-description-out" : "link-preview-description-in"}>{linkPreviewDescription}</p>
            }
            {linkPreviewImage &&
                <div>
                    <img className="link-preview-image" src={linkPreviewImage} alt="" />
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageLinkPreview);
