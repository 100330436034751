import React from "react";
// import "react-tippy/dist/tippy.css";
// import { Tooltip as Tippy } from "react-tippy";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";

const Tooltip = props => {
  const { position, placement } = props;
  const place = position || placement || "right";
  return <Tippy arrow={true} placement={place} {...props} />;
};

export default Tooltip;
