import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const CaseReportService = {
  getCaseReport(idOrTime, month, year, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      month,
      year: Number(year),
      format: 'csv'
    };

    BaseService.doPostAccessTokenNoWebWorker(API.GET_CASE_REPORT, data, headers, callback, 'blob');
  },
};

const API = {
  GET_CASE_REPORT: "/organization/case/export_list_by_created_month",
}

export default CaseReportService;
