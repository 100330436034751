import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const FaqService = {
    getList(idOrTime, callback) {
        let headers = {
          "API-Key": API_KEY(),
          "Device-Platform": "web",
          "Organization-ID": idOrTime
        };
    
        BaseService.doPostAccessToken(API.GET_LIST, {}, headers, callback);  
    },

    edit(idOrTime, data, callback) {
        let headers = {
          "API-Key": API_KEY(),
          "Device-Platform": "web",
          "Organization-ID": idOrTime
        };
    
        BaseService.doPostAccessToken(API.EDIT, data, headers, callback);  
    },

    add(idOrTime, data, callback) {
        let headers = {
          "API-Key": API_KEY(),
          "Device-Platform": "web",
          "Organization-ID": idOrTime
        };
    
        BaseService.doPostAccessToken(API.ADD, data, headers, callback);  
    },

    addChild(idOrTime, data, callback) {
        let headers = {
          "API-Key": API_KEY(),
          "Device-Platform": "web",
          "Organization-ID": idOrTime
        };
    
        BaseService.doPostAccessToken(API.ADD_CHILD, data, headers, callback);  
    },

    delete(idOrTime, data, callback) {
        let headers = {
          "API-Key": API_KEY(),
          "Device-Platform": "web",
          "Organization-ID": idOrTime
        };
    
        BaseService.doPostAccessToken(API.DELETE, data, headers, callback);  
    },

    get_detail(idOrTime, data, callback) {
        let headers = {
          "API-Key": API_KEY(),
          "Device-Platform": "web",
          "Organization-ID": idOrTime
        };
    
        BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);  
    },

    validate(idOrTime, data, callback) {
      let headers = {
        "API-Key": API_KEY(),
        "Device-Platform": "web",
        "Organization-ID": idOrTime
      };
  
      BaseService.doPostAccessToken(API.VALIDATE, data, headers, callback);  
    }
};

const API = {
  GET_LIST: "/organization/scf/path/get_list",
  EDIT: "/organization/scf/path_item/edit",
  DELETE: "/organization/scf/path_item/delete",
  ADD: "/organization/scf/path/create",
  GET_DETAIL: "/organization/scf/path_item/get_details",
  ADD_CHILD: "/organization/scf/path_item/create_child",
  VALIDATE: "/organization/scf/path/check_qna_api"
}

export default FaqService;
