import BaseService from "../BaseService";

const ServiceResetPassword = {
  requestToken(email) {
    const data = {
      "email": email
    }
    return (
      BaseService.doPost(API.REQUEST_TOKEN, data)
        .then(response => {
          return {
            dataResult: response.data,
          }
        })
        .catch(error => {
          return BaseService.generateErrorMessage(error);
        })
    )
  },
  verifyToken(dataToken) {
    const data = {
      "email": dataToken.email,
      "otpID": dataToken.otpID,
      "otpKey": dataToken.otpKey,
      "otpCode": dataToken.otpCode
    }
    return (
      BaseService.doPost(API.VERIFY_TOKEN, data)
        .then(response => {
          return {
            dataResult: response.data,
          }
        })
        .catch(error => {
          return BaseService.generateErrorMessage(error);
        })
    )
  },
  setPassword(password, dataToken) {
    const data = {
      "email": dataToken.email,
      "otpID": dataToken.otpID,
      "otpKey": dataToken.otpKey,
      "otpCode": dataToken.otpCode,
      "password": password
    }

    return (
      BaseService.doPost(API.SET_PASSWORD, data)
        .then(response => {
          return {
            dataResult: response.data,
          }
        })
        .catch(error => {
          return BaseService.generateErrorMessage(error);
        })
    )
  },
}

const API = {
  REQUEST_TOKEN: "/client/reset_password/request_token",
  VERIFY_TOKEN: "/client/reset_password/verify_token",
  SET_PASSWORD: "/client/reset_password/set_password"
}

export default ServiceResetPassword