import React, { useEffect, useState } from 'react';
import './OfficeHour.scss';
import OfficeHourServices from '../../../services/newServices/OfficeHourService';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
// import IconRemoveRed from "../../../assets/img/";
import { doToast, checkID } from '../../../helper/HelperGeneral';
import { FiPlus, FiEdit, FiTrash2 } from 'react-icons/fi';
import PopupRemove from '../../reuseableComponent/popupRemove/PopupRemove';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";
import { NavLink } from 'react-router-dom';

const DAY_OF_WEEK = {
  10: {
    value: 10,
    label: 'Sunday',
  },
  11: {
    value: 11,
    label: 'Monday',
  },
  12: {
    value: 12,
    label: 'Tuesday',
  },
  13: {
    value: 13,
    label: 'Wednesday',
  },
  14: {
    value: 14,
    label: 'Thursday',
  },
  15: {
    value: 15,
    label: 'Friday',
  },
  16: {
    value: 16,
    label: 'Saturday',
  },
  90: {
    value: 90,
    label: 'Everyday',
  },
  91: {
    value: 91,
    label: 'Weekdays (Monday until Friday)',
  },
  92: {
    value: 92,
    label: 'Weekends (Saturday and Sunday)',
  },
};

const OfficeHour = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchOfficeHour, setIsWaitingFetchOfficeHour] = useState(true);
  let [printedOfficeHourData, setPrintedOfficeHourData] = useState([]);
  let [isShowModalRemoveOFficeHour, setIsShowModalRemoveOFficeHour] = useState(false);
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [singleRemoveOfficeHour, setSingleRemoveOfficeHour] = useState({
    id: '',
    dayOfWeek: '',
    createdTime: '',
  });
  let [retriveDataError, setRetrieveDataError] = React.useState({
      code: false,
      message: false
  });

  useEffect(() => {
    if(orgID) {
        getOfficeHour();
    }
  }, [orgID])

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Office Hours", 
        {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  const doMount = async () => {
    const paramID = checkID(props.match);

    if (paramID) {
        setOrgID(paramID);
    } else {
        ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
                let responseData = response.dataResult.data;
                if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                const { id, createdTime } = responseData.memberships[0].organization;
                const urlOrgID = `${id}-${createdTime}`;
                props.history.push(`/o/${urlOrgID}/setup/office-hour`);
            } else {
                console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
        });
    }
  };

  // let goToAdd = () => {
  //   let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
  //   if(retriveDataError.code !== "49901") {
  //     if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
  //       mixpanel.track(
  //         "[Button] Add Office Hours", 
  //         {
  //             userFullName : myAgentData.account.fullName,
  //             userID : myAgentData.account.id,
  //             organizationID : props.match.params.orgID.split("-")[0]
  //         }
  //       );
  //     }

  //     props.history.push(
  //       `/o/${props.match.params.orgID}/setup/office-hour/add`
  //     );
  //   }
  // };

  let goToEdit = (data) => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Button] Edit Office Hours", 
        {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }

    props.history.push({
      pathname: `/o/${props.match.params.orgID}/setup/office-hour/edit`,
      state: {
        officeHourData: data,
      },
    });
  };

  let getOfficeHour = () => {
    setIsWaitingFetchOfficeHour(true);
    let _retriveDataError = {...retriveDataError};

    OfficeHourServices.getOfficeHour(props.match.params.orgID, (response) => {
      setIsWaitingFetchOfficeHour(false);
      let dataResult = response.dataResult;

      if (dataResult.error.message === '') {
        setPrintedOfficeHourData(dataResult.data.officeHours);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    });
  };

  let toggleModalRemoveOfficeHour = () => {
    setIsShowModalRemoveOFficeHour(!isShowModalRemoveOFficeHour);
  };

  let deleteOFficeHour = () => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Button] Delete Office Hour", 
        {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }

    setWaitForResponseDelete(true);

    OfficeHourServices.deleteOfficeHour(props.match.params.orgID, singleRemoveOfficeHour, (response) => {
        setWaitForResponseDelete(false);
        toggleModalRemoveOfficeHour();

        let dataResult = response.dataResult;

        if (dataResult.error.message === '') {
          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Delete Office Hour", 
              {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : props.match.params.orgID.split("-")[0]
              }
            );
          }
          doToast(dataResult.data.message);

          dataResult.data.success && getOfficeHour();
        } else {
          doToast(dataResult.error.message, 'fail');
        }
      }
    );
  };

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      } else {
        doToast(props.history.location.state.message, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history]);

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="office-hour-wrapper sectionWrap">
        {/* tier blocking */}
        {retriveDataError.code  === "49901" &&
          <RetrieveDataLoading 
            isLoading={isWaitingFetchOfficeHour}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="office-hour-title">
            <b>Office Hour</b>
          </p>

          <p className="office-hour-description">
            The office hours added will be the total hours your organization is
            available for. E.g if you were to add ‘Weekdays 09:00 - 17:00’ and
            ‘Monday 09:00 - 18:00’ then ‘Monday’ will effectively be open from
            09:00 - 18:00.
          </p>

          {(!isWaitingFetchOfficeHour && !retriveDataError.code) &&
            <NavLink to={`/o/${props.match.params.orgID}/setup/office-hour/add`}>
              <ButtonWithLoadingOrIcon 
                className="orange-button main-button-40 add-office-hour-button"
                text="Add Office Hour"
                position="left"
                icon={{
                  type: "svg",
                  src: FiPlus
                }}
              />
            </NavLink>
          }

          {(isWaitingFetchOfficeHour || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
            <RetrieveDataLoading 
              isLoading={isWaitingFetchOfficeHour}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
            :
            <div className="office-hour-data-wrapper">
              <table>
                <thead>
                  <tr>
                    <td className="col_day">
                      <b>Day</b>
                    </td>
                    <td className="col_start_time">
                      <b>Start Time</b>
                    </td>
                    <td className="col_end_time">
                      <b>End Time</b>
                    </td>
                    <td className="col_action"></td>
                  </tr>
                </thead>

                <tbody>
                  {printedOfficeHourData.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="no-office-hour no-hover">
                        <b>
                          No office hours found. Start by clicking “+Add Office
                          Hours”.
                        </b>
                      </td>
                    </tr>
                  ) : (
                    printedOfficeHourData.map((value, key) => {
                      return (
                        <tr key={`office-hour-row-${key}`}>
                          <td className="col_day">
                            {DAY_OF_WEEK[value.dayOfWeek].label}
                          </td>
                          <td className="col_start_time">{value.startTime}</td>
                          <td className="col_end_time">{value.endTime}</td>
                          <td className="col_action">
                            <span
                              className="edit-button"
                              onClick={() => goToEdit(value)}
                            >
                              <FiEdit /> <b>Edit</b>
                            </span>
                            
                            <span
                              className="remove-button"
                              onClick={() => {
                                toggleModalRemoveOfficeHour();
                                setSingleRemoveOfficeHour({
                                  id: value.id,
                                  dayOfWeek: value.dayOfWeek,
                                  createdTime: value.createdTime,
                                });
                              }}
                            >
                              <FiTrash2 />
                              <b>Delete</b>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          }

          <PopupRemove
            removeTitle={`Remove this office hour "${
              DAY_OF_WEEK[singleRemoveOfficeHour.dayOfWeek]
                ? DAY_OF_WEEK[singleRemoveOfficeHour.dayOfWeek].label
                : ''
            }" ?`}
            removeDescription={`This office hour will be removed from this organization`}
            onClickCancel={toggleModalRemoveOfficeHour}
            onClickRemove={deleteOFficeHour}
            waitForResponse={waitForResponseDelete}
            showModalProps={isShowModalRemoveOFficeHour}
          />
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default OfficeHour;
