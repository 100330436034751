import React, { useEffect, useState } from "react";
import { tapCoreMessageManager } from "@taptalk.io/web-sdk";
import { FiInfo } from "react-icons/fi"
import "./FormInteractiveMessagePreview.scss";
import { connect } from "react-redux";
// import { Scrollbars } from "react-custom-scrollbars";
import checkMarkBlue from "../../../../../../assets/img/icon-checkmark-blue-2.svg";
import iconSendWhite from "../../../../../../assets/img/icon-send-white.svg";
import whatsappPreviewPdfIcon from "../../../../../../assets/img/whatsapp-preview-pdf-icon.svg";
import { FiX, FiEye, FiImage, FiVideo, FiList } from "react-icons/fi";
import { doToast, scrollToClass } from "../../../../../../helper/HelperGeneral";
import HelperDate from "../../../../../../helper/HelperDate";
import HelperQuickReply from "../../../../../../helper/HelperQuickReply";
// import { doToast, scrollToClass } from "../../../../../../helper/HelperGeneral";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSelectOption from "../../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import PopupConfirmation from "../../../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import IntegrationWhatsappBusinessServices from "../../../../../../services/integration/IntegrationWhatsappBusinessServices";
import ServiceQuickReply from "../../../../../../services/newServices/QuickReplyService";
// import ErrorOrInfoComp from "../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";

// var style = {
//   scrollStyle: {
//     position: "relative",
//     backgroundColor: "#ff7d00",
//     right: "-2px",
//     width: "5px",
//     borderRadius: "8px",
//   },
// };

let FormInteractiveMessagePreview = (props) => {
  let { isShowModalFormInteractivePreview, toggle, back, type, data, parentProps, caseDetailData, forceCloseModal, _labelFor } = props;
  let [isLoadingSend, setIsLoadingSend] = useState(false);
  let [shortcut, setShorcut] = useState("");
  let [saveShortcut, setSaveShortcut] = useState(false);
  let [dropdownShortcut, setDropdownShortcut] = useState({
    label: "",
    value: ""
  });
  let [errorMessageData, setErrorMessageData] = useState({
    shortcut: ""
  });
  let [popupConfirmationShow, setPopupConfirmationShow] = useState(false);

  let tooglePopupConfirmation = () => {
    setPopupConfirmationShow(!popupConfirmationShow);
  }

  useEffect(() => {
    if(props.activeQuickReplyInteractive) {

    }
  }, [props.activeQuickReplyInteractive])

  useEffect(() => {
    if(!isShowModalFormInteractivePreview) {
      setShorcut("");
      setSaveShortcut(false);
      setDropdownShortcut( {
        label: "Personal Quick Reply",
        value: "personal"
      });
      setErrorMessageData({
        shortcut: ""
      })
    }
  }, [isShowModalFormInteractivePreview])

  let getAgentOrAdmin = () => {
    let role = "";
    
    let idxOrg = props.myAgentData.memberships.findIndex((v) => v.organization.id === parseInt(props.parentProps.match.params.orgID.split("-")[0]));
    
    if(props.myAgentData.memberships[idxOrg]) {
      role = props.myAgentData.memberships[idxOrg].roleCode;
    }
    
    return role;
  }

  let doUpload = (file) => {
    setIsLoadingSend(true);

    let uploadData = {
      file: file,
      caption: "",
      room: ""
    };

    tapCoreMessageManager.uploadChatFile(uploadData, {
      onProgress: (percentage, bytes) => {
      
      },

      onSuccess: (response) => {
          if(response) {
            runSendInteractive(response);
          }
      },

      onError: (error) => {
        doToast(error, "fail");
        console.log(error);
      }
    })
  }

  let doUploadShortcut = (file) => {
    setIsLoadingSend(true);

    let type = "";

    if(file.type.split("/")[0] !== "video" && file.type.split("/")[0] !== "image") {
      type = "file";
    }else {
      type = file.type.split("/")[0];
    }

    var formData = new FormData();
    formData.append("fileType", type);
    formData.append("file", file);

    ServiceQuickReply.uploadMedia(props.parentProps.match.params.orgID, formData, (response) => {
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
        runSaveQuickReply(dataResult.data);
      }else {
        setIsLoadingSend(false);
        doToast(dataResult.error.message, "fail");
      }
    });
  }
  
  let runSaveQuickReply = (media) => {
    setIsLoadingSend(true);

    let dataSave = {
      "ownerType": dropdownShortcut.value === "general" ? HelperQuickReply.ownerTypeGeneral : HelperQuickReply.ownerTypePersonal,
      "type": type,
      "bodyText": data.body,
      "footerText": data.footer,
      "shortcut": shortcut
    }

    if(type === "button") {
      if(data.headerType === "text") {
        dataSave.header = {
          type: "text",
          text: data.headerText
        };
      }

      if(media) {
        if(data.headerType === "document") {
          dataSave.header = {
            type: "document",
            document: {
              mediaFileID: media.id,
              filename: data.document.name
            }
          }
        }else {
          if(data.document.type.split("/")[0] === "image") {
            dataSave.header = {
              type: "image",
              image: {
                mediaFileID: media.id
              }
            }
          }

          if(data.document.type.split("/")[0] === "video") {
            dataSave.header = {
              type: "video",
              video: {
                mediaFileID: media.id
              }
            }
          }
        }
      }

      let _buttonText = [];

      data.buttonText.map((v) => {
        _buttonText.push({
          id: "",
          title: v
        })
        return null;
      })

      dataSave.buttonAction = {
        buttons:_buttonText
      };
    }

    if(type === "list") {
      if(data.headerText !== "") {
        dataSave.header = {
          type: "text",
          text: data.headerText
        }
      }

      dataSave.listAction = {
        "buttonText": data.buttonText,
        "sections": data.section
      }
    }

    ServiceQuickReply.quickReplyInteractive(parentProps.match.params.orgID, dataSave, (res) => {
      let dataResult = res.dataResult;
      let _errorMessageData = {...errorMessageData};

      if(dataResult.error.message === "") {
        if(dataResult.data.success) {
          doToast(dataResult.data.message);
          
          if(data.headerType === "media" || data.headerType === "document") {
            doUpload(data.document)
          }else {
            runSendInteractive()
          }    
        }else {
          _errorMessageData.shortcut = dataResult.data.message;
          doToast(dataResult.data.message, "fail");  
          setIsLoadingSend(false);
        }
      }else {
        _errorMessageData.shortcut = dataResult.error.message;
        doToast(dataResult.error.message, "fail");
        setIsLoadingSend(false);
      }

      setErrorMessageData(_errorMessageData);
    })
  }

  let runSendInteractive = (media = false) => {
    setIsLoadingSend(true);

    let dataSend = {
      "id": caseDetailData.case.id,
      "createdTime": caseDetailData.case.createdTime,
      "localID": "",
      "replyToLocalID": "",
      "type": type,
      "bodyText": data.body,
      "footerText": data.footer,
    }

    if(type === "button") {
      if(data.headerType === "text") {
        dataSend.header = {
          type: "text",
          text: data.headerText
        };
      }

      if(media) {
        if(data.headerType === "document") {
          dataSend.header = {
            type: "document",
            document: {
              link: media.fileURL,
              filename:  data.document.name ? data.document.name : data.mediaFilename
            }
          }
        }else {
          if(data.document.type.split("/")[0] === "image") {
            dataSend.header = {
              type: "image",
              image: {
                link: media.fileURL
              }
            }
          }

          if(data.document.type.split("/")[0] === "video") {
            dataSend.header = {
              type: "video",
              video: {
                link: media.fileURL
              }
            }
          }
        }
      }

      let _buttonText = [];

      data.buttonText.map((v) => {
        _buttonText.push({
          id: "",
          title: v
        })
        return null;
      })

      dataSend.buttonAction = {
        buttons:_buttonText
      };
    }

    if(type === "list") {
      if(data.headerText !== "") {
        dataSend.header = {
          type: "text",
          text: data.headerText
        }
      }

      dataSend.listAction = {
        "buttonText": data.buttonText,
        "sections": data.section
      }
    }

    IntegrationWhatsappBusinessServices.sendInteractive(parentProps.match.params.orgID, dataSend, (res) => {
      let dataResult = res.dataResult;

      if(dataResult.error.message === "") {
        if(dataResult.data.success) {
          doToast(dataResult.data.message);

          forceCloseModal();
        }else {
          doToast(dataResult.data.message, "fail");  
        }
      }else {
        doToast(dataResult.error.message, "fail");
      }

      setIsLoadingSend(false);
      setPopupConfirmationShow(false);
    })
  }

  let checkSaveShortcut = () => {
    let errorCount = 0;
    let _errorMessageData = {...errorMessageData};
    let scrollTo = "";

    if(shortcut === "") {
      _errorMessageData.shortcut = "This field is required";
      errorCount = errorCount + 1;
      scrollTo = "input-quick-reply-preview";
    }

    if(shortcut.length > 50) {
      _errorMessageData.shortcut = "Characters exceed limit";
      errorCount = errorCount + 1;
      scrollTo = "input-quick-reply-preview";
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${scrollTo}`)
    }else {
      if(data.headerType === "media" || data.headerType === "document") {
        if(saveShortcut) {
          doUploadShortcut(data.document);
        }else {
          doUpload(data.document);
        }
      }else {
        runSaveQuickReply();
      }
    }
  }

  return (
    <>
      <PopupConfirmation 
         isOpen={popupConfirmationShow}
         toggle={tooglePopupConfirmation}
         title="Send Message without Saving as Quick Reply?"
         description="The interactive message you have created will be send as a one time message and will not be saved as your quick reply. Are you sure you want to continue?"
         className="modal-confirmation-send-interactive"
      >
        {!isLoadingSend ?
          <ButtonWithLoadingOrIcon
              text="Send Message"
              className="main-button-48 orange-button"
              onClickAction={() => {
                data.headerType === "media" || data.headerType === "document" ?
                  doUpload(data.document)
                  :
                  runSendInteractive()
              }}
              position="left"
              icon={{
                type: "img",
                src: iconSendWhite
              }}
          />
          :
          <ButtonWithLoadingOrIcon
              text="Send Message"
              className="main-button-48 orange-button"
              isLoading
              isDisabled
              loadingColor="gray"
              position="left"
          />
        }
      </PopupConfirmation>

      <div
        className={`
          modal-new-interactivePreview-message 
          ${isShowModalFormInteractivePreview ? 
            "active-modal-new-interactivePreview-message"
            : 
            ""
          } 
        `}
      >
        <div
          className={`
            modal-body 
            ${isShowModalFormInteractivePreview ? 
              "action-modal-body-new-interactivePreview-message"
              : 
              ""
            }
          `}
        >
          <div onClick={() => !isLoadingSend && toggle(true)} className="close-modal-interactivePreview">
            <FiX />
          </div>
          
          <div className="new-interactivePreview-message-wrapper">
            {/* <Scrollbars
              autoHideTimeout={500}
              renderThumbVertical={(props) => (
                <div {...props} style={style.scrollStyle} />
              )}
            > */}
              <div className="new-interactivePreview-message-container">
                <BackButton 
                  text="Back to Interactive Message"
                  onClick={() => !isLoadingSend && back()}
                />
                <div className="new-interactivePreview-message-title">
                  <FiEye />

                  <b>Preview Message</b>
                  <p>
                    Below is a preview of what your recipient will recieve.
                  </p>
                </div>

                <div className={"preview-message-body"}>
                  <div className="whatsapp-preview-interactive">
                    <div className="whatsapp-info-wrapper">
                        <div className="whatsapp-info-content" />
                    </div>

                    {(data.headerType === "text" || data.headerType === "no_header" || typeof data.headerType === "undefined") &&
                      <div className="whatsapp-chat-bubble">
                          <p 
                              className="whatsapp-chat-bubble-title" 
                              dangerouslySetInnerHTML={{
                                  __html:(`<b>${data.headerText}</b>`)
                              }}
                          />

                          <p className="whatsapp-chat-bubble-description">
                              {data.body}
                          </p>

                          
                          <p className="whatsapp-chat-bubble-description color-707070">
                              {data.footer}
                          </p>
                          
                          <p className="whatsapp-chat-bubble-status">
                              {HelperDate.formatToString(new Date(), "HH:mm")}
                              <img src={checkMarkBlue} alt="" />
                          </p>
                      </div>
                    }

                    {data.headerType === "media" &&
                      (data.document !== false &&
                        <div className="whatsapp-chat-bubble">
                          <div className="media-wrapper">
                              {data.document.type.split("/")[0] === "video" &&
                                <video src={data.document.fileURL ? data.document.fileURL : data.document.fileSrc} className="video-media-preview" />
                              }

                              {data.document.type.split("/")[0] === "image" &&
                                <img alt="" src={data.document.fileURL ? data.document.fileURL : URL.createObjectURL(data.document)} className="image-media-preview" />
                              }

                              <div className="black-layer" />
                              
                              {data.document.type.split("/")[0] === "video" ? <FiVideo /> : <FiImage />}
                            </div>

                            <p className="whatsapp-chat-bubble-description">
                                {data.body}
                            </p>

                            
                            <p className="whatsapp-chat-bubble-description color-707070">
                                {data.footer}
                            </p>
                            
                            <p className="whatsapp-chat-bubble-status">
                                {HelperDate.formatToString(new Date(), "HH:mm")}
                                <img src={checkMarkBlue} alt="" />
                            </p>
                        </div>
                      )
                    }
                    
                    {data.headerType === "document" &&
                      <div className="whatsapp-chat-bubble">
                          <div className="static-media">
                            <div className="static-media-document">
                                <img src={whatsappPreviewPdfIcon} alt="" />

                                {data.document.name ?  data.document.name : data.mediaFilename}
                            </div>
                          </div>

                          <p className="whatsapp-chat-bubble-description">
                              {data.body}
                          </p>

                          
                          <p className="whatsapp-chat-bubble-description color-707070">
                              {data.footer}
                          </p>
                          
                          <p className="whatsapp-chat-bubble-status">
                              {HelperDate.formatToString(new Date(), "HH:mm")}
                              <img src={checkMarkBlue} alt="" />
                          </p>
                      </div>
                    }

                    {type === "button" &&
                      (data.buttonText.map((v, i) => {
                        return (
                          <div className="button-whatsapp-interactive-preview" key={`button-whatsapp-interactive-preview-${i}`}>
                            <b>{v}</b>
                          </div>
                        )
                      }))
                    }

                    {type === "list" &&
                      <div className="button-whatsapp-interactive-preview with-icon">
                        <FiList />
                        <b>{data.buttonText}</b>
                      </div>
                    }
                  </div>
                </div>
                
                {type === "list" &&
                  <div className="interactive-section-list">
                    {data.section.map((v, i) => {
                      return (
                        <React.Fragment key={`rows-list-${i}`}>
                          <p className="table-title">
                            <b>Section #{i+ 1}{v.title === "" ? "" : `: ${v.title}`}</b>
                          </p>

                          <table key={`rows-table-${i}`}>
                            <thead>
                              <tr>
                                <td><b>Row</b></td>
                                <td><b>Title</b></td>
                                <td><b>Description</b></td>
                              </tr>
                            </thead>

                            <tbody>
                              {v.rows.map((_v, _i) => {
                                return (
                                    <tr key={`rows-tbody-${_i}`}>
                                      <td>{_i + 1}</td>
                                      <td>{_v.title}</td>
                                      <td>{_v.description}</td>
                                    </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </React.Fragment>
                      )
                    })}
                  </div>
                }

                {!props.activeQuickReplyInteractive &&
                  <div className="add-to-quick-reply-wrapper">
                    <div className="add-to-quick-reply-save">
                      <div className="add-to-quick-reply-save-left">
                        <div className="custom-checkbox">
                          <input 
                            type="checkbox" 
                            id={`save-quick-reply-${_labelFor}`}
                            checked={saveShortcut} 
                            onChange={(e) => {  
                              setSaveShortcut(!saveShortcut);
                            }} 
                          />
                          <label htmlFor={`save-quick-reply-${_labelFor}`}>
                            Save message as {dropdownShortcut.value === "general" ? "General" : "Personal"} Quick Reply
                          </label>
                        </div>
                      </div>

                      {(getAgentOrAdmin() !== "member") &&
                        <div className="add-to-quick-reply-save-right">
                          <CustomSelectOption
                              isDisabled={false}
                              optionListProps={[
                                {
                                  label: "Personal Quick Reply",
                                  value: "personal"
                                },
                                {
                                  label: "General Quick Reply",
                                  value: "general"
                                },
                              ]}
                              valueDropdownProps={dropdownShortcut}
                              placeholderProps={"Select Quick Reply"}
                              onClickDropDownListOptionProps={(e) => setDropdownShortcut(e)}
                              specificClassNameProps="custom-select-quick-reply"
                              // _className={`input-account ${errorMessageData.account === "" ? "" : "border-red"}`}
                              _className={`input-quick-reply-select`}
                          />
                        </div>
                      }
                    </div>
                    
                    {saveShortcut &&
                      <>
                        <div className={`add-to-quick-reply-input input-personal`}>
                          <label>
                            <b>Quick Reply Shortcut</b>

                            <span className={`grey-font ${errorMessageData.shortcut === "Characters exceed limit" ? "red-text" : ""}`}>{shortcut.length}/50 Characters</span>
                          </label>

                          <input className="input-slash" type="text" value="/" disabled={true} />
                          
                          <input 
                            className={`input-quick-reply input-quick-reply-preview ${dropdownShortcut.value === "general" ? "input-quick-reply-general" : ""} ${errorMessageData.shortcut !== "" ? "border-red" : ""}`} 
                            type="text"
                            disabled={isLoadingSend}
                            value={shortcut} 
                            placeholder="Type shortcut here"
                            onChange={(e) => {
                              setErrorMessageData({
                                shortcut: ""
                              })

                              if(e.target.value.length > 50) {
                                setErrorMessageData({
                                  shortcut: "Characters exceed limit"
                                })
                              }

                              setShorcut(e.target.value.replaceAll(" ", ""));
                            }} 
                          />
                          
                          {dropdownShortcut.value === "personal" &&
                            <input className="input-me" type="text" value="_me" disabled={true} />
                          }
                        </div>

                        {errorMessageData.shortcut !== "" &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.shortcut}
                            _className={`red-text`}
                            icon={<FiInfo />}
                          />
                        }

                        <div className="add-to-quick-reply-info">
                          This is the message that will appear after you select a specific shortcut. 
                          <b>Only alphanumeric and underscore are allowed.</b>
                          Your shortcut will be displayed like this "/shortcut" 
                          (Tip: keep it short and simple).
                        </div>
                      </>
                    }
                  </div>
                }
              </div>
            {/* </Scrollbars> */}
          </div>

          <div className="send-message-button">
            {(!isLoadingSend || popupConfirmationShow) ? 
              <ButtonWithLoadingOrIcon
                onClickAction={() => {
                  saveShortcut ?
                    checkSaveShortcut()
                    :
                    (!props.activeQuickReplyInteractive ?
                      tooglePopupConfirmation()
                      :
                      data.headerType === "media" || data.headerType === "document" ?
                        (data.document.fileURL ?
                          runSendInteractive(data.document)
                          :
                          doUpload(data.document)
                        )
                        :
                        runSendInteractive()
                    )
                }}
                // isDisabled={isDisabledSubmit()}
                className="orange-button main-button-48"
                text="Send Message"
                position="left"
                icon={{
                  type: "img",
                  src: iconSendWhite
                }}
              />
              :
              <ButtonWithLoadingOrIcon
                isLoading
                isDisabled
                loadingColor="gray"
                className="orange-button main-button-48"
                text="Send Message"
                position="left"
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeQuickReplyInteractive: state.activeQuickReplyInteractive,
  myAgentData: state.myAgentData
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FormInteractiveMessagePreview);
  