const INITIAL_STATE = false;

let reduxReducerMyAgentAccount = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_MY_AGENT_DATA':
        return action.myAgentData;
      case 'CLEAR_MY_AGENT_DATA':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerMyAgentAccount;