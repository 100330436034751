import React, { useState, useEffect } from 'react';
import './ChatRoomCaseHistoryTab.scss';
// import iconLine from "../../../../../assets/img/omnichannel/room-list/icon-line.svg";
import { FiCircle, FiCheckCircle, FiClock } from "react-icons/fi";
import { connect } from 'react-redux';
import HelperChat from "../../../../../../helper/HelperChat";
import HelperDate from "../../../../../../helper/HelperDate";
// import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
// import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
// import { Scrollbars } from 'react-custom-scrollbars';
import { TAPLIVE_MEDIUM_LOGO } from "../../../../../../constants/taplive";
import { FixedSizeList as List } from 'react-window';

// var style = {
//   scrollStyle: {
//     position: "relative",
//     backgroundColor: "#ff7d00",
//     right: "-5px",
//     width: "3px",
//     borderRadius: "8px"
//   }
// };


var ChatRoomChatHistoryTab = (props) => {
    let [panelHeight, setPanelHeight] = useState(0);

    useEffect(() => {
        setPanelHeight(document.querySelectorAll(".main-chat-history-content")[0].offsetHeight)
    }, [])

    let generateIconOmnichannel = (medium) => {
        return TAPLIVE_MEDIUM_LOGO[medium] ? TAPLIVE_MEDIUM_LOGO[medium] : "";
    }

    const Row = ({ index, style }) => {
        let data = {
            user: props.caseDetailProps.user,
            case: props.caseHistoryData[index]
        }

        return(
            <div style={style}>
                {/* {Object.keys(props.caseDetailProps.userCases).map((value) => { */}
            
                <div className="chat-history-tab-list" onClick={() => props.onClickCaseHistoryProps(data)} key={`chat-history-${props.caseHistoryData[index].id}`}>
                    {generateIconOmnichannel([props.caseHistoryData[index].medium]) === "" ?
                        ""
                        :
                        <img src={generateIconOmnichannel([props.caseHistoryData[index].medium])} alt="" className="chat-history-omnichannel-icon" />
                    }
                    
                    <p className="chat-history-topic-name">
                        <b>{props.caseHistoryData[index].topicName} (#{props.caseHistoryData[index].stringID})</b>
                    </p>

                    <p className="chat-history-last-message">
                        {HelperChat.lineBreakRoomList(props.caseHistoryData[index].firstMessage)}
                    </p>

                    <div className="chat-history-time-and-status-wrapper">
                        <div className="chat-history-time-wrapper">
                        <FiClock />
                        <b>Created {HelperDate.formatToString(new Date(props.caseHistoryData[index].createdTime), "dd/MM/yyyy")}</b>
                        </div>

                        {!props.caseHistoryData[index].isClosed ?
                        <div className="chat-history-case-status">
                            <FiCircle />
                            <b>Open</b>
                        </div>
                        :
                        <div className="chat-history-case-status chat-history-solved">
                            <FiCheckCircle />
                            <b>Resolved</b>
                        </div>
                        }

                        {/* {props.caseHistoryData[index].isClosed &&
                        <div className="chat-history-case-status chat-history-solved">
                            <FiCheckCircle />
                            <b>Resolved</b>
                        </div>
                        } */}

                        {/* {((props.caseHistoryData[index].agentAccountID !== 0) && (props.caseHistoryData[index].agentAccountID !== myAgentAccountID) && (!props.caseHistoryData[index].isClosed)) &&
                        <div className="chat-history-case-status chat-history-other-agent">
                            <FiUser />
                            <b>{props.caseHistoryData[index].agentFullName}</b>
                        </div>
                        }

                        {((props.caseHistoryData[index].agentAccountID !== 0) && (props.caseHistoryData[index].agentAccountID === myAgentAccountID) && (!props.caseHistoryData[index].isClosed)) &&
                        <div className="chat-history-case-status">
                            <FiUser />
                            <b>Me</b>
                        </div>
                        } */}
                    </div>
                </div>
                {/* })} */}
            </div>
        )
    };

    return (
        // <Scrollbars autoHideTimeout={500}
        //     renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
        // >
        <div className={`chat-history-outer-wrapper ${props.className}`}>
            {props.loadingGetCaseHistory ?
                <div className='lds-ring'>
                    <div /><div /><div /><div />
                </div>
                :
                (props.caseHistoryData.length > 0 ? 
                    <List
                        height={panelHeight}
                        itemCount={props.caseHistoryData.length}
                        itemSize={93}
                        width={"100%"}
                    >
                        {Row}
                    </List>
                    :
                    <div className="empty-broadcast-list">
                        <p>
                            <b>No Results Were Found</b>
                        </p>

                        <p>
                            There are no messages to display.
                        </p>
                    </div>
                )
        }
        </div>
        // </Scrollbars>
    );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomChatHistoryTab);