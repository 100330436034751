import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./IntegrationGoogleProfileAddAccount.scss";
import "react-toastify/dist/ReactToastify.css";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SearchBox from "../../../reuseableComponent/searchBox/SearchBox";
import Loading from "../../../reuseableComponent/loading/Loading";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import IntegrationGoogleBusinessProfileServices from "../../../../services/integration/integrationGoogleBusinessProfile";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";
import { setMediumLastRun } from "../../../../redux/actions/reduxActionMediumLastRun";
import { FiX, FiChevronLeft, FiInfo, FiEdit, FiUserCheck, FiMapPin } from "react-icons/fi";
import { connect } from 'react-redux';
import iconGoogleButton from "../../../../assets/img/icon-google-button.svg";

const IntegrationGoogleProfileAddAccount = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingGetApp, setIsLoadingGetApp] = useState(true);
    let [topicList, setTopicList] = useState(false);
    let [isConnected, setIsConnected] = useState(false);
    let [accountList, setAccountList] = useState([]);
    let [isMore, setIsMore] = useState(false);
    let [isMoreAccount, setIsMoreAccount] = useState(false);
    let [nextPageToken, setNextPageToken] = useState("");
    let [nextPageTokenAccount, setNextPageTokenAccount] = useState("");
    let [isLoadingMore, setIsLoadingMore] = useState(false);
    let [isLoadingMoreAccount, setIsLoadingMoreAccount] = useState(false);
    let [locationList, setLocationList] = useState([]);
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [showModalLocation, setShowModalLocation] = useState(false);
    let [showModalAccount, setShowModalAccount] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    // let [isLoadingSearchLocationName, setIsLoadingSearchLocationName] = useState(false);
    let [formVal, setFormVal] = useState({
        topic: {
            value: "",
            label: "",
        },
        topicEror: "",
        channelName: "",
        channelNameError: "",
        accountLocationError: "",
        account: {
            accountID: "",
            name: "",
            type: ""
        },
        location: {
            locationID: "",
            name: "",
            fullAddress: "",
            mapsUri: ""
        }
    })
    let [formValOrigin, setFormValOrigin] = useState({
        topic: {
            value: "",
            label: "",
        },
        topicEror: "",
        channelName: "",
        channelNameError: "",
        accountLocationError: "",
        account: {
            accountID: "",
            name: "",
            type: ""
        },
        location: {
            locationID: "",
            name: "",
            fullAddress: "",
            mapsUri: ""
        }
    })
    let [temporarySelectedAccount, setTemporarySelectedAccount] = useState({
        accountID: "",
        name: "",
        type: ""
    })
    let [loadingGetAccount, setLoadingGetAccount] = useState(false);
    let [loadingGetLocation, setLoadingGetLocation] = useState(false);

    //google
    let [googleApp, setGoogleApp] = useState(false);
    let [googleClient, setGoogleClient] = useState(false);
    let [googleAccessToken, setGoogleAccessToken] = useState(false);
    let [isShowModalContinueWithGoogle, setIsShowModalContinueWithGoogle] = useState(false);
    let [searchVal, setSearchVal] = useState("");
    let [isLoadingCheckLocation, setIsLoadingCheckLocation] = useState(-1);
    let [showDiscardLocation, setShowDiscardLocation] = useState(false);
    //google

    let back = () => history.push(`/o/${orgID}/integration/google-business-profile`);

    let toogleModalLocation = (ignore = false) => {
        if (!isLoadingSubmit) {
            if (!ignore) {
                if (showModalLocation) {
                    if (JSON.stringify(formVal.account) !== JSON.stringify(temporarySelectedAccount) && temporarySelectedAccount.accountID !== "") {
                        toggleDiscard();
                    } else {
                        getLocationList(false, false);
                        setShowModalLocation(!showModalLocation);
                    }
                } else {
                    // setTemporarySelectedAccount({
                    //     accountID: "",
                    //     name: "",
                    //     type: ""
                    // });
                    setShowModalLocation(!showModalLocation)
                }
            } else {
                // setTemporarySelectedAccount({
                //     accountID: "",
                //     name: "",
                //     type: ""
                // });
                setShowModalLocation(!showModalLocation);
            }
        }
    }

    let toogleModalAccount = () => {
        if (!isLoadingSubmit) {
            setShowModalAccount(!showModalAccount)
        }
    }

    let toogleModalContinueWithGoogle = () => {
        setIsShowModalContinueWithGoogle(!isShowModalContinueWithGoogle)
    }

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);

            if (localStorage.getItem("onetalkgbp")) {
                setGoogleAccessToken(localStorage.getItem("onetalkgbp"));
            }
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        setIsLoadingGetApp(true);

        topicServices.getTopic(orgID, "listss", null, (response) => {
            let _retriveDataError = { ...retriveDataError };

            let dataResult = response.dataResult;

            if (response.dataResult.status === 200) {
                const data = response.dataResult.data.topics;
                const createList = () => {
                    let newData = [];
                    data.map(val => {
                        let _data = {};
                        _data.value = val.id;
                        _data.label = val.name;
                        newData.push(_data);
                        return null;
                    });
                    setTopicList(newData);
                };

                createList();
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setIsLoadingGetApp(false);
            }

        });
    }

    let getApp = (orgID) => {
        setIsLoadingGetApp(true);

        IntegrationGoogleBusinessProfileServices.getGoogleApp(orgID, (response) => {
            let _retriveDataError = { ...retriveDataError };
            let dataResult = response.dataResult;

            if (response.dataResult.status === 200) {
                setGoogleApp(dataResult.data);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setIsLoadingGetApp(false);
            }

            // setIsLoadingGetApp(false);
        });
    }

    let getAccountList = (isMore = false) => {
        if(!isMore) {
            setLoadingGetAccount(true);
        }

        let data = {
            appClientID: googleApp.appClientID,
            authorizationCode: googleAccessToken,
            pageToken: nextPageTokenAccount
        }

        IntegrationGoogleBusinessProfileServices.getAccount(orgID, data, (response) => {
            // let _retriveDataError = {...retriveDataError};
            let dataResult = response.dataResult;
            let _accountList = accountList.slice();

            if (response.dataResult.status === 200) {
                if(!isMore) {
                    setAccountList(dataResult.data.accounts);
                }else {
                    let _newArr = [];
                    _newArr = _accountList.concat(dataResult.data.accounts);
                    setAccountList(_newArr);
                }

                setIsMoreAccount(dataResult.data.hasMore);
                setNextPageTokenAccount(dataResult.data.nextPageToken);
            } else {
                setGoogleAccessToken(false);
                toogleModalAccount();
                doToast(dataResult.error.message, "fail");
                // _retriveDataError.message = dataResult.error.message;
                // _retriveDataError.code = dataResult.error.code;
                // setRetrieveDataError(_retriveDataError);
            }

            setLoadingGetAccount(false);
            setIsLoadingMoreAccount(false);
        });
    }

    let getLocationList = (isClear, ignoreTemporary = false, isLoadMore = false) => {
        if (!isLoadMore) {
            setLoadingGetLocation(true);
        }

        let _locationList = locationList.slice();

        let data = {
            appClientID: googleApp.appClientID,
            authorizationCode: googleAccessToken,
            gmbAccountID: ignoreTemporary ? formVal.account.accountID : (temporarySelectedAccount.accountID !== "" ? temporarySelectedAccount.accountID : formVal.account.accountID),
            search: isClear ? "" : searchVal,
            pageToken: nextPageToken
        }

        IntegrationGoogleBusinessProfileServices.getLocation(orgID, data, (response) => {
            // let _retriveDataError = {...retriveDataError};
            let dataResult = response.dataResult;

            if (response.dataResult.error.code === "") {
                if (!isLoadMore) {
                    setLocationList(dataResult.data.locations);
                } else {
                    let _newArr = [];
                    _newArr = _locationList.concat(dataResult.data.locations);
                    setLocationList(_newArr);
                }

                setIsMore(dataResult.data.hasMore);
                
                setNextPageToken(dataResult.data.nextPageToken);
            } else {
                setGoogleAccessToken(false);
                toogleModalLocation(true);
                doToast(dataResult.error.message, "fail");
                // _retriveDataError.message = dataResult.error.message;
                // _retriveDataError.code = dataResult.error.code;
                // setRetrieveDataError(_retriveDataError);
            }

            setLoadingGetLocation(false);
            setIsLoadingMore(false);
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        setFormVal({
            topic: {
                value: "",
                label: "",
            },
            topicEror: "",
            channelName: "",
            channelNameError: "",
            accountLocationError: "",
            account: {
                accountID: "",
                name: "",
                type: ""
            },
            location: {
                locationID: "",
                name: "",
                fullAddress: "",
                mapsUri: ""
            }
        })
    }, [isConnected])

    useEffect(() => {
        if (orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    useEffect(() => {
        if (topicList) {
            getApp(orgID)
        }
    }, [topicList])

    useEffect(() => {
        if (googleAccessToken) {
            setIsConnected(true);
        }
    }, [googleAccessToken])

    useEffect(() => {
        if (showModalAccount) {
            getAccountList();
        }
    }, [showModalAccount])

    useEffect(() => {
        if (temporarySelectedAccount.accountID !== "") {
            getLocationList(false, false);
        }
    }, [temporarySelectedAccount])

    useEffect(() => {
        if (googleApp) {

            //google script
            if (!document.querySelector("#google-jssdk")) {
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) { return; }
                    js = d.createElement(s); js.id = id;
                    js.onload = () => {
                        initClient();
                    }
                    js.src = "https://accounts.google.com/gsi/client";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'google-jssdk'));
            } else {
                initClient();
            }

            function initClient() {
                setGoogleClient(window.google.accounts.oauth2.initCodeClient({
                    client_id: googleApp.appClientID,
                    scope: 'https://www.googleapis.com/auth/business.manage',
                    callback: (tokenResponse) => {
                        localStorage.setItem("onetalkgbp", tokenResponse.code);
                        setGoogleAccessToken(tokenResponse.code);
                    },
                }));

                setIsLoadingGetApp(false);
            }
            //google script

        }
    }, [googleApp])

    let onSelectTopic = (value) => {
        let _formVal = { ...formVal };
        _formVal.topic = value;
        _formVal.topicEror = "";
        setFormVal(_formVal);
    }

    let isFormValid = () => {
        return isConnected;
    }

    let connectTo = () => {
        toogleModalContinueWithGoogle();

        if (googleClient) {
            googleClient.requestCode();
        }
    }

    let logoutFrom = () => {
        localStorage.removeItem("onetalkgbp");
        // window.google.accounts.oauth2.revoke(googleAccessToken, () => {console.log('access token revoked')});
        setIsConnected(false);
        setGoogleAccessToken(false);
    }

    let checkLocationExist = (_v, i) => {
        setIsLoadingCheckLocation(i);

        let data = {
            gmbLocationID: _v.locationID,
            appClientID: googleApp.appClientID,
            authorizationCode: googleAccessToken
        };

        IntegrationGoogleBusinessProfileServices.checkLocationExist(orgID, data, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.status === 200) {
                if (dataResult.data.canCreate) {
                    toogleModalLocation(true);
                    let _formVal = { ...formVal };

                    _formVal.account = temporarySelectedAccount;
                    _formVal.location = _v;
                    _formVal.accountLocationError = "";
                    setFormVal(_formVal);
                } else {
                    doToast(dataResult.data.message, 'fail');
                }
            } else {
                doToast(dataResult.error.message, "fail");
            }

            setIsLoadingCheckLocation(-1);
        })
    }

    let onSubmit = () => {
        let _formVal = { ...formVal };
        let errorCount = 0;
        let scrollToError = "";

        if (_formVal.account.accountID === "") {
            errorCount = errorCount + 1;

            _formVal.accountLocationError = "Please select business profile";

            if (scrollToError === "") { scrollToError = `select-business-profile` }
        }

        if (_formVal.channelName === "") {
            errorCount = errorCount + 1;

            _formVal.channelNameError = "This field is required";

            if (scrollToError === "") { scrollToError = `input-channel-name` }
        }

        if (_formVal.channelName.length > 30) {
            errorCount = errorCount + 1;

            _formVal.channelNameError = "Characters exceed limit";

            if (scrollToError === "") { scrollToError = `input-channel-name` }
        }

        if (_formVal.topic.value === "") {
            errorCount = errorCount + 1;

            _formVal.topicEror = "Please select a topic";

            if (scrollToError === "") { scrollToError = `custom-select-google-profile-topic` }
        }

        setFormVal(_formVal);

        if (scrollToError !== "" || errorCount > 0) {
            scrollToClass(`.${scrollToError}`);
        } else {
            setIsLoadingSubmit(true);

            let data = {
                topicID: formVal.topic.value,
                channelName: formVal.channelName,
                appClientID: googleApp.appClientID,
                authorizationCode: googleAccessToken,
                gmbAccountID: formVal.account.accountID,
                gmbLocationID: formVal.location.locationID,
            };

            IntegrationGoogleBusinessProfileServices.createAccount(orgID, data, (response) => {
                let dataResult = response.dataResult;

                if (dataResult.status === 200) {
                    if (dataResult.data.success) {
                        setFormValOrigin(formVal);
                        doToast(dataResult.data.message);
                        setTimeout(() => {
                            back();
                        }, 3000);
                    } else {
                        doToast(dataResult.data.message, 'fail');
                    }
                } else {
                    doToast(dataResult.error.message, "fail");
                    setIsLoadingSubmit(false);
                }
            })
        }
    }

    let onScrollRun = (e) => {
        if ((e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight) < 1) {
            if (isMore) {
                setIsLoadingMore(true);
                getLocationList(false, false, true);
            }
        }
    }

    let onScrollAccountRun = (e) => {
        if ((e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight) < 1) {
            if (isMoreAccount) {
                setIsLoadingMoreAccount(true);
                getAccountList(true);
            }
        }
    }

    let modalSelectAccount = () => {
        return (
            <Modal toggle={toogleModalAccount} isOpen={showModalAccount} className="modal-select-account-google-profile">
                <ModalBody>
                    <p className="modal-header">
                        <b>Select Account</b>

                        <FiX className="" onClick={toogleModalAccount} />
                    </p>

                    <div className="table-modal-select-location-wrapper"  onScroll={(e) => onScrollAccountRun(e)}>
                        <table>
                            <thead>
                                <tr>
                                    <td><b>Account Name</b></td>
                                    <td><b>Account Type</b></td>
                                    <td></td>
                                </tr>
                            </thead>

                            <tbody>
                                {loadingGetAccount ?
                                    <tr>
                                        <td colSpan={3}>
                                            <Loading />
                                        </td>
                                    </tr>
                                    :
                                    (accountList.length === 0 ?
                                        <tr>
                                            <td colSpan={3} className="text-center no-data-found">
                                                No data found
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {accountList.map((v, i) => {
                                                return (
                                                    <tr key={`tr-account-${i}`}>
                                                        <td>{v.name}</td>
                                                        <td>{v.type}</td>
                                                        <td>
                                                            <ButtonWithLoadingOrIcon
                                                                className={`${v.accountID === formVal.account.accountID ? "no-fill-button" : "orange-button"} main-button-26`}
                                                                text={`${v.accountID === formVal.account.accountID ? "Selected Account" : "Select Account"}`}
                                                                onClickAction={() => {
                                                                    toogleModalAccount();
                                                                    toogleModalLocation(true);
                                                                    setTemporarySelectedAccount(v);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                            {isLoadingMoreAccount &&
                                                <tr>
                                                    <td colSpan={3} className="more-accounts">
                                                        <div className="lds-ring">
                                                            <div /><div /><div /><div />
                                                        </div>

                                                        <b>More Accounts</b>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    let modalSelectLocation = () => {
        return (
            <Modal toggle={toogleModalLocation} isOpen={showModalLocation} className="modal-select-location-google-profile">
                <ModalBody>
                    <p className="modal-header">
                        <div
                            className="back-to-select-account"
                            onClick={() => {
                                toogleModalLocation(true);
                                toogleModalAccount();
                            }}
                        >
                            <b>
                                <FiChevronLeft />
                                Back to Select Account <span className="grey-font">({temporarySelectedAccount.name !== "" ? temporarySelectedAccount.name : formVal.account.name})</span>
                            </b>
                        </div>

                        <b className="modal-header-title">Select Location</b>

                        <FiX className="close-modal-select-location" onClick={() => toogleModalLocation(false)} />

                        <SearchBox
                            onSubmitSearch={getLocationList}
                            onChangeInputSearch={(v) => {
                                setSearchVal(v);
                            }}
                            searchValue={searchVal}
                            placeholder="Search for location name"
                            isWaitResponse={loadingGetLocation}
                            // clearInside
                            onClearSearch={() => getLocationList(true, false)}
                        />
                    </p>

                    <div className="table-modal-select-location-wrapper" onScroll={(e) => onScrollRun(e)}>
                        <table>
                            <thead>
                                <tr>
                                    <td><b>Location ID</b></td>
                                    <td><b>Location Name</b></td>
                                    <td><b>Location Address</b></td>
                                    <td></td>
                                </tr>
                            </thead>

                            <tbody>
                                {loadingGetLocation ?
                                    <tr>
                                        <td colSpan={4}>
                                            <Loading />
                                        </td>
                                    </tr>
                                    :
                                    (locationList.length === 0 ?
                                        <tr>
                                            <td colSpan={4} className="text-center no-data-found">
                                                No data found
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {locationList.map((v, i) => {
                                                return (
                                                    <>
                                                        <tr key={`tr-location-${i}`}>
                                                            <td>{v.locationID}</td>
                                                            <td>{v.name}</td>
                                                            <td>
                                                                <a href={v.mapsUri} target="_blank" rel="noopener noreferrer">
                                                                    {v.fullAddress}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {isLoadingCheckLocation === i ?
                                                                    <ButtonWithLoadingOrIcon
                                                                        className={`${v.locationID === formVal.location.locationID ? "no-fill-button" : "orange-button"} main-button-26`}
                                                                        text={`${v.locationID === formVal.location.locationID ? "Selected Location" : "Select Location"}`}
                                                                        position="left"
                                                                        isLoading
                                                                        isDisabled
                                                                        loadingColor="gray"
                                                                    />
                                                                    :
                                                                    <ButtonWithLoadingOrIcon
                                                                        className={`${v.locationID === formVal.location.locationID ? "no-fill-button" : "orange-button"} main-button-26`}
                                                                        text={`${v.locationID === formVal.location.locationID ? "Selected Location" : "Select Location"}`}
                                                                        onClickAction={() => {
                                                                            if (isLoadingCheckLocation === -1) {
                                                                                checkLocationExist(v, i)
                                                                            }
                                                                        }}
                                                                        isDisabled={isLoadingCheckLocation !== -1 && isLoadingCheckLocation !== i}
                                                                    />
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}

                                            {isLoadingMore &&
                                                <tr>
                                                    <td colSpan={4} className="more-locations">
                                                        <div className="lds-ring">
                                                            <div /><div /><div /><div />
                                                        </div>

                                                        <b>More Locations</b>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    let toggleDiscard = () => {
        setShowDiscardLocation(!showDiscardLocation);
    }

    let discardProgress = () => {
        setTemporarySelectedAccount({
            accountID: "",
            name: "",
            type: ""
        });
        setShowDiscardLocation(false);
        setShowModalLocation(false);

        if (formVal.account.accountID !== "") {
            getLocationList(false, true);
        }
    }

    return (
        <div className="integration-outer-wrapper">
            {modalSelectAccount()}
            {modalSelectLocation()}

            <PopupRemove
                removeTitle={'Discard Progress?'}
                removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
                onClickCancel={toggleDiscard}
                onClickRemove={discardProgress}
                showModalProps={showDiscardLocation}
                submitText="Discard Changes"
                colorButton="red-button"
            />

            <PopupConfirmation
                isOpen={isShowModalContinueWithGoogle}
                toggle={toogleModalContinueWithGoogle}
                title="Continue with Google"
                description="You will be redirected to Google where you can add an account for this Channel"
                className="modal-continue-with-google"
            >
                <ButtonWithLoadingOrIcon
                    position="left"
                    icon={{
                        type: "img",
                        src: iconGoogleButton
                    }}
                    text="Connect with Google"
                    onClickAction={connectTo}
                    className="main-button-48 no-fill-black-button"
                />
            </PopupConfirmation>

            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={JSON.stringify(formValOrigin)}
                dataAfterChange={JSON.stringify(formVal)}
            >
                {/* <Integrations activeIntegrationChannel="Facebook" parentProps={props} orgIDProps={orgID} /> */}
                <div className="sectionWrap integration-main-container integration-google-profile-add-account text-left">
                    {isLoadingGetApp || retriveDataError.code ?
                        <RetrieveDataLoading
                            isLoading={isLoadingGetApp}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <>
                            <BackButton text="Back to Google Business Profile" onClick={back} />

                            <div className="integration-google-profile-add-account-box">
                                <div className="integration-google-profile-add-account-box-top">
                                    <b>Add Channel</b>

                                    {isConnected &&
                                        (!isLoadingSubmit ?
                                            <ButtonWithLoadingOrIcon
                                                isDisabled={!isFormValid()}
                                                onClickAction={onSubmit}
                                                className="orange-button main-button-40"
                                                text="Create Channel"
                                                position="left"
                                            />
                                            :
                                            <ButtonWithLoadingOrIcon
                                                isLoading
                                                loadingColor="gray"
                                                isDisabled
                                                className="orange-button main-button-40"
                                                text="Create Channel"
                                                position="left"
                                            />
                                        )
                                    }
                                </div>

                                <div className="top-component">
                                    <p className="top-desc">
                                        Manage your Google Business review efficiently with OneTalk.
                                        Connect to OneTalk using a Google account that has access to manage Google Business Profile.
                                        To learn more regarding Google My Business Integration, you can check this <a target="_blank" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/google-business-profile-integration" rel="noopener noreferrer">Documentation.</a>
                                    </p>

                                    <div className="facebook-component">
                                        {/* <p>
                                            Ensure that you have requirement below to connect with your Facebook Messenger channel.
                                        </p> */}

                                        {/* <ul>
                                            <li>
                                                An <a target="_blank" href="https://help.facebook.com/502981923235522" rel="noopener noreferrer">Facebook Business Account</a> or <a href="https://help.facebook.com/2358103564437429">Facebook Creator Account</a>
                                            </li>
                                            <li>
                                                A <a target="_blank" href="https://help.facebook.com/399237934150902" rel="noopener noreferrer">Facebook Page Connected</a> to your Facebook account
                                            </li>
                                        </ul> */}

                                        {!isConnected ?
                                            (!isLoadingGetApp ?
                                                <ButtonWithLoadingOrIcon
                                                    position="left"
                                                    icon={{
                                                        type: "img",
                                                        src: iconGoogleButton
                                                    }}
                                                    text="Connect with Google"
                                                    onClickAction={toogleModalContinueWithGoogle}
                                                    className="main-button-48 no-fill-black-button google-button"
                                                />
                                                :
                                                <ButtonWithLoadingOrIcon
                                                    position="left"
                                                    isLoading
                                                    loadingColor="gray"
                                                    className="main-button-48 no-fill-black-button google-button"
                                                />
                                            )
                                            :
                                            <ButtonWithLoadingOrIcon
                                                icon={{
                                                    type: "img",
                                                    src: iconGoogleButton
                                                }}
                                                position="left"
                                                text={`Logout`}
                                                onClickAction={logoutFrom}
                                                className="main-button-48 no-fill-black-button google-button"
                                            />
                                        }
                                    </div>
                                </div>

                                {isConnected &&
                                    <div className="integration-google-profile-add-account-box-form">
                                        {formVal.location.locationID === "" ?
                                            <>
                                                <label><b>Business Profile</b></label>
                                                <p className="select-business-profile" onClick={toogleModalAccount}>
                                                    <b><FiEdit /> Select Business Profile</b>
                                                </p>
                                            </>
                                            :
                                            <label className="margin-0"><b>Business Account Details</b></label>
                                        }

                                        {formVal.accountLocationError !== "" &&
                                            <ErrorOrInfoComp
                                                text={formVal.accountLocationError}
                                                _className={"font-red"}
                                                icon={<FiInfo />}
                                            />
                                        }

                                        {formVal.account.accountID !== "" &&
                                            <>
                                                <p className="selected-label" onClick={toogleModalAccount}>
                                                    <FiUserCheck />
                                                    <b>Account Name</b>
                                                    <FiEdit className="selected-label-edit" />
                                                </p>
                                                <p className="selected-title">
                                                    {formVal.account.name}
                                                </p>
                                            </>
                                        }

                                        {formVal.location.locationID !== "" &&
                                            <>
                                                <p className="selected-label" onClick={toogleModalLocation}>
                                                    <FiMapPin />
                                                    <b>Location Details</b>
                                                    <FiEdit className="selected-label-edit" />
                                                </p>
                                                <p className="selected-title">
                                                    {formVal.location.name}
                                                </p>
                                                <p className="selected-address">
                                                    {formVal.location.fullAddress}
                                                </p>
                                            </>
                                        }

                                        <label><b>Channel Name</b></label>
                                        <div className="input-wrap">
                                            <span className={`font-grey ${formVal.channelNameError === "Characters exceed limit" ? "red-text" : ""}`}>
                                                {formVal.channelName.length}/30 characters
                                            </span>

                                            <input
                                                type="text"
                                                placeholder="Input channel name"
                                                className={`input-channel-name ${formVal.channelNameError === "" ? "" : "border-red"}`}
                                                value={formVal.channelName}
                                                onChange={(e) => {
                                                    let _formVal = { ...formVal };
                                                    _formVal.channelName = e.target.value;
                                                    _formVal.channelNameError = "";
                                                    setFormVal(_formVal);
                                                }}
                                                disabled={isLoadingSubmit}
                                            />
                                        </div>
                                        {formVal.channelNameError !== "" &&
                                            <ErrorOrInfoComp
                                                text={formVal.channelNameError}
                                                _className={"font-red"}
                                                icon={<FiInfo />}
                                            />
                                        }

                                        <label><b>Assigned Topic</b></label>
                                        <CustomSelectOption
                                            optionListProps={topicList}
                                            valueDropdownProps={formVal.topic}
                                            placeholderProps={"Select topic"}
                                            onClickDropDownListOptionProps={onSelectTopic}
                                            specificClassNameProps="custom-select-google-profile-topic"
                                            dropdownError={formVal.topicEror}
                                            isDisabled={isLoadingSubmit}
                                        />
                                        <ErrorOrInfoComp
                                            text={formVal.topicEror === "" ? "Incoming messages on this channel will be assigned to this topic." : formVal.topicEror}
                                            _className={`${formVal.topicEror === "" ? "" : "font-red"}`}
                                            icon={<FiInfo />}
                                        />
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            </SectionWrap>
        </div>
    )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    setMediumLastRun
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleProfileAddAccount);