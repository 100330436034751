import React, { useEffect, useState } from "react";
import TDTopicForm from "./TDTopicForm";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import { doToast } from "../../helper/HelperGeneral";
import mixpanel from "mixpanel-browser";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiTrash2 } from "react-icons/fi";
import PopupRemove from "../reuseableComponent/popupRemove/PopupRemove";
import topicServices from "../../services/newServices/TopicServices";

const TDTopic = props => {
  const { detailsData: data } = props;
  const { retriveDataError: error } = props;
  const { isLoadingTopic: loadingTopic } = props;
  const { backToTopic: back } = props;

  const [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  const [showPopupRemove, setShowPopupRemove] = useState(false);
  
  const viewForm = data ? <TDTopicForm {...props} /> : <RetrieveDataLoading isLoading={loadingTopic} errorMessage={error.message} />;

  let toggleModalRemoveTopic = () => {
    if(!waitForResponseDelete) {
      setShowPopupRemove(!showPopupRemove);
    }
  }

  let deleteTopic = () => {
    setWaitForResponseDelete(true);

    topicServices.delTopic(parseInt(props.detailsData.topic.id), props.detailsData.topic.createdTime, props.orgID, (response) => {
      if (response.dataResult.status === 200) {
        if(response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
  
          setTimeout(() => {
            back()
          }, 3000)
        }else {
          setWaitForResponseDelete(false);
          doToast(response.dataResult.data.message, "fail");
        }
      } else {
        doToast(response.dataResult.error.message, "fail");
        setWaitForResponseDelete(false);
      }
    })
  }

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (props.orgID) {
      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Topic Details",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: props.orgID.split("-")[0]
          }
        );
      }
    }
  }, [props.orgID])

  return (
    <div className="topic sectionWrap">
      <PopupRemove
        removeTitle={`Remove topic?`}
        removeDescription={`This topic will be removed from this organization`}
        onClickCancel={() => toggleModalRemoveTopic(false)}
        onClickRemove={() => deleteTopic()}
        waitForResponse={waitForResponseDelete}
        showModalProps={showPopupRemove}
        colorButton="red-button"
        waitForResponseDelete={waitForResponseDelete}
      />

      <div className="topContent bordered topContent-topic-detail">
        <div className="title title-topic-details">
          Topic Details

          <ButtonWithLoadingOrIcon
            className="red-button button-delete-topic main-button-40"
            text="Delete Topic"
            icon={{
              src: FiTrash2,
              type: "svg"
            }}
            position="left"
            onClickAction={() => {
              toggleModalRemoveTopic();
            }}
          />
        </div>
      </div>
      <br />
      {viewForm}
    </div>
  );
};

export default TDTopic;
