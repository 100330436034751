import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FiAlignLeft, FiEdit, FiInfo, FiUser, FiTrash2 } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import "./AccountProfile.scss";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import Loading from "../../../../reuseableComponent/loading/Loading";
import { checkID, doToast } from "../../../../../helper/HelperGeneral";
import HelperUpload from "../../../../../helper/HelperUpload";
import HelperInput from "../../../../../helper/HelperInput";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";

let AccountProfile = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [channelDetail, setChannelDetail] = useState(false);
  let [accountDetail, setAccountDetail] = useState({});
  let [channelID, setChannelID] = useState();
  let [isLoadingUpload, setIsLoadingUpload] = useState(false);
  let [isLoadingRemove, setIsLoadingRemove] = useState(false);
  let [initProfilePhoto, setInitProfilePhoto] = useState("");
  let [profilePhoto, setProfilePhoto] = useState("");
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [phoneVal, setPhoneVal] = useState({
    value: "",
    label: ""  
  });
  let [phoneNumberList, setPhoneNumberList] = useState(false);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
        getPhoneNumber();
    }
  }, [orgID]);

  useEffect(() => {
      if(phoneNumberList) {
        getAccountDetail(true, phoneNumberList[0].value);
      }
  }, [phoneNumberList])

  let getPhoneNumber = () => {
      setIsLoadingGetDetailData(true);
      let _retriveDataError = {...retriveDataError};

      IntegrationWhatsappBusinessServices.getPhoneNumber(orgID, {channelID: Number(match.params.id)}, (res) => {
          let _phone = [];
          
          if(res.dataResult.error.code !== "") {
            _retriveDataError.message = res.dataResult.error.message;
            _retriveDataError.code = res.dataResult.error.code;
            setRetrieveDataError(_retriveDataError);
            setIsLoadingGetDetailData(false);
          }else {
            if(res.dataResult.data.items === null) {
              _retriveDataError.message = "Phone number not found";
              _retriveDataError.code = 200;
              setRetrieveDataError(_retriveDataError);
              setIsLoadingGetDetailData(false);
            }else {
                res.dataResult.data.items.map(v => {
                    v.label = v.phoneNumber.split("")[0] === "+" ? "+"+HelperInput.phoneBeautify(v.phoneNumber) : "+"+HelperInput.phoneBeautify(v.phoneNumber);
                    v.value = v.id;
                    _phone.push(v);

                    return null;
                })

                setPhoneVal(_phone[0]);
                setPhoneNumberList(_phone);
            }
          }
      });
  }

  let getAccountDetail = (loading = true, phone) => {
    if(loading) setIsLoadingGetDetailData(true);

    let data = {
      id: Number(match.params.id),
      phoneNumberID: phone
    };

    IntegrationWhatsappBusinessServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setChannelDetail(response.dataResult.data.channel);
        setAccountDetail(response.dataResult.data.settings.profile);
        setInitProfilePhoto(
            response.dataResult.data.settings.profile.photoLink
        );
        setChannelID(response.dataResult.data.channel.id);
        setProfilePhoto(response.dataResult.data.settings.profile.photoLink);
        setIsLoadingUpload(false);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let uploadPhoto = (photo) => {
    setIsLoadingUpload(true);
    var formData = new FormData();
    formData.append("id", channelID);
    formData.append("photo", photo);
    formData.append("phoneNumberID", phoneVal.value);
    
    IntegrationWhatsappBusinessServices.uploadPhoto(
      orgID,
      formData,
      (response) => {
        if (response.dataResult.error.message === "") {
          doToast(response.dataResult.data.message);
          getAccountDetail(false, phoneVal.value)
        } else {
          setProfilePhoto(initProfilePhoto);
          doToast(response.dataResult.error.message, "fail");
          setIsLoadingUpload(false);
        }
      }
    );
  };

  let removePhoto = () => {
    setIsLoadingRemove(true);
    if (initProfilePhoto !== profilePhoto) {
      setProfilePhoto("");
      setIsLoadingRemove(false);
    } else {
      let data = {
        id: channelID,
        photoLink: profilePhoto,
      };
      IntegrationWhatsappBusinessServices.deletePhoto(
        orgID,
        data,
        (response) => {
          if (response.dataResult.error.message === "") {
            setProfilePhoto("");
            doToast(response.dataResult.data.message);
          } else {
            doToast(response.dataResult.error.message, "fail");
          }
          setIsLoadingRemove(false);
          toggleModalRemove()
        }
      );
    }
  };

  function readURL(input) {
    let validImageTypes = ["image/jpeg", "image/png"];
    if (input.files && input.files[0]) {
      if (validImageTypes.includes(input.files[0]["type"])) {
        var reader = new FileReader();

        reader.onload = function(e) {
          HelperUpload.checkFileSizeLimit(input.files, {
            onReachLimit: (message) => {
              doToast("File size limit is 5MB", "fail");
            },
            onAllowed: (files) => {
              setProfilePhoto(e.target.result);
              uploadPhoto(files[0]);
            }
          }, 5242880)
        };

        reader.readAsDataURL(input.files[0]);
      } else {
        doToast("Unsupported media type", "fail");
      }
    }
  }

  useEffect(() => {
    let inputUploadPhoto = document.getElementById("upload-profile-photo");
    if (inputUploadPhoto) {
      inputUploadPhoto.onchange = function() {
        readURL(this);
      };
    }
  });

  let [showModalRemove, setShowModalRemove] = useState(false)
  let toggleModalRemove = () => {
      setShowModalRemove(!showModalRemove)
  }
  let modalRemove = () => {
      return(
        <Modal isOpen={showModalRemove} toggle={toggleModalRemove} className={"modal-remove-photo"}>
          <ModalBody>
            <p className="modal-remove-photo-title">
              <b>Remove Photo?</b>
            </p>
            <p>Are you sure you want to remove your account photo?</p>
          </ModalBody>
          <ModalFooter>
            <button className="no-fill-button" onClick={toggleModalRemove}>
              <b>Cancel</b>
            </button>
            
            {isLoadingRemove ?
              <ButtonWithLoadingOrIcon 
                className="red-button main-button-48"
                text="Remove Photo"
                isLoading
                loadingColor="gray"
                isDisabled
                position="left"
              />
              :
              <ButtonWithLoadingOrIcon 
                className="red-button main-button-48"
                text="Remove Photo"
                onClickAction={removePhoto}
              />
            }
          </ModalFooter>
        </Modal>
      )
  }

  let onSelectPhone = (val) => {
    getAccountDetail(true, val.value);
    setPhoneVal(val);
  }

  return (
    <div className="account-profile-whatsapp sectionWrap">
      {modalRemove()}
      {isLoadingGetDetailData || retriveDataError.code ? (
        <div className="integration-detail-loading-wrapper">
          <RetrieveDataLoading
            isLoading={isLoadingGetDetailData}
            errorMessage={retriveDataError.message}
          />
        </div>
      ) : (
        <>
          <div className="account-profile-header">
            <div className="title-wrapper">
              <b>Account Profile</b>
              <p>This is displayed on your WhatsApp profile.</p>
            </div>
          </div>
          <div className="account-profile-content">
            <label>
              <b>Phone Number</b>
            </label>

            <CustomSelectOption
                isDisabled={false}
                optionListProps={phoneNumberList}
                valueDropdownProps={phoneVal}
                placeholderProps={"Select Phone Number"}
                onClickDropDownListOptionProps={onSelectPhone}
                specificClassNameProps="custom-select-phonenumber"
            />

            <div className="account-profile-content-wrapper">
              <div className="label-wrapper">
                <FiUser />
                <label>
                  <b>Account Photo</b>
                </label>
              </div>
              <div className="photo-wrapper">
                    {profilePhoto ? (
                      <img src={profilePhoto} alt="" />
                    ) : (
                      <div className="profile-photo-empty">
                        <FiUser />
                      </div>
                    )}
              </div>
              <div className="info-wrapper">
                <FiInfo />
                {profilePhoto ? (
                  <b>This is the photo that is displayed on your WhatsApp.
                      <br></br>PNG or JPG. 5MB max with a minimum of 192x192px.
                  </b>
                ) : (
                  <b>You have no photo uploaded. 
                      <br></br>PNG or JPG. 5MB max with a minimum of 192x192px.
                  </b>
                )}
              </div>

              {/* {!channelDetail.isCloud && */}
                  <div className="button-wrapper">
                    {(profilePhoto && !isLoadingUpload) ? (
                      <div>
                        <label
                          className={
                            "no-fill-button main-button-40" +
                            (isLoadingUpload ||
                            isLoadingRemove
                              ? " disabled"
                              : "")
                          }
                        >
                          <FiEdit />
                          Change Photo

                          <input
                            id="upload-profile-photo"
                            type="file"
                            accept="image/*"
                            hidden
                            disabled={isLoadingUpload || isLoadingRemove}
                          />
                        </label>
                        
                        {!channelDetail.isCloud &&
                          <ButtonWithLoadingOrIcon
                            className="no-fill-button-red main-button-40"
                            text="Remove Photo"
                            position="left"
                            icon={{
                              type: "svg",
                              src: FiTrash2,
                            }}
                            onClickAction={toggleModalRemove}
                            isDisabled={
                              isLoadingUpload || isLoadingRemove
                            }
                          />
                        }
                      </div>
                    ) : (
                      <label
                        className={
                          "no-fill-button main-button-40" +
                          (isLoadingUpload ? " disabled" : "")
                        }
                      >
                        {isLoadingUpload ? (
                            <Loading color={"gray"} />
                        ) : (
                          <FiEdit />
                        )}
                        <div>{isLoadingUpload ? "Uploading" : "Upload Photo"}</div>
                        <input
                          id="upload-profile-photo"
                          type="file"
                          accept="image/*"
                          hidden
                          disabled={isLoadingUpload || isLoadingRemove}
                        />
                      </label>
                    )}
                  {/* <ButtonWithLoadingOrIcon
                        className="no-fill-button  main-button-40"
                        text="Edit Photo"
                        position="left"
                        icon={{
                            type: "svg",
                            src: FiEdit
                        }}
                        onClickAction={() => history.push(`/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}/edit-account-profile/edit-photo`)}
                    /> */}
                </div>
              {/* } */}
            </div>
            
            {!channelDetail.isCloud && 
              <div className="account-profile-content-wrapper">
                <div className="label-wrapper">
                  <FiAlignLeft />
                  <label>
                    <b>About</b>
                  </label>
                </div>
                <div className="text-wrapper">
                  <p>{accountDetail.aboutText === "" ? "-" : accountDetail.aboutText}</p>
                </div>

                
                <div className="button-wrapper">
                  <NavLink to={`/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}/${phoneVal.value}/edit-account-profile`}>
                    <ButtonWithLoadingOrIcon
                      className="no-fill-button main-button-40"
                      text="Edit About"
                      position="left"
                      icon={{
                        type: "svg",
                        src: FiEdit,
                      }}
                      style={{marginTop: "12px"}}
                    />
                  </NavLink>
                </div>
              </div>
            }
          </div>
        </>
      )}
    </div>
  );
};

export default AccountProfile;
