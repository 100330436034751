import React from "react";
import "./FormInput.scss";

const FormInput = ({
  type,
  error,
  placeholder,
  label,
  icon,
  className,
  classNameInput,
  isDisabled,
  ...props
}) => {
  const classNamed = `input-wrapper input-wrapperr ${className || ""}`;
  const inType = type || "text";
  const allProps = { type: inType, placeholder, ...props };

  return (
    <div className={classNamed}>
      {label && <label>{label}</label>}
      <input autoComplete={"off"} disabled={isDisabled} {...allProps} style={{ margin: "0" }} className={classNameInput} />
      {icon && <span className="input-icon">{icon}</span>}
      {error && <div className="input-error">{error}</div>}
    </div>
  );
};

export default FormInput;
