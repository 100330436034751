import React, { useState, useEffect } from 'react';
import './IntegrationKataaiEditDetail.scss';
import { FiInfo } from 'react-icons/fi';
import IntegrationKataaiServices from '../../../../services/integration/IntegrationKataaiServices';
import topicServices from '../../../../services/newServices/TopicServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
// import CustomSelectOption from '../../../reuseableComponent/customSelectOption/CustomSelectOption';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

let IntegrationKataaiEditDetail = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [topicList, setTopicList] = useState([]);
  let [topicListOrigin, setTopicListOrigin] = useState([]);
  let [isCheckAll, setIsCheckAll] = useState(false);
  let [editData, setEditData] = useState({
    channelName: "",
    topic: []
  })
  let [editDataOrigin, setEditDataOrigin] = useState({
    channelName: "",
    topic: []
  })
  let [searchTopic, setSearchTopic] = useState("");
  let [errorMessageData, setErrorMessageData] = useState({
    channelName: "",
    topic: ""
  })

  console.log("editData", editData)
  console.log("editDataOrigin", editDataOrigin)

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getTopics(orgID);
    }
  }, [orgID]);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getAccountDetail(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getAccountDetail = (orgID) => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: parseInt(props.match.params.accountID)
    }

    IntegrationKataaiServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        let _editData = { ...editData };
        let _editDataOrigin = { ...editDataOrigin };

        if (response.dataResult.data.channel.topics.length > 0) {
          response.dataResult.data.channel.topics.map((v) => {
            v.value = v.id;
            return null;
          });

          _editData.channelName = response.dataResult.data.channel.channelName;
          _editData.topic = response.dataResult.data.channel.topics;
          _editDataOrigin.channelName = response.dataResult.data.channel.channelName;
          _editDataOrigin.topic = response.dataResult.data.channel.topics;
          setEditData(_editData);
          setEditDataOrigin(_editDataOrigin);
        }
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let getTopics = async (orgID) => {
    topicServices.getTopic(orgID, 'list', null, (response) => {
      let data = response.dataResult.data.topics;
      let createList = () => {
        let newData = [];
        data.map((val) => {
          let _data = {};
          _data.id = val.id;
          _data.name = val.name;
          newData.push(_data);
          return null;
        });
        setTopicList(newData);
        setTopicListOrigin(newData);
      };

      createList();
    });
  };

  let editAccount = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    if (editData.name === "") {
      _errorMessageData.name = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-name" }
    }

    if (editData.channelName.length > 50) {
      _errorMessageData.channelName = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-name" }
    }

    if (editData.topic.length === 0) {
      _errorMessageData.topic = "Please select topic";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-topic" }
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      setIsLoadingSubmit(true);

      let topics = [];

      editData.topic.map((v) => {
        topics.push(v.id);
        return null;
      })

      let data = {
        id: parseInt(props.match.params.accountID),
        channelName: editData.channelName,
        topicIDs: topics
      }

      IntegrationKataaiServices.editDetail(
        orgID,
        data,
        (response) => {
          if (response.dataResult.error.message === "") {
            if (!response.dataResult.data.success) {
              doToast(response.dataResult.data.message, "fail");
              setIsLoadingSubmit(false);
            } else {
              if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Edit Detail",
                  {
                    channel: "Kata.ai",
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    organizationID: orgID.split("-")[0]
                  }
                );
              }

              setEditDataOrigin(editData);

              setTimeout(() => {
                history.push(`/o/${orgID}/integration/kataai/detail/${match.params.accountID}`);
              }, 3000)
              doToast(response.dataResult.data.message);
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setIsLoadingSubmit(false);
          }
        }
      );
    }
  };

  // let onSelectTopic = (value) => {
  //   setAccountTopicVal(value);
  // };

  // let isFormValid = () => {
  //   // let _accountTopicVal = accountTopicVal.value;
  //   return (
  //     selectedTopic.length !== 0 && (JSON.stringify(selectedTopic) !== JSON.stringify(selectedTopicOrigin))
  //   );
  // };

  let back = () => history.push(`/o/${orgID}/integration/kataai/detail/${props.match.params.accountID}`);

  let onChangeSingleSelect = (e, val) => {
    if (!isLoadingSubmit) {
      let _editData = { ...editData };
      let _errorMessageData = { ...errorMessageData };
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);

      if (e.target.checked) {
        _editData.topic.push(val);
      } else {
        let findIndex = _editData.topic.findIndex(v => v.id === val.id);

        if (findIndex !== -1) {
          _editData.topic.splice(findIndex, 1);
        }
      }

      setEditData(_editData);
    }
  }

  let onClearAll = () => {
    let _editData = { ...editData };
    if (!isLoadingSubmit) {
      setIsCheckAll(false);

      _editData.topic = [];
      setEditData(_editData);
    }
  }

  let onCheckAll = (e) => {
    if (!isLoadingSubmit) {
      let _editData = { ...editData };
      let _errorMessageData = { ...errorMessageData };
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);

      if (e.target.checked) {
        _editData.topic = topicList.slice();
      } else {
        _editData.topic = [];
      }

      setIsCheckAll(e.target.checked);
      setEditData(_editData);
    }
  }

  let isChecked = (id) => {
    return editData.topic.findIndex(val => val.id === id) !== -1;
  }

  let removeSingleTopic = (val) => {
    setIsCheckAll(false);
    let _editData = { ...editData };

    let findIndex = _editData.topic.findIndex(v => v.id === val.id);

    if (findIndex !== -1) {
      _editData.topic.splice(findIndex, 1);
    }

    setEditData(_editData);
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
      for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
          if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
          }
        }
      }
    }

    setTopicList(result);
  }

  let onChangeText = (e) => {
    let _editData = { ...editData };
    let _errorMessageData = { ...errorMessageData };

    _editData.channelName = e.target.value;
    _errorMessageData.channelName = "";

    if (e.target.value.length > 50) {
      _errorMessageData.channelName = "Characters exceed limit";
    }

    setEditData(_editData);
    setErrorMessageData(_errorMessageData);
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify(editDataOrigin)}
        dataAfterChange={JSON.stringify(editData)}
      >
        <div className="sectionWrap integration-main-container integration-kataai-edit-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <>
              <BackButton
                text={`Back to Channel Details`}
                onClick={() => back()}
              />

              <div className="integration-kataai-edit-detail-account-box">
                <div className="integration-kataai-edit-detail-account-box-top">
                  <b>Edit Channel Details</b>

                  {!isLoadingSubmit ? (
                    <ButtonWithLoadingOrIcon
                      // isDisabled={!isFormValid()}
                      onClickAction={editAccount}
                      className="orange-button main-button-40 minWidth180"
                      text="Save Changes"
                      position="left"
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      className="orange-button main-button-40 minWidth180"
                      text="Save Changes"
                      position="left"
                    />
                  )}
                </div>
                <div className="integration-kataai-edit-detail-account-box-form">
                  <div className="form-detail">
                    <label>
                      <span className={errorMessageData.channelName === "Characters exceed limit" ? "red-text" : "grey-font"}>{editData.channelName.length}/50 characters</span>
                      <b>Channel Name</b>
                    </label>
                    <input type="text" value={editData.channelName} onChange={(e) => { onChangeText(e) }} className={`input-name ${errorMessageData.channelName === "" ? "" : "border-red"}`} placeholder="Input channel name" disabled={isLoadingSubmit} />

                    {errorMessageData.channelName !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.channelName}
                        _className={"font-red"}
                        icon={<FiInfo />}
                      />
                    }

                    <SelectTopic
                      onChangeSearchTopic={onChangeSearchTopic}
                      onCheckAll={onCheckAll}
                      onClearAll={onClearAll}
                      topicList={topicList}
                      onChangeSingleSelect={onChangeSingleSelect}
                      isChecked={isChecked}
                      isCheckAll={isCheckAll}
                      searchTopic={searchTopic}
                      title={"Topic"}
                      selectedTopic={editData.topic}
                      removeSingleTopic={removeSingleTopic}
                      _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                      isDisabled={isLoadingSubmit}
                    />

                    <ErrorOrInfoComp
                      text={errorMessageData.topic === "" ? "Incoming messages on this channel will be assigned to this topic." : errorMessageData.topic}
                      _className={errorMessageData.topic !== "" ? "font-red" : ""}
                      icon={<FiInfo />}
                    />

                    {/* <p className="input-info-text no-margin-bottom width-100">
                      <FiInfo />
                      <b>Customer will be able to choose if you select more than one topics.</b>
                    </p> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationKataaiEditDetail;
