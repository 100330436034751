import React, { useEffect, useState } from "react";
import "./OnBoardingIntegrateChannel.scss";
import { FiHelpCircle, FiExternalLink, FiCheck, FiXCircle } from "react-icons/fi";
import { Modal, ModalBody } from "reactstrap";
// import IconInviteMember from "../../../../assets/img/onboarding/icon-invite-member.svg";
import MiniTaptalk from "../../../../assets/img/onboarding/mini-taptalk.svg";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IconTaptalk from "../../../../assets/img/integration/logo-taptalk.svg";
import IconTelegram from "../../../../assets/img/integration/logo-telegram.svg";
import IconWhatsappSme from "../../../../assets/img/integration/logo-whatsapp-sme.svg";
import OnboardingServices from "../../../../services/onboarding/OnboardingServices";
import { doToast } from "../../../../helper/HelperGeneral";

// const ON_BOARDING = "integrateChannel";

const ONBOARDING_INTEGRATION_LIST = [
    {
        label: "Paid",
        labelColor: "#FFB438",
        img: IconWhatsappSme,
        name: "WhatsApp SME",
        link: "https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/whatsapp-sme-integration"
    },
    {
        label: "Free",
        labelColor: "#19C700",
        img: IconTelegram,
        name: "Telegram",
        link: "https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/telegram-integration"
    },
    {
        label: "Free",
        labelColor: "#19C700",
        img: IconTaptalk,
        name: "Live Chat",
        link: "https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/live-chat"
    }
]

let OnBoardingIntegrateChannel = (props) => {
    let [onBoardingInviteMemberDone, setOnBoardingInviteMemberDone] = useState(false);
    let [isShowModalAssistance, setIsShowModalAssistance] = useState(false);
    let [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false)

    useEffect(() => {
        setOnBoardingInviteMemberDone(props.status)
    }, [props.status])

    let onClickMarkAsDone = () => {
        let _orgID = props.match.params.orgID.split("-")[0];
        
        let data = {
            itemID: props.id,
            isDone: true
        }
        updateStatus(data, _orgID)
    }

    let onClickMarkAsUndone = () => {
        let _orgID = props.match.params.orgID.split("-")[0];
        
        let data = {
            itemID: props.id,
            isDone: false
        }
        updateStatus(data, _orgID)
    }

    let updateStatus = (data, orgID) => {
        setLoadingUpdateStatus(true);
        OnboardingServices.postUpdateStatus(orgID, data, (response) => {
            let dataResult = response.dataResult;

            if(dataResult.error.code === "") {
                setOnBoardingInviteMemberDone(data.isDone);
                props.runLastChangeOnBoarding();
                if(data.isDone) props.completeCount + 1 === 5 && props.handleComplete()
            } else {
                doToast(dataResult.error.message, 'fail');
            }
            setLoadingUpdateStatus(false);
        })
    }

    let toggleModalAssistance = () => {
        setIsShowModalAssistance(!isShowModalAssistance);
    }

    let modalAssistance = () => {
        return (
            <Modal isOpen={isShowModalAssistance} toggle={toggleModalAssistance} className="modal-assistance">
                <ModalBody>
                    <p>
                        <FiHelpCircle />
                        <b>Request Assistance</b>
                    </p>

                    <ol>
                        <li>
                            Click on the widget <img src={MiniTaptalk} alt="Onetalk icon" /> on the bottom right corner widget.
                        </li>
                        <li>
                            Select the topic “Integrations”.
                        </li>
                        <li>
                            Send a message regarding the assistance you need and one of our agents will get back to you as soon as possible.
                        </li>
                    </ol>

                    <ButtonWithLoadingOrIcon
                        className="orange-button main-button-40 width-100"
                        text="Dismiss"
                        onClickAction={toggleModalAssistance}
                    />
                </ModalBody>
            </Modal>
        )
    }

    const onClickReadGuide = (link) => {
        window.open(link, "_blank", "noopener noreferrer")
    }

    return (
        <div className={`onboarding-child-wrapper onboarding-invite-member`}>
            {modalAssistance()}

            <div className="integration-wrapper">
                {ONBOARDING_INTEGRATION_LIST.map((value, index) => {
                    return (
                        <div key={`integration-${index}`} className="integration-card">
                            <div className="intergration-card-label" style={{ background: value.labelColor }}>
                                <b>{value.label}</b>
                            </div>

                            <br />

                            <img src={value.img} alt="Onetalk integration channel" className="intergration-card-image" />

                            <p className="intergration-card-name">
                                <b>{value.name}</b>
                            </p>

                            <ButtonWithLoadingOrIcon
                                className="orange-button main-button-40 width-100"
                                icon={{
                                    type: "svg",
                                    src: FiExternalLink
                                }}
                                text="Read Guide"
                                position="left"
                                onClickAction={() => onClickReadGuide(value.link)}
                            />
                        </div>
                    )
                })}
            </div>

            <p className="onboarding-description">
                Start by integrating at least one of the channels above. The one’s with the tag “Paid” requires a payment upfront
                whereas the one’s that has a “Free” tag is included with the OneTalk tier.
            </p>

            <div className="button-wrapper">
                {/* <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-40"
                    icon={{
                        type: "svg",
                        src: FiHelpCircle
                    }}
                    text="Request Assistance"
                    position="left"
                    onClickAction={toggleModalAssistance}
                /> */}

                {loadingUpdateStatus ? 
                 <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40 mark-as-undone"
                    text={onBoardingInviteMemberDone ? "Unmark as Done" : "Mark as Done"}
                    position="left"
                    isLoading
                    loadingColor="orange"
                />
                :
                 (onBoardingInviteMemberDone ?
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40 mark-as-undone"
                        icon={{
                            type: "svg",
                            src: FiXCircle
                        }}
                        text="Unmark as Done"
                        position="left"
                        onClickAction={onClickMarkAsUndone}
                        loadingColor="gray"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40"
                        icon={{
                            type: "svg",
                            src: FiCheck
                        }}
                        text="Mark as Done"
                        position="left"
                        onClickAction={onClickMarkAsDone}
                        loadingColor="gray"
                    />)
                }
            </div>
        </div>
    )
}

export default OnBoardingIntegrateChannel;