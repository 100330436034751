import React from "react";
import IntegrationAutoAssignChatbot from "../../integrations/integrationAutoMessage/integrationAutoAssignChatbot/IntegrationAutoAssignChatbot";

let AutoAssignChatbot = (props) => {
    return (
        <div className="integration-taptalk-auto-message-setup">
            <React.Fragment>
                <IntegrationAutoAssignChatbot
                    {...props}
                    back={() => {
                        props.history.push({
                            pathname: `/o/${props.match.params.orgID}/setup/general-assignments`,
                            state: {
                                tab: "idleCase"
                            }
                        })
                    }}
                    backToText="Back to General Assignments"
                    medium={"global"}
                />
            </React.Fragment>
        </div>
    )
}

export default AutoAssignChatbot;
