const INITIAL_STATE = [];
  
let ReduxReducerMyTopics = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SET_MY_TOPICS':
        return action.myTopics;
    case 'CLEAR_MY_TOPICS':
        return INITIAL_STATE;
    default:
        return state;
    }
};
  
export default ReduxReducerMyTopics;