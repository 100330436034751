import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
import "./PayPending.scss";
import { Modal, ModalBody } from "reactstrap";
import ManualPaymentVerificationModal from "./manualPaymentVerificationModal/ManualPaymentVerificationModal";
import Blocking401 from "../../../../reuseableComponent/blocking401/Blocking401";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import HelperDate from "../../../../../helper/HelperDate";
import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
import { checkID, rupiahFormat, doToast } from "../../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import BillingServices from '../../../../../services/newServices/BillingServices';
import mandiriIcon from "../../../../../assets/img/mandiri.png";
import iconCheckLargeGreen from "../../../../../assets/img/icon-check-large-green.svg";
import iconInfoLargeRed from "../../../../../assets/img/icon-info-large-red.svg";
import { FiInfo, FiCopy, FiRefreshCcw } from "react-icons/fi"; //FiRefreshCcw // FiAlertCircle
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

let PayPending = (props) => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingDetail, setIsLoadingDetail] = useState(true);
    let [detailInvoice, setDetailInvoice] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [isShowModalManualVerification, setIsShowModalManualVerification] = useState(false);

    let [isWaitRetryPayment, setIsWaitRetryPayment] = useState(false);
    let [forceHideRetryPayment, setForceHideRetryPayment] = useState(false);
    let [redirectPaymentTo, setRedirectPaymentTo] = useState(false);
    let [isShowModalPaymentSuccess, setIsShowModalPaymentSuccess] = useState(false);
    let [isShowModalPaymentFailed, setIsShowModalPaymentFailed] = useState(false);
    let [paymentSettings, setPaymentSettings] = useState(false);
    let [paymentFailedMessage, setPaymentFailedMessage] = useState("");

    let toggleModalPaymentSuccess = () => {
        setIsShowModalPaymentSuccess(!isShowModalPaymentSuccess);
    }

    let toggleModalManualVerification = () => {
        setIsShowModalManualVerification(!isShowModalManualVerification);
    }

    let copyToClip = () => {
        document.getElementById("account-mandiri").select();
        document.execCommand("copy");
        doToast("Account Number Copied");
    };

    let back = () => history.push(`/o/${orgID}/organization/billing/unpaid-invoices`)

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/billing`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getPaymentSetting = () => {
        setIsLoadingDetail(true);
        let _retriveDataError = {...retriveDataError};

        BillingServices.getPaymentSetting(match.params.orgID, (response) => {
            let dataResult = response.dataResult;
            
            if(dataResult.error.message === "") {
                setPaymentSettings(dataResult.data)
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
        })
    }        

    const getDetail = () => {
        let id = match.params.id;
        let runGetDetail = () => {
            // setIsLoadingDetail(true);

            let data = {
                id: parseInt(match.params.id)
            }

            BillingServices.getInvoiceDetail(orgID, data, (response) => {
                let _retriveDataError = {...retriveDataError};
    
                if (response.dataResult.error.message === "") {
                    setDetailInvoice(response.dataResult.data);
                } else {
                    _retriveDataError.message = response.dataResult.error.message;
                    _retriveDataError.code = response.dataResult.error.code;
                    setRetrieveDataError(_retriveDataError);
                    // doToast(response.dataResult.error.message, 'fail');
                }

                setIsLoadingDetail(false);
            });
        }

        if (id) {
            runGetDetail()
        } else {
            history.push(`/o/${orgID}/organization/billing`)
        }
    };

    const runRetryPayment = () => {
        setIsWaitRetryPayment(true);

        setTimeout(() => {
            let data = {
                id: parseInt(match.params.id),
                cardID: paymentSettings.primaryCardID
            };
    
            BillingServices.retryPayment(orgID, data, (response) => {
              let dataResult = response.dataResult;
              if (response.dataResult.status === 200) {
                if(dataResult.data.success) {
                    setForceHideRetryPayment(true);
                    getSubscriptionList();
                }else {
                    // getSubscriptionList();
                    // doToast(dataResult.data.message, "fail");
                    setPaymentFailedMessage(dataResult.data.message);
                    setIsShowModalPaymentFailed(true);
                    setIsWaitRetryPayment(false);
                }
    
              } else {
                // doToast(dataResult.error.message, "fail");
                setPaymentFailedMessage(dataResult.error.message);
                setIsShowModalPaymentFailed(true);
                setIsWaitRetryPayment(false);
              }
            
            });
        }, 2000)
    }

    const getSubscriptionList = () => {
        BillingServices.getUnpaidList(orgID, (response) => {
          let dataResult = response.dataResult;
    
          if (response.dataResult.status === 200) {
            if(dataResult.data.invoices.length > 0) {
                setRedirectPaymentTo("unpaid");
            }else {
                setRedirectPaymentTo("subscription");
            }
          } else {
            doToast(dataResult.error.message, "fail");
          }
    
          setIsWaitRetryPayment(false);
        });
    };

    useEffect(() => {
        if(redirectPaymentTo) {
            setIsWaitRetryPayment(false);
            toggleModalPaymentSuccess();
        }
    }, [redirectPaymentTo])

    let convertCardMasking = (cardNumber) => {
        return cardNumber.replace(/X/g, "<div class='masking-dot'></div>");
    }

    useEffect(() => {
        doMount();
    }, [location.pathname]);

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
        if(orgID) {
            // getDetail();
            if(process.env.REACT_APP_HIDE_BILLING === "false") { 
                getPaymentSetting();
    
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                        "[Page] Unpaid Invoice Detail", 
                        {
                            userFullName : myAgentData.account.fullName,
                            userID : myAgentData.account.id,
                            organizationID : orgID.split("-")[0]
                        }
                    );
                }
            }
        }
    }, [orgID])

    useEffect(() => {
        if(paymentSettings) {
            getDetail();
        }
    }, [paymentSettings])

    let modalLoadingRetryPayment = () => {
        return (
            <Modal className="modal-retry-payment" isOpen={isWaitRetryPayment}>
                <ModalBody> 
                    <div className="lds-ring">
                        <div /><div /><div /><div />
                    </div>
    
                    <p>
                        Retrying payment... Please make sure you have a stable connection.
                    </p>
                </ModalBody>
            </Modal>
        );
    }

    let modalSuccessPayment = () => {
        return (
            <Modal className="modal-success-payment" isOpen={isShowModalPaymentSuccess} toggle={onClickDismiss}>
                <ModalBody> 
                    <img src={iconCheckLargeGreen} alt="" />
    
                    <p>
                        Payment Success
                    </p>

                    <ButtonWithLoadingOrIcon 
                        className="orange-button main-button-40"
                        text="Dismiss"
                        onClickAction={onClickDismiss}
                    />
                </ModalBody>
            </Modal>
        );
    }

    let modalFailedPayment = () => {
        return (
            <Modal className="modal-success-payment" isOpen={isShowModalPaymentFailed} toggle={() => setIsShowModalPaymentFailed(false)}>
                <ModalBody> 
                    <img src={iconInfoLargeRed} alt="" />
    
                    <p>
                        {paymentFailedMessage}
                    </p>

                    <ButtonWithLoadingOrIcon 
                        className="orange-button main-button-40"
                        text="Dismiss"
                        onClickAction={() => setIsShowModalPaymentFailed(false)}
                    />
                </ModalBody>
            </Modal>
        );
    }

    let onClickDismiss = () => {
        if(redirectPaymentTo === "unpaid") {
            props.history.push(`/o/${orgID}/organization/billing/unpaid-invoices`);
        }else {
            props.history.push(`/o/${orgID}/organization/billing`)
        }
    }

    return (
        <React.Fragment>
            {process.env.REACT_APP_HIDE_BILLING === "true" ?
                <SectionWrap
                    {...props}
                    orgID={orgID}
                    withHeader
                    withSideMenu
                >
                    <Blocking401 {...props} />
                </SectionWrap>
                :
                <SectionWrap
                    {...props}
                    orgID={orgID}
                    withHeader
                    withSideMenu
                >   
                    {modalLoadingRetryPayment()}
                    {modalSuccessPayment()}
                    {modalFailedPayment()}

                    <BackButton text="Back to Unpaid Invoices" onClick={back} />
                    <ManualPaymentVerificationModal 
                        isOpen={isShowModalManualVerification}
                        toggle={toggleModalManualVerification}
                        {...props}
                    />

                    <div className="sectionWrap unpaid-invoices-detail-wrapper">
                        {isLoadingDetail || retriveDataError.code ?
                            <RetrieveDataLoading 
                                noPaddingTop={true}
                                isLoading={isLoadingDetail}
                                errorMessage={retriveDataError.message} 
                            />
                            :
                            <>
                                <div className="unpaid-invoices-detail-title">
                                    <b>{detailInvoice.invoice.invoiceNo}</b>
                                    
                                    <div className="button-top-wrapper">
                                        {/* <NavLink to={{
                                            pathname: `/o/${orgID}/organization/billing`,
                                            state: {
                                                whichTab: "paymentSettings"
                                            }
                                        }}>
                                            <button className="no-fill-button main-button-40">
                                                Change Payment Method
                                            </button>
                                        </NavLink> */}

                                        {(detailInvoice.lastPayment.card && !detailInvoice.lastPayment.isPending && !detailInvoice.lastPayment.isError && !forceHideRetryPayment) &&
                                            // (!isWaitRetryPayment ?
                                                <ButtonWithLoadingOrIcon 
                                                    className="orange-button main-button-40"
                                                    text="Retry Payment"
                                                    icon={{
                                                        type: "svg",
                                                        src: FiRefreshCcw
                                                    }}
                                                    position="left"
                                                    onClickAction={runRetryPayment}
                                                />
                                                // :
                                                // <ButtonWithLoadingOrIcon 
                                                //     isLoading
                                                //     loadingWhite
                                                //     className="orange-button main-button-40"
                                                //     text="Retry Payment"
                                                //     position="left"
                                                // />
                                            // )
                                        }

                                        {/* <button className="orange-button main-button-40" onClick={toggleModalManualVerification}>
                                            <FiAlertCircle />
                                            Instructions and Payment Verification
                                        </button> */}
                                    </div>
                                </div>

                                {(!detailInvoice.lastPayment.isPending && !forceHideRetryPayment) &&
                                    (detailInvoice.lastPayment.isError ?
                                        // Show lastPayment.errorMessage
                                        <div className="alert-component alert-failed">
                                            <FiInfo />
                                            <b>
                                                Failed payment on the {HelperDate.formatToString(new Date(detailInvoice.lastPayment.createdTime), "dd/MM/yyyy")}.
                                            </b>
                                            
                                            <br />
                                            <b>
                                                Reason: {detailInvoice.lastPayment.errorMessage}.
                                            </b>
                                        </div>
                                        :
                                        detailInvoice.lastPayment.channelCategory === "card" &&
                                            detailInvoice.lastPayment.status === "failed" &&
                                                // Show lastPayment.card.failureReasonDescription
                                                <div className="alert-component alert-failed">
                                                    <FiInfo />
                                                    <b>
                                                        Failed payment with <span dangerouslySetInnerHTML={{__html: convertCardMasking(detailInvoice.lastPayment.card.maskedCardNumber)}} /> on the {HelperDate.formatToString(new Date(detailInvoice.lastPayment.createdTime), "dd/MM/yyyy")}.
                                                    </b>
                                                    <br />
                                                    <b>Reason: {detailInvoice.lastPayment.card.failureReasonDesciption}.</b>
                                                </div>
                                    )  
                                }

                                <div className="invoice-payment-issue-due">
                                    <div>
                                        <b>Issue Date</b>
                                        <br />
                                        {HelperDate.formatToString(new Date(detailInvoice.invoice.issueTime), "d MMM yyyy")}
                                    </div>

                                    <div>
                                        <b>Due Date</b>
                                        <br />
                                        {HelperDate.formatToString(new Date(detailInvoice.invoice.dueTime), "d MMM yyyy")}
                                    </div>
                                </div>

                                <table className="no-hover">
                                    <thead>
                                        <tr>
                                            <td className="col_description"><b>Description</b></td>
                                            <td className="col_quantity"><b>Qty</b></td>
                                            <td className="col_price"><b>Unit Price</b></td>
                                            <td className="col_discount"><b>Discount</b></td>
                                            <td className="col_amount"><b>Amount</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detailInvoice.skuItems.map((value, key) => {
                                            return (
                                                <tr key={`sku-items-${key}`}>
                                                    <td className="col_description">
                                                        <b>{value.skuName}</b>
                                                        <br />
                                                        {value.note}
                                                    </td>
                                                    <td className="col_quantity">{value.quantity}</td>
                                                    <td className="col_price">{rupiahFormat(value.skuUnitPrice)}</td>
                                                    <td className="col_discount">{value.discountAmount === 0 ? "-" : value.discountAmount}</td>
                                                    <td className="col_amount">{rupiahFormat(value.subtotalAmount)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <div className="invoice-detail-footer">
                                    <div className="invoice-footer-right">
                                        <table className="no-hover">
                                            <tbody>
                                                {detailInvoice.footerItems.map((value, key) => {
                                                    return (
                                                        <tr key={`footer-invoice-${key}`}>
                                                            <td className={`col_left ${value.itemID === "subtotal_amount" ? "sub_total_amount" : ""}`}>
                                                                <b>{value.itemName}</b>
                                                            </td>
                                                            <td className="col_right">
                                                                {value.amount === 0 ? "-" : rupiahFormat(value.amount)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                                <tr className="col_footer_total">
                                                    <td className="col_left">
                                                        <b>Total</b>
                                                    </td>
                                                    <td className="col_right">
                                                        <b>{rupiahFormat(detailInvoice.invoice.totalAmount)}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    {/* {(!detailInvoice.lastPayment.card &&!detailInvoice.invoice.isPaid) && */}
                                        {(!detailInvoice.invoice.isPaid && !forceHideRetryPayment) &&
                                            <div className="pay-pending-bottom-wrapper">
                                                <p>
                                                    <b>Please Transfer to The Following Account</b>
                                                </p>

                                                <div className="pay-transfer-to">
                                                    <b>
                                                        {process.env.REACT_APP_TAPTALK_BANK_ACCOUNT_NAME_MANDIRI}
                                                    </b>

                                                    <div className="account-number-wrapper">
                                                        <img src={mandiriIcon} alt="" />
                                                        <input type="text"
                                                            value={process.env.REACT_APP_TAPTALK_BANK_ACCOUNT_NUMBER_MANDIRI}
                                                            // onClick={copyToClip}
                                                            id="account-mandiri"
                                                            readOnly
                                                        />
                                                        <FiCopy onClick={copyToClip} />
                                                    </div>

                                                    <div className="alert-component">
                                                        <FiInfo />
                                                        <b>
                                                            Once you have completed payment please send payment proof to sales@taptalk.io to confirm.
                                                            Please allow up to 24 hours for you payment to be verified
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                </div>
                            </>
                        }
                    </div>
                </SectionWrap>
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    allOrganizationDetail: state.allOrganizationDetail
});
  
export default connect(mapStateToProps, null)(PayPending);