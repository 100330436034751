import React, { useState, useEffect } from "react";
import "../../../auth/Auth.css";
import "../../QuickReplyAdd.scss";
import { FiInfo, FiUploadCloud, FiTrash2, FiFile } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceQuickReply from "../../../../services/newServices/QuickReplyService";
// import HelperInput from "../../../helper/HelperInput";
import HelperQuickReply from "../../../../helper/HelperQuickReply";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

let QuickReplyGeneralAdd = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [lastChangeMediaData, setLastChangeMediaData] = useState({
    lastChange: false,
    data: false
  });
  let [mediaData, setMediaData] = useState(null);
  let [mediaDataRaw, setMediaDataRaw] = useState(null);
  let [mediaDataType, setMediaDataType] = useState("");
  let [addQuickReplyData, setAddQuickReplyData] = useState({
      ownerType: HelperQuickReply.ownerTypeGeneral,
      message: "",
      shortcut: "",
      messageType: "",
      mediaFileID: null
  });
  let [addQuickReplyDataOrigin, setAddQuickReplyDataOrigin] = useState({
      ownerType: HelperQuickReply.ownerTypeGeneral,
      message: "",
      shortcut: "",
      messageType: "",
      mediaFileID: null
  });
  let [isWaitResponseAddQuickReply, setIsWaitResponseAddQuickReply] = useState(false);
  let [quickReplyType, setQuickReplyType] = useState("text");
  let [loadingUpload, setLoadingUpload] = useState(false);
  let [errorMessageData, setErrorMessageData] = useState({
    file: "",
    message: "",
    shortcut: ""
  })

  let back = () => {
    history.push(`/o/${orgID}/setup/quick-reply`, {
      tab: "generalQuickReply"
    });
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // let { id, createdTime } = responseData.memberships[0].organization;
          // let urlOrgID = `${id}-${createdTime}`;
          back();
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if(lastChangeMediaData.lastChange) {
      let _addQuickReplyData = {...addQuickReplyData};
      _addQuickReplyData.messageType = lastChangeMediaData.data.messageType;
      _addQuickReplyData.mediaFileID = lastChangeMediaData.data.mediaFileID;

      setAddQuickReplyData(_addQuickReplyData);
    }
  }, [lastChangeMediaData])

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    let _state = props.location.state;

    if(_state) {
      setQuickReplyType(_state.type);
    }
  }, []);

  let onChangeAddQuickReplyInput = (e) => {
    let _addQuickReplyData = {...addQuickReplyData};
    let _errorMessageData = {...errorMessageData};

    _errorMessageData[e.target.id] = "";

    if(e.target.id === "shortcut") {
      _addQuickReplyData[e.target.id] = e.target.value.replaceAll(" ", "");
    }else {
      _addQuickReplyData[e.target.id] = e.target.value;
    }
    setErrorMessageData(_errorMessageData);
    setAddQuickReplyData(_addQuickReplyData);
  };

  let onSubmitCreateQuickReply = () => {
    let _addQuickReplyData = {...addQuickReplyData};
    let errorCount = 0;
    let scrollToError = "";
    let _errorMessageData = {...errorMessageData}

    if(quickReplyType !== "text" && quickReplyType !== "") {
      if(_addQuickReplyData.mediaFileID === null) {
        _errorMessageData.file = "This field is required"
        errorCount = errorCount + 1;

        if(scrollToError === "") { scrollToError = `input-file`}
      }
    }

    if(_addQuickReplyData.message === "") {
      _errorMessageData.message = "This field is required"
      errorCount = errorCount + 1;

      if(scrollToError === "") { scrollToError = `input-message`}
    }

    if(_addQuickReplyData.message.length > 1000) {
      _errorMessageData.message = "Characters exceed limit"
      errorCount = errorCount + 1;

      if(scrollToError === "") { scrollToError = `input-message`}
    }

    if(_addQuickReplyData.shortcut === "") {
      _errorMessageData.shortcut = "This field is required"
      errorCount = errorCount + 1;

      if(scrollToError === "") { scrollToError = `input-shortcut`}
    }

    if(_addQuickReplyData.shortcut.length > 50) {
      _errorMessageData.shortcut = "Characters exceed limit"
      errorCount = errorCount + 1;

      if(scrollToError === "") { scrollToError = `input-shortcut`}
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${scrollToError}`);
    }else {
      setIsWaitResponseAddQuickReply(true);
  
      ServiceQuickReply.addQuickReply(orgID, _addQuickReplyData, (response) => {
        let dataResult = response.dataResult;
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        setIsWaitResponseAddQuickReply(false);
  
        if(dataResult.error.message === "") {
          if(dataResult.data.success) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
              mixpanel.track(
                "[Action] Add Quick Reply",
                {
                  type : "General",
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
                }
              )
            }
            
            setAddQuickReplyDataOrigin(addQuickReplyData);
            setTimeout(() => {
              history.push({
                pathname: `/o/${orgID}/setup/quick-reply`,
                state: {
                  message: response.dataResult.data.message,
                  success: response.dataResult.data.success,
                  tab: "generalQuickReply"
                }
              })
            }, 2000)
          }else {
            doToast(dataResult.error.message, "fail");  
          }
        }else {
          doToast(dataResult.error.message, "fail");
        }
      });
    }
  }
  
  // let isButtonSubmitDisabled = () => {
  //   let alphanumbericAndUnderscore =  /^[a-zA-Z0-9_]+$/;
  //   let _addQuickReplyData = {...addQuickReplyData};
  //   let isContainSpace = _addQuickReplyData.shortcut.includes(" ");

  //   return (
  //     (_addQuickReplyData.message.length === 0 || 
  //      _addQuickReplyData.shortcut.length === 0 ||
  //      _addQuickReplyData.shortcut === "/" ||
  //      !alphanumbericAndUnderscore.test(_addQuickReplyData.shortcut) ||
  //      isContainSpace) ? 
  //      true : false
  //   )
  // }

  let onChangeMedia = (e) => {
    let fileType = e.target.files[0].type.split("/")[0]; 
    let fileExt = e.target.files[0].type.split("/")[1];

    if(quickReplyType === "media") {
      if(fileType === "image") {
        if(fileExt === "gif" || fileExt === "jpeg" || fileExt === "png") {
          setMediaData(URL.createObjectURL(e.target.files[0]));
          setMediaDataType("image");
          uploadMedia(e.target.files[0], "image");
        }else {
          doToast("The following image formats are accepted: image/gif, image/jpeg, image/png", "fail");
        }
      }else if(fileType === "video") {
        setMediaData(URL.createObjectURL(e.target.files[0]));
        setMediaDataType("video");
        uploadMedia(e.target.files[0], "video");
      }else {
        doToast("Allowed file types: image (jpeg, png, gif) and video", "fail");
      }
    }else {
      if(fileExt === "pdf") {
        setMediaData(URL.createObjectURL(e.target.files[0]));
        setMediaDataRaw(e.target.files[0]);
        setMediaDataType("file");
        uploadMedia(e.target.files[0], "file");
      }else {
        doToast("Only pdf file are allowed", "fail");
      }
    }

    e.target.value = null;
  }

  let uploadMedia = (file, type) => {
    let _errorMessageData = {...errorMessageData};
    setLoadingUpload(true);
    _errorMessageData.file = "";
    setErrorMessageData(_errorMessageData);

    var formData = new FormData();
    formData.append("fileType", type);
    formData.append("file", file);

    ServiceQuickReply.uploadMedia(orgID, formData, (response) => {
      // let _addQuickReplyData = {...addQuickReplyData};
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
          // _addQuickReplyData.messageType = type;
          // _addQuickReplyData.mediaFileID = dataResult.data.id;
          setLoadingUpload(false);
          setLastChangeMediaData({
            lastChange: new Date().valueOf(),
            data: {
              messageType: type,
              mediaFileID: dataResult.data.id
            }
          })
          // setAddQuickReplyData(_addQuickReplyData);
      }else {
        setMediaData(false);
        doToast(dataResult.error.message, "fail");
      }

      setLoadingUpload(false);
    });
  }

  return (
    <React.Fragment>
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={addQuickReplyDataOrigin}
            dataAfterChange={addQuickReplyData}
        >   
            <BackButton text="Back to General Quick Replies" onClick={back} />

            <div className="sectionWrap form-add-quick-reply">
                {/* <form 
                  onSubmit={(e) => {
                      onSubmitCreateQuickReply(e);
                  }}
                > */}
                    <div className="form-title-wrapper">
                        <b>Add General Quick Reply</b>

                        {isWaitResponseAddQuickReply ?
                          <ButtonWithLoadingOrIcon 
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            className="orange-button main-button-40"
                            text="Create Quick Reply"
                            position="left"
                          />
                          :
                          <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-40"
                            text="Create Quick Reply"
                            // isDisabled={isButtonSubmitDisabled()}
                            onClickAction={onSubmitCreateQuickReply}
                          />    
                        }
                    </div>
                
                    <div className="form-content-wrapper">
                        <label>
                          <b>Content Type</b>
                        </label>

                        {quickReplyType === "text" ? "Text" : (quickReplyType === "file" ? "Document" : "Media")}
                        
                        {quickReplyType !== "text" &&
                          <>
                            <label>
                              <b>{quickReplyType !== "file" ?  "Media" : "Document"}</b>
                            </label>

                            {!mediaData &&
                              <ButtonWithLoadingOrIcon 
                                className="main-button-40 light-orange-button upload-media-button"
                                position="left"
                                text={`Upload ${quickReplyType !== "file" ?  "Media" : "Document"}`}
                                icon={{
                                  type: "svg",
                                  src: FiUploadCloud
                                }}
                                onClickAction={() => {
                                  let target = document.querySelector("#quick-reply-file");

                                  target.click();
                                }}
                              />
                            }
                            
                            <input disabled={isWaitResponseAddQuickReply} type="file" id="quick-reply-file" accept={quickReplyType === "file" ? "application/pdf" : "video/*, image/gif, image/png, image/jpeg"} onChange={(e) => onChangeMedia(e)} />
                            
                            {mediaData &&
                              <>
                                <div className="media-action-wrapper">
                                  <button 
                                    className="light-orange-button" 
                                    onClick={() => {
                                      let target = document.querySelector("#quick-reply-file");

                                      target.click();
                                    }}
                                    disabled={loadingUpload || isWaitResponseAddQuickReply}
                                  >
                                    <FiUploadCloud />
                                    Replace {quickReplyType === "file" ? "Document" : "Media"}
                                  </button>

                                  <button 
                                    className="red-button-transparent" 
                                    disabled={loadingUpload || isWaitResponseAddQuickReply}
                                    onClick={() => {
                                      let _addQuickReplyData = {...addQuickReplyData};
                                      _addQuickReplyData.messageType = "";
                                      _addQuickReplyData.mediaFileID = null;

                                      setMediaData(null);
                                      setAddQuickReplyData(_addQuickReplyData);
                                    }}
                                  >
                                    <FiTrash2 />
                                    Delete {quickReplyType === "file" ? "Document" : "Media"}
                                  </button>
                                </div>
                                
                                {mediaDataType === "file" ?
                                  <div className="file-wrapper">
                                    <FiFile />

                                    <p>
                                      {mediaDataRaw.name}
                                    </p>
                                    
                                    {loadingUpload &&
                                      <div className="lds-ring">
                                        <div /><div /><div /><div />
                                      </div>
                                    }
                                  </div>
                                  :
                                  <div className="media-image-wrapper">
                                    {mediaDataType === "image" ?
                                      <img src={mediaData} alt="" />
                                      :
                                      <video src={mediaData} />
                                    }

                                    {loadingUpload &&
                                      <div className="lds-ring-wrapper">
                                        <div className="lds-ring">
                                          <div /><div /><div /><div />
                                        </div>
                                      </div>
                                    }
                                  </div>
                                }
                              </>
                            }
                            
                            <ErrorOrInfoComp 
                              text={errorMessageData.file !== "" ? errorMessageData.file : `Allowed file types: ${quickReplyType === "media" ? "image (jpeg, png, gif) and video" : "pdf"}`}
                              icon={<FiInfo />}
                              _className={`${errorMessageData.file !== "" ? "red-text" : ""}`}
                            />
                          </>
                        }

                        <label>
                          <b>Message</b>

                          <span className={errorMessageData.message === "Characters exceed limit" ? "red-text" : ""}>{addQuickReplyData.message.length}/1000</span>
                        </label>
                        <textarea disabled={isWaitResponseAddQuickReply} type="text" placeholder="Type Message Here" id="message" className={`input-message ${errorMessageData.message !== "" ? "border-red" : ""}`} onChange={(e) => {
                            onChangeAddQuickReplyInput(e);
                        }}/>

                        {errorMessageData.message !== "" &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.message}
                            icon={<FiInfo />}
                            _className="red-text margin-top-0"
                          />
                        }

                        <p className="input-info-wrapper">
                            This is the message that will appear after you select a specific shortcut. <b>No blank space are allowed at the beginning and end of a message.</b>
                        </p>

                        <label className="pos-relative">
                          <b>Shortcut</b>
                          <span className={errorMessageData.shortcut === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{addQuickReplyData.shortcut.length}/50 characters</span>
                        </label>
                        <div className="quick-reply-prefix disabled-input">
                          {HelperQuickReply.prefixShortcut}
                        </div>

                        <input disabled={isWaitResponseAddQuickReply} type="text" value={addQuickReplyData.shortcut} placeholder="Type shortcut here" id="shortcut" className={`input-shortcut ${errorMessageData.shortcut !== "" ? "border-red" : ""}`} onChange={(e) => {
                            onChangeAddQuickReplyInput(e);
                        }}/>

                        {errorMessageData.shortcut !== "" &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.shortcut}
                            icon={<FiInfo />}
                            _className="red-text margin-top-0"
                          />
                        }

                        <p className="input-info-wrapper">
                            This is the message that will appear after you select a specific shortcut.
                            <b> Only alphanumberic and underscore are allowed. </b> 
                            Your shortcut will be displayed like this "/shortcut" 
                            (Tip: keep it short and simple).
                        </p>

                        {/* {!isWaitResponseAddQuickReply &&
                            <ButtonWithLoadingOrIcon 
                              className="orange-button main-button-40"
                              text="Create Quick Reply"
                              isDisabled={isButtonSubmitDisabled()}
                            />
                        } */}
                    </div>
                {/* </form> */}

                {/* {isWaitResponseAddQuickReply &&
                  <ButtonWithLoadingOrIcon 
                    isLoading
                    loadingWhite
                    className="orange-button main-button-40"
                    text="Create Quick Reply"
                    position="left"
                    style={{width: "300px"}}
                  />
                } */}
            </div>
        </SectionWrap>
    </React.Fragment>
  );
};

export default QuickReplyGeneralAdd;