const INITIAL_STATE = false;

let reduxReducerNeedReplyColorIndicator = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_NEED_REPLY_COLOR_INDICATOR':
        return action.needReplyColorIndicator;
      case 'CLEAR_NEED_REPLY_COLOR_INDICATOR':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerNeedReplyColorIndicator;