import React, { useEffect, useState } from "react";
import "./IntegrationKataaiAdd.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import HelperInput from "../../../../helper/HelperInput";
import IntegrationKataaiServices from "../../../../services/integration/IntegrationKataaiServices";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";

const IntegrationKataaiAdd = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [topicList, setTopicList] = useState([]);
    let [addData, setAddData] = useState({
        channelName: "",
        url: "",
        topic: {
            label: "",
            value: ""
        },
    });
    let [addDataOrigin, setAddDataOrigin] = useState({
        channelName: "",
        url: "",
        topic: {
            label: "",
            value: ""
        },
    });
    let [errorMessageData, setErrorMessageData] = useState({
        channelName: "",
        topic: "",
        url: ""
    })
    let [isLoadingCreate, setIsLoadingCreate] = useState(false);

    let back = () => history.push(`/o/${orgID}/integration/kataai`);

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        topicServices.getTopic(orgID, "list", null, (response) => {
            const data = response.dataResult.data.topics;
            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.value = val.id;
                    _data.label = val.name;
                    newData.push(_data);
                    return null;
                });
                setTopicList(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if (orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    let onSelectTopic = (value) => {
        let _errorMessageData = { ...errorMessageData };
        let _addData = { ...addData };
        _errorMessageData.topic = "";
        _addData.topic = value;
        setErrorMessageData(_errorMessageData);
        setAddData(_addData);
    }

    let onChangeText = (e) => {
        let _errorMessageData = { ...errorMessageData };
        let _addData = { ...addData };

        _errorMessageData[e.target.id] = "";
        _addData[e.target.id] = e.target.value;

        if(e.target.id === "channelName") {
            if(e.target.value.length > 50) {
                _errorMessageData.channelName = "Characters exceed limit";
            }
        }

        setErrorMessageData(_errorMessageData);
        setAddData(_addData);
    }

    let onClickCreate = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = { ...errorMessageData };

        if (addData.channelName === "") {
            _errorMessageData.channelName = "This field is required";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-name" }
        }

        if(addData.channelName.length > 50) {
            _errorMessageData.channelName = "Characters exceed limit";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-name" }
        }

        if (addData.topic.value === "") {
            _errorMessageData.topic = "Please select topic";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-topic" }
        }

        if (!HelperInput.validURL(addData.url)) {
            _errorMessageData.url = "Invalid URL";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-url" }
        }

        if (addData.url === "") {
            _errorMessageData.url = "This field is required";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-url" }
        }

        if (errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        } else {
            //run api
            setIsLoadingCreate(true);

            let data = {
                "channelName": addData.channelName,
                "topicID": addData.topic.value,
                "kataURL": addData.url
            };

            IntegrationKataaiServices.create(orgID, data, (response) => {
                if (response.dataResult.error.message === "") {
                    if (response.dataResult.data.success) {
                        doToast(response.dataResult.data.message);
                        setAddDataOrigin(addData);

                        setTimeout(() => {
                            back();
                        }, 3000)
                    } else {
                        doToast(response.dataResult.data.message, "fail");
                        setIsLoadingCreate(false);
                    }
                } else {
                    doToast(response.dataResult.error.message);
                    setIsLoadingCreate(false);
                }
            });
        }
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={JSON.stringify(addDataOrigin)}
                dataAfterChange={JSON.stringify(addData)}
            >
                <div className="sectionWrap integration-main-container integration-kataai-add-account text-left">
                    <BackButton text="Back to Kata.ai Channel" onClick={back} />

                    <div className="integration-kataai-add-account-box">
                        <div className="integration-kataai-add-account-box-top">
                            <b>Add Channel</b>

                            {!isLoadingCreate ?
                                <ButtonWithLoadingOrIcon
                                    onClickAction={onClickCreate}
                                    className="orange-button main-button-40"
                                    text="Create Channel"
                                    position="left"
                                />
                                :
                                <ButtonWithLoadingOrIcon
                                    isDisabled
                                    loadingColor="gray"
                                    className="orange-button main-button-40"
                                    text="Create Channel"
                                    position="left"
                                    isLoading
                                />
                            }
                        </div>

                        <div className="integration-kataai-add-account-box-form">
                            <label>
                                <span className={errorMessageData.channelName === "Characters exceed limit" ? "red-text" : "grey-font"}>{addData.channelName.length}/50 characters</span>
                                <b>Channel Name</b>
                            </label>
                            <input type="text" placeholder="Input channel name" onChange={(e) => { onChangeText(e) }} id="channelName" value={addData.channelName} className={`input-name ${errorMessageData.channelName !== "" ? "border-red" : ""}`} />
                            {errorMessageData.channelName !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.channelName}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label><b>Topic</b></label>
                            <CustomSelectOption
                                optionListProps={topicList}
                                valueDropdownProps={addData.topic}
                                placeholderProps={"Select topic"}
                                onClickDropDownListOptionProps={onSelectTopic}
                                specificClassNameProps="custom-select-whatsapp-topic"
                                _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                            />
                            <ErrorOrInfoComp
                                text={errorMessageData.topic === "" ? "Incoming messages on this channel will be assigned to this topic." : errorMessageData.topic}
                                _className={errorMessageData.topic !== "" ? "font-red margin-top-0" : "margin-top-0"}
                                icon={<FiInfo />}
                            />

                            <label>
                                <b>Kata URL</b>
                            </label>
                            <input type="text" placeholder="Input Kata URL here" onChange={(e) => { onChangeText(e) }} id="url" value={addData.url} className={`input-url ${errorMessageData.url !== "" ? "border-red" : ""}`} />
                            {errorMessageData.url !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.url}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }
                        </div>
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationKataaiAdd;