import React, { useEffect } from "react";
import "./BlockingPageRole.scss";
import { connect } from 'react-redux';
import { setParentMenuBlockActive } from "../../../redux/actions/reduxActionSideMenu";
import icon401 from "../../../assets/img/401-image.svg";
import { Link } from "react-router-dom";

const BlockingPageRole = (props) => {
    useEffect(() => {
        props.setParentMenuBlockActive({});
    }, [])
    
    let isSubmenuExist = () => {
        return typeof props.sideMenu === "object" && props.sideMenu.submenuList;
    }

    return (
        <div className={`blocking-screen-page-role ${!isSubmenuExist() ? "deactive-sub-menu" : ""} ${props.billingWarning ? "blocking-page-role-active-billing-warning" : ""}`}>
            <div className="blocking-screen-page-role-wrapper">
                <img src={icon401} alt="" />

                <p className="blocking-screen-page-role-alert">
                    <b>You are not authorized to view this page</b>
                </p>

                <Link to={`/o/${props.match.params.orgID}/home`} onClick={() => {
                    props.setParentMenuBlockActive({
                        activeMenuBlock: "home",
                        submenuList: null
                    })
                }}>
                    <button className="orange-button">
                        Go to Home
                    </button>
                </Link>
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    sideMenu: state.sideMenu,
    billingWarning: state.billingWarning
});

const mapDispatchToProps = {
    setParentMenuBlockActive
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockingPageRole)