import React, { useState, useEffect } from "react";
import "./AssignIdleCase.scss";
import { FiEdit } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import iconCheckGreen from '../../assets/img/icon-check-green.svg';
import iconCloseRed from '../../assets/img/close-red-icon.svg';
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import AutoResolveServices from "../../services/newServices/AutoResolveServices";
import AutoUnassignServices from "../../services/newServices/AutoUnassignServices";
import ChatbotService from "../../services/newServices/ChatbotService";

let AssignIdleCase = (props) => {
    let [waitFetchResolve, setWaitFetchResolve] = useState(true);
    let [resolveData, setResolveData] = useState(null);
    let [retriveDataErrorResolve, setRetrieveDataErrorResolve] = useState({
        code: "",
        message: ""
    });

    let [waitFetchUnassign, setWaitFetchUnassign] = useState(true);
    let [unassignData, setUnassignData] = useState(null);
    let [retriveDataErrorUnassign, setRetrieveDataErrorUnassign] = useState({
        code: "",
        message: ""
    });

    let [waitFetchChatbot, setWaitFetchChatbot] = useState(true);
    let [chatbotData, setChatbotData] = useState(null);
    let [retriveDataErrorChatbot, setRetrieveDataErrorChatbot] = useState({
        code: "",
        message: ""
    });

    useEffect(() => {
        getDataAutoResolve();
        getDataAutoUnassign();
        getAssignChatbot();
    }, [])

    let getDataAutoResolve = () => {
        let _retriveDataError = { ...retriveDataErrorResolve };
        setWaitFetchResolve(true);

        AutoResolveServices.getAutoResolve(props.match.params.orgID, "global", {}, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setResolveData(dataResult.data.autoResolveConfig);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataErrorResolve(_retriveDataError);
            }

            setWaitFetchResolve(false);
        })
    }

    let getDataAutoUnassign = () => {
        let _retriveDataError = { ...retriveDataErrorUnassign };
        setWaitFetchUnassign(true);

        AutoUnassignServices.getAutoUnassign(props.match.params.orgID, "global", {}, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setUnassignData(dataResult.data.autoUnassignAgentConfig);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataErrorUnassign(_retriveDataError);
            }

            setWaitFetchUnassign(false);
        })
    }

    let getAssignChatbot = () => {
        let _retriveDataError = { ...retriveDataErrorChatbot };
        setWaitFetchChatbot(true);

        ChatbotService.getAutoChatbot(props.match.params.orgID, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setChatbotData(dataResult.data.config);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataErrorChatbot(_retriveDataError);
            }

            setWaitFetchChatbot(false);
        })
    }

    return (
        <>
            <div className="sectionWrap idle-case-container">
                {/* tier blocking */}
                {retriveDataErrorResolve.code === "49901" &&
                    <RetrieveDataLoading
                        isLoading={waitFetchResolve}
                        errorMessage={retriveDataErrorResolve.message}
                        errorCode={retriveDataErrorResolve.code}
                    />
                }
                {/* tier blocking */}

                <div className="topContent">
                    <div className="title">Automatic Resolve Config</div>
                    <p className="sub-title">Chats will be automatically resolved once customer does not reply back within a specific time range.</p>

                    {(!retriveDataErrorResolve.code && !waitFetchResolve) &&
                        <NavLink to={`/o/${props.match.params.orgID}/setup/general-assignments/auto-resolve-case`}>
                            <ButtonWithLoadingOrIcon
                                icon={{
                                    type: "svg",
                                    src: FiEdit
                                }}
                                position="left"
                                className="no-fill-button main-button-40 button-edit-idle"
                                text="Edit"
                            />
                        </NavLink>
                    }
                </div>

                {(waitFetchResolve || retriveDataErrorResolve.code) ?
                    <RetrieveDataLoading
                        isLoading={waitFetchResolve}
                        errorMessage={retriveDataErrorResolve.message}
                    />
                    :
                    <div className="idle-case-value-wrapper">
                        <b className="idle-case-value-label">
                            Status
                        </b>

                        <p className={`idle-case-value ${resolveData.isEnabled ? "green-text" : "red-text"}`}>
                            {resolveData.isEnabled ?
                                <>
                                    <img src={iconCheckGreen} alt="" />
                                    Enabled
                                </>
                                :
                                <>
                                    <img src={iconCloseRed} alt="" />
                                    Disabled
                                </>
                            }
                        </p>

                        {resolveData.isEnabled &&
                            <p className="idle-case-value idle-case-value-2">
                                A case will be automatically resolved after <b>{resolveData.afterValue} {resolveData.afterType}</b> from agent’s last reply.
                            </p>
                        }
                    </div>
                }
            </div>

            <div className="sectionWrap idle-case-container">
                {/* tier blocking */}
                {retriveDataErrorUnassign.code === "49901" &&
                    <RetrieveDataLoading
                        isLoading={waitFetchUnassign}
                        errorMessage={retriveDataErrorUnassign.message}
                        errorCode={retriveDataErrorUnassign.code}
                    />
                }
                {/* tier blocking */}

                <div className="topContent">
                    <div className="title">Automatic Unassigns Agent Config</div>
                    <p className="sub-title">Agents will be automatically unassigned from their cases if agent does not reply in a specific range of time.</p>

                    {(!retriveDataErrorUnassign.code && !waitFetchUnassign) &&
                        <NavLink to={`/o/${props.match.params.orgID}/setup/general-assignments/auto-unassigns-agent`}>
                            <ButtonWithLoadingOrIcon
                                icon={{
                                    type: "svg",
                                    src: FiEdit
                                }}
                                position="left"
                                className="no-fill-button main-button-40 button-edit-idle"
                                text="Edit"
                            />
                        </NavLink>
                    }
                </div>

                {(waitFetchUnassign || retriveDataErrorUnassign.code) ?
                    <RetrieveDataLoading
                        isLoading={waitFetchUnassign}
                        errorMessage={retriveDataErrorUnassign.message}
                    />
                    :
                    <div className="idle-case-value-wrapper">
                        <b className="idle-case-value-label">
                            Status
                        </b>

                        <p className={`idle-case-value ${unassignData.isEnabled ? "green-text" : "red-text"}`}>
                            {unassignData.isEnabled ?
                                <>
                                    <img src={iconCheckGreen} alt="" />
                                    Enabled
                                </>
                                :
                                <>
                                    <img src={iconCloseRed} alt="" />
                                    Disabled
                                </>
                            }
                        </p>

                        {unassignData.isEnabled &&
                            <p className="idle-case-value idle-case-value-2">
                                Agent will be automatically unassigned after <b>{unassignData.afterValue} {unassignData.afterType}</b> from customer’s last reply.
                            </p>
                        }
                    </div>
                }
            </div>

            <div className="sectionWrap idle-case-container">
                {/* tier blocking */}
                {retriveDataErrorChatbot.code === "49901" &&
                    <RetrieveDataLoading
                        isLoading={waitFetchChatbot}
                        errorMessage={retriveDataErrorChatbot.message}
                        errorCode={retriveDataErrorChatbot.code}
                    />
                }
                {/* tier blocking */}

                <div className="topContent">
                    <div className="title">Case Assigned to Chatbot</div>
                    <p className="sub-title">Chatbot cases will be automatically handled once there are no active conversation within a specific time range.</p>

                    {(!retriveDataErrorChatbot.code && !waitFetchChatbot) &&
                        <NavLink to={`/o/${props.match.params.orgID}/setup/general-assignments/auto-assign-chatbot`}>
                            <ButtonWithLoadingOrIcon
                                icon={{
                                    type: "svg",
                                    src: FiEdit
                                }}
                                position="left"
                                className="no-fill-button main-button-40 button-edit-idle"
                                text="Edit"
                            />
                        </NavLink>
                    }
                </div>

                {(waitFetchChatbot || retriveDataErrorChatbot.code) ?
                    <RetrieveDataLoading
                        isLoading={waitFetchChatbot}
                        errorMessage={retriveDataErrorChatbot.message}
                    />
                    :
                    <div className="idle-case-value-wrapper">
                        <b className="idle-case-value-label">
                            Status
                        </b>

                        <p className={`idle-case-value ${chatbotData.isEnabled ? "green-text" : "red-text"}`}>
                            {chatbotData.isEnabled ?
                                <>
                                    <img src={iconCheckGreen} alt="" />
                                    Enabled
                                </>
                                :
                                <>
                                    <img src={iconCloseRed} alt="" />
                                    Disabled
                                </>
                            }
                        </p>

                        {chatbotData.isEnabled &&
                            <> 
                                {chatbotData.idleAction === "handover" ?
                                    <p className="idle-case-value idle-case-value-2">
                                        Cases will be automatically <b>handed over to agent</b> after <b>{chatbotData.afterValue} {chatbotData.afterType}</b> from the last conversation sent or received.
                                    </p>
                                    :
                                    <p className="idle-case-value idle-case-value-2">
                                        Cases will be automatically <b>resolved</b> after <b>{chatbotData.afterValue} {chatbotData.afterType}</b> from the last conversation sent or received.
                                    </p>
                                }

                            </>
                        }
                    </div>
                }
            </div>
        </>
    )
}

export default AssignIdleCase;