import React, { useState, useEffect } from "react";
import "./FormInteractiveMessageManageRow.scss";
import { connect } from "react-redux";
import { FiInfo, FiList, FiPlus, FiTrash2 } from "react-icons/fi";
import ErrorOrInfoComp from "../../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../../../../../../reuseableComponent/BackButton/BackButton";
import { scrollToClass } from "../../../../../../../helper/HelperGeneral";

let FormInteractiveMessageManageRow = (props) => {
  let { activeManageRow, onClickBack, rowLeft, lastClickSaveChangeRow, runSaveChangesRow, sectionLength } = props;

  let [allowedRow, setAllowedRow] = useState(0);
  let [rowVal, setRowVal] = useState(false);
  // let [rowValOrigin, setRowValOrigin] = useState(false);
  let [errorMessageData, setErrorMessageData] = useState(false);

  useEffect(() => {
    if(activeManageRow.show) {
      setRowVal(activeManageRow.data);
      // setRowValOrigin(activeManageRow.data);
      setErrorMessageData(activeManageRow.error);
    }
  }, [activeManageRow])

  useEffect(() => {
    if(activeManageRow.show) {
      setAllowedRow(rowLeft);
    }
  }, [activeManageRow])

  useEffect(() => {
    if(lastClickSaveChangeRow) {
      // section-title-text
      // section-description-text
      let _rowVal = JSON.parse(JSON.stringify(rowVal));
      let _errorMessageData = JSON.parse(JSON.stringify(errorMessageData));
      let errorCount = 0;
      let scrollClass = "";

      _rowVal.rows.map((v, i) => {
        if(v.title.length > 24) {
          errorCount = errorCount + 1;
          scrollClass = `section-title-text-${i}`;
          _errorMessageData.rows[i].title = "Characters exceed limit";
        }

        if(v.description.length > 72) {
          errorCount = errorCount + 1;
          scrollClass = `section-description-text-${i}`;
          _errorMessageData.rows[i].description = "Characters exceed limit";
        }

        if(v.title === "") {
          errorCount = errorCount + 1;
          scrollClass = `section-title-text-${i}`;
          _errorMessageData.rows[i].title = "This field is required";
        }

        // if(v.description === "") {
        //   errorCount = errorCount + 1;
        //   scrollClass = `section-description-text-${i}`;
        //   _errorMessageData.rows[i].description = "This field is required";
        // }

        if(_errorMessageData.rows[i].title !== "") {
          errorCount = errorCount + 1;
          scrollClass = `section-title-text-${i}`;
        }

        return null;
      })

      if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${scrollClass}`);
      }else {
        runSaveChangesRow(rowVal, errorMessageData, activeManageRow.index);
      }
    }
  }, [lastClickSaveChangeRow])

  let onAddAnotherror = () => {
    let _rowVal = {...rowVal};
    let _errorMessageData = {...errorMessageData};

    _rowVal.rows.push({
      id: "",
      title: "",
      description: ""
    });

    _errorMessageData.rows.push({
      id: "",
      title: "",
      description: ""
    })

    setAllowedRow(allowedRow - 1);
    setRowVal(_rowVal);
    setErrorMessageData(_errorMessageData);
  }

  let check_duplicate_in_array = (input_array)=>{
    let newArr = [];

    input_array.map((v) => {
      newArr.push(v.title);
      return null;
    })

    let duplicates = newArr.filter((item, index) =>newArr.indexOf(item) !== index);
    return Array.from(new Set(duplicates));
  }

  let onChangeText = (id, val, idx) => {
    let _rowVal = JSON.parse(JSON.stringify(rowVal));
    let _errorMessageData = JSON.parse(JSON.stringify(errorMessageData));
    let _rowsSlice = _rowVal.rows.slice();
    let _errorMessageDataSlice = _errorMessageData.rows.slice();
    
    _errorMessageDataSlice[idx][id] = "";

    _rowsSlice[idx][`${id}`] = val; 

    if(id === "title") {
      if(val.length > 24) {
        _errorMessageDataSlice[idx].title = "Characters exceed limit";
      }

      let dupeArr = [];

      let duplicateVal = check_duplicate_in_array(_rowsSlice);

      
      if(duplicateVal[0] !== "") {
        _rowsSlice.filter((v, i) => {
          if(v.title === duplicateVal[0]) {
            dupeArr.push(i);
          }

          return null;
        })
      }

      _errorMessageDataSlice.map((v, i) => {
        if(_rowsSlice[i].title !== "This field is required" && _rowsSlice[i].title !== "Characters exceed limit") {
          v.title = "";

          if(_rowsSlice[i].title.length > 24) {
            v.title = "Characters exceed limit";
          }else {
            v.title = "";
          }
        }
  
        return null;
      })

      if(dupeArr.length > 0) {
        dupeArr.map((v) => {
          _errorMessageDataSlice[v].title = "You can't enter the same text for multiple buttons";
  
          return null;
        })
      }
    }else {
      if(val.length > 72) {
        _errorMessageDataSlice[idx].description = "Characters exceed limit";
      }
    }

    _rowVal.rows = _rowsSlice;
    _errorMessageData.rows = _errorMessageDataSlice;

    setRowVal(_rowVal);
    setErrorMessageData(_errorMessageData);
  }

  let removeRow = (i) => {
    let _rowVal = JSON.parse(JSON.stringify(rowVal));
    let _errorMessageData = JSON.parse(JSON.stringify(errorMessageData));

    _rowVal.rows.splice(i, 1);
    _errorMessageData.rows.splice(i, 1);
    let _rowsSlice = _rowVal.rows.slice(); 

    let dupeArr = [];
    
    let duplicateVal = check_duplicate_in_array(_rowsSlice);

    if(duplicateVal[0] !== "") {
      _rowsSlice.filter((v, i) => {
        if(v.title === duplicateVal[0]) {
          dupeArr.push(i);
        }

        return null;
      })
    }

    _errorMessageData.rows.map((v, i) => {
      let _findIdx = dupeArr.findIndex(_v => _v === i);
      if(v.title === "You can't enter the same text for multiple buttons" && _findIdx === -1) {
        if(_rowsSlice[i].title.length > 24) {
          v.title = "Characters exceed limit";
        }else {
          v.title = "";
        }
      }

      return null;
    })

    setAllowedRow(allowedRow + 1);
    setRowVal(_rowVal);
    setErrorMessageData(_errorMessageData);
  }

  return (
    <div className={`new-interactiveList-row-message-wrapper ${activeManageRow.show ? "new-interactiveList-row-message-wrapper-active" : ""}`}>
      {/* <Scrollbars
        autoHideTimeout={500}
        renderThumbVertical={(props) => (
          <div {...props} style={style.scrollStyle} />
        )}
      >  */}
        <BackButton 
          text="Back to Interactive Message"
          onClick={onClickBack}
        />

        <div className="new-interactiveList-message-container">
          <div className="new-interactiveList-message-title">
            <FiList />

            <b>Section #{activeManageRow ? activeManageRow.index + 1 : ""}{activeManageRow ? (activeManageRow.data.title === "" ? "" : ": "+activeManageRow.data.title) : ""}</b>
            <p>
              Create row content for your sections to complete your interactive message list.
            </p>
          </div>

          {(activeManageRow && rowVal) &&
            <>
              {rowVal.rows.map((v, i) => {
                return (
                  <div className={`button-text-wrapper-form ${i === 0 ? "section-title-wrapper-form-1": ""}`} key={`button-text-${i}`}>
                    <label className={`input-button-text-${i}`}>
                      <b>Row #{i + 1}</b>
                      <span className={`grey-font  ${errorMessageData.rows[i].title === "Characters exceed limit" ? "red-text" : ""}`}>
                        {v.title.length}/24 characters

                        {(i === 0 && sectionLength > 1) ?
                          (rowVal.rows.length > 1 ?
                            <FiTrash2 onClick={() => removeRow(i)} className="remove-button-text" />
                            :
                            ""  
                          ) 
                          :
                          <FiTrash2 onClick={() => removeRow(i)} className="remove-button-text" />
                        }
                      </span>
                    </label>
                    <input type="text" value={v.title} onChange={(e) => onChangeText("title", e.target.value, i)} placeholder={`Row Title`} className={errorMessageData.rows[i].title !== "" ? `border-red button-text-${i} section-title-text-${i}` : `section-title-text-${i}`} />
                    {errorMessageData.rows[i].title !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.rows[i].title}
                        icon={<FiInfo />}
                        _className="red-text"
                      />
                    }

                    <label className={`input-button-text-${i} row-description`}>
                      <span className={`grey-font  ${errorMessageData.rows[i].description === "Characters exceed limit" ? "red-text" : ""}`}>
                        {v.description.length}/72 characters
                      </span>
                    </label>
                    <input type="text" value={v.description} onChange={(e) => onChangeText("description", e.target.value, i)} placeholder={`Row Description (optional)`} className={errorMessageData.rows[i].description !== "" ? `border-red button-text-${i} section-description-text-${i}` : `section-description-text-${i}`} />
                    {errorMessageData.rows[i].description !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.rows[i].description}
                        icon={<FiInfo />}
                        _className="red-text"
                      />
                    }
                  </div>
                )
              })}
              
              {allowedRow > 0 &&
                <p className="add-another-button" onClick={() => onAddAnotherror(activeManageRow.index)}>
                  <b><FiPlus /> Add Another Row</b>
                </p>
              }
            </>
          }
        </div>
      {/* </Scrollbars> */}
    </div>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FormInteractiveMessageManageRow);
  