import React from "react";
import "./SalesTalkErrorProductComp.scss";
import { FiAlertCircle } from "react-icons/fi"; 
import { connect } from 'react-redux';

let SalesTalkErrorProductComp = (props) => {
    let { withIconWarning, title, desc } = props;
    return (
        <div className="error-product-component">
            <p className="error-title">
                {withIconWarning && <FiAlertCircle />}
                <b>{title}</b>
            </p>

            <p className="error-text">
                {desc}
            </p>
        </div>
    )
}


let mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    // salesTalkInbox: state.salesTalkInbox
})

let mapDispatchToProps = {
    // setSalesTalkInbox
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTalkErrorProductComp);