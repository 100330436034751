import React, { useState, useEffect } from 'react';
import './IntegrationEmailDetailAccount.scss';
import { NavLink } from "react-router-dom";
import PopupRemove from '../../../reuseableComponent/popupRemove/PopupRemove';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import AutoResolveServices from '../../../../services/newServices/AutoResolveServices';
import AutoUnassignServices from '../../../../services/newServices/AutoUnassignServices';
import IntegrationEmailServices from '../../../../services/integration/IntegrationEmailServices';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import IntegrationAutoMessageDetail from "../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import { FiCopy, FiEdit, FiTrash2, FiLink2, FiInfo } from 'react-icons/fi';
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
import statusDisconnect from '../../../../assets/img/integration/icon-disconnect.svg';
import iconXgrey from '../../../../assets/img/integration/icon-x-grey.svg';
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import { connect } from 'react-redux';

let IntegrationEmailDetailAccount = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [emailAccountDetail, setEmailAccountDetail] = useState(null);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [isShowModalRemoveChannel, setIsShowModalRemoveChannel] = useState(false);
  let [waitForResponseRemoveChannel, setWaitForResponseRemoveChannel] = useState(false);
  let [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
  let [closingMessageData, setClosingMessageData] = useState(false);
  let [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
  let [ratingMessageData, setRatingMessageData] = useState(false);
  let [loadingAutoResolve, setLoadingAutoResolve] = useState(false);
  let [autoResolveData, setAutoResolveData] = useState(false);
  let [errorClosing, setErrorClosing] = useState(false);
  let [errorRating, setErrorRating] = useState(false);
  let [errorAutoResolve, setErrorAutoResolve] = useState(false);
  let [loadingAutoUnassign, setLoadingAutoUnassign] = useState(false);
  let [autoUnassignData, setAutoUnassignData] = useState(false);
  let [errorAutoUnassign, setErrorAutoUnassign] = useState(false);
  let [emailChannelLink, setEmailChannelLink] = useState(null);
  let [emailChannelLinkNeverSet, setEmailChannelLinkNeverSet] = useState(false);

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getEmailChannelLink();
      getAccountDetail();
      runGetClosingMessage();
      runGetRatingMessage();
      runGetAutoResolve();
      runGetAutoUnassign();
    }
  }, [orgID])

  let toggleModalRemoveChannel = () => {
    if (!waitForResponseRemoveChannel) {
      setIsShowModalRemoveChannel(!isShowModalRemoveChannel);
    }
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  let getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: parseInt(props.match.params.accountID)
    }

    IntegrationEmailServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };
      if (response.dataResult.error.message === "") {
        setEmailAccountDetail(response.dataResult.data);
      } else {
        setEmailAccountDetail(null);
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
        // setIsLoadingGetDetailData(false);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let getEmailChannelLink = () => {
    let data = {
      id: parseInt(match.params.accountID)
    }

    IntegrationEmailServices.getChannelLink(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };
      let dataResult = response.dataResult;

      if (dataResult.status === 200) {
        setEmailChannelLink(dataResult.data.channelLink);
      } else {
        if (dataResult.error.code === "40401") {
          setEmailChannelLinkNeverSet(true);
        } else {
          setEmailChannelLink(null);
          _retriveDataError.message = dataResult.error.message;
          _retriveDataError.code = dataResult.error.code;
          setRetrieveDataError(_retriveDataError);
        }
      }

      // setIsLoadingGetDetailData(false);
    });
  }

  let runGetClosingMessage = () => {
    setLoadingGetClosingMessage(true);

    IntegrationEmailServices.getClosingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setClosingMessageData(response.dataResult.data.closingMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorClosing({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetClosingMessage(false);
    });
  }

  let runGetRatingMessage = () => {
    setLoadingGetRatingMessage(true);

    IntegrationEmailServices.getRatingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setRatingMessageData(response.dataResult.data.userRating);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorRating({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetRatingMessage(false);
    });
  }

  let removeChannel = () => {
    setWaitForResponseRemoveChannel(true);

    let data = {
      id: emailAccountDetail.channel.id,
      createdTime: emailAccountDetail.channel.createdTime
    };

    IntegrationEmailServices.deleteDetail(
      orgID,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message);
            setWaitForResponseRemoveChannel(false);
          } else {
            doToast(response.dataResult.data.message);

            setTimeout(() => {
              back();
            }, 3000)
          }
        } else {
          doToast(response.dataResult.data.message, 'fail');
          setWaitForResponseRemoveChannel(false);
        }
      }
    );
  }

  let back = () => history.push(`/o/${orgID}/integration/email`);

  // let editTopic = (id) => history.push(`/o/${orgID}/integration/email/edit-topic/${id}`);

  // let goToEditChannelButton = (id) => {
  //   history.push(`/o/${orgID}/integration/email/edit-channel-button/${id}`);
  // }

  let getChannelLink = () => {
    let link = emailChannelLink.url;
    const CURRENT_LOCATION = encodeURIComponent(window.location.href);

    link = `${link}${emailChannelLink.sendPageURL ? `%0A%0AFrom URL: ${CURRENT_LOCATION}` : ""}`;

    return link;
  }

  let renderTopics = () => {
    let text = "";

    emailAccountDetail.channel.topics.map((v, i) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  let runGetAutoResolve = () => {
    setLoadingAutoResolve(true);

    AutoResolveServices.getAutoResolve(orgID, "email", { id: parseInt(match.params.accountID) }, (response) => {
      // let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setAutoResolveData(response.dataResult.data.autoResolveConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoResolve({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingAutoResolve(false);
    });
  }

  let runGetAutoUnassign = () => {
    setLoadingAutoUnassign(true);

    AutoUnassignServices.getAutoUnassign(orgID, "email", { id: parseInt(match.params.accountID) }, (response) => {
      // let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setAutoUnassignData(response.dataResult.data.autoUnassignAgentConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoUnassign({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingAutoUnassign(false);
    });
  }

  return (
    <div className="integration-outer-wrapper">
      <PopupRemove
        removeTitle={`Delete Channel?`}
        removeDescription={`Once deleted, you will not be able to receive and send messages from this channel anymore.`}
        onClickCancel={toggleModalRemoveChannel}
        onClickRemove={removeChannel}
        withoutBorder
        loadingButtonGray
        waitForResponse={waitForResponseRemoveChannel}
        showModalProps={isShowModalRemoveChannel}
        submitText={'Delete'}
        colorButton="red-button"
      />

      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-email-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ?
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
            :
            <>
              <BackButton text="Back to Email Integration" onClick={back} />

              <div className="integration-email-detail-account-box-1">
                <div className="integration-email-detail-account-box-1-top">
                  <b>{emailAccountDetail.channel.emailAddress}</b>
                  <p className="bot-name">{`${emailAccountDetail.channel.senderName === "" ? "No sender name" : emailAccountDetail.channel.senderName}`}</p>
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Channel ID</b>
                  </label>
                  <div className="disabled-form">
                    <input type="text"
                      value={emailAccountDetail.channel.stringID}
                      // onClick={copyToClip}
                      id="copy-string-id"
                      readOnly
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("copy-string-id", "Channel ID copied")
                      }}
                    />
                  </div>
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Email Address</b>
                  </label>
                  <div className="disabled-form">
                    <input type="text"
                      value={emailAccountDetail.channel.emailAddress}
                      // onClick={copyToClip}
                      id="copy-string-email"
                      readOnly
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("copy-string-email", "Email address copied")
                      }}
                    />
                  </div>
                </div>

                {emailAccountDetail.channel.topics.length > 0 &&
                  <div className="email-detail-topic">
                    <div className="topic-wrapper">
                      <b className="topic-title">Topic</b>
                      <p className="topic-val">
                        <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                      </p>
                    </div>

                    <ButtonWithLoadingOrIcon
                      icon={{
                        type: "svg",
                        src: FiTrash2
                      }}
                      className="red-button main-button-40"
                      text="Delete"
                      position="left"
                      onClickAction={(() => {
                        toggleModalRemoveChannel()
                      })}
                    />

                    <NavLink to={`/o/${orgID}/integration/email/edit-detail/${emailAccountDetail.channel.id}`}>
                      <ButtonWithLoadingOrIcon
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40"
                        text="Edit"
                        position="left"
                      />
                    </NavLink>
                  </div>
                }


                <div className="email-bot-token-status">
                  <div className="bot-status-wrapper">
                    <b className="bot-status-title">Status</b>
                    <div className="status-wrapper">
                      {emailAccountDetail.channel.channelStatus !== "error" ?
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-text text-green">{emailAccountDetail.channel.channelStatusText}</b>
                        </>
                        :
                        <>
                          <img src={statusDisconnect} alt="" />
                          <b className="status-text text-red">{emailAccountDetail.channel.channelStatusText}</b>
                        </>
                      }
                    </div>
                    
                    {emailAccountDetail.channel.channelStatus === "error" &&
                      <ErrorOrInfoComp
                        text="Please edit your mail server config to reconnect"
                        _className=""
                        icon={<FiInfo />}
                      />
                    }
                  </div>
                </div>
              </div>

              <div className="integration-email-detail-account-box-1 margin-top-24">
                <div className="integration-email-detail-account-box-1-top integration-email-detail-account-box-2-top">
                  <b>Mail Server Config</b>
                  <p className="subtitle-chatbot text-left">
                    For more details, <a href="https://docs.taptalk.io/onetalk-omnichannel-documentation/email-integration" target="_blank" rel="noopener noreferrer">please read the documentation.</a>
                  </p>
                </div>

                <p className='big-title no-border-top'>
                  <b>Incoming Email (IMAP)</b>
                </p>

                <div className="email-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title email-title no-margin-top">Server Address</b>
                    <p className="topic-val email-val">
                      {emailAccountDetail.channel.imapServerHost}
                    </p>
                  </div>

                  <div className="topic-wrapper">
                    <b className="topic-title email-title">Port</b>
                    <p className="topic-val email-val">
                      {emailAccountDetail.channel.imapServerPort}
                    </p>
                  </div>

                  <div className="topic-wrapper">
                    <b className="topic-title email-title">Protocol</b>
                    <p className="topic-val email-val">
                      {emailAccountDetail.channel.imapServerProtocol}
                    </p>
                  </div>

                  <NavLink to={`/o/${orgID}/integration/email/edit-mail-server-config/${emailAccountDetail.channel.id}`}>
                    <ButtonWithLoadingOrIcon
                      icon={{
                        type: "svg",
                        src: FiEdit
                      }}
                      className="no-fill-button main-button-40"
                      text="Edit"
                      position="left"
                    />
                  </NavLink>
                </div>

                <p className='big-title'>
                  <b>Outgoing Email (SMTP)</b>
                </p>

                <div className="email-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title email-title no-margin-top">Server Address</b>
                    <p className="topic-val email-val">
                      {emailAccountDetail.channel.smtpServerHost}
                    </p>
                  </div>

                  <div className="topic-wrapper">
                    <b className="topic-title email-title">Port</b>
                    <p className="topic-val email-val">
                      {emailAccountDetail.channel.smtpServerPort}
                    </p>
                  </div>

                  <div className="topic-wrapper">
                    <b className="topic-title email-title">Protocol</b>
                    <p className="topic-val email-val">
                      {emailAccountDetail.channel.smtpServerProtocol}
                    </p>
                  </div>

                  <NavLink to={`/o/${orgID}/integration/email/edit-mail-server-config/${emailAccountDetail.channel.id}`}>
                    <ButtonWithLoadingOrIcon
                      icon={{
                        type: "svg",
                        src: FiEdit
                      }}
                      className="no-fill-button main-button-40"
                      text="Edit"
                      position="left"
                    />
                  </NavLink>
                </div>
              </div>

              {/* channel button */}
              {(emailChannelLink || emailChannelLinkNeverSet) &&
                <div className="integration-email-detail-account-box-1 box-channel-button">
                  <div className="integration-email-detail-account-box-1-top">
                    <b>Channel Button for Live Chat</b>
                    <p className="bot-name">
                      For more details, <a className="link-orange" rel="noopener noreferrer" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/social-channel-button" target="_blank">please read the documentation.</a>
                    </p>

                    <div className="integration-email-detail-account-box-1-top-button">
                      {!emailChannelLinkNeverSet && emailChannelLink.isEnabled &&
                        <ButtonWithLoadingOrIcon
                          icon={{
                            type: "svg",
                            src: FiLink2
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Preview Link"
                          position="left"
                          onClickAction={() => {
                            window.open(getChannelLink(), "_blank");
                          }}
                        />
                      }

                      <NavLink to={`/o/${orgID}/integration/email/edit-channel-button/${emailAccountDetail.channel.id}`}>
                        <ButtonWithLoadingOrIcon
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Edit"
                          position="left"
                        />
                      </NavLink>
                    </div>
                  </div>


                  <div className="whatsapp-detail-channel-button">
                    <div className="channel-button-wrapper">
                      <b className="channel-button-title">Title</b>
                      <br />
                      <b>{emailChannelLinkNeverSet ? "Not Set" : (emailChannelLink.title === "" ? "Not Set" : emailChannelLink.title)}</b>
                    </div>

                    <div className="channel-button-wrapper">
                      <b className="channel-button-title">Email Address</b>
                      <br />
                      <b>{emailChannelLinkNeverSet ? "Not Set" : (emailChannelLink.targetID[0] === "6" ? "+" : "") + emailChannelLink.targetID}</b>
                    </div>

                    {/* <div className="channel-button-wrapper">
                      <b className="channel-button-title">First Message</b>
                      <br />
                      <b dangerouslySetInnerHTML={{
                        __html:
                          emailChannelLinkNeverSet ?
                            "Not Set"
                            :
                            (emailChannelLink.message === "" ? "Not Set" : HelperChat.lineBreakChatRoom(emailChannelLink.message))
                      }}
                      />
                    </div> */}

                    {/* <div className="channel-button-wrapper">
                      <b className="channel-button-title">Send Page URL</b>
                      <br />
                      <b>{emailChannelLinkNeverSet ? "Not Set" : (emailChannelLink.sendPageURL? "Yes" : "No")}</b>
                    </div>  */}

                    <div className="channel-button-wrapper status-wrapper">
                      <b className="channel-button-title">Status</b>
                      <br />

                      {emailChannelLinkNeverSet ?
                        <>
                          <img src={iconXgrey} alt="" />
                          <b className="status-value">Disabled</b>
                        </>
                        :
                        emailChannelLink.isEnabled ?
                          <>
                            <img src={statusConnected} alt="" />
                            <b className="status-value status-enabled">Enabled</b>
                          </>
                          :
                          <>
                            <img src={iconXgrey} alt="" />
                            <b className="status-value">Disabled</b>
                          </>
                      }
                    </div>
                  </div>
                </div>
              }
              {/* channel button */}

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/email/auto-message/setup/${match.params.accountID}`,
                    state: {
                      type: type
                    }
                  })
                }}
                data={closingMessageData}
                isLoading={loadingGetClosingMessage}
                type="closing"
                error={errorClosing}
              />

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/email/auto-message/setup/${match.params.accountID}`,
                    state: {
                      type: type
                    }
                  })
                }}
                data={ratingMessageData}
                isLoading={loadingGetRatingMessage}
                type="rating"
                error={errorRating}
              />

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/email/auto-resolve-setup/${match.params.accountID}`,
                    state: {
                      type: type,
                      medium: "email",
                      backToLink: `/o/${orgID}/integration/email/detail/${match.params.accountID}`
                    }
                  })
                }}
                data={autoResolveData}
                isLoading={loadingAutoResolve}
                type="autoResolve"
                error={errorAutoResolve}
              />

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/email/auto-unassign-setup/${match.params.accountID}`,
                    state: {
                      type: type,
                      medium: "email",
                      backToLink: `/o/${orgID}/integration/email/detail/${match.params.accountID}`
                    }
                  })
                }}
                data={autoUnassignData}
                isLoading={loadingAutoUnassign}
                type="autoUnassign"
                error={errorAutoUnassign}
              />
            </>
          }
        </div>
      </SectionWrap>
    </div>
  );
};

let mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

let mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationEmailDetailAccount);
