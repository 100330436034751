import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import { NavLink } from "react-router-dom";
import { Modal, ModalBody } from 'reactstrap';
import { FiUser, FiInfo, FiEdit, FiCopy, FiClock } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import "./AccountProfile.scss";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import { checkID, doToast, HelperCopy, scrollToClass } from "../../../../../helper/HelperGeneral";
import HelperInput from "../../../../../helper/HelperInput";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ColoredInfoBox from "../../../../reuseableComponent/coloredInfoBox/ColoredInfoBox";
import CopyBox from "../../../../reuseableComponent/copyBox/CopyBox";
import ErrorOrInfoComp from '../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import IntegrationGoogleServices from "../../../../../services/integration/IntegrationGoogleServices";
import iconCloseRed from '../../../../../assets/img/close-red-icon.svg';
import statusConnected from '../../../../../assets/img/integration/status-connected.svg';
import IconQr from "../../../../../assets/img/icon-qr.svg";

let AccountProfile = (props) => {
  const LANG = {
    id: "Indonesia",
    en: "English"
  };

  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [channelDetail, setChannelDetail] = useState(false);
  let [qrAndroid, setQrAndroid] = useState("");
  let [qrIOS, setQrIOS] = useState("");
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if(channelDetail) {
      QRCode.toDataURL(getTestUrlImage("android"))
        .then(url => {
          setQrAndroid(url);
        })
        .catch(err => {
          console.error(err)
        });

      QRCode.toDataURL(getTestUrlImage("ios"))
        .then(url => {
          setQrIOS(url);
        })
        .catch(err => {
          console.error(err)
        })
      }
  }, [channelDetail])

  useEffect(() => {
    if (orgID) {
      getAccountDetail(true);
    }
  }, [orgID]);

  let getAccountDetail = (loading = true) => {
    if(loading) setIsLoadingGetDetailData(true);

    let data = {
      id: Number(match.params.id),
    };

    IntegrationGoogleServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setChannelDetail(response.dataResult.data);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let [showModalAndroid, setShowModalAndroid] = useState(false)

  let toggleModalAndroid = () => {
    setShowModalAndroid(!showModalAndroid)
  }
  
  let modalTestAndroid = () => {
      return(
        <Modal isOpen={showModalAndroid} toggle={toggleModalAndroid} className={"modal-test"}>
          <ModalBody>
            <p className="modal-title">
              <b>Android Test URL</b>
            </p>

            <p className="modal-desc">
              Scan this barcode in your Android device
            </p>

            <div className="qr-wrapper">
              <img src={qrAndroid} alt="" />
            </div>

            <div className="qr-button-wrapper">
              <ButtonWithLoadingOrIcon 
                text="Close"
                onClickAction={toggleModalAndroid}
                className="no-fill-button main-button-40"
              />

              <ButtonWithLoadingOrIcon 
                text="Copy Android URL"
                icon={{
                  src: FiCopy,
                  type: "svg"
                }}
                position="left"
                className="orange-button main-button-40"
                onClickAction={() => HelperCopy(getTestUrlImage("android"), "Android URL Copied")}
              />
            </div>
          </ModalBody>
        </Modal>
      )
  }

  let [showModalIOS, setShowModalIOS] = useState(false)

  let toggleModalIOS = () => {
    setShowModalIOS(!showModalIOS)
  }

  let modalTestIOS = () => {
      return(
        <Modal isOpen={showModalIOS} toggle={toggleModalIOS} className={"modal-test"}>
           <ModalBody>
            <p className="modal-title">
              <b>iOS Test URL</b>
            </p>

            <p className="modal-desc">
              Scan this barcode in your iOS device
            </p>

            <div className="qr-wrapper">
              <img src={qrIOS} alt="" />
            </div>

            <div className="qr-button-wrapper">
              <ButtonWithLoadingOrIcon 
                text="Close"
                onClickAction={toggleModalIOS}
                className="no-fill-button main-button-40"
              />

              <ButtonWithLoadingOrIcon 
                text="Copy iOS URL"
                icon={{
                  src: FiCopy,
                  type: "svg"
                }}
                position="left"
                className="orange-button main-button-40"
                onClickAction={() => HelperCopy(getTestUrlImage("ios"), "iOS URL Copied")}
              />
            </div>
          </ModalBody>
        </Modal>
      )
  }

  let [showModalVerification, setShowModalVerification] = useState(false)
  let [employeeName, setEmployeeName] = useState("");
  let [errorEmployeeName, setErrorEmployeeName] = useState("");
  let [employeeEmail, setEmployeeEmail] = useState("");
  let [errorEmployeeEmail, setErrorEmployeeEmail] = useState("");
  let [businessURL, setBusinessURL] = useState("");
  let [errorBusinessURL, setErrorBusinessURL] = useState("");
  let [loadingVerification, setLoadingVerification] = useState(false);

  let toggleModalSendVerification = () => {
    setEmployeeName("");
    setErrorEmployeeName("");
    setEmployeeEmail("");
    setErrorEmployeeEmail("");
    setBusinessURL("");
    setErrorBusinessURL("");
    setShowModalVerification(!showModalVerification)
  }

  let getTestUrlImage = (type) => {
    let url = "";

    if(type === "android") {
      let _findIdx = channelDetail.profile.testUrls.findIndex(v => v.surface === "SURFACE_ANDROID_MAPS");
      
      url =  channelDetail.profile.testUrls[_findIdx].url;
    }

    if(type === "ios") {
      let _findIdx = channelDetail.profile.testUrls.findIndex(v => v.surface === "SURFACE_IOS_MAPS");
      
      url =  channelDetail.profile.testUrls[_findIdx].url;
    }

    return url;
  }

  let runRequestVerification = () => {
    setLoadingVerification(true);

    let data = {
      id: Number(match.params.id),
      contactName: employeeName,
      contactEmail: employeeEmail,
      websiteURL: businessURL
    };

    IntegrationGoogleServices.sendVerification(orgID, data, (response) => {
      if (response.dataResult.error.message === "") {
        if(response.dataResult.data.success) {
          toggleModalSendVerification();
          getAccountDetail();
        }else {
          doToast(response.dataResult.data.message, "fail");
        }
      } else {
        doToast(response.dataResult.error.message, "fail");
      }

      setLoadingVerification(false);
    });
  }

  let modalSendVerification = () => {
      let onClickSubmit = () => {
        let errorSubmitVerif = "";

        if(employeeName === "") {
          setErrorEmployeeName("This field is required")

          if(errorSubmitVerif === "") {
            errorSubmitVerif = "employee-name";
          }
        }

        if(!HelperInput.emailFormatValid(employeeEmail)) {
          setErrorEmployeeEmail("Invalid email format")

          if(errorSubmitVerif === "") {
            errorSubmitVerif = "employee-email";
          }
        }

        if(employeeEmail === "") {
          setErrorEmployeeEmail("This field is required")

          if(errorSubmitVerif === "") {
            errorSubmitVerif = "employee-email";
          }
        }

        if(!HelperInput.validURL(businessURL)) {
          setErrorBusinessURL("Invalid URL format")

          if(errorSubmitVerif === "") {
            errorSubmitVerif = "business-url";
          }
        }

        if(businessURL === "") {
          setErrorBusinessURL("This field is required")

          if(errorSubmitVerif === "") {
            errorSubmitVerif = "business-url";
          }
        }

        if(errorSubmitVerif !== "") {
          scrollToClass(`.${errorSubmitVerif}`);
        }else {
          runRequestVerification();
        }
      };

      return(
        <Modal isOpen={showModalVerification} toggle={toggleModalSendVerification} className={"modal-test modal-send-verification"}>
           <ModalBody>
            <p className="modal-title">
              <b>Request Verification</b>
            </p>

            <div className="form-verification-wrapper">
              <div className="form-verification-wrapper-inner">
                <ColoredInfoBox
                  text={"Provide contact information for an employee of the business to verify channel's information. The employee must have authority to verify that TapTalk.io have the right to control the channel representing the business. Please inform the contact submitted below to check email from Google."}
                  color={"blue"}
                  icon={<FiInfo />}
                />

                <label>
                  <b>Employee Name</b>
                </label>

                <input 
                  type="text"
                  placeholder="Type your name here"
                  value={employeeName}
                  className={`employee-name ${errorEmployeeName === "" ? "" : "border-red"}`}
                  id="employee-name"
                  onChange={(e) => {
                    setEmployeeName(e.target.value);
                    setErrorEmployeeName("");
                  }}
                  disabled={loadingVerification}
                />
                {errorEmployeeName !== "" &&
                  <ErrorOrInfoComp
                      text={errorEmployeeName}
                      _className={"font-red"}
                      icon={<FiInfo />}
                  />
                }

                <label>
                  <b>Employee Email</b>
                </label>

                <input 
                  type="text"
                  placeholder="Type your email here"
                  value={employeeEmail}
                  className={`employee-email ${errorEmployeeEmail === "" ? "" : "border-red"}`}
                  id="employee-email"
                  onChange={(e) => {
                    setEmployeeEmail(e.target.value);
                    setErrorEmployeeEmail("");
                  }}
                  disabled={loadingVerification}
                />
                {errorEmployeeEmail !== "" &&
                  <ErrorOrInfoComp
                      text={errorEmployeeEmail}
                      _className={"font-red"}
                      icon={<FiInfo />}
                  />
                }

                <label>
                  <b>Business URL</b>
                </label>

                <input 
                  type="text"
                  placeholder="https://www.example.com"
                  value={businessURL}
                  className={`business-url ${errorBusinessURL === "" ? "" : "border-red"}`}
                  id="business-url"
                  onChange={(e) => {
                    setBusinessURL(e.target.value);
                    setErrorBusinessURL("");
                  }}
                  disabled={loadingVerification}
                />
                {errorBusinessURL !== "" &&
                  <ErrorOrInfoComp
                      text={errorBusinessURL}
                      _className={"font-red"}
                      icon={<FiInfo />}
                  />
                }
              </div>
            </div>

            <div className="qr-button-wrapper">
              <ButtonWithLoadingOrIcon 
                text="Cancel"
                onClickAction={toggleModalSendVerification}
                className="no-fill-button main-button-40"
              />

              {loadingVerification ?
                <ButtonWithLoadingOrIcon 
                  text="Submit"
                  position="left"
                  className="orange-button main-button-40"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                />
                :
                <ButtonWithLoadingOrIcon 
                  text="Request Verification"
                  position="left"
                  className="orange-button main-button-40"
                  onClickAction={onClickSubmit}
                />
              }
            </div>
          </ModalBody>
        </Modal>
      )
  }

  return (
    isLoadingGetDetailData || retriveDataError.code ? 
      <RetrieveDataLoading
        isLoading={isLoadingGetDetailData}
        errorMessage={retriveDataError.message}
      />
      :
      <>
        <div className="account-profile-google sectionWrap">
          {modalTestAndroid()}
          {modalTestIOS()}
          {modalSendVerification()}
          <>
            <div className="account-profile-header">
              <div className="title-wrapper">
                <b>Channel Profile</b>
              </div>

              <div className="header-button-wrapper">
                {/* {channelDetail.profile.verificationStatus === "VERIFICATION_STATE_UNVERIFIED" && */}
                  <ButtonWithLoadingOrIcon 
                    isDisabled={channelDetail.profile.verificationStatus === "VERIFICATION_STATE_PENDING" || channelDetail.profile.verificationStatus === "VERIFICATION_STATE_VERIFIED"}
                    text="Edit"
                    icon={{
                      src: FiEdit,
                      type: "svg"
                    }}
                    position="left"
                    className="no-fill-button main-button-40"
                    onClickAction={() => props.history.push(`/o/${props.match.params.orgID}/integration/google-business-messages/detail/${props.match.params.id}/edit-channel-profile`)}
                  />
                {/* } */}

                {(
                  channelDetail.profile.verificationStatus === "VERIFICATION_STATE_UNVERIFIED"
                ) &&
                  <ButtonWithLoadingOrIcon 
                    text="Request Verification"
                    className="orange-button main-button-40"
                    onClickAction={toggleModalSendVerification}
                  />
                }
              </div>
            </div>
            <div className="account-profile-content">
              {(
                channelDetail.channel.status === "verified"
              ) &&
                <ColoredInfoBox
                  text={"Your channel has been verified and some fields can no longer be modified."}
                  color={"blue"}
                  icon={<FiInfo />}
                />
              }

              <label>
                <b>Display Name</b>
              </label>

              <p className="display-name">
                <b>{channelDetail.channel.displayName}</b>
              </p>

              <div className="account-profile-content-wrapper">
                <div className="label-wrapper">
                  <label>
                    <b>Profile Logo</b>
                  </label>
                </div>
                <div className="photo-wrapper">
                   {channelDetail.profile.logoUrl !== "" ?
                    <img src={channelDetail.profile.logoUrl} alt="" />
                    : 
                    <div className="profile-photo-empty">
                      <FiUser />
                    </div>
                  }
                </div>

                <label>
                  <b>Test URLs</b>
                </label>

                <ButtonWithLoadingOrIcon 
                  icon={{
                    src: IconQr,
                    type: "img"
                  }}
                  position="left"
                  text="Android"
                  className="no-fill-button main-button-40"
                  onClickAction={toggleModalAndroid}
                />

                <ButtonWithLoadingOrIcon 
                  icon={{
                    src: IconQr,
                    type: "img"
                  }}
                  position="left"
                  text="iOS"
                  className="no-fill-button main-button-40"
                  onClickAction={toggleModalIOS}
                />  

                <div className="verification-status">
                  <label>
                    <b>Verification Status</b>
                  </label>
                  
                  {channelDetail.profile.verificationStatus === "VERIFICATION_STATE_UNVERIFIED" ?
                    <>
                      <b className="status-value font-red">
                        <img src={iconCloseRed} alt="" />
                        {channelDetail.profile.verificationStatusText}
                      </b>
                    </>
                    : 
                    (
                      channelDetail.profile.verificationStatus === "VERIFICATION_STATE_PENDING" ?
                        <>
                          <b className="status-value font-yellow">
                            <FiClock />
                            {channelDetail.profile.verificationStatusText}
                          </b>
                        </>
                        :
                        <>
                          <b className="status-value font-green">
                            <img src={statusConnected} alt="" />
                            {channelDetail.profile.verificationStatusText}
                          </b>
                        </>
                    )
                  }
                </div>
              </div>
            </div>
          </>
        </div>

        <div className="account-profile-google sectionWrap">
                  
            <>
              <div className="account-profile-header account-profile-header-bottom">
                <div className="title-wrapper">
                  <b>Greeting Message</b>
                  <p>
                    Google will automatically send this greeting message when a customer initiate chat with you. 
                  </p>
                </div>

                <div className="header-button-wrapper">
                  <NavLink to={`/o/${props.match.params.orgID}/integration/google-business-messages/detail/${props.match.params.id}/edit-greeting-message`}>
                    <ButtonWithLoadingOrIcon 
                      text="Edit"
                      icon={{
                        src: FiEdit,
                        type: "svg"
                      }}
                      position="left"
                      className="no-fill-button main-button-40"
                    />
                  </NavLink>
                </div>
              </div>
              
              {channelDetail.profile.conversationalSettings.map((v, i) => {
                return (
                  <div className={`account-profile-content ${i > 0 ? "account-profile-content-with-border" : ""}`} key={`greeting-${i}`}>
                    <label>
                      <b>Language{i > 0 ? `#${i + 1}` : ""}</b>
                    </label>

                    <p className="display-name">
                      <b>{LANG[v.languageCode]} {v.languageCode === channelDetail.profile.defaultLocale ? `(Default)` : ""}</b>
                    </p>

                    <label>
                      <b>Message</b>
                    </label>

                    <p className="display-name">
                      <b>{v.welcomeMessage}</b>
                    </p>

                    <label>
                      <b>Privacy Policy URLs</b>
                    </label>

                    <CopyBox 
                      id={`url-${i}`}
                      val={v.privacyPolicyURL}
                      copyText="Privacy Policy URLs Copied"
                    />
                  </div>
                )
              })}
            </>
        </div>
      </>
  )
};

export default AccountProfile;
