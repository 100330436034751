import BaseService from "../BaseService";
import { API_KEY } from "../../config";
// import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";

class ServiceAuthAccessToken {
    postRequestAccessToken(param) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Authorization": "Basic " + btoa(param)
        };

        return (
            BaseService.doPost(API.REQUEST_ACCESS_TOKEN, param, headers)
                    .then(response => {
                        return {
                            dataResult:  response.data,
                        }
                    })
                    .catch(error => {
                        return BaseService.generateErrorMessage(error);
                    })
        )
    }
    
    postLogout(accessToken) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Authorization": "Bearer " + accessToken
        };
        
        return (
            BaseService.doPostAccessTokenNoWebWorker(API.LOGOUT, {}, headers)
                .then(response => {
                    return {
                        dataResult: response.data,
                    }
                })
                .catch(error => {
                    return BaseService.generateErrorMessage(error);
                })
        );
    }
}

const API = {
    REQUEST_ACCESS_TOKEN: "/auth/access_token/request",
    LOGOUT: "/account/logout"
}

export default new ServiceAuthAccessToken()