import React, { useEffect, useState } from "react";
import "../../auth/Auth.css";
import "./MemberInvite.scss";
import iconInvitationSuccess from "../../../assets/img/icon-invitation-sent.svg";
import { FiInfo } from "react-icons/fi";
import { Modal, ModalBody } from "reactstrap";
import { checkID, doToast, scrollToClass } from "../../../helper/HelperGeneral";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SelectTopic from "../../reuseableComponent/selectTopic/SelectTopic";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import MemberService from "../../../services/newServices/MemberServices";
import TopicService from "../../../services/newServices/TopicServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceGroupRole from "../../../services/organization/ServiceGroupRole";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import HelperInput from "../../../helper/HelperInput";
// import Loading from "../reuseableComponent/loading/Loading";
import mixpanel from "mixpanel-browser";

let MEMBER_ROLE = {
  admin: "admin",
  agent: "member",
  custom: "custom"
}

let MemberInvite = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [isOpenModalSendInvitation, setIsOpenModalSendInvitation] = useState(false);
  let [memberEmail, setMemberEmail] = useState("");
  let [memberAlias, setMemberAlias] = useState("");
  let [memberRole, setMemberRole] = useState("");
  let [memberEmailOrigin, setMemberEmailOrigin] = useState("");
  let [memberAliasOrigin, setMemberAliasOrigin] = useState("");
  let [memberRoleOrigin, setMemberRoleOrigin] = useState("");
  let [memberCustomRole, setMemberCustomRole] = useState([]);
  let [memberCustomRoleOrigin, setMemberCustomRoleOrigin] = useState([]);
  let [isWaitLoadingTopicList, setIsWaitLoadingTopicList] = useState(false);
  let [isWaitResponseInviteMember, setIsWaitResponseInviteMember] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [isCheckAll, setIsCheckAll] = useState(false);
  let [selectedTopic, setSelectedTopic] = useState([]);
  let [topicListOrigin, setTopicListOrigin] = useState([]);
  let [topicList, setTopicList] = useState([]);
  let [searchTopic, setSearchTopic] = useState("");
  let [errorMessageData, setErrorMessageData] = useState({
    email: "",
    role: "",
    topic: "",
    alias: "",
    memberCustomRole: ""
  })
  let [moduleList, setModuleList] = useState([]);

  let back = () => history.push(`/o/${orgID}/organization/members`);

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (orgID) {
      getTopic();
    }
  }, [orgID])

  let getModule = () => {
    let _retriveDataError = { ...retriveDataError };

    ServiceGroupRole.postGetGroupRole(orgID, (res) => {
      let dataResult = res.dataResult;

      if (dataResult.error.message === "") {
        setModuleList(dataResult.data.roles);

        setIsWaitLoadingTopicList(false);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    })
  }

  let getTopic = () => {
    setIsWaitLoadingTopicList(true);

    TopicService.getTopic(orgID, "list", null, (response) => {
      if (response.dataResult.error.message === "") {
        setTopicListOrigin(response.dataResult.data.topics);
        setTopicList(response.dataResult.data.topics);

        getModule();
      } else {
        let _retriveDataError = { ...retriveDataError };
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    });
  }

  let toggleModalSendInvitation = () => {
    setIsOpenModalSendInvitation(!isOpenModalSendInvitation);
  }

  let onChangeEmailMember = (e) => {
    if (HelperInput.noSpace(e.target.value)) {
      let _errorMessageData = { ...errorMessageData };
      _errorMessageData.email = "";
      setErrorMessageData(_errorMessageData);
      setMemberEmail(e.target.value);
    }
  };

  let onChangeEmailAlias = (e) => {
    let _errorMessageData = { ...errorMessageData };
    _errorMessageData.alias = "";
    setErrorMessageData(_errorMessageData);
    setMemberAlias(e.target.value);
  };

  let onChangeRoleMember = (e) => {
    let _errorMessageData = { ...errorMessageData };
    _errorMessageData.role = "";
    _errorMessageData.memberCustomRole = "";
    setErrorMessageData(_errorMessageData);

    if (e.target.id !== "role-custom") {
      setMemberCustomRole([]);
    }

    setMemberRole(e.target.value);
  }

  let onSubmitInviteMember = () => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    if (memberEmail === "") {
      _errorMessageData.email = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-email" }
    }

    if (memberEmail.length > 250) {
      _errorMessageData.email = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-email" }
    }

    if (memberAlias.length > 25) {
      _errorMessageData.alias = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-alias" }
    }

    if (memberRole === "") {
      _errorMessageData.role = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-role" }
    }

    if (memberRole === MEMBER_ROLE.custom) {
      if (memberCustomRole.length === 0) {
        _errorMessageData.memberCustomRole = "This field is required";
        errorCount = errorCount + 1;

        if (errorClass === "") { errorClass = "input-role" }
      }
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      setIsWaitResponseInviteMember(true);

      let _selectedTopic = [...selectedTopic]
      let _selectedCustomRole = [...memberCustomRole];
      let listTopicID = _selectedTopic.map(item => item.id)
      let listRoleID = _selectedCustomRole.map(item => item.id)

      let dataMember = {
        email: memberEmail,
        alias: memberAlias,
        roleCode: memberRole,
        topicIDs: listTopicID,
        customRoleIDs: listRoleID
      };

      MemberService.addMember(orgID, dataMember, (response) => {
        if (response.dataResult.status === 200) {
          // setMemberDetail(data.data.member);
          if (response.dataResult.data.success) {
            if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
              mixpanel.track(
                "[Action] Invite Member",
                {
                  userFullName: myAgentData.account.fullName,
                  userID: myAgentData.account.id,
                  organizationID: orgID.split("-")[0]
                }
              );
            }
            // history.push({
            //   pathname: `/o/${orgID}/organization/members`,
            //   state: {
            //     inviteMessage: response.dataResult.data.message,
            //     success: response.dataResult.data.success
            //   }
            // });
            setMemberEmailOrigin(memberEmail);
            setMemberRoleOrigin(memberRole);
            setTopicListOrigin(topicList);
            setMemberAliasOrigin(memberAlias);
            setMemberCustomRoleOrigin(memberCustomRole);
            toggleModalSendInvitation();
          } else {
            doToast(response.dataResult.data.message, 'fail');
          }

          setIsWaitResponseInviteMember(false);
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setIsWaitResponseInviteMember(false);
        }
      });
    }
  }

  // let isButtonSubmitDisabled = () => {
  //   return (
  //     (memberEmail.length === 0 || memberRole.length === 0 || !HelperInput.emailFormatValid(memberEmail)) ? true : false
  //   )
  // }

  let modalInvitationSuccess = () => {
    return (
      <Modal isOpen={isOpenModalSendInvitation} className="modal-invitation-sent" toggle={back}>
        <ModalBody>
          <img src={iconInvitationSuccess} alt="" />

          <br />
          <b>
            Invitation Sent!
          </b>
          <p>
            Once the invited member has created an account, don’t forget to assign them onto available topics, so they can start receiving and replying to messages.
          </p>

          <ButtonWithLoadingOrIcon
            onClickAction={back}
            className="orange-button main-button-40"
            text="Got it, Thanks!"
          />
        </ModalBody>
      </Modal>
    )
  }

  let onCheckAll = (e) => {
    if (!isWaitResponseInviteMember) {
      if (e.target.checked) {
        setSelectedTopic(topicList);
      } else {
        setSelectedTopic([]);
      }

      let _errorMessageData = { ...errorMessageData };
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
      setIsCheckAll(e.target.checked);
    }
  }

  let onClearAll = () => {
    let _errorMessageData = { ...errorMessageData };
    _errorMessageData.topic = "";
    setErrorMessageData(_errorMessageData);
    setIsCheckAll(false);
    setSelectedTopic([]);
  }

  let isChecked = (id) => {
    let _data = [...selectedTopic]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let onChangeSingleSelect = (e, val) => {
    if (!isWaitResponseInviteMember) {
      let _errorMessageData = { ...errorMessageData };
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
      let _selectedTopic = [...selectedTopic]

      if (e.target.checked) {
        _selectedTopic = [..._selectedTopic, val]
      } else {
        let _dataSelectedTopic = [..._selectedTopic]
        _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
        setIsCheckAll(false);
      }

      setSelectedTopic(_selectedTopic);
    }
  }

  let removeSingleTopic = (val) => {
    setIsCheckAll(false);
    let _selectedTopic = [...selectedTopic];
    let _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    setSelectedTopic(_selectedTopic);
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
      for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
          if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
          }
        }
      }
    }

    setTopicList(result);
  }

  let onSelectRole = (data, e) => {
    let _errorMessageData = { ...errorMessageData };
    let _memberCustomRole = memberCustomRole.slice();

    if (e.target.checked) {
      _memberCustomRole.push(data)
    } else {
      let _findIndex = _memberCustomRole.findIndex(v => v.id === data.id);

      if (_findIndex !== -1) {
        _memberCustomRole.splice(_findIndex, 1);
      }
    }

    _errorMessageData.role = "";
    _errorMessageData.memberCustomRole = "";

    setMemberRole(MEMBER_ROLE.custom);
    setMemberCustomRole(_memberCustomRole);
    setErrorMessageData(_errorMessageData);
  }

  let isCheckedRole = (id) => {
    let checked = false;

    if (memberCustomRole.findIndex(v => v.id === id) !== -1) {
      checked = true;
    }

    return checked;
  }

  return (
    <>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={Object.assign({ email: memberEmailOrigin }, { role: memberRoleOrigin }, { topic: topicListOrigin }, { alias: memberAliasOrigin }, { customRole: memberCustomRoleOrigin })}
        dataAfterChange={Object.assign({ email: memberEmail }, { role: memberRole }, { topic: topicList }, { alias: memberAlias }, { customRole: memberCustomRole })}
      >
        <BackButton text="Back to members" onClick={back} />
        {modalInvitationSuccess()}

        <div className="sectionWrap invite-member-wrapper">
          {isWaitLoadingTopicList || retriveDataError.code ?
            <RetrieveDataLoading
              noPaddingTop={true}
              isLoading={isWaitLoadingTopicList}
              errorMessage={retriveDataError.message}
            />
            :
            <>
              <div className="form-title-wrapper">
                <b>Invite Member</b>

                {isWaitResponseInviteMember ?
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    position="left"
                    className="orange-button main-button-40 minWidth180"
                    text="Send Invitation"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40 minWidth180"
                    text="Send Invitation"
                    onClickAction={onSubmitInviteMember}
                  />
                }
              </div>

              <div className="form-content-wrapper form-content-wrapper-label16 form-member-invite">
                <form>
                  <label className="pos-relative width-300">
                    <b>Email Address</b>
                    <span className={errorMessageData.email === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{memberEmail.length}/250 characters</span>
                  </label>
                  <input disabled={isWaitResponseInviteMember} className={`input-email ${errorMessageData.email !== "" ? "border-red" : ""}`} type="text" placeholder="your@email.com" value={memberEmail} onChange={(e) => {
                    onChangeEmailMember(e);
                  }} />

                  {errorMessageData.email !== "" &&
                    <ErrorOrInfoComp
                      text={errorMessageData.email}
                      _className={"font-red"}
                      icon={<FiInfo />}
                    />
                  }

                  <label className="pos-relative width-300">
                    <b>Alias</b>
                    <span className="grey-font"> (optional)</span>
                    <span className={errorMessageData.alias === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{memberAlias.length}/25 characters</span>
                  </label>
                  <input disabled={isWaitResponseInviteMember} className={`input-alias ${errorMessageData.alias !== "" ? "border-red" : ""}`} type="text" placeholder="Alias for this member" value={memberAlias} onChange={(e) => {
                    onChangeEmailAlias(e);
                  }} />

                  <ErrorOrInfoComp
                    text={errorMessageData.alias === "" ? "Adding alias will replace agent’s name in chat room" : errorMessageData.alias}
                    _className={errorMessageData.alias === "" ? "" : "font-red"}
                    icon={<FiInfo />}
                  />

                  <label className="input-role"><b>Role</b></label>
                  <div className={`custom-radio ${isWaitResponseInviteMember ? "custom-radio-disabled" : ""} ${errorMessageData.role === "" ? "" : "custom-radio-error"}`}>
                    <input disabled={isWaitResponseInviteMember} type="radio" id="role-admin" name="role-radio" checked={memberRole === MEMBER_ROLE.admin} value={MEMBER_ROLE.admin} onChange={(e) => onChangeRoleMember(e)} />
                    <label htmlFor="role-admin">
                      <div className="role-radio-label">
                        <b className={(isWaitResponseInviteMember) ? "grey-text" : ""}>Admin</b>
                        <span className={(isWaitResponseInviteMember) ? "grey-text-04" : ""}>Full access to all permissions.</span>
                      </div>
                    </label>

                    <input disabled={isWaitResponseInviteMember} type="radio" id="role-agent" name="role-radio" checked={memberRole === MEMBER_ROLE.agent} value={MEMBER_ROLE.agent} onChange={(e) => onChangeRoleMember(e)} />
                    <label htmlFor="role-agent">
                      <div className="role-radio-label">
                        <b className={(isWaitResponseInviteMember) ? "grey-text" : ""}>Agent</b>
                        <span className={(isWaitResponseInviteMember) ? "grey-text-04" : ""}>Access to inbox and Personal Quick Replies</span>
                      </div>
                    </label>

                    <div className={`custom-radio ${moduleList.length === 0 ? "custom-radio-inactive" : ""} ${errorMessageData.role === "" ? "" : "custom-radio-error"}`}>
                      <input disabled={isWaitResponseInviteMember || moduleList.length === 0} type="radio" id="role-custom" name="role-radio" checked={memberRole === MEMBER_ROLE.custom} value={MEMBER_ROLE.custom} onChange={(e) => onChangeRoleMember(e)} />
                      <label htmlFor="role-custom">
                        <div className={`role-radio-label ${moduleList.length === 0 ? "" : ""}`}>
                          <b className={(isWaitResponseInviteMember || moduleList.length === 0) ? "grey-text" : ""}>Custom</b>
                          <span className={(isWaitResponseInviteMember || moduleList.length === 0) ? "grey-text-04" : ""}>Access to custom permissions from selected roles.</span>
                        </div>
                      </label>
                    </div>

                    {moduleList.length > 0 ?
                      <div className="role-list-wrapper custom-checkbox custom-checkbox-child-wrapper">
                        {moduleList.map((v, i) => {
                          return (
                            <div className={`custom-checkbox custom-checkbox-child ${errorMessageData.memberCustomRole !== "" ? "border-redd" : ""}`} key={`child-role-${v.id}-${i}`}>
                              <input
                                type="checkbox"
                                checked={isCheckedRole(v.id)}
                                id={`child-role-${v.id}`}
                                onChange={(e) => onSelectRole(v, e)}
                                // disabled={isWaitResponseInviteMember || memberRole !== MEMBER_ROLE.custom}
                                disabled={isWaitResponseInviteMember}
                              />
                              <label htmlFor={`child-role-${v.id}`} className={(isWaitResponseInviteMember) ? `grey-textt` : ""}>
                                {v.name}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                      :
                      <ErrorOrInfoComp
                        icon={<FiInfo />}
                        _className="there-is-no-custom-role"
                        text="You don’t have any custom roles yet."
                      />
                    }
                  </div>

                  {errorMessageData.role !== "" &&
                    <ErrorOrInfoComp
                      text={errorMessageData.role}
                      _className={"font-red"}
                      icon={<FiInfo />}
                    />
                  }

                  {errorMessageData.memberCustomRole !== "" &&
                    <ErrorOrInfoComp
                      text={errorMessageData.memberCustomRole}
                      _className={"font-red"}
                      icon={<FiInfo />}
                    />
                  }

                  <SelectTopic
                    onChangeSearchTopic={onChangeSearchTopic}
                    onCheckAll={onCheckAll}
                    onClearAll={onClearAll}
                    topicList={topicList}
                    onChangeSingleSelect={onChangeSingleSelect}
                    isChecked={isChecked}
                    isCheckAll={isCheckAll}
                    searchTopic={searchTopic}
                    title={"Assign Topics"}
                    isTitleOptional
                    selectedTopic={selectedTopic}
                    removeSingleTopic={removeSingleTopic}
                    _className={"input-topic"}
                    isDisabled={isWaitResponseInviteMember}
                  />

                  {errorMessageData.topic !== "" &&
                    <ErrorOrInfoComp
                      text={errorMessageData.topic}
                      _className={"font-red"}
                      icon={<FiInfo />}
                    />
                  }
                </form>
              </div>
            </>
          }
        </div >
      </SectionWrap >
    </>
  );
};

export default MemberInvite;