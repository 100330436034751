import React, { useState, useRef, useEffect } from 'react';
import './IntegrationTelegramAddAccount.scss';
import 'react-toastify/dist/ReactToastify.css';
import IntegrationTelegramServices from '../../../../services/integration/IntegrationTelegramServices';
import topicServices from '../../../../services/newServices/TopicServices';
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import Tooltip from '../../../reuseableComponent/Tooltip/Tooltip';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import CustomSelectOption from '../../../reuseableComponent/customSelectOption/CustomSelectOption';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import { connect } from 'react-redux';
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import mixpanel from "mixpanel-browser";

const IntegrationTelegramAddAccount = (props) => {
  const { match, history } = props;
  const [topicList, setTopicList] = useState([]);
  const [isLoadingCreateAccount, setIsLoadingCreateAccount] = useState(false);
  const [orgID, setOrgID] = useState(false);
  const [accountTopicVal, setAccountTopicVal] = useState({
    label: '',
    value: '',
  });
  const [accountTopicValOrigin, setAccountTopicValOrigin] = useState({
    label: '',
    value: '',
  });
  const botTokenInput = useRef(null);
  const [isValidate, setIsValidate] = useState('validate');
  const [inputWidth, setInputWidth] = useState('721px');
  const [tokenInputBorderColor, setTokenInputBorderColor] = useState(
    'solid 1px #dcdcdc'
  );
  const [validateColor, setValidateColor] = useState('rgba(25, 25, 25, 0.2)');
  const [isTokenInputDisabled, setIsTokenInputDisabled] = useState(false);
  const [inputBotTokenValue, setInputBotTokenValue] = useState('');
  const [inputBotTokenValueOrigin, setInputBotTokenValueOrigin] = useState('');
  const [botName, setBotName] = useState(
    "This field will be filled when you're connected with Telegram"
  );
  const [botUsername, setBotUsername] = useState(
    "This field will be filled when you're connected with Telegram"
  );
  const [errorMessageData, setErrorMessageData] = useState({
    topic: "",
    token: ""
  }) 

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getTopics(orgID);
    }
  }, [orgID]);

  useEffect(() => {
    const width = `${botTokenInput.current.offsetWidth -
      botTokenInput.current.lastElementChild.offsetWidth}px`;
    setInputWidth(width);
  }, [isValidate]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (botTokenInput.current.firstChild.contains(event.target)) {
        if (isValidate === 'validate') {
          setTokenInputBorderColor('1px solid #FF7E00');
          setValidateColor('#FF7E00');
        }
        return;
      }

      setTokenInputBorderColor('solid 1px #dcdcdc');

      if (inputBotTokenValue !== '') {
        setValidateColor('#FF7E00');
      } else {
        setValidateColor('rgba(25, 25, 25, 0.2)');
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, [isValidate, inputBotTokenValue]);

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const onValidate = () => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Button] Validate Bot Token",
        {
          userFullName : myAgentData.account.fullName,
          userID : myAgentData.account.id,
          organizationID : orgID.split("-")[0]
        }
      );
    }

    if (inputBotTokenValue === '') return;
    if (isValidate === 'valid') return;
    setIsValidate('validating');
    setIsTokenInputDisabled(true);

    IntegrationTelegramServices.validateBotToken(
      orgID,
      inputBotTokenValue,
      (response) => {
        setIsTokenInputDisabled(false);
        if (response.dataResult.status === 200) {
          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Validate Bot Token",
              {
                status : response.dataResult.data.success ? "Success" : "Fail",
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
              }
            );
          }

          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
            setIsValidate('validate');
          } else {
            setIsValidate('valid');
            setBotName(response.dataResult.data.botName);
            setBotUsername(response.dataResult.data.botUsername);
            doToast(response.dataResult.data.message);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setIsValidate('validate');
        }
      }
    );
  };

  const getTopics = async (orgID) => {
    topicServices.getTopic(orgID, 'list', null, (response) => {
      const data = response.dataResult.data.topics;
      const createList = () => {
        let newData = [];
        data.map((val) => {
          let _data = {};
          _data.value = val.id;
          _data.label = val.name;
          newData.push(_data);
          return null;
        });
        setTopicList(newData);
      };

      createList();
    });
  };

  const createTelegramAccount = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(accountTopicVal.value === "") {
        _errorMessageData.topic = "Please select topic";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-topic" }
    }

    if(inputBotTokenValue === "") {
        _errorMessageData.token = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-token" }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      setIsLoadingCreateAccount(true);
      setIsTokenInputDisabled(true);

      IntegrationTelegramServices.create(
        orgID,
        accountTopicVal.value,
        inputBotTokenValue,
        (response) => {
          setIsTokenInputDisabled(false);

          if (response.dataResult.status === 200) {
            if (!response.dataResult.data.success) {
              setIsLoadingCreateAccount(false);
              doToast(response.dataResult.data.message);
            } else {
              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Add channel",
                  {
                    channel: "Telegram",
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : orgID.split("-")[0]
                  }
                );
              }
              doToast(response.dataResult.data.message);
              setAccountTopicValOrigin(accountTopicVal);
              setInputBotTokenValueOrigin(inputBotTokenValue);
              runGetOrganizationDetail(response.dataResult);
            }
          } else {
            setIsLoadingCreateAccount(false);
            setIsValidate('validate');
            doToast(response.dataResult.error.message, 'fail');
          }
        }
      );
    }
  };

  let runGetOrganizationDetail = (responseResult) => {
    ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {
      if(response.dataResult.status === 200) {
        props.setAllOrganizationDetail(response.dataResult.data);

        setTimeout(() => {
          history.push(
            `/o/${orgID}/integration/telegram/detail/${responseResult.data.id}`,
            {
              state: 'create',
              message: responseResult.data.message,
            }
          );
        }, 2000)
      }else {
        doToast(response.dataResult.error.message, "fail")
      }
    })
  }

  const onSelectTopic = (value) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.topic = "";
    setErrorMessageData(_errorMessageData);
    setAccountTopicVal(value);
  };

  const validateStyled = () => {
    if (isValidate === 'validate') {
      return <div>Validate</div>;
    } else if (isValidate === 'validating') {
      return (
        <div className="validating">
          <div className="lds-ring grey-lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div>Validating</div>
        </div>
      );
    } else if (isValidate === 'valid') {
      return (
        <div className="valid">
          <div
            className={`${isTokenInputDisabled ? 'valid-grey' : 'valid-green'}`}
          ></div>
          <div
            className={`${isTokenInputDisabled ? 'text-grey' : 'text-green'}`}
          >
            Valid
          </div>
        </div>
      );
    }
  };

  const labelWebPlatform = (
    <div>
      <FaRegQuestionCircle />
    </div>
  );

  const titleTooltip = (
    <div style={{ padding: '8px 12px' }}>
      <p style={{ margin: 0 }}>To obtain Telegram Bot Token please</p>
      <a
        href="https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/telegram-integration"
        className="orange"
        target="_blank"
        rel="noopener noreferrer"
      >
        check out the step-by-step guide
      </a>
    </div>
  );

  const propsTip = {
    content: titleTooltip,
    children: labelWebPlatform,
  };

  const back = () => history.push(`/o/${orgID}/integration/telegram`);

  // const isFormValid = () => {
  //   const _accountTopicVal = accountTopicVal.value;
  //   return isValidate === 'valid' && _accountTopicVal !== '';
  // };

  const onChangeInputBotToken = (event) => {
    const prevInput = inputBotTokenValue;
    if (event.target.value !== prevInput) {
      setIsValidate('validate');
    }
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.token = "";
    setErrorMessageData(_errorMessageData);
    setInputBotTokenValue(event.target.value);
  };

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID} 
        withHeader 
        withSideMenu
        dataOrigin={{
          topic: accountTopicValOrigin,
          bot: inputBotTokenValueOrigin
        }}
        dataAfterChange={{
          topic: accountTopicVal,
          bot: inputBotTokenValue
        }}
      >
        {/* <Integrations activeIntegrationChannel="telegramwhatsapp" parentProps={props} orgIDProps={orgID} /> */}

        <div className="sectionWrap integration-main-container integration-telegram-add-account text-left">
          <BackButton text="Back to Telegram" onClick={back} />

          <div className="integration-telegram-add-account-box">
            <div className="integration-telegram-add-account-box-top">
              <b>Add Channel</b>

              {!isLoadingCreateAccount ? (
                <ButtonWithLoadingOrIcon 
                  // isDisabled={!isFormValid()}
                  onClickAction={createTelegramAccount}
                  className="orange-button main-button-40"
                  text="Connect"
                />
              ) : (
                <ButtonWithLoadingOrIcon 
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  className="orange-button main-button-40"
                  text="Connect"
                  position="left"
                />
              )}
            </div>

            <div className="integration-telegram-add-account-box-form">
              {/* <label>
                <b>Topic</b>
              </label>
              <input
                type="text"
                placeholder="Your telegram account name"
                // onChange={(e) => {
                //   onChangeAccountName(e);
                // }}
              />
              <span className="integration-info-form">
                Account Name is used to differentiate the various accounts
                linked to this particular channel.
              </span> */}

              <div className="form-topic">
                <label>
                  <b>Topic</b>
                </label>
                <CustomSelectOption
                  optionListProps={topicList}
                  valueDropdownProps={accountTopicVal}
                  placeholderProps={'Select account topic'}
                  onClickDropDownListOptionProps={onSelectTopic}
                  specificClassNameProps="custom-select-telegram-topic"
                  isDisabled={isLoadingCreateAccount}
                  _className={`input-topic ${errorMessageData.topic === "" ? "" : "border-red"}`}
                />
                
                <ErrorOrInfoComp
                    text={errorMessageData.topic === "" ? "Incoming messages on this channel will be assigned to this topic." : errorMessageData.topic}
                    _className={errorMessageData.topic !== "" ? "font-red margin-top-0" : "margin-top-0"}
                    icon={<FiInfo />}
                />
                {/* <span className="integration-info-form">
                  Incoming messages on this channel will be assigned to this
                  topic.
                </span> */}
              </div>

              <div className="form-bot-token">
                <label>
                  <b className="bot-token-title">Bot Token</b>
                  <Tooltip {...propsTip} interactive />
                </label>
                <div className={`bot-token-wrapper input-token ${errorMessageData.token !== "" ? "border-red" : ""}`} ref={botTokenInput}>
                  <input
                    type="text"
                    placeholder="Insert your Bot Token"
                    style={{ width: inputWidth, border: tokenInputBorderColor }}
                    disabled={isTokenInputDisabled || isLoadingCreateAccount}
                    onChange={onChangeInputBotToken}
                  />
                  <button
                    onClick={onValidate}
                    style={{
                      border: tokenInputBorderColor,
                      color: validateColor,
                    }}
                    disabled={isTokenInputDisabled}
                  >
                    {validateStyled()}
                  </button>
                </div>
                
                {errorMessageData.token !== "" &&
                  <ErrorOrInfoComp
                      text={errorMessageData.token}
                      _className={"font-red margin-top-0"}
                      icon={<FiInfo />}
                  />
                }
              </div>

              <div className="form-bot-username">
                <label>
                  <b>Bot Username</b>
                </label>
                <input
                  type="text"
                  value={botUsername}
                  disabled
                  className="form-disabled"
                />
              </div>

              <div className="form-bot-name">
                <label>
                  <b>Bot Name</b>
                </label>
                <input type="text" value={botName} disabled />
              </div>
            </div>
          </div>
        </div>
      </SectionWrap>
    </div>
  );
};

const mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

const mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTelegramAddAccount);