import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ChatRoomMessageInBroadcastImage.scss';
import { FaReply } from 'react-icons/fa';
// import { MdClose } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';
import { IoIosClose } from "react-icons/io";
import BroadcastGrey from "../../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg";
import Helper from '../../../../helper/HelperChat';
// import { MdInsertDriveFile } from 'react-icons/md';
import { taptalk, tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import iconAgent from '../../../../assets/img/chatroom/icon-agent.svg';
import iconChatbot from '../../../../assets/img/chatroom/icon-chatbot.svg';
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageInBroadcastImage = (props) => {
    let {
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        // activeRoom
    } = props;

    let [percentageDownload, setPercentageDownload] = useState(0);
    let [imageSrc, setImageSrc] = useState('');
    let [isImageExistInDB, setIsImageExistInDB] = useState(false);
    let [onDownloadImageProgress, setOnImageDownloadProgress] = useState(false);
    let [imageFromUrl, setImageFromUrl] = useState(false);
    let [isShowModalImage, setIsShowModalImage] = useState(false);

    useEffect(() => {
        let isUnmounted = false;
        let fetchFromDB = () => {
            if (singleChatDataProps.data.fileID) {
                tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function (data) {
                    if (data) {
                        if (!isUnmounted) {
                            setImageSrc(data.file);
                            setIsImageExistInDB(true);
                        }

                    } else {
                        if (!isUnmounted) {
                            setImageSrc(singleChatDataProps.data.thumbnail);
                            setIsImageExistInDB(false);
                        }
                    }
                })
            }
        }

        if (singleChatDataProps.data.url) {
            setImageFromUrl(true);
            setImageSrc(singleChatDataProps.data.url);
        } else if (singleChatDataProps.data.fileURL) {
            if (singleChatDataProps.data.fileURL !== "") {
                setImageFromUrl(true);
                setImageSrc(singleChatDataProps.data.fileURL);
            } else {
                setImageFromUrl(false);
                fetchFromDB();
            }
        } else {
            fetchFromDB();
        }

        return () => {
            isUnmounted = true;
        }
    }, [singleChatDataProps])

    let messageActionView = (message) => {
        let onClickReply = () => {
            onReplyMessage(message)
        }

        return (
            <div
                className={`message-action-wrapper message-action-right`}
            >
                <div className="message-action-button-wrapper reply-button" title="Reply" onClick={() => onClickReply()}>
                    <FaReply />
                </div>
            </div>
        )
    }

    let downloadFile = () => {
        setOnImageDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnImageDownloadProgress(false);
                setImageSrc(data.base64);
                setIsImageExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
            },

            onError: (errorCode, errorMessage) => {
                setOnImageDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    //   let getImageBase64 = () => {
    //     tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
    //         if(data) {
    //             setImageSrc(data.file);
    //             setIsImageExistInDB(true);
    //         }else {
    //             setImageSrc(singleChatDataProps.data.thumbnail);
    //             setIsImageExistInDB(false);
    //         }
    //     })
    //   }

    let toggleModalImage = () => {
        if (isImageExistInDB || imageFromUrl) {
            setIsShowModalImage(!isShowModalImage);
        }
    }

    let generateModalImage = () => {
        let zoomImage = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '200%';
            var offsetX = e.nativeEvent.offsetX;
            var offsetY = e.nativeEvent.offsetY;

            var x = offsetX / zoomer.offsetWidth * 100
            var y = offsetY / zoomer.offsetHeight * 100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        }

        let zoomImageOut = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '0%';
        }

        return (
            <div>
                <Modal isOpen={isShowModalImage} className={'modal-image'} toggle={toggleModalImage}>
                    <ModalBody onClick={() => toggleModalImage()}>
                        <IoIosClose className="close-modal-image" onClick={() => toggleModalImage()} />

                        <div className="image-wrapper">
                            <figure
                                className="zoom"
                                style={{ "backgroundImage": `url(${imageSrc})` }}
                                onMouseMove={(e) => zoomImage(e, imageSrc)}
                                onMouseLeave={(e) => zoomImageOut(e)}
                            >
                                <img
                                    src={imageSrc}
                                    alt=""
                                    className="image-preview-val"
                                />
                            </figure>
                        </div>

                    </ModalBody>
                </Modal>
            </div>
        );
    }

    return (
        <div className="chat-room-message-image-in-wrapper" id={`message-${singleChatDataProps.localID}`}>
            {/* {singleChatDataProps.room.type === 2 && */}
            <div className="group-sender-avatar-wrapper" style={{ background: taptalk.getRandomColor(singleChatDataProps.user.fullname) }}>
                {singleChatDataProps.user.imageURL.thumbnail !== "" ?
                    <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" onError={(e) => { e.target.onerror = null; e.target.src = iconReplaceAvatar; }} />
                    :
                    <b>{Helper.renderUserAvatarWord(singleChatDataProps.user.fullname)}</b>
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value ||
                    singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value
                ) &&
                    <img src={iconAgent} alt="" className="avatar-icon-badge" />
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value) &&
                    <img src={iconChatbot} alt="" className="avatar-icon-badge" />
                }
            </div>
            {/* } */}

            <div className={`message-in-bubble-broadcast-image ${props.status !== 'downloaded' ? 'not-sent-message-bubble' : ''}`}>
                {/* {singleChatDataProps.replyTo.localID !== "" &&
                    <div className="reply-message reply-file">
                        {singleChatDataProps.quote.fileType !== "" &&  
                            <div className="reply-file-thumbnail">
                                {singleChatDataProps.quote.fileType === "file" ? 
                                    <MdInsertDriveFile />
                                    :
                                    <img src={''} alt="" />
                                }
                            </div>
                        }

                        <div className="reply-text-wrapper">
                            <p className="reply-from">
                                <b> 
                                    {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                        "You"
                                        :
                                        singleChatDataProps.replyTo.fullname
                                    }
                                </b>
                            </p>
                            <p className="reply-text">{singleChatDataProps.quote.content}</p>
                        </div>
                    </div>
                }   */}

                {/* ${singleChatDataProps.data.caption !== "" && singleChatDataProps.replyTo.localID !== "" ? "with-reply-caption" : ""}
                ${singleChatDataProps.data.caption === "" && singleChatDataProps.replyTo.localID !== "" ? "with-reply-no-caption" : ""}
                {singleChatDataProps.data.caption === "" ? "bubble-wrapper-without-caption" : ""} */}
                <div
                    className={`
                        bubble-image-wrapper 
                        ${singleChatDataProps.data.caption !== "" ? "bubble-wrapper-with-caption" : ""}
                    `}
                >
                    <p className="title-broadcast">
                        <img src={BroadcastGrey} className="broadcast-image" alt="" />
                        <b>Broadcasted Message</b>
                    </p>

                    <p className="group-sender-name-wrapper">
                        <b>
                            {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                            }

                            {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                            }

                            {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                            }

                            {singleChatDataProps.user.fullname}
                        </b>
                    </p>

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyInMessage
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }

                    {imageFromUrl ?
                        // <img src={imageSrc} className="image-from-url" alt="" onClick={() => {toggleModalImage()}} />
                        <LazyLoadImage
                            alt={""}
                            onClick={() => { toggleModalImage() }}
                            src={imageSrc}
                            className='image-from-url'
                        />
                        :
                        <React.Fragment>
                            {isImageExistInDB ?
                                <LazyLoadImage
                                    alt={""}
                                    onClick={() => { toggleModalImage() }}
                                    src={'data:image/png;base64, ' + imageSrc}
                                    className='main-image-message'
                                />
                                // <img src={'data:image/png;base64, '+imageSrc} alt="" className="main-image-message" onClick={() => {toggleModalImage()}} />
                                :
                                <LazyLoadImage
                                    alt={""}
                                    src={'data:image/png;base64, ' + (imageSrc === "" && singleChatDataProps.data.thumbnail ? singleChatDataProps.data.thumbnail : imageSrc)}
                                    className='thumbnail-image-message'
                                />
                                // <img src={'data:image/png;base64, ' + imageSrc} alt="" className="thumbnail-image-message" />
                            }

                            {(!isImageExistInDB && !onDownloadImageProgress && !imageFromUrl) &&
                                <div className="icon-status-wrapper">
                                    <FiDownload onClick={() => downloadFile()} />
                                </div>
                            }

                            {onDownloadImageProgress &&
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={percentageDownload} />
                                        {/* <MdClose onClick={() => console.log('cancel')} /> */}
                                        <FiDownload />
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>

                {singleChatDataProps.data.caption !== '' &&
                    <p className="caption-text" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.data.caption) }} />
                }

                {/* <div className="dark-cover">
                    {(!isImageExistInDB && !onDownloadImageProgress) &&
                        <div className="icon-status-wrapper">
                            <MdFileDownload onClick={() => downloadFile()} />
                        </div>
                    }
                        
                    {onDownloadImageProgress && 
                        <div className="icon-status-wrapper">
                            <div className="onprocess-wrapper">
                                <CircularProgressbar value={percentageDownload} />
                                <MdClose onClick={() => console.log('cancel')} />
                            </div>
                        </div>
                    }
                </div> */}

                <p className={`message-info ${singleChatDataProps.data.caption === '' ? "message-info-dark" : ""}`}>
                    {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}
                </p>

                {messageActionView(singleChatDataProps)}
            </div>

            {(isImageExistInDB || imageFromUrl) && generateModalImage()}
        </div>
    );
}

const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    activeRoom: state.activeRoom
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInBroadcastImage);
