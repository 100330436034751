import React from "react";
import { FiX, FiUsers, FiUser, FiPhone, FiCalendar, FiGlobe, FiAtSign } from "react-icons/fi";
import { Modal, ModalBody } from "reactstrap";
import HelperDate from "../../../../helper/HelperDate";
import "./BroadcastMessageModalContact.scss";

let BroadcastMessageModalContact = (props) => {
    let {
        contactData,
        isOpen,
        toggle
    } = props;  

    return (
        <Modal className="broadcast-modal-contact" isOpen={isOpen} toggle={toggle}>
            <ModalBody>
                <div className="broadcast-modal-close" onClick={toggle}>
                    <FiX />
                </div>
                
                <div className="broadcast-modal-content">
                    {contactData &&
                        <>
                            {contactData.fullName &&
                                <p className="contact-fullname">
                                    <FiUsers /> <b>{contactData.fullName}</b>
                                </p>
                            }

                            {contactData.alias &&
                                <>
                                    <p className="contact-label">
                                        <FiUser /> <b>Alias</b>
                                    </p>
                                    <p className="contact-value">
                                        {contactData.alias}
                                    </p>
                                </>
                            }

                            {contactData.email &&
                                <>
                                    <p className="contact-label">
                                        <FiAtSign /> <b>Email</b>
                                    </p>
                                    <p className="contact-value">
                                        {contactData.email}
                                    </p>
                                </>
                            }

                            {contactData.phone &&
                                <>
                                    <p className="contact-label">
                                        <FiPhone /> <b>Phone Number</b>
                                    </p>
                                    <p className="contact-value">
                                        {contactData.phone}
                                    </p>
                                </>
                            }

                            {contactData.createdTime &&
                                <>
                                    <p className="contact-label">
                                        <FiCalendar /> <b>Date Created</b>
                                    </p>
                                    <p className="contact-value">
                                        {HelperDate.formatToString(new Date(contactData.createdTime), "dd MMM yyyy")}
                                    </p>
                                </>
                            }

                            {contactData.origin &&
                                <>
                                    <p className="contact-label">
                                        <FiGlobe /> <b>Origin</b>
                                    </p>
                                    <p className="contact-value">
                                        {contactData.origin}
                                    </p>
                                </>
                            }
                        </>
                    }
                </div>

            </ModalBody>
        </Modal>
    )
}

export default BroadcastMessageModalContact;