const IdTranslation = {
  pageWaba: {
    text1: "Survei",
    text2: "Tentang Perusahaan",
    text3: "Info Pemohon",
    form1: {
      textForm1: "Apakah perusahaan yang ingin didaftarkan berbadan hukum?",
      textForm2: "Ya",
      textForm3: "Tidak",
      textForm4: "Lebih",
      textForm5: "Verified",
      textForm6: "Tidak verified",
      textForm6a: "3 atau lebih",
      textForm7: "Apakah Anda memiliki Facebook Manager ID?",
      textForm8: "Apa status Facebook Manager ID Anda?",
      textForm9: "Apakah Anda dapat menyediakan dokumen legal dari perusahaan Anda?",
      textForm10: "Apakah perusahaan Anda bergerak di industri dalam",
      textForm11: "daftar ini",
      textForm12: "Berapa jumlah channel yang ingin Anda register?",
      textForm13: "Lanjut",
      textFormTooltip1: "Perusahaan harus berkedudukan hukum dan memiliki dokumen legal yang dikeluarkan oleh pemerintah.",
      textFormTooltip2: "Cari Facebook Manager ID Anda",
      textFormTooltip3: "Apa itu verifikasi Facebook business?",
      textFormTooltip4: "Satu akun WhatsApp sama dengan satu channel.",
      textListedHere1: "Daftar industri yang tidak akan disetujui",
      textListedHere2: "Mata uang dan produk ilegal",
      textListedHere3: "Produk medis dan kesehatan",
      textListedHere4: "Penawaran dan produk yang menyesatkan, menipu, menipu, atau menyinggung",
      textListedHere5: "Lainnya",
      textListedHere6: "Mata uang asli dan virtual",
      textListedHere7: "Pelanggaran pihak ke-3",
      textListedHere8: "Produk dan jasa ilegal",
      textListedHere9: "Alat Medis",
      textListedHere10: "Perawatan dan alat kesehatan",
      textListedHere11: "Obat-obatan dan farmasi",
      textListedHere12: "Cairan Tubuh",
      textListedHere13: "Kategori keuangan yang menyesatkan: pinjaman P2P, penagihan utang, pinjaman jangka pendek",
      textListedHere14: "Hewan, senjata, perjudian, kencan, suplemen, tembakau, produk dan layanan dewasa, alkohol"
    },
    form2: {
      textForm1: "Name Perusahaan",
      textForm2: "Masukan nama perusahaan",
      textForm3: "Asal Negara Berbadan Hukum",
      textForm3a: "Pilih Negara",
      textForm4: "Website",
      textForm5: "Alamat",
      textForm6: "Masukan alamat lengkap",
      textForm7: "Nomor Telepon Perusahaan",
      textForm8: "Email Perusahaan",
      textForm8a: "Masukan email",
      textForm9: "Industri",
      textForm10: "Pilih industri",
      textForm11: "e.g Financial Services/ Information Technology",
      textForm12: "Kembali",
      textForm13: "Lanjut",
      textTooltip1: "Asal negara di akui secara legal dan berbadan hukum.",
      textTooltip2: "Pastikan nomor telpon ini sudah di registrasi secara legal oleh perusahaan Anda.",
      textTooltip3: "Pastikan ini email perusahaan Anda.",
      textWarning1: "Pastikan website URL valid",
      textWarning2: "Pastikan alamat email valid",
      textWarning3: "Kombinasi kode negara dan nomor telepon harus antara 7-15 angka"
    },
    form3: {
      textForm1: "Nama Lengkap",
      textForm2: "Masukan nama lengka",
      textForm3: "Email",
      textForm3a: "Masukan email",
      textForm4: "Nomor Telepon",
      textForm4a: "Nomor telepon",
      textForm5: "Pilih negara dahulu",
      textForm6: "Jabatan di Perusahaan",
      textForm7: "Masukan jabatan",
      textForm8: "e.g Customer Support Manager",
      textForm9: "Kembali",
      textForm10: "Lanjut",
      textWarning1: "Pastikan alamat email valid"
    }
  }
}

export default IdTranslation;