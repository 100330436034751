import React, { useState, useEffect } from "react";
import "../../auth/Auth.css";
import "./RolesAddMember.scss";
import { FiX } from "react-icons/fi";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import CustomSearchBox from "../../reuseableComponent/customSearchBox/CustomSearchBox";
import PopupConfirmation from "../../reuseableComponent/popupConfirmation/PopupConfirmation";
import userGrey from "../../../assets/img/icon-user-circle-grey.svg";
import MemberServices from "../../../services/newServices/MemberServices";
import ServiceGroupRole from "../../../services/organization/ServiceGroupRole";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

let RolesAddMember = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [searchVal, setSearchVal] = useState("");
  let [member, setMember] = useState([]);
  let [memberOrigin, setMemberOrigin] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [selectedMember, setSelectedMember] = useState([]);
  let [selectedMemberOrigin, setSelectedMemberOrigin] = useState([]);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [isWaitingSubmit, setIsWaitingSubmit] = useState(false);
  let [showModalChangeRole, setShowModalChangeRole] = useState(false);

  let toggleChangeRole = () => {
    if(!isWaitingSubmit) {
      setShowModalChangeRole(!showModalChangeRole);
    }
  }
  
  let back = () => {
    if (!isWaitingSubmit) {
      history.push(`/o/${orgID}/organization/roles/detail/${props.match.params.roleID}`);
    }
  }

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberhips.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberhips[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/member`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (orgID) {
      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Roles",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        );
      }
    }
  }, [orgID])

  let onChangeSearch = (e) => {
    let val = e.target.value.toLowerCase();
    setSearchVal(val);

    if (val === "") {
      setMember(memberOrigin)
    } else {
      let result = [];
      for (let i in memberOrigin) {
        let objectKey = Object.keys(memberOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof memberOrigin[i][objectKey[j]] === "string";
          if (memberOrigin[i][objectKey[j]].toString().toLowerCase().includes(val) && isString) {
            result.push(memberOrigin[i])
            break;
          }
        }
      }

      setMember(result);
    }
  }

  let onClearSearch = () => {
    let valSearch = document.querySelector(`#search-box-input-val`);
    valSearch.value = "";
    setMember(memberOrigin);
    setSearchVal("");
  }

  let getMemberList = () => {
    let _retriveDataError = { ...retriveDataError };

    MemberServices.getList(orgID, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.status === 200) {
        setMember(response.dataResult.data.members);
        setMemberOrigin(response.dataResult.data.members);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if (orgID) getMemberList(orgID);
  }, [orgID]);

  let onClickCheckAll = (e) => {
    let checked = e.target.checked;
    let data = [];

    if (checked) {
      member.map((v) => {
        data.push(v);

        return null;
      })
    }

    setSelectedMember(data);
  }

  let onClickSingleCheck = (e, val) => {
    let checked = e.target.checked;
    let _selectedMember = selectedMember.slice();

    let _findIndex = _selectedMember.findIndex(v => v.rowID === val.rowID);

    if (checked) {
      _selectedMember.push(val);
    } else {
      _selectedMember.splice(_findIndex, 1);
    }


    setSelectedMember(_selectedMember);
  }

  let submitAddMemberRole = () => {
    setIsWaitingSubmit(true);

    let arrSelectedMember = [];

    selectedMember.map((v) => {
      arrSelectedMember.push(v.rowID);

      return null;
    });

    let data = {
      id: parseInt(props.match.params.roleID),
      memberIDs: arrSelectedMember
    }

    ServiceGroupRole.postCreateGroupRoleMember(props.match.params.orgID, data, (res) => {
      if (res.dataResult.error.message === "") {
        if (res.dataResult.data.success) {
          doToast(res.dataResult.data.message);
          setSelectedMemberOrigin(selectedMember);
          setTimeout(() => {
            back();
          }, 3000)
        } else {
          doToast(res.dataResult.data.message, "fail");
        }
      } else {
        doToast(res.dataResult.error.message, "fail");
        setIsWaitingSubmit(false);
      }
    })
  }

  let removeSingleMember = (data) => {
    let _selectedMember = selectedMember.slice();

    let _findIndex = _selectedMember.findIndex(v => v.rowID === data.rowID);

    if (_findIndex !== -1) {
      _selectedMember.splice(_findIndex, 1);
    }

    setSelectedMember(_selectedMember);
  }

  let renderName = (data, index, isDisabled) => {
    return (
      <div className="topic-list-content topic-list-with-remove" key={`selected - name - ${index} `}>
        <b>{data.memberAlias !== "" ? `${data.memberAlias} (${data.accountName})` : data.accountName}</b>

        <div
          className="remove-topic"
          onClick={() => {
            if (!isDisabled) {
              removeSingleMember(data)
            }
          }}
        >
          <FiX />
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <PopupConfirmation
        isOpen={showModalChangeRole}
        toggle={toggleChangeRole}
        title="Change member’s role?"
        description="Member’s role will change to custom role and if you wish to change it again, you can edit later in member’s detail. Are you sure you want to continue?"
        className="modal-confirmation-change-role"
      >
        {!isWaitingSubmit ?
          <ButtonWithLoadingOrIcon
            text="Change Role"
            className="main-button-48 orange-button"
            onClickAction={() => submitAddMemberRole()}
          />
          :
          <ButtonWithLoadingOrIcon
            text="Change Role"
            className="main-button-48 orange-button"
            isLoading
            isDisabled
            loadingColor="gray"
            position="left"
          />
        }
      </PopupConfirmation>

      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataAfterChange={JSON.stringify(selectedMember)}
        dataOrigin={JSON.stringify(selectedMemberOrigin)}
      >
        <BackButton text="Back to Role Details" onClick={back} _className="back-button-add-role-member" />

        {isWaitingSubmit ?
          <ButtonWithLoadingOrIcon
            className="orange-button main-button-40 button-invite-member-save"
            text="Save Changes"
            isLoading
            loadingColor="gray"
            position="left"
            isDisabled
          />
          :
          <ButtonWithLoadingOrIcon
            className="orange-button main-button-40 button-invite-member-save"
            text="Save Changes"
            onClickAction={toggleChangeRole}
          />
        }


        <div className="sectionWrap sectionWrap-add-role-members">
          <div className="member-list-add-member-title">
            <b>Add Role Members</b>

            <p>
              Add members to role "{props.location.state ? props.location.state.roleTitle : ""}"
            </p>
          </div>

          {waitForResponseGetList || retriveDataError.code ?
            <RetrieveDataLoading
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message}
            />
            :
            <React.Fragment>
              <div className="member-list-add-member-tool-wrapper">
                <CustomSearchBox
                  placeholder={"Search for a member"}
                  onChangeSearchSpecial={onChangeSearch}
                  style={{ width: '343px' }}
                  dataToSearch={member}
                  onClearSearch={onClearSearch}
                  search={searchVal}
                />
              </div>

              <div className="member-list-add-member-table-wrapper">
                <div className="custom-table">
                  <table className="custom-table-content table-member-list-add-member">
                    <thead>
                      <tr>
                        <td className="col_full_name">
                          <div className={selectedMember.length === member.length ? "custom-checkbox" : "custom-checkbox-stripe"}>
                            <input
                              type="checkbox"
                              id="select-all"
                              onChange={(e) => onClickCheckAll(e)}
                              checked={selectedMember.length > 0}
                            />
                            <label htmlFor="select-all"></label>
                          </div>
                          Full Name
                        </td>
                        <td className="col_alias">Alias</td>
                        <td className="col_email">Email</td>
                        <td className="col_role">Role</td>
                      </tr>
                    </thead>

                    {member.length < 1 ?
                      <tbody>
                        <tr className="no-data-tr">
                          <td colSpan="4" className="no-data-column"><b className="no-result-found">No data found</b></td>
                        </tr>
                      </tbody>
                      :
                      <tbody>
                        {member.map((value, index) => {
                          return (
                            <tr key={`member-${index}`}>
                              <td className="col_full_name">
                                <div className="custom-checkbox">
                                  <input type="checkbox"
                                    id={`select-member-${value.rowID}`}
                                    onChange={(e) => onClickSingleCheck(e, value)}
                                    checked={selectedMember.findIndex(v => v.rowID === value.rowID) !== -1}
                                  />
                                  <label htmlFor={`select-member-${value.rowID}`}></label>
                                </div>
                                {value.accountName}
                              </td>
                              <td className="col_alias">{value.memberAlias === "" ? "-" : value.memberAlias}</td>
                              <td className="col_email">{value.accountEmail}</td>
                              <td className={`col_role agent-${value.rowID}`}>
                                {value.roleName}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </React.Fragment>
          }
        </div>

        <div className="member-list-add-member-selected">
          <div className="member-list-add-member-selected-head">
            <b>Selected ({selectedMember.length})</b>
          </div>

          <div className="member-list-add-member-selected-body">
            {selectedMember.length === 0 ?
              <div className="member-list-add-member-selected-body-no-data">
                <img src={userGrey} alt="" />

                <p>
                  Selected contacts will be displayed here.
                </p>
              </div>
              :
              selectedMember.map((v, i) => {
                return (
                  renderName(v, i, isWaitingSubmit)
                )
              })
            }
          </div>
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default RolesAddMember;
