import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { FiCopy } from "react-icons/fi";
import QRCode from "qrcode";
import "./EntryPoint.scss";
import { doToast, HelperCopy } from "../../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import IntegrationGoogleServices from "../../../../../services/integration/IntegrationGoogleServices";
// import CustomSearchBox from "../../../../reuseableComponent/customSearchBox/CustomSearchBox";
import ErrorOrInfoComp from "../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import Pagination from "../../../../reuseableComponent/pagination/Pagination";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import DisabledEnabled from "../../../../reuseableComponent/disabledEnabled/DisabledEnabled";
import ColoredInfoBox from "../../../../reuseableComponent/coloredInfoBox/ColoredInfoBox";
import PopupConfirmation from "../../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import CopyBox from "../../../../reuseableComponent/copyBox/CopyBox";
import IconQr from "../../../../../assets/img/icon-qr.svg";
import IconVerified from "../../../../../assets/img/entry-point/icon-verified.svg";
import IconSuspend from "../../../../../assets/img/entry-point/icon-suspend.svg";
import IconUnspecified from "../../../../../assets/img/entry-point/icon-unspecified.svg";
import IconReview from "../../../../../assets/img/entry-point/icon-review.svg";
import IconAddLocation from "../../../../../assets/img/entry-point/add-location.svg";
import IconImportLocation from "../../../../../assets/img/entry-point/import-location.svg";
import { FiEdit, FiPlus, FiActivity, FiInfo, FiX, FiFilePlus, FiDownload, FiLink, FiHeadphones, FiPhone, FiBookOpen, FiTrash2 } from "react-icons/fi";

let EntryPoint = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [verication, setVerification] = useState({
    isShow: false,
    locationID: ""
  });
  let [editAlias, setEditAlias] = useState({
    show: false,
    errorEditAlias: "",
    alias: "",
    id: ""
  })
  let [showModalAddLocation, setShowModalAddLocation] = useState(false);
  let [deleteList, setDeleteList] = useState([]);
  let [isLoadingVerification, setIsLoadingVerification] = useState(false);
  let [loadingAddMultiLocation, setLoadingAddMultipleLocation] = useState(false);
  let [regionList, setRegionList] = useState(false);
  // let [multiLocationList, setMultiLocationList] = useState([]);

  const CONTACTS = {
    FAQS: "FAQs",
    WEB_CHAT: "Web Chat",
    TWITTER: "Twitter",
    PHONE: "Phone",
    EMAIL: "Email",
    WHATSAPP: "WhatsApp"
  }

  let toggleModalAddLocation = () => {
    if(!loadingAddMultiLocation) {
      setShowModalAddLocation(!showModalAddLocation);
    }
  }

  let toogleEditAlias = (alias = false) => {
    let _editAlias = {...editAlias};
    _editAlias.show = !_editAlias.show;

    if(alias) {
      _editAlias.alias = alias.alias;
      _editAlias.id = alias.id;
      _editAlias.errorEditAlias = "";
    }

    setEditAlias(_editAlias);
  }

  let modalEditAlias = () => {
    return (
      <Modal isOpen={editAlias.show} toggle={toogleEditAlias} className="modal-edit-alias-entry">
        <ModalBody>
          <b className="edit-alias-title">
            Edit Place Alias
          </b>

          <p>
            <b>Place Alias</b>
            <span className={editAlias.errorEditAlias === "Characters exceed limit" ? "red-text" : ""}>{editAlias.alias.length}/50 characters</span>
          </p>

          <input 
            type="text"
            placeholder={"Place your alias here"}
            onChange={(e) => {
              let _editAlias = {...editAlias};
              _editAlias.alias = e.target.value;
              _editAlias.errorEditAlias = "";
              setEditAlias(_editAlias);
            }}
            value={editAlias.alias}
            disabled={loadingEditLocationAlias}
            className={editAlias.errorEditAlias !== "" ? "border-red" : ""}
          />

          {editAlias.errorEditAlias !== "" &&
            <ErrorOrInfoComp
                text={editAlias.errorEditAlias}
                _className={"font-red"}
                icon={<FiInfo />}
            />
          }

          <ButtonWithLoadingOrIcon 
            className="no-fill-button main-button-48"
            text="Cancel"
            onClickAction={toogleEditAlias}
          />

          {loadingEditLocationAlias ?
            <ButtonWithLoadingOrIcon 
              className="orange-button main-button-48"
              text="Save Changes"
              isLoading
              loadingColor="gray"
              isDisabled
              position="left"
              />
              :
            <ButtonWithLoadingOrIcon 
              // isDisabled={disabledEditAlias()}
              className="orange-button main-button-48"
              text="Save Changes"
              onClickAction={submitEditAlias}
            />
          }
        </ModalBody>
      </Modal>
    )
  };

  let modalAddLocation = () => {
    return (
      <Modal isOpen={showModalAddLocation} toggle={toggleModalAddLocation} className="modal-add-location">
        <ModalBody>
          <input 
            type='file'
            id="import-csv"
            onChange={(e) => {}}
          />

          <b className="modal-add-location-title">
            Add Location
          </b>

          <p className="modal-add-location-desc">
            There are 2 ways to add a location.
          </p>

          <FiX className="close-icon" onClick={toggleModalAddLocation} />

          <div className="selection-box">
            <img src={IconAddLocation} alt="" />

            <br />

            <b>
              New Location
            </b>

            <p>
              Create a new location by inputting Place ID and Place Alias.
            </p> 

            <button className={`orange-button ${loadingAddMultiLocation ? "disabled" : ""}`} onClick={goToAdd}>
              <FiPlus />
              <b>Add Location</b>
            </button>
          </div>

          <div className="selection-box selection-import">
            <img src={IconImportLocation} alt="" />

            <br />

            <b>
              Import Locations
            </b>

            <p>
              Dowload the template and use it to bring your existing location here.
            </p> 

            <a
              href={require(`./Add_locations_example.csv`)}
              download="Add_locations_example.csv"
            >
              <button className="orange-button download-template">
                <FiDownload />
                <b>Download Template</b>
              </button>
            </a>

            <button 
              className={`orange-button ${loadingAddMultiLocation ? "disabled" :""}`}
              onClick={() => {
                if(!loadingAddMultiLocation) {
                  // let _sel = document.querySelector("#import-csv");
  
                  // if(_sel) {
                  //   _sel.click();
                  // }

                  importLocationHandler();
                  // addMultipleLocation();
                }
              }}
            >
              {loadingAddMultiLocation ?
                <div className="lds-ring">
                  <div /><div /><div /><div />
                </div>
                :  
                <FiFilePlus />
              }

              <b>Upload CSV</b>
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  let listPageOption = [
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
  ];

  let [channelData, setChannelData] = useState({});
  let [locationList, setLocationList] = useState({locations: []});
  
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });

  let [retrieveDataErrorDetail, setRetrieveDataErrorDetail] = useState({
    code: false,
    message: false,
  });

  let [isLoadingGetData, setIsLoadingGetData] = useState(true);
  let [isLoadingGetLocationList, setIsLoadingGetLocationList] = useState(true);

  let [pageDisplay, setPageDisplay] = useState(1);
  let [listPageVal, setListPageVal] = useState(listPageOption[0]);
  let [loadingEditLocationAlias, setLoadingEditLocationAlias] = useState(false);
  let [isLoadingRemoveLocation, setIsLoadingRemoveLocation] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);

  let toggleModalDelete = () => {
    setShowModalDelete(!showModalDelete);
  }

  let goToAdd = () => {
    if(!loadingAddMultiLocation) {
      history.push(`/o/${orgID}/integration/google-business-messages/detail/${match.params.id}/add-location`)
    }
  }

  // let goToEdit = () => {
  //   history.push(`/o/${orgID}/integration/google-business-messages/detail/${match.params.id}/edit-entry-point-local`)
  // }

  // let goToEditNonLocal = () => {
  //   history.push(`/o/${orgID}/integration/google-business-messages/detail/${match.params.id}/edit-entry-point-non-local`)
  // }

  let onSelectListPage = (val) => {
    setListPageVal(val);
    setPageDisplay(1);
    getLocationList(1, val.value);
  };

  let handleChangePage = (page) => {
    setPageDisplay(page);
    getLocationList(page, false);
  };

  let checkID = (match) => {
    let { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if(regionList) {
      getChannelDetail();
    }
  }, [regionList])

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getRegionList();
    }
  }, [orgID]);

  let addMultipleLocation = (d) => {
    setLoadingAddMultipleLocation(true);

    let data = {
      channelID: Number(match.params.id),
      locations: d
    };

    IntegrationGoogleServices.addLocationMultiple(orgID, data, (response) => {
      if (response.dataResult.error.message === "") {
        if(response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          toggleModalAddLocation();
          // setMultiLocationList([]);
          setLoadingAddMultipleLocation(false);
          getLocationList();
        }else {
          doToast(response.dataResult.data.message, "fail");
          setLoadingAddMultipleLocation(false);
        }
      } else {
        doToast(response.dataResult.error.message, "fail");
        setLoadingAddMultipleLocation(false);
      }
    });
  }

  let getRegionList = () => {
    setIsLoadingGetLocationList(true);

    IntegrationGoogleServices.getRegionList(orgID, (response) => {
      let _retriveDataError = { ...retrieveDataError };

      if (response.dataResult.error.message === "") {
        let arr = [];
        response.dataResult.data.regions.map(v => {
          arr.push({
            name: v.name,
            id: v.code
          })

          return null;
        })

        setRegionList(arr);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    });
  }

  let getChannelDetail = () => {
    setIsLoadingGetData(true);

    let data = {
      id: Number(match.params.id),
    };

    IntegrationGoogleServices.getDetail(orgID, data, (response) => {
        let _retrieveDataError = { ...retrieveDataErrorDetail };

        if (response.dataResult.error.message === "") {
          setChannelData(response.dataResult.data);
          setIsLoadingGetData(false);
          
          if(response.dataResult.data.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "LOCATION") !== -1) {
            getLocationList();
          }else {
            setIsLoadingGetLocationList(false);
            setIsLoadingGetData(false);
          }
        } else {
          _retrieveDataError.message = response.dataResult.error.message;
          _retrieveDataError.code = response.dataResult.error.code;
          setRetrieveDataErrorDetail(_retrieveDataError);
          setIsLoadingGetData(false);
        }
      }
    );
  }
  
  let getLocationList = (page = false, pageSize = false) => {
    setIsLoadingGetLocationList(true);

    let data = {
      channelID: Number(match.params.id),
      pageNumber: page ? page : pageDisplay,
      pageSize: pageSize ? pageSize : listPageVal.value
    };

    IntegrationGoogleServices.getLocationList(
      orgID,
      data,
      (response) => {
        let _retrieveDataError = { ...retrieveDataError };

        if (response.dataResult.error.message === "") {
          setLocationList(response.dataResult.data);
        } else {
          _retrieveDataError.message = response.dataResult.error.message;
          _retrieveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retrieveDataError);
        }

        setIsLoadingGetLocationList(false);
      }
    );
  }

  let submitEditAlias = () => {
    // let error = "";

    if(editAlias.alias === "") {
      let _editAlias = {...editAlias};

      _editAlias.errorEditAlias = "This field is required";
      setEditAlias(_editAlias);
    }else if(editAlias.alias.length > 50) {
      let _editAlias = {...editAlias};

      _editAlias.errorEditAlias = "Characters exceed limit";
      setEditAlias(_editAlias);
    }else {
      setLoadingEditLocationAlias(true);
  
      let data = {
        channelID: Number(match.params.id),
        locationID: editAlias.id,
        alias: editAlias.alias
      };
  
      IntegrationGoogleServices.editLocationAlias(orgID, data, (response) => {
        if (response.dataResult.error.message === "") {
          if(response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
            toogleEditAlias();
            getLocationList();
          }else {
            doToast(response.dataResult.data.message, "fail");
            setLoadingEditLocationAlias(false);
          }
        } else {
          doToast(response.dataResult.error.message, "fail");
          setLoadingEditLocationAlias(false);
        }
      });
    }
  }

  let resendVerification = () => {
    setIsLoadingVerification(true);

    let data = {
      channelID: Number(match.params.id),
      locationID: verication.locationID,
    };
  
    IntegrationGoogleServices.locationVerification(orgID, data, (response) => {
      if (response.dataResult.error.message === "") {
        if(response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          toggleVerification();
          getLocationList();
        }else {
          doToast(response.dataResult.data.message, "fail");
          setIsLoadingVerification(false);
        }
      } else {
        doToast(response.dataResult.error.message, "fail");
        setIsLoadingVerification(false);
      }
    });
  }

  let deleteListAction = () => {
    setIsLoadingRemoveLocation(true);

    let data = {
      channelID: Number(match.params.id),
      locationIDs: deleteList,
    };
  
    IntegrationGoogleServices.deleteLocation(orgID, data, (response) => {
      if (response.dataResult.error.message === "") {
        if(response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          toggleModalDelete();
          getLocationList();
          setDeleteList([]);
          setIsLoadingRemoveLocation(false);
        }else {
          doToast(response.dataResult.data.message, "fail");
          setIsLoadingRemoveLocation(false);
        }
      } else {
        doToast(response.dataResult.error.message, "fail");
        setIsLoadingRemoveLocation(false);
      }
    });
  }

  // let onChangeSearch = () => {

  // }

  let toggleVerification = (id = false) => {
    let _ver = {...verication};

    _ver.isShow = !_ver.isShow;

    if(id) {
      _ver.locationID = id;
    }

    setVerification(_ver);
  }

  //qr
  let [qrAndroid, setQrAndroid] = useState("");
  let [qrIOS, setQrIOS] = useState("");
  let [dataQr, setDataQr] = useState([]);

  let [showModalAndroid, setShowModalAndroid] = useState(false);

  let getTestUrlImage = (type, data = false) => {
    let url = "";

    if(type === "android") {
      let _data = data ? data : dataQr;
      let _findIdx = _data.findIndex(v => v.surface === "SURFACE_ANDROID_MAPS");
      
      url =  _data[_findIdx].url;

      QRCode.toDataURL(url)
        .then(_url => {
          setQrAndroid(_url);
        })
        .catch(err => {
          console.error(err)
        });
    }

    if(type === "ios") {
      let _data = data ? data : dataQr;
      let _findIdx = _data.findIndex(v => v.surface === "SURFACE_IOS_MAPS");
      
      url =  _data[_findIdx].url;

      QRCode.toDataURL(url)
        .then(_url => {
          setQrIOS(_url);
        })
        .catch(err => {
          console.error(err)
        });
    }

    return url;
  }

  let toggleModalAndroid = () => {
    setShowModalAndroid(!showModalAndroid)
  }
  
  let modalTestAndroid = () => {
      return(
        <Modal isOpen={showModalAndroid} toggle={toggleModalAndroid} className={"modal-test"}>
          <ModalBody>
            <p className="modal-title">
              <b>Android Test URL</b>
            </p>

            <p className="modal-desc">
              Scan this barcode in your Android device
            </p>

            <div className="qr-wrapper">
              <img src={qrAndroid} alt="" />
            </div>

            <div className="qr-button-wrapper">
              <ButtonWithLoadingOrIcon 
                text="Close"
                onClickAction={toggleModalAndroid}
                className="no-fill-button main-button-40"
              />

              <ButtonWithLoadingOrIcon 
                text="Copy Android URL"
                icon={{
                  src: FiCopy,
                  type: "svg"
                }}
                position="left"
                className="orange-button main-button-40"
                onClickAction={() => HelperCopy(getTestUrlImage("android"), "Android URL Copied")}
              />
            </div>
          </ModalBody>
        </Modal>
      )
  }

  let [showModalIOS, setShowModalIOS] = useState(false)

  let toggleModalIOS = () => {
    setShowModalIOS(!showModalIOS)
  }

  let modalTestIOS = () => {
      return(
        <Modal isOpen={showModalIOS} toggle={toggleModalIOS} className={"modal-test"}>
           <ModalBody>
            <p className="modal-title">
              <b>iOS Test URL</b>
            </p>

            <p className="modal-desc">
              Scan this barcode in your iOS device
            </p>

            <div className="qr-wrapper">
              <img src={qrIOS} alt="" />
            </div>

            <div className="qr-button-wrapper">
              <ButtonWithLoadingOrIcon 
                text="Close"
                onClickAction={toggleModalIOS}
                className="no-fill-button main-button-40"
              />

              <ButtonWithLoadingOrIcon 
                text="Copy iOS URL"
                icon={{
                  src: FiCopy,
                  type: "svg"
                }}
                position="left"
                className="orange-button main-button-40"
                onClickAction={() => HelperCopy(getTestUrlImage("ios"), "iOS URL Copied")}
              />
            </div>
          </ModalBody>
        </Modal>
      )
  }
  //qr

  //upload csv
  let importLocationHandler = () => {
    let guessDelimiters = (text, possibleDelimiters) => {
      return possibleDelimiters.filter(weedOut);
  
      function weedOut (delimiter) {
          var cache = -1;
          return text.split('\n').every(checkLength);
  
          function checkLength (line) {
              if (!line) {
                  return true;
              }
  
              var length = line.split(delimiter).length;
              if (cache < 0) {
                  cache = length;
              }
              return cache === length && length > 1;
          }
      }
    }
    
    let input = document.querySelector('#import-csv')
    input.click()

    input.onchange = (e) => {
      if (input.files.length > 0) {

        var reader = new FileReader();
        reader.onload = function () {
          const data = reader.result.split('\n')
          const cleanData = data.map(item => item.replace(/"/g, ""))
          const rawDataUser = cleanData.map(item => item.split(guessDelimiters(item, [";", ","])[0]))

          if(rawDataUser[0][0].includes("placeID") && rawDataUser[0][1].includes("alias")) {
            const dataUser = rawDataUser.slice(1);
            
            let _data = [];

            dataUser.map(v => {
              if(v.length === 2) {
                _data.push({
                  placeID: v[0],
                  alias: v[1]
                })
              }

              return null;
            })

            addMultipleLocation(_data);
          }else {
            doToast("Invalid CSV Format", "fail");
          }

          // addMultipleLocation(data);
        };

        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsText(input.files[0]);

        e.target.value = null;
      }
    }

  }
  //upload csv

  return (
      // local
      <>
        {(!isLoadingGetData && !isLoadingGetLocationList && !retrieveDataError.code) &&
          <>
            {channelData.channel.status === "verified" &&
              <ColoredInfoBox
                text={"Your channel has been verified and some fields can no longer be modified."}
                color={"blue"}
                icon={<FiInfo />}
              />
            }

            {modalTestAndroid()}
            {modalTestIOS()}

            <PopupConfirmation
              isOpen={verication.isShow}
              toggle={toggleVerification}
              title="Request Verification"
              description="To verify all locations, first request verification for just one location. Are you sure you want to verify location?"
              className="modal-verification-entry"
            >
              {!isLoadingVerification ?
                  <ButtonWithLoadingOrIcon
                      text="Request Verification"
                      className="main-button-48 orange-button"
                      onClickAction={() => resendVerification()}
                  />
                  :
                  <ButtonWithLoadingOrIcon
                      text="Request Verification"
                      className="main-button-48 orange-button"
                      isLoading
                      isDisabled
                      loadingColor="gray"
                      position="left"
                  />
              }
            </PopupConfirmation>

            <PopupConfirmation
              isOpen={showModalDelete}
              toggle={toggleModalDelete}
              title={`Delete ${deleteList.length} Location${deleteList.length > 1 ? "s" : ""}`}
              description={`Are you sure you want to delete ${deleteList.length} location${deleteList.length > 1 ? "s" : ""}?`}
              className="modal-verification-entry"
            >
              {!isLoadingRemoveLocation ?
                  <ButtonWithLoadingOrIcon
                      text="Delete"
                      className="main-button-48 red-button"
                      onClickAction={() => deleteListAction()}
                      icon={{
                        src: FiTrash2,
                        type: "svg"
                      }}
                      position="left"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                      text="Delete"
                      className="main-button-48 red-button"
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      position="left"
                  />
              }
            </PopupConfirmation>
          </>
        }

        <div className="entry-google sectionWrap">
          <div className={`entry-google-top ${(channelData.profile && channelData.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "LOCATION") === -1) ? "local-disabled" : ""}`}>
            <b>Local</b>
            <p>
              Local entry point group encompasses entry points that rely on locations. 
            </p>

            {(!isLoadingGetData && !isLoadingGetLocationList && !retrieveDataError.code1) &&
              <>
                <ButtonWithLoadingOrIcon
                  icon={{
                    type: "svg",
                    src: FiEdit,
                  }}
                  className={`${channelData.channel.status === "verified" ? "force-hide" : ""} no-fill-button entry-google-top-button main-button-40 ${channelData.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "LOCATION") === -1 ? "right-0" : ""}`}
                  text="Edit"
                  position="left"
                  isDisabled={channelData.channel.status === "verified"}
                  onClickAction={() => {
                    if(channelData.channel.status !== "verified") {
                      props.history.push(`/o/${orgID}/integration/google-business-messages/detail/${match.params.id}/edit-entry-point-local`)
                    }
                  }}
                />
                
                {channelData.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "LOCATION") !== -1 &&
                  <ButtonWithLoadingOrIcon
                    icon={{
                      type: "svg",
                      src: FiPlus,
                    }}
                    className="orange-button entry-google-top-button main-button-40"
                    text="Add Location"
                    position="left"
                    onClickAction={toggleModalAddLocation}
                  />
                }
              </>
            }
          </div>
          
          {isLoadingGetData || isLoadingGetLocationList || retrieveDataError.code ?
            <RetrieveDataLoading
              isLoading={isLoadingGetData || isLoadingGetLocationList}
              errorMessage={retrieveDataError.message}
              hideRefresh
            />
            :
             
            <>
              {modalEditAlias()}
              {modalAddLocation()}

              <div className="entry-google-middle">
                <label>
                  <FiActivity />
                  <b>Status</b>
                </label>
                
                <DisabledEnabled 
                  status={channelData.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "LOCATION") !== -1 ? "enabled" : "disabled"}
                />

                {channelData.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "LOCATION") !== -1 &&
                  <div className="search-delete-wrapper">
                    {/* <label className="location-list-label">
                      <FiMapPin />
                      <b>Location List</b>
                    </label>
                    <CustomSearchBox 
                      placeholder={"Search by alias and place ID"}
                      onChangeSearch={onChangeSearch}
                      dataToSearch={channelData} 
                    /> */}

                    <ButtonWithLoadingOrIcon 
                      icon={{
                        type: "svg",
                        src: FiTrash2
                      }}
                      position="left"
                      className="red-button main-button-48 delete-location"
                      isDisabled={deleteList.length === 0}
                      text="Delete Location"
                      onClickAction={toggleModalDelete}
                    />
                  </div>
                }
              </div>
              
              {channelData.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "LOCATION") !== -1 &&
                <div className="entry-table-wrapper">
                  <div className="custom-table">
                    <table className="custom-table-content table-entry-list">
                      <thead>
                        <tr>
                          {locationList.locations.length > 0 &&
                            <td>
                              <div className={deleteList.length === locationList.locations.length ?`custom-checkbox` : `custom-checkbox-stripe`}>
                                <input 
                                    type="checkbox" 
                                    id="check-all-region"
                                    onChange={(e) => {}}
                                    checked={deleteList.length > 0}
                                />
                                <label 
                                  htmlFor="check-all-region"
                                  onClick={(e) => {
                                    if(!isLoadingRemoveLocation) {
                                      let _del = deleteList.slice();
                                      let ar = [];
                                      
                                      if(_del.length !== locationList.locations.length) {
                                        locationList.locations.map((v) => {
                                          ar.push(v.id);
                                          return null;
                                        })
                                      }

                                      setDeleteList(ar);
                                    }
                                  }}
                                />
                              </div>
                            </td>
                          }
                          <td className="col_td_center td_place_id">Place ID</td>
                          <td className="col_td_center td_place_alias">Place Alias</td>
                          <td className="col_td_center td_verification_status">Verification Status</td>
                          <td className="col_td_center td_test_url">Test URLs</td>
                          
                          {locationList.locations.length > 0 &&
                            <td className="td-item-per-page">
                              <CustomSelectOption
                                optionListProps={listPageOption}
                                valueDropdownProps={listPageVal}
                                onClickDropDownListOptionProps={onSelectListPage}
                                _className="dropdown-item-page"
                              />
                            </td>
                          }
                        </tr>
                      </thead>
                      
                      <tbody>
                        {locationList.locations.length > 0 ? 
                          locationList.locations.map((item, idx) => {
                            return (
                              <tr key={`cost-${idx}`}>
                                <td className="">
                                  {locationList.locations.length > 0 &&
                                    <div className="custom-checkbox">
                                      <input 
                                          type="checkbox" 
                                          id={`region-${idx}`}
                                          checked={deleteList.findIndex(v => v === item.id) !== -1}
                                      />
                                      <label 
                                        htmlFor={`region-${idx}`}
                                        onClick={(e) => {
                                          if(!isLoadingRemoveLocation) {
                                            let _del = deleteList.slice();
                                            let idx = _del.findIndex(v => v === item.id);

                                            if(idx === -1) {
                                              _del.push(item.id);
                                            }else {
                                              _del.splice(idx, 1);
                                            }

                                            setDeleteList(_del);
                                          }
                                        }}
                                      />
                                    </div>
                                  }
                                </td>

                                <td className="td_place_id">{item.gbPlaceID}</td>
                                <td className="alias-wrap td_place_alias">
                                  {item.alias}

                                  <FiEdit onClick={() => toogleEditAlias(item)} />
                                </td>
                                <td className="status-wrap">
                                  {item.verificationStatusText === "Unverified" &&
                                    <button onClick={() => toggleVerification(item.id)}>
                                      <b>Request Verification</b>
                                    </button>
                                  }
                                  
                                  {item.verificationStatusText === "Verified" &&
                                    <b className="badge-status green-text">
                                      <img src={IconVerified} alt="" />
                                      Verified
                                    </b>
                                  }

                                  {item.verificationStatusText === "In Review" &&
                                    <b className="badge-status yellow-text">
                                      <img src={IconReview} alt="" />
                                      In Review
                                    </b>
                                  }

                                  {item.verificationStatusText === "Unspecified" &&
                                    <b className="badge-status blue-text">
                                      <img src={IconUnspecified} alt="" />
                                      Unspecified
                                    </b>
                                  }

                                  {item.verificationStatusText === "Suspended" &&
                                    <b className="badge-status red-text">
                                      <img src={IconSuspend} alt="" />
                                      Suspended
                                    </b>
                                  }
                                </td>
                                <td className="test-url-wrap">
                                  <p>
                                    <b 
                                      onClick={() => {
                                        toggleModalAndroid();
                                        setDataQr(item.testURLs);
                                        getTestUrlImage("android", item.testURLs);
                                      }}
                                    >
                                      <img src={IconQr} alt="" />
                                      Android
                                    </b>

                                    <b
                                      onClick={() => {
                                        toggleModalIOS();
                                        setDataQr(item.testURLs);
                                        getTestUrlImage("ios", item.testURLs);
                                      }}
                                    >
                                      <img src={IconQr} alt="" />
                                      iOS
                                    </b>
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                          :
                          <tr className="no-data-row">
                            <td
                              colSpan={4}
                              className="no-data-column"
                              style={{ textAlign: "center" }}
                            >
                              <p className="no-result-found">
                                <b>No location found. Start by clicking “+ add location”.</b>
                              </p>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className="pagination-wrapper">
                    {locationList.locations.length > 0 && (
                      <Pagination
                        total={locationList.totalItems}
                        page={pageDisplay}
                        listPerPage={listPageVal.value}
                        step={1}
                        onChangePage={handleChangePage}
                      />
                    )}
                  </div>
                </div>
              }
            </>
          }
        </div>
        {/* local */}
        
        {/* non local */}
        <div className="entry-google sectionWrap">
          <div className="entry-google-top entry-google-top-non-local">
            <b>Non-local</b>
            <p>
              Non-local entry point group includes all entry points that don't rely on locations.
            </p>

            {(!isLoadingGetData && !retrieveDataErrorDetail.code) &&
              <>
                <ButtonWithLoadingOrIcon
                  icon={{
                    type: "svg",
                    src: FiEdit,
                  }}
                  className={`${channelData.channel.status === "verified" ? "force-hide" : ""} no-fill-button entry-google-top-button main-button-40 edit-non-local`}
                  text="Edit"
                  position="left"
                  isDisabled={channelData.channel.status === "verified"}
                  onClickAction={() => {
                    if(channelData.channel.status !== "verified") {
                      props.history.push(`/o/${orgID}/integration/google-business-messages/detail/${match.params.id}/edit-entry-point-non-local`)
                    }
                  }}
                />
              </>
            }
          </div>
          
          {isLoadingGetData || retrieveDataErrorDetail.code ?
            <RetrieveDataLoading
              isLoading={isLoadingGetData}
              errorMessage={retrieveDataError.message}
              hideRefresh
            />
            :
            <>
              <div className="entry-google-middle">
                <label>
                  <FiActivity />
                  <b>Status</b>
                </label>
                
                <DisabledEnabled 
                  status={channelData.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "NON_LOCAL") !== -1 ? "enabled" : "disabled"}
                />

                {channelData.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "NON_LOCAL") !== -1 &&
                  <div className="non-local-block">
                    {channelData.profile.entryPointConfigs.nonLocal.sitelinkDomains.map((v, i) => {
                      return (
                        <>
                          <label>
                            <FiLink />
                            <b>Domain{i > 0 ? `#${i + 1}` : ""}</b>
                          </label>
                          
                          <CopyBox
                            className="margin-bottom-24" 
                            id={`domain-link[${i}]`}
                            val={v}
                            copyText="Domain Copied"
                          />
                        </>
                      )
                    })}

                    <label>
                      <FiBookOpen />
                      <b>Regions</b>
                    </label>

                    <p className="value-non-local">
                      <b>
                        {regionList.length === channelData.profile.entryPointConfigs.nonLocal.regionCodes.length ?
                          "All Regions" 
                          : 
                          <div className="region-block">
                            <b className="custom-regions">Custom Regions</b>

                            {channelData.profile.entryPointConfigs.nonLocal.regionCodes.map((v) => {
                              return (
                                <div className="region-badge">
                                  <b>{regionList[regionList.findIndex(_v => _v.id === v)].name}</b>
                                </div>
                              )
                            })}
                          </div>
                        }
                      </b>
                    </p>

                    <label>
                      <FiPhone />
                      <b>Phone Number</b>
                    </label>
                    <p className="value-non-local"><b>{channelData.profile.entryPointConfigs.nonLocal.phoneNumber}</b></p>

                    <label>
                      <FiHeadphones />
                      <b>Contact Us URLs</b>
                    </label>
                    
                    <CopyBox 
                      id={`url-contact-us`}
                      val={channelData.profile.entryPointConfigs.nonLocal.contactURL}
                      copyText="Contact Us URLs Copied"
                    />

                    {channelData.profile.entryPointConfigs.nonLocal.contactOptions.length > 0 &&
                      <div className="region-block contact-block">
                        {channelData.profile.entryPointConfigs.nonLocal.contactOptions.map((v) => {
                          return (
                            <div className="region-badge">
                              <b>{CONTACTS[v]}</b>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                }
              </div>
            </>
          }
        </div>
        {/* non local */}
      </>
  );
};

export default EntryPoint;