import BaseService from "../../BaseService";
import { CONFIG, API_KEY } from "../../../config";
import { checkDevice, checkOS, getDeviceID } from "../../../helper/HelperGeneral";

class ServiceSalesTalkDefault {
    generateToken(callback) {     
        BaseService.doGetCustomHeader(CONFIG.requestURLSalestalk + API.GENERATE_TOKEN, {}, callback);
    }

    postRequestOtp(data, callback) {        
        let headers = {
            API_SECRET: "tap2order",
            API_TOKEN: localStorage.getItem("token_st")
        };
        
        BaseService.doPostCustomHeader(CONFIG.requestURLSalestalk + API.REQUEST_OTP, data, headers, callback);
    }

    postResendOtp(data, callback) {        
        let headers = {
            API_SECRET: "tap2order",
            API_TOKEN: localStorage.getItem("token_st")
        };
        
        BaseService.doPostCustomHeader(CONFIG.requestURLSalestalk + API.RESEND_OTP, data, headers, callback);
    }

    postVerifyOtp(data, callback) {        
        let headers = {
            "API_SECRET": "tap2order",
            "API_TOKEN": localStorage.getItem("token_st"),
            "app-version": "",
            'device-type': 'web',
            'device-model': checkDevice(),
            'device-os-version': checkOS(),
            'device-name': navigator.userAgent,
            'user-agent-version': 'web',
            'Device-Identifier': getDeviceID(),
            'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        
        BaseService.doPostCustomHeader(CONFIG.requestURLSalestalk + API.VERIFY_OTP, data, headers, callback);
    }

    postGetStatus(idOrTime, data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };
        
        BaseService.doPostAccessToken(API.GET_STATUS, data, headers, callback);
    }

    postConnect(idOrTime, data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };
        
        BaseService.doPostAccessToken(API.CONNECT, data, headers, callback);
    }

    postDisconnect(idOrTime, data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };
        
        BaseService.doPostAccessToken(API.DISCONNECT, data, headers, callback);
    }

    postGetCategory(idOrTime, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };
        
        BaseService.doPostAccessToken(API.GET_CATEGORY, {}, headers, callback);
    }

    postProductList(idOrTime, data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };
        
        BaseService.doPostAccessToken(API.GET_PRODUCT_LIST, data, headers, callback);
    }

    postProductDetail(idOrTime, data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };
        
        BaseService.doPostAccessToken(API.GET_PRODUCT_DETAIL, data, headers, callback);
    }

    postSetCustomProvider(idOrTime, data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };
        
        BaseService.doPostAccessToken(API.SET_CUSTOM, data, headers, callback);
    }

    postEditCustomProvider(idOrTime, data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": idOrTime
        };
        
        BaseService.doPostAccessToken(API.EDIT_CUSTOM, data, headers, callback);
    }
}

const API = {
    GENERATE_TOKEN: "/api-token/generate",
    REQUEST_OTP: "/v5/login",
    RESEND_OTP: "/v5/otp/request",
    VERIFY_OTP: "/v5/verify/request_api_key",
    GET_STATUS: "/organization/salestalk_admin/get_status",
    CONNECT: "/organization/salestalk_admin/connect_tap2order",
    DISCONNECT: "/organization/salestalk_admin/disconnect",
    GET_CATEGORY: "/organization/salestalk/get_category_list",
    GET_PRODUCT_LIST: "/organization/salestalk/get_product_list",
    GET_PRODUCT_DETAIL: "/organization/salestalk/get_product_details",
    SET_CUSTOM: "/organization/salestalk_admin/connect_custom",
    EDIT_CUSTOM: "/organization/salestalk_admin/edit_configs/custom"
}

export default new ServiceSalesTalkDefault()