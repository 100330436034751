const INITIAL_STATE = [];

let reduxQuickReplyList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_QUICK_REPLY_LIST':
        return action.quickReplyList;
      case 'CLEAR_QUICK_REPLY_LIST':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxQuickReplyList;