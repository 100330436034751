const INITIAL_STATE = {
    lastRun: false,
    callback: false,
    room: false
};
  
let reduxReducerReloadCaseDetail = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SET_RELOAD_CASE_DETAIL':
        return action.reloadCaseDetail;
    case 'CLEAR_RELOAD_CASE_DETAIL':
        return INITIAL_STATE;
    default:
        return state;
    }
};
  
export default reduxReducerReloadCaseDetail;