import React, { useState, useEffect } from 'react';
import './MessageHistoryFilterModal.scss';
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import 'react-day-picker/lib/style.css';
import LabelDropdown from '../labelDropdown/LabelDropdown';
import ErrorOrInfoComp from "../errorOrInfoComp/ErrorOrInfoComp";
import { FiPlus, FiX, FiInfo } from 'react-icons/fi';
import iconFilter from '../../../assets/img/icon-filter.svg';
import iconCheck from '../../../assets/img/icon-check-white.svg';
import iconText from '../../../assets/img/icon-text-purple-v2.svg';
import iconImage from '../../../assets/img/icon-image-green.svg';
import iconFile from '../../../assets/img/icon-file-orange.svg';
import iconWSME from '../../../assets/img/integration/launcher/icon-whatsapp.svg';
import iconWABA from '../../../assets/img/integration/launcher/icon-whatsapp-ba.svg';
import HelperInput from '../../../helper/HelperInput';
import { doToast, scrollToClass } from '../../../helper/HelperGeneral';

const MessageHistoryFilterModal = (props) => {
    let [filterDataMessageHistory, setFilterDataMessageHistory] = useState({
        messageTypes: [],
        channelTypes: [],
        status: "",
        contactPhone: "",
    });
    let [isShowStatusDropdown, setIsShowStatusDropdown] = useState(false);
    let [selectedStatus, setSelectedStatus] = useState({
        val: "",
        text: "",
        background: ""
    });
    let [errorMessageData, setErrorMessageData] = useState({
        phone: ""
    })

    useEffect(() => {
        // Set current/previous filter data when opened/closed
        const currentFilter = {...props.currentFilter};
        setFilterDataMessageHistory({
            messageTypes: currentFilter.messageTypes.slice(),
            channelTypes: currentFilter.channelTypes.slice(),
            status: (' ' + currentFilter.status).slice(1),
            contactPhone: beautifyPhoneNumber(currentFilter.contactPhone),
        });
        setSelectedStatus({
            val: "",
            text: "",
            background: ""
        });
        MESSAGE_STATUS_LABELS.map((v) => {
            if (props.currentFilter.status === v.val) {
                setSelectedStatus(v);
            }

            return null;
        })
    }, [props.isOpen]);

    const MESSAGE_TYPES = [
        {
            val: "text",
            text: "Text",
            icon: <img alt="" className="icon-message-type" src={iconText} style={{background: "#F2D5FB"}} />
        },
        {
            val: "image",
            text: "Image",
            icon: <img alt="" className="icon-message-type" src={iconImage} style={{background: "#E0FBE9"}} />
        },
        {
            val: "file",
            text: "Document",
            icon: <img alt="" className="icon-message-type" src={iconFile} style={{background: "#FFF2E5"}} />
        },
    ];

    const CHANNEL_TYPES = [
        {
            val: "whatsapp",
            text: "WhatsApp SME",
            icon: <img alt="" className="icon-channel-type" src={iconWSME} />
        },
        {
            val: "whatsappba",
            text: "WhatsApp Business API",
            icon: <img alt="" className="icon-channel-type" src={iconWABA} />
        },
    ];
    
    const MESSAGE_STATUS_LABELS = [
        {
            val: "pending",
            text: "Pending",
            background: "#FF7E00"
        },
        {
            val: "sent",
            text: "Sent",
            background: "#19C700"
        },
        {
            val: "acknowledged",
            text: "Acknowledged",
            background: "#458500"
        },
        {
            val: "delivered",
            text: "Delivered",
            background: "#025F2D"
        },
        {
            val: "read",
            text: "Read",
            background: "#3BAEFF"
        },
        {
            val: "failed",
            text: "Failed",
            background: "#FF3F57"
        },
    ];

    let onContactPhoneChanged = (e) => {
        let filterDataCopy = {...filterDataMessageHistory};
        if (HelperInput.numberOnly(e.target.value.replaceAll(" ", ""))) {
            filterDataCopy.contactPhone = beautifyPhoneNumber(e.target.value);

            setErrorMessageData({
                phone: ""
            })
        }
        setFilterDataMessageHistory(filterDataCopy);
    }

    let beautifyPhoneNumber = (phoneNumber) => {
        return HelperInput.phoneBeautify(phoneNumber.replaceAll(" ", ""));
    }

    let toggleMessageTypeFilter = (messageType) => {
        let filterDataCopy = {...filterDataMessageHistory};
        var index = filterDataCopy.messageTypes.indexOf(messageType);
        if (index !== -1) {
            filterDataCopy.messageTypes.splice(index, 1);
        }
        else {
            filterDataCopy.messageTypes.push(messageType);
        }
        setFilterDataMessageHistory(filterDataCopy);
    }

    let toggleChannelTypeFilter = (channelType) => {
        let filterDataCopy = {...filterDataMessageHistory};
        var index = filterDataCopy.channelTypes.indexOf(channelType);
        if (index !== -1) {
            filterDataCopy.channelTypes.splice(index, 1);
        }
        else {
            filterDataCopy.channelTypes.push(channelType);
        }
        setFilterDataMessageHistory(filterDataCopy);
    }

    let onLabelSelected = (status) => {
        let filterDataCopy = {...filterDataMessageHistory};
        filterDataCopy.status = status.val;
        setFilterDataMessageHistory(filterDataCopy);
        setSelectedStatus(status);
    }

    let applyFilter = () => {
        let _errorMessageData = {...errorMessageData};
        let errorClass = "";
        let errorCount = 0;

        if(filterDataMessageHistory.contactPhone.replaceAll(" ", "").length > 15) {
            _errorMessageData.phone = "Characters exceed limit";
            errorCount = errorCount + 1;
            errorClass = "input-phone";
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`${errorClass}`);
        }else {
            let filterDataCopy = {...filterDataMessageHistory};
            if (filterDataCopy.contactPhone.length > 0) {
                filterDataCopy.contactPhone = filterDataCopy.contactPhone.replaceAll(" ", "");
                if (filterDataCopy.contactPhone.length < 7 || filterDataCopy.contactPhone.length > 15) {
                    doToast("Phone number's length must be 7-15 digits", "fail");
                    return;
                }
            }
            // if (JSON.stringify(filterDataCopy) === JSON.stringify(props.currentFilter)) {
            //     doToast("No changes found", "fail");
            //     return;
            // }
            props.updateMessageHistoryFilter(filterDataCopy);
            props.toggle();
        }
    };

    let clearFilter = () => {
        setFilterDataMessageHistory({
            messageTypes: [],
            channelTypes: [],
            status: "",
            contactPhone: "",
        });
        setSelectedStatus({
            val: "",
            text: "",
            background: ""
        });
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-message-history-filter">
            <ModalBody>
                <div className="modal-title">
                    <img alt="" className="icon-filter" src={iconFilter} />
                    <h3>Filters</h3>
                </div>

                <h5>
                    Recipient’s Phone Number

                    <span className={errorMessageData.phone === "Characters exceed limit" ? "font-root-medium red-text absolute-right-0" : "font-root-medium grey-font absolute-right-0"}>{filterDataMessageHistory.contactPhone.replaceAll(" ", "").length}/15</span>
                </h5>
                <div className={`phone-input ${errorMessageData.phone !== "" ? "border-red" : ""}`}>
                    <input
                        type="text"
                        value={filterDataMessageHistory.contactPhone}
                        placeholder="62 8XXX XXX XXX"
                        onChange={onContactPhoneChanged}
                        disabled={false}
                        className={"input-phone"}
                    />
                    <FiPlus />
                </div>

                {errorMessageData.phone !== "" &&
                    <ErrorOrInfoComp 
                        icon={<FiInfo />}
                        text={errorMessageData.phone}
                        _className={"red-text"}
                    />
                }

                <h5>Message Type</h5>
                <div id="message-type-wrapper">
                    {MESSAGE_TYPES.map((value, index) => {
                        return <div 
                            className={`filter-item-wrapper${filterDataMessageHistory.messageTypes.includes(value.val) ? `-active`: ``}`} 
                            key={`message-type-${index}`}
                            style={{marginLeft: "14px"}}
                            onClick={() => toggleMessageTypeFilter(value.val)}
                        >
                            <img alt="" className="checkbox" src={iconCheck} />
                            {value.icon}
                            <p className="text-message-type">{value.text}</p>
                        </div>
                    })}
                </div>

                <h5>Channel Type</h5>
                <div id="channel-type-wrapper">
                    {CHANNEL_TYPES.map((value, index) => {
                        return <div 
                            className={`filter-item-wrapper${filterDataMessageHistory.channelTypes.includes(value.val) ? `-active`: ``}`} 
                            key={`channel-type-${index}`}
                            style={{marginLeft: "8px"}}
                            onClick={() => toggleChannelTypeFilter(value.val)}
                        >
                            <img alt="" className="checkbox" src={iconCheck} />
                            {value.icon}
                            <p className="text-channel-type">{value.text}</p>
                        </div>
                    })}
                </div>

                <h5>Status</h5>
                <LabelDropdown 
                    isOpenDropdown={isShowStatusDropdown}
                    toggleDropdown={() => setIsShowStatusDropdown(!isShowStatusDropdown)}
                    labelsArray={MESSAGE_STATUS_LABELS}
                    selectedLabel={selectedStatus}
                    onLabelSelected={onLabelSelected}
                    isDisabled={false}
                />
            </ModalBody>
            
            <ModalFooter>
                <button className="button-clear" onClick={clearFilter}>
                    <FiX />
                    <b>Clear Selection</b>
                </button>

                <button 
                    className="no-fill-button" 
                    onClick={() => {
                        props.toggle();
                    }}
                >
                    Cancel
                </button>

                <button className="orange-button" onClick={applyFilter}>
                    Apply Filters
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default MessageHistoryFilterModal;
