import React from "react";
import "./ModalSendTemplatedMessage.scss";
import { Modal, ModalBody } from "reactstrap";
import Airplane from "../../../assets/img/icon-airplane.svg";
import ButtonWithLoadingOrIcon from "../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

let ModalSending = ({action, isLoading, isShow, toggle}) => {
  return (
    <Modal
      className="modal-send-templated-message"
      isOpen={isShow}
    >
      <ModalBody>
        <div>
          <b className="modal-send-title">Confirm to send templated message</b>
        </div>
        <p>
          By sending this, your organization may be charged according to
          WhatsApp Business API pricing scheme.
        </p>

        <div className="modal-send-templated-message-buttons">
          <button
            className="no-fill-button"
            onClick={() => {
              if(!isLoading) {
                toggle();
              }else {
                console.log("Please wait")
              }
            }}
          >
            <b>Cancel</b>
          </button>

          {!isLoading ?
            <ButtonWithLoadingOrIcon
              className="orange-button main-button-48"
              text="Send Message"
              position="left"
              icon={{
                type: "img",
                src: Airplane,
              }}
              onClickAction={action}
            />
            :
            <ButtonWithLoadingOrIcon
              className="orange-button main-button-48"
              text="Send Message"
              position="left"
              loadingColor="gray"
              isDisabled
              isLoading
            />
          }
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalSending;
