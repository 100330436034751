const INITIAL_STATE = {
  data: {
    topic: {
      label: "",
      value: ""
    },
    channel: {
      displayName: "",
      errorDisplayName: "",
      logoURL: "",
      logoFile: null,
      errorLogo: ""
    },
    conversations: [
      {
        languageCode: {
            value: "",
            label: ""
        },
        errorLanguageCode: "",
        welcomeMessage: "",
        errorWelcomeMessage: "",
        privacyPolicyURL: "",
        errorPrivacyPolicyURL: "",
        defaultLocale: true
      }
    ],
    entry: {
      local: false,
      nonLocal: {
        isEnabled: false,
        domain: [
          {
            value: "",
            error: ""
          },
        ],
        region: [],
        phoneNumber: "",
        phoneNumberError: "",
        contactURL: "",
        contactURLError: "",
        contactOptions: [],
        contactOptionsError: "",
        isAllRegion: false,
        regionError: "" 
      }
    }
  },

  origin: {
    topic: {
      label: "",
      value: ""
    },
    channel: {
      displayName: "",
      errorDisplayName: "",
      logoURL: "",
      logoFile: null,
      errorLogo: ""
    },
    conversations: [
      {
        languageCode: {
            value: "",
            label: ""
        },
        errorLanguageCode: "",
        welcomeMessage: "",
        errorWelcomeMessage: "",
        privacyPolicyURL: "",
        errorPrivacyPolicyURL: "",
        defaultLocale: true
      }
    ],
    entry: {
      local: false,
      nonLocal: {
        isEnabled: false,
        domain: [
          {
            value: "",
            error: ""
          },
        ],
        region: [],
        phoneNumber: "",
        phoneNumberError: "",
        contactURL: "",
        contactURLError: "",
        contactOptions: [],
        contactOptionsError: "",
        isAllRegion: false,
        regionError: "" 
      }
    }
  },
};

let reduxReducerCreateGoogle = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_CREATE_GOOGLE':
        return action.createGoogle;
      case 'CLEAR_CREATE_GOOGLE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerCreateGoogle;