import React, { useState, useEffect } from "react";
import { doToast } from "../../../../../helper/HelperGeneral";
import "./Launch.scss";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import IntegrationGoogleServices from "../../../../../services/integration/IntegrationGoogleServices";
import CustomSearchBox from "../../../../reuseableComponent/customSearchBox/CustomSearchBox";
// import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
// import Pagination from "../../../../reuseableComponent/pagination/Pagination";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupConfirmation from "../../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import LaunchWhite from "../../../../../assets/img/launch-white.svg";
import LaunchGrey from "../../../../../assets/img/launch-grey.svg";
import { FiArrowUpRight } from "react-icons/fi";

let Launch = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);

  // let listPageOption = [
  //   {
  //     label: "20",
  //     value: 20,
  //   },
  //   {
  //     label: "50",
  //     value: 50,
  //   },
  //   {
  //     label: "100",
  //     value: 100,
  //   },
  // ];

  let [isLoadingGetDetail, setIsLoadingGetDetail] = useState(true);
  let [retrieveDataErrorDetail, setRetrieveDataErrorDetail] = useState({
    code: false,
    message: false,
  });

  let [showModalLaunch, setShowModalLaunch] = useState(false);
  let [showModaUnlaunch, setShowModalUnlaunch] = useState(false);
  let [showModalLaunchNonLocal, setShowModalLaunchNonLocal] = useState(false);
  let [showModaUnlaunchNonLocal, setShowModalUnlaunchNonLocal] = useState(false);

  let [showModalLaunchError, setShowModalLaunchError] = useState(false);
  let [showModaUnlaunchError, setShowModalUnlaunchError] = useState(false);
  let [launchError, setLaunchError] = useState("");
  let [unlaunchError, setUnlaunchError] = useState("");

  let [locationListOrigin, setLocationListOrigin] = useState([]);
  let [locationList, setLocationList] = useState([]);
  let [locationListNonLocalOrigin, setLocationListNonLocalOrigin] = useState([]);
  let [locationListNonLocal, setLocationListNonLocal] = useState([]);

  let [selectedLaunchList, setSelectedLaunchList] = useState([]);
  let [selectedUnlaunchList, setSelectedUnlaunchList] = useState([]);
  let [selectedLaunchNonLocalList, setSelectedLaunchNonLocalList] = useState([]);
  let [selectedUnlaunchNonLocalList, setSelectedUnlaunchNonLocalList] = useState([]);

  let [loadingLaunch, setLoadingLaunch] = useState(false);
  let [loadingUnlaunch, setLoadingUnlaunch] = useState(false);

  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [retrieveDataErrorNonLocal, setRetrieveDataErrorNonLocal] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetLocationList, setIsLoadingGetLocationList] = useState(true);
  let [isLoadingGetLocationNonLocalList, setIsLoadingGetLocationNonLocalList] = useState(true);
  // let [pageDisplay, setPageDisplay] = useState(1);
  // let [listPage, setListPage] = useState(20);
  // let [listPageVal, setListPageVal] = useState(listPageOption[0]);
  let [channelData, setChannelData] = useState(false);
  let [searchLocalVal, setSearchLocalVal] = useState("");
  let [searchNonLocalVal, setSearchNonLocalVal] = useState("");

  let toggleModal = (modal) => {
    if (!loadingLaunch && !loadingUnlaunch) {
      if (modal === "launched") {
        setShowModalLaunch(!showModalLaunch)
      }

      if (modal === "unlaunched") {
        setShowModalUnlaunch(!showModaUnlaunch)
      }

      if (modal === "launchError") {
        setShowModalLaunchError(!showModalLaunchError)
      }

      if (modal === "unlaunchError") {
        setShowModalUnlaunchError(!showModaUnlaunchError)
      }
    }
  }

  let toggleModalNonLocal = (modal) => {
    if (!loadingLaunch && !loadingUnlaunch) {
      if (modal === "launched") {
        setShowModalLaunchNonLocal(!showModalLaunchNonLocal)
      }

      if (modal === "unlaunched") {
        setShowModalUnlaunchNonLocal(!showModaUnlaunchNonLocal)
      }

      if (modal === "launchError") {
        setShowModalLaunchError(!showModalLaunchError)
      }

      if (modal === "unlaunchError") {
        setShowModalUnlaunchError(!showModaUnlaunchError)
      }
    }
  }

  // let onSelectListPage = (val) => {
  //   setListPageVal(val);
  //   setPageDisplay(1);
  //   getLaunchList(1, val.value);
  // };

  // let handleChangePage = (page) => {
  //   setPageDisplay(page);
  //   getLaunchList(page, false);
  // };

  let checkID = (match) => {
    let { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getChannelDetail();
    }
  }, [orgID]);

  let onChangeSearch = (e) => {
    let val = e.target.value.toLowerCase();
    setSearchLocalVal(val);

    if (val === "") {
      setLocationList(locationListOrigin)
    } else {
      let result = [];
      for (let i in locationListOrigin) {
        let objectKey = Object.keys(locationListOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof locationListOrigin[i][objectKey[j]] === "string";
          if (locationListOrigin[i][objectKey[j]].toString().toLowerCase().includes(val) && isString) {
            result.push(locationListOrigin[i])
            break;
          }
        }
      }

      setLocationList(result);
    }
  }

  let onChangeSearchNonLocal = (e) => {
    let val = e.target.value.toLowerCase();
    setSearchNonLocalVal(val);

    if (val === "") {
      setLocationListNonLocal(locationListNonLocalOrigin)
    } else {
      let result = [];
      for (let i in locationListNonLocalOrigin) {
        let objectKey = Object.keys(locationListNonLocalOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof locationListNonLocalOrigin[i][objectKey[j]] === "string";
          if (locationListNonLocalOrigin[i][objectKey[j]].toString().toLowerCase().includes(val) && isString) {
            result.push(locationListNonLocalOrigin[i])
            break;
          }
        }
      }

      setLocationListNonLocal(result);
    }
  }

  let getChannelDetail = () => {
    setIsLoadingGetDetail(true);

    let data = {
      id: Number(match.params.id),
    };

    IntegrationGoogleServices.getDetail(orgID, data, (response) => {
      let _retrieveDataError = { ...retrieveDataErrorDetail };

      if (response.dataResult.error.message === "") {
        setChannelData(response.dataResult.data);

        if (response.dataResult.data.channel.status === "verified") {
          getLaunchList();
          getLaunchListNonLocal();
        }

        setIsLoadingGetDetail(false);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataErrorDetail(_retrieveDataError);
        setIsLoadingGetDetail(false);
      }

    }
    );
  }

  let getLaunchList = (page = false, pageSize = false) => {
    setIsLoadingGetLocationList(true);

    let data = {
      channelID: Number(match.params.id),
      pageNumber: page ? page : 1,
      pageSize: pageSize ? pageSize : 9999
    };

    IntegrationGoogleServices.getLaunchList(orgID, data, (response) => {
      let _retrieveDataError = { ...retrieveDataError };

      if (response.dataResult.error.message === "") {
        setLocationList(response.dataResult.data.locations);
        setLocationListOrigin(response.dataResult.data.locations);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
      }
      setIsLoadingGetLocationList(false);
    }
    );
  }

  let getLaunchListNonLocal = (page = false, pageSize = false) => {
    setIsLoadingGetLocationNonLocalList(true);

    let data = {
      channelID: Number(match.params.id),
      // pageNumber: page ? page : pageDisplay,
      // pageSize: pageSize ? pageSize : listPageVal.value
    };

    IntegrationGoogleServices.getLaunchListNonLocal(orgID, data, (response) => {
      let _retrieveDataError = { ...retrieveDataErrorNonLocal };

      if (response.dataResult.error.message === "") {
        setLocationListNonLocalOrigin(response.dataResult.data.regions);
        setLocationListNonLocal(response.dataResult.data.regions);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataErrorNonLocal(_retrieveDataError);
      }
      setIsLoadingGetLocationNonLocalList(false);
    }
    );
  }

  let submitLaunch = () => {
    setLaunchError("");
    setLoadingLaunch(true);

    let _data = {
      locationIDs: selectedLaunchList,
      channelID: Number(match.params.id),
    };

    IntegrationGoogleServices.launchLocation(orgID, _data, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          setLoadingLaunch(false);
          toggleModal("launched");
          getLaunchList();
          setSelectedUnlaunchList([]);
          setSelectedLaunchList([]);
        } else {
          setLaunchError(response.dataResult.data.message);
          toggleModal("launched");
          toggleModal("launchError");
          setLoadingLaunch(false);
        }
      } else {
        toggleModal("launched");
        toggleModal("launchError");
        setLaunchError(response.dataResult.error.message);
        setLoadingLaunch(false);
      }
    });
  };

  let submitUnlaunch = () => {
    setUnlaunchError("");
    setLoadingUnlaunch(true);

    let _data = {
      locationIDs: selectedUnlaunchList,
      channelID: Number(match.params.id),
    };

    IntegrationGoogleServices.unlaunchLocation(orgID, _data, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          setLoadingUnlaunch(false);
          toggleModal("unlaunched");
          getLaunchList();
          setSelectedLaunchList([]);
          setSelectedUnlaunchList([]);
        } else {
          setUnlaunchError(response.dataResult.data.message);
          toggleModal("unlaunched");
          toggleModal("unlaunchError");
          setLoadingUnlaunch(false);
        }
      } else {
        toggleModal("unlaunched");
        toggleModal("unlaunchError");
        setUnlaunchError(response.dataResult.error.message);
        setLoadingUnlaunch(false);
      }
    });
  };

  let submitLaunchNonLocal = () => {
    setLaunchError("");
    setLoadingLaunch(true);

    let _data = {
      regionCodes: selectedLaunchNonLocalList,
      channelID: Number(match.params.id),
    };

    IntegrationGoogleServices.launchLocationNonLocal(orgID, _data, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          setLoadingLaunch(false);
          toggleModalNonLocal("launched");
          getLaunchListNonLocal();
          setSelectedUnlaunchNonLocalList([]);
          setSelectedLaunchNonLocalList([]);
        } else {
          setLaunchError(response.dataResult.data.message);
          toggleModalNonLocal("launched");
          toggleModalNonLocal("launchError");
          setLoadingLaunch(false);
        }
      } else {
        toggleModalNonLocal("launched");
        toggleModalNonLocal("launchError");
        setLaunchError(response.dataResult.error.message);
        setLoadingLaunch(false);
      }
    });
  };

  let submitUnlaunchNonLocal = () => {
    setUnlaunchError("");
    setLoadingUnlaunch(true);

    let _data = {
      regionCodes: selectedUnlaunchNonLocalList,
      channelID: Number(match.params.id),
    };

    IntegrationGoogleServices.unlaunchLocationNonLocal(orgID, _data, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          setLoadingUnlaunch(false);
          toggleModalNonLocal("unlaunched");
          getLaunchListNonLocal();
          setSelectedLaunchNonLocalList([]);
          setSelectedUnlaunchNonLocalList([]);
        } else {
          setUnlaunchError(response.dataResult.data.message);
          toggleModalNonLocal("unlaunched");
          toggleModalNonLocal("unlaunchError");
          setLoadingUnlaunch(false);
        }
      } else {
        toggleModalNonLocal("unlaunched");
        toggleModalNonLocal("unlaunchError");
        setUnlaunchError(response.dataResult.error.message);
        setLoadingUnlaunch(false);
      }
    });
  };

  let onClearSearch = () => {
    setLocationList(locationListOrigin);
    setSearchLocalVal("");
  }

  let onClearSearchNonLocal = () => {
    setLocationListNonLocal(locationListNonLocal);
    setSearchNonLocalVal("");
  }

  return (
    (isLoadingGetDetail || retrieveDataErrorDetail.code ?
      <RetrieveDataLoading
        isLoading={isLoadingGetLocationList}
        errorMessage={retrieveDataError.message}
        hideRefresh
      />
      :
      (channelData.channel.status === "unverified" ?
        <div className="no-launch-data">
          <b>
            No Entry Point Found
          </b>

          <p>
            Before launching entry points, you have to verify your channel.
          </p>

          <ButtonWithLoadingOrIcon
            text="Go to Channel Profile"
            position="right"
            className="orange-button main-button-48"
            icon={{
              src: FiArrowUpRight,
              type: "svg"
            }}
            onClickAction={() => {
              props.goToTab("channelProfile");
            }}
          />
        </div>
        :
        <>
          <PopupConfirmation
            isOpen={showModaUnlaunchError}
            toggle={() => toggleModal("unlaunchError")}
            title={"Can’t unlaunch location"}
            description={unlaunchError}
            className="modal-launch modal-launch-error"
          >
            <ButtonWithLoadingOrIcon
              text="Close"
              className="main-button-48 orange-button"
              onClickAction={() => toggleModal("unlaunchError")}
            />
          </PopupConfirmation>

          <PopupConfirmation
            isOpen={showModalLaunchError}
            toggle={() => toggleModal("launchError")}
            title={"Can’t launch location"}
            description={launchError}
            className="modal-launch modal-launch-error"
          >
            <ButtonWithLoadingOrIcon
              text="Close"
              className="main-button-48 orange-button"
              onClickAction={() => toggleModal("launchError")}
            />
          </PopupConfirmation>

          <div className="launch-google sectionWrap">
            <PopupConfirmation
              isOpen={showModalLaunch}
              toggle={() => toggleModal("launched")}
              title={`Launch ${selectedLaunchList.length} Location${selectedLaunchList.length > 1 ? "s" : ""}`}
              description={`Are you sure you want to launch ${selectedLaunchList.length} location${selectedLaunchList.length > 1 ? "s" : ""}?`}
              className="modal-launch"
            >
              {!loadingLaunch ?
                <ButtonWithLoadingOrIcon
                  text="Launch"
                  className="main-button-48 orange-button"
                  onClickAction={() => submitLaunch()}
                  icon={{
                    src: LaunchWhite,
                    type: "img"
                  }}
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon
                  text="Launch"
                  className="main-button-48 orange-button"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  position="left"
                />
              }
            </PopupConfirmation>

            <PopupConfirmation
              isOpen={showModaUnlaunch}
              toggle={() => toggleModal("unlaunched")}
              title={`Unlaunch ${selectedUnlaunchList.length} Location${selectedUnlaunchList.length > 1 ? "s" : ""}`}
              description={`Are you sure you want to unlaunch ${selectedUnlaunchList.length} location${selectedUnlaunchList.length > 1 ? "s" : ""}?`}
              className="modal-launch"
            >
              {!loadingUnlaunch ?
                <ButtonWithLoadingOrIcon
                  text="Unlaunch"
                  className="main-button-48 red-button"
                  onClickAction={() => submitUnlaunch()}
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon
                  text="Unlaunch"
                  className="main-button-48 red-button"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  position="left"
                />
              }
            </PopupConfirmation>

            <PopupConfirmation
              isOpen={showModalLaunch}
              toggle={() => toggleModal("launched")}
              title={`Launch ${selectedLaunchList.length} Location${selectedLaunchList.length > 1 ? "s" : ""}`}
              description={`Are you sure you want to launch ${selectedLaunchList.length} location${selectedLaunchList.length > 1 ? "s" : ""}?`}
              className="modal-launch"
            >
              {!loadingLaunch ?
                <ButtonWithLoadingOrIcon
                  text="Launch"
                  className="main-button-48 orange-button"
                  onClickAction={() => submitLaunch()}
                  icon={{
                    src: LaunchWhite,
                    type: "img"
                  }}
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon
                  text="Launch"
                  className="main-button-48 orange-button"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  position="left"
                />
              }
            </PopupConfirmation>

            <div className="launch-google-top">
              <b>Local</b>
            </div>


            {isLoadingGetLocationList || retrieveDataError.code ?
              <div className="message-template-google-loading-wrapper">
                <RetrieveDataLoading
                  isLoading={isLoadingGetLocationList}
                  errorMessage={retrieveDataError.message}
                  hideRefresh
                />
              </div>
              :
              (channelData.profile.verificationStatus !== "VERIFICATION_STATE_VERIFIED" ?
                <div className="no-launch-data">
                  <b>
                    No Entry Point Found
                  </b>

                  <p>
                    Before launching entry points, you have to verify your channel.
                  </p>

                  <ButtonWithLoadingOrIcon
                    text="Go to Channel Profile"
                    position="right"
                    className="orange-button main-button-48"
                    icon={{
                      src: FiArrowUpRight,
                      type: "svg"
                    }}
                    onClickAction={() => {
                      props.goToTab("channelProfile");
                    }}
                  />
                </div>
                :
                <>
                  <div className="launch-google-middle">
                    {locationListOrigin.length > 0 &&
                      <CustomSearchBox
                        placeholder={"Search by place ID and alias"}
                        onChangeSearch={onChangeSearch}
                        dataToSearch={locationListOrigin}
                        style={{ width: '343px' }}
                        onClearSearch={onClearSearch}
                        search={searchLocalVal}
                      />
                    }

                    {locationList.length > 0 &&
                      <>
                        <ButtonWithLoadingOrIcon
                          className="red-button launch-google-top-button main-button-40 unlaunch-button"
                          text="Unlaunch"
                          onClickAction={() => toggleModal("unlaunched")}
                          isDisabled={selectedUnlaunchList.length === 0}
                        />

                        <ButtonWithLoadingOrIcon
                          icon={{
                            type: "img",
                            src: selectedLaunchList.length === 0 ? LaunchGrey : LaunchWhite,
                          }}
                          className="orange-button launch-google-top-button main-button-40"
                          text="Launch"
                          position="left"
                          onClickAction={() => toggleModal("launched")}
                          isDisabled={selectedLaunchList.length === 0}
                        />
                      </>
                    }
                  </div>

                  {/* {locationList.length > 0 ? */}
                  <>
                    {/* table */}
                    <div className="launch-table-wrapper">
                      <div className="custom-table">
                        <table className="custom-table-content table-launch-list">
                          <thead>
                            <tr>
                              <td>
                                <div className={(selectedUnlaunchList.length + selectedLaunchList.length) === locationList.length ? "custom-checkbox" : "custom-checkbox-stripe"}>
                                  <input
                                    type="checkbox"
                                    id="check-all-region"
                                    onChange={(e) => { }}
                                    checked={(selectedUnlaunchList > 0 || selectedLaunchList.length > 0)}
                                  />
                                  <label
                                    htmlFor="check-all-region"
                                    onClick={(e) => {
                                      if (!loadingLaunch && !loadingUnlaunch) {
                                        let _selectedUnlaunchList = selectedUnlaunchList.slice();
                                        let _selectedLaunchList = selectedLaunchList.slice();
                                        // let _del = deleteList.slice();
                                        let arLaunch = [];
                                        let arUnlaunch = [];

                                        if ((_selectedUnlaunchList.length + _selectedLaunchList.length) !== locationList.length) {
                                          locationList.map((v) => {
                                            if (v.launchStatusText === "Launched") {
                                              arUnlaunch.push(v.id);
                                            }

                                            if (v.launchStatusText === "Unlaunched") {
                                              arLaunch.push(v.id);
                                            }

                                            return null;
                                          })
                                        }

                                        setSelectedLaunchList(arLaunch);
                                        setSelectedUnlaunchList(arUnlaunch);
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="col_td_left">Place ID</td>
                              <td className="col_td_center">Alias</td>
                              <td className="col_td_center">Status</td>
                            </tr>
                          </thead>

                          {locationList.length > 0 &&
                            <tbody>
                              {locationList.map((item, idx) => {
                                return (
                                  <tr key={`cost-${idx}`}>
                                    <td>
                                      <div className="custom-checkbox">
                                        <input
                                          type="checkbox"
                                          id={`region-${idx}`}
                                          onChange={(e) => { }}
                                          checked={
                                            item.launchStatusText === "Launched" ?
                                              (selectedUnlaunchList.findIndex(v => v === item.id) !== -1 ? true : false)
                                              :
                                              (selectedLaunchList.findIndex(v => v === item.id) !== -1 ? true : false)
                                          }
                                        />
                                        <label
                                          htmlFor={`region-${idx}`}
                                          onClick={(e) => {
                                            if (!loadingLaunch && !loadingUnlaunch) {
                                              let _selectedUnlaunchList = selectedUnlaunchList.slice();
                                              let _selectedLaunchList = selectedLaunchList.slice();

                                              let idxUnlaunch = _selectedUnlaunchList.findIndex(v => v === item.id);
                                              let idxLaunch = _selectedLaunchList.findIndex(v => v === item.id);

                                              if (item.launchStatusText === "Launched") {
                                                if (idxUnlaunch === -1) {
                                                  _selectedUnlaunchList.push(item.id);
                                                } else {
                                                  _selectedUnlaunchList.splice(idxUnlaunch, 1);
                                                }
                                              }

                                              if (item.launchStatusText === "Unlaunched") {
                                                if (idxLaunch === -1) {
                                                  _selectedLaunchList.push(item.id);
                                                } else {
                                                  _selectedLaunchList.splice(idxLaunch, 1);
                                                }
                                              }

                                              setSelectedLaunchList(_selectedLaunchList);
                                              setSelectedUnlaunchList(_selectedUnlaunchList);
                                            }
                                          }}
                                        />
                                      </div>
                                    </td>

                                    <td className="">
                                      {item.gbPlaceID}
                                    </td>
                                    <td className="">
                                      {item.alias}
                                    </td>
                                    <td className="">
                                      <p>{item.launchStatusText}</p>
                                    </td>
                                    {/* <td className=""></td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          }
                        </table>

                        {locationList.length === 0 &&
                          <div className="no-launch-data">
                            <b>
                              No Entry Point Found
                              </b>

                            <p>
                              Before launching local entry points, you have to add and verify location.
                              </p>

                            <ButtonWithLoadingOrIcon
                              text="Go to Entry Points"
                              position="right"
                              className="orange-button main-button-48"
                              icon={{
                                src: FiArrowUpRight,
                                type: "svg"
                              }}
                              onClickAction={() => {
                                props.goToTab("entryPoints");
                              }}
                            />
                          </div>
                        }
                      </div>

                      {/* <div className="pagination-wrapper">
                          {locationList.locations.length > 0 && (
                            <Pagination
                              total={locationList.totalItems}
                              page={pageDisplay}
                              listPerPage={listPageVal.value}
                              step={1}
                              onChangePage={handleChangePage}
                            />
                          )}
                        </div> */}
                    </div>
                    {/* table */}
                  </>

                  {/* <div className="no-launch-data">
                      <b>
                        No Entry Point Found
                      </b>

                      <p>
                        Before launching local entry points, you have to add and verify location.
                      </p>
                      
                      <ButtonWithLoadingOrIcon 
                        text="Go to Entry points Profile"
                        position="right"
                        className="orange-button main-button-48"
                        icon={{
                          src: FiArrowUpRight,
                          type: "svg"
                        }}
                        onClickAction={() => {
                          props.goToTab("entryPoints");
                        }}
                      />
                    </div> */}
                  {/* } */}
                </>
              )
            }
          </div>

          <div className="launch-google sectionWrap">
            <div className="launch-google-top">
              <b>Non-local</b>
            </div>

            <PopupConfirmation
              isOpen={showModalLaunchNonLocal}
              toggle={() => toggleModalNonLocal("launched")}
              title={`Launch ${selectedLaunchNonLocalList.length} Location${selectedLaunchNonLocalList.length > 1 ? "s" : ""}`}
              description={`Are you sure you want to launch ${selectedLaunchNonLocalList.length} location${selectedLaunchNonLocalList.length > 1 ? "s" : ""}?`}
              className="modal-launch"
            >
              {!loadingLaunch ?
                <ButtonWithLoadingOrIcon
                  text="Launch"
                  className="main-button-48 orange-button"
                  onClickAction={() => submitLaunchNonLocal()}
                  icon={{
                    src: LaunchWhite,
                    type: "img"
                  }}
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon
                  text="Launch"
                  className="main-button-48 orange-button"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  position="left"
                />
              }
            </PopupConfirmation>

            <PopupConfirmation
              isOpen={showModaUnlaunchNonLocal}
              toggle={() => toggleModalNonLocal("unlaunched")}
              title={`Unlaunch ${selectedUnlaunchNonLocalList.length} Location${selectedUnlaunchNonLocalList.length > 1 ? "s" : ""}`}
              description={`Are you sure you want to unlaunch ${selectedUnlaunchNonLocalList.length} location${selectedUnlaunchNonLocalList.length > 1 ? "s" : ""}?`}
              className="modal-launch"
            >
              {!loadingUnlaunch ?
                <ButtonWithLoadingOrIcon
                  text="Unlaunch"
                  className="main-button-48 red-button"
                  onClickAction={() => submitUnlaunchNonLocal()}
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon
                  text="Unlaunch"
                  className="main-button-48 red-button"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  position="left"
                />
              }
            </PopupConfirmation>

            <PopupConfirmation
              isOpen={showModalLaunch}
              toggle={() => toggleModal("launched")}
              title={`Launch ${selectedLaunchList.length} Location${selectedLaunchList.length > 1 ? "s" : ""}`}
              description={`Are you sure you want to launch ${selectedLaunchList.length} location${selectedLaunchList.length > 1 ? "s" : ""}?`}
              className="modal-launch"
            >
              {!loadingLaunch ?
                <ButtonWithLoadingOrIcon
                  text="Launch"
                  className="main-button-48 orange-button"
                  onClickAction={() => submitLaunch()}
                  icon={{
                    src: LaunchWhite,
                    type: "img"
                  }}
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon
                  text="Launch"
                  className="main-button-48 orange-button"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  position="left"
                />
              }
            </PopupConfirmation>

            {isLoadingGetLocationList || retrieveDataErrorNonLocal.code ?
              <div className="message-template-google-loading-wrapper">
                <RetrieveDataLoading
                  isLoading={isLoadingGetLocationNonLocalList}
                  errorMessage={retrieveDataErrorNonLocal.message}
                  hideRefresh
                />
              </div>
              :
              <>
                <div className="launch-google-middle">
                  {locationListNonLocalOrigin.length > 0 &&
                    <CustomSearchBox
                      placeholder={"Search by region"}
                      onChangeSearch={onChangeSearchNonLocal}
                      dataToSearch={locationListNonLocalOrigin}
                      style={{ width: '343px' }}
                      onClearSearch={onClearSearchNonLocal}
                      search={searchNonLocalVal}
                    />
                  }

                  {locationListNonLocal.length > 0 &&
                    <>
                      <ButtonWithLoadingOrIcon
                        className="red-button launch-google-top-button main-button-40 unlaunch-button"
                        text="Unlaunch"
                        onClickAction={() => toggleModalNonLocal("unlaunched")}
                        isDisabled={selectedUnlaunchNonLocalList.length === 0}
                      />

                      <ButtonWithLoadingOrIcon
                        icon={{
                          type: "img",
                          src: selectedLaunchNonLocalList.length === 0 ? LaunchGrey : LaunchWhite,
                        }}
                        className="orange-button launch-google-top-button main-button-40"
                        text="Launch"
                        position="left"
                        onClickAction={() => toggleModalNonLocal("launched")}
                        isDisabled={selectedLaunchNonLocalList.length === 0}
                      />
                    </>
                  }
                </div>

                {locationListNonLocal.length > 0 ?
                  <>
                    {/* table */}
                    <div className="launch-table-wrapper">
                      <div className="custom-table">
                        <table className="custom-table-content table-launch-list">
                          <thead>
                            <tr>
                              <td>
                                <div className={(selectedUnlaunchNonLocalList.length + selectedLaunchNonLocalList.length) === locationListNonLocal.length ? "custom-checkbox" : "custom-checkbox-stripe"}>
                                  <input
                                    type="checkbox"
                                    id="check-all-region-non-local"
                                    onChange={(e) => { }}
                                    checked={(selectedUnlaunchNonLocalList > 0 || selectedLaunchNonLocalList.length > 0)}
                                  />
                                  <label
                                    htmlFor="check-all-region-non-local"
                                    onClick={(e) => {
                                      if (!loadingLaunch && !loadingUnlaunch) {
                                        let _selectedUnlaunchList = selectedUnlaunchNonLocalList.slice();
                                        let _selectedLaunchList = selectedLaunchNonLocalList.slice();
                                        // let _del = deleteList.slice();
                                        let arLaunch = [];
                                        let arUnlaunch = [];

                                        if ((_selectedUnlaunchList.length + _selectedLaunchList.length) !== locationListNonLocal.length) {
                                          locationListNonLocal.map((v) => {
                                            if (v.launchStatusText === "Launched") {
                                              arUnlaunch.push(v.code);
                                            }

                                            if (v.launchStatusText === "Unlaunched") {
                                              arLaunch.push(v.code);
                                            }

                                            return null;
                                          })
                                        }

                                        setSelectedLaunchNonLocalList(arLaunch);
                                        setSelectedUnlaunchNonLocalList(arUnlaunch);
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="col_td_left">Region</td>
                              <td className="col_td_center">Code</td>
                              <td className="col_td_center">Status</td>
                              {/* <td>
                                <CustomSelectOption
                                  optionListProps={listPageOption}
                                  valueDropdownProps={listPageVal}
                                  onClickDropDownListOptionProps={onSelectListPage}
                                  _className="dropdown-item-page"
                                />
                              </td> */}
                            </tr>
                          </thead>

                          <tbody>
                            {locationListNonLocal.map((item, idx) => {
                              return (
                                <tr key={`cost-${idx}`}>
                                  <td>
                                    <div className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        id={`region-non-local-${idx}`}
                                        onChange={(e) => { }}
                                        checked={
                                          item.launchStatusText === "Launched" ?
                                            (selectedUnlaunchNonLocalList.findIndex(v => v === item.code) !== -1 ? true : false)
                                            :
                                            (selectedLaunchNonLocalList.findIndex(v => v === item.code) !== -1 ? true : false)
                                        }
                                      />
                                      <label
                                        htmlFor={`region-non-local-${idx}`}
                                        onClick={(e) => {
                                          if (!loadingLaunch && !loadingUnlaunch) {
                                            let _selectedUnlaunchList = selectedUnlaunchNonLocalList.slice();
                                            let _selectedLaunchList = selectedLaunchNonLocalList.slice();

                                            let idxUnlaunch = _selectedUnlaunchList.findIndex(v => v === item.code);
                                            let idxLaunch = _selectedLaunchList.findIndex(v => v === item.code);

                                            if (item.launchStatusText === "Launched") {
                                              if (idxUnlaunch === -1) {
                                                _selectedUnlaunchList.push(item.code);
                                              } else {
                                                _selectedUnlaunchList.splice(idxUnlaunch, 1);
                                              }
                                            }

                                            if (item.launchStatusText === "Unlaunched") {
                                              if (idxLaunch === -1) {
                                                _selectedLaunchList.push(item.code);
                                              } else {
                                                _selectedLaunchList.splice(idxLaunch, 1);
                                              }
                                            }

                                            setSelectedLaunchNonLocalList(_selectedLaunchList);
                                            setSelectedUnlaunchNonLocalList(_selectedUnlaunchList);
                                          }
                                        }}
                                      />
                                    </div>
                                  </td>

                                  <td className="">
                                    {item.name}
                                  </td>
                                  <td className="">
                                    {item.code}
                                  </td>
                                  <td className="">
                                    <p>{item.launchStatusText}</p>
                                  </td>
                                  {/* <td className=""></td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      {/* <div className="pagination-wrapper">
                        {locationList.locations.length > 0 && (
                          <Pagination
                            total={locationList.totalItems}
                            page={pageDisplay}
                            listPerPage={listPageVal.value}
                            step={1}
                            onChangePage={handleChangePage}
                          />
                        )}
                      </div> */}
                    </div>
                    {/* table */}
                  </>
                  :
                  <div className="no-launch-data">
                    <b>
                      No Region Found
                    </b>
                  </div>
                }
              </>
            }
          </div>
        </>
      )
    )
  );
};

export default Launch;
