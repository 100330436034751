import React, { useState, useEffect } from "react";
import "../../../auth/Auth.css";
import "../../QuickReplyAdd.scss";
import "../quickReplyAddInteractive/QuickReplyAddInteractive.scss";
import QuickReplyAddInteractiveHeader from "../quickReplyAddInteractive/QuickReplyAddInteractiveHeader/QuickReplyAddInteractiveHeader";
import FormInteractiveMessagePreviewQuickReply from "../../FormInteractiveMessagePreviewQuickReply/FormInteractiveMessagePreview";
import { FiInfo, FiPlus, FiTrash2, FiEye } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceQuickReply from "../../../../services/newServices/QuickReplyService";
import HelperQuickReply from "../../../../helper/HelperQuickReply";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const DEF_DATA = {
  headerType: "",
  headerText: "",
  document: false,
  bodyText: "",
  footerText: "",
  buttonText: [""].slice(),
  shortcut: ""
};

const DEF_ERROR = {
  headerText: "",
  headerType: "",
  document: "",
  bodyText: "",
  footerText: "",
  buttonText: [""].slice(),
  shortcut: ""
};

let QuickReplyGeneralEditButton = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [data, setData] = useState(JSON.parse(JSON.stringify(DEF_DATA)));
  let [dataOrigin, setDataOrigin] = useState(JSON.parse(JSON.stringify(DEF_DATA)));
  let [isWaitResponseAddQuickReply, setIsWaitResponseAddQuickReply] = useState(false);
  let [errorMessageData, setErrorMessageData] = useState(JSON.parse(JSON.stringify(DEF_ERROR)));
  let [showModalPreviewMessage, setShowModalPreviewMessage] = useState(false);

  let togglePreview = () => {
    setShowModalPreviewMessage(!showModalPreviewMessage);
  }

  let back = () => {
    history.push(`/o/${orgID}/setup/quick-reply`, {
      tab: "generalQuickReply"
    });
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // let { id, createdTime } = responsedata.memberships[0].organization;
          // let urlOrgID = `${id}-${createdTime}`;
          back();
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if(props.history.location.state) {
      let _state = JSON.parse(JSON.stringify(props.history.location.state));
      if(props.history.location.state.message) {
        let _whatsapp = JSON.parse(_state.data);

        let _buttons = [];
        let _buttonsErr = [];

        _whatsapp.buttonAction.buttons.map((v) => {
          _buttons.push(v.title);
          _buttonsErr.push("");
          return null;
        })


        let _editQuickReplyData = {
          mediaFilename: _state.mediaFilename,
          headerType: _whatsapp.header ? ((_whatsapp.header.type === "image" || _whatsapp.header.type === "video") ? "media" : _whatsapp.header.type)  : "no_header",
          headerText: _whatsapp.header ? (_whatsapp.header.type === "text" ? _whatsapp.header.text : "") : "",
          document: false,
          bodyText: _whatsapp.bodyText,
          footerText: _whatsapp.footerText ? _whatsapp.footerText : "",
          buttonText: _buttons,
          shortcut: _state.shortcut,
          isDefaultDoc: _whatsapp.header ? (_whatsapp.header.type === "text" ? false : true) : false
        };

        if(_whatsapp.header) {
          if(_whatsapp.header.type === "document") {
            _editQuickReplyData.document = {
              name: _whatsapp.header.document.filename,
              link: _whatsapp.header.document.link
            }
          }

          if(_whatsapp.header.type === "image") {
            _editQuickReplyData.document = {
              type: "image/media",
              link: _whatsapp.header.image.link
            }
          }

          if(_whatsapp.header.type === "video") {
            _editQuickReplyData.document = {
              type: "video/media",
              link: _whatsapp.header.video.link
            }
          }
        }

        setData(_editQuickReplyData);
        setDataOrigin(_editQuickReplyData);

        setErrorMessageData({
          headerText: "",
          headerType: "",
          document: "",
          bodyText: "",
          footerText: "",
          buttonText: _buttonsErr,
          shortcut: ""
        })
      }
    }else {
      back();
    }
  }, [props.history])

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  let onSubmitEditQuickReply = () => {
    let errorCount = 0;
    let scrollTo = "";
    let _errorMessageData = {...errorMessageData}

    if(data.headerType === "") {
      _errorMessageData.headerType = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
    }

    if(data.headerType === "text") {
      if(data.headerText === "") {
        _errorMessageData.headerText = "This field is required";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
      }

      if(data.headerText.length > 20) {
        _errorMessageData.headerText = "Characters exceed limit";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
      }
    }

    if(data.headerType === "media" || data.headerType === "document") {
      if(data.document === false) {
        _errorMessageData.document = "This field is required";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
      }
    }

    if(data.bodyText.length > 1024) {
      _errorMessageData.bodyText = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-bodyText"; }
    }

    if(data.bodyText === "") {
      _errorMessageData.bodyText = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-bodyText"; }
    }

    if(data.footerText.length > 60) {
      _errorMessageData.footerText = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-footerText"; }
    }

    data.buttonText.map((v, i) => {
      if(v.length > 20) {
        _errorMessageData.buttonText[i] = "Characters exceed limit";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-button-text-${i}`; }
      }

      if(v === "") {
        _errorMessageData.buttonText[i] = "This field is required";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-button-text-${i}`; }
      }

      if(_errorMessageData.buttonText[i] !== "") {
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-button-text-${i}`; }
      }

      return null;
    });

    _errorMessageData.buttonText.map((v, i) => {
      if(v !== "") {
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-button-text-${i}`; }
      }

      return null;
    })

    if(data.shortcut === "") {
      _errorMessageData.shortcut = "This field is required";
      errorCount = errorCount + 1;
      scrollTo = `input-shortcut`;
    }

    if(data.shortcut.length > 50) {
      _errorMessageData.shortcut = "Characters exceed limit";
      errorCount = errorCount + 1;
      scrollTo = `input-shortcut`;
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${scrollTo}`);
    }else {
      setIsWaitResponseAddQuickReply(true);
      
      let _data = {
        ...data,
        id: parseInt(props.match.params.quickReplyID),
        ownerType: HelperQuickReply.ownerTypeGeneral,
        type: "button",
        shortcut: data.shortcut
      }

      _data.buttonAction = {
        buttons: []
      }

      _data.buttonText.map((v) => {
        _data.buttonAction.buttons.push({
          id: "",
          title: v
        });

        return null;
      })
      
      let runEdit = () => {
        ServiceQuickReply.quickReplyInteractiveEdit(orgID, _data, (response) => {
          let dataResult = response.dataResult;
          let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
          
          if(dataResult.error.message === "") {
            if(dataResult.data.success) {
              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Edit Quick Reply",
                  {
                    type : "General - Interactive",
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : orgID.split("-")[0]
                  }
                )
              }
              
              setDataOrigin(data);

              setTimeout(() => {
                history.push({
                  pathname: `/o/${orgID}/setup/quick-reply`,
                  state: {
                    message: response.dataResult.data.message,
                    success: response.dataResult.data.success,
                    tab: "generalQuickReply"
                  }
                })
              }, 3000)
            }else {
              setIsWaitResponseAddQuickReply(false);
              doToast(dataResult.data.message, "fail");  
            }
          }else {
            setIsWaitResponseAddQuickReply(false);
            doToast(dataResult.error.message, "fail");
          }
        });
      }

      if(_data.headerType === "text" || _data.headerType === "no_header") {
        if(data.headerType === "text") {
          _data.header = {
            type: "text",
            text: _data.headerText
          }
        }

        runEdit();
      }else {
        if(_data.isDefaultDoc) {
          if(_data.headerType === "document") {
            _data.header = {
              type: "document",
              document: {
                mediaFileID: 0
              }
            }
          }else {
            _data.header = {
              type: _data.document.type.split("/")[0],
              [_data.document.type.split("/")[0]]: {
                mediaFileID: 0
              }
            }
          }

          runEdit();
        }else {
          var formData = new FormData();
          formData.append("fileType", _data.headerType === "document" ? "file" : _data.document.type.split("/")[0]);
          formData.append("file", data.document);
  
          ServiceQuickReply.uploadMedia(orgID, formData, (response) => {
            let dataResult = response.dataResult;
  
            if(dataResult.error.message === "") {
              if(_data.headerType === "document") {
                _data.header = {
                  type: "document",
                  document: {
                    mediaFileID: dataResult.data.id
                  }
                }
              }else {
                _data.header = {
                  type: _data.document.type.split("/")[0],
                  [_data.document.type.split("/")[0]]: {
                    mediaFileID: dataResult.data.id
                  }
                }
              }
  
              runEdit();
            }else {
              doToast(dataResult.error.message, "fail");
            }
          });
        }
      }
    }
  }

  let onChangeText = (id, val) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    
    if(id === "shortcut") {
      _data[id] = val.replaceAll(" ", "");
    }else {
      _data[id] = val;
    }

    _errorMessageData[id] = "";

    if(id === "headerText" && val.length > 20) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "bodyText" && val.length > 1024) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "footerText" && val.length > 60) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "shortcut" && val.length > 50) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let onChangeDocument = (file = false) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.document = "";
    _data.isDefaultDoc = false;
    _data.document = file === false ? file : file[0];
    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let check_duplicate_in_array = (input_array)=>{
    let duplicates = input_array.filter((item, index) =>input_array.indexOf(item) !== index);
    return Array.from(new Set(duplicates));
  }

  let onChangeButtonText = (val, idx) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.buttonText[idx] = "";
    _data.buttonText[idx] = val;
    let dupeArr = [];
  
    let duplicateVal = check_duplicate_in_array(_data.buttonText);

    if(duplicateVal[0] !== "") {
      _data.buttonText.filter((v, i) => {
        if(v === duplicateVal[0]) {
          dupeArr.push(i);
        }

        return null;
      })
    }

    _data.buttonText.map((v, i) => {
      if(_errorMessageData.buttonText[i] !== "This field is required" && _errorMessageData.buttonText[i] !== "Characters exceed limit") {
        if(v.length > 20) {
          _errorMessageData.buttonText[i] = "Characters exceed limit";
        }else {
          _errorMessageData.buttonText[i] = "";
        }
      }

      return null;
    })
    
    if(dupeArr.length > 0) {
      dupeArr.map((v) => {
        _errorMessageData.buttonText[v] = "You can't enter the same text for multiple buttons";

        return null;
      })
    }

    if(val.length > 20) {
      _errorMessageData.buttonText[idx] = "Characters exceed limit";  
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let addButtonText = () => {
    if(!isWaitResponseAddQuickReply) {
      let _data = {...data};
      let _errorMessageData = {...errorMessageData};
  
      if(_data.buttonText.length < 3) {
        _data.buttonText.push("");
        _errorMessageData.buttonText.push("");
      }
  
      setData(_data);
      setErrorMessageData(_errorMessageData);
    }
  }

  let removeButtonText = (idx) => {
    if(!isWaitResponseAddQuickReply) {
      let _data = {...data};
      let _errorMessageData = {...errorMessageData};
      _data.buttonText.splice(idx, 1);
      _errorMessageData.buttonText.splice(idx, 1);

      let dupeArr = [];
    
      let duplicateVal = check_duplicate_in_array(_data.buttonText);

      if(duplicateVal[0] !== "") {
        _data.buttonText.filter((v, i) => {
          if(v === duplicateVal[0]) {
            dupeArr.push(i);
          }

          return null;
        })
      }

      _errorMessageData.buttonText.map((v, i) => {
        let _findIdx = dupeArr.findIndex(_v => _v === i);
        if(v === "You can't enter the same text for multiple buttons" && _findIdx === -1) {
          if(_data.buttonText[i].length > 20) {
            _errorMessageData.buttonText[i] = "Characters exceed limit";
          }else {
            _errorMessageData.buttonText[i] = "";
          }
        }

        return null;
      })
  
      setData(_data);
      setErrorMessageData(_errorMessageData);
    }
  }

  let onChangeHeaderType = (type) => {
    if(!isWaitResponseAddQuickReply) {
      let _data = {...data};
      let _errorMessageData = {...errorMessageData};
      _data.headerType = type;
      _data.headerText = "";
      _data.document = false;
      _errorMessageData.headerType = "";
      _errorMessageData.headerText = "";
      _errorMessageData.document = ""
  
      setData(_data);
      setErrorMessageData(_errorMessageData);
    }
  }

  let runErrorDoc = (text) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.document = text;
    setErrorMessageData(_errorMessageData);
  }
  
  return (
    <React.Fragment>
        <FormInteractiveMessagePreviewQuickReply
          isShowModalFormInteractivePreview={showModalPreviewMessage}
          toggle={togglePreview}
          data={data}
          type={"button"}
        />

        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={dataOrigin}
            dataAfterChange={data}
        >   
            <BackButton text="Back to General Quick Replies" onClick={back} />

            <div className="sectionWrap form-add-quick-reply form-add-quick-reply-interactive-button">
              <div className="form-title-wrapper">
                <b>Edit General Quick Reply</b>

                {isWaitResponseAddQuickReply ?
                  <ButtonWithLoadingOrIcon 
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    className="orange-button main-button-40"
                    text="Edit Quick Reply"
                    position="left"
                  />
                  :
                  <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-40"
                    text="Edit Quick Reply"
                    // isDisabled={isButtonSubmitDisabled()}
                    onClickAction={onSubmitEditQuickReply}
                  />    
                }
              </div>
                
              <div className="form-content-wrapper">
                  <label>
                    <b>Content Type</b>
                  </label>
                  <p className="type-val">
                    WABA Interactive Message
                  </p>

                  <label>
                    <b>Button Type</b>
                  </label>
                  <p className="type-val">
                    Reply Button
                  </p>

                  <div className="line-through" />

                  <div className="message-content">
                    <p>
                      <b>Message Content</b>
                    </p>

                    <ButtonWithLoadingOrIcon 
                      className="no-fill-button main-button-48"
                      icon={{
                        type: "svg",
                        src: FiEye
                      }}
                      position="left"
                      text="Preview Message"
                      onClickAction={togglePreview}
                    />
                  </div>

                  <label className="input-interactive-header-type">
                    <b>Header Type</b>
                  </label>
                  
                  <QuickReplyAddInteractiveHeader 
                    data={data}
                    errorMessageData={errorMessageData}
                    onChangeHeaderType={onChangeHeaderType}
                    isWaitResponseAddQuickReply={isWaitResponseAddQuickReply}
                    onChangeText={onChangeText}
                    onChangeDocument={onChangeDocument}
                    runErrorDoc={runErrorDoc}
                  />

                  {errorMessageData.headerType !== "" &&
                    <ErrorOrInfoComp 
                      text={errorMessageData.headerType}
                      icon={<FiInfo />}
                      _className="red-text"
                    />
                  }

                  <div className="input-outer-wrapper">
                    <label className="input-interactive-bodyText">
                      <b>Body</b>
                      <span className={`grey-font ${errorMessageData.bodyText === "Characters exceed limit" ? "red-text" : ""}`}>{data.bodyText.length}/1024 characters</span>
                    </label>
                    <textarea disabled={isWaitResponseAddQuickReply} value={data.bodyText} onChange={(e) => onChangeText("bodyText", e.target.value)} placeholder="Type message here" className={errorMessageData.bodyText !== "" ? "border-red" : ""} />
                    {errorMessageData.bodyText !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.bodyText}
                        icon={<FiInfo />}
                        _className="red-text no-margin"
                      />
                    }
                    <span className="input-info-wrapper">
                      <p className="no-margin">
                        This is the message that will appear after you select a specific shortcut.
                      </p> 
                      <p className="no-margin">
                        <b>No blank space are allowed at the beginning and end of a message.</b>
                      </p>
                    </span>

                    <label className="input-interactive-footerText">
                      <b>Footer </b>
                      <span className="text-optional grey-font">
                        (optional)
                      </span>
                      <span className={`grey-font  ${errorMessageData.footerText === "Characters exceed limit" ? "red-text" : ""}`}>{data.footerText.length}/60 characters</span>
                    </label>
                    <input disabled={isWaitResponseAddQuickReply} type="text" value={data.footerText} onChange={(e) => onChangeText("footerText", e.target.value)} placeholder="Type your footer here" className={errorMessageData.footerText !== "" ? "border-red" : ""} />
                    {errorMessageData.footerText !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.footerText}
                        icon={<FiInfo />}
                        _className="red-text"
                      />
                    }
                  </div>

                  {data.buttonText.map((v, i) => {
                    return (
                      <div className={`button-text-wrapper-form ${i === 0 ? "button-text-wrapper-form-1": ""}`} key={`button-text-${i}`}>
                        <label className={`input-interactive-button-text-${i}`}>
                          <b>Button Text</b>
                          <span className={`grey-font  ${errorMessageData.buttonText[i] === "Characters exceed limit" ? "red-text" : ""}`}>
                            {v.length}/20 characters

                            {data.buttonText.length !== 1 ?
                              <FiTrash2 onClick={() => removeButtonText(i)} className="remove-button-text" />
                              :
                              ""
                            }
                          </span>
                        </label>
                        <input disabled={isWaitResponseAddQuickReply} type="text" value={v} onChange={(e) => onChangeButtonText(e.target.value, i)} placeholder="Type your button text here" className={errorMessageData.buttonText[i] !== "" ? `border-red button-text-${i}` : `button-text-${i}`} />
                        {errorMessageData.buttonText[i] !== "" &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.buttonText[i]}
                            icon={<FiInfo />}
                            _className="red-text no-margin"
                          />
                        }
                      </div>
                    )
                  })}

                  {data.buttonText.length < 3 &&
                    <p className="add-another-button" onClick={addButtonText}>
                      <b><FiPlus /> Edit Another Button</b>
                    </p>
                  }

                  <div className="input-outer-wrapper">
                    <label className="pos-relative">
                      <b>Shortcut</b>
                      <span className={errorMessageData.shortcut === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{data.shortcut.length}/50 characters</span>
                    </label>
                    <div className="quick-reply-prefix disabled-input">
                      {HelperQuickReply.prefixShortcut}
                    </div>

                    <input disabled={isWaitResponseAddQuickReply} type="text" value={data.shortcut} placeholder="Type shortcut here" id="shortcut" className={`input-shortcut ${errorMessageData.shortcut !== "" ? "border-red" : ""}`} onChange={(e) => {
                        onChangeText("shortcut", e.target.value);
                    }}/>

                    {errorMessageData.shortcut !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.shortcut}
                        icon={<FiInfo />}
                        _className="red-text margin-top-0"
                      />
                    }

                    <span className="input-info-wrapper">
                        <p className="no-margin">
                          This is the message that will appear after you select a specific shortcut.
                        </p>
                        <p className="no-margin">
                          <b> Only alphanumberic and underscore are allowed. </b>
                        </p> 
                        <p className="no-margin">
                          Your shortcut will be displayed like this "/shortcut" 
                        </p>
                        <p className="no-margin">
                          (Tip: keep it short and simple).
                        </p>
                    </span>
                  </div>
              </div>
          </div>
        </SectionWrap>
    </React.Fragment>
  );
};

export default QuickReplyGeneralEditButton;