const INITIAL_STATE = null;

let reduxReducerActiveOrgID = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_ORG_ID':
        return action.activeOrgID;
      case 'CLEAR_ACTIVE_ORG_ID':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerActiveOrgID;