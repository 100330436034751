import React, { useState, useEffect } from 'react';
import './VerifyCardTransaction.scss';
import NewOrganizationHeader from "../../newOrganizationHeader/NewOrganizationHeader";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import Loading from "../../../reuseableComponent/loading/Loading";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import BillingServices from "../../../../services/newServices/BillingServices";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import { doToast } from "../../../../helper/HelperGeneral";
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { setSelectedTier, clearSelectedTier } from "../../../../redux/actions/reduxActionSelectedTier";

const REDIRECT_TO = {
  home: "home",
  subscription: "subscription",
  invoice: "invoice"
}

const PAY_FOR = {
  month: "month",
  year: "year"
};

const VerifyCard = (props) => {
  const [isWaitAddCard, setIsWaitAddCard] = useState(false);
  const [isShowLoadingVerifyCard, setIsShowLoadingVerifyCard] = useState(false);
  const [cardVerificationData, setCardVerificationData] = useState(false);

  let backWithMessage = () => {
    props.history.push({
      pathname: `/o/${props.match.params.orgID}/select-tier/pay-methods/add-card`,
      state: {
        failed: true
      }
    })
  }
  
  const back = () => {
    props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction`)
  }

  let setTier = () => {
    let redirectTo = (to, invoiceID) => {
        if(to === REDIRECT_TO.home) {
          props.history.push({
            pathname: `/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction/success`,
            state: {
              redirectTo: `/o/${props.match.params.orgID}/home`
            }
          })
        }else if(to === REDIRECT_TO.subscription) {
          props.history.push({
            pathname: `/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction/success`,
            state: {
              redirectTo: `o/${props.match.params.orgID}/organization/billing/unpaid-invoices`
            }
          })
        }else {
          props.history.push({
            pathname: `/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction/success`,
            state: {
              redirectTo: `/o/${props.match.params.orgID}/organization/billing/unpaid-invoices-detail/${invoiceID}`
            }
          })
        }
    }

    let payUnit = props.selectedTier.payUnit === "year" ? PAY_FOR.year : PAY_FOR.month;
    let indexSku = props.selectedTier.skuItems.findIndex(value => value.validityType === payUnit);

    let _selectedTier = {
        currentTierID: props.selectedTier.currentTier,
        tierID: props.selectedTier.id,
        tierCode: props.selectedTier.code,
        skuID: props.selectedTier.skuItems[indexSku].id,
        skuString: props.selectedTier.skuItems[indexSku].sku,
        name: props.selectedTier.name
    };

    ServiceOrganization.postSetTierList(props.match.params.orgID, _selectedTier, (response) => {
        let dataResult = response.dataResult;

        if(dataResult.status === 200) {
            if(dataResult.data.success) {
                // props.clearSelectedTier();
                redirectTo(dataResult.data.redirectTo, dataResult.data.invoiceID)
            }else {
              doToast(dataResult.data.message, "fail");
            }
        }else {
          doToast(dataResult.error.message, 'fail');
        }
    })
  }

  let submitPaymentSetting = (cardID) => {
    let data = {
        isAutomaticPayment: true,
        primaryCardID: cardID 
    }

    BillingServices.setPaymentSetting(props.match.params.orgID, data, (response) => {
        let dataResult = response.dataResult;

        if(dataResult.error.message === "") {
            console.log(dataResult.data.message);
            setTier();
        }else {
            // doToast(dataResult.error.message, "fail");
            backWithMessage();
        }
    })
  }

  //xendit token
  let getBillingConfig = () => {
    setIsShowLoadingVerifyCard(true);

    BillingServices.getBillingConfig(props.match.params.orgID, {
        onFailCredential: (errorMessage, erroCode) => {
            doToast(errorMessage, "fail")
        },
        onSuccessCredential: (response) => {
          createXenditToken(response.data);
        }
    })
  }

  let createXenditToken = (billingConfig) => {
    let _cardFormData = {...props.selectedTier.cardDetail};

    window.Xendit.setPublishableKey(billingConfig.paymentGateways.credentials.xendit.clientKey);

    let data ={
        card_number: _cardFormData.cardNumber.replace(/\s/g, ''),
        card_exp_month: _cardFormData.cardExpiry.split("/")[0],
        card_exp_year: _cardFormData.cardExpiry.split("/")[1],
        card_cvn: _cardFormData.cardCvvCvc,
        is_multiple_use: true
    }

    let xenditTokenResponseHandler = (err, tokenResponse) => {   
        if(err !== null) {
            doToast(err.message, "fail");
            backWithMessage();
        }else {
            createXenditAuthorization(tokenResponse);
        }
    }
    
    window.Xendit.card.createToken(data, xenditTokenResponseHandler);
  }
  //xendit token

  //add card
  let addCard = (authenticationResponse, _tokenData) => {
    setIsWaitAddCard(true);
    
    let _formData = props.selectedTier.cardDetail;
    let _authenticationResponse = authenticationResponse;

    let data = {
      tokenID: _tokenData.id,
      authenticationID: _authenticationResponse.id,
      cardExpiryMonth: _formData.cardExpiry.split("/")[0],
      cardExpiryYear: "20"+_formData.cardExpiry.split("/")[1],
      nameOnCard: _formData.cardName
    };

    // let pushTo = (path, data) => {
    //   history.push({
    //     pathname: path,
    //     state: data
    //   })
    // }

    BillingServices.addCard(props.match.params.orgID, data, {
      onFailCredential: (errorMessage, erroCode) => {
        console.log(errorMessage);
        backWithMessage();
      },
      onSuccessCredential: (response) => {
          setIsWaitAddCard(false);

          if(response.error.message === "") {
            if(response.data.success) {
              submitPaymentSetting(response.data.cardID);
              // setTier();
            }else {
              backWithMessage(response.data.message);
            }
          }else {
            backWithMessage();
          }
      }
    })
  }

  let createXenditAuthorization = (tokenData) => {
    let xenditAuthenticationResponseHandler = (err, authenticationResponse) => {
        if(err !== null) {
            console.log(err.message);
            backWithMessage();
        }

        if(authenticationResponse) {
          if(authenticationResponse.status === "IN_REVIEW") {
            setCardVerificationData(authenticationResponse);
          }else if(authenticationResponse.status === "VERIFIED") {
            addCard(authenticationResponse, tokenData);
          }else if(authenticationResponse.status === "FAILED") {
            console.log("3DS verification was unsuccessful, please try again");
            backWithMessage();
          }
        }
    } 

    window.Xendit.card.createAuthentication({
        token_id: tokenData.id,
        amount: 0
    }, xenditAuthenticationResponseHandler);
  }
  //add card

  // useEffect(() => {
  //   console.log(cardVerificationData)
  // }, [cardVerificationData])

  useEffect(() => {
    if(!props.selectedTier) {
      props.history.push(`/o/${props.match.params.orgID}/home`);
    }else {
      if(!props.selectedTier.paymentSetting) {
        props.history.push(`/o/${props.match.params.orgID}/home`);
      }

      if(!props.selectedTier.paymentSetting.isAutomaticPayment) {
        props.history.push(`/o/${props.match.params.orgID}/home`);
      }

      if(props.selectedTier.cardDetail) {
        getBillingConfig();
      }
    }
  }, [props.selectedTier])

  useEffect(() => {
    if(cardVerificationData) {
      setIsShowLoadingVerifyCard(false);
    }
  }, [cardVerificationData])

  return (
    <>
      <NewOrganizationHeader
        titleText="Start Subscription"
        hideLogout
      // topRightContent={renderStep}
      />

      <ToastContainer className="ToastContainer" />

      <div className="verify-card-main-wrapper">
        <SectionWrap
            {...props}
            orgID={props.match.params.orgID}
            _className="section-width-100 pos-relative"
            barVerifyClass="margin-top-0"
        >
          <div className="verify-card-content">
            <div className="verify-card-header">
              <BackButton text="Back to Review Checkout" onClick={back} />
            </div>

            <div className="verify-card-container">
              <h1>Verify Card</h1>
              
              <div className="verify-card-iframe" >
                {isShowLoadingVerifyCard ?
                    <Loading />
                    :
                    <div className="iframe-card-wrapper">
                      {isWaitAddCard ?
                        <div className="loading-add-card-wrapper">
                          <Loading />
                          <b>Adding Card</b>
                        </div>
                        :
                        <iframe src={cardVerificationData.payer_authentication_url} title="iframe xendit" />
                      }
                    </div>
                }
              </div>
            </div>
          </div>
        </SectionWrap>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  setSelectedTier,
  clearSelectedTier
};

const mapStateToProps = state => ({
  selectedTier: state.selectedTier
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCard);