import React from "react";
import "./IntegrationAutoMessageDetail.scss";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
// import statusDisconnect from '../../../../assets/img/integration/icon-disconnect.svg';
import iconXgrey from '../../../../assets/img/integration/icon-x-grey.svg';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";

import { FiEdit } from "react-icons/fi";
import HelperChat from "../../../../helper/HelperChat";

let IntegrationAutoMessageDetail = (props) => {
    let { withMarginTop, onClickEdit, isLoading, type, data, error } = props;

    return (
        <>
            <div className={`integration-auto-message-detail ${withMarginTop ? "integration-auto-message-detail-margin-top" : ""}`}>
                <>
                    <div className="title-wrapper">
                        {type === "welcome" &&
                            <div className="title-description">
                                <p className="title-customize">
                                    <b>Welcome Message</b>
                                </p>
                                <p className="description-customize">
                                    Welcome your visitors with a custom type message once they open up the messenger on your website.
                                </p>
                            </div>
                        }

                        {type === "away" &&
                            <div className="title-description">
                                <p className="title-customize">
                                    <b>Away Message</b>
                                </p>
                                <p className="description-customize">
                                    Notify your visitors with a custom type message when you are away.
                                </p>
                            </div>
                        }

                        {type === "closing" &&
                            <div className="title-description">
                                <p className="title-customize">
                                    <b>Closing Message</b>
                                </p>
                                <p className="description-customize">
                                    Leave a custom type message to your visitors once you have resolved their case. 
                                </p>
                            </div>
                        }

                        {type === "rating" &&
                            <div className="title-description">
                                <p className="title-customize">
                                    <b>User Rating</b>
                                </p>
                                <p className="description-customize">
                                    Send a rating form to your customers once you have resolved their case. 
                                </p>
                            </div>
                        }

                        {type === "autoResolve" &&
                            <div className="title-description">
                                <p className="title-customize">
                                    <b>Automatic Resolve Case</b>
                                </p>
                                <p className="description-customize">
                                    Chats will be automatically resolved once customer does not reply back within a specific time range.
                                </p>
                            </div>
                        }

                        {type === "autoUnassign" &&
                            <div className="title-description">
                                <p className="title-customize">
                                    <b>Automatic Unassign Agent</b>
                                </p>
                                <p className="description-customize">
                                    Agents will be automatically unassigned from their cases if agent does not reply in a specific range of time.
                                </p>
                            </div>
                        }

                        {(!isLoading && !error) &&
                            <ButtonWithLoadingOrIcon 
                                text="Edit"
                                position="left"
                                icon={{
                                    type: "svg",
                                    src: FiEdit
                                }}
                                onClickAction={() => onClickEdit(type)}
                                className="main-button-40 no-fill-button minWidth180"
                            />
                        }
                    </div>
                
                    {isLoading ?
                        <div className="loading-wrapper">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                        </div>
                        :
                        (error ?
                            <RetrieveDataLoading 
                                errorCode={error ? error.errorCode : false}
                                errorMessage={error ? error.errorMessage : false}
                                isLoading={isLoading}
                            />
                            :

                            <div className="detail-wrapper">
                                <label>
                                    <b>Status</b>
                                </label>
                                
                                <p className={`status-value status-${!data.isEnabled ? "disabled" : "enabled"}`}>
                                    {!data.isEnabled?
                                        <>
                                            <img src={iconXgrey} alt="" />
                                            Disabled {data.useGlobalConfig ? " (Global)" : " (Custom)"}
                                        </>
                                        :
                                        <>
                                            <img src={statusConnected} alt="" />
                                            Enabled {data.useGlobalConfig ? " (Global)" : " (Custom)"}
                                        </>
                                    }
                                </p>
                                
                                {((data.isEnabled || data.useGlobalConfig) && !(!data.isEnabled && data.useGlobalConfig)) &&
                                    (type !== "autoUnassign" && type !== "autoResolve" ?
                                        <>
                                            <label>
                                                <b>Message</b>
                                            </label>
                                            
                                            <p dangerouslySetInnerHTML={{__html: HelperChat.lineBreak((type === "rating") ? data.message : data.text)}} />
    
                                            {type === "rating" &&
                                                <>
                                                    <label>
                                                        <b>Theme Color</b>
                                                    </label>
                                                    
                                                    <p className="theme-color-text">
                                                    <div className="theme-color" style={{backgroundColor: `${data.themeColor}`}} />
                                                    {data.themeColor}
                                                    </p>
                                                </>
                                            }
                                        </>
                                        :
                                        <>  
                                            {type === "autoResolve" ?
                                                <p className="no-title">
                                                    A case will be automatically resolved after <b>{data.afterValue} {data.afterType}</b> from agent’s last reply.
                                                </p>
                                                :
                                                <p className="no-title">
                                                    Agent will be automatically unassigned after <b>{data.afterValue} {data.afterType}</b> from customer’s last reply.
                                                </p>
                                            }
                                        </>
                                    )
                                }
                            </div>
                        )
                    }
                </>
            </div>
        </>
    )
}

export default IntegrationAutoMessageDetail;