import React from 'react';
import './ModalStartConversation.scss';
import { connect } from 'react-redux';
// import { Scrollbars } from 'react-custom-scrollbars';
import { FiX } from 'react-icons/fi';
import { clearStartConversation, setStartConversation } from '../../../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../../../redux/actions/reduxActionStartConversationBa';
import { setActiveCasePanel } from '../../../../../redux/actions/reduxActionActiveCasePanel';
import { setToggleMineCase } from "../../../../../redux/actions/reduxActionToggleMineCase";
import { setShowOtherCase } from "../../../../../redux/actions/reduxActionShowOtherCase";
import { setModalStartConversationBalance, clearModalStartConversationBalance } from "../../../../../redux/actions/reduxActionModalStartConversationBalance";
import ModalStartConversation from './ModalStartConversation';
import ModalStartConversationWABA from './ModalStartConversationWABA';

// var style = {
//     scrollStyle: {
//         position: "relative",
//         backgroundColor: "#ff7d00",
//         right: "-2px",
//         width: "5px",
//         borderRadius: "8px"
//     }
// };

let ModalStartConversationNew = (props) => {
    let onCloseClick = () => {
        props.toggleModalStartConversationProps(props.medium, true);
    };

    return (
        <div className={`modal-new-conversation ${props.isShowModalStartConversationProps ? 'active-modal-new-conversation' : ''} `}>
            <div className={`modal-body ${props.isShowModalStartConversationProps ? 'action-modal-body-new-conversation' : ''}`}>
                <div onClick={onCloseClick} className="close-modal-start-conversation">
                    <FiX />
                </div>

                <>
                    {props.medium === "sme" &&
                        <ModalStartConversation
                            ModalStartConversation
                            {...props}
                        />
                    }


                    {props.medium === "waba" &&
                        <ModalStartConversationWABA
                            {...props}
                        />
                    }
                </>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    countryList: state.countryList,
    startConversation: state.startConversation,
    toggleMineCase: state.toggleMineCase,
    activeRoom: state.activeRoom,
    priceList: state.priceList,
    modalStartConversationBalance: state.modalStartConversationBalance,
    showOtherCase: state.showOtherCase,
});

const mapDispatchToProps = {
    setActiveCasePanel,
    setStartConversation,
    clearStartConversation,
    clearStartConversationBa,
    setToggleMineCase,
    setModalStartConversationBalance,
    clearModalStartConversationBalance,
    setShowOtherCase
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStartConversationNew);