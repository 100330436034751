import React, { useState, useEffect } from 'react';
import './IntegrationGoogle.scss';
import { Link } from 'react-router-dom';
import { FiArrowUpRight, FiPlus } from 'react-icons/fi';
import GoogleIcon from '../../../assets/img/integration/logo-google.svg';
// import IconAdd from '../../../assets/img/icon-add.svg';
// import Integrations from "../Integrations";
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import { checkID } from '../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import IntegrationGoogleServices from '../../../services/integration/IntegrationGoogleServices';
import { clearCreateGoogle } from '../../../redux/actions/reduxActionCreateGoogle';
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

let IntegrationGoogle = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = React.useState(false);
  let [isLoadingGoogleAccount, setIsLoadingGoogleAccount] = useState(true);
  let [googleAccountData, setGoogleAccountData] = useState([]);
  let [showPopupWarning, setShowPopupWarning] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
      code: false,
      message: false
  });

  let toggleShowWarning = () => {
    setShowPopupWarning(!showPopupWarning);
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getGoogleAccount(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getGoogleAccount = (orgID) => {
    let _retriveDataError = {...retriveDataError};

    setIsLoadingGoogleAccount(true);

    IntegrationGoogleServices.getList(orgID, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.status === 200) {
        // console.log('successs');
        // console.log(response.dataResult.data.channels);
        setGoogleAccountData(response.dataResult.data.channels);
      } else {
        setGoogleAccountData([]);
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError); 
      }

      setIsLoadingGoogleAccount(false);
    });
  };

  useEffect(() => {
    props.clearCreateGoogle();
    doMount();
  }, []);
  
  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(orgID) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Google Integration",
          {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : orgID.split("-")[0]
          }
        )
      }
    }
  }, [orgID])

  // let goToAdd = () => {
  //   let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
  //   if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
  //     mixpanel.track(
  //       "[Button] Add channel",
  //       {
  //         channel: "Google",
  //         userFullName : myAgentData.account.fullName,
  //         userID : myAgentData.account.id,
  //         organizationID : orgID.split("-")[0]
  //       }
  //     );
  //   }
    
  //   history.push(`/o/${orgID}/integration/google-business-messages/add`);            
  // }

  let goToAdd = () => {
    // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    // mixpanel.track(
    //     "[Button] Add Channel",
    //     {
    //         channel: "Google Business Messages",
    //         userFullName : myAgentData.account.fullName,
    //         userID : myAgentData.account.id,
    //         organizationID : orgID.split("-")[0]
    //     }
    // );

    if(!props.mediumData) {
        toggleShowWarning();
    }else {
        if(props.mediumData.numberOfItems.maximum !== -1 && props.mediumData.numberOfItems.current >= props.mediumData.numberOfItems.maximum) {
            toggleShowWarning();
        }else {
            history.push(`/o/${orgID}/integration/google-business-messages/add`);
        }
    }
  };

  return (
    <div className="integration-outer-wrapper">
      <PopupWarning 
          isShow={showPopupWarning}
          toggle={toggleShowWarning}
          text="Maximum number of allowed channels has been reached"
      />
      
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {/* tier blocking */}
        {retriveDataError.code  === "49901" &&
          <RetrieveDataLoading 
            isLoading={isLoadingGoogleAccount}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <div className="sectionWrap integration-main-container integration-google-list text-left">          
            <>
              <div className="integration-list-header">
                <img
                  src={GoogleIcon}
                  alt=""
                  className="integration-list-header-icon"
                />

                <div className="integration-list-header-text-wrapper">
                  <b>Google Business Messages</b>
                  {/* <p>
                    To integrate with Google,
                    <a href="/"> please read the documentation.</a>
                  </p> */}
                </div>
                
                {(!isLoadingGoogleAccount && !retriveDataError.code) &&
                  <ButtonWithLoadingOrIcon 
                      icon={{
                        type: "svg",
                        src: FiPlus
                      }}
                      className="no-fill-button integration-list-header-button main-button-40"
                      text="Add Channel"
                      position="left"
                      onClickAction={goToAdd}
                  />
                }
              </div>

              {isLoadingGoogleAccount || retriveDataError.code ? 
                <RetrieveDataLoading 
                  isLoading={isLoadingGoogleAccount}
                  errorMessage={retriveDataError.message} 
                />    
                :
                (googleAccountData.length > 0 ? 
                  <div className="integration-list-outer-wrapper">
                    {googleAccountData.map((value, index) => {
                      return (
                        <Link
                          key={`google-account-${index}`}
                          to={`/o/${orgID}/integration/google-business-messages/detail/${value.id}`}
                          className="link-google-account-list"
                        >
                          <div className="integration-list-inner-wrapper">
                            <FiArrowUpRight />
                            <p>
                              <b>{value.displayName}</b>
                            </p>

                            <span>{value.stringID}</span>

                            <p className={`font-root-medium status-${value.status}`}>
                              {value.statusText}
                            {/* `// font-red
                            // font-orange
                            // font-green
                            //   Active 
                            //   Suspended
                            //   Verified` */}
                            </p>
                           </div>
                        </Link>
                      );
                    })}
                  </div>
                  : 
                  <div className="integration-list-no-data">
                    <b>No Channel</b>
                    <p>
                      To add a channel, simply click the "+ Add Channel" button on the top right.
                    </p>
                  </div>
                )
              }
            </>
        </div>
        
      </SectionWrap>
    </div>
  );
};

let mapStateToProps = state => ({
  mediumData: state.mediumData,
  createGoogle: state.createGoogle
});

let mapDispatchToProps = {
  clearCreateGoogle
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogle);
