import React, { useState, useEffect } from 'react';
import './MessageHistoryFilter.scss';
import { Dropdown, DropdownToggle } from 'reactstrap';
import iconFilter from '../../../assets/img/icon-filter.svg';
import MessageHistoryFilterModal from '../messageHistoryFilterModal/MessageHistoryFilterModal';

const MessageHistoryFilter = (props) => {
    let { 
        currentFilter,
        onFilterUpdated,
        isDisabled,
    } = props;

    let [isOpenMessageFilterModal, setIsOpenMessageFilterModal] = useState(false);
    let [filterCount, setFilterCount] = useState(0);

    let toggleMessageFilterModal = () => {
        if (isDisabled) {
            return;
        }
        setIsOpenMessageFilterModal(!isOpenMessageFilterModal);
    }

    let updateMessageHistoryFilter = (val) => {
        onFilterUpdated(val)
    }

    let calculateFilterCount = () => {
        let filterCount = 0;
        Object.keys(currentFilter).forEach(function(key, index) {
            let value = currentFilter[key];
            if (typeof value === "string") {
                if (value) {
                    filterCount++;
                }
            }
            else if (value instanceof Array) {
                value.map((val, idx) => {
                    if (val) {
                        filterCount++;
                    }

                    return null;
                });
            }
        });
        setFilterCount(filterCount);
    }

    useEffect(() => {
        calculateFilterCount();
    }, [currentFilter]);

    return (
        <>
            <MessageHistoryFilterModal 
                isOpen={isOpenMessageFilterModal}
                toggle={toggleMessageFilterModal}
                currentFilter={{...currentFilter}}
                updateMessageHistoryFilter={updateMessageHistoryFilter}
            />

            <Dropdown 
                isOpen={false} 
                className={`message-history-filter ${isDisabled ? "disabled-message-history-filter" : ""}`} 
                isDisabled={isDisabled}
                toggle={toggleMessageFilterModal} 
            >
                <DropdownToggle>
                    <img alt="" src={iconFilter} className={`icon-filter ${isDisabled ? "disabled" : ""}`} /> 
                    Filters
                    <b className={`filter-badge ${filterCount <= 0 ? "hidden" : ""} ${isDisabled ? "disabled-badge" : ""}`}>{filterCount}</b>
                </DropdownToggle>
            </Dropdown>
        </>
    );
};

export default MessageHistoryFilter;
