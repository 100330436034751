import React, { useState } from "react";
import "../../auth/Auth.css";
import "../QuickReply.scss";
import { FiX } from "react-icons/fi";
import { checkID, doToast } from "../../../helper/HelperGeneral";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { Modal, ModalBody } from "reactstrap";
import { FiPlus, FiImage, FiFile, FiList, FiInfo } from "react-icons/fi";
import iconChatGrey from "../../../assets/img/icon-chat-grey.svg";
// import iconChatOrange from "../../../assets/img/icon-chat-orange.svg";
import iconEditOrange from "../../../assets/img/icon-edit.svg";
import IconRemoveRed from "../../../assets/img/icon-remove-red.svg";
import iconImage from "../../../assets/img/broadcast-message/icon_image.svg";
import iconFile from "../../../assets/img/broadcast-message/icon_file.svg";
import iconText from "../../../assets/img/broadcast-message/icon_text.svg";
import iconWaba from "../../../assets/img/broadcast-message/icon-waba.svg";
import iconCheckOrange from "../../../assets/img/broadcast-message/icon_bubble_check_orange.svg";
import iconCheckGrey from "../../../assets/img/broadcast-message/icon_bubble_check_grey.svg";
// import { IoIosRefresh } from "react-icons/io";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import PopupRemove from "../../reuseableComponent/popupRemove/PopupRemove";
// import Loading from "../../reuseableComponent/loading/Loading";
import NewButton from "../../reuseableComponent/NewButton/NewButton";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceQuickReply from "../../../services/newServices/QuickReplyService";
import HelperQuickReply from "../../../helper/HelperQuickReply";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

let QuickReplyPersonal = props => {
  let { match, history, location, quickReply, retriveDataError } = props;
  let [orgID, setOrgID] = React.useState(false);
  let [waitForResponseDelete, setWaitForResponseDelete] = React.useState(false);
  let [quickReplyList, setQuickReplyList] = React.useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = React.useState(false);
  let [showPopupSingleRemove, setShowPopupSingleRemove] = React.useState(false);
  let [singleRemoveQuickReply, setSingleRemoveQuickReply] = React.useState({
    shortcut: "",
    id: ""
  });
  // let [retriveDataError, setRetrieveDataError] = React.useState({
  //   code: false,
  //   message: false
  // });
  let [showModalAdd, setShowModalAdd] = useState(false);
  let [activeSelect, setActiveSelect] = useState(0);
  let [activeButtonType, setActiveButtonType] = useState(0);
  let [errorMessageData, setErrorMessageData] = useState({
    type: "",
    buttonType: ""
  })
  
  let toggleModalAdd = () => {
    setErrorMessageData({
      type: "",
      buttonType: ""
    });
    setShowModalAdd(!showModalAdd);
    setActiveSelect(0);
    setActiveButtonType(0);
  }
  
  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/setup/quick-reply/personal`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  // let fetchQuickReplyList = () => {
  //   let _retriveDataError = {...retriveDataError};

  //   ServiceQuickReply.getQuickReplyList(orgID, {channelType: "all"}, (response) => {
  //     let dataResult = response.dataResult;
      
  //     if(dataResult.error.message === "") {
  //       setQuickReplyList(dataResult.data.personal);
  //     }else {
  //       _retriveDataError.message = dataResult.error.message;
  //       _retriveDataError.code = dataResult.error.code;
  //       setRetrieveDataError(_retriveDataError);
  //     }

  //     setWaitForResponseGetList(false);
  //   })
  // }

  React.useEffect(() => {
    if(orgID) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      // fetchQuickReplyList();
      setWaitForResponseGetList(false);
      
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Personal Quick Reply", 
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : orgID.split("-")[0]
            }
        );
      }
    }
  }, [orgID]);

  React.useEffect(() => {
    if(quickReply) {
      setQuickReplyList(quickReply.personal);
    }
  }, [quickReply])

  React.useEffect(() => {
    doMount();
  }, [location.pathname]);

  React.useEffect(() => {
    if(props.history.location.state) {
      if(props.history.location.state.message) {
        let _history = {...props.history.location.state};
        delete _history.message;

        doToast(props.history.location.state.message);
        props.history.replace({
          state: _history
        });
      }
    }
  }, [props.history])

  // let onChangePage = () => {
  //   console.log('change page')
  // }

  let toggleModalRemoveQuickReply = () => {
    setShowPopupSingleRemove(!showPopupSingleRemove);
  }

  let deleteQuickReply = () => {
    setWaitForResponseDelete(true);
    let _singleRemoveQuickReply = {...singleRemoveQuickReply};
    
    ServiceQuickReply.deleteQuickReply(orgID, _singleRemoveQuickReply, (response) => {
      let dataResult = response.dataResult;
      setWaitForResponseDelete(false);
      toggleModalRemoveQuickReply();

      if(dataResult.error.message === "") {
        if(!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, "fail");
        }else {
          doToast(response.dataResult.data.message);

          let _quickRep = quickReplyList.slice();

          let findIdx = quickReplyList.findIndex(v => v.id === singleRemoveQuickReply.id);

          if(findIdx !== -1) {
            _quickRep.splice(findIdx, 1);
          }

          setQuickReplyList(_quickRep);
          // fetchQuickReplyList();
        }
      }else {
        doToast(response.dataResult.error.message, 'fail');
      }

    })
  }

  let onClickSingleDelete = (data) => {
    let _data = {
      ownerType: HelperQuickReply.ownerTypePersonal,
      shortcut: data.shortcut,
      id: data.id,
      createdTime: data.createdTime
    }

    setSingleRemoveQuickReply(_data);
    setShowPopupSingleRemove(true);
  }

  let addBtn = () => {
    // let fullUrl = `/o/${orgID}/setup/quick-reply/personal/add`;
    
    // let onClick = () => history.push(fullUrl);
    let onClick= () => {};
    let btnProps = { text: "Add Quick Reply", icon: FiPlus, onClick };

    return <NewButton {...btnProps} />;
  };

  let ModalAdd = () => {
    return (
      <Modal className="modal-add-quick-reply" isOpen={showModalAdd} toggle={toggleModalAdd}>
        <ModalBody>
          <FiX onClick={toggleModalAdd} className="close-modal-quick-reply" />
          
          <b className="add-quick-reply-title">Add Personal Quick Reply</b>

          <label>
            <b>Type</b>
          </label>

          <div 
            className={`
              add-quick-reply-selection
              ${activeSelect === 1 ? "active-add-quick-reply-selection" : ""}
            `} 
            onClick={() => {
              setErrorMessageData({
                type: "",
                buttonType: ""
              });
              setActiveSelect(1);
              setActiveButtonType(0);
            }}
          >
            <img src={iconText} alt="" />
            Text
          </div>

          <div 
            className={`
              add-quick-reply-selection
              no-margin-right
              ${activeSelect === 2 ? "active-add-quick-reply-selection" : ""}
            `} 
            onClick={() => {
              setErrorMessageData({
                type: "",
                buttonType: ""
              });
              setActiveSelect(2);
              setActiveButtonType(0);
            }}
          >
            <img src={iconFile} alt="" />
            Text with Document
          </div>

          <div 
            className={`
              add-quick-reply-selection
              ${activeSelect === 3 ? "active-add-quick-reply-selection" : ""}
            `} 
            onClick={() => {
              setErrorMessageData({
                type: "",
                buttonType: ""
              });
              setActiveSelect(3);
              setActiveButtonType(0);
            }}
          >
            <img src={iconImage} alt="" />
            Text with Media
          </div>

          <div 
            className={`
              add-quick-reply-selection
              no-margin-right
              ${activeSelect === 4 ? "active-add-quick-reply-selection" : ""}
            `} 
            onClick={() => {
              setErrorMessageData({
                type: "",
                buttonType: ""
              });
              setActiveSelect(4);
              setActiveButtonType(0);
            }}
          >
            <img src={iconWaba} alt="" />
            WABA Interactive Message
          </div>

          {errorMessageData.type !== "" &&
            <ErrorOrInfoComp 
              _className="red-text no-margin"
              text={errorMessageData.type}
              icon={<FiInfo />}
            />
          }

          {activeSelect === 4 &&
            <>
              <label className="button-type">
                <b>Button Type</b>
              </label>

              <div 
                className={`add-quick-reply-selection-button-type ${activeButtonType === 1 ? "active-add-quick-reply-selection-button-type" : ""}`} 
                onClick={() => {
                  setErrorMessageData({
                    type: "",
                    buttonType: ""
                  })
                  setActiveButtonType(1)
                }}
              >
                <img src={activeButtonType === 1 ? iconCheckOrange : iconCheckGrey} alt="" />
                Reply Button
              </div>

              <div 
                className={`add-quick-reply-selection-button-type no-margin-right ${activeButtonType === 2 ? "active-add-quick-reply-selection-button-type" : ""}`} 
                onClick={() => {
                  setErrorMessageData({
                    type: "",
                    buttonType: ""
                  })
                  setActiveButtonType(2)
                }}
              >
                <FiList className={activeButtonType === 2 ? "orange-button-type" : ""} />
                List
              </div>

              {errorMessageData.buttonType !== "" &&
                <ErrorOrInfoComp 
                  _className="red-text no-margin"
                  text={errorMessageData.buttonType}
                  icon={<FiInfo />}
                />
              }
            </>
          }

          <ButtonWithLoadingOrIcon 
            text="Continue"
            onClickAction={() => {
              let _errorMessageData = {...errorMessageData};
              let errorCount = 0;
              
              if(activeSelect === 0) {
                errorCount = errorCount + 1;
                _errorMessageData.type = "Please select type";
              }

              if(activeSelect === 4 && activeButtonType === 0) {
                errorCount = errorCount + 1;
                _errorMessageData.buttonType = "Please select button type";
              }
 
              if(errorCount > 0) {
                setErrorMessageData(_errorMessageData);
              }else {
                activeSelect === 4 ?
                  props.history.push({
                    pathname: `/o/${orgID}/setup/quick-reply/personal/add/interactive/${activeButtonType === 1 ? "button" : "list"}`,
                    state: {
                      type: activeButtonType === 1 ? "button" : "list"
                    }
                  })
                  :
                  props.history.push({
                    pathname: `/o/${orgID}/setup/quick-reply/personal/add`,
                    state: {
                      type: activeSelect === 1 ? "text" : (activeSelect === 3 ? "media" : "file")
                    }
                  })
              }
            }}
            // isDisabled={activeSelect === 0}
            className="orange-button main-button-48"
          />
        </ModalBody>
      </Modal>
    )
  }

  let getWabaType = (data) => {
    let type = "";

    if(data.whatsAppBAInteractivePayload !== "") {
      type = JSON.parse(data.whatsAppBAInteractivePayload).type;
    }

    return type;
  }

  return (
    <React.Fragment>
      {ModalAdd()}

      <div className="sectionWrap quick-reply-main-wrapper">
          {/* tier blocking */}
          {retriveDataError.code  === "49901" &&
            <RetrieveDataLoading 
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
          }
          {/* tier blocking */}
          
          <div className="topContent">
              <div className="title">Personal Quick Replies</div>

              {!waitForResponseGetList &&
                // <NavLink to={`/o/${orgID}/setup/quick-reply/personal/add`}>
                  <div 
                    className="btn"
                    onClick={() => {
                      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                      
                      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                          "[Button] Add Quick Reply",
                          {
                            type : "Personal",
                            userFullName : myAgentData.account.fullName,
                            userID : myAgentData.account.id,
                            organizationID : orgID.split("-")[0]
                          }
                        )
                      }

                      toggleModalAdd();
                    }}
                  >
                    {addBtn()}
                  </div>
                // </NavLink>
              }
          </div>

          <div className="quick-reply-list-info">
              Speed up your workflow by adding quick replies for frequently sent messages. To send quick replies, simply type “/“ in OneTalk’s inbox and select the shortcut from the list.
          </div>

          {waitForResponseGetList || retriveDataError.code ? 
              <RetrieveDataLoading 
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message} 
              />
            :
            <React.Fragment>
              <div className="quick-reply-list-table-wrapper">
                <div className="custom-table">
                    <table className="custom-table-content table-quick-reply-list">
                        <thead>
                            <tr>
                              <td className="col_message">Message</td>
                              <td className="col_shortcut">Shortcut</td>
                              <td className="col_type">Type</td>
                              <td className="col_action"></td>
                            </tr>
                        </thead>
                        
                        {quickReplyList.length < 1 ?
                          <tbody>
                            <tr>
                              <td colSpan="4" className="no-data-column no-hover"><b className="no-result-found">No quick replies found, start with ‘+ Add Quick Reply’</b></td>
                            </tr>
                          </tbody>
                          :
                          <tbody>
                            {quickReplyList.map((value, index) => {
                              return (
                                <tr key={`quick-reply-${index}`}>
                                    <td className="col_message">{value.message}</td>
                                    <td className="col_shortcut">
                                      <span className="shortcut-badge"><b>{HelperQuickReply.prefixShortcut}{value.shortcut}{HelperQuickReply.suffixShortcut}</b></span>
                                    </td>
                                    <td className="col_type">
                                      {value.isWhatsAppBAInteractive ?
                                        <>
                                          <img src={iconWaba} alt="" className="img-waba" />
                                          WABA Interactive Message
                                        </>
                                        :
                                        ((value.messageType === "text" || value.messageType === "") ?
                                          <>
                                            <img src={iconChatGrey} alt="" />
                                            Text
                                          </>
                                          :
                                          (value.messageType === "file" ?
                                            <>
                                              <FiFile />
                                              Text with Document
                                            </>
                                            :
                                            <>
                                              <FiImage />
                                              Text with Media
                                            </>
                                          )
                                        )
                                      }
                                    </td>
                                    <td className={`col_action`}>
                                      <div className="col-action-button-wrapper">
                                          <button 
                                            className="single-remove-button no-background-and-border-button" 
                                            onClick={() => {
                                              let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                              
                                              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                mixpanel.track(
                                                  "[Button] Edit Quick Reply",
                                                    {
                                                      type : "Personal",
                                                      userFullName : myAgentData.account.fullName,
                                                      userID : myAgentData.account.id,
                                                      organizationID : orgID.split("-")[0]
                                                    }
                                                )
                                              }

                                              props.history.push({
                                                pathname: value.isWhatsAppBAInteractive ? `/o/${orgID}/setup/quick-reply/personal/edit/${value.id}/${getWabaType(value)}` : `/o/${orgID}/setup/quick-reply/personal/edit/${value.id}`,
                                                state: {
                                                  shortcut: value.shortcut,
                                                  message: value.message,
                                                  id: value.id,
                                                  mediaFilename: value.mediaFilename,
                                                  mediaURL: value.mediaURL,
                                                  messageType: value.messageType,
                                                  data: value.whatsAppBAInteractivePayload
                                                }
                                              })
                                            }}
                                          >
                                              <img className="view-agent" src={iconEditOrange} alt="" />
                                              <b className="view-agent">Edit</b>
                                          </button>
                                          
                                          <button 
                                            className="single-remove-button no-background-and-border-button" 
                                            onClick={() => {
                                              let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                              
                                              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                mixpanel.track(
                                                  "[Button] Delete Quick Reply",
                                                    {
                                                      type : "Personal",
                                                      userFullName : myAgentData.account.fullName,
                                                      userID : myAgentData.account.id,
                                                      organizationID : orgID.split("-")[0]
                                                    }
                                                )
                                              }
                                            
                                              onClickSingleDelete(value)
                                            }}
                                          >
                                            <img className="view-agent" src={IconRemoveRed} alt="" />
                                            <b>Delete</b>
                                          </button>
                                      </div>
                                    </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        }
                    </table>
                </div>
                  
                {/* <Pagination total={100} 
                            page={1} 
                            listPerPage={8} 
                            step={3} 
                            onChangePage={onChangePage} 
                /> */}

                <PopupRemove removeTitle={`Delete Quick Reply ${singleRemoveQuickReply.shortcut}?`}
                            removeDescription={`This quick reply will be removed from the list`}
                            onClickCancel={() => toggleModalRemoveQuickReply()}
                            onClickRemove={deleteQuickReply}
                            waitForResponse={waitForResponseDelete}
                            showModalProps={showPopupSingleRemove}
                            disabledButtonSubmit={waitForResponseDelete}
                            submitText="Delete Quick Reply"
                />
              </div>
            </React.Fragment>
          }
      </div>
    </React.Fragment>
  );
};

export default QuickReplyPersonal;
