import React, { useState, useEffect } from 'react';
import './IntegrationEmailEditChannelButton.scss';
import { FiInfo } from 'react-icons/fi';
import IntegrationEmailServices from '../../../../services/integration/IntegrationEmailServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import { checkID, doToast, excludeEmojiInput, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import CopyBox from '../../../reuseableComponent/copyBox/CopyBox';

const REG_NUMERIC = /^[0-9]*$/;

let IntegrationEmailEditChannelButton = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [isLoadingEditChannelLink, setIsLoadingEditChannelLink] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [detailData, setDetailData] = useState(false);
  let [data, setData] = useState({
    targetID: "",
    message: "",
    sendPageURL: false,
    isEnabled: false,
    title: "",
    subject: ""
  })
  let [dataOrigin, setDataOrigin] = useState({
    targetID: "",
    message: "",
    sendPageURL: false,
    isEnabled: false,
    title: "",
    subject: ""
  })
  let [errorMessageData, setErrorMessageData] = useState({
    title: "",
    targetID: "",
    message: "",
    subject: ""
  })

  useEffect(() => {
    doMount();
  }, []);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getAccountDetail(paramID, match.params.accountID);
      // getEmailChannelLink(paramID, match.params.accountID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };


  let getAccountDetail = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);

    IntegrationEmailServices.getDetail(orgID, { id: parseInt(accountID) }, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {

        setDetailData(response.dataResult.data.channel);
        getEmailChannelLink(orgID, accountID);
      } else {
        if (response.dataResult.error.code !== "40401") {
          _retriveDataError.message = response.dataResult.error.message;
          _retriveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retriveDataError);
        }
      }

      // setIsLoadingGetDetailData(false);
    });
  };

  let getEmailChannelLink = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);

    IntegrationEmailServices.getChannelLink(orgID, { id: parseInt(accountID) }, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        response.dataResult.data.channelLink.subject = response.dataResult.data.channelLink.emailSubject;
        setData(response.dataResult.data.channelLink);
        setDataOrigin(response.dataResult.data.channelLink);
      } else {
        if (response.dataResult.error.code !== "40401") {
          _retriveDataError.message = response.dataResult.error.message;
          _retriveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retriveDataError);
        }
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let editEmailChannelLink = () => {
    let __data = { ...data };
    let _errorMessageData = { ...errorMessageData };
    let errorCount = 0;
    let errorClass = "";

    if (__data.title === "") {
      _errorMessageData.title = "This field is required";
      errorCount = errorCount + 1;
      if (errorClass === "") { errorClass = "input-title" }
    }

    if (__data.title.length > 25) {
      _errorMessageData.title = "Characters exceed limit";
      errorCount = errorCount + 1;
      if (errorClass === "") { errorClass = "input-title" }
    }

    if (__data.subject === "") {
      _errorMessageData.subject = "This field is required";
      errorCount = errorCount + 1;
      if (errorClass === "") { errorClass = "input-subject" }
    }

    if (__data.message === "") {
      _errorMessageData.message = "This field is required";
      errorCount = errorCount + 1;
      if (errorClass === "") { errorClass = "input-message" }
    }

    if (__data.message.length > 1000) {
      _errorMessageData.message = "Characters exceed limit";
      errorCount = errorCount + 1;
      if (errorClass === "") { errorClass = "input-content" }
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      setIsLoadingEditChannelLink(true);

      let _data = {
        channelID: parseInt(match.params.accountID),
        title: data.title,
        emailSubject: data.subject,
        message: data.message,
        sendPageURL: data.sendPageURL,
        isEnabled: data.isEnabled
      }

      IntegrationEmailServices.setChannelLink(orgID, _data, (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
            setIsLoadingEditChannelLink(false);
          } else {
            doToast(response.dataResult.data.message);

            setTimeout(() => {
              if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Set Channel Link",
                  {
                    channel: "Email",
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    organizationID: orgID.split("-")[0]
                  }
                );
              }

              setDataOrigin(data);
              setTimeout(() => {
                history.push(
                  `/o/${orgID}/integration/email/detail/${match.params.accountID}`,
                  {
                    state: 'edit-channel-link',
                    message: response.dataResult.data.message,
                  }
                );
              }, 0);

              setIsLoadingEditChannelLink(false);
            }, 200)
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setIsLoadingEditChannelLink(false);
        }
      }
      );
    }
  };

  // let isFormValid = () => {
  //   let isDisabled = false;

  //   if(data.targetID === "") {
  //     isDisabled = true;
  //   }

  //   if(JSON.stringify(data) === JSON.stringify(dataOrigin)) {
  //     isDisabled = true;
  //   }

  //   return isDisabled;
  // };

  let back = (id) => history.push(`/o/${orgID}/integration/email/detail/${id}`);

  let onChangeToggle = (e) => {
    let _data = { ...data };
    _data.isEnabled = e.target.checked;
    setData(_data)
  }

  let onChange = (e) => {
    let _data = { ...data };
    let _errorMessageData = { ...errorMessageData };

    if (e.target.id === "message" && e.target.value === "") {
      _data.sendPageURL = false;
    }

    if (e.target.id === "targetID") {
      if (REG_NUMERIC.test(e.target.value.replaceAll(" ", ""))) {
        _data[e.target.id] = e.target.value;
        _errorMessageData[e.target.id] = "";
        setData(_data);
      }
    } else {
      _data[e.target.id] = excludeEmojiInput(e.target.value);
      _errorMessageData[e.target.id] = "";
      setData(_data);
    }

    if (e.target.id === "title") {
      if (_data.title.length > 25) {
        _errorMessageData.title = "Characters exceed limit";
      } else {
        _errorMessageData.title = "";
      }
    }

    setErrorMessageData(_errorMessageData)
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >
        <div className="sectionWrap integration-main-container integration-email-edit-channel-button text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <>
              <BackButton
                text={`Back to Channel Details`}
                onClick={() => back(match.params.accountID)}
              />

              <div className="integration-email-edit-channel-button-box">
                <div className="integration-email-edit-channel-button-box-top">
                  <b>Edit Channel Button for Live Chat</b>

                  {!isLoadingEditChannelLink ? (
                    <ButtonWithLoadingOrIcon
                      // isDisabled={isFormValid()}
                      onClickAction={editEmailChannelLink}
                      className="orange-button main-button-40"
                      text="Save Changes"
                      position="left"
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      className="orange-button main-button-40"
                      text="Save Changes"
                      position="left"
                    />
                  )}
                </div>
                <div className="integration-email-edit-channel-button-box-form form-content-wrapper">
                  <div className="form-channel-button">
                    <div className="switch-channel-button">
                      <label>
                        <b>Enable Channel Button</b>
                      </label>
                      <span className="integration-info-form">
                        Your email channel will be shown in live chat widget.
                      </span>

                      <label className="custom-switch-wrapper">
                        <input disabled={isLoadingEditChannelLink} type="checkbox" checked={data.isEnabled} onChange={(e) => onChangeToggle(e)} />
                        <span className="custom-switch round"></span>
                      </label>
                    </div>

                    <div className="form-content-wrapper">
                      <label>
                        <b>Title</b>

                        <span className={`grey-font ${errorMessageData.title === "Characters exceed limit" ? "red-text" : ""}`}>{data.title.length}/25 characters</span>
                      </label>
                      <input className={errorMessageData.title !== "" ? "border-red input-title" : "input-title"} disabled={isLoadingEditChannelLink} type="text" placeholder="Type your channel button title" id="title" value={data.title} onChange={(e) => onChange(e)} />
                      {errorMessageData.title !== "" &&
                        <ErrorOrInfoComp
                          text={errorMessageData.title}
                          _className={"font-red"}
                          icon={<FiInfo />}
                        />
                      }

                      <label>
                        <b>Email Address</b>
                      </label>
                      <CopyBox
                        id={`copy-email`}
                        val={detailData.emailAddress}
                        copyText="Email Copied"
                        disabled
                      />

                      <label>
                        <b>Subject</b>
                      </label>
                      <textarea rows="5" className={errorMessageData.subject !== "" ? "border-red input-subject width-100" : "input-subject width-100"} disabled={isLoadingEditChannelLink} placeholder="Type your subject" id="subject" value={data.subject} onChange={(e) => onChange(e)} />
                      {errorMessageData.subject !== "" &&
                        <ErrorOrInfoComp
                          text={errorMessageData.subject}
                          _className={"font-red"}
                          icon={<FiInfo />}
                        />
                      }

                      <label className='width-100'>
                        <b>Body</b>
                      </label>
                      <textarea placeholder="Type your body" rows="10" id="message" disabled={isLoadingEditChannelLink} value={data.message} className={errorMessageData.message !== "" ? "border-red input-message width-100" : "input-message width-100"} onChange={(e) => onChange(e)} />
                      {errorMessageData.message !== "" &&
                        <ErrorOrInfoComp
                          text={errorMessageData.message}
                          _className={"font-red margin-top-0 margin-bottom-8"}
                          icon={<FiInfo />}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationEmailEditChannelButton;
