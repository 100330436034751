const INITIAL_STATE = false;

let reduxReducerIsWaitMarkAsJunk = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_IS_WAIT_MARK_AS_JUNK':
        return action.isWaitMarkAsJunk;
      case 'CLEAR_IS_WAIT_MARK_AS_JUNK':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerIsWaitMarkAsJunk;