import React, { useState, useEffect } from "react";
import "./FormCompanyDetails.scss";
import { FiHelpCircle, FiArrowLeft, FiInfo } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import Tooltip from "../../../../reuseableComponent/Tooltip/Tooltip";
import HelperInput from "../../../../../helper/HelperInput";
// import PhoneCodePicker from '../../../../reuseableComponent/phoneCodePicker/PhoneCodePicker';
import CountryPicker from "../../../../reuseableComponent/countryPicker/CountryPicker";
import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";
import { connect } from 'react-redux';

const DEFAULT_COMPANY_DETAIL = {
    companyName: "",
    country: "",
    address: "",
    companyPhone: {
        country: {
            value: "",
            countryLabel: "",
            countryFlag: "",
            countryCodeNumber: ""

            // value: 1,
            // countryLabel: "Republic of Indonesia",
            // countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png",
            // countryCodeNumber: "62"

            // id: "0",
            // countryCodeNumber: "62",
            // countryCode: "IDN",
            // countryName: "Indonesia",
            // countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"

            // callingCode: "62"
            // commonName: "Indonesia"
            // flagIconURL: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
            // id: 1
            // iso3Code: "IDN"
            // officialName: "Republic of Indonesia"
        },
        number: "",
    },
    companyEmail: "",
    industry: {
        value: "",
        label: ""
    }
}

let FormCompanyDetails = (props) => {
    let { lang, goToForm, _className, submitData, match } = props;
    let [valCompanyDetail, setValCompanyDetail] = useState(DEFAULT_COMPANY_DETAIL);
    let [countryList, setCountryList] = useState(false);
    let [industryList, setIndustryList] = useState([]);

    const onSelectIndustry = (value) => {
        let _data = { ...data };
        _data.verticalDropdown = value;
        _data.vertical = value.label
        submitData("company", _data)
    };

    useEffect(() => {
        getVerticalOptions()
    }, [])

    useEffect(() => {
        if (props.countryList) {
            let newCountryList = [];
            props.countryList.map((value) => {
                let data = {
                    value: "",
                    label: "",
                    countryLabel: "",
                    countryFlag: "",
                    countryCodeNumber: ""
                }

                data.value = value.id;
                data.label = value.officialName;
                data.countryLabel = value.officialName;
                data.countryFlag = value.flagIconURL;
                data.countryCodeNumber = value.callingCode

                newCountryList.push(data);

                return null;
            })

            setCountryList(newCountryList)
        }
    }, [props.countryList])

    let labelWebPlatform = (
        <div className="tooltip-toggle">
            <FiHelpCircle />
        </div>
    );

    let titleTooltip = (content, link = false) => {
        return (
            <div style={{ padding: '6px 10px' }}>
                {!link &&
                    <p style={{ margin: 0 }}>{content}</p>
                }

                {link &&
                    <a
                        href={link}
                        className="tooltip-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <b>{content}</b>
                    </a>
                }
            </div>
        )
    };

    let propsTip = (content, link = false) => {
        return {
            content: titleTooltip(content, link),
            children: labelWebPlatform
        }
    };

    let onChangeSelect = (value, e) => {
        let _valCompanyDetail = { ...valCompanyDetail };
        let _data = { ...data };
        _data[value] = e.value;
        _valCompanyDetail.country = e;
        // if(_data.phone) {
        //     let countryCode = getCountryCode(_data.phone)
        //     _data.phone = _data.phone.replace(countryCode, e.countryCodeNumber)
        // } 
        if (!_data.phone) {
            _data.phone = "+" + e.countryCodeNumber + " "
        } else {
            _data.phone = "+" + e.countryCodeNumber + " " + splitPhoneNumber(getPhoneNumber(_data.phone))
        }

        submitData("company", _data)
        setValCompanyDetail(_valCompanyDetail);
    }

    let onChangeCountryCode = (data) => {
        let _valCompanyDetail = { ...valCompanyDetail };
        _valCompanyDetail.companyPhone.country = data;
        setValCompanyDetail(_valCompanyDetail);
    }

    let [data, setData] = useState({
        name: "",
        countryID: "",
        website: "",
        address: "",
        phone: "",
        email: "",
        vertical: "",
        verticalDropdown: {
            value: "",
            label: ""
        }
    })

    let [validations, setValidations] = useState({
        website: true,
        email: true,
        phone: true,
    })

    let insertData = (e, field) => {
        let re;
        let value = ""
        let _validations = { ...validations }
        let _data = { ...data }
        let phone = _data.phone.replace("+", "").split(" ").join("").replaceAll(" ", "");

        switch (field) {
            case "website":
                // validation
                re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
                _validations.website = re.test(e.target.value)
                setValidations(_validations)
                value = e.target.value
                break
            case "phone":
                // re = /^(?:\+|\([0-9]*\))?[0-9\s-]*$/
                re = /^[0-9]*$/;
                _validations.phone = (re.test(e.target.value.replaceAll(" ", "")) && (phone.length > 6 && phone.length < 16))
                setValidations(_validations)
                // value = "+" + valCompanyDetail.companyPhone.country.countryCodeNumber + " " + e.target.value
                if (HelperInput.numberOnly(e.target.value.replace("+", "").replaceAll(" ", ""))) {
                    value = e.target.value
                }
                break
            case "email":
                // validation
                re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                _validations.email = re.test(e.target.value)
                setValidations(_validations)
                value = e.target.value
                break
            default:
                value = e.target.value
        }
        _data[field] = value
        // setData(_data)
        submitData("company", _data)
    }

    let splitPhoneNumber = (phoneNumber) => {
        let number = phoneNumber.replace(/\s/g, '');
        if (number.length > 7) {
            let part1 = number.substring(0, 3),
                part2 = number.substring(3, 7),
                part3 = number.substring(7);
            number = part1 + " " + part2 + " " + part3
        } else if (number.length > 4) {
            let part1 = number.substring(0, 2),
                part2 = number.substring(2);
            number = part1 + " " + part2
        }
        return number
    }

    let findData = (key) => {
        return data[key]
    }

    let submitCompany = () => {
        let _data = { ...data };

        _data.phone = _data.phone.replaceAll(" ", "");
        submitData("company", _data)
        goToForm(2)
    }

    const getCountryCode = (phone) => {
        let countryCode = String(phone).split(" ")[0]
        // countryCode = countryCode.split("+")[1]
        return countryCode
    }

    const getPhoneNumber = (phone) => {
        let phoneNumber = ""
        if (phone) {
            phoneNumber = String(phone).split(getCountryCode(phone))[1]
            phoneNumber = (phoneNumber ? splitPhoneNumber(phoneNumber) : "")
        }
        return phoneNumber
    }

    const checkPhoneNumberFilled = (phone) => {
        let phoneNumber = false
        if (phone) {
            phoneNumber = String(phone).split(getCountryCode(phone))[1].trim()
            phoneNumber = (phoneNumber ? phoneNumber.trim() : false)
        }
        return phoneNumber
    }

    // const getCountryLabel = () => {
    //     let selectedCountry = ""
    //     if(countryList) {
    //         selectedCountry = countryList.filter((obj) => {return obj.value === data.countryID})[0]
    //         if(selectedCountry) {
    //             selectedCountry = selectedCountry.countryLabel
    //         }
    //     }
    //     return selectedCountry
    // }

    const validateForm = () => {
        let valid = true

        if (!validations.email) valid = false
        else if (!validations.phone) valid = false
        else if (!validations.website) valid = false
        else if (!data.name) valid = false
        else if (!data.countryID) valid = false
        else if (!data.website) valid = false
        else if (!data.address) valid = false
        else if (!checkPhoneNumberFilled(data.phone)) valid = false
        else if (!data.email) valid = false
        else if (!data.vertical) valid = false

        return valid
    }

    useEffect(() => {
        if (props.data.company && props.data.company.countryID) {
            // let countryCode = getCountryCode(props.data.company.phone)
            let value = props.countryList.find((obj) => obj.id === props.data.company.countryID)
            if (value) {
                let country = {
                    value: value.id,
                    countryLabel: value.officialName,
                    countryFlag: value.flagIconURL,
                    countryCodeNumber: value.callingCode
                }
                onChangeCountryCode(country)
            }
        }
        setData({ ...props.data.company })
    }, [props.data])

    const getVerticalOptions = () => {
        IntegrationWhatsappBusinessServices.getVerticalOptions(match.params.orgID, false, (response) => {
            // let _retriveDataError = { ...retriveDataError };

            if (response.dataResult.error.message === "") {
                let _verticals = [];
                response.dataResult.data.verticals.map((item, idx) => {
                    _verticals.push({ value: idx, label: item });
                    return null;
                });
                setIndustryList(_verticals);
            } else {
                //   _retriveDataError.message = response.dataResult.error.message;
                //   _retriveDataError.code = response.dataResult.error.code;
                //   setRetrieveDataError(_retriveDataError);
            }

            // setIsLoadingGetDetailData(false);
        }
        );
    };

    return (
        <div className={`form-company-detail form-content-wrapper ${_className}`}>
            <label>
                <b>{lang.pageWaba.form2.textForm1}</b>
            </label>

            <input type="text" defaultValue={findData("name")} placeholder={lang.pageWaba.form2.textForm2} onChange={(e) => insertData(e, "name")} />

            <label>
                <b>{lang.pageWaba.form2.textForm3}</b>

                <Tooltip
                    {...propsTip(lang.pageWaba.form2.textTooltip1)}
                    interactive
                />
            </label>

            <CountryPicker
                list={countryList}
                placeholder={lang.pageWaba.form2.textForm3a}
                onChange={onChangeSelect}
                selectedID={findData("countryID")}
                // selectedCountryLabel={getCountryLabel}
                selectedCountryLabel={
                    countryList ?
                        countryList.filter((obj) => { return obj.value === data.countryID })[0] ?
                            countryList.filter((obj) => { return obj.value === data.countryID })[0].countryLabel
                            : ""
                        : ""
                }
            />

            <label>
                <b>{lang.pageWaba.form2.textForm4}</b>
            </label>

            <input type="text" defaultValue={findData("website")} placeholder={"www.company.com"} className={validations.website ? "" : "border-warning-red"} onChange={(e) => insertData(e, "website")} />
            {validations.website ? "" : <div className="input-info-wrapper warning-text">
                <FiInfo />
                <b>{lang.pageWaba.form2.textWarning1}</b>
            </div>}

            <label>
                <b>{lang.pageWaba.form2.textForm5}</b>
            </label>

            <textarea defaultValue={findData("address")} placeholder={lang.pageWaba.form2.textForm6} maxLength="256" onChange={(e) => insertData(e, "address")} />

            <label>
                <b>{lang.pageWaba.form2.textForm7}</b>

                <Tooltip
                    {...propsTip(lang.pageWaba.form2.textTooltip2)}
                    interactive
                />
            </label>

            <div className="phone-input-wrapper">
                {/* <PhoneCodePicker 
                    countryListProps={countryList}
                    indexPhoneCodePickerProps={0}
                    countryProps={valCompanyDetail.companyPhone.country}
                    onChangeCountryCodeProps={onChangeCountryCode}
                    isMultipleProps={false}
                /> */}

                <span className="phone-input-plus">
                    +
                </span>

                <input
                    type="text"
                    // value={findData("phone")}
                    value={data.countryID ?
                        valCompanyDetail.companyPhone.country.countryCodeNumber + " " + HelperInput.phoneBeautify(getPhoneNumber(findData("phone")).replaceAll(" ", ""))
                        :
                        ""
                    }
                    className={(validations.phone ? "phone-waba-input" : "phone-waba-input border-warning-red")}
                    placeholder={"62 8123 456 789"}
                    disabled={!data.countryID}
                    // pattern="\+[0-9\s]*"
                    onChange={(e) => {
                        // if(e.target.validity.valid) insertData(e, "phone")
                        insertData(e, "phone")
                    }}
                    maxLength={20}
                // onClick={(e) => insertData(e, "phone")} 
                // onKeyPress={(e) => insertData(e, "phone")} 
                // onKeyDown={(e) => insertData(e, "phone")} 
                // onKeyUp={(e) => insertData(e, "phone")}
                />

                {validations.phone ? "" : <div className="input-info-wrapper warning-text">
                    <FiInfo />
                    <b>{lang.pageWaba.form2.textWarning3}</b>
                </div>}
            </div>

            <label>
                <b>{lang.pageWaba.form2.textForm8}</b>

                <Tooltip
                    {...propsTip(lang.pageWaba.form2.textTooltip3)}
                    interactive
                />
            </label>

            <input type="text" defaultValue={findData("email")} placeholder={lang.pageWaba.form2.textForm8a} className={validations.email ? "" : "border-warning-red"} onChange={(e) => insertData(e, "email")} />
            {validations.email ? "" : <div className="input-info-wrapper warning-text">
                <FiInfo />
                <b>{lang.pageWaba.form2.textWarning2}</b>
            </div>}

            <label>
                <b>{lang.pageWaba.form2.textForm9}</b>
            </label>

            <div className="">
                <CustomSelectOption
                    optionListProps={industryList}
                    valueDropdownProps={data.verticalDropdown}
                    placeholderProps={lang.pageWaba.form2.textForm10}
                    onClickDropDownListOptionProps={onSelectIndustry}
                />
                {/* <input type="text" defaultValue={findData("vertical")} placeholder={lang.pageWaba.form2.textForm10} onChange={(e) => insertData(e, "vertical")} /> */}
            </div>

            <div className="input-info-wrapper">
                <FiInfo />
                <b>{lang.pageWaba.form2.textForm11}</b>
            </div>

            <ButtonWithLoadingOrIcon
                className="no-fill-button main-button-40 width-100 submit-button"
                icon={{
                    type: "svg",
                    src: FiArrowLeft
                }}
                position="left"
                text={lang.pageWaba.form2.textForm12}
                onClickAction={() => goToForm(0)}
            // isDisabled={!this.validateForm()}
            />

            <ButtonWithLoadingOrIcon
                className="orange-button main-button-40 width-100 submit-button"
                text={lang.pageWaba.form2.textForm13}
                onClickAction={submitCompany}
                isDisabled={!validateForm()}
            />
        </div>
    )
}

const mapDispatchToProps = {

};

const mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormCompanyDetails);