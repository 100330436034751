import React from "react";
import './IntegrationSalestalkLoggedIn.scss';
import { FiUser } from "react-icons/fi";
import statusConnected from '../../../../assets/img/integration/status-connected.svg';

let IntegrationSalestalkLoggedIn = (props) => {
    return (
        <div className="integration-salestalk-login">
            <label>
                <b>Provider</b>
            </label>
            <p className="account-name">
                <b className="text-capitalize">{props.data.salesTalk.vendor}</b>
            </p>

            {/* {props.data.salesTalk.custom &&
                <>
                    <label>
                        <b>Store URL</b>
                    </label>
                    <p className="account-name">
                        <b>{props.data}</b>
                    </p>
                </>
            } */}

            <label>
                <b>Account Photo</b>
            </label>
            <div className="account-photo-wrapper">
                {props.data.avatarURL === "" ?
                    <FiUser />
                    :
                    <img src={props.data.salesTalk.avatarURL} alt="" />
                }
            </div>

            <label>
                <b>Account Name</b>
            </label>
            <p className="account-name">
                <b>{props.data.salesTalk.name}</b>
            </p>

            <label>
                <b>Status</b>
            </label>
            <p className="status-salestalk"> 
                <img src={statusConnected} alt="" />
                <b>Connected</b>
            </p>
        </div>
    )
}

export default IntegrationSalestalkLoggedIn;