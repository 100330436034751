import React, { useEffect, useState } from "react";
import "./FormQuestionnaire.scss";
import { FiHelpCircle, FiX } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import Tooltip from "../../../../reuseableComponent/Tooltip/Tooltip";
import listedHere1 from "../../../../../assets/img/integration/whatsapp-business/icon-1.svg";
import listedHere2 from "../../../../../assets/img/integration/whatsapp-business/icon-2.svg";
import listedHere3 from "../../../../../assets/img/integration/whatsapp-business/icon-3.svg";
import listedHere4 from "../../../../../assets/img/integration/whatsapp-business/icon-4.svg";
import { ModalBody, Modal } from "reactstrap";

let FormQuestionnaire = (props) => {
    let { lang, goToForm, submitData, _className } = props;
    let [showModalListedHere, setShowModalListedHere] = useState(false);

    let toggleModalListedHere = () => {
        setShowModalListedHere(!showModalListedHere)
    }

    let modalListedHere = () => {
        let listListedHere = [
            {
                img: listedHere1,
                title: lang.pageWaba.form1.textListedHere2,
                list: [
                    lang.pageWaba.form1.textListedHere6,
                    lang.pageWaba.form1.textListedHere7,
                    lang.pageWaba.form1.textListedHere8,
                    lang.pageWaba.form1.textListedHere9
                ]
            },
            {
                img: listedHere2,
                title: lang.pageWaba.form1.textListedHere3,
                list: [
                    lang.pageWaba.form1.textListedHere10,
                    lang.pageWaba.form1.textListedHere11,
                    lang.pageWaba.form1.textListedHere12
                ]
            },
            {
                img: listedHere3,
                title: lang.pageWaba.form1.textListedHere4,
                list: [
                    lang.pageWaba.form1.textListedHere13
                ]
            },
            {
                img: listedHere4,
                title: lang.pageWaba.form1.textListedHere5,
                list: [
                    lang.pageWaba.form1.textListedHere14
                ]
            }
        ]
        return (
            <Modal className="modal-listed-here" isOpen={showModalListedHere} toggle={toggleModalListedHere}>
                <ModalBody>
                    <FiX className="close-modal-listed-here" onClick={toggleModalListedHere} />

                    <div className="modal-listed-here-container">
                        <p className="modal-listed-here-title">
                            <b>{lang.pageWaba.form1.textListedHere1}</b>
                        </p>

                        {listListedHere.map((value, index) => {
                            return (
                                <div className="listed-here-card" key={`listed-here-${index}`}>
                                    <img src={value.img} alt="card-listed-here" />

                                    <div className="listed-here-card-title-wrapper">
                                        <b className="listed-here-card-title">
                                            {value.title}
                                        </b>
                                    </div>

                                    <ul className="listed-here-card-list">
                                        {value.list.map((_value, index) => {
                                            return (
                                                <li key={`listed-here-card-${index}`}>{_value}</li>
                                            )
                                        })}
                                    </ul> 
                                </div>
                            )
                        })}
                    </div>
                </ModalBody>
            </Modal>
        )
    }


    let labelWebPlatform = (
        <div className="tooltip-toggle">
            <FiHelpCircle />
        </div>
    );

    const titleTooltip = (content, link = false) => {
        return (
            <div style={{ padding: '6px 10px' }}>
                {!link &&
                    <p style={{ margin: 0 }}>{content}</p>
                } 

                {link &&
                    <a
                        href={link}
                        className="tooltip-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <b>{content}</b>
                    </a>
                }
            </div>
        )
    };
    
    const propsTip = (content, link = false) => {
        return {
            content: titleTooltip(content, link),
            children: labelWebPlatform
        }
    };

    let [data, setData] = useState([])
    const insertData = (questionID, answer) => {
        let index = data.findIndex(item => item.questionID === questionID);
        let _data = [...data]
        if (index >= 0) {
            _data[index].answer = answer
        } else {
            _data.push({
                questionID: questionID,
                answer: answer
            })
        }
        // setData(_data)
        submitData("questionnaire", _data)
    }

    const findData = (questionID, answer) => {
        let index = data.findIndex(item => item.questionID === questionID);
        if(index < 0) return false
        else return String(data[index].answer).toLowerCase() === String(answer).toLowerCase()
    }

    const submitQuestionnaire = () => {
        // sort questionnaire for easier reading
        let _data = [...data]
        _data.sort(function(a,b) {return a.questionID - b.questionID});
        // setData(_data)
        //
        submitData("questionnaire", _data)
        goToForm(1)
    }

    const validateForm = () => {
        // return true
        return data.length >= 6
    }

    useEffect(() => {
        setData([...props.data.questionnaire])
    }, [props.data])

    return (
        <div className={`form-questionnaire form-content-wrapper ${_className}`}>
            {modalListedHere()}

            <label>
                <b>{lang.pageWaba.form1.textForm1}</b> 

                <Tooltip 
                    {...propsTip(lang.pageWaba.form1.textFormTooltip1)}
                    interactive 
                />
            </label>

            <div className="custom-radio">
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-1" 
                    id="question-1-answer-1" 
                    value="finance" 
                    onChange={() => insertData(1, lang.pageWaba.form1.textForm2)}
                    checked={findData(1, lang.pageWaba.form1.textForm2)}
                />
                <label htmlFor="question-1-answer-1">
                    {lang.pageWaba.form1.textForm2}
                </label>

                <br />
                
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-1" 
                    id="question-1-answer-2" 
                    value="no" 
                    onChange={() => insertData(1, lang.pageWaba.form1.textForm3)}
                    checked={findData(1, lang.pageWaba.form1.textForm3)}
                />
                <label htmlFor="question-1-answer-2">
                    {lang.pageWaba.form1.textForm3}
                </label>
            </div>

            <label>
                <b>{lang.pageWaba.form1.textForm7}</b>

                <Tooltip 
                    {...propsTip(lang.pageWaba.form1.textFormTooltip2, "/")}
                    interactive 
                />
            </label>

            <div className="custom-radio">
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-2" 
                    id="question-2-answer-1" 
                    value="finance" 
                    onChange={() => insertData(2, lang.pageWaba.form1.textForm2)}
                    checked={findData(2, lang.pageWaba.form1.textForm2)}
                />
                <label htmlFor="question-2-answer-1">
                    {lang.pageWaba.form1.textForm2}
                </label>

                <br />
                
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-2" 
                    id="question-2-answer-2" 
                    value="no" 
                    onChange={() => insertData(2, lang.pageWaba.form1.textForm3)}
                    checked={findData(2, lang.pageWaba.form1.textForm3)}
                />
                <label htmlFor="question-2-answer-2">
                    {lang.pageWaba.form1.textForm3}
                </label>
            </div>

            <label>
                <b>{lang.pageWaba.form1.textForm8}</b>
                
                <Tooltip 
                    {...propsTip(lang.pageWaba.form1.textFormTooltip3, "/")}
                    interactive 
                />
            </label>

            <div className="custom-radio">
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-3" 
                    id="question-3-answer-1" 
                    value="finance" 
                    onChange={() => insertData(3, lang.pageWaba.form1.textForm5)}
                    checked={findData(3, lang.pageWaba.form1.textForm5)}
                />
                <label htmlFor="question-3-answer-1">
                    {lang.pageWaba.form1.textForm5}
                </label>

                <br />
                
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-3" 
                    id="question-3-answer-2" 
                    value="no" 
                    onChange={() => insertData(3, lang.pageWaba.form1.textForm6)}
                    checked={findData(3, lang.pageWaba.form1.textForm6)}
                />
                <label htmlFor="question-3-answer-2">
                    {lang.pageWaba.form1.textForm6}
                </label>
            </div>

            <label>
                <b>{lang.pageWaba.form1.textForm9}</b> 
            </label>

            <div className="custom-radio">
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-4" 
                    id="question-4-answer-1" 
                    value="finance" 
                    onChange={() => insertData(4, lang.pageWaba.form1.textForm2)}
                    checked={findData(4, lang.pageWaba.form1.textForm2)}
                />
                <label htmlFor="question-4-answer-1">
                    {lang.pageWaba.form1.textForm2}
                </label>

                <br />
                
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-4" 
                    id="question-4-answer-2" 
                    value="no" 
                    onChange={() => insertData(4, lang.pageWaba.form1.textForm3)}
                    checked={findData(4, lang.pageWaba.form1.textForm3)}
                />
                <label htmlFor="question-4-answer-2">
                    {lang.pageWaba.form1.textForm3}
                </label>
            </div>

            <label>
                <b>{lang.pageWaba.form1.textForm10} <span className="tooltip-link" onClick={toggleModalListedHere}>{lang.pageWaba.form1.textForm11}</span>?</b>
            </label>

            <div className="custom-radio">
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-5" 
                    id="question-5-answer-1" 
                    value="finance" 
                    onChange={() => insertData(5, lang.pageWaba.form1.textForm2)}
                    checked={findData(5, lang.pageWaba.form1.textForm2)}
                />
                <label htmlFor="question-5-answer-1">
                    {lang.pageWaba.form1.textForm2}
                </label>

                <br />
                
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-5" 
                    id="question-5-answer-2" 
                    value="no" 
                    onChange={() => insertData(5, lang.pageWaba.form1.textForm3)}
                    checked={findData(5, lang.pageWaba.form1.textForm3)}
                />
                <label htmlFor="question-5-answer-2">
                    {lang.pageWaba.form1.textForm3}
                </label>
            </div>

            <label>
                <b>{lang.pageWaba.form1.textForm12}</b> 
                
                <Tooltip 
                    {...propsTip(lang.pageWaba.form1.textFormTooltip4)}
                    interactive 
                />
            </label>

            <div className="custom-radio">
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-6" 
                    id="question-6-answer-1" 
                    value="1" 
                    onChange={() => insertData(6, String(1))}
                    checked={findData(6, 1)}
                />
                <label htmlFor="question-6-answer-1">
                    {1}
                </label>

                <br />
                
                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-6" 
                    id="question-6-answer-2" 
                    value="2" 
                    onChange={() => insertData(6, String(2))}
                    checked={findData(6, 2)}
                />
                <label htmlFor="question-6-answer-2">
                    {2}
                </label>

                <br />

                <input type="radio" 
                    className="form-questionnaire-inputs"
                    name="question-6" 
                    id="question-6-answer-3" 
                    value="no" 
                    onChange={() => insertData(6, lang.pageWaba.form1.textForm6a)}
                    checked={findData(6, lang.pageWaba.form1.textForm6a)}
                />
                <label htmlFor="question-6-answer-3">
                    {lang.pageWaba.form1.textForm6a}
                </label>
            </div>

            <ButtonWithLoadingOrIcon
                className="orange-button main-button-40 width-100 submit-button"
                text={lang.pageWaba.form1.textForm13}
                onClickAction={submitQuestionnaire}
                isDisabled={!validateForm()}
            />
        </div>
    )
}

export default FormQuestionnaire;