import React, { useState, useEffect } from "react";
import { FiInfo } from "react-icons/fi";
import "./IntegrationWebhookSetup2.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import HelperInput from "../../../../helper/HelperInput";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import IntegrationWebhookServices from "../../../../services/integration/IntegrationWebhookServices";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

const IntegrationWebhookSetup2 = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [dataList, setDataList] = useState(false);
  let [data, setData] = useState(false);
  let [dataOrigin, setDataOrigin] = useState(false);
  let [waitResponseSet, setWaitResponseSet] = useState(false);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [errorMessageData, setErrorMessageData] = useState({
    url: "",
    event: ""
  });

  useEffect(() => {
    if(dataList) {
      fetchData();
    }
  }, [dataList])

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration/webhook`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let back = () => {
    props.history.push(`/o/${props.match.params.orgID}/integration/webhooks`);
  }

  let fetchData = () => {
    let _retriveDataError = {...retriveDataError};
    
    IntegrationWebhookServices.getWebhook(orgID, (response) => {
      let dataResult = response.dataResult;
      if(dataResult.error.message === "") {
        setData(dataResult.data.webhookConfigs);
        setDataOrigin(dataResult.data.webhookConfigs);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  let fetchDataList = () => {
    let _retriveDataError = {...retriveDataError};
    
    IntegrationWebhookServices.getWebhookEvent(orgID, (response) => {
      let dataResult = response.dataResult;
      if(dataResult.error.message === "") {
        setDataList(dataResult.data.eventTypes);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        setWaitForResponseGetList(false);
      }
    })
  }

  let findIndexEvent = (text) => {
    let idx = data.subscribedEventTypes.findIndex((v) => v === text);

    return idx;
  }

  useEffect(() => {
    if(orgID) {
      fetchDataList();
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(props.history.location.state) {
      if(props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  let onChangeSwitch = () => {
    let _data = {...data};
    _data.isEnabled = !_data.isEnabled;
    setData(_data);
  }

  let saveChanges = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(data.isEnabled) {
      if(!HelperInput.validURL(data.webhookURL)) {
        _errorMessageData.url = "Invalid URL format";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-url"; }
      }

      if(data.webhookURL === "") {
        _errorMessageData.url = "This field is required";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-url"; }
      }

      if(data.subscribedEventTypes.length === 0) {
        _errorMessageData.event = "Please select one or more option for “Subscribed Events”";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-event"; }
      }
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`)
    }else {
      setWaitResponseSet(true);

      IntegrationWebhookServices.setWebhook(orgID, data, (res) => {
        let dataResult = res.dataResult;

        if(dataResult.error.message === "") {
          if(dataResult.data.success) {
            doToast(dataResult.data.message);
            setDataOrigin(data);
            setTimeout(() => {
              back();
            }, 3000);
          }else {
            doToast(dataResult.data.message, "fail");
            setWaitResponseSet(false);
          }
        }else {
         doToast(dataResult.error.message, "fail");
         setWaitResponseSet(false);
        }
      })
    }
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >
        <BackButton text="Back to Webhooks" onClick={back} />

        <div className="sectionWrap webhook2-main-setup-wrapper">
            <div className="webhook2-main-setup-wrapper-title-wrapper">
              <div className="title"><b>Edit Webhooks</b></div>

              {(retriveDataError.code  !== "49901" && !waitForResponseGetList) &&
                (!waitResponseSet ?
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    onClickAction={saveChanges}
                    text="Save Changes"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    text="Save Changes"
                    position="left"
                  />
                )
              }
            </div>

            <div className="webhook2-list-info">

            </div>

            {waitForResponseGetList || retriveDataError.code ? 
              <RetrieveDataLoading 
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message} 
              />
              :
              <React.Fragment>
                <div className="webhook2-wrapper">
                  <p className="label-title">
                    <b>Enable Webhooks</b>

                    <label className="custom-switch-wrapper">
                        <input type="checkbox" onClick={(e) => onChangeSwitch()} checked={data.isEnabled} />
                        <span className="custom-switch round"></span>
                    </label>
                  </p>
                  
                  <p className="label-desc">
                    If enabled, webhook notifications will be sent the subscribed events
                  </p>

                  {data.isEnabled &&
                    <>
                      <p className="label-title input-url">
                        <b>Webhook URL</b>
                      </p>
                      <input 
                        type="text"
                        value={data.webhookURL}
                        onChange={(e) => {
                          let _errorMessageData = {...errorMessageData};
                          let _data = {...data};
                          _data.webhookURL = e.target.value;
                          _errorMessageData.url = "";
                          setErrorMessageData(_errorMessageData);
                          setData(_data);
                        }}
                        className="input-webhook"
                        placeholder="Insert webhooks URL here."
                        disabled={waitResponseSet}
                      />

                      <ErrorOrInfoComp
                        text={errorMessageData.url !== "" ? errorMessageData.url : "Secret key will be automatically generated once after your changes are saved"}
                        _className={errorMessageData.url !== "" ? "red-text no-margin" : "no-margin"}
                        icon={<FiInfo />} 
                      />

                      <p className="label-title input-event">
                        <b>Subscribed Events</b>
                      </p>
                      
                      {dataList.map((v, i) => {
                        return (
                          <div className="custom-checkbox" key={`select-webhook2-${i}`}>
                            <input
                                type="checkbox"
                                id={`select-webhook2-${i}`}
                                checked={findIndexEvent(v.type) !== -1}
                                onChange={(e) => {
                                  let _data = {...data};
                                  let _errorMessageData = {...errorMessageData};

                                  if(!e.target.checked) {
                                    let idx = findIndexEvent(v.type);

                                    _data.subscribedEventTypes.splice(idx, 1);
                                    setData(_data);
                                  }else {
                                    _errorMessageData.event = "";
                                    _data.subscribedEventTypes.push(v.type);
                                    setErrorMessageData(_errorMessageData);
                                    setData(_data);
                                  }
                                }}
                            />
                            <label htmlFor={`select-webhook2-${i}`}>
                              <span>{v.name}</span>
                              <p>
                                {v.description}
                              </p>
                            </label>
                          </div>
                        )
                      })}

                      {errorMessageData.event !== "" &&
                        <ErrorOrInfoComp
                          text={errorMessageData.event !== "" ? errorMessageData.event : ""}
                          _className={errorMessageData.event !== "" ? "red-text no-margin" : "no-margin"}
                          icon={<FiInfo />} 
                        />
                      }
                    </>
                  }
                </div>
              </React.Fragment>
            }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default IntegrationWebhookSetup2;
