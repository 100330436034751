import React, { useState, useEffect } from 'react';
import { FiInfo } from 'react-icons/fi';
import { checkID, doToast } from '../../../../../../helper/HelperGeneral';
import IntegrationWhatsappBusinessServices from '../../../../../../services/integration/IntegrationWhatsappBusinessServices';
import ServiceOrganization from '../../../../../../services/organization/ServiceOrganization';
import BackButton from '../../../../../reuseableComponent/BackButton/BackButton';
import ButtonWithLoadingOrIcon from '../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import RetrieveDataLoading from '../../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import SectionWrap from '../../../../../reuseableComponent/sectionWrap/SectionWrap';
import './EditAccountProfile.scss'

const EditAccountProfile = (props) => {
  const { match, history } = props
  let [orgID, setOrgID] = useState(false);
  const [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(false)
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [initAboutText, setInitAboutText] = useState("")
  const [aboutText, setAboutText] = useState("")
  const [charLength, setCharLength] = useState(0)
  const [isDisabledButton, setIsDisabledButton] = useState(true)
  const [isLoadingEditAbout, setIsLoadingEditAbout] = useState(false)

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getAccountDetail()
    }
  }, [orgID]);

  const getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    const data = {
      id: Number(match.params.id),
      phoneNumberIDoptional: match.params.phoneID
    }

    IntegrationWhatsappBusinessServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setInitAboutText(response.dataResult.data.settings.profile.aboutText)
        setAboutText(response.dataResult.data.settings.profile.aboutText)
        setCharLength(response.dataResult.data.settings.profile.aboutText.length)
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  const onChangeAboutText = (e) => {
    setAboutText(e.target.value)
    setCharLength(e.target.value.length)
  }

  useEffect(() => {
    if (aboutText !== initAboutText) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [aboutText])

  const submitEditAbout = () => {
    setIsLoadingEditAbout(true)

    const data = {
      id: Number(match.params.id),
      aboutText: aboutText,
      phoneNumberID: match.params.phoneID
    }

    IntegrationWhatsappBusinessServices.editAbout(orgID, data, (response) => {
      setIsLoadingEditAbout(false);
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          setIsDisabledButton(true)
          setInitAboutText(aboutText)
          setTimeout(() => {
            history.push(
              `/o/${orgID}/integration/whatsapp-business-api/detail/${props.match.params.id}`,
              {
                whichTab: 'accountProfile'
              }
            );
            doToast(response.dataResult.data.message);
          }, 0)
        } else {
          doToast(response.dataResult.data.message, 'fail')
        }
      } else {
        doToast(response.dataResult.error.message, 'fail')
      }
    });
  }

  return (
    <div className="edit-profile-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={initAboutText}
        dataAfterChange={aboutText}
      >

      <BackButton 
        text="Back to Account Profile" 
        onClick={() => history.push({
          pathname: `/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}`,
          state: {
            whichTab: "accountProfile"
          }
        })}
      />

        <div className="edit-account-profile-whatsapp sectionWrap">
          {isLoadingGetDetailData || retriveDataError.code ?
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
            :
            <>
              <div className="edit-account-profile-header">
                <div className="title-wrapper">
                  <b>Edit About</b>
                </div>
                <div className="button-wrapper">
                  {
                    !isLoadingEditAbout ?
                      <ButtonWithLoadingOrIcon
                        className="orange-button  main-button-40"
                        text="Save Changes"
                        isDisabled={isDisabledButton}
                        onClickAction={submitEditAbout}
                      />
                      :
                      <ButtonWithLoadingOrIcon
                        isLoading
                        loadingColor="gray"
                        isDisabled
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                      />
                  }
                </div>
              </div>
              <div className="edit-account-profile-content">
                <div className="account-profile-content-wrapper">
                  <div className="label-wrapper">
                    <label><b>About</b></label>
                    <div className="count-char">
                      <p>{charLength}/139 characters</p>
                    </div>
                  </div>
                  <div className="edit-input-wrapper">
                    <textarea name="about" rows="4" maxLength="139" value={aboutText} onChange={(e) => onChangeAboutText(e)} disabled={isLoadingEditAbout} />
                  </div>
                  <div className="info-wrapper">
                    <FiInfo />
                    <b>This will be displayed on your about description in your WhatsApp account.</b>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </SectionWrap>
    </div>
  );
};

export default EditAccountProfile;