import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import '../Auth.css';
import './ResetPassword.scss';
import Logo from "../../../assets/img/logo-taptalk-white.svg";
import PaperPlaneLeft from "../../../assets/img/auth/img-paper-plane-left.svg";
import PaperPlaneRight from "../../../assets/img/auth/img-paper-plane-right.svg";
import IconFacebookMessenger from "../../../assets/img/auth/icon-fb-messenger-circle.svg";
import IconGoogle from "../../../assets/img/auth/icon-google-circle.svg";
import IconGoogleBusinessProfile from "../../../assets/img/auth/icon-gb-profile-circle.svg";
import IconInstagram from "../../../assets/img/auth/icon-instagram-circle.svg";
import IconMail from "../../../assets/img/auth/icon-mail-circle.svg";
import IconTelegram from "../../../assets/img/auth/icon-telegram-circle.svg";
import IconTokopedia from "../../../assets/img/auth/icon-tokopedia-circle.svg";
import IconWhatsAppSME from "../../../assets/img/auth/icon-whatsapp-circle.svg";
import IconWhatsAppBA from "../../../assets/img/auth/icon-whatsapp-ba-circle.svg";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import { FiAlertCircle, FiArrowLeft, FiCheck, FiExternalLink, FiEye, FiEyeOff, FiX } from 'react-icons/fi'
import ServiceResetPassword from '../../../services/auth/ServiceResetPassword';
import HelperQueryString from "../../../helper/HelperQueryString";
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import HelperGTag from '../../../helper/HelperGTag';

const style = {
  overflowY: {
    overflowY: "hidden"
  },
  passwordStrength: {
    fontSize: 0,
    marginTop: '16px'
  },
  marginBottomCustom: {
    marginBottom: "13px"
  },
  heightUnset: {
    height: "unset"
  },
  scrollStyle: {
    position: "relative",
    backgroundColor: "#dcdcdc",
    right: "-2px",
    borderRadius: "8px"
  }
};

const ResetPassword = () => {
  const [password, setPassword] = useState("")
  const [rePassword, setRePassword] = useState("")
  const [lowerCase, setLowerCase] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [number, setNumber] = useState(false)
  const [specialCharacter, setSpecialCharacter] = useState(false)
  const [minimumCharacter, setMinimumCharacter] = useState(false)
  const [isWaitForResponse, setIsWaitForResponse] = useState(false)
  const [isSuccessChangePassword, setIsSuccessChangePassword] = useState(false)
  const [failStateActive, setFailStateActive] = useState(false)
  // const [failStateMessage, setFailStateMessage] = useState("")
  const [passwordErrorInfoText, setPasswordErrorInfoText] = useState("")
  const [rePasswordErrorInfoText, setRePasswordErrorInfoText] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [isTokenVerified, setIsTokenVerified] = useState(false)
  const [tokenData, setTokenData] = useState(null)
  const history = useHistory();

  useEffect(() => {
    checkAndShowPasswordError();
  }, [password]);

  useEffect(() => {
    checkAndShowRePasswordError();
  }, [rePassword]);

  const handlePassword = event => {
    let value = event.target.value;
    setPassword(value);

    // Lowercase
    if ((/[a-z]/.test(value))) {
      setLowerCase(true)
    }
    else {
      setLowerCase(false)
    }

    // Uppercase
    if ((/[A-Z]/.test(value))) {
      setUpperCase(true)
    }
    else {
      setUpperCase(false)
    }

    // Number
    if ((/[0-9]/.test(value))) {
      setNumber(true)
    }
    else {
      setNumber(false)
    }

    // Special character
    if (!(/^[a-zA-Z0-9]*$/).test(value)) {
      setSpecialCharacter(true)
    }
    else {
      setSpecialCharacter(false)
    }

    // 8 minimum character
    if (value.length > 7) {
      setMinimumCharacter(true)
    }
    else {
      setMinimumCharacter(false)
    }
  }

  const handleRePasswordChange = event => {
    setRePassword(event.target.value);
  }

  const checkAndShowPasswordError = () => {
    if (passwordErrorInfoText) {
      setPasswordErrorInfoText(getPasswordFormErrorMessage());
    }
    checkAndShowRePasswordError();
  }

  const checkAndShowRePasswordError = () => {
    if (rePasswordErrorInfoText) {
      setRePasswordErrorInfoText(getRePasswordFormErrorMessage);
    }
  }

  const passwordStrengthCheck = (skipRePasswordCheck) => {
    return (
      lowerCase &&
      upperCase &&
      number &&
      specialCharacter &&
      minimumCharacter &&
      (skipRePasswordCheck || password === rePassword)
    );
  }

  const getPasswordFormErrorMessage = () => {
    if (!password || password.length <= 0) {
      return "This field is required";
    }
    else if (!passwordStrengthCheck(true)) {
      return "Password does not meet requirements";
    }
    return "";
  }

  const getRePasswordFormErrorMessage = () => {
    if (!password || password.length <= 0) {
      return "";
    }
    if (!rePassword || rePassword.length <= 0) {
      return "This field is required";
    }
    else if (password !== rePassword) {
      return "Password does not match";
    }
    return "";
  }

  const [peekPassword, setPeekPassword] = useState(false)
  const [peekRetypePassword, setPeekRetypePassword] = useState(false)
  const peekPasswordClick = () => {
    setPeekPassword(!peekPassword)
  }
  const peekRetypePasswordClick = () => {
    setPeekRetypePassword(!peekRetypePassword)
  }

  const handleSetPassword = (e) => {
    e.preventDefault();

    if (getPasswordFormErrorMessage() || getRePasswordFormErrorMessage()) {
      setPasswordErrorInfoText(getPasswordFormErrorMessage);
      setRePasswordErrorInfoText(getRePasswordFormErrorMessage);
      return;
    }

    setIsWaitForResponse(true);
    setFailStateActive(false);

    ServiceResetPassword.setPassword(password, tokenData).then(response => {
      setIsWaitForResponse(false)
      if (response.dataResult.status === 200 && response.dataResult.data.success) {
        setIsSuccessChangePassword(true);
      }
      else {
        setFailStateActive(true);
        // setFailStateMessage(response.dataResult.error.message);
      }
    })
  }

  const verifyToken = (data) => {
    setIsLoading(true);

    ServiceResetPassword.verifyToken(data).then(response => {
      setIsLoading(false);
      if (response.dataResult.status === 200 && response.dataResult.data.isValid) {
        setIsTokenVerified(true);
      }
      else {
        setIsTokenVerified(false);
        setFailStateActive(true);
        // setFailStateMessage(response.dataResult.error.message);
      }
    })
  }

  useEffect(() => {
    let qs = HelperQueryString.parseQuerystring();
    let valToken = qs["token"];

    if (valToken) {
      try {
        let tokenVerificationData = JSON.parse(
          atob(valToken)
        );

        setTokenData(tokenVerificationData)
        verifyToken(tokenVerificationData);
      }
      catch (e) {
        setIsLoading(false)
        setIsTokenVerified(false)
        setFailStateActive(true)
        // setFailStateMessage("Wrong Token Format")
      }
    } else {
      window.location.href = "/login";
    }

  }, [])

  const backToForgotPassword = () => {
    history.push('/forgot-password')
  }

  return (
    <div id="outer-container">
      <img alt="" src={PaperPlaneLeft} className="bg-img-paper-plane-left" />
      <img alt="" src={PaperPlaneRight} className="bg-img-paper-plane-right" />
      <img alt="" src={IconWhatsAppSME} className="bg-img-whatsapp-sme" />
      <img alt="" src={IconTelegram} className="bg-img-telegram" />
      <img alt="" src={IconGoogleBusinessProfile} className="bg-img-gb-profile" />
      <img alt="" src={IconGoogle} className="bg-img-google" />
      <img alt="" src={IconInstagram} className="bg-img-instagram" />
      <img alt="" src={IconFacebookMessenger} className="bg-img-fb-messenger" />
      <img alt="" src={IconMail} className="bg-img-mail" />
      <img alt="" src={IconTokopedia} className="bg-img-tokopedia" />
      <img alt="" src={IconWhatsAppBA} className="bg-img-whatsapp-ba" />

      {
        isLoading ?
        <>
          <img src={Logo} alt="TapTalk Logo" className="logo-image" />
          <p className="subtitle">Omnichannel Chat Platform</p>
          <div className="loading-wrapper-password">
            <div className="lds-ring auth-lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            Loading...
          </div>
        </>
        :
        (isSuccessChangePassword || failStateActive) ?
        <>
          <img src={Logo} alt="TapTalk Logo" className="logo-image" />
          <p className="subtitle">Omnichannel Chat Platform</p>
          <div className="failed-wrapper">
            {
              isSuccessChangePassword ?
              <>
                <div className="reset-password-success">
                  <FiCheck />
                </div>

                <div className="message-wrapper">
                  <b>Password Changed</b>
                  <p>Your password has been successfully changed.</p>
                </div>
              </>
              :
              <>
                <div className="reset-password-failed">
                  <FiX />
                </div>

                <div className="message-wrapper">
                  <b>Invalid Link</b>
                  <p>There is no reset password request found, or the code has expired. Please try again.</p>
                </div>
              </>
            }
            
            <div style={{marginTop: "32px"}}>
              <Link to={`/login${window.location.search}`}>
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-48 width-100"
                  text="Back to Log In"
                  position="left"
                  icon={{
                    type: "svg",
                    src: FiArrowLeft
                  }}
                />
              </Link>
            </div>

            {
              failStateActive &&
              <div className="forgot-password" onClick={backToForgotPassword}>
                <Link to={`/forgot-password${window.location.search}`}>
                  Forgot Password<FiExternalLink />
                </Link>
              </div>
            }
          </div>
        </>
        :
        isTokenVerified &&
        <div className="wrapper-auth-v2">
          <img src={Logo} alt="TapTalk Logo" className="logo-image" />
          <p className="subtitle">Omnichannel Chat Platform</p>
          <div className="wrapper-form-center">
            <div className="content-wrapper" style={style.heightUnset}>
              <div className="header-centered-content">
                <div className="content-title">
                  <b>Reset Password</b>
                </div>
              </div>

              <form onSubmit={(e) => handleSetPassword(e)}>
                <div className={`input-wrapper${isWaitForResponse ? `-loading`: ``}`}>
                  <label><b>New Password</b></label>
                  <input
                    type={peekPassword ? "text" : "password"}
                    id="password"
                    onChange={handlePassword}
                    placeholder="Type your password here"
                    autoComplete="false"
                    className={passwordErrorInfoText ? 'fail-state-input' : ''}
                    disabled={isWaitForResponse}
                  />
                  <div
                    className={`peek-password ${peekPassword ? 'active-eye' : ''}`}
                    onClick={peekPasswordClick}
                  >
                    {
                      peekPassword ?
                      <FiEye />
                      :
                      <FiEyeOff />
                    }
                  </div>
                  {
                    passwordErrorInfoText &&
                    <div className="input-error-info">
                      <FiAlertCircle />
                      <b>{passwordErrorInfoText}</b> 
                    </div>
                  }
                </div>

                <div className={`input-wrapper${isWaitForResponse ? `-loading`: ``} password-strength`} style={style.passwordStrength}>
                  <div className="div50">
                    <div className="checkLowerCase">
                      { 
                        isWaitForResponse ?
                        <FaCheckCircle size={16} color="#19191966" /> :
                        lowerCase ?
                        <FaCheckCircle size={16} color="#7EC82A" /> :
                        <FaRegCircle size={16} color="#A4A4A4"/>
                      }
                      One lowercase character
                    </div>
                    <div className="checkUpperCase">
                      {
                        isWaitForResponse ?
                        <FaCheckCircle size={16} color="#19191966" /> :
                        upperCase ?
                        <FaCheckCircle size={16} color="#7EC82A" /> :
                        <FaRegCircle size={16} color="#A4A4A4"/>
                      }
                      One uppercase character
                    </div>
                    <div className="checkNumber">
                      {
                        isWaitForResponse ?
                        <FaCheckCircle size={16} color="#19191966" /> :
                        number ?
                        <FaCheckCircle size={16} color="#7EC82A" /> :
                        <FaRegCircle size={16} color="#A4A4A4"/>
                      }
                      One number
                    </div>
                  </div>

                  <div className="div50">
                    <div className="checkSpecialCharacter">
                      {
                        isWaitForResponse ?
                        <FaCheckCircle size={16} color="#19191966" /> :
                        specialCharacter ?
                        <FaCheckCircle size={16} color="#7EC82A" /> :
                        <FaRegCircle size={16} color="#A4A4A4"/>
                      }
                      One special character
                    </div>
                    <div className="checkMinimumCharacter">
                      {
                        isWaitForResponse ?
                        <FaCheckCircle size={16} color="#19191966" /> :
                        minimumCharacter ?
                        <FaCheckCircle size={16} color="#7EC82A" /> :
                        <FaRegCircle size={16} color="#A4A4A4"/>
                      }
                      8 characters minimum
                    </div>
                  </div>
                </div>
                  
                <div className={`input-wrapper${isWaitForResponse ? `-loading`: ``}`}>
                  <label><b>Re-enter New Password</b></label>
                  <input
                    type={peekRetypePassword ? "text" : "password"}
                    id="rePassword"
                    onChange={handleRePasswordChange}
                    placeholder="Type your password again"
                    autoComplete="false"
                    className={rePasswordErrorInfoText ? 'fail-state-input' : ''}
                    disabled={isWaitForResponse}
                  />
                  <div
                    className={`peek-password ${peekRetypePassword ? 'active-eye' : ''}`}
                    onClick={peekRetypePasswordClick}
                  >
                    {
                      peekRetypePassword ?
                      <FiEye />
                      :
                      <FiEyeOff />
                    }
                  </div>
                  {
                    rePasswordErrorInfoText &&
                    <div className="input-error-info">
                      <FiAlertCircle />
                      <b>{rePasswordErrorInfoText}</b> 
                    </div>
                  }
                </div>

                {
                  !isWaitForResponse ?
                  <ButtonWithLoadingOrIcon
                    gtmevent={JSON.stringify(Object.assign({...HelperGTag.basicParameter("change-password")},
                      {
                        event: "action-change-password-button-click"
                      }
                    ))}
                    className="login-button main-button-48 width-100"
                    text="Change Password"
                  />
                  :
                  <ButtonWithLoadingOrIcon 
                    isLoading
                    loadingGray
                    className="gray-button-disabled main-button-48 width-100"
                    text="Change Password"
                    position="left"
                    disabled={true}
                  />
                }
              </form>
            </div>
          </div>
        </div>
      }
    </div>
  )
};

export default ResetPassword;
