import React, { useEffect, useState } from "react";
import "./IntegrationWhatsappEditAccount.scss";
// import Integrations from "../../Integrations";
import { FiInfo } from "react-icons/fi";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import "react-toastify/dist/ReactToastify.css";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";
import IntegrationWhatsappServices from "../../../../services/integration/IntegrationWhatsappServices";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const IntegrationWhatsappEditAccount = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [accountNameVal, setAccountNameVal] = useState("");
    // let [accountTopicVal, setAccountTopicVal] = useState({
    //     label: "",
    //     value: ""
    // });
    let [accountNameValOrigin, setAccountNameValOrigin] = useState("");
    // let [accountTopicValOrigin, setAccountTopicValOrigin] = useState({
    //     label: "",
    //     value: ""
    // });
    let [isLoadingDetailVal, setIsLoadingDetailVal] = useState(true);
    let [accountDetailVal, setAccountDetailVal] = useState(null);

    let [isLoadingEditAccount, setIsLoadingEditAccount] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [topicList, setTopicList] = useState([]);
    let [topicListOrigin, setTopicListOrigin] = useState([]);
    let [isCheckAll, setIsCheckAll] = useState(false);
    let [selectedTopic, setSelectedTopic] = useState([]);
    let [selectedTopicOrigin, setSelectedTopicOrigin] = useState([]);
    let [searchTopic, setSearchTopic] = useState("");
    let [errorMessageData, setErrorMessageData] = useState({
        name: "",
        topic: ""
    }) 

    const back = (id) => history.push(`/o/${orgID}/integration/whatsapp-sme/detail/${id}`);

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
            getWhatsappAccountDetail(paramID, match.params.accountID)
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        topicServices.getTopic(orgID, "list", null, (response) => {
            const data = response.dataResult.data.topics;
            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.id = val.id;
                    _data.name = val.name;
                    newData.push(_data);
                    return null;
                });
                setTopicList(newData);
                setTopicListOrigin(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if (orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    // let onSelectTopic = (value) => {
    //     setAccountTopicVal(value)
    // }

    // let isFormValid = () => {
    //     let _accountNameVal = accountNameVal;
    //     // let _accountTopicVal = accountTopicVal.value;
    //     return (
    //         _accountNameVal !== "" && 
    //         selectedTopic.length !== 0 && 
    //         (
    //             (JSON.stringify(selectedTopicOrigin) !== JSON.stringify(selectedTopic)) ||
    //             (accountNameValOrigin !== _accountNameVal)
    //         )
    //     );
    // }

    let onChangeAccountName = (e) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.name = "";
        setErrorMessageData(_errorMessageData);
        setAccountNameVal(e.target.value);
    }

    let getWhatsappAccountDetail = (orgID, accountID) => {
        setIsLoadingDetailVal(true);

        IntegrationWhatsappServices.getDetail(orgID, accountID, (response) => {
            let _retriveDataError = { ...retriveDataError };

            if (response.dataResult.error.message === "") {
                setAccountDetailVal(response.dataResult.data);
                setAccountNameVal(response.dataResult.data.channel.accountName);
                // setAccountTopicVal({
                //     label: response.dataResult.data.channel.topicName,
                //     value: response.dataResult.data.channel.topicID
                // })
                setAccountNameValOrigin(response.dataResult.data.channel.accountName);

                if(response.dataResult.data.channel.topics.length > 0) {
                    response.dataResult.data.channel.topics.map((v) => {
                        v.value = v.id;
                        return null;
                    });

                    setSelectedTopic(response.dataResult.data.channel.topics);
                    setSelectedTopicOrigin(response.dataResult.data.channel.topics);
                }

                // setAccountTopicValOrigin({
                //     label: response.dataResult.data.channel.topicName,
                //     value: response.dataResult.data.channel.topicID
                // })
            } else {
                _retriveDataError.message = response.dataResult.error.message;
                _retriveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setAccountDetailVal(null);
                // doToast(response.dataResult.error.message, "fail"); 
            }

            setIsLoadingDetailVal(false);
        })

    }

    let editWhatsappAccount = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = {...errorMessageData};

        if(accountNameVal === "") {
            _errorMessageData.name = "This field is required";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-name" }
        }

        if(accountNameVal.length > 50) {
            _errorMessageData.name = "Characters exceed limit";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-name" }
        }

        if(selectedTopic.length === 0) {
            _errorMessageData.topic = "Please select topic";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-topic" }
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

            let topics = [];

            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Button] Edit Channel",
                    {
                        channel: "WhatsApp SME",
                        userFullName: myAgentData.account.fullName,
                        userID: myAgentData.account.id,
                        organizationID: orgID.split("-")[0]
                    }
                );
            }

            setIsLoadingEditAccount(true);

            selectedTopic.map((v) => {
                topics.push(v.id);
                return null;
            })

            IntegrationWhatsappServices.edit(orgID, match.params.accountID, topics, accountNameVal, (response) => {
                setIsLoadingEditAccount(false);

                if (response.dataResult.status === 200) {
                    if (!response.dataResult.data.success) {
                        doToast(response.dataResult.data.message);
                    } else {
                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                            mixpanel.track(
                                "[Action] Edit Channel",
                                {
                                    channel: "WhatsApp SME",
                                    userFullName: myAgentData.account.fullName,
                                    userID: myAgentData.account.id,
                                    organizationID: orgID.split("-")[0]
                                }
                            );
                        }
                        setAccountNameValOrigin(accountNameVal);
                        // setAccountTopicValOrigin(accountTopicVal);
                        setSelectedTopicOrigin(selectedTopic);

                        setTimeout(() => {
                            history.push(`/o/${orgID}/integration/whatsapp-sme/detail/${match.params.accountID}`, {
                                state: "edit",
                                message: response.dataResult.data.message
                            });
                        }, 0)
                    }
                } else {
                    doToast(response.dataResult.error.message, "fail");
                }

            })
        }
    };

    let onChangeSingleSelect = (e, val) => {
        if(!isLoadingEditAccount) {
            let _errorMessageData = {...errorMessageData};
            _errorMessageData.topic = "";
            setErrorMessageData(_errorMessageData);
            let _selectedTopic = [...selectedTopic]
            if (e.target.checked) {
                _selectedTopic = [..._selectedTopic, val]
            } else {
                const _dataSelectedTopic = [..._selectedTopic]
                _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
                setIsCheckAll(false);
            }
    
            setSelectedTopic(_selectedTopic);
        }
    }

    const onClearAll = () => {
        if(!isLoadingEditAccount) {
            setIsCheckAll(false);
            setSelectedTopic([]);
        }
    }

    let onCheckAll = (e) => {
        if(!isLoadingEditAccount) {
            let _errorMessageData = {...errorMessageData};
            _errorMessageData.topic= "";
            setErrorMessageData(_errorMessageData);
            if (e.target.checked) {
                setSelectedTopic(topicList);
            } else {
                setSelectedTopic([]);
            }
    
            setIsCheckAll(e.target.checked);
        }
    }

    let isChecked = (id) => {
        const _data = [...selectedTopic];
        return _data.findIndex(val => val.id === id) !== -1;
    }

    let removeSingleTopic = (val) => {
        setIsCheckAll(false);
        let _selectedTopic = [...selectedTopic];
        const _dataSelectedTopic = [..._selectedTopic]

        _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

        setSelectedTopic(_selectedTopic);
    }

    let onChangeSearchTopic = (e) => {
        let _topicOrigin = topicListOrigin.slice();
        let result = [];
        setSearchTopic(e.target.value);

        if (_topicOrigin.length > 0) {
            for (let i in _topicOrigin) {
            let objectKey = Object.keys(_topicOrigin[i]);

            for (let j in objectKey) {
                let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
                if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
                result.push(_topicOrigin[i])
                break;
                }
            }
            }
        }

        setTopicList(result);
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={{
                    name: accountNameValOrigin,
                    topic: JSON.stringify(selectedTopicOrigin)
                }}
                dataAfterChange={{
                    name: accountNameVal,
                    topic: JSON.stringify(selectedTopic)
                }}
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

                <div className="sectionWrap integration-main-container integration-whatsapp-edit-account text-left">
                    {isLoadingDetailVal || retriveDataError.code ?
                        <div className="integration-detail-loading-wrapper">
                            {/* <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div> */}

                            <RetrieveDataLoading
                                isLoading={isLoadingDetailVal}
                                errorMessage={retriveDataError.message}
                            />
                        </div>
                        :
                        <React.Fragment>
                            <BackButton text={`Back to ${accountDetailVal.channel.accountName}`} onClick={() => back(accountDetailVal.channel.id)} />

                            <div className="integration-whatsapp-edit-account-box">
                                <div className="integration-whatsapp-edit-account-box-top">
                                    <b>Edit</b>

                                    {!isLoadingEditAccount ?
                                        <ButtonWithLoadingOrIcon
                                            className="orange-button main-button-40"
                                            onClickAction={() => editWhatsappAccount()}
                                            text="Save Changes"
                                            // isDisabled={!isFormValid()}
                                        />
                                        :
                                        <ButtonWithLoadingOrIcon
                                            isLoading
                                            loadingColor="gray"
                                            isDisabled
                                            className="orange-button main-button-40"
                                            text="Save Changes"
                                            position="left"
                                        />
                                    }
                                </div>

                                <div className="integration-whatsapp-edit-account-box-form">
                                    <label>
                                        <span className={errorMessageData.name === "Characters exceed limit" ? "red-text" : "grey-font"}>{accountNameVal.length}/50</span>
                                        <b>Account Name</b>
                                    </label>
                                    <input className={`input-name ${errorMessageData.name !== "" ? "border-red" : ""}`} disabled={isLoadingEditAccount} type="text" placeholder="Your WhatsApp account name" value={accountNameVal} onChange={(e) => { onChangeAccountName(e) }} />
                                    
                                    <ErrorOrInfoComp
                                        text={errorMessageData.name === "" ? "Account Name is used to differentiate the various accounts linked to this particular channel." : errorMessageData.name}
                                        _className={errorMessageData.name !== "" ? "font-red" : ""}
                                        icon={<FiInfo />}
                                    />
                                    
                                    {/* <span className="integration-info-form">
                                        
                                    </span> */}

                                    {/* <label><b>Topic</b></label>
                                    <CustomSelectOption
                                        optionListProps={topicList}
                                        valueDropdownProps={accountTopicVal}
                                        placeholderProps={"Select account topic"}
                                        onClickDropDownListOptionProps={onSelectTopic}
                                        specificClassNameProps="custom-select-whatsapp-topic"
                                    />
                                    <span className="integration-info-form">
                                        Incoming messages on this channel will be assigned to this topic.
                                    </span> */}

                                    <SelectTopic 
                                        onChangeSearchTopic={onChangeSearchTopic}
                                        onCheckAll={onCheckAll}
                                        onClearAll={onClearAll}
                                        topicList={topicList}
                                        onChangeSingleSelect={onChangeSingleSelect}
                                        isChecked={isChecked}
                                        isCheckAll={isCheckAll}
                                        searchTopic={searchTopic}
                                        title={"Topic"}
                                        selectedTopic={selectedTopic}
                                        removeSingleTopic={removeSingleTopic}
                                        isDisabled={isLoadingEditAccount}
                                        _className={errorMessageData.topic !== "" ? "input-topic border-red" : "input-topic"}
                                    />

                                    {errorMessageData.topic !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.topic === "" ? "Customer will be able to choose if you select more than one topics." : errorMessageData.topic}
                                            _className={errorMessageData.topic !== "" ? "font-red" : ""}
                                            icon={<FiInfo />}
                                        />
                                    }

                                    {/* <p className="input-info-text no-margin-bottom width-100">
                                        <FiInfo />
                                        <b>Customer will be able to choose if you select more than one topics.</b>
                                    </p> */}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationWhatsappEditAccount;