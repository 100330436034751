import React, { useState, useEffect } from 'react';
import './ChatRoomCaseHistoryModal.css';
import ChatRoomCaseHistoryModalHeader from './chatRoomCaseHistoryModalHeader/ChatRoomCaseHistoryModalHeader';
import ChatRoomCaseHistoryModalMainChat from './chatRoomCaseHistoryModalMainChat/ChatRoomCaseHistoryModalMainChat';
import ChatRoomCaseHistoryCaseDetail from './chatRoomCaseHistoryModalCaseInfo/ChatRoomCaseHistoryCaseDetail';
import { connect } from 'react-redux';

const MESSAGE_TYPE = {
  CASE_CREATED: 3005,
  CASE_CLOSED: 3001,
  CASE_REOPEN: 3002,
  CASE_RATING_BUTTON: 3003,
  CASE_RATING_BUTTON_DISABLED: 3004,
  CASE_UPDATE_AGENT_CHANGE: 3006,
  CASE_UPDATE_DETAIL_UPDATE: 3007
}

var ChatRoomCaseHistoryModal = (props) => {
  let [isUserDetailShow, setIsUserDetailShow] = useState(true);
  let [caseHistoryPopupData, setCaseHistoryPopupData] = useState(null);

  let toggleIsUserDetailShowAction = () => {
    setIsUserDetailShow(!isUserDetailShow);
  }

  useEffect(() => {
    if(props.caseHistoryPopupDataProps !== null) {
      setCaseHistoryPopupData(props.caseHistoryPopupDataProps)
    }
  }, [props.caseHistoryPopupDataProps])

  useEffect(() => {
    let listenerNewMessage = props.messageListenerNewMessageProps === null ? null : props.messageListenerNewMessageProps;

    if(props.activeRoom !== null && props.isShowModalCaseHistoryProps && caseHistoryPopupData !== null) {

      if(listenerNewMessage !== null && (listenerNewMessage.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE || listenerNewMessage.type === MESSAGE_TYPE.CASE_UPDATE_DETAIL_UPDATE|| listenerNewMessage.type === MESSAGE_TYPE.CASE_CLOSED)) {
        
        if(listenerNewMessage.data.id === caseHistoryPopupData.case.id) {
            let _caseHistoryPopupData = {...caseHistoryPopupData};
            _caseHistoryPopupData.case = listenerNewMessage.data; 
            setCaseHistoryPopupData(_caseHistoryPopupData)
        }
        
      }
      
    }
  }, [props.messageListenerNewMessageProps]);
  
  return (
    // caseHistoryPopupData !== null &&
      <div className={`chat-room-case-history-modal-wrapper ${props.isShowModalCaseHistoryProps ? 'active-chat-room-case-history-modal-wrapper' : ''}`}>
          <div className={`chat-room-case-history-modal-content ${props.isShowModalCaseHistoryProps ? 'active-chat-room-case-history-modal-content' : ''}`}>
            <ChatRoomCaseHistoryModalHeader isUserDetailShowProps={isUserDetailShow} 
                                            toggleIsUserDetailShowActionProps={toggleIsUserDetailShowAction} 
                                            toggleCaseModalHistoryProps={props.toggleCaseModalHistoryProps}
                                            caseHistoryPopupDataProps={caseHistoryPopupData}
            />

            <ChatRoomCaseHistoryModalMainChat isUserDetailShowProps={isUserDetailShow} 
                                              caseHistoryPopupDataProps={caseHistoryPopupData}
                                              isShowModalCaseHistoryProps={props.isShowModalCaseHistoryProps}
                                              messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                                              messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
            />

            <ChatRoomCaseHistoryCaseDetail isUserDetailShowProps={isUserDetailShow}
                                          caseHistoryPopupDataProps={caseHistoryPopupData}
                                          isShowModalCaseHistoryProps={props.isShowModalCaseHistoryProps}
                                          messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                                          messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                                          parentProps={props.parentProps}
            />
          </div>
      </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomCaseHistoryModal);
