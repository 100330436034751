const INITIAL_STATE = [];

let reduxReducerAllTopics = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ALL_TOPIC':
        return action.allTopicList;
      case 'CLEAR_ALL_TOPIC':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerAllTopics;