import React from 'react';
import './NumberDropdown.scss';
import { FiChevronDown, FiCheck } from 'react-icons/fi';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const NumberDropdown = (props) => {
    let {
        isOpenDropdown, 
        toggleDropdown,
        numbersArray,
        selectedNumber,
        onNumberSelected,
        isDisabled
    } = props;

    let onClickNumber = (val) => {
        if (val === selectedNumber) {
            return;
        }
        onNumberSelected(val);
    }

    return (
        <>
            <Dropdown 
                isOpen={isOpenDropdown} 
                toggle={!isDisabled ? toggleDropdown : null} 
                className={`number-dropdown ${isDisabled ? "disabled-number-dropdown" : ""}`} 
                isDisabled={isDisabled}
            >
                <DropdownToggle>
                    {selectedNumber}
                    <FiChevronDown className={`icon-caret ${isOpenDropdown ? "icon-caret-open" : ""}`} />
                </DropdownToggle>
                <DropdownMenu>
                    {numbersArray.map((v) => {
                        return (
                            <DropdownItem
                                key={`selection-${v}`}
                                className={`${selectedNumber === v ? "active" : ""}`} 
                                onClick={() => onClickNumber(v)}
                            >
                                {selectedNumber === v &&
                                    <FiCheck />
                                }
                                {v}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default NumberDropdown;
