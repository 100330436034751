import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const ClosingMessageServicesServices = {
  getClosingMessage(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CLOSING, {}, headers, callback);  
  },

  setClosingMessage(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);  
  },
};

const API = {
  SET_CLOSING: "/organization/setup/closing_message/set",
  GET_CLOSING: "/organization/setup/closing_message/get",
}

export default ClosingMessageServicesServices;
