import React, { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";
import "./IntegrationTokopediaAdd.scss";
import "react-toastify/dist/ReactToastify.css";
// import Integrations from "../../Integrations";
import HelperInput from "../../../../helper/HelperInput";
import Icon1 from "../../../../assets/img/integration/tokopedia/icon1.svg";
import Icon2 from "../../../../assets/img/integration/tokopedia/icon2.svg";
import Icon3 from "../../../../assets/img/integration/tokopedia/icon3.svg";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";
import IntegrationTokopediaServices from "../../../../services/integration/IntegrationTokopediaServices";

const IntegrationTokopediaAdd = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [topicList, setTopicList] = useState([]);
    let [shopUrlVal, setShopUrlVal] = useState("");
    let [accountTopicVal, setAccountTopicVal] = useState({
        label: "",
        value: ""
    });
    let [shopUrlValOrigin, setShopUrlValOrigin] = useState("");
    let [accountTopicValOrigin, setAccountTopicValOrigin] = useState({
        label: "",
        value: ""
    });
    let [errorMessageData, setErrorMessageData] = useState({
        url: "",
        topic: ""
    })
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const back = () => history.push(`/o/${orgID}/integration/tokopedia`);

    let onSubmitClick = () => {
       let _errorMessageData = {...errorMessageData};
       let errorCount = 0;
       let errorClass = "";

       if(shopUrlVal.length < 4) {
        errorCount = errorCount + 1;
        _errorMessageData.url = "Minimum 3 character";

        errorClass = "input-url";
       }

       if(shopUrlVal.length === 0) {
        errorCount = errorCount + 1;
        _errorMessageData.url = "This field is required";

        errorClass = "input-url";
       }

       if(shopUrlVal.length > 24) {
        errorCount = errorCount + 1;
        _errorMessageData.url = "Characters exceed limit";

        errorClass = "input-url";
       }

       if(shopUrlVal.length > 24) {
        errorCount = errorCount + 1;
        _errorMessageData.url = "Characters exceed limit";

        errorClass = "input-url";
       }

       if(accountTopicVal.value === "") {
        errorCount = errorCount + 1;
        _errorMessageData.topic = "Please select topic";

        errorClass = "input-topic";
       }

       if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
       }else {
        setIsLoadingSubmit(true);

        IntegrationTokopediaServices.create(orgID, {topicID: accountTopicVal.value, shopDomain: shopUrlVal}, (res) => {
            let _res = res.dataResult;

            if(_res.error.code === "") {
                if(_res.data.success) {
                    doToast(_res.data.message);
                    setShopUrlValOrigin(shopUrlVal);
                    setAccountTopicValOrigin(accountTopicVal);
                    
                    setTimeout(() => {
                        props.history.push(`/o/${orgID}/integration/tokopedia/detail/${_res.data.id}`);
                    }, 3000)
                }else {
                    doToast(_res.data.message, "fail");
                    setIsLoadingSubmit(false);
                }
            }else {
                doToast(_res.error.message, "fail");
                setIsLoadingSubmit(false);

                if(_res.error.code === "40002") {
                    let _errorMessageData = {...errorMessageData};
                    _errorMessageData.url = _res.error.message;
                    setErrorMessageData(_errorMessageData);
                }
            }
        })
       }
    };

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        topicServices.getTopic(orgID, "list", null, (response) => {
            const data = response.dataResult.data.topics;
            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.value = val.id;
                    _data.label = val.name;
                    newData.push(_data);
                    return null;
                });
                setTopicList(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if(orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    let onSelectTopic = (value) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.topic = "";
        setErrorMessageData(_errorMessageData);
        setAccountTopicVal(value)
    }

    // let isFormValid = () => {
    //     let _shopUrlVal = shopUrlVal;
    //     let _accountTopicVal = accountTopicVal.value;
    //     return (_shopUrlVal !== "" && _accountTopicVal !== "");
    // }

    let onChangeDomain= (e) => {
        let _errorMessageData = {...errorMessageData};

        if(HelperInput.alphanumericAndHypen(e.target.value) && e.target.value !== "") {
            _errorMessageData.url = "";
            setErrorMessageData(_errorMessageData);
            setShopUrlVal(e.target.value);
        }

        if(e.target.value === "") {
            _errorMessageData.url = "";
            setErrorMessageData(_errorMessageData);
            setShopUrlVal(e.target.value);
        }
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={{
                    name: shopUrlValOrigin,
                    topic: accountTopicValOrigin
                }}
                dataAfterChange={{
                    name: shopUrlVal,
                    topic: accountTopicVal
                }}
            >
                <div className="sectionWrap integration-main-container integration-add-account text-left">
                    <BackButton text="Back to Tokopedia" onClick={back} />

                    <div className="integration-add-account-box">
                        <div className="integration-add-account-box-top">
                            <b>Add Channel</b>
                            
                            {isLoadingSubmit ?
                                <ButtonWithLoadingOrIcon 
                                    // isDisabled={!isFormValid()} 
                                    isLoading
                                    position="left"
                                    loadingColor="gray"
                                    className="orange-button main-button-40"
                                    text="Save Changes"
                                    isDisabled
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    // isDisabled={!isFormValid()} 
                                    onClickAction={onSubmitClick}
                                    className="orange-button main-button-40"
                                    text="Save Changes"
                                    position="left"
                                />
                            }
                        </div>

                        <div className="integration-add-account-box-form">
                            <ul>
                                <li>
                                    Only Power Merchants 
                                    <img src={Icon1} alt="" />
                                    <img src={Icon2} alt="" />
                                    and Official Stores 
                                    <img src={Icon3} alt="" />
                                    are eligible for registration.
                                </li>
                                <li>
                                    Make sure your Tokopedia shop is not connected to other 3rd party applications.
                                </li>
                            </ul>

                            <div className="width-455">
                                <label><b>Shop URL</b></label>
                                <span className={`grey-font ${errorMessageData.url === "Characters exceed limit" ? "red-text" : ""}`}>
                                    ({shopUrlVal.length}/24)
                                </span>
                                <p>
                                    https://www.tokopedia.com/
                                </p>
                                <input disabled={isLoadingSubmit} className={errorMessageData.url !== "" ? "border-red" : ""} type="text" placeholder="shopdomain" onChange={(e) => {onChangeDomain(e)}} value={shopUrlVal} />
                                {errorMessageData.url !== "" &&
                                    <ErrorOrInfoComp 
                                        _className={`red-text margin-top-0`}
                                        icon={<FiInfo />}
                                        text={errorMessageData.url}
                                    />
                                }
                            </div>

                            <div className="width-320">
                                <label><b>Topic</b></label>
                                <CustomSelectOption
                                    optionListProps={topicList}
                                    valueDropdownProps={accountTopicVal}
                                    placeholderProps={"Select topic"}
                                    onClickDropDownListOptionProps={onSelectTopic}
                                    specificClassNameProps="custom-select-whatsapp-topic"
                                    _className={`${errorMessageData.topic !== "" ? "border-red input-topic" : "input-topic"}`}
                                    isDisabled={isLoadingSubmit}
                                />
                                <ErrorOrInfoComp 
                                    _className={`${errorMessageData.topic !== "" ? "red-text" : ""} margin-top-0`}
                                    icon={<FiInfo />}
                                    text={`${errorMessageData.topic !== "" ? errorMessageData.topic : "Incoming messages on this channel will be assigned to this topic."}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationTokopediaAdd;