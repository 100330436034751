const INITIAL_STATE = {};

let reducerAuth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_AUTH':
        return action.authData;
      case 'CLEAR_ACTIVE_AUTH':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerAuth;