import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./IntegrationWhatsappBusiness.scss";
import { connect } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { FiArrowUpRight } from "react-icons/fi";
// import IconAdd from "../../../assets/img/icon-add.svg";
import WhatsApp32 from "../../../assets/img/integration/logo-whatsapp-business-api-32.svg";
import CheckGreenLogo from "../../../assets/img/integration/integration-check-list-green.svg";
import iconInfoCircleOrange from "../../../assets/img/icon-info-circle-orange.svg";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SearchBox from "../../reuseableComponent/searchBox/SearchBox";
import PopupInfo from "../../reuseableComponent/popupInfo/PopupInfo";
import FilterStatusDropdown from "../../reuseableComponent/FilterStatusDropdown/FilterStatusDropdown";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import IntegrationWhatsappBusinessServices from "../../../services/integration/IntegrationWhatsappBusinessServices";
// import Integrations from "../Integrations";
import mixpanel from "mixpanel-browser";
import HelperDate from "../../../helper/HelperDate";

const IntegrationWhatsappBusiness = props => {
    const FILTER_STATUS = [
        {
            value: "not_paid",
            label: "Not Paid"
        },
        {
            value: "waiting_setup",
            label: "Waiting Setup"
        },
        {
            value: "active",
            label: "Active"
        },
        {
            value: "error",
            label: "Error"
        }
    ]

    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingWhatsappAccount, setIsLoadingWhatsappAccount] = useState(true);
    let [whatsappAccountData, setWhatsappAccountData] = useState([]);
    let [showPopupWarning, setShowPopupWarning] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [hasOngoingRequest, setHasOngoingRequestStatus] = useState(null);
    let [isShowModalUnderReview, setIsShowModalUnderReview] = useState(false);
    let [query, setQuery] = useState("");
    let [submitQuery, setSubmitQuery] = useState("");
    let [showFilterStatus, setShowFilterStatus] = useState(false);
    let [filterVal, setFilterVal] = useState({ value: "all", label: "All Status" });
    let [isShowResultFound, setIsShowResultFound] = useState(false);

    let toggleFilterStatus = () => {
        setShowFilterStatus(!showFilterStatus);
    }

    let toggleModalUnderReview = () => {
        setIsShowModalUnderReview(!isShowModalUnderReview);
    }

    let toggleShowWarning = () => {
        setShowPopupWarning(!showPopupWarning);
    }

    let getWhatsappAccount = (_data = false) => {
        setWhatsappAccountData([]);
        let _retriveDataError = { ...retriveDataError };
        setIsLoadingWhatsappAccount(true);

        let data = {
            value: "",
            label: ""
        }

        if (_data) {
            data = _data;
        } else {
            data = {
                search: submitQuery,
                channelStatus: filterVal.value
            }
        }

        data.channelStatus = data.channelStatus === "all" ? "" : data.channelStatus;

        if (data.channelStatus === "" && data.search === "") {
            setIsShowResultFound(false);
        } else {
            setIsShowResultFound(true);
        }

        IntegrationWhatsappBusinessServices.getList(orgID, data, (response) => {
            let dataResult = response.dataResult;

            if (response.dataResult.status === 200) {
                setWhatsappAccountData(response.dataResult.data.channels);

                if (data.query === "" && data.channelStatus === "") {
                    if (response.dataResult.data.channels.length === 0 && !hasOngoingRequest) {
                        history.push({
                            pathname: `/o/${orgID}/integration/whatsapp-business-api/add`,
                            state: { hasNoBackButton: true }
                        })
                    }
                }
            } else {
                setWhatsappAccountData([]);
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsLoadingWhatsappAccount(false);
        })
    }

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
    }, []);

    // let [loadingRequestStatus, setLoadingRequestStatus] = useState(false)
    let [requestTime, setRequestTime] = useState(false)

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (orgID) {
            if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Page] WhatsApp Business API Integration",
                    {
                        userFullName: myAgentData.account.fullName,
                        userID: myAgentData.account.id,
                        organizationID: orgID.split("-")[0]
                    }
                );
            }

            let _retriveDataError = {...retriveDataError};
            // setLoadingRequestStatus(true)
            IntegrationWhatsappBusinessServices.getRequestStatus(
                orgID,
                (response) => {
                    if (response.dataResult.error.message === "") {
                        let responseData = response.dataResult.data;
                        setHasOngoingRequestStatus(responseData.hasOngoing)
                        setRequestTime(responseData.requestTime)
                    } else {
                        _retriveDataError.message = response.dataResult.error.message;
                        _retriveDataError.code = response.dataResult.error.code;
                        setRetrieveDataError(_retriveDataError);
                        setIsLoadingWhatsappAccount(false);
                        doToast(response.dataResult.error.message, "fail");
                    }
                    // setLoadingRequestStatus(false)
                    // getWhatsappAccount(orgID);
                }
            )
        }
    }, [orgID])

    useEffect(() => {
        if (hasOngoingRequest !== null) {
            getWhatsappAccount();
        }
    }, [hasOngoingRequest])

    let goToAdd = () => {
        // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        // mixpanel.track(
        //     "[Button] Add Channel",
        //     {
        //         channel: "WhatsApp Business API",
        //         userFullName : myAgentData.account.fullName,
        //         userID : myAgentData.account.id,
        //         organizationID : orgID.split("-")[0]
        //     }
        // );

        if (!props.mediumData) {
            toggleShowWarning();
        } else {
            if (props.mediumData.numberOfItems.maximum !== -1 && props.mediumData.numberOfItems.current >= props.mediumData.numberOfItems.maximum) {
                toggleShowWarning();
            } else {
                history.push(`/o/${orgID}/integration/whatsapp-business-api/add`);
            }
        }
    };

    let onChangeSearch = (e) => {
        setQuery(e);
    }

    let onSubmitSearch = (e) => {
        setSubmitQuery(query);
        getWhatsappAccount({ search: query, channelStatus: filterVal.value });
    }

    let onClearSearch = () => {
        setSubmitQuery("");
        getWhatsappAccount({ search: "", channelStatus: filterVal.value });
    }

    let changeFilterStatus = (v) => {
        setFilterVal(v);
        toggleFilterStatus();

        getWhatsappAccount({ search: query, channelStatus: v.value });
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >

                {(!isLoadingWhatsappAccount && whatsappAccountData.length <= 0 && hasOngoingRequest && submitQuery === "" && filterVal.value === "all") ?
                    <div className="integration-has-request-and-no-channels">
                        <img src={CheckGreenLogo} alt="" />
                        <div>
                            <b>{`Registration submitted on ${HelperDate.formatToString(new Date(requestTime), "dd/MM/yyyy")}!`}</b>
                            <p>
                                We will contact you through email or phone once your submission has been approved.
                            </p>
                        </div>
                    </div>
                    :
                    <>
                        {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}
                        <PopupWarning
                            isShow={showPopupWarning}
                            toggle={toggleShowWarning}
                            text="Maximum number of allowed channels has been reached"
                        />

                        <PopupInfo
                            isOpen={isShowModalUnderReview}
                            toggle={toggleModalUnderReview}
                            titleImage={iconInfoCircleOrange}
                            title={`Current submission is under review`}
                            description={`Your last registration was submitted on <b>01/04/2020.</b> We will get back to you in 1 - 2 days after your submission.`}
                            textButton={`Dismiss`}
                            actionButton={toggleModalUnderReview}
                        />

                        <div className="sectionWrap integration-main-container integration-whatsapp-business-list text-left">
                            <React.Fragment>
                                <div className="integration-list-header">
                                    <img src={WhatsApp32} alt="" className="integration-list-header-icon" />

                                    <div className="integration-list-header-text-wrapper">
                                        <b>WhatsApp Business API</b>
                                        {/* <p>
                                                To Whatsapp, <a href="#">please read the documentation.</a>
                                            </p> */}
                                    </div>

                                    {(!isLoadingWhatsappAccount && !retriveDataError.code) &&
                                        (hasOngoingRequest ?
                                            <ButtonWithLoadingOrIcon
                                                className="no-fill-button integration-list-header-button main-button-40"
                                                text={`Last registration submitted on ${HelperDate.formatToString(new Date(requestTime), "dd/MM/yyyy")}`}
                                                isDisabled
                                            />
                                            :
                                            <ButtonWithLoadingOrIcon
                                                className="no-fill-button integration-list-header-button main-button-40"
                                                text="Register to Add Channel"
                                                onClickAction={goToAdd}
                                            />
                                        )
                                    }
                                </div>

                                {!retriveDataError.code &&
                                    <div className="search-and-filter-wrapper">
                                        <SearchBox
                                            onSubmitSearch={onSubmitSearch}
                                            onChangeInputSearch={onChangeSearch}
                                            searchValue={query}
                                            placeholder="Search for a channel name or channel ID"
                                            isWaitResponse={isLoadingWhatsappAccount}
                                            onClearSearch={onClearSearch}
                                        />

                                        <FilterStatusDropdown
                                            isOpen={showFilterStatus}
                                            toggle={toggleFilterStatus}
                                            isLoading={isLoadingWhatsappAccount}
                                            filterList={FILTER_STATUS}
                                            changeFilter={(v) => {
                                                changeFilterStatus(v);
                                            }}
                                            value={filterVal}
                                        />
                                    </div>
                                }

                                {(
                                    whatsappAccountData.length > 0 &&
                                    isShowResultFound
                                ) &&
                                    <p className="filter-result-found">
                                        <b>{whatsappAccountData.length} result{whatsappAccountData.length > 1 ? "s" : ""} found</b>
                                    </p>
                                }

                                {(isLoadingWhatsappAccount || retriveDataError.code) ?
                                    <RetrieveDataLoading
                                        isLoading={isLoadingWhatsappAccount}
                                        errorMessage={retriveDataError.message}
                                    />
                                    :
                                    whatsappAccountData.length > 0 ?
                                        <>
                                            <div className="integration-list-outer-wrapper">
                                                {whatsappAccountData.map((value, index) => {
                                                    return (
                                                        <Link key={`whatsapp-account-${index}`}
                                                            to={`/o/${orgID}/integration/whatsapp-business-api/detail/${value.id}`}
                                                            className="link-whatsapp-account-list"
                                                        >
                                                            <div className="integration-list-inner-wrapper">
                                                                <FiArrowUpRight />
                                                                <p>
                                                                    <b>{value.accountName}</b>
                                                                </p>

                                                                <span>{value.stringID}</span>

                                                                <p className="font-root-medium">
                                                                    {value.channelStatusText}
                                                                </p>

                                                                <p className={`font-root-medium ${value.isChannelLinkEnabled ? "font-green" : "font-grey"}`}>
                                                                    Channel Button for Live Chat is {!value.isChannelLinkEnabled ? "disabled" : "enabled"}
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    )
                                                })}
                                            </div>
                                        </>
                                        :
                                        (submitQuery !== "" || filterVal.value !== "all" ?
                                            <div className="integration-list-no-data">
                                                <b className="grey-font">No channels found</b>
                                            </div>
                                            :
                                            <div className="integration-list-no-data">
                                                <b>No Channel</b>
                                                <p>
                                                    To add a channel, simply click the "+ Add Channel" button on the top right.
                                                </p>
                                            </div>
                                        )
                                }
                            </React.Fragment>
                        </div>
                    </>
                }
            </SectionWrap>
        </div>)
}

const mapStateToProps = state => ({
    mediumData: state.mediumData
});

export default connect(mapStateToProps, null)(IntegrationWhatsappBusiness);
