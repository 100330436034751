import React, { useEffect, useState } from "react";
import "../../auth/Auth.css";
import "./RolesEdit.scss";
import { FiInfo } from "react-icons/fi";
import { checkID, doToast, scrollToClass } from "../../../helper/HelperGeneral";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
// import MemberService from "../../../services/newServices/MemberServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceGroupRole from "../../../services/organization/ServiceGroupRole";

let RolesEdit = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [isWaitResponseEditRole, setIsWaitResponseEditRole] = useState(false);
  let [moduleList, setModuleList] = useState([]);
  let [data, setData] = useState({
    name: "",
    description: "",
    roles: []
  })
  let [dataOrigin, setDataOrigin] = useState({
    name: "",
    description: "",
    roles: []
  })
  let [errorMessageData, setErrorMessageData] = useState({
    name: "",
    description: "",
    roles: ""
  })
  let [loadingGet, setLoadingGet] = useState(true)
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })

  let back = () => {
    if (!isWaitResponseEditRole) {
      history.push(`/o/${orgID}/organization/roles/detail/${props.match.params.roleID}`);
    }
  }

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if (orgID) {
      getModule();
    }
  }, [orgID])

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    setDataOrigin({ ...data })
  }, [])

  let onChangeText = (e) => {
    let _errorMessageData = { ...errorMessageData };
    let _data = { ...data };
    _data[e.target.id] = e.target.value;

    _errorMessageData[e.target.id] = "";

    if (e.target.id === "name") {
      if (e.target.value.length > 50) {
        _errorMessageData.name = "Characters exceed limit"
      }
    }

    if (e.target.id === "description") {
      if (e.target.value.length > 200) {
        _errorMessageData.description = "Characters exceed limit"
      }
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  };

  let onSelectRole = (isParent, code, e) => {
    let _errorMessageData = { ...errorMessageData };
    let _data = { ...data };
    let indexParent = moduleList.findIndex(v => v.code === code);

    if (isParent) {
      if (e.target.checked) {
        if(moduleList[indexParent].childs) {
          moduleList[indexParent].childs.map((v) => {
            if(_data.roles.findIndex(_v => _v === v.code) === -1) {
              _data.roles.push(v.code);
            }

            return null;
          })
        }
      } else {
        if(moduleList[indexParent].childs) {
          moduleList[indexParent].childs.map((v) => {
            if(_data.roles.findIndex(_v => _v === v.code) !== -1) {
              _data.roles.splice(_data.roles.findIndex(_v => _v === v.code), 1);
            }

            return null;
          })
        }
      }
    } else {
    }

    if (e.target.checked) {
      _data.roles.push(code);
    } else {
      let findIndex = data.roles.findIndex(v => v === code);
    
      _data.roles.splice(findIndex, 1);
    }

    _errorMessageData.roles = "";
    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let isChecked = (code) => {
    let checked = false;
    let isCodeChild = code.includes(":");

    if (data.roles.findIndex(v => v === code) !== -1) {
      checked = true;
    }
    
    if(isCodeChild) {
      if(data.roles.findIndex(v => v === code.split(":")[0]) !== -1) {
        checked = true;
      }
    }
  
    return checked;
  }

  let getModule = () => {
    setLoadingGet(true);
    let _retriveDataError = { ...retriveDataError };

    ServiceGroupRole.postGetModule(orgID, (res) => {
      let dataResult = res.dataResult;
      let modules = dataResult.data.modules.slice();
      let newModule = [];

      if (dataResult.error.message === "") {
        if (modules.length > 0) {
          modules.map((v) => {
            if (!v.code.includes(":")) {
              newModule.push(v);
            } else {
              let findIdx = newModule.findIndex(_v => _v.code === v.code.split(":")[0]);
              if (findIdx !== -1) {
                if (!newModule[findIdx].childs) {
                  newModule[findIdx].childs = [];
                }

                newModule[findIdx].childs.push(v);
              }
            }
            return null;
          })
        }
        setModuleList(newModule);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      getRolesDetail();
    })
  }

  let getRolesDetail = () => {
    let _retriveDataError = { ...retriveDataError };

    let data = {
      id: parseInt(props.match.params.roleID)
    };

    ServiceGroupRole.postGetGroupRoleDetail(orgID, data, (res) => {
      let dataResult = res.dataResult;
      
      if (dataResult.error.message === "") {
        let newRole = [];
        let _data = {...dataResult.data};

        _data.grantedModules.map((v) => {
          newRole.push(v.code);

          return null;
        })

        setData({
          name: _data.role.name,
          description: _data.role.description,
          roles: newRole
        });

        setDataOrigin({
          name: _data.role.name,
          description: _data.role.description,
          roles: newRole
        })

        setLoadingGet(false);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    })
  }

  let onSubmitEditRole = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    if (data.name === "") {
      _errorMessageData.name = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-name" }
    }

    if (data.description === "") {
      _errorMessageData.description = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-desc" }
    }

    if (data.name.length > 50) {
      _errorMessageData.name = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-name" }
    }

    if (data.description.length > 200) {
      _errorMessageData.description = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-desc" }
    }

    if (data.roles.length === 0) {
      _errorMessageData.roles = "Please select roles";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-role" }
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      setIsWaitResponseEditRole(true);

      //   let _selectedTopic = [...selectedTopic]
      //   let listTopicID = _selectedTopic.map(item => item.id)

      let _data = {
        "id": parseInt(props.match.params.roleID),
        "name": data.name,
        "description": data.description,
        "modules": data.roles
      };

      ServiceGroupRole.postEditGroupRole(orgID, _data, (response) => {
        if (response.dataResult.error.message === "") {
          if (response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
            setDataOrigin(data);

            setTimeout(() => {
              back();
            }, 3000)
          } else {
            setIsWaitResponseEditRole(false);
            doToast(response.dataResult.data.message, 'fail');
          }

        } else {
          doToast(response.dataResult.error.message, 'fail');
          setIsWaitResponseEditRole(false);
        }
      });
    }
  }

  let isParentChecked = (code) => {
    let checked = false;

    if(data.roles.findIndex(v => v === code) !== -1) {
      checked = true;
    }

    return checked;
  }

  return (
    <>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >
        <BackButton text="Back to Role Details" onClick={back} />

        <div className="sectionWrap create-role-wrapper">
          {loadingGet || retriveDataError.code ?
            <RetrieveDataLoading
              isLoading={loadingGet}
              errorMessage={retriveDataError.message}
            />
            :
            <>
              <div className="form-title-wrapper">
                <b>Edit Role</b>

                {!isWaitResponseEditRole ?
                  <ButtonWithLoadingOrIcon
                    text="Save Changes"
                    onClickAction={onSubmitEditRole}
                    className="main-button-40 orange-button"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    text="Save Changes"
                    className="main-button-40 orange-button"
                    isLoading
                    isDisabled
                    position="left"
                    loadingColor="gray"
                  />
                }
              </div>

              <div className="form-content-wrapper form-roles-create">
                <label className="pos-relative">
                  <b>Name</b>
                  <span className={errorMessageData.name === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{data.name.length}/50 characters</span>
                </label>
                <input
                  disabled={isWaitResponseEditRole}
                  id="name"
                  className={`input-nane ${errorMessageData.name !== "" ? "border-red" : ""}`}
                  type="text"
                  placeholder="Type name here"
                  value={data.name}
                  onChange={(e) => {
                    onChangeText(e);
                  }}
                />

                {errorMessageData.name !== "" &&
                  <ErrorOrInfoComp
                    text={errorMessageData.name}
                    _className={"font-red"}
                    icon={<FiInfo />}
                  />
                }

                <label className="pos-relative">
                  <b>Description</b>
                  <span className={errorMessageData.description === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{data.description.length}/200 characters</span>
                </label>
                <textarea
                  rows="5"
                  disabled={isWaitResponseEditRole}
                  id="description"
                  className={`input-desc ${errorMessageData.description !== "" ? "border-red" : ""}`}
                  placeholder="Type description here"
                  value={data.description}
                  onChange={(e) => {
                    onChangeText(e);
                  }}
                />

                {errorMessageData.description &&
                  <ErrorOrInfoComp
                    text={errorMessageData.description}
                    _className={errorMessageData.description === "" ? "no-margin" : "font-red no-margin"}
                    icon={<FiInfo />}
                  />
                }

                <label className="input-role no-margin-bottom"><b>Permissions</b></label>

                <p className="role-info">
                  Customize permission for this role.
                </p>

                <p className="role-info role-info-24">
                  Check parent permissions to include children permissions.
                </p>

                <div className="role-list-wrapper custom-checkbox">
                  {moduleList.map((v, i) => {
                    return (
                      <>
                        <div className="main-role" key={`main-role-${i}`}>
                          <input
                            type="checkbox"
                            checked={isChecked(v.code)}
                            // checked={true}
                            id={`main-role-${v.code}`}
                            onChange={(e) => onSelectRole(true, v.code, e)}
                            disabled={isWaitResponseEditRole}
                          />
                          <label htmlFor={`main-role-${v.code}`}>
                            <b>{v.title}</b>
                          </label>
                        </div>

                        {v.childs ?
                          <div className="custom-checkbox custom-checkbox-child-wrapper">
                            {v.childs.map((_v, _i) => {
                              return (
                                <div className="custom-checkbox custom-checkbox-child" key={`child-role-${_v.code}-${_i}`}>
                                  <input
                                    type="checkbox"
                                    checked={isChecked(_v.code)}
                                    id={`child-role-${_v.code}`}
                                    onChange={(e) => onSelectRole(false, _v.code, e)}
                                    disabled={isWaitResponseEditRole || isParentChecked(v.code)}
                                  />
                                  <label htmlFor={`child-role-${_v.code}`}>
                                    {_v.title}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                          :
                          ""
                        }
                      </>
                    )
                  })}
                </div>

                {errorMessageData.roles !== "" &&
                  <ErrorOrInfoComp
                    _className="font-red no-margin"
                    text={errorMessageData.roles}
                    icon={<FiInfo />}
                  />
                }
              </div>
            </>
          }
        </div>
      </SectionWrap >
    </>
  );
};

export default RolesEdit;