import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Tooltip } from 'reactstrap';
import "../../../auth/Auth.css";
import "../contactInfoCustomFieldAdd/ContactInfoCustomFieldAdd.scss";
import { FiInfo, FiTrash2, FiPlus } from "react-icons/fi";
import IconHelpCircle from '../../../../assets/img/chatroom/user-info-tab/icon-help-circle.svg';
import IconDragDrop from '../../../../assets/img/icon-menu-dragdrop.svg';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ContactCustomFieldServices from "../../../../services/newServices/ContactCustomFieldServices";
import { 
  FIELD_TYPE_SINGLE_LINE_TEXT,
  FIELD_TYPE_MULTILINE_TEXT,
  FIELD_TYPE_DROPDOWN,
  FIELD_TYPE_MULTIPLE_SELECT,
  FIELD_TYPE_DATE,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_LABELS,
  VALIDATION_FREE_TEXT,
  VALIDATION_NUMERIC,
  VALIDATION_NUMBER,
  VALIDATION_ALPHANUMERIC,
  VALIDATION_LABELS,
  FIELD_NAME_MAX_LENGTH,
  FIELD_CODE_MAX_LENGTH,
  MULTIPLE_OPTION_MAX_LENGTH,
  MULTIPLE_OPTION_LIMIT,
  ERROR_MESSAGE_FIELD_REQUIRED,
  ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT,
  ERROR_MESSAGE_DUPLICATE_OPTIONS,
  DEFAULT_CUSTOM_FIELD_VAL,
} from "../ContactInfoCustomField";

let ContactInfoCustomFieldEdit = props => {

  let { match, history, location } = props;
  let [orgID, setOrgID] = useState("");
  let [isLoadingSave, setIsLoadingSave] = useState(false);
  let [customFieldVal, setCustomFieldVal] = useState(DEFAULT_CUSTOM_FIELD_VAL);
  let [customFieldValOrigin, setCustomFieldValOrigin] = useState(DEFAULT_CUSTOM_FIELD_VAL);
  let [errorMessageData, setErrorMessageData] = useState({
    fieldName: "",
    fieldCode: "",
    fieldType: "",
    validation: "",
    options: [],
  });
  let [showTooltipRequired, setShowTooltipRequired] = useState(false);
  let [showTooltipCharacterLimit, setShowTooltipCharacterLimit] = useState(false);
  let [draggingItem, setDraggingItem] = useState(null);
  let [isShowEditFieldModal, setIsShowEditFieldModal] = useState(false);
  
  let toggleTooltipRequired = () => {
    setShowTooltipRequired(!showTooltipRequired);
  }
  
  let toggleTooltipCharacterLimit = () => {
    setShowTooltipCharacterLimit(!showTooltipCharacterLimit);
  }
  
  let toggleShowEditFieldModal = () => {
    setIsShowEditFieldModal(!isShowEditFieldModal);
  }

  let back = () => {
    backToPreviousPage();
  }

  let backToPreviousPage = () => {
    if (match.params.id) {
      props.history.push({
        pathname: `/o/${orgID}/setup/contact-info-custom-field/detail/${match.params.id}`,
        state: customFieldValOrigin
      });
    }
    else {
      props.history.push(`/o/${orgID}/setup/contact-info-custom-field`);
      history.push(`/o/${orgID}/setup/contact-info-custom-field`);
    }
  }

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    }
    else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          back();
        }
        else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.id) {
        const customFieldData = {
          id: props.history.location.state.id,
          fieldName: props.history.location.state.fieldName ? props.history.location.state.fieldName : "",
          isRequired: props.history.location.state.isRequired ? props.history.location.state.isRequired : false,
          fieldCode: props.history.location.state.fieldCode ? props.history.location.state.fieldCode : "",
          fieldType: props.history.location.state.fieldType ? props.history.location.state.fieldType : "",
          validation: props.history.location.state.validation ? props.history.location.state.validation : "",
          maxCharacters: props.history.location.state.maxCharacters ? props.history.location.state.maxCharacters : 0,
          options: props.history.location.state.options ? props.history.location.state.options : [],
          mappedOptions: []
        };
        if (customFieldData.options && customFieldData.options.length > 0) {
          customFieldData.options.map((option, index) => {
            customFieldData.mappedOptions.push({
              id: index,
              text: option
            });
            return null;
          });
        }
        setCustomFieldVal(customFieldData);
        setCustomFieldValOrigin(JSON.parse(JSON.stringify(customFieldData)));
      }
    }
    else {
      back();
    }
  }, [props.history]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  let onChange = (e) => {
    let id = e.target.id;
    let val = e.target.value;
    
    let _customFieldVal = {...customFieldVal};
    if (id === "maxCharacters") {
      let maxCharsString = val.replace(/\D/g,'');
      _customFieldVal[id] = maxCharsString ? parseInt(maxCharsString) : 0;
    }
    else {
      _customFieldVal[id] = val;
    }
    setCustomFieldVal(_customFieldVal);

    let _errorMessageData = {...errorMessageData};
    _errorMessageData[id] = "";
    setErrorMessageData(_errorMessageData);

    if (id === "fieldName" && val.length > FIELD_NAME_MAX_LENGTH) {
      _errorMessageData[id] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
    if (id === "fieldCode" && val.length > FIELD_CODE_MAX_LENGTH) {
      _errorMessageData[id] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
  }

  let onChangeRequired = (e) => {
    if (isLoadingSave) {
      return;
    }
    let _customFieldVal = {...customFieldVal};
    _customFieldVal.isRequired = e.target.checked;
    setCustomFieldVal(_customFieldVal);
  }

  let checkDuplicateInArray = (inputArray) => {
    let duplicates = inputArray.filter((item, index) => inputArray.indexOf(item) !== index);
    return Array.from(new Set(duplicates));
  }

  let onChangeOption = (val, idx) => {
    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.options[idx] = "";
    _customFieldVal.options[idx] = val;
    _customFieldVal.mappedOptions[idx].text = val;

    let dupeArray = [];
    let duplicateVal = checkDuplicateInArray(_customFieldVal.options);

    _customFieldVal.options.map((v, i) => {
      if (v) {
        duplicateVal.map((dupe) => {
          if (dupe && v === dupe) {
            dupeArray.push(i);
          }
          return null;
        });
      }
      return null;
    })

    _customFieldVal.options.map((v, i) => {
      if (_errorMessageData.options[i] !== ERROR_MESSAGE_FIELD_REQUIRED && _errorMessageData.options[i] !== ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT) {
        if (v.length > MULTIPLE_OPTION_MAX_LENGTH) {
          _errorMessageData.options[i] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
        }
        else {
          _errorMessageData.options[i] = "";
        }
      }

      return null;
    })

    if (val.length > MULTIPLE_OPTION_MAX_LENGTH) {
      _errorMessageData.options[idx] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;  
    }
    
    if (dupeArray.length > 0) {
      dupeArray.map((v) => {
        _errorMessageData.options[v] = ERROR_MESSAGE_DUPLICATE_OPTIONS;

        return null;
      })
    }

    setCustomFieldVal(_customFieldVal);
    setErrorMessageData(_errorMessageData);
  }

  let addOption = () => {
    if (isLoadingSave) {
      return;
    }
    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};

    if (_customFieldVal.options.length < MULTIPLE_OPTION_LIMIT) {
      _customFieldVal.options.push("");
      _customFieldVal.mappedOptions.push({
        id: Date.now(),
        text: ""
      });
      _errorMessageData.options.push("");
    }

    setCustomFieldVal(_customFieldVal);
    setErrorMessageData(_errorMessageData);
  }

  let removeOption = (idx) => {
    if (isLoadingSave) {
      return;
    }
    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};
    _customFieldVal.options.splice(idx, 1);
    _customFieldVal.mappedOptions.splice(idx, 1);
    _errorMessageData.options.splice(idx, 1);
    
    let dupeArray = [];
    let duplicateVal = checkDuplicateInArray(_customFieldVal.options);

    _customFieldVal.options.map((v, i) => {
      if (v) {
        duplicateVal.map((dupe) => {
          if (dupe && v === dupe) {
            dupeArray.push(i);
          }
          return null;
        });
      }
      return null;
    })

    _errorMessageData.options.map((v, i) => {
      let _findIdx = dupeArray.findIndex(_v => _v === i);
      if (v === ERROR_MESSAGE_DUPLICATE_OPTIONS && _findIdx === -1) {
        if (_customFieldVal.options[i].length > MULTIPLE_OPTION_MAX_LENGTH) {
          _errorMessageData.options[i] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
        }
        else {
          _errorMessageData.options[i] = "";
        }
      }

      return null;
    })

    setCustomFieldVal(_customFieldVal);
    setErrorMessageData(_errorMessageData);
  }

  let onOptionDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', ''); 
  }

  let onOptionDragEnd = (e) => {
    setDraggingItem(null);
  }

  let onOptionDragOver = (e) => {
    e.preventDefault(); 
  }

  let onOptionDrop = (e, item) => {
    if (!draggingItem) {
      return; 
    }

    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};
    const currentIndex = _customFieldVal.mappedOptions.indexOf(draggingItem);
    const targetIndex = _customFieldVal.mappedOptions.indexOf(item);

    if (currentIndex !== -1 && targetIndex !== -1) {
      _customFieldVal.options.splice(currentIndex, 1);
      _customFieldVal.options.splice(targetIndex, 0, draggingItem.text);
      _customFieldVal.mappedOptions.splice(currentIndex, 1);
      _customFieldVal.mappedOptions.splice(targetIndex, 0, draggingItem);
      const draggedErrorMessage = _errorMessageData.options[currentIndex];
      _errorMessageData.options.splice(currentIndex, 1);
      _errorMessageData.options.splice(targetIndex, 0, draggedErrorMessage);
      setCustomFieldVal(_customFieldVal);
    }
  }

  let validateCustomFieldForm = async () => {
    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};
    let errorCount = 0;
    let errorClass = "";

    if (_customFieldVal.fieldType === FIELD_TYPE_DROPDOWN || _customFieldVal.fieldType === FIELD_TYPE_MULTIPLE_SELECT) {
      let dupeArray = [];
      let duplicateVal = checkDuplicateInArray(_customFieldVal.options);

      _customFieldVal.options.map((v, i) => {
        if (v) {
          duplicateVal.map((dupe) => {
            if (dupe && v === dupe) {
              dupeArray.push(i);
            }
            return null;
          });
        }
        return null;
      })
      
      if (dupeArray.length > 0) {
        dupeArray.map((v) => {
          if (_customFieldVal.options[v]) {
            _errorMessageData.options[v] = ERROR_MESSAGE_DUPLICATE_OPTIONS;
            errorCount = errorCount + 1;
            if (errorClass === "") { 
              errorClass = `input-option-${v}`
            }
          }

          return null;
        })
      }

      _customFieldVal.options.map((option, index) => {
        if (_errorMessageData.options[index] !== ERROR_MESSAGE_DUPLICATE_OPTIONS) {
          if (!option) {
            _errorMessageData.options[index] = ERROR_MESSAGE_FIELD_REQUIRED;
            errorCount = errorCount + 1;
            if (errorClass === "") { 
              errorClass = `input-option-${index}`
            }
          }
          else if (option.length > MULTIPLE_OPTION_MAX_LENGTH) {
            _errorMessageData.options[index] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
            errorCount = errorCount + 1;
            if (errorClass === "") { 
              errorClass = `input-option-${index}`
            }
          }
        }
        return null;
      });
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }
    else if (JSON.stringify(customFieldVal) === JSON.stringify(customFieldValOrigin)) {
      doToast("No changes found", "fail");
    }
    else {
      setIsShowEditFieldModal(true);
    }
  };

  let editCustomField = () => {
    setIsLoadingSave(true);
    let _customFieldVal = {...customFieldVal};
    let request = {};
    request.id = _customFieldVal.id;
    request.isRequired = _customFieldVal.isRequired;
    if (_customFieldVal.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT || _customFieldVal.fieldType === FIELD_TYPE_MULTILINE_TEXT) {
      request.maxCharacters = _customFieldVal.maxCharacters;
    }
    if (_customFieldVal.fieldType === FIELD_TYPE_DROPDOWN || _customFieldVal.fieldType === FIELD_TYPE_MULTIPLE_SELECT) {
      request.options = _customFieldVal.options;
    }
    ContactCustomFieldServices.editContactCustomField(orgID, request, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          setCustomFieldVal(_customFieldVal);
          setCustomFieldValOrigin(_customFieldVal);
          setIsLoadingSave(false);
          setIsShowEditFieldModal(false);
          backToPreviousPage();
          setTimeout(() => {
            doToast(response.dataResult.data.message);
          }, 200);
        }
        else {
          setIsShowEditFieldModal(false);
          setIsLoadingSave(false);
          doToast(response.dataResult.data.message, "fail");
        }
      }
      else {
        setIsShowEditFieldModal(false);
        setIsLoadingSave(false);
        doToast(response.dataResult.error.message, 'fail');

        if (response.dataResult.error.code === "40002" && response.dataResult.error.message.includes("already exists")) {          
          let _errorMessageData = {...errorMessageData};
          _errorMessageData.fieldCode = response.dataResult.error.message;
          setErrorMessageData(_errorMessageData);
        }
      }
    });
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
      dataOrigin={JSON.stringify(customFieldValOrigin)}
      dataAfterChange={JSON.stringify(customFieldVal)}
    >
      <BackButton text="Back to Contact Info Custom Field Details" onClick={back} />

      <div className="sectionWrap create-custom-field-main-wrapper">
        <div className="topContent create-custom-field-header">
          <p className="create-custom-field-title"><b>Edit Custom Field</b></p>

          <div className="topContent-button-wrapper">
            {(isLoadingSave) ?
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                text="Save Changes"
                position="left"
                loadingColor="gray"
                isLoading
                isDisabled
              />
              :
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                text="Save Changes"
                onClickAction={validateCustomFieldForm}
              />
            }
          </div>
        </div>

        <>
          <div className="create-custom-field-add-wrapper">

            <div className="form-content-wrapper">
              
              <label><b>Field Name</b></label>
              <p className="field-value">{customFieldVal.fieldName}</p>

              <label className="label-left">
                <b>Required</b>
                <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-required" alt="" />
                <div>
                  <Tooltip 
                    // className="tooltip-content"
                    className="tooltip-required"
                    placement="bottom"
                    isOpen={showTooltipRequired}
                    target="tooltip-required"
                    toggle={toggleTooltipRequired}
                    opacity={1.0}
                  >
                    If Required is on, this field should always be filled when edited in contact’s info
                  </Tooltip>
                </div>

                <span>
                  <label className="custom-switch-wrapper">
                    <input 
                      type="checkbox" 
                      checked={customFieldVal.isRequired}
                      onChange={(e) => onChangeRequired(e)}
                    />
                    <span className="custom-switch round"></span>
                  </label>
                  <p className="required-value">This field is {customFieldVal.isRequired ? "required" : "optional"}</p>
                </span>
              </label>

              <label><b>Field Code</b></label>
              <p className="field-value">{customFieldVal.fieldCode}</p>

              <label><b>Field Type</b></label>
              <p className="field-value">{FIELD_TYPE_LABELS[customFieldVal.fieldType] ? FIELD_TYPE_LABELS[customFieldVal.fieldType] : customFieldVal.fieldType}</p>

              {
                (customFieldVal.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ||
                 customFieldVal.fieldType === FIELD_TYPE_MULTILINE_TEXT ||
                 customFieldVal.fieldType === FIELD_TYPE_DATE ||
                 customFieldVal.fieldType === FIELD_TYPE_NUMBER)
                &&
                <>
                  <div className="form-content-wrapper dropdown-validation">
                    <label><b>Validation</b></label>
                    <p className="field-value">{VALIDATION_LABELS[customFieldVal.validation] ? VALIDATION_LABELS[customFieldVal.validation] : customFieldVal.validation}</p>
                  </div>
                  {
                    (customFieldVal.validation === VALIDATION_FREE_TEXT ||
                     customFieldVal.validation === VALIDATION_NUMERIC ||
                     customFieldVal.validation === VALIDATION_NUMBER ||
                     customFieldVal.validation === VALIDATION_ALPHANUMERIC)
                    &&
                    <ErrorOrInfoComp
                      text={
                        customFieldVal.validation === VALIDATION_FREE_TEXT ? "Field can only be filled with alphabets, numbers, and special characters" :
                        customFieldVal.validation === VALIDATION_NUMERIC ? "Field can only be filled with numbers" :
                        customFieldVal.validation === VALIDATION_NUMBER ? "Field can only be filled with positive or negative number" :
                        customFieldVal.validation === VALIDATION_ALPHANUMERIC ? "Field can only be filled with alphabets and numbers with no space" :
                        ""
                      }
                      _className={"font-black"}
                      icon={<FiInfo />}
                    />
                  }
                </>
              }

              {
                (customFieldVal.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ||
                 customFieldVal.fieldType === FIELD_TYPE_MULTILINE_TEXT)
                &&
                <>
                  <label className="label-left">
                    <b>Limit Maximum Character</b>
                    <span className="optional"> (Optional)</span>
                    <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-char-limit" alt="" />
                    <div>
                      <Tooltip 
                        className="tooltip-char-limit"
                        placement="right"
                        isOpen={showTooltipCharacterLimit}
                        target="tooltip-char-limit"
                        toggle={toggleTooltipCharacterLimit}
                        opacity={1.0}
                      >
                        When filled, this limits how many characters agents can input when editing customer’s info.
                      </Tooltip>
                    </div>
                  </label>
                  <input
                    disabled={isLoadingSave}
                    type="text"
                    id="maxCharacters"
                    onChange={(e) => onChange(e)}
                    value={customFieldVal.maxCharacters ? '' + customFieldVal.maxCharacters : ''}
                    maxLength={3}
                    placeholder="000"
                    className="input-char-limit"
                  />
                  <span className="characters-label">Characters</span>
                </>
              }
              
              {
                (customFieldVal.fieldType === FIELD_TYPE_DROPDOWN || customFieldVal.fieldType === FIELD_TYPE_MULTIPLE_SELECT) &&
                <>
                  <label><b>Manage Options</b></label>
                  <p className="label-description">{`${FIELD_TYPE_LABELS[customFieldVal.fieldType] ? FIELD_TYPE_LABELS[customFieldVal.fieldType] : customFieldVal.fieldType} requires 1 option and you can add up to ${MULTIPLE_OPTION_LIMIT} options.`}</p>
                  {
                    customFieldVal.mappedOptions.map((v, i) => {
                      return (
                        <div 
                          key={`multiple-option-${i}`}
                          draggable={customFieldVal.options.length > 1 && !isLoadingSave}
                          onDragStart={(e) => onOptionDragStart(e, v)}
                          onDragEnd={(e) => onOptionDragEnd(e)}
                          onDragOver={(e) => onOptionDragOver(e)}
                          onDrop={(e) => onOptionDrop(e, v)}
                        >
                          <label className={`multiple-option-count input-option-${i}`}>
                            <span className={`grey-font ${v.text.length > MULTIPLE_OPTION_MAX_LENGTH ? "red-text" : ""}`}>
                              {v.text.length}/{MULTIPLE_OPTION_MAX_LENGTH} characters
                              {
                                customFieldVal.options.length > 1 &&
                                <FiTrash2 onClick={() => removeOption(i)} className="remove-button-text" />
                              }
                            </span>
                          </label>
                          <input
                            className={errorMessageData.options[i] ? `border-red button-text-${i} ${customFieldVal.options.length > 1 ? " draggable" : ""}` : `button-text-${i} ${customFieldVal.options.length > 1 ? " draggable" : ""}`}
                            type="text"
                            value={v.text}
                            placeholder={`Option ${i + 1}`}
                            disabled={isLoadingSave}
                            onChange={(e) => onChangeOption(e.target.value, i)}
                          />
                          {
                            customFieldVal.options.length > 1 &&
                            <img
                              src={IconDragDrop}
                              className="drag-indicator"
                              alt=""
                              draggable={false}
                            />
                          }
                          {
                            errorMessageData.options[i] &&
                            <ErrorOrInfoComp 
                              text={errorMessageData.options[i]}
                              icon={<FiInfo />}
                              _className="red-text"
                            />
                          }
                        </div>
                      )
                    })
                  }
                  {
                    customFieldVal.options.length < MULTIPLE_OPTION_LIMIT &&
                    <p className="add-option" onClick={addOption}>
                      <b><FiPlus /> Add Option</b>
                    </p>
                  }
                </>
              }
            </div>
          </div>
        </>
      </div>
      
      <Modal 
        isOpen={isShowEditFieldModal} 
        className="modal-replace-data" 
        toggle={toggleShowEditFieldModal}
      >
        <ModalBody>
          <b className="modal-title">
            {
              customFieldVal.isRequired === customFieldValOrigin.isRequired ?
              "Save Changes?"
              :
              `Save ${customFieldVal.isRequired ? "Required" : "Optional"} Custom Field?`
            }
          </b>

          <p className="modal-desc">
            {
              customFieldVal.isRequired === customFieldValOrigin.isRequired ?
              "You have changes in this Contact’s Info Custom Field, are you sure you want to continue?"
              :
              customFieldVal.isRequired ?
              "You are saving a required custom field, agents will have to fill in information details for this custom field when editing customer contact’s info. Are you sure you want to continue?"
              :
              "Turning off the required toggle will allow agents to not fill in this custom field when editing customer contact’s info. Are you sure you want to continue?"
            }
          </p>

          {
            isLoadingSave ?
            <>
              <ButtonWithLoadingOrIcon 
                text="Cancel"
                className="main-button-48 no-fill-button-grey"
                onClickAction={() => {}}
              />
              <ButtonWithLoadingOrIcon 
                text="Save Changes"
                className="main-button-48 orange-button"
                loadingColor="gray"
                isDisabled
                isLoading
                position="left"
              />
            </>
            :
            <>
              <ButtonWithLoadingOrIcon 
                text="Cancel"
                className="main-button-48 no-fill-button"
                onClickAction={toggleShowEditFieldModal}
              />
              <ButtonWithLoadingOrIcon 
                text="Save Changes"
                className="main-button-48 orange-button"
                onClickAction={editCustomField}
              />
            </>
          }
        </ModalBody>
      </Modal>
    </SectionWrap >
  );
};

export default ContactInfoCustomFieldEdit;
