import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationTokopediaServices = {
    getList(idOrTime, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.GET_LIST, {}, headers, callback);
    },

    create(idOrTime, data, callback) {
      let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
      };

      BaseService.doPostAccessToken(API.CREATE, data, headers, callback);
    },

    editTopic(idOrTime, data, callback) {
      let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
      };

      BaseService.doPostAccessToken(API.EDIT_TOPIC, data, headers, callback);
    },

    getDetail(idOrTime, data, callback) {
      let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
      };

      BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);
    },

    reqActive(idOrTime, data, callback) {
      let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
      };

      BaseService.doPostAccessToken(API.ACTIVATION, data, headers, callback);
    },

    getChatBot(idOrTime, data, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      BaseService.doPostAccessToken(API.GET_CHAT_BOT, data, headers, callback);
    },

    setChatBot(idOrTime, data, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      BaseService.doPostAccessToken(API.SET_CHAT_BOT, data, headers, callback);
    },

    removeChatBot(idOrTime, data, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      BaseService.doPostAccessToken(API.REMOVE_CHAT_BOT, data, headers, callback);
    },

    getWelcomeMessage(idOrTime, id, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      const data = {
        id: parseInt(id)
      };
  
      BaseService.doPostAccessToken(API.GET_WELCOME, data, headers, callback);
    },

    getAwayMessage(idOrTime, id, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      const data = {
        id: parseInt(id)
      };
  
      BaseService.doPostAccessToken(API.GET_AWAY, data, headers, callback);
    },

    getClosingMessage(idOrTime, id, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      const data = {
        id: parseInt(id)
      };
  
      BaseService.doPostAccessToken(API.GET_CLOSING, data, headers, callback);
    },

    setWelcomeMessage(idOrTime, data, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);
    },

    setAwayMessage(idOrTime, data, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);
    },
    
    setClosingMessage(idOrTime, data, callback) {
      const headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
      };
  
      BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);
    },
};

const API = {
  GET_LIST: '/organization/channel/tokopedia/get_list',
  CREATE: '/organization/channel/tokopedia/create',
  EDIT_TOPIC: '/organization/channel/tokopedia/edit_topic',
  GET_DETAIL: '/organization/channel/tokopedia/get_details',
  ACTIVATION: '/organization/channel/tokopedia/request_activation',
  GET_CHAT_BOT: '/organization/channel/tokopedia/get_chatbot',
  SET_CHAT_BOT: '/organization/channel/tokopedia/set_chatbot',
  REMOVE_CHAT_BOT: '/organization/channel/tokopedia/remove_chatbot',
  GET_WELCOME: "/organization/channel/tokopedia/get_welcome_message",
  SET_WELCOME:  "/organization/channel/tokopedia/set_welcome_message",
  GET_AWAY: "/organization/channel/tokopedia/get_away_message",
  SET_AWAY: "/organization/channel/tokopedia/set_away_message",
  GET_CLOSING: "/organization/channel/tokopedia/get_closing_message",
  SET_CLOSING: "/organization/channel/tokopedia/set_closing_message",
};

export default IntegrationTokopediaServices;
