import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const ReportService = {
  getOverview(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_OVERVIEW, data, headers, callback);  
  },

  getOverviewDaily(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_OVERVIEW_DAILY, data, headers, callback);  
  },

  getCaseReport(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CASE_REPORT, data, headers, callback);  
  },

  getCaseRating(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CASE_RATING, data, headers, callback);  
  },

  getAgentReport(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_AGENT_REPORT, data, headers, callback);  
  },

  getTopicReport(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_TOPIC_REPORT, data, headers, callback);  
  },

  getMessageApiHistory(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_MESSAGE_HISTORY, data, headers, callback);  
  },

  getUTM(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_UTM, data, headers, callback);  
  },

  getRef(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_REFERRER, data, headers, callback);  
  },

  getClockIn(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CLOCK_IN, data, headers, callback);  
  },

  exportAgentReport(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_AGENT_REPORT, data, headers, callback, 'blob');
  },

  exportCaseRating(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_CASE_RATING, data, headers, callback, 'blob');
  },

  exportCaseReport(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_CASE_REPORT, data, headers, callback, 'blob');
  },

  exportTopicReport(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_TOPIC, data, headers, callback, 'blob');
  },

  exportMessageHistoryReport(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_MESSAGE_HISTORY, data, headers, callback, 'blob');
  },

  exportUTM(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_UTM, data, headers, callback, 'blob');
  },

  exportReferrer(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_REFERRER, data, headers, callback, 'blob');
  },

  exportClockIn(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_CLOCK_IN, data, headers, callback, 'blob');
  },
};

const API = {
  GET_OVERVIEW: "/organization/report/overview",
  GET_OVERVIEW_DAILY: "/organization/report/daily_overview",
  GET_CASE_REPORT: "/organization/report/case_report/get_list",
  GET_CASE_RATING: "/organization/report/case_ratings",
  GET_AGENT_REPORT: "/organization/report/agent_report/get_list",
  GET_TOPIC_REPORT: "/organization/report/topic_report/get_list",
  GET_MESSAGE_HISTORY: "/organization/report/integration_message_history/get_list",
  GET_UTM: "/organization/report/utm/get_list",
  GET_REFERRER: "/organization/report/referrer_url/get_list",
  GET_CLOCK_IN: "/organization/report/clock_in_report/get_list",
  EXPORT_AGENT_REPORT: "/organization/report/agent_report/export",
  EXPORT_CASE_RATING: "/organization/report/case_ratings/export",
  EXPORT_CASE_REPORT: "/organization/report/case_report/export",
  EXPORT_TOPIC: "/organization/report/topic_report/export",
  EXPORT_MESSAGE_HISTORY: "/organization/report/integration_message_history/export",
  EXPORT_UTM: "/organization/report/utm/export",
  EXPORT_REFERRER: "/organization/report/referrer_url/export",
  EXPORT_CLOCK_IN: "/organization/report/clock_in_report/export"
}

export default ReportService;