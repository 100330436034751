import React, { useEffect } from "react";
import IntegrationAutoResolve from "../../integrations/integrationAutoMessage/integrationAutoResolve/IntegrationAutoResolve";

let IntegrationAutoResolveCase = (props) => {
    useEffect(() => {
        if(!props.location.state) {
            props.history.push(`/o/${props.match.params.orgID}/integration/channels`);
        }
    }, [])

    let back = () => {
        props.history.push(props.location.state.backToLink)
    }

    return (
        <div className="integration-taptalk-auto-message-setup">
            {props.location.state &&
                <IntegrationAutoResolve
                    {...props}
                    back={back}
                    backToText={`Back to Channel Details`}
                    type={props.location.state.type}
                    medium={props.location.state.medium}
                    data={props.data}
                />
            }
        </div>
    )
}

export default IntegrationAutoResolveCase;
