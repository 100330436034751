import React from 'react';
import './ModalStartConversationBalance.scss';
import { connect } from 'react-redux';
import { Modal, ModalBody } from "reactstrap";
import ButtonWithLoadingOrIcon from "../../components/reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { setModalStartConversationBalance, clearModalStartConversationBalance } from "../../redux/actions/reduxActionModalStartConversationBalance";
import { FiAlertCircle } from 'react-icons/fi';

let ModalStartConversationBalance = (props) => {
    let { modalStartConversationBalance, setModalStartConversationBalance, currentMembership } = props;

    let modalConfirmationSend = () => {
        let cancelAction = () => {
            let _modalStartConversationBalance = {...modalStartConversationBalance};
            _modalStartConversationBalance.confirmation.show = false;
            setModalStartConversationBalance(_modalStartConversationBalance);
        }

        let continueClick = () => {
            let _modalStartConversationBalance = {...modalStartConversationBalance};
            _modalStartConversationBalance.confirmation.isLoading = true;
            props.setModalStartConversationBalance(_modalStartConversationBalance);
        }

        
        let countPrice = () => {
            let price = 0;
            
            if(modalStartConversationBalance.confirmation.priceList.whatsapp) {
                let findIndexPrice = modalStartConversationBalance.confirmation.priceList.whatsapp.findIndex(val => val.messageType === modalStartConversationBalance.confirmation.messageType);
                price = modalStartConversationBalance.confirmation.priceList.whatsapp[findIndexPrice].price - modalStartConversationBalance.confirmation.priceList.whatsapp[findIndexPrice].discountAmount;
            }
            
            return price;
        }

        return (
            <Modal isOpen={modalStartConversationBalance.confirmation.show} className="modal-confirmation-send-conversation" toggle={cancelAction}>
                <ModalBody>
                    <p>
                        <FiAlertCircle />
                        <b>Send Message</b>
                    </p>

                    <p>
                        You may be charged Rp {countPrice()} for this message.
                    </p>

                    <ButtonWithLoadingOrIcon 
                        className="no-fill-button main-button-48"
                        onClickAction={cancelAction}
                        text="Cancel"
                    />
                    
                    {!modalStartConversationBalance.confirmation.isLoading ?
                        <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-48"
                            onClickAction={() => {
                                continueClick();
                                modalStartConversationBalance.confirmation.confirmAction()
                            }}
                            text="Continue"
                        />
                        :
                        <ButtonWithLoadingOrIcon 
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            position="left"
                            className="orange-button main-button-48"
                            text="Continue"
                        />
                    }
                </ModalBody>
            </Modal>
        )
    }

    let modalBalanceTopup = () => {
        let cancelAction = () => {
            let _modalStartConversationBalance = {...modalStartConversationBalance};
            _modalStartConversationBalance.topupBalance.show = false;
            setModalStartConversationBalance(_modalStartConversationBalance);
        }

        let topUpNowAction = () => {
            window.open(`/o/${props.match.params.orgID}/organization/top-up`, "_blank");
            cancelAction();
        }

        return (
            <Modal isOpen={modalStartConversationBalance.topupBalance.show} className="modal-topup-balance" toggle={cancelAction}>
                <ModalBody>
                    <p>
                        <FiAlertCircle />
                        <b>Insufficient Balance</b>
                    </p>

                    <p>
                        {currentMembership.roleCode === "admin" ?
                            `Your organization currently don’t have enough balance to send this templated message. Please top up first and try again.`
                            :
                            `Your organization currently don’t have enough balance to send this templated message. Please contact your admin to top up.`
                        }                  
                    </p>
                    
                    {currentMembership.roleCode === "admin" ?
                        <>
                            <ButtonWithLoadingOrIcon 
                                className="no-fill-button main-button-48"
                                onClickAction={cancelAction}
                                text="Cancel"
                            />

                            <ButtonWithLoadingOrIcon 
                                className="orange-button main-button-48"
                                onClickAction={topUpNowAction}
                                text="Top Up Now"
                            />
                        </>
                        :
                        <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-48 width-100"
                            onClickAction={cancelAction}
                            text="Dismiss"
                        />
                    }
                </ModalBody>
            </Modal>
        )
    }

    // let modalBalanceNonAdmin = () => {
    //     let cancelAction = () => {
    //         let _modalStartConversationBalance = {...modalStartConversationBalance};
    //         _modalStartConversationBalance.topupAdmin.show = false;
    //         setModalStartConversationBalance(_modalStartConversationBalance);
    //     }
        
    //     return (
    //         <Modal isOpen={modalStartConversationBalance.topupNonAdmin.show} className="modal-balance-non-admin">
    //             <ModalBody>
    //                 <p>
    //                     <FiAlertCircle />
    //                     <b>Insufficient Balance</b>
    //                 </p>

    //                 <p>
    //                     Your organization currently don’t have enough balance to send this templated message. Please contact your admin to top up.
    //                 </p>

    //                 <ButtonWithLoadingOrIcon 
    //                     className="orange-button main-button-48"
    //                     onClickAction={cancelAction}
    //                     text="Dismiss"
    //                 />
    //             </ModalBody>
    //         </Modal>
    //     )
    // }

    return (
        <>
            {modalConfirmationSend()}
            {modalBalanceTopup()}
            {/* {modalBalanceNonAdmin()} */}
        </>
    )
}

const mapStateToProps = state => ({
    modalStartConversationBalance: state.modalStartConversationBalance
});

const mapDispatchToProps = {
    setModalStartConversationBalance,
    clearModalStartConversationBalance
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStartConversationBalance);