import React, { useState } from "react";
// import FormInput from "../reuseableComponent/FormInput/FormInput";
import ActionAgent from "./TDAgentAction";
import TDATableAgent from "./TDATableAgent";
import Loading from "../reuseableComponent/loading/Loading";
import TDATableHead from "./TDATableHead";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSearchBox from "../reuseableComponent/customSearchBox/CustomSearchBox";

const TDATopAction = props => {
  const { agentData, setCopyAgentData } = props;
  let [searchVal, setSearchVal] = useState("");

  const searchAgent = e => {
    const data = agentData;
    const hasAgent = data && data.length > 0;
    setSearchVal(e.target.value);
    if (e.target.value && hasAgent) {
      const { value: keyword } = e.target;
      const filtered = data.filter(item => {
        const { accountName, accountEmail } = item;
        const lowKey = keyword.toLowerCase();
        const lowName = accountName.toLowerCase();
        const lowEmail = accountEmail.toLowerCase();
        return lowName.includes(lowKey) || lowEmail.includes(lowKey);
      });
      setCopyAgentData(filtered);
    } else setCopyAgentData(false);
  };

  useState(() => {
    setCopyAgentData(agentData);
  }, [JSON.stringify(agentData)])

  return (
    <div className="flexContent">
      <div className="agentSearch">
        {/* <FormInput
          name="agentSearch"
          type="text"
          placeholder="Search for agent"
          onChange={searchAgent}
          icon={<FaSearch />}
        /> */}

        <CustomSearchBox
          placeholder={"Search for agent"}
          onChangeSearchSpecial={searchAgent}
          style={{ width: '343px' }}
          search={searchVal}
          // dataToSearch={members} 
          onClearSearch={() => {
            let valSearch = document.querySelector(`#search-box-input-val`);
            valSearch.value = "";
            setSearchVal("");
            setCopyAgentData(agentData);
          }}
        />
      </div>
      <div className="agentAction">
        <ActionAgent {...props} />
      </div>
    </div>
  );
};

const NoAgent = () => (
  <table width="100%" className="tableContent">
    <TDATableHead />
    <tbody>
      <tr>
        <td colSpan="3">
          <div className="textInfo center grey">
            <p style={{ marginTop: "32px" }}>No agent assigned</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
);

// const LoadingMember = () => (
//   <React.Fragment>
//     <br />
//     <br />
//     <Loading />
//   </React.Fragment>
// );

const Reload = () => (
  <div className="reload">
    <Loading />
  </div>
);

const MemberView = agentProps => {
  const { agentData, retriveDataError, isLoadingTopic } = agentProps;
  if (agentData.length > 0) {
    return (
      <>
        <TDATopAction {...agentProps} />
        <TDATableAgent {...agentProps} />
      </>
    );
  } else if (agentData.length < 1) {
    return (
      <>
        <TDATopAction {...agentProps} />
        <NoAgent />
      </>
    )
  } else {
    return <RetrieveDataLoading errorMessage={retriveDataError.message} isLoading={isLoadingTopic} />;
  }
};

const TDContent = props => {
  const { onReload } = props;
  return (
    <div className="bodyContent assigned-agent-content">
      <MemberView {...props} />
      {onReload && <Reload />}
    </div>
  );
};

export default TDContent;
