import React, { useState, useEffect } from "react";
import "./VerifyCard.scss";
import Blocking401 from "../../../../reuseableComponent/blocking401/Blocking401";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../../reuseableComponent/sectionWrap/SectionWrap";
import Loading from "../../../../reuseableComponent/loading/Loading";
import { checkID } from "../../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import BillingServices from "../../../../../services/newServices/BillingServices";
import mixpanel from "mixpanel-browser";

let VerifyCard = (props) => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [cardAuthorizationData, setCardAuthorizationData] = useState(false);
    let [isShowLoadingVerifyCard, setIsShowLoadingVerifyCard] = useState(true);
    let [isWaitAddCard, setIsWaitAddCard] = useState(false);
    
    let back = () => history.push(`/o/${orgID}/organization/billing/add-card`);
    let backWithMessage = (message) => {
      history.push({
        pathname: `/o/${orgID}/organization/billing/add-card`,
        state: {
          message: message
        }
      })
    }

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
          
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if(response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              const { id, createdTime } = responseData.memberships[0].organization;
              const urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/billing`);
            }else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };
    
    useEffect(() => {
       doMount();
      }, [location.pathname]);
      
      useEffect(() => {
        if(orgID) {
          if(location.state) {
            createXenditAuthorization()
          }else {
            back();
          }
        }
    }, [orgID])
    
    useEffect(() => {
      if(cardAuthorizationData) {
        setIsShowLoadingVerifyCard(false);
      }
    }, [cardAuthorizationData])

    let addCard = (authenticationResponse) => {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      setIsWaitAddCard(true);

      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Button] Add Card",
          {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : orgID.split("-")[0]
          }
        );
      }
      
      let _formData = location.state.formData;
      let _tokenData = location.state.tokenData;
      let _authenticationResponse = authenticationResponse;

      let data = {
        tokenID: _tokenData.id,
        authenticationID: _authenticationResponse.id,
        cardExpiryMonth: _formData.cardExpiry.split("/")[0],
        cardExpiryYear: "20"+_formData.cardExpiry.split("/")[1],
        nameOnCard: _formData.cardName
      };

      let pushTo = (path, data) => {
        history.push({
          pathname: path,
          state: data
        })
      }

      BillingServices.addCard(orgID, data, {
        onFailCredential: (errorMessage, erroCode) => {
          backWithMessage(errorMessage);
        },
        onSuccessCredential: (response) => {
            setIsWaitAddCard(false);

            if(response.error.message === "") {
              if(response.data.success) {
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                  mixpanel.track(
                    "[Action] Add Card",
                    {
                      userFullName : myAgentData.account.fullName,
                      userID : myAgentData.account.id,
                      organizationID : orgID.split("-")[0]
                    }
                  );
                }
                
                pushTo(`/o/${orgID}/organization/billing`, {
                    message: response.data.message,
                    whichTab: "paymentSettings"
                });
              }else {
                backWithMessage(response.data.message);
              }
            }else {
              backWithMessage(response.error.message);
            }
        }
      })
    }

    let createXenditAuthorization = () => {
      let xenditAuthenticationResponseHandler = (err, authenticationResponse) => {
          if(err !== null) {
              backWithMessage(err.message);
          }

          if(authenticationResponse) {
            if(authenticationResponse.status === "IN_REVIEW") {
              setCardAuthorizationData(authenticationResponse);
            }else if(authenticationResponse.status === "VERIFIED") {
              addCard(authenticationResponse);
            }else if(authenticationResponse.status === "FAILED") {
              backWithMessage("3DS verification was unsuccessful, please try again");
            }
          }
      } 

      window.Xendit.card.createAuthentication({
          token_id: location.state.tokenData.id,
          amount: 0
      }, xenditAuthenticationResponseHandler);
    }

    return (
        <React.Fragment>
          {process.env.REACT_APP_HIDE_BILLING === "true" ?
              <SectionWrap
                  {...props}
                  orgID={orgID}
                  withHeader
                  withSideMenu
              >
                  <Blocking401 {...props} />
              </SectionWrap>
              :
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >   
                <BackButton text="Back to Add Card" onClick={back} />

                <div className="sectionWrap verify-card-wrapper">
                    <p className="verify-card-title">
                        <b>Verify Card</b>
                    </p>

                    {isShowLoadingVerifyCard ?
                      <Loading />
                      :
                      <div className="iframe-card-wrapper">
                        {isWaitAddCard ?
                          <div className="loading-add-card-wrapper">
                            <Loading />
                            <b>Adding Card</b>
                          </div>
                          :
                          <iframe src={cardAuthorizationData.payer_authentication_url} title="iframe-xendit" />
                        }
                      </div>
                    }
                </div>
            </SectionWrap>
          }     
        </React.Fragment>
    )
}

export default VerifyCard;