import React, { useState, useEffect } from "react";
import './ChatRoomSalesTalkProductModal.scss';
import { FiX } from "react-icons/fi";
import SalesTalkProductComp from "./salesTalkProductComp/SalesTalkProductComp";
import SalesTalkProductTopComp from "./salesTalkProductTopComp/SalesTalkProductTopComp";
import SalesTalkErrorProductComp from "./salesTalkErrorProductComp/SalesTalkErrorProductComp";
import SalesTalkShimmerProductComp from "./salesTalkShimmerProductComp/SalesTalkShimmerProductComp";
import ServiceSalesTalkDefault from "../../../../services/salesTalk/default/SalesTalkDefault";
import salesTalkNotConnect from "../../../../assets/img/salestalk-not-connect-min.png";
import { setSalestalkProductFilter, clearSalestalkProductFilter } from "../../../../redux/actions/reduxActionSalestalkProductFilter";
import { setSalesTalkNotConnect } from "../../../../redux/actions/reduxActionSalesTalkNotConnect";
import { setSalesTalkErrorServer } from "../../../../redux/actions/reduxActionSalesTalkErrorServer";
import { setSalesTalkInbox } from "../../../../redux/actions/reduxActionSalesTalkInbox";
import { setSalestalkSelectedProduct } from "../../../../redux/actions/reduxActionSalestalkSelectedProduct";
import { connect } from 'react-redux';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ExclamationRed from "../../../../assets/img/exclamation-red.svg";
// import { doToast } from "../../../../helper/HelperGeneral";

let ChatRoomSalesTalkProductModal = (props) => {
    let [isLoadingData, setIsLoadingData] = useState(false);
    let [categoryList, setCategoryList] = useState([]);
    let [productData, setProductData] = useState({});
    let [errorData, setErrorData] = useState({
        title: "",
        desc: ""
    })

    useEffect(() => {
        if(!isLoadingData && props.salesTalkInbox.isShow && !props.salesTalkNotConnected) {
            setErrorData({
                title: "",
                desc: ""
            });

            getCategoryList();
        } 
    }, [props.salesTalkInbox.isShow])


    let getCategoryList = () => {
        props.setSalesTalkErrorServer("");
        let _salesTalkProductFilter = {...props.salesTalkProductFilter};
        setIsLoadingData(true);

        ServiceSalesTalkDefault.postGetCategory(props.parentProps.match.params.orgID, (res) => {
            let dataRes = res.dataResult;

            // status 500
            if(dataRes.status === 500) {
                // doToast(dataRes.error.message, "fail");
                props.setSalesTalkErrorServer("There’s something wrong, please contact our support for help.");
                setIsLoadingData(false);
            }else {
                if(dataRes.error.message !== "") {
                    // doToast(dataRes.error.message, "fail");
                    if(dataRes.error.code === "40002") {
                        props.setSalesTalkNotConnect(true);
                        setIsLoadingData(false);
                    }else {
                        setErrorData({
                            title: "Error",
                            desc: dataRes.error.message
                        });
                    }
                }else {
                    setCategoryList(dataRes.data.items);
                    _salesTalkProductFilter.categoryID = "";
                    props.setSalestalkProductFilter(_salesTalkProductFilter);
                    getProductList();
                }
            }
        })
    }

    let getProductList = (param = false) => {
        setErrorData({
            title: "",
            desc: ""
        });

        setIsLoadingData(true);
        
        let _salesTalkProductFilter = {...props.salesTalkProductFilter};

        let data = {};

        if(!param) {
            data = {
                "search": _salesTalkProductFilter.search,
                "categoryID":_salesTalkProductFilter.categoryID,
                "pageNumber": _salesTalkProductFilter.pageNumber,
                "pageSize": _salesTalkProductFilter.pageSize,
                "sortBy": _salesTalkProductFilter.sortBy.value,
                "sortOrder": _salesTalkProductFilter.sortOrder
            };
        }else {
            data = {
                "search": param.search,
                "categoryID":param.categoryID,
                "pageNumber": param.pageNumber,
                "pageSize": param.pageSize,
                "sortBy": param.sortBy.value,
                "sortOrder": param.sortOrder
            };
        }

        ServiceSalesTalkDefault.postProductList(props.parentProps.match.params.orgID, data, (res) => {
            let dataRes = res.dataResult;

            if(dataRes.status === 404) {
                // doToast(dataRes.error.message, "fail");
                props.setSalesTalkErrorServer("There’s something wrong, please contact our support for help.");
            }else {
                if(dataRes.error.message !== "") {
                    // doToast(dataRes.error.message, "fail");
                    setErrorData({
                        title: "Loading Error",
                        desc: dataRes.error.message
                    });
                }else {
                    setProductData(dataRes.data);
                }
            }
            
            setIsLoadingData(false);
        })
    }
    
    return (
        <div 
            className={`
                chat-room-sales-talk-product-modal
                ${props.salesTalkInbox.isShow ? "chat-room-sales-talk-product-modal-active" : ""}
            `}
        >   
            <div className={`chat-room-sales-talk-product-modal-title ${props.salesTalkErrorServer === "" ? "" : "chat-room-sales-talk-product-modal-title-no-border"}`}>
                <b> {props.salesTalkErrorServer !== "" ? "" : (!props.salesTalkNotConnect ? "Product List" : "Connect with SalesTalk")}</b> 
                <FiX 
                    onClick={() => {
                        let _salesTalk = {...props.salesTalkInbox};
                        _salesTalk.isShow = false;
                        props.setSalestalkSelectedProduct({
                            isShow: false,
                            data: {},
                            lastSend: false,
                            isFromSelectedModal: false
                        });
                        props.setSalesTalkInbox(_salesTalk);
                        props.clearSalestalkProductFilter();
                    }}
                />
            </div>

            {!props.salesTalkNotConnect ?
                (props.salesTalkErrorServer !== "" ?
                    <div className="salestalk-error-server-wrapper">
                        <div className="salestalk-error-server-content">
                            <img src={ExclamationRed} alt="" />

                            <p className="desc-2">
                                {props.salesTalkErrorServer}
                            </p>

                            <ButtonWithLoadingOrIcon 
                                text="Contact Support"
                                className="orange-button main-button-48"
                                onClickAction={() => {
                                    window.open(
                                        "https://api.whatsapp.com/send?phone=6282180885808&text=Halo%2C%20saya%20ingin%20berdiskusi%20tentang%20kesalahan%20yang%20terjadi%20pada%20salestalk%20inbox",
                                        "_blank"
                                    )
                                }}
                            />
                        </div>
                    </div>
                    :
                    <>
                        
                        <SalesTalkProductTopComp 
                            categoryList={categoryList}
                            isLoadingData={isLoadingData}
                            getProductList={getProductList}
                            productData={productData}
                            errorData={errorData}
                        />
                        
                        {errorData.title !== "" ?
                            <SalesTalkErrorProductComp 
                                withIconWarning
                                title={errorData.title}
                                desc={errorData.desc}
                            />
                            :
                            (isLoadingData ?
                                <SalesTalkShimmerProductComp />
                                :
                                <SalesTalkProductComp
                                    productData={productData}
                                />
                            )
                        }
                    </>
                )
                :
                <div className="salestalk-not-connect-wrapper">
                    <h3>
                        The Easiest Way to Sell on Social Media 
                    </h3>

                    <p className="desc-1">
                        Simple solution to boost your sales!
                    </p>

                    <img src={salesTalkNotConnect} alt="" />

                    <p className="desc-2">
                        Recommend your products in the easiest way possible. 
                        Connect your product catalog and send product link directly via OneTalk
                    </p>

                    <ButtonWithLoadingOrIcon 
                        text="Get Free Consultation"
                        className="orange-button main-button-48"
                        onClickAction={() => {
                            window.open(
                                "https://api.whatsapp.com/send?phone=6282180885808&text=Hello%20TapTalk.io%20team%2C%20saya%20ingin%20berdiskusi%20lebih%20lanjut%20mengenai%20produk%20SalesTalk%20yang%20tertera%20pada%20Inbox%20OneTalk",
                                "_blank"
                            )
                        }}
                    />
                </div>
            }
        </div>
    )
}

let mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    salesTalkInbox: state.salesTalkInbox,
    salesTalkProductFilter: state.salesTalkProductFilter,
    salesTalkNotConnect: state.salesTalkNotConnect,
    salesTalkErrorServer: state.salesTalkErrorServer,
    salesTalkSelectedProduct: state.salesTalkSelectedProduct
})

let mapDispatchToProps = {
    setSalesTalkInbox,
    setSalestalkProductFilter,
    setSalesTalkNotConnect,
    setSalesTalkErrorServer,
    setSalestalkSelectedProduct,
    clearSalestalkProductFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomSalesTalkProductModal);