import React, { Component } from 'react';
import '../Auth.css';
import './Register.scss';
import { ToastContainer } from "react-toastify";
import { Dropdown, DropdownToggle, Modal, ModalBody } from 'reactstrap';
import Logo from "../../../assets/img/logo-taptalk-white.svg";
import PaperPlaneLeft from "../../../assets/img/auth/img-paper-plane-left.svg";
import PaperPlaneRight from "../../../assets/img/auth/img-paper-plane-right.svg";
import IconFacebookMessenger from "../../../assets/img/auth/icon-fb-messenger-circle.svg";
import IconGoogle from "../../../assets/img/auth/icon-google-circle.svg";
import IconGoogleBusinessProfile from "../../../assets/img/auth/icon-gb-profile-circle.svg";
import IconInstagram from "../../../assets/img/auth/icon-instagram-circle.svg";
import IconMail from "../../../assets/img/auth/icon-mail-circle.svg";
import IconTelegram from "../../../assets/img/auth/icon-telegram-circle.svg";
import IconTokopedia from "../../../assets/img/auth/icon-tokopedia-circle.svg";
import IconWhatsAppSME from "../../../assets/img/auth/icon-whatsapp-circle.svg";
import IconWhatsAppBA from "../../../assets/img/auth/icon-whatsapp-ba-circle.svg";
import { Link } from "react-router-dom";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { FiAlertCircle, FiChevronDown, FiEye, FiEyeOff } from "react-icons/fi";
import ServiceAuthAccessToken from "../../../services/auth/ServiceAuthAccessToken";
import ServiceAuthRegister from '../../../services/auth/ServiceAuthRegister';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import CustomToastr from '../../reuseableComponent/customToastr/CustomToastr';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import flagGlobe from "../../../assets/img/flag-globe.svg";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGTag from "../../../helper/HelperGTag";
import HelperInput from "../../../helper/HelperInput";
import { scrollToClass } from "../../../helper/HelperGeneral";
import HelperQueryString from "../../../helper/HelperQueryString";
import CountryPickerModal from '../countryPickerModal/CountryPickerModal';
import { setAuthActive } from "../../../redux/actions/reduxActionAuth";
import { connect } from "react-redux";

const ERROR_MESSAGE_NUMBER = {
  length: "The combined country code and phone number must be between 7-15 digits",
  isNumeric: "Only numerics are allowed",
  isStartZero: "Phone number cannot start from 0"
}

const REG_NUMERIC = /^[0-9]*$/;

const style = {
  grayLabelButton: {
    color: "#19191999",
    cursor: "pointer",
    fontSize: "16px",
    marginLeft: "4px"
  },
  orangeLabelButton: {
    color: "#ff7e00",
    cursor: "pointer",
    fontSize: "16px",
    marginLeft: "4px"
  },
  overflowY: {
    overflowY: "hidden"
  },
  passwordStrength: {
    fontSize: 0,
    marginTop: '16px'
  },
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.toggleModalCreatingAccount = this.toggleModalCreatingAccount.bind(this);

    this.state = {
      showModalCreatingAccount: false,
      countryList: [],
      countryMap: {},
      fullName: "",
      email: "",
      password: "",
      rePassword: "",
      lowerCase: false,
      upperCase: false,
      number: false,
      specialCharacter: false,
      minimumCharacter: false,
      errorMessageFullName: "",
      errorMessageEmail: "",
      errorMessagePassword: "",
      errorMessageRePassword: "",
      isWaitForResponse: false,
      toastrText: "",
      toastrClassName: "",
      fieldAddRecipient: {
        country: {
          id: "1",
          countryCodeNumber: "62",
          callingCode: "62",
          iso2Code: "ID",
          countryCode: "IDN",
          iso3Code: "IDN",
          countryName: "Indonesia",
          commonName: "Indonesia",
          currencyCode: "IDR",
          countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png",
          flagIconURL: "https://storage.googleapis.com/9a3048-taptalk-prd-public/static/flags/ID@2x.png",
          isEnabled: true,
          isHidden: false,
          officialName: "Republic of Indonesia"
        },
        number: ""
      },
      countryListSearch: {
        keyword: "",
        countryList: []
      },
      isShowDropdownCountry: false,
      fullNameErrorInfoText: "",
      emailErrorInfoText: "",
      phoneErrorInfoText: "",
      passwordErrorInfoText: "",
      rePasswordErrorInfoText: "",
      peekPassword: false,
      peekRePassword: false
    };
  }

  componentDidMount() {
    HelperGTag.checkIfUrlContainAnalyticParam({
      setAnalyticQueryString: (queryString) => {
        this.setState({
          analyticQueryString: `?${queryString}`
        })
      },
      ifAnalyticExistInLocalStorage: () => {
        this.setState({
          analyticQueryString: `?${HelperGTag.generateQueryStringAnalytic()}`
        })
      }
    });

    // mixpanel.init(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY);
    this.getCountryList();

    let checkContainQsEmail = () => {
      let qs = HelperQueryString.parseQuerystring();

      if (qs["email"] && HelperInput.emailFormatValid(qs["email"])) {
        this.setState({
          email: qs["email"]
        })

        let el = document.querySelector("#email");

        el.disabled = true;
      }
    }

    checkContainQsEmail();
  }

  toggleDropdownCountry = () => {
    if (this.state.isWaitForResponse) {
      return;
    }
    this.setState(prevState => ({
      isShowDropdownCountry: !prevState.isShowDropdownCountry
    }))
  }

  loginClick = () => {
    if (this.getEmailFormErrorMessage() || this.getPasswordFormErrorMessage()) {
      this.showFormError();
      return;
    }

    this.setState({
      isWaitForResponse: true,
      emailErrorInfoText: "",
      passwordErrorInfoText: ""
    });

    let param = `${this.state.email.trim()}:${this.state.password}`;

    ServiceAuthAccessToken.postRequestAccessToken(param).then(response => {
      if (response.dataResult.status !== 200) {
        this.setState({
          isWaitForResponse: false
        });

        this.toggleModalCreatingAccount();

        if (response.dataResult.error.code === "40107") {
          this.setState({
            modalResendEmail: true
          })
        }
        else {
          let emailError = "";
          let passwordError = "";
          if (response.dataResult.error.code === "40103") {
            passwordError = "The email and password does not match";
          }
          else if (response.dataResult.error.code === "40106") {
            emailError = "Account is not found";
          }
          else {
            emailError = response.dataResult.error.message;
            passwordError = response.dataResult.error.message;
          }
          this.showFormError(emailError, passwordError);

          HelperGTag.dataLayerPush(
            Object.assign({ ...HelperGTag.basicParameter("login") }, {
              event: "action-login-failed",
              error_message: response.dataResult.error.message
            })
          )
        }
      } else {
        let _dataResult = { ...response.dataResult.data };

        HelperGTag.dataLayerPush(
          Object.assign({ ...HelperGTag.basicParameter("login") }, {
            event: "action-login-success",
          })
        )

        let runSetAuthActive = () => {
          this.props.setAuthActive({
            data: HelperCustomEncryptor.doEncrypt(_dataResult)
          });
        }

        if (response.dataResult.data.memberships.length === 0) {
          runSetAuthActive();

          setTimeout(() => {
            this.setState({
              isWaitForResponse: true,
            });
          }, 2400);

          setTimeout(function () {

            window.location.href = window.location.origin + "/organization/new";
          }, 2500);
        } else {
          // let organizationData = response.dataResult.data.memberships;

          // _dataResult.activeOrganization = organizationData[0].organization;

          localStorage.removeItem("OneTalk.tabKey");
          runSetAuthActive();
          window.location.href = window.location.origin + `/home`;
        }
      }
    })
  }

  getCountryList = () => {
    ServiceOrganization.postGetCountry((response) => {
      if (response.dataResult.error.message === "") {
        let data = response.dataResult.data;
        let newCountryList = [];
        let newCountryMap = {};
        let _country = () => {
          data.countries.map((value) => {
            let country = value;
            country.countryCodeNumber = value.callingCode;
            country.countryCode = value.iso3Code;
            country.countryName = value.commonName;
            country.countryFlag = value.flagIconURL;
            country.countryLabel = `${value.commonName} (+${value.callingCode})`;

            newCountryList.push(country);

            let initial = value.commonName[0];

            if (newCountryMap[initial]) {
              newCountryMap[initial].push(country);
            }
            else {
              newCountryMap[initial] = [country];
            }
            return null;
          })
        }

        _country();

        this.setState({
          countryList: newCountryList,
          countryMap: newCountryMap,
          countryListSearch: {
            keyword: "",
            countryList: newCountryList,
          }
        });
      } else {
        console.log(response.dataResult.error.message);
      }
    })
  };

  toggleModalCreatingAccount() {
    this.setState(prevState => ({
      showModalCreatingAccount: !prevState.showModalCreatingAccount
    }))
  }

  renderModalCreatingAccount() {
    return (
      <Modal isOpen={this.state.showModalCreatingAccount} className="modal-creating-account">
        <ModalBody>
          <div className='lds-ring'>
            <div /><div /><div /><div />
          </div>

          <p className='modal-title-text'>
            <b>Creating your account...</b>
          </p>

          <p className='modal-desc-text'>
            While you wait, verify your account from an email sent to your registered account <b>{this.state.email}</b>
          </p>
        </ModalBody>
      </Modal>
    )
  }

  render() {
    let onChangeSelectCountry = (data) => {
      let _fieldAddRecipient = { ...this.state.fieldAddRecipient };
      _fieldAddRecipient.country = data;
      this.setState({
        fieldAddRecipient: _fieldAddRecipient
      });
      // this.toggleDropdownCountry();
    }

    let onChangePhoneNumber = (e) => {
      let _fieldAddRecipient = { ...this.state.fieldAddRecipient };
      _fieldAddRecipient.number = HelperInput.phoneBeautify(e.target.value.replaceAll(" ", ""));
      this.setState({
        fieldAddRecipient: _fieldAddRecipient
      }, this.checkAndShowPhoneError);
    }

    let renderPhoneNumberForm = () => {
      return (
        <>
          <div className={`phone-number-form${this.state.isWaitForResponse ? ` loading` : ``} ${this.state.phoneErrorInfoText !== "" ? "fail-state-input" : ""}`}>
            <div className="area-code-wrapper">
              <Dropdown isOpen={false/*this.state.isShowDropdownCountry && !this.state.isWaitForResponse*/} toggle={this.toggleDropdownCountry}>
                <DropdownToggle className={`value-country-code-wrapper ${this.state.isWaitForResponse ? `loading` : ``}`}>
                  <img
                    src={this.state.fieldAddRecipient.country.flagIconURL}
                    alt="" className="country-flag"
                    onError={(e) => { e.target.onerror = null; e.target.src = flagGlobe; }}
                  />
                  <FiChevronDown className={this.state.isShowDropdownCountry ? "chevron-up" : ""} />
                  <p>+{this.state.fieldAddRecipient.country.callingCode}</p>
                </DropdownToggle>
              </Dropdown>
            </div>

            <div className={`phone-wrapper`}>
              <input
                type="text"
                placeholder="8XXX XXXX XXX"
                maxLength="15"
                value={this.state.fieldAddRecipient.number}
                onChange={(e) => onChangePhoneNumber(e)}
                className={this.state.phoneErrorInfoText !== "" ? "error-input-message" : ""}
                disabled={this.state.isWaitForResponse}
              />
            </div>
          </div>
          {
            this.state.phoneErrorInfoText &&
            <div className="input-error-info">
              <FiAlertCircle />
              <b>{this.state.phoneErrorInfoText}</b>
            </div>
          }
        </>
      )
    }

    return (
      <div id="outer-container" className="register-outer-wrapper">
        {this.renderModalCreatingAccount()}
        <ToastContainer className="ToastContainer" />
        <CustomToastr toastrText={this.state.toastrText} toastrClassName={this.state.toastrClassName} />
        <CountryPickerModal
          isOpen={this.state.isShowDropdownCountry && !this.state.isWaitForResponse}
          onSelect={onChangeSelectCountry}
          toggle={this.toggleDropdownCountry}
          value={this.state.fieldAddRecipient.country}
          countryList={this.state.countryMap}
          countryListArray={this.state.countryList}
        />

        <img alt="" src={PaperPlaneLeft} className="bg-img-paper-plane-left" />
        <img alt="" src={PaperPlaneRight} className="bg-img-paper-plane-right" />
        <img alt="" src={IconWhatsAppSME} className="bg-img-whatsapp-sme" />
        <img alt="" src={IconTelegram} className="bg-img-telegram" />
        <img alt="" src={IconGoogleBusinessProfile} className="bg-img-gb-profile" />
        <img alt="" src={IconGoogle} className="bg-img-google" />
        <img alt="" src={IconInstagram} className="bg-img-instagram" />
        <img alt="" src={IconFacebookMessenger} className="bg-img-fb-messenger" />
        <img alt="" src={IconMail} className="bg-img-mail" />
        <img alt="" src={IconTokopedia} className="bg-img-tokopedia" />
        <img alt="" src={IconWhatsAppBA} className="bg-img-whatsapp-ba" />

        <div className="wrapper-auth-v2">
          <img src={Logo} alt="TapTalk Logo" className="logo-image" />
          <p className="subtitle">Omnichannel Chat Platform</p>
          <div className="wrapper-form-center">
            <div className="content-wrapper regis-top">
              <div className="header-centered-content">
                <div className="content-title">
                  <b>Register</b>
                </div>

                <div className="content-subtitle">
                  It seems that this is your first time using OneTalk,
                  <br />
                  please fill out the informations needed below.
                </div>
              </div>

              <form onSubmit={this.registerClick}>
                <div className={`input-wrapper${this.state.isWaitForResponse ? `-loading` : ``}`}>
                  <label><b>Full Name</b></label>
                  <input
                    type="text"
                    id="fullName"
                    onChange={this.handleFullNameChange}
                    placeholder="Type your full name"
                    autoComplete="false"
                    className={this.state.fullNameErrorInfoText ? 'fail-state-input' : ''}
                    disabled={this.state.isWaitForResponse}
                  />
                  {
                    this.state.fullNameErrorInfoText &&
                    <div className="input-error-info">
                      <FiAlertCircle />
                      <b>{this.state.fullNameErrorInfoText}</b>
                    </div>
                  }
                </div>

                <div className={`input-wrapper${this.state.isWaitForResponse ? `-loading` : ``}`}>
                  <label><b>Email</b></label>
                  <input
                    type="text"
                    id="email"
                    onChange={this.handleEmailChange}
                    placeholder="your@email.com"
                    autoComplete="false"
                    value={this.state.email}
                    className={this.state.emailErrorInfoText ? 'fail-state-input input-email' : 'input-email'}
                    disabled={this.state.isWaitForResponse}
                  />
                  {
                    this.state.emailErrorInfoText &&
                    <div className="input-error-info">
                      <FiAlertCircle />
                      <b>{this.state.emailErrorInfoText}</b>
                    </div>
                  }
                </div>

                <div className={`input-wrapper${this.state.isWaitForResponse ? `-loading` : ``}`}>
                  <label><b>Phone Number</b></label>
                  {renderPhoneNumberForm()}
                </div>

                <div className={`input-wrapper${this.state.isWaitForResponse ? `-loading` : ``}`}>
                  <label><b>Password</b></label>
                  <input
                    type={this.state.peekPassword ? "text" : "password"}
                    id="password"
                    onChange={this.handlePassword}
                    placeholder="Type your password"
                    autoComplete="false"
                    className={this.state.passwordErrorInfoText ? 'fail-state-input' : ''}
                    disabled={this.state.isWaitForResponse}
                  />
                  <div
                    className={`peek-password ${this.state.peekPassword ? 'active-eye' : ''}`}
                    onClick={this.peekPasswordClick}
                  >
                    {
                      this.state.peekPassword ?
                        <FiEye />
                        :
                        <FiEyeOff />
                    }
                  </div>
                  {
                    this.state.passwordErrorInfoText &&
                    <div className="input-error-info">
                      <FiAlertCircle />
                      <b>{this.state.passwordErrorInfoText}</b>
                    </div>
                  }
                </div>

                <div className={`input-wrapper${this.state.isWaitForResponse ? `-loading` : ``} password-strength`} style={style.passwordStrength}>
                  <div className="div50">
                    <div className="checkLowerCase">
                      {
                        this.state.isWaitForResponse ?
                          <FaCheckCircle size={16} color="#19191966" /> :
                          this.state.lowerCase ?
                            <FaCheckCircle size={16} color="#7EC82A" /> :
                            <FaRegCircle size={16} color="#A4A4A4" />
                      }
                      One lowercase character
                    </div>
                    <div className="checkUpperCase">
                      {
                        this.state.isWaitForResponse ?
                          <FaCheckCircle size={16} color="#19191966" /> :
                          this.state.upperCase ?
                            <FaCheckCircle size={16} color="#7EC82A" /> :
                            <FaRegCircle size={16} color="#A4A4A4" />
                      }
                      One uppercase character
                    </div>
                    <div className="checkNumber">
                      {
                        this.state.isWaitForResponse ?
                          <FaCheckCircle size={16} color="#19191966" /> :
                          this.state.number ?
                            <FaCheckCircle size={16} color="#7EC82A" /> :
                            <FaRegCircle size={16} color="#A4A4A4" />
                      }
                      One number
                    </div>
                  </div>

                  <div className="div50">
                    <div className="checkSpecialCharacter">
                      {
                        this.state.isWaitForResponse ?
                          <FaCheckCircle size={16} color="#19191966" /> :
                          this.state.specialCharacter ?
                            <FaCheckCircle size={16} color="#7EC82A" /> :
                            <FaRegCircle size={16} color="#A4A4A4" />
                      }
                      One special character
                    </div>
                    <div className="checkMinimumCharacter">
                      {
                        this.state.isWaitForResponse ?
                          <FaCheckCircle size={16} color="#19191966" /> :
                          this.state.minimumCharacter ?
                            <FaCheckCircle size={16} color="#7EC82A" /> :
                            <FaRegCircle size={16} color="#A4A4A4" />
                      }
                      8 characters minimum
                    </div>
                  </div>
                </div>

                <div className={`input-wrapper${this.state.isWaitForResponse ? `-loading` : ``}`}>
                  <label><b>Re-enter Password</b></label>
                  <input
                    type={this.state.peekRePassword ? "text" : "password"}
                    id="rePassword"
                    onChange={this.handleRePasswordChange}
                    placeholder="Type your password again"
                    autoComplete="false"
                    className={this.state.rePasswordErrorInfoText ? 'fail-state-input' : ''}
                    disabled={this.state.isWaitForResponse}
                  />
                  <div
                    className={`peek-password ${this.state.peekRePassword ? 'active-eye' : ''}`}
                    onClick={this.peekRePasswordClick}
                  >
                    {
                      this.state.peekRePassword ?
                        <FiEye />
                        :
                        <FiEyeOff />
                    }
                  </div>
                  {
                    this.state.rePasswordErrorInfoText &&
                    <div className="input-error-info">
                      <FiAlertCircle />
                      <b>{this.state.rePasswordErrorInfoText}</b>
                    </div>
                  }
                </div>

                {
                  !this.state.isWaitForResponse ?
                    <ButtonWithLoadingOrIcon
                      gtmevent={JSON.stringify(Object.assign({ ...HelperGTag.basicParameter("register") },
                        {
                          event: "action-register-button-click"
                        }
                      ))}
                      className="login-button main-button-48 width-100"
                      text="Register"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingGray
                      className="gray-button-disabled main-button-48 width-100"
                      text="Register"
                      position="left"
                      disabled={true}
                    />
                }
              </form>

              <div id="sign-in">
                <b>
                  Already have an account?
                  <Link
                    to={`login${window.location.search}`}
                    style={this.state.isWaitForResponse ? style.grayLabelButton : style.orangeLabelButton}
                    onClick={(e) => {
                      if (this.state.isWaitForResponse) {
                        e.preventDefault();
                      }
                    }
                    }
                    gtmevent={JSON.stringify(Object.assign({ ...HelperGTag.basicParameter("register") },
                      {
                        event: "button-click-login",
                        source: "register"
                      }
                    ))}
                  >
                    Log In
                  </Link>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleFullNameChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.checkAndShowFullNameError);
  }

  handleEmailChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.checkAndShowEmailError);
  }

  handleRePasswordChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.checkAndShowRePasswordError);
  }

  peekPasswordClick = () => {
    this.setState({
      peekPassword: !this.state.peekPassword
    });
  }

  peekRePasswordClick = () => {
    this.setState({
      peekRePassword: !this.state.peekRePassword
    });
  }

  getFullNameFormErrorMessage() {
    if (!this.state.fullName || this.state.fullName.length <= 0) {
      return "This field is required";
    }
    return "";
  }

  getEmailFormErrorMessage() {
    if (!this.state.email || this.state.email.length <= 0) {
      return "This field is required";
    }
    else if (!this.state.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return "Invalid email address. Please try another email";
    }
    return "";
  }

  getPhoneFormErrorMessage() {
    let errorMessage = "";
    let phoneLength = (this.state.fieldAddRecipient.country.callingCode + this.state.fieldAddRecipient.number.replaceAll(" ", "")).length;

    if (!this.state.fieldAddRecipient.country.callingCode ||
      !this.state.fieldAddRecipient.number ||
      this.state.fieldAddRecipient.country.callingCode.length <= 0 ||
      this.state.fieldAddRecipient.number.replaceAll(" ", "").length <= 0
    ) {
      errorMessage = "This field is required";
    }
    else if (this.state.fieldAddRecipient.number[0] === "0") {
      errorMessage = ERROR_MESSAGE_NUMBER.isStartZero;
    }
    else if (phoneLength < 7 || phoneLength > 15) {
      errorMessage = ERROR_MESSAGE_NUMBER.length;
    }
    else if (!REG_NUMERIC.test(this.state.fieldAddRecipient.number.replaceAll(" ", ""))) {
      errorMessage = ERROR_MESSAGE_NUMBER.isNumeric;
    }
    return errorMessage;
  }

  passwordStrengthCheck = (skipRePasswordCheck) => {
    return (
      this.state.lowerCase &&
      this.state.upperCase &&
      this.state.number &&
      this.state.specialCharacter &&
      this.state.minimumCharacter &&
      (skipRePasswordCheck || this.state.password === this.state.rePassword)
    );
  }

  getPasswordFormErrorMessage() {
    if (!this.state.password || this.state.password.length <= 0) {
      return "This field is required";
    }
    else if (!this.passwordStrengthCheck(true)) {
      return "Password does not meet requirements";
    }
    return "";
  }

  getRePasswordFormErrorMessage() {
    if (!this.state.password || this.state.password.length <= 0) {
      return "";
    }
    if (!this.state.rePassword || this.state.rePassword.length <= 0) {
      return "This field is required";
    }
    else if (this.state.password !== this.state.rePassword) {
      return "Your password does not match";
    }
    return "";
  }

  handlePassword = event => {
    let value = event.target.value;
    this.setState({
      password: value
    }, this.checkAndShowPasswordError);

    let setStatePasswordCheck = (id, value) => {
      this.setState({
        [id]: value
      });
    };

    // Lowercase
    if ((/[a-z]/.test(value))) {
      setStatePasswordCheck("lowerCase", true);
    }
    else {
      setStatePasswordCheck("lowerCase", false);
    }

    // Uppercase
    if ((/[A-Z]/.test(value))) {
      setStatePasswordCheck("upperCase", true);
    }
    else {
      setStatePasswordCheck("upperCase", false);
    }

    // Number
    if ((/[0-9]/.test(value))) {
      setStatePasswordCheck("number", true);
    }
    else {
      setStatePasswordCheck("number", false);
    }

    // Special character
    if (!(/^[a-zA-Z0-9]*$/).test(value)) {
      setStatePasswordCheck("specialCharacter", true);
    }
    else {
      setStatePasswordCheck("specialCharacter", false);
    }

    // 8 minimum character
    if (value.length > 7) {
      setStatePasswordCheck("minimumCharacter", true);
    }
    else {
      setStatePasswordCheck("minimumCharacter", false);
    }
  }

  setErrorMessageForm = (state, message) => {
    this.setState({
      [state]: message
    });
  }

  checkAndShowFullNameError() {
    if (this.state.fullNameErrorInfoText) {
      this.setState({
        fullNameErrorInfoText: this.getFullNameFormErrorMessage()
      });
    }
  }

  checkAndShowEmailError() {
    if (this.state.emailErrorInfoText) {
      this.setState({
        emailErrorInfoText: this.getEmailFormErrorMessage()
      });
    }
  }

  checkAndShowPhoneError() {
    if (this.state.phoneErrorInfoText) {
      this.setState({
        phoneErrorInfoText: this.getPhoneFormErrorMessage()
      });
    }
  }

  checkAndShowPasswordError() {
    if (this.state.passwordErrorInfoText) {
      this.setState({
        passwordErrorInfoText: this.getPasswordFormErrorMessage()
      });
    }
    this.checkAndShowRePasswordError();
  }

  checkAndShowRePasswordError() {
    if (this.state.rePasswordErrorInfoText) {
      this.setState({
        rePasswordErrorInfoText: this.getRePasswordFormErrorMessage()
      });
    }
  }

  showFormError(
    fullNameError,
    emailError,
    phoneError,
    passwordError,
    rePasswordError
  ) {
    this.setState({
      fullNameErrorInfoText: fullNameError ? fullNameError : this.getFullNameFormErrorMessage(),
      emailErrorInfoText: emailError ? emailError : this.getEmailFormErrorMessage(),
      phoneErrorInfoText: phoneError ? phoneError : this.getPhoneFormErrorMessage(),
      passwordErrorInfoText: passwordError ? passwordError : this.getPasswordFormErrorMessage(),
      rePasswordErrorInfoText: rePasswordError ? rePasswordError : this.getRePasswordFormErrorMessage()
    });
  }

  registerClick = (e) => {
    e.preventDefault();

    let qs = HelperQueryString.parseQuerystring();

    if (this.getFullNameFormErrorMessage() ||
      this.getEmailFormErrorMessage() ||
      this.getPhoneFormErrorMessage() ||
      this.getPasswordFormErrorMessage() ||
      this.getRePasswordFormErrorMessage()
    ) {
      this.showFormError();
      return;
    } else {
      this.toggleModalCreatingAccount();
    }

    this.setState({
      isWaitForResponse: true
    });

    let form = {
      fullname: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
      countryID: parseInt(this.state.fieldAddRecipient.country.id),
      phone: this.state.fieldAddRecipient.number.replaceAll(" ", ""),
      fromURL: window.location.href,
      utmSource: qs.utm_source !== undefined ? decodeURIComponent(qs.utm_source) : qs.utm_source,
      utmMedium: qs.utm_medium !== undefined ? decodeURIComponent(qs.utm_medium) : qs.utm_medium,
      utmCampaign: qs.utm_campaign !== undefined ? decodeURIComponent(qs.utm_campaign) : qs.utm_campaign,
      utmTerm: qs.utm_term !== undefined ? decodeURIComponent(qs.utm_term) : qs.utm_term,
      utmContent: qs.utm_content !== undefined ? decodeURIComponent(qs.utm_content) : qs.utm_content
    }

    ServiceAuthRegister.postRegisterAccount(form).then(response => {
      this.setState({
        isWaitForResponse: false
      });

      if (response.dataResult.error.message === "") {
        // mixpanel.track(
        //   "[Action] Register",
        //   {
        //     email: this.state.email,
        //     userFullName: this.state.fullName
        //   }
        // )

        HelperGTag.dataLayerPush(
          Object.assign({ ...HelperGTag.basicParameter("register") }, {
            event: "action-register-success",
            email: this.state.email
          })
        )

        this.loginClick();

        // this.props.history.push({
        //   pathname: '/verify/register-account',
        //   state: { 
        //     email: this.state.email
        //   }
        // });
      }
      else {
        HelperGTag.dataLayerPush(
          Object.assign({ ...HelperGTag.basicParameter("register") }, {
            event: "action-register-failed",
            error_message: response.dataResult.error.message
          })
        )

        // doToast(response.dataResult.error.message, "fail")

        this.setState({
          emailErrorInfoText: response.dataResult.error.message
        })

        this.toggleModalCreatingAccount();
        scrollToClass(`.regis-top`)
      }
    })
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  setAuthActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register)
