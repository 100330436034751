import React from 'react';
import './Blocking401.scss';
import Icon401 from '../../../assets/img/icon-401.svg';
import ButtonWithLoadingOrIcon from '../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';


let Blocking401 = (props) => {
    return (
        <div className='blocking-401-wrapper'>
            <img src={Icon401} alt="" />

            <p>
                <b>
                    You do not have the authorization to view this page.
                </b>
            </p>

            <ButtonWithLoadingOrIcon 
                className="orange-button main-button-40"
                onClickAction={() => props.history.push(`/o/${props.match.params.orgID}/home`)}
                text="Take Me Back Home"
            />
        </div>
    );
};

export default Blocking401;