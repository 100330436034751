import React, { useState } from "react";
import './SalesTalkProductTopComp.scss';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { setSalesTalkInbox } from "../../../../../redux/actions/reduxActionSalesTalkInbox";
import { setSalestalkSelectedProduct } from "../../../../../redux/actions/reduxActionSalestalkSelectedProduct";
import { setSalestalkProductFilter } from "../../../../../redux/actions/reduxActionSalestalkProductFilter";
import SearchBox from "../../../../reuseableComponent/searchBox/SearchBox";
import SortBy from "../../../../reuseableComponent/sortBy/SortBy";
import { connect } from 'react-redux';

const SORT_BY_SALES_TALK = [
    {
        label: "ID",
        value: "id"
    },
    {
        label: "Name",
        value: "name"
    },
    {
        label: "Category",
        value: "category"
    },
    {
        label: "Unit Price",
        value: "unitPrice"
    }
]

let SalesTalkProductTopComp = (props) => {
    let { categoryList, isLoadingData, getProductList, productData, salesTalkProductFilter, setSalestalkProductFilter, errorData } = props;
    // let [querySearch, setQuerySearch] = useState("");
    let [isShowSortBy, setIsShowSortBy] = useState(false);

    let onChangeSearch = (val) => {
        // setQuerySearch(val);
        let _salesTalkProductFilter = {...salesTalkProductFilter};
        _salesTalkProductFilter.search = val;
        props.setSalestalkProductFilter(_salesTalkProductFilter);
    }

    let handleOnSubmitSearch = () => {
        let _salesTalkProductFilter = {...salesTalkProductFilter};
        // _salesTalkProductFilter.search = querySearch;
        _salesTalkProductFilter.pageNumber = 1;
        
        getProductList(_salesTalkProductFilter);
        setSalestalkProductFilter(_salesTalkProductFilter);
    }

    let handleClearSearch = () => {
        // setQuerySearch("");
        let _salesTalkProductFilter = {...salesTalkProductFilter};
        _salesTalkProductFilter.search = "";
        
        getProductList(_salesTalkProductFilter);
        setSalestalkProductFilter(_salesTalkProductFilter);
    }

    let toggleSortBy = () => {
        setIsShowSortBy(!isShowSortBy);
    }

    let actionSwitchSortBy = (e) => {
        let _salesTalkProductFilter = {...salesTalkProductFilter};
        _salesTalkProductFilter.sortBy = e;
        _salesTalkProductFilter.pageNumber = 1;
        
        getProductList(_salesTalkProductFilter);
        setSalestalkProductFilter(_salesTalkProductFilter);
    }

    let showSelectedProduct = () => {
        let _salesTalkSelectedProduct = {...props.salesTalkSelectedProduct};
        _salesTalkSelectedProduct.isShow = true;
        props.setSalestalkSelectedProduct(_salesTalkSelectedProduct);
    }

    let generatePageEnd = () => {
        let result = "";
        result = Math.ceil(productData.totalItems/salesTalkProductFilter.pageSize);
        
        return result;
    }

    let generateItemStart = () => {
        let result = "";
        result = salesTalkProductFilter.pageSize === 1 ? 1 : (((salesTalkProductFilter.pageNumber - 1) * salesTalkProductFilter.pageSize) + 1)
        return result;
    }

    let generateItemEnd = () => {
        let result = "";
        result = salesTalkProductFilter.pageNumber * salesTalkProductFilter.pageSize;

        if(result > productData.totalItems) {
            result = productData.totalItems;
        }
        return result;
    }

    let pagePrev = () => {
        if(!isLoadingData) {
            let _salesTalkProductFilter = {...salesTalkProductFilter};
            _salesTalkProductFilter.pageNumber = _salesTalkProductFilter.pageNumber - 1;
            
            getProductList(_salesTalkProductFilter);
            setSalestalkProductFilter(_salesTalkProductFilter);
        }
    }

    let pageNext = () => {
        if(!isLoadingData) {
            let _salesTalkProductFilter = {...salesTalkProductFilter};
            _salesTalkProductFilter.pageNumber = _salesTalkProductFilter.pageNumber + 1;
            
            getProductList(_salesTalkProductFilter);
            setSalestalkProductFilter(_salesTalkProductFilter);
        }
    }

    let onClickCategory = (id) => {
        if(!isLoadingData) {
            let _salesTalkProductFilter = {...salesTalkProductFilter};
            _salesTalkProductFilter.categoryID = id;
            _salesTalkProductFilter.pageNumber = 1;
            
            getProductList(_salesTalkProductFilter);
            setSalestalkProductFilter(_salesTalkProductFilter);
        }
    }

    let clickScrollLeft = () => {
        let el = document.getElementById("sales-talk-category-wrapper");
        el.scrollLeft -= 20;
    }   

    let clickScrollRight = () => {
        let el = document.getElementById("sales-talk-category-wrapper");
        el.scrollLeft += 20;
    }

    return (
        <div 
            className={`
                chat-room-sales-talk-product-top
            `}
        >   
            {/* search */}
            <div className="sales-talk-search-product-wrapper">
                <SearchBox
                    placeholder={"Search"}
                    onChangeInputSearch={onChangeSearch}
                    searchValue={props.salesTalkProductFilter.search}
                    isWaitResponse={false}
                    onSubmitSearch={handleOnSubmitSearch}
                    onClearSearch={handleClearSearch}
                    noClearText
                />
            </div>
            {/* search */}

            {/* category */}
            <div className="sales-talk-category-wrapper-outer taplive-shine">
                <div className="scroll-left-wrapper" onClick={clickScrollLeft}>
                    <FiChevronLeft />
                </div>

                <div className="scroll-right-wrapper" onClick={clickScrollRight}>
                    <FiChevronRight />
                </div>
                
                <div className="sales-talk-category-wrapper" id="sales-talk-category-wrapper">
                    {categoryList.length > 0 &&
                        categoryList.map((v, i) => {
                            return (
                                <div 
                                    className={`
                                        sales-talk-category-selection 
                                        ${isLoadingData ? "sales-talk-category-selection-disabled" : "" }
                                        ${salesTalkProductFilter.categoryID === v.id ? "sales-talk-category-selection-active" : ""}
                                    `} 
                                    key={`sales-talk-category-selection-${i}`}
                                    onClick={() => onClickCategory(v.id)}
                                >
                                    <b>{v.name}</b>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {/* category */}

            {/* pagination */}
            <div className={`sales-talk-pagination-outer ${errorData.desc !== "" || productData.totalItems === 0 ? "force-hide" : ""}`}>
                <div className="sales-talk-pagination">
                    {errorData.desc === "" &&
                        (productData.totalItems ?
                                <div className="sales-talk-pagination-wrapper">
                                    <p className="total-products">
                                        <b>Products: <span>{generateItemStart()}</span> <span>-</span> <span>{generateItemEnd()}</span> <span>of</span> <span>{productData.totalItems}</span></b>
                                    </p>
                                    
                                    <p className="paging">
                                        {salesTalkProductFilter.pageNumber !== 1 &&
                                            <FiChevronLeft onClick={pagePrev} />
                                        }

                                        <b><span>{salesTalkProductFilter.pageNumber < 10 ? "0" + salesTalkProductFilter.pageNumber : salesTalkProductFilter.pageNumber}</span>/{generatePageEnd() < 10 ? "0"+generatePageEnd() :generatePageEnd()}</b>
                                        
                                        {salesTalkProductFilter.pageNumber !== generatePageEnd() &&
                                            <FiChevronRight onClick={pageNext} />
                                        }
                                    </p>
                                </div>
                                :
                                <div className="sales-talk-pagination-wrapper">
                                    <p className="total-products">
                                        <b>Products:</b>
                                    </p>
                                </div>
                        )
                    }
                </div>
            </div>
            {/* pagination */}

            {/* sort */}
            <div 
                className={`
                    sales-talk-sort-by-wrapper
                `}
            >
                <SortBy
                    isShowSortByOptionProps={isShowSortBy}
                    sortByVal={salesTalkProductFilter.sortBy}
                    sortByList={SORT_BY_SALES_TALK}
                    toggleSortByOpenCaseProps={toggleSortBy}
                    actionSwitchSortByProps={actionSwitchSortBy}
                    disabledSortByProps={isLoadingData}
                    valueWithObject
                />

                {/* {!isEmptyObject(props.salesTalkSelectedProduct.data) && */}
                    <p 
                        className={`
                            view-all-items 
                            ${(
                                errorData.desc !== "" ||
                                (productData.totalItems === 0 && salesTalkProductFilter.search === "")
                            ) ? 
                                "force-hide" 
                                : 
                                ""
                            }
                        `} 
                        onClick={() => {
                            if(Object.keys(props.salesTalkSelectedProduct.data).length > 0) {
                                showSelectedProduct()
                            }
                        }}
                    >
                        <b>View Selected Products <span>({Object.keys(props.salesTalkSelectedProduct.data).length})</span></b>
                    </p>
                {/* } */}
            </div>
            {/* sort */}
        </div>
    )
}

let mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    salesTalkInbox: state.salesTalkInbox,
    salesTalkSelectedProduct: state.salesTalkSelectedProduct,
    salesTalkProductFilter: state.salesTalkProductFilter,
    salesTalkErrorServer: state.salesTalkErrorServer
})

let mapDispatchToProps = {
    setSalesTalkInbox,
    setSalestalkSelectedProduct,
    setSalestalkProductFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTalkProductTopComp);