import React, { useState, useEffect } from "react";
import "../auth/Auth.css";
import "./Topic.css";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import topicServices from "../../services/newServices/TopicServices";
import { TopicsList, TopicsHead } from "./TopicsContent";
import TopicsAdd from "./TopicsAdd";
import { checkID } from "../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

// const fakeTopics = {
//   status: "OK",
//   data: [
//     {
//       id: 1,
//       name: "Technical",
//       createdTime: 1566455001340,
//       updatedTime: 0,
//       deletedTime: 0
//     },
//     {
//       id: 2,
//       name: "Billing",
//       createdTime: 1566455009213,
//       updatedTime: 0,
//       deletedTime: 0
//     }
//   ]
// };

const Topics = props => {
  const { match, history, location } = props;
  const [orgID, setOrgID] = useState(false);
  const [listTopic, setListTopic] = useState(false);
  const [popupAdd, setPopupAdd] = useState(false);
  const [waitList, setWaitList] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })

  const getTopic = async (orgID, mode) => {
    setWaitList(true);
    let _retriveDataError = {...retriveDataError};

    topicServices.getTopic(orgID, "list", null, (response) => {
      let dataResult = response.dataResult;
      
      const success = response.dataResult.status === 200;
      const hasError = response.dataResult.error && response.dataResult.error.code && response.dataResult.error.message;
      const createList = () => {
        if (success) {
          setListTopic({ status: "OK", data: response.dataResult.data.topics })
        }else if(hasError) {
          setListTopic({ status: "error", data: response.dataResult.error.message })
        }
        setWaitList(false);
      };
      
      if(response.dataResult.error.code === "") {
        if (mode === "reload") setTimeout(() => createList(), 500);
        createList();
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        setWaitList(false);
        // doToast(response.dataResult.error.message, "fail");
      }
    });
  };

  const reloadTopic = async () => {
    // setListTopic(false);
    setWaitList(true);
    // doToast(mode || "Success");
    await getTopic(orgID);
  };

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getTopic(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/topics`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  React.useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(orgID) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
        mixpanel.track(
            "[Page] Topics", 
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
}, [orgID])

  const togglePopupAdd = () => setPopupAdd(!popupAdd);

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="topic sectionWrap">
          <TopicsHead setPopupAdd={setPopupAdd} mainProps={props} isError={retriveDataError.code !== false} />
          <div className="bodyContent">
            {(waitList || retriveDataError.code) ?
                <RetrieveDataLoading 
                  isLoading={waitList}
                  errorMessage={retriveDataError.message} 
                />
                :
                <TopicsList
                  orgID={orgID}
                  listTopic={listTopic}
                  reloadTopic={reloadTopic}
                  history={history}
                />
            }
          </div>
        </div>

        <TopicsAdd
          isOpen={popupAdd}
          toggle={togglePopupAdd}
          orgID={orgID}
          success={reloadTopic}
          parentProps={props}
        />
      </SectionWrap>
    </React.Fragment>
  );
};

export default Topics;
