import React, { useRef, useState, useEffect } from 'react';
import './ChatRoomChatHistoryTab.scss';
import ChatRoomCaseHistoryTab from './chatRoomCaseHistoryTab/ChatRoomCaseHistoryTab';
import ChatRoomBroadcastHistoryTab from './chatRoomBroadcastHistoryTab/ChatRoomBroadcastHistoryTab';
import { doToast } from "../../../../../helper/HelperGeneral";
import UserContactServices from "../../../../../services/newServices/UserContactServices";

import { connect } from 'react-redux';

const SHOW_PANEL = {
  chat: "chat",
  broadcast: "broadcast"
}

var ChatRoomChatHistoryTab = (props) => {
  let [showPanel, setShowPanel] = useState("chat");
  let [countBroadcast, setCountBroadcast] = useState(0);
  let [caseHistoryData, setCaseHistoryData] = useState([]);
  let [loadingGetCaseHistory, setLoadingGetCaseHistory] = useState(true);
  let mainChatHistoryContent = useRef(null);

  useEffect(() => {
      getCaseHistory();
  }, [])
    
  let getCaseHistory = () => {
      setLoadingGetCaseHistory(true);

      let data = {
        "userID": props.caseDetailProps ? props.caseDetailProps.case.userID : "",
        "pageNumber": 1,
        "pageSize": 99999
      };
    
      UserContactServices.getCaseHistory(props.parentProps.match.params.orgID, data, (res) => {
        let response = res.dataResult;
          
        if(response.error.message === "") {
          setCaseHistoryData(response.data.cases);
        }else {
          doToast(res.error.message, "fail");
        }

        setLoadingGetCaseHistory(false);
      })
  }

  let runSetCountBroadcast = (val) => {
    setCountBroadcast(val)
  }

  let onClickSwitchPanel = (panel) => {
    setShowPanel(panel);
  }

  return (
      <div className="chat-room-chat-history-tab-wrapper">
        {/* switch */}
        <div className="chat-history-switch-content-outer">
          <div className="chat-history-switch-content">
              <div className={`chat-history-active-panel ${showPanel === SHOW_PANEL.broadcast ? "active-broadcast-panel" : ""}`}></div>

              <div className={`chat-history-switch-panel ${showPanel === SHOW_PANEL.chat ? "active-broadcast-panel-tab" : ""}`} onClick={() => onClickSwitchPanel(SHOW_PANEL.chat)}>
                  <div><b>Cases <div className="centered-dot" /> {caseHistoryData.length > 99 ? "99+" : caseHistoryData.length}</b></div>
                  {/* <div className="counter-case"><b>{999}</b></div> */}
              </div>

              <div className={`chat-history-switch-panel ${showPanel === SHOW_PANEL.broadcast ? "active-broadcast-panel-tab" : ""}`} onClick={() => onClickSwitchPanel(SHOW_PANEL.broadcast)}>
                  <div><b>Broadcasts <div className="centered-dot" /> {countBroadcast > 99 ? "99+" : countBroadcast}</b></div>
                  {/* <div className="counter-case"><b>{10}</b></div> */}
              </div>
          </div>
        </div>
        {/* switch */}

        <div className="main-chat-history-content" ref={mainChatHistoryContent}>
          <ChatRoomCaseHistoryTab 
            {...props}
            mainChatHistoryContentProps={mainChatHistoryContent}
            caseDetailProps={props.caseDetailProps}
            className={`${showPanel !== SHOW_PANEL.chat ? 'hide-panel-chat-history' : ""}`}
            loadingGetCaseHistory={loadingGetCaseHistory}
            caseHistoryData={caseHistoryData}
          />
          
          <ChatRoomBroadcastHistoryTab 
            {...props} 
            runSetCountBroadcastProps={runSetCountBroadcast}
            className={`${showPanel !== SHOW_PANEL.broadcast ? 'hide-panel-chat-history' : ""}`}
          />
        </div>
      </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomChatHistoryTab);
