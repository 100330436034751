import React, { useEffect, useState } from "react";
import "./IntegrationWhatsappBusinessAddAccount.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiCheck } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import PopupInfo from "../../../reuseableComponent/popupInfo/PopupInfo";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
// import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import EnTranslation from "../../../../assets/lang/en";
import IdTranslation from "../../../../assets/lang/id";
import { checkID, doToast } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import iconEnLang from "../../../../assets/img/en-lang.svg";
import iconIdLang from "../../../../assets/img/id-lang.svg";
import { ToastContainer } from "react-toastify";
import FormQuestionnaire from "./formQuestionnaire/FormQuestionnaire";
import FormCompanyDetails from "./formCompanyDetails/FormCompanyDetails";
import FormRequesterInfo from "./formRequesterInfo/FormRequesterInfo";
import IntegrationWhatsappBusinessServices from "../../../../services/integration/IntegrationWhatsappBusinessServices";
import iconCheckGreen from "../../../../assets/img/icon-check-large-green-dark.svg";
import HelperDate from "../../../../helper/HelperDate";

const IntegrationWhatsappBusinessAddAccount = props => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [activeForm, setActiveForm] = useState(0);
    let [formLang, setFormLang] = useState("en");
    let [isShowModalSubmit, setIsShowModalSubmit] = useState(false);
    let [isLoadingWhatsappAccount, setIsLoadingWhatsappAccount] = useState(true);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [hasOngoingRequest, setHasOngoingRequestStatus] = useState(null);

    let toggleModalUnderReview = () => {
        setIsShowModalSubmit(!isShowModalSubmit);
    }

    let getLang = () => {
        return formLang === "en" ? EnTranslation : IdTranslation;
    }

    const FORM_LANG = {
     en: [EnTranslation.pageWaba.text1, EnTranslation.pageWaba.text2, EnTranslation.pageWaba.text3],
     id: [IdTranslation.pageWaba.text1, EnTranslation.pageWaba.text2, EnTranslation.pageWaba.text3],   
    };

    const LANG_OPTION = {
        en: {
            value: "en",
            img: iconEnLang
        },
        id: {
            value: "id",
            img: iconIdLang
        },
    }
    
    const back = () => history.push(`/o/${orgID}/integration/whatsapp-business-api`);

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration/whatsapp-business-api`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if(orgID) {
            IntegrationWhatsappBusinessServices.getRequestStatus(orgID, (response) => {
                let _retriveDataError = {...retriveDataError};
                let dataResult = response.dataResult;

                if(response.dataResult.error.message === "") {
                    let responseData = response.dataResult.data;
                    setHasOngoingRequestStatus(responseData.hasOngoing)
                    // setRequestTime(responseData.requestTime)
                }else {
                    _retriveDataError.message = dataResult.error.message;
                    _retriveDataError.code = dataResult.error.code;
                    setRetrieveDataError(_retriveDataError);
                    // doToast(response.dataResult.error.message, "fail");
                }
                setIsLoadingWhatsappAccount(false)
            })
        }
    }, [orgID])

    useEffect(() => {
        if(hasOngoingRequest !== null && hasOngoingRequest === true) {
            history.push({
                pathname: `/o/${orgID}/integration/whatsapp-business-api`,
                state: {hasNoBackButton: true}
            })
        }
    }, [hasOngoingRequest])

    // let getWhatsappAccount = () => {
    //     let _retriveDataError = {...retriveDataError};
    //     setIsLoadingWhatsappAccount(true);
    //     IntegrationWhatsappBusinessServices.getList(orgID, (response) => {
    //         let dataResult = response.dataResult;

    //         if(response.dataResult.status === 200) {
    //             setWhatsappAccountData(response.dataResult.data.channels);
    //             if(hasOngoingRequest ) {
    //                 history.push({
    //                     pathname: `/o/${orgID}/integration/whatsapp-business-api`,
    //                     state: {hasNoBackButton: true}
    //                 })
    //             }
    //         }else {
    //             setWhatsappAccountData([]);
    //             _retriveDataError.message = dataResult.error.message;
    //             _retriveDataError.code = dataResult.error.code;
    //             setRetrieveDataError(_retriveDataError);
    //         }

    //         setIsLoadingWhatsappAccount(false);
    //     })
    // }

    let printFormStep = () => {
        return (
            <div className="whatsapp-business-form-step">
                {FORM_LANG[formLang].map((value, index) => {
                    return (
                        <React.Fragment  key={`step-${index}`}>
                            <div className={`whatsapp-business-form-step-content ${index === activeForm ? "active-whatsapp-business-form-step" : ""}`}>
                                {index < activeForm ?
                                    <div className={`value-number value-check`}>
                                        <FiCheck />
                                    </div>
                                    :
                                    <div className={`value-number ${index !== activeForm ? "not-active-value-wrapper" : ""}`}>
                                        <b>{index + 1}</b>
                                    </div>
                                }

                                <b>{value}</b>
                            </div>

                            {index < 2  && <div className="line-separator" />}
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }

    let printLang = () => {
        return (
            <div className="integration-whatsapp-business-api-add-account-box-form-top-lang">
                {Object.keys(LANG_OPTION).map((_value, index) => {
                    return (
                        <div 
                            className={`lang-content ${formLang === LANG_OPTION[_value].value ? "active-lang-content" : ""}`}
                            onClick={() => setFormLang(LANG_OPTION[_value].value)}
                            key={`lang-${index}`}
                        >
                            <img src={LANG_OPTION[_value].img} alt="lang" />
                            {LANG_OPTION[_value].value}
                        </div>
                    )
                })}   
            </div>
        )
    }

    let goToForm = (to) => {
        setActiveForm(to);
    } 

    let [data, setData] = useState({
        requester: {
            fullName: "",
            email: "",
            phoneCountryID: "",
            phoneNumber: "",
            jobTitle: ""
        },
        company: {
            name: "",
            countryID: "",
            website: "",
            address: "",
            phone: "",
            email: "",
            vertical: "",
            verticalDropdown: {
                value: "",
                label: ""
            }
        },
        questionnaire: []
    })
    let processData = (form, submittedData, submit = false) => {
        let _data = {...data}
        _data[form] = submittedData

        setData(_data)
        if(submit) submitData(_data)
    }

    let [loadingSubmit, setLoadingSubmit] = useState(false)
    let submitData = (data) => {
        let _data = {...data};
        // _data.company.phone = _data.company.phone.split(" ");
        // _data.company.phone.shift();
        // _data.company.phone = _data.company.phone.join("");
        _data.requester.phoneNumber = _data.requester.phoneNumber.split(" ");
        _data.requester.phoneNumber.shift();
        _data.requester.phoneNumber = _data.requester.phoneNumber.join("");
        setLoadingSubmit(true)

        IntegrationWhatsappBusinessServices.addChannel(orgID, _data, (response) => {
            if(response.dataResult.error.message === "") {
                toggleModalUnderReview()
            } else {
                doToast(response.dataResult.error.message, "fail");
            }

            setLoadingSubmit(false)
        })
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                {isLoadingWhatsappAccount || retriveDataError.code ?
                    <RetrieveDataLoading
                        isLoading={isLoadingWhatsappAccount}
                        errorMessage={retriveDataError.message}
                    />
                    :
                    <>
                        <PopupInfo
                            isOpen={isShowModalSubmit}
                            toggle={toggleModalUnderReview}
                            titleImage={iconCheckGreen}
                            title={`Registration Submitted on ${HelperDate.formatToString(new Date(), "dd/MM/yyyy")}!`}
                            description={`We will contact you through email or phone once your submission has been apporved.`}
                            textButton={`Dismiss`}
                            actionButton={() => {history.push(`/o/${orgID}/integration/whatsapp-business-api`)}}
                        />
                        <div className="sectionWrap integration-main-container integration-whatsapp-business-api-add-account text-left">
                            {location.state && location.state.hasNoBackButton ? "" : <BackButton text="Back to WhatsApp Business API" onClick={back} />}

                            <div className="integration-whatsapp-business-api-add-account-box-top">
                                <b>Register your business to WhatsApp</b>

                                <p>
                                    In order to start using WhatsApp Business API, we'll need you to submit some information.
                                </p>
                            </div>

                            <div className="integration-whatsapp-business-api-add-account-box-form">
                                <div className="integration-whatsapp-business-api-add-account-box-form-top">
                                    {printFormStep()}
                                    {printLang()}
                                </div>

                                <div className="integration-whatsapp-business-api-add-account-box-form-tab">
                                    {activeForm === 0 && 
                                        <FormQuestionnaire 
                                            lang={getLang()} 
                                            goToForm={goToForm}
                                            submitData={processData}
                                            data={{...data}}
                                            _className={`integration-whatsapp-business-tab-content ${activeForm === 0 ? "active-tab" : ""}`}
                                        />
                                    }

                                    {activeForm === 1 && 
                                        <FormCompanyDetails 
                                            {...props}
                                            lang={getLang()} 
                                            goToForm={goToForm}
                                            submitData={processData}
                                            data={{...data}}
                                            _className={`integration-whatsapp-business-tab-content ${activeForm === 1 ? "active-tab" : ""}`} 
                                        />
                                    }

                                    {activeForm === 2 && 
                                        <FormRequesterInfo 
                                            lang={getLang()}
                                            loadingSubmit={loadingSubmit}
                                            goToForm={goToForm} 
                                            submitData={processData}
                                            data={{...data}}
                                            _className={`integration-whatsapp-business-tab-content ${activeForm === 2 ? "active-tab" : ""}`}
                                        />
                                    }
                                </div>

                            </div>
                        </div>
                    </>
                }
            </SectionWrap>

            <ToastContainer className="ToastContainer" />
        </div>
    )
}

export default IntegrationWhatsappBusinessAddAccount;