import React from "react";

const TDATableHead = ({ data, select, selected }) => {
  const hasSelected = () => {
    // return selected && selected.length > 0 && selected.length === data.length;
    return selected.length > 0;
  }

  const change = () => {
    if (data) {
      // const arrs = [...data];
      // if (hasSelected) {
      //   select([]);
      // } else {
      //   select(arrs);
      // }
      if (selected.length === 0) {
        select(data);
      } else {
        select([]);
      }
    }
  };

  return (
    <thead>
      <tr className="tcHeader">
        {data ?
          <td width="10%" className={`${data.length === selected.length ? "custom-checkbox" : "custom-checkbox-stripe"} tcColCheck`}>
            <input
              type="checkbox"
              id="checkAllAgent"
              onChange={change}
              checked={hasSelected()}
              disabled={!data ? "disabled" : false}
            />
            <label htmlFor="checkAllAgent"></label>
          </td>
          :
          <td width="10%" className={`custom-checkbox tcColCheck`}>
            <label htmlFor="checkAllAgent"></label>
          </td>
        }

        <td width="20%" className="tcCol">
          Fullname
        </td>
        <td width="20%" className="tcCol">
          Alias
        </td>
        <td width="50%" className="tcCol">
          Email
        </td>
      </tr>
    </thead>
  );
};

export default TDATableHead;
