const INITIAL_STATE = {
    state: false,
    alias: "",
    userID: "",
    fullname: "",
    email: "",
    phone: "",
    lastChange: ""
};

let reducerChangeContactInfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_CHANGE_CONTACT_INFO':
        return action.changeContactInfo;
      case 'CLEAR_CHANGE_CONTACT_INFO':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerChangeContactInfo;