const INITIAL_STATE = []

let reducerCaseLabelList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CASE_LABEL_LIST':
      return action.caseLabelList;
    default:
      return state;
  }
};

export default reducerCaseLabelList;