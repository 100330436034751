import React from "react";
import "./BillingNotificationAdd.scss";
import { FiTrash2, FiMail, FiPlus, FiInfo } from "react-icons/fi";
import { Modal, ModalBody } from "reactstrap";
import HelperInput from "../../../../../helper/HelperInput";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupConfirmation from "../../../../reuseableComponent/popupConfirmation/PopupConfirmation";

let BillingNotificationAdd = (props) => {
    let { 
        showModal, value, onChange, 
        toggle, onSubmit, onAddMore, 
        onDeleteClick, loadingAddBilling, 
        showModalSubmit, toggleModalSubmit, showModalMaxLimit,
        toggleModalMaxLimit, showModalPleaseAdd,toggleModalPleaseAdd,
        valueError, setValueError, billingNotifList
    } = props;

    let checkPleaseAdd = () => {
        let pass = true;

        value.map((v) => {
            if(v !== "") {
                pass = false;
            }

            return null;
        })

        return pass;
    }

    let checkSubmit = () => {
        let _valueError = valueError.slice();

        let pass = true;

        value.map((v, i) => {
            if(!HelperInput.emailFormatValid(v)) {
                _valueError[i] = true;
                pass = false;
            }else {
                _valueError[i] = false;
            }

            setValueError(_valueError);
            return null;
        })

        
        if(pass) {
            toggleModalSubmit();
        }
    }
    
    return (
        <>
            <PopupConfirmation
                isOpen={showModalSubmit}
                toggle={toggleModalSubmit}
                title="Add Recipient?"
                description="You are going to submit new emails as your Billing Recipient, are you sure you want to continue?"
                className="modal-add-email-notification"
            >
                {!loadingAddBilling ?
                    <ButtonWithLoadingOrIcon
                        text="Submit"
                        className="orange-button main-button-48 confirmation-button"
                        onClickAction={onSubmit}
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Submit"
                        className="main-button-48 confirmation-button orange-button"
                        isLoading
                        position="left"
                        loadingColor="gray"
                        isDisabled
                    />
                }
            </PopupConfirmation>

            <Modal isOpen={showModal} toggle={toggle} className="billing-notification-add-modal">
                <ModalBody>
                    <p className="modal-title">
                        <b>
                            Add Recipients <span>({value.length})</span>
                        </b>
                    </p>

                    <div className="email-input-wrapper">
                        <label>
                            <b>Email</b>
                        </label>
                        
                        <br />

                        {value.map((v, i) => {
                            return (
                                <React.Fragment key={`email-add-${i}`}>
                                    <div className="email-input-box">
                                        <FiMail className="mail-icon" />

                                        <input disabled={loadingAddBilling} type="text" value={v} onChange={(e) => onChange(e, i)} placeHolder={"user@email.com"} />

                                        {/* {i !== 0 && */}
                                            <FiTrash2 className="trash-icon" onClick={() => onDeleteClick(i)} />
                                        {/* } */}

                                        {valueError[i] &&
                                            <p className="red-text">
                                                <b><FiInfo /> Invalid email address</b>
                                            </p>
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        })}

                        {/* {value.length < 5 && */}
                            <div 
                                class="light-orange-button add-more-button" 
                                onClick={() => {
                                    if(value.length === 0) {
                                        onAddMore();
                                    }else {
                                        if((billingNotifList.length + value.length) >= 5) {
                                            toggleModalMaxLimit();
                                        }else {
                                            onAddMore();
                                        }
                                    }
                                }}
                            >
                                <FiPlus />
                                Add More
                            </div>
                        {/* } */}
                    </div>

                    <div className="add-recipient-button-wrapper">
                        <ButtonWithLoadingOrIcon 
                            className="no-fill-button main-button-40"
                            text={"Cancel"}
                            onClickAction={toggle}
                        />

                        <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-40"
                            text={"Submit"}
                            onClickAction={() => {
                                if(value.length > 1) {
                                    checkSubmit();
                                }else {
                                    if(checkPleaseAdd()) {
                                        toggleModalPleaseAdd();
                                    }else {
                                        checkSubmit();
                                    }
                                }
                            }}
                            // isDisabled={isDisabledSubmitAdd()}
                        />
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={showModalMaxLimit} className="billing-notification-max-limit" toggle={toggleModalMaxLimit}>
                <ModalBody>
                    <p className="modal-title">
                        <b>
                            Maximum Recipients
                        </b>
                    </p>

                    <p className="modal-desc">
                        You have reached the maximum recipient of five, please delete existing recipients if you wish to add new recipients.
                    </p>

                    <ButtonWithLoadingOrIcon 
                        className="orange-button main-button-48"
                        text={"OK"}
                        onClickAction={toggleModalMaxLimit}
                    />
                </ModalBody>
            </Modal>

            <Modal isOpen={showModalPleaseAdd} className="billing-notification-max-limit" toggle={toggleModalPleaseAdd}>
                <ModalBody>
                    <p className="modal-title">
                        <b>
                            Add Billing Recipients
                        </b>
                    </p>

                    <p className="modal-desc">
                        Please add at least one recipient before submitting, or you can cancel if you do not want to add any recipient.
                    </p>

                    <ButtonWithLoadingOrIcon 
                        className="orange-button main-button-48"
                        text={"OK"}
                        onClickAction={toggleModalPleaseAdd}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}

export default BillingNotificationAdd;