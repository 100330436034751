import React, { useState, useEffect } from 'react';
import './IntegrationTaptalkAddAccount.scss';
import 'react-toastify/dist/ReactToastify.css';
// import topicServices from '../../../../services/newServices/TopicServices';
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import SecretKeyServices from '../../../../services/newServices/SecretKeyServices';
import topicServices from '../../../../services/newServices/TopicServices';
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import CustomSelectOption from '../../../reuseableComponent/customSelectOption/CustomSelectOption';
import Tooltip from '../../../reuseableComponent/Tooltip/Tooltip';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

const PLATFORM = [
  {
    label: 'iOS',
    value: 'ios',
    placeholder: 'Your Bundle ID',
  },
  {
    label: 'Android',
    value: 'android',
    placeholder: 'Your Package Name',
  },
  {
    label: 'Web',
    value: 'web',
    placeholder: 'Your Origin',
  },
];

const IntegrationTaptalkAddAccount = (props) => {
  const { match, history } = props;
  const [isLoadingCreateAccount, setIsLoadingCreateAccount] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [orgID, setOrgID] = useState(false);
  const [accountTopicVal, setAccountTopicVal] = useState(PLATFORM[0]);
  const [inputIdentifier, setInputIdentifier] = useState('');
  const [accountTopicValOrigin, setAccountTopicValOrigin] = useState(PLATFORM[0]);
  const [inputIdentifierOrigin, setInputIdentifierOrigin] = useState('');
  const [errorMessageData, setErrorMessageData] = useState({
    inputIdentifier: "",
    topic: "",
    platform: ""
  }) ;
  const [topicListOrigin, setTopicListOrigin] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [selectedTopicOrigin, setSelectedTopicOrigin] = useState([]);
  const [searchTopic, setSearchTopic] = useState("");

  useEffect(() => {
    doMount();
  }, []);

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getTopics(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getTopics = async (orgID) => {
    topicServices.getTopic(orgID, 'list', null, (response) => {
      let data = response.dataResult.data.topics;
      let createList = () => {
        let newData = [];
        data.map((val) => {
          let _data = {};
          _data.id = val.id;
          _data.name = val.name;
          newData.push(_data);
          return null;
        });
        setTopicList(newData);
        setTopicListOrigin(newData);
      };

      createList();
    });
  };

  let onChangeSingleSelect = (e, val) => {
    if(!isLoadingCreateAccount) {
      let _selectedTopic = [...selectedTopic];
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
  
      if (e.target.checked) {
          _selectedTopic = [..._selectedTopic, val]
      } else {
          let _dataSelectedTopic = [..._selectedTopic]
          _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
          setIsCheckAll(false);
      }
  
      setSelectedTopic(_selectedTopic);
    }
  }

  let onClearAll = () => {
    if(!isLoadingCreateAccount) {
      setIsCheckAll(false);
      setSelectedTopic([]);
    }
  }

  let onCheckAll = (e) => {
    if(!isLoadingCreateAccount) {
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);

      if (e.target.checked) {
          setSelectedTopic(topicList);
      } else {
          setSelectedTopic([]);
      }
  
      setIsCheckAll(e.target.checked);
    }
  }

  let isChecked = (id) => {
    let _data = [...selectedTopic]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let removeSingleTopic = (val) => {
    setIsCheckAll(false);
    let _selectedTopic = [...selectedTopic];
    let _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    setSelectedTopic(_selectedTopic);
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
        for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
            let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
            if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
            }
        }
        }
    }

    setTopicList(result);
  }

  const onSelectPlatform = (value) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.inputIdentifier = "";
    setErrorMessageData(_errorMessageData);
    setAccountTopicVal(value);
  };

  const back = () => {
    if(!isLoadingCreateAccount) {
      history.push(`/o/${orgID}/integration/launcher`);
    }
  }

  const labelWebPlatform = (
    <div>
      <FaRegQuestionCircle />
    </div>
  );

  const titleTooltip = (
    <div style={{ padding: '8px 12px' }}>
      <p style={{ margin: 0 }}>More information on CORS: </p>
      <a
        href="https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS"
        className="orange"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
      </a>
    </div>
  );

  const propsTip = {
    content: titleTooltip,
    children: labelWebPlatform,
  };

  const labelRendered = () => {
    if (accountTopicVal.value === 'ios') {
      return (
        <label>
          <span className={errorMessageData.inputIdentifier === "Characters exceed limit" ? "red-text" : "grey-font"}>{inputIdentifier.length}/155</span>
          <b>Bundle ID</b>
        </label>
      );
    } else if (accountTopicVal.value === 'android') {
      return (
        <label>
          <b>Package Name</b>
        </label>
      );
    } else if (accountTopicVal.value === 'web') {
      return (
        <label>
          <b className="origin-title">
            Origin <span>(for CORS)</span>
          </b>
          <Tooltip {...propsTip} interactive />
        </label>
      );
    }
  };

  const doSuccess = (message) => {
    setSelectedTopicOrigin(selectedTopic);
    setAccountTopicValOrigin(accountTopicVal);
    setInputIdentifierOrigin(inputIdentifier);
    setTimeout(() => {
      history.push({
        pathname: `/o/${orgID}/integration/launcher`,
        state: {
          message: message
        }
      })
    }, 0)
  };

  const doErrAPI = (data) => {
    setIsLoadingCreateAccount(false);
    doToast(data.error.message, 'fail');
  };

  const createChannel = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};
    if(accountTopicVal.value === "") {
      _errorMessageData.inputIdentifier = "This field is required";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-end" }
    }

    if(inputIdentifier === "") {
      _errorMessageData.inputIdentifier = "This field is required";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-topic" }
    }

    if(accountTopicVal.value === 'ios') {
      if(inputIdentifier.length > 155) {
        _errorMessageData.inputIdentifier = "Characters exceed limit";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-end" }
      }
    }

    if(selectedTopic.length === 0) {
      _errorMessageData.topic = "Please select topic";
      errorCount = errorCount + 1;

      if(errorClass === "") { errorClass = "input-topic" }
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      setIsLoadingCreateAccount(true);
      
      const isValidURL = (value) => {
        var lc = value.toLowerCase();
        var isMatchH = lc.substr(0, 8) === 'https://' || lc.substr(0, 7) === 'http://';
        var isContainQuery = lc.includes("?");
        var fragmentLength = (lc.match(new RegExp("/", "g")) || []).length;
        
        // return regHttp.test(value) || regHttps.test(value);
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*');
        
        return isMatchH && pattern.test(value) && !isContainQuery && (fragmentLength < 3);
      };

      const platform = accountTopicVal.value;
      const identifier = inputIdentifier;
      const topicIDs = [];

      selectedTopic.map((v) => {
        topicIDs.push(v.id);

        return null;
      })

      const body = { platform, identifier, topicIDs};

      if (platform === 'web') {
        if (isValidURL(identifier)) {
          SecretKeyServices.create(body, orgID, (response) => {
            if (response.dataResult.error.message === "") {
              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Add Channel",
                  {
                    channel: "web",
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : orgID.split("-")[0]
                  }
                );
              }
              doSuccess(response.dataResult.data.message);
            }
            else doErrAPI(response.dataResult);
          });
        } else {
          doToast('Invalid url format', 'fail');
          setIsLoadingCreateAccount(false);
        }
      } else {
        SecretKeyServices.create(body, orgID, (response) => {
          setIsLoadingCreateAccount(false);
          if (response.dataResult.status === 200) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
              mixpanel.track(
                "[Action] Add Channel",
                {
                  channel: platform,
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
                }
              );
            }
            doSuccess(response.dataResult.data.message);
          }
          else doErrAPI(response.dataResult);
        });
      }
    }
  };

  // let runGetOrganizationDetail = () => {
  //   ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {
  //     if(response.dataResult.status === 200) {
  //       props.setAllOrganizationDetail(response.dataResult.data);

  //       // setTimeout(() => {
  //       //   doSuccess()
  //       // }, 2000)
  //     }else {
  //       doToast(response.dataResult.error.message, "fail")
  //     }
  //   })
  // }

  const handleInputIdentifier = (event) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.inputIdentifier = "";
    setErrorMessageData(_errorMessageData);
    setInputIdentifier(event.target.value);
  };

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID} 
        withHeader 
        withSideMenu
        dataOrigin={JSON.stringify({
          platform: accountTopicValOrigin,
          bundle: inputIdentifierOrigin,
          topic: selectedTopicOrigin
        })}
        dataAfterChange={JSON.stringify({
          platform: accountTopicVal,
          bundle: inputIdentifier,
          topic: selectedTopic
        })}
      >
        {/* <Integrations activeIntegrationChannel="telegramwhatsapp" parentProps={props} orgIDProps={orgID} /> */}

        <div className="sectionWrap integration-main-container integration-livechat-add-account text-left">
          <BackButton text="Back to Live Chat" onClick={back} />

          <div className="integration-livechat-add-account-box">
            <div className="integration-livechat-add-account-box-top">
              <b>Add Channel</b>

              {!isLoadingCreateAccount ? (
                <ButtonWithLoadingOrIcon 
                    // isDisabled={!inputIdentifier}
                    onClickAction={createChannel}
                    className="orange-button main-button-40"
                    text="Create Channel"
                    position="left"
                />
              ) : (
                <ButtonWithLoadingOrIcon 
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  className="orange-button main-button-40"
                  text="Create Channel"
                  position="left"
                />
              )}
            </div>

            <div className="integration-livechat-add-account-box-form">
              <div className="form-topic">
                <label>
                  <b>Platform</b>
                </label>
                <CustomSelectOption
                  optionListProps={PLATFORM}
                  valueDropdownProps={accountTopicVal}
                  placeholderProps={'Select account topic'}
                  onClickDropDownListOptionProps={onSelectPlatform}
                  specificClassNameProps="custom-select-telegram-topic"
                  isDisabled={isLoadingCreateAccount}
                  _className={`input-topic ${errorMessageData.platform !== "" ? "border-red" : ""}`}
                />

                {errorMessageData.platform !== "" &&
                    <ErrorOrInfoComp
                        text={errorMessageData.platform}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                }
              </div>

              <div className="form-origin">
                {labelRendered()}
                <input
                  type="text"
                  placeholder={accountTopicVal.placeholder}
                  value={inputIdentifier}
                  onChange={handleInputIdentifier}
                  disabled={isLoadingCreateAccount}
                  className={`input-identifier ${errorMessageData.inputIdentifier !== "" ? "border-red" : ""}`}
                />

                {errorMessageData.inputIdentifier !== "" &&
                    <ErrorOrInfoComp
                        text={errorMessageData.inputIdentifier}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                }
              </div>

              <SelectTopic 
                onChangeSearchTopic={onChangeSearchTopic}
                onCheckAll={onCheckAll}
                onClearAll={onClearAll}
                topicList={topicList}
                onChangeSingleSelect={onChangeSingleSelect}
                isChecked={isChecked}
                isCheckAll={isCheckAll}
                searchTopic={searchTopic}
                title={"Topic"}
                selectedTopic={selectedTopic}
                removeSingleTopic={removeSingleTopic}
                isDisabled={isLoadingCreateAccount}
              />
              {errorMessageData.topic !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.topic}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }
            </div>
          </div>
        </div>
      </SectionWrap>
    </div>
  );
};

const mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

const mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTaptalkAddAccount);
