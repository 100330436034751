const INITIAL_STATE = {
  isShow: false,
  case: null,
  message: null
};

let reduxReducerForwardMessage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_FORWARD_MESSAGE':
        return action.forwardMessage;
      case 'CLEAR_FORWARD_MESSAGE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerForwardMessage;