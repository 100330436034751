// import axios from "axios";
// import getHeaderServices from "./headerServices";
import BaseService from "../BaseService";
import { API_KEY } from "../../config";

// const setUrl = `${process.env.REACT_APP_ENVIRONMENT_API}/project/secret_key/`;
// const getHeader = getHeaderServices;

const IntegrationKataaiServices = {
  create(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CREATE, data, headers, callback);
  },
  get(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET, {}, headers, callback);
  },
  getDetail(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DETAIL, data, headers, callback);
  },
  delete(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE, data, headers, callback);
  },
  editDetail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_DETAIL, data, headers, callback);
  },
  editURL(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_URL, data, headers, callback);
  },
  getChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHAT_BOT, data, headers, callback);
  },
  setChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHAT_BOT, data, headers, callback);
  },
  removeChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REMOVE_CHAT_BOT, data, headers, callback);
  },
  getWelcomeMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_WELCOME, data, headers, callback);
  },
  getAwayMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_AWAY, data, headers, callback);
  },
  getClosingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_CLOSING, data, headers, callback);
  },
  getRatingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_USER_RATING, data, headers, callback);
  },
  setWelcomeMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);
  },
  setAwayMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);
  },
  setClosingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);
  },
  setRatingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_USER_RATING, data, headers, callback);
  },
};

const API = {
  CREATE: "/organization/channel/kataai/create",
  GET: "/organization/channel/kataai/get_list",
  DETAIL: "/organization/channel/kataai/get_details",
  DELETE: "/organization/channel/kataai/delete",
  EDIT_DETAIL: '/organization/channel/kataai/edit_channel',
  EDIT_URL: '/organization/channel/kataai/edit_kata_url',
  GET_CHAT_BOT: '/organization/channel/kataai/get_chatbot',
  SET_CHAT_BOT: '/organization/channel/kataai/set_chatbot',
  REMOVE_CHAT_BOT: '/organization/channel/kataai/remove_chatbot',
  GET_WELCOME: "/organization/channel/kataai/get_welcome_message",
  SET_WELCOME: "/organization/channel/kataai/set_welcome_message",
  GET_AWAY: "/organization/channel/kataai/get_away_message",
  SET_AWAY: "/organization/channel/kataai/set_away_message",
  GET_CLOSING: "/organization/channel/kataai/get_closing_message",
  SET_CLOSING: "/organization/channel/kataai/set_closing_message",
  GET_USER_RATING: "/organization/channel/kataai/get_user_rating",
  SET_USER_RATING: "/organization/channel/kataai/set_user_rating"
}

export default IntegrationKataaiServices;
