import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import { FiX } from "react-icons/fi";
import "./IntegrationSalestalkModal.scss";
import { doToast, uuidv4 } from "../../../../helper/HelperGeneral";
import IntegrationSalesTalkSuccess from "../../../../assets/img/integration/integration-salestalk-success.svg";
import IntegrationSalesTalkFailed from "../../../../assets/img/integration/integration-salestalk-failed.svg";
import tap2orderWhatsappIcon from "../../../../assets/img/tap2order-whatsapp-icon.png";
import tap2orderSMSIcon from "../../../../assets/img/tap2order-sms-icon.png";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceSalesTalkDefault from "../../../../services/salesTalk/default/SalesTalkDefault";
import { numberOnly } from "../../../../helper/HelperGeneral";

const MODAL_STATE = {
    phone: 1,
    otp: 2,
    finish: 3,
    register: 4
}

const SET_SEND_OTP_TO = {
    smsotp: "smsotp",
    waotp: "waotp"
}

let IntegrationSalestalkModal = (props) => {
    let { isOpen, toggle, runGetStatus } = props;
    let [isLoadingGetOTP, setIsLoadingGetOTP] = useState(false);
    let [phoneNumber, setPhoneNumber] = useState("");
    let [modalState, setModalState] = useState(MODAL_STATE["phone"]);
    let [loginData, setLoginData] = useState(false);
    let [timerCounter, setTimerCounter] = useState(30);
    let [otpVal, setOtpVal] = useState(["", "", "", ""]);
    // let [otpError, setOtpError] = useState(false);
    let [loadingOtpVerify, setLoadingOtpVerify] = useState(false);
    let [loadingResendOtp, setLoadingResendOtp] = useState(false);
    let [errorOTP, setErrorOTP] = useState({
        code: "",
        text: ""
    });
    // let [loadingConnect, setLoadingConnect] = useState(false);

    let timerRef = useRef(null);

    let timerCountdown = () => {
        timerRef.current =  setInterval(() => {
            setTimerCounter(timerCounter => timerCounter - 1);
        }, 1000)
    }

    let getOTPAction = () => {
        setIsLoadingGetOTP(true);

        ServiceSalesTalkDefault.postRequestOtp({
            countryPhonecode: "62",
            gocraft: 0,
            isWeb: 1,
            phoneNumber: "62"+phoneNumber
        }, (res) => {
            if(res.data.error.message) {
                doToast(res.data.error.message, "fail");
            }else {
                setLoginData(res.data.data)
                setModalState(MODAL_STATE["otp"]);
            }

            setIsLoadingGetOTP(false);
        })
    }

    let changePhone = (e) => {
        if(numberOnly(e.target.value)) {
            setPhoneNumber(e.target.value);
        }
    }

    let onClickToggle = () => {
        toggle();
        setModalState(MODAL_STATE["phone"]);
        setLoginData(false);
        setPhoneNumber("");
        clearInterval(timerRef.current);
        // setOtpError(false);
        setErrorOTP({
            code: "",
            text: ""
        })
    }

    let otpInput = (e, index) => {
        if(numberOnly(e.target.value)) {
            let _otp = otpVal.slice();
            _otp[index] = e.target.value;
            setOtpVal(_otp);

            let elTarget = document.querySelector(`#otp-${index + 1}`);

            if(elTarget && e.target.value !== "") {
                elTarget.focus();
            }

            if(index === 3) {
                verifyOtp(_otp);
            }
        }
    }

    let onKeyDownInput = (e, index) => {
        if(index !== 0 && e.which === 8) {
            let elTarget = document.querySelector(`#otp-${index - 1}`);

            if(elTarget) {
                elTarget.focus();
            }
        }
    }

    let verifyOtp = (otpCode) => {
        setLoadingOtpVerify(true);
        // setOtpError(false);
        setErrorOTP({
            code: "",
            text: ""
        })
        setOtpVal(["", "", "", ""]);
        
        let generateOTPCode = () => {
            let _otpCode = "";

            otpCode.map((x) => { 
                _otpCode += x;
                return null;
            });

            return _otpCode;
        }

        ServiceSalesTalkDefault.postVerifyOtp({
            code: generateOTPCode(),
            gocraft: 0,
            isWeb: 1,
            phoneNumber: "62"+phoneNumber,
            userID: loginData.userID,
            uuid: uuidv4()
        }, (res) => {
            if(res.data.error.message) {
                // doToast(res.data.error.message, "fail");
                // setOtpError(res.data);
                // setModalState(MODAL_STATE["register"]);
                setErrorOTP({
                    code: res.data.error.code,
                    text: res.data.error.code === 404 ? "Invalid OTP code!" : res.data.error.message
                })
                setLoadingOtpVerify(false);
            }else {
                if(res.data.data.status === 0) {
                    setErrorOTP({
                        code: 0,
                        text: "You are not registered in Tap2Order"
                    });
                    setModalState(MODAL_STATE["register"]);
                    setLoadingOtpVerify(false);
                }else {
                    connect(res.data.data.apiKey);
                }
            }

            setOtpVal(["", "", "", ""]);
        });
    }

    let onClickResendOtp = () => {
        clearInterval(timerRef.current);

        if(!loadingResendOtp) {
            setLoadingResendOtp(true);
            
            ServiceSalesTalkDefault.postResendOtp({
                countryPhonecode: "62",
                gocraft: 0,
                isWeb: 1,
                otpType: loginData.otpType,
                phoneNumber: "62"+phoneNumber,
                type: "login"
            }, (res) => {
                if(res.data.error.message) {
                    doToast(res.data.error.message, "fail");
                }else {
                    setTimerCounter(30);
                    timerCountdown();
                }
    
                setLoadingResendOtp(false);
            });
        }
    }

    let connect = (apiKey) => {
        clearInterval(timerRef.current);

        let data = {
            "apiKey": apiKey
        };

        // setLoadingConnect(true);

        ServiceSalesTalkDefault.postConnect(props.orgID, data, (res) => {
            console.log(res)
            if(res.dataResult.error.message) {
                // doToast(res.dataResult.error.message, "fail");
                setModalState(MODAL_STATE["register"]);
                setErrorOTP({
                    code: res.dataResult.error.code,
                    text: res.dataResult.error.code === 404 ? "Invalid OTP code!" : res.dataResult.error.message
                })
                setLoadingOtpVerify(false);
            }else {
                if(res.dataResult.data.success) {
                    setLoadingOtpVerify(false);
                    // onClickToggle();
                    setModalState(MODAL_STATE.finish);
                    // runGetStatus();
                }
                
                doToast(res.dataResult.data.message);
            }
            
            setTimerCounter(30);
            timerCountdown();
            // setLoadingConnect(false);
        });        
    }

    useEffect(() => {
        if(modalState === MODAL_STATE["otp"]) {
            timerCountdown();
        }
    }, [modalState])

    useEffect(() => {
        if(timerCounter === 1) {
            return () => clearInterval(timerRef.current);
        }
    }, [timerCounter])

    useEffect(() => {
        setLoadingOtpVerify(false);
        setLoadingResendOtp(false);
        setIsLoadingGetOTP(false);
    }, [isOpen])

    return (
        <>
            <Modal isOpen={isOpen && modalState === MODAL_STATE["phone"]} toggle={onClickToggle} className="modal-login-salestalk">
                <ModalBody>
                    <FiX className="modal-login-salestalk-close" onClick={onClickToggle} />

                    <p className="modal-login-salestalk-title">
                        <b>Log in</b>
                    </p>

                    <p className="modal-login-salestalk-desc">
                        Enter mobile phone number to continue
                    </p>


                    <label style={{width: "100%", textAlign: "left"}}>
                        <b>Phone Number</b>
                    </label>

                    <div className="modal-login-salestalk-phone-number">

                        <span className={`${isLoadingGetOTP ? "disabled-span" : ""}`}>+62</span>
                        <input type="text" value={phoneNumber} onChange={changePhone} maxLength="20" disabled={isLoadingGetOTP} />
                    </div>

                    {isLoadingGetOTP ?
                        // <ButtonWithLoadingOrIcon 
                        //     text="Continue"
                        //     className="main-button-40 orange-button"
                        //     isLoading
                        //     loadingWhite
                        //     position="left"
                        // />
                        <div className="lds-ring loading-login">
                            <div /><div /><div /><div />
                        </div>
                        :
                        <ButtonWithLoadingOrIcon 
                            isDisabled={phoneNumber === ""}
                            text="Continue"
                            className="main-button-40 orange-button"
                            onClickAction={getOTPAction}
                        />
                    }
                </ModalBody>
            </Modal>

            <Modal 
                isOpen={isOpen && modalState === MODAL_STATE["otp"]} 
                className="modal-login-otp-salestalk"
                toggle={() => {
                    clearInterval(timerRef.current);
                    setTimerCounter(30);
                    setOtpVal(["", "", "", ""]);
                    onClickToggle();
                }}
            >
                <ModalBody>
                    <FiX 
                        className="modal-login-otp-salestalk-close" 
                        onClick={() => {
                            clearInterval(timerRef.current);
                            setTimerCounter(30);
                            setOtpVal(["", "", "", ""]);
                            onClickToggle();
                        }} 
                    />

                    <p className="modal-login-otp-salestalk-title">
                        <b>Verification Code Sent</b>
                    </p>

                    <p className="modal-login-otp-salestalk-desc">
                        Verification code has been sent via <img src={loginData.otpType === SET_SEND_OTP_TO["smsotp"] ? tap2orderSMSIcon : tap2orderWhatsappIcon} alt="" className="send-otp-to-img" /> <b>{loginData.otpType === SET_SEND_OTP_TO["smsotp"] ? "SMS" : "WhatsApp"}</b> to
                        <br />
                        <b>+62{phoneNumber}</b>
                        <br />
                        Enter the code below
                    </p>
                    
                    <div className="modal-login-otp-salestalk-phone-number">
                        {loadingOtpVerify ?
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                            :
                            <>
                                <input type="text" id="otp-0" className={`${otpVal[0] !== "" ? "filled" : ""}`} onChange={(e) => otpInput(e, 0)} value={otpVal[0]} maxLength={1} onKeyDown={(e) => onKeyDownInput(e, 0)} />
                                <input type="text" id="otp-1" className={`${otpVal[1] !== "" ? "filled" : ""}`} onChange={(e) => otpInput(e, 1)} value={otpVal[1]} maxLength={1} onKeyDown={(e) => onKeyDownInput(e, 1)} />
                                <input type="text" id="otp-2" className={`${otpVal[2] !== "" ? "filled" : ""}`} onChange={(e) => otpInput(e, 2)} value={otpVal[2]} maxLength={1} onKeyDown={(e) => onKeyDownInput(e, 2)} />
                                <input type="text" id="otp-3" className={`${otpVal[3] !== "" ? "filled" : ""}`} onChange={(e) => otpInput(e, 3)} value={otpVal[3]} maxLength={1} onKeyDown={(e) => onKeyDownInput(e, 3)} />
                                
                                {errorOTP.code !== "" &&
                                    <p className="color-red otp-invalid">
                                        {errorOTP.text}
                                    </p>
                                }
                            </>
                        }
                    </div>  
                    
                    {/* {!loadingOtpVerify && */}
                        <p className="modal-login-otp-salestalk-desc">
                            Haven't received the code?
                            <br />
                            {timerCounter > 0 ?
                                <p className="resent-otp-button counter-timer">
                                    Try again {timerCounter}s
                                </p>
                                :
                                <>  
                                    <p className="resent-otp-button" onClick={onClickResendOtp}>
                                        {loadingResendOtp &&
                                            <div className="lds-ring">
                                                <div /><div /><div /><div />
                                            </div>
                                        }

                                        <b>Resend OTP</b>
                                    </p>
                                </> 
                            }
                        </p>
                    {/* } */}

                    {/* Kirim SMS atau WhatsApp */}
                </ModalBody>
            </Modal>

            <Modal 
                isOpen={isOpen && modalState === MODAL_STATE["finish"]} 
                className="modal-login-success-salestalk"
                toggle={() => {
                    onClickToggle();
                    setModalState(MODAL_STATE.phone);
                    runGetStatus();
                }}
            >
                <ModalBody>
                    {/* <FiX className="modal-login-success-salestalk-close" onClick={onClickToggle} /> */}

                    <p className="modal-login-success-salestalk-title">
                        <b>Successfully Connected</b>
                    </p>

                    <img src={IntegrationSalesTalkSuccess} alt="" />

                    <p className="modal-login-success-salestalk-desc">
                        Your Tap2Order Account have successfully connected to OneTalk
                    </p>

                    <ButtonWithLoadingOrIcon 
                        text="Back to OneTalk"
                        className="main-button-40 orange-button"
                        onClickAction={() => {
                            onClickToggle();
                            setModalState(MODAL_STATE.phone);
                            runGetStatus();
                        }}
                    />
                </ModalBody>
            </Modal>

            <Modal 
                isOpen={isOpen && modalState === MODAL_STATE["register"]} 
                className="modal-login-success-salestalk"
                toggle={onClickToggle}
            >
                <ModalBody>
                    <FiX className="modal-login-success-salestalk-close" onClick={onClickToggle} />

                    <p className="modal-login-success-salestalk-title">
                        <b>Failed to Connect</b>
                    </p>

                    <img src={IntegrationSalesTalkFailed} alt="" />

                    <p className="modal-login-success-salestalk-desc red-text">
                        {errorOTP.text}
                    </p>
                    
                    {errorOTP.code === 0 ?
                        <ButtonWithLoadingOrIcon 
                            text="Register Now"
                            className="main-button-40 orange-button"
                            onClickAction={() => {
                                onClickToggle();

                                window.open(
                                    "https://dashboard.t2o.io/login",
                                    "_blank"
                                )
                            }}
                        />
                        :
                        <ButtonWithLoadingOrIcon 
                            text="Try Again"
                            className="main-button-40 orange-button"
                            onClickAction={() => {
                               onClickToggle();
                            }}
                        />
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default IntegrationSalestalkModal;