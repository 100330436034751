import React, { useState, useEffect } from "react";
import { checkID, doToast } from "../../../../../../helper/HelperGeneral";
import IntegrationGoogleServices from "../../../../../../services/integration/IntegrationGoogleServices";
import ServiceOrganization from "../../../../../../services/organization/ServiceOrganization";
import BackButton from "../../../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../../../../reuseableComponent/sectionWrap/SectionWrap";
// import ErrorOrInfoComp from '../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
// import Loading from "../../../../../reuseableComponent/loading/Loading";
import "./EditEntryPointLocal.scss";

let IntegrationGoogleEditEntryPointLocal = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingEdit, setIsLoadingEdit] = useState(false);
  let [data, setData] = useState({
    isEnabled: false
  });
  let [dataOrigin, setDataOrigin] = useState({
    isEnabled: false
  });

  let doMount = () => {
    setIsLoadingEdit(false);
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getAccountDetail();
    }
  }, [orgID]);

  let getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: Number(match.params.id),
    };

    IntegrationGoogleServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        let _data = {
          isEnabled: response.dataResult.data.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "LOCATION") !== -1
        };

        setData(_data);
        setDataOrigin(_data);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let submitEdit = () => {
    setIsLoadingEdit(true);

    let _data = {
      ...data,
      channelID: Number(match.params.id),
    };

    IntegrationGoogleServices.editLocal(orgID, _data, (response) => {
      if (response.dataResult.error.message === "") {
        if(response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          setDataOrigin(data);
          setTimeout(() => {
            history.push({
              pathname: `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
              state: {
                whichTab: "entryPoints",
              },
            })
          }, 3000);
        }else {
          doToast(response.dataResult.data.message, "fail");
          setIsLoadingEdit(false);
        }
      } else {
        doToast(response.dataResult.error.message, "fail");
        setIsLoadingEdit(false);
      }
    });
  };

  let onChangeSwitch = (e) => {
    let _data = {...data};
    _data.isEnabled = e.target.checked;
    setData(_data);
  }

  return (
    <div className="edit-entry-point-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID}
        withHeader 
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >
        <BackButton
          text="Back to Entry Points"
          onClick={() =>
            history.push({
              pathname: `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
              state: {
                whichTab: "entryPoints",
              },
            })
          }
        />

        <div className="edit-entry-point-google sectionWrap">
          {isLoadingGetDetailData || retriveDataError.code ?
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
            :
            <>
              <div className="edit-entry-point-header">
                <div className="title-wrapper">
                  <b>Edit Local</b>
                </div>
                <div className="button-wrapper">
                  {!isLoadingEdit ? (
                    <ButtonWithLoadingOrIcon
                      className="orange-button  main-button-40"
                      text="Save Changes"
                      isDisabled={JSON.stringify(dataOrigin) === JSON.stringify(data)}
                      onClickAction={submitEdit}
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      className="orange-button main-button-40"
                      text="Save Changes"
                      position="left"
                    />
                  )}
                </div>
              </div>

              <div className="edit-entry-point-body">
                  <p className="form-title">
                    <b>
                      Enable Local
                    </b>

                    <label className="custom-switch-wrapper">
                      <input disabled={isLoadingEdit} type="checkbox" onChange={(e) => onChangeSwitch(e)} checked={data.isEnabled} />
                      <span className="custom-switch round"></span>
                    </label>
                  </p>

                  <p className="form-description">
                    Local entry point group encompasses entry points that rely on locations.
                  </p>
              </div>
            </>
          }
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationGoogleEditEntryPointLocal;
