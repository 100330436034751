import React from "react";
import "./RetrieveDataLoading.scss"
import { FiAlertTriangle, FiMail, FiLock } from "react-icons/fi";

let RetrieveDataLoading = (props) => {
  let onClickRefreshPage = () => {
    window.location.reload();
  }

  return (
      <div id="retrieve-data-loading-page" className={props.noPaddingTop ? "no-padding-top" : ""}>
          {props.isLoading ?
              <div className="loading-retrieve-data">
                  <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                  </div>
                  <p className="retrieve-loading-text">
                    <b>{!props.loadingText ? "Loading Data" : props.loadingText}</b>
                  </p>
                  <br />
                  {/* Fetching Data */}
              </div>
              :
              props.errorCode === "49901" ?
                <div className="fail-retrive-data tier-blocking">
                    <div className="tier-blur-layer">
                    </div>

                    <div className="alert-component">
                      <FiLock />
                      {props.errorMessage}

                      <a href="mailto:sales@taptalk.io">
                        <button className="contact-us-button blue-button">
                            <FiMail />
                            <b>Contact Us</b>
                        </button>
                      </a>
                    </div>
                </div>
                :
                <div className="fail-retrive-data">
                    <FiAlertTriangle />
                    <br />
                    <p>{props.errorMessage}.{!props.hideRefresh && " Try refreshing the page again."}</p>

                    {!props.hideRefresh &&
                      <button className="orange-button" onClick={onClickRefreshPage}>
                        Refresh Page
                      </button>
                    }
                </div>
          }
      </div>
  )
}

export default RetrieveDataLoading;