const INITIAL_STATE = null;

let reduxReducerActiveCasePanel = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_CASE_PANEL':
        return action.activeCasePanel;
      case 'CLEAR_ACTIVE_ROOM':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerActiveCasePanel;