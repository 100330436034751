const INITIAL_STATE = false;

let reduxReducerActiveCaseActionHistory = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_CASE_ACTION_HISTORY':
        return action.activeCaseActionHistory;
      case 'CLEAR_ACTIVE_CASE_ACTION_HISTORY':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerActiveCaseActionHistory;