import React, { useState, useEffect } from 'react';
import './IntegrationWhatsappVerify.scss'
import { connect } from 'react-redux';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import IntegrationWhatsappServices from "../../../../services/integration/IntegrationWhatsappServices";
import BillingServices from '../../../../services/newServices/BillingServices';
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const IntegrationWhatsappVerify = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingShow, setIsLoadingShow] = useState(true);
  let [cardAuthorizationData, setCardAuthorizationData] = useState(false);

  const back = () => {
    let stateWhatsapp = props.history.location.state.whatsappAccount;
    
    if (stateWhatsapp) {
      props.history.push({
        pathname: `/o/${orgID}/integration/whatsapp-sme/checkout`,
        state: {
            whatsappAccount: {
                name: stateWhatsapp.accountName,
                topic: stateWhatsapp.topic
            }
        }
      })
    }
  }

  let backWithMessage = (message) => {
    let stateWhatsapp = props.history.location.state.whatsappAccount;
    
    props.history.push({
      pathname: `/o/${orgID}/integration/whatsapp-sme/checkout`,
      state: {
          whatsappAccount: {
              name: stateWhatsapp.accountName,
              topic: stateWhatsapp.topic
          },
          message: message   
      }
    })
  }

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if(orgID) {
      getBillingConfig()
    }
  }, [orgID])

  useEffect(() => {
    if(cardAuthorizationData) {
      setIsLoadingShow(false)
    }
  }, [cardAuthorizationData])

  let getBillingConfig = () => {
    BillingServices.getBillingConfig(props.match.params.orgID, {
        onFailCredential: (errorMessage, errorCode) => {
            doToast(errorMessage, "fail")
        },
        onSuccessCredential: (response) => {
          createXenditAuthorization(response.data);
        }
    })
  }

  let createXenditAuthorization = (billingConfig) => {
    window.Xendit.setPublishableKey(billingConfig.paymentGateways.credentials.xendit.clientKey);

    let xenditAuthenticationResponseHandler = (err, authenticationResponse) => {
        if(err !== null) {
            backWithMessage(err.message);
        }

        if(authenticationResponse) {
          if(authenticationResponse.status === "IN_REVIEW") {
            setCardAuthorizationData(authenticationResponse);
          }else if(authenticationResponse.status === "VERIFIED") {
            createWhatsappAccount(authenticationResponse)
          }else if(authenticationResponse.status === "FAILED") {
            backWithMessage("3DS verification was unsuccessful, please try again");
          }
        }
    } 
    window.Xendit.card.createAuthentication({
        token_id: props.history.location.state.cardData.tokenID,
        amount:  props.history.location.state.whatsappAccount.unitPrice
    }, xenditAuthenticationResponseHandler);
  }

  let doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      // getWhatsappAccountDetail(paramID, match.params.accountID);
      // getWhatsappPrice(paramID);
      // getPaymentSetting(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    if (props.history.location.state) {
      if (!props.history.location.state.whatsappAccount) {
        props.history.push(`/o/${props.match.params.orgID}/home`); 
      }
    }
  }, [props.history]);

  let createWhatsappAccount = (auth) => {
    setIsLoadingShow(true);
    let _whatsappAccount = props.history.location.state.whatsappAccount;
    let _paySetting = props.history.location.state.paymentSetting;

    let data = {
      accountName: _whatsappAccount.accountName,
      topicID: _whatsappAccount.topicID,
      skuID: _whatsappAccount.skuID,
      skuString: _whatsappAccount.skuString,
      currency: _whatsappAccount.currency,
      unitPrice: _whatsappAccount.unitPrice,
      cardID: _paySetting.primaryCardID,
      authenticationID: auth.id
    }

    let runCreateAccount = () => {
      setIsLoadingShow(true);
  
      IntegrationWhatsappServices.create(orgID, data, (response) => {
          let dataRes = response.dataResult;
          let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

          if(dataRes.status === 200) {
              doToast(dataRes.data.message);
              if(dataRes.data.success) {
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                  mixpanel.track(
                    "[Action] Add Channel",
                    {
                        channel: "WhatsApp SME",
                        userFullName : myAgentData.account.fullName,
                        userID : myAgentData.account.id,
                        organizationID : orgID.split("-")[0]
                    }
                  );
                }
                runGetOrganizationDetail(dataRes.data.redirectTo, dataRes.data.id, dataRes.data.invoiceID)
              }
          }else {
              // setIsLoadingCreateAccount(false);
              doToast(dataRes.error.message, "fail"); 
          }
      });
    }

    runCreateAccount();
  };

  let runGetOrganizationDetail = (redirectTo, channelID, invoiceID) => {
    let runRedirectTo = (to, channelID, invoiceID) => {
      if(to === "channel") {
        props.history.push(`/o/${orgID}/integration/whatsapp-sme/detail/${channelID}`);
      }else {
        props.history.push(`/o/${orgID}/organization/billing/unpaid-invoices-detail/${invoiceID}`);
      }
    }

    ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {  
      if(response.dataResult.status === 200) {
        props.setAllOrganizationDetail(response.dataResult.data);

        setTimeout(() => {
          runRedirectTo(redirectTo, channelID, invoiceID)
        }, 2000)
      }else {
        doToast(response.dataResult.error.message, "fail")
      }
    })
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >

        <div className="sectionWrap integration-main-container integration-whatsapp-verify text-left">
          <>
            <BackButton text="Back to Add Channel" onClick={back} />
            <div className="integration-whatsapp-verify-box">
              <div className="integration-whatsapp-verify-box-top">
                <h1>Verify Payment</h1>
              </div>
              <div className="integration-whatsapp-checkout-box-form">

                <div className="verify-card-iframe" >
                  <div className="iframe-card-wrapper">
                    {isLoadingShow ?
                      <div className="lds-ring">
                        <div /><div /><div /><div />
                      </div>
                      :
                      <iframe src={cardAuthorizationData.payer_authentication_url} title="iframe-xendit" />
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </SectionWrap>

    </div>
  );
};

const mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

const mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationWhatsappVerify);