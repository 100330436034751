import React from "react";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import {
  checkID,
  getSplitID,
} from "../../helper/HelperGeneral";
import AssignRulesForm from "./AssignRulesForm";
import ServiceOrganization from "../../services/organization/ServiceOrganization";

const AssignRules = props => {
  const { match, history, location } = props;
  const [orgID, setOrgID] = React.useState(false);
  const [orgTime, setOrgTime] = React.useState(false);

  const setID = fullID => {
    setOrgID(getSplitID("id", fullID));
    setOrgTime(getSplitID("time", fullID));
  };

  const doGetOrg = () => {
    ServiceOrganization.postGetOrganization((response) => {
      if(response.dataResult.error !== "") {
        let responseData = response.dataResult.data;
        if (responseData.memberships.length < 1) window.location.href = "/organization/new";
        const { id, createdTime } = responseData.memberships[0].organization;
        const urlOrgID = `${id}-${createdTime}`;
        setID(urlOrgID);
        history.push(`/assign-rules/${urlOrgID}`);
      }else {
        console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
      }
    });
  };

  React.useEffect(() => {
    const matchID = checkID(match);
    if (matchID) setID(matchID);
    else doGetOrg();
  }, [location.pathname]);

  return (
    <SectionWrap orgID={orgID} orgTime={orgTime} withHeader withSideMenu>
      <div className="sectionWrap">
        <div className="topContent bordered">
          <div className="title">Assignment Rules</div>
        </div>
        <div className="bodyContent">
          <AssignRulesForm {...props} />
        </div>
      </div>
    </SectionWrap>
  );
};

export default AssignRules;
