import React from "react";
import "./BroadcastMessageFormAdditionalMessage.scss";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BroadcastMessageFormTextMessage from "../broadcastMessageFormTextMessage/BroadcastMessageFormTextMessage";
import BroadcastMessageFormImageMessageNew from "../broadcastMessageFormImageMessageNew/BroadcastMessageFormImageMessageNew";
import BroadcastMessageFormFileMessageNew from "../broadcastMessageFormFileMessageNew/BroadcastMessageFormFileMessageNew";
// import { checkID, doToast } from "../../../../helper/HelperGeneral"; //doToast
import { FiTrash2, FiInfo } from "react-icons/fi";
import iconImage from "../../../../assets/img/broadcast-message/icon_image.svg";
import iconFile from "../../../../assets/img/broadcast-message/icon_file.svg";
import iconText from "../../../../assets/img/broadcast-message/icon_text.svg";
import IconMessage from "../../../../assets/img/sidemenu-icon/icon-messages-black.svg";

const MESSAGE_TYPE = [
  {
    type: "text",
    img: iconText,
    text: "Text"
  },
  {
    type: "image",
    img: iconImage,
    text: "Image"
  },
  {
    type: "file",
    img: iconFile,
    text: "File"
  }
]

let BroadcastMessageFormAdditionalMessage = (props) => {
  let {
    broadCastMessageData,
    onChangeBroadcastMessage,
    onChangeVariableMessage,
    onChangeMultipleMessageType,
    onDeleteMultipleMessage,
    orgIDProps,
    onChangeAssetFile,
    onRemoveFile,
    index,
    _errorMessageData
  } = props;


  return (
    <div className="broadcast-message-form-additional-message">
      <p className="message-content-title">
        <img src={IconMessage} alt="" />
        <b>Message Content #{index + 1}</b>
        
        <ButtonWithLoadingOrIcon 
          text="Delete"
          icon={{
            type: "svg",
            src: FiTrash2
          }}
          position="left"
          className="main-button-40 red-button delete-message-button"
          onClickAction={() => {
            onDeleteMultipleMessage(index)
          }}
        />
      </p>
      
      <label className={`message-type-${index}`}>
        <b>Message Type</b>
      </label>

      <div className="more-message-type-selection-wrapper">
        {MESSAGE_TYPE.map((_val, _idx) => {
          return (
              <div 
                  className={`message-type-selection-selection ${broadCastMessageData.messages[index].type === _val.type ? "active-message-type-selection-selection" : ""}`} 
                  key={`message-type-selection-${_idx}`} 
                  onClick={() => onChangeMultipleMessageType(_val.type, index)}
              >
                  <div className={`message-type-selection-icon-wrapper message-type-selection-${_val.type}`}>
                      <img src={_val.img} alt="" />
                  </div>

                  {_val.text}
              </div>
          )
        })}
      </div>
      {_errorMessageData.messageType[index] !== "" &&
        <ErrorOrInfoComp 
          _className={_errorMessageData.messageType[index] !== "" ? "font-red" : ""}
          icon={<FiInfo />}
          text={_errorMessageData.messageType[index]}
        />
      }

      <div className={`message-content-${index} message-content-value`}>
        {broadCastMessageData.messages[index].type === MESSAGE_TYPE[0].type &&
          <BroadcastMessageFormTextMessage 
              broadCastMessageDataProps={broadCastMessageData}
              onChangeBroadcastMessageProps={onChangeBroadcastMessage}
              onChangeVariableMessageProps={onChangeVariableMessage}
              _index={index}
          />
        }

        {broadCastMessageData.messages[index].type === MESSAGE_TYPE[1].type &&
          <BroadcastMessageFormImageMessageNew 
              orgIDProps={orgIDProps}
              onRemoveFileAssetProps={onRemoveFile}
              broadCastMessageDataProps={broadCastMessageData}
              onChangeAssetFileProps={onChangeAssetFile}
              onChangeBroadcastMessageProps={onChangeBroadcastMessage}
              onChangeVariableMessageProps={onChangeVariableMessage}
              _index={index}
          />
        }

        {broadCastMessageData.messages[index].type === MESSAGE_TYPE[2].type &&
          <BroadcastMessageFormFileMessageNew 
            orgIDProps={orgIDProps}
            onRemoveFileAssetProps={onRemoveFile}
            broadCastMessageDataProps={broadCastMessageData}
            onChangeAssetFileProps={onChangeAssetFile}
            onChangeBroadcastMessageProps={onChangeBroadcastMessage}
            _index={index}
          />
        }

        {_errorMessageData.messageValue[index] !== "" &&
          <ErrorOrInfoComp 
            _className={_errorMessageData.messageValue[index] !== "" ? "font-red" : ""}
            icon={<FiInfo />}
            text={_errorMessageData.messageValue[index]}
          />
        }
      </div>
    </div>
  );
};

export default BroadcastMessageFormAdditionalMessage;