import React from "react";
import "./IntegrationWhatsappModalComparison.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiX } from "react-icons/fi";
// import GreenTickIcon from "../../../../assets/img/green-tick.png";
import WhatsApp32 from "../../../../assets/img/integration/logo-whatsapp-32.svg";
import WhatsappLightGreen from "../../../../assets/img/integration/logo-whatsapp-lightgreen-small.svg";

let tableHeader = [
    {
        text: ""
    },
    {
        text: "WhatsApp Business API"
    },
    {
        text: "WhatsApp for SME"
    },
]

let tableBody = [
    {
        textCol: "<b>Technology</b>",
        textCol2: "Official WhatsApp API",
        textCol3: "WhatsApp Desktop/Web",
    },
    {
        textCol: "<b>Approval</b>",
        textCol2: "Up to 20 working days",
        textCol3: "Instant <span class='upto-sync'>*up to 36 working hours to sync</span>",
    },
    // {
    //     textCol: "<b>Online Device</b>",
    //     textCol2: "Doesn't need online device",
    //     textCol3: "Need device connected to good internet service all the time",
    // },
    {
        textCol: "<b>Stability</b>",
        textCol2: "Very stable",
        textCol3: "Less stable",
    },
    {
        textCol: "<b>Load Limit</b>",
        textCol2: "Up to unlimited",
        textCol3: "Up to 6000 messages per day",
    },
    {
        textCol: `<b>Masking & Green Tick</b>`,
        textCol2: "Yes <span class='green-tick-span'>*with verification and approval from META</span>",
        textCol3: "No",
    },
    {
        textCol: "<b>Price</b>",
        textCol2: "Start from Rp750.000",
        textCol3: "Start from Rp750.000",
    },
    {
        textCol: "<b>Industries</b>",
        textCol2: "Some industries is prohibited for using WABA",
        textCol3: "All industries",
    },
    // {
    //     textCol: "<b>Outbound Message</b>",
    //     textCol2: "Chargeable after 24 hour of incoming message or for broadcast",
    //     textCol3: "Free",
    // },
    {
        textCol: "<b>Variable Cost</b>",
        textCol2: "Conversation Based Pricing (UI/BI)",
        textCol3: "No variable cost",
    },
    {
        textCol: "<b>Ban Possibillities</b>",
        textCol2: "Rarely",
        textCol3: "High",
    }
]

let IntegrationWhatsappModalComparison = (props) => {
    return (
        <Modal className="modal-comparison-whatsapp" isOpen={props.isShow} toggle={props.toggle}>
            <ModalBody>
                <div className="title">
                    <img src={WhatsApp32} alt="" className="icon-whatsapp" />
                    <b>
                        WhatsApp for SME
                    </b>

                    <b className="and-text">and</b>
                    
                    <img src={WhatsappLightGreen} alt="" className="icon-whatsapp" />
                    <b>
                        WhatsApp Business API
                    </b>
                </div>

                <FiX onClick={props.toggle} />

                <table>
                    <thead>
                        <tr>
                            {tableHeader.map((value, index) => {
                                return (
                                    <td key={`header-${index}`}>
                                        <b>{value.text}</b>
                                    </td>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {tableBody.map((value, index) => {
                            return (
                                <tr key={`table-${index}`}>
                                    {Object.keys(value).map((valBody, indexBody) => {
                                        return (
                                            <td key={`body-${indexBody}`} dangerouslySetInnerHTML={{ __html: value[valBody] }} />
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </ModalBody>
        </Modal>
    )
}

export default IntegrationWhatsappModalComparison;