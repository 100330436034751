import React, { useState, useEffect } from "react";
import "./SelectInteractiveMessage.scss";
import { FiX, FiPlus } from "react-icons/fi";
import { Modal, ModalBody } from "reactstrap";
import { connect } from 'react-redux';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import FormInteractiveMessage from "./FormInteractiveMessage/FormInteractiveMessage";
import FormInteractiveMessageList from "./FormInteractiveMessageList/FormInteractiveMessageList";
import IconReply from "../../../../../assets/img/icon-interactive-reply.svg";
import IconList from "../../../../../assets/img/icon-interactive-list.svg";
import { setQuickReplyInteractive } from '../../../../../redux/actions/reduxActionQuickReplyInteractive';

let SelectInteractiveMessage = (props) => {
    let [interactiveMessageType, setInteractiveMessageType] = useState("");

    let closeFormInteractive = (type = "") => {
        if(type === "") {
            props.setQuickReplyInteractive(false);
        }
        setInteractiveMessageType(type === "" ? "" : type);
    }

    useEffect(() => {
        if(props.activeQuickReplyInteractive) {
            setInteractiveMessageType(props.activeQuickReplyInteractive.type);
        }
    }, [props.activeQuickReplyInteractive])

    return (
        <>
            <Modal isOpen={props.isShow} toggle={props.toggle} className="modal-select-interactive-message" >
                <ModalBody>
                    <FiX className="icon-close" onClick={props.toggle} />

                    <p className="title-modal">
                        <b>Interactive Message</b>
                    </p>

                    <p className="modal-subtitle">
                        There are 2 types of interactive message that can be added.
                    </p>

                    <div className="select-interactive-50">
                        <img src={IconReply} alt="" />

                        <p className="select-title">
                            <b>Reply Button</b>
                        </p>

                        <p className="select-subtitle">
                            Consists of up to 3 preset reply options that customers can click to indicate the action they want to take.
                        </p>

                        <ButtonWithLoadingOrIcon 
                            text="Add Reply Button"
                            icon={{
                                src: FiPlus,
                                type: "Svg"
                            }}
                            position="left"
                            className="main-button-40 orange-button"
                            onClickAction={() => {
                                props.toggle();
                                setInteractiveMessageType("button");
                            }}
                        />
                    </div>

                    <div className="select-interactive-50">
                        <img src={IconList} alt="" />

                        <p className="select-title">
                            <b>List</b>
                        </p>

                        <p className="select-subtitle">
                            Consists of up to 10 menu options that customers can choose to let you know what they want from your business.
                        </p>

                        <ButtonWithLoadingOrIcon 
                            text="Add List"
                            icon={{
                                src: FiPlus,
                                type: "Svg"
                            }}
                            position="left"
                            className="main-button-40 orange-button"
                            onClickAction={() => {
                                props.toggle();
                                setInteractiveMessageType("list");
                            }}
                        />
                    </div>
                </ModalBody>
            </Modal>
            
            <FormInteractiveMessage 
                isShowModalFormInteractive={interactiveMessageType === "button"}
                toggle={closeFormInteractive}
                type="button"
                caseDetailData={props.caseDetailData}
                parentProps={props.parentProps}
            />

            <FormInteractiveMessageList
                isShowModalFormInteractive={interactiveMessageType === "list"}
                toggle={closeFormInteractive}
                type="list"
                caseDetailData={props.caseDetailData}
                parentProps={props.parentProps}
            />
        </>
    )
}

const mapStateToProps = state => ({
    activeQuickReplyInteractive: state.activeQuickReplyInteractive,
});

const mapDispatchToProps = {
    setQuickReplyInteractive
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectInteractiveMessage);
