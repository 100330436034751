import React, { useState, useEffect } from 'react';
import './ChatRoomBroadcastHistoryTab.scss';
import iconConnect from "../../../../../../assets/img/icon-connect.svg";
// import iconLine from "../../../../../assets/img/omnichannel/room-list/icon-line.svg";
// import { FiCircle, FiCheckCircle } from "react-icons/fi";
import { connect } from 'react-redux';
import { doToast } from "../../../../../../helper/HelperGeneral";
// import HelperChat from "../../../../../../helper/HelperChat";
import HelperDate from "../../../../../../helper/HelperDate";
import { TAPLIVE_MEDIUM_LOGO } from "../../../../../../constants/taplive";
import CaseService from "../../../../../../services/chat/caseServices";
import Loading from "../../../../../reuseableComponent/loading/Loading";
// import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
// import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
// import { Scrollbars } from 'react-custom-scrollbars';
import { FixedSizeList as List } from 'react-window';

// var style = {
//   scrollStyle: {
//     position: "relative",
//     backgroundColor: "#ff7d00",
//     right: "-5px",
//     width: "3px",
//     borderRadius: "8px"
//   }
// };

var ChatRoomBroadcastHistoryTab = (props) => {
    let [panelHeight, setPanelHeight] = useState(0);

    let [tempBroadcastList, setTempBroadcastList] = useState({
        time: false,
        list: [],
        room: false
    })
    let [broadcastList, setBroadcastList] = useState([]);
    let [isLoadingBroadcastMessageList, setIsLoadingBroadcastMessageList] = useState(true);
    
    let generateIconOmnichannel = (medium) => {
        return TAPLIVE_MEDIUM_LOGO[medium] ? TAPLIVE_MEDIUM_LOGO[medium] : "";
    }
    
    let runGetBroadcast = () => {
        setIsLoadingBroadcastMessageList(true);
        CaseService.getBroadcastHistoryList(props.parentProps.match.params.orgID, {userID: props.caseDetailProps.user.userID}, (res) => {
            let _dataResult = res.dataResult;
            
            if(_dataResult.status !== 200) {
                doToast(_dataResult.error.message, "fail");
                setBroadcastList([]);
            }else {
                setTempBroadcastList({
                    time: new Date().valueOf(),
                    list: _dataResult.data.broadcasts,
                    room: props.activeRoom
                })
            }
            
            setIsLoadingBroadcastMessageList(false);
        })
    }

    useEffect(() => {
        setPanelHeight(document.querySelectorAll(".main-chat-history-content")[0].offsetHeight)
    }, [])

    useEffect(() => {
        if(tempBroadcastList.time && props.activeRoom === tempBroadcastList.room) {
            setBroadcastList(tempBroadcastList.list)
            props.runSetCountBroadcastProps(tempBroadcastList.list.length)
        }
    }, [tempBroadcastList])

    useEffect(() => {
        if(props.activeRoom !== null) {
            runGetBroadcast();
        }
    }, [props.activeRoom])

    const Row = ({ index, style }) => {
        let data = {
            user: props.caseDetailProps.user,
            case: props.caseDetailProps.userCases[Object.keys(props.caseDetailProps.userCases)[index]],
            broadcast: broadcastList[index]
        }
        
        return(
            <div style={style}>
                {/* {Object.keys(props.caseDetailProps.userCases).map((value) => { */}
            
                <div className="broadcast-history-tab-list" onClick={() => props.onClickBroadcastHistoryProps(data)} key={`broadcast-history-${index}`}>
                    {generateIconOmnichannel([broadcastList[index].medium]) === "" ?
                        ""
                        :
                        <img src={generateIconOmnichannel([broadcastList[index].medium])} alt="" className="broadcast-history-omnichannel-icon" />
                    }
                    
                    <p className="broadcast-history-topic-name">
                        <b>{broadcastList[index].name}</b>
                    </p>

                    <p className="broadcast-history-last-message">
                        {/* {broadcastList[index].messageType === "image" ? 
                            (broadcastList[index].contentCaption !== "" ?
                                `🖼 ${broadcastList[index].contentCaption}`
                                :
                                "🖼 Photo" 
                            )
                            : 
                            HelperChat.lineBreakRoomList(broadcastList[index].contentBody)}  */}
                    </p>

                    <div className="broadcast-history-time-and-status-wrapper">
                        <div className="broadcast-history-time-wrapper">
                        <img src={iconConnect} alt="" />
                        <b>Created {HelperDate.formatToString(new Date(broadcastList[index].createdTime), "dd/MM/yyyy")}</b>
                        </div>

                        {/* {broadcastList[index].isClosed &&
                        <div className="broadcast-history-case-status broadcast-history-solved">
                            <FiCheckCircle />
                            <b>Resolved</b>
                        </div>
                        } */}

                        {/* {((broadcastList[index].agentAccountID !== 0) && (broadcastList[index].agentAccountID !== myAgentAccountID) && (!broadcastList[index].isClosed)) &&
                        <div className="broadcast-history-case-status broadcast-history-other-agent">
                            <FiUser />
                            <b>{broadcastList[index].agentFullName}</b>
                        </div>
                        }

                        {((broadcastList[index].agentAccountID !== 0) && (broadcastList[index].agentAccountID === myAgentAccountID) && (!broadcastList[index].isClosed)) &&
                        <div className="broadcast-history-case-status">
                            <FiUser />
                            <b>Me</b>
                        </div>
                        } */}
                    </div>
                </div>
                {/* })} */}
            </div>
        )
    };

    return (
        // <Scrollbars autoHideTimeout={500}
        //     renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
        // >
        <div className={`broadcast-history-outer-wrapper ${props.className}`}>
            {isLoadingBroadcastMessageList ?
                <Loading />
                :
                broadcastList.length > 0 ? 
                    <List
                        height={panelHeight}
                        itemCount={broadcastList.length}
                        // itemSize={93}
                        itemSize={71}
                        width={"100%"}
                    >
                        {Row}
                    </List>
                    :
                    <div className="empty-broadcast-list">
                        <p>
                            <b>No Results Were Found</b>
                        </p>

                        <p>
                            There are no messages to display.
                        </p>
                    </div>
            }
        </div>
        // </Scrollbars>
    );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomBroadcastHistoryTab);