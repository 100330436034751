import React, { useState, useEffect } from 'react';
import './ReviewTransaction.scss';
import NewOrganizationHeader from "../../newOrganizationHeader/NewOrganizationHeader";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import iconTier from '../../../../assets/img/select-tier/icon-tier.svg';
import iconMethod from '../../../../assets/img/select-tier/icon-method.svg';
import iconManual from '../../../../assets/img/select-tier/icon-method-manual.svg';
import iconVisa from "../../../../assets/img/payment-card/visa-medium.png";
import iconJsb from "../../../../assets/img/payment-card/jcb-medium.png";
import iconMastercard from "../../../../assets/img/payment-card/mastercard-medium.png";
import iconAmex from "../../../../assets/img/payment-card/amex-medium.png";
import { FiInfo } from 'react-icons/fi';
import { Modal, ModalBody } from 'reactstrap';
import { AiOutlineWarning } from 'react-icons/ai';
import { FiArrowUpRight } from 'react-icons/fi';
import { connect } from 'react-redux';
import { setSelectedTier, clearSelectedTier } from "../../../../redux/actions/reduxActionSelectedTier";
import { doToast } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import { ToastContainer } from 'react-toastify';
import BillingServices from "../../../../services/newServices/BillingServices";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

const REDIRECT_TO = {
  home: "home",
  subscription: "subscription",
  invoice: "invoice"
}

const tierDiscount = 17;

const PAY_FOR = {
  month: "month",
  year: "year"
};

const CARD_BRAND = {
  visa: {
    label: "Visa",
    img: iconVisa
  },
  mastercard: {
    label: "Mastercard",
    img: iconMastercard
  },
  jcb: {
    label: "JCB",
    img: iconJsb
  },
  american_express: {
    label: "American Express",
    img: iconAmex
  },
}

let getCardBrandId = (cardNumber) => {
  let _value = false;

  let regs = {
    visa: /^4[0-9]\d+$/,
    mastercard: /^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9][0-9]|27[0-1][0-9]|2720)\d+$/,
    american_express: /^(34|37)\d+$/,
    jcb: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/,
  };

  Object.keys(regs).map((value) => {
    if (regs[value].test(cardNumber)) {
      _value = value;
    }

    return null;
  })

  return _value;
}

const ReviewTransaction = (props) => {
  const [isAllow, setIsAllow] = useState(false);
  const [isWaitResponseSubmit, setIsWaitResponseSubmit] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
      mixpanel.init(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY);
      mixpanel.identify(myAgentData.account.id);
    }
  }, [])

  const back = () => {
    let _selectedTier = { ...props.selectedTier };

    if(_selectedTier.paymentSetting.isAutomaticPayment) {
      goToAddCard();
    } else {
      goToSelectPayment();
    }
  }

  const backToSelectTier = () => {
    props.history.push(`/o/${props.match.params.orgID}/select-tier`);
  }
  
  const goToAddCard = () => {
    props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods/add-card`);
  }

  const goToSelectPayment = () => {
    props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods`);
  }

  const goToChangePaymentSetting = () => {
    props.history.push({
      pathname: `/o/${props.match.params.orgID}/organization/billing`,
      state: {
          whichTab: "paymentSettings"
      }
    })
  }

  const goToSelectTier = () => {
    props.history.push(`/o/${props.match.params.orgID}/select-tier`);
  }

  let submitPaymentSetting = () => {
    setIsWaitResponseSubmit(true);

    let data = {
        isAutomaticPayment: false,
        primaryCardID: "" 
    }

    BillingServices.setPaymentSetting(props.match.params.orgID, data, (response) => {
        let dataResult = response.dataResult;

        if(dataResult.error.message === "") {
            console.log(dataResult.data.message);
            setTier();
        }else {
            doToast(dataResult.error.message, "fail");
            setIsWaitResponseSubmit(false);
        }
    })
  }

  let setTier = () => {
    setIsWaitResponseSubmit(true);
    
    let redirectTo = (to, invoiceID) => {
      if (to === REDIRECT_TO.home) {
        props.history.push({
          pathname: `/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction/success`,
          state: {
            redirectTo: `/o/${props.match.params.orgID}/home`
          }
        })
      } else if (to === REDIRECT_TO.subscription) {
        props.history.push({
          pathname: `/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction/success`,
          state: {
            redirectTo: `o/${props.match.params.orgID}/organization/billing/unpaid-invoices`
          }
        })
      } else {
        props.history.push({
          pathname: `/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction/success`,
          state: {
            redirectTo: `/o/${props.match.params.orgID}/organization/billing/unpaid-invoices-detail/${invoiceID}`
          }
        })
      }
    }

    let payUnit = props.selectedTier.payUnit === "year" ? PAY_FOR.year : PAY_FOR.month;
    let indexSku = props.selectedTier.skuItems.findIndex(value => value.validityType === payUnit);

    let _selectedTier = {
      currentTierID: props.selectedTier.currentTier,
      tierID: props.selectedTier.id,
      tierCode: props.selectedTier.code,
      skuID: props.selectedTier.skuItems[indexSku].id,
      skuString: props.selectedTier.skuItems[indexSku].sku,
      name: props.selectedTier.name
    };

    ServiceOrganization.postSetTierList(props.match.params.orgID, _selectedTier, (response) => {
      let dataResult = response.dataResult;

      if (dataResult.status === 200) {
        if (dataResult.data.success) {
          redirectTo(dataResult.data.redirectTo, dataResult.data.invoiceID)
        } else {
          setIsWaitResponseSubmit(false);
          doToast(dataResult.data.message, "fail");
        }
      } else {
        setIsWaitResponseSubmit(false);
        doToast(dataResult.error.message, 'fail');
      }
    })
  }

  const alertText = [
    {
      text: 'Your next subscription payment will be auto deducted from your selected primary card when your free trial ends'
    },
    {
      text: 'Your next payment will be auto deducted from your card once it reaches the due date'
    },
    {
      text: 'You will be directed to the invoice details to complete the payment'
    }
  ]

  const onConfirm = () => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Button] Checkout",
        {
          freeTrial: props.selectedTier.freeTrialDays > 0 ? "Yes" : "No",
          paymentMethod: !props.selectedTier.paymentSetting.isAutomaticPayment ? "Manual" : "Automatic",
          tier: props.selectedTier.name,
          userFullName : myAgentData.account.fullName,
          userID : myAgentData.account.id,
          organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }

    if(props.selectedTier.paymentSetting.isSet) {
      setTier();
    }else {
      if(!props.selectedTier.paymentSetting.isAutomaticPayment) {
        submitPaymentSetting();
      } else {
        props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction/verify`);
      }
    }

    // setShowModalConfirm(true);
  }

  const closeModalConfirm = () => {
    setShowModalConfirm(false)
  }

  let printPrice = (data, isDiscount = false) => {
    let payUnit = props.selectedTier.payUnit !== "month" ? PAY_FOR.year : PAY_FOR.month;
    let index = data.skuItems.findIndex(value => value.validityType === payUnit);

    let price = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: data.skuItems[index].prices[0].currency,
    }).format(
      !isDiscount ?
        data.skuItems[index].prices[0].unitPrice
        :
        data.skuItems[index].prices[0].unitPrice * tierDiscount / 100
    ).replace(/,/g, ".").replace(/\D00$/, "");

    return price + "/" + (props.selectedTier.payUnit !== "month" ? "year" : "month").replace("Rp", "IDR");
  }

  useEffect(() => {
    let _isAllow = true;

    if (!props.selectedTier) {
      _isAllow = false;
    }else {
      if(!props.selectedTier.paymentSetting) {
        _isAllow = false;
      }else {
        if(props.selectedTier.paymentSetting.isAutomaticPayment === "null") {
          _isAllow = false;
        }
      }
    }

    if (!_isAllow) {
      props.history.push(`/o/${props.match.params.orgID}/home`)
    } else {
      setIsAllow(_isAllow);
    }

  }, [props.selectedTier])

  const renderModal = () => {
    return (
      <Modal isOpen={showModalConfirm} toggle={closeModalConfirm} className={"confirm-modal"}>
        <ModalBody>
          <div className="confirm-modal-wrapper">
            <div className="confirm-title-wrapper">
              <AiOutlineWarning />
              <b className="confirm-title">You Will Be Redirected to Payment Settings</b>
            </div>
            <p className="confirm-description">By changing your payment method, you are discarding your current progress and will be redirected to payment settings.</p>

            <div className="button-container">
              <button className="no-fill-button cancel" onClick={closeModalConfirm}>Cancel</button>
              <button className="orange-button discard">
                <FiArrowUpRight />
                Discard and Open Payment Settings</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  return (
    isAllow &&
      <>
        <ToastContainer className="ToastContainer" />

        <NewOrganizationHeader
          titleText="Start Subscription"
          hideLogout
        // topRightContent={renderStep}
        />

        <div className="review-transaction-main-wrapper">
          <SectionWrap
            {...props}
            orgID={props.match.params.orgID}
            _className="section-width-100 pos-relative"
            barVerifyClass="margin-top-0"
          >
            {renderModal()}
            <div className="review-transaction-content">
              <div className="review-transaction-header">
                {props.selectedTier.paymentSetting.isSet ?
                  <BackButton text={`Back To Select Tier`} onClick={backToSelectTier} />
                  :
                  <BackButton text={`${!props.selectedTier.paymentSetting.isAutomaticPayment ? "Back to Select Payment Method" : "Back to Add Card"}`} onClick={back} />
                }
            </div>
            
            <div className="review-transaction-container">
              <h1>Review Tier & Payment Method</h1>
              <div className="review-container">
                <div className="box-container">
                  <div className="box-item-container">
                    <div className="img-title-wrapper">
                      <img src={iconTier} alt="" />
                      <div className="tier-wrapper">
                        <b>{props.selectedTier.name} - {props.selectedTier.payUnit === "month" ? "Monthly" : "Yearly"}</b>
                        <p>{printPrice(props.selectedTier)}</p>
                      </div>
                    </div>
                    <button className="light-orange-button tier-button" onClick={goToSelectTier}>Change Tier</button>
                  </div>

                  {props.selectedTier.paymentSetting.isAutomaticPayment ?
                    <div className="box-item-container">
                      <div className="img-title-wrapper">
                        <img src={iconMethod} alt="" />
                        <div className="method-wrapper">
                          <b>Automatic Payments</b>
                          <p>Automatically charge the selected primary payment method.</p>
                        </div>
                      </div>
                      {!props.selectedTier.paymentSetting.isSet ?
                        <button className="light-orange-button method-button" onClick={goToSelectPayment}>Change Method</button>
                        :
                        <button className="light-orange-button method-button" onClick={goToChangePaymentSetting}><FiArrowUpRight /> Change Method</button>
                      }
                    </div>
                    :
                    <div className="box-item-container">
                      <div className="img-title-wrapper">
                        <img src={iconManual} alt="" />
                        <div className="method-wrapper">
                          <b>Manual Payments</b>
                          <p>Make payments manually via bank transfers.</p>
                        </div>
                      </div>
                      <button className="light-orange-button method-button" onClick={goToSelectPayment}>Change Method</button>
                    </div>
                  }

                  {props.selectedTier.paymentSetting.isAutomaticPayment &&
                    <div className="box-item-container">
                      <div className="img-title-wrapper">
                        {props.selectedTier.cardDetail &&
                          <>
                            {getCardBrandId(props.selectedTier.cardDetail.cardNumber.replace(/\s/g, '')) &&
                              <img className="image-credit-card" src={CARD_BRAND[getCardBrandId(props.selectedTier.cardDetail.cardNumber.replace(/\s/g, ''))].img} alt="" />
                            }
                            
                            <div className="card-wrapper">
                              <b>{props.selectedTier.cardDetail.cardNumber}</b>
                              <p>Expires {props.selectedTier.cardDetail.cardExpiry}</p>
                            </div>
                          </>
                          // :
                          // <>
                          //   {getCardBrandId(props.selectedTier.paymentSetting.primaryCardID) &&
                          //     <img className="image-credit-card" src={CARD_BRAND[getCardBrandId(props.selectedTier.paymentSetting.primaryCardID)].img} alt="" />
                          //   }

                          //   <div className="card-wrapper">
                          //     <b>{props.selectedTier.paymentSetting.primaryCardID}</b>
                          //     {/* <p>Expires {props.selectedTier.cardDetail.cardExpiry}</p> */}
                          //   </div>
                          // </>
                        }
                      </div>
                      {!props.selectedTier.paymentSetting.isSet &&
                        <button className="light-orange-button card-button" onClick={goToAddCard}>Change Card</button>
                      }
                    </div>
                  }
                </div>

                {
                  props.selectedTier.freeTrialDays > 0 ?
                    <div className="trial-container">
                      <b>Free {props.selectedTier.freeTrialDays} Day{props.selectedTier.freeTrialDays > 1 ? "s" : ""} Trial</b>
                    </div>
                    :
                    <div className="amount-container">
                      <p>Total Amount Due</p>
                      <b>{printPrice(props.selectedTier)}</b>
                    </div>
                }
      
                {(props.selectedTier.paymentSetting.isAutomaticPayment && props.selectedTier.freeTrialDays > 0) &&
                  <div className="alert-container">
                    <div className="alert-component alert-blue alert-review-transaction">
                      <FiInfo />
                      <b>
                        {alertText[0].text}
                      </b>
                    </div>
                  </div>
                }

                {(props.selectedTier.paymentSetting.isAutomaticPayment && props.selectedTier.freeTrialDays <= 0) &&
                  <div className="alert-container">
                    <div className="alert-component alert-blue alert-review-transaction">
                      <FiInfo />
                      <b>
                        {alertText[1].text}
                      </b>
                    </div>
                  </div>
                }

                {(!props.selectedTier.paymentSetting.isAutomaticPayment && props.selectedTier.freeTrialDays <= 0) &&
                  <div className="alert-container">
                    <div className="alert-component alert-blue alert-review-transaction">
                      <FiInfo />
                      <b>
                        {alertText[2].text}
                      </b>
                    </div>
                  </div>
                }
                
                {isWaitResponseSubmit ?
                  <button className="orange-button confirm-button">
                    <div className="lds-ring">
                      <div /><div /><div /><div />
                    </div>
                  </button>
                  :
                  <button className="orange-button confirm-button" onClick={onConfirm}>Confirm</button>
                }
              </div>
            </div>
          </div>
        </SectionWrap>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  setSelectedTier,
  clearSelectedTier
};

const mapStateToProps = state => ({
  selectedTier: state.selectedTier
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTransaction);