import React, { useState, useEffect } from 'react';
import './IntegrationFacebookMessengerDetailAccount.scss';
import { NavLink } from "react-router-dom";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import AutoResolveServices from '../../../../services/newServices/AutoResolveServices';
import IntegrationFacebookServices from '../../../../services/integration/IntegrationFacebookServices';
import AutoUnassignServices from '../../../../services/newServices/AutoUnassignServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupRemove from '../../../reuseableComponent/popupRemove/PopupRemove';
import IntegrationAutoMessageDetail from "../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import { FiCopy, FiEdit, FiLink2 } from 'react-icons/fi';
import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
// import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
import iconXgrey from '../../../../assets/img/integration/icon-x-grey.svg';
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
import statusDisconnect from '../../../../assets/img/integration/icon-disconnect.svg';
import FacebookIcon from "../../../../assets/img/integration/icon-button-facebook.svg";
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import { connect } from 'react-redux';

let CONNECTED = "connected";

const IntegrationFacebookMessengerDetailAccount = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  const [messengerAccountDetail, setMessengerAccountDetail] = useState(null);
  const [messengerChannelLink, setMessengerChannelLink] = useState(null);
  const [messengerChannelLinkNeverSet, setMessengerChannelLinkNeverSet] = useState(false);
  const [messengerChatbot, setMessengerChatbot] = useState(false);
  // const [isShowModalDeleteChannel, setIsShowModalDeleteChannel] = useState(false);
  const [pageList, setPageList] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [fbAccessToken, setFbAccessToken] = useState("");
  const [fbData, setFbData] = useState(false);
  const [loadingRecon, setLoadingRecon] = useState(false);
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  const [isShowModalRemoveChatbot, setIsShowModalRemoveChatbot] = useState(false);
  const [waitForResponseRemoveChatbot, setWaitForResponseRemoveChatbot] = useState(false);
  const [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(false);
  const [welcomeMessageData, setWelcomeMessageData] = useState(false);
  const [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
  const [awayMessageData, setAwayMessageData] = useState(false);
  const [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
  const [closingMessageData, setClosingMessageData] = useState(false);
  const [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
  const [ratingMessageData, setRatingMessageData] = useState(false);
  let [loadingAutoResolve, setLoadingAutoResolve] = useState(false);
  let [autoResolveData, setAutoResolveData] = useState(false);
  let [errorWelcome, setErrorWelcome] = useState(false);
  let [errorAway, setErrorAway] = useState(false);
  let [errorClosing, setErrorClosing] = useState(false);
  let [errorRating, setErrorRating] = useState(false);
  let [errorChatbot, setErrorChatbot] = useState(false);
  let [errorAutoResolve, setErrorAutoResolve] = useState(false);
  let [loadingAutoUnassign, setLoadingAutoUnassign] = useState(false);
  let [autoUnassignData, setAutoUnassignData] = useState(false);
  let [errorAutoUnassign, setErrorAutoUnassign] = useState(false);
  
  let getChannelLink = () => {
    let link = messengerChannelLink.url;
    const CURRENT_LOCATION = encodeURIComponent(window.location.href);

    link = `${link}${messengerChannelLink.sendPageURL ? `%0A%0AFrom URL: ${CURRENT_LOCATION}` : ""}`;
    
    return link;
  }

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if(fbData && window.FB) {
        window.FB.init({
            appId: fbData.appID,
            cookie: true,
            xfbml: true,
            version: fbData.graphAPIVersion
        });

        // setIsLoadingGetDetailData(false);
    }
  }, [fbData])

  useEffect(() => {
    if(messengerAccountDetail !== null) {
      getMessengerChannelLink();
      runGetWelcomeMessage();
      runGetAwayMessage();
      runGetClosingMessage();
      runGetRatingMessage();
      runGetAutoResolve();
      runGetAutoUnassign();
    }
  }, [messengerAccountDetail])

  useEffect(() => {
    if(messengerChannelLink !== null || messengerChannelLinkNeverSet) {
      getChatBot();
    }
  }, [messengerChannelLink, messengerChannelLinkNeverSet])

  useEffect(() => {
    if(isConnected) {
        window.FB.api("/me/accounts/", (r) => {
            if(r.data.length > 0) {
                let _list = [];
                r.data.map((val) => {
                    _list.push({
                        value: val.id,
                        label: val.name
                    })

                    return null;
                })

                setPageList(_list);
            }
        })
    }
  }, [isConnected])

  useEffect(() => {
    if(pageList) {
      let idx = pageList.findIndex(val => val.value === messengerAccountDetail.channel.fbPageID);
      
      if(idx !== -1) {
        reconAction();
      }else {
        doToast("Page Not Found", "fail");
      }
    }
  }, [pageList])

  let toggleModalRemoveChatbot = () => {
    setIsShowModalRemoveChatbot(!isShowModalRemoveChatbot);
  };

  let reconAction = () => {
    setLoadingRecon(true);

    let data = {
      "id": messengerAccountDetail.channel.id,
      "fbAppID": messengerAccountDetail.channel.fbAppID,
      "fbUserAccessToken": fbAccessToken,
      "fbPageID": messengerAccountDetail.channel.fbPageID,
    };

    IntegrationFacebookServices.recon(orgID, data, res => {
      let dataRes = res.dataResult;

      if(dataRes.status === 200) {
        if(dataRes.data.success) {
          doToast(dataRes.data.message);
          getFacebookAccountDetail(orgID, match.params.accountID);
        }else {
          doToast(dataRes.data.message, "fail")
        }
      }else {
        setIsConnected(false);
        doToast(dataRes.error.message, "fail");
      }

      setLoadingRecon(false);
    })
  }

  const getChatBot = () => {
    IntegrationFacebookServices.getChatBot(orgID, {channelID: parseInt(match.params.accountID)}, (response) => {
      //  let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setMessengerChatbot(response.dataResult.data);
      } else {
        // setMessengerAccountDetail(null);
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorChatbot({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        })

        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingGetDetailData(false);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let getFbData = async () => {
    setIsLoadingGetDetailData(true);

    IntegrationFacebookServices.getFB(orgID, {platform: "facebook"}, (response) => {
        let _retriveDataError = {...retriveDataError};
        let dataResult = response.dataResult;
        
        if(response.dataResult.status === 200) {
            // setMessengerAccountData(response.dataResult.data.channels);
            setFbData(dataResult.data);
        }else {
            _retriveDataError.message = dataResult.error.message;
            _retriveDataError.code = dataResult.error.code;
            setRetrieveDataError(_retriveDataError);
        }
        
        // setIsLoadingGetDetailData(false);
    });
  }

  let connectTo = () => {
    window.FB.login(
        (r) => { 
            setIsConnected(r.status === CONNECTED) 

            if(r.status === CONNECTED) {
                setFbAccessToken(r.authResponse.accessToken);
            } 
        }, 
        { scope: 'pages_manage_metadata,pages_messaging,business_management', return_scopes: true }
    )
  }

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getFacebookAccountDetail(paramID, match.params.accountID);
      
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };
  
  let copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  const getFacebookAccountDetail = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);
    let data = {
      id: parseInt(accountID)
    }

    IntegrationFacebookServices.getDetail(orgID, data, (response) => {
       let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setMessengerAccountDetail(response.dataResult.data);
      } else {
        setMessengerAccountDetail(null);
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingGetDetailData(false);
      }
    });
  };

  const runGetWelcomeMessage = () => {
    setLoadingGetWelcomeMessage(true);

    IntegrationFacebookServices.getWelcomeMessage(orgID, match.params.accountID, (response) => {
        // let _retriveDataError = {...retriveDataError};
        if (response.dataResult.error.message === "") {
            setWelcomeMessageData(response.dataResult.data.welcomeMessage);
        } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorWelcome({
              errorCode: response.dataResult.error.code,
              errorMessage: response.dataResult.error.message
            });

            // doToast(response.dataResult.error.message, 'fail');
        }

        setLoadingGetWelcomeMessage(false);
    });
  }

  const runGetAwayMessage = () => {
    setLoadingGetAwayMessage(true);

    IntegrationFacebookServices.getAwayMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAwayMessageData(response.dataResult.data.awayMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAway({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetAwayMessage(false);
    });
  }

  const runGetClosingMessage = () => {
    setLoadingGetClosingMessage(true);

    IntegrationFacebookServices.getClosingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setClosingMessageData(response.dataResult.data.closingMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorClosing({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetClosingMessage(false);
    });
  }

  const runGetRatingMessage = () => {
    setLoadingGetRatingMessage(true);

    IntegrationFacebookServices.getRatingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setRatingMessageData(response.dataResult.data.userRating);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorRating({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetRatingMessage(false);
    });
  }

  const getMessengerChannelLink = () => {
    let data = {
      id: parseInt(match.params.accountID)
    }

    IntegrationFacebookServices.getChannelLink(orgID, data, (response) => {
      let _retriveDataError = {...retriveDataError};
      let dataResult = response.dataResult;

      if (dataResult.status === 200) {
        setMessengerChannelLink(dataResult.data.channelLink);
      } else {
        if(dataResult.error.code === "40401") {
          setMessengerChannelLinkNeverSet(true);
        }else {
          setMessengerChannelLink(null);
          _retriveDataError.message = dataResult.error.message;
          _retriveDataError.code = dataResult.error.code;
          setRetrieveDataError(_retriveDataError); 
          setIsLoadingGetDetailData(false);
        }
      }

      getFbData();
    });
  }

  const removeChatbot = () => {
    setWaitForResponseRemoveChatbot(true);

    let data = {
      connectionID: messengerChatbot.item.id,
      channelID: parseInt(match.params.accountID),
      chatbotID: messengerChatbot.item.chatbotID,
    };
    
    IntegrationFacebookServices.removeChatBot(
      orgID,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message);
            setWaitForResponseRemoveChatbot(false);
          } else {
            doToast(response.dataResult.data.message);
            toggleModalRemoveChatbot();
            setIsLoadingGetDetailData(true);
            setMessengerChannelLink(null);
            setMessengerChatbot(null);
            setMessengerChannelLinkNeverSet(false);
            getFacebookAccountDetail(orgID, match.params.accountID);
          }
        } else {
          doToast(response.dataResult.data.message, 'fail');
          setWaitForResponseRemoveChatbot(false);
        }
      }
    );
  }

  const back = () => history.push(`/o/${orgID}/integration/messenger`);

  // const editTopic = (id) => history.push(`/o/${orgID}/integration/messenger/edit-topic/${id}`);
  
  // const goToEditChannelButton = (id) => history.push(`/o/${orgID}/integration/messenger/edit-channel-button/${id}`);

  // const goToEditChannelNameButton = (id) => history.push(`/o/${orgID}/integration/messenger/edit-channel-name/${id}`);

  let renderTopics = () => {
    let text = "";
    
    messengerAccountDetail.channel.topics.map((v, i) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  let runGetAutoResolve = () => {
    setLoadingAutoResolve(true);
  
    AutoResolveServices.getAutoResolve(orgID, "facebook", {id: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAutoResolveData(response.dataResult.data.autoResolveConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoResolve({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }
  
      setLoadingAutoResolve(false);
    });
  }

  let runGetAutoUnassign = () => {
    setLoadingAutoUnassign(true);

    AutoUnassignServices.getAutoUnassign(orgID, "facebook", {id: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAutoUnassignData(response.dataResult.data.autoUnassignAgentConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoUnassign({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingAutoUnassign(false);
    });
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-messenger-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading 
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message} 
              />
            </div>
          ) : (
            <>
              <BackButton text="Back to Facebook Messenger" onClick={back} />

              <div className="integration-messenger-detail-account-box-1">
                <div className="integration-messenger-detail-account-box-1-top">
                  <b>{messengerAccountDetail.channel.fbPageName}</b>

                  {/* <div className="integration-messenger-detail-account-box-1-top-button"> */}
                    {/* <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "img",
                          src: iconDelete
                        }}
                        className="red-button main-button-40"
                        text="Delete"
                        position="left"
                        onClickAction={() => toggleModalDeleteChannel()}
                    /> */}
                    {/* <ButtonWithLoadingOrIcon 
                      icon={{
                        type: "svg",
                        src: FiEdit
                      }}
                      className="no-fill-button main-button-40"
                      text="Edit Topic"
                      position="left"
                      onClickAction={() => editTopic(messengerAccountDetail.channel.id)}
                    /> */}
                  {/* </div> */}
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Channel ID</b>
                  </label>
                  <div className="disabled-form"> 
                    <input type="text"
                      value={messengerAccountDetail.channel.stringID}
                      // onClick={copyToClip}
                      id="copy-string-id"
                      readOnly
                      style={{paddingRight: "40px"}}
                    /> 
            
                    <FiCopy 
                      onClick={() => {
                        copyToClip("copy-string-id", "Channel ID copied")
                      }}
                    />
                  </div>
                </div>

                {messengerAccountDetail.channel.topics.length > 0 &&
                  <div className="messenger-detail-topic">
                    <div className="topic-wrapper">
                      <b className="topic-title">Topic</b>
                      <p className="topic-val">
                        <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                      </p>
                    </div>

                    <NavLink to={`/o/${orgID}/integration/messenger/edit-topic/${messengerAccountDetail.channel.id}`}>
                      <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40"
                        text="Edit Topic"
                        position="left"
                      />
                    </NavLink>
                  </div>
                }

                <div className="messenger-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title">Facebook Page ID</b>
                    <p className="topic-val">
                      <b>{messengerAccountDetail.channel.fbPageID}</b>
                    </p>
                  </div>
                </div>

                <div className="messenger-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title">Facebook Page Name</b>
                    <b>{messengerAccountDetail.channel.fbPageName}</b>
                  </div>
                </div>

                {/* <ButtonWithLoadingOrIcon 
                    icon={{
                      type: "svg",
                      src: FiEdit
                    }}
                    className="no-fill-button main-button-40"
                    text="Edit Topic"
                    position="left"
                    onClickAction={() => editTopic(messengerAccountDetail.channel.id)}
                /> */}
              
                <div className="messenger-bot-token-status">
                  <div className="bot-status-wrapper">
                    <b className="bot-status-title">Status</b>
                    <div className="status-wrapper">
                      {messengerAccountDetail.channel.isConnected ? (
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-text text-green">Connected</b>
                        </>
                      ) : (
                        <>
                          <img src={statusDisconnect} alt="" />
                          <b className="status-text text-red">Not Connected</b>
                          
                          <br />

                          {!loadingRecon ?
                            <ButtonWithLoadingOrIcon
                              position="left"
                              icon={{
                                type: "img",
                                src: FacebookIcon
                              }}
                              text="Reconnect"
                              onClickAction={connectTo}
                              className="main-button-48 facebook-button"
                            />
                            :
                            <ButtonWithLoadingOrIcon
                              position="left"
                              isLoading
                              loadingColor="gray"
                              isDisabled
                              text="Reconnect"
                              onClickAction={connectTo}
                              className="main-button-48 facebook-button"
                            />
                          }
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* channel button */}
              <div className="integration-facebook-detail-account-box-1 box-channel-button">
                <div className="integration-facebook-detail-account-box-1-top">
                  <b>Channel Button for Live Chat</b>
                  <p className="bot-name">
                    For more details, <a className="link-orange" rel="noopener noreferrer" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/social-channel-button" target="_blank">please read the documentation.</a>
                  </p>

                  <div className="integration-facebook-detail-account-box-1-top-button">
                    {!messengerChannelLinkNeverSet && messengerChannelLink.isEnabled &&
                      <ButtonWithLoadingOrIcon 
                          icon={{
                          type: "svg",
                          src: FiLink2
                          }}
                          className="no-fill-button main-button-40 button-channel-detail button-channel-detail"
                          text="Preview Link"
                          position="left"
                          onClickAction={() => {
                              window.open(getChannelLink(), "_blank");
                          }}
                      />
                    }

                    <NavLink to={`/o/${orgID}/integration/messenger/edit-channel-button/${messengerAccountDetail.channel.id}`}>
                      <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Edit"
                          position="left"
                      />
                    </NavLink>
                  </div>
                </div>

                <div className="facebook-detail-topic">
                  <div className="channel-button-wrapper">
                      <b className="channel-button-title">Title</b>
                      <br />
                      <b>{messengerChannelLinkNeverSet ? "Not Set" : (messengerChannelLink.title === "" ? "Not Set" : messengerChannelLink.title)}</b>
                  </div>

                  <div className="topic-wrapper status-wrapper">
                    <b className="topic-title">Status</b>
                    <br />
                    
                    {messengerAccountDetail.channel.isChannelLinkEnabled ?
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-value status-enabled">Enabled</b>
                        </>
                        :
                        <>
                          <img src={iconXgrey} alt="" />
                          <b className="status-value">Disabled</b>
                        </>
                    }
                  </div>
                </div>
                
              </div>
              {/* channel button */}

              {/* chatbot */}
              <div className="integration-messenger-detail-account-box-1 box-channel-button">
                <div className="integration-messenger-detail-account-box-1-top">
                  <b>Chatbot</b>
                  <p className='bot-name'>
                    Setup Your Chatbot
                  </p>
                  
                  {!errorChatbot &&
                    <div className="integration-messenger-detail-account-box-1-top-button">
                      {messengerChatbot.item.id !== 0 &&
                        <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "img",
                            src: iconDelete
                          }}
                          className="red-button main-button-40 button-channel-detail"
                          text="Remove Chatbot"
                          position="left"
                          onClickAction={() => toggleModalRemoveChatbot()}
                        />
                      }

                      <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Set Chatbot"
                          position="left"
                          onClickAction={() => {
                            history.push({
                              pathname: `/o/${orgID}/integration/messenger/edit-chatbot/${match.params.accountID}`,
                              state: {
                                accountDetail: messengerAccountDetail,
                                chatbot: messengerChatbot.item
                              }
                            })
                          }}
                      />
                    </div>
                  }
                </div>
                
                {errorChatbot ?
                  <RetrieveDataLoading 
                    isLoading={false}
                    errorCode={errorChatbot ? errorChatbot.errorCode : false}
                    errorMessage={errorChatbot ? errorChatbot.errorMessage : false}
                  />
                  :
                  <div className="facebook-detail-topic">
                    <div className="channel-button-wrapper margin-top24">
                        <b className="channel-button-title">Chatbot Name</b>
                        <br />
                        <b>{messengerChatbot.item.id === 0 ? "Not Set" : messengerChatbot.item.chatbotName}</b>
                    </div>

                    <div className="channel-button-wrapper margin-top24">
                        <b className="channel-button-title">Chatbot Type</b>
                        <br />
                        <b>{messengerChatbot.item.id === 0 ? "Not Set" : messengerChatbot.item.chatbotTypeName}</b>
                    </div>

                    {/* <ButtonWithLoadingOrIcon

                    /> */}
                  </div>
                }

                <PopupRemove
                  removeTitle={`Remove Chatbot?`}
                  removeDescription={`This chatbot will be removed from this channel.`}
                  onClickCancel={toggleModalRemoveChatbot}
                  onClickRemove={removeChatbot}
                  waitForResponse={waitForResponseRemoveChatbot}
                  showModalProps={isShowModalRemoveChatbot}
                  submitText="Delete"
                  colorButton="red-button"
                />
              </div>
              {/* chatbot */}

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/messenger/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={welcomeMessageData}
                  isLoading={loadingGetWelcomeMessage}
                  type="welcome"
                  error={errorWelcome}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/messenger/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={awayMessageData}
                  isLoading={loadingGetAwayMessage}
                  type="away"
                  error={errorAway}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/messenger/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={closingMessageData}
                  isLoading={loadingGetClosingMessage}
                  type="closing"
                  error={errorClosing}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/messenger/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={ratingMessageData}
                  isLoading={loadingGetRatingMessage}
                  type="rating"
                  error={errorRating}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/messenger/auto-resolve-setup/${match.params.accountID}`,
                          state: {
                              type: type,
                              medium: "facebook",
                              backToLink: `/o/${orgID}/integration/messenger/detail/${match.params.accountID}`
                          }
                      })
                  }}
                  data={autoResolveData}
                  isLoading={loadingAutoResolve}
                  type="autoResolve"
                  error={errorAutoResolve}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/messenger/auto-unassign-setup/${match.params.accountID}`,
                          state: {
                              type: type,
                              medium: "facebook",
                              backToLink: `/o/${orgID}/integration/messenger/detail/${match.params.accountID}`
                          }
                      })
                  }}
                  data={autoUnassignData}
                  isLoading={loadingAutoUnassign}
                  type="autoUnassign"
                  error={errorAutoUnassign}
              />
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

const mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

const mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationFacebookMessengerDetailAccount);
