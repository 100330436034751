import React from "react";
import "./BackButton.css";
import { FaChevronLeft } from "react-icons/fa";

const BackButton = ({ text, onClick, _className }) => (
  <button className={`comp-back-button ${_className}`} onClick={onClick}>
    <p>
      <FaChevronLeft />
      &nbsp; &nbsp;
      {text}
    </p>
  </button>
);

export default BackButton;
