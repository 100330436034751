import React, { useState, useEffect } from "react";
import "./OnBoarding.scss";
import { CircularProgressbar } from 'react-circular-progressbar';
import { FiChevronDown } from "react-icons/fi";
import 'react-circular-progressbar/dist/styles.css';
import { Collapse } from 'reactstrap';
import OnBoarding1 from "../../../assets/img/onboarding/onboarding-1.svg";
import IconCheckGreen from "../../../assets/img/onboarding/icon-check-green.svg";
import IconCheckGreenLarge from "../../../assets/img/onboarding/icon-checked-green-large.svg";
import OnBoardingInviteMember from "./onBoardingInviteMember/OnBoardingInviteMember";
import OnBoardingAddTopic from "./onBoardingAddTopic/OnBoardingAddTopic";
import OnBoardingAssignedAgent from "./onBoardingAssignedAgent/OnBoardingAssignedAgent";
import OnBoardingIntegrateChannel from "./onBoardingIntegrateChannel/OnBoardingIntegrateChannel";
import OnBoardingReplyInbox from "./onBoardingReplyInbox/OnBoardingReplyInbox";
// import { getAllOrganizationOnBoardingVal, getOrganizationOnBoardingVal } from "../../../helper/HelperOnBoarding";
import { Modal, ModalBody } from 'reactstrap';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import OnboardingServices from "../../../services/onboarding/OnboardingServices";
import { doToast } from "../../../helper/HelperGeneral";

const TOTAL_ONBOARDING = 5;

let OnBoarding = (props) => {
    let [isShowCollapseChild, setIsShowCollapseChild] = useState([false, false, false, false, false]);
    let [completeCount, setCompleteCount] = useState(0);
    let [isOpenCollapseOnBoarding, setIsOpenCollapseOnBoarding] = useState(false);
    let [lastChangeOnboarding, setLastChangeOnboarding] = useState(false);
    let [statusList, setStatusList] = useState([])

    let runLastChangeOnBoarding = () => {
        setLastChangeOnboarding(new Date());
    }

    const handleComplete = () => {
        setIsShowModalComplete(true)
        toggleCollapseOnBoarding()
        setIsShowCollapseChild([false, false, false, false, false]);
    }

    const LIST_COLLAPSE_ONBOARDING = [
        {
            value: "inviteMember",
            title: "Invite a member to your organization",
            content: <OnBoardingInviteMember {...props} id={1} status={statusList.length > 0 ? statusList[0].isDone : false} runLastChangeOnBoarding={runLastChangeOnBoarding} completeCount={completeCount} handleComplete={handleComplete} />
        },
        {
            value: "addTopic",
            title: "Add topic",
            content: <OnBoardingAddTopic {...props} id={2} status={statusList.length > 1 ? statusList[1].isDone : false} runLastChangeOnBoarding={runLastChangeOnBoarding} completeCount={completeCount} handleComplete={handleComplete} />
        },
        {
            value: "assignedAgent",
            title: "Assign agent to topics",
            content: <OnBoardingAssignedAgent {...props} id={3} status={statusList.length > 2 ? statusList[2].isDone : false} runLastChangeOnBoarding={runLastChangeOnBoarding} completeCount={completeCount} handleComplete={handleComplete} />
        },
        {
            value: "integrateChannel",
            title: "Integrate a channel",
            content: <OnBoardingIntegrateChannel {...props} id={4} status={statusList.length > 3 ? statusList[3].isDone : false} runLastChangeOnBoarding={runLastChangeOnBoarding} completeCount={completeCount} handleComplete={handleComplete} />
        },
        {
            value: "replyInbox",
            title: "Reply to a message in your inbox",
            content: <OnBoardingReplyInbox {...props} id={5} status={statusList.length > 4 ? statusList[4].isDone : false} runLastChangeOnBoarding={runLastChangeOnBoarding} completeCount={completeCount} handleComplete={handleComplete} />
        },
    ]

    useEffect(() => {
        getAllOrganizationOnBoardingStatus()
    }, [])

    useEffect(() => {
        if (lastChangeOnboarding) {
            // doCount();
            getAllOrganizationOnBoardingStatus()
        }
    }, [lastChangeOnboarding])

    useEffect(() => {
        if(completeCount === TOTAL_ONBOARDING) {
            setIsOpenCollapseOnBoarding(false)
        }else {
            setIsOpenCollapseOnBoarding(true)
        }
    }, [completeCount])

    let getAllOrganizationOnBoardingStatus = () => {
        let orgID = props.match.params.orgID.split("-")[0];
        let list = []
        OnboardingServices.postGetStatusList(orgID, (response) => {
            let dataResult = response.dataResult;

            if(dataResult.error.code === "") {
                setStatusList(dataResult.data.items)
                list = dataResult.data.items
                setCompleteCount(list.filter(val => val.isDone).length)
            } else {
                doToast(dataResult.error.message, 'fail');
            }
        })
    };

    // let doCount = (add) => {
    //     if (add) setCompleteCount(completeCount + 1)
    //     else setCompleteCount(completeCount - 1)
    // }

    let toggleCollapseOnBoarding = () => {
        setIsOpenCollapseOnBoarding(!isOpenCollapseOnBoarding)
    }

    let toggleCollapseChild = (index) => {
        let _isShowCollapseChild = [false, false, false, false, false];

        _isShowCollapseChild[index] = !isShowCollapseChild[index];
        setIsShowCollapseChild(_isShowCollapseChild);
    }

    const [isShowModalComplete, setIsShowModalComplete] = useState(false)

    const onDismissModal = () => {
        setIsShowModalComplete(false)
    }

    const renderModalComplete = () => {
        return (
            <Modal isOpen={isShowModalComplete} className="onboarding-complete-modal" toggle={onDismissModal}>
                <ModalBody>
                    <div className="onboarding-complete-wrapper">
                        <img src={IconCheckGreenLarge} alt="" className="check-icon" />
                        <b className="modal-title">Setup guide completed!</b>
                        <p className="modal-description">Congratulations! Now you are ready to reply all incoming messages in your inbox!</p>
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40 btn-complete"
                            text="Dismiss"
                            onClickAction={onDismissModal}
                        />
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    return (
        <div className={`sectionWrap onboarding-wrapper`}>
            <div
                className={`onboarding-collapse-opener onboarding-collapse-opener-1 ${isOpenCollapseOnBoarding ? "active-collapse" : ""}`}
                onClick={toggleCollapseOnBoarding}
            >
                {renderModalComplete()}
                <div className="onboarding-progress-bar">
                    {completeCount === TOTAL_ONBOARDING ?
                        <img src={IconCheckGreenLarge} alt="Onetalk icon checked large" />
                        :
                        <>
                            <CircularProgressbar value={completeCount * 20} strokeWidth={9} />

                            <p>
                                <b>{completeCount}/{TOTAL_ONBOARDING}</b>
                            </p>
                        </>
                    }
                </div>

                <div className="onboarding-collapse-opener-1-info">
                    {completeCount !== TOTAL_ONBOARDING ? 
                        <>
                            <b>Hello{props.userData && `, ${props.userData.account.fullName}`}! Lets get you started!</b>
                            <p>
                                Follow these 5 steps below in order to start using OneTalk Inbox!

                                
                            </p>
                        </>
                        :
                        <>
                            <b>Setup guide completed!</b>
                            <p>
                                Congratulations! Now you are ready to reply all incoming messages in your inbox!
                            </p>
                        </>
                    }
                </div>

                <img src={OnBoarding1} alt="Onetalk onboarding 1" className="onboarding-collapse-opener-1-image" />

                <FiChevronDown className="icon-arrow-collapse" />
            </div>

            <Collapse isOpen={isOpenCollapseOnBoarding} className="onboarding-collapse-all">
                {LIST_COLLAPSE_ONBOARDING.map((value, index) => {
                    return (
                        <React.Fragment key={`onboarding-${index}`}>
                            <div
                                className={`onboarding-collapse-opener onboarding-collapse-opener-child ${isShowCollapseChild[index] ? "active-collapse" : ""}`}
                                onClick={() => toggleCollapseChild(index)}
                            >
                                <div className={`collapse-number ${isShowCollapseChild[index] ? "active-collapse-number" : ""}`}>
                                    {statusList.length > index && statusList[index].isDone ?
                                        <img src={IconCheckGreen} alt="Onetalk icon checked green" />
                                        :
                                        <b>{index + 1}</b>
                                    }
                                </div>

                                <b>{value.title}</b>

                                <FiChevronDown className="icon-arrow-collapse" />
                            </div>

                            <Collapse isOpen={isShowCollapseChild[index]}>
                                {value.content}
                            </Collapse>
                        </React.Fragment>
                    )
                })}
            </Collapse>
        </div>
    )
}

export default OnBoarding;