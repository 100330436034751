import React, { useEffect, useState } from "react";
import "./DetailHistory.scss";
import { FiHash, FiCopy, FiFileText, FiClock } from "react-icons/fi";
import SlideModal from "../../../reuseableComponent/slideModal/SlideModal";
import caseServices from "../../../../services/chat/caseServices";
import { doToast } from "../../../../helper/HelperGeneral";
import HelperDate from "../../../../helper/HelperDate";
import Loading from "../../../reuseableComponent/loading/Loading";

let DetailHistory = (props) => {
    let { toggle, isShow, data, orgID } = props;
    let [isLoadingGetCaseHistory, setIsLoadingGetCaseHistory] = useState(true);
    let [caseHistoryData, setCaseHistoryData] = useState(false);

    useEffect(() => {
        if(isShow) {
            getCaseActionHistory();
        }
    }, [isShow])

    let getCaseActionHistory = () => {
        setIsLoadingGetCaseHistory(true);

        caseServices.getCaseActionHistory(orgID, {id: data.id}, (response) => {
            // let _retriveDataError = { ...retriveDataError };
            if (response.dataResult.status === 200) {
                console.log(response.dataResult.data.items)
                setCaseHistoryData(response.dataResult.data.items);
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }

            setIsLoadingGetCaseHistory(false);
        })
    }

    let copyToClip = (selector, text) => {
        document.getElementById(selector).select();
        document.execCommand("copy");
        doToast(text);
    };

    return (
        <SlideModal 
            _className={`detail-modal-history`}
            toggle={toggle}
            isShow={isShow}
        >
            <div className="detail-modal-content">
                {data &&
                    <>
                        <div className="detail-modal-content-top">
                            <label>
                                <b>Case ID</b>
                            </label>

                            <div className="detail-value-wrapper detail-value-with-copy">
                                <FiHash className="icon-title" />

                                <input type="text"
                                    value={data.stringID}
                                    // onClick={copyToClip}
                                    id="copy-string-id"
                                    readOnly
                                /> 

                                <span className="icon-copy" onClick={() => copyToClip('copy-string-id', "Copied Case ID")}>
                                    <FiCopy />
                                    <b>Copy</b>
                                </span>
                            </div>
                        </div>

                    {console.log("caseHistoryData", caseHistoryData)}
                        <div className="detail-modal-content-bottom">
                            {isLoadingGetCaseHistory ?
                                <Loading />
                                :
                                <>  
                                    {caseHistoryData &&
                                        <>
                                            <b className="case-title">
                                                Case Action History
                                            </b>

                                            <div className="case-action-history-list-wrapper">
                                                {caseHistoryData.map((val, idx) => {
                                                    return (
                                                        <div className="case-action-history-card" key={`list-${idx}`}>
                                                            <p className="action-time">
                                                                <FiClock />
                                                                {HelperDate.formatToString(new Date(val.createdTime), "dd/MM/yyyy HH:mm")}
                                                            </p>
                                        
                                                            <p className="action-text">
                                                                <b>{val.text}</b>
                                                            </p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }

                                    <b className="case-title">
                                        Case Remarks
                                    </b>

                                    <div className="detail-value-wrapper">
                                        <FiFileText className="icon-title" />

                                        <p className="grey-font">
                                            {data.agentRemark === "" ? 
                                                "Write down anything that may help you or others understand the case context better"
                                                :
                                                data.agentRemark
                                            }
                                        </p>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
            </div>
        </SlideModal>
    )
}

export default DetailHistory;