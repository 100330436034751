import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationFacebookServices = {
  getList(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LIST, {}, headers, callback);
  },

  getDetail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);
  },

  sendVerification(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SEND_VERIFICATION, data, headers, callback);
  },

  getLocationList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LOCATION_LIST, data, headers, callback);
  },

  create(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.CREATE, data, headers, callback);
  },

  getChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHANNEL_LINK, data, headers, callback);
  },
  setChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHANNEL_LINK, data, headers, callback);
  },
  deleteChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.DELETE_CHANNEL_LINK, data, headers, callback);
  },
  editTopic(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_TOPIC, data, headers, callback);
  },
  editChannelProfile(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_CHANNEL_PROFILE, data, headers, callback);
  },
  editConvo(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_CONVO, data, headers, callback);
  },
  addLocation(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.ADD_LOC, data, headers, callback);
  },
  addLocationMultiple(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.ADD_LOC_MULTI, data, headers, callback);
  },
  editLocal(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_LOCAL, data, headers, callback);
  },
  editNonLocal(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_NON_LOCAL, data, headers, callback);
  },
  editLocationAlias(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDOT_LOCATION_ALIAS, data, headers, callback);
  },
  locationVerification(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.LOCATION_VERIFICATION, data, headers, callback);
  },
  deleteLocation(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.LOCATION_DELETE, data, headers, callback);
  },
  getLaunchList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.LAUNCH_LIST, data, headers, callback);
  },
  getLaunchListNonLocal(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.LAUNCH_LIST_NON_LOCAL, data, headers, callback);
  },
  launchLocation(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.LOCATION_LAUNCH, data, headers, callback);
  },
  unlaunchLocation(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.LOCATION_UNLAUNCH, data, headers, callback);
  },
  launchLocationNonLocal(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.NON_LOCAL_LAUNCH, data, headers, callback);
  },
  unlaunchLocationNonLocal(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.NON_LOCAL_UNLAUNCH, data, headers, callback);
  },
  getRegionList(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_REGION_LIST, {}, headers, callback);
  },
  getChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHAT_BOT, data, headers, callback);
  },
  setChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHAT_BOT, data, headers, callback);
  },
  removeChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REMOVE_CHAT_BOT, data, headers, callback);
  },
  getWelcomeMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_WELCOME, data, headers, callback);
  },
  getAwayMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_AWAY, data, headers, callback);
  },
  getClosingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_CLOSING, data, headers, callback);
  },
  getRatingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_USER_RATING, data, headers, callback);
  },
  setWelcomeMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);
  },
  setAwayMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);
  },
  setClosingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);
  },
  setRatingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_USER_RATING, data, headers, callback);
  },
};

const API = {
  GET_LIST: '/organization/channel/google_business/get_list',
  GET_DETAIL: '/organization/channel/google_business/get_details',
  SEND_VERIFICATION: '/organization/channel/google_business/submit_agent_verification_request',
  CREATE: '/organization/channel/google_business/create',
  EDIT_CHANNEL_PROFILE: "/organization/channel/google_business/edit_channel_profile",
  EDIT_CONVO: "/organization/channel/google_business/edit_conversational_settings",
  GET_CHANNEL_LINK: '/organization/channel/google_business/get_channel_link',
  SET_CHANNEL_LINK: '/organization/channel/google_business/set_channel_link',
  DELETE_CHANNEL_LINK: '/organization/channel/google_business/delete_channel_link',
  EDIT_TOPIC: '/organization/channel/google_business/edit_topic',
  GET_CHAT_BOT: '/organization/channel/google_business/get_chatbot',
  SET_CHAT_BOT: '/organization/channel/google_business/set_chatbot',
  REMOVE_CHAT_BOT: '/organization/channel/google_business/remove_chatbot',
  GET_WELCOME: "/organization/channel/google_business/get_welcome_message",
  SET_WELCOME: "/organization/channel/google_business/set_welcome_message",
  GET_AWAY: "/organization/channel/google_business/get_away_message",
  SET_AWAY: "/organization/channel/google_business/set_away_message",
  GET_CLOSING: "/organization/channel/google_business/get_closing_message",
  SET_CLOSING: "/organization/channel/google_business/set_closing_message",
  GET_USER_RATING: "/organization/channel/google_business/get_user_rating",
  SET_USER_RATING: "/organization/channel/google_business/set_user_rating",
  GET_LOCATION_LIST: "/organization/channel/google_business/location/get_list",
  ADD_LOC: "/organization/channel/google_business/location/add",
  ADD_LOC_MULTI: "/organization/channel/google_business/location/add_multiple",
  EDIT_LOCAL: "/organization/channel/google_business/entry_points/edit_local",
  EDIT_NON_LOCAL: "/organization/channel/google_business/entry_points/edit_non_local",
  EDOT_LOCATION_ALIAS: "/organization/channel/google_business/location/edit_alias",
  LOCATION_VERIFICATION: "/organization/channel/google_business/location/submit_verification_request",
  LOCATION_DELETE: "/organization/channel/google_business/location/delete",
  LOCATION_LAUNCH: "/organization/channel/google_business/location/launch",
  LOCATION_UNLAUNCH: "/organization/channel/google_business/location/unlaunch",
  NON_LOCAL_LAUNCH: "/organization/channel/google_business/non_local/launch",
  NON_LOCAL_UNLAUNCH: "/organization/channel/google_business/non_local/unlaunch",
  LAUNCH_LIST: "/organization/channel/google_business/location/get_list_for_launch",
  LAUNCH_LIST_NON_LOCAL: "/organization/channel/google_business/get_region_list_for_launch",
  GET_REGION_LIST: "/organization/channel/google_business/get_region_list"
};

export default IntegrationFacebookServices;
