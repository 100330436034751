import React, { useState } from "react";
import "./BarVerifyEmail.scss";
import iconCheckGreen from "../../../assets/img/icon-check-green-new.svg";
import { Modal, ModalBody } from "reactstrap";
import { connect } from 'react-redux';
import { FiAlertTriangle, FiMail } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceAuthRegister from "../../../services/auth/ServiceAuthRegister";
import { doToast } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import { setAuthActive } from "../../../redux/actions/reduxActionAuth";

const BarVerifyEmail = (props) => {
    let [loadingResend, setLoadingResend] = useState(false);
    let [modalConfirmResend, setModalConfirmResend] = useState("");

    let onClickResendEmailVerification = () => {
        let data = {...HelperCustomEncryptor.doDecrypt(props.user.data)};

        setLoadingResend(true);

        ServiceAuthRegister.postResendEmailVerification({email: data.account.email}).then(response => {
            if(response.dataResult.error.code === "") {
                if(response.dataResult.data.success) {
                    setModalConfirmResend("success");
                }else {
                    if(response.dataResult.data.message === "The account has already been verified") {
                        setModalConfirmResend("already");
                        data.account.isEmailVerified = true;

                        props.setAuthActive({
                            data: HelperCustomEncryptor.doEncrypt(data)
                        });
                
                    }else {
                        doToast(response.dataResult.data.message, "fail");
                    }
                }
            }else {
                doToast(response.dataResult.error.message, "fail");
            }

            setLoadingResend(false);
        })
    }

    let ModalConfirmResendComp = () => (
        <Modal isOpen={modalConfirmResend !== ""} className="modal-confirm-resend-comp">
            <ModalBody>
                <img src={iconCheckGreen} alt="" />
                
                {modalConfirmResend === "success" ?
                    <>
                        <p className="modal-confirm-resend-comp-title">
                            <b>Instruction Sent to Email</b>
                        </p>

                        <p className="modal-confirm-resend-comp-desc">
                            Please follow the instructions that have been sent to the registered account <b>{{...HelperCustomEncryptor.doDecrypt(props.user.data)}.account.email}</b>
                        </p>
                    </>
                    :
                    <p className="modal-confirm-resend-comp-title">
                        <b>Your account has been verified</b>
                    </p>
                }

                <ButtonWithLoadingOrIcon 
                    text="OK"
                    onClickAction={() => setModalConfirmResend("")}
                    className="orange-button main-button-40"
                />
            </ModalBody>
        </Modal>
    )
    
    return (
        <div className={`bar-verify-email ${props._className}`}>
            <ModalConfirmResendComp />

            <FiAlertTriangle className="bar-verify-email-icon" />

            <b>
                The email you are using for this account has not been verified. If you are an owner of an organization this may affect your billing invoice. 
            </b>

            {loadingResend ?
                <ButtonWithLoadingOrIcon 
                    isLoading
                    loadingColor="gray"
                    position="left"
                    className="orange-button main-button-22"
                    isDisabled
                    text="Resend Email Verification"
                />
                :
                <ButtonWithLoadingOrIcon 
                    position="left"
                    className="orange-button main-button-22"
                    icon={{
                        type: "svg",
                        src: FiMail
                    }}
                    text="Resend Email Verification"
                    onClickAction={onClickResendEmailVerification}
                />
            }
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {
    setAuthActive
};

export default connect(mapStateToProps, mapDispatchToProps)(BarVerifyEmail)

