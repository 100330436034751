import React, { useState, useEffect } from "react";
import "./FormRequesterInfo.scss";
import { FiInfo, FiArrowLeft } from "react-icons/fi";
import HelperInput  from "../../../../../helper/HelperInput";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupInfo from "../../../../reuseableComponent/popupInfo/PopupInfo";
import PhoneCodePicker from '../../../../reuseableComponent/phoneCodePicker/PhoneCodePicker';
import iconCheckGreen from "../../../../../assets/img/icon-check-large-green-dark.svg";
import { connect } from 'react-redux';

const DEFAULT_REQUESTER_INFO = {
    fullName: "",
    email: "",
    phone: {
        country: {
            value: "",
            countryLabel: "",
            countryFlag: "",
            countryCodeNumber: ""

            // value: 1,
            // countryLabel: "Republic of Indonesia",
            // countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png",
            // countryCodeNumber: "62"

            // id: "0",
            // countryCodeNumber: "62",
            // countryCode: "IDN",
            // countryName: "Indonesia",
            // countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
        },
        number: "",
    },
    jobTitle: ""
}

let FormRequesterInfo = (props) => {
    let { lang, goToForm, submitData, _className, loadingSubmit } = props;
    let [valRequesterInfo, setValRequesterInfo] = useState(DEFAULT_REQUESTER_INFO);
    let [countryList, setCountryList] = useState(false);
    
    useEffect(() => {
        if(props.countryList) {
            let newCountryList = [];

            props.countryList.map((value) => {
                let data = {
                    value: "",
                    label: "",
                    countryLabel: "",
                    countryFlag: "",
                    countryCodeNumber: ""
                }

                data.value = value.id;
                data.label = value.officialName;
                data.countryLabel = value.officialName;
                data.countryFlag = value.flagIconURL;
                data.countryCodeNumber = value.callingCode

                newCountryList.push(data);

                return null;
            })

            setCountryList(newCountryList)
        }
    }, [props.countryList])

    let [isShowModalSubmit, setIsShowModalSubmit] = useState(false);

    let toggleModalUnderReview = () => {
        setIsShowModalSubmit(!isShowModalSubmit);
    }

    let onChangeCountryCode = (e) => {
        let _valRequesterInfo = {...valRequesterInfo};
        _valRequesterInfo.phone.country = e;
        let _data = {...data}
        _data.phoneCountryID = e.value
        if(!_data.phoneNumber) {
            _data.phoneNumber = "+" + e.countryCodeNumber + " "
        } else {
            _data.phoneNumber = "+" + e.countryCodeNumber + " " + splitPhoneNumber(getPhoneNumber(_data.phoneNumber))
        }
        submitData("requester", _data)
        setValRequesterInfo(_valRequesterInfo);
    }

    let [data, setData] = useState({
        fullName: "",
        email: "",
        phoneCountryID: "",
        phoneNumber: "",
        jobTitle: ""
    })
    let [validations, setValidations] = useState({
        email: true,
        phone: true,
    })
    const insertData = (field, e) => {
        let re;
        let value = ""
        let _validations = {...validations}
        let _data = {...data}
        let phone = "+" + valRequesterInfo.phone.country.countryCodeNumber + " " + getPhoneNumber(findData("phoneNumber"));
        phone = phone.replace("+", "").split(" ").join("");

        switch(field) {
            case "phoneNumber":
                // merge with phone code
                re = /^\+[0-9\s]*$/
                _validations.phone = re.test(e.target.value)
                _validations.phone = (re.test(e.target.value) && (phone.length > 6 && phone.length < 16))
                setValidations(_validations)
                value = e.target.value
                break
            case "email":
                // validation
                re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                _validations.email = re.test(e.target.value)
                setValidations(_validations)
                value = e.target.value
                break
            default:
                value = e.target.value
        }
        _data[field] = value
        // setData(_data)
        submitData("requester", _data)
    }

    let findData = (key) => {
        return data[key]
    }

    const submitRequester = () => {
        submitData("requester", data, true)
    }

    let splitPhoneNumber = (phoneNumber) => {
        let number = phoneNumber.replace(/\s/g, '');
        if(number.length > 7) {
            let part1 = number.substring(0, 3),
            part2 = number.substring(3, 7),
            part3 = number.substring(7);
            number = part1 + " " + part2 + " " + part3
        } else if (number.length > 4) {
            let part1 = number.substring(0, 2),
            part2 = number.substring(2);
            number = part1 + " " + part2
        }
        return number
    }

    const getCountryCode = (phone) => {
        let countryCode = String(phone).split(" ")[0]
        // countryCode = countryCode.split("+")[1]
        return countryCode
    }

    const getPhoneNumber = (phone) => {
        let phoneNumber = ""
        if(phone) {
            phoneNumber = String(phone).split(getCountryCode(phone))[1]
            phoneNumber = (phoneNumber ? splitPhoneNumber(phoneNumber) : "")
        }
        
        return HelperInput.phoneBeautify(phoneNumber.replaceAll(" ", ""));
    }

    const checkPhoneNumberFilled = (phone) => {
        let phoneNumber = false
        if(phone) {
            phoneNumber = String(phone).split(getCountryCode(phone))[1].trim()
            phoneNumber = (phoneNumber ? phoneNumber.trim() : false)
        }
        return phoneNumber
    }

    const validateForm = () => {
        let valid = true

        if(!validations.email) valid = false
        else if(!validations.phone) valid = false
        else if(!data.fullName) valid = false
        else if(!data.email) valid = false
        else if(!data.phoneCountryID) valid = false
        else if(!checkPhoneNumberFilled(data.phoneNumber)) valid = false
        else if(!data.jobTitle) valid = false
        return valid
    }

    useEffect(() => {
        if(props.data.requester && props.data.requester.phoneNumber) {
            let countryCode = getCountryCode(props.data.requester.phoneNumber)
            let value = props.countryList.find((obj) => obj.countryCode === countryCode)
            if(value) {
                let country = {
                    value: value.id,
                    countryLabel: value.officialName,
                    countryFlag: value.flagIconURL,
                    countryCodeNumber: value.callingCode
                }
                onChangeCountryCode(country)
            }
        }
        setData({...props.data.requester})
    }, [props.data])

    return (
        <div className={`form-requester-info form-content-wrapper ${_className}`}>
            <PopupInfo
                isOpen={isShowModalSubmit}
                toggle={toggleModalUnderReview}
                titleImage={iconCheckGreen}
                title={`Registration Submitted on 12/12/2020!`}
                description={`We will contact you through email or phone once your submission has been apporved.`}
                textButton={`Dismiss`}
                actionButton={toggleModalUnderReview}
            />

            <label>
                <b>{lang.pageWaba.form3.textForm1}</b> 
            </label>

            <input type="text" disabled={loadingSubmit} defaultValue={findData("fullName")} placeholder={lang.pageWaba.form3.textForm2} onChange={(e) => insertData("fullName", e)} />

            <label>
                <b>{lang.pageWaba.form3.textForm3}</b> 
            </label>

            <input type="text" disabled={loadingSubmit} defaultValue={findData("email")} placeholder={lang.pageWaba.form3.textForm3a} className={validations.email ? "" : "border-warning-red"} onChange={(e) => insertData("email", e)}/>
            {validations.email ? "" : <div className="input-info-wrapper warning-text">
                <FiInfo />
                <b>{lang.pageWaba.form3.textWarning1}</b>
            </div>}

            <label>
                <b>{lang.pageWaba.form3.textForm4}</b> 
            </label>

            <div className={"phone-input-wrapper" + (loadingSubmit ? " phone-input-disabled" : "")}>
                <PhoneCodePicker
                    withoutCode={true}
                    countryListProps={countryList}
                    indexPhoneCodePickerProps={0}
                    countryProps={valRequesterInfo.phone.country}
                    onChangeCountryCodeProps={onChangeCountryCode}
                    isMultipleProps={false}
                    isDisabled={loadingSubmit}
                />

                {/* <input type="text" defaultValue={getPhoneNumber(findData("phoneNumber"))} className={"phone-input" + (validations.phone ? "" : " border-warning-red")} placeholder={lang.pageWaba.form3.textForm4a} onChange={(e) => insertData("phoneNumber", e)}/> */}
                <input 
                    type="text" 
                    // value={findData("phone")}
                    disabled={loadingSubmit}
                    value={data.phoneCountryID ?
                        "+" + valRequesterInfo.phone.country.countryCodeNumber + " " + getPhoneNumber(findData("phoneNumber"))
                        : ""
                    } 
                    className={"phone-input" + (validations.phone ? "" : " border-warning-red")} 
                    placeholder={"Select country first"}
                    // disabled={!data.phoneCountryID}
                    pattern="\+[0-9\s]*"
                    onChange={(e) => {
                        if(e.target.validity.valid && data.phoneCountryID) insertData("phoneNumber", e)
                    }}
                />
            </div>
            {validations.phone ? "" : <div className="input-info-wrapper warning-text">
                <FiInfo />
                <b>{lang.pageWaba.form2.textWarning3}</b>
            </div>}

            <label>
                <b>{lang.pageWaba.form3.textForm6}</b> 
            </label>

            <input type="text" disabled={loadingSubmit} defaultValue={findData("jobTitle")} placeholder={lang.pageWaba.form3.textForm7} onChange={(e) => insertData("jobTitle", e)}/>
            <div className="input-info-wrapper">
                <FiInfo />
                <b>{lang.pageWaba.form3.textForm8}</b>
            </div>

            <ButtonWithLoadingOrIcon
                className="no-fill-button main-button-40 width-100 submit-button"
                icon={{
                    type: "svg",
                    src: FiArrowLeft
                }}
                position="left"
                isDisabled={loadingSubmit}
                text={lang.pageWaba.form3.textForm9}
                onClickAction={() => goToForm(1)}
                // isDisabled={!this.validateForm()}
            />

            <ButtonWithLoadingOrIcon
                className="orange-button main-button-40 width-100 submit-button"
                text={lang.pageWaba.form3.textForm10}
                onClickAction={submitRequester}
                loadingColor="gray"
                position="left"
                isLoading={loadingSubmit}
                isDisabled={!validateForm() || loadingSubmit}
            />
        </div>
    )
}

const mapDispatchToProps = {
    
};

const mapStateToProps = state => ({
    countryList: state.countryList,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(FormRequesterInfo);