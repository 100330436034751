import React, { useState, useEffect } from "react";
import "./PhoneNumberList.scss";
import HelperInput from "../../../../../helper/HelperInput";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";

let PhoneNumberList = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);

  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetTemplateData, setIsLoadingGetTemplateData] = useState(false);

  let checkID = (match) => {
    let { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  // let [pageDisplay, setPageDisplay] = useState(1);
  // let [listPage, setListPage] = useState(20);

  let [phoneNumber, setPhoneNumber] = useState([]);

  useEffect(() => {
    doMount();
    // let data = [
    //   {
    //     "id": 1,
    //     "name": "greetings_v1",
    //     "categoryCode": "ISSUE_RESOLUTION",
    //     "categoryName": "Issue Resolution",
    //     "templateType": "text",
    //     "isEnabled": true,
    //     "createdTime": 1611496973940,
    //     "updatedTime": 0,
    //     "deletedTime": 0
    //   }
    // ];
    // setPhoneNumber(data);
  }, []);

  useEffect(() => {
    if (orgID) {
      setIsLoadingGetTemplateData(true);
      let data = {
        channelID: Number(match.params.id),
      };

      IntegrationWhatsappBusinessServices.getPhoneNumber(
        orgID,
        data,
        (response) => {
          let _retrieveDataError = { ...retrieveDataError };

          if (response.dataResult.error.message === "") {
            setPhoneNumber(response.dataResult.data.items);
          } else {
            _retrieveDataError.message = response.dataResult.error.message;
            _retrieveDataError.code = response.dataResult.error.code;
            setRetrieveDataError(_retrieveDataError);
          }

          setIsLoadingGetTemplateData(false);
        }
      );
    }
  }, [orgID]);

  return (
    <div className="phone-number-whatsapp sectionWrap">
      {/* <SectionWrap {...props} orgID={orgID} withHeader withSideMenu> */}
      <div className="phone-number-whatsapp-top">
        <b>Phone Numbers</b>
      </div>
      {/* </SectionWrap> */}
      {isLoadingGetTemplateData || retrieveDataError.code ? (
        <div className="phone-number-whatsapp-loading-wrapper">
          <RetrieveDataLoading
            isLoading={isLoadingGetTemplateData}
            errorMessage={retrieveDataError.message}
          />
        </div>
      ) : (
        <div className="phone-number-table-wrapper">
          <div className="custom-table">
            <table className="custom-table-content table-phone-number-list no-hover">
              <thead>
                <tr>
                  <td className="col_td_left">Phone Number ID</td>
                  <td className="col_td_left">Displayed Phone Number</td>
                  <td className="col_td_center text-center">Verified Name</td>
                  <td className="col_td_center text-center">Quality Rating</td>
                </tr>
              </thead>
              <tbody>
                {phoneNumber.length > 0 ? (
                  phoneNumber.map((item, idx) => {
                    return (
                      <tr key={`cost-${idx}`}>
                        <td className="">
                          <p className="">{item.id}</p>
                        </td>
                        <td className="">
                          <p className="">+{HelperInput.phoneBeautify(item.phoneNumber)}</p>
                        </td>
                        <td className="col_td_center text-center">
                          <p>{item.verifiedName === "" ? "-" : item.verifiedName}</p>
                        </td>
                        <td className="col_td_center text-center">
                          <p>{item.qualityRating === "" ? "-" : item.qualityRating}</p>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="no-data-row">
                    <td
                      colSpan="8"
                      className="no-data-column"
                      style={{ textAlign: "center" }}
                    >
                      <b className="no-result-found">
                        No phone number found.
                      </b>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhoneNumberList;
