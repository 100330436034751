import React from "react";
import './ColoredInfoBox.css'

const ColoredInfoBox = ({text, style = {}, color, icon}) => {
    const divStyle = {
        ...style,
        backgroundColor: color,
    }
    return(
        <div style={divStyle} className="colored-info-box">
           <p>{icon}{text}</p>
        </div>
    )
}

export default ColoredInfoBox;
