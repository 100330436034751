import React, { useState } from "react";
import "./QuickReplyAddInteractiveHeader.scss";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { FiInfo, FiUploadCloud, FiFile, FiTrash2, FiEye, FiX } from "react-icons/fi";
import HelperUpload from "../../../../../helper/HelperUpload";
import iconImage from "../../../../../assets/img/broadcast-message/icon_image.svg";
import iconFile from "../../../../../assets/img/broadcast-message/icon_file.svg";
import iconText from "../../../../../assets/img/broadcast-message/icon_text.svg";
import IconNoHeader from "../../../../../assets/img/broadcast-message/icon_no_header.svg";
import ErrorOrInfoComp from "../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { doToast } from "../../../../../helper/HelperGeneral";

let QuickReplyAddInteractiveHeader = (props) => {
  let { data, errorMessageData, onChangeHeaderType, onChangeText, onChangeDocument, isWaitResponseAddQuickReply, runErrorDoc } = props;

  let [showModalMediaPreview, setShowModalMediaPreview] = useState(false);

  let toggleModalMediaPreview = () => {
    setShowModalMediaPreview(!showModalMediaPreview);
  }

  const MESSAGE_TYPE = [
    {
      type: "text",
      img: iconText,
      text: "Text"
    },
    {
      type: "media",
      img: iconImage,
      text: "Media"
    },
    {
      type: "document",
      img: iconFile,
      text: "Document"
    },
    {
      type: "no_header",
      img: IconNoHeader,
      text: "No Header"
    },
  ];

  let renderModalMediaPreview = () => {
    return (
      <Modal className="modal-image-preview" toggle={toggleModalMediaPreview} isOpen={showModalMediaPreview}>
        <ModalBody>
          <FiX onClick={toggleModalMediaPreview} />

          {data.document !== false &&
            (data.document.type &&
              <>
                {data.document.type.split("/")[0] === "image" &&
                  <img src={data.document.link ? data.document.link : window.URL.createObjectURL(data.document)} alt="" />
                }

                {data.document.type.split("/")[0] === "video" &&
                  <video src={data.document.link ? data.document.link : window.URL.createObjectURL(data.document)} controls />
                }
              </>
            )
          }
        </ModalBody>
      </Modal>
    )
  }

  let handleChangeDocument = (e) => {
    HelperUpload.checkFileSizeLimit(e.target.files, {
      onAllowed: (result) => {
        // console.log(result)
        onChangeDocument(result);
      },
      onReachLimit: (text) => {
        runErrorDoc("Failed to upload, Maximum upload size for documents: 25MB");
        doToast(text, "fail");
      }
    }, 26214400)
    
    e.target.value = null;
  }
  
  let handleChangeMedia = (e) => {
    // name, size, type,
    let fileType = e.target.files[0].type.split("/")[0];
    
    HelperUpload.checkFileSizeLimit(e.target.files, {
      onAllowed: (result) => {
        let mediaFileReader = new FileReader();
        
        mediaFileReader.onload = (e) => {
          result[0].fileSrc = e.target.result;
          onChangeDocument(result);
        }
        
        mediaFileReader.readAsDataURL(result[0]);
      },
      onReachLimit: (text) => {
        runErrorDoc("Failed to upload, maximum upload size for image: 5MB/video: 16MB");
        doToast(text, "fail");
      }
    }, fileType === "image" ? 5242880: 16777216)

    e.target.value = null;
  }

  return (
    <>
      {renderModalMediaPreview()}

      <div className="header-type-quick-reply-outer-wrapper">
        <div className={`header-type-quick-reply-wrapper`}>
          {MESSAGE_TYPE.map((_val, _idx) => {
              return (
                  <div 
                      className={`message-type-selection-selection ${data.headerType === _val.type ? "active-message-type-selection-selection" : ""}`} 
                      key={`message-type-selection-${_idx}`} 
                      onClick={() => onChangeHeaderType(_val.type)}
                  >
                      <div className={`message-type-selection-icon-wrapper message-type-selection-${_val.type}`}>
                          <img src={_val.img} alt="" />
                      </div>

                      {_val.text}
                  </div>
              )
          })}
        </div>
        
        {/* text */}
        {data.headerType === "text" &&
          <>
            <label className="label-header-type-quick-reply">
              <b>Header Content</b>
              <span className={`grey-font  ${errorMessageData.headerText === "Characters exceed limit" ? "red-text" : ""}`}>{data.headerText.length}/20 characters</span>
            </label>
            <input disabled={isWaitResponseAddQuickReply} type="text" value={data.headerText} onChange={(e) => onChangeText("headerText", e.target.value)} placeholder="Type your header here" className={errorMessageData.headerText !== "" ? "border-red" : ""} />
            {errorMessageData.headerText !== "" &&
              <ErrorOrInfoComp 
                text={errorMessageData.headerText}
                icon={<FiInfo />}
                _className="red-text"
              />
            }
          </>
        }
        {/* text */}

        {/* media */}
        {data.headerType === "media" && 
          <>
            <input type="file" className="media-interactive" accept="image/*, video/*" onChange={(e) => handleChangeMedia(e)} />
            <b className="header-content">Header Content</b>

            {data.document === false ?
              <ButtonWithLoadingOrIcon 
                position="left"
                icon={{
                  src: FiUploadCloud,
                  type: "svg"
                }}
                text="Upload Media"
                className="main-button-40 light-orange-button button-upload-document"
                onClickAction={() =>{
                  let el = document.querySelectorAll(".media-interactive");
                  
                  if(el.length > 0) {
                    el[0].click();
                  }
                }}
                isDisabled={isWaitResponseAddQuickReply}
              />
              :
              <>
                <ButtonWithLoadingOrIcon 
                  position="left"
                  isDisabled={isWaitResponseAddQuickReply}
                  icon={{
                    src: FiTrash2,
                    type: "svg"
                  }}
                  text="Delete Media"
                  className="main-button-22 no-fill-button-red"
                  onClickAction={onChangeDocument}
                />

                <ButtonWithLoadingOrIcon 
                  position="left"
                  isDisabled={isWaitResponseAddQuickReply}
                  icon={{
                    src: FiUploadCloud,
                    type: "svg"
                  }}
                  text="Replace Media"
                  className="main-button-22 light-orange-button"
                  onClickAction={() =>{
                    let el = document.querySelectorAll(".media-interactive");
                    
                    if(el.length > 0) {
                      el[0].click();
                    }
                  }}
                />

                <div className="media-value-wrapper">
                  {data.document.type.split("/")[0] === "video" &&
                    <video src={data.document.link ? data.document.link : data.document.fileSrc} />
                  }

                  {data.document.type.split("/")[0] === "image" &&
                    <img src={data.document.link ? data.document.link : URL.createObjectURL(data.document)} alt="" />
                  }
                  <div className="media-value-loading-wrapper" onClick={toggleModalMediaPreview}>
                    {/* <div className="lds-ring">
                      <div /><div /><div /><div />
                    </div> */}
                    <FiEye />
                  </div>
                </div>
              </>
            }

            <ErrorOrInfoComp 
              text={errorMessageData.document === "" ? "Maximum upload size for image: 5MB/video: 16MB" : errorMessageData.document}
              icon={<FiInfo />}
              _className={errorMessageData.document === ""  ? "" : "red-text"}
            />
          </>
        }
        {/* media */}

        {/* document */}
        {data.headerType === "document" &&
          <>
            <input type="file" className="document-interactive" onChange={(e) => handleChangeDocument(e)} />
            <b className="header-content">Header Content</b>

            {data.document === false ?
              <ButtonWithLoadingOrIcon 
                position="left"
                icon={{
                  src: FiUploadCloud,
                  type: "svg"
                }}
                text="Upload Document"
                className="main-button-40 light-orange-button button-upload-document"
                onClickAction={() =>{
                  let el = document.querySelectorAll(".document-interactive");
                  
                  if(el.length > 0) {
                    el[0].click();
                  }
                }}
                isDisabled={isWaitResponseAddQuickReply}
              />
              :
              <>
                <ButtonWithLoadingOrIcon 
                  position="left"
                  icon={{
                    src: FiTrash2,
                    type: "svg"
                  }}
                  text="Delete Document"
                  className="main-button-22 no-fill-button-red"
                  onClickAction={onChangeDocument}
                  isDisabled={isWaitResponseAddQuickReply}
                />

                <ButtonWithLoadingOrIcon 
                  position="left"
                  icon={{
                    src: FiUploadCloud,
                    type: "svg"
                  }}
                  text="Replace Document"
                  className="main-button-22 light-orange-button"
                  onClickAction={() =>{
                    let el = document.querySelectorAll(".document-interactive");
                    
                    if(el.length > 0) {
                      el[0].click();
                    }
                  }}
                  isDisabled={isWaitResponseAddQuickReply}
                />

                <div className="document-value-wrapper">
                  <FiFile />

                  {data.document.name ?
                    data.document.name
                    :
                    data.mediaFilename
                  }

                  {/* <div className="lds-ring">
                    <div /><div /><div /><div />
                  </div> */}
                </div>
              </>
            }

            <ErrorOrInfoComp 
              text={errorMessageData.document === "" ? "Maximum upload size for documents: 25MB" : errorMessageData.document}
              icon={<FiInfo />}
              _className={errorMessageData.document === ""  ? "" : "red-text"}
            />
          </>
        }
        {/* document */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(QuickReplyAddInteractiveHeader);
  