import React from "react";
import "./StepComp.scss";
import { FiCheck } from "react-icons/fi"; 

let StepComp = (props) => {
    let {
        steps,
        activeForm
    } = props;

    return (
        <div className="form-step">
            {steps.map((value, index) => {
                return (
                    <React.Fragment  key={`step-${index}`}>
                        <div className={`form-step-content ${index === activeForm ? "active-form-step" : ""}`}>
                            {index < activeForm ?
                                <div className={`value-number value-check`}>
                                    <FiCheck />
                                </div>
                                :
                                <div className={`value-number ${index !== activeForm ? "not-active-value-wrapper" : ""}`}>
                                    <b>{index + 1}</b>
                                </div>
                            }

                            <b>{value.label}</b>
                        </div>

                        {index < steps.length - 1  && <div className="line-separator" />}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default StepComp;