import React, { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";
import "./IntegrationTokopediaEdit.scss";
import "react-toastify/dist/ReactToastify.css";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";
import IntegrationTokopediaServices from "../../../../services/integration/IntegrationTokopediaServices";

let IntegrationTokopediaEdit = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [topicList, setTopicList] = useState([]);
    let [topicListOrigin, setTopicListOrigin] = useState([]);
    let [errorMessageData, setErrorMessageData] = useState({
        topic: ""
    })
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [isCheckAll, setIsCheckAll] = useState(false);
    let [selectedTopic, setSelectedTopic] = useState([]);
    let [selectedTopicOrigin, setSelectedTopicOrigin] = useState([]);
    let [searchTopic, setSearchTopic] = useState("");

    let back = () => history.push(`/o/${props.match.params.orgID}/integration/tokopedia/detail/${parseInt(props.match.params.accountID)}`);

    let onSubmitClick = () => {
       let _errorMessageData = {...errorMessageData};
       let errorCount = 0;
       let errorClass = "";

       if(selectedTopic.length === 0) {
        errorCount = errorCount + 1;
        _errorMessageData.topic = "Please select topic";

        errorClass = "input-topic";
       }

       if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
       }else {
        setIsLoadingSubmit(true);

        let topics = [];

        selectedTopic.map((v) => {
            topics.push(v.id);
            return null;
        })

        IntegrationTokopediaServices.editTopic(orgID, {topicIDs: topics, id: parseInt(props.match.params.accountID)}, (res) => {
            let _res = res.dataResult;

            if(_res.error.code === "") {
                if(_res.data.success) {
                    doToast(_res.data.message);
                    setSelectedTopicOrigin(selectedTopic);
                    
                    setTimeout(() => {
                        back();
                    }, 3000)
                }else {
                    doToast(_res.data.message, "fail");
                    setIsLoadingSubmit(false);
                }
            }else {
                doToast(_res.error.message, "fail");
                setIsLoadingSubmit(false);
            }
        })
       }
    };

    let doMount = () => {
        let paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    let { id, createdTime } = responseData.memberships[0].organization;
                    let urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        topicServices.getTopic(orgID, "list", null, (response) => {
            let data = response.dataResult.data.topics;
            let createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.id = val.id;
                    _data.name = val.name;
                    newData.push(_data);
                    return null;
                });
                setTopicList(newData);
                setTopicListOrigin(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        if(props.location.state) {
            setSelectedTopic(props.location.state.topic);
            setSelectedTopicOrigin(props.location.state.topic);
        }else {
            back();
        }
        doMount();
    }, []);

    useEffect(() => {
        if(orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    let onChangeSingleSelect = (e, val) => {
        if(!isLoadingSubmit) {
          let _selectedTopic = [...selectedTopic]
          
          if (e.target.checked) {
              _selectedTopic = [..._selectedTopic, val]
          } else {
              let _dataSelectedTopic = [..._selectedTopic]
              _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
              setIsCheckAll(false);
          }
          
          let _errorMessageData = {...errorMessageData};
          _errorMessageData.topic = "";
          setErrorMessageData(_errorMessageData);
          setSelectedTopic(_selectedTopic);
        }
    }

    let onClearAll = () => {
        if(!isLoadingSubmit) {
            setIsCheckAll(false);
            setSelectedTopic([]);
        }
    }

    let onCheckAll = (e) => {
        if(!isLoadingSubmit) {
            if (e.target.checked) {
            let _errorMessageData = {...errorMessageData};
            _errorMessageData.topic = "";
            setErrorMessageData(_errorMessageData);

                setSelectedTopic(topicList);
            } else {
                setSelectedTopic([]);
            }
        
            setIsCheckAll(e.target.checked);
        }
    }

    let isChecked = (id) => {
        let _data = [...selectedTopic]
        return _data.findIndex(val => val.id === id) !== -1;
    }

    let removeSingleTopic = (val) => {
        setIsCheckAll(false);
        let _selectedTopic = [...selectedTopic];
        let _dataSelectedTopic = [..._selectedTopic]

        _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

        setSelectedTopic(_selectedTopic);
    }
    
    let onChangeSearchTopic = (e) => {
        let _topicOrigin = topicListOrigin.slice();
        let result = [];
        setSearchTopic(e.target.value);
    
        if (_topicOrigin.length > 0) {
            for (let i in _topicOrigin) {
            let objectKey = Object.keys(_topicOrigin[i]);
    
            for (let j in objectKey) {
                let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
                if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
                    result.push(_topicOrigin[i])
                    break;
                }
            }
            }
        }
    
        setTopicList(result);
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={JSON.parse(JSON.stringify({
                    topic: selectedTopicOrigin
                }))}
                dataAfterChange={JSON.parse(JSON.stringify({
                    topic: selectedTopic
                }))}
            >
                <div className="sectionWrap integration-main-container integration-add-account text-left">
                    <BackButton text="Back to Tokopedia Account Detail" onClick={back} />

                    <div className="integration-add-account-box">
                        <div className="integration-add-account-box-top">
                            <b>Edit Channel Details</b>
                            
                            {isLoadingSubmit ?
                                <ButtonWithLoadingOrIcon 
                                    // isDisabled={!isFormValid()} 
                                    isLoading
                                    position="left"
                                    loadingColor="gray"
                                    className="orange-button main-button-40"
                                    text="Save Changes"
                                    isDisabled
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    // isDisabled={!isFormValid()} 
                                    onClickAction={onSubmitClick}
                                    className="orange-button main-button-40"
                                    text="Save Changes"
                                    position="left"
                                />
                            }
                        </div>

                        <div className="integration-add-account-box-form">
                            <SelectTopic 
                                onChangeSearchTopic={onChangeSearchTopic}
                                onCheckAll={onCheckAll}
                                onClearAll={onClearAll}
                                topicList={topicList}
                                onChangeSingleSelect={onChangeSingleSelect}
                                isChecked={isChecked}
                                isCheckAll={isCheckAll}
                                searchTopic={searchTopic}
                                title={"Topic"}
                                selectedTopic={selectedTopic}
                                removeSingleTopic={removeSingleTopic}
                                _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                                isDisabled={isLoadingSubmit}
                            />

                            <ErrorOrInfoComp
                                text={errorMessageData.topic === "" ? "Customer will be able to choose if you select more than one topics." : errorMessageData.topic}
                                _className={errorMessageData.topic !== "" ? "font-red" : ""}
                                icon={<FiInfo />}
                            />
                        </div>
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationTokopediaEdit;