import React from "react";
import "./IntegrationSalestalkCustomConfig.scss";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import {checkID, doToast} from "../../../../helper/HelperGeneral";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceSalesTalkDefault from "../../../../services/salesTalk/default/SalesTalkDefault";
// import HelperInput from "../../../helper/HelperInput";
// import HelperInput from "../../../../helper/HelperInput";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const IntegrationSalestalkCustomConfig = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = React.useState(false);
  let [customProviderData, setCustomProviderData] = React.useState("");
  let [customProviderDataOrigin, setCustomProviderDataOrigin] = React.useState("");
  let [isWaitResponseSet, setIsWaitResponseSet] = React.useState(false);

  const back = () => {
    history.push(`/o/${orgID}/integration/salestalk`);
  };

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // const { id, createdTime } = responseData.memberships[0].organization;
          // const urlOrgID = `${id}-${createdTime}`;
          back();
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  React.useEffect(() => {
    doMount();
  }, [location.pathname]);

  const onChangeCustomProvider = (e) => {
    setCustomProviderData(e.target.value);
  };

  const onSubmitSet = (e) => {
    e.preventDefault();
    setIsWaitResponseSet(true);

    ServiceSalesTalkDefault.postSetCustomProvider(orgID, {apiURL: customProviderData}, (response) => {
      let dataResult = response.dataResult;
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);


      if(dataResult.error.message === "") {
        if(dataResult.data.success) {
          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Set SalesTalk Custom Provider",
              {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
              }
            )
          }
          
          doToast(dataResult.data.message);
          setCustomProviderDataOrigin(customProviderData);

          setTimeout(() => {
           back();
          }, 3000)
        }else {
          doToast(dataResult.error.message, "fail"); 
          setIsWaitResponseSet(false); 
        }
      }else {
        doToast(dataResult.error.message, "fail");
        setIsWaitResponseSet(false);
      }
    });
  }
  
  // const isButtonSubmitDisabled = () => {
  //   return customProviderData.length === 0 || !HelperInput.validURL(customProviderData);
  // }

  return (
    <React.Fragment>
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={customProviderDataOrigin}
            dataAfterChange={customProviderData}
        >   
            <BackButton text="Back to SalesTalk" onClick={back} />

            <div className="sectionWrap form-custom-provider">
                <form onSubmit={(e) => {
                      onSubmitSet(e);
                }}>
                    <div className="form-title-wrapper">
                        <b>Custom Provider</b>

                        <p className="form-title-desc">
                            Connect your SalesTalk with a custom provider
                        </p>
                    </div>
                
                    <div className="form-content-wrapper">
                        <label><b>API URL</b></label>
                        <input 
                            type="text" 
                            placeholder="Insert store URL" 
                            value={customProviderData} 
                            onChange={(e) => {
                                onChangeCustomProvider(e);
                            }}
                            disabled={isWaitResponseSet}
                        />

                        <p className="input-info-wrapper">
                            for more details, <a rel="noopener noreferrer" href="https://taptalk-io.github.io/onetalk/salestalk-custom-provider-integration.html" target="_blank">please read this documentation.</a> 
                        </p>

                        {!isWaitResponseSet ?
                            <ButtonWithLoadingOrIcon 
                              className="orange-button main-button-40"
                              text="Save Changes"
                              // isDisabled={isButtonSubmitDisabled()}
                            />
                            :
                            <ButtonWithLoadingOrIcon 
                              isLoading
                              loadingColor="gray"
                              isDisabled
                              className="orange-button main-button-40"
                              text="Save Changes"
                              position="left"
                            />
                        }
                    </div>
                </form>
            </div>
        </SectionWrap>
    </React.Fragment>
  );
};

export default IntegrationSalestalkCustomConfig;