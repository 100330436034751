import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./IntegrationEmail.scss";
import { connect } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { FiArrowUpRight, FiPlus } from "react-icons/fi";
// import IconAdd from "../../../assets/img/icon-add.svg";
import LogoEmail from "../../../assets/img/integration/logo-email-side-menu.svg";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import { checkID } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import IntegrationEmailServices from "../../../services/integration/IntegrationEmailServices";
// import Integrations from "../Integrations";
import mixpanel from "mixpanel-browser";

const IntegrationEmail = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingAccount, setIsLoading] = useState(true);
    let [accountData, setAccountData] = useState([]);
    let [showPopupWarning, setShowPopupWarning] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });

    let toggleShowWarning = () => {
        setShowPopupWarning(!showPopupWarning);
    }

    let getAccount = (orgID) => {
        let _retriveDataError = {...retriveDataError};
        setIsLoading(true);

        IntegrationEmailServices.getList(orgID, (response) => {
            let dataResult = response.dataResult;

            if(response.dataResult.status === 200) {
                setAccountData(response.dataResult.data.channels);
            }else {
                setAccountData([]);
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsLoading(false);
        })
    }

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
            getAccount(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(orgID) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Page] Email Integration",
                    {
                        userFullName : myAgentData.account.fullName,
                        userID : myAgentData.account.id,
                        organizationID : orgID.split("-")[0]
                    }
                );
            }
        }
    }, [orgID])

    let goToAdd = () => {
        // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        // if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        //     mixpanel.track(
        //         "[Button] Add Channel",
        //         {
        //             channel: "WhatsApp SME",
        //             userFullName : myAgentData.account.fullName,
        //             userID : myAgentData.account.id,
        //             organizationID : orgID.split("-")[0]
        //         }
        //     );
        // }

        if(!props.mediumData) {
            toggleShowWarning();
        }else {
            if(props.mediumData.numberOfItems.maximum !== -1 && props.mediumData.numberOfItems.current >= props.mediumData.numberOfItems.maximum) {
                toggleShowWarning();
            }else {
                history.push(`/o/${orgID}/integration/email/add`);
            }
        }
    };

    // let goToDetail = (account) => history.push(`/o/${orgID}/integration/email/detail/${account.id}`);

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <PopupWarning
                    isShow={showPopupWarning}
                    toggle={toggleShowWarning}
                    text="Maximum number of allowed channels has been reached"
                />
                {/* <Integrations activeIntegrationChannel="instagram" parentProps={props} orgIDProps={orgID} /> */}
                {/* tier blocking */}
                {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                    isLoading={isLoadingAccount}
                    errorMessage={retriveDataError.message}
                    errorCode={retriveDataError.code}
                    />
                }
                {/* tier blocking */}
                <div className="sectionWrap integration-main-container integration-email-list text-left">
                    <React.Fragment>
                        <div className="integration-list-header">
                            <img src={LogoEmail} alt="" className="integration-list-header-icon" />

                            <div className="integration-list-header-text-wrapper">
                                <b>Email</b>
                                <p>
                                    To integrate with Email, <a rel="noopener noreferrer" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/email-integration" target="_blank">please read the documentation.</a>
                                </p>
                            </div>

                            {(!isLoadingAccount && !retriveDataError.code) &&
                                <ButtonWithLoadingOrIcon 
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    className="no-fill-button integration-list-header-button main-button-40"
                                    text="Add Channel"
                                    position="left"
                                    onClickAction={goToAdd}
                                />
                            }
                        </div>
                        
                        {isLoadingAccount || retriveDataError.code ?
                            <RetrieveDataLoading
                                isLoading={isLoadingAccount}
                                errorMessage={retriveDataError.message}
                            />
                            :
                            accountData.length > 0 ?
                                <>
                                    <div className="integration-list-outer-wrapper">
                                        {accountData.map((value, index) => {
                                            return (
                                                <Link key={`instagram-account-${index}`}
                                                    to={`/o/${orgID}/integration/email/detail/${value.id}`}
                                                    
                                                    className="link-instagram-account-list"
                                                >
                                                    <div className="integration-list-inner-wrapper">
                                                        <FiArrowUpRight />
                                                        <p>
                                                            <b>{value.emailAddress}</b>
                                                        </p>

                                                        <span>{value.stringID}</span>

                                                        <p className={`font-root-medium status-text ${value.channelStatusText === "Error" ? "red-text" : ""}`}>
                                                            {value.channelStatusText}
                                                        </p>

                                                        <p className={`font-root-medium email-button-text ${value.isChannelLinkEnabled ? "font-green" : "font-grey"}`}>
                                                            Channel Button is {!value.isChannelLinkEnabled ? "disabled" : "enabled"}
                                                        </p>
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <div className="integration-list-no-data">
                                    <b>No Channel</b>
                                    <p>
                                        To add a channel, simply click the "+ Add Channel" button on the top right.
                                    </p>
                                </div>
                        }
                    </React.Fragment>
                </div>
            </SectionWrap>
        </div>
    )
}

const mapStateToProps = state => ({
    mediumData: state.mediumData
});

export default connect(mapStateToProps, null)(IntegrationEmail);
