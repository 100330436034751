import React, { useState, useEffect } from "react";
import "./OfficeHourEdit.scss";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import OfficeHourServices from "../../../../services/newServices/OfficeHourService";
import HelperInput from "../../../../helper/HelperInput";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import { FiPlus, FiClock, FiInfo } from "react-icons/fi";
import mixpanel from "mixpanel-browser";

const DAY_OF_WEEK = {
    10: {
        value: 10,
        label: "Sunday"
    },
    11: {
        value: 11,
        label: "Monday"
    },
    12: {
        value: 12,
        label: "Tuesday"
    },
    13: {
        value: 13,
        label: "Wednesday"
    },
    14: {
        value: 14,
        label: "Thursday"
    },
    15: {
        value: 15,
        label: "Friday"
    },
    16: {
        value: 16,
        label: "Saturday"
    },
    90: {
        value: 90,
        label: "Everyday"
    },
    91: {
        value: 91,
        label: "Weekdays (Monday until Friday)"
    },
    92: {
        value: 92,
        label: "Weekends (Saturday and Sunday)"
    }
};

const OfficeHourEdit = (props) => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [timeVal, setTimeVal] = useState({
        start: "08:00",
        end: "17:00"
    });
    let [timeValOrigin, setTimeValOrigin] = useState({
        start: "08:00",
        end: "17:00"
    });
    let [dayOfWekVal, setDayOfWeekVal] = useState(0);
    let [officeHourID, setOfficeHourID] = useState(0);
    let [isWaitResponseSubmit, setIsWaitResponseSubmit] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        start: "",
        end: ""
    })
  
    const back = () => history.push(`/o/${orgID}/setup/office-hour`);

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if(response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              const { id, createdTime } = responseData.memberships[0].organization;
              const urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/setup/office-hour`);
            }else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };
    
    useEffect(() => {
       doMount();
    }, [location.pathname]);

    useEffect(() => {
        if(props.history.location.state) {
            if(props.history.location.state.officeHourData) {
              let officeHour = {...props.history.location.state.officeHourData};
              let data = {...timeVal};
              data.start = officeHour.startTime;
              data.end = officeHour.endTime;

              setOfficeHourID(officeHour.id);
              setDayOfWeekVal(officeHour.dayOfWeek);
              setTimeVal(data);
              setTimeValOrigin(data);
            }else {
              window.location.href = "/";
            }
        }
    }, [props.history])

    let submitOfficeHour = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = {...errorMessageData};

        if(timeVal.start === "") {
            _errorMessageData.start = "This field is required";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-start" }
        }

        if(timeVal.end === "") {
            _errorMessageData.end = "This field is required";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-end" }
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            setIsWaitResponseSubmit(true);

            let data = {
                id: officeHourID,
                startTime: timeVal.start,
                endTime: timeVal.end
            }
            
            OfficeHourServices.editOfficeHour(props.match.params.orgID, data, (response) => {
                setIsWaitResponseSubmit(false)
                let dataResult = response.dataResult;

                if(dataResult.error.message === "") {
                    if(dataResult.data.success) {
                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                        
                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                            mixpanel.track(
                                "[Action] Edit Office Hours", 
                                {
                                    userFullName : myAgentData.account.fullName,
                                    userID : myAgentData.account.id,
                                    organizationID : props.match.params.orgID.split("-")[0]
                                }
                            );
                        }
                        
                        setTimeValOrigin(timeVal);
                        setTimeout(() => {
                            props.history.push({
                                pathname: `/o/${orgID}/setup/office-hour`,
                                state: {
                                    success: true,
                                    message: dataResult.data.message
                                }
                            })
                        }, 0)
                    }else {
                        doToast(dataResult.data.message);
                    }
                }else {
                    doToast(dataResult.error.message, "fail");
                }
            })
        }
    }

    let onChangeTime = (e) => {
        let data = {...timeVal};
        let value = e.target.value;
        
        if(value.length === 2 && !value.includes(":")) {
            value = value + ":";
        }

        if(value.length === 5 && !HelperInput.valid24HourFormat(value)) {
            value = "00:00";
        }
        
        data[e.target.id] = value;        
        setTimeVal(data);
    }

    let onKeyPressChangeTime = (e) => {
        let data = {...timeVal};
        let value = e.target.value;
        
        if(e.keyCode === 8 && (data[e.target.id].includes(":") && data[e.target.id].length === 3)) {
            data[e.target.id] = value[0];    
            setTimeVal(data);
            e.preventDefault();
        }
    }

    // let disabledSubmit = () => {
    //     let data = {...timeVal};

    //     return (data.start.length === 0 || data.end.length === 0) || (!HelperInput.hourMinuteFormat(data.start) || !HelperInput.hourMinuteFormat(data.end));
    // }

    return (
        <React.Fragment>
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={timeValOrigin}
                dataAfterChange={timeVal}
            >   
                <BackButton text="Back to office hour" onClick={back} />

                <div className="sectionWrap office-hour-form-wrapper">
                    <p className="office-hour-form-title">
                        <b>Edit Office Hour</b>
                    </p>

                    <div className="form-content-wrapper">
                        <label><b>Day</b></label>
                        <div className="day-of-week-office-hour">
                            {dayOfWekVal !== 0 && DAY_OF_WEEK[dayOfWekVal].label}
                        </div>
                    </div>

                    <div className="form-content-wrapper">
                        <div className="office-hour-time-form-wrapper">
                            <label><b>Start Time</b></label>
                            <input type="text"
                               placeholder="00:00" 
                               maxLength="5" 
                               id="start" 
                               value={timeVal.start}
                               onKeyDown={(e) => onKeyPressChangeTime(e)}
                               onChange={(e) => onChangeTime(e)}
                               disabled={isWaitResponseSubmit}
                               className={`input-start ${errorMessageData.start !== "" ? "border-red" : ""}`}
                            />
                            <FiClock />

                            {errorMessageData.start !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.start}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }
                        </div>

                        <div className="office-hour-time-form-wrapper">
                            <label><b>End Time</b></label>
                            <input type="text" 
                                placeholder="00:00" 
                                maxLength="5" 
                                id="end" 
                                value={timeVal.end}
                                onKeyDown={(e) => onKeyPressChangeTime(e)}
                                onChange={(e) => onChangeTime(e)}
                                disabled={isWaitResponseSubmit}
                                className={`input-end ${errorMessageData.end !== "" ? "border-red" : ""}`}
                            />
                            <FiClock />

                            {errorMessageData.end !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.end}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }
                        </div>
                    </div>

                    {!isWaitResponseSubmit ?
                        <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-40 save-change-office-hour"
                            text="Edit Office Hour"
                            onClickAction={submitOfficeHour} 
                            // isDisabled={disabledSubmit()}
                            icon={{
                                type: "svg",
                                src: FiPlus
                            }}
                        />
                        :
                        <ButtonWithLoadingOrIcon 
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            className="orange-button main-button-40 save-change-office-hour"
                            text="Edit Office Hour"
                            position="left"
                        />
                    }       
                </div>
            </SectionWrap>
        </React.Fragment>
    )
}

export default OfficeHourEdit;