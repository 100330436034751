import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const ChatbotServices = {
  getChatbot(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATBOT, {}, headers, callback);  
  },

  detailChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DETAIL_CHATBOT, data, headers, callback);  
  },

  addChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_CHATBOT, data, headers, callback);  
  },

  addChatbotCustom(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_CHATBOT_CUSTOM, data, headers, callback);  
  },

  editChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_CHATBOT, data, headers, callback);  
  },

  editChatbotCustom(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_CHATBOT_CUSTOM, data, headers, callback);  
  },

  deleteChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_CHATBOT, data, headers, callback);  
  },

  getAutoChatbot(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_AUTO_ASSIGN, {}, headers, callback);  
  },

  setAutoChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_AUTO_ASSIGN, data, headers, callback);  
  },
};

const API = {
  GET_CHATBOT: "/organization/chatbot/get_list",
  DETAIL_CHATBOT: "/organization/chatbot/get_details",
  ADD_CHATBOT: "/organization/chatbot/create/scf",
  ADD_CHATBOT_CUSTOM: "/organization/chatbot/create/custom",
  EDIT_CHATBOT: "/organization/chatbot/edit_info",
  EDIT_CHATBOT_CUSTOM: "/organization/chatbot/edit_configs/custom",
  DELETE_CHATBOT: "/organization/chatbot/delete",
  GET_AUTO_ASSIGN: "/organization/setup/chatbot_idle_case_config/get",
  SET_AUTO_ASSIGN: "/organization/setup/chatbot_idle_case_config/set"
}

export default ChatbotServices;
