import React, { useEffect } from 'react';
import './ChatRoomCaseHistoryModalHeader.scss';
import Helper from '../../../../helper/HelperChat';
import { TAPLIVE_MEDIUM_LOGO } from "../../../../constants/taplive";
import { taptalk } from '@taptalk.io/web-sdk';
import { FiInfo, FiXCircle, FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";

var ChatRoomCaseHistoryModalHeader = (props) => {
  useEffect(() => {

  }, [])

  let generateIconOmnichannel = (caseDetail) => {
    return TAPLIVE_MEDIUM_LOGO[caseDetail];
  }
  
  return (
    props.caseHistoryPopupDataProps !== null &&
      <div className={`chat-room-case-history-modal-header-wrapper chat-room-header-container ${props.isUserDetailShowProps ? 'modal-chat-room-header-show-detail' : ''}`}>
          <div className="chat-room-case-history-close" onClick={() => props.toggleCaseModalHistoryProps()}>
            <FiX />
          </div>
          
          <div className="chat-room-header-avatar-wrapper">
              {props.caseHistoryPopupDataProps.user.photoURL === "" ?
                <div 
                  className="user-avatar-name" 
                  style={{background: taptalk.getRandomColor(props.caseHistoryPopupDataProps.user.fullName)}}
                >
                    <b>{Helper.renderUserAvatarWord(props.caseHistoryPopupDataProps.user.fullName)}</b>
                </div>
              :
                <img 
                  className="user-avatar-name" 
                  src={props.caseHistoryPopupDataProps.user.photoURL} 
                  alt=""
                  onError={(e) => {e.target.onerror = null; e.target.src = iconReplaceAvatar;}}
                />
              }

              <img className="chat-rooom-omnichannel-header" 
                  src={generateIconOmnichannel(props.caseHistoryPopupDataProps.case.medium)} 
                  alt=""
              />
          </div>

          <div className="chat-room-identifier">
              <p>
                <b>
                  {props.caseHistoryPopupDataProps.user.alias ?
                    props.caseHistoryPopupDataProps.user.alias !== "" ? `${props.caseHistoryPopupDataProps.user.alias} (${props.caseHistoryPopupDataProps.user.fullName})`: props.caseHistoryPopupDataProps.user.fullName
                    :
                    props.caseHistoryPopupDataProps.user.fullName
                  }
                </b>
              </p>
              <div className="connectivity-status">
                {props.caseHistoryPopupDataProps.case.topicName} (#{props.caseHistoryPopupDataProps.case.stringID})
              </div>
          </div>

          <div className="open-detail-button" onClick={() => props.toggleIsUserDetailShowActionProps()}>
              {props.isUserDetailShowProps ?
                <React.Fragment>
                  <FiXCircle />
                  <b>Hide Details</b>
                </React.Fragment>
                :
                <React.Fragment>
                  <FiInfo />
                  <b>Details</b>
                </React.Fragment>
              }
          </div>
      </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomCaseHistoryModalHeader);
