import React, { Component } from "react";
import "../Auth.css";
import "./Login.css";
import Logo from "../../../assets/img/logo-taptalk-white.svg";
import PaperPlaneLeft from "../../../assets/img/auth/img-paper-plane-left.svg";
import PaperPlaneRight from "../../../assets/img/auth/img-paper-plane-right.svg";
import IconFacebookMessenger from "../../../assets/img/auth/icon-fb-messenger-circle.svg";
import IconGoogle from "../../../assets/img/auth/icon-google-circle.svg";
import IconGoogleBusinessProfile from "../../../assets/img/auth/icon-gb-profile-circle.svg";
import IconInstagram from "../../../assets/img/auth/icon-instagram-circle.svg";
import IconMail from "../../../assets/img/auth/icon-mail-circle.svg";
import IconTelegram from "../../../assets/img/auth/icon-telegram-circle.svg";
import IconTokopedia from "../../../assets/img/auth/icon-tokopedia-circle.svg";
import IconWhatsAppSME from "../../../assets/img/auth/icon-whatsapp-circle.svg";
import IconWhatsAppBA from "../../../assets/img/auth/icon-whatsapp-ba-circle.svg";
import { Link } from "react-router-dom";
import ServiceAuthAccessToken from "../../../services/auth/ServiceAuthAccessToken";
import ServiceAuthRegister from '../../../services/auth/ServiceAuthRegister';
import CustomToastr from "../../reuseableComponent/customToastr/CustomToastr";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { setAuthActive } from "../../../redux/actions/reduxActionAuth";
import { connect } from "react-redux";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGTag from "../../../helper/HelperGTag";
import HelperQueryString from "../../../helper/HelperQueryString";
import HelperInput from "../../../helper/HelperInput";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { TiWarningOutline } from "react-icons/ti";
import IconCheck from "../../../assets/img/icon-check.png";
import { FiAlertCircle, FiEye, FiEyeOff } from "react-icons/fi";

const style = {
  grayLabelButton: {
    color: "#19191999",
    cursor: "pointer",
    fontSize: "16px",
    marginLeft: "4px"
  },
  orangeLabelButton: {
    color: "#ff7e00",
    cursor: "pointer",
    fontSize: "16px",
    marginLeft: "4px"
  },
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      toastrText: "",
      toastrClassName: "",
      isWaitForResponse: false,
      isWaitForResponseEmail: false,
      modalResendEmail: false,
      modalEmailSent: false,
      canCreateOrganization: false,
      emailErrorInfoText: "",
      passwordErrorInfoText: "",
      peekPassword: false
    };
  }

  componentDidMount() {
    HelperGTag.checkIfUrlContainAnalyticParam({
        setAnalyticQueryString: (queryString) => {
            this.setState({
                analyticQueryString: `?${queryString}`
            })
        },
        ifAnalyticExistInLocalStorage: () => {
            this.setState({
                analyticQueryString: `?${HelperGTag.generateQueryStringAnalytic()}`
            })
        }
    });

    let checkContainQsEmail = () => {
      let qs = HelperQueryString.parseQuerystring();

      if(qs["email"] && HelperInput.emailFormatValid(qs["email"])) {
        this.setState({
          email: qs["email"]
        })
      }
    }

    checkContainQsEmail();
  }

  render() {
    return (
      <div id="outer-container">
        <CustomToastr toastrText={this.state.toastrText} toastrClassName={this.state.toastrClassName} />
        
        <img alt="" src={PaperPlaneLeft} className="bg-img-paper-plane-left" />
        <img alt="" src={PaperPlaneRight} className="bg-img-paper-plane-right" />
        <img alt="" src={IconWhatsAppSME} className="bg-img-whatsapp-sme" />
        <img alt="" src={IconTelegram} className="bg-img-telegram" />
        <img alt="" src={IconGoogleBusinessProfile} className="bg-img-gb-profile" />
        <img alt="" src={IconGoogle} className="bg-img-google" />
        <img alt="" src={IconInstagram} className="bg-img-instagram" />
        <img alt="" src={IconFacebookMessenger} className="bg-img-fb-messenger" />
        <img alt="" src={IconMail} className="bg-img-mail" />
        <img alt="" src={IconTokopedia} className="bg-img-tokopedia" />
        <img alt="" src={IconWhatsAppBA} className="bg-img-whatsapp-ba" />

        <div className="wrapper-auth-v2">
          <img src={Logo} alt="TapTalk Logo" className="logo-image" />
          <p className="subtitle">Omnichannel Chat Platform</p>
          <div className="wrapper-form-center">
            <div className="content-wrapper">
              <div className="header-centered-content">
                <div className="content-title">
                    <b>Welcome Back!</b>
                </div>
              </div>

              <form onSubmit={this.loginClick}>
                <div className={`input-wrapper${this.state.isWaitForResponse ? `-loading`: ``}`}>
                  <label><b>Email</b></label>
                  <input 
                    type="text"
                    id="email"
                    onChange={this.handleEmailChange}
                    placeholder="your@email.com"
                    autoComplete="false"
                    tabIndex="1"
                    className={this.state.emailErrorInfoText ? 'fail-state-input' : ''}
                    value={this.state.email}
                    disabled={this.state.isWaitForResponse}
                  />
                  {
                    this.state.emailErrorInfoText &&
                    <div className="input-error-info">
                      <FiAlertCircle />
                      <b>{this.state.emailErrorInfoText}</b> 
                    </div>
                  }
                </div>

                <div className={`input-wrapper${this.state.isWaitForResponse ? `-loading`: ``}`}>
                  <div className="password-title-wrapper">
                    <label><b>Password</b></label>
                  </div>
                  <input 
                    type={this.state.peekPassword ? "text" : "password"}
                    id="password"
                    onChange={this.handlePasswordChange}
                    placeholder="Type your password"
                    autoComplete="false"
                    tabIndex="2"
                    className={this.state.passwordErrorInfoText ? 'fail-state-input' : ''}
                    disabled={this.state.isWaitForResponse}
                  />
                  <div
                    className={`peek-password ${this.state.peekPassword ? 'active-eye' : ''}`}
                    onClick={this.peekPasswordClick}
                  >
                    {
                      this.state.peekPassword ?
                      <FiEye />
                      :
                      <FiEyeOff />
                    }
                  </div>
                  {
                    this.state.passwordErrorInfoText &&
                    <div className="input-error-info">
                      <FiAlertCircle />
                      <b>{this.state.passwordErrorInfoText}</b> 
                    </div>
                  }
                </div>

                {
                  !this.state.isWaitForResponse ?
                  <ButtonWithLoadingOrIcon
                    gtmevent={JSON.stringify(Object.assign({...HelperGTag.basicParameter("login")},
                      {
                        event: "action-login-button-click",
                        source: "login"
                      }
                    ))}
                    className="orange-button main-button-48 width-100"
                    text="Log In"
                  />
                  :
                  <ButtonWithLoadingOrIcon 
                    isLoading
                    loadingGray
                    className="gray-button-disabled main-button-48 width-100"
                    text="Log In"
                    position="left"
                    disabled={true}
                  />
                }
              </form>

              <div className={`forgot-password${this.state.isWaitForResponse ? `-loading`: ``}`}>
                <Link to={`/forgot-password${window.location.search}`}><b>Forgot Password?</b></Link>
              </div>

              <div id="signup-here">
                <b>
                  Don't have an account? 
                  <Link
                    to={`/register${window.location.search}`}
                    style={this.state.isWaitForResponse ? style.grayLabelButton : style.orangeLabelButton}
                    onClick={(e) => {
                      if (this.state.isWaitForResponse) {
                        e.preventDefault();
                      }
                    }
                  }
                    gtmevent={JSON.stringify(Object.assign({...HelperGTag.basicParameter("login")},
                      {
                        event: "button-click-register",
                        source: "login"
                      }
                    ))}
                  >
                    Register
                  </Link>
                </b>
              </div>
            </div>
          </div>
        </div>

        {this.generateModalResendEmail()}
        {this.generateModalEmailSent()}
      </div>
    );
  }

  handleEmailChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.checkAndShowEmailError);
  }

  handlePasswordChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.checkAndShowPasswordError);
  }

  peekPasswordClick = () => {
    this.setState({
      peekPassword: !this.state.peekPassword
    });
  }

  getEmailFormErrorMessage() {
    if (!this.state.email || this.state.email.length <= 0) {
      return "This field is required";
    }
    else if (!this.state.email.trim().toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return "Invalid email address. Please try another email";
    }
    return "";
  }

  getPasswordFormErrorMessage() {
    if (!this.state.password || this.state.password.length <= 0) {
      return "This field is required";
    }
    else if (this.state.password.length < 8) {
      return "Incorrect password, please try again.";
    }
  }

  validateForm() {
    return !this.getEmailFormErrorMessage() && !this.getPasswordFormErrorMessage();
  }

  checkAndShowEmailError() {
    if (this.state.emailErrorInfoText) {
      this.setState({
        emailErrorInfoText: this.getEmailFormErrorMessage()
      });
    }
  }

  checkAndShowPasswordError() {
    if (this.state.passwordErrorInfoText) {
      this.setState({
        passwordErrorInfoText: this.getPasswordFormErrorMessage()
      });
    }
  }

  showFormError(emailError, passwordError) {
    this.setState({
      emailErrorInfoText: emailError ? emailError : this.getEmailFormErrorMessage(),
      passwordErrorInfoText: passwordError ? passwordError : this.getPasswordFormErrorMessage()
    });
  }

  loginClick = (e) => {
    e.preventDefault();

    if (this.getEmailFormErrorMessage() || this.getPasswordFormErrorMessage()) {
      this.showFormError();
      return;
    }

    this.setState({
      isWaitForResponse: true,
      emailErrorInfoText: "",
      passwordErrorInfoText: ""
    });

    let param = `${this.state.email.trim()}:${this.state.password}`;

    ServiceAuthAccessToken.postRequestAccessToken(param).then(response => {
      if (response.dataResult.status !== 200) {
        this.setState({
          isWaitForResponse: false
        });

        if (response.dataResult.error.code === "40107") {
          this.setState({
            modalResendEmail: true
          })
        }
        else {
          let emailError = "";
          let passwordError = "";
          if (response.dataResult.error.code === "40103") {
            passwordError = "The email and password does not match";
          }
          else if (response.dataResult.error.code === "40106") {
            emailError = "Account is not found";
          }
          else {
            emailError = response.dataResult.error.message;
            passwordError = response.dataResult.error.message;
          }
          this.showFormError(emailError, passwordError);
          // this.setState({
          //   failStateActive: true,
          //   failStateMessage: response.dataResult.error.message,
          //   emailErrorInfoText: response.dataResult.error.message,
          //   passwordErrorInfoText: response.dataResult.error.message
          // })
          HelperGTag.dataLayerPush(
            Object.assign({...HelperGTag.basicParameter("login")} ,{
              event: "action-login-failed",
              error_message: response.dataResult.error.message
            })
          )
        }
      }else {
        let _dataResult = {...response.dataResult.data};
        // _dataResult.activeOrganization = null;
        HelperGTag.dataLayerPush(
          Object.assign({...HelperGTag.basicParameter("login")} ,{
            event: "action-login-success",
          })
        )

        let runSetAuthActive = () => {
          this.props.setAuthActive({
            data: HelperCustomEncryptor.doEncrypt(_dataResult)
          });
        }

        if(response.dataResult.data.memberships.length === 0) {
          runSetAuthActive();
          
          setTimeout(() => {
            this.setState({
              isWaitForResponse: true,
            });
          }, 2400);
          
          setTimeout(function() {

            window.location.href = window.location.origin + "/organization/new";
          }, 2500);
        }else {
          // let organizationData = response.dataResult.data.memberships;

          // _dataResult.activeOrganization = organizationData[0].organization;

          localStorage.removeItem("OneTalk.tabKey");
          runSetAuthActive();
          window.location.href = window.location.origin + `/home`;
        }
      }
    })
  }

  toggleModalResendEmail = () => {
    this.setState(prevState => ({
      modalResendEmail: !prevState.modalResendEmail
    }))
  }

  generateModalResendEmail() {
    return (
      <Modal isOpen={this.state.modalResendEmail} toggle={this.toggleModalResendEmail} className="modal-resend-email">
        <ModalBody>
          <p>
            <TiWarningOutline /> Account Not Verified
          </p>

          <p>
            The email address you have entered <b>has not been verified</b>.
            If you didn’t receive any verification email, please click
            the button below to resend the verification email.
          </p>
        </ModalBody>
        <ModalFooter>
          {this.state.isWaitForResponseEmail ?
            <ButtonWithLoadingOrIcon 
              isLoading
              loadingWhite
              className="orange-button main-button-48 width-100"
              text="Resend Verification Email"
              position="left"
            />
            :
            <ButtonWithLoadingOrIcon
              className="orange-button main-button-48 width-100"
              text="Resend Verification Email"
              // Resend Verification Email={!this.validateForm()}
              onClickAction={this.onClickSendVerificationEmail}
            />
          }
        </ModalFooter>
      </Modal>
    )
  }

  onClickSendVerificationEmail = () => {
    this.setState({
      isWaitForResponseEmail: true
    })

    ServiceAuthRegister.postResendEmailVerification({email: this.state.email}).then(response => {
      this.setState({
        modalResendEmail: false,
        isWaitForResponseEmail: false
      });

      if(response.dataResult.data.success) {
        this.setState({
          modalEmailSent: true
        })
      }else {
        this.setState({
            toastrText: response.dataResult.error.message,
            toastrClassName: "toast-fail active-toast"
        })

        setTimeout(() => {
            this.setState({
                toastrClassName: "toast-fail"
            })
        }, 4000);
      }
    })
  }

  toggleModalEmailSent = () => {
    this.setState(prevState => ({
      modalEmailSent: !prevState.modalEmailSent
    }))
  }

  generateModalEmailSent() {
    return (
      <Modal isOpen={this.state.modalEmailSent} toggle={this.toggleModalEmailSent} className="modal-resend-email modal-email-sent">
        <ModalBody>
          <p>
            <img alt="" src={IconCheck} /> Verification Email Sent
          </p>

          <p>
            Please check your inbox with the registered email address and <b>verify your account</b>.
          </p>
        </ModalBody>
        <ModalFooter>
          <ButtonWithLoadingOrIcon
            className="orange-button main-button-48 width-100"
            text="Got It Thanks!"
            // isDisabled={!this.validateForm()}
            onClickAction={this.toggleModalEmailSent}
          />
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  setAuthActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
