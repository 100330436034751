import React from "react";
import "./DisabledEnabled.scss";
import statusConnected from '../../../assets/img/integration/status-connected.svg';
import statusDisconnect from '../../../assets/img/integration/icon-disconnect.svg';

const DisabledEnabled = props => {
  return (
    <div className="disabled-enabled-wrapper">
        {props.status === "enabled" &&
            <p className="enabled-text">
                <img src={statusConnected} alt="" />
                <b>Enabled</b>
            </p>
        }

        {props.status === "disabled" &&
            <p className="disabled-text">
                <img src={statusDisconnect} alt="" />
                <b>Disabled</b>
            </p>
        }
    </div>
  );
};

export default DisabledEnabled;
