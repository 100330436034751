import React, { useState, useEffect } from "react";
import "./BusinessProfile.scss";
import { NavLink } from "react-router-dom";
import { FiAlignLeft, FiEdit, FiHome, FiAtSign, FiGlobe } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import iconIndustry from '../../../../../assets/img/integration/whatsapp-business/icon-industry.svg'
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import { checkID } from "../../../../../helper/HelperGeneral";
import HelperInput from "../../../../../helper/HelperInput";
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";

let BusinessProfile = (props) => {
    let { match, history } = props
    let [orgID, setOrgID] = useState(false);
    let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true)
    let [accountDetail, setAccountDetail] = useState({})
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [phoneVal, setPhoneVal] = useState({
        value: "",
        label: ""  
    });
    let [phoneNumberList, setPhoneNumberList] = useState(false);

    let doMount = () => {
        let paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    let { id, createdTime } = responseData.memberships[0].organization;
                    let urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount()
    }, [])

    useEffect(() => {
        if (orgID) {
            getPhoneNumber();
        }
    }, [orgID]);

    useEffect(() => {
        if(phoneNumberList) {
            getAccountDetail(phoneNumberList[0].value);
        }
    }, [phoneNumberList])

    let getPhoneNumber = () => {
        setIsLoadingGetDetailData(true);
        let _retriveDataError = {...retriveDataError};

        IntegrationWhatsappBusinessServices.getPhoneNumber(orgID, {channelID: Number(match.params.id)}, (res) => {
            let _phone = [];
            
            if(res.dataResult.error.code !== "") {
                _retriveDataError.message = res.dataResult.error.message;
                _retriveDataError.code = res.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setIsLoadingGetDetailData(false);
            }else {
                if(res.dataResult.data.items === null) {
                    _retriveDataError.message = "Phone number not found";
                    _retriveDataError.code = 200;
                    setRetrieveDataError(_retriveDataError);
                    setIsLoadingGetDetailData(false);
                }else {
                    res.dataResult.data.items.map(v => {
                        v.label = v.phoneNumber.split("")[0] === "+" ? "+"+HelperInput.phoneBeautify(v.phoneNumber) : "+"+HelperInput.phoneBeautify(v.phoneNumber);
                        v.value = v.id;
                        _phone.push(v);
    
                        return null;
                    })
    
                    setPhoneVal(_phone[0]);
                    setPhoneNumberList(_phone);
                }
            }
        });
    }

    let getAccountDetail = (phone) => {
        setIsLoadingGetDetailData(true);

        let data = {
            id: Number(match.params.id),
            phoneNumberID: phone
        }

        IntegrationWhatsappBusinessServices.getDetail(orgID, data, (response) => {
            let _retriveDataError = { ...retriveDataError };

            if (response.dataResult.error.message === "") {
                setAccountDetail(response.dataResult.data.settings.businessProfile);
            } else {
                _retriveDataError.message = response.dataResult.error.message;
                _retriveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsLoadingGetDetailData(false);
        });
    };

    let onSelectPhone = (val) => {
        getAccountDetail(val.value);
        setPhoneVal(val);
    }

    return (
        <div className="business-profile-whatsapp sectionWrap">
            {isLoadingGetDetailData || retriveDataError.code ?
                <div className="integration-detail-loading-wrapper">
                    <RetrieveDataLoading
                        isLoading={isLoadingGetDetailData}
                        errorMessage={retriveDataError.message}
                    />
                </div>
                :
                <>
                    <div className="business-profile-header">
                        <div className="title-wrapper">
                            <b>Business Profile</b>
                            <p>This is displayed on your WhatsApp business details.</p>
                        </div>
                        <div className="button-wrapper">
                            <NavLink to={`/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}/${phoneVal.value}/edit-business-profile`}>
                                <ButtonWithLoadingOrIcon
                                    className="no-fill-button  main-button-40"
                                    text="Edit"
                                    position="left"
                                    icon={{
                                        type: "svg",
                                        src: FiEdit
                                    }}
                                />
                            </NavLink>
                        </div>
                    </div>
                    <div className="business-profile-content">
                        <div className="phone-number-wrapper">
                            <label>
                                <b>Phone Number</b>
                            </label>

                            <CustomSelectOption
                                isDisabled={false}
                                optionListProps={phoneNumberList}
                                valueDropdownProps={phoneVal}
                                placeholderProps={"Select Phone Number"}
                                onClickDropDownListOptionProps={onSelectPhone}
                                specificClassNameProps="custom-select-phonenumber"
                            />
                        </div>

                        <div className="business-profile-content-wrapper">
                            <div className="label-wrapper">
                                <FiAlignLeft />
                                <label><b>Description</b></label>
                            </div>
                            <div className="text-wrapper">
                                <p>{accountDetail.description === "" ? "-" : accountDetail.description}</p>
                            </div>
                        </div>
                        <div className="business-profile-content-wrapper">
                            <div className="label-wrapper">
                                <FiHome />
                                <label><b>Address</b></label>
                            </div>
                            <div className="text-wrapper">
                                <p>{accountDetail.address === "" ? "-" : accountDetail.address}</p>
                            </div>
                        </div>
                        <div className="business-profile-content-wrapper">
                            <div className="label-wrapper">
                                <img src={iconIndustry} alt="" />
                                <label><b>Industry</b></label>
                            </div>
                            <div className="text-wrapper">
                                <p>{accountDetail.vertical === "" ? "-" : accountDetail.vertical}</p>
                            </div>
                        </div>
                        <div className="business-profile-content-wrapper">
                            <div className="label-wrapper">
                                <FiAtSign />
                                <label><b>Email</b></label>
                            </div>
                            <div className="text-wrapper">
                                <p>{accountDetail.email === "" ? "-" : accountDetail.email}</p>
                            </div>
                        </div>

                        {(
                            !accountDetail.websites ||
                            accountDetail.websites.length === 0
                        ) &&
                            <div className="business-profile-content-wrapper">
                                <div className="label-wrapper">
                                    <FiGlobe />
                                    <label><b>Website</b></label>
                                </div>
                                {
                                    accountDetail.websites &&
                                    accountDetail.websites.map((item, idx) => {
                                        return (
                                            <div className="text-wrapper web-link" key={`website-${idx}`}>
                                                <p>{item}</p>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default BusinessProfile;