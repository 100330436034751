const INITIAL_STATE = false;

let reduxReducerBillingWarning = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_BILLING_WARNING':
        return action.billingWarning;
      case 'CLEAR_BILLING_WARNING':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerBillingWarning;