const INITIAL_STATE = {
    show: false
};

let reduxReducerGlobalSearch = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_GLOBAL_SEARCH':
        return action.globalSearch;
      case 'CLEAR_GLOBAL_SEARCH':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerGlobalSearch;