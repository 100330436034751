import React, { useState, useEffect } from "react";
import "./FormInteractiveMessageList.scss";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { FiX, FiInfo, FiPlus, FiTrash2, FiList } from "react-icons/fi";
import IconAttachMessage from '../../../../../../assets/img/icon-attach-message-black.svg';
import { doToast, scrollToClass } from "../../../../../../helper/HelperGeneral";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupDiscard from "../../../../../reuseableComponent/popupDiscard/PopupDiscard";
import ErrorOrInfoComp from "../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import FormInteractiveMessagePreview from "../FormInteractiveMessagePreview/FormInteractiveMessagePreview";
import FormInteractiveMessageManageRow from "./FormInteractiveMessageManageRow/FormInteractiveMessageManageRow";
import PopupRemove from "../../../../../reuseableComponent/popupRemove/PopupRemove";

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-2px",
    width: "5px",
    borderRadius: "8px",
  },
};

const DEFAULT_ROW = {
  id: "",
  title: "",
  description: ""
}

const DEFAULT_SECTION = {
  title: "",
  manageRow: "",
  rows: [{...DEFAULT_ROW}]
}

let FormInteractiveMessageList = (props) => {
  let { isShowModalFormInteractive, toggle, type, parentProps, caseDetailData } = props;
  
  let [isShowModalFormInteractivePreview, setIsShowModalFormInteractivePreview] = useState(false);

  let [data, setData] = useState({
    headerText: "",
    document: {
      
    },
    body: "",
    footer: "",
    buttonText: "",
    section: [{
      title: "",
      manageRow: "",
      rows: []
    }]
  });
  let [dataOrigin, setDataOrigin] = useState({
    headerText: "",
    document: {
      
    },
    body: "",
    footer: "",
    buttonText: "",
    section: [{
      title: "",
      manageRow: "",
      rows: []
    }]
  });
  let [errorMessageData, setErrorMessageData] = useState({
    headerText: "",
    document: "",
    body: "",
    footer: "",
    buttonText: "",
    section: [{
      title: "",
      manageRow: "",
      rows: []
    }]
  })
  let [activeManageRow, setActiveManageRow] = useState(false);
  let [tempRemoveSectionIndex, setTempRemoveSectionIndex] = useState(false);
  let [showPopupRemoveSection, setShowPopupRemoveSection] = useState(false);
  let [lastClickSaveChangeRow, setLastClickSaveChangeRow] = useState(false);
  let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);
  
  useEffect(() => {
    setDataOrigin({
      headerText: "",
      document: {
        
      },
      body: "",
      footer: "",
      buttonText: "",
      section: [{
        title: "",
        manageRow: "",
        rows: []
      }]
    });

    if(props.activeQuickReplyInteractive) {
      if(props.activeQuickReplyInteractive.type === "list") {

        let _whatsapp = JSON.parse(JSON.stringify(props.activeQuickReplyInteractive));
        let _errorRow = [];
  
        _whatsapp.listAction.sections.map((v, i) => {
          _errorRow.push({
            title: "",
            manageRow: "",
            rows: []
          });
  
          v.rows.map((_v, _i) => {
            _errorRow[i].rows.push({
              title: "",
              description: ""
            })

            return null;
          })
  
          return null;
        })
  
        let _quickReplyInteractive = {
          headerText: _whatsapp.header ? _whatsapp.header.text : "",
          body: _whatsapp.bodyText,
          footer: _whatsapp.footerText ? _whatsapp.footerText : "",
          buttonText: _whatsapp.listAction.buttonText,
          section: _whatsapp.listAction.sections,
        };
  
        setData(_quickReplyInteractive);
  
        setErrorMessageData({
          headerText: "",
          body: "",
          footer: "",
          buttonText: "",
          section: _errorRow,
        })
      }
    }
  }, [props.activeQuickReplyInteractive])
 
  let toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard);
  }

  let toggleRemoveSection = (idx = false) => {
    if(idx !== false) {
      setTempRemoveSectionIndex(idx);
    }else {
      setTempRemoveSectionIndex(false);
    }

    setShowPopupRemoveSection(!showPopupRemoveSection);
  }

  // let closeModalPreview = () => {
  //   setIsShowModalFormInteractivePreview(false);
  // }

  let toggleModalPreview = (isClose = true) => {
    if(isClose) {
      onCloseClick(type, true);
    }else {
      onCloseClick("preview", true);
    }

    setIsShowModalFormInteractivePreview(!isShowModalFormInteractivePreview);
  }
  
  let onCloseClick = (type = "", ignoreDiscard = false) => {
    if(JSON.stringify(data) !== JSON.stringify(dataOrigin)) {
      if(ignoreDiscard === false) {
        toggleModalDiscard();
      }else {
        toggle(type === "" ? "" : type)
      }
    }else {
      if(!ignoreDiscard) {
        setErrorMessageData({
          headerText: "",
          document: "",
          body: "",
          footer: "",
          buttonText: "",
          section: [{
            title: "",
            manageRow: "",
            rows: []
          }]
        });
      }
      
      toggle(type === "" ? "" : type);
    }
  };

  let onContinue = () => {
    let errorCount = "";
    let scrollTo = "";
    let _errorMessageData = {...errorMessageData};

    if(data.headerText.length > 60) {
      _errorMessageData.headerText = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-header"; }
    }

    if(data.body.length > 1024) {
      _errorMessageData.body = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-body"; }
    }

    if(data.body === "") {
      _errorMessageData.body = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-body"; }
    }

    if(data.footer.length > 60) {
      _errorMessageData.footer = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-footer"; }
    }

    if(data.buttonText.length > 20) {
      _errorMessageData.buttonText = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-button-text"; }
    }

    if(data.buttonText === "") {
      _errorMessageData.buttonText = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-button-text"; }
    }

    data.section.map((v, i) => {
      if(v.title.length > 24) {
        _errorMessageData.section[i].title= "Characters exceed limit";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-section-${i}`; }
      }
      
      if(v.title === "" && data.section.length > 1) {
        _errorMessageData.section[i].title= "This field is required";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-section-${i}`; }
      }
      
      v.rows.map((_v, _i) => {
        if(_v.title === "") {
          _errorMessageData.section[i].manageRow = "Row title is required";
        }

        return null;
      })

      if(v.rows.length === 0) {
        _errorMessageData.section[i].manageRow= "Each section requires at least 1 row";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-section-${i}`; }
      }

      return null;
    })

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${scrollTo}`);
    }else {
      toggleModalPreview(false);
    }
  };

  let onChangeText = (id, val) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _data[id] = val;
    _errorMessageData[id] = "";

    if(id === "headerText" && val.length > 20) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "body" && val.length > 1024) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "footer" && val.length > 60) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "buttonText" && val.length > 20) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let onChangeSectionTitle = (val, index) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _data.section[index].title = val;
    _errorMessageData.section[index].title = "";
    setData(_data);
    setErrorMessageData(_errorMessageData);
  }
  
  let countRows = () => {
    let rowsCount = 0;

    data.section.map((v) => {
      rowsCount = rowsCount + v.rows.length;

      return null;
    })

    return rowsCount;
  }

  let addSection = () => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    
    if(countRows() < 10) {
      if(_data.section.length === 1 && _data.section[0].rows.length === 0) {
        let _tempTitle = _data.section[0].title;
        _data.section[0] = {...DEFAULT_SECTION};
        _errorMessageData.section[0] = {...DEFAULT_SECTION};
        _data.section[0].title = _tempTitle;
      }

      _data.section.push({...DEFAULT_SECTION});
      _errorMessageData.section.push({...DEFAULT_SECTION});  
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let removeSection = () => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _data.section.splice(tempRemoveSectionIndex, 1);
    _errorMessageData.section.splice(tempRemoveSectionIndex, 1);

    if(_data.section.length === 1) {
      _errorMessageData.section[0].title = "";
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
    toggleRemoveSection();
    doToast("Section deleted successfully");
  }

  let runSaveChangesRow = (dataVal, errorVal, idx) => {
    let _data = JSON.parse(JSON.stringify(data));
    let _errorMessageData = JSON.parse(JSON.stringify(errorMessageData));

    _data.section[idx] = dataVal;
    _errorMessageData.section[idx] = errorVal;
    _errorMessageData.section[idx].manageRow = "";

    setData(_data);
    setErrorMessageData(_errorMessageData);
    setActiveManageRow(false);
  }

  return (
    <>
      <PopupRemove 
        removeTitle={`Delete Section?`}
        removeDescription={`All rows in this section will be deleted as well when you delete a section and this action cannot be undone, are you sure you want to continue?`}
        onClickCancel={toggleRemoveSection}
        onClickRemove={removeSection}
        withoutBorder
        loadingButtonGray
        waitForResponse={false}
        showModalProps={showPopupRemoveSection}
        submitText={'Delete'}
        colorButton={"red-button"}
      />
      
      <div
        className={`modal-new-interactiveList-message ${
          isShowModalFormInteractive
            ? "active-modal-new-interactiveList-message"
            : ""
        } `}
      >
        <div
          className={`modal-body ${
            isShowModalFormInteractive
              ? "action-modal-body-new-interactiveList-message"
              : ""
          }`}
        >
          <div
            onClick={() => {
              if(activeManageRow !== false) {
                setIsShowModalDiscard(true);
              }else {
                onCloseClick();
              }
              // setData({
              //   headerText: "",
              //   document: {
                  
              //   },
              //   body: "",
              //   footer: "",
              //   buttonText: "",
              //   section: [{
              //     title: "",
              //     rows: [],
              //   }]
              // });
              // setErrorMessageData({
              //   headerText: "",
              //   document: "",
              //   body: "",
              //   footer: "",
              //   buttonText: "",
              //   section: [{
              //     title: "",
              //     manageRow: "",
              //     rows: []
              //   }]
              // })
            }} 
            className="close-modal-interactiveList"
          >
            <FiX />
          </div>
          
          <div className={`new-interactiveList-message-wrapper ${activeManageRow !== false ? "new-interactiveList-message-wrapper-deactive" : ""}`}>
            <Scrollbars
              autoHideTimeout={500}
              renderThumbVertical={(props) => (
                <div {...props} style={style.scrollStyle} />
              )}
            >
              <div className={`new-interactiveList-message-container`}>
                <div className="new-interactiveList-message-title">
                  <img src={IconAttachMessage} alt="" />

                  <b>Interactive Message</b>
                  <p>
                    Create an interactive message to send
                  </p>
                </div>

                <div
                  className={"form-content-wrapper form-label"}
                >
                  <label>
                    <b>Type</b>
                  </label>

                  <p className="form-value">List</p>

                  <label className="input-interactive-header">
                    <b>Header </b>
                    <span className="text-optional grey-font">
                      (optional)
                    </span>
                    <span className={`grey-font  ${errorMessageData.headerText === "Characters exceed limit" ? "red-text" : ""}`}>{data.headerText.length}/60 characters</span>
                  </label>
                  <input type="text" value={data.headerText} onChange={(e) => onChangeText("headerText", e.target.value)} placeholder="Type your header here" className={errorMessageData.headerText !== "" ? "border-red" : ""} />
                  {errorMessageData.headerText !== "" &&
                    <ErrorOrInfoComp 
                      text={errorMessageData.headerText}
                      icon={<FiInfo />}
                      _className="red-text"
                    />
                  }

                  <label className="input-interactive-body">
                    <b>Body</b>
                    <span className={`grey-font ${errorMessageData.body === "Characters exceed limit" ? "red-text" : ""}`}>{data.body.length}/1024 characters</span>
                  </label>
                  <textarea value={data.body} onChange={(e) => onChangeText("body", e.target.value)} placeholder="Type message here" className={errorMessageData.body !== "" ? "border-red" : ""} />
                  {errorMessageData.body !== "" &&
                    <ErrorOrInfoComp 
                      text={errorMessageData.body}
                      icon={<FiInfo />}
                      _className="red-text"
                    />
                  }

                  <label className="input-interactive-footer">
                    <b>Footer </b>
                    <span className="text-optional grey-font">
                      (optional)
                    </span>
                    <span className={`grey-font  ${errorMessageData.footer === "Characters exceed limit" ? "red-text" : ""}`}>{data.footer.length}/60 characters</span>
                  </label>
                  <input type="text" value={data.footer} onChange={(e) => onChangeText("footer", e.target.value)} placeholder="Type your footer here" className={errorMessageData.footer !== "" ? "border-red" : ""} />
                  {errorMessageData.footer !== "" &&
                    <ErrorOrInfoComp 
                      text={errorMessageData.footer}
                      icon={<FiInfo />}
                      _className="red-text"
                    />
                  }

                  <label className="input-interactive-button-text">
                    <b>Button Text</b>
                    <span className={`grey-font  ${errorMessageData.buttonText === "Characters exceed limit" ? "red-text" : ""}`}>{data.buttonText.length}/20 characters</span>
                  </label>
                  <input type="text" value={data.buttonText} onChange={(e) => onChangeText("buttonText", e.target.value)} placeholder="Type your button text here" className={errorMessageData.buttonText !== "" ? "border-red" : ""} />
                  {errorMessageData.buttonText !== "" &&
                    <ErrorOrInfoComp 
                      text={errorMessageData.buttonText}
                      icon={<FiInfo />}
                      _className="red-text"
                    />
                  }

                  <label className="input-interactive-footer">
                    <b>Manage Sections</b>
                    
                    <p className="grey-font label-sub">
                      You can add up to 10 sections and a total of 10 rows from all sections. 
                    </p>
                  </label>

                  {data.section.map((v, i) => {
                    return (
                      <div className={`button-text-wrapper-form ${i === 0 ? "section-title-wrapper-form-1": ""}`} key={`button-text-${i}`}>
                        <label className={`input-interactive-section-${i}`}>
                          <b>Section #{i + 1}</b>
                          <span className={`grey-font  ${errorMessageData.section[i].title === "Characters exceed limit" ? "red-text" : ""}`}>
                            {v.title.length}/24 characters

                            {data.section.length !== 1 ?
                              <FiTrash2 onClick={() => toggleRemoveSection(i)} className="remove-button-text" />
                              :
                              ""
                            }
                          </span>
                        </label>
                        <input type="text" value={v.title} onChange={(e) => onChangeSectionTitle(e.target.value, i)} placeholder={`Section Title${data.section.length === 1 ? " (optional)" : ""}`} className={(errorMessageData.section[i].title !== "" && errorMessageData.section[i].title !== "Each section requires at least 1 row") ? `border-red button-text-${i}` : `section-title-text-${i}`} />
                        {(errorMessageData.section[i].title !== "" && errorMessageData.section[i].title !== "Each section requires at least 1 row") &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.section[i].title}
                            icon={<FiInfo />}
                            _className="red-text"
                          />
                        }

                        <p 
                          className="manage-rows" 
                          onClick={() => {
                            setActiveManageRow({
                              data: JSON.parse(JSON.stringify(v)), 
                              error: JSON.parse(JSON.stringify(errorMessageData.section[i])),
                              index: i, 
                              show: true
                            })
                          }}
                        >
                          <FiList />
                          <b>Manage Rows ({v.rows.length})</b>
                        </p>

                        {(errorMessageData.section[i].manageRow === "Each section requires at least 1 row" || errorMessageData.section[i].manageRow === "Row title is required") &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.section[i].manageRow}
                            icon={<FiInfo />}
                            _className="red-text"
                          />
                        }
                      </div>
                    )
                  })}
                  
                  {(countRows() < 10) &&
                    <p className="add-another-button" onClick={addSection}>
                      <b><FiPlus /> Add Another Section</b>
                    </p>
                  }
                </div>
              </div>
            </Scrollbars>
          </div>

          <FormInteractiveMessageManageRow 
            activeManageRow={activeManageRow}
            onClickBack={() => {
              setActiveManageRow(false)
            }}
            errorMessageActiveManageRow={errorMessageData}
            rowLeft={10 - countRows()}
            sectionLength={data.section.length}
            lastClickSaveChangeRow={lastClickSaveChangeRow}
            runSaveChangesRow={runSaveChangesRow}
          />

          <div className={`send-message-button ${activeManageRow === false ? "" : "send-message-button-2"}`}>
            {activeManageRow === false ?
              <ButtonWithLoadingOrIcon
                onClickAction={onContinue}
                // isDisabled={isDisabledSubmit()}
                className="orange-button main-button-48"
                text="Continue"
                position="left"
              />
              :
              <>
                <ButtonWithLoadingOrIcon
                  onClickAction={() => (setActiveManageRow(false))}
                  // isDisabled={isDisabledSubmit()}
                  className="no-fill-button main-button-48"
                  text="Cancel"
                  position="left"
                />

                <ButtonWithLoadingOrIcon
                  onClickAction={() => setLastClickSaveChangeRow(new Date().valueOf())}
                  // isDisabled={isDisabledSubmit()}
                  className="orange-button main-button-48"
                  text="Save Changes"
                  position="left"
                />
              </>
            }
          </div>
        </div>
      </div>

      <FormInteractiveMessagePreview 
        _labelFor="list"
        isShowModalFormInteractivePreview={isShowModalFormInteractivePreview}
        toggle={() => {
          // setData({
          //   headerText: "",
          //   document: {
              
          //   },
          //   body: "",
          //   footer: "",
          //   buttonText: "",
          //   section: [{
          //     title: "",
          //     rows: [],
          //   }]
          // });

          // setErrorMessageData({
          //   headerText: "",
          //   document: "",
          //   body: "",
          //   footer: "",
          //   buttonText: "",
          //   section: [{
          //     title: "",
          //     manageRow: "",
          //     rows: []
          //   }]
          // })

          // toggle();
          // setIsShowModalFormInteractivePreview(false);
          // setActiveManageRow(false);
          onCloseClick("");
        }}
        data={data}
        back={() => toggleModalPreview(type)}
        type={type}
        parentProps={parentProps}
        caseDetailData={caseDetailData}
        forceCloseModal={() => {
          setData({
            headerText: "",
            document: {
              
            },
            body: "",
            footer: "",
            buttonText: "",
            section: [{
              title: "",
              rows: [],
            }]
          });

          setErrorMessageData({
            headerText: "",
            document: "",
            body: "",
            footer: "",
            buttonText: "",
            section: [{
              title: "",
              manageRow: "",
              rows: []
            }]
          });

          toggle();
          setIsShowModalFormInteractivePreview(false);
        }}
      />

      <PopupDiscard 
        dataOrigin={dataOrigin}
        dataAfterChange={data}
        isOpenDiscard={isShowModalDiscard}
        toggleDiscard={toggleModalDiscard}
        discardProgress={() => {
          setData({
            headerText: "",
            document: {
              
            },
            body: "",
            footer: "",
            buttonText: "",
            section: [{
              title: "",
              manageRow: "",
              rows: [],
            }]
          });

          setErrorMessageData({
            headerText: "",
            document: "",
            body: "",
            footer: "",
            buttonText: "",
            section: [{
              title: "",
              manageRow: "",
              rows: []
            }]
          })

          toggle();
          toggleModalDiscard();
          setIsShowModalFormInteractivePreview(false);
          setActiveManageRow(false);
        }}
        discardDesc={"You have unsaved progress on this page. Are you sure you want to discard your progress?"}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeQuickReplyInteractive: state.activeQuickReplyInteractive
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FormInteractiveMessageList);
  