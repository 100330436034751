import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const ContactCustomFieldServices = {
  getContactCustomFieldList(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CONTACT_CUSTOM_FIELD_LIST, {}, headers, callback);  
  },

  getContactCustomFieldDetails(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CONTACT_CUSTOM_FIELD_DETAILS, data, headers, callback);  
  },

  createContactCustomField(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CREATE_CONTACT_CUSTOM_FIELD, data, headers, callback);  
  },

  editContactCustomField(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_CONTACT_CUSTOM_FIELD, data, headers, callback);
  },

  deleteContactCustomField(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_CONTACT_CUSTOM_FIELD, data, headers, callback);
  },
};

const API = {
  GET_CONTACT_CUSTOM_FIELD_LIST: "/organization/setup/contact_custom_field/get_list",
  GET_CONTACT_CUSTOM_FIELD_DETAILS: "/organization/setup/contact_custom_field/get_details",
  CREATE_CONTACT_CUSTOM_FIELD: "/organization/setup/contact_custom_field/create",
  EDIT_CONTACT_CUSTOM_FIELD: "/organization/setup/contact_custom_field/edit",
  DELETE_CONTACT_CUSTOM_FIELD: "/organization/setup/contact_custom_field/delete",
}

export default ContactCustomFieldServices;
