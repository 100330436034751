import React, { useEffect, useState } from "react";
import "./SelectSegment.scss";
import { FiSearch, FiX } from "react-icons/fi";
import iconSegment from '../../../assets/img/icon-segment.svg';
import EditSegment from '../../../assets/img/edit-contact-segment.svg';
import IconSad from '../../../assets/img/icon-sad.svg';
import UserSegmentServices from "../../../services/newServices/UserSegmentServices";
import ButtonWithLoadingOrIcon from "../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupConfirmation from "../popupConfirmation/PopupConfirmation";
import { doToast } from "../../../helper/HelperGeneral";

const SelectSegment = ({
    toggle,
    originData,
    onSubmitData,
    showSegment,
    title,
    _className,
    orgID,
    isLoadingSubmit,
    ...props
}) => {
    let [segmentList, setSegmentList] = useState([]);
    let [segmentListOrigin, setSegmentListOrigin] = useState([]);
    // let [loadingGetSegment, setLoadingGetSegment] = useState(false);
    let [selectedSegment, setSelectedSegment] = useState([]);
    let [selectedSegmentOrigin, setSelectedSegmentOrigin] = useState([]);
    let [showConfirmContactSegment, setShowConfirmContactSegment] = useState(false);
    let [showDiscardContactSegment, setShowDiscardContactSegment] = useState(false);
    let [searchSegment, setSearchSegment] = useState("");
    
    useEffect(() => {
        if(showSegment) {
            setSearchSegment("");
            setSelectedSegment(originData);
            setSelectedSegmentOrigin(originData);
            getSegment();
        }else {
            setShowConfirmContactSegment(false);
        }
    }, [showSegment])

    useEffect(() => {
        if(!isLoadingSubmit) {
            setShowConfirmContactSegment(false);
        }
    }, [isLoadingSubmit])
    
    useEffect(() => {
        if(searchSegment === "") {
            setSegmentList(segmentListOrigin);
        }else {
            let newData = segmentListOrigin.filter(v => v.name.toLowerCase().includes(searchSegment.toLowerCase()));
            setSegmentList(newData);
        }
    }, [searchSegment])

    let toggleConfirmContactSegment = () => {
        setShowConfirmContactSegment(!showConfirmContactSegment);
    }

    let toggleDiscardContactSegment = () => {
        setShowDiscardContactSegment(!showDiscardContactSegment);
    }

    let getSegment = () => {
        // setLoadingGetSegment(true);

        UserSegmentServices.getSegmentList(orgID, 1, 99999, "", (response) => {
            if(response.dataResult.error.message !== "") {
                doToast(response.dataResult.error.message, "fail");
            }else {
                let arr = [];

                response.dataResult.data.segments.map((v, i) => {
                    arr.push({
                        id: v.id,
                        name: v.name
                    })

                    return null;
                })

                setSegmentList(arr);
                setSegmentListOrigin(arr);
            }

            // setLoadingGetSegment(false);
        })
    }

    let onCheckAll = (e) => {
        if(e.target.checked) {
            if(searchSegment !== "") {
                setSelectedSegment(selectedSegment.slice().concat(segmentList));
            }else {
                setSelectedSegment(segmentListOrigin);
            }
        }else {
            if(searchSegment === "") {
                onClearAll();
            }else {
                onClearAllSearch();
            }
        }
    }

    let onChangeSingleSelect = (e, v) => {
        let _selectedSegment = selectedSegment.slice();
        if(e.target.checked) {
            _selectedSegment.push(v);
        }else {
            let findIndex = selectedSegment.findIndex(_v => _v.id === v.id);

            _selectedSegment.splice(findIndex, 1);
        }

        setSelectedSegment(_selectedSegment);   
    }

    let isChecked = (id) => {
        let findIndex = selectedSegment.findIndex(v => v.id === id);

        return findIndex !== -1;
     }

    let onClearAll = () => {
        setSelectedSegment([]);
    }

    let onClearAllSearch = () => {
        let _selected = selectedSegment.slice();

        segmentList.map((v) => {
            let findIndex = _selected.findIndex(_v => _v.id === v.id);

            if(findIndex !== -1) {
                _selected.splice(findIndex, 1);
            }

            return null;
        })

        setSelectedSegment(_selected);
    }

    let removeSingleSegment = (id) => {
        let _selectedSegment = selectedSegment.slice();
        let findIndex = selectedSegment.findIndex(v => v.id === id);

        _selectedSegment.splice(findIndex, 1);
        setSelectedSegment(_selectedSegment);
    }

    let onChangeSearchSegment = (e) => {
        setSearchSegment(e.target.value);
    }

    let isCheckedSearch = () => {
        let _checked = false;
        let _selected = selectedSegment.slice();

        segmentList.map((v) => {
            let findIndex = _selected.findIndex(_v => _v.id === v.id);

            if(findIndex !== -1) {
                _checked = true;
            }

            return null;
        })      

        return _checked;
    }

    let countAllSearchIsSelected = () => {
        let _checked = false;
        let _checkedCount = 0;
        let _selected = selectedSegment.slice();

        segmentList.map((v) => {
            let findIndex = _selected.findIndex(_v => _v.id === v.id);

            if(findIndex !== -1) {
                _checkedCount = _checkedCount + 1;
            }

            return null;
        })      

        _checked = _checkedCount === segmentList.length;

        return _checked;
    }

    return (
        <div className={`option-select-segment-wrapper ${_className}`}>
            <PopupConfirmation
              isOpen={showConfirmContactSegment}
              toggle={toggleConfirmContactSegment}
              title="Save Changes?"
              description="There has been changes in this contact’s contact segments detail. Are you sure you want to continue?"
              className="modal-confirm-contact-segment"
            >
              {!isLoadingSubmit ?
                  <ButtonWithLoadingOrIcon
                      text="Save Changes"
                      className="main-button-48 orange-button"
                      onClickAction={() => onSubmitData(selectedSegment)}
                  />
                  :
                  <ButtonWithLoadingOrIcon
                      text="Save Changes"
                      className="main-button-48 orange-button"
                      isLoading
                      isDisabled
                      loadingColor="gray"
                      position="left"
                  />
              }
            </PopupConfirmation>

            <PopupConfirmation
              isOpen={showDiscardContactSegment}
              toggle={toggleDiscardContactSegment}
              title="Discard Progress?"
              description="There has been unsaved changes in this contact’s contact segment details. Are you sure you want to go back without saving?"
              className="modal-confirm-contact-segment"
            >
                <ButtonWithLoadingOrIcon
                    text="Discard Changes"
                    className="main-button-48 red-button"
                    onClickAction={toggle}
                />
            </PopupConfirmation>

            <p className="main-title">
                <img src={EditSegment} alt="" />
                <b>{title}</b>
            </p>
            
            <div className="option-select-segment-wrapper-50">
                <label>
                    <b>Select Contact Segments</b> <span className="grey-font">(optional)</span>
                </label>
        
                <div className="selection-segment-wrapper custom-checkbox">
                    <div className="search-box-wrapper">
                        <input type="text"
                            placeholder={"Search for contact segments"}
                            onChange={(e) => onChangeSearchSegment(e)}
                            value={searchSegment}
                        />

                        <div id="search-icon">
                            <FiSearch size="20" color="#191919" />
                        </div>
                    </div>

                    <div className="overflow-select-segment">
                        {segmentList.length > 0 &&
                            <>
                                {searchSegment === "" &&
                                    <div className={`select-content ${selectedSegment.length === segmentList.length ? "custom-checkbox" : "custom-checkbox-stripe"} custom-checkbox-min checkbox-all`}>
                                        <input type="checkbox" id="select-all-segment" onChange={(e) => onCheckAll(e)} checked={selectedSegment.length > 0} />
                                        {/* <label htmlFor="select-all-segment">{selectedSegment.length === segmentList.length ? "Deselect all" : "Select all"}</label> */}
                                        <label htmlFor="select-all-segment">{selectedSegment.length > 0 ? "Deselect all" : "Select all"}</label>
                                    </div>
                                }

                                {searchSegment !== "" &&
                                    <div className={`select-content ${countAllSearchIsSelected() ? "custom-checkbox" : "custom-checkbox-stripe"} custom-checkbox-min checkbox-all`}>
                                        <input type="checkbox" id="select-all-segment" onChange={(e) => onCheckAll(e)} checked={isCheckedSearch()} />
                                        {/* <label htmlFor="select-all-segment">{selectedSegment.length === segmentList.length ? "Deselect all" : "Select all"}</label> */}
                                        <label htmlFor="select-all-segment">{isCheckedSearch() ? "Deselect all" : "Select all"}</label>
                                    </div>
                                }
                            </>
                        }

                        {segmentList.length > 0 ?
                            <>
                                {/* <div className="select-content select-all">
                                    <input 
                                        type="checkbox" 
                                        id={"selectAll"} 
                                        onChange={(e) => onCheckAll(e)} 
                                        checked={isCheckAll} 
                                    />
                                    <label htmlFor={"selectAll"}>Select All</label>
                                </div> */}

                                {segmentList.map((value, index) => {
                                    return (
                                        <div className="select-content" key={`segment-${index}`}>
                                            <input 
                                                type="checkbox" 
                                                id={value.id} 
                                                onChange={(e) => {onChangeSingleSelect(e, value)}} 
                                                checked={isChecked(value.id)} 
                                            />
                                            <label htmlFor={value.id}>{value.name}</label>
                                        </div>
                                    )
                                })}
                            </>
                            :
                            <div className="select-content not-found">
                                <img src={IconSad} alt="" />

                                <p>
                                    No results found. Please try a different keyword.
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="option-select-segment-wrapper-50">
                <label>
                    <b>Selected</b> <span>({selectedSegment.length})</span>

                    {/* {selectedSegment.length > 0 &&
                        <b className="clear-segment" onClick={onClearAll}>
                            <FiX />
                            Clear All
                        </b>
                    } */}
                </label>

                <div className="select-segment-wrapper">
                    <div className="selected-segment-container">
                        {selectedSegment.length > 0 ?
                            <>
                                {selectedSegment.map((value, index) => {
                                    return (
                                        <div className="topic-list-content" key={`selected-segment-${index}`}>
                                            <b>{value.name}</b>

                                            <div className="remove-topic" onClick={() => removeSingleSegment(value.id)}>
                                                <FiX />
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            :
                            <div className="no-selected-segment">
                                <img src={iconSegment} alt="" />
                                <p className="font-root-medium">Selected contact segments will be displayed here.</p>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="contact-segment-button-wrapper">
                <ButtonWithLoadingOrIcon 
                    className="no-fill-button main-button-48"
                    text="Cancel"
                    onClickAction={() => {
                        if(JSON.stringify(selectedSegment) !== JSON.stringify(selectedSegmentOrigin)) {
                            toggleDiscardContactSegment();
                        }else {
                            toggle();
                        }
                    }}
                />

                <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-48"
                    text="Save Changes"
                    onClickAction={toggleConfirmContactSegment}
                />
            </div>
        </div>
    )
}

export default SelectSegment;