import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const AssignmentRulesServices = {
  get(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET, {}, headers, callback);  
  },

  set(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET, data, headers, callback);  
  },
};

const API = {
  GET: "/organization/setup/assignment_rule/get",
  SET: "/organization/setup/assignment_rule/set"
}

export default AssignmentRulesServices;
