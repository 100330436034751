const INITIAL_STATE = false;

let reduxReducerSalesTalkNotConnect = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_SALESTALK_NOT_CONNECT':
        return action.salesTalkNotConnect;
      case 'CLEAR_SALESTALK_NOT_CONNECT':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerSalesTalkNotConnect;