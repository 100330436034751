const INITIAL_STATE = false;

let reduxReducerBalance = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_BALANCE_DATA':
        return action.balanceData;
      case 'CLEAR_BALANCE_DATA':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerBalance;