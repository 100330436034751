const INITIAL_STATE = {
    globalSearch: {
        isShow: false,
        data: {},
        checkedAll: false
    },
    bot: {
        isShow: false,
        data: {},
        checkedAll: false
    },
    mine: {
        isShow: false,
        data: {},
        checkedAll: false
    },
    unassigned: {
        isShow: false,
        data: {},
        checkedAll: false
    },
    other: {
        isShow: false,
        data: {},
        checkedAll: false
    },
    solved: {
        isShow: false,
        data: {},
        checkedAll: false
    },
};

let reduxReducerCaseListChecked = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_CASE_LIST_CHECKED':
        return action.caseListChecked;
      case 'CLEAR_CASE_LIST_CHECKED':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerCaseListChecked;