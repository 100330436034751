import React, { useState, useEffect } from "react";
import { FiInfo } from "react-icons/fi";
import { checkID, doToast } from "../../../../../../helper/HelperGeneral";
import IntegrationGoogleServices from "../../../../../../services/integration/IntegrationGoogleServices";
import ServiceOrganization from "../../../../../../services/organization/ServiceOrganization";
import BackButton from "../../../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import SectionWrap from "../../../../../reuseableComponent/sectionWrap/SectionWrap";
import { scrollToClass } from "../../../../../../helper/HelperGeneral";
import "./AddLocation.scss";

let IntegrationGoogleAddLocationEntryPoint = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  let [data, setData] = useState({
    placeID: "",
    errorPlaceID: "",
    alias: "",
    errorAlias: ""
  })
  let [dataOrigin, setDataOrigin] = useState({
    placeID: "",
    errorPlaceID: "",
    alias: "",
    errorAlias: ""
  })

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  let onSubmit = () => {
    let errorSubmitVerif = "";
    let _data = {...data};

    if(data.placeID === "") {
      _data.errorPlaceID = "This field is required";
      
      if(errorSubmitVerif === "") {
        errorSubmitVerif = "place-id";
      }
    }

    if(data.alias === "") {
      _data.errorAlias = "This field is required";
      
      if(errorSubmitVerif === "") {
        errorSubmitVerif = "alias";
      }
    }

    if(data.alias.length > 50) {
      _data.errorAlias = "Characters exceed limit";
      
      if(errorSubmitVerif === "") {
        errorSubmitVerif = "alias";
      }
    }

    if(errorSubmitVerif !== "") {
      scrollToClass(`.${errorSubmitVerif}`);
      setData(_data);
    }else {
      setIsLoadingSubmit(true);
  
      let _data = {
        ...data,
        channelID: Number(match.params.id),
      };
  
      IntegrationGoogleServices.addLocation(orgID, _data, (response) => {
        if (response.dataResult.error.message === "") {
          if(response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
            setDataOrigin(data);
            setTimeout(() => {
              history.push({
                pathname: `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
                state: {
                  whichTab: "entryPoints",
                },
              })
            }, 3000);
          }else {
            doToast(response.dataResult.data.message, "fail");
            setIsLoadingSubmit(false);
          }
        } else {
          doToast(response.dataResult.error.message, "fail");
          setIsLoadingSubmit(false);
        }
      });
    }
  };

  // let isDisabled = () => {
  //   return data.placeID === "" || data.alias === "";
  // }
  
  return (
    <div className="location-entry-point-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID} 
        withHeader 
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >
        <BackButton
          text="Back to Entry Points"
          onClick={() =>
            history.push({
              pathname: `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
              state: {
                whichTab: "entryPoints",
              },
            })
          }
        />

        <div className="location-entry-point-google sectionWrap">
          <>
            <div className="location-entry-point-header">
              <div className="title-wrapper">
                <b>Add Location</b>

                <p>
                  Add all locations for the brand where you have permission to enable messaging. To verify all locations, first request verification for just one location. Once that location is verified, all other associated locations will be verified automatically.
                </p>
              </div>
            </div>

            <div className="location-entry-point-body">
                <p className="form-title">
                  <b>
                    Place ID
                  </b>
                </p>

                <input 
                  type="text"
                  placeholder="Type your place ID here"
                  value={data.placeID}
                  className={data.errorPlaceID !== "" ? "border-red" : ""}
                  id="placeID"
                  onChange={(e) => {
                      let _data = {...data};
                      _data.errorPlaceID = "";
                      _data[e.target.id] = e.target.value;
                      setData(_data);
                  }}
                  disabled={isLoadingSubmit}
                />

                {data.errorPlaceID === "" ?
                  <p className="info-input">
                    <b>
                        <FiInfo />
                        To get Place ID number, <a href="https://developers-dot-devsite-v2-prod.appspot.com/my-business/content/tools/placeid-lookup" target="_blank" rel="noopener noreferrer">please read the documentation.</a>
                    </b>
                  </p>
                  :
                  <ErrorOrInfoComp
                      text={data.errorPlaceID}
                      _className={"font-red"}
                      icon={<FiInfo />}
                  />
                }

                <p className="form-title">
                  <b>
                    Place Alias
                  </b>

                  <span className={data.errorAlias === "Characters exceed limit" ? "red-text" : ""}>{data.alias.length}/50 characters</span>
                </p>

                <input 
                  type="text"
                  placeholder="Type your place alias here"
                  value={data.alias}
                  className={data.errorAlias !== "" ? "border-red" : ""}
                  id="alias"
                  onChange={(e) => {
                      let _data = {...data};
                      _data.errorAlias = "";
                      _data[e.target.id] = e.target.value;
                      setData(_data);
                  }}
                  disabled={isLoadingSubmit}
                />

                {data.errorAlias !== "" &&
                  <ErrorOrInfoComp
                      text={data.errorAlias}
                      _className={"font-red"}
                      icon={<FiInfo />}
                  />
                }

                {!isLoadingSubmit ? 
                  <ButtonWithLoadingOrIcon
                    className="orange-button  main-button-40"
                    text="Save Changes"
                    // isDisabled={isDisabled()}
                    onClickAction={onSubmit}
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    className="orange-button main-button-40"
                    text="Save Changes"
                    position="left"
                  />
                }
            </div>
          </>
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationGoogleAddLocationEntryPoint;
