import React, { useState, useEffect } from 'react';
import { FiTrash2, FiPlus, FiInfo } from "react-icons/fi"; 
import { checkID, doToast, scrollToClass } from '../../../../../../helper/HelperGeneral';
import HelperInput from "../../../../../../helper/HelperInput";
import IntegrationGoogleServices from '../../../../../../services/integration/IntegrationGoogleServices';
import ServiceOrganization from '../../../../../../services/organization/ServiceOrganization';
import BackButton from '../../../../../reuseableComponent/BackButton/BackButton';
import ButtonWithLoadingOrIcon from '../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import RetrieveDataLoading from '../../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import SectionWrap from '../../../../../reuseableComponent/sectionWrap/SectionWrap';
import CustomSelectOption from "../../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from '../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import './EditGreetingMessage.scss'

let IntegrationGoogleEditAccountProfile = (props) => {
  const LANG = [
    {
        label: "English US",
        value: "en"
    },
    {
        label: "Indonesia",
        value: "id"
    }
  ]

  const DEFAULT_DATA =  {
    languageCode: {
        value: "",
        label: ""
    },
    errorLanguageCode: "",
    welcomeMessage: "",
    errorWelcomeMessage: "",
    privacyPolicyURL: "",
    errorPrivacyPolicyURL: "",
    defaultLocale: true
  };
  
  let { match, history } = props
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(false)
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [greetings, setGreetings] = useState([DEFAULT_DATA]);
  let [greetingsOrigin, setGreetingsOrigin] = useState([DEFAULT_DATA]);
  let [isLoadingEditGreeting, setIsLoadingEditGreeting] = useState(false)

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getAccountDetail()
    }
  }, [orgID]);

  let onClickContinue = () => {
    let _editGoogle = JSON.stringify(greetings);
    let _parse = JSON.parse(_editGoogle);
    let errorCount = 0;
    let scrollToError = "";

    _parse.map((v, i) => {
        if(v.languageCode.value === "") {
            v.errorLanguageCode = "This field is required";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `language-code-${i}`}
        }

        if(v.welcomeMessage === "") {
            v.errorWelcomeMessage = "This field is required";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `welcome-message-${i}`}
        }

        if(v.welcomeMessage.length > 1000) {
          v.errorWelcomeMessage = "Characters exceed limit";
          errorCount = errorCount + 1;

          if(scrollToError === "") { scrollToError = `welcome-message-${i}`}
        }

        if(v.privacyPolicyURL === "") {
            v.errorPrivacyPolicyURL = "This field is required";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `privacy-url-${i}`}
        }

        // if(
        //    _parse.length > 1 && 
        //     (_parse[0].privacyPolicyURL  !== "" &&_parse[1].privacyPolicyURL !== "") &&
        //     (_parse[0].privacyPolicyURL === _parse[1].privacyPolicyURL)
        // ) {
        //    _parse[0].errorPrivacyPolicyURL = "Duplicate URL";
        //    _parse[1].errorPrivacyPolicyURL = "Duplicate URL";
        //     errorCount = errorCount + 1;

        //     if(scrollToError === "") { scrollToError = `privacy-url-0`}
        // }

        if(!HelperInput.validURL(v.privacyPolicyURL)) {
            v.errorPrivacyPolicyURL = "Invalid URL Format";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `privacy-url-${i}`}
        }

        return null;
    })

    if(errorCount === 0) {
      submitEditGreeting();
    }else {
      scrollToClass(`.${scrollToError}`);
      setGreetings(_parse);
    }
}

  let getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: Number(match.params.id)
    }

    IntegrationGoogleServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        let greet = [];

        response.dataResult.data.profile.conversationalSettings.map((v, i) => {
          greet.push({
            languageCode: {
              value: v.languageCode,
              label: (v.languageCode === "en" ? "English US" : "Indonesia")
            },
            errorLanguageCode: "",
            welcomeMessage: v.welcomeMessage,
            errorWelcomeMessage: "",
            privacyPolicyURL: v.privacyPolicyURL,
            errorPrivacyPolicyURL: "",
            defaultLocale: v.languageCode === response.dataResult.data.profile.defaultLocale
          })

          return null;
        })

        
        setGreetings(greet);
        setGreetingsOrigin(greet);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let submitEditGreeting = () => {
    setIsLoadingEditGreeting(true)

    let data = {
      id: Number(match.params.id),
      conversationalSettings: [],
      defaultLanguageCode: ""
    }

    greetings.map((v) => {
        data.conversationalSettings.push({
            "languageCode": v.languageCode.value,
            "welcomeMessage": v.welcomeMessage,
            "privacyPolicyURL": v.privacyPolicyURL
        })

        if(v.defaultLocale) {
            data.defaultLanguageCode = v.languageCode.value;
        }

        return null;
    })

    IntegrationGoogleServices.editConvo(orgID, data, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
          setGreetingsOrigin(greetings);

          setTimeout(() => {
            history.push(
              `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
              {
                whichTab: 'channelProfile'
              }
            );
          }, 3000)
        } else {
          doToast(response.dataResult.data.message, 'fail');
          setIsLoadingEditGreeting(false);
        }
      } else {
        doToast(response.dataResult.error.message, 'fail');
        setIsLoadingEditGreeting(false);
      }
    });
  }

  let filterLangList = (currentIndex) => {
    let _editGoogle = JSON.stringify(greetings);
    let _parse = JSON.parse(_editGoogle);
    let _langList = LANG.slice();

    _langList.map((valLang, indexLang) => {
        _parse.map((valTemp, indexTemp) => {
            if(valLang.value === valTemp.languageCode.value && currentIndex !== indexTemp) {
                _langList.splice(indexLang, 1);
            }
    
            return null;
        })

        return null;
    })
    
    return _langList;
  }

  let printGreeting = (v, i) => {
    return (
        <>  
            {i === 1 &&
                <div className="border-top">
                    <p>
                        <FiPlus />
                        <b>Add Language</b>
                    </p>

                    <ButtonWithLoadingOrIcon 
                        className="red-button main-button-40"
                        icon={{
                            src: FiTrash2,
                            type: "svg"
                        }}
                        text="Delete"
                        onClickAction={() => onClickDelete()}
                        position="left"
                    />
                </div>
            }

            <div className={`greeting-wrapper`}>
                <p className="check-locale">
                    <div className="custom-checkbox">
                        <input disabled={isLoadingEditGreeting} type="checkbox" id={`check-locale-${i}`} checked={greetings[i].defaultLocale} onClick={(e) => onCheckLocale(e, i)} />
                        <label htmlFor={`check-locale-${i}`}>
                            Set as Default Locale
                        </label>
                    </div>

                </p>

                <label className={`language-code-${i}`}>
                    <b>Language</b>
                </label>

                <CustomSelectOption
                    optionListProps={filterLangList(i)}
                    valueDropdownProps={greetings[i].languageCode}
                    onClickDropDownListOptionProps={(e) => onSelectListLang(e, i)}
                    placeholderProps={"Select Language"}
                    _className={greetings[i].errorLanguageCode === "" ? '' : 'error-select'}  
                    isDisabled={isLoadingEditGreeting}
                />

                {greetings[i].errorLanguageCode !== "" &&
                    <ErrorOrInfoComp
                        text={greetings[i].errorLanguageCode}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                }

                <label className={`margin-top-24 welcome-message-${i}`}>
                    <b>Message</b>

                    <span className={`grey-font ${greetings[i].errorWelcomeMessage === "Characters exceed limit" ? "red-text" : ""}`}>
                        {greetings[i].welcomeMessage.length}/1000 characters
                    </span>
                </label>

                <textarea
                    placeholder="Type your message here"
                    rows={4}
                    value={greetings[i].welcomeMessage}
                    onChange={(e) => {
                        let _editGoogle = JSON.stringify(greetings);
                        let _parse = JSON.parse(_editGoogle);
                
                        _parse[i].welcomeMessage = e.target.value;
                        _parse[i].errorWelcomeMessage = "";
                        setGreetings(_parse);
                    }}
                    className={`${greetings[i].errorWelcomeMessage === "" ? "" : "border-red"}`}
                    disabled={isLoadingEditGreeting}
                />

                {greetings[i].errorWelcomeMessage !== "" &&
                    <ErrorOrInfoComp
                        text={greetings[i].errorWelcomeMessage}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                }

                <label className={`margin-top-24 privacy-url-${i}`}>
                    <b>Privacy Policy URL</b>
                </label>

                <input
                    type="text"
                    placeholder="Type your privacy policy URL here"
                    value={greetings[i].privacyPolicyURL}
                    onChange={(e) => {
                        let _editGoogle = JSON.stringify(greetings);
                        let _parse = JSON.parse(_editGoogle);
                
                        _parse[i].privacyPolicyURL = e.target.value;
                        _parse[i].errorPrivacyPolicyURL = "";
                        setGreetings(_parse);
                    }}
                    className={`${greetings[i].errorPrivacyPolicyURL === "" ? "" : "border-red"}`}
                    disabled={isLoadingEditGreeting}
                />

                {greetings[i].errorPrivacyPolicyURL !== "" &&
                    <ErrorOrInfoComp
                        text={greetings[i].errorPrivacyPolicyURL}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                }
            </div>
        </>
    )
  }

  let onClickAddMoreLang = () => {
    if(!isLoadingEditGreeting) {
      let _editGoogle = JSON.stringify(greetings);
      let _parse = JSON.parse(_editGoogle);
  
      _parse.push({
        languageCode: {
          value: "",
          label: ""
        },
        errorLanguageCode: "",
        welcomeMessage: "",
        errorWelcomeMessage: "",
        privacyPolicyURL: "",
        errorPrivacyPolicyURL: "",
        defaultLocale: false
      });
      setGreetings(_parse);
    }
  }

  let onClickDelete = () => {
    if(!isLoadingEditGreeting){
      let _editGoogle = JSON.stringify(greetings);
      let _parse = JSON.parse(_editGoogle);

      _parse.pop();
      
      _parse[0].defaultLocale = true;
      setGreetings(_parse);
    }
  }

  let onSelectListLang = (e, i) => {
    let _editGoogle = JSON.stringify(greetings);
    let _parse = JSON.parse(_editGoogle);

    _parse[i].languageCode = e;
    _parse[i].errorLanguageCode = "";
    setGreetings(_parse);
  }

  let onCheckLocale = (e, i) => {
    let _editGoogle = JSON.stringify(greetings);
    let _parse = JSON.parse(_editGoogle);

    if(_parse.length > 1) {
      _parse.map((v) => {
          v.defaultLocale = false;
          return null;
      })

      _parse[i].defaultLocale = true;
    }

    // if(e.target.checked === false) {
    //     let count = 0;

    //     _parse.map((v) => {
    //         if(v.defaultLocale === true) {
    //             count = count + 1;
    //         }
    //     })
        
    //     if(count === 1) {
    //         _parse[0].defaultLocale = true;

    //         if(_parse.length === 2) {
    //             _parse[1].defaultLocale = false;
    //         }
    //     }else {
    //         _parse[i].defaultLocale = false;
    //     }
    // }else {
    //     _parse[i].defaultLocale = e.target.checked;
    // }

    setGreetings(_parse);
  }

  // let isDisabled = () => {
  //   let dis = false;

  //   if(JSON.stringify(greetings) === JSON.stringify(greetingsOrigin)) {
  //     dis = true;
  //   }

  //   return dis;
  // }

  return (
    <div className="edit-profile-outer-wrapper main-integration-google-create-greeting">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={greetingsOrigin}
        dataAfterChange={greetings}
      >

      <BackButton 
        text="Back to Channel Profile" 
        onClick={() => history.push({
          pathname: `/o/${orgID}/integration/google-business-messages/detail/${match.params.id}`,
          state: {
            whichTab: "channelProfile"
          }
        })}
      />

        <div className="edit-account-profile-google sectionWrap">
          {isLoadingGetDetailData || retriveDataError.code ?
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
            :
            <>
              <div className="edit-account-profile-header">
                <div className="title-wrapper">
                  <b>Edit Greeting Message</b>
                </div>

                <div className="button-wrapper">
                  {
                    !isLoadingEditGreeting ?
                      <ButtonWithLoadingOrIcon
                        className="orange-button  main-button-40"
                        text="Save Changes"
                        // isDisabled={isDisabled()}
                        onClickAction={onClickContinue}
                      />
                      :
                      <ButtonWithLoadingOrIcon
                        isLoading
                        isDisabled
                        loadingColor="gray"
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                      />
                  }
                </div>
              </div>
              <div className="edit-account-profile-content">
                <div className="account-profile-content-wrapper">
                  {greetings.map((v, i) => {
                      return printGreeting(v, i)
                  })}

                  {greetings.length === 1 &&
                      <div className="add-lang" onClick={onClickAddMoreLang}>
                          <FiPlus />
                          <b>Add More Language</b>
                      </div>
                  }
                </div>
              </div>
            </>
          }
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationGoogleEditAccountProfile;