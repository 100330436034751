import React, { useState, useEffect } from 'react';
import './ChannelDetailEdit.scss';
import IntegrationWhatsappBusinessServices from "../../../../../../services/integration/IntegrationWhatsappBusinessServices";
import topicServices from '../../../../../../services/newServices/TopicServices';
import SectionWrap from '../../../../../reuseableComponent/sectionWrap/SectionWrap';
import ErrorOrInfoComp from "../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { FiInfo } from 'react-icons/fi';
// import CustomSelectOption from '../../../../../reuseableComponent/customSelectOption/CustomSelectOption';
import BackButton from '../../../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SelectTopic from "../../../../../reuseableComponent/selectTopic/SelectTopic";
import ServiceOrganization from '../../../../../../services/organization/ServiceOrganization';
import { checkID, doToast, scrollToClass } from '../../../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const IntegrationWabaChannelDetailEdit = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  const [isLoadingEditTopicAccount, setIsLoadingEditTopicAccount] = useState(false);
  const [accountDetail, setAccountDetail] = useState(null);
  const [accountDetailInitial, setAccountDetailInitial] = useState(null);
  // const [accountTopicInitial, setAccountTopicInitial] = useState({
  //   label: '',
  //   value: '',
  // });
  // const [accountTopicVal, setAccountTopicVal] = useState({
  //   label: '',
  //   value: '',
  // });
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [topicList, setTopicList] = useState([]);
  let [topicListOrigin, setTopicListOrigin] = useState([]);
  let [isCheckAll, setIsCheckAll] = useState(false);
  let [selectedTopic, setSelectedTopic] = useState([]);
  let [selectedTopicOrigin, setSelectedTopicOrigin] = useState([]);
  let [searchTopic, setSearchTopic] = useState("");
  let [errorMessageData, setErrorMessageData] = useState({
    name: "",
    topic: ""
  }) 

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getTopics(orgID);
    }
  }, [orgID]);

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getWhatsappAccountDetail(paramID, match.params.id);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const getWhatsappAccountDetail = (orgID, id) => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: parseInt(id),
      phoneNumberID: props.match.params.phoneID
    }

    IntegrationWhatsappBusinessServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        // setAccountTopicInitial({
        //   label: response.dataResult.data.channel.topicName,
        //   value: response.dataResult.data.channel.topicID,
        // });
        // setAccountTopicVal({
        //   label: response.dataResult.data.channel.topicName,
        //   value: response.dataResult.data.channel.topicID,
        // });
        setAccountDetail(response.dataResult.data.channel);
        setAccountDetailInitial(response.dataResult.data.channel);

        if(response.dataResult.data.channel.topics.length > 0) {
          response.dataResult.data.channel.topics.map((v) => {
              v.value = v.id;
              return null;
          });

          setSelectedTopic(response.dataResult.data.channel.topics);
          setSelectedTopicOrigin(response.dataResult.data.channel.topics);
        }
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        
        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetailData(false);
    });
  };

  const getTopics = async (orgID) => {
    topicServices.getTopic(orgID, 'list', null, (response) => {
      const data = response.dataResult.data.topics;
      const createList = () => {
        let newData = [];
        data.map((val) => {
          let _data = {};
          _data.id = val.id;
          _data.name = val.name;
          newData.push(_data);
          return null;
        });
        setTopicList(newData);
        setTopicListOrigin(newData);
      };

      createList();
    });
  };

  const editWhatsappAccount = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(accountDetail.accountName === "") {
        _errorMessageData.name = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-name" }
    }

    if(selectedTopic.length === 0) {
      _errorMessageData.topic = "Please select topic";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-topic" }
    }

    if(accountDetail.accountName.length > 100) {
      _errorMessageData.name = "Characters exceed limit";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-name" }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      setIsLoadingEditTopicAccount(true);

      let topics = [];

      selectedTopic.map((v) => {
        topics.push(v.id);
        return null;
      })

      let data = {
        id: parseInt(props.match.params.id),
        accountName: accountDetail.accountName,
        // topicID: accountTopicVal.value,
        topicIDs: topics,
        showFAQ: false,
        phoneNumberID: props.match.params.phoneID
    }

      IntegrationWhatsappBusinessServices.editChannel(orgID, data, (response) => {
          setIsLoadingEditTopicAccount(false);

          if (response.dataResult.status === 200) {
            if (!response.dataResult.data.success) {
              doToast(response.dataResult.data.message);
            } else {
              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Edit Topic",
                  {
                    channel : "WhatsApp Business API",
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : orgID.split("-")[0]
                  }
                );
              }
              
              setAccountDetailInitial(accountDetail);
              // setAccountTopicInitial(accountTopicVal);
              setSelectedTopicOrigin(selectedTopic);

              setTimeout(() => {
                history.push(
                  `/o/${orgID}/integration/whatsapp-business-api/detail/${props.match.params.id}`,
                  {
                    whichTab: 'channelDetails'
                  }
                );
                doToast(response.dataResult.data.message);
              }, 0)
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
          }
        }
      );
    }
  };

  // let onSelectTopic = (value) => {
  //   setAccountTopicVal(value);
  // };

  // let isFormValid = () => {
  //   // let _accountTopicVal = accountTopicVal.value;
  //   let _accountDetail = {...accountDetail};
  //   let isValid = true;

  //   if(_accountDetail.accountName === "") {
  //     isValid = false;
  //   }

  //   if(selectedTopic.length === 0) {
  //     isValid = false;
  //   }

  //   if((_accountDetail.accountName === accountDetailInitial.accountName) && ((JSON.stringify(selectedTopic) === JSON.stringify(selectedTopicOrigin)))) {
  //     isValid = false;
  //   }

  //   return isValid;
  // };

  let back = (id) => {
    history.push(`/o/${orgID}/integration/whatsapp-business-api/detail/${id}`);
  }

  let changeDetail = (e) => {
    let _accountDetail = {...accountDetail};
    _accountDetail[e.target.id] = e.target.value;
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.name = "";
    setErrorMessageData(_errorMessageData);
    setAccountDetail(_accountDetail);
  }

  let onChangeSingleSelect = (e, val) => {
    if(!isLoadingEditTopicAccount) {
      let _selectedTopic = [...selectedTopic]
  
      if (e.target.checked) {
          _selectedTopic = [..._selectedTopic, val]
      } else {
          const _dataSelectedTopic = [..._selectedTopic]
          _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
          setIsCheckAll(false);
      }
      
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
      setSelectedTopic(_selectedTopic);
    }
  }

  const onClearAll = () => {
    if(!isLoadingEditTopicAccount) {
      setIsCheckAll(false);
      setSelectedTopic([]);
    }
  }

  let onCheckAll = (e) => {
    if(!isLoadingEditTopicAccount) {
      if (e.target.checked) {
          setSelectedTopic(topicList);
      } else {
          setSelectedTopic([]);
      }
      
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
      setIsCheckAll(e.target.checked);
    }
  }

  let isChecked = (id) => {
    const _data = [...selectedTopic]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let removeSingleTopic = (val) => {
    if(!isLoadingEditTopicAccount) {
      setIsCheckAll(false);
      let _selectedTopic = [...selectedTopic];
      const _dataSelectedTopic = [..._selectedTopic]
  
      _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
  
      setSelectedTopic(_selectedTopic);
    }
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
        for (let i in _topicOrigin) {
          let objectKey = Object.keys(_topicOrigin[i]);

          for (let j in objectKey) {
              let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
              if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
                result.push(_topicOrigin[i])
                break;
              }
          }
        }
    }

    setTopicList(result);
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID} 
        withHeader 
        withSideMenu
        dataOrigin={{
          detail: accountDetailInitial,
          topic: JSON.stringify(selectedTopicOrigin)
        }}
        dataAfterChange={{
          detail: accountDetail,
          topic:  JSON.stringify(selectedTopic)
        }}
      >
        <div className="sectionWrap integration-main-container integration-waba-edit-topic-account text-left">
            
            <>
              <BackButton
                text={`Back to Channel Details`}
                onClick={() => back(props.match.params.id)}
              />

              <div className="integration-waba-edit-topic-account-box">
                {isLoadingGetDetailData || retriveDataError.code ? 
                  <div className="integration-detail-loading-wrapper">
                    <RetrieveDataLoading 
                      isLoading={isLoadingGetDetailData}
                      errorMessage={retriveDataError.message} 
                    />
                  </div>
                  : 
                  <>
                    <div className="integration-waba-edit-topic-account-box-top">
                      <b>Edit Channel Details</b>

                      {!isLoadingEditTopicAccount ? (
                        <ButtonWithLoadingOrIcon 
                            // isDisabled={!isFormValid()}
                            onClickAction={editWhatsappAccount}
                            className="orange-button main-button-40"
                            text="Save Changes"
                            position="left"
                        />
                      ) : (
                        <ButtonWithLoadingOrIcon 
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            className="orange-button main-button-40"
                            text="Save Changes"
                            position="left"
                        />
                      )}
                    </div>

                    <div className="integration-waba-edit-topic-account-box-form">
                      <div className="form-topic">
                        <div className="form-content-wrapper">
                            <label>
                              <span className={errorMessageData.name === "Characters exceed limit" ? "red-text" : "grey-font"}>{accountDetail.accountName.length}/100</span>
                              <b>Channel Name</b>
                            </label>

                            <input className={`input-name ${errorMessageData.name !== "" ? "border-red" : ""}`} disabled={isLoadingEditTopicAccount} type="text" placeholder="Input channel name" value={accountDetail.accountName} onChange={(e) => changeDetail(e)}  id="accountName" />
                            {errorMessageData.name !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.name}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }
                        </div>
                        
                        {/* <label>
                          <b>Topic</b>
                        </label>
                        <CustomSelectOption
                          optionListProps={topicList}
                          valueDropdownProps={accountTopicVal}
                          placeholderProps={'Select channel topic'}
                          onClickDropDownListOptionProps={onSelectTopic}
                          specificClassNameProps="custom-select-waba-topic"
                        />
                        <span className="integration-info-form">
                            Incoming messages on this channel will be assigned to this topic.
                        </span> */}
                        <SelectTopic 
                          onChangeSearchTopic={onChangeSearchTopic}
                          onCheckAll={onCheckAll}
                          onClearAll={onClearAll}
                          topicList={topicList}
                          onChangeSingleSelect={onChangeSingleSelect}
                          isChecked={isChecked}
                          isCheckAll={isCheckAll}
                          searchTopic={searchTopic}
                          title={"Topic"}
                          selectedTopic={selectedTopic}
                          removeSingleTopic={removeSingleTopic}
                          _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                          isDisabled={isLoadingEditTopicAccount}
                        />

                        <ErrorOrInfoComp
                            text={errorMessageData.topic === "" ? "Customer will be able to choose if you select more than one topics." : errorMessageData.name}
                            _className={errorMessageData.topic !== "" ? "font-red" : ""}
                            icon={<FiInfo />}
                        />
                        {/* <p className="input-info-text no-margin-bottom width-100">
                            <FiInfo />
                            <b>Customer will be able to choose if you select more than one topics.</b>
                        </p> */}
                      </div>
                    </div>
                  </>
                }
              </div>
            </>
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationWabaChannelDetailEdit;
