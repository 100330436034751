import React from "react";
import "./SlideModal.scss";
import { FiX } from "react-icons/fi";

let SlideModal = (props) => {
    let { toggle, isShow, children, _className } = props;

    return (
        <div className={`slide-modal ${_className} ${isShow ? "active-slide-modal" : ""}`}>
            <div className={`modal-body ${isShow ? "active-slide-modal-body" : ""}`}>
                <div onClick={toggle} className="close-slide-modal">
                    <FiX />
                </div>

                {children}
            </div>
        </div>
    )
}

export default SlideModal;