import React from 'react';
import './LabelDropdown.scss';
import { FiChevronDown } from 'react-icons/fi';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const LabelDropdown = (props) => {
    let {
        isOpenDropdown, 
        toggleDropdown,
        labelsArray, // {val, text, background}
        selectedLabel,
        onLabelSelected,
        isDisabled
    } = props;

    let onClickLabel = (val) => {
        if (val === selectedLabel) {
            return;
        }
        onLabelSelected(val);
    }

    return (
        <>
            <Dropdown 
                isOpen={isOpenDropdown} 
                toggle={!isDisabled ? toggleDropdown : null} 
                className={`label-dropdown ${isDisabled ? "disabled-label-dropdown" : ""}`} 
                isDisabled={isDisabled}
            >
                <DropdownToggle>
                    <div className="button-text-wrapper">
                    {
                        selectedLabel.text
                        ?
                        <p className="label" style={{background: selectedLabel.background}}>{selectedLabel.text}</p>
                        :
                        <p className="placeholder">Please select</p>
                    }
                    </div>
                    <FiChevronDown className={`icon-caret ${isOpenDropdown ? "icon-caret-open" : ""}`} />
                </DropdownToggle>
                <DropdownMenu>
                    {labelsArray.map((v) => {
                        return (
                            <DropdownItem key={`selection-${v.val}`} onClick={() => onClickLabel(v)}>
                                {/* {selectedLabel === v &&
                                    <FiCheck />
                                } */}
                                <p className="label" style={{background: v.background}}>{v.text}</p>
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default LabelDropdown;
