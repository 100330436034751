import React, { useEffect, useState } from "react";
import "./BroadcastMessageContactsPreview.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiEye, FiX } from "react-icons/fi";
import Pagination from "../../../../../reuseableComponent/pagination/Pagination";

let BroadcastMessageContactsPreview = (props) => {
    let {
        isOpen,
        toggle,
        data
    } = props;

    let [page, setPage] = useState(1);

    let handleChangePage = (e) => {
        setPage(e);
    }

    useEffect(() => {
        setPage(1);
    }, [isOpen])

    useEffect(() => {
        let el = document.querySelectorAll(".modal-preview-contacts-table-wrapper");

        if(el.length > 0) {
            el[0].scrollTop = 0;
        }
    }, [page])

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-preview-contacts">
            <ModalBody>
                <p className="modal-preview-contacts-title">
                    <b><FiEye /> Preview Data</b>
                </p>

                <FiX onClick={toggle} className="modal-preview-contacts-close"/>

                <div className="modal-preview-contacts-table-wrapper">
                    <table>
                        <thead>
                            <tr >
                                {data.header.map((v, i) => {
                                    return (
                                        <td key={`theader-${i}`}>
                                            <b>{v.label}</b>
                                        </td>
                                    )
                                })}
                            </tr>
                        </thead>

                        <tbody>
                            {data.body.map((v, i) => {
                                return (
                                    <tr key={`tbody-tr-${i}`}>
                                        {v.map((_v, _i) => {
                                            return (
                                                (page === 1 ?
                                                    ((i < (page * 10)) ?
                                                        <td key={`tbody-td-${i}-${_i}`}>
                                                            {_v}
                                                        </td>
                                                        :
                                                        ""
                                                    )
                                                    :
                                                    ((i >= (page - 1) * 10) && (i < (page * 10)) ?
                                                        <td key={`tbody-td-${i}-${_i}`}>
                                                            {_v}
                                                        </td>
                                                        :
                                                        ""
                                                    )
                                                )
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                
                {data.body.length > 0 &&
                    <Pagination
                        total={data.body.length}
                        page={page}
                        listPerPage={10}
                        step={1}
                        onChangePage={handleChangePage}
                    />
                }
            </ModalBody>
        </Modal>
    )
}

export default BroadcastMessageContactsPreview;