import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./MessageTemplate.scss";
import { Tooltip } from "reactstrap";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";
// import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
// import Pagination from "../../../../reuseableComponent/pagination/Pagination";
import IconHelpCircle from "../../../../../assets/img/chatroom/user-info-tab/icon-help-circle.svg";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { FiEye } from "react-icons/fi";
import { doToast } from "../../../../../helper/HelperGeneral";

let MessageTemplate = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [showTooltip, setShowTooltip] = useState(false);

  let toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetTemplateData, setIsLoadingGetTemplateData] = useState(
    false
  );

  let checkID = (match) => {
    let { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  // let [pageDisplay, setPageDisplay] = useState(1);
  // let [listPage, setListPage] = useState(20);
  // let [listPageVal, setListPageVal] = useState({
  //   label: "20",
  //   value: 20,
  // });

  // let listPageOption = [
  //   {
  //     label: "20",
  //     value: 20,
  //   },
  //   {
  //     label: "50",
  //     value: 50,
  //   },
  //   {
  //     label: "100",
  //     value: 100,
  //   },
  // ];

  let [messageTemplates, setMessageTemplates] = useState([]);

  // let onSelectListPage = (val) => {
  //   setListPageVal(val);
  //   setListPage(val.value);
  //   setPageDisplay(1);
  // };

  // let handleChangePage = (page) => {
  //   setPageDisplay(page);
  // };

  let onChangeStatus = (e, item) => {
    let messages = messageTemplates;
    let itemIndex = messages.findIndex((obj) => obj.id === item.id);
    messages[itemIndex].isEnabled = !messages[itemIndex].isEnabled;
    setMessageTemplates([...messages]);
    let data = {
      channelID: Number(match.params.id),
      templateID: Number(item.id),
      isEnabled: e.target.checked,
    };
    IntegrationWhatsappBusinessServices.editTemplateStatus(
      orgID,
      data,
      (response) => {
        if (response.dataResult.error.message) {
          messages[itemIndex].isEnabled = !messages[itemIndex].isEnabled;
          setMessageTemplates([...messages]);
          doToast(response.dataResult.error.message, "fail");
        }
      }
    );
  };

  useEffect(() => {
    doMount();
    // let data = [
    //   {
    //     "id": 1,
    //     "name": "greetings_v1",
    //     "categoryCode": "ISSUE_RESOLUTION",
    //     "categoryName": "Issue Resolution",
    //     "templateType": "text",
    //     "isEnabled": true,
    //     "createdTime": 1611496973940,
    //     "updatedTime": 0,
    //     "deletedTime": 0
    //   }
    // ];
    // setMessageTemplates(data);
  }, []);

  useEffect(() => {
    if (orgID) {
      setIsLoadingGetTemplateData(true);
      let data = {
        channelID: Number(match.params.id),
      };
      IntegrationWhatsappBusinessServices.getTemplateList(
        orgID,
        data,
        (response) => {
          let _retrieveDataError = { ...retrieveDataError };

          if (response.dataResult.error.message === "") {
            setMessageTemplates(response.dataResult.data.templates);
          } else {
            _retrieveDataError.message = response.dataResult.error.message;
            _retrieveDataError.code = response.dataResult.error.code;
            setRetrieveDataError(_retrieveDataError);
          }
          setIsLoadingGetTemplateData(false);
        }
      );
    }
  }, [orgID]);

  return (
    <div className="message-template-whatsapp sectionWrap">
      {/* <SectionWrap {...props} orgID={orgID} withHeader withSideMenu> */}
      <div className="message-template-whatsapp-top">
        <b>Message Templates</b>
      </div>
      {/* </SectionWrap> */}
      {isLoadingGetTemplateData || retrieveDataError.code ? (
        <div className="message-template-whatsapp-loading-wrapper">
          <RetrieveDataLoading
            isLoading={isLoadingGetTemplateData}
            errorMessage={retrieveDataError.message}
          />
        </div>
      ) : (
        <div className="message-template-table-wrapper">
          <div className="custom-table">
            <table className="custom-table-content table-message-template-list">
              <thead>
                <tr>
                  <td className="col_td_left">Name</td>
                  <td className="col_td_center">Template Type</td>
                  <td className="col_td_center">
                    Status
                    <img
                      src={IconHelpCircle}
                      className="tooltip-icon"
                      id="tooltip-status-message-template"
                      alt=""
                    />
                    <Tooltip
                      className="tooltip-content"
                      placement="bottom"
                      isOpen={showTooltip}
                      target="tooltip-status-message-template"
                      toggle={toggleTooltip}
                    >
                      Shows if you can use the message or not (you can enable or
                      disable).
                    </Tooltip>
                  </td>
                  <td>
                    {/* <CustomSelectOption
                      optionListProps={listPageOption}
                      valueDropdownProps={listPageVal}
                      onClickDropDownListOptionProps={onSelectListPage}
                    /> */}
                  </td>
                </tr>
              </thead>
              <tbody>
                {messageTemplates.length > 0 ? (
                  messageTemplates.map((item, idx) => {
                    return (
                      <tr key={`cost-${idx}`}>
                        <td className="">
                          <p className="">{item.name}</p>
                        </td>
                        <td className="">
                          <p>
                            {item.templateType.charAt(0).toUpperCase() +
                              item.templateType.slice(1)}
                          </p>
                        </td>
                        <td className="">
                          <label className="custom-switch-wrapper">
                            <input
                              type="checkbox"
                              onChange={(e) => onChangeStatus(e, item)}
                              checked={item.isEnabled}
                            />
                            <span className="custom-switch round"></span>
                          </label>
                          <b>{item.isEnabled ? "Enabled" : "Disabled"}</b>
                        </td>
                        <td className="col-view">
                          <NavLink to={`/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}/message-template/${item.id}`}
                          >
                            <FiEye />
                            View
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="no-data-row">
                    <td
                      colSpan="8"
                      className="no-data-column"
                      style={{ textAlign: "center" }}
                    >
                      <b className="no-result-found">
                        No message template found.
                      </b>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* <div className="pagination-wrapper">
            {messageTemplates.length > 0 && (
              <Pagination
                total={messageTemplates.length}
                page={pageDisplay}
                listPerPage={listPage}
                step={1}
                onChangePage={handleChangePage}
              ></Pagination>
            )}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default MessageTemplate;
