import React from "react";
import "./ExtraHeader.scss";

let ExtraHeader = ({title, sub}) => {
    return (
        <div className="extra-header-wrapper">
            <b className="extra-header-title">
                {title}  
            </b>

            <p className="extra-header-sub">
                {sub}  
            </p>
        </div>
    )
}

export default ExtraHeader;