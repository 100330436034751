import React, { useState, useEffect } from 'react';
import './DetailContact.scss';
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from '../../reuseableComponent/BackButton/BackButton';
import PopupRemove from '../../reuseableComponent/popupRemove/PopupRemove';
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import { FiUser, FiEdit, FiEdit3, FiAtSign, FiPhone, FiCalendar, FiGlobe, FiCircle, FiCheckCircle, FiEye, FiBriefcase, FiList } from 'react-icons/fi'
import ServiceOrganization from '../../../services/organization/ServiceOrganization'
import { checkID, doToast } from '../../../helper/HelperGeneral';
import UserContactServices from '../../../services/newServices/UserContactServices'
import HelperDate from '../../../helper/HelperDate'
import HelperInput from '../../../helper/HelperInput'
import iconWhatsapp from '../../../assets/img/user-contact/icon-whatsapp.svg'
import iconWhatsappBa from '../../../assets/img/user-contact/icon-whatsapp-ba.svg'
import iconFacebook from '../../../assets/img/user-contact/icon-facebook.svg'
import iconGoogle from '../../../assets/img/user-contact/icon-google.svg'
import iconGoogleProfile from '../../../assets/img/user-contact/icon-google-profile.svg'
import iconTelegram from '../../../assets/img/user-contact/icon-telegram.svg'
import iconLauncher from '../../../assets/img/user-contact/icon-launcher.svg'
import iconBroadcast from '../../../assets/img/user-contact/icon-broadcast.svg'
import iconTwitter from '../../../assets/img/user-contact/icon-twitter.svg'
import iconLine from '../../../assets/img/user-contact/icon-line.svg'
import iconInstagram from '../../../assets/img/user-contact/icon-instagram.svg'
import iconUser from '../../../assets/img/user-contact/icon-user-plus.svg'
import iconUsers from '../../../assets/img/fi-user-circle.svg'
import iconTokopedia from '../../../assets/img/user-contact/icon-tokopedia.svg'
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import ModalChooseChannel from '../../roomList/roomListTab/startConversation/modalChooseChannel/ModalChooseChannel';
import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption"
import Loading from '../../reuseableComponent/loading/Loading';
import Pagination from '../../reuseableComponent/pagination/Pagination';
import DetailHistory from "./detailHistory/DetailHistory";
import { TAPLIVE_MEDIUM_LOGO } from '../../../constants/taplive';
import { FIELD_TYPE_DATE, FIELD_TYPE_MULTIPLE_SELECT } from '../../setup/contactInfoCustomField/ContactInfoCustomField';

let DetailContact = (props) => {
  let listPageOption = [
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
  ];

  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [isShowModalDeleteContact, setIsShowModalDeleteContact] = useState(false)
  let [
    waitForResponseDeleteContact,
    setWaitForResponseDeleteContact,
  ] = useState(false);
  let [isLoadingUserContact, setIsLoadingUserContact] = useState(true)
  let [isLoadingCaseHistory, setIsLoadingCaseHistory] = useState(true)
  let [dataUserContact, setDataUserContact] = useState({})
  let [showTopic, setShowTopic] = useState(false)
  let [caseHistory, setCaseHistory] = useState([]);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [showModalNewMessage, setShowModalNewMessage] = useState(false)

  let [listPageVal, setListPageVal] = useState({
    label: "20",
    value: 20,
  })
  
  let [page, setPage] = useState(1);
  let [showDetailHistory, setShowDetailHistory] = useState(false)
  let [valCaseDetail, setValCaseDetail] = useState(false)

  let toggleModalNewMessage = () => {
    setShowModalNewMessage(!showModalNewMessage);
  }

  let toggleDetailHistory = () => {
    setShowDetailHistory(!showDetailHistory);
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getUserContactDetail(paramID, match.params.id);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  let getUserContactDetail = (orgID, userID) => {
    setIsLoadingUserContact(true);

    UserContactServices.getContactDetail(orgID, userID, (response) => {
      let _retriveDataError = { ...retriveDataError };
      if (response.dataResult.status === 200) {
        setShowTopic(response.dataResult.data.showAssociatedTopics);
        setDataUserContact(response.dataResult.data.user);
        getCaseHistory(orgID, userID);
      }
      else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingUserContact(false);
    });
  };

  let getCaseHistory = (orgID, userID, _page = false, _pageSize = false) => {
    setIsLoadingCaseHistory(true);

    let data = {
      "userID": userID,
      "pageNumber": _page ? _page : page,
      "pageSize": _pageSize ? _pageSize : listPageVal.value
    };

    UserContactServices.getCaseHistory(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };
      if (response.dataResult.status === 200) {
        setCaseHistory(response.dataResult.data);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }
      setIsLoadingCaseHistory(false);
    });
  };

  let back = () => history.push({
    pathname: `/o/${orgID}/operational/user-contact`,
    state: {
      previousPage: {
        pageDisplay: location.state ? location.state.pageDisplay : 1,
        listPage: location.state ? location.state.listPage : 20,
        submitQuery: location.state ? location.state.submitQuery : ""
      }
    }
  });

  let editContact = () => history.push({
    pathname: `/o/${orgID}/operational/user-contact/${match.params.id}/edit`,
    state: {
      pageDisplay: location.state ? location.state.pageDisplay : 1,
      listPage: location.state ? location.state.listPage : 20,
      submitQuery: location.state ? location.state.submitQuery : ""
    }
  });

  let toggleModalDeleteContact = () => {
    setIsShowModalDeleteContact(!isShowModalDeleteContact);
  };

  let deleteContact = () => {
    setWaitForResponseDeleteContact(true);
    toggleModalDeleteContact()
    setWaitForResponseDeleteContact(false);
  }

  let source = (src) => {
    switch (src) {
      case 'gb_profile':
        return {
          text: 'Google Business Profile',
          icon: iconGoogleProfile
        }
      case 'google_business':
        return {
          text: 'Google Business Messages',
          icon: iconGoogle
        }
      case 'customer':
        return {
          text: 'Customer',
          icon: iconUser
        }
      case 'facebook': {
        return {
          text: 'Facebook Messenger',
          icon: iconFacebook
        }
      }
      case 'launcher': {
        return {
          text: 'Live Chat',
          icon: iconLauncher
        }
      }
      case 'line': {
        return {
          text: 'LINE',
          icon: iconLine
        }
      }
      case 'telegram': {
        return {
          text: 'Telegram',
          icon: iconTelegram
        }
      }
      case 'twitter': {
        return {
          text: 'Twitter DM',
          icon: iconTwitter
        }
      }
      case 'instagram': {
        return {
          text: 'Instagram DM',
          icon: iconInstagram
        }
      }
      case 'whatsapp': {
        return {
          text: 'WhatsApp SME',
          icon: iconWhatsapp
        }
      }
      case 'whatsappba': {
        return {
          text: 'WhatsApp Business API',
          icon: iconWhatsappBa
        }
      }
      case 'manual': {
        return {
          text: 'User Added',
          icon: iconUser
        }
      }
      case 'manual_import': {
        return {
          text: 'Manual Import',
          icon: iconUser
        }
      }
      case 'broadcast': {
        return {
          text: 'Broadcast',
          icon: iconBroadcast
        }
      }
      case 'integration_api': {
        return {
          text: 'Integration API',
          icon: iconUser
        }
      }
      case 'integrationAPI': {
        return {
          text: 'Integration API',
          icon: iconUser
        }
      }
      case 'inbox_new_chat': {
        return {
          text: 'Inbox - New Chat',
          icon: iconUser
        }
      }
      case 'tokopedia': {
        return {
          text: 'Tokopedia',
          icon: iconTokopedia
        }
      }
      default: {
        return {
          text: 'User Added',
          icon: iconUser
        }
      }
    }
  }
  
  let onClickContinueAction = (medium) => {
    // props.history.push({
    //   pathname: `/o/${orgID}/inbox`,
    //   state: {
    //     startConversation: {
    //       medium: medium,
    //       recipient: {
    //         "country": {
    //             "id": "0",
    //             "countryCodeNumber": "62",
    //             "countryCode": "IDN",
    //             "countryName": "Indonesia",
    //             "countryFlag": "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
    //         },
    //         "number": "",
    //         "contact": {
    //             "name": dataUserContact.fullName,
    //             "number": "+"+dataUserContact.phone
    //         }
    //       }
    //     }
    //   }
    // })

    localStorage.setItem(
      "nm",
      JSON.stringify(
        {
          medium: medium,
          recipient: {
            // "country": {
            //     "id": "0",
            //     "countryCodeNumber": "62",
            //     "countryCode": "IDN",
            //     "countryName": "Indonesia",
            //     "countryFlag": "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
            // },
            "number": "",
            "contact": {
                "name": dataUserContact.fullName,
                "number": "+"+dataUserContact.phone
            }
          }
        }
      )
    )

    window.open(
      `${window.location.origin}/o/${orgID}/inbox`,
      `_blank`
    )

    toggleModalNewMessage();
  }

  let onSelectListPage = (val) => {
    setPage(1);
    setListPageVal(val);

    getCaseHistory(orgID, match.params.id, 1, val.value);
  };

  let handleChangePage = (val) => {
    setPage(val);
    getCaseHistory(orgID, match.params.id, val);
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="sectionWrap integration-main-container detail-contact-container text-left">
        {
          (isLoadingUserContact || retriveDataError.code) ?
            <RetrieveDataLoading
              isLoading={isLoadingUserContact}
              errorMessage={retriveDataError.message}
            />
            :
            <>
              <BackButton
                text={`Back to Contacts`}
                onClick={() => back()}
              />

              <ModalChooseChannel
                isShowModalChooseChannelProps={showModalNewMessage}
                toggleModalChooseChannelProps={toggleModalNewMessage}
                onClickContinue={onClickContinueAction}
              />
              
              <PopupRemove
                removeTitle={`Delete x User Contact?`}
                removeDescription={`Are you sure you want to delete x user contacts?`}
                onClickCancel={toggleModalDeleteContact}
                onClickRemove={deleteContact}
                waitForResponse={waitForResponseDeleteContact}
                showModalProps={isShowModalDeleteContact}
                submitText="Delete"
                colorButton="red-button"
              />

              <DetailHistory 
                toggle={toggleDetailHistory}
                isShow={showDetailHistory}
                data={valCaseDetail}
                orgID={orgID}
              />

              <div className="detail-contact-box">
                <div className="detail-contact-box-top">
                  <div className="title-wrapper">
                    <FiUser />
                    <b>Contact Info Details</b>
                  </div>
                  <div className="button-wrapper">
                    {dataUserContact.phone !== "" &&
                      <ButtonWithLoadingOrIcon
                        className="no-fill-button-no-border main-button-40 button-new-message"
                        text="New Message"
                        position="left"
                        onClickAction={toggleModalNewMessage}
                        icon={{
                          type: "svg",
                          src: FiEdit3
                        }}
                      />
                    }

                    <ButtonWithLoadingOrIcon
                      className="no-fill-button main-button-40"
                      text="Edit"
                      position="left"
                      onClickAction={editContact}
                      icon={{
                        type: "svg",
                        src: FiEdit
                      }}
                    />
                  </div>
                </div>
                
                <div className="detail-contact-box-form">
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <FiUser />
                      <b>Full Name</b>
                    </div>
                    <p>{dataUserContact.fullName === "" ? <span className="grey-font">Unavailable</span> : dataUserContact.fullName}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <FiUser />
                      <b>Alias</b>
                    </div>
                    <p>{dataUserContact.alias ? dataUserContact.alias : <span className="grey-font">Unavailable</span>}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <FiBriefcase />
                      <b>Company</b>
                    </div>
                    <p>{dataUserContact.companyName ? dataUserContact.companyName : <span className="grey-font">Unavailable</span>}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <img alt="" src={iconUsers} />
                      <b>Job Title</b>
                    </div>
                    <p>{dataUserContact.jobTitle ? dataUserContact.jobTitle : <span className="grey-font">Unavailable</span>}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <FiAtSign />
                      <b>Email Address</b>
                    </div>
                    <p>{dataUserContact.email ? dataUserContact.email : <span className="grey-font">Unavailable</span>}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <FiPhone />
                      <b>Phone Number</b>
                    </div>

                    {dataUserContact.maskedPhone ?
                      <p>{dataUserContact.maskedPhone ? `+${HelperInput.phoneBeautify(dataUserContact.maskedPhone)}` : <span className="grey-font">Unavailable</span>}</p>
                      :
                      <p>{dataUserContact.phone ? `+${HelperInput.phoneBeautify(dataUserContact.phone)}` : <span className="grey-font">Unavailable</span>}</p>
                    }
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <FiCalendar />
                      <b>Date Created</b>
                    </div>
                    <p>{HelperDate.formatToString(new Date(dataUserContact.createdTime), 'd MMM yyyy')}</p>
                  </div>
                  <div className="contact-attribute-wrapper">
                    <div className="contact-title-wrapper">
                      <FiGlobe />
                      <b>Origin</b>
                    </div>
                    <p className='contact-origin'>
                      <img src={source(dataUserContact.source).icon} alt="" />
                      {source(dataUserContact.source).text}
                    </p>
                  </div>
                    
                  {showTopic &&
                    <div className="contact-attribute-wrapper">
                      <div className="contact-title-wrapper">
                        <FiList />
                        <b>Associated Topics</b>
                      </div>
                      <div className='ass-topic-wrapper'>
                        {dataUserContact.associatedTopicNames.length === 0 ?
                          <span className="grey-font">Unavailable</span>
                          :
                          (dataUserContact.associatedTopicNames.map((v, i) => {
                            return (
                              <div className='topic-list-content' key={`ass-topic-${i}`}>
                                <b>{v}</b>
                              </div>
                            )
                          }))
                        }
                      </div>
                    </div>
                  }
                  {
                    dataUserContact.customFields &&
                    dataUserContact.customFields.length > 0 &&
                    <>
                      <div className="contact-custom-field-separator" />
                      {
                        dataUserContact.customFields.map((customField, index) => {
                          let multipleSelectValue = "-";
                          if (customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT && customField.value) {
                            const multipleSelectValuesArray = JSON.parse(customField.value);
                            if (multipleSelectValuesArray && multipleSelectValuesArray.length > 0) {
                              multipleSelectValue = multipleSelectValuesArray.map((e, i) => (e)).join(", ");
                            }
                          }
                          return (
                            <div className="contact-attribute-wrapper" key={`custom-field-${index}`}>
                              <div className="contact-title-wrapper">
                                <b>{customField.fieldName}</b>
                              </div>
                              <p>
                                {
                                  customField.value
                                  ?
                                  customField.fieldType === FIELD_TYPE_DATE
                                  ?
                                  HelperDate.formatToString(new Date(Date.parse(customField.value, "yyyy-MM-dd")), "d MMM yyyy")
                                  :
                                  customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT
                                  ?
                                  multipleSelectValue
                                  :
                                  customField.value
                                  :
                                  "-"
                                }
                              </p>
                            </div>
                          )
                        })
                      }
                    </>
                  }
                </div>
              </div>

              <div className="detail-contact-box detail-contact-box-bottom">
                <div className="detail-contact-box-top">
                  <div className="title-wrapper">
                    <b>Case Activites</b>
                  </div>
                </div>
                
                {isLoadingCaseHistory ?
                  <Loading />
                  :
                  <div className="detail-contact-table-wrapper">
                    <table className="custom-table-content table-detail-contact">
                      <thead>
                        <tr>
                          <td>Case ID</td>
                          <td>Topic</td>
                          <td>Channel</td>
                          <td>Status</td>
                          <td>Created Time</td>
                          <td>
                            <CustomSelectOption
                              optionListProps={listPageOption}
                              valueDropdownProps={listPageVal}
                              onClickDropDownListOptionProps={onSelectListPage}
                            />
                          </td>
                        </tr>
                      </thead>

                      {caseHistory.cases.length < 1 ? 
                        <tbody>
                          <tr>
                            <td colSpan="6" className="no-data-column">
                              <b className="no-result-found">
                                No Data found.
                              </b>
                            </td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                          {caseHistory.cases.map((value, index) => {
                            return (
                              <tr key={`case-history-${index}`}>
                                <td>#{value.stringID}</td>
                                <td>{value.topicName}</td>
                                <td className='col-channel'>
                                  <img src={TAPLIVE_MEDIUM_LOGO[value.medium]} alt="" />
                                  {value.mediumChannelName}
                                </td>
                                <td>
                                  {value.closedTime === 0 ? 
                                    <div className='status-badge badge-open'>
                                      <FiCircle />
                                      <b>Open</b>
                                    </div>
                                    : 
                                    <div className='status-badge badge-resolved'>
                                      <FiCheckCircle />
                                      <b>Resolved</b>
                                    </div>
                                  }
                                </td>
                                <td>{HelperDate.formatToString(new Date(value.createdTime), "dd/MM/yyyy HH:mm")}</td>
                                <td onClick={() => {
                                  toggleDetailHistory();
                                  setValCaseDetail(value);
                                }}>
                                  <FiEye />
                                  View Details
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      }
                    </table>
                    
                    {caseHistory.totalItems > 0 &&
                      <Pagination
                        total={caseHistory.totalItems}
                        page={page}
                        listPerPage={listPageVal.value}
                        step={1}
                        onChangePage={handleChangePage}
                      />
                    }
                  </div>
                }
              </div>
            </>
        }
      </div>
    </SectionWrap>
  );
};

export default DetailContact;