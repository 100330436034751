import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationTelegramServices = {
  getList(idOrTime, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LIST, {}, headers, callback);
  },
  getDetail(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id),
    };

    BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);
  },

  editTopic(idOrTime, id, topicID, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id),
      topicIDs: topicID,
    };

    BaseService.doPostAccessToken(API.EDIT_TOPIC, data, headers, callback);
  },
  
  editBotToken(idOrTime, id, botToken, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id),
      botToken,
    };

    BaseService.doPostAccessToken(API.EDIT_BOT_TOKEN, data, headers, callback);
  },
  validateBotToken(idOrTime, botToken, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      botToken,
    };

    BaseService.doPostAccessToken(
      API.VALIDATE_BOT_TOKEN,
      data,
      headers,
      callback
    );
  },
  create(idOrTime, topicID, botToken, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      topicId: parseInt(topicID),
      botToken,
    };

    BaseService.doPostAccessToken(API.CREATE_CHANNEL, data, headers, callback);
  },
  disconnect(idOrTime, id, createdTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id),
      createdTime: parseInt(createdTime),
    };

    BaseService.doPostAccessToken(
      API.DISCONNECT_CHANNEL,
      data,
      headers,
      callback
    );
  },
  delete(idOrTime, id, createdTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id),
      createdTime: parseInt(createdTime),
    };

    BaseService.doPostAccessToken(API.DELETE_CHANNEL, data, headers, callback);
  },
  getChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHANNEL_LINK, data, headers, callback);
  },
  setChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHANNEL_LINK, data, headers, callback);
  },
  deleteChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.DELETE_CHANNEL_LINK, data, headers, callback);
  },
  getChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHAT_BOT, data, headers, callback);
  },
  setChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHAT_BOT, data, headers, callback);
  },
  removeChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REMOVE_CHAT_BOT, data, headers, callback);
  },
  getWelcomeMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_WELCOME, data, headers, callback);
  },
  getAwayMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };
    
    BaseService.doPostAccessToken(API.GET_AWAY, data, headers, callback);
  },
  getClosingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_CLOSING, data, headers, callback);
  },
  getRatingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_USER_RATING, data, headers, callback);
  },
  setWelcomeMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);
  },
  setAwayMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);
  },
  setClosingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);
  },
  setRatingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_USER_RATING, data, headers, callback);
  },
};

const API = {
  GET_LIST: '/organization/channel/telegram/get_list',
  GET_DETAIL: '/organization/channel/telegram/get_details',
  EDIT_TOPIC: '/organization/channel/telegram/edit_topic',
  EDIT_BOT_TOKEN: '/organization/channel/telegram/edit_bot_token',
  VALIDATE_BOT_TOKEN: '/organization/channel/telegram/validate_bot_token',
  CREATE_CHANNEL: '/organization/channel/telegram/create',
  DISCONNECT_CHANNEL: '/organization/channel/telegram/disconnect_bot_token',
  DELETE_CHANNEL: '/organization/channel/telegram/delete',
  GET_CHANNEL_LINK: '/organization/channel/telegram/get_channel_link',
  SET_CHANNEL_LINK: '/organization/channel/telegram/set_channel_link',
  DELETE_CHANNEL_LINK: '/organization/channel/telegram/delete_channel_link',
  GET_CHAT_BOT: '/organization/channel/telegram/get_chatbot',
  SET_CHAT_BOT: '/organization/channel/telegram/set_chatbot',
  REMOVE_CHAT_BOT: '/organization/channel/telegram/remove_chatbot',
  GET_WELCOME: "/organization/channel/telegram/get_welcome_message",
  SET_WELCOME: "/organization/channel/telegram/set_welcome_message",
  GET_AWAY: "/organization/channel/telegram/get_away_message",
  SET_AWAY: "/organization/channel/telegram/set_away_message",
  GET_CLOSING: "/organization/channel/telegram/get_closing_message",
  SET_CLOSING: "/organization/channel/telegram/set_closing_message",
  GET_USER_RATING: "/organization/channel/telegram/get_user_rating",
  SET_USER_RATING: "/organization/channel/telegram/set_user_rating"
};

export default IntegrationTelegramServices;
