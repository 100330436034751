import { withFormik } from "formik";
import topicServices from "../../services/newServices/TopicServices";
import TopicsAddView from "./TopicsAddView";
// import { FaCheck } from "react-icons/fa";
import { doToast } from "../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const FormTopicsAdd = withFormik({
  mapPropsToValues: () => ({
    name: "",
    apiLoading: false,
    respAPI: false,
    errAPI: false
  }),
  validate: values => {
    const errors = {};
    const { name } = values;
    // if (!name) errors.name = "Please fill topic's name";

    // if (name.length < 3) errors.name = "Fill complete name";
    // else if (!name[0].match(/^([^0-9]*)$/))
    //   errors.name = "No first number allowed";
    if (!name[0].match(/^([^0-9]*)$/)) {
      errors.name = "No first number allowed";
    }
    
    return errors;
  },
  handleSubmit: async (values, { resetForm, setFieldValue, props }) => {
    // const { orgID, success, toggle, parentProps } = props;
    const { orgID, parentProps } = props;

    setFieldValue("apiLoading", "true");
    setFieldValue("respAPI", false);
    setFieldValue("errAPI", false);

    const waitAPI = setTimeout(() => {
      setFieldValue("apiLoading", false);
      doToast("Failed to add new topic");
    }, 5500);

    const doSucces = (id) => {
      resetForm();
      // toggle();
      parentProps.history.push(`/o/${orgID}/organization/topics/details/${id}`);

      // success(
      //   <React.Fragment>
      //     <FaCheck />
      //     &nbsp; New Topic Added
      //   </React.Fragment>
      // );
    };

    // const doError = error => setFieldValue("errAPI", error.message);
    const { name } = values;
    
    topicServices.addTopic({ name }, orgID, (response) => {
      setFieldValue("respAPI", response.dataResult.status);
      
      if (response.dataResult.status === 200) {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
          mixpanel.track(
            "[Action] Add Topic",
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : orgID.split("-")[0]
            }
          );
        }
        
        if(response.dataResult.data.success) {
          doSucces(response.dataResult.data.topic.id);
        }else {
          doToast(response.dataResult.data.message, "fail");
          setFieldValue("apiLoading", false);
        }
      }else {
        doToast(response.dataResult.error.message, "fail");
        setFieldValue("apiLoading", false);
      }
      
      clearTimeout(waitAPI);
    });
  }
})(TopicsAddView);

export default FormTopicsAdd;
