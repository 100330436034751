import BaseService from "../BaseService";
import { API_KEY } from "../../config";

class OnboardingServices {
    postUpdateStatus(orgID, param, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };
        
        BaseService.doPostAccessToken(API.UPDATE_STATUS, param, headers, callback);
    }

    postGetStatusList(orgID, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };
        
        BaseService.doPostAccessToken(API.GET_STATUS, {}, headers, callback);
    }
}

const API = {
    UPDATE_STATUS: "/organization/onboarding/update_status",
    GET_STATUS: "/organization/onboarding/get_status_list",
}

export default new OnboardingServices()