import React, { useEffect, useState } from "react";
import "./IntegrationChatbotEdit.scss";
import { FiInfo } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { doToast, checkID, scrollToClass } from "../../../../helper/HelperGeneral";
import { CHATBOT_TYPE } from "../../../../constants/chatbot";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
// import FaqServices from "../../../../services/newServices/FaqServices";
import ChatbotServices from "../../../../services/newServices/ChatbotService";

const IntegrationChatbotEdit = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingGetDetail, setIsLoadingGetDetail] = useState(false);
    let [retrieveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false,
    });
    let [chatbotNameVal, setChatbotNameVal] = useState("");
    let [chatbotURLVal, setChatbotURLVal] = useState("");
    let [chatbotPathVal, setChatbotPathVal] = useState("");
    let [chatbotTypeVal, setChatbotTypeVal] = useState("");
    let [chatbotNameValOrigin, setChatbotNameValOrigin] = useState("");
    let [chatbotURLValOrigin, setChatbotURLValOrigin] = useState("");
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        name: "",
        type: "",
        path: "",
        url: ""
    }); 

    useEffect(() => {
        if (props.history.location.state) {
            if(props.history.location.state.whatsappVal) {
                setChatbotNameVal(props.history.location.state.whatsappVal.name)
                setChatbotPathVal(props.history.location.state.whatsappVal.path)
            }
        }
    }, [props.history])

    const back = () => history.push(`/o/${orgID}/integration/chatbots/detail/${props.match.params.id}`);

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getDetail = (orgID) => {
        let _retrieveDataError = { ...retrieveDataError };
        setIsLoadingGetDetail(true);
        
        let data = {
          id: Number(props.match.params.id)
        }
        
        ChatbotServices.detailChatbot(orgID, data, (response) => {
            if (response.dataResult.status === 200) {
                let item = response.dataResult.data.chatbot;
                setChatbotNameVal(item.name);
                setChatbotNameValOrigin(item.name);
                setChatbotTypeVal(item.typeName);

                if(item.type === "taptalk/scf") {
                    setChatbotPathVal(item.scf.scfPathTitle);
                }

                if(item.type === "custom") {
                    setChatbotURLVal(item.custom.chatbotURL);
                    setChatbotURLValOrigin(item.custom.chatbotURL);
                }
            } else {
                _retrieveDataError.message = response.dataResult.error.message;
                _retrieveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retrieveDataError);
                doToast(response.dataResult.error.message, 'fail');
            }
        
            setIsLoadingGetDetail(false);
        });
    };

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if(orgID) {
            getDetail(orgID);
        }
    }, [orgID])

    // let isFormValid = () => {
    //     let formValid = true;
    //     let _chatbotNameVal = chatbotNameVal;

    //     if(_chatbotNameVal === "") {
    //         formValid = false;
    //     }

    //     return formValid;
    // }

    // let isFormValidConfig = () => {
    //     let formValid = true;
    //     let _chatbotURLVal = chatbotURLVal;
    //     let _chatbotTypeVal = chatbotTypeVal;

    //     if(_chatbotTypeVal === "Custom") {
    //         if(_chatbotURLVal === "" || !HelperInput.validURL(_chatbotURLVal)) {
    //             formValid = false;
    //         }
    //     }

    //     return formValid;
    // }

    let onChangeChatbotName= (e) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.name = "";
        setErrorMessageData(_errorMessageData);
        setChatbotNameVal(e.target.value);
    }

    let onChangeChatbotURL= (e) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.url = "";
        setErrorMessageData(_errorMessageData);
        setChatbotURLVal(e.target.value);
    }

    let onSubmitAction = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = {...errorMessageData};

        if(chatbotNameVal === "") {
            _errorMessageData.name = "This field is required";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-name" }
        }
                                
        if(chatbotNameVal.length > 30) {
            _errorMessageData.name = "Characters exceed limit";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-name" }
        }

        if(chatbotTypeVal.value === "") {
            _errorMessageData.type = "Please select chatbot type";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-type" }
        }

        if(chatbotTypeVal.value === CHATBOT_TYPE[0].value) {
            if(chatbotPathVal.value === "") {
                _errorMessageData.path = "Please select qna path";
                errorCount = errorCount + 1;
                
                if(errorClass === "") { errorClass = "input-path" }
            }
        }

        if(chatbotTypeVal.value === CHATBOT_TYPE[1].value) {
            if(chatbotURLVal === "") {
                _errorMessageData.url = "This field is required";
                errorCount = errorCount + 1;
                
                if(errorClass === "") { errorClass = "input-url" }
            }
        }

        console.log(_errorMessageData)

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            setIsLoadingSubmit(true);

            if(props.match.params.type === "edit-detail") {
                let data = {
                    id: Number(props.match.params.id),
                    name: chatbotNameVal
                }

                ChatbotServices.editChatbot(props.match.params.orgID, data, (response) => {
                    let dataResult = response.dataResult;
                    
                    if(dataResult.error.message === "") {
                        if(dataResult.data.success) {   
                            setChatbotNameValOrigin(chatbotNameVal);
                            
                            setTimeout(() => {
                                props.history.push({
                                    pathname: `/o/${props.match.params.orgID}/integration/chatbots/detail/${props.match.params.id}`,
                                    state: props.location.state && props.location.state.pathname ?
                                        {
                                            accountDetail: props.location.state.accountDetail,
                                            chatbot: props.location.state.chatbot
                                        }
                                        :
                                        undefined
                                })
                            }, 3000)
        
                        }else {
                            setIsLoadingSubmit(false)
                        }
        
                        doToast(dataResult.data.message);
                    }else {
                        doToast(dataResult.error.message, "fail");
                        setIsLoadingSubmit(false);
                    }
                })
            }else {
                let data = {
                    id: Number(props.match.params.id),
                    chatbotURL: chatbotURLVal
                }

                ChatbotServices.editChatbotCustom(props.match.params.orgID, data, (response) => {
                    let dataResult = response.dataResult;
                    
                    if(dataResult.error.message === "") {
                        if(dataResult.data.success) {                        
                            setTimeout(() => {
                                props.history.push({
                                    pathname: `/o/${props.match.params.orgID}/integration/chatbots/detail/${props.match.params.id}`,
                                    state: props.location.state && props.location.state.pathname ?
                                        {
                                            accountDetail: props.location.state.accountDetail,
                                            chatbot: props.location.state.chatbot
                                        }
                                        :
                                        undefined
                                })
                            }, 3000)
        
                        }else {
                            setIsLoadingSubmit(false)
                        }
        
                        doToast(dataResult.data.message);
                    }else {
                        doToast(dataResult.error.message, "fail");
                        setIsLoadingSubmit(false);
                    }
                })
            }
        }
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={{
                    name: chatbotNameValOrigin,
                    // path: chatbotPathValOrigin,
                    // type: chatbotTypeValOrigin,
                    url: chatbotURLValOrigin
                }}
                dataAfterChange={{
                    name: chatbotNameVal,
                    // path: chatbotPathVal,
                    // type: chatbotTypeVal,
                    url: chatbotURLValOrigin
                }}
            >
                <div className="sectionWrap integration-main-container chatbot-edit-chatbot text-left">
                    {isLoadingGetDetail || retrieveDataError.code ? 
                        <div className="chatbot-detail-loading-wrapper">
                            <RetrieveDataLoading
                            isLoading={isLoadingGetDetail}
                            errorMessage={retrieveDataError.message}
                            />
                        </div>
                        :
                        <>
                            <BackButton text="Back to Chatbot" onClick={back} />

                            <div className="chatbot-edit-chatbot-box">
                                <div className="chatbot-edit-chatbot-box-top">
                                    <b>Chatbot{props.match.params.type === "edit-detail" ? " Details" : ""}</b>

                                    {props.match.params.type === "edit-detail" &&
                                        <>
                                            <p>
                                                Edit automated messages to answer repetitive questions from your customers.
                                            </p>

                                            {isLoadingSubmit ?
                                                <ButtonWithLoadingOrIcon
                                                    isLoading
                                                    loadingColor="gray"
                                                    isDisabled
                                                    position="left"
                                                    text="Save Changes"
                                                    className="main-button-40 orange-button"
                                                />
                                                :
                                                <ButtonWithLoadingOrIcon 
                                                    text="Save Changes"
                                                    className="main-button-40 orange-button"
                                                    onClickAction={onSubmitAction}
                                                    // isDisabled={!isFormValid()}
                                                />    
                                            }
                                        </>
                                    }
                                </div>

                                <div className="chatbot-edit-chatbot-box-form">
                                    <label>
                                        <b>Chatbot Name</b>
                                        {props.match.params.type === "edit-detail" ? <span className={errorMessageData.name === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{chatbotNameVal.length}/30</span> : ""}
                                    </label>
                                    {props.match.params.type === "edit-detail" ?
                                        <input className={`input-name ${errorMessageData.name !== "" ? "border-red" : ""}`} disabled={isLoadingSubmit} type="text" placeholder="Your chatbot name" onChange={(e) => {onChangeChatbotName(e)}} value={chatbotNameVal} />
                                        :
                                        <b className="chatbot-val">{chatbotNameVal}</b>
                                    }
                                    {errorMessageData.name !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.name}
                                            _className={"font-red"}
                                            icon={<FiInfo />}
                                        />
                                    }

                                    <label><b>Chatbot Type</b></label>
                                    {props.match.params.type === "edit-detail" ?
                                        <input type="text" placeholder="Your chatbot name" onChange={(e) => {onChangeChatbotName(e)}} value={chatbotTypeVal} disabled />
                                        :
                                        <b className="chatbot-val">{chatbotTypeVal}</b>
                                    }

                                    {chatbotTypeVal === CHATBOT_TYPE[0].label &&
                                        <>
                                            <label><b>QnA Path</b></label>
                                            <input type="text" placeholder="Your chatbot type" onChange={(e) => {onChangeChatbotName(e)}} value={chatbotPathVal} disabled />
                                        </>
                                    }

                                    {/* <>
                                        <label><b>Chatbot URL</b></label>
                                        <input type="text" placeholder="Insert URL" onChange={(e) => {onChangeChatbotURL(e)}} value={chatbotURLVal} />
                                    </> */}
                                </div>
                            </div>

                            {props.match.params.type === "edit-config" &&
                                <div className="chatbot-edit-chatbot-box">
                                    <div className="chatbot-edit-chatbot-box-top">
                                        <b>Chatbot Configs</b>

                                        {isLoadingSubmit ?
                                            <ButtonWithLoadingOrIcon
                                                isLoading
                                                loadingColor="gray"
                                                isDisabled
                                                position="left"
                                                text="Save Changes"
                                                className="main-button-40 orange-button"
                                            />
                                            :
                                            <ButtonWithLoadingOrIcon 
                                                text="Save Changes"
                                                className="main-button-40 orange-button"
                                                onClickAction={onSubmitAction}
                                                // isDisabled={!isFormValidConfig()}
                                            />    
                                        }
                                    </div>

                                    <div className="chatbot-edit-chatbot-box-form">
                                        <>
                                            <label><b>Chatbot URL</b></label>
                                            <input disabled={isLoadingSubmit} type="text" placeholder="Insert URL" onChange={(e) => {onChangeChatbotURL(e)}} value={chatbotURLVal} className={errorMessageData.url !== "" ? "border-red" : ""} />
                                            {errorMessageData.url !== "" &&
                                                <ErrorOrInfoComp 
                                                    icon={<FiInfo />}
                                                    text={errorMessageData.url}
                                                    _className={"red-text"}
                                                />
                                            }
                                        </>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationChatbotEdit;