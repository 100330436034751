const INITIAL_STATE = false;
  
let ReduxReducerToggleMineCase = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SET_TOGGLE_MINE_CASE':
        return action.toggleMineCase;
    case 'CLEAR_TOGGLE_MINE_CASE':
        return INITIAL_STATE;
    default:
        return state;
    }
};
  
export default ReduxReducerToggleMineCase;