import React from "react";
import "./IntegrationGoogleCreateGreeting.scss";
import { connect } from "react-redux";
import { FiArrowLeft, FiTrash2, FiPlus, FiInfo } from "react-icons/fi"; 
import { setCreateGoogle } from "../../../../../redux/actions/reduxActionCreateGoogle";
import HelperInput from "../../../../../helper/HelperInput";
import { scrollToClass } from "../../../../../helper/HelperGeneral";
import ErrorOrInfoComp from '../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";

let IntegrationGoogleCreateGreeting = (props) => {
    let onClickContinue = () => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);
        let errorCount = 0;
        let scrollToError = "";

        _parse.data.conversations.map((v, i) => {
            if(v.languageCode.value === "") {
                v.errorLanguageCode = "This field is required";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `language-cpde-${i}`}
            }

            if(v.welcomeMessage === "") {
                v.errorWelcomeMessage = "This field is required";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `welcome-message-${i}`}
            }

            if(v.welcomeMessage.length > 1000) {
                v.errorWelcomeMessage = "Characters exceed limit";
                errorCount = errorCount + 1;
                
                if(scrollToError === "") { scrollToError = `welcome-message-${i}`}
            }

            if(!HelperInput.validURL(v.privacyPolicyURL)) {
                v.errorPrivacyPolicyURL = "Invalid URL Format";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `privacy-url-${i}`}
            }

            if(v.privacyPolicyURL === "") {
                v.errorPrivacyPolicyURL = "This field is required";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `privacy-url-${i}`}
            }

            return null;
        })

        // if(props.createGoogle.data.topic.value === "") {
        //     setErrorTopic("Select your topic first.");
        // }else {
        //     _setActiveForm(1);
        // }
        if(errorCount === 0) {
            props._setActiveForm(3);
        }else {
            scrollToClass(`.${scrollToError}`);
            props.setCreateGoogle(_parse);
        }
    }

    const LANG = [
        {
            label: "English US",
            value: "en"
        },
        {
            label: "Indonesia",
            value: "id"
        }
    ]

    let onSelectListLang = (e, i) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        _parse.data.conversations[i].languageCode = e;
        _parse.data.conversations[i].errorLanguageCode = "";
        props.setCreateGoogle(_parse);
    }

    let onCheckLocale = (e, i) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        if(_parse.data.conversations.length > 1) {
            _parse.data.conversations.map((v) => {
                v.defaultLocale = false;
                return null;
            })

            _parse.data.conversations[i].defaultLocale = true;
        }
        // if(e.target.checked === false) {
        //     let count = 0;

        //     _parse.data.conversations.map((v) => {
        //         if(v.defaultLocale === true) {
        //             count = count + 1;
        //         }
        //     })
            
        //     if(count === 1) {
        //         _parse.data.conversations[0].defaultLocale = true;

        //         if(_parse.data.conversations.length === 2) {
        //             _parse.data.conversations[1].defaultLocale = false;
        //         }
        //     }else {
        //         _parse.data.conversations[i].defaultLocale = false;
        //     }
        // }else {
        //     _parse.data.conversations[i].defaultLocale = e.target.checked;
        // }

        props.setCreateGoogle(_parse);
    }

    let onClickAddMoreLang = () => {
        let data = {
            languageCode: {
                value: "",
                label: ""
            },
            errorLanguageCode: "",
            welcomeMessage: "",
            errorWelcomeMessage: "",
            privacyPolicyURL: "",
            errorPrivacyPolicyURL: "",
            defaultLocale: false
        }

        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        _parse.data.conversations.push(data);
        props.setCreateGoogle(_parse);
    }

    let onClickDelete = () => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        _parse.data.conversations.pop();
        
        _parse.data.conversations[0].defaultLocale = true;
        props.setCreateGoogle(_parse);
    }

    let filterLangList = (currentIndex) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);
        let _langList = LANG.slice();

        _langList.map((valLang, indexLang) => {
            _parse.data.conversations.map((valTemp, indexTemp) => {
                if(valLang.value === valTemp.languageCode.value && currentIndex !== indexTemp) {
                    _langList.splice(indexLang, 1);
                }
        
                return null;
            })
    
            return null;
        })
        
        return _langList;
      }

    let printGreeting = (v, i) => {
        return (
            <>  
                {i === 1 &&
                    <div className="border-top">
                        <p>
                            <FiPlus />
                            <b>Add Language</b>
                        </p>

                        <ButtonWithLoadingOrIcon 
                            className="red-button main-button-40"
                            icon={{
                                src: FiTrash2,
                                type: "svg"
                            }}
                            text="Delete"
                            onClickAction={() => onClickDelete()}
                            position="left"
                        />
                    </div>
                }

                <div className={`greeting-wrapper`}>
                    <p className="check-locale">
                        <div className="custom-checkbox">
                            <input type="checkbox" id={`check-locale-${i}`} checked={props.createGoogle.data.conversations[i].defaultLocale} onClick={(e) => onCheckLocale(e, i)} />
                            <label htmlFor={`check-locale-${i}`}>
                                Set as Default Locale
                            </label>
                        </div>

                    </p>

                    <label className={`language-code-${i}`}>
                        <b>Language</b>
                    </label>

                    <CustomSelectOption
                        optionListProps={filterLangList(i)}
                        valueDropdownProps={props.createGoogle.data.conversations[i].languageCode}
                        onClickDropDownListOptionProps={(e) => onSelectListLang(e, i)}
                        placeholderProps={"Select language"}
                        _className={props.createGoogle.data.conversations[i].errorLanguageCode === "" ? '' : 'error-select'}  
                    />

                    {props.createGoogle.data.conversations[i].errorLanguageCode !== "" &&
                        <ErrorOrInfoComp
                            text={props.createGoogle.data.conversations[i].errorLanguageCode}
                            _className={"font-red"}
                            icon={<FiInfo />}
                        />
                    }

                    <label className={`margin-top-24 welcome-message-${i}`}>
                        <b>Message</b>

                        <span className={`grey-font ${props.createGoogle.data.conversations[i].errorWelcomeMessage === "Characters exceed limit" ? "red-text" : ""}`}>
                            {props.createGoogle.data.conversations[i].welcomeMessage.length}/1000 characters
                        </span>
                    </label>

                    <textarea
                        placeholder="Type your message here"
                        rows={4}
                        value={props.createGoogle.data.conversations[i].welcomeMessage}
                        onChange={(e) => {
                            let _createGoogle = JSON.stringify(props.createGoogle);
                            let _parse = JSON.parse(_createGoogle);
                    
                            _parse.data.conversations[i].welcomeMessage = e.target.value;
                            _parse.data.conversations[i].errorWelcomeMessage = "";
                            props.setCreateGoogle(_parse);
                        }}
                        className={`${props.createGoogle.data.conversations[i].errorWelcomeMessage === "" ? "" : "border-red"}`}
                    />

                    {props.createGoogle.data.conversations[i].errorWelcomeMessage !== "" &&
                        <ErrorOrInfoComp
                            text={props.createGoogle.data.conversations[i].errorWelcomeMessage}
                            _className={"font-red"}
                            icon={<FiInfo />}
                        />
                    }

                    <label className={`margin-top-24 privacy-url-${i}`}>
                        <b>Privacy Policy URL</b>
                    </label>

                    <input
                        type="text"
                        placeholder="Type your privacy policy URL here"
                        value={props.createGoogle.data.conversations[i].privacyPolicyURL}
                        onChange={(e) => {
                            let _createGoogle = JSON.stringify(props.createGoogle);
                            let _parse = JSON.parse(_createGoogle);
                    
                            _parse.data.conversations[i].privacyPolicyURL = e.target.value;
                            _parse.data.conversations[i].errorPrivacyPolicyURL = "";
                            props.setCreateGoogle(_parse);
                        }}
                        className={`${props.createGoogle.data.conversations[i].errorPrivacyPolicyURL === "" ? "" : "border-red"}`}
                    />

                    {props.createGoogle.data.conversations[i].errorPrivacyPolicyURL !== "" &&
                        <ErrorOrInfoComp
                            text={props.createGoogle.data.conversations[i].errorPrivacyPolicyURL}
                            _className={"font-red"}
                            icon={<FiInfo />}
                        />
                    }
                </div>
            </>
        )
    }

    return (
        <div className="main-integration-google-create-greeting">
            {props.createGoogle.data.conversations.map((v, i) => {
                return printGreeting(v, i)
            })}

            {props.createGoogle.data.conversations.length === 1 &&
                <div className="add-lang" onClick={onClickAddMoreLang}>
                    <FiPlus />
                    <b>Add More Language</b>
                </div>
            }

            <ButtonWithLoadingOrIcon 
                className="no-fill-button main-button-40"
                onClickAction={() => props._setActiveForm(1)}
                text="Go Back"
                icon={{
                    src: FiArrowLeft,
                    type: "svg"
                }}
                position="left"
            />

            <ButtonWithLoadingOrIcon 
                className="orange-button main-button-40"
                onClickAction={onClickContinue}
                text="Continue"
            />
        </div>
    )
}

const mapStateToProps = state => ({
    createGoogle: state.createGoogle
});
  
const mapDispatchToProps = {
    setCreateGoogle
};
  
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleCreateGreeting);
