import BaseService from '../BaseService';
import { API_KEY } from '../../config';

class StartConversationServices {
  postSendMessageWhatsapp(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SEND_MESSAGE_WHATSAPP, data, headers, callback);
  }

  postSendMessageWhatsappBa(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SEND_MESSAGE_WHATSAPP_BA, data, headers, callback);
  }

  postCheckStatusWhatsapp(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.CHECK_STATUS_WHATSAPP, data, headers, callback);
  }

  postCheckStatusWhatsappBa(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.CHECK_STATUS_WHATSAPP_BA, data, headers, callback);
  }

  postReplyMessageWhatsappBa(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REPLY_MESSAGE_WHATSAPP_BA, data, headers, callback);
  }

  postGetPriceList(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.PRICE_LIST, {}, headers, callback);
  }
}

const API = {
  // SEND_MESSAGE_WHATSAPP: '/organization/case/send_message_whatsapp_chatapicom',
  // CHECK_STATUS_WHATSAPP: '/organization/case/check_status_whatsapp_chatapicom',
  SEND_MESSAGE_WHATSAPP: '/organization/case/send_message_whatsapp',
  CHECK_STATUS_WHATSAPP: '/organization/case/check_status_whatsapp',
  SEND_MESSAGE_WHATSAPP_BA: '/organization/case/send_templated_message_whatsappba',
  CHECK_STATUS_WHATSAPP_BA: '/organization/case/check_status_whatsappba',
  REPLY_MESSAGE_WHATSAPP_BA: '/organization/case/reply_templated_message_whatsappba',
  PRICE_LIST: '/organization/message_type/get_price_list'
};

export default new StartConversationServices();
