import React from 'react';
import PopupRemove from '../../reuseableComponent/popupRemove/PopupRemove';

const MarkAsJunk = ({
    onClickCancel = () => {},
    markCase = () => {},
    isSetMarkAsResolved,
    toggleMarkAsResolved,
    isLoadingMarkCase,
    showModal,
    unmarkJunk,
    hideMarkAsResolve
}) => {
    return(
      <PopupRemove 
        removeTitle={unmarkJunk ? `Mark as Not Junk?` : `Mark as Junk?`}
        removeDescription={`This case will be ${unmarkJunk ? "marked as not junk" : "marked as junk"}, are you sure?`}
        onClickCancel={onClickCancel}
        onClickRemove={markCase}
        additionalContent={ unmarkJunk ? "" :
          !hideMarkAsResolve ?
            <div 
              className="set-send-closing-message-wrapper"
              style={{
                display: "flex",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 14,
                lineHeight: "21px",
                letterSpacing: "-0.2px",
                color: "rgba(25, 25, 25, 0.6)",
                padding: "0px 8px 8px",
                background: "rgba(25, 25, 25, 0.05)",
                borderRadius: 8,
                marginTop: 8,
              }}
            >
              <div className="custom-checkbox">
                  <input 
                    type="checkbox"
                    id="set-send-closing-message"
                    onClick={toggleMarkAsResolved}
                    checked={isSetMarkAsResolved}
                    disabled={isLoadingMarkCase}
                    readOnly
                  />
                  <label htmlFor="set-send-closing-message" />
              </div>
              <p style={{marginTop: 7}}>Mark as Resolved</p>
            </div>
            :
            ""
        }
        withoutBorder
        loadingButtonGray
        waitForResponse={isLoadingMarkCase}
        showModalProps={showModal}
        submitText={unmarkJunk ? 'Mark as Not Junk' : 'Mark as Junk'}
      />
    )
}

export default MarkAsJunk;
