import React, { useState, useEffect } from 'react';
import './RoomListSetupModal.scss'
import { Modal, ModalBody } from 'reactstrap';
// import ChatGreen from '../../../assets/img/roomlist/icon-chat-buble-green.svg';
import ChatRed from '../../../assets/img/roomlist/icon-fail-circle.svg';
// import ChatGrey from '../../../assets/img/roomlist/icon-chat-buble-grey.svg';
import { MdRefresh } from 'react-icons/md';
import { FiLock, FiMail, FiExternalLink } from 'react-icons/fi';

const SETUP_ROOM_MODAL_STATUS = {
    loadingClock: 1,
    loadingWebsocket: 2,
    success: 3,
    fail: 4,
    tierblock: 5
}

var RoomListSetupModal = (props) => {
  let [modalSetupRoomList, setModalSetupRoomList] = useState(true);

  useEffect(() => {
    if(props.setupModalProps === SETUP_ROOM_MODAL_STATUS.success) {
        // setTimeout(() => {
        setModalSetupRoomList(false)
        // }, 150);
    }
  }, [props.setupModalProps]);

  let setupOnProgressModal = () => {
    return (
        <div className="setup-main-content">
            <div className="setup-image-content">
                <div className="lds-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                </div>

                {/* <img src={ChatGrey} alt="" /> */}
            </div>
            <br />
            <b>
                {props.setupModalTextProps}
            </b>
            {/* <p>
                Make sure you have a stable connection
            </p> */}
        </div>
    )
  }

//   let setupSuccessModal = () => {
//     return (
//         <div className="setup-main-content">
//             <div className="setup-image-content setup-success">
//                 {/* <img src={ChatGreen} alt="" /> */}
//             </div>
//             <br />
//             <b>
//                 Setup successful
//             </b>
//             <p>
//                 You are all set and ready to go!
//             </p>
//         </div>
//     )
//   }

  let retrySetupAction = () => {
    window.location.reload();
  }

  let setupFailModal = () => {
    return (
        <div className="setup-main-content">
            <div className="setup-image-content setup-fail">
                <img src={ChatRed} alt="" />
            </div>
            <br />
            <b className="setup-fail">
                Setup failed
            </b>
            <p className="retry-setup" onClick={() => retrySetupAction()}>
                <b><MdRefresh />Try Again</b>
            </p>
        </div>
    )
  }

  let setupTierBlockModal = () => {
    return (
        <div id="retrieve-data-loading-page" className={props.noPaddingTop ? "no-padding-top" : ""}>
            <div className="fail-retrive-data tier-blocking">
                <div className="tier-blur-layer">
                </div>

                <div className="alert-component">
                    <FiLock />
                    <b>
                        Inbox feature is not available for your current tier
                    </b>

                    <div className='alert-component-button-wrap'>
                        <a href="mailto:sales@taptalk.io">
                            <button className="contact-us-button blue-button">
                                <FiMail />
                                <b>Contact Us</b>
                            </button>
                        </a>

                        <a href={window.location.origin+"/o/"+props.match.params.orgID+"/home"}>
                            <button className="contact-us-button no-fill-blue-button">
                                <FiExternalLink />
                                <b>Go to Dashboard</b>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
      </div>
    )
  }

  return (
    <Modal isOpen={modalSetupRoomList} className={`setup-room-list-modal ${props.setupModalProps === SETUP_ROOM_MODAL_STATUS.tierblock ? "setup-room-list-modal-tier" : ""}`}>
        <ModalBody>
            {(props.setupModalProps === SETUP_ROOM_MODAL_STATUS.loadingClock) || (props.setupModalProps === SETUP_ROOM_MODAL_STATUS.loadingWebsocket) ?
                setupOnProgressModal()
                :
                props.setupModalProps === SETUP_ROOM_MODAL_STATUS.fail ? 
                    setupFailModal()
                    :
                    props.setupModalProps === SETUP_ROOM_MODAL_STATUS.tierblock ?
                        setupTierBlockModal()
                        :
                        "" 
            }
        </ModalBody>
    </Modal>
  );
}

export default RoomListSetupModal;
