import React, { useEffect, useState } from 'react';
import './IntegrationTokopediaDetailAccount.scss';
import { NavLink } from "react-router-dom";
// import Integrations from "../../Integrations";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import CopyBox from '../../../reuseableComponent/copyBox/CopyBox';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import AutoResolveServices from '../../../../services/newServices/AutoResolveServices';
import AutoUnassignServices from '../../../../services/newServices/AutoUnassignServices';
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import 'react-toastify/dist/ReactToastify.css';
import { FiEdit, FiRefreshCw, FiInfo, FiUser } from 'react-icons/fi';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import IntegrationAutoMessageDetail from "../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";
import IntegrationTokopediaServices from '../../../../services/integration/IntegrationTokopediaServices';
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
import iconCheckGreen from '../../../../assets/img/icon-check-green.svg';
import iconCloseRed from '../../../../assets/img/close-red-icon.svg';
import iconXgrey from '../../../../assets/img/integration/icon-x-grey.svg';
import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
import iconWarningOrange from '../../../../assets/img/integration/icon-warning-orange.svg';
import iconWarningYellow from '../../../../assets/img/integration/icon-warning-yellow.svg';

const IntegrationTokopediaDetailAccount = (props) => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);

  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [accountDetailVal, setAccountDetailVal] = useState(null);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [showModalActivation, setShowModalActivation] = useState(false);
  let [isLoadingActivation, setIsLoadingActivation] = useState(false);

  let [chatbot, setChatbot] = useState(false);
  let [isShowModalRemoveChatbot, setIsShowModalRemoveChatbot] = useState(false);
  let [waitForResponseRemoveChatbot, setWaitForResponseRemoveChatbot] = useState(false);
  let [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(false);
  let [welcomeMessageData, setWelcomeMessageData] = useState(false);
  let [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
  let [awayMessageData, setAwayMessageData] = useState(false);
  let [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
  let [closingMessageData, setClosingMessageData] = useState(false);
  let [loadingAutoResolve, setLoadingAutoResolve] = useState(false);
  let [autoResolveData, setAutoResolveData] = useState(false);
  let [errorWelcome, setErrorWelcome] = useState(false);
  let [errorAway, setErrorAway] = useState(false);
  let [errorClosing, setErrorClosing] = useState(false);
  let [errorChatbot, setErrorChatbot] = useState(false);
  let [errorAutoResolve, setErrorAutoResolve] = useState(false);
  let [loadingAutoUnassign, setLoadingAutoUnassign] = useState(false);
  let [autoUnassignData, setAutoUnassignData] = useState(false);
  let [errorAutoUnassign, setErrorAutoUnassign] = useState(false);
  
  let toggleModalActivation = () => {
    if(!isLoadingActivation) {
      setShowModalActivation(!showModalActivation);
    }
  }

  let back = () => history.push(`/o/${orgID}/integration/tokopedia`);

  let toggleModalRemoveChatbot = () => {
    setIsShowModalRemoveChatbot(!isShowModalRemoveChatbot);
  };
  
  let doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getAccountDetail(paramID, match.params.accountID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getAccountDetail = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);

    IntegrationTokopediaServices.getDetail(orgID, {id: parseInt(accountID)}, (response) => {
      let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setAccountDetailVal(response.dataResult.data);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        setIsLoadingGetDetailData(false);
        setAccountDetailVal(null);
      }
    });
  };

  useEffect(() => {
    doMount();

    if (location.state) {
      doToast(location.state.message);
      history.replace({
        state: undefined,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if(accountDetailVal !== null) {
      getChatBot()
      runGetWelcomeMessage()
      runGetAwayMessage()
      runGetClosingMessage()
      runGetAutoResolve()
      runGetAutoUnassign()
    }
  }, [accountDetailVal])
  
  let renderTopics = () => {
    let text = "";
    
    accountDetailVal.channel.topics.map((v, i) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  let runRequestActivation = () => {
      setIsLoadingActivation(true);

      let data = {
        "id": accountDetailVal.channel.id,
        "shopDomain": accountDetailVal.channel.shopDomain,
        "createdTime": accountDetailVal.channel.createdTime
      };

      IntegrationTokopediaServices.reqActive(orgID, data, (response) => {
        let res = response.dataResult;

        if(res.error.message === "") {
          if(res.data.success) {
            doToast(res.data.message);
            getAccountDetail(orgID, match.params.accountID);
          } else {
            doToast(res.data.message, "fail");
          }
        }else {
          doToast(res.error.message, "fail");
        }

        toggleModalActivation();
        setIsLoadingActivation(false);
      })
  }

  let removeChatbot = () => {
    setWaitForResponseRemoveChatbot(true);

    let data = {
      connectionID: chatbot.item.id,
      channelID: parseInt(match.params.accountID),
      chatbotID: chatbot.item.chatbotID,
    };
    
    IntegrationTokopediaServices.removeChatBot(
      orgID,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message);
            setWaitForResponseRemoveChatbot(false);
          } else {
            doToast(response.dataResult.data.message);
            toggleModalRemoveChatbot();
            setIsLoadingGetDetailData(true);
            setChatbot(null);
            getAccountDetail(orgID, match.params.accountID);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setWaitForResponseRemoveChatbot(false);
        }
      }
    );
  }

  let getChatBot = () => {
    IntegrationTokopediaServices.getChatBot(orgID, {channelID: parseInt(match.params.accountID)}, (response) => {
      //  let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setChatbot(response.dataResult.data);
      } else {
        // setAccountDetailVal(null);
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorChatbot({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        })

        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingGetDetailData(false);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let runGetWelcomeMessage = () => {
    setLoadingGetWelcomeMessage(true);

    IntegrationTokopediaServices.getWelcomeMessage(orgID, match.params.accountID, (response) => {
        // let _retriveDataError = {...retriveDataError};
        
        if (response.dataResult.error.message === "") {
          setWelcomeMessageData(response.dataResult.data.welcomeMessage);
        } else {
          // _retriveDataError.message = response.dataResult.error.message;
          // _retriveDataError.code = response.dataResult.error.code;
          // setRetrieveDataError(_retriveDataError);
          setErrorWelcome({
            errorCode: response.dataResult.error.code,
            errorMessage: response.dataResult.error.message
          });
          // doToast(response.dataResult.error.message, 'fail');
        }

        setLoadingGetWelcomeMessage(false);
    });
  }

  let runGetAwayMessage = () => {
    setLoadingGetAwayMessage(true);

    IntegrationTokopediaServices.getAwayMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAwayMessageData(response.dataResult.data.awayMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAway({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetAwayMessage(false);
    });
  }

  let runGetClosingMessage = () => {
    setLoadingGetClosingMessage(true);

    IntegrationTokopediaServices.getClosingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setClosingMessageData(response.dataResult.data.closingMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorClosing({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetClosingMessage(false);
    });
  }

  let runGetAutoResolve = () => {
    setLoadingAutoResolve(true);
  
    AutoResolveServices.getAutoResolve(orgID, "tokopedia", {id: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAutoResolveData(response.dataResult.data.autoResolveConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoResolve({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }
  
      setLoadingAutoResolve(false);
    });
  }
  
  let runGetAutoUnassign = () => {
    setLoadingAutoUnassign(true);

    AutoUnassignServices.getAutoUnassign(orgID, "tokopedia", {id: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAutoUnassignData(response.dataResult.data.autoUnassignAgentConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoUnassign({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingAutoUnassign(false);
    });
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

        <div className="sectionWrap integration-main-container integration-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ?
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
            :
            <React.Fragment>
              <BackButton text="Back to Tokopedia" onClick={back} />

              <PopupConfirmation
                isOpen={showModalActivation}
                toggle={toggleModalActivation}
                title="Request Activation"
                description="You are submitting for another request activation, continue?"
                className="modal-confirmation-activation"
              >
                {!isLoadingActivation ?
                    <ButtonWithLoadingOrIcon
                        text="Request Activation"
                        className="main-button-48 orange-button"
                        onClickAction={runRequestActivation}
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Request Activation"
                        className="main-button-48 orange-button"
                        isLoading
                        isDisabled
                        loadingColor="gray"
                        position="left"
                    />
                }
              </PopupConfirmation>

              <div className="integration-detail-account-box-1">
                <div className="integration-detail-account-box-1-top">
                  <b>Channel Details</b>

                  <div className="integration-detail-account-box-1-top-button">
                    {/* <ButtonWithLoadingOrIcon 
                        className="red-button main-button-40"
                        text="Delete"
                        onClickAction={() => toggleModalRemoveChannel()}
                        position="left"
                        icon={{
                          type: "svg",
                          src: FiTrash2
                        }}
                    /> */}

                    <NavLink to={{
                      pathname: `/o/${orgID}/integration/tokopedia/edit-topic/${accountDetailVal.channel.id}`,
                      state: {
                        topic: accountDetailVal.channel.topics
                      }
                    }}>
                      <ButtonWithLoadingOrIcon 
                          className="no-fill-button main-button-40"
                          text="Edit Topic"
                          position="left"
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                      />
                    </NavLink>

                  </div>
                </div>

                <div className="integration-detail-account-box-1-bottom">
                  {accountDetailVal.channel.statusText === "Pending" &&
                    <div className="alert-component alert-blue">
                        <FiInfo />
                        <b className="text-alert">
                          Check your Tokopedia account’s email regularly. If you receive an email from Tokopedia, please accept it.
                        </b>
                    </div>
                  }
                  
                  <div className="form-content-wrapper" style={{marginBottom: "16px"}}>
                    <label>
                      <b>Channel ID</b>
                    </label>
                    <CopyBox
                      className="margin-bottom-24" 
                      id={`channel-id`}
                      val={accountDetailVal.channel.stringID}
                      copyText="Channel ID copied"
                      isDisabled={true}
                    />
                    
                    <label>
                      <b>Shop URL</b>
                    </label>
                    <CopyBox
                      className="margin-bottom-24" 
                      id={`shop-url`}
                      val={accountDetailVal.channel.shopURL === "" ? "-" : accountDetailVal.channel.shopURL}
                      copyText="Shop URL copied"
                      isDisabled={true}
                    />

                    <label>
                      <b>Shop Name</b>
                    </label>
                    <p className='detail-value-wrapper'>{accountDetailVal.channel.shopName === "" ? accountDetailVal.channel.shopDomain : accountDetailVal.channel.shopName}</p>

                    <label>
                      <b>Shop Logo</b>
                    </label>
                    <div className='account-photo-wrapper'>
                      {accountDetailVal.channel.shopLogo !== "" ?
                        <img src={accountDetailVal.channel.shopLogo} alt="" />
                        :
                        <FiUser />
                      }
                    </div>

                    {accountDetailVal.channel.topics.length > 0 &&
                      <>
                        <label>
                          <b>Topic</b>
                        </label>
                        <p className="topic-val">
                          <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                        </p>
                      </>
                    }

                    <label>
                      <b>Status</b>
                    </label>

                    {accountDetailVal.channel.statusText === "Active" &&
                      <div className={`detail-status-wrapper green-text`}>
                        <b><img src={iconCheckGreen} alt="" />Active</b>
                      </div>
                    }

                    {accountDetailVal.channel.statusText === "Canceled" &&
                      <div className={`detail-status-wrapper red-text`}>
                        <b><img src={iconCloseRed} alt="" />Canceled</b>
                      </div>
                    }

                    {accountDetailVal.channel.statusText === "Not Connected" &&
                      <div className={`detail-status-wrapper grey-font`}>
                        <b><img src={iconXgrey} alt="" />Not Connected</b>
                      </div>
                    }

                    {accountDetailVal.channel.statusText === "Suspended" &&
                      <div className={`detail-status-wrapper yellow-text`}>
                        <b><img src={iconWarningYellow} alt="" />Suspended</b>
                      </div>
                    }

                    {accountDetailVal.channel.statusText === "Pending" &&
                      <div className={`detail-status-wrapper orange-text`}>
                        <b><img src={iconWarningOrange} alt="" />Pending</b>
                      </div>
                    }
                    
                    {accountDetailVal.channel.reason !== "" &&
                      <>
                        <label>
                          <b>Reason</b>
                        </label>
                        <p className='detail-reason-wrapper'>
                          {accountDetailVal.channel.reason}
                        </p>
                      </>
                    }

                    {(accountDetailVal.channel.statusText === "Canceled" || accountDetailVal.channel.statusText === "Not Connected") &&
                      <ButtonWithLoadingOrIcon 
                        className={"orange-button main-button-40 margin-top-0"}
                        position="left"
                        text="Request Activation"
                        icon={{
                          type: "svg",
                          src: FiRefreshCw
                        }}
                        onClickAction={toggleModalActivation}
                      />
                    }
                  </div>
                </div>
              </div>

              {/* chatbot */}
              <div className="integration-detail-account-box-1 box-channel-button">
                <div className="integration-detail-account-box-1-top padding-bottom24">
                  <b>Chatbot</b>
                  {/* <p className='bot-name'>
                    Setup Your Chatbot
                  </p> */}

                  {!errorChatbot &&
                    <div className="integration-detail-account-box-1-top-button">
                      {chatbot.item.id !== 0 &&
                        <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "img",
                            src: iconDelete
                          }}
                          className="red-button main-button-40 button-channel-detail"
                          text="Remove Chatbot"
                          position="left"
                          onClickAction={() => toggleModalRemoveChatbot()}
                        />
                      }

                      <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Set Chatbot"
                          position="left"
                          onClickAction={() => {
                            history.push({
                              pathname: `/o/${orgID}/integration/tokopedia/edit-chatbot/${match.params.accountID}`,
                              state: {
                                accountDetail: accountDetailVal,
                                chatbot: chatbot.item
                              }
                            })
                          }}
                      />
                    </div>
                  }
                </div>
                
                {errorChatbot ?
                  <RetrieveDataLoading 
                    isLoading={false}
                    errorCode={errorChatbot ? errorChatbot.errorCode : false}
                    errorMessage={errorChatbot ? errorChatbot.errorMessage : false}
                  />
                  :
                  <div className="facebook-detail-topic">
                    <div className="channel-button-wrapper margin-top24">
                        <b className="channel-button-title">Chatbot Name</b>
                        <br />
                        <b>{chatbot.item.id === 0 ? "Not Set" : chatbot.item.chatbotName}</b>
                    </div>

                    <div className="channel-button-wrapper margin-top24">
                        <b className="channel-button-title">Chatbot Type</b>
                        <br />
                        <b>{chatbot.item.id === 0 ? "Not Set" : chatbot.item.chatbotTypeName}</b>
                    </div>

                    <div className="channel-button-wrapper status-wrapper margin-top24">
                      <b className="topic-title">Status</b>
                      <br />
                      
                      {chatbot.item.id !== 0 ? 
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-value text-green">Enabled</b>
                        </>
                        :
                        <>
                          <img src={iconXgrey} alt="" />
                          <b className="status-value">Disabled</b>
                        </>
                      }
                    </div>

                    {/* <ButtonWithLoadingOrIcon

                    /> */}
                  </div>
                }
              </div>

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/tokopedia/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={welcomeMessageData}
                  isLoading={loadingGetWelcomeMessage}
                  type="welcome"
                  error={errorWelcome}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/tokopedia/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={awayMessageData}
                  isLoading={loadingGetAwayMessage}
                  type="away"
                  error={errorAway}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/tokopedia/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={closingMessageData}
                  isLoading={loadingGetClosingMessage}
                  type="closing"
                  error={errorClosing}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/tokopedia/auto-resolve-setup/${match.params.accountID}`,
                          state: {
                            type: type,
                            medium: "tokopedia",
                            backToLink: `/o/${orgID}/integration/tokopedia/detail/${match.params.accountID}`
                          }
                      })
                  }}
                  data={autoResolveData}
                  isLoading={loadingAutoResolve}
                  type="autoResolve"
                  error={errorAutoResolve}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/tokopedia/auto-unassign-setup/${match.params.accountID}`,
                          state: {
                            type: type,
                            medium: "tokopedia",
                            backToLink: `/o/${orgID}/integration/tokopedia/detail/${match.params.accountID}`
                          }
                      })
                  }}
                  data={autoUnassignData}
                  isLoading={loadingAutoUnassign}
                  type="autoUnassign"
                  error={errorAutoUnassign}
              />
              {/* chatbot */}

              <PopupRemove
                removeTitle={`Remove Chatbot?`}
                removeDescription={`This chatbot will be removed from this channel.`}
                onClickCancel={toggleModalRemoveChatbot}
                onClickRemove={removeChatbot}
                waitForResponse={waitForResponseRemoveChatbot}
                showModalProps={isShowModalRemoveChatbot}
                submitText="Delete"
                colorButton="red-button"
              />
            </React.Fragment>
          }
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationTokopediaDetailAccount;
