import React, { useState, useEffect } from "react";
import "./IntegrationGoogleCreateTopic.scss";
import { connect } from "react-redux";
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import Select from "react-select";
import topicServices from "../../../../../services/newServices/TopicServices";
import { setCreateGoogle } from "../../../../../redux/actions/reduxActionCreateGoogle";
import { doToast } from "../../../../../helper/HelperGeneral";
import ErrorOrInfoComp from '../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

let iconUpDark = "url('/image/chevron-up-dark.svg') center / contain no-repeat !important";
let iconDropGrey = "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

let IntegrationGoogleCreateTopic = (props) => {
    let [topicList, setTopicList] = useState([]);
    let [errorTopic, setErrorTopic] = useState("");

    useEffect(() => {
        getTopic();
    }, [])

    let styleSelect = () => ({
        indicatorsContainer: provide => ({
            ...provide,
            height: "48px",
        }),
        valueContainer: provide => ({
            ...provide,
            height: "100%",
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused ? iconUpDark : iconDropGrey,
            transition: "0.25s ease-in-out",
            color: "transparent !important",
            width: "24px",
            height: "24px",
            zIndex: 0
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            width: "400px"
        }),
        control: (provide, { isFocused }) => ({
            ...provide,
            padding: "0 5px",
            // height: "48px",
            borderRadius: "8px",
            border: errorTopic !== "" ? "1px solid #ff3f57 !important" : (isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important"),
            boxShadow: "none !important",
            // marginBottom: "24px",
            cursor: "pointer"
        }),
        indicatorSeparator: provide => ({ ...provide, display: "none" }),
        menu: provide => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden"
        }),
        option: (provide, state) => {
            let { isSelected } = state;
            let checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)"
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null
            };
        }
    })

    let getTopic = () => {
        topicServices.getTopic(props.match.params.orgID, "", "", (response) => {
            if (response.dataResult.error.code === "") {
                let dataResult = response.dataResult;
                dataResult.data.topics.map((v, i) => {
                    v.label = v.name;
                    v.value = v.id;

                    return null;
                })

                setTopicList(dataResult.data.topics);
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }
        })
    }

    let onSelectTopic = (e) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        _parse.data.topic = e;
        setErrorTopic("");
        props.setCreateGoogle(_parse);
    }

    let onClickContinue = () => {
        if (props.createGoogle.data.topic.value === "") {
            setErrorTopic("Select your topic first.");
        } else {
            props._setActiveForm(1);
        }
    }

    return (
        <div className="main-integration-google-create">
            <label>
                <b>Topic</b>
            </label>

            <div className="custom-select2-wrapper">
                <Select
                    options={topicList}
                    placeholder="Select Topic"
                    onChange={(e) => onSelectTopic(e)}
                    styles={styleSelect()}
                    classNamePrefix="custom-select-topic-option"
                    value={topicList.filter(
                        (obj) => obj.value === props.createGoogle.data.topic.value
                    )}
                    blurInputOnSelect={true}
                />
            </div>

            <ErrorOrInfoComp
                text={errorTopic === "" ? `Incoming messages on this channel will be assigned to this topic.` : errorTopic}
                _className={errorTopic === "" ? "" : "font-red"}
                icon={errorTopic === "" ? <FiInfo /> : <FiAlertCircle />}
            />

            <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                onClickAction={onClickContinue}
                text="Continue"
            />
        </div>
    )
}

const mapStateToProps = state => ({
    createGoogle: state.createGoogle
});

const mapDispatchToProps = {
    setCreateGoogle
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleCreateTopic);
