import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const UserRatingServices = {
  getUserRating(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_USER_RATING, {}, headers, callback);  
  },

  setUserRating(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_USER_RATING, data, headers, callback);  
  },

  getUserRatingToken(data) {
    return (
      BaseService.doPost(API.GET_USER_RATING_TOKEN, data)
        .then(response => {
          return {
            dataResult: response.data,
          }
        })
        .catch(error => {
          return BaseService.generateErrorMessage(error);
        })
    )
  },

  setUserRatingForm(data) {
    return (
      BaseService.doPost(API.SUBMIT_USER_RATING, data)
        .then(response => {
          return {
            dataResult: response.data,
          }
        })
        .catch(error => {
          return BaseService.generateErrorMessage(error);
        })
    )
  },
};

const API = {
  GET_USER_RATING: "/organization/setup/user_rating/get",
  SET_USER_RATING: "/organization/setup/user_rating/set",
  GET_USER_RATING_TOKEN: "/client/case_rating/verify_token",
  SUBMIT_USER_RATING: "/client/case_rating/submit",
}

export default UserRatingServices;
