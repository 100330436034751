import React from "react";
import IntegrationAutoResolve from "../../integrations/integrationAutoMessage/integrationAutoResolve/IntegrationAutoResolve";

let AutoResolveCase = (props) => {
    return (
        <div className="integration-taptalk-auto-message-setup">
           <React.Fragment>
                <IntegrationAutoResolve
                    {...props}
                    back={() => {
                        props.history.push({
                            pathname: `/o/${props.match.params.orgID}/setup/general-assignments`,
                            state: {
                                tab: "idleCase"
                            }
                        })
                    }}
                    backToText="Back to General Assignments"
                    medium={"global"}
                />
            </React.Fragment>
        </div>
    )
}

export default AutoResolveCase;
