import React, { useEffect } from "react";
import "./ListPerPage.scss";
import {  Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FiChevronDown, FiCheck } from "react-icons/fi";

const LIST_PER_PAGE = [20, 40, 60];

let ListPerPage = (props) => {
    useEffect(() => {
        if(props.currentValue === "") {
            props.onChangeDropdown(LIST_PER_PAGE[0])
        }
    }, []);
    
    return (
        <Dropdown isOpen={props.isOpen} toggle={props.toggle} className="dropdown-list-per-page">
            <DropdownToggle>
                <b>{props.currentValue}</b>

                <FiChevronDown className={props.isOpen ? "show-dropdown" : ""} />
            </DropdownToggle>
            <DropdownMenu>
                {LIST_PER_PAGE.map((value, key) => {
                    return (
                        <DropdownItem 
                            key={`list-per-page-${key}`} 
                            onClick={() => {
                                props.toggle();
                                props.onChangeDropdown(value);
                            }}
                            className={props.currentValue === value ? "selected-list-per-page" : ""}
                        >
                            {props.currentValue === value && <FiCheck />}
                            {value}
                        </DropdownItem>
                    )
                })}
            </DropdownMenu>
        </Dropdown>
    )
}

export default ListPerPage;