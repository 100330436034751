import React, { useEffect, useState } from "react";
import "./IntegrationInstagramAutoMessageSetup.scss";
import IntegrationAutoMessageSetup from "../../integrationAutoMessage/integrationAutoMessageSetup/IntegrationAutoMessageSetup";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import IntegrationInstagramServices from '../../../../services/integration/IntegrationInstagramServices';

let IntegrationInstagramAutoMessageSetup = (props) => {
    let [autoMessageData, setAutoMessageData] = useState(false);
    let [loadingGetAutoMessageData, setLoadingGetAutoMessageData] = useState(true);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    })

    useEffect(() => {
        if(props.location.state) {
            switch(props.location.state.type) {
                case "welcome":
                    runGetWelcomeMessage();
                    break;
                case "away":
                    runGetAwayMessage();
                    break;
                case "closing":
                    runGetClosingMessage();
                    break;
                default:
                    runGetRatingMessage();
            }
        }else {
            // back();
        }
    }, [props.location])

    let back = () => {
        props.history.push(`/o/${props.match.params.orgID}/integration/instagram/detail/${props.match.params.accountID}`)
    }

    let runGetWelcomeMessage = () => {
        setLoadingGetAutoMessageData(true);
    
        IntegrationInstagramServices.getWelcomeMessage(props.match.params.orgID, props.match.params.accountID, (response) => {
          let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            response.dataResult.data.welcomeMessage.medium = "instagram";
            setAutoMessageData(response.dataResult.data.welcomeMessage);
          } else {
            _retriveDataError.message = response.dataResult.error.message;
            _retriveDataError.code = response.dataResult.error.code;
            setRetrieveDataError(_retriveDataError);
          }
    
          setLoadingGetAutoMessageData(false);
        });
    }

    let runGetAwayMessage = () => {
        console.log("away insta")
        setLoadingGetAutoMessageData(true);
    
        IntegrationInstagramServices.getAwayMessage(props.match.params.orgID, props.match.params.accountID, (response) => {
          let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            response.dataResult.data.awayMessage.medium = "instagram";
            setAutoMessageData(response.dataResult.data.awayMessage);
          } else {
            _retriveDataError.message = response.dataResult.error.message;
            _retriveDataError.code = response.dataResult.error.code;
            setRetrieveDataError(_retriveDataError);
          }
    
          setLoadingGetAutoMessageData(false);
        });
    }

    let runGetClosingMessage = () => {
        setLoadingGetAutoMessageData(true);
    
        IntegrationInstagramServices.getClosingMessage(props.match.params.orgID, props.match.params.accountID, (response) => {
          let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            response.dataResult.data.closingMessage.medium = "instagram";
            setAutoMessageData(response.dataResult.data.closingMessage);
          } else {
            _retriveDataError.message = response.dataResult.error.message;
            _retriveDataError.code = response.dataResult.error.code;
            setRetrieveDataError(_retriveDataError);
          }
    
          setLoadingGetAutoMessageData(false);
        });
    }

    let runGetRatingMessage = () => {
        setLoadingGetAutoMessageData(true);
    
        IntegrationInstagramServices.getRatingMessage(props.match.params.orgID, props.match.params.accountID, (response) => {
          let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            response.dataResult.data.userRating.medium = "instagram";
            setAutoMessageData(response.dataResult.data.userRating);
          } else {
            _retriveDataError.message = response.dataResult.error.message;
            _retriveDataError.code = response.dataResult.error.code;
            setRetrieveDataError(_retriveDataError);
          }
    
          setLoadingGetAutoMessageData(false);
        });
    }
    
    return (
        <div className="integration-taptalk-auto-message-setup">
           {loadingGetAutoMessageData || retriveDataError.code ? 
                <SectionWrap {...props} orgID={props.match.params.orgID} withHeader withSideMenu>
                    <div className="integration-detail-loading-wrapper">
                        <RetrieveDataLoading
                            isLoading={loadingGetAutoMessageData}
                            errorMessage={retriveDataError.message}
                        />
                    </div>
                </SectionWrap>
                : 
                <React.Fragment>
                    <IntegrationAutoMessageSetup 
                        {...props}
                        back={back}
                        backToText="Back to Instagram Detail"
                        data={autoMessageData}
                    />
                </React.Fragment>
            }
        </div>
    )
}

export default IntegrationInstagramAutoMessageSetup;
