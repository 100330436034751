const INITIAL_STATE = false;

let reduxReducerAllOrganizationDetail = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ALL_ORGANIZATION_DETAIL':
        return action.allOrganizationDetail;
      case 'CLEAR_ALL_ORGANIZATION_DETAIL':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerAllOrganizationDetail;