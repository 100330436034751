import React from 'react';
import './ChatRoomBroadcastHistoryModal.scss';
import ChatRoomBroadcastHistoryBroadcastDetail from './chatRoomBroadcastHistoryModalBroadastDetail/ChatRoomBroadcastHistoryBroadcastDetail';
import { connect } from 'react-redux';

// const MESSAGE_TYPE = {
//   CASE_CREATED: 3005,
//   CASE_CLOSED: 3001,
//   CASE_REOPEN: 3002,
//   CASE_RATING_BUTTON: 3003,
//   CASE_RATING_BUTTON_DISABLED: 3004,
//   CASE_UPDATE_AGENT_CHANGE: 3006
// }

var ChatRoomBroadcastHistoryModal = (props) => {
  // let [isUserDetailShow, setIsUserDetailShow] = useState(true);
  // let [broadcastHistoryPopupData, setbroadcastHistoryPopupData] = useState(null);

  // let toggleIsUserDetailShowAction = () => {
  //   setIsUserDetailShow(!isUserDetailShow);
  // }

  // useEffect(() => {
  //   if(props.broadcastHistoryPopupDataProps !== null) {
  //     setbroadcastHistoryPopupData(props.broadcastHistoryPopupDataProps)
  //   }
  // }, [props.broadcastHistoryPopupDataProps])

  // useEffect(() => {
  //   let listenerNewMessage = props.messageListenerNewMessageProps === null ? null : props.messageListenerNewMessageProps;

  //   if(props.activeRoom !== null && props.isShowModalbroadcastHistoryProps && broadcastHistoryPopupData !== null) {

  //     if(listenerNewMessage !== null && (listenerNewMessage.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE || listenerNewMessage.type === MESSAGE_TYPE.CASE_CLOSED)) {
        
  //       if(listenerNewMessage.data.id === broadcastHistoryPopupData.case.id) {
  //           let _broadcastHistoryPopupData = {...broadcastHistoryPopupData};
  //           _broadcastHistoryPopupData.case = listenerNewMessage.data; 
  //           setbroadcastHistoryPopupData(_broadcastHistoryPopupData)
  //       }
        
  //     }
      
  //   }
  // }, [props.messageListenerNewMessageProps]);
  
  return (
    // broadcastHistoryPopupData !== null &&
      <div className={`chat-room-history-modal-wrapper ${props.isShowModalBroadcastHistoryProps ? 'active-chat-room-history-modal-wrapper' : ''}`}>
          <div className={`chat-room-history-modal-content ${props.isShowModalBroadcastHistoryProps ? 'active-chat-room-history-modal-content' : ''}`}>
            <ChatRoomBroadcastHistoryBroadcastDetail
              {...props}
              toggleBroadcastModalHistoryProps={props.toggleBroadcastModalHistoryProps} 
              // isUserDetailShowProps={isUserDetailShow}
              broadcastHistoryPopupDataProps={props.broadcastHistoryPopupDataProps}
              isShowModalbroadcastHistoryProps={props.isShowModalBroadcastHistoryProps}
              messageListenerNewMessageProps={props.messageListenerNewMessageProps}
              messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
            />
          </div>
      </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomBroadcastHistoryModal);
