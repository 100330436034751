import React from "react";
import { Redirect, Route } from "react-router-dom";
import HelperAuth from "../../../helper/HelperAuth";

const WasLoggedIn = ({ component: Component, ...rest }) => (
    <Route 
        {...rest}
        render={props => 
            (HelperAuth.userWasLoggedIn() && rest.path !== "/verify/email") ?
                (
                    <Redirect to="/home" />               
                ) 
                : 
                (
                    <Component {...props} />
                )
        }
    />
);

export default WasLoggedIn;
  