import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./OnBoardingReplyInbox.scss";
import { FiExternalLink, FiCheck, FiXCircle } from "react-icons/fi";
import IconReplyInbox from "../../../../assets/img/onboarding/icon-reply-inbox.svg";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import OnboardingServices from "../../../../services/onboarding/OnboardingServices";
import { doToast } from "../../../../helper/HelperGeneral";

// const ON_BOARDING = "replyInbox";

let OnBoardingReplyInbox = (props) => {
    let [onBoardingInviteMemberDone, setOnBoardingInviteMemberDone] = useState(false);
    let [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false)

    useEffect(() => {
        setOnBoardingInviteMemberDone(props.status)
    }, [props.status])

    let onClickMarkAsDone = () => {
        let _orgID = props.match.params.orgID.split("-")[0];
        
        let data = {
            itemID: props.id,
            isDone: true
        }
        updateStatus(data, _orgID)
    }

    let onClickMarkAsUndone = () => {
        let _orgID = props.match.params.orgID.split("-")[0];
        
        let data = {
            itemID: props.id,
            isDone: false
        }
        updateStatus(data, _orgID)
    }

    let updateStatus = (data, orgID) => {
        setLoadingUpdateStatus(true);
        OnboardingServices.postUpdateStatus(orgID, data, (response) => {
            let dataResult = response.dataResult;

            if(dataResult.error.code === "") {
                setOnBoardingInviteMemberDone(data.isDone);
                props.runLastChangeOnBoarding();
                if(data.isDone) props.completeCount + 1 === 5 && props.handleComplete()
            } else {
                doToast(dataResult.error.message, 'fail');
            }
            setLoadingUpdateStatus(false);
        })
    }

    return (
        <div className={`onboarding-child-wrapper onboarding-invite-member`}>
            <img src={IconReplyInbox} alt="Onetalk reply inbox" className="onboarding-child-image" />

            <p className="onboarding-description">
                You’re nearly there! One last thing to do is to reply to incoming messages from your inbox.
                <b>Try messaging to one of the channels you have successfully integrated and reply it from the inbox!</b>
            </p>

            <div className="button-wrapper">
                <NavLink to={`/o/${props.match.params.orgID}/inbox`} target="_blank">
                    <ButtonWithLoadingOrIcon
                        className="orange-button main-button-40"
                        icon={{
                            type: "svg",
                            src: FiExternalLink
                        }}
                        text="Open Inbox"
                        position="left"
                    />
                </NavLink>

                { loadingUpdateStatus ? 
                 <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40 mark-as-undone"
                    text={onBoardingInviteMemberDone ? "Unmark as Done" : "Mark as Done"}
                    position="left"
                    isLoading
                    loadingColor="orange"
                />
                :
                 (onBoardingInviteMemberDone ?
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40 mark-as-undone"
                        icon={{
                            type: "svg",
                            src: FiXCircle
                        }}
                        text="Unmark as Done"
                        position="left"
                        onClickAction={onClickMarkAsUndone}
                        loadingColor="gray"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40"
                        icon={{
                            type: "svg",
                            src: FiCheck
                        }}
                        text="Mark as Done"
                        position="left"
                        onClickAction={onClickMarkAsDone}
                        loadingColor="gray"
                    />)
                }
            </div>
        </div>
    )
}

export default OnBoardingReplyInbox;