import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./ChannelDetail.scss";
import { FiEdit, FiCopy } from 'react-icons/fi';
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";
import AutoResolveServices from "../../../../../services/newServices/AutoResolveServices";
import AutoUnassignServices from '../../../../../services/newServices/AutoUnassignServices';
import { doToast, numberWithCommas } from '../../../../../helper/HelperGeneral';
import Helper from '../../../../../helper/HelperDate';
import HelperChat from '../../../../../helper/HelperChat';
import HelperInput from '../../../../../helper/HelperInput';
import { FiClock, FiLink2 } from "react-icons/fi";
import iconDelete from '../../../../../assets/img/integration/icon-delete.svg';
import iconXgrey from '../../../../../assets/img/integration/icon-x-grey.svg';
import iconCheckGreen from '../../../../../assets/img/icon-check-green.svg';
import iconCloseRed from '../../../../../assets/img/close-red-icon.svg';
import statusConnected from '../../../../../assets/img/integration/status-connected.svg';
import PopupRemove from '../../../../reuseableComponent/popupRemove/PopupRemove';
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IntegrationAutoMessageDetail from "../../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";

const INSTANCE_STATUS = {
    not_paid: 'not_paid',
    waiting_setup: 'waiting_setup',
    active: 'active',
    error: 'error',
};

let ChannelDetail = (props) => {
    let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true)
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [accountDetail, setAccountDetail] = useState(null);
    let [whatsappbaChatbot, setWhatsappbaChatbot] = useState(null);
    let [whatsappChannelLink, setWhatsappChannelLink] = useState(null);
    let [whatsappChannelLinkNeverSet, setWhatsappChannelLinkNeverSet] = useState(false);
    let [isShowModalRemoveChatbot, setIsShowModalRemoveChatbot] = useState(false);
    let [waitForResponseRemoveChatbot, setWaitForResponseRemoveChatbot] = useState(false);
    let [phoneVal, setPhoneVal] = useState({
        value: "",
        label: ""  
    });
    let [phoneNumberList, setPhoneNumberList] = useState(false);
    let [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(false);
    let [welcomeMessageData, setWelcomeMessageData] = useState(false);
    let [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
    let [awayMessageData, setAwayMessageData] = useState(false);
    let [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
    let [closingMessageData, setClosingMessageData] = useState(false);
    let [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
    let [ratingMessageData, setRatingMessageData] = useState(false);
    let [loadingAutoResolve, setLoadingAutoResolve] = useState(false);
    let [autoResolveData, setAutoResolveData] = useState(false);
    let [errorWelcome, setErrorWelcome] = useState(false);
    let [errorAway, setErrorAway] = useState(false);
    let [errorClosing, setErrorClosing] = useState(false);
    let [errorRating, setErrorRating] = useState(false);
    let [errorChatbot, setErrorChatbot] = useState(false);  
    let [errorAutoResolve, setErrorAutoResolve] = useState(false);
    let [loadingAutoUnassign, setLoadingAutoUnassign] = useState(false);
    let [autoUnassignData, setAutoUnassignData] = useState(false);
    let [errorAutoUnassign, setErrorAutoUnassign] = useState(false);

    useEffect(() => {
        if (props.match.params.orgID) {
            getPhoneNumber();
        }
    }, [props.match.params.orgID]);

    useEffect(() => {
        if(phoneNumberList) {
            getAccountDetail(phoneNumberList[0].value);
        }
    }, [phoneNumberList])

    let getPhoneNumber = () => {
        setIsLoadingGetDetailData(true);
        let _retriveDataError = {...retriveDataError};

        IntegrationWhatsappBusinessServices.getPhoneNumber(props.match.params.orgID, {channelID: Number(props.match.params.id)}, (res) => {
            let _phone = [];
            
            if(res.dataResult.error.code !== "") {
                _retriveDataError.message = res.dataResult.error.message;
                _retriveDataError.code = res.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setIsLoadingGetDetailData(false);
            }else {
                if(res.dataResult.data.items === null) {
                    _retriveDataError.message = "Phone number not found";
                    _retriveDataError.code = 200;
                    setRetrieveDataError(_retriveDataError);
                    setIsLoadingGetDetailData(false);
                }else {
                    res.dataResult.data.items.map(v => {
                        v.label = v.phoneNumber.split("")[0] === "+" ? "+"+HelperInput.phoneBeautify(v.phoneNumber) : "+"+HelperInput.phoneBeautify(v.phoneNumber);
                        v.value = v.id;
                        _phone.push(v);
    
                        return null;
                    })
    
                    setPhoneVal(_phone[0]);
                    setPhoneNumberList(_phone);
                }
            }
        });
    }

    useEffect(() => {
        if(accountDetail !== null) {
          getWhatsappChannelLink()
          runGetWelcomeMessage()
          runGetAwayMessage()
          runGetClosingMessage()
          runGetRatingMessage()
          runGetAutoResolve()
          runGetAutoUnassign()
        }
    }, [accountDetail])

    useEffect(() => {
        if(whatsappChannelLink !== null || whatsappChannelLinkNeverSet) {
            getChatBot();
        }
    }, [whatsappChannelLink, whatsappChannelLinkNeverSet])

    let runGetWelcomeMessage = () => {
        setLoadingGetWelcomeMessage(true);
    
        IntegrationWhatsappBusinessServices.getWelcomeMessage(props.match.params.orgID, props.match.params.id, (response) => {
            // let _retriveDataError = {...retriveDataError};
            
            if (response.dataResult.error.message === "") {
                setWelcomeMessageData(response.dataResult.data.welcomeMessage);
            } else {
                // _retriveDataError.message = response.dataResult.error.message;
                // _retriveDataError.code = response.dataResult.error.code;
                // setRetrieveDataError(_retriveDataError);
                setErrorWelcome({
                    errorCode: response.dataResult.error.code,
                    errorMessage: response.dataResult.error.message
                });
                // doToast(response.dataResult.error.message, 'fail');
            }
    
            setLoadingGetWelcomeMessage(false);
        });
    }

    let runGetAwayMessage = () => {
        setLoadingGetAwayMessage(true);
    
        IntegrationWhatsappBusinessServices.getAwayMessage(props.match.params.orgID, props.match.params.id, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setAwayMessageData(response.dataResult.data.awayMessage);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorAway({
                errorCode: response.dataResult.error.code,
                errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
    
          setLoadingGetAwayMessage(false);
        });
    }

    let runGetClosingMessage = () => {
        setLoadingGetClosingMessage(true);
    
        IntegrationWhatsappBusinessServices.getClosingMessage(props.match.params.orgID, props.match.params.id, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setClosingMessageData(response.dataResult.data.closingMessage);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorClosing({
                errorCode: response.dataResult.error.code,
                errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
    
          setLoadingGetClosingMessage(false);
        });
    }

    let runGetRatingMessage = () => {
        setLoadingGetRatingMessage(true);
    
        IntegrationWhatsappBusinessServices.getRatingMessage(props.match.params.orgID, props.match.params.id, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setRatingMessageData(response.dataResult.data.userRating);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorRating({
                errorCode: response.dataResult.error.code,
                errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
    
          setLoadingGetRatingMessage(false);
        });
      }

    let copyToClip = (selector, text) => {
        document.getElementById(selector).select();
        document.execCommand("copy");
        doToast(text);
    };

    let barPaymentValue = () => {
        // let nextPaymentTime = accountDetail.channel.nextPaymentTime;
        // let lastPaymentTime = accountDetail.channel.lastPaymentTime;
        // let totalTimeDiff = Helper.dayDiff(nextPaymentTime, lastPaymentTime).day;
        // let currentTimeDiff = Helper.dayDiff(nextPaymentTime, new Date().valueOf())
        //   .day;
        // return 100 / (totalTimeDiff / currentTimeDiff);
        return 92;
    };
    
    // let goToEdit = (id, phoneID) => {
    //     props.history.push(`/o/${props.match.params.orgID}/integration/whatsapp-business-api/detail/${id}/${phoneID}/edit-topic`)
    // }

    // let editChannelLink = (id, phoneID) => {
    //     props.history.push(`/o/${props.match.params.orgID}/integration/whatsapp-business-api/edit-channel-link/${id}/${phoneID}`)
    // }

    let toggleModalRemoveChatbot = () => {
        setIsShowModalRemoveChatbot(!isShowModalRemoveChatbot);
    };

    let removeChatbot = () => {
        setWaitForResponseRemoveChatbot(true);
    
        let data = {
          connectionID: whatsappbaChatbot.item.id,
          channelID: parseInt(props.match.params.id),
          chatbotID: whatsappbaChatbot.item.chatbotID,
        };
        
        IntegrationWhatsappBusinessServices.removeChatBot(
          props.match.params.orgID,
          data,
          (response) => {
            if (response.dataResult.status === 200) {
              if (!response.dataResult.data.message) {
                doToast(response.dataResult.data.message);
                setWaitForResponseRemoveChatbot(false);
              } else {
                doToast(response.dataResult.data.message);
                toggleModalRemoveChatbot();
                setIsLoadingGetDetailData(true);
                setWhatsappChannelLink(null);
                setWhatsappbaChatbot(null);
                setWhatsappChannelLinkNeverSet(false);
                getAccountDetail(phoneVal.value);
              }
            } else {
              doToast(response.dataResult.data.message, 'fail');
              setWaitForResponseRemoveChatbot(false);
            }
          }
        );
      }

    const getAccountDetail = (phone) => {
        setIsLoadingGetDetailData(true);

        const data = {
            id: Number(props.match.params.id),
            phoneNumberID: phone
        }

        IntegrationWhatsappBusinessServices.getDetail(props.match.params.orgID, data, (response) => {
            let _retriveDataError = { ...retriveDataError };
            
            if (response.dataResult.error.message === "") {
                setAccountDetail(response.dataResult.data);
            } else {
                _retriveDataError.message = response.dataResult.error.message;
                _retriveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setIsLoadingGetDetailData(false);
            }
        });
    };

    const getChatBot = () => {
        IntegrationWhatsappBusinessServices.getChatBot(props.match.params.orgID, {channelID: parseInt(props.match.params.id)}, (response) => {
        //    let _retriveDataError = {...retriveDataError};
    
          if (response.dataResult.error.message === "") {
            setWhatsappbaChatbot(response.dataResult.data);
          } else {
            // setWhatsappbaChatbot(null);
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorChatbot({
                errorCode: response.dataResult.error.code,
                errorMessage: response.dataResult.error.message
            })
    
            // doToast(response.dataResult.error.message, 'fail');
            setIsLoadingGetDetailData(false);
          }
    
          setIsLoadingGetDetailData(false);
        });
    };

    let getWhatsappChannelLink = () => {
        let data = {
          id: parseInt(props.match.params.id)
        }
    
        IntegrationWhatsappBusinessServices.getChannelLink(props.match.params.orgID, data, (response) => {
          let _retriveDataError = {...retriveDataError};
          let dataResult = response.dataResult;
    
          if (dataResult.status === 200) {
            setWhatsappChannelLink(dataResult.data.channelLink);
          } else {
            if(dataResult.error.code === "40401") {
              setWhatsappChannelLinkNeverSet(true);
            }else {
              setWhatsappChannelLink(null);
              _retriveDataError.message = dataResult.error.message;
              _retriveDataError.code = dataResult.error.code;
              setRetrieveDataError(_retriveDataError); 
            }
          }
    
        //   setIsLoadingGetDetailData(false);
        });
    }

    let getChannelLink = () => {
        let link = whatsappChannelLink.url;
        const CURRENT_LOCATION = encodeURIComponent(window.location.href);
    
        link = `${link}${whatsappChannelLink.sendPageURL ? `%0A%0AFrom URL: ${CURRENT_LOCATION}` : ""}`;
        
        return link;
    }

    let renderTopics = () => {
        let text = "";
        
        accountDetail.channel.topics.map((v, i) => {
          text += `
            <div class="topic-list-content">
                <b>${v.name}</b>
            </div>
          `

          return null;
        })
    
        return text;
    }

    let onSelectPhone = (val) => {
        getAccountDetail(val.value);
        setPhoneVal(val);
    }

    let runGetAutoResolve = () => {
        setLoadingAutoResolve(true);
        AutoResolveServices.getAutoResolve(props.match.params.orgID, "whatsappba", {id: parseInt(props.match.params.id)}, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setAutoResolveData(response.dataResult.data.autoResolveConfig);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorAutoResolve({
              errorCode: response.dataResult.error.code,
              errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
      
          setLoadingAutoResolve(false);
        });
    }

    let runGetAutoUnassign = () => {
        setLoadingAutoUnassign(true);
    
        AutoUnassignServices.getAutoUnassign(props.match.params.orgID, "whatsappba", {id: parseInt(props.match.params.id)}, (response) => {
          // let _retriveDataError = {...retriveDataError};
          
          if (response.dataResult.error.message === "") {
            setAutoUnassignData(response.dataResult.data.autoUnassignAgentConfig);
          } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorAutoUnassign({
              errorCode: response.dataResult.error.code,
              errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
          }
    
          setLoadingAutoUnassign(false);
        });
    }

    return (
        <div>
            <div className="integration-main-container integration-whatsapp-ba-detail-account text-left">
                {isLoadingGetDetailData || retriveDataError.code ? (
                    <div className="channel-detail-loading-wrapper">
                        <RetrieveDataLoading
                            isLoading={isLoadingGetDetailData}
                            errorMessage={retriveDataError.message}
                        />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="integration-whatsapp-ba-detail-account-box-1">
                                <div className="integration-whatsapp-ba-detail-account-box-1-top">
                                        <b>Channel Detail</b>

                                        <div className="integration-whatsapp-ba-detail-account-box-1-top-button">
                                            <NavLink to={`/o/${props.match.params.orgID}/integration/whatsapp-business-api/detail/${accountDetail.channel.id}/${phoneVal.value}/edit-topic`}>
                                                <ButtonWithLoadingOrIcon 
                                                    className="no-fill-button main-button-40"
                                                    text="Edit"
                                                    position="left"
                                                    icon={{
                                                        type: "svg",
                                                        src: FiEdit
                                                    }}
                                                />
                                            </NavLink>
                                        </div>
                                </div>

                                <div className="integration-whatsapp-ba-detail-account-box-1-bottom">
                                    <div className="form-content-wrapper" style={{marginBottom: "16px"}}>
                                        <label>
                                            <b>Phone Number</b>
                                        </label>

                                        <CustomSelectOption
                                            isDisabled={false}
                                            optionListProps={phoneNumberList}
                                            valueDropdownProps={phoneVal}
                                            placeholderProps={"Select Phone Number"}
                                            onClickDropDownListOptionProps={onSelectPhone}
                                            specificClassNameProps="custom-select-phonenumber"
                                        />

                                        <label>
                                            <b>Channel ID</b>
                                        </label>
                                        <div className="disabled-form" style={{marginTop: "8px"}}> 
                                            <input type="text"
                                                value={accountDetail.channel.stringID}
                                                id="copy-string-id"
                                                readOnly
                                                style={{paddingRight: "40px"}}
                                            /> 
                                    
                                            <FiCopy 
                                                onClick={() => {
                                                    copyToClip("copy-string-id", "Channel ID copied")
                                                }}
                                            />
                                        </div>

                                        <label>
                                            <b>Channel Name</b>
                                        </label>
                                        <p className="value-detail">
                                            <b>{accountDetail.channel.accountName}</b>
                                        </p>
                                        
                                        {accountDetail.channel.topics.length > 0 &&
                                            <>
                                            <label>
                                                <b>Topic</b>
                                            </label>
                                            <p className="topic-val">
                                                <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                                            </p>
                                            </>
                                        }

                                        <label>
                                            <b>Status</b>
                                        </label>
                                        
                                        <p 
                                            className={`
                                                value-detail-status
                                                value-detail 
                                                value-active-${(accountDetail.channel.channelStatus === INSTANCE_STATUS.not_paid || accountDetail.channel.channelStatus === INSTANCE_STATUS.error) ? 
                                                    "red" 
                                                    : 
                                                    accountDetail.channel.channelStatus === INSTANCE_STATUS.active ? 
                                                        "green"
                                                        :
                                                        accountDetail.channel.channelStatus === INSTANCE_STATUS.waiting_setup ?
                                                            "orange"
                                                            :
                                                            ""
                                                }
                                            `}
                                        >
                                            {accountDetail.channel.channelStatus === "" ?
                                                <b>-</b>
                                                :
                                                <>
                                                    {(accountDetail.channel.channelStatus === INSTANCE_STATUS.not_paid || accountDetail.channel.channelStatus === INSTANCE_STATUS.error) ?
                                                        <img src={iconCloseRed} alt="" />
                                                        :
                                                        accountDetail.channel.channelStatus === INSTANCE_STATUS.active ?
                                                            <img src={iconCheckGreen} alt="" />
                                                            :
                                                            <FiClock />
                                                    } 
                                                    
                                                    <b>{accountDetail.channel.channelStatusText}</b>
                                                </>
                                            }
                                        </p>

                                        <label>
                                            <b>Phone Number</b>
                                        </label>

                                        <p className="value-detail">
                                            <b>+{HelperInput.phoneBeautify(accountDetail.channel.phone)}</b>
                                        </p>

                                        <label>
                                            <b>Monthly Active Users Limit</b>
                                        </label>
                                        <p className="value-detail">
                                            <b>{accountDetail.channel.mauLimit === -1 ? "Unlimited" : numberWithCommas(accountDetail.channel.mauLimit)}</b>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* channel button */}
                            <div className="integration-whatsapp-ba-detail-account-box-1 box-channel-button">
                                <div className="integration-whatsapp-ba-detail-account-box-1-top">
                                    <b>Channel Button for Live Chat</b>
                                    <p className="bot-name">
                                        For more details, <a className="link-orange" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/social-channel-button" target="_blank" rel="noopener noreferrer" >please read the documentation.</a>
                                    </p>

                                    <div className="integration-whatsapp-ba-detail-account-box-1-top-button">
                                        {!whatsappChannelLinkNeverSet && whatsappChannelLink.isEnabled &&
                                            <ButtonWithLoadingOrIcon 
                                                icon={{
                                                type: "svg",
                                                src: FiLink2
                                                }}
                                                className="no-fill-button main-button-40 button-channel-detail"
                                                text="Preview Link"
                                                position="left"
                                                onClickAction={() => {
                                                    window.open(getChannelLink(), "_blank");
                                                }}
                                            />
                                        }
                                        
                                        <NavLink to={`/o/${props.match.params.orgID}/integration/whatsapp-business-api/edit-channel-link/${accountDetail.channel.id}/${phoneVal.value}`}>
                                            <ButtonWithLoadingOrIcon 
                                                icon={{
                                                type: "svg",
                                                src: FiEdit
                                                }}
                                                className="no-fill-button main-button-40 button-channel-detail"
                                                text="Edit"
                                                position="left"
                                            />
                                        </NavLink>
                                    </div>
                                </div>

                                <div className="whatsapp-detail-channel-button">
                                    <div className="channel-button-wrapper">
                                        <b className="channel-button-title">Title</b>
                                        <br />
                                        <b>{whatsappChannelLinkNeverSet ? "Not Set" : (whatsappChannelLink.title === "" ? "Not Set" : whatsappChannelLink.title)}</b>
                                    </div>
                                    
                                    <div className="channel-button-wrapper">
                                        <b className="channel-button-title">Phone Number</b>
                                        <br />
                                        <b>{whatsappChannelLinkNeverSet ? "Not Set" : (whatsappChannelLink.targetID === "" ? "Not Set" : (whatsappChannelLink.targetID[0] === "6" ? "+" : "")+HelperInput.phoneBeautify(whatsappChannelLink.targetID))}</b>
                                    </div>

                                    <div className="channel-button-wrapper">
                                        <b className="channel-button-title">First Message</b>
                                        <br />
                                        
                                        <b 
                                            dangerouslySetInnerHTML={{__html: 
                                                whatsappChannelLinkNeverSet ? 
                                                "Not Set" 
                                                : 
                                                (whatsappChannelLink.message === "" ? "Not Set" : HelperChat.lineBreakChatRoom(whatsappChannelLink.message))
                                            }}
                                        />
                                    </div>

                                    {/* <div className="channel-button-wrapper">
                                        <b className="channel-button-title">Send Page URL</b>
                                        <br />
                                        <b>{whatsappChannelLinkNeverSet ? "Not Set" : (whatsappChannelLink.sendPageURL? "Yes" : "No")}</b>
                                    </div>  */}

                                    <div className="channel-button-wrapper status-wrapper">
                                        <b className="channel-button-title">Status</b>
                                        <br />
                                        
                                        {whatsappChannelLinkNeverSet ? 
                                            <>
                                                <img src={iconXgrey} alt="" />
                                                <b className="status-value">Disabled</b>
                                            </>
                                            :
                                            whatsappChannelLink.isEnabled ?
                                                <>
                                                <img src={statusConnected} alt="" />
                                                <b className="status-value status-enabled">Enabled</b>
                                                </>
                                                :
                                                <>
                                                <img src={iconXgrey} alt="" />
                                                <b className="status-value">Disabled</b>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* channel button */}
                        
                            {/* payment */}
                            {accountDetail.channel.nextPaymentTime !== 0 && accountDetail.channel.channelStatus !== INSTANCE_STATUS.not_paid }
                            <div className="integration-whatsapp-ba-detail-account-box-2">
                                <p className="integration-whatsapp-ba-detail-account-box-2-title">
                                    <b>Payment</b>
                                </p>

                                <div className="integration-whatsapp-ba-detail-account-box-2-payment-wrapper">
                                    <label>
                                        <b>Payment Status</b>
                                    </label>

                                    <div className="payment-info-wrapper">
                                        <React.Fragment>
                                            <p className="payment-until">
                                                <img src={iconCheckGreen} alt="" />
                                                <b>
                                                    Paid until{' '}
                                                    {Helper.formatToString(
                                                        new Date(
                                                            accountDetail.channel.nextPaymentTime
                                                        ),
                                                        'dddd, dd MMMM yyyy'
                                                    )}
                                                </b>
                                            </p>

                                            <div className="payment-bar">
                                                <div style={{ width: `${barPaymentValue()}%` }} />
                                            </div>

                                                <p className="payment-left">
                                                <b>
                                                    {
                                                        Helper.dayDiff(accountDetail.channel.nextPaymentTime, new Date().valueOf()).day
                                                    }

                                                    {' '}
                                                    
                                                    {Helper.dayDiff(accountDetail.channel.nextPaymentTime, new Date().valueOf()).day > 1 ? 
                                                        'days'
                                                        : 
                                                        'day'
                                                    }
                                                    
                                                    {' '}

                                                    {Helper.dayDiff(accountDetail.channel.nextPaymentTime, new Date().valueOf()).hour}
                                                    
                                                    {' '}
                                                    
                                                    {Helper.dayDiff(accountDetail.channel.nextPaymentTime, new Date().valueOf()).hour > 1 ? 
                                                        'hours'
                                                        : 
                                                        'hour'
                                                    }
                                                    
                                                    {' '}
                                                    
                                                    left until next billing. Last payment{' '}

                                                    {Helper.formatToString(new Date(accountDetail.channel.lastPaymentTime), 'dd MMMM yyyy')}.
                                                </b>
                                            </p>
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                            {/* payment */}

                            {/* chatbot */}
                            <div className="integration-whatsapp-ba-detail-account-box-1 box-channel-button">
                              <div className="integration-whatsapp-ba-detail-account-box-1-top padding-bottom24">
                                <b>Chatbot</b>
                                {/* <p className='bot-name'>
                                    Setup Your Chatbot
                                </p> */}

                                {!errorChatbot &&
                                    <div className="integration-whatsapp-ba-detail-account-box-1-top-button">
                                        {whatsappbaChatbot.item.id !== 0 &&
                                        <ButtonWithLoadingOrIcon 
                                            icon={{
                                            type: "img",
                                            src: iconDelete
                                            }}
                                            className="red-button main-button-40 button-channel-detail"
                                            text="Remove Chatbot"
                                            position="left"
                                            onClickAction={() => toggleModalRemoveChatbot()}
                                        />
                                        }

                                        <ButtonWithLoadingOrIcon 
                                            icon={{
                                            type: "svg",
                                            src: FiEdit
                                            }}
                                            className="no-fill-button main-button-40 button-channel-detail"
                                            text="Set Chatbot"
                                            position="left"
                                            onClickAction={() => {
                                                props.history.push({
                                                    pathname: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/edit-chatbot/${props.match.params.id}`,
                                                    state: {
                                                        accountDetail: accountDetail,
                                                        chatbot: whatsappbaChatbot.item
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            
                            {errorChatbot ?
                                <RetrieveDataLoading 
                                    isLoading={false}
                                    errorCode={errorChatbot ? errorChatbot.errorCode : false}
                                    errorMessage={errorChatbot ? errorChatbot.errorMessage : false}
                                />
                                :
                                <div className="telegram-detail-topic">
                                    <div className="channel-button-wrapper margin-top24">
                                        <b className="channel-button-title">Chatbot Name</b>
                                        <br />
                                        <b>{whatsappbaChatbot.item.id === 0 ? "Not Set" : whatsappbaChatbot.item.chatbotName}</b>
                                    </div>

                                    <div className="channel-button-wrapper margin-top24">
                                        <b className="channel-button-title">Chatbot Type</b>
                                        <br />
                                        <b>{whatsappbaChatbot.item.id === 0 ? "Not Set" : whatsappbaChatbot.item.chatbotTypeName}</b>
                                    </div>

                                    <div className="channel-button-wrapper status-wrapper margin-top24">
                                        <b className="topic-title">Status</b>
                                        <br />
                                        
                                        {whatsappbaChatbot.item.id !== 0 ? 
                                            <>
                                            <img src={statusConnected} alt="" />
                                            <b className="status-value text-green">Enabled</b>
                                            </>
                                            :
                                            <>
                                            <img src={iconXgrey} alt="" />
                                            <b className="status-value">Disabled</b>
                                            </>
                                        }
                                    </div>

                                    {/* <ButtonWithLoadingOrIcon

                                    /> */}
                                </div>
                            }
                            
                            </div>

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/auto-message/setup/${props.match.params.id}`,
                                        state: {
                                            type: type
                                        }
                                    })
                                }}
                                data={welcomeMessageData}
                                isLoading={loadingGetWelcomeMessage}
                                type="welcome"
                                error={errorWelcome}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/auto-message/setup/${props.match.params.id}`,
                                        state: {
                                            type: type
                                        }
                                    })
                                }}
                                data={awayMessageData}
                                isLoading={loadingGetAwayMessage}
                                type="away"
                                error={errorAway}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/auto-message/setup/${props.match.params.id}`,
                                        state: {
                                            type: type
                                        }
                                    })
                                }}
                                data={closingMessageData}
                                isLoading={loadingGetClosingMessage}
                                type="closing"
                                error={errorClosing}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/auto-message/setup/${props.match.params.id}`,
                                        state: {
                                            type: type
                                        }
                                    })
                                }}
                                data={ratingMessageData}
                                isLoading={loadingGetRatingMessage}
                                type="rating"
                                error={errorRating}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/auto-resolve-setup/${props.match.params.id}`,
                                        state: {
                                            type: type,
                                            medium: "whatsappba",
                                            backToLink: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/detail/${props.match.params.id}`
                                        }
                                    })
                                }}
                                data={autoResolveData}
                                isLoading={loadingAutoResolve}
                                type="autoResolve"
                                error={errorAutoResolve}
                            />

                            <IntegrationAutoMessageDetail 
                                withMarginTop
                                onClickEdit={(type) => {
                                    props.history.push({
                                        pathname: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/auto-unassign-setup/${props.match.params.id}`,
                                        state: {
                                            type: type,
                                            medium: "whatsappba",
                                            backToLink: `/o/${props.match.params.orgID}/integration/whatsapp-business-api/detail/${props.match.params.id}`
                                        }
                                    })
                                }}
                                data={autoUnassignData}
                                isLoading={loadingAutoUnassign}
                                type="autoUnassign"
                                error={errorAutoUnassign}
                            />

                            <PopupRemove
                                removeTitle={`Remove Chatbot?`}
                                removeDescription={`This chatbot will be removed from this channel.`}
                                onClickCancel={toggleModalRemoveChatbot}
                                onClickRemove={removeChatbot}
                                waitForResponse={waitForResponseRemoveChatbot}
                                showModalProps={isShowModalRemoveChatbot}
                                submitText="Delete"
                                colorButton="red-button"
                                />
                            {/* chatbot */}
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default ChannelDetail;