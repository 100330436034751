const INITIAL_STATE = {
    "search": "",
    "categoryID": "",
    "pageNumber": 1,
    "pageSize": 20,
    "sortBy": {
      label: "ID",
      value: "id"
    },
    "sortOrder": "ASC"
    // "sortOrder": ""
};
  
  let reduxReducerSalestalkFilter = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case 'SET_SALESTALK_PRODUCT_FILTER':
          return action.salesTalkProductFilter;
        case 'CLEAR_SALESTALK_PRODUCT_FILTER':
          return INITIAL_STATE;
        default:
          return state;
      }
  };
  
  export default reduxReducerSalestalkFilter;