import React, { useState } from 'react';
import './LauncherBox.scss';
import iconWa from '../../../../../assets/img/integration/launcher/icon-whatsapp2.svg'
import iconTelegram from '../../../../../assets/img/integration/launcher/icon-telegram.svg'
import iconFacebook from '../../../../../assets/img/integration/launcher/icon-facebook.svg'
import iconTwitter from '../../../../../assets/img/integration/launcher/icon-twitter.svg'
import iconLine from '../../../../../assets/img/integration/launcher/icon-line.svg'
import logoTaptalk from '../../../../../assets/img/integration/launcher/logo-taptalk.png'

const LauncherBox = ({ welcomeTab, formTab, chatroomTab, brandColorHex, rgbColorBrand }) => {
  const [hover, setHover] = useState(false);
  const [hoverFooter, setHoverFooter] = useState(false);
  const [hoverSend, setHoverSend] = useState(false);
  const [focusFullname, setFocusFullname] = useState(false);
  const [focusEmail, setFocusEmail] = useState(false);
  const [focusMessage, setFocusMessage] = useState(false);
  const [focusSendMessage, setFocusSendMessage] = useState(false);

  const brandColor = brandColorHex || 'rgba(255, 126, 0, 1)';
  const rgbColor = rgbColorBrand || { r: 255, g: 126, b: 0 };

  const toggleHover = () => {
    setHover(!hover)
  }

  const toggleHoverFooter = () => {
    setHoverFooter(!hoverFooter)
  }

  const toggleHoverSend = () => {
    setHoverSend(!hoverSend)
  }

  const hoverStyle = () => {
    if (hover) {
      return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    } else {
      return 'transparent'
    }
  }

  const hoverFooterStyle = () => {
    if (hoverFooter) {
      return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    } else {
      return 'transparent'
    }
  }

  const hoverSendStyle = () => {
    if (hoverSend) {
      return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.8)`
    } else {
      return brandColor
    }
  }

  const onFocusFullname = () => {
    setFocusFullname(true)
  }

  const onBlurFullName = () => {
    setFocusFullname(false);
  }

  const onFocusEmail = () => {
    setFocusEmail(true)
  }

  const onBlurEmail = () => {
    setFocusEmail(false);
  }

  const onFocusMessage = () => {
    setFocusMessage(true)
  }

  const onBlurMessage = () => {
    setFocusMessage(false);
  }

  const onFocusSendMessage = () => {
    setFocusSendMessage(true)
  }

  const onBlurSendMessage = () => {
    setFocusSendMessage(false);
  }

  return (
    <div
      className="taplive2-outer-container active-taplive2-power"
      style={{ display: 'block' }}
    >
      <div className="taplive2-main-chat-wrapper">
        <div className="taplive2-snack-bar-wrapper">
          <img
            src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/snackbar/exclamation-red.svg"
            className="taplive2-snack-bar-icon taplive2-red-snack-bar-icon"
            alt=""
          />
          <img
            src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/snackbar/exclamation-orange.svg"
            className="taplive2-snack-bar-icon taplive2-orange-snack-bar-icon"
            alt=""
          />
          <b className="taplive2-snack-bar-message">Unable To Connect</b>
          <img
            src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/snackbar/close-red.svg"
            className="taplive2-close-snack-bar taplive2-red-snack-bar-icon"
            alt=""
          />
          <img
            src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/snackbar/close-orange.svg"
            className="taplive2-close-snack-bar taplive2-orange-snack-bar-icon"
            alt=""
          />
        </div>
        <div
          className={`taplive2-start-new-chat-wrapper taplive2-start-new-case-wrapper taplive2-start-new-case-without-omnichannel taplive2-active-start-new-case-with-omnichannel ${formTab ? 'taplive2-active-start-new-case-wrapper' : ''
            }`}
        >
          <div
            className="taplive2-start-new-chat-top-content"
            style={{ background: brandColor }}
          >
            {/*<div className="taplive2-start-new-chat-top-content-logo-wrapper">
                      <img src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/taptalk-icon.jpg" />
                  </div>*/}
            <img
              className="first-start-new-case-back-button"
              src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-chevron-left.svg"
              alt=""
            />
            <p>
              <b>New Message</b>
            </p>
            <p>Please fill out the form below before you leave a message</p>
            <div className="taplive2-close-widget-button">
              <img
                src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/icon-close.svg"
                alt=""
              />
            </div>
          </div>
          <div className="taplive2-start-new-chat-bottom-content">
            <div className="taplive2-start-new-chat-form-wrapper taplive2-start-new-case-form-wrapper">
              <form className="taplive2-start-new-case-form" onSubmit={((e) => e.preventDefault())}>
                <label>
                  <b>Full Name</b>
                </label>
                <input
                  type="text"
                  className="taplive2-new-case-fullname"
                  placeholder="Enter full name"
                  onFocus={onFocusFullname}
                  onBlur={onBlurFullName}
                  style={focusFullname ? { boxShadow: `0 0 0 1px ${brandColor}` } : { boxShadow: 'none' }}
                />
                <label>
                  <b>Email Address</b>
                </label>
                <input
                  type="text"
                  className="taplive2-new-case-email"
                  placeholder="Enter email address"
                  onFocus={onFocusEmail}
                  onBlur={onBlurEmail}
                  style={focusEmail ? { boxShadow: `0 0 0 1px ${brandColor}` } : { boxShadow: 'none' }}
                />
                <label>
                  <b>Topic</b>
                </label>
                <div
                  className="taplive2-custom-select-option"
                  data="start-new-case"
                >
                  <div className="taplive2-custom-select-option-value-box taplive2-custom-select-option-value-box-start-new-case">
                    Select your topic
                  </div>
                  <div
                    className="taplive2-custom-select-option-wrapper taplive2-custom-select-start-new-case"
                    style={{ display: 'none' }}
                  >
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={1}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="Billing"
                    >
                      Billing
                    </div>
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={3}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="Career"
                    >
                      Career
                    </div>
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={96}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="General"
                    >
                      General
                    </div>
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={113}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="Ini Topic Yang Panjang Sekali Sangat Panjang"
                    >
                      Ini Topic Yang Panjang Sekali Sangat Panjang
                    </div>
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={104}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="Logistic"
                    >
                      Logistic
                    </div>
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={92}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="Partnership"
                    >
                      Partnership
                    </div>
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={109}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="rey topic"
                    >
                      rey topic
                    </div>
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={2}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="Technical"
                    >
                      Technical
                    </div>
                    <div
                      className="taplive2-custom-select-option-list"
                      data-value={144}
                      data-value-to="topic"
                      data-select="start-new-case"
                      data-label="Telegram"
                    >
                      Telegram
                    </div>
                  </div>
                </div>
                <label>
                  <b>Message</b>
                </label>
                <textarea
                  rows={2}
                  className="taplive2-new-case-message"
                  placeholder="Enter your message"
                  defaultValue={''}
                  onFocus={onFocusMessage}
                  onBlur={onBlurMessage}
                  style={focusMessage ? { boxShadow: `0 0 0 1px ${brandColor}` } : { boxShadow: 'none' }}
                />
                <button
                  className="taplive2-submit-new-chat taplive2-main-brand-button taplive2-submit-new-case"
                  style={{ backgroundColor: hoverSendStyle() }}
                  onMouseEnter={toggleHoverSend}
                  onMouseLeave={toggleHoverSend}
                >
                  <b>Send Message</b>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div
          className={`taplive2-start-new-chat-wrapper taplive2-start-new-case-wrapper taplive2-start-new-case-with-omnichannel ${formTab ? '' : 'taplive2-active-start-new-case-wrapper'
            }`}
        >
          <div
            className="taplive2-start-new-chat-top-content"
            style={{ background: brandColor }}
          >
            {/*<div className="taplive2-start-new-chat-top-content-logo-wrapper">
                      <img src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/taptalk-icon.jpg" />
                  </div>*/}
            <p>
              <b>Need help with anything?</b>
            </p>
            <p>
              Select your preferred channel or message us directy from here.
            </p>
            <div className="taplive2-close-widget-button">
              <img
                src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/icon-close.svg"
                alt=""
              />
            </div>
          </div>
          <div className="taplive2-start-new-chat-bottom-content">
            <div className="taplive2-start-new-case-with-omnichannel-box">
              <div className="omnichannel-option-wrapper">
                <p className="omnichannel-option-description">
                  <b>
                    Click on one of the channels to get started<b></b>
                  </b>
                </p>
                <b>
                  <b>
                    <div className="omnichannel-option-image-wrapper">
                      <div className="omnichannel-option-image-content">
                        <img
                          src={iconWa}
                          alt=""
                        />
                        <img
                          src={iconTelegram}
                          alt=""
                        />
                        <img
                          src={iconFacebook}
                          alt=""
                        />
                        <img
                          src={iconTwitter}
                          alt=""
                        />
                        <img
                          src={iconLine}
                          alt=""
                        />
                      </div>
                    </div>
                  </b>
                </b>
              </div>
              <b>
                <b>
                  <div className="omnichannel-option-separator"></div>
                  <button
                    className={`taplive2-first-message-us-directly taplive2-main-brand-nofill-button`}
                    style={{ borderColor: brandColor, color: brandColor, backgroundColor: hoverStyle() }}
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}
                  >
                    <b>Message Us Directly</b>
                  </button>
                </b>
              </b>
            </div>
            <b>
              <b></b>
            </b>
          </div>
          <b>
            <b></b>
          </b>
        </div>
        <b>
          <b></b>
        </b>
        <div className="taplive2-start-new-chat-wrapper taplive2-start-new-message-wrapper">
          <div
            className="taplive2-start-new-chat-top-content taplive2-start-new-chat-top-content-color-background"
            style={{ background: brandColor }}
          >
            <div className="taplive2-start-new-message-header">
              <img
                src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-chevron-left.svg"
                id="taplive2-close-new-message"
                alt=""
              />
              <b>New Message</b>
            </div>
          </div>
          <div className="taplive2-start-new-chat-bottom-content taplive2-start-new-chat-bottom-content-new-message new-conversation-form-wrapper">
            <div className="taplive2-start-new-chat-form-wrapper">
              <label>
                <b>Topic</b>
              </label>
              <div
                className="taplive2-custom-select-option"
                data="start-new-case"
              >
                <div className="taplive2-custom-select-option-value-box taplive2-custom-select-option-value-box-start-new-case">
                  Select your topic
                </div>
                <div
                  className="taplive2-custom-select-option-wrapper taplive2-custom-select-start-new-case"
                  style={{ display: 'none' }}
                >
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={1}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="Billing"
                  >
                    Billing
                  </div>
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={3}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="Career"
                  >
                    Career
                  </div>
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={96}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="General"
                  >
                    General
                  </div>
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={113}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="Ini Topic Yang Panjang Sekali Sangat Panjang"
                  >
                    Ini Topic Yang Panjang Sekali Sangat Panjang
                  </div>
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={104}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="Logistic"
                  >
                    Logistic
                  </div>
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={92}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="Partnership"
                  >
                    Partnership
                  </div>
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={109}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="rey topic"
                  >
                    rey topic
                  </div>
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={2}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="Technical"
                  >
                    Technical
                  </div>
                  <div
                    className="taplive2-custom-select-option-list"
                    data-value={144}
                    data-value-to="topic"
                    data-select="start-new-case"
                    data-label="Telegram"
                  >
                    Telegram
                  </div>
                </div>
              </div>
              <label>
                <b>Message</b>
              </label>
              <textarea
                rows={2}
                className="taplive2-new-message-input"
                placeholder="Enter your message"
                defaultValue={''}
              />
              <button className="taplive2-submit-new-chat taplive2-main-brand-button taplive2-submit-new-message">
                <b>Submit</b>
              </button>
            </div>
          </div>
        </div>
        <div className="taplive2-review-wrapper">
          <div className="taplive2-review-content-wrapper">
            <div className="taplive2-review-content-head">
              <img
                src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/review/icon-close.svg"
                id="taplive2-close-review-button"
                alt=""
              />
              <b>Leave a review</b>
            </div>
            <div className="taplive2-review-content-body">
              <div className="taplive2-review-content-body-star-wrapper">
                <div className="taplive2-star-image-wrapper">
                  <img
                    src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/review/stars-0.svg"
                    className="taplive2-star-image"
                    alt=""
                  />
                  <div className="taplive2-star-image-click-wrapper">
                    <div
                      className="taplive2-star-image-click-content"
                      data-star={1}
                    />
                    <div
                      className="taplive2-star-image-click-content"
                      data-star={2}
                    />
                    <div
                      className="taplive2-star-image-click-content"
                      data-star={3}
                    />
                    <div
                      className="taplive2-star-image-click-content"
                      data-star={4}
                    />
                    <div
                      className="taplive2-star-image-click-content"
                      data-star={5}
                    />
                  </div>
                </div>
                <p className="taplive2-star-word">
                  <b>No Rating</b>
                </p>
              </div>
              <textarea
                rows={3}
                placeholder="Leave a comment"
                className="taplive2-review-comment"
                defaultValue={''}
              />
            </div>
            <div className="taplive2-review-content-foot">
              <button
                className="taplive2-main-brand-button taplive2-submit-review-button"
                id="taplive2-submit-review"
              >
                <b>Submit Review</b>
              </button>
            </div>
          </div>
        </div>
        {/* taplive2-active-main-chat-room-wrapper */}
        <div
          className={`taplive2-main-chat-room-wrapper ${chatroomTab ? 'taplive2-active-main-chat-room-wrapper' : ''
            }`}
        >
          <div
            className="taplive2-main-chat-room-header"
            style={{ background: brandColor }}
          >
            <img
              className="taplive2-main-chat-room-back-button"
              src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-chevron-left.svg"
              alt=""
            />
            {/*<p className="taplive2-main-chat-room-admin-name">
                      <b>Neville Hashfoard</b>
                  </p>*/}
            <div className="taplive2-main-chat-header-wrapper">
              <p className="taplive2-main-chat-room-case-topic">
                <b>Technical</b>
              </p>
              <p className="taplive2-main-chat-room-case-id">
                <b>#123445</b>
              </p>
            </div>
          </div>
          <div className="taplive2-main-chat-room-container">
            <div className="taplive2-main-chat-room-bubble-container">
              <div className="taplive2-chat-room-date">
                <p>Today</p>
              </div>
              <div
                className="taplive2-chat-room-message-out-wrapper"
                data-chat-id="2oDow52ei2zEf2sqQgDzJn4nQuEMB7ny"
              >
                <div
                  className="taplive2-message-out-bubble"
                  style={{ background: brandColor }}
                >
                  <span className="message-body">
                    Hey there, I’m having some technical issues with my device.
                    Can you please help me?
                  </span>
                  <p className="taplive2-message-info">
                    22/9/2020
                    <span className="taplive2-centered-dot taplive2-centered-dot-white" />
                    15:52
                    <img
                      src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-double-check-dark.svg"
                      alt=""
                    />
                  </p>
                </div>
              </div>
              <div
                className="taplive2-chat-room-message-in-wrapper"
                data-chat-id="dlrDRSLH5aeneDIc0MQdwb8k_4ZXr8Z_"
              >
                <div
                  className="taplive2-group-sender-avatar-wrapper"
                  style={{ background: '#f4c22c' }}
                >
                  <b>PT</b>
                </div>
                <div className="taplive2-message-in-bubble">
                  <p className="taplive2-group-sender-name-wrapper">
                    <b>PT. Tap Talk Teknologi</b>
                  </p>
                  <span className="message-body">
                    Hey there <span role='img' aria-label="hello">👋</span>. You’ve reached us outside our office hours
                    Mon-Fri 09:00-17:30. We’ll get back to you during these
                    hours.
                  </span>
                  <p className="taplive2-message-info">
                    22/9/2020
                    <span className="taplive2-centered-dot" />
                    15:52
                  </p>
                  {/* "messageActionView(props.singleChatDataProps.body)" */}
                </div>
              </div>
            </div>
          </div>
          {/* chatroom */}
          <div className="taplive2-main-chat-room-send-message-panel-wrapper">
            <div
              className="taplive2-main-chat-room-send-message-hamburger taplive2-mark-as-solved-case-panel-toggle"
              style={{ background: brandColor }}
            >
              <img
                src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-hamburger.svg"
                alt=""
              />
            </div>
            <div className="taplive2-main-chat-room-send-message-input">
              <textarea
                type="text"
                className="taplive2-input-text"
                rows={1}
                defaultValue={''}
                placeholder="Send a message…"
                onFocus={onFocusSendMessage}
                onBlur={onBlurSendMessage}
                style={focusSendMessage ? { boxShadow: `0 0 0 1px ${brandColor}` } : { boxShadow: '0 0 0 1px #dcdcdc' }}
              />
              <img
                src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-attach.svg"
                id="taplive2-icon-attach-input-message"
                alt=""
              />
              <div className="taplive2-input-file-media-wrapper">
                <input type="file" id="taplive2-input-file-document" />
                <label htmlFor="taplive2-input-file-document">
                  <div className="taplive2-input-file-media-list">
                    <img
                      src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/bubble/icon-paper-black.svg"
                      alt=""
                    />
                    Document
                  </div>
                </label>
                <input
                  type="file"
                  id="taplive2-input-file-media"
                  accept="image/*, video/*"
                />
                <label htmlFor="taplive2-input-file-media">
                  <div className="taplive2-input-file-media-list">
                    <img
                      src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-media-black.svg"
                      alt=""
                    />
                    Media
                  </div>
                </label>
              </div>
            </div>
            <div
              className="input-message-wrapper button-disabled"
              style={{ display: 'none' }}
            >
              <img
                src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-airplane-white.svg"
                alt=""
              />
            </div>
            <div
              className="taplive2-main-chat-room-solve-wrapper taplive2-main-chat-room-solve-wrapper-hide"
              id="taplive2-chat-room-mark-as-solved-button"
            >
              <img
                src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-check-black.svg"
                alt=""
              />
              <b>Mark as resolved</b>
            </div>
          </div>
        </div>
      </div>
      <div className="taplive2-power">
        <div style={{ backgroundColor: hoverFooterStyle() }} onMouseEnter={toggleHoverFooter} onMouseLeave={toggleHoverFooter}>
          <a
            href="https://taptalk.io/onetalk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Powered by
            <img
              src={logoTaptalk}
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="taplive2-fullscreen-background-dark taplive2-image-preview-panel">
        <div>
          <img
            src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/icon-close.svg"
            className="taplive2-close-blackscreen taplive2-close-preview-panel"
            alt=""
          />
          <img className="taplive2-image-preview-value" src="" alt="" />
        </div>
      </div>
      <div className="taplive2-fullscreen-background-dark taplive2-video-preview-panel">
        <div>
          <img
            src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/icon-close.svg"
            className="taplive2-close-blackscreen taplive2-close-preview-panel"
            alt=""
          />
          <video className="taplive2-video-preview-value" src="" controls />
        </div>
      </div>
      <div className="taplive2-fullscreen-background-dark taplive2-video-preupload-panel">
        <div>
          <img
            src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/icon-close.svg"
            className="taplive2-close-blackscreen taplive2-close-preupload-panel"
            alt=""
          />
          <div className="taplive2-video-preupload-file-wrapper taplive2-preupload-file-wrapper">
            <video className="taplive2-video-preupload-value" src="" controls />
            <input
              type="text"
              placeholder="Your caption here"
              className="taplive2-caption-input"
              maxLength={100}
            />
            <img
              src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-airplane-white.svg"
              className="taplive2-send-media-button-submit"
              id="taplive2-submit-video"
              alt=""
            />
            <div className="taplive2-caption-limit">0/100</div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="taplive2-fullscreen-background-dark taplive2-image-preupload-panel">
        <div>
          <img
            src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/icon-close.svg"
            className="taplive2-close-blackscreen taplive2-close-preupload-panel"
            alt=""
          />
          <div className="taplive2-image-preupload-file-wrapper taplive2-preupload-file-wrapper">
            <img className="taplive2-image-preupload-value" src="" alt="" />
            <input
              type="text"
              placeholder="Your caption here"
              className="taplive2-caption-input"
              maxLength={100}
            />
            <img
              src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/chat-room/icon-airplane-white.svg"
              className="taplive2-send-media-button-submit"
              id="taplive2-submit-image"
              alt=""
            />
            <div className="taplive2-caption-limit">0/100</div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LauncherBox;
