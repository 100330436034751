import React, { useEffect } from "react";
import "./IntegrationLine.scss";
import LineIcon from "../../../assets/img/integration/logo-line.svg";
// import Integrations from "../Integrations";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import { checkID } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const IntegrationLine = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = React.useState(false);

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };
    
    useEffect(() => {
        doMount();
    }, [])

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(orgID) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Page] Line Integration",
                    {
                        userFullName : myAgentData.account.fullName,
                        userID : myAgentData.account.id,
                        organizationID : orgID.split("-")[0]
                    }
                );
            }
        }
    }, [orgID])

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                {/* <Integrations activeIntegrationChannel="line" parentProps={props} orgIDProps={orgID} /> */}

                <div className="sectionWrap integration-main-container">
                    <img className="icon-integration-content" src={LineIcon} alt="" />
                    <br />
                    <b>Coming soon</b>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationLine;