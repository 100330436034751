import React from "react";
import "./SalesTalkShimmerProductComp.scss";
import { connect } from 'react-redux';

let SalesTalkShimmerProductComp = (props) => {
    let boxList = [{}, {}];
    // let { withIconWarning, title, desc } = props;

    return (
        <div className="shimmer-product-component">
            {boxList.map((v, k) => {
                return (
                    <div className="main-shimmer-wrapper" key={`main-shimmer-wrapper-${k}`}>
                        <div className="shimmer-image taplive-shine" />
                        <div className="shimmer-title taplive-shine" />
                        <div className="shimmer-title taplive-shine" />
                        <div className="shimmer-title taplive-shine" />
                    </div>
                )
            })}
        </div>
    )
}


let mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    // salesTalkInbox: state.salesTalkInbox
})

let mapDispatchToProps = {
    // setSalesTalkInbox
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTalkShimmerProductComp);