import React, { useState, useEffect } from 'react';
import './PayMethods.scss';
import NewOrganizationHeader from "../../newOrganizationHeader/NewOrganizationHeader";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import autoIcon from '../../../../assets/img/select-tier/auto-method.svg';
import manualIcon from '../../../../assets/img/select-tier/manual-method.svg';
import { FiAlertCircle } from 'react-icons/fi';
import { connect } from 'react-redux';
import { setSelectedTier, clearSelectedTier } from "../../../../redux/actions/reduxActionSelectedTier";

const PayMethods = (props) => {
  let [isAllow, setIsAllow] = useState(false);

  const back = () => {
    props.history.push(`/o/${props.match.params.orgID}/select-tier`)
  }

  const autoOnClick = () => {
    let _selectedTier = {...props.selectedTier};
    _selectedTier.paymentSetting.isAutomaticPayment = true;
    props.setSelectedTier(_selectedTier);
    onContinue();
  }

  const manualOnClick = () => {
    let _selectedTier = {...props.selectedTier};
    _selectedTier.paymentSetting.isAutomaticPayment = false;
    props.setSelectedTier(_selectedTier);
    onContinue();
  }

  const onContinue = () => {
    if(props.selectedTier.paymentSetting.isSet) {
      props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction`);
    }else {
      if(props.selectedTier.paymentSetting.isAutomaticPayment === true) {
        props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods/add-card`);
      }

      if(props.selectedTier.paymentSetting.isAutomaticPayment === false) {
        props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction`);
      }
    }
  }

  useEffect(() => {
    if(!props.selectedTier) {
      props.history.push(`/o/${props.match.params.orgID}/home`)
    }else {
      setIsAllow(true);
    }
  }, [props.selectedTier])

  return (
    <>
      <NewOrganizationHeader
        titleText="Start Subscription"
        hideLogout
        // topRightContent={renderStep}
      />

      <div className="pay-methods-main-wrapper">
        <SectionWrap
            {...props}
            orgID={props.match.params.orgID}
            _className="section-width-100 pos-relative"
            barVerifyClass="margin-top-0"
        >
          {/* <div className="main-content-loading-screen">
            <div className="lds-ring">
              <div /><div /><div /><div />
            </div>
          </div> */}

          <div className="pay-methods-content">
            {isAllow &&
              <>
                <div className="pay-methods-header">
                  <BackButton text="Back to Select Tier" onClick={back} />

                  {/* {continueButton ?
                    <button className="orange-button cont-button" disabled>
                      <div className="lds-ring grey-lds-ring">
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                      <div>Continue</div>
                    </button>
                    : */}
                    {/* <button className="orange-button button" onClick={onContinue} disabled={props.selectedTier.paymentSetting.isAutomaticPayment === null}>
                      Continue
                    </button> */}
                  {/* } */}
                </div>


                <div className="pay-methods-container">
                  <h1 className="pay-title">Select How You Pay</h1>
                  
                  {/* {props.selectedTier.freeTrialDays > 0 &&
                    <div className="alert-component">
                      <FiAlertCircle />
                      <b>
                        Please choose manual payment method if you wish to try free trial without a credit card. 
                        You can change to automatic payment method at anytime you want.
                      </b>
                    </div>
                  } */}

                  <div className="pay-methods-wrapper">
                    <div className="pay-methods-auto">
                      <img src={autoIcon} alt="" />
                      <b>Automatic Payments</b>
                      <p>Automatically charge the selected primary payment method.</p>
                      <button className={`select-button ${props.selectedTier.paymentSetting.isAutomaticPayment === true ? "orange-button" : "light-orange-button"}`} onClick={autoOnClick}>
                        {/* {props.selectedTier.paymentSetting.isAutomaticPayment === true  ? <><FiCheck /><b>Selected Method</b></> : <b>Select Method</b>} */}
                        <b>Select Method</b>
                      </button>
                    </div>
                    <div className="pay-methods-manual">
                      <img src={manualIcon} alt="" />
                      <b>Manual Payments</b>
                      <p>Make payments manually via bank transfers.</p>
                      <div className="alert-component">
                        <FiAlertCircle />
                        <b>
                          No credit card required 
                        </b>
                      </div>

                      <button className={`select-button ${props.selectedTier.paymentSetting.isAutomaticPayment === false ? "orange-button" : "light-orange-button"}`} onClick={manualOnClick}>
                        {/* {props.selectedTier.paymentSetting.isAutomaticPayment === false ? <><FiCheck /><b>Selected Method</b></> : <b>Select Method</b>} */}
                        <b>Select Method</b>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </SectionWrap>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  selectedTier: state.selectedTier
});

const mapDispatchToProps = {
  setSelectedTier,
  clearSelectedTier
};

export default connect(mapStateToProps, mapDispatchToProps)(PayMethods);