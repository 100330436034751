import React from "react";
import "./SortBy.scss";
import { FiChevronDown, FiCheck } from "react-icons/fi";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const SortBy = (props) => (
  <div className="sort-by-content-wrapper">
    <Dropdown isOpen={props.isShowSortByOptionProps} toggle={props.toggleSortByOpenCaseProps} disabled={props.disabledSortByProps}>
        <DropdownToggle>
          <FiChevronDown className={props.isShowSortByOptionProps ? "active-sort-by-menu" : ""} />
          <b>Sort By: {props.sortByVal.label}</b>
        </DropdownToggle>
      <DropdownMenu>
        {Object.keys(props.sortByList).map((val, index) => {
          return (
            <DropdownItem 
              key={`sory-by-${index}`}
              onClick={() => {
                props.actionSwitchSortByProps(props.valueWithObject ? props.sortByList[val] : props.sortByList[val].value)
              }}
              className={props.sortByVal.value === props.sortByList[val].value ? "active-sort-by-option" : ""}
            >
              {props.sortByVal.value === props.sortByList[val].value && <FiCheck />}
              <b>{props.sortByList[val].label}</b>
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  </div>
);

export default SortBy;
