const INITIAL_STATE = false;

let reduxReducerActiveQuickReplyInteractive = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_QUICK_REPLY_INTERACTIVE':
        return action.activeQuickReplyInteractive;
      case 'CLEAR_ACTIVE_QUICK_REPLY_INTERACTIVE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerActiveQuickReplyInteractive;