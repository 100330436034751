import React from "react";
import { Link } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import "./TierBlockingScreen.scss";
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";

const TierBlockingScreen = (props) => {
    let isSubmenuExist = () => {
        return typeof props.sideMenu === "object" && props.sideMenu.submenuList;
    }

    let renderTierHasError = () => {
        return (
            <div className={`please-select-tier-wrapper`}>
                <React.Fragment>
                    <p className="please-select-tier-alert">
                        <FiAlertTriangle />
                        <b>{props.organizationErrorProps.message}</b>
                    </p>

                    <p className="please-select-tier-description">
                        Please contact <a href="mailto:sales@taptalk.io"><b className="orange-text">sales@taptalk.io</b></a> if you need any assistance.
                    </p>

                    {props.goHomeButton && 
                        <Link to={`/o/${props.match.params.orgID}/home`}>
                            <button className="orange-button">
                                <b>Go To Home</b>
                            </button>
                        </Link>
                    }
                </React.Fragment>
            </div>
        )
    }

    let renderPleaseSelectTier = () => {
        return (
            <div className={`please-select-tier-wrapper`}>
                {props.currentMembershipProps.isOwner ?
                    <React.Fragment>
                        <p className="please-select-tier-alert">
                            <FiAlertTriangle />
                            <b>You Have No Active Subscription</b>
                        </p>

                        <p className="please-select-tier-description">
                            You will need to subscribe to one of OneTalk's tier in order to keep using this services
                        </p>

                        <Link 
                            to={`/o/${props.match.params.orgID}/select-tier`}
                            onClick={() => {
                                let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                
                                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                    mixpanel.track(
                                        "[Button] Start Subscription",
                                        {
                                            userFullName : myAgentData.account.fullName,
                                            userID : myAgentData.account.id,
                                            organizationID : props.match.params.orgID.split("-")[0]
                                        }
                                    )
                                }
                            }}
                        >
                            {/* to={{
                                pathname: `/o/${props.match.params.orgID}/select-tier`,
                                state: { currentTier: props.currentTierDetailProps }
                            }}
                        > */}
                            <button className="orange-button">
                                <b>Start Subscription</b>
                            </button>
                        </Link>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <p className="please-select-tier-alert">
                            <FiAlertTriangle />
                            <b>Please Contact the Owner</b>
                        </p>

                        <p className="please-select-tier-description">
                            The organization owner needs to select a tier in order to keep using OneTalk’s services. Please contact the owner to continue.
                        </p>

                        {props.goHomeButton && 
                            <Link to={`/o/${props.match.params.orgID}/home`}>
                                <button className="orange-button">
                                    <b>Go To Home</b>
                                </button>
                            </Link>
                        }
                    </React.Fragment>
                }
            </div>
        )
    }

    let renderTierIsNotActive = () => {
        return (
            <div className="please-select-tier-wrapper">
                <p className="please-select-tier-alert">
                    <FiAlertTriangle />
                    <b>Your subscription is not active</b>
                </p>

                <p className="please-select-tier-description">
                    Please contact <a href="mailto:sales@taptalk.io"><b className="orange-text">sales@taptalk.io</b></a> if you need any assistance.
                </p>
            </div>
        )
    }

    let renderTierIsSuspended = () => {
        return (
            <div className="please-select-tier-wrapper">
                <p className="please-select-tier-alert">
                    <FiAlertTriangle />
                    <b>Your subscription is suspended</b>
                </p>

                <p className="please-select-tier-description">
                    Please contact <a href="mailto:sales@taptalk.io"><b className="orange-text">sales@taptalk.io</b></a> if you need any assistance.
                </p>
            </div>
        )
    }

    return (
        <div className={`tier-home-wrapper ${!isSubmenuExist() ? "deactive-sub-menu" : ""} ${props.billingWarning ? "tier-home-active-billing-warning" : ""}`}>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                _className="section-width-100 pos-relative"
            >
                {!props.allOrganizationDetail ?
                    renderTierHasError()
                    :
                    props.allOrganizationDetail.selectedTier.id === 0 ?
                        renderPleaseSelectTier()
                        :
                        props.allOrganizationDetail.selectedTier.isSuspended ?
                            renderTierIsSuspended()
                            :
                            !props.allOrganizationDetail.selectedTier.isActive &&
                                renderTierIsNotActive()
                }
            </SectionWrap>
        </div>
    )
};

const mapStateToProps = state => ({
    sideMenu: state.sideMenu,
    billingWarning: state.billingWarning,
    allOrganizationDetail: state.allOrganizationDetail
});

export default connect(mapStateToProps, null)(TierBlockingScreen)