import React, { useState, useEffect } from "react";
import "./ChatRoomProductSelectedModal.scss";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { FiX, FiTrash2 } from "react-icons/fi";
import { PRODUCT_PREFIX } from "../../../constants/taplive";
import { rupiahFormat, isEmptyObject } from "../../../helper/HelperGeneral";
import { setSalestalkSelectedProduct } from "../../../redux/actions/reduxActionSalestalkSelectedProduct";
import { setSalestalkProductDetail } from "../../../redux/actions/reduxActionSalestalkProductDetail";
import { setSalesTalkInbox } from "../../../redux/actions/reduxActionSalesTalkInbox";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupConfirmation from "../../reuseableComponent/popupConfirmation/PopupConfirmation";
import { Scrollbars } from 'react-custom-scrollbars';
import Airplane from '../../../assets/img/icon-airplane.svg';

let ChatRoomProductSelectedModal = (props) => {
    let { salesTalkSelectedProduct, setSalestalkSelectedProduct, setSalesTalkInbox } = props;
    let [isShowModalSend, setIsShowModalSend] = useState(false);

    useEffect(() => {
        if(isEmptyObject(salesTalkSelectedProduct.data)) {
            toggleModalSelectedProduct();
        }
    }, [salesTalkSelectedProduct.data])

    let style = {
        scrollStyle: {
            position: "relative",
            backgroundColor: "#ff7d00",
            right: "-4px",
            borderRadius: "8px",
            width: "4px"
        }
    };

    let toggleModalSelectedProduct = () => {
        let _salesTalkSelectedProduct = {...salesTalkSelectedProduct};
        _salesTalkSelectedProduct.isShow = false;
        setSalestalkSelectedProduct(_salesTalkSelectedProduct);
    }

    let toggleModalSend = () => {
        setIsShowModalSend(!isShowModalSend);
    }

    let onSelectProduct = (_data) => {
        let _salesTalkSelectedProduct = {...salesTalkSelectedProduct};
        let _salesTalkSelectedProductData = {..._salesTalkSelectedProduct.data};
        if(_salesTalkSelectedProductData[PRODUCT_PREFIX + _data.id]) {
            delete _salesTalkSelectedProductData[PRODUCT_PREFIX + _data.id];

            if(Object.keys(_salesTalkSelectedProductData).length === 0) {
                _salesTalkSelectedProduct.isShow = false;
            }
        }

        _salesTalkSelectedProduct.data = _salesTalkSelectedProductData;
        setSalestalkSelectedProduct(_salesTalkSelectedProduct);
    }

    let onClickUnSelectAll = () => {
        let _salesTalkSelectedProduct = {...salesTalkSelectedProduct};
        _salesTalkSelectedProduct.data = {};

        setSalestalkSelectedProduct(_salesTalkSelectedProduct);
    }

    let sendSelectedProduct = () => {
        let _salesTalkSelectedProduct = {...salesTalkSelectedProduct};
        let _salesTalk = {...props.salesTalkInbox};
        _salesTalk.isShow = false;
        _salesTalkSelectedProduct.lastSend = new Date().valueOf();
        _salesTalkSelectedProduct.isShow = false;
        toggleModalSend();
        
        setSalesTalkInbox(_salesTalk);
        setSalestalkSelectedProduct(_salesTalkSelectedProduct);
    }

    return (
        <>
            <Modal isOpen={salesTalkSelectedProduct.isShow} className="modal-selected-product" toggle={toggleModalSelectedProduct}>
                <ModalBody>
                    <div className="modal-header">
                        <p>
                            <b>Selected Products</b>
                            <span>({Object.keys(salesTalkSelectedProduct.data).length} Item{Object.keys(salesTalkSelectedProduct.data).length > 1 ? "s" : ""})</span>
                        </p>
                        
                        <FiX onClick={toggleModalSelectedProduct} />
                    </div>

                    <div className="sales-talk-product-selected-outer">
                        <Scrollbars 
                            autoHide 
                            autoHideTimeout={500}
                            renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
                        >   
                            {Object.keys(salesTalkSelectedProduct.data).length > 0 &&
                                Object.keys(salesTalkSelectedProduct.data).map((v, k) => {
                                    return (
                                        <div className="sales-talk-product-selected-comp-box" key={`sales-talk-item-${k}`}>
                                            <div className="sales-talk-product-selected-comp-image-wrapper">
                                                <img 
                                                    src={salesTalkSelectedProduct.data[v].imageURL}
                                                    alt=""
                                                />

                                                <div className="custom-checkbox custom-checkbox-circle">
                                                    {/* <input 
                                                        type="checkbox" 
                                                        id={`product-${k}`} 
                                                        checked={salesTalkSelectedProduct.data[salesTalkSelectedProduct.data[v].id]} 
                                                        onClick={() => onSelectProduct(salesTalkSelectedProduct.data[v])}  */}
                                                    {/* /> */}

                                                    <FiTrash2
                                                        onClick={() => onSelectProduct(salesTalkSelectedProduct.data[v])}
                                                        className="remove-product-icon"
                                                    />

                                                    <label htmlFor={`product-${k}`} />
                                                </div>    

                                            </div>

                                            <p 
                                                className="product-title" 
                                                onClick={() => {
                                                    let _salesTalkSelectedProduct = {...props.salesTalkSelectedProduct};
                                                    _salesTalkSelectedProduct.isShow = false;
                                                    _salesTalkSelectedProduct.isFromSelectedModal = true;
                                                    props.setSalestalkSelectedProduct(_salesTalkSelectedProduct); 

                                                    let _salesTalkProductDetail = {...props.salesTalkProductDetail};
                                                    _salesTalkProductDetail.isShow = true;
                                                    _salesTalkProductDetail.data = salesTalkSelectedProduct.data[v];

                                                    props.setSalestalkProductDetail(_salesTalkProductDetail);
                                                }}
                                            >
                                                <b>{salesTalkSelectedProduct.data[v].name}</b>
                                            </p>
                                            <p className="product-price">{rupiahFormat(salesTalkSelectedProduct.data[v].prices[0].unitPrice)}</p>
                                            <p className="product-stock">STOCK: {salesTalkSelectedProduct.data[v].stock}</p>
                                        </div>
                                    )
                                })
                            }
                        </Scrollbars>   
                    </div>

                    <div className="modal-foot">
                        <ButtonWithLoadingOrIcon 
                            text="Remove All Products"
                            className="main-button-48 no-fill-button"
                            onClickAction={onClickUnSelectAll}
                        />

                        <ButtonWithLoadingOrIcon 
                            text="Send Selected Products"
                            className="main-button-48 orange-button"
                            icon={{
                                type: "img",
                                src: Airplane
                            }}
                            position="left"
                            onClickAction={toggleModalSend}
                        />
                    </div>
                </ModalBody>
            </Modal>

            <PopupConfirmation
                isOpen={isShowModalSend}
                toggle={toggleModalSend}
                title="Send Selected Products?"
                description="You will be sending the products you have selected and this action cannot be undone. Please make sure you have all the products you want to send."
                className="modal-confirmation-send-product"
            >
                <ButtonWithLoadingOrIcon
                    text="Send Selected Products"
                    className="main-button-48 orange-button"
                    onClickAction={sendSelectedProduct}
                />
            </PopupConfirmation>
        </>
    )
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    salesTalkSelectedProduct: state.salesTalkSelectedProduct,
    salesTalkProductDetail: state.salesTalkProductDetail,
    salesTalkInbox: state.salesTalkInbox
});
  
const mapDispatchToProps = {
    setSalestalkSelectedProduct,
    setSalestalkProductDetail,
    setSalesTalkInbox
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomProductSelectedModal);