import React, { useEffect, useState } from "react";
import "./FormInteractiveMessage.scss";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { FiX, FiInfo, FiPlus, FiTrash2 } from "react-icons/fi";
import IconAttachMessage from '../../../../../../assets/img/icon-attach-message-black.svg';
import { scrollToClass } from "../../../../../../helper/HelperGeneral";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupDiscard from "../../../../../reuseableComponent/popupDiscard/PopupDiscard";
import ErrorOrInfoComp from "../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import FormInteractiveMessageHeaderType from "./FormInteractiveMessageHeaderType/FormInteractiveMessageHeaderType";
import FormInteractiveMessagePreview from "../FormInteractiveMessagePreview/FormInteractiveMessagePreview";

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-2px",
    width: "5px",
    borderRadius: "8px",
  },
};

const DEF_DATA = {
  headerType: "",
  headerText: "",
  document: false,
  body: "",
  footer: "",
  buttonText: [""].slice()
};

const DEF_ERROR = {
  headerText: "",
  headerType: "",
  document: "",
  body: "",
  footer: "",
  buttonText: [""].slice()
};

let FormInteractiveMessage = (props) => {
  let { isShowModalFormInteractive, toggle, type, parentProps, caseDetailData } = props;
  let [isShowModalFormInteractivePreview, setIsShowModalFormInteractivePreview] = useState(false);
  let [data, setData] = useState(JSON.parse(JSON.stringify(DEF_DATA)));
  let [dataOrigin, setDataOrigin] = useState(JSON.parse(JSON.stringify(DEF_DATA)));
  let [errorMessageData, setErrorMessageData] = useState(JSON.parse(JSON.stringify(DEF_ERROR)));
  let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);

  useEffect(() => {
    setDataOrigin(JSON.parse(JSON.stringify(DEF_DATA)));
    if(props.activeQuickReplyInteractive) {
      if(props.activeQuickReplyInteractive.type === "button") {
        let _whatsapp = JSON.parse(JSON.stringify(props.activeQuickReplyInteractive));
        let _buttons = [];
        let _buttonsErr = [];
        _whatsapp.buttonAction.buttons.map((v) => {
          _buttons.push(v.title);
          _buttonsErr.push("");
          return null;
        })
  
        let _quickReplyData = {
          mediaFilename: _whatsapp.mediaFilename,
          headerType: _whatsapp.header ? (_whatsapp.header.type === "document" ? _whatsapp.header.type : "media") : "no_header",
          headerText: _whatsapp.header ? (_whatsapp.header.type === "text" ? _whatsapp.header.text : "") : "",
          document: false,
          body: _whatsapp.bodyText,
          footer: _whatsapp.footerText ? _whatsapp.footerText : "",
          buttonText: _buttons,
          isDefaultDoc: _whatsapp.header ? (_whatsapp.header.type === "text" ? false : true) : false
        };
  
        if(_whatsapp.header) {
          if(_whatsapp.header.type === "text") {
            _quickReplyData.headerType = "text";
          }
  
          if(_whatsapp.header.type === "document") {
            _quickReplyData.document = {
              name: _whatsapp.header.document.filename,
              fileURL: _whatsapp.header.document.link
            }
          }
  
          if(_whatsapp.header.type === "image") {
            _quickReplyData.document = {
              type: "image/media",
              fileURL: _whatsapp.header.image.link
            }
          }
  
          if(_whatsapp.header.type === "video") {
            _quickReplyData.document = {
              type: "video/media",
              fileURL: _whatsapp.header.video.link
            }
          }
        }
  
        setData(_quickReplyData);
  
        setErrorMessageData({
          headerText: "",
          headerType: "",
          document: "",
          body: "",
          footer: "",
          buttonText: _buttonsErr,
        })
      }
    }
  }, [props.activeQuickReplyInteractive])
 
  let toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard);
  }

  // let closeModalPreview = () => {
  //   setIsShowModalFormInteractivePreview(false);
  // }

  let toggleModalPreview = (isClose = true) => {
    if(isClose) {
      onCloseClick(type, true);
    }else {
      onCloseClick("preview", true);
    }

    setIsShowModalFormInteractivePreview(!isShowModalFormInteractivePreview);
  }
  
  let onCloseClick = (type = "", ignoreDiscard = false) => {
    if(JSON.stringify(data) !== JSON.stringify(dataOrigin)) {
      if(ignoreDiscard === false) {
        toggleModalDiscard();
      }else {
        toggle(type === "" ? "" : type);
      }
    }else {
      if(!ignoreDiscard) {
        setErrorMessageData(JSON.parse(JSON.stringify(DEF_ERROR)));
      }

      toggle(type === "" ? "" : type);
    }
  };

  let onContinue = () => {
    let errorCount = "";
    let scrollTo = "";
    let _errorMessageData = {...errorMessageData};
    
    if(data.headerType === "") {
      _errorMessageData.headerType = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
    }

    if(data.headerType === "text") {
      if(data.headerText === "") {
        _errorMessageData.headerText = "This field is required";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
      }

      if(data.headerText.length > 60) {
        _errorMessageData.headerText = "Characters exceed limit";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
      }
    }

    if(data.headerType === "media" || data.headerType === "document") {
      if(data.document === false) {
        _errorMessageData.document = "This field is required";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
      }
    }

    if(data.body.length > 1024) {
      _errorMessageData.body = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-body"; }
    }

    if(data.body === "") {
      _errorMessageData.body = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-body"; }
    }

    if(data.footer.length > 60) {
      _errorMessageData.footer = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-footer"; }
    }

    data.buttonText.map((v, i) => {
      if(v.length > 20) {
        _errorMessageData.buttonText[i] = "Characters exceed limit";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-button-text-${i}`; }
      }

      if(v === "") {
        _errorMessageData.buttonText[i] = "This field is required";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-button-text-${i}`; }
      }

      if(_errorMessageData.buttonText[i] !== "") {
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-button-text-${i}`; }
      }

      return null;
    });

    _errorMessageData.buttonText.map((v, i) => {
      if(v !== "") {
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-button-text-${i}`; }
      }

      return null;
    })

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${scrollTo}`);
    }else {
      toggleModalPreview(false);
    }
  };

  let onChangeDocument = (file = false) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.document = "";
    _data.document = file === false ? file : file[0];
    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let onChangeText = (id, val) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _data[id] = val;
    _errorMessageData[id] = "";

    if(id === "headerText" && val.length > 20) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "body" && val.length > 1024) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "footer" && val.length > 60) {
      _errorMessageData[id] = "Characters exceed limit";
    }
    
    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let check_duplicate_in_array = (input_array)=>{
    let duplicates = input_array.filter((item, index) =>input_array.indexOf(item) !== index);
    return Array.from(new Set(duplicates));
  }

  let onChangeButtonText = (val, idx) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.buttonText[idx] = "";
    _data.buttonText[idx] = val;
    let dupeArr = [];

    let duplicateVal = check_duplicate_in_array(_data.buttonText);

    if(duplicateVal[0] !== "") {
      _data.buttonText.filter((v, i) => {
        if(v === duplicateVal[0]) {
          dupeArr.push(i);
        }

        return null;
      })
    }

    _data.buttonText.map((v, i) => {
      if(_errorMessageData.buttonText[i] !== "This field is required" && _errorMessageData.buttonText[i] !== "Characters exceed limit") {
        if(v.length > 20) {
          _errorMessageData.buttonText[i] = "Characters exceed limit";
        }else {
          _errorMessageData.buttonText[i] = "";
        }
      }

      return null;
    })
    
    if(dupeArr.length > 0) {
      dupeArr.map((v) => {
        _errorMessageData.buttonText[v] = "You can't enter the same text for multiple buttons";

        return null;
      })
    }

    if(val.length > 20) {
      _errorMessageData.buttonText[idx] = "Characters exceed limit";  
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let addButtonText = () => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};

    if(_data.buttonText.length < 3) {
      _data.buttonText.push("");
      _errorMessageData.buttonText.push("");
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let removeButtonText = (idx) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _data.buttonText.splice(idx, 1);
    _errorMessageData.buttonText.splice(idx, 1);

    let dupeArr = [];
    
    let duplicateVal = check_duplicate_in_array(_data.buttonText);

    if(duplicateVal[0] !== "") {
      _data.buttonText.filter((v, i) => {
        if(v === duplicateVal[0]) {
          dupeArr.push(i);
        }

        return null;
      })
    }

    _errorMessageData.buttonText.map((v, i) => {
      let _findIdx = dupeArr.findIndex(_v => _v === i);
      if(v === "You can't enter the same text for multiple buttons" && _findIdx === -1) {
        if(_data.buttonText[i].length > 20) {
          _errorMessageData.buttonText[i] = "Characters exceed limit";
        }else {
          _errorMessageData.buttonText[i] = "";
        }
      }

      return null;
    })

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let onChangeHeaderType = (type) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _data.headerType = type;
    _data.headerText = "";
    _data.document = false;
    _errorMessageData.headerType = "";
    _errorMessageData.headerText = "";
    _errorMessageData.document = ""

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let runErrorDoc = (text) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.document = text;
    setErrorMessageData(_errorMessageData);
  }

  return (
    <>
      <div
        className={`
          modal-new-interactive-message 
          ${isShowModalFormInteractive ? 
              "active-modal-new-interactive-message"
              : 
              ""
          } 
        `}
      >
        <div
          className={`
            modal-body 
            ${isShowModalFormInteractive ? 
              "action-modal-body-new-interactive-message"
              : 
              ""
            }
          `}
        >
          <div 
            onClick={() => {
              // setData({
              //   headerType: "",
              //   headerText: "",
              //   document: false,
              //   body: "",
              //   footer: "",
              //   buttonText: [""].slice()
              // });
              // setErrorMessageData({
              //   headerText: "",
              //   headerType: "",
              //   document: "",
              //   body: "",
              //   footer: "",
              //   buttonText: [""].slice()
              // });
              onCloseClick();
            }} 
            className="close-modal-interactive"
          >
            <FiX />
          </div>
          
          <div className="new-interactive-message-wrapper">
            <Scrollbars
              autoHideTimeout={500}
              renderThumbVertical={(props) => (
                <div {...props} style={style.scrollStyle} />
              )}
            >
              <div className="new-interactive-message-container">
                <div className="new-interactive-message-title">
                  <img src={IconAttachMessage} alt="" />

                  <b>Interactive Message</b>
                  <p>
                    Create an interactive message to send
                  </p>
                </div>

                <div
                  className={"form-content-wrapper form-label"}
                >
                  <label>
                    <b>Type</b>
                  </label>

                  <p className="form-value">Reply Button</p>

                  <label>
                    <b>Header</b>
                  </label>

                  <label className="label-header-type input-interactive-header-type">
                    <b>Header Type</b>
                  </label>
                  
                  <FormInteractiveMessageHeaderType 
                    data={data}
                    errorMessageData={errorMessageData}
                    onChangeHeaderType={onChangeHeaderType}
                    onChangeText={onChangeText}
                    onChangeDocument={onChangeDocument}
                    runErrorDoc={runErrorDoc}
                  />
                  {errorMessageData.headerType !== "" &&
                    <ErrorOrInfoComp 
                      text={errorMessageData.headerType}
                      icon={<FiInfo />}
                      _className="red-text"
                    />
                  }

                  <label className="input-interactive-body">
                    <b>Body</b>
                    <span className={`grey-font ${errorMessageData.body === "Characters exceed limit" ? "red-text" : ""}`}>{data.body.length}/1024 characters</span>
                  </label>
                  <textarea value={data.body} onChange={(e) => onChangeText("body", e.target.value)} placeholder="Type message here" className={errorMessageData.body !== "" ? "border-red" : ""} />
                  {errorMessageData.body !== "" &&
                    <ErrorOrInfoComp 
                      text={errorMessageData.body}
                      icon={<FiInfo />}
                      _className="red-text"
                    />
                  }

                  <label className="input-interactive-footer">
                    <b>Footer </b>
                    <span className="text-optional grey-font">
                      (optional)
                    </span>
                    <span className={`grey-font  ${errorMessageData.footer === "Characters exceed limit" ? "red-text" : ""}`}>{data.footer.length}/60 characters</span>
                  </label>
                  <input type="text" value={data.footer} onChange={(e) => onChangeText("footer", e.target.value)} placeholder="Type your footer here" className={errorMessageData.footer !== "" ? "border-red" : ""} />
                  {errorMessageData.footer !== "" &&
                    <ErrorOrInfoComp 
                      text={errorMessageData.footer}
                      icon={<FiInfo />}
                      _className="red-text"
                    />
                  }

                  {data.buttonText.map((v, i) => {
                    return (
                      <div className={`button-text-wrapper-form ${i === 0 ? "button-text-wrapper-form-1": ""}`} key={`button-text-${i}`}>
                        <label className={`input-interactive-button-text-${i}`}>
                          <b>Button Text</b>
                          <span className={`grey-font  ${errorMessageData.buttonText[i] === "Characters exceed limit" ? "red-text" : ""}`}>
                            {v.length}/20 characters

                            {data.buttonText.length !== 1 ?
                              <FiTrash2 onClick={() => removeButtonText(i)} className="remove-button-text" />
                              :
                              ""
                            }
                          </span>
                        </label>
                        <input type="text" value={v} onChange={(e) => onChangeButtonText(e.target.value, i)} placeholder="Type your button text here" className={errorMessageData.buttonText[i] !== "" ? `border-red button-text-${i}` : `button-text-${i}`} />
                        {errorMessageData.buttonText[i] !== "" &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.buttonText[i]}
                            icon={<FiInfo />}
                            _className="red-text"
                          />
                        }
                      </div>
                    )
                  })}

                  {data.buttonText.length < 3 &&
                    <p className="add-another-button" onClick={addButtonText}>
                      <b><FiPlus /> Add Another Button</b>
                    </p>
                  }
                </div>
              </div>
            </Scrollbars>
          </div>

          <div className="send-message-button">
            <ButtonWithLoadingOrIcon
              onClickAction={onContinue}
              // isDisabled={isDisabledSubmit()}
              className="orange-button main-button-48"
              text="Continue"
              position="left"
            />
          </div>
        </div>
      </div>

      <FormInteractiveMessagePreview 
        _labelFor="button"
        isShowModalFormInteractivePreview={isShowModalFormInteractivePreview}
        toggle={() => {
          // setData({
          //   headerType: "",
          //   headerText: "",
          //   document: false,
          //   body: "",
          //   footer: "",
          //   buttonText: [""].slice()
          // });
          // setErrorMessageData({
          //   headerText: "",
          //   headerType: "",
          //   document: "",
          //   body: "",
          //   footer: "",
          //   buttonText: [""].slice()
          // });
          // closeModalPreview();
          
          onCloseClick("");
        }}
        data={data}
        back={() => toggleModalPreview(type)}
        type={type}
        parentProps={parentProps}
        caseDetailData={caseDetailData}
        forceCloseModal={() => {
          setData({
            headerType: "",
            headerText: "",
            document: false,
            body: "",
            footer: "",
            buttonText: [""].slice()
          });
          setErrorMessageData({
            headerText: "",
            headerType: "",
            document: "",
            body: "",
            footer: "",
            buttonText: [""].slice()
          });

          toggle();
          setIsShowModalFormInteractivePreview(false);
        }}
      />

      <PopupDiscard 
        dataOrigin={dataOrigin}
        dataAfterChange={data}
        isOpenDiscard={isShowModalDiscard}
        toggleDiscard={toggleModalDiscard}
        discardProgress={() => {
          setData({
            headerType: "",
            headerText: "",
            document: false,
            body: "",
            footer: "",
            buttonText: [""].slice()
          });
          setErrorMessageData({
            headerText: "",
            headerType: "",
            document: "",
            body: "",
            footer: "",
            buttonText: [""].slice()
          });

          toggle();
          toggleModalDiscard();
          setIsShowModalFormInteractivePreview(false);
        }}
        discardDesc={"You have unsaved progress on this page. Are you sure you want to discard your progress?"}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeQuickReplyInteractive: state.activeQuickReplyInteractive
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FormInteractiveMessage);
  