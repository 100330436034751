import React, { useEffect, useState } from 'react';
import './AdvancedDateFilter.scss';
import { FiCalendar, FiChevronDown, FiCheck } from 'react-icons/fi';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody } from 'reactstrap';
import ButtonWithLoadingOrIcon from '../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import HelperDate from "../../../helper/HelperDate";
import CalendarPicker from "../calendarPicker/CalendarPicker";

let DATE_SELECTION = {
    all_time: {
        val: "all_time",
        text: "All Time"
    },
    last_30: {
        val: "last_30",
        text: "Last 30 Days"
    },
    this_month: {
        val: "this_month",
        text: "This Month"
    },
    last_month: {
        val: "last_month",
        text: "Last Month"
    },
    custom: {
        val: "custom",
        text: "Custom"
    }
}

const AdvancedDateFilter = (props) => {
    let {
        isOpenDropdown,
        toggleDropdownDate,
        dateVal,
        filterDateForCalender,
        filterBy,
        onClickFilterBy,
        onClickCalendar,
        onClickDate,
        ignoreAllTime,
        isDisabled,
        disabledDays,
        maxRange,
        customDate,
        lastChangeSubmitAction
    } = props;

    let [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false);
    let [showModalMaxRange, setShowModalMaxRange] = useState(false);

    useEffect(() => {
        if (customDate) {
            onClickFilterBy(DATE_SELECTION.custom);
            onClickDate({
                from: new Date(customDate.from),
                to: new Date(customDate.to)
            })
            lastChangeSubmitAction(new Date())
        }
    }, [])

    useEffect(() => {
        if (isOpenModalCalendar) {
            onClickCalendar(dateVal)
        }
    }, [isOpenModalCalendar])

    let toggleModalCalendar = () => {
        setIsOpenModalCalendar(!isOpenModalCalendar);
    }

    let _handleDateFilterClick = (date) => {
        onClickCalendar(date);
    }

    let submitDateFilter = () => {
        onClickFilterBy(DATE_SELECTION.custom);
        onClickDate(filterDateForCalender);
        toggleModalCalendar();
    }

    let handleDateFilterCancel = () => {
        toggleModalCalendar();
    }

    let onClearDatePicker = () => {
        onClickCalendar({
            from: undefined,
            to: undefined
        })
    }

    let onClickDateSelection = (val) => {
        let _dateVal = { ...dateVal };

        switch (val) {
            case DATE_SELECTION.all_time.val:
                onClickFilterBy(DATE_SELECTION.all_time);

                _dateVal.from = undefined;
                _dateVal.to = undefined;
                onClickDate(_dateVal);
                break;
            case DATE_SELECTION.last_30.val:
                onClickFilterBy(DATE_SELECTION.last_30);

                _dateVal.from = new Date(new Date().setDate(new Date().getDate() - 30));
                _dateVal.to = new Date();
                onClickDate(_dateVal);
                break;
            case DATE_SELECTION.this_month.val:
                onClickFilterBy(DATE_SELECTION.this_month);

                _dateVal.from = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
                _dateVal.to = new Date();
                onClickDate(_dateVal);
                break;
            case DATE_SELECTION.last_month.val:
                onClickFilterBy(DATE_SELECTION.last_month);
                let generateLastMonth = (year = false) => {
                    let d = new Date();
                    let thisM = d.getMonth();
                    let thisY = d.getFullYear();

                    if (thisM === 0) {
                        thisY = thisY - 1;
                        thisM = 11;
                    } else {
                        thisM = thisM - 1;
                    }

                    if (!year) {
                        return new Date(thisY, thisM, 1);
                    } else {
                        return new Date(thisY, thisM + 1, 0)
                    }
                }

                _dateVal.from = new Date(generateLastMonth());
                _dateVal.to = new Date(generateLastMonth(true));

                onClickDate(_dateVal);

                break;
            case DATE_SELECTION.custom.val:
                toggleModalCalendar();
                break;
            default:
                break;
        }
    }

    let isPassDateRange = () => {
        let _pass = false;

        let _from = filterDateForCalender.from;
        let _to = filterDateForCalender.to;

        if (_from && _to) {
            let dif_in_day = (_to.getTime() - _from.getTime()) / (1000 * 3600 * 24);

            _pass = dif_in_day <= maxRange;
        }

        return _pass;
    }

    let toggleModalMaxRange = () => {
        setShowModalMaxRange(!showModalMaxRange);
    }

    let ModalMaxRangeFilter = () => (
        <Modal isOpen={showModalMaxRange} toggle={toggleModalMaxRange} className="modal-max-range-filter">
            <ModalBody>
                <p>
                    <b>Please select date within 365 days period</b>
                </p>

                <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    onClickAction={toggleModalMaxRange}
                    text="OK"
                />
            </ModalBody>
        </Modal>
    );

    return (
        <>
            <ModalMaxRangeFilter />

            <CalendarPicker
                isOpen={isOpenModalCalendar}
                toggle={toggleModalCalendar}
                filterDate={filterDateForCalender}
                handleDateFilterClick={_handleDateFilterClick}
                // isFilterDateActive={isFilterDateActive}
                submitDateFilter={isPassDateRange() ? submitDateFilter : toggleModalMaxRange}
                cancelDatePicker={handleDateFilterCancel}
                clearDatePicker={onClearDatePicker}
                disabledDays={disabledDays}
                {...props}
            />

            <Dropdown
                isOpen={isOpenDropdown}
                toggle={!isDisabled ? toggleDropdownDate : null}
                className={`advanced-date-selection ${isDisabled ? "disabled-advanced-date-selection" : ""}`}
                isDisabled={isDisabled}
            >
                <DropdownToggle>
                    <FiCalendar className="icon-calendar" />
                    {(!filterBy.val || filterBy.val === DATE_SELECTION.all_time.val) ?
                        DATE_SELECTION.all_time.text
                        :
                        `${filterBy.text} (${HelperDate.formatToString(dateVal.from, "dd MMM yyyy")} - ${HelperDate.formatToString(dateVal.to, "dd MMM yyyy")})`
                    }

                    <FiChevronDown className="icon-caret" />
                </DropdownToggle>
                <DropdownMenu>
                    {Object.keys(DATE_SELECTION).map((v, i) => {
                        return (
                            i === 0 ?
                                !ignoreAllTime ?
                                    <DropdownItem key={`selection-${i}`} onClick={() => onClickDateSelection(v)}>
                                        {filterBy === DATE_SELECTION[v].text &&
                                            <FiCheck />
                                        }

                                        {DATE_SELECTION[v].text}
                                    </DropdownItem>
                                    :
                                    ""
                                :
                                <DropdownItem key={`selection-${i}`} onClick={() => onClickDateSelection(v)}>
                                    {filterBy === DATE_SELECTION[v].text &&
                                        <FiCheck />
                                    }

                                    {DATE_SELECTION[v].text}
                                </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default AdvancedDateFilter;
