import React, { useState, useEffect } from "react";
import { FiUpload, FiInfo, FiUser  } from "react-icons/fi";
import { checkID, doToast, getImageDimention } from "../../../../../../helper/HelperGeneral";
import HelperUpload from "../../../../../../helper/HelperUpload";
import { taptalk, tapCoreMessageManager } from "@taptalk.io/web-sdk"; 
import IntegrationGoogleServices from "../../../../../../services/integration/IntegrationGoogleServices";
import ServiceOrganization from "../../../../../../services/organization/ServiceOrganization";
import ChatService from "../../../../../../services/chat/caseServices";
import BackButton from "../../../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../../../../reuseableComponent/sectionWrap/SectionWrap";
import ErrorOrInfoComp from '../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import Loading from "../../../../../reuseableComponent/loading/Loading";
import "./EditGreetingMessage";
import "./EditChannelProfile.scss";

let IntegrationGoogleEditChannelProfile = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingEdit, setIsLoadingEdit] = useState(false);
  let [isLoadingUpload, setIsLoadingUpload] = useState(false);
  let [data, setData] = useState({
    displayName: "",
    errorDisplayName: "",
    logoURL: "",
    logoFile: null,
    errorLogo: ""
  });
  let [dataOrigin, setDataOrigin] = useState({
    displayName: "",
    errorDisplayName: "",
    logoURL: "",
    logoFile: null,
    errorLogo: ""
  });

  let doMount = () => {
    setIsLoadingEdit(false);
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getAccountDetail();
    }
  }, [orgID]);

  let getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: Number(match.params.id),
    };

    IntegrationGoogleServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        let _data = response.dataResult.data.profile;
        let dataVal = {
          displayName: _data.displayName,
          errorDisplayName: "",
          logoURL: _data.logoUrl,
          logoFile: null,
          errorLogo: ""
        }

        setDataOrigin(dataVal);
        setData(dataVal);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let submitEditAbout = () => {
    let errorCount = 0;
    let _data = {...data};
    
    if(_data.displayName === "") {
      _data.errorDisplayName = "This field is required";
      errorCount = errorCount + 1;
    }

    if(_data.displayName.length > 200) {
      _data.errorDisplayName = "Characters exceed limit";
      errorCount = errorCount + 1;
    }

    if(errorCount > 0) {
      setData(_data);
    }else {
      setIsLoadingEdit(true);
      
      let param = {
        id: Number(match.params.id),
        displayName: data.displayName,
        logoURL: data.logoURL
      };
  
      IntegrationGoogleServices.editChannelProfile(orgID, param, (response) => {
        if (response.dataResult.error.message === "") {
          let _data = response.dataResult.data;
          
          if(_data.success) {
            setDataOrigin(data);
            doToast(_data.message);
  
            setTimeout(() => {
              history.push({
                pathname: `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
                state: {
                  whichTab: "channelProfile",
                },
              })
            }, 3000)
          }else {
            doToast(_data.message, "fail");
            setIsLoadingEdit(false);
          }
  
        } else {
          doToast(response.dataResult.error.message, "fail");
          
        setIsLoadingEdit(false);
        }
      });
    }
  };

  let taptalkAuthTicket = (file) => {
    ChatService.postRequestAuthTicket(props.match.params.orgID, (response) => {
      if (response.dataResult.error.code === "") {
        taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
          onSuccess: () => {
            doUpload(file);
          },
          onError: (errorCode, errorMessage) => {
            doToast(errorMessage, 'fail')
            console.log(errorCode, errorMessage);
          }
        })
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let doUpload = (file) => {
      //do upload
      let uploadData = {
          file: file,
          caption: "",
          room: ""
      };

      tapCoreMessageManager.uploadChatFile(uploadData, {
          onProgress: (percentage, bytes) => {
          },

          onSuccess: (response) => {
              if(response) {
                  let _createGoogle = JSON.stringify(data);
                  let _parse = JSON.parse(_createGoogle);

                  _parse.logoURL = response.url;

                  setData(_parse);
                  setIsLoadingUpload(false);
              }
          },

          onError: (error) => {
              doToast(error, "fail");
              console.log(error);
          }
      })
      //do upload
  }

  let onChangeUploadLogo = (e) => {
      let _createGoogle = JSON.stringify(data);
      let _parse = JSON.parse(_createGoogle);

      HelperUpload.checkFileSizeLimit(e.target.files, {
          onAllowed: (arrayOfFiles) => {
              
              getImageDimention(e.target.files[0], (imageHeight, imageWidth, file) => {
                  if(imageHeight > 511 && imageWidth > 511) {
                      _parse.logoFile = file;
      
                      setData(_parse);
                      
                      setIsLoadingUpload(true);
                      taptalkAuthTicket(file);
                  }else {
                      doToast("Minimum image resolution is 1024x1024px.", "fail");
                      _parse.errorLogo = "Minimum image resolution is 1024x1024px.";
                  }
              })

          },
          onReachLimit: (message) => {
            doToast(message, "fail");
          }
      }, 51200)
      
      e.target.value = null;
  }

  let disabledButton = () => {
    // let dis = false;

    // if(data.displayName === "") {
    //   dis = true;
    // }

    // if(JSON.stringify(data) === JSON.stringify(dataOrigin)) {
    //   dis = true;
    // }

    // if(isLoadingUpload) {
    //   dis = true;
    // }

    return false;
  }

  return (
    <div className="edit-profile-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID} 
        withHeader 
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >
        <BackButton
          text="Back to Channel Profile"
          onClick={() =>
            history.push({
              pathname: `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
              state: {
                whichTab: "channelProfile",
              },
            })
          }
        />

        <div className="edit-account-profile-google sectionWrap">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <>
              <div className="edit-account-profile-header">
                <div className="title-wrapper">
                  <b>Edit Channel Profile</b>
                </div>
                <div className="button-wrapper">
                  {!isLoadingEdit ? (
                    <ButtonWithLoadingOrIcon
                      className="orange-button  main-button-40"
                      text="Save Changes"
                      isDisabled={disabledButton()}
                      onClickAction={submitEditAbout}
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      className="orange-button main-button-40"
                      text="Save Changes"
                      position="left"
                    />
                  )}
                </div>
              </div>
              
              <div className="edit-account-profile-content main-integration-google-create-channel">
                <div className="account-profile-content-wrapper">
                    {/* // */}
                    <input type="file" id="upload-logo" accept="image/jpg, image/png, image/jpeg" onChange={(e) => onChangeUploadLogo(e)} />

                    <label>
                        <b>Display Name</b>

                        <span className={`${data.errorDisplayName === "Characters exceed limit" ? "red-text" : "grey-font"}`}>{data.displayName.length}/200</span>
                    </label>

                    <input 
                        type="text"
                        onChange={(e) => {
                            let _data = JSON.stringify(data);
                            let _parse = JSON.parse(_data);

                            _parse.displayName = e.target.value;
                            _parse.errorDisplayName = "";
                            setData(_parse);
                        }}
                        value={data.displayName}
                        placeholder="Type your display name here"
                        className={`${data.errorDisplayName === "" ? "" : "border-red"}`}
                        disabled={isLoadingEdit}
                    />

                    <ErrorOrInfoComp
                        text={data.errorDisplayName === "" ? `The display name should not be representing a personal/test/demo and also it should have the connection with the business/brand. 
                        Display name is visible when users interact with your channel's agent.` : data.errorDisplayName}
                        _className={data.errorDisplayName === "" ? "" : "font-red"}
                        icon={<FiInfo />}
                    />

                    <div className="create-channel-upload">
                        {!isLoadingUpload &&
                            <ButtonWithLoadingOrIcon 
                                className="no-fill-button main-button-40"
                                text="Change Logo"
                                position="left"
                                icon={{
                                    src: FiUpload,
                                    type: "svg"
                                }}
                                onClickAction={() => {
                                    let el = document.querySelector("#upload-logo");
                                    el.click();
                                }}
                                isDisabled={isLoadingEdit}
                            />
                        }
                        
                        <label>
                            <b>Profile Logo</b>
                        </label>

                        <div className="logo-wrapper">
                            {(data.logoFile === null && data.logoURL === "") &&
                                <FiUser />
                            }


                            <>  
                                {(data.logoFile !== null && data.logoURL === "") &&
                                    <img src={URL.createObjectURL(data.logoFile)} alt="" /> 
                                }

                                {data.logoURL !== "" &&
                                    <img src={data.logoURL} alt="" /> 
                                }

                                {isLoadingUpload &&
                                    <div className="loading-wrapper">
                                        <Loading />
                                    </div>
                                }
                            </>
                        </div>

                        <ErrorOrInfoComp
                            text={"The profile logo should be related to the business in some manner."}
                            _className={data.errorLogo === "" ? "" : "font-red"}
                            icon={<FiInfo />}
                        />
                        
                        <ErrorOrInfoComp
                            text={"PNG or JPG. 50KB max with a minimum of 1024x1024px."}
                            _className={data.errorLogo === "" ? "no-margin-top" : "no-margin-top font-red"}
                            icon={""}
                        />
                    </div>
                    {/* // */}
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationGoogleEditChannelProfile;
