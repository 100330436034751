import React, { useState } from "react";
import "./ModalChooseChannel.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiX } from "react-icons/fi";
// import { TAPLIVE_MEDIUM } from "../../../../../constants/taplive";
import { setCloseCaseNewMessageRecipient } from "../../../../../redux/actions/reduxActionCloseCaseNewMessageRecipient";
import LogoWhatsAppSME from "../../../../../assets/img/integration/logo-whatsapp-sme.svg";
import IconPencilNew from "../../../../../assets/img/icon-pencil-new-message.svg";
import LogoWhatsAppBusiness from "../../../../../assets/img/integration/logo-whatsapp-business.svg";
import { TAPLIVE_MEDIUM } from "../../../../../constants/taplive";
import { connect } from "react-redux";
// import ButtonWithLoadingOrIcon from "../../../../../components/reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

let ModalChooseChannel = (props) => {
  let [whatsAppSMESelected, setwhatsAppSMESelected] = useState(false);
  let [whatsAppBusinessSelected, setwhatsAppBusinessSelected] = useState(false);
  // let [loadingStartConversation, setLoadingStartConversation] = useState(false);

  // let startConversation = () => {
  //   setLoadingStartConversation(true);
  //   // TODO
  //   // setTimeout(() => {
  //   setLoadingStartConversation(false);
  //   cancelModal()

  //   if (props.toggleModalStartConversation) {
  //     props.toggleModalStartConversation(whatsAppBusinessSelected ? "waba" : "sme")
  //   }
  //   // }, 2000);
  // }

  let cancelModal = () => {
    setwhatsAppSMESelected(false)
    setwhatsAppBusinessSelected(false)
    // setLoadingStartConversation(false)
    props.toggleModalChooseChannelProps(false)
  }

  return (
    <Modal
      className="modal-choose-channel"
      isOpen={props.isShowModalChooseChannelProps}
      toggle={cancelModal}
    >
      <ModalBody>
        <div id="modal-choose-channel-title">
          <b>
            {/* <FiEdit3 className="icon-edit" /> */}
            <img src={IconPencilNew} alt="" className="icon-edit" />
            New Message
          </b>

          <FiX className="cancel-new-message" onClick={cancelModal} />
        </div>

        <div>
          <b>Channel Type</b>
        </div>

        <div className="modal-choose-channel-options">
          <div
            className={
              whatsAppSMESelected ? "modal-choose-channel-selected modal-choose-select" : "modal-choose-select"
            }
            onClick={() => {
              // setwhatsAppSMESelected(true);
              // setwhatsAppBusinessSelected(false);
              if(props.clearPreviewMessageData) {
                props.clearPreviewMessageData();
              }

              cancelModal();
              if (props.onClickContinue) {
                props.onClickContinue(TAPLIVE_MEDIUM.whatsapp);
              } else {
                props.toggleModalStartConversation("sme");
              }

              let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
              _closeCaseNewMessage.lastClick = false;

              props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);
            }}
          >
            <img src={LogoWhatsAppSME} alt="" />
            <b>WhatsApp SME</b>
          </div>
          <div
            className={
              whatsAppBusinessSelected ? "modal-choose-channel-selected modal-choose-select" : "modal-choose-select"
            }
            onClick={() => {
              // setwhatsAppBusinessSelected(true);
              // setwhatsAppSMESelected(false);
              if(props.clearPreviewMessageData) {
                props.clearPreviewMessageData();
              }
              
              cancelModal();
              if (props.onClickContinue) {
                props.onClickContinue(TAPLIVE_MEDIUM.whatsappba);
              } else {
                props.toggleModalStartConversation("waba");
              }

              let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
              _closeCaseNewMessage.lastClick = false;

              props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);
            }}
          >
            <img src={LogoWhatsAppBusiness} alt="" />
            <b>WhatsApp Business API</b>
          </div>
        </div>

        {/* <div className="modal-choose-channel-buttons">
          <button
            className="no-fill-button"
            onClick={cancelModal}
            disabled={loadingStartConversation}
          >
            <b>Cancel</b>
          </button>

          <ButtonWithLoadingOrIcon
            className="orange-button main-button-40"
            text="Continue"
            position="left"
            loadingColor="gray"
            onClickAction={props.onClickContinue ? () => props.onClickContinue(whatsAppSMESelected ? TAPLIVE_MEDIUM.whatsapp : TAPLIVE_MEDIUM.whatsappba) : startConversation}
            isLoading={loadingStartConversation}
            isDisabled={(!whatsAppSMESelected && !whatsAppBusinessSelected) || loadingStartConversation}
          />
        </div> */}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => ({
  closeCaseNewMessageRecipient: state.closeCaseNewMessageRecipient
});

const mapDispatchToProps = {
  setCloseCaseNewMessageRecipient
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChooseChannel);
