import React, { useRef, useEffect } from "react";
import Select from "react-select";

const iconSearch =
  "url('/image/search-orange.svg') center / contain no-repeat !important";
const iconDropOrange =
  "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
const iconDropGrey =
  "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

const styles = (type, search, isMulti = false) => ({
  indicatorsContainer: provide => ({
    ...provide,
    height: "48px",
    alignItems: type === "textarea" ? "flex-start" : "center"
  }),
  valueContainer: provide => ({
    ...provide,
    height: "100%"
  }),
  clearIndicator: () => ({
    display: "none"
  }),
  dropdownIndicator: (provided, { isFocused }) => ({
    ...provided,
    background: search ? iconSearch : isFocused ? iconDropOrange : iconDropGrey,
    transition: "0.25s ease-in-out",
    color: "transparent !important",
    width: "24px",
    height: "24px",
    transform: !search && isFocused ? "rotate(-180deg)" : ""
  }),
  control: (provide, { isFocused }) => ({
    ...provide,
    padding: type === "textarea" ? "16px" : "0 8px",
    // height: "48px",
    // minHeight: type === "textarea" ? "85px" : "",
    borderRadius: "8px",
    border: isFocused ? "1px solid #ff7e00 !important" : "",
    boxShadow: "none !important",
    marginBottom: "24px",
    alignItems: type === "textarea" ? "flex-start" : "center",
    height: isMulti ? "unset" : "48px"
  }),
  indicatorSeparator: provide => ({ ...provide, display: "none" }),
  menu: provide => ({
    ...provide,
    borderRadius: "8px",
    boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
    overflow: "hidden"
  }),
  multiValue: provide => ({
    ...provide,
    borderRadius: "6px"
  }),
  multiValueLabel: provide => ({
    ...provide,
    background: "#DCEBFF",
    order: "2",
    padding: "3px !important",
    paddingRight: "12px !important",
    fontSize: "16px",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
    lineHeight: "30px",
    position: "relative",
    top: "-1px",
    letterSpacing: "-0.5px",
    // fontWeight: "bold",
    fontFamily: "PTRootBold !important"
  }),
  multiValueRemove: provide => ({
    ...provide,
    background: "#DCEBFF",
    order: "1",
    borderTopLeftRadius: "6px",
    borderBottomLeftRadius: "6px",
    paddingLeft: "12px",
    fontSize: "20px",
  }),
  option: (provide, state) => {
    const { isSelected } = state;
    const checked = {
      color: "#ff7e00",
      content: '"✔"',
      position: "absolute",
      left: "0",
      top: "50%",
      transform: "translate(100%, -50%)"
    };
    return {
      ...provide,
      position: "relative",
      background: "#fff",
      padding: "12px 36px",
      color: isSelected ? "#ff7e00" : "#121212",
      ":hover": { background: "#ffe7d0" },
      ":before": isSelected ? checked : null
    };
  }
});

const NewSelect = props => {
  const { type, search, isMulti, openSelect, isDisabled } = props;
  const refSelectAssignAgent = useRef(null);

  useEffect(() => {
    if(openSelect) {
      setTimeout(() => {
        refSelectAssignAgent.current.focus();
      }, 500)
    }
  }, [openSelect])

  return (
    <Select
      styles={styles(type, search, isMulti)}
      {...props}
      isSearchable={type === "textarea"}
      ref={refSelectAssignAgent}
      isDisabled={isDisabled}
    />
  );
};

export default NewSelect;

