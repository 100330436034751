import React from "react";
import "../../../assets/styles/index.scss";
import "./NewButton.css";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

// const IconBtn = props => {
//   const { icon } = props;
//   if (!icon) return "";
//   return <span className="newButton__icon">{icon}</span>;
// };

const NewButton = ({
  type,
  className,
  icon,
  text,
  children,
  onClick,
  ...props
}) => {
  // const btnType = type || "button";
  const defClassName = `newButton ${className || "orange-button"}`;
  const word = text || children || "Text";
  const click = onClick || undefined;
  
  return (
    // <button {...props} type={btnType} onClick={click} className={defClassName}>
    //   {/* <IconBtn icon={icon} /> */}
    //   {icon ? <React.Fragment>{icon}&nbsp;&nbsp;</React.Fragment> : ""}
    //   {word}
    //   {/* <span>{word}</span> */}
    // </button>

    icon ?
      <ButtonWithLoadingOrIcon 
        className={`${defClassName} main-button-40`}
        text={word}
        position="left"
        onClickAction={click}
        icon={{
          type: "svg",
          src: icon 
        }}
      />
      :
      <ButtonWithLoadingOrIcon 
        className={`${defClassName} main-button-40`}
        text={word}
        onClickAction={click}
      />
  );
};

export default NewButton;
