import React from "react";
import "./ErrorComponent.scss";
import { FiInfo } from "react-icons/fi";

let ErrorComponent = (props) => {
    return (
        <div className="error-component-wrapper">
            <FiInfo />

            <p>
                {props.text}
            </p>
        </div>
    )
}

export default ErrorComponent;