const INITIAL_STATE = {
    confirmation: {
        show: false,
        isLoading: false,
        priceList: {},
        messageType: "",
        confirmAction: false,
        participant: 1
    },
    topupBalance: {
        show: false,
    },
};

let reduxReducerModalStartConversationBalance = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_MODAL_START_CONVERSATION_BALANCE':
        return action.modalStartConversationBalance;
      case 'CLEAR_MODAL_START_CONVERSATION_BALANCE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerModalStartConversationBalance;