import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const AutoUnassignServices = {
  getAutoUnassign(idOrTime, medium, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };
    
    BaseService.doPostAccessToken(API[medium], data, headers, callback);  
  },

  setAutoUnassign(idOrTime, medium, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };
    
    BaseService.doPostAccessToken(API[`set_${medium}`], data, headers, callback);  
  },
};

const API = {
  global: "/organization/setup/auto_unassign_agent_config/get",
  facebook: "/organization/channel/facebook/get_auto_unassign_agent_config",
  instagram: "/organization/channel/instagram/get_auto_unassign_agent_config",
  launcher: "/organization/channel/launcher/get_auto_unassign_agent_config",
  telegram: "/organization/channel/telegram/get_auto_unassign_agent_config",
  whatsapp: "/organization/channel/whatsapp/get_auto_unassign_agent_config",
  whatsappba: "/organization/channel/whatsappba/get_auto_unassign_agent_config",
  google_business: "/organization/channel/google_business/get_auto_unassign_agent_config",
  google_profile: "/organization/channel/google_business_profile/get_auto_unassign_agent_config",
  tokopedia: "/organization/channel/tokopedia/get_auto_unassign_agent_config",
  email: "/organization/channel/email/get_auto_unassign_agent_config",
  kataai: "/organization/channel/kataai/get_auto_unassign_agent_config",
  set_global: "/organization/setup/auto_unassign_agent_config/set",
  set_facebook: "/organization/channel/facebook/set_auto_unassign_agent_config",
  set_instagram: "/organization/channel/instagram/set_auto_unassign_agent_config",
  set_launcher: "/organization/channel/launcher/set_auto_unassign_agent_config",
  set_telegram: "/organization/channel/telegram/set_auto_unassign_agent_config",
  set_whatsapp: "/organization/channel/whatsapp/set_auto_unassign_agent_config",
  set_whatsappba: "/organization/channel/whatsappba/set_auto_unassign_agent_config",
  set_google_business: "/organization/channel/google_business/set_auto_unassign_agent_config",
  set_google_profile: "/organization/channel/google_business_profile/set_auto_unassign_agent_config",
  set_tokopedia: "/organization/channel/tokopedia/set_auto_unassign_agent_config",
  set_email: "/organization/channel/email/set_auto_unassign_agent_config",
  set_kataai: "/organization/channel/kataai/set_auto_unassign_agent_config",
}

export default AutoUnassignServices;
