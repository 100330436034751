var defaultQuickReplyObject = {
    shortcut: "",
    message: "",
    createdTime: "",
    updatedTime: "",
    deletedTime: 0,
    id: ""
};

const QUICK_REPLY_KEY = "TapTalk.quickReply";
const QUICK_REPLY_MESSAGE = {
    new: "New quick reply added",
    exist: "Shortcut key exists",
    update: "Quick reply Updated",
    delete: "Quick reply deleted"
}

let responseQuickReply = (isSuccess, message, errorMessage = "") => {
    return {
        dataResult: {
            success: isSuccess,
            message: message
        },
        error: errorMessage
    }
}

var HelperQuickReply = {
    prefixShortcut: "/",
    suffixShortcut: "_me",
    ownerTypeGeneral: "cst_organization",
    ownerTypePersonal: "cst_account",
    isTapTalkQuickReplyObject: () => {
        return typeof localStorage.getItem("TapTalk.quickReply") === "object";
    },
    isObjectTapTalkQuickReplyStorageNull: (quickReplyStorage) => {
        return quickReplyStorage === null;
    },
    isObjectQuickReplyOrganizationExist: (quickReplyOrganization, orgID) => {
        return quickReplyOrganization[orgID];
    },
    isObjectQuickReplyOrganizationEmpty: (quickReplyOrganization, orgID) => {
        return quickReplyOrganization[orgID].length === 0;
    },
    findShortcutIndex: (shortcutID, shortcut, orgID, quickReplyOrganization) => {
        let findIndex = null;

        if(shortcutID === null) {
            findIndex = quickReplyOrganization[orgID].findIndex(value => value.shortcut.toLowerCase() === HelperQuickReply.prefixShortcut+shortcut.toLowerCase());
        }else {
            findIndex = quickReplyOrganization[orgID].findIndex(value => value.id === shortcutID);
        }
        return findIndex;
    },
    setQuickReplyStorage: (quickReplyData) => {
        localStorage.setItem(QUICK_REPLY_KEY, JSON.stringify(quickReplyData));
    },
    fetchQuickReplyObject: () => {
        let quickReplyLocalStorage = localStorage.getItem("TapTalk.quickReply");

        if(quickReplyLocalStorage === null) {
            return null;
        }else {
            try {
                let objectQuickReply = JSON.parse(quickReplyLocalStorage)
                return objectQuickReply;
            }catch(e) {
                return null;
            }
        }
    },
    getOrganizationQuickReply: (orgID, callback) => {
        let _orgID = orgID.split("-")[0];
        let _fetchQuickReply = HelperQuickReply.fetchQuickReplyObject();
        
        if(_fetchQuickReply === null) {
            callback({
                dataResult: []
            });
        }else {
            callback({
                dataResult: _fetchQuickReply[_orgID] ? _fetchQuickReply[_orgID] : []
            });
        }
    },
    addOrganizationQuickReply: (orgID, shortcut, message, callback) => {
        let now = new Date().valueOf();
        let _orgID = orgID.split("-")[0];
        let _defaultQuickReplyObject = {...defaultQuickReplyObject};
        let _fetchQuickReply = HelperQuickReply.fetchQuickReplyObject();
        _defaultQuickReplyObject.message = message.trim();
        _defaultQuickReplyObject.shortcut = HelperQuickReply.prefixShortcut+shortcut;
        _defaultQuickReplyObject.createdTime = now;
        _defaultQuickReplyObject.updatedTime = now;
        _defaultQuickReplyObject.id = 'shortcut'+now;

        let pushNewQuickReply = () => {
            if(!_fetchQuickReply[_orgID]) {
                _fetchQuickReply[_orgID] = [];
            }

            _fetchQuickReply[_orgID].push(_defaultQuickReplyObject);
            return _fetchQuickReply;
        }

        if(HelperQuickReply.isObjectTapTalkQuickReplyStorageNull(_fetchQuickReply)) {
            _fetchQuickReply = {};
            _fetchQuickReply[_orgID] = [];

            HelperQuickReply.setQuickReplyStorage(pushNewQuickReply());

            callback(responseQuickReply(true, QUICK_REPLY_MESSAGE.new));
        }else {            
            if(HelperQuickReply.isObjectQuickReplyOrganizationExist(_fetchQuickReply, _orgID)) {
                if(HelperQuickReply.findShortcutIndex(null, shortcut, _orgID, _fetchQuickReply) !== -1) {
                    callback(responseQuickReply(false, QUICK_REPLY_MESSAGE.exist, QUICK_REPLY_MESSAGE.exist));
                }else {
                    HelperQuickReply.setQuickReplyStorage(pushNewQuickReply());
                    callback(responseQuickReply(true, QUICK_REPLY_MESSAGE.new));
                }
            }else if(!HelperQuickReply.isObjectQuickReplyOrganizationExist(_fetchQuickReply, _orgID)) {
                HelperQuickReply.setQuickReplyStorage(pushNewQuickReply());
                callback(responseQuickReply(true, QUICK_REPLY_MESSAGE.new));
            }else {
                HelperQuickReply.setQuickReplyStorage(pushNewQuickReply());
                callback(responseQuickReply(true, QUICK_REPLY_MESSAGE.new));
            }
        }
    },
    editOrganizationQuickReply: (orgID, shortcutID, shortcut, message, callback) => {
        let _orgID = orgID.split("-")[0];
        let _fetchQuickReply = HelperQuickReply.fetchQuickReplyObject();
        let findShortcutIndex = HelperQuickReply.findShortcutIndex(shortcutID, shortcut, _orgID, _fetchQuickReply);
        
        if(HelperQuickReply.findShortcutIndex(null, shortcut, _orgID, _fetchQuickReply) === -1) {
            _fetchQuickReply[_orgID][findShortcutIndex].message = message.trim();
            _fetchQuickReply[_orgID][findShortcutIndex].shortcut = HelperQuickReply.prefixShortcut+shortcut;
            _fetchQuickReply[_orgID][findShortcutIndex].updatedTime = new Date().valueOf();
            HelperQuickReply.setQuickReplyStorage(_fetchQuickReply);
            callback(responseQuickReply(true, QUICK_REPLY_MESSAGE.update));
        }else {
            if(_fetchQuickReply[_orgID][findShortcutIndex].id === shortcutID && 
               _fetchQuickReply[_orgID][findShortcutIndex].shortcut === HelperQuickReply.prefixShortcut+shortcut
               ) {
                _fetchQuickReply[_orgID][findShortcutIndex].message = message.trim();
                _fetchQuickReply[_orgID][findShortcutIndex].shortcut = HelperQuickReply.prefixShortcut+shortcut;
                _fetchQuickReply[_orgID][findShortcutIndex].updatedTime = new Date().valueOf();
                HelperQuickReply.setQuickReplyStorage(_fetchQuickReply);
                callback(responseQuickReply(true, QUICK_REPLY_MESSAGE.update));
            }else {
                callback(responseQuickReply(false, QUICK_REPLY_MESSAGE.exist, QUICK_REPLY_MESSAGE.exist));
            }
        }
    },
    deleteOrganizationQuickReply: (orgID, shortcutID, callback) => {
        let _orgID = orgID.split("-")[0];
        let _fetchQuickReply = HelperQuickReply.fetchQuickReplyObject();
        let findShortcutIndex = HelperQuickReply.findShortcutIndex(shortcutID, null, _orgID, _fetchQuickReply);
        _fetchQuickReply[_orgID].splice(findShortcutIndex, 1);
        HelperQuickReply.setQuickReplyStorage(_fetchQuickReply);
        callback(responseQuickReply(true, QUICK_REPLY_MESSAGE.delete));
    },
}

export default HelperQuickReply;



