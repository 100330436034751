import React, { useEffect, useState } from "react";
import "./AccountSettingDefaultOrganization.scss";
import { checkID } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ExtraHeader from "../../reuseableComponent/extraHeader/ExtraHeader";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceProfile from "../../../services/profile/ServiceProfile";
import mixpanel from "mixpanel-browser"; 
import IconOrganization from "../../../assets/img/icon-organization.svg";
import { FiEdit } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const AccountSettingDefaultOrganization = (props) => {
    let [val, setVal] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [orgID, setOrgID] = React.useState(false);

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Account Setting",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if(orgID) {
            getData();
        }
    }, [orgID])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/account-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getData = () => {
        let _retriveDataError = {...retriveDataError};
        setIsWaitingFetch(true);

        ServiceProfile.postGetProfile((response) => {
            let dataResult = response.dataResult;

            if(dataResult.error.message === "") {
                setVal(dataResult.data);
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitingFetch(false);
        })
    }

    let getMemberShip = (id) => {
        let _findIndex = val.memberships.findIndex((v) => v.organization.id === id);
        return _findIndex === -1 ? false : val.memberships[_findIndex];
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >
            <ExtraHeader
                title="Settings"
                sub="Account general settings"
            />

            <div className="account-settings sectionWrap">
                {/* tier blocking */}
                {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                        isLoading={isWaitingFetch}
                        errorMessage={retriveDataError.message}
                        errorCode={retriveDataError.code}
                    />
                }
                {/* tier blocking */}

                <React.Fragment>
                    <div className="account-settings-header">
                        <b>My Organizations</b>

                        <NavLink to={`/o/${orgID}/account/settings/edit`}>
                            <ButtonWithLoadingOrIcon 
                                icon={{
                                    type: "svg",
                                    src: FiEdit
                                }}
                                className="no-fill-button main-button-40 button-save"
                                text="Edit"
                                position="left"
                            />
                        </NavLink>
                    </div>
                    
                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
                        <RetrieveDataLoading 
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message} 
                        />
                        :
                        <div className="account-settings-form">
                            <div className="account-settings-form-content form-content-wrapper">
                                <b><img className="account-setting-icon" src={IconOrganization} alt="" /> Default Selected Organization</b>
                                <p className="status-wrapper">
                                    {val.account.defaultOrganizationID === 0 ?
                                        "Not Specified"
                                        :
                                        (!getMemberShip(val.account.defaultOrganizationID)) ? "Not Specified" : getMemberShip(val.account.defaultOrganizationID).organization.name
                                    }
                                </p>
                            </div>
                        </div>
                    }
                </React.Fragment>
            </div>
        </SectionWrap>
    )
}

export default AccountSettingDefaultOrganization;