import React, { useEffect, useState } from "react";
import "./ChatRoomHandoverCaseModal.scss";
import { connect } from 'react-redux';
import { Modal, ModalBody } from "reactstrap";
import { setActiveHandoverCase, clearActiveHandoverCase } from '../../../../redux/actions/reduxActionActiveHandoverCase';
import { setSubmitHandoverCase } from '../../../../redux/actions/reduxActionSubmitHandoverCase';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CaseService from "../../../../services/chat/caseServices";

import topicService from "../../../../services/newServices/TopicServices";
import { doToast } from "../../../../helper/HelperGeneral";
import Select from "react-select";
// import { FiInfo, FiXCircle, FiCheckCircle, FiAlertTriangle, FiRefreshCw } from 'react-icons/fi';

let iconDropOrange = "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
let iconDropGrey = "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

let ChatRoomHandoverCaseModal = (props) => {
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    // let [topicList, setTopicList] = useState([]);
    let [agentList, setAgentList] = useState([]);
    let [topicVal, setTopicVal] = useState({
        value: "",
        label: ""
    })
    let [agentAccountVal, setAgentAccountVal] = useState({
        value: "",
        label: ""
    })
    let [topicValOrigin, setTopicValOrigin] = useState({
        value: "",
        label: ""
    })
    let [agentAccountValOrigin, setAgentAccountValOrigin] = useState({
        value: "",
        label: ""
    })
    let [showModalConfirmation, setShowModalConfirmation] = useState(false)
    let [isWaitAssignMe, setIsWaitAssignMe] = useState(false)

    useEffect(() => {
        if (props.activeHandoverCase.show) {
            getAgentList(topicVal.value);
        }
    }, [props.activeHandoverCase])

    useEffect(() => {
        if (props.caseDetailData !== null) {
            let _case = { ...props.caseDetailData };

            setTopicValOrigin({
                value: _case.case.topicID,
                label: _case.case.topicName
            })
            setAgentAccountValOrigin({
                value: _case.case.agentAccountID,
                label: _case.case.agentFullName
            })
            setTopicVal({
                value: _case.case.topicID,
                label: _case.case.topicName
            })
            setAgentAccountVal({
                value: _case.case.agentAccountID,
                label: _case.case.agentFullName
            })
        }
    }, [props.caseDetailData])

    useEffect(() => {
        if (!props.activeHandoverCase.show) {
            setIsWaitAssignMe(false);
        }
    }, [props.activeHandoverCase])

    // let resetHandover = () => {
    //     setAgentAccountValOrigin(({
    //         value: "",
    //         label: ""
    //     }))
    //     setTopicValOrigin({
    //         value: "",
    //         label: ""
    //     })
    //     setAgentAccountVal({
    //         value: "",
    //         label: ""
    //     })
    //     setTopicVal({
    //         value: "",
    //         label: ""
    //     })
    // }

    let isDisabled = () => {
        let _dis = false;

        if ((JSON.stringify(agentAccountValOrigin) === JSON.stringify(agentAccountVal))) {
            _dis = true;
        }

        if (props.activeHandoverCase.topicID !== 0 && agentAccountVal.value === "") {
            _dis = true;
        }

        return _dis;
    }

    let getAgentList = (topicID) => {
        topicService.getTopic(props.parentProps.match.params.orgID, "details", topicID, (response) => {
            if (response.dataResult.error.code === "") {
                let dataResult = response.dataResult;
                let arr = [];

                if (topicID !== props.caseDetailValProps.topicID) {
                    arr.push({
                        value: 0,
                        label: "Unassigned"
                    })
                }

                dataResult.data.agents.map((v, i) => {
                    if (v.accountName !== "") {
                        arr.push({
                            value: v.accountID,
                            label: `${v.accountName}${v.accountAlias === "" ? "" : ` (${v.accountAlias})`} `
                        })
                    }

                    return null;
                })

                setAgentList(arr);
            } else {
                // doToast(response.dataResult.error.message, 'fail');
            }
        })
    }

    let submitHandover = () => {
        setIsLoadingSubmit(true);

        let data = {
            id: props.caseDetailValProps.id,
            topicID: topicVal.value,
            agentAccountID: agentAccountVal.value
        }

        CaseService.handoverCase(props.parentProps.match.params.orgID, data, (response) => {
            let dataRes = response.dataResult;

            if (dataRes.status === 200) {
                let _submitHandoverCase = { ...props.submitHandoverCase };
                _submitHandoverCase.lastChange = new Date().valueOf();
                _submitHandoverCase.data = dataRes.data.case;
                props.setSubmitHandoverCase(_submitHandoverCase);

                setTopicValOrigin({
                    value: dataRes.data.case.topicID,
                    label: dataRes.data.case.topicName
                })
                setAgentAccountVal({
                    value: dataRes.data.case.agentAccountID,
                    label: dataRes.data.case.agentFullName
                })
                doToast("Succesfully handover this case");
                setShowModalConfirmation(false);
                props.clearActiveHandoverCase();
            } else {
                doToast(dataRes.error.message, "fail");
            }

            setIsLoadingSubmit(false);
        })
    }

    let submitAssignMe = () => {
        setIsWaitAssignMe(true);
        let _activeHandoverCase = { ...props.activeHandoverCase };
        _activeHandoverCase.lastSubmit = new Date().valueOf();
        _activeHandoverCase.topicValue = topicVal.value;
        props.setActiveHandoverCase(_activeHandoverCase);
    }

    let onSelectAgent = (e) => {
        let _agent = { ...agentAccountVal };
        _agent.value = e.value;
        _agent.label = e.label;

        setAgentAccountVal(_agent);
    }

    let onSelectTopic = (e) => {
        let _topic = { ...topicVal };
        _topic.value = e.value;
        _topic.label = e.label;

        getAgentList(e.value);
        setTopicVal(_topic);
        setAgentAccountVal({
            value: "",
            label: ""
        })
    }

    let styleSelect = () => ({
        indicatorsContainer: provide => ({
            ...provide,
            height: "48px",
        }),
        valueContainer: provide => ({
            ...provide,
            height: "100%",
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused ? iconDropOrange : iconDropGrey,
            transition: "0.25s ease-in-out",
            color: "transparent !important",
            width: "24px",
            height: "24px",
            zIndex: 0
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            // width: "300px"
        }),
        control: (provide, { isFocused }) => ({
            ...provide,
            padding: "0 5px",
            // height: "48px",
            borderRadius: "8px",
            border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
            boxShadow: "none !important",
            // marginBottom: "24px",
            cursor: "pointer"
        }),
        indicatorSeparator: provide => ({ ...provide, display: "none" }),
        menu: provide => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden"
        }),
        option: (provide, state) => {
            let { isSelected } = state;
            let checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)"
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null
            };
        }
    })

    return (
        <>
            <Modal
                isOpen={props.activeHandoverCase.show && !showModalConfirmation}
                className="modal-handover-case"
                toggle={() => {
                    if (!isLoadingSubmit) {
                        setTopicVal(topicValOrigin);
                        setAgentAccountVal(agentAccountValOrigin);
                        props.clearActiveHandoverCase()
                    }
                }}
            >
                <ModalBody>
                    <p className="modal-handover-case-title">
                        {props.activeHandoverCase.topicID === 0 ?
                            <b>Assign Me</b>
                            :
                            <b>Handover Case</b>
                        }
                    </p>

                    <label><b>Topic</b></label>

                    <div className="custom-select2-wrapper">
                        <Select
                            options={props.activeHandoverCase.topicID !== 0 ? props.allTopicListHandover : props.myTopics}
                            placeholder="Select Topic"
                            onChange={(e) => onSelectTopic(e)}
                            styles={styleSelect()}
                            classNamePrefix="custom-select-filter-option"
                            value={(props.activeHandoverCase.topicID !== 0 ? props.allTopicListHandover : props.myTopics).filter(
                                (obj) => obj.value === topicVal.value
                            )}
                            blurInputOnSelect={true}
                        />
                    </div>

                    {props.activeHandoverCase.topicID !== 0 &&
                        <>
                            <label><b>Agent</b></label>
                            <div className="custom-select2-wrapper">
                                <Select
                                    options={agentList}
                                    placeholder="Select Agent"
                                    onChange={(e) => onSelectAgent(e)}
                                    styles={styleSelect()}
                                    classNamePrefix="custom-select-filter-option"
                                    value={agentList.filter(
                                        (obj) => obj.value === agentAccountVal.value
                                    )}
                                    blurInputOnSelect={true}
                                />
                            </div>
                        </>
                    }

                    <div className="handover-case-button-wrapper">
                        <ButtonWithLoadingOrIcon
                            text="Cancel"
                            className="main-button-48 no-fill-button"
                            onClickAction={() => {
                                if (!isLoadingSubmit) {
                                    setTopicVal(topicValOrigin);
                                    setAgentAccountVal(agentAccountValOrigin);
                                    props.clearActiveHandoverCase()
                                }
                            }}
                        />

                        {isWaitAssignMe ?
                            <ButtonWithLoadingOrIcon
                                text={`Submit`}
                                className="main-button-48 orange-button"
                                loadingColor="gray"
                                isDisabled
                                isLoading
                                position="left"
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                text={`Submit`}
                                className="main-button-48 orange-button"
                                onClickAction={() => {
                                    // submitAssignMe
                                    props.activeHandoverCase.topicID === 0 ?
                                        submitAssignMe()
                                        :
                                        setShowModalConfirmation(true);
                                }}
                                isDisabled={isDisabled()}
                            />
                        }
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={props.activeHandoverCase.show && showModalConfirmation}
                className="modal-handover-case"
                toggle={() => {
                    if (!isLoadingSubmit) {
                        setShowModalConfirmation(false);
                        setTopicVal(topicValOrigin);
                        setAgentAccountVal(agentAccountValOrigin);
                        props.clearActiveHandoverCase();
                    }
                }}
            >
                <ModalBody>
                    <p className="modal-handover-case-title"><b>Handover Case</b></p>

                    <p className="modal-handover-description">
                        You are about to handover this case to {agentAccountVal.label} in topic: {topicVal.label}. Are you sure you want to handover the case?
                    </p>

                    <div className="handover-case-button-wrapper">
                        <ButtonWithLoadingOrIcon
                            text="Cancel"
                            className="main-button-48 no-fill-button"
                            onClickAction={() => {
                                if (!isLoadingSubmit) {
                                    setShowModalConfirmation(false);
                                    setTopicVal(topicValOrigin);
                                    setAgentAccountVal(agentAccountValOrigin);
                                    props.clearActiveHandoverCase();
                                }
                            }}
                        />

                        {!isLoadingSubmit ?
                            <ButtonWithLoadingOrIcon
                                text="Handover"
                                className="main-button-48 orange-button"
                                onClickAction={() => {
                                    submitHandover()
                                    // props.setActiveHandoverCase(false)
                                }}
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                text="Handover"
                                className="main-button-48 orange-button"
                                isLoading
                                loadingColor="gray"
                                isDisabled
                                position="left"
                            />
                        }
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}


const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    caseDetailData: state.caseDetailData,
    activeHandoverCase: state.activeHandoverCase,
    submitHandoverCase: state.submitHandoverCase,
    allTopicList: state.allTopicList,
    myTopics: state.myTopics,
    allTopicListHandover: state.allTopicListHandover
});

const mapDispatchToProps = {
    setActiveHandoverCase,
    setSubmitHandoverCase,
    clearActiveHandoverCase
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHandoverCaseModal);