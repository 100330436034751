import React from "react";
import "./ChatRoomEmailCompText.scss";

let ChatRoomEmailCompText = (props) => {
    let { singleChatDataProps } = props;

    let printHtml = singleChatDataProps.data.body.html === "" ? singleChatDataProps.data.body.text : singleChatDataProps.data.body.html;

    printHtml = printHtml.replace(/(<style[\w\W]+style>)/g, "").replace(/(<script[\w\W]+script>)/g, "");

    return (
        <div className="comp-email-wrapper-text">
            <span dangerouslySetInnerHTML={{ __html: printHtml.replace(/(<style[\w\W]+style>)/g, "") }} />
        </div>
    )
}

export default ChatRoomEmailCompText;