import React from "react";
import Select from "react-select";
import "./CustomSelect2.scss";

let CustomSelect2 = (props) => {
    let { options, placeholder, onChange, _className, _disabled, val, isBorderRed } = props;

    let iconDropOrange = "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
    let iconDropGrey = "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";
    const iconDropDark = "url('/image/chevron-down-dark.svg') center / contain no-repeat !important";

    let styleSelect = (isLoading) => ({
        indicatorsContainer: (provide) => ({
            ...provide,
            height: "48px",
        }),
        valueContainer: (provide) => ({
            ...provide,
            height: "100%",
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused, isDisabled }) => ({
            ...provided,
            background: isDisabled ? iconDropGrey : (isFocused ? iconDropOrange : iconDropDark),
            transition: "0.25s ease-in-out",
            width: "24px",
            height: "24px",
            visibility: isLoading ? "hidden" : "visible",
            padding: "8px 32px 8px 8px"
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            width: "100%",
            // zIndex: 9999
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "rgba(25, 25, 25, 0.4)",
        }),
        control: (provide, { isFocused, isDisabled }) => ({
            ...provide,
            padding: "0 5px",
            // height: "48px",
            borderRadius: "8px",
            border: isFocused
                ? "1px solid #ff7e00 !important"
                : "1px solid rgba(25, 25, 25, 0.1) !important",
            boxShadow: "none !important",
            marginBottom: "0",
            cursor: "pointer",
            backgroundColor: isDisabled ? "rgba(25, 25, 25, 0.1)" : "white",
        }),
        indicatorSeparator: (provide) => ({ ...provide, display: "none" }),
        menu: (provide) => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
        }),
        option: (provide, state) => {
            const { isSelected } = state;
            const checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)",
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null,
            };
        },
        input: (baseStyles) => ({
            ...baseStyles,
            color: 'transparent'
        })
    });

    return (
        <div className={`custom-select2-wrapper ${isBorderRed ? "border-red" : ""}`}>
            <Select
                options={options}
                placeholder={placeholder}
                onChange={(e) => {
                    onChange(e);
                }}
                styles={styleSelect()}
                classNamePrefix={`react-select-custom`}
                isDisabled={_disabled}
                value={val}
                blurInputOnSelect={true}
                classNames={_className}
            />
        </div>
    )
}

export default CustomSelect2;