import React, { useState, useEffect, useRef } from "react";
import "./LabelEdit.scss";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast, scrollToClass } from "../../../helper/HelperGeneral";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
// import iconBrushBlack from "../../../assets/img/icon-brush-black.svg";
// import { SketchPicker } from 'react-color';
import { FiCheck, FiInfo, FiTrash2 } from "react-icons/fi";
import CaseLabelService from "../../../services/newServices/CaseLabelService";
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading'
import BlockingDiscardChanges from "../../reuseableComponent/blockingDiscardChanges/BlockingDiscardChanges";
import PopupRemove from "../../reuseableComponent/popupRemove/PopupRemove";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
// import ServiceLabel from "../../../services/newServices/LabelService";
// import HelperInput from "../../../helper/HelperInput";

let LabelEdit = props => {
  let { match, history, location } = props;
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [showPopupRemove, setShowPopupRemove] = useState(false);
  let [orgID, setOrgID] = useState(false);
  let [isWaitResponseEditLabel, setIsWaitResponseEditLabel] = useState(false);
  let [isShowLabelColorPicker, setIsShowLabelColorPicker] = useState(false);
  let colorPickerRef = useRef(null);
  let [isLoadingGetDetailLabel, setIsLoadingGetDetailLabel] = useState(false)
  // let [detailLabel, setDetailLabel] = useState(null)
  // let [isButtonEditDisabled, setIsButtonEditDisabled] = useState(true)
  let [labelCreatedTime, setLabelCreatedTime] = useState("")
  let [labelName, setLabelName] = useState("")
  let [labelDescription, setLabelDescription] = useState("")
  let [labelColor, setLabelColor] = useState("");
  let [labelNameOri, setLabelNameOri] = useState("")
  let [labelDescriptionOri, setLabelDescriptionOri] = useState("")
  let [labelColorOri, setLabelColorOri] = useState("");
  // let [initialLabelName, setInitialLabelName] = useState("")
  // let [initialLabelDescription, setInitialLabelDescription] = useState("")
  let [intialLabelColor, setInitialLabelColor] = useState("")
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [errorMessageData, setErrorMessageData] = useState({
    label: "",
    color: ""
  })
  let [colorList, setColorList] = useState([
    {
      id: 1,
      color: '#E02D2D',
      selected: false
    },
    {
      id: 2,
      color: '#E5057E',
      selected: false
    },
    {
      id: 3,
      color: '#CC1FCF',
      selected: false
    },
    {
      id: 4,
      color: '#9B30EF',
      selected: false
    },
    {
      id: 5,
      color: '#6C2FEC',
      selected: false
    },
    {
      id: 6,
      color: '#4131F5',
      selected: false
    },
    {
      id: 7,
      color: '#056DFF',
      selected: false
    },
    {
      id: 8,
      color: '#008496',
      selected: false
    },
    {
      id: 9,
      color: '#00873F',
      selected: false
    },
    {
      id: 10,
      color: '#458500',
      selected: false
    },
    {
      id: 11,
      color: '#857700',
      selected: false
    },
    {
      id: 12,
      color: '#A16B00',
      selected: false
    },
    {
      id: 13,
      color: '#CC4B14',
      selected: false
    }
  ])

  let back = () => history.push(`/o/${orgID}/organization/labels`);

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/labels`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let toggleModalRemoveLabel = () => {
    if(!waitForResponseDelete) {
      setShowPopupRemove(!showPopupRemove);
    }
  }

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (orgID) {
      getDetailLabel(match.params.id)
    }
  }, [orgID])

  let getDetailLabel = (id) => {
    setIsLoadingGetDetailLabel(true)

    CaseLabelService.getCaseLabelDetail(orgID, id, (response) => {
      console.log(response.dataResult.data)
      if (response.dataResult.status === 200) {
        // setInitialLabelName(response.dataResult.data.caseLabel.name)
        setLabelCreatedTime(response.dataResult.data.caseLabel.createdTime)
        setLabelName(response.dataResult.data.caseLabel.name)
        setLabelNameOri(response.dataResult.data.caseLabel.name)
        // setInitialLabelDescription(response.dataResult.data.caseLabel.description)
        setLabelDescription(response.dataResult.data.caseLabel.description)
        setLabelDescriptionOri(response.dataResult.data.caseLabel.description)
        setInitialLabelColor(response.dataResult.data.caseLabel.backgroundColor)
        setLabelColor(response.dataResult.data.caseLabel.backgroundColor)
        setLabelColorOri(response.dataResult.data.caseLabel.backgroundColor)
      } else {
        let _retriveDataError = { ...retriveDataError };
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail')
      }
      setIsLoadingGetDetailLabel(false)
    })
  }

  useEffect(() => {
    if (intialLabelColor) {
      let _colorList = [...colorList]
      let idx = _colorList.findIndex(item => item.color.toLowerCase() === intialLabelColor)

      if (idx !== -1) {
        _colorList[idx].selected = true
      }

      setColorList(_colorList)
    }
  }, [intialLabelColor])

  let editLabel = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    if (labelName === "") {
      _errorMessageData.label = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-name" }
    }

    if (labelName.length > 50) {
      _errorMessageData.label = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-name" }
    }

    if (labelColor === "") {
      _errorMessageData.color = "Please select color";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-customize" }
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      setIsWaitResponseEditLabel(true);
      setCondition(false)

      let dataLabel = {
        id: match.params.id,
        name: labelName,
        description: labelDescription,
        backgroundColor: labelColor
      }

      CaseLabelService.editCaseLabel(orgID, dataLabel, (response) => {
        let dataResult = response.dataResult;

        if (dataResult.status === 200) {
          if (dataResult.data.success) {
            // setInitialLabelName(dataLabel.name)
            // setInitialLabelDescription(dataLabel.description)
            // setInitialLabelColor(dataLabel.backgroundColor)
            // getDetailLabel(match.params.id)
            // resetSelectedColor()
            setLabelColorOri(labelColor);
            setLabelNameOri(labelName);
            setLabelDescriptionOri(labelDescription);
            doToast(dataResult.data.message)

            setTimeout(() => {
              history.push(`/o/${orgID}/organization/labels`)
            }, 3000)
          } else {
            setIsWaitResponseEditLabel(false);
            doToast(dataResult.data.message, "fail");
            setCondition(true)
          }
        } else {
          doToast(dataResult.error.message, "fail");
          setIsWaitResponseEditLabel(false);
        }
      });
    }
  }

  // useEffect(() => {
  //   if (labelName === '' || labelColor === '') {
  //     setIsButtonEditDisabled(true)
  //   } else if (labelName === initialLabelName && labelDescription === initialLabelDescription && labelColor === intialLabelColor) {
  //     setIsButtonEditDisabled(true)
  //     setCondition(false)
  //   } else {
  //     if (!validateHex()) {
  //       setIsButtonEditDisabled(true)
  //     } else {
  //       setIsButtonEditDisabled(false)
  //     }
  //     setCondition(true)
  //   }
  // }, [labelName, initialLabelName, labelDescription, initialLabelDescription, labelColor, intialLabelColor])

  useEffect(() => {
    let onBodyClick = (event) => {
      if (isShowLabelColorPicker) {
        if (!colorPickerRef.current.contains(event.target) && !colorPickerRef.current.previousSibling.contains(event.target)) {
          setIsShowLabelColorPicker(false);
        }
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, [isShowLabelColorPicker]);

  // let changeColorPicker = (color) => {
  //   setLabelColor(color.hex)
  // }

  // let toggleColorPicker = () => {
  //   setIsShowLabelColorPicker(!isShowLabelColorPicker);
  // }

  // let hexToRgb = (hex) => {
  //   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //   return result ? {
  //     r: parseInt(result[1], 16),
  //     g: parseInt(result[2], 16),
  //     b: parseInt(result[3], 16)
  //   } : null;
  // }

  // let validateHex = () => {
  //   return /^#[0-9A-F]{6}$/i.test(labelColor)
  // }

  // let handleChangeColor = (e) => {
  //   if (e.target.value === '') {
  //     setLabelColor(e.target.value);
  //   } else {
  //     setLabelColor(getHexWithHashTag(e.target.value));
  //   }
  // }

  // let getHexWithHashTag = (val) => {
  //   return `#${val}`
  // }

  // let getHexWithoutHashTag = (val) => {
  //   // if (!val) return;
  //   return val.substring(1, 7)
  // }

  let onChangeLabelName = (e) => {
    let _errorMessageData = { ...errorMessageData };
    _errorMessageData.label = "";
    setErrorMessageData(_errorMessageData);
    setLabelName(e.target.value)
  };

  let onChangeLabelDescription = (e) => {
    setLabelDescription(e.target.value)
  };

  // for blocking page
  let toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  let discardProgress = () => {
    setIsShowModalDiscard(false)
    setConfirmedNavigation(true)
  }

  let [confirmedNavigation, setConfirmedNavigation] = useState(false)
  let [condition, setCondition] = useState(false)
  let [isShowModalDiscard, setIsShowModalDiscard] = useState(false)

  let handleOpenModal = (isOpen) => {
    setIsShowModalDiscard(isOpen)
  }

  let handleSelectColor = (item) => {
    if(!isWaitResponseEditLabel) {
      let _colorList = [...colorList]
      _colorList.forEach(item => {
        item.selected = false
      })
      _colorList[item.id - 1].selected = true
  
      setLabelColor(item.color)
      setColorList(_colorList)
    }
  }

  // let resetSelectedColor = () => {
  //   let _colorList = [...colorList]
  //   _colorList.forEach(item => {
  //     item.selected = false
  //   })
  //   setColorList(_colorList)
  // }

  let deleteLabel = () => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    let _body = null;

    setWaitForResponseDelete(true);

    _body = {
      id: parseInt(props.match.params.id),
      createdTime: labelCreatedTime
    }

    CaseLabelService.deleteCaseLabel(orgID, _body, (response) => {
      if (response.dataResult.status === 200) {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Remove Member",
            {
              userFullName: myAgentData.account.fullName,
              userID: myAgentData.account.id,
              organizationID: orgID.split("-")[0]
            }
          );
        }

        if(response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
  
          setTimeout(() => {
            back()
          }, 3000)
        }else {
          setWaitForResponseDelete(false);
          doToast(response.dataResult.data.message, "fail");
        }
      } else {
        doToast(response.dataResult.error.message, "fail");
        setWaitForResponseDelete(false);
      }
    })
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify({
          1: labelNameOri,
          2: labelDescriptionOri,
          3: labelColorOri
        })}
        dataAfterChange={JSON.stringify({
          1: labelName,
          2: labelDescription,
          3: labelColor
        })}
      >
        {
          isLoadingGetDetailLabel || retriveDataError.code ?
            <RetrieveDataLoading
              isLoading={isLoadingGetDetailLabel}
              errorMessage={retriveDataError.message}
            />
            :
            <>
              <BlockingDiscardChanges
                openModal={handleOpenModal}
                condition={condition}
                confirmedNavigation={confirmedNavigation}
              />

              <BackButton text="Back to Case Labels" onClick={back} />

              <PopupRemove
                removeTitle={`Remove label?`}
                removeDescription={`This label will be removed from this organization`}
                onClickCancel={() => toggleModalRemoveLabel(false)}
                onClickRemove={() => deleteLabel(false)}
                waitForResponse={waitForResponseDelete}
                showModalProps={showPopupRemove}
                colorButton="red-button"
                waitForResponseDelete={waitForResponseDelete}
              />

              <div className="sectionWrap form-edit-label">
                <div>
                  <div className="form-title-wrapper">
                    <b>Edit Label</b>

                    <ButtonWithLoadingOrIcon
                      text="Delete Label"
                      position="left"
                      className="red-button main-button-40"
                      icon={{
                        src: FiTrash2,
                        type: "svg"
                      }}
                      onClickAction={toggleModalRemoveLabel}
                    />

                    {isWaitResponseEditLabel ?
                      <ButtonWithLoadingOrIcon
                        isLoading
                        loadingColor="gray"
                        isDisabled
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                      />
                      :
                      <ButtonWithLoadingOrIcon
                        className="orange-button main-button-40"
                        text="Save Changes"
                        onClickAction={editLabel}
                      // isDisabled={isButtonEditDisabled}
                      />
                    }
                  </div>

                  <div className="form-content-wrapper">
                    <label className="pos-relative">
                      <b>Name</b>

                      <span className={errorMessageData.label === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{labelName.length}/50</span>
                    </label>
                    <input disabled={isWaitResponseEditLabel} type="text" placeholder="Type label name here" id="label" onChange={(e) => onChangeLabelName(e)} value={labelName} className={`input-name ${errorMessageData.label !== "" ? "border-red" : ""}`} />
                    <ErrorOrInfoComp
                      text={errorMessageData.label !== "" ? errorMessageData.label : "This label will be searchable in OneTalk's inbox."}
                      _className={errorMessageData.label !== "" ? "font-red margin-top-0" : "margin-top-0"}
                      icon={<FiInfo />}
                    />

                    {/* <p className="input-info-wrapper">
                      This label will be searchable in OneTalk's inbox.
                    </p> */}

                    <label><b>Description</b><span className="input-label-optional"> (optional)</span></label>
                    <textarea disabled={isWaitResponseEditLabel} placeholder="Type description here" id="description" onChange={(e) => onChangeLabelDescription(e)} value={labelDescription}
                    />

                    <div className="input-customize">
                      <div className="input-color">
                        <p>
                          <b>Select Color</b>
                        </p>
                        <div className={`color-select-wrapper ${isWaitResponseEditLabel ? "grey-box" : ""} ${errorMessageData.color === "" ? "" : "border-red"}`}>
                          {
                            colorList.map(item => {
                              return (
                                <div className="color-item" key={`color-${item.id}`} style={{ backgroundColor: item.color }} onClick={() => { if (!isWaitResponseEditLabel) { handleSelectColor(item) } }}>
                                  {
                                    item.selected && <FiCheck />
                                  }
                                </div>
                              )
                            })
                          }
                        </div>
                        {/* <div className="color-input-wrapper">
                          <div
                            className="color-input-container"
                            onClick={() => {
                              toggleColorPicker();
                            }}
                          >
                            <>
                              {labelColor === '' ?
                                <img src={iconBrushBlack} alt="" className="brush-icon" />
                                :
                                <div className="color-value" style={{ background: labelColor }} />
                              }
                              <label className="color-tag">#</label>
                              <input className="color-input" type="text" onChange={handleChangeColor} value={getHexWithoutHashTag(labelColor)} placeholder="Select Color" maxLength="6" />
                            </>
                          </div>

                          {isShowLabelColorPicker && (
                            <div className="color-picker-container" ref={colorPickerRef}>
                              <FiX
                                onClick={toggleColorPicker}
                                className="close-color-picker"
                              />
                              <SketchPicker
                                color={labelColor}
                                onChange={changeColorPicker}
                              />
                            </div>
                          )}

                        </div> */}
                        <PopupRemove
                          removeTitle={`Discard Progress?`}
                          removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
                          onClickCancel={toggleModalDiscard}
                          onClickRemove={discardProgress}
                          // waitForResponse={waitForResponseDiscard}
                          showModalProps={isShowModalDiscard}
                          submitText="Discard Changes"
                          colorButton="red-button"
                        />
                      </div>
                    </div>
                  </div>

                  {errorMessageData.color !== "" &&
                    <ErrorOrInfoComp
                      text={errorMessageData.color}
                      _className={"font-red"}
                      icon={<FiInfo />}
                    />
                  }
                </div>
              </div>

            </>
        }
      </SectionWrap>
    </React.Fragment>
  );
};

export default LabelEdit;