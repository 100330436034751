import React, { useState, useEffect } from "react";
import "./AddCard.scss";
import { FiAlertCircle } from "react-icons/fi";
import Blocking401 from "../../../../reuseableComponent/blocking401/Blocking401";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../../reuseableComponent/sectionWrap/SectionWrap";
import Loading from "../../../../reuseableComponent/loading/Loading";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { MdInfoOutline } from "react-icons/md";
import iconVisa from "../../../../../assets/img/payment-card/visa.png";
import iconJsb from "../../../../../assets/img/payment-card/jcb.png";
import iconMastercard from "../../../../../assets/img/payment-card/mastercard.png";
import iconAmex from "../../../../../assets/img/payment-card/amex.png";
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import { FiChevronDown } from "react-icons/fi";
import { checkID, doToast } from "../../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import BillingServices from "../../../../../services/newServices/BillingServices";

const CARD_BRAND = {
    visa: {
        label: "Visa",
        img: iconVisa
    },
    mastercard: {
        label: "Mastercard",
        img: iconMastercard
    },
    jcb: {
        label: "JCB",
        img: iconJsb
    },
    american_express: {
        label: "American Express",
        img: iconAmex
    },
}

let AddCard = (props) => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [isShowTooltipCvc, setIsShowTooltipCvc] = useState(false);
    // let [isShowDropdownCountry, setIsShowDropdownCountry] = useState(false);
    let [isWaitBillingConfigResponse, setIsWaitBillingConfigResponse] = useState(false);
    let [billingConfig, setBillingConfig] = useState(false);
    let [isWaitCardAuthentication, setIsWaitCardAuthentication] = useState(false);
    let [cardTokenData, setCardTokenCata] = useState(false);
    let [cardFormData, setCardFormData] = useState({
        cardName: "",
        cardNumber: "",
        cardCvvCvc: "",
        cardExpiry: ""
    })
    let [errorAddCard, setErrorAddCard] = useState({
        name: "",
        number: "",
        cvvCvc: "",
        expiry: ""
    })
    
    let back = () => history.push({
        pathname: `/o/${orgID}/organization/billing`,
        state: {
            whichTab: "paymentSettings"
        }
    })

    let goToVerifyCard = (formData, tokenData) => {
        history.push({
            pathname: `/o/${orgID}/organization/billing/verify-card`,
            state: {
                formData: formData,
                tokenData: tokenData
            }
        });
    } 

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if(response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              const { id, createdTime } = responseData.memberships[0].organization;
              const urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/billing`);
            }else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let toggleTooltip = () => {
        setIsShowTooltipCvc(!isShowTooltipCvc);
    }

    // let toggleDropdownCountry = () => {
    //     setIsShowDropdownCountry(!isShowDropdownCountry);
    // }

    React.useEffect(() => {
        if(props.history.location.state) {
            if(props.history.location.state.message) {
                doToast(props.history.location.state.message);
            }
            
            window.history.pushState(null, '');
        }
    }, [props.history])
    
    useEffect(() => {
       doMount();
    }, [location.pathname]);

    useEffect(() => {
        if(orgID) {
            if(process.env.REACT_APP_HIDE_BILLING === "false") {
                getBillingConfig();
            }
        }
    }, [orgID])

    useEffect(() => {
        if(cardTokenData) {
            goToVerifyCard(cardFormData, cardTokenData)
        }
    }, [cardTokenData])

    let getBillingConfig = () => {
        setIsWaitBillingConfigResponse(true);

        BillingServices.getBillingConfig(orgID, {
            onFailCredential: (errorMessage, erroCode) => {
                doToast(errorMessage, "fail")
            },
            onSuccessCredential: (response) => {
                setIsWaitBillingConfigResponse(false);
                setBillingConfig(response.data);
            }
        })
    }

    let createXenditToken = (e) => {
        e.preventDefault();

        let _cardFormData = {...cardFormData};

        setIsWaitCardAuthentication(true);

        window.Xendit.setPublishableKey(billingConfig.paymentGateways.credentials.xendit.clientKey);

        let data ={
            card_number: _cardFormData.cardNumber.replace(/\s/g, ''),
            card_exp_month: _cardFormData.cardExpiry.split("/")[0],
            card_exp_year: "20"+_cardFormData.cardExpiry.split("/")[1],
            card_cvn: _cardFormData.cardCvvCvc,
            is_multiple_use: true
        }

        let xenditTokenResponseHandler = (err, tokenResponse) => {   
            if(err !== null) {
                doToast(err.message, "fail");
                setIsWaitCardAuthentication(false);
            }else {
                setIsWaitCardAuthentication(false);
                setCardTokenCata(tokenResponse);
            }
        }
        
        window.Xendit.card.createToken(data, xenditTokenResponseHandler);
    }

    let getCardBrandId = (cardNumber) => {
        let _value = false;

        let regs = {
            visa: /^4[0-9]\d+$/,
            mastercard: /^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9][0-9]|27[0-1][0-9]|2720)\d+$/,
            american_express: /^(34|37)\d+$/,
            jcb: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/,
        };

        Object.keys(regs).map((value) => {
            if(regs[value].test(cardNumber)) {
                _value = value;
            }
            
            return null;
        })

        return _value;
    }

    let onChangeFormCard = (e) => {
        let _cardFormData = {...cardFormData};
        let _errorAddCard = {...errorAddCard};

        if(e.target.id === "cardExpiry") {
            let value = e.target.value;
                
            if(value.length === 2) {
                value = value + "/";
            }
                
            _cardFormData[e.target.id] = value;        
        }else if(e.target.id === "cardNumber") {
            let value = e.target.value;
            
            if(value.length > 0) {
                value = value.split(" ").join("");
                value = value.match(new RegExp('.{1,4}', 'g')).join(" ");
            }
            
            if(_errorAddCard.number !== "" && window.Xendit.card.validateCardNumber(value.replace(/\s/g, ''))) {
                _errorAddCard.number = "";
            }

            _cardFormData[e.target.id] = value;
        }else {
            _cardFormData[e.target.id] = e.target.value;
        }

        setCardFormData(_cardFormData);

        if(e.target.id === "cardName" && e.target.value.length === 0) {
            _errorAddCard.name = "";
        }
  
        if(e.target.id === "cardNumber"  && e.target.value.length === 0) {
            _errorAddCard.number = "";
        }
  
        if(e.target.id === "cardExpiry" && e.target.value.length === 0) {
            _errorAddCard.expiry = "";
        }
  
        if(e.target.id === "cardCvvCvc" && e.target.value.length === 0) {
            _errorAddCard.cvvCvc = "";
        }
        
        setErrorAddCard(_errorAddCard);
    }

    let onKeyPressNumberOnly = (e) => {
        if(e.keyCode !== 8) {
            if(e.keyCode > 57 || e.keycode < 47 || e.shiftKey) {
                e.preventDefault();
            }
        }
    }

    let onKeyPressExpiry = (e) => {
        let data = {...cardFormData};
        let value = e.target.value;
        
        if(e.keyCode !== 8) {
            if(e.keyCode > 57 || e.keycode < 47 || e.shiftKey) {
                e.preventDefault();
            }
        }else {
            if(e.keyCode === 8 && (data[e.target.id].includes("/") && data[e.target.id].length === 3)) {
                data[e.target.id] = value[0];    
                setCardFormData(data);
                e.preventDefault();
            }
        }
    }

    let checkExpiriy = (month, year) => {
        let monthNow = new Date().getMonth() + 1;
        let yearNow = parseInt(new Date().getFullYear().toString().substr(-2));
        let result = false;
        let _year = parseInt(year);
        let _month = month.length === 2 && month[0] === "0" ? parseInt(month[1]) : parseInt(month);
        
        if(_year > yearNow) {
            result = true;
        }else if(_year === yearNow) {
            if(_month >= monthNow) {
                result = true;
            }else {
                result = false;
            }
        }else {
            result = false;
        }

        return result
    }

    let disabledVerifyCard = () => {
        let data = {...cardFormData};
        let regNumber = /\d/;

        return (
            data.cardName.length < 1 ||
            regNumber.test(data.cardName) ||
            !window.Xendit.card.validateCardNumber(data.cardNumber.replace(/\s/g, '')) ||
            // !window.Xendit.card.validateExpiry(data.cardExpiry.split("/")[0], data.cardExpiry.split("/")[1]) ||
            !window.Xendit.card.validateCvn(data.cardCvvCvc) ||
            !checkExpiriy(data.cardExpiry.split("/")[0], data.cardExpiry.split("/")[1]) ||
            data.cardCvvCvc.length !== 3
        )
    }

    let onBlurInput = (e) => {
        let data = {...cardFormData};
        let id = e.target.id;
        let _errorAddCard = {...errorAddCard};
        let regNumber = /\d/;
        let errorText = {
            name: "Name on card cannot be numbers",
            number: "Card number is invalid",
            expiry: "Expiry is invalid",
            cvvCvc: "CVV/CVC is invalid"
        }

        if(id === "cardName") {
            if(regNumber.test(data.cardName)) {
                _errorAddCard.name = errorText.name;
            }else {
                _errorAddCard.name = "";
            }
        }

        if(id === "cardNumber") {
            if(!window.Xendit.card.validateCardNumber(data.cardNumber.replace(/\s/g, ''))) {
                _errorAddCard.number = errorText.number;
            }else {
                _errorAddCard.number = "";
            }
        }

        if(id === "cardExpiry") {
            if(
                // !window.Xendit.card.validateExpiry(data.cardExpiry.split("/")[0], data.cardExpiry.split("/")[1])
                !checkExpiriy(data.cardExpiry.split("/")[0], data.cardExpiry.split("/")[1])
            ) {
                _errorAddCard.expiry = errorText.expiry;
            }else {
                _errorAddCard.expiry = "";
            }
        }

        if(id === "cardCvvCvc") {
            if(!window.Xendit.card.validateCvn(data.cardCvvCvc) || data.cardCvvCvc.length !== 3) {
                _errorAddCard.cvvCvc = errorText.cvvCvc;
            }else {
                _errorAddCard.cvvCvc = "";
            }
        }

        setErrorAddCard(_errorAddCard);
    }

    return (
        <React.Fragment>
            {process.env.REACT_APP_HIDE_BILLING === "true" ?
                <SectionWrap
                    {...props}
                    orgID={orgID}
                    withHeader
                    withSideMenu
                >
                    <Blocking401 {...props} />
                </SectionWrap>
                :
                <SectionWrap
                    {...props}
                    orgID={orgID}
                    withHeader
                    withSideMenu
                >   
                    <BackButton text="Back to Payment Settings" onClick={back} />

                    <div className="sectionWrap add-card-wrapper">
                        <p className="add-card-title">
                            <b>Add Card</b>
                        </p>

                        {isWaitBillingConfigResponse ?
                            <Loading />
                            :
                            <div className="form-content-wrapper">
                                <form onSubmit={(e) => createXenditToken(e)}>
                                    <label><b>Name on Card</b></label>
                                    <input type="text" 
                                        placeholder="Insert name on card"
                                        id="cardName"
                                        value={cardFormData.cardName}
                                        onChange={(e) => onChangeFormCard(e)}
                                        onBlur={(e) => onBlurInput(e)}
                                        maxLength="50"
                                        disabled={isWaitCardAuthentication}
                                    />
                                    {errorAddCard.name !== "" &&
                                        <p className="warning-error-red">
                                            <FiAlertCircle />
                                            {errorAddCard.name}
                                        </p>
                                    }

                                    <label><b>Card Number</b></label>
                                    <div className="card-number">
                                        <input type="text" 
                                            maxLength="24"
                                            autoComplete="false"
                                            placeholder="xxxxxxxxxxxxxxxxx"
                                            id="cardNumber"
                                            onBlur={(e) => onBlurInput(e)}
                                            value={cardFormData.cardNumber}
                                            onChange={(e) => onChangeFormCard(e)}
                                            disabled={isWaitCardAuthentication}
                                        />
                                        {errorAddCard.number !== "" &&
                                            <p className="warning-error-red">
                                                <FiAlertCircle />
                                                {errorAddCard.number}
                                            </p>
                                        }
                                        
                                        {getCardBrandId(cardFormData.cardNumber.replace(/\s/g, '')) &&
                                            <img src={CARD_BRAND[getCardBrandId(cardFormData.cardNumber.replace(/\s/g, ''))].img} alt="" />
                                        }
                                    </div>

                                    <div className="payment-div-50"> 
                                        <label><b>Expiry</b></label>
                                        <input type="text" 
                                            autoComplete="false" 
                                            id="cardExpiry"
                                            value={cardFormData.cardExpiry}
                                            placeholder="mm/yy"
                                            maxLength="5"
                                            onKeyDown={(e) => onKeyPressExpiry(e)}
                                            onChange={(e) => onChangeFormCard(e)}
                                            onBlur={(e) => onBlurInput(e)}
                                            disabled={isWaitCardAuthentication}
                                        />
                                        {errorAddCard.expiry !== "" &&
                                            <p className="warning-error-red">
                                                <FiAlertCircle />
                                                {errorAddCard.expiry}
                                            </p>
                                        }
                                    </div>

                                    <div className="payment-div-50 no-margin"> 
                                        <label>
                                            <b>CVV/CVC </b>
                                            <MdInfoOutline onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} />

                                            <div className="custom-tooltip" style={{display: isShowTooltipCvc ? "block" : "none"}}>
                                                CVV/CVC is a 3 digit number that is 
                                                located at the back of your card.
                                            </div>
                                        </label>
                                        <input type="password" autoComplete="false" 
                                            id="cardCvvCvc"
                                            value={cardFormData.cardCvvCvc}
                                            placeholder="3 digits"
                                            maxLength="3"
                                            onKeyDown={(e) => onKeyPressNumberOnly(e)}
                                            onChange={(e) => onChangeFormCard(e)}
                                            onBlur={(e) => onBlurInput(e)}
                                            disabled={isWaitCardAuthentication}
                                        />
                                        {errorAddCard.cvvCvc !== "" &&
                                            <p className="warning-error-red">
                                                <FiAlertCircle />
                                                {errorAddCard.cvvCvc}
                                            </p>
                                        }
                                    </div>

                                    {/* <label><b>Country</b></label>
                                    <Dropdown isOpen={isShowDropdownCountry} toggle={toggleDropdownCountry}>
                                        <DropdownToggle>
                                            Indonesia

                                            <FiChevronDown className={isShowDropdownCountry ? "active-dropdown-card" : ""} />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>
                                                Indonesia
                                            </DropdownItem>
                                            <DropdownItem>
                                                India
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                    <label>
                                        <b>Address</b>
                                        <span className="span-optional">Optional</span>
                                    </label>
                                    <textarea rows="3" placeholder="Enter full address" /> */}

                                    {!isWaitCardAuthentication &&
                                        <ButtonWithLoadingOrIcon 
                                            isDisabled={disabledVerifyCard()}
                                            className="orange-button main-button-40"
                                            text="Verify Card"
                                            position="left"
                                        />
                                    }
                                </form>
                                
                                {isWaitCardAuthentication &&
                                    <ButtonWithLoadingOrIcon 
                                        isLoading
                                        loadingColor="gray"
                                        isDisabled
                                        className="orange-button main-button-40"
                                        text="Verify Card"
                                        position="left"
                                    />
                                }
                            </div>
                        }
                    </div>
                </SectionWrap>
            }
        </React.Fragment>
    )
}

export default AddCard;