import React, { useEffect } from "react";
import "./IntegrationHome.scss";
// import ServiceOrganization from "../../../services/organization/ServiceOrganization";
// import {
//     checkID,
//     // doToast
//   } from "../../../helper/HelperGeneral";

const IntegrationHome = props => {
    // let { match, history } = props;
    // let [ setOrgID ] = React.useState(false);

    // const doMount = () => {
    //     const paramID = checkID(match);
    //     if (paramID) {
    //         setOrgID(paramID);
    //     } else {
    //         ServiceOrganization.postGetOrganization((response) => {
    //             if(response.dataResult.error !== "") {
    //                 let responseData = response.dataResult.data;
    //                 if (responseData.memberships.length < 1) window.location.href = "/organization/new";
    //                 const { id, createdTime } = responseData.memberships[0].organization;
    //                 const urlOrgID = `${id}-${createdTime}`;
    //                 history.push(`/o/${urlOrgID}/integration`);
    //             }else {
    //                 console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
    //             }
    //         });
    //     }
    // };
    
    useEffect(() => {
        props.history.push("/home");
    }, [])

    return (
        <div className="integration-outer-wrapper">
            
        </div>
    )
}

export default IntegrationHome;