import React, { useState, useEffect } from 'react';
import './ChatRoomUserInfoTab.scss';
// import IconWorld from '../../../../../assets/img/chatroom/user-info-tab/world.svg';
import IconAt from '../../../../../assets/img/chatroom/user-info-tab/at.svg';
import IconPhone from '../../../../../assets/img/chatroom/user-info-tab/phone.svg';
import IconUser from '../../../../../assets/img/chatroom/user-info-tab/user.svg';
import IconNote from '../../../../../assets/img/chatroom/user-info-tab/note.svg';
import IconHelpCircle from '../../../../../assets/img/chatroom/user-info-tab/icon-help-circle.svg';
import IconUsers from '../../../../../assets/img/fi-user-circle.svg';
import IconBriefcase from '../../../../../assets/img/fi-briefcase.svg';
import { setReloadCaseDetail } from '../../../../../redux/actions/reduxActionReloadCaseDetail';
import { connect } from 'react-redux';
import { FiCopy, FiEdit } from 'react-icons/fi';
import { Tooltip } from 'reactstrap';
import CaseService from "../../../../../services/chat/caseServices";
import UserSegmentServices from "../../../../../services/newServices/UserSegmentServices";
import { Modal, ModalBody } from "reactstrap";
import { doToast, autoFocusField } from '../../../../../helper/HelperGeneral';
import HelperInput from '../../../../../helper/HelperInput';
import Helper from '../../../../../helper/HelperChat';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SelectSegment from "../../../../reuseableComponent/selectSegment/SelectSegment";
import { Scrollbars } from 'react-custom-scrollbars';
import mixpanel from "mixpanel-browser";
import { FIELD_TYPE_DATE, FIELD_TYPE_MULTIPLE_SELECT } from '../../../../setup/contactInfoCustomField/ContactInfoCustomField';
import HelperDate from '../../../../../helper/HelperDate';

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};

const copyToClip = (selector, text) => {
  document.getElementById(selector).select();
  document.execCommand("copy");
  doToast(text);
};

var ChatRoomUserInfoTab = (props) => {
  let [showTooltipAlias, setShowTooltipAlias] = useState(false);
  let [showModalEditRemarkForUser, setShowModalEditRemarkForUser] = useState(false);
  let [valEditRemarkForUser, setValEditRemarkForUser] = useState("");
  let [isWaitReponseEditRemarkForUser, setIsWaitReponseEditRemarkForUser] = useState(false);
  let [isShowEditUserRemarkButton, setIsShowEditUserRemarkButton] = useState(false);
  let [openSelectSegment, setOpenSelectSegment] = useState(false);
  let [loadingSubmitContactSegment, setLoadingSubmitContactSegment] = useState(false);

  let toggleSelectSegment = () => {
    setOpenSelectSegment(!openSelectSegment);
  }

  useEffect(() => {
    if(showModalEditRemarkForUser) {
      setTimeout(() => {
        autoFocusField("edit-remark-for-contact-input-1");
      }, 500)
    }
  }, [showModalEditRemarkForUser])

  useEffect(() => {
    if(props.caseDetailProps !== null) {
      let _userAgentData = props.myAgentData.account;

      setIsShowEditUserRemarkButton(_userAgentData.id === props.caseDetailProps.case.agentAccountID); 
    }
  }, [props.caseDetailProps])
  
  let toggleTooltip = () => {
    setShowTooltipAlias(!showTooltipAlias);
  }

  let toggleModalEditRemarkForUser = () => {
    setShowModalEditRemarkForUser(!showModalEditRemarkForUser);
  }

  let renderModalRemarkForCase = () => {
    let onChangeInputRemarkForUser = (e) => {
        setValEditRemarkForUser(e.target.value);
    }

    let submitRemarksForUser = () => {
        setIsWaitReponseEditRemarkForUser(true);

        let data = {
            userID: props.caseDetailProps.user.userID,
            agentRemark: valEditRemarkForUser
        }

        CaseService.postUpdateUserRemark(props.parentProps.match.params.orgID, data, (response) => {
            setIsWaitReponseEditRemarkForUser(false);
            let _dataResult = response.dataResult;

            if(response.dataResult.error.message === "") {
                doToast(response.dataResult.data.message);

                if(_dataResult.data.success) {
                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                      mixpanel.track(
                        "[Action] Set User Remark",
                        {
                          userFullName : props.myAgentData.account.fullName,
                          userID : props.myAgentData.account.id,
                          organizationID : props.parentProps.match.params.orgID.split("-")[0]
                        }
                      );
                    }

                    toggleModalEditRemarkForUser();
                    props.actionRunGetRoomDataProps();
                }
            }else {
                doToast(response.dataResult.error.message, "fail");
            }
        })
    }

    return (
        <Modal isOpen={showModalEditRemarkForUser} toggle={toggleModalEditRemarkForUser} className={"modal-edit-alias"}>
            <ModalBody>
                <p className="title">
                    <b>Edit Remarks for Contact</b>
                </p>

                <textarea 
                  type="text" 
                  value={valEditRemarkForUser} 
                  onChange={(e) => onChangeInputRemarkForUser(e)} 
                  placeholder="Write down anything that may help you or others understand the customer better" 
                  rows="2"
                  className='edit-remark-for-contact-input-1'
                  disabled={isWaitReponseEditRemarkForUser}
                />

                <ButtonWithLoadingOrIcon 
                    onClickAction={toggleModalEditRemarkForUser}
                    className="no-fill-button main-button-48"
                    text="Cancel"
                />

                {isWaitReponseEditRemarkForUser ?
                    <ButtonWithLoadingOrIcon 
                        isLoading
                        loadingColor="gray"
                        isDisabled
                        className="orange-button main-button-48"
                        text="Save Changes"
                        position="left"
                    />
                    :
                    <ButtonWithLoadingOrIcon 
                        onClickAction={submitRemarksForUser}
                        className="orange-button main-button-48"
                        text="Save Changes"
                    />
                }
            </ModalBody>
        </Modal>
    )
  }

  let runSetSegment = (_data) => {
    let _reloadCaseDetail = {...props.reloadCaseDetail};
    setLoadingSubmitContactSegment(true);
    let data = {};

    if(_data.length === 0) {
      data = {
        userID: props.caseDetailProps.user.userID,
        clearAll: true
      };
    }else {
      let arr = [];

      _data.map((v) => {
        arr.push(v.id);
        return null;
      })
      data = {
        segmentIDs: arr,
        userID: props.caseDetailProps.user.userID
      };
    }

    UserSegmentServices.setIntoSegment(props.parentProps.match.params.orgID, data, (res) => {
      if(res.dataResult.error.message === "") {
        if(res.dataResult.data.success) {
          toggleSelectSegment();
          doToast(res.dataResult.data.message);
          _reloadCaseDetail.lastRun = new Date().valueOf();
          _reloadCaseDetail.room = props.activeRoom;
          props.setReloadCaseDetail(_reloadCaseDetail);
        }else {
          doToast(res.dataResult.data.message, "fail");
        }
      }else {
        doToast(res.dataResult.error.message, "fail")
      }
      
      setLoadingSubmitContactSegment(false);
    });
  }

  let renderModalSelectSegment = () => {
    return (
      <Modal className="modal-select-segment" isOpen={openSelectSegment} toggle={toggleSelectSegment}>
        <ModalBody>
          <SelectSegment 
            orgID={props.parentProps.match.params.orgID}
            title={"Edit Contact Segments"}
            showSegment={openSelectSegment}
            onSubmitData={runSetSegment}
            _className={`input-segment`}
            toggle={toggleSelectSegment}
            originData={props.caseDetailProps.userSegments}
            isLoadingSubmit={loadingSubmitContactSegment}
          />
        </ModalBody>
      </Modal>
    )
  }

  let renderSegment = (data) => {
    let text = "";
    
    data.map((v) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  return (
    <Scrollbars autoHideTimeout={500}
                renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
    >
      <div className="chat-room-user-info-tab-wrapper main-user-info-wrapper">
          {renderModalRemarkForCase()}
          {renderModalSelectSegment()}

          {props.caseDetailProps !== null && 
            <React.Fragment>
              <div className="user-info-list-wrapper">
                <label><b>Full Name</b></label>
                <div className="user-info-box-wrapper with-button-copy">
                  <img src={IconUser} alt="" />
                  {props.caseDetailProps.user.fullName}
                </div>
              </div>

              <div className="user-info-list-wrapper">
                <label>
                  <b>Alias</b>
                  <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-alias" alt="" />
                  <div>
                    <Tooltip className="tooltip-content" placement="left" isOpen={showTooltipAlias} target="tooltip-alias" toggle={toggleTooltip}>
                      Alias will be added as a prefix to the user’s name e.g Alias (Full Name)
                    </Tooltip>
                  </div>
                </label>
                <div className="user-info-box-wrapper with-button-copy">
                  <img src={IconUser} alt="" />
                  {props.caseDetailProps.user.alias ?
                    props.caseDetailProps.user.alias !== "" ?
                      props.caseDetailProps.user.alias
                      :
                      <span className="grey-font">E.g John</span>
                    :
                    <span className="grey-font">E.g John</span>
                  }
                </div>
              </div>

              <div className="user-info-list-wrapper">
                <label><b>Company</b></label>
                <div className="user-info-box-wrapper">
                  <img src={IconBriefcase} alt="" />
                  {props.caseDetailProps.user.companyName ?
                    props.caseDetailProps.user.companyName !== "" ?
                      props.caseDetailProps.user.companyName
                      :
                      <span className="grey-font">E.g Taptalk</span>
                    :
                    <span className="grey-font">E.g Taptalk</span>
                  }
                </div>
              </div>

              <div className="user-info-list-wrapper">
                <label><b>Job Title</b></label>
                <div className="user-info-box-wrapper">
                  <img src={IconUsers} alt="" />
                  {props.caseDetailProps.user.jobTitle ?
                    props.caseDetailProps.user.jobTitle !== "" ?
                      props.caseDetailProps.user.jobTitle
                      :
                      <span className="grey-font">E.g Account Executive</span>
                    :
                    <span className="grey-font">E.g Account Executive</span>
                  }
                </div>
              </div>

              <div className="user-info-list-wrapper">
                <label><b>Email Address</b></label>
                <div className="user-info-box-wrapper with-button-copy">
                  <img src={IconAt} alt="" />
                  {props.caseDetailProps.user.email === "" ? 
                    "-" 
                    : 
                    <input type="text"
                          value={props.caseDetailProps.user.email}
                          // onClick={copyToClip}
                          id="copy-email-detail"
                          readOnly
                    />
                  }

                  {props.caseDetailProps.user.email !== "" &&
                    <div className="user-detail-copy-wrapper" onClick={() => {
                      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                          "[Action] Copy Email Address",
                          {
                            userFullName : props.myAgentData.account.fullName,
                            userID : props.myAgentData.account.id,
                            organizationID : props.parentProps.match.params.orgID.split("-")[0]
                          }
                        );
                      }

                      copyToClip("copy-email-detail", "Email address copied")
                    }}>
                      <FiCopy />
                      <b>Copy</b>
                    </div>
                  }
                </div>
              </div>
              
              {props.caseDetailProps.user.phone !== "" &&
                <div className="user-info-list-wrapper">
                  <label><b>Phone Number</b></label>
                  <div className="user-info-box-wrapper with-button-copy">
                    <img src={IconPhone} alt="" />

                    <input type="text"
                          value={`+${HelperInput.phoneBeautify(props.caseDetailProps.user.phone)}`}
                          // onClick={copyToClip}
                          id="copy-phone-detail"
                          readOnly
                    />
                    {/* <span id="copy-phone-detail">+{props.caseDetailProps.user.phone}</span> */}

                    <div className="user-detail-copy-wrapper" onClick={() => {
                      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                          "[Action] Copy Phone Number",
                          {
                            userFullName : props.myAgentData.account.fullName,
                            userID : props.myAgentData.account.id,
                            organizationID : props.parentProps.match.params.orgID.split("-")[0]
                          }
                        );
                      }

                      copyToClip("copy-phone-detail", "Phone number copied")
                    }}>
                      <FiCopy />
                      <b>Copy</b>
                    </div>
                  </div>
                </div>
              }
            </React.Fragment>
          }

          {/* <div className="user-info-list-wrapper">
            <label><b>Location</b></label>
            <div className="user-info-box-wrapper">
              <img src="https://storage.googleapis.com/9a3048-taptalk-prd-public/static/flags/ID@2x.png" alt="" className="case-nation-flag" />
              Jakarta, Indonesia
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>IP Address</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconWorld} alt="" />
              192.169.1.1
            </div>
          </div> */}

          {/* <div className="user-info-list-wrapper">
            <label><b>Tags</b></label>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
          </div> */}

          {
            (props.caseDetailProps.user.customFields &&
            props.caseDetailProps.user.customFields.length > 0) &&
            <div className="user-info-list-wrapper remarks-for-contact">
              {
                props.caseDetailProps.user.customFields.map((customField, index) => {
                  let multipleSelectValue = "-";
                  if (customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT && customField.value) {
                    const multipleSelectValuesArray = JSON.parse(customField.value);
                    if (multipleSelectValuesArray && multipleSelectValuesArray.length > 0) {
                      multipleSelectValue = multipleSelectValuesArray.map((e, i) => (e)).join("\n");
                    }
                  }
                  return (
                    <>
                      <label><b>{customField.fieldName}</b></label>
                      <div className="user-info-box-wrapper-multiple-line no-icon">
                        {
                          customField.value
                          ?
                          customField.fieldType === FIELD_TYPE_DATE
                          ?
                          <span>{HelperDate.formatToString(new Date(Date.parse(customField.value, "yyyy-MM-dd")), "d MMM yyyy")}</span>
                          :
                          customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT
                          ?
                          <span dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(multipleSelectValue)}} />
                          :
                          <span dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(customField.value)}} /> 
                          :
                          <span className="grey-font">-</span>
                        }
                      </div>
                    </>
                  );
                })
              }
            </div>
          }

          <div className="user-info-list-wrapper remarks-for-contact">
            <label><b>Contact Segments</b></label>
            {isShowEditUserRemarkButton &&
              <span className="user-info-list-button-action" 
                onClick={() => {
                  if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                      "[Button] Select Contact Segment",
                      {
                        userFullName : props.myAgentData.account.fullName,
                        userID : props.myAgentData.account.id,
                        organizationID : props.parentProps.match.params.orgID.split("-")[0]
                      }
                    );
                  }
                  
                  toggleSelectSegment();
                }
              }>
                <FiEdit />
                <b>Edit</b>
              </span>
            }

            <p className='grey-font contact-segment-text'>
              {(props.caseDetailProps.userSegments && props.caseDetailProps.userSegments.length === 0) ?
                (isShowEditUserRemarkButton ? "Edit to add this contact to a contact segment" : "This contact is not in any contact segment")
                :
                <div dangerouslySetInnerHTML={{ __html: renderSegment(props.caseDetailProps.userSegments) }} />
              }
            </p>
          </div>

          <div className="user-info-list-wrapper remarks-for-contact">
            <label><b>Remarks for Contact</b></label>
            {isShowEditUserRemarkButton &&
              <span className="user-info-list-button-action" 
                onClick={() => {
                  if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                      "[Button] Set User Remark",
                      {
                        userFullName : props.myAgentData.account.fullName,
                        userID : props.myAgentData.account.id,
                        organizationID : props.parentProps.match.params.orgID.split("-")[0]
                      }
                    );
                  }
                  
                  setValEditRemarkForUser(props.caseDetailProps.user.agentRemark);
                  toggleModalEditRemarkForUser();
                }
              }>
                <FiEdit />
                <b>Edit</b>
              </span>
            }
            <div className="user-info-box-wrapper-multiple-line">
              <img src={IconNote} alt="" />
              {props.caseDetailProps.user.agentRemark ?
                props.caseDetailProps.user.agentRemark !== "" ?
                  <span dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(props.caseDetailProps.user.agentRemark) }} /> 
                  :
                  <span className="grey-font">Write down anything that may help you or others understand the user better</span>
                :
                <span className="grey-font">Write down anything that may help you or others understand the user better</span>
              }
            </div>
          </div>
        </div>
      </Scrollbars>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  reloadCaseDetail: state.reloadCaseDetail,
  myAgentData: state.myAgentData
});

const mapDispatchToProps = {
  setReloadCaseDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomUserInfoTab);
