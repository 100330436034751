import React, { useEffect, useState } from "react";
import "./ChatRoomModalInteractiveList.scss";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { FiX, FiList } from "react-icons/fi";
import { setActiveInteractiveList } from "../../../../redux/actions/reduxActionActiveInteractiveList";

var style = {
    scrollStyle: {
      position: "relative",
      backgroundColor: "#ff7d00",
      right: "-2px",
      width: "5px",
      borderRadius: "8px"
    }
};

let ChatRoomModalInteractiveList = (props) => {
    let [isShowModalFormInteractive, setIsShowModalFormInteractive] = useState(false);

    useEffect(() => {
        if(props.activeInteractiveList !== false) {
            setIsShowModalFormInteractive(true); 
        }else {
            setIsShowModalFormInteractive(false);
        }
    }, [JSON.stringify(props.activeInteractiveList)]);

    return (
        <>
            <div
                className={`
                    modal-interactiveList-message 
                    ${isShowModalFormInteractive ? 
                        "active-modal-interactiveList-message"
                        : 
                        ""
                    } 
                `}
            >
                <div
                    className={`
                        modal-body 
                        ${isShowModalFormInteractive ? 
                            "action-modal-body-interactiveList-message"
                            : 
                            ""
                        }
                    `}
                >
                    <div
                        onClick={() => props.setActiveInteractiveList(false)}
                        className="close-modal-interactiveList"
                    >
                        <FiX />
                    </div>
                    
                    <div className={`interactiveList-message-wrapper`}>
                        <Scrollbars
                            autoHideTimeout={500}
                            renderThumbVertical={(props) => (
                                <div {...props} style={style.scrollStyle} />
                            )}
                        >
                            <div className={`interactiveList-message-container`}>
                                <div className="interactiveList-message-title">
                                    <FiList />

                                    <b>Preview List</b>
                                    <p>
                                        Below is a preview of what your recipient will recieve.
                                    </p>
                                </div>

                                

                                <div className="interactive-section-list">
                                    {props.activeInteractiveList &&
                                        (props.activeInteractiveList.map((v, i) => {
                                            return (
                                                <React.Fragment key={`rows-list-${i}`}>
                                                    <p className="table-title">
                                                        <b>Section #{i+ 1}{v.title === "" ? "" : `: ${v.title}`}</b>
                                                    </p>

                                                    <table key={`rows-table-${i}`}>
                                                        <thead>
                                                            <tr>
                                                            <td><b>Row</b></td>
                                                            <td><b>Title</b></td>
                                                            <td><b>Description</b></td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {v.rows.map((_v, _i) => {
                                                                return (
                                                                    <tr key={`rows-tbody-${_i}`}>
                                                                        <td>{_i + 1}</td>
                                                                        <td>{_v.title}</td>
                                                                        <td>{_v.description}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </React.Fragment>
                                            )
                                        }))
                                    }
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>        
        </>
    );
};

const mapStateToProps = (state) => ({
    activeInteractiveList: state.activeInteractiveList
});

const mapDispatchToProps = {
    setActiveInteractiveList
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomModalInteractiveList);
  