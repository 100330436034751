const INITIAL_STATE = {
  isShow: false,
  data: {},
};

let reduxReducerSalestalkProductDetail = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_SALESTALK_PRODUCT_DETAIL':
        return action.salesTalkProductDetail;
      case 'CLEAR_SALESTALK_PRODUCT_DETAIL':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerSalestalkProductDetail;