import React, { useState, useEffect } from "react";
import "./AssignRulesTab.scss";
import { TabContent, Nav, NavItem } from 'reactstrap';
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import { checkID } from "../../helper/HelperGeneral";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import { connect } from 'react-redux';
import AssignmentRules from "../setup/assignmentRules/AssignmentRules";
import AssignIdleCase from "./AssignIdleCase";

const AssignRulesTab = (props) => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);

    let toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const ASSIGN = {
        assignRule: {
            id: "assignRule",
            tab: "Assignment Rules",
            component: <AssignmentRules {...props} />,
            // isHidden: props.memberRole === "member"
        },
        idleCase: {
            id: "idleCase",
            tab: "Idle Case Settings",
            component: <AssignIdleCase {...props} />
        },
    }

    // let [activeTab, setActiveTab] = useState(props.memberRole === "member" ? ASSIGN.personalQuickReply.id : ASSIGN.generalQuickReply.id);
    let [activeTab, setActiveTab] = useState(ASSIGN.assignRule.id);

    let doMount = async () => {
        const paramID = checkID(match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
    }, [location.pathname]);

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.tab) {
                setActiveTab(props.history.location.state.tab);
            }
        }
    }, [props.history])

    return (
        <>
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <div className="assign-rule-page-wrapper">
                    <>
                        <Nav tabs>
                            {Object.keys(ASSIGN).map((value, index) => {
                                return (
                                    !ASSIGN[value].isHidden &&
                                    <NavItem
                                        key={`tab-setup-${index}`}
                                        className={`
                                            ${activeTab === ASSIGN[value].id ?
                                                'active-setup-tab'
                                                :
                                                ''
                                            }
                                        `}
                                    >
                                        <div className="setup-tab-wrapper"
                                            onClick={() => { toggle(ASSIGN[value].id); }}
                                        >
                                            <b>{ASSIGN[value].tab}</b>
                                        </div>
                                    </NavItem>
                                )
                            })}
                        </Nav>

                        <TabContent>
                            {ASSIGN[activeTab].component}
                        </TabContent>
                    </>
                </div>
            </SectionWrap>
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(AssignRulesTab)