import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Tooltip } from 'reactstrap';
import { connect } from "react-redux";
import "./BroadcastMessageDetail.scss";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiActivity, FiClock, FiUser, FiUsers, FiDownload, FiHelpCircle, FiEdit, FiTrash2, FiMessageSquare } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
// import { BiMessageDetail } from "react-icons/bi";
import IconMessage from "../../../assets/img/sidemenu-icon/icon-messages-black.svg";
import IconMessagePending from "../../../assets/img/message-bubbles/comment-minus.svg";
import IconMessageSent from "../../../assets/img/message-bubbles/comment-checked.svg";
import IconMessageNotSent from "../../../assets/img/message-bubbles/comment-delete.svg";
import IconMessageRead from "../../../assets/img/message-bubbles/comment-read.svg";
import IconMessageResponded from "../../../assets/img/message-bubbles/comment-responded.svg";
import IconMessageDelivered from "../../../assets/img/message-bubbles/comment-delivered.svg";
// import IconComment from "../../../assets/img/message-bubbles/comment.svg";
import BroadcastBlack from "../../../assets/img/sidemenu-icon/new/sub/broadcast-message-black.svg";
import IconFile from "../../../assets/img/file.svg";
import BroadcastMessageServices from "../../../services/newServices/BroadcastMessageServices";
import { TAPLIVE_MEDIUM_LOGO, TAPLIVE_STATUS, RECIPIENT_TYPE } from "../../../constants/taplive";
import { MESSAGE_TYPE } from "../../../constants/message";
import HelperDate from "../../../helper/HelperDate";
import HelperInput from "../../../helper/HelperInput";
import HelperChat from "../../../helper/HelperChat";
import ColoredInfoBox from "../../reuseableComponent/coloredInfoBox/ColoredInfoBox";
// import ModalPreviewImageOrVideo from "../../reuseableComponent/cmodalPreviewImageOrVideo/ModalPreviewImageOrVideo";
import { setPreviewImageOrVideo } from "../../../redux/actions/reduxActionPreviewImageOrVideo";
import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
import Pagination from "../../reuseableComponent/pagination/Pagination";
import IcGroup from "../../../assets/img/ic-group.svg";
import ImageUpload from "../../reuseableComponent/imageUpload/ImageUpload";
import PopupConfirmation from "../../reuseableComponent/popupConfirmation/PopupConfirmation";
import { doToast } from "../../../helper/HelperGeneral";

// let MESSAGE_NUMBER = ["First", "Second", "Third", "Fourth", "Fifth"];
let RELEASE_DATE = new Date(2021, 10, 23);

let BroadcastMessageDetail = (props) => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [broadcastDetail, setBroadcastDetail] = useState();
  let [imageSrc, setImageSrc] = useState('');
  let [isShowModalImage, setIsShowModalImage] = useState(false);
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetBroadcastData, setIsLoadingGetBroadcastData] = useState(
    true
  );
  let [isLoadingGetRecipients, setIsLoadingGetRecipients] = useState(false);
  let [listPageVal, setListPageVal] = useState({
    label: "20",
    value: 20,
  });
  let [pageDisplay, setPageDisplay] = useState(1);
  let [listPage, setListPage] = useState(20);
  let listPageOption = [
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
  ];
  let [isLoadingGenerateReport, setIsLoadingGenerateReport] = useState(false);
  let [showTooltipDelivered, setShowTooltipDelivered] = useState(false);
  let [showTooltipRead, setShowTooltipRead] = useState(false);
  let [isShowModalDelete, setIsShowModalDelete] = useState(false);
  let [isLoadingDelete, setIsLoadingDelete] = useState(false);

  let toggleModalDelete = () => {
    setIsShowModalDelete(!isShowModalDelete);
  }

  let toggleTooltipRead = () => {
    setShowTooltipRead(!showTooltipRead)
  }

  let toggleTooltipDelivered = () => {
    setShowTooltipDelivered(!showTooltipDelivered)
  }

  let onSelectListPage = (val) => {
    let data = {
      id: checkID(match),
      pageNumber: 1,
      pageSize: val.value,
    };
    fetchBroadcastMessageDetail(data, false);
    setListPageVal(val);
    setListPage(val.value);
    setPageDisplay(1);
  };

  let handleChangePage = (page) => {
    let data = {
      id: checkID(match),
      pageNumber: page,
      pageSize: listPage,
    };
    fetchBroadcastMessageDetail(data, false);
    setPageDisplay(page);
  };

  let convertBody = (text) => {
    return HelperChat.convertFormatTextWhatsapp(text);
  };

  let toCapitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  let removeUnderscoreAndCapitalize = (str) => {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = toCapitalize(frags[i]);
    }
    return frags.join(" ");
  };

  let checkOrgID = (match) => {
    let { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  let checkID = (match) => {
    let { id: param } = match.params;
    if (!param) return false;
    else return Number(param);
  };

  let back = () => {
    history.push({
      pathname: `/o/${orgID}/operational/broadcast-message`,
      state: {
        pageNumber: location.pageNumber ? location.pageNumber : 1,
        pageSize: location.pageSize ? location.pageSize : 20,
        tab: props.history.location.state.tab
      },
    });
  }

  let goToEdit = () => {
    history.push({
      pathname: `/o/${orgID}/operational/broadcast-message/edit/${props.match.params.id}`,
      state: {
        pageNumber: location.pageNumber ? location.pageNumber : 1,
        pageSize: location.pageSize ? location.pageSize : 20,
        tab: props.history.location.state.tab
      },
    });
  }

  let doMount = async () => {
    let paramID = checkOrgID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let fetchBroadcastMessageDetail = (data, loadAll = true) => {
    if (loadAll) setIsLoadingGetBroadcastData(true);
    setIsLoadingGetRecipients(true);
    BroadcastMessageServices.getBroadcastDetails(orgID, data, (response) => {
      let _retrieveDataError = { ...retrieveDataError };

      if (response.dataResult.error.message === "") {
        setBroadcastDetail(response.dataResult.data);
        setImageSrc(response.dataResult.data.broadcast.contentBody);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
      }
      setIsLoadingGetBroadcastData(false);
      setIsLoadingGetRecipients(false);
    });
  };

  // let clickMediaPreview = (media, fileURL) => {
  //   let _previewImageOrVideo = { ...props.previewImageOrVideo };

  //   _previewImageOrVideo.fileType = media;
  //   _previewImageOrVideo.fileURL = fileURL;
  //   props.setPreviewImageOrVideo(_previewImageOrVideo);
  // };

  let openURL = (url) => {
    var win = window.open(url, "_blank");
    if (win) {
      //Browser has allowed it to be opened
      win.focus();
    } else {
      //Browser has blocked it
      alert("Please allow popups for this website");
    }
  };

  let renderMessageContent = (body, type, caption = "", filename = "", idx) => {
    if (type === MESSAGE_TYPE.file) {
      return (
        <div className="message-content file-url" onClick={() => openURL(body)}>
          <div className="flexing">
            <img src={IconFile} alt="" />
            <p>{filename}</p>
          </div>
          <p
            className="content-caption"
            dangerouslySetInnerHTML={{
              __html: convertBody(HelperChat.lineBreakWithoutTrim(caption)),
            }}
          ></p>
        </div>
      );
    } else if (type === MESSAGE_TYPE.image) {
      // setImageSrc(body);
      return (
        <div className="message-content">
          <ImageUpload
            imageSrc={body}
            // onClick={() => clickMediaPreview("image", body)}
            onClick={() => {
              toggleModalImage();
            }}
            withoutRemove
          />
          {caption ? (
            <>
              <b>Caption</b>
              <p
                className="content-caption"
                dangerouslySetInnerHTML={{
                  __html: convertBody(HelperChat.lineBreakWithoutTrim(caption)),
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      );
    } else
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: convertBody(HelperChat.lineBreakWithoutTrim(body)),
          }}
        ></p>
      );
  };

  let toggleModalImage = () => {
    setIsShowModalImage(!isShowModalImage);
  };

  let generateModalImage = () => {
    let zoomImage = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = "200%";
      var offsetX = e.nativeEvent.offsetX;
      var offsetY = e.nativeEvent.offsetY;

      var x = (offsetX / zoomer.offsetWidth) * 100;
      var y = (offsetY / zoomer.offsetHeight) * 100;
      zoomer.style.backgroundPosition = x + "% " + y + "%";
    };

    let zoomImageOut = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = "0%";
    };

    return (
      <div>
        <Modal isOpen={isShowModalImage} className={"modal-image"} toggle={toggleModalImage}>
          <ModalBody onClick={() => toggleModalImage()}>
            <IoIosClose
              className="close-modal-image"
              onClick={() => toggleModalImage()}
            />
            <div className="image-wrapper">
              <figure
                className="zoom"
                style={{ backgroundImage: `url(${imageSrc})` }}
                onMouseMove={(e) => zoomImage(e, imageSrc)}
                onMouseLeave={(e) => zoomImageOut(e)}
              >
                <img src={imageSrc} alt="" className="image-preview-val" />
              </figure>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  let downloadReport = (data) => {
    // let id = orgID.split('-');
    // let timestamp = + new Date();
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${broadcastDetail.broadcast.name.replace(/\[|\]/g, "")}-data.csv`);
    document.body.appendChild(link);
    link.click();
  }

  let generateReport = () => {
    setIsLoadingGenerateReport(true);

    BroadcastMessageServices.exportBroadcastDetail(
      orgID,
      { id: parseInt(props.match.params.id) },
      (response) => {
        setIsLoadingGenerateReport(false);

        if (!response) {
          // setShowModalFailed(true);
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  let deleteBroadcast = () => {
    setIsLoadingDelete(true);

    BroadcastMessageServices.deleteBroadcastScheduled(orgID, { id: parseInt(props.match.params.id), createdTime: broadcastDetail.broadcast.createdTime }, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          setTimeout(() => {
            back();
          }, 2000)
        } else {
          setIsLoadingDelete(false);
        }

        doToast(response.dataResult.data.message);
      } else {
        doToast(response.dataResult.error.message, "fail");
        setIsLoadingDelete(false);
      }
    }
    )
  }

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    let paramID = checkID(match);
    if (orgID && paramID) {
      let data = {
        id: paramID,
        pageNumber: pageDisplay,
        pageSize: listPage
      };
      fetchBroadcastMessageDetail(data);
    } else if (orgID) back();
  }, [orgID]);

  return (
    <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
      {/* <ModalPreviewImageOrVideo /> */}
      {imageSrc && generateModalImage()}

      <PopupConfirmation
        isOpen={isShowModalDelete}
        toggle={toggleModalDelete}
        title="Delete Scheduled Broadcast?"
        description="Scheduled broadcast message will be canceled and deleted. Are you sure you want to delete this broadcast?"
        className="modal-confirmation-delete-broadcast-scheduled"
      >
        {!isLoadingDelete ?
          <ButtonWithLoadingOrIcon
            text="Delete"
            className="main-button-48 red-button"
            onClickAction={() => deleteBroadcast()}
          />
          :
          <ButtonWithLoadingOrIcon
            text="Delete"
            className="main-button-48 red-button"
            isLoading
            isDisabled
            loadingColor="gray"
            position="left"
          />
        }
      </PopupConfirmation>

      <BackButton text="Back to Broadcast Message" onClick={back} />
      {/* BROADCAST */}
      <div className="view-broadcast-message-detail sectionWrap">
        <div className="view-broadcast-message-detail-top">
          <b>Broadcast Details</b>

          {(
            !isLoadingGetBroadcastData &&
            broadcastDetail.broadcast.scheduledInputDate !== "" &&
            broadcastDetail.broadcast.status === TAPLIVE_STATUS.scheduled.value
          ) &&
            <>
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40 button-edit"
                text="Edit Schedule"
                icon={{
                  type: "svg",
                  src: FiEdit
                }}
                position="left"
                onClickAction={goToEdit}
              />

              <ButtonWithLoadingOrIcon
                className="red-button main-button-40 button-delete"
                text="Delete"
                icon={{
                  type: "svg",
                  src: FiTrash2
                }}
                position="left"
                onClickAction={toggleModalDelete}
              />
            </>
          }
        </div>
        {isLoadingGetBroadcastData || retrieveDataError.code ? (
          <div className="broadcast-message-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetBroadcastData}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : broadcastDetail ? (
          <React.Fragment>
            <div className="view-broadcast-message-detail-middle">
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-name">
                <img src={BroadcastBlack} alt="" className="label-icon" />
                <b>Broadcast Name</b>
                <p>{broadcastDetail.broadcast.name}</p>
              </div>
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-channel">
                <img src={IcGroup} alt="" className="label-icon" />
                <b>Channel</b>
                <div className="flexing">
                  <img
                    src={TAPLIVE_MEDIUM_LOGO[broadcastDetail.broadcast.medium]}
                    alt=""
                    className="channel-icon"
                  />
                  <p>{broadcastDetail.channel.accountName}</p>
                </div>
              </div>
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-sender">
                <FiUser />
                <b>Sender</b>
                <p>{broadcastDetail.broadcast.senderAccountName}{broadcastDetail.broadcast.senderAccountAlias !== "" ? ` (${broadcastDetail.broadcast.senderAccountAlias})` : ""}</p>
              </div>
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-sender">
                <FiUser />
                <b>Recipient Type</b>
                <p>{RECIPIENT_TYPE[broadcastDetail.broadcast.recipientType].text}</p>
              </div>
              {broadcastDetail.broadcast.recipientType === RECIPIENT_TYPE.user_segment.val &&
                <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-sender">
                  <FiUsers />
                  <b>Segment Name</b>
                  <p>{broadcastDetail.broadcast.userSegmentName}</p>
                </div>
              }
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-created-time">
                <FiClock />
                <b>Started Time</b>
                {broadcastDetail.broadcast.startTime === 0 ?
                  <p>-</p>
                  :
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.startTime),
                      "dd MMM yyyy HH:mm"
                    )}
                  </p>
                }
              </div>
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-created-time">
                <FiClock />
                <b>Finished Time</b>
                {broadcastDetail.broadcast.endTime === 0 ?
                  <p>-</p>
                  :
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.endTime),
                      "dd MMM yyyy HH:mm"
                    )}
                  </p>
                }
              </div>


              {broadcastDetail.broadcast.scheduledInputDate !== "" &&
                <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-created-time">
                  <FiClock />
                  <b>Scheduled Time</b>
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.scheduledInputDate), "dd MMM yyyy")} {broadcastDetail.broadcast.scheduledInputTime}
                  </p>
                </div>
              }

              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-created-time">
                <FiMessageSquare />
                <b>Create Message as Case</b>
                <p className={broadcastDetail.broadcast.withCase ? "yes-color" : "no-color"}>
                  <b className={broadcastDetail.broadcast.withCase ? "green-text" : "red-text"}>{broadcastDetail.broadcast.withCase ? "Yes" : "No"} </b>
                </p>
              </div>

              {broadcastDetail.broadcast.scheduledInputDate === "" &&
                <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-status">
                  <FiActivity />
                  <b>Status</b>
                  {TAPLIVE_STATUS[broadcastDetail.broadcast.status] ? (
                    <ColoredInfoBox
                      text={toCapitalize(
                        TAPLIVE_STATUS[broadcastDetail.broadcast.status].value
                      )}
                      color={
                        TAPLIVE_STATUS[broadcastDetail.broadcast.status].color
                      }
                    />
                  ) : (
                    broadcastDetail.broadcast.status
                  )}
                </div>
              }
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      <div className="view-broadcast-message-detail sectionWrap">
        <div className="view-broadcast-message-detail-top">
          <b>Broadcast Messages</b>
        </div>
        {isLoadingGetBroadcastData || retrieveDataError.code ? (
          <div className="broadcast-message-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetBroadcastData}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : broadcastDetail ? (
          <React.Fragment>
            {broadcastDetail.messages.map((val, idx) => {
              return (
                <div className="view-broadcast-message-detail-middle message-number-wrapper" key={`view-broadcast-message-detail-${idx}`}>
                  <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-content-body">
                    <img src={IconMessage} alt="" />
                    <b>Message {idx > 0 ? `#${idx + 1}` : ""}</b>
                  </div>
                  <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-message-type">
                    <b>Message Type</b>
                    <p>{toCapitalize(val.messageType)}</p>
                  </div>
                  <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-content-body">
                    <b>Content</b>
                    {renderMessageContent(
                      val.contentBody,
                      val.messageType,
                      val.contentCaption,
                      val.contentFileName,
                      idx
                    )}
                  </div>
                </div>
              )
            })}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      {/* RECIPIENTS */}
      <div className="view-broadcast-message-detail sectionWrap">
        <div className="view-broadcast-message-detail-top">
          <b>Recipients</b>
          {broadcastDetail ? (
            <span>{" (" + broadcastDetail.broadcast.totalUsers + ")"}</span>
          ) : (
            ""
          )}

          {!isLoadingGenerateReport ?
            <ButtonWithLoadingOrIcon
              position="left"
              icon={{
                type: "svg",
                src: FiDownload
              }}
              text="Export"
              className="main-button-48 orange-button button-export"
              onClickAction={generateReport}
            />
            :
            <ButtonWithLoadingOrIcon
              position="left"
              isLoading
              loadingColor="gray"
              isDisabled
              text="Export"
              className="main-button-48 orange-button button-export"
            // onClickAction={}
            />
          }
        </div>
        {isLoadingGetRecipients || retrieveDataError.code ? (
          <div className="broadcast-message-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetRecipients}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : broadcastDetail ? (
          <React.Fragment>
            <div className="view-broadcast-message-detail-middle">
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-total-status flexing">
                <div>
                  <div className="flexing white-space-nowrap">
                    <img src={IconMessagePending} alt="" />
                    <b>Pending</b>
                  </div>
                  <div className="total">{broadcastDetail.totalPending}</div>
                </div>
                <div>
                  <div className="flexing white-space-nowrap">
                    <img src={IconMessageSent} alt="" />
                    <b>Sent</b>
                  </div>
                  <div className="total">{broadcastDetail.totalSent}</div>
                </div>
                <div>
                  <div className="flexing white-space-nowrap">
                    <img src={IconMessageDelivered} alt="" />
                    <b>Delivered</b>
                    {RELEASE_DATE.valueOf() > broadcastDetail.broadcast.createdTime &&
                      <>
                        <span
                          href="#"
                          id="tooltip-delivered-2"
                          onMouseEnter={toggleTooltipDelivered}
                          onMouseLeave={toggleTooltipDelivered}
                        >
                          <FiHelpCircle className="bc-tootlip-toggle" />
                        </span>
                        <Tooltip
                          flip
                          target="tooltip-delivered-2"
                          isOpen={showTooltipDelivered}
                          // toggle={function noRefCheck(){}}
                          placement="right"
                          toogle={toggleTooltipDelivered}
                        >
                          Exclude broadcasts created before 23 Nov 2021
                        </Tooltip>
                      </>
                    }
                  </div>
                  <div className="total">{broadcastDetail.totalDelivered}</div>
                </div>
                <div>
                  <div className="flexing white-space-nowrap">
                    <img src={IconMessageRead} alt="" />
                    <b>Read</b>
                    {RELEASE_DATE.valueOf() > broadcastDetail.broadcast.createdTime &&
                      <>
                        <span
                          href="#"
                          id="tooltip-read-2"
                          onMouseEnter={toggleTooltipRead}
                          onMouseLeave={toggleTooltipRead}
                        >
                          <FiHelpCircle className="bc-tootlip-toggle" />
                        </span>
                        <Tooltip
                          flip
                          target="tooltip-read-2"
                          isOpen={showTooltipRead}
                          // toggle={function noRefCheck(){}}
                          placement="right"
                          toogle={toggleTooltipRead}
                        >
                          Exclude broadcasts created before 23 Nov 2021
                        </Tooltip>
                      </>
                    }
                  </div>
                  <div className="total">{broadcastDetail.totalRead}</div>
                </div>
                <div>
                  <div className="flexing white-space-nowrap">
                    <img src={IconMessageResponded} alt="" />
                    <b>Responded</b>
                  </div>
                  <div className="total">{broadcastDetail.totalReplied}</div>
                </div>
                <div>
                  <div className="flexing white-space-nowrap">
                    <img src={IconMessageNotSent} alt="" />
                    <b>Not Sent</b>
                  </div>
                  <div className="total">{broadcastDetail.totalNotSent}</div>
                </div>
              </div>
              <div className="broadcast-recipients-table-wrapper">
                <table className="custom-table-content table-broadcast-recipients no-hover">
                  <thead>
                    <tr>
                      <td className="col_name">Name</td>
                      <td className="col_alias">Alias</td>
                      <td className="col_phone">Phone Number</td>
                      <td className="col_status">Last Status</td>
                      <td className="col_responded">Responded</td>
                      <td className="col_last_update">Last Updated</td>
                      <td className="col_reason">Reason</td>
                      <td>
                        <CustomSelectOption
                          optionListProps={listPageOption}
                          valueDropdownProps={listPageVal}
                          onClickDropDownListOptionProps={onSelectListPage}
                        />
                      </td>
                    </tr>
                  </thead>

                  {broadcastDetail.recipients.length < 1 ? (
                    <tbody>
                      <tr>
                        <td colSpan="5" className="no-data-column">
                          <b className="no-result-found">
                            No Recipients found.
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {broadcastDetail.recipients.map((value, index) => {
                        return (
                          <tr key={`broadcast-message-${index}`}>
                            <td className="col_name">{value.fullName}</td>
                            <td className="col_alias">{value.alias}</td>
                            <td className="col_phone">{"+" + HelperInput.phoneBeautify(value.phone)}</td>
                            <td className="col_status">
                              {TAPLIVE_STATUS[value.status] ? (
                                <ColoredInfoBox
                                  text={removeUnderscoreAndCapitalize(
                                    TAPLIVE_STATUS[value.status].value
                                  )}
                                  color={TAPLIVE_STATUS[value.status].color}
                                />
                              ) : (
                                value.status
                              )}
                            </td>
                            <td className="col_responded">
                              {value.isReplied ?
                                <b className="responded-text yes-text">Yes</b>
                                :
                                <b className="responded-text no-text">No</b>
                              }
                            </td>
                            <td className="col_last_updated">
                              {value.updatedTime === 0 ? "-" : HelperDate.formatToString(new Date(value.updatedTime), "dd/MM/yyyy HH:mm")}
                            </td>
                            <td className="col_reason" colSpan="2">
                              {value.statusReason}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>

              <div className="pagination-wrapper">
                {broadcastDetail.broadcast.totalUsers > 0 && (
                  <Pagination
                    total={broadcastDetail.broadcast.totalUsers}
                    page={pageDisplay}
                    listPerPage={listPage}
                    step={1}
                    onChangePage={handleChangePage}
                  ></Pagination>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    </SectionWrap>
  );
};

let mapStateToProps = (state) => ({
  previewImageOrVideo: state.previewImageOrVideo,
});

let mapDispatchToProps = {
  setPreviewImageOrVideo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadcastMessageDetail);
