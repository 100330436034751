import React, { useState, useEffect } from "react";
import "./UTMTab1.scss";
import FilterReportForm from '../../FilterReportForm/FilterReportForm';
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperQueryString from "../../../../helper/HelperQueryString";
import { checkID } from '../../../../helper/HelperGeneral';  
import mixpanel from "mixpanel-browser";
import UTMTab1Show from "./UTMTab1Show/UTMTab1Show";

let UTMTab1 = (props) => {
    // let [orgID, setOrgID] = useState(false);
    let [isSubmitedFilter, setIsSubmitedFilter] = useState(false);
    let [filterData, setFilterData] = useState(null);
    
    useEffect(() => {
        if (filterData) {
            setIsSubmitedFilter(true);
        }
    }, [filterData])

    useEffect(() => {
        doMount();
        let myTopicData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Referrer & UTM - From URL",
                {
                    userFullName: myTopicData.account.fullName,
                    userID: myTopicData.account.id,
                    organizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, []);

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            // setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let submitFilterReport = (data) => {
        setFilterData(data);
    }

    return (
        !isSubmitedFilter ?
            <div className="utm-wrapper sectionWrap">
                <React.Fragment>
                    <p className="utm-title">
                        <b>Referrer URL Report</b>
                    </p>

                    <FilterReportForm
                        {...props}
                        submitFilterReport={submitFilterReport}
                        withoutCancel
                        withoutGetTopicList
                        customDate={props.location.search === "" ? 
                            false 
                            : 
                            {
                                from: HelperQueryString.parseQuerystring()["start_date"],
                                to: HelperQueryString.parseQuerystring()["end_date"]
                            }
                        }
                    />
                </React.Fragment>
            </div>

            :

            <UTMTab1Show
                {...props}
                filterData={filterData}
            />
    )
}

export default UTMTab1;