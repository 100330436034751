import React, { Component } from 'react';
import './CustomToastr.css';
import iconFailed from "../../../assets/img/icon-failed.png";
import iconCheck from "../../../assets/img/icon-check.png";

class CustomToastr extends Component {
  render() {
    return (
          <div className={`custom-toastr ${this.props.toastrClassName}`} style={this.props.style && this.props.style}>
              <div className="icon-toast-wrapper">
                {this.props.toastrClassName === "toast-process active-toast" &&
                  <div className="lds-ring"><div></div><div></div><div></div><div></div></div> 
                }

                {this.props.toastrClassName === "toast-fail active-toast" && <img src={iconFailed} alt="" />}
                
                {this.props.toastrClassName === "toast-success active-toast" && <img src={iconCheck} alt="" />}
              </div>

              <div className="text-toast-wrapper">
                {this.props.toastrText}
              </div>  
          </div>
    );
  }
}

export default CustomToastr;
