const INITIAL_STATE = {};

let reducerAuthSalestalk = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_AUTH_SALESTALK':
        return action.authDataSalestalk;
      case 'CLEAR_ACTIVE_AUTH_SALESTALK':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerAuthSalestalk;