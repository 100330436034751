import React, { useEffect, useState } from "react";
import "./IntegrationWhatsappSecureAuthenticate.scss";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import { checkID } from "../../../../helper/HelperGeneral";
import "react-toastify/dist/ReactToastify.css";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { FiInfo } from "react-icons/fi";
import IntegrationWhatsappServices from "../../../../services/integration/IntegrationWhatsappServices";

const IntegrationWhatsappSecureAuthenticate = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [accountDetailVal, setAccountDetailVal] = useState(null);
    let [isWaitingAccountDetail, setIsWaitingAccountDetail] = useState(true);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    })

    const back = () => history.push(`/o/${orgID}/integration/whatsapp-sme/detail/${match.params.accountID}`);

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
            getWhatsappAccountDetail(paramID, match.params.accountID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getWhatsappAccountDetail = (orgID, accountID) => {
        setIsWaitingAccountDetail(true);

        IntegrationWhatsappServices.getDetail(orgID, accountID, (response) => {
            let _retriveDataError = {...retriveDataError};

            if(response.dataResult.status === 200) {
                setAccountDetailVal(response.dataResult.data);
            }else {
                _retriveDataError.message = response.dataResult.error.message;
                _retriveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setAccountDetailVal(null);
                // doToast(response.dataResult.error.message, "fail");
            }

            setIsWaitingAccountDetail(false);
        })
    }
    
    useEffect(() => {
        doMount();
    }, []);
    
    return (
        <div className="integration-outer-wrapper"> 
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

                <div className="sectionWrap integration-main-container integration-whatsapp-secure-authenticate text-left">
                    {isWaitingAccountDetail || retriveDataError.code ?
                        <div className="main-loading-wrapper">
                            {/* <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div> */}
                            <RetrieveDataLoading 
                                isLoading={isWaitingAccountDetail}
                                errorMessage={retriveDataError.message} 
                            />
                        </div>
                        :
                        <React.Fragment>
                            <BackButton text={`Back to ${accountDetailVal.channel.accountName}`} onClick={back} />
                    
                            <div className="integration-whatsapp-secure-authenticate-box">
                                <div className="integration-whatsapp-secure-authenticate-box-top">
                                    <b>Secure Authentication</b>
                                </div>

                                <div className="alert-component alert-blue">
                                    <FiInfo />
                                    <b>
                                        Please follow these instructions
                                    </b>

                                    <div className="payment-step-list">
                                        <div className="payment-step-counter"><b>1</b></div>
                                        <p className="font-root-medium">
                                            Please make sure you are using Google Chrome Browser
                                        </p>
                                    </div>
                                    
                                    <div className="payment-step-list">
                                        <div className="payment-step-counter"><b>2</b></div>
                                        <p className="font-root-medium">
                                           Firstly click <b>"Secure Authorization"</b>, secondly install the extension by clicking the button <b>Install Extension</b>.
                                        </p>
                                    </div>

                                    <div className="payment-step-list">
                                        <div className="payment-step-counter"><b>3</b></div>
                                        <p className="font-root-medium">
                                            After installing the extension, please refresh this page and click <b>"Secure Authentication"</b> again.
                                        </p>
                                    </div>
                                </div>

                                {/* <button className="orange-button button-refresh" onClick={() => {window.location.reload()}}>
                                    <FiRefreshCcw />
                                    Refresh Page
                                </button> */}

                                <div className="iframe-space">
                                    <iframe src={accountDetailVal.chatapicom.widgetURL} title="WhatApp Scan Qr"></iframe>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationWhatsappSecureAuthenticate;