export const CASE_TYPES = {
    allTypes: {
        label: "All Types",
        value: ""
    },
    junkOnly: {
        label: "Junk Only",
        value: "junk"
    },
    nonJunkOnly: {
        label: "Non-Junk Only",
        value: "not_junk"
    }
}

export const CHAT_TYPE = {
    TAPChatMessageTypeText : 1001,
    TAPChatMessageTypeImage : 1002,
    TAPChatMessageTypeVideo : 1003,
    TAPChatMessageTypeFile : 1004,
    TAPChatMessageTypeLocation : 1005,
    TAPChatMessageTypeContact : 1006,
    TAPChatMessageTypeSticker : 1007,
    TAPChatMessageTypeLink : 1010,
    TAPChatMessageTypeSystemMessage : 9001,
    TAPChatMessageTypeUnreadMessageIdentifier : 9002,
    TAPChatMessageTypeCaseClosed : 3001,
    TAPChatMessageTypeLeaveReview: 3003,
    TAPChatMessageTypeLeaveReviewDisabled: 3004,
    MessageTypeBroadcastText: 3011,
    MessageTypeBroadcastImage: 3012,
    MessageTypeBroadcastVideo: 3013,
    MessageTypeBroadcastFile: 3014,
    MessageTypeWhatsAppBATemplateText: 3021,
    MessageTypeWhatsAppBATemplateImage: 3022,
    MessageTypeWhatsAppBATemplateVideo: 3023,
    MessageTypeWhatsAppBATemplateFile: 3024,
    MessageTypeTokopedia: 3031
}

export const ASSIGNEE_TYPE_CHATBOT = "chatbot";
export const ASSIGNEE_TYPE_AGENT = "agent";