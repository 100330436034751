import React, { useState, useEffect, useRef } from 'react';
import './RoomListConnectionBar.scss';
import { FaExclamationCircle } from 'react-icons/fa';

const CONNECTING_STATUS = {
  disconnected: 1,
  loading: 2,
  connected: 3
};

var RoomListConnectionBar = (props) => {
  let connectionStatus = props.connectingStatusProps;
  let [refreshIn, setRefreshIn] = useState(30);
  let timerRef = useRef(null);

  let timerRefresh = () => {
    timerRef.current =  setInterval(() => {
        setRefreshIn(refreshIn => refreshIn - 1);
    }, 1000)
  }

  let onClickRefresh = () => {
    window.location.reload();
  }

  useEffect(() => {
    if(connectionStatus === 2) {
      setRefreshIn(30);
    }
  }, [connectionStatus])

  useEffect(() => {
    if(refreshIn === 30) {
      clearTimeout(timerRef.current);
      timerRefresh();
    }

    if(refreshIn === 0) {
      clearTimeout(timerRef.current);
    }
  }, [refreshIn])

  let connectStatusClassName = () => {
    let status = "";

    switch(connectionStatus) {
      case CONNECTING_STATUS.disconnected:
          status = "error";
          break;
      case CONNECTING_STATUS.loading:
          status = "waiting";
          break;
      default:
          break;
      // case CONNECTING_STATUS.connected:
      //     status = "connected";
      //     break;
    }

    return status;
  }

  var renderStatus = (status) => {
    switch(status) {
        case CONNECTING_STATUS.loading:
          return (
            <React.Fragment>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                <b>RECONNECTING</b>

                <div className='refresh-in'>
                  {refreshIn < 1 ?
                    <button className='orange-button' onClick={onClickRefresh}>
                      Refresh Now
                    </button>
                    :
                    `Refresh Button in ${refreshIn}s`
                  }
                </div>
            </React.Fragment>
          )
        // case CONNECTING_STATUS.disconnected:
        //   return (
        //     <React.Fragment>
        //         <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        //         <b>WAITING FOR NETWORK</b>
        //     </React.Fragment>
        //   )
        case CONNECTING_STATUS.disconnected:
            return (
                <React.Fragment>
                    <FaExclamationCircle />
                    <b>DISCONNECTED</b>
                </React.Fragment>
            )
        // case CONNECTING_STATUS.connected:
        //     return (
        //         <React.Fragment>
        //             <FaCheck />
        //             <b>CONNECTED</b>
        //         </React.Fragment>
        //     )
        default:
            break;
    }
}

  return (
    <div 
      className={`
        room-list-connection-bar-container 
        status-${connectStatusClassName()} ${props.connectingStatusProps !== CONNECTING_STATUS.connected ? 'active-connection-bar': ''}
        ${props.connectingStatusProps === CONNECTING_STATUS.loading ? "text-left" : ""}
      `} 
         style={{height: `${props.connectingStatusProps !== CONNECTING_STATUS.connected ? '26px' : '0'}`}}
    >
      {renderStatus(connectionStatus)}
    </div>
  );
}

export default RoomListConnectionBar;
