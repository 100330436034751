import React from "react";
import "./TermsWhatsappModal.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiInfo } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IconPaperPlane from "../../../assets/img/icon-airplane.svg";

const LIST_TERMS = [
    "I acknowledge there is a possiblity of this WhatsApp number being banned by WhatsApp for sending marketing, promotion, spam messages to one or more recipients at once.",
    "I understand that exceeding the recommended usage can also result in ban by WhatsApp.",
    "I take full responsibilty for any repercussions resulted."
]

let TermsWhatsappModal = (props) => {
    return (
        <Modal isOpen={props.isShowModalTermsProps} toggle={props.toggleModalTermsProps} className="terms-and-condition-modal">
            <ModalBody>
                {!props.isWaba ?
                    <>
                        <p>
                            <FiInfo />
                            <b>Terms and Conditions</b>
                        </p>

                        <ol>
                            {LIST_TERMS.map((value, index) => {
                                return (
                                    <li key={`terms-${index}`}>
                                        {value}
                                    </li>
                                )
                            })}
                        </ol>
                        
                        <ButtonWithLoadingOrIcon 
                            className={`${!props.withClose ? 'no-fill-button' : 'orange-button width-100'} main-button-40`}
                            text={`${!props.withClose ? 'Decline' : 'Dismiss'}`}
                            onClickAction={props.toggleModalTermsProps}
                        />
                        
                        {!props.withClose &&
                            <>
                                {!props.isLoadingAcceptProps ?
                                    <ButtonWithLoadingOrIcon 
                                        className="orange-button main-button-40"
                                        text="Accept"
                                        onClickAction={props.onClickAcceptProps}
                                    />
                                    :
                                    <ButtonWithLoadingOrIcon 
                                        isLoading
                                        loadingColor="gray"
                                        isDisabled
                                        className="orange-button main-button-40"
                                        text="Accept"
                                        position="left"
                                    />
                                }
                            </>
                        }
                    </>
                    :
                    <>
                        <p className="title-waba">
                            <b>Confirm to send templated message</b>
                        </p>

                        <span>
                            {/* By sending this, your organization may be charged according to WhatsApp Business API pricing scheme. */}
                            This message will be sent to your selected recipients
                        </span>

                        <ButtonWithLoadingOrIcon 
                            className="no-fill-button main-button-48"
                            text="Cancel"
                            onClickAction={props.toggleModalTermsProps}
                        />
                        
                        {!props.isLoadingAcceptProps ?
                            <ButtonWithLoadingOrIcon 
                                className="orange-button main-button-48"
                                text="Send Message"
                                icon={{
                                    type: "img",
                                    src: IconPaperPlane
                                }}
                                position="left"
                                onClickAction={props.onClickAcceptProps}
                            />
                            :
                            <ButtonWithLoadingOrIcon 
                                isLoading
                                loadingColor="gray"
                                isDisabled
                                className="orange-button main-button-48"
                                text="Send Message"
                                position="left"
                            />
                        }
                    </>
                }
            </ModalBody>
        </Modal>
    )
}

export default TermsWhatsappModal;