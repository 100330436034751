import React from 'react';
import './RoomListNoChat.css';

var RoomListNoChat = (props) => {
  return (
    <div className="room-list-no-chat-container">
        {/* <b>
            {props.text}
        </b> */}

        <p>
         {props.text}
        </p>
    </div>
  );
}

export default RoomListNoChat;
