const INITIAL_STATE = {
  isShow: false,
  dataOrigin: {},
  data: {}
};

let reduxReducerEditContactInfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_EDIT_CONTACT_INFO':
        return action.editContactInfo;
      case 'CLEAR_EDIT_CONTACT_INFO':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerEditContactInfo;