import React, { useEffect, useState, useRef } from "react";
import "./UserRatingSetup.scss";
import { FiX, FiExternalLink, FiInfo } from "react-icons/fi";
import UserRatingServices from "../../../services/newServices/UserRatingServices";
import { doToast, scrollToClass } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import iconBrushBlack from '../../../assets/img/icon-brush-black.svg';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import WhatsappPreview from "../../reuseableComponent/whatsappPreview/WhatsappPreview";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { SketchPicker } from 'react-color';
import mixpanel from "mixpanel-browser"; 

const UserRatingSetup = (props) => {
    let [valueUserRating, setValueUserRating] = useState({
        isEnabled: false,
        message: "",
        themeColor: ""
    })
    let [valueUserRatingOrigin, setValueUserRatingOrigin] = useState({
        isEnabled: false,
        message: "",
        themeColor: ""
    })
    let [isWaitingFetchUserRating, setIsWaitingFetchUserRating] = useState(false);
    let [isWaitingSetUserRating, setIsWaitingSetUserRating] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [isShowColorPicker, setIsShowColorPicker] = useState(false);
    let colorPickerRef = useRef(null);
    let [errorMessageData, setErrorMessageData] = useState({
        color: "",
        message: ""
    })

    useEffect(() => {
        const onBodyClick = (event) => {
          if (isShowColorPicker) {
            if (!colorPickerRef.current.contains(event.target) && !colorPickerRef.current.previousSibling.contains(event.target)) {
                setIsShowColorPicker(false);
            }
          }
        };
    
        document.body.addEventListener('click', onBodyClick);
    
        return () => {
          document.body.removeEventListener('click', onBodyClick);
        };
    }, [isShowColorPicker]);

    useEffect(() => {
        getUserRating();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
            mixpanel.track(
                "[Page] User Rating Setup",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    let toggleUserRatingSetup = () => {
        let _value = {...valueUserRating};
        _value.isEnabled = !_value.isEnabled;
        setValueUserRating(_value);
    }

    let getUserRating = () => {
        let _retriveDataError = {...retriveDataError};
        setIsWaitingFetchUserRating(true);

        UserRatingServices.getUserRating(props.match.params.orgID, (response) => {
            setIsWaitingFetchUserRating(false);
            let dataResult = response.dataResult;
            if(dataResult.error.message === "") {
                let _userRating = dataResult.data.userRating;
                _userRating.themeColor = _userRating.themeColor.replace("#", "");
                setValueUserRating(_userRating);
                setValueUserRatingOrigin(_userRating);
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
        })
    }

    let setUserRating = () => {
        let errorCount = 0;
        let errorClass  = "";
        let _errorMessageData = {...errorMessageData};
        
        if(valueUserRating.themeColor === "") {
            _errorMessageData.color = "This field is required";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "color-input" }
        }

        if(valueUserRating.message === "") {
            _errorMessageData.message = "This field is required";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "message-input" }
        }

        if(valueUserRating.message.length > 250) {
            _errorMessageData.message = "Characters exceed limit";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "color-input" }
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            if(retriveDataError.code !== "49901") {
                setIsWaitingSetUserRating(true);
    
                let data = {
                    isEnabled: valueUserRating.isEnabled,
                    themeColor: "#"+valueUserRating.themeColor,
                    message: valueUserRating.message
                }
    
                UserRatingServices.setUserRating(props.match.params.orgID, data, (response) => {
                    setIsWaitingSetUserRating(false);
                    let dataResult = response.dataResult;
    
                    if(dataResult.error.message === "") {
                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                        
                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                            mixpanel.track(
                                "[Action] Set User Rating Setup",
                                {
                                    status : valueUserRating.isEnabled ? "On" : "Off",
                                    userFullName : myAgentData.account.fullName,
                                    userID : myAgentData.account.id,
                                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                                }
                            );
                        }
    
                        if(dataResult.data.success) {
                            setValueUserRatingOrigin(valueUserRating);
                            doToast(dataResult.data.message);
                        }else {
                            doToast(dataResult.data.message, "fail");
                        }
                    }else {
                        doToast(dataResult.error.message, "fail");
                    }
                })
            }
        }
    }

    // let isDisabledSubmitButton = () => {
    //     let dis = false;

    //     if(valueUserRating.message === "" || valueUserRating.themeColor ==="" || (JSON.stringify(valueUserRatingOrigin) === JSON.stringify(valueUserRating))) {
    //         dis = true;
    //     }

    //     return dis;
    // }

    let changeValueUserRating = (e) => {
        let _errorMessageData = {...errorMessageData};
        let _value = {...valueUserRating};
        _value.message = e.target.value;
        _errorMessageData.message = "";
        setErrorMessageData(_errorMessageData);
        setValueUserRating(_value);
    }

    let toggleColorPicker = () => {
        setIsShowColorPicker(!isShowColorPicker);
    };

    let changeColorPicker = (color) => {
        let data = { ...valueUserRating };
        data.themeColor = color.hex.substring(1, 7);
        // _addData.rgb = hexToRgb(color.hex);
        // toggleColorPicker();
        setValueUserRating(data);
    };

    const handleChangeColor = (event) => {
        // const val = /^[0-9A-F]{6}$/i.test(event.target.value);
        let data = { ...valueUserRating };
        data.themeColor = event.target.value;
        // _addData.rgb = val ? hexToRgb(`#${event.target.value}`) : '';
        setValueUserRating(data);
    }

    // let hexToRgb = (hex) => {
    //     var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    //     return result ? {
    //       r: parseInt(result[1], 16),
    //       g: parseInt(result[2], 16),
    //       b: parseInt(result[3], 16)
    //     } : null;
    // };
    
    return (
        <SectionWrap
            {...props}
            orgID={props.match.params.orgID}
            withHeader
            withSideMenu
            dataOrigin={JSON.stringify(valueUserRatingOrigin)}
            dataAfterChange={JSON.stringify(valueUserRating)}
        >
            <div className="user-rating-setup sectionWrap">
                {/* tier blocking */}
                {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                        isLoading={isWaitingFetchUserRating}
                        errorMessage={retriveDataError.message}
                        errorCode={retriveDataError.code}
                    />
                }
                {/* tier blocking */}

                <React.Fragment>
                    <div className="user-rating-setup-header">
                        <b>User Rating</b>

                        {!retriveDataError.code &&
                            <>
                                {!isWaitingSetUserRating ?
                                    <ButtonWithLoadingOrIcon 
                                        // isDisabled={isDisabledSubmitButton()} 
                                        onClickAction={setUserRating}
                                        className="orange-button main-button-40"
                                        text="Save Changes"
                                    />
                                    :
                                    <ButtonWithLoadingOrIcon 
                                        isLoading
                                        loadingColor={"gray"}
                                        className="orange-button main-button-40"
                                        text="Save Changes"
                                        position="left"
                                        isDisabled
                                    />
                                }
                            </>
                        }
                    </div>

                    {(isWaitingFetchUserRating || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
                        <RetrieveDataLoading 
                            isLoading={isWaitingFetchUserRating}
                            errorUserRating={retriveDataError.message} 
                        />
                        :
                        <div className="user-rating-setup-form">
                            <div className="closing-welcome-switch-wrapper">
                                <label className="custom-switch-wrapper">
                                    <input disabled={isWaitingSetUserRating} type="checkbox" checked={valueUserRating.isEnabled} onChange={() => toggleUserRatingSetup()} />
                                    <span className="custom-switch round"></span>
                                </label>
                            </div>

                            <div className="user-rating-setup-form-content form-content-wrapper">
                                <b>Enable User Rating</b>
                                <p>
                                    Send a rating form to your customers once you have resolved their case.
                                </p>

                                <div className="color-select-wrapper">
                                    <b>Theme Color</b>
                                    <p>
                                        Used for background and action buttons.
                                    </p>

                                    <ButtonWithLoadingOrIcon 
                                        position="left"
                                        icon={{
                                            type: "svg",
                                            src: FiExternalLink
                                        }}
                                        onClickAction={() => {
                                            window.open(window.location.origin+`/case_rating?token=example&color=${valueUserRating.themeColor}`, "_blank")
                                        }}
                                        className="no-fill-button main-button-40 button-go-preview"
                                        text="Preview Form"
                                    />

                                    <div className={`color-input-wrapper ${isWaitingSetUserRating ? "grey-box" : ""} ${errorMessageData.color !== "" ? "border-red" : ""}`}>
                                        <div
                                            className="color-input-container"
                                            onClick={() => {
                                                if(!isWaitingSetUserRating) {
                                                    toggleColorPicker();
                                                }
                                            }}
                                        >
                                            <>
                                                {valueUserRating.themeColor === '' ?
                                                    <img src={iconBrushBlack} alt="" className="brush-icon" />
                                                    :
                                                    <div className="color-value" style={{ background: `#${valueUserRating.themeColor}` }} />
                                                }
                                                <label className="color-tag">#</label>
                                                <input disabled={isWaitingSetUserRating} className={`color-input`} type="text" onChange={handleChangeColor} value={valueUserRating.themeColor} placeholder="Select Color" maxLength="6" />
                                            </>
                                        </div>

                                        {isShowColorPicker && (
                                            <div className="color-picker-container" ref={colorPickerRef}>
                                            <FiX
                                                onClick={toggleColorPicker}
                                                className="close-color-picker"
                                            />
                                            <SketchPicker
                                                color={`#${valueUserRating.themeColor}`}
                                                onChange={changeColorPicker}
                                            />
                                            </div>
                                        )}
                                    </div>

                                    {errorMessageData.color !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.color}
                                            _className={"font-red"}
                                            icon={<FiInfo />}
                                        />
                                    }
                                </div>
                                
                                <div className="message-wrapper">
                                    <div className="message-wrapper-50">
                                        <label>
                                            <b>Message</b>
                                            <span className={`grey-font ${errorMessageData.message === "Characters exceed limit" ? "red-text" : ""}`}>{valueUserRating.message.length}/250</span>
                                        </label>
                                        <textarea rows="5" 
                                            value={valueUserRating.message} 
                                            placeholder="Thank you for messaging..."
                                            onChange={(e) => changeValueUserRating(e)}
                                            disabled={isWaitingSetUserRating}
                                            className={`message-input ${errorMessageData.message !== "" ? "border-red" : ""}`}
                                        />
                                        {errorMessageData.message !== "" &&
                                            <ErrorOrInfoComp
                                                text={errorMessageData.message}
                                                _className={"font-red"}
                                                icon={<FiInfo />}
                                            />
                                        }
                                    </div>

                                    <div className="message-wrapper-50">
                                        <WhatsappPreview
                                            header={"head"}
                                            body={valueUserRating.message}
                                            footer={`Rate Your Conversation:`}
                                            footerLink={`${window.location.origin}?case_rating?token=XXX`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </React.Fragment>
            </div>
        </SectionWrap>
    )
}

export default UserRatingSetup;