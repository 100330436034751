const INITIAL_STATE = false;

let reduxReducerAllowedModule = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ALLOWED_MODULE':
        return action.allowedModule;
      case 'CLEAR_ALLOWED_MODULE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerAllowedModule;