import React from "react";
import "./FormInteractiveMessagePreview.scss";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import checkMarkBlue from "../../../assets/img/icon-checkmark-blue-2.svg";
import whatsappPreviewPdfIcon from "../../../assets/img/whatsapp-preview-pdf-icon.svg";
import { FiX, FiEye, FiImage, FiVideo, FiList } from "react-icons/fi";
import HelperDate from "../../../helper/HelperDate";

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-2px",
    width: "5px",
    borderRadius: "8px",
  },
};

let FormInteractiveMessagePreviewQuickReply = (props) => {
  let { isShowModalFormInteractivePreview, toggle, type, data } = props;
  
  return (
    <div
      className={`
        modal-new-interactivePreview-message 
        ${isShowModalFormInteractivePreview ? 
          "active-modal-new-interactivePreview-message"
          : 
          ""
        } 
      `}
    >
      <div
        className={`
          modal-body 
          ${isShowModalFormInteractivePreview ? 
            "action-modal-body-new-interactivePreview-message"
            : 
            ""
          }
        `}
      >
        <div onClick={toggle} className="close-modal-interactivePreview">
          <FiX className=""/>
        </div>
        
        <div className="new-interactivePreview-message-wrapper">
          <Scrollbars
            autoHideTimeout={500}
            renderThumbVertical={(props) => (
              <div {...props} style={style.scrollStyle} />
            )}
          >
            <div className="new-interactivePreview-message-container">
              <div className="new-interactivePreview-message-title title-preivew-quick-reply">
                <FiEye className="eye-modal-preview-quick-reply" />

                <b>Preview Message</b>
                <p>
                  Below is a preview of what your recipient will recieve.
                </p>
              </div>

              <div className={"preview-message-body"}>
                <div className="whatsapp-preview-interactive">
                  <div className="whatsapp-info-wrapper">
                      <div className="whatsapp-info-content" />
                  </div>

                  {(data.headerType === "text" || data.headerType === "no_header" || typeof data.headerType === "undefined" || data.headerType === "") &&
                    <div className="whatsapp-chat-bubble">
                        <p 
                            className="whatsapp-chat-bubble-title" 
                            dangerouslySetInnerHTML={{
                                __html:(`<b>${data.headerText}</b>`)
                            }}
                        />

                        <p className="whatsapp-chat-bubble-description">
                            {data.bodyText}
                        </p>

                        
                        <p className="whatsapp-chat-bubble-description color-707070">
                            {data.footerText}
                        </p>
                        
                        <p className="whatsapp-chat-bubble-status">
                            {HelperDate.formatToString(new Date(), "HH:mm")}
                            <img src={checkMarkBlue} alt="" />
                        </p>
                    </div>
                  }

                  {data.headerType === "media" &&
                    (data.document !== false &&
                      <div className="whatsapp-chat-bubble">
                        <div className="media-wrapper">
                            {data.document.type.split("/")[0] === "video" &&
                              <video src={data.document.link ? data.document.link : URL.createObjectURL(data.document)} className="video-media-preview" />
                            }

                            {data.document.type.split("/")[0] === "image" &&
                              <img alt="" src={data.document.link ? data.document.link : URL.createObjectURL(data.document)} className="image-media-preview" />
                            }

                            <div className="black-layer" />
                            
                            {data.document.type.split("/")[0] === "video" ? <FiVideo /> : <FiImage />}
                          </div>

                          <p className="whatsapp-chat-bubble-description">
                              {data.bodyText}
                          </p>

                          
                          <p className="whatsapp-chat-bubble-description color-707070">
                              {data.footerText}
                          </p>
                          
                          <p className="whatsapp-chat-bubble-status">
                              {HelperDate.formatToString(new Date(), "HH:mm")}
                              <img src={checkMarkBlue} alt="" />
                          </p>
                      </div>
                    )
                  }
                  
                  {data.headerType === "document" &&
                    <div className="whatsapp-chat-bubble">
                        <div className="static-media">
                          <div className="static-media-document">
                              <img src={whatsappPreviewPdfIcon} alt="" />

                              {data.document.name ?  data.document.name : data.mediaFilename}
                          </div>
                        </div>

                        <p className="whatsapp-chat-bubble-description">
                            {data.bodyText}
                        </p>

                        
                        <p className="whatsapp-chat-bubble-description color-707070">
                            {data.footerText}
                        </p>
                        
                        <p className="whatsapp-chat-bubble-status">
                            {HelperDate.formatToString(new Date(), "HH:mm")}
                            <img src={checkMarkBlue} alt="" />
                        </p>
                    </div>
                  }

                  {type === "button" &&
                    (data.buttonText.map((v, i) => {
                      return (
                        <div className="button-whatsapp-interactive-preview" key={`button-whatsapp-interactive-preview-${i}`}>
                          <b>{v}</b>
                        </div>
                      )
                    }))
                  }

                  {type === "list" &&
                    <div className="button-whatsapp-interactive-preview with-icon">
                      <FiList />
                      <b>{data.buttonText}</b>
                    </div>
                  }
                </div>
              </div>
              
              {type === "list" &&
                <div className="interactive-section-list">
                  {data.section.map((v, i) => {
                    return (
                      <React.Fragment key={`rows-list-${i}`}>
                        <p className="table-title">
                          <b>Section #{i+ 1}{v.title === "" ? "" : `: ${v.title}`}</b>
                        </p>

                        <table key={`rows-table-${i}`}>
                          <thead>
                            <tr>
                              <td><b>Row</b></td>
                              <td><b>Title</b></td>
                              <td><b>Description</b></td>
                            </tr>
                          </thead>

                          <tbody>
                            {v.rows.map((_v, _i) => {
                              return (
                                  <tr key={`rows-tbody-${_i}`}>
                                    <td>{_i + 1}</td>
                                    <td>{_v.title}</td>
                                    <td>{_v.description}</td>
                                  </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </React.Fragment>
                    )
                  })}
                </div>
              }
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FormInteractiveMessagePreviewQuickReply);
  