import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
// import { FiInfo } from "react-icons/fi";
import NewButton from "../reuseableComponent/NewButton/NewButton";
// import ErrorOrInfoComp from "../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import FormInput from "../reuseableComponent/FormInput/FormInput";
// import Loading from "../reuseableComponent/loading/Loading";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { autoFocusField } from "../../helper/HelperGeneral";

const HasError = ({ errAPI }) => (
  <div className="input-wrapper">
    <span className="input-error">{errAPI}</span>
  </div>
);

const AddAction = ({ loading, errAPI, ...props }) => {
  const { disabled, doReset } = props;
  // const submitLoading = loading ? <Loading /> : `Add Topic`;

  return (
    <React.Fragment>
      {errAPI && <HasError />}
      <div className="button-flex button-flexx">
        {/* <NewButton
          type="submit"
          text={submitLoading}
          disabled={disabled}
          className="orange-button abc"
        /> */}

        <NewButton
          type="reset"
          className="no-fill-button"
          text="Cancel"
          onClick={doReset}
        />

        &nbsp; &nbsp;
        {loading ?
          <ButtonWithLoadingOrIcon
            isLoading
            isDisabled
            loadingColor="gray"
            className="orange-button main-button-40"
            text="Add Topic"
            position="left"
          />
          :
          <ButtonWithLoadingOrIcon
            isDisabled={disabled}
            className="orange-button main-button-40"
            text="Add Topic"
          />
        }
      </div>
    </React.Fragment>
  );
};

const TopicsAddView = props => {
  const { isOpen, toggle } = props;
  const { values, errors, handleChange, handleSubmit, resetForm } = props;
  const { name: vName, apiLoading, errAPI } = values;
  const isDisabled = apiLoading ? true : false;
  let [errorMessageData, setErrorMessageData] = useState({
    name: ""
  })

  const doReset = () => {
    resetForm();
    toggle();
  };

  useEffect(() => {
    if(isOpen) {
      setTimeout(() => {
        autoFocusField("add-topic-input-1a");
      }, 500)
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} className="Modal modal-add-topic" toggle={toggle}>
      <div className="popup-header">Add Topic</div>
      <form 
        className="popup-body" 
        onSubmit={(e) => {
          e.preventDefault();
          let errorCount = 0;
          let _errorMessageData = {...errorMessageData};

          if(vName.length > 50) {
            _errorMessageData.name = "Characters exceed limit";
            errorCount = errorCount + 1;
          }

          if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
          }else {
            handleSubmit();
          }
        }}
      >
        <label>
          <b>Topic Name</b>
          <span className={errorMessageData.name === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{vName.length}/50</span>
        </label>
        <FormInput
          type="text"
          name="name"
          value={vName}
          error={errors.name}
          onChange={(e) => {
            setErrorMessageData({
              name: ""
            })
            handleChange(e)
          }}
          placeholder="e.g. Technical"
          classNameInput={`add-topic-input-1a ${errorMessageData.name === "Characters exceed limit" ? "border-red" : ""}`}
          isDisabled={apiLoading}
        />
        <AddAction
          doReset={doReset}
          loading={apiLoading}
          disabled={isDisabled}
          errAPI={errAPI}
        />
      </form>
    </Modal>
  );
};

export default TopicsAddView;
