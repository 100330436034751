import React, { useState, useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom'

/*
  condition (boolean): when discard changes triggered
  openModal (callback to pass boolean): is modal show or close
  confirmedNavigation (boolean): confirm discard changes and go to other link
*/
const BlockingDiscardChanges = ({ condition, openModal, confirmedNavigation }) => {
  const [discardRoute, setDiscardRoute] = useState(null)
  const history = useHistory()

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && (nextLocation.pathname !== window.location.pathname)) {
      openModal(true)
      setDiscardRoute(nextLocation)

      return false
    }

    return true
  }

  useEffect(() => {
    if (confirmedNavigation && discardRoute) {
      history.push({
        pathname: discardRoute.pathname,
        state: discardRoute.state,
      });
    }
  }, [confirmedNavigation, discardRoute]);

  return (
    <>
      <Prompt
        when={condition}
        message={handleBlockedNavigation}
      />
    </>
  );
};

export default BlockingDiscardChanges;