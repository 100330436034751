import React, { useEffect, useState } from "react";
import "./AllowReplyOnAgentAway.scss";
import { doToast, checkID } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import InboxConfigServices from "../../../../services/newServices/InboxConfigServices";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import mixpanel from "mixpanel-browser";

const AllowReplyOnAgentAway = (props) => {
    let [val, setVal] = useState(false);
    let [valOrigin, setValOrigin] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [isWaitingSet, setIsWaitingSet] = useState(false);
    let [isShowModalSave, setIsShowModalSave] = useState(false);
    let [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [orgID, setOrgID] = React.useState(false);

    let back = () => {
        props.history.push(`/o/${props.match.params.orgID}/setup/inbox-settings`);
    }

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Inbox Setting",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if (orgID) {
            getMessage();
        }
    }, [orgID])

    useEffect(() => {
        checkSaveButtonEnabled();
    }, [val, valOrigin])

    useEffect(() => {
    }, [isSaveButtonEnabled])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        }
        else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/inbox-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getMessage = () => {
        let _retriveDataError = {...retriveDataError};
        setIsWaitingFetch(true);

        InboxConfigServices.getConfig(orgID, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setValOrigin(JSON.parse(JSON.stringify(dataResult.data)));
                setVal(JSON.parse(JSON.stringify(dataResult.data)));
            }
            else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitingFetch(false);
        })
    }

    let setAllowReplyOnAway = () => {
        if (retriveDataError.code !== "49901") {
            setIsWaitingSet(true);

            let data = {
                allowReplyOnAway: val.inboxConfigs.allowReplyOnAway
            }

            InboxConfigServices.setAllowReplyOnAway(orgID, data, (response) => {
                setIsWaitingSet(false);
                setIsShowModalSave(false);
                let dataResult = response.dataResult;

                if(dataResult.error.message === "") {
                    if (dataResult.data.success) {
                        let _valOrigin = {...valOrigin};
                        _valOrigin.inboxConfigs.allowReplyOnAway = val.inboxConfigs.allowReplyOnAway;
                        setValOrigin(_valOrigin);
    
                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                        
                        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                            mixpanel.track(
                                "[Action] Set Inbox Setting",
                                {
                                    status : val.inboxConfigs.allowReplyOnAway ? "On" : "Off",
                                    userFullName : myAgentData.account.fullName,
                                    userID : myAgentData.account.id,
                                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                                }
                            );
                        }
    
                        // doToast(dataResult.data.message);
                        doToast("Away Status Config updated");
                    }else {
                        doToast(dataResult.data.message, "fail");
                    }
                }else {
                    doToast(dataResult.error.message, "fail")
                }
            })
        }
    }

    let toggleConfig = () => {
        let _inboxConfig = {...val};
        _inboxConfig.inboxConfigs.allowReplyOnAway = !_inboxConfig.inboxConfigs.allowReplyOnAway;
        setVal(_inboxConfig);
    }

    let checkSaveButtonEnabled = () => {
        if (val && val.inboxConfigs && valOrigin && valOrigin.inboxConfigs) {
            setIsSaveButtonEnabled(val.inboxConfigs.allowReplyOnAway !== valOrigin.inboxConfigs.allowReplyOnAway);
        }
        else {
            setIsSaveButtonEnabled(false);
        }
    }

    let toggleModalSave = () => {
        setIsShowModalSave(!isShowModalSave);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={valOrigin}
            dataAfterChange={val}
        >
            <BackButton 
                onClick={back}
                text="Back To Inbox Settings"
            />

            <div className="inbox-settings sectionWrap">
                <React.Fragment>
                    <div className="allowreply-settings-header">
                        <b>Allow Reply on Agent Away</b>
                            <ButtonWithLoadingOrIcon 
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                onClickAction={toggleModalSave}
                                // isDisabled={!isSaveButtonEnabled}
                            />
                    </div>
                    
                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
                        <RetrieveDataLoading 
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message} 
                        />
                        :
                        <>
                            <div className="allowreply-settings-form">
                                <div className="allowreply-settings-form-content form-content-wrapper">
                                    <b>Allow Reply on Agent Away</b>
                                    <p>
                                        Turning this on will allow agents to reply their inbox when their status is set as “away”.
                                    </p>
                                    
                                    <div className="closing-welcome-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input type="checkbox" checked={val.inboxConfigs.allowReplyOnAway} onChange={() => toggleConfig()} />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </React.Fragment>
            </div>

            <PopupConfirmation
                isOpen={isShowModalSave}
                toggle={toggleModalSave}
                title="Save Changes?"
                description="You have changes in your Away status config, are you sure you want to continue?"
                className="modal-confirmation-save-changes"
            >
                <ButtonWithLoadingOrIcon 
                    text="Save Changes"
                    className="main-button-48 orange-button"
                    isLoading={isWaitingSet}
                    isDisabled={isWaitingSet}
                    loadingColor="gray"
                    position="left"
                    onClickAction={setAllowReplyOnAway}
                />
            </PopupConfirmation>
        </SectionWrap>
    )
}

export default AllowReplyOnAgentAway;
