import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const UserContactServices = {
  getContactList(idOrTime, pageNumber, listPerPage, query, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      search: query,
      pageNumber: pageNumber,
      pageSize: Number(listPerPage)
    };

    BaseService.doPostAccessToken(API.GET_CONTACT_LIST, data, headers, callback);
  },
  getContactDetail(idOrTime, userID, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      userID
    };

    BaseService.doPostAccessToken(API.GET_CONTACT_DETAIL, data, headers, callback);
  },
  createContact(idOrTime, dataUser, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      fullName: dataUser.fullName,
      alias: dataUser.alias,
      email: dataUser.email,
      phone: dataUser.phone,
      companyName: dataUser.companyName,
      jobTitle: dataUser.jobTitle,
      customFields: dataUser.customFields
    };

    BaseService.doPostAccessToken(API.CREATE_CONTACT, data, headers, callback);
  },
  editContact(idOrTime, dataUser, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      userID: dataUser.userID,
      fullName: dataUser.fullName,
      alias: dataUser.alias,
      email: dataUser.email,
      phone: dataUser.phone,
      companyName: dataUser.companyName,
      jobTitle: dataUser.jobTitle,
      customFields: dataUser.customFields
    };

    BaseService.doPostAccessToken(API.EDIT_CONTACT, data, headers, callback);
  },
  importContact(idOrTime, dataUser, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    console.log("userItem", dataUser);

    const data = {
      "users": dataUser.map(userItem => {
        return {
          fullName: userItem[0] ? userItem[0].trim() : "",
          alias: userItem[1] ? userItem[1].trim() : "",
          email: userItem[2] ? userItem[2].trim() : "",
          phone: userItem[3] ? userItem[3].trim() : "",
          companyName: userItem[4] ? userItem[4].trim() : "",
          jobTitle: userItem[5] ? userItem[5].trim() : ""
        }
      })
    }

    BaseService.doPostAccessToken(API.IMPORT_CONTACT, data, headers, callback);
  },
  getContactWhatsapp(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SEARCH_CONTACT, data, headers, callback);
  },
  exportContacts(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EXPORT_CONTACT, data, headers, callback, 'blob');
  },
  getCaseHistory(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CASE_HISTORY, data, headers, callback);
  },
};

const API = {
  GET_CONTACT_LIST: "/organization/user/get_list",
  GET_CONTACT_DETAIL: "/organization/user/get_details",
  CREATE_CONTACT: "/organization/user/create",
  EDIT_CONTACT: "/organization/user/edit",
  IMPORT_CONTACT: "/organization/user/import",
  SEARCH_CONTACT: "/organization/user/get_list_with_phone",
  EXPORT_CONTACT: "/organization/user/export_list",
  GET_CASE_HISTORY: "/organization/user/get_case_history"
}

export default UserContactServices;
