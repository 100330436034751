import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const QuickReplyServices = {
  getQuickReplyList(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_QUICK_REPLY_LIST, data, headers, callback);  
  },

  addQuickReply(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_QUICK_REPLY, data, headers, callback);  
  },

  editQuickReply(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_QUICK_REPLY, data, headers, callback);  

    // {
    //     "id": 1,
    //     "ownerType": "HelperQuickReply",
    //     "shortcut": "hello",
    //     "message": "Hello, thank you very much for contacting us. How can I help?"
    // }
  },

  deleteQuickReply(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_QUICK_REPLY, data, headers, callback);  

    // {
    //     "id": 1,
    //     "ownerType": "HelperQuickReply",
    //     "createdTime": 1597764528598
    // }
  },

  uploadMedia(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime,
      'Content-Type': 'multipart/form-data'
    };

    BaseService.doPostAccessTokenNoWebWorker(API.UPLOAD_MEDIA, data, headers, callback);
  },

  quickReplyInteractive(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime,
      'Content-Type': 'multipart/form-data'
    };

    BaseService.doPostAccessTokenNoWebWorker(API.INTERACTIVE, data, headers, callback);
  },

  quickReplyInteractiveEdit(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime,
      'Content-Type': 'multipart/form-data'
    };

    BaseService.doPostAccessTokenNoWebWorker(API.EDIT_INTERACTIVE, data, headers, callback);
  }
};

const API = {
  GET_QUICK_REPLY_LIST: "/organization/quick_reply/get_list",
  ADD_QUICK_REPLY: "/organization/quick_reply/add",
  EDIT_QUICK_REPLY: "/organization/quick_reply/edit",
  DELETE_QUICK_REPLY: "/organization/quick_reply/delete",
  UPLOAD_MEDIA: "/organization/quick_reply/upload_media",
  INTERACTIVE: "/organization/quick_reply/add_whatsappba_interactive",
  EDIT_INTERACTIVE: "/organization/quick_reply/edit_whatsappba_interactive"
}

export default QuickReplyServices;
