import React, { Component } from "react";
// import Header from "../reuseableComponent/header/Header";
// import AccountSettingMyProfile from "./accountSettingMyProfile/AccountSettingMyProfile";
import AccountSettingSecurity from "./accountSettingSecurity/AccountSettingSecurity";
import "./AccountSetting.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import { checkID } from "../../helper/HelperGeneral";
import "react-toastify/dist/ReactToastify.css";
import { doToast } from "../../helper/HelperGeneral";

class AccountSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOrganizationTab: "security",
      orgID: ""
    };

    this.changeActiveTab = this.changeActiveTab.bind(this);
  }

  changeActiveTab(tab) {
    this.setState({
      activeOrganizationTab: tab
    });
  }

  doMount() {
    // setUserData(HelperCustomEncryptor.doDecrypt(props.user.data));
    const paramID = checkID(this.props.match);
    if (paramID) {
      this.setState({
        orgID: paramID
      });
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          this.props.history.push(`/o/${urlOrgID}/account/security`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  componentDidMount() {
    this.doMount();
  }

  doToastAccountSetting(message, status = null) {
    doToast(message, status);
  }

  render() {
    let listOrganizationTabs = [
      // {
      //   name: 'Profile',
      //   code: 'profile',
      //   component: AccountSettingMyProfile
      // },
      {
        name: 'Security',
        code: 'security',
        component: AccountSettingSecurity
      }
    ];
    
    return (
      <React.Fragment>
        <SectionWrap
          {...this.props}
          orgID={this.state.orgID}
          withHeader
          withSideMenu
        >
            {/* <Header headerTitle={"Your Profile"} /> */}
            <div className="sectionWrap">
              <div id="page-account-setting">
                <Nav tabs> 
                    {listOrganizationTabs.map((value, index) => {
                        return <NavItem key={`nav-item-${index}`}>
                                  <NavLink
                                    className={this.state.activeOrganizationTab === value.code ? 'active-tabs-account-setting': '' }
                                    onClick={() => { this.changeActiveTab(value.code); }}
                                  >
                                      {value.name}
                                  </NavLink>
                              </NavItem>
                    })}
                </Nav>

                <TabContent activeTab={this.state.activeOrganizationTab}>
                    {listOrganizationTabs.map((value, index) => {
                        return <TabPane tabId={value.code} key={`tabpane-${index}`}>
                                  <value.component doToastAccountSettingProps={this.doToastAccountSetting} />
                              </TabPane>
                    })}
                </TabContent>
              </div>
            </div>
            
        </SectionWrap>
      </React.Fragment>
    );
  }
}

export default AccountSetting;
