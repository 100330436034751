import React, { useState } from 'react';
import './ChatRoomCaseHistoryUserInfoTab.css';
// import IconWorld from '../../../../../assets/img/chatroom/user-info-tab/world.svg';
import IconAt from '../../../../../assets/img/chatroom/user-info-tab/at.svg';
import IconPhone from '../../../../../assets/img/chatroom/user-info-tab/phone.svg';
import IconNote from '../../../../../assets/img/chatroom/user-info-tab/note.svg';
import IconUsers from '../../../../../assets/img/fi-user-circle.svg';
import IconBriefcase from '../../../../../assets/img/fi-briefcase.svg';
import { connect } from 'react-redux';
import { setCaseDetailData } from '../../../../../redux/actions/reduxActionCaseDetailData';
import { FiCopy } from 'react-icons/fi';
import { doToast } from '../../../../../helper/HelperGeneral';
import HelperInput from '../../../../../helper/HelperInput';
import Helper from '../../../../../helper/HelperChat';
import { Tooltip } from 'reactstrap';
import IconUser from '../../../../../assets/img/chatroom/user-info-tab/user.svg';
import IconHelpCircle from '../../../../../assets/img/chatroom/user-info-tab/icon-help-circle.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import { FIELD_TYPE_DATE, FIELD_TYPE_MULTIPLE_SELECT } from '../../../../setup/contactInfoCustomField/ContactInfoCustomField';
import HelperDate from '../../../../../helper/HelperDate';

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};

const copyToClip = (selector, text) => {
  document.getElementById(selector).select();
  document.execCommand("copy");
  doToast(text);
};

var ChatRoomCaseHistoryUserInfoTab = (props) => {
  let [showTooltipAlias, setShowTooltipAlias] = useState(false);
  
  let toggleTooltip = () => {
    setShowTooltipAlias(!showTooltipAlias);
  }

  let renderSegment = (data) => {
    if (!data) {
      return "";
    }

    let text = "";
    
    data.map((v) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  return (
    <Scrollbars autoHideTimeout={500}
                renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
    >
      <div className="chat-room-user-info-tab-wrapper main-user-info-wrapper">
          <div className="user-info-list-wrapper">
            <label><b>Full Name</b></label>
            <div className="user-info-box-wrapper with-button-copy">
              <img src={IconUser} alt="" />
              {props.caseHistoryPopupDataProps.user.fullName}
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label>
              <b>Alias</b>
              <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-alias-on-modal" alt="" />
              <div>
                <Tooltip className="tooltip-content" placement="left" isOpen={showTooltipAlias} target="tooltip-alias-on-modal" toggle={toggleTooltip}>
                  Alias will be added as a prefix to the user’s name e.g Alias (Full Name)
                </Tooltip>
              </div>
            </label>
            <div className="user-info-box-wrapper with-button-copy">
              <img src={IconUser} alt="" />
              {props.caseHistoryPopupDataProps.user.alias ? 
                props.caseHistoryPopupDataProps.user.alias !== "" ?
                  props.caseHistoryPopupDataProps.user.alias
                  :
                  <span className="grey-font">E.g John</span>
                :
                <span className="grey-font">E.g John</span>
              }
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Company</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconBriefcase} alt="" />
              {props.caseHistoryPopupDataProps.user.companyName ?
                props.caseHistoryPopupDataProps.user.companyName !== "" ?
                  props.caseHistoryPopupDataProps.user.companyName
                  :
                  <span className="grey-font">E.g Taptalk</span>
                :
                <span className="grey-font">E.g Taptalk</span>
              }
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Job Title</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconUsers} alt="" />
              {props.caseHistoryPopupDataProps.user.jobTitle ?
                props.caseHistoryPopupDataProps.user.jobTitle !== "" ?
                  props.caseHistoryPopupDataProps.user.jobTitle
                  :
                  <span className="grey-font">E.g Account Executive</span>
                :
                <span className="grey-font">E.g Account Executive</span>
              }
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Email Address</b></label>
            <div className="user-info-box-wrapper with-button-copy">
              <img src={IconAt} alt="" />
              {props.caseHistoryPopupDataProps.user.email === "" ? 
                "-" 
                :
                <input type="text"
                      value={props.caseHistoryPopupDataProps.user.email}
                      // onClick={copyToClip}
                      id="copy-email-detail-modal"
                      readOnly
                /> 
              }
              
              {props.caseHistoryPopupDataProps.user.email !== "" &&
                  <div className="user-detail-copy-wrapper" onClick={() => {
                    copyToClip("copy-email-detail-modal", "Email address copied")
                  }}>
                    <FiCopy />
                    <b>Copy</b>
                  </div>
              }
            </div>
          </div>
          
          {props.caseHistoryPopupDataProps.user.phone !== "" &&
            <div className="user-info-list-wrapper">
              <label><b>Phone Number</b></label>
              <div className="user-info-box-wrapper with-button-copy">
                <img src={IconPhone} alt="" />

                <input type="text"
                      value={`+${HelperInput.phoneBeautify(props.caseHistoryPopupDataProps.user.phone)}`}
                      // onClick={copyToClip}
                      id="copy-phone-detail-modal"
                      readOnly
                /> 

                <div className="user-detail-copy-wrapper" onClick={() => {
                      copyToClip("copy-phone-detail-modal", "Phone number copied")
                    }}>
                  <FiCopy />
                  <b>Copy</b>
                </div>
              </div>
            </div>
          }

          {/* <div className="user-info-list-wrapper">
            <label><b>Location</b></label>
            <div className="user-info-box-wrapper">
              <img src="https://storage.googleapis.com/9a3048-taptalk-prd-public/static/flags/ID@2x.png" alt="" className="case-nation-flag" />
              Jakarta, Indonesia
            </div>
          </div> */}

          {/* <div className="user-info-list-wrapper">
            <label><b>IP Address</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconWorld} alt="" />
              192.169.1.1
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Tags</b></label>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
          </div> */}

          {
            (props.caseHistoryPopupDataProps.user.customFields &&
            props.caseHistoryPopupDataProps.user.customFields.length > 0) &&
            <div className="user-info-list-wrapper remarks-for-contact">
              {
                props.caseHistoryPopupDataProps.user.customFields.map((customField, index) => {
                  let multipleSelectValue = "-";
                  if (customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT && customField.value) {
                    const multipleSelectValuesArray = JSON.parse(customField.value);
                    if (multipleSelectValuesArray && multipleSelectValuesArray.length > 0) {
                      multipleSelectValue = multipleSelectValuesArray.map((e, i) => (e)).join("\n");
                    }
                  }
                  return (
                    <>
                      <label><b>{customField.fieldName}</b></label>
                      <div className="user-info-box-wrapper-multiple-line no-icon">
                        {
                          customField.value
                          ?
                          customField.fieldType === FIELD_TYPE_DATE
                          ?
                          <span>{HelperDate.formatToString(new Date(Date.parse(customField.value, "yyyy-MM-dd")), "d MMM yyyy")}</span>
                          :
                          customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT
                          ?
                          <span dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(multipleSelectValue)}} />
                          :
                          <span dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(customField.value)}} /> 
                          :
                          <span className="grey-font">-</span>
                        }
                      </div>
                    </>
                  );
                })
              }
            </div>
          }

          {props.caseDetailData &&
            <div className="user-info-list-wrapper remarks-for-contact">
              <label><b>Contact Segments</b></label>
              <p className='grey-font contact-segment-text'>
                {(props.caseDetailData.userSegments && props.caseDetailData.userSegments.length === 0) ?
                  "This contact is not in any contact segment"
                  :
                  <div dangerouslySetInnerHTML={{ __html: renderSegment(props.caseDetailData.userSegments) }} />
                }
              </p>
            </div>
          }

          <div className="user-info-list-wrapper remarks-for-contact">
              <label><b>Remarks for Contact</b></label>
              <div className="user-info-box-wrapper-multiple-line">
                <img src={IconNote} alt="" />
                {props.caseHistoryPopupDataProps.user.agentRemark ?
                  props.caseHistoryPopupDataProps.user.agentRemark !== "" ?
                    <>
                      <span dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(props.caseHistoryPopupDataProps.user.agentRemark) }} /> 
                    </>
                    :
                    <span className="grey-font">Write down anything that may help you or others understand the user better</span>
                  :
                  <span className="grey-font">Write down anything that may help you or others understand the user better</span>
                }
              </div>
          </div>
        </div>
      </Scrollbars>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  caseDetailData: state.caseDetailData,
});

const mapDispatchToProps = {
    setCaseDetailData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomCaseHistoryUserInfoTab);
