import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const OfficeHourServices = {
  getOfficeHour(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_OFFICE_HOUR, {}, headers, callback);  
  },

  addOfficeHour(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_OFFICE_HOUR, data, headers, callback);  
  },

  editOfficeHour(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_OFFICE_HOUR, data, headers, callback);  
  },

  deleteOfficeHour(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_OFFICE_HOUR, data, headers, callback);  
  },
};

const API = {
  GET_OFFICE_HOUR: "/organization/setup/office_hours/get",
  ADD_OFFICE_HOUR: "/organization/setup/office_hours/add",
  EDIT_OFFICE_HOUR: "/organization/setup/office_hours/edit",
  DELETE_OFFICE_HOUR: "/organization/setup/office_hours/remove",
}

export default OfficeHourServices;
