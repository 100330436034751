import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Tooltip } from 'reactstrap';
import "../../../auth/Auth.css";
import "../contactInfoCustomFieldAdd/ContactInfoCustomFieldAdd.scss";
import { FiTrash2, FiEdit } from "react-icons/fi";
import IconHelpCircle from '../../../../assets/img/chatroom/user-info-tab/icon-help-circle.svg';
import IconCircleCheckGreen from '../../../../assets/img/sidemenu-icon/new/sub/icon-circle-check-green.svg';
import IconCircleXRed from '../../../../assets/img/sidemenu-icon/new/sub/icon-circle-x-red.svg';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast } from "../../../../helper/HelperGeneral";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ContactCustomFieldServices from "../../../../services/newServices/ContactCustomFieldServices";
import { 
  FIELD_TYPE_SINGLE_LINE_TEXT,
  FIELD_TYPE_MULTILINE_TEXT,
  FIELD_TYPE_DROPDOWN,
  FIELD_TYPE_MULTIPLE_SELECT,
  FIELD_TYPE_DATE,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_LABELS,
  VALIDATION_LABELS,
  DEFAULT_CUSTOM_FIELD_VAL,
} from "../ContactInfoCustomField";

let ContactInfoCustomFieldDetail = props => {

  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingDelete, setIsLoadingDelete] = useState(false);
  let [customFieldVal, setCustomFieldVal] = useState(DEFAULT_CUSTOM_FIELD_VAL);
  let [showTooltipRequired, setShowTooltipRequired] = useState(false);
  let [showTooltipCharacterLimit, setShowTooltipCharacterLimit] = useState(false);
  let [isShowDeleteFieldModal, setIsShowDeleteFieldModal] = useState(false);

  useEffect(() => {
    if (orgID) {
      let _customFieldVal;
      if (customFieldVal && customFieldVal.id) {
        _customFieldVal = {...customFieldVal};
      }
      else if (match.params.id) {
        _customFieldVal = {
          id: parseInt(match.params.id)
        }
      }
      if (_customFieldVal && _customFieldVal.id) {
        ContactCustomFieldServices.getContactCustomFieldDetails(orgID, _customFieldVal, (response) => {
          if (response.dataResult.error.message === "" && response.dataResult.data.customField) {
            setCustomFieldVal(response.dataResult.data.customField);
          }
        });
      }
      else {
        back();
      }
    }
  }, [orgID]);

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.id) {
        const customFieldData = {
          id: props.history.location.state.id,
          fieldName: props.history.location.state.fieldName ? props.history.location.state.fieldName : "",
          isRequired: props.history.location.state.isRequired ? props.history.location.state.isRequired : false,
          fieldCode: props.history.location.state.fieldCode ? props.history.location.state.fieldCode : "",
          fieldType: props.history.location.state.fieldType ? props.history.location.state.fieldType : "",
          validation: props.history.location.state.validation ? props.history.location.state.validation : "",
          maxCharacters: props.history.location.state.maxCharacters ? props.history.location.state.maxCharacters : "",
          options: props.history.location.state.options ? props.history.location.state.options : []
        };
        setCustomFieldVal(customFieldData);
      }
    }
  }, [props.history]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);
  
  let toggleTooltipRequired = () => {
    setShowTooltipRequired(!showTooltipRequired);
  }
  
  let toggleTooltipCharacterLimit = () => {
    setShowTooltipCharacterLimit(!showTooltipCharacterLimit);
  }
  
  let toggleShowDeleteFieldModal = () => {
    setIsShowDeleteFieldModal(!isShowDeleteFieldModal);
  }

  let back = () => {
    history.push(`/o/${orgID}/setup/contact-info-custom-field`);
  }

  let goToEdit = () => {
    history.push({
      pathname: `/o/${orgID}/setup/contact-info-custom-field/edit/${customFieldVal.id}`,
      state: customFieldVal
    });
  }

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    }
    else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          back();
        }
        else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let deleteCustomField = () => {
    setIsLoadingDelete(true);
    ContactCustomFieldServices.deleteContactCustomField(orgID, customFieldVal, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          setIsShowDeleteFieldModal(false);
          setIsLoadingDelete(false);
          back();
          setTimeout(() => {
            doToast(response.dataResult.data.message);
          }, 200);
        }
        else {
          setIsShowDeleteFieldModal(false);
          setIsLoadingDelete(false);
          doToast(response.dataResult.data.message, "fail");
        }
      }
      else {
        setIsShowDeleteFieldModal(false);
        setIsLoadingDelete(false);
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <BackButton text="Back to Contact Info Custom Field" onClick={back} />

      <div className="sectionWrap create-custom-field-main-wrapper">
        <div className="topContent create-custom-field-header">
          <p className="create-custom-field-title"><b>Contact Info Custom Field Details</b></p>

          <div className="topContent-button-wrapper">
            <span>
              <ButtonWithLoadingOrIcon 
                className="red-button main-button-40 button-delete button-delete-custom-field"
                text="Delete"
                icon={{
                  type: "svg",
                  src: FiTrash2
                }}
                position="left"
                onClickAction={toggleShowDeleteFieldModal}
              />
              <ButtonWithLoadingOrIcon 
                icon={{
                  type: "svg",
                  src: FiEdit
                }}
                className="no-fill-button main-button-40"
                text="Edit"
                position="left"
                onClickAction={goToEdit}
              />
            </span>
          </div>
        </div>

        <>
          <div className="create-custom-field-add-wrapper">

            <div className="form-content-wrapper">
              
              <label><b>Field Name</b></label>
              <p className="field-value">{customFieldVal.fieldName}</p>

              <label className="label-left">
                <b>Required</b>
                <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-required" alt="" />
                <div>
                  <Tooltip 
                    // className="tooltip-content"
                    className="tooltip-required"
                    placement="bottom"
                    isOpen={showTooltipRequired}
                    target="tooltip-required"
                    toggle={toggleTooltipRequired}
                    opacity={1.0}
                  >
                    If Required is on, this field should always be filled when edited in contact’s info
                  </Tooltip>
                </div>
              </label>
              <div className="required-view">
                {
                  customFieldVal.isRequired ?
                  <>
                    <img src={IconCircleCheckGreen} alt="" />
                    Yes
                  </>
                  :
                  <>
                    <img src={IconCircleXRed} alt="" />
                    No
                  </>
                }
              </div>

              <label><b>Field Code</b></label>
              <p className="field-value">{customFieldVal.fieldCode}</p>

              <label><b>Field Type</b></label>
              <p className="field-value">{FIELD_TYPE_LABELS[customFieldVal.fieldType] ? FIELD_TYPE_LABELS[customFieldVal.fieldType] : customFieldVal.fieldType}</p>

              {
                (customFieldVal.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ||
                 customFieldVal.fieldType === FIELD_TYPE_MULTILINE_TEXT ||
                 customFieldVal.fieldType === FIELD_TYPE_DATE ||
                 customFieldVal.fieldType === FIELD_TYPE_NUMBER)
                &&
                <>
                  <div className="form-content-wrapper dropdown-validation">
                    <label><b>Validation</b></label>
                    <p className="field-value">{VALIDATION_LABELS[customFieldVal.validation] ? VALIDATION_LABELS[customFieldVal.validation] : customFieldVal.validation}</p>
                  </div>
                  {/* {
                    (customFieldVal.validation === VALIDATION_FREE_TEXT ||
                     customFieldVal.validation === VALIDATION_NUMERIC ||
                     customFieldVal.validation === VALIDATION_NUMBER ||
                     customFieldVal.validation === VALIDATION_ALPHANUMERIC)
                    &&
                    <ErrorOrInfoComp
                      text={
                        customFieldVal.validation === VALIDATION_FREE_TEXT ? "Field can only be filled with alphabets, numbers, and special characters" :
                        customFieldVal.validation === VALIDATION_NUMERIC ? "Field can only be filled with numbers" :
                        customFieldVal.validation === VALIDATION_NUMBER ? "Field can only be filled with positive or negative number" :
                        customFieldVal.validation === VALIDATION_ALPHANUMERIC ? "Field can only be filled with alphabets and numbers with no space" :
                        ""
                      }
                      _className={"font-black"}
                      icon={<FiInfo />}
                    />
                  } */}
                </>
              }

              {
                (customFieldVal.maxCharacters &&
                 customFieldVal.maxCharacters !== "0" &&
                 customFieldVal.maxCharacters > 0 &&
                 (customFieldVal.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ||
                  customFieldVal.fieldType === FIELD_TYPE_MULTILINE_TEXT)) ?
                <>
                  <label className="label-left">
                    <b>Limit Maximum Character</b>
                    <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-char-limit" alt="" />
                    <div>
                      <Tooltip 
                        className="tooltip-char-limit"
                        placement="right"
                        isOpen={showTooltipCharacterLimit}
                        target="tooltip-char-limit"
                        toggle={toggleTooltipCharacterLimit}
                        opacity={1.0}
                      >
                        When filled, this limits how many characters agents can input when editing customer’s info.
                      </Tooltip>
                    </div>
                  </label>
                  <p className="field-value">{`${customFieldVal.maxCharacters} Characters`}</p>
                </>
                :
                <></>
              }
              
              {
                (customFieldVal.fieldType === FIELD_TYPE_DROPDOWN || customFieldVal.fieldType === FIELD_TYPE_MULTIPLE_SELECT) &&
                <>
                  <label><b>Options</b></label>
                  {
                    customFieldVal.options.map((v, i) => {
                      return (
                        <div 
                          key={`multiple-option-${i}`}
                          className="view-options"
                        >
                          {v}
                        </div>
                      )
                    })
                  }
                </>
              }
            </div>
          </div>
        </>
      </div>
      
      <Modal 
        isOpen={isShowDeleteFieldModal} 
        className="modal-replace-data" 
        toggle={toggleShowDeleteFieldModal}
      >
        <ModalBody>
          <b className="modal-title">
            Delete Custom Field?
          </b>

          <p className="modal-desc">
            {`Deleting “${customFieldVal.fieldName}” will also delete any added information for this custom field in all contacts. This action cannot be undone, are you sure you want to continue?`}
          </p>

          <ButtonWithLoadingOrIcon 
            text="Cancel"
            className="main-button-48 no-fill-button"
            onClickAction={toggleShowDeleteFieldModal}
          />

          {
            !isLoadingDelete ?
            <ButtonWithLoadingOrIcon
                text="Delete"
                className="main-button-48 red-button"
                onClickAction={deleteCustomField}
            />
            :
            <ButtonWithLoadingOrIcon
                text="Delete"
                className="main-button-48 red-button"
                isLoading
                isDisabled
                loadingColor="gray"
                position="left"
            />
          }
        </ModalBody>
      </Modal>
    </SectionWrap >
  );
};

export default ContactInfoCustomFieldDetail;
