import React, { useState, useEffect } from "react";
import "../../../auth/Auth.css";
import "../../QuickReplyAdd.scss";
import "../quickReplyAddInteractiveList/QuickReplyAddInteractiveList.scss";
import { FiInfo, FiPlus, FiTrash2, FiEye, FiList } from "react-icons/fi";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import FormInteractiveMessagePreviewQuickReply from "../../FormInteractiveMessagePreviewQuickReply/FormInteractiveMessagePreview";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import QuickReplyAddInteractiveManageRow from "../quickReplyAddInteractiveList/QuickReplyAddInteractiveManageRow/QuickReplyAddInteractiveManageRow";
import ServiceQuickReply from "../../../../services/newServices/QuickReplyService";
// import HelperInput from "../../../helper/HelperInput";
import HelperQuickReply from "../../../../helper/HelperQuickReply";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const DEFAULT_ROW = {
  id: "",
  title: "",
  description: ""
}

const DEFAULT_SECTION = {
  title: "",
  manageRow: "",
  rows: [{...DEFAULT_ROW}]
}


let QuickReplyGeneralEditInteractiveList = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [data, setData] = useState({
    headerText: "",
    document: {
      
    },
    bodyText: "",
    footerText: "",
    buttonText: "",
    section: [{
      title: "",
      manageRow: "",
      rows: []
    }],
    shortcut: ""
  });
  let [dataOrigin, setDataOrigin] = useState({
    headerText: "",
    document: {
      
    },
    bodyText: "",
    footerText: "",
    buttonText: "",
    section: [{
      title: "",
      manageRow: "",
      rows: []
    }],
    shortcut: ""
  });
  let [isWaitResponseAddQuickReply, setIsWaitResponseAddQuickReply] = useState(false);
  let [errorMessageData, setErrorMessageData] = useState({
    headerText: "",
    document: "",
    bodyText: "",
    footerText: "",
    buttonText: "",
    section: [{
      title: "",
      manageRow: "",
      rows: []
    }],
    shortcut: ""
  });
  let [activeManageRow, setActiveManageRow] = useState(false);
  let [tempRemoveSectionIndex, setTempRemoveSectionIndex] = useState(false);
  let [showPopupRemoveSection, setShowPopupRemoveSection] = useState(false);
  let [showModalPreviewMessage, setShowModalPreviewMessage] = useState(false);

  let togglePreview = () => {
    setShowModalPreviewMessage(!showModalPreviewMessage);
  }

  let back = () => {
    history.push(`/o/${orgID}/setup/quick-reply`, {
      tab: "generalQuickReply"
    });
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // let { id, createdTime } = responsedata.memberships[0].organization;
          // let urlOrgID = `${id}-${createdTime}`;
          back();
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if(props.history.location.state) {
      let _state = JSON.parse(JSON.stringify(props.history.location.state));
      if(props.history.location.state.message) {
        let _whatsapp = JSON.parse(_state.data);
        
        let _errorRow = [];

        _whatsapp.listAction.sections.map((v, i) => {
          _errorRow.push({
            title: "",
            manageRow: "",
            rows: []
          });

          v.rows.map((_v, _i) => {
            _errorRow[i].rows.push({
              title: "",
              description: ""
            })

            return null;
          })

          return null;
        })

        let _editQuickReplyData = {
          headerText: _whatsapp.header ? _whatsapp.header.text : "",
          bodyText: _whatsapp.bodyText,
          footerText: _whatsapp.footerText ? _whatsapp.footerText : "",
          buttonText: _whatsapp.listAction.buttonText,
          section: _whatsapp.listAction.sections,
          shortcut: _state.shortcut,
        };

        setData(_editQuickReplyData);
        setDataOrigin(_editQuickReplyData);

        setErrorMessageData({
          headerText: "",
          bodyText: "",
          footerText: "",
          buttonText: "",
          section: _errorRow,
          shortcut: ""
        })
      }
    }else {
      back();
    }
  }, [props.history])

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  let toggleRemoveSection = (idx = false) => {
    if(idx !== false) {
      setTempRemoveSectionIndex(idx);
    }else {
      setTempRemoveSectionIndex(false);
    }

    setShowPopupRemoveSection(!showPopupRemoveSection);
  }

  let onSubmitEditQuickReply = () => {
    let errorCount = 0;
    let scrollTo = "";
    let _errorMessageData = {...errorMessageData}

    if(data.headerText.length > 60) {
      _errorMessageData.headerText = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-header-type"; }
    }

    if(data.bodyText.length > 1024) {
      _errorMessageData.bodyText = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-bodyText"; }
    }

    if(data.bodyText === "") {
      _errorMessageData.bodyText = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-bodyText"; }
    }

    if(data.footerText.length > 60) {
      _errorMessageData.footerText = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-footerText"; }
    }

    if(data.buttonText.length > 20) {
      _errorMessageData.buttonText = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-button-text"; }
    }

    if(data.buttonText === "") {
      _errorMessageData.buttonText = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = "input-interactive-button-text"; }
    }

    data.section.map((v, i) => {
      if(v.title.length > 24) {
        _errorMessageData.section[i].title= "Characters exceed limit";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-section-${i}`; }
      }
      
      if(v.title === "" && data.section.length > 1) {
        _errorMessageData.section[i].title= "This field is required";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-section-${i}`; }
      }
      
      v.rows.map((_v, _i) => {
        if(_v.title === "")  {
          _errorMessageData.section[i].manageRow = "Row title is required";
        }

        return null;
      })

      if(v.rows.length === 0) {
        _errorMessageData.section[i].manageRow= "Each section requires at least 1 row";
        errorCount = errorCount + 1;
        if(scrollTo === "") { scrollTo = `input-interactive-section-${i}`; }
      }

      return null;
    })

    if(data.shortcut === "") {
      _errorMessageData.shortcut = "This field is required";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = `input-shortcut`; }
    }

    if(data.shortcut.length > 50) {
      _errorMessageData.shortcut = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(scrollTo === "") { scrollTo = `input-shortcut`; }
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${scrollTo}`);
    }else {
      setIsWaitResponseAddQuickReply(true);
      
      let _data = {
        ...data,
        id: parseInt(props.match.params.quickReplyID),
        ownerType: HelperQuickReply.ownerTypeGeneral,
        type: "list",
        shortcut: data.shortcut
      }

      _data.listAction = {
        buttonText: data.buttonText,
        sections: _data.section
      }
      
      let runEdit = () => {
        ServiceQuickReply.quickReplyInteractiveEdit(orgID, _data, (response) => {
          let dataResult = response.dataResult;
          let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
          
          if(dataResult.error.message === "") {
            if(dataResult.data.success) {
              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Edit Quick Reply",
                  {
                    type : "General - Interactive",
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : orgID.split("-")[0]
                  }
                )
              }
              
              setDataOrigin(data);

              setTimeout(() => {
                history.push({
                  pathname: `/o/${orgID}/setup/quick-reply`,
                  state: {
                    message: response.dataResult.data.message,
                    success: response.dataResult.data.success,
                    tab: "generalQuickReply"
                  }
                })
              }, 3000)
            }else {
              setIsWaitResponseAddQuickReply(false);
              doToast(dataResult.data.message, "fail");  
            }
          }else {
            setIsWaitResponseAddQuickReply(false);
            doToast(dataResult.error.message, "fail");
          }
        });
      }

      if(data.headerText !== "") {
        _data.header = {
          type: "text",
          text: _data.headerText
        }
      }

      runEdit();
    }
  }

  let onChangeText = (id, val) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    
    if(id === "shortcut") {
      _data[id] = val.replaceAll(" ", "");
    }else {
      _data[id] = val;
    }

    _errorMessageData[id] = "";

    if(id === "headerText" && val.length > 60) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "bodyText" && val.length > 1024) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "footerText" && val.length > 60) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "buttonText" && val.length > 20) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if(id === "shortcut" && val.length > 50) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let onChangeSectionTitle = (val, index) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _data.section[index].title = val;
    _errorMessageData.section[index].title = "";
    setData(_data);
    setErrorMessageData(_errorMessageData);
  }
  
  let countRows = () => {
    let rowsCount = 0;

    data.section.map((v) => {
      rowsCount = rowsCount + v.rows.length;

      return null;
    })

    return rowsCount;
  }

  let addSection = () => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    
    if(countRows() < 10) {
      if(_data.section.length === 1 && _data.section[0].rows.length === 0) {
        let _tempTitle = _data.section[0].title;
        _data.section[0] = {...DEFAULT_SECTION};
        _errorMessageData.section[0] = {...DEFAULT_SECTION};
        _data.section[0].title = _tempTitle;
      }

      _data.section.push({...DEFAULT_SECTION});
      _errorMessageData.section.push({...DEFAULT_SECTION});  
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let removeSection = () => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};
    _data.section.splice(tempRemoveSectionIndex, 1);
    _errorMessageData.section.splice(tempRemoveSectionIndex, 1);

    if(_data.section.length === 1) {
      _errorMessageData.section[0].title = "";
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
    toggleRemoveSection();
    doToast("Section deleted successfully");
  }

  let runSaveChangesRow = (dataVal, errorVal, idx) => {
    let _data = JSON.parse(JSON.stringify(data));
    let _errorMessageData = JSON.parse(JSON.stringify(errorMessageData));

    _data.section[idx] = dataVal;
    _errorMessageData.section[idx] = errorVal;
    _errorMessageData.section[idx].manageRow = "";

    setData(_data);
    setErrorMessageData(_errorMessageData);
    setActiveManageRow(false);
  }
  
  return (
    <React.Fragment>
        <FormInteractiveMessagePreviewQuickReply
          isShowModalFormInteractivePreview={showModalPreviewMessage}
          toggle={togglePreview}
          data={data}
          type={"list"}
        />

        <QuickReplyAddInteractiveManageRow 
          isShowModalFormManageRow={activeManageRow}
          activeManageRow={activeManageRow}
          closeManageRow={() => {
            setActiveManageRow(false);
          }}
          errorMessageActiveManageRow={errorMessageData}
          rowLeft={10 - countRows()}
          sectionLength={data.section.length}
          runSaveChangesRow={runSaveChangesRow}
        />

        <PopupRemove 
          removeTitle={`Delete Section?`}
          removeDescription={`All rows in this section will be deleted as well when you delete a section and this action cannot be undone, are you sure you want to continue?`}
          onClickCancel={toggleRemoveSection}
          onClickRemove={removeSection}
          withoutBorder
          loadingButtonGray
          waitForResponse={false}
          showModalProps={showPopupRemoveSection}
          submitText={'Delete'}
          colorButton={"red-button"}
        />

        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={dataOrigin}
            dataAfterChange={data}
        >   
            <BackButton text="Back to General Quick Replies" onClick={back} />

            <div className="sectionWrap form-add-quick-reply form-add-quick-reply-interactive-list">
              <div className="form-title-wrapper">
                <b>Edit General Quick Reply</b>

                {isWaitResponseAddQuickReply ?
                  <ButtonWithLoadingOrIcon 
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    className="orange-button main-button-40"
                    text="Edit Quick Reply"
                    position="left"
                  />
                  :
                  <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-40"
                    text="Edit Quick Reply"
                    // isDisabled={isButtonSubmitDisabled()}
                    onClickAction={onSubmitEditQuickReply}
                  />    
                }
              </div>
                
              <div className="form-content-wrapper">
                  <label>
                    <b>Content Type</b>
                  </label>
                  <p className="type-val">
                    WABA Interactive Message
                  </p>

                  <label>
                    <b>Button Type</b>
                  </label>
                  <p className="type-val">
                    List
                  </p>

                  <div className="line-through" />

                  <div className="message-content">
                    <p>
                      <b>Message Content</b>
                    </p>

                    <ButtonWithLoadingOrIcon 
                      className="no-fill-button main-button-48"
                      icon={{
                        type: "svg",
                        src: FiEye
                      }}
                      position="left"
                      text="Preview Message"
                      onClickAction={togglePreview}
                    />
                  </div>
                  
                  <div className="input-outer-wrapper">

                    <label className="input-interactive-header">
                      <b>Header </b>
                      <span className="text-optional grey-font">
                        (optional)
                      </span>
                      <span className={`grey-font  ${errorMessageData.headerText === "Characters exceed limit" ? "red-text" : ""}`}>{data.headerText.length}/60 characters</span>
                    </label>
                    <input disabled={isWaitResponseAddQuickReply} type="text" value={data.headerText} onChange={(e) => onChangeText("headerText", e.target.value)} placeholder="Type your header here" className={errorMessageData.headerText !== "" ? "border-red" : ""} />
                    {errorMessageData.headerText !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.headerText}
                        icon={<FiInfo />}
                        _className="red-text"
                      />
                    }

                    <label className="input-interactive-bodyText">
                      <b>Body</b>
                      <span className={`grey-font ${errorMessageData.bodyText === "Characters exceed limit" ? "red-text" : ""}`}>{data.bodyText.length}/1024 characters</span>
                    </label>
                    <textarea disabled={isWaitResponseAddQuickReply} value={data.bodyText} onChange={(e) => onChangeText("bodyText", e.target.value)} placeholder="Type message here" className={errorMessageData.bodyText !== "" ? "border-red" : ""} />
                    {errorMessageData.bodyText !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.bodyText}
                        icon={<FiInfo />}
                        _className="red-text no-margin"
                      />
                    }
                    <span className="input-info-wrapper">
                      <p className="no-margin">
                        This is the message that will appear after you select a specific shortcut.
                      </p> 
                      <p className="no-margin">
                        <b>No blank space are allowed at the beginning and end of a message.</b>
                      </p>
                    </span>

                    <label className="input-interactive-footerText">
                      <b>Footer </b>
                      <span className="text-optional grey-font">
                        (optional)
                      </span>
                      <span className={`grey-font  ${errorMessageData.footerText === "Characters exceed limit" ? "red-text" : ""}`}>{data.footerText.length}/60 characters</span>
                    </label>
                    <input disabled={isWaitResponseAddQuickReply} type="text" value={data.footerText} onChange={(e) => onChangeText("footerText", e.target.value)} placeholder="Type your footer here" className={errorMessageData.footerText !== "" ? "border-red" : ""} />
                    {errorMessageData.footerText !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.footerText}
                        icon={<FiInfo />}
                        _className="red-text"
                      />
                    }

                    <label className="input-interactive-button-text">
                      <b>Button Text</b>
                      <span className={`grey-font  ${errorMessageData.buttonText === "Characters exceed limit" ? "red-text" : ""}`}>{data.buttonText.length}/20 characters</span>
                    </label>
                    <input disabled={isWaitResponseAddQuickReply} type="text" value={data.buttonText} onChange={(e) => onChangeText("buttonText", e.target.value)} placeholder="Type your button text here" className={errorMessageData.buttonText !== "" ? "border-red" : ""} />
                    {errorMessageData.buttonText !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.buttonText}
                        icon={<FiInfo />}
                        _className="red-text no-margin"
                      />
                    }
                  </div>

                  <label className="input-interactive-footerText">
                    <b>Manage Sections</b>
                    
                    <p className="grey-font label-sub">
                      You can add up to 10 sections and a total of 10 rows from all sections. 
                    </p>
                  </label>

                  {data.section.map((v, i) => {
                    return (
                      <div className={`button-text-wrapper-form ${i === 0 ? "section-title-wrapper-form-1": ""}`} key={`button-text-${i}`}>
                        <label className={`input-interactive-section-${i}`}>
                          <b>Section #{i + 1}</b>
                          <span className={`grey-font  ${errorMessageData.section[i].title === "Characters exceed limit" ? "red-text" : ""}`}>
                            {v.title.length}/24 characters

                            {data.section.length !== 1 ?
                              <FiTrash2 onClick={() => toggleRemoveSection(i)} className="remove-button-text" />
                              :
                              ""
                            }
                          </span>
                        </label>
                        <input disabled={isWaitResponseAddQuickReply} type="text" value={v.title} onChange={(e) => onChangeSectionTitle(e.target.value, i)} placeholder={`Section Title${data.section.length === 1 ? " (optional)" : ""}`} className={(errorMessageData.section[i].title !== "" && errorMessageData.section[i].title !== "Each section requires at least 1 row") ? `border-red button-text-${i}` : `section-title-text-${i}`} />
                        {(errorMessageData.section[i].title !== "" && errorMessageData.section[i].title !== "Each section requires at least 1 row") &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.section[i].title}
                            icon={<FiInfo />}
                            _className="red-text"
                          />
                        }

                        <p 
                          className="manage-rows" 
                          onClick={() => {
                            if(!isWaitResponseAddQuickReply) {
                              setActiveManageRow({
                                data: JSON.parse(JSON.stringify(v)), 
                                error: JSON.parse(JSON.stringify(errorMessageData.section[i])),
                                index: i, 
                                show: true
                              })
                            }
                          }}
                        >
                          <FiList />
                          <b>Manage Rows ({v.rows.length})</b>
                        </p>

                        {(errorMessageData.section[i].manageRow === "Each section requires at least 1 row" || errorMessageData.section[i].manageRow === "Row title is required") &&
                          <ErrorOrInfoComp 
                            text={errorMessageData.section[i].manageRow}
                            icon={<FiInfo />}
                            _className="red-text"
                          />
                        }
                      </div>
                    )
                  })}
                  
                  {(countRows() < 10) &&
                    <p className="add-another-button" onClick={addSection}>
                      <b><FiPlus /> Add Another Section</b>
                    </p>
                  }

                  <div className="input-outer-wrapper">
                    <label className="pos-relative">
                      <b>Shortcut</b>
                      <span className={errorMessageData.shortcut === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{data.shortcut.length}/50 characters</span>
                    </label>
                    <div className="quick-reply-prefix disabled-input">
                      {HelperQuickReply.prefixShortcut}
                    </div>

                    <input disabled={isWaitResponseAddQuickReply} type="text" value={data.shortcut} placeholder="Type shortcut here" id="shortcut" className={`input-shortcut ${errorMessageData.shortcut !== "" ? "border-red" : ""}`} onChange={(e) => {
                        onChangeText("shortcut", e.target.value);
                    }}/>

                    {errorMessageData.shortcut !== "" &&
                      <ErrorOrInfoComp 
                        text={errorMessageData.shortcut}
                        icon={<FiInfo />}
                        _className="red-text margin-top-0"
                      />
                    }

                    <span className="input-info-wrapper">
                        <p className="no-margin">
                          This is the message that will appear after you select a specific shortcut.
                        </p>
                        <p className="no-margin">
                          <b> Only alphanumberic and underscore are allowed. </b>
                        </p> 
                        <p className="no-margin">
                          Your shortcut will be displayed like this "/shortcut" 
                        </p>
                        <p className="no-margin">
                          (Tip: keep it short and simple).
                        </p>
                    </span>
                  </div>
              </div>
          </div>
        </SectionWrap>
    </React.Fragment>
  );
};

export default QuickReplyGeneralEditInteractiveList;