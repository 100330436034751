import React, { useState, useEffect } from 'react';
import './ChatRoomForward.scss';
import { tapCoreRoomListManager } from "@taptalk.io/web-sdk";
import { connect } from 'react-redux';
import Select from "react-select";
import { Scrollbars } from 'react-custom-scrollbars';
import { doToast } from '../../../helper/HelperGeneral';
import HelperChat from '../../../helper/HelperChat';
import HelperInput from '../../../helper/HelperInput';
import { CHAT_TYPE } from "../../../helper/HelperConst";
import ForwardIcon from "../../../assets/img/forward-icon.svg";
import { Modal, ModalBody } from "reactstrap";
import { FiX, FiPhone, FiAlertCircle, FiSearch, FiPlus, FiUser, FiSend, FiEye, FiFile } from 'react-icons/fi';
// import BroadcastMessageFormImageMessage from "../../broadcastMessage/broadcastMessageForm/broadcastMessageFormImageMessage/BroadcastMessageFormImageMessage";
import BroadcastMessageServices from "../../../services/newServices/BroadcastMessageServices";
import UserContactServices from "../../../services/newServices/UserContactServices";
import StartConversationServices from "../../../services/chat/startConversationServices";
import PhoneCodePicker from '../../reuseableComponent/phoneCodePicker/PhoneCodePicker';
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import CustomSelectOption from '../../reuseableComponent/customSelectOption/CustomSelectOption';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { clearStartConversation, setStartConversation } from '../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../redux/actions/reduxActionStartConversationBa';
import { setActiveCasePanel } from '../../../redux/actions/reduxActionActiveCasePanel';
import { setToggleMineCase } from "../../../redux/actions/reduxActionToggleMineCase";
import { setShowOtherCase } from "../../../redux/actions/reduxActionShowOtherCase";
import { setForwardMessage } from "../../../redux/actions/reduxActionForwardMessage";
import { setModalStartConversationBalance, clearModalStartConversationBalance } from "../../../redux/actions/reduxActionModalStartConversationBalance";
import { FAIL_SEND_MESSAGE_REASON } from "../../../constants/message";
import { TAPLIVE_MEDIUM_LOGO, TAPLIVE_MEDIUM } from "../../../constants/taplive";

var style = {
    scrollStyle: {
      position: "relative",
      backgroundColor: "#ff7d00",
      right: "-2px",
      width: "5px",
      borderRadius: "8px"
    }
};

const REG_NUMERIC = /^[0-9]*$/;

const ERROR_MESSAGE_NUMBER = {
    length: "The combined country code and phone number must be between 7-15 digits",
    isNumeric: "Only numerics are allowed",
    isStartZero: "Phone number cannot start from 0"
}

const iconDropOrange =
  "url('/image/chevron-up-orange.svg') center / contain no-repeat !important";
const iconDropGrey =
  "url('/image/chevron-down-dark.svg') center / contain no-repeat !important";

const MESSAGE_TYPE = {
    text: "text",
    image: "image",
    file: "file",
    video: "video"
}

let ChatRoomForward = (props) => {
    let [isLoadingWhatsappStatus, setIsLoadingWhatsappStatus] = useState(false);
    let [channelList, setChannelList] = useState([]);
    let [conversationData, setConversationData] = useState({
        channel: {
          label: "",
          value: "",
        },
        recipient: {
            country: {
                id: "0",
                countryCodeNumber: "62",
                countryCode: "IDN",
                countryName: "Indonesia",
                countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
            },
            number: "",
            contact: {
                name: "",
                alias: "",
                number: ""
            }
        },
        message: "",
        fileAsset: {
          fileURL: "",
          fileName: "",
          fileData: false,
          caption: ""
        },
        topic: {
            value: "",
            label: ""
        },
        type: ""
    });
    let [contactSearchVal, setContactSearchVal] = useState("");
    let [temporaryContactList, setTemporaryContactList] = useState({
        search: "",
        list: [],
        time: 0
    })
    let [contactList, setContactList] = useState([]);

    let [temporaryFileValue, setTemporaryFileValue] = useState({
        fileAsset: {},
        time: false
    })
        
    let [errorFieldNumber, setErrorFieldNumber] = useState("");
    
    let [countryList, setCountryList] = useState(false);
    let [toggleAddPhoneForm, setToggleAddPhoneForm] = useState(false);
    let [includeInfo, setIncludeInfo] = useState(true);
    let [showModalImageVideoPreview, setShowModalImageVideoPreview] = useState(false);

    useEffect(() => {
        setIncludeInfo(true);
    }, [props.forwardMessage.isShow])

    useEffect(() => {
        if(temporaryContactList.search === contactSearchVal) {
            setContactList(temporaryContactList.list);
        }
    }, [temporaryContactList])

    useEffect(() => {
        if(temporaryFileValue.time) {
          let _temporaryFileValue = {...temporaryFileValue};
          let _conversationData = {...conversationData};
    
          _conversationData.fileAsset.fileName = _temporaryFileValue.fileAsset.fileName;
          _conversationData.fileAsset.fileURL = _temporaryFileValue.fileAsset.fileURL ? _temporaryFileValue.fileAsset.fileURL : _temporaryFileValue.fileAsset.url;
          _conversationData.fileAsset.fileData = _temporaryFileValue.fileAsset.fileData;
          setConversationData(_conversationData);
        }
    }, [temporaryFileValue])
    
    useEffect(() => {
        if(props.countryList) {
            let newCountryList = [];

            props.countryList.map((value) => {
                let country = {
                    id: value.id,
                    countryCodeNumber: value.callingCode,
                    countryCode: value.iso3Code,
                    countryName: value.commonName,
                    countryFlag: value.flagIconURL,
                    countryLabel: `${value.commonName} (+${value.callingCode})`
                };

                newCountryList.push(country);

                return null;
            })

            setCountryList(newCountryList)
        }
    }, [props.countcryList])

    let onClickInclude = (e) => {
        setIncludeInfo(e.target.checked);
    }

    let styleSelectChannel = () => ({
        placeholder: provide => ({
            ...provide,
            color: "rgba(25, 25, 25, 0.4)"
        }),
        indicatorsContainer: provide => ({
            ...provide,
            height: "48px",
            position: "relative",
            right: "8px"
        }),
        valueContainer: provide => ({
            ...provide,
            height: "100%"
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused ? iconDropOrange : iconDropGrey,
            transition: "0.25s ease-in-out",
            color: "transparent !important",
            width: "24px",
            height: "24px",
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            width: "100%"
        }),
        control: (provide, { isFocused }) => ({
            ...provide,
            padding: "0 5px",
            // height: "48px",
            borderRadius: "8px",
            border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
            boxShadow: "none !important",
            marginBottom: "24px",
            cursor: "pointer"
        }),
        indicatorSeparator: provide => ({ ...provide, display: "none" }),
            menu: provide => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden"
        }),
        option: (provide, state) => {
            const { isSelected } = state;
            const checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)"
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null
            };
        }
    })

    let resetValue = () => {
        setIncludeInfo(false);
        setIsLoadingWhatsappStatus(false);
        setConversationData({
            channel: {
              label: "",
              value: ""
            },
            recipient: {
                country: {
                    id: "0",
                    countryCodeNumber: "62",
                    countryCode: "IDN",
                    countryName: "Indonesia",
                    countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
                },
                number: "",
                contact: {
                    name: "",
                    number: ""
                }
            },
            message: "",
            fileAsset: {
              fileURL: "",
              fileName: "",
              fileData: false,
              caption: ""
            },
            topic: {
                value: "",
                label: ""
            }
        });
    
        setTemporaryFileValue({
            fileAsset: {},
            time: false
        })
            
        setErrorFieldNumber("");
    }

    let onChangeConversationMessage = (e) => {
        let _conversationData = {...conversationData};
        
        if(e.target.id === "phoneNumber") {
            let val = e.target.value.replaceAll(" ", "");
            if(HelperInput.numberOnly(val)) {
                _conversationData.recipient.number = HelperInput.phoneBeautify(val, true);
                setErrorFieldNumber("");
            }
        }else if(e.target.id === "caption") {
            _conversationData.fileAsset.caption = e.target.value;
        }else {
            _conversationData[e.target.id] = e.target.value;
        }
        setConversationData(_conversationData)
    };

    let onCloseClick = () => {
        if(!isLoadingWhatsappStatus) {
            resetValue();
            
            props.setForwardMessage({
                isShow: false,
                message: null,
                case: null
            });
        }
    }

    let onBlurAction = () => {
        let _errorFieldNumber = errorFieldNumber.slice();
        let errorMessage = "";

        // let phoneLength = (conversationData.recipient.country.countryCodeNumber + conversationData.recipient.number).replaceAll(" ", "").length;

        if(conversationData.recipient.number[0] === "0") {
            errorMessage = ERROR_MESSAGE_NUMBER.isStartZero;
        }

        // if(phoneLength < 7 || phoneLength > 15) {
        //     errorMessage = ERROR_MESSAGE_NUMBER.length;
        // }

        if(!REG_NUMERIC.test(conversationData.recipient.number.replaceAll(" ", ""))) {
            errorMessage = ERROR_MESSAGE_NUMBER.isNumeric;
        }

        _errorFieldNumber = errorMessage;
        setErrorFieldNumber(_errorFieldNumber);
    }

    let getChannelList = () => {
        BroadcastMessageServices.getBroadcastChannelList(props.orgIDProps, (response) => {
          let dataResult = response.dataResult;
          
          if(dataResult.status === 200) {
            let _channelList = [];
            let channels = dataResult.data.channels;
            
            if(channels.length > 0) {
              channels.map((value) => {
                if(props.topicListProps[value.topicID] || value.topicID === 0) {
                    _channelList.push({
                      value: value.id,
                      label: value.accountName,
                      //topicID: value.topicID,
                      topics: value.topics
                    })
                }
    
                return null;
              })
    
              setChannelList(_channelList);
            }
            
          }else {
            doToast(dataResult.error.message, "fail");
          }
        })
    }

    let onChangeChannel = (e) => {
        let _conversationData = {...conversationData};
        _conversationData.channel = e;
        setConversationData(_conversationData);
    }

    let onChangeCountryCode = (data) => {
        let _conversationData = {...conversationData};
        _conversationData.recipient.country = data;
        setConversationData(_conversationData);
    }

    let messageType = () => {
        let _messageType = props.forwardMessage.message.type;

        if(_messageType === CHAT_TYPE.TAPChatMessageTypeLink) {
            _messageType = "text";
        }

        if(_messageType === CHAT_TYPE.TAPChatMessageTypeText) {
            _messageType = "text";
        }

        if(_messageType === CHAT_TYPE.TAPChatMessageTypeImage) {
            _messageType = "image";
        }

        if(_messageType === CHAT_TYPE.TAPChatMessageTypeVideo) {
            _messageType = "video";
        }

        if(_messageType === CHAT_TYPE.TAPChatMessageTypeFile) {
            _messageType = "file";
        }

        return _messageType;
    }

    let generateBody = () => {
        let _forwardMessage = props.forwardMessage.message;
        let _result = "";

        if(messageType() === "text") {
            if(includeInfo) {
                _result = `Forwarded from ${props.forwardMessage.message.user.fullname}
Case ID: #${props.forwardMessage.case.stringID}

${_forwardMessage.body}`;
            }else {
                _result = _forwardMessage.body;
            }
        }
        
        if(messageType() === "image" || messageType() === "video") {
            _result = _forwardMessage.data.url;
        }

        if(messageType() === "file") {
            _result = _forwardMessage.data.url;
        }

        return _result;
    }

    let generateCaption = () => {
        let _forwardMessage = props.forwardMessage.message;
        let _result = "";
        
        if(messageType() !== "text") {
            if(includeInfo) {
                _result = `Forwarded from ${props.forwardMessage.message.user.fullname}
Case ID: #${props.forwardMessage.case.stringID}

${_forwardMessage.data.caption}`;
            }else {
                _result = _forwardMessage.data.caption;
            }
        }

        return _result;
    }   

    let generateFileAssetAndIncludeInfo = (data) => {
        let _forwardMessage = props.forwardMessage.message;
        let _fileAsset = {
            caption: "",
            fileData: false,
            fileName: "",
            fileURL: ""
        };

        if(
            _forwardMessage.type === CHAT_TYPE.TAPChatMessageTypeImage ||
            _forwardMessage.type === CHAT_TYPE.TAPChatMessageTypeFile ||
            _forwardMessage.type === CHAT_TYPE.TAPChatMessageTypeVideo
        ) {
            _fileAsset.caption = includeInfo ?
                generateCaption()
                :
                props.forwardMessage.message.data.caption
            ;
            _fileAsset.fileURL = props.forwardMessage.message.data.fileURL ? props.forwardMessage.message.data.fileURL : props.forwardMessage.message.data.url;

            if(props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeFile) {
                _fileAsset.fileName = props.forwardMessage.message.data.fileName;
                data.type = MESSAGE_TYPE.file;
            }

            if(props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeImage) {
                data.type = MESSAGE_TYPE.image;
            }

            if(props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeVideo) {
                data.type = MESSAGE_TYPE.video;
            }
        }else {
            data.message = generateBody();
        }

        data.fileAsset = _fileAsset;
    }

    let runSendMessage = () => {
        let _conversationData = {...conversationData};
        let _forwardMessage = {...props.forwardMessage.message};

        let dataChat = {
            channelID: _conversationData.channel.value,
            phone: _conversationData.recipient.contact.number.replace("+", ""),
            messageType: messageType(),
            body: generateBody(),
            filename: _forwardMessage.data.fileName,
            caption: generateCaption(),
            topicID: parseInt(_conversationData.topic.value)
        }

        StartConversationServices.postSendMessageWhatsapp(props.orgIDProps, dataChat, (response) => {
            let dataResult = response.dataResult;
            let dataResultData = dataResult.data;

            if(dataResult.status === 200) {
                if(dataResultData.success) {
                    tapCoreRoomListManager.getRoomByXcID(dataResultData.case.tapTalkXCRoomID, {
                        onSuccess: (response) => {
                            props.setToggleMineCase(true);

                            if(props.activeRoom === null) {
                                props.onClickRoomListProps(response.room, dataResultData.case);
                            }else {
                                if(props.activeRoom.roomID !== response.room.roomID) {
                                    props.onClickRoomListProps(response.room, dataResultData.case);
                                }
                            }
                            let _modalStartConversationBalance = {...props.modalStartConversationBalance};
                            _modalStartConversationBalance.confirmation.show = false;
                            props.setChatRoomForwardBalance(_modalStartConversationBalance);

                            doToast("Successfully send the message");
                            resetValue();
                        },
                        onError: (errorCode, errorMessage) => {
                            let _modalStartConversationBalance = {...props.modalStartConversationBalance};
                            _modalStartConversationBalance.confirmation.show = false;
                            props.setChatRoomForwardBalance(_modalStartConversationBalance);
                            
                            console.log(errorCode, errorMessage);
                            doToast(errorMessage, "fail");
                        }
                    })
                }else {
                    if(dataResultData.reason === FAIL_SEND_MESSAGE_REASON.insufficient_balance) {
                        let _modalStartConversationBalance = {...props.modalStartConversationBalance};
                        _modalStartConversationBalance.confirmation.show = false;
                        _modalStartConversationBalance.confirmation.isLoading = false;
                        _modalStartConversationBalance.topupBalance.show = true;
                        
                        props.setChatRoomForwardBalance(_modalStartConversationBalance);
                    }else {
                        doToast(dataResultData.message, "fail");
                    }

                    props.toggleModalSendingProps(false);
                }

                props.clearStartConversation();
            }else {
                doToast(dataResult.error.message, "fail");
            }

            setIsLoadingWhatsappStatus(false);
        })
    }

    let onSubmitNewConversation = (isSendNow = "no") => {
        setIsLoadingWhatsappStatus(true);
        let _conversationData = {...conversationData};

        let data = {
            channelID: _conversationData.channel.value,
            phone: _conversationData.recipient.contact.number.replace("+", ""),
        }

        StartConversationServices.postCheckStatusWhatsapp(props.orgIDProps, data, (response) => {
            let dataResult = response.dataResult;
            let dataResultData = dataResult.data;
            let _startConversation = {...props.startConversation};
            let myAgentAccountID = props.myAgentData.account.id;

            if(dataResult.status === 200) {
                if(!dataResultData.canSend && !dataResultData.isValidNumber) {
                    doToast("Can't send, phone number is not valid", "fail");
                    setIsLoadingWhatsappStatus(false);
                }

                props.clearStartConversationBa();

                if(
                    dataResultData.openCase.id === 0 && 
                    (!props.topicListProps[dataResultData.openCase.topicID] && dataResultData.openCase.topicID !== 0) && dataResultData.canSend
                ) {
                    _startConversation.openCase = {
                        topicID: _conversationData.topic.value,
                        agentAccountID: ""
                    };
                    _startConversation.isForward = true;

                    generateFileAssetAndIncludeInfo(_conversationData);

                    _startConversation.conversation = _conversationData;
                    onCloseClick();
                    props.setStartConversation(_startConversation);
                }
                
                if(dataResultData.openCase.id !== 0 || isSendNow === "no") {
                    // doToast("Recipient has ongoing case", "fail");
                    if(!props.topicListProps[dataResultData.openCase.topicID]) {
                        _startConversation.openCase = dataResultData.openCase;

                        generateFileAssetAndIncludeInfo(_conversationData);

                        _startConversation.conversation = _conversationData;
                        _startConversation.isForward = true;
                        props.setStartConversation(_startConversation);
                        onCloseClick();
                        setIsLoadingWhatsappStatus(false);
                    }else {
                        tapCoreRoomListManager.getRoomByXcID(dataResultData.openCase.tapTalkXCRoomID, {
                            onSuccess: (response) => {
                                let goToChatRoom = () => {
                                    if(props.activeRoom === null) {
                                        props.onClickRoomListProps(response.room, dataResultData.openCase, false);
                                    }else {
                                        if(props.activeRoom.roomID !== response.room.roomID) {
                                            props.onClickRoomListProps(response.room, dataResultData.openCase, false);
                                        }
                                    }
                                }
                                
                                generateFileAssetAndIncludeInfo(_conversationData);   
                                _startConversation.conversation = _conversationData;
                                _startConversation.openCase = dataResultData.openCase;
                                _startConversation.isForward = true;
                                props.setStartConversation(_startConversation);
                                
                                if(dataResultData.openCase.agentAccountID === 0) {
                                    props.setActiveCasePanel("open");
                                    props.setToggleMineCase(false);
                                    goToChatRoom();
                                }else if(dataResultData.openCase.agentAccountID === myAgentAccountID) {
                                    props.setActiveCasePanel("open");
                                    props.setToggleMineCase(true);
                                    goToChatRoom();
                                }else {
                                    if(props.showOtherCase.configShow) {
                                        props.setActiveCasePanel("other");
                                        goToChatRoom();
                                    }else {
                                        let _showOther = {...props.showOtherCase};
                                        _showOther.modalWarningHideOther = true;
                                        _showOther.case = dataResultData.openCase;
                                        props.setShowOtherCase(_showOther);
                                    }
                                }

                                onCloseClick();
                                setIsLoadingWhatsappStatus(false);
                            },
                            onError: (errorCode, errorMessage) => {
                                console.log(errorCode, errorMessage);
                                doToast(errorMessage, "fail");
                                setIsLoadingWhatsappStatus(false);
                            }
                        })
                    }
                }

                if(
                    props.topicListProps[conversationData.topic.value] && 
                    dataResultData.canSend && 
                    dataResultData.isValidNumber &&
                    dataResultData.openCase.id === 0
                ) {
                    onCloseClick();
                    // if(isSendNow === "check") {
                    //     getWhatsappPriceList();
                    // }else {
                    //     runSendMessage();
                    // }

                    runSendMessage();
                }
            }else {
               doToast(dataResult.error.message, "fail");
               
               setIsLoadingWhatsappStatus(false);
            }
        })
    }
    
    useEffect(() => {
        // if(props.isShowModalStartConversationProps) {
            getChannelList()
        // }
    }, [props.isShowModalStartConversationProps])

    // useEffect(() => {
    //     console.log(conversationData)
    // }, [conversationData])

    let isDisabledSubmit = () => {
        let _conversationData = {...conversationData};
        let isDisabled = false;
        // let phoneLength = (conversationData.recipient.country.countryCodeNumber + conversationData.recipient.number).length;
        
        if(_conversationData.channel.value === "") {
            isDisabled = true;
        }

        if(conversationData.recipient.contact.number === "") {
            isDisabled = true;
        }

        if(conversationData.topic.value === "") {
            isDisabled = true;
        }
        
        // if(_conversationData.fileAsset.fileData) {
        //     if(_conversationData.fileAsset.fileURL === "") {
        //         isDisabled = true;
        //     }
        // }else {
        //     if(_conversationData.message === "") {
        //         isDisabled = true;
        //     }
        // }

        return isDisabled;
    }

    let disabledAddPhoneNumber = () => {
        let isDisabled = false;
        let phoneLength = (conversationData.recipient.country.countryCodeNumber + conversationData.recipient.number).replaceAll(" ", "").length;
        
        if(conversationData.recipient.number[0] === "0") {
            isDisabled = true;
        }

        if(phoneLength < 7 || phoneLength > 15) {
            isDisabled = true;
        }

        if(!REG_NUMERIC.test(conversationData.recipient.number.replaceAll(" ", ""))) {
            isDisabled = true;
        }

        return isDisabled;
    }

    let onChangeSearchContact = (e) => {
        runGetContactList(e.target.value)
        setContactSearchVal(e.target.value);
    }

    let runToggleAddPhoneForm = () => {
        let _conversationData = {...conversationData};
        setContactSearchVal("");
        setToggleAddPhoneForm(!toggleAddPhoneForm);
        _conversationData.recipient = {
            "country": {
                "id": 1,
                "countryCodeNumber": "62",
                "countryCode": "IDN",
                "countryName": "Indonesia",
                "countryFlag": "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png",
                "countryLabel": "Indonesia (+62)"
            },
            "number": "",
            "contact": {
                "name": "",
                "number": ""
            }
        };

        setConversationData(_conversationData);
        setErrorFieldNumber("");
    }

    let submitAddPhoneNumber = () => {
        let _conversationData = {...conversationData};
        _conversationData.recipient.contact = {
            name: "",
            alias: "",
            number: `+${_conversationData.recipient.country.countryCodeNumber}${_conversationData.recipient.number.replaceAll(" ", "")}`,
            maskedPhone: "",
            isFromAdd: true
        }

        setConversationData(_conversationData);
        // runToggleAddPhoneForm();
        setToggleAddPhoneForm(!toggleAddPhoneForm);
    }

    let clearContactNumber = () => {
        let _conversationData = {...conversationData};
        _conversationData.recipient.contact = {
            name: "",
            number: ""
        }

        setConversationData(_conversationData);
    }

    let runGetContactList = (search) => {
        let data = {
            search: search,
            pageNumber: 1,
            pageSize: 1000
        }
        
        UserContactServices.getContactWhatsapp(props.orgIDProps, data, (response) => {
            if(response.dataResult.status === 200) {
                let dataResult = response.dataResult;
                let newData = {
                    search: search,
                    list: dataResult.data.users,
                    time: new Date().valueOf()
                }

                setTemporaryContactList(newData);
                // setContactList(dataResult.data.users);
            }
        });
    }

    let onClickContact = (contact) => {
        let _conversationData = {...conversationData};
        _conversationData.recipient.contact.name = contact.fullName;
        _conversationData.recipient.contact.alias = contact.alias;
        _conversationData.recipient.contact.number = "+"+contact.phone;
        _conversationData.recipient.contact.maskedPhone = "+"+(contact.maskedPhone ? contact.maskedPhone : contact.phone);

        setContactList([]);
        setContactSearchVal("");
        setConversationData(_conversationData);
    }

    let onSelectTopic = (value) => {
        let _conversationData = {...conversationData};
        _conversationData.topic = value;
        setConversationData(_conversationData);
    };

    let getListTopic = () => {
        let list = [];
        let _conversationData = {...conversationData};
        if(_conversationData.channel.topics) {
            Object.keys(props.topicListProps).map(v => {
                if(_conversationData.channel.topics.findIndex(e => e.id === parseInt(v)) !== -1) {
                    list.push(props.topicListProps[v]);
                }

                return null;
            })
        }

        return list;
    }

    let toggleModalImageVideoPreview = () => {
        setShowModalImageVideoPreview(!showModalImageVideoPreview);
    }

    let renderModalImageVideoDetail = () => {
        return (
          <Modal className="modal-image-video-preview" toggle={toggleModalImageVideoPreview} isOpen={showModalImageVideoPreview}>
            <ModalBody>
                <FiX onClick={toggleModalImageVideoPreview} />

                {props.forwardMessage.message &&
                    <>
                        {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeImage &&
                            <img src={props.forwardMessage.message.data.fileURL ? props.forwardMessage.message.data.fileURL : props.forwardMessage.message.data.url} alt="" />
                        }

                        {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeVideo &&
                            <video src={props.forwardMessage.message.data.fileURL ? props.forwardMessage.message.data.fileURL : props.forwardMessage.message.data.url} controls />
                        }
                    </>
                }
            </ModalBody>
          </Modal>
        )
      }

    return (
        <>
            <div className={`modal-forward-message ${props.forwardMessage.isShow ? "active-modal-forward-message" : ""}`}>
                <div className={`modal-body ${props.forwardMessage.isShow ? "action-modal-body-new-conversation" : ""}`}>
                    <div onClick={onCloseClick} className="close-modal-start-conversation">
                        <FiX />
                    </div>

                    {!toggleAddPhoneForm ?
                        // main form
                        <>
                        <div className="new-conversation-wrapper">
                            <Scrollbars autoHideTimeout={500}
                                renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
                            >
                                <div className="new-conversation-container">
                                    <div className="new-conversation-title">
                                        <img src={ForwardIcon} alt="" className='' />

                                        <b>Forward Message</b>
                                        <p>
                                            Forward a message to a single recipient through one of your active channels.
                                        </p>
                                    </div>

                                    <div className="form-content-wrapper">
                                        <label><b>Channel Type</b></label>

                                        <div className='channel-type-icon-wrapper'>
                                            <img src={TAPLIVE_MEDIUM_LOGO[TAPLIVE_MEDIUM.whatsapp]} alt="" />
                                            WhatsApp SME
                                        </div>
                                    </div>

                                    <div className="form-content-wrapper">
                                        <label><b>Channel</b></label>
                                    </div>
                                    
                                    <div className='custom-select2-wrapper'>
                                        <Select 
                                            options={channelList} 
                                            placeholder="Select channel" 
                                            onChange={(e) => onChangeChannel(e)}
                                            styles={styleSelectChannel()}
                                            value={channelList.filter(obj => obj.value === conversationData.channel.value)}
                                            blurInputOnSelect={true}
                                        />
                                    </div>

                                    {/* <div className="form-content-wrapper">
                                        <div className="form-label">
                                            <p><b>Country</b></p>
                                            <p><b>Phone Number</b></p>
                                        </div>
                                    </div> */}

                                    {/* <div className="phone-number-form">
                                        <PhoneCodePicker 
                                            countryListProps={countryList}
                                            indexPhoneCodePickerProps={0}
                                            countryProps={conversationData.recipient.country}
                                            onChangeCountryCodeProps={onChangeCountryCode}
                                            isMultipleProps={false}
                                        />
                                                                    
                                        <div className="phone-wrapper">
                                            <input 
                                                type="text" 
                                                placeholder="ex: 1234 567 890" 
                                                onChange={(e) => onChangeConversationMessage(e)} 
                                                value={conversationData.recipient.number}
                                                onBlur={() => onBlurAction()}
                                                id="phoneNumber"
                                                maxLength="15"
                                            />

                                            {errorFieldNumber !== "" &&
                                                <p>
                                                    <FiAlertCircle />
                                                    <b>{errorFieldNumber}</b>
                                                </p>
                                            }
                                        </div>
                                    </div> */}

                                    <div className="start-conversation-contact-wrapper">
                                        <div className="form-content-wrapper">
                                            <label><b>Recipient</b></label>

                                            {conversationData.recipient.contact.number === "" &&
                                                <div className="light-orange-button add-by-phone-number" onClick={runToggleAddPhoneForm}>
                                                    <FiPlus />
                                                    Add by Phone Number
                                                </div>
                                            }
                                        </div>

                                        <div className="search-contact-wrapper">
                                            {conversationData.recipient.contact.number === "" &&
                                                <>
                                                    <input 
                                                        type="text" 
                                                        onChange={(e) => onChangeSearchContact(e)} 
                                                        value={contactSearchVal}
                                                        placeholder="Search for a contact"
                                                    />
                                                    <FiSearch />
                                                </>
                                            }
                                            
                                            {contactSearchVal !== "" &&
                                                <div className="dropdown-contact-list">
                                                    {contactList.length === 0 ?
                                                        <div className="option-contact no-contact-found">
                                                            No Contact Found
                                                        </div>
                                                        :
                                                        contactList.map((value, index) => {
                                                            return (
                                                                <div 
                                                                    className={`option-contact ${conversationData.recipient.contact.number === value.phone ? "selected-number-active" : ""}`} 
                                                                    onClick={() => onClickContact(value)} 
                                                                    key={`contact-${index}`}
                                                                >
                                                                    {value.alias === "" ? value.fullName : `${value.alias}(${value.fullName})`}
                                                                    <span>+{HelperInput.phoneBeautify(value.maskedPhone ? value.maskedPhone : value.phone)}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                        
                                        {conversationData.recipient.contact.number !== "" &&
                                            <div className="selected-contact">
                                                {conversationData.recipient.contact.isFromAdd ?
                                                    <FiPhone />
                                                    :
                                                    <FiUser />
                                                }
                                                <b>{conversationData.recipient.contact.alias === "" ? conversationData.recipient.contact.name : `${conversationData.recipient.contact.alias}(${conversationData.recipient.contact.name})`} {(HelperInput.phoneBeautify(conversationData.recipient.contact.number).substr(0, 1) !== "+" ? "+": "")+HelperInput.phoneBeautify(conversationData.recipient.contact.maskedPhone ? conversationData.recipient.contact.maskedPhone : conversationData.recipient.contact.number)}</b>

                                                <div className="remove-contact" onClick={clearContactNumber}>
                                                    <FiX />
                                                </div>
                                            </div>
                                        }

                                        {/* <div className="add-phone-number-info">
                                            <FiAlertCircle />
                                            <b>User contact’s with case history in the selected channel will be displayed.</b>
                                        </div> */}
                                    </div>

                                    <div className="form-content-wrapper">
                                        <label>
                                            <b>Topic</b>
                                        </label>

                                        <CustomSelectOption
                                            optionListProps={getListTopic()}
                                            valueDropdownProps={conversationData.topic}
                                            placeholderProps={conversationData.channel.value !== "" ? 'Select topic' : 'Select channel first'}
                                            onClickDropDownListOptionProps={onSelectTopic}
                                            specificClassNameProps="custom-select-topic"
                                            isDisabled={conversationData.channel.value === ""}
                                        />
                                    </div>

                                    <div className="form-content-wrapper forward-message-content-wrapper">
                                        <label><b>Message</b></label>
                                        
                                        <div className='include-forward-info'>
                                            <label className="custom-switch-wrapper">
                                                <input disabled={isLoadingWhatsappStatus} type="checkbox" checked={includeInfo}  onChange={(e) => onClickInclude(e)} />
                                                <span className="custom-switch round"></span>
                                            </label>
                                            <b>Include Forwarded Info</b>
                                        </div>

                                        <div className='forward-message-content-box'>
                                            {props.forwardMessage.message &&
                                                <>
                                                    {(
                                                        props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeText ||
                                                        props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLink
                                                    ) &&
                                                        <>
                                                            {includeInfo &&
                                                                <div className="include-info-text">
                                                                    <p>
                                                                        Forwarded from {props.forwardMessage.message.user.fullname}
                                                                    </p>

                                                                    <p>
                                                                        Case ID: #{props.forwardMessage.case.stringID}
                                                                    </p>
                                                                </div>
                                                            }

                                                            <p 
                                                                className='body-text'
                                                                dangerouslySetInnerHTML={{__html: HelperChat.lineBreakChatRoom(props.forwardMessage.message.body)}}
                                                            />
                                                        </>
                                                    }
                                                    
                                                    {(
                                                        props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeImage ||
                                                        props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeVideo ||
                                                        props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeFile
                                                    ) &&
                                                        <>
                                                            {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeImage &&
                                                                <>
                                                                    <div className='forward-image-video-outer-wrapper'>
                                                                        <div className='forward-image-video-wrapper'>
                                                                            <img src={props.forwardMessage.message.data.fileURL ? props.forwardMessage.message.data.fileURL : props.forwardMessage.message.data.url} alt="" />

                                                                            <div className='forward-hover-wrapper' onClick={toggleModalImageVideoPreview}>
                                                                                <FiEye />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {includeInfo &&
                                                                        <div className="include-info-text include-info-image-video-file">
                                                                            <p>
                                                                                Forwarded from {props.forwardMessage.message.user.fullname}
                                                                            </p>

                                                                            <p>
                                                                                Case ID: #{props.forwardMessage.case.stringID}
                                                                            </p>
                                                                        </div>
                                                                    }

                                                                    {props.forwardMessage.message.data.caption !== "" &&
                                                                        <p 
                                                                            className='caption-text'
                                                                            dangerouslySetInnerHTML={{__html: HelperChat.lineBreakChatRoom(props.forwardMessage.message.data.caption)}}
                                                                        />
                                                                    }
                                                                </>
                                                            }
                                                            
                                                            {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeVideo &&
                                                                <>
                                                                    <div className='forward-image-video-outer-wrapper'>
                                                                        <div className='forward-image-video-wrapper'>
                                                                            <video src={props.forwardMessage.message.data.fileURL ? props.forwardMessage.message.data.fileURL : props.forwardMessage.message.data.url} />

                                                                            <div className='forward-hover-wrapper' onClick={toggleModalImageVideoPreview}>
                                                                                <FiEye />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {includeInfo &&
                                                                        <div className="include-info-text include-info-image-video-file">
                                                                            <p>
                                                                                Forwarded from {props.forwardMessage.message.user.fullname}  
                                                                            </p>

                                                                            <p>
                                                                                Case ID: #{props.forwardMessage.case.stringID}
                                                                            </p>
                                                                        </div>
                                                                    }

                                                                    {props.forwardMessage.message.data.caption !== "" &&
                                                                        <p 
                                                                            className='caption-text'
                                                                            dangerouslySetInnerHTML={{__html: HelperChat.lineBreakChatRoom(props.forwardMessage.message.data.caption)}}
                                                                        />
                                                                    }
                                                                </>
                                                            }

                                                            {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeFile &&
                                                                <>
                                                                    <div className='forward-file-wrapper'>
                                                                        <FiFile />

                                                                        <p className=''>
                                                                            {props.forwardMessage.message.data.fileName}
                                                                        </p>
                                                                    </div>

                                                                    {includeInfo &&
                                                                        <div className="include-info-text include-info-image-video-file">
                                                                            <p>
                                                                                Forwarded from {props.forwardMessage.message.user.fullname}  
                                                                            </p>

                                                                            <p>
                                                                                Case ID: #{props.forwardMessage.case.stringID}
                                                                            </p>
                                                                        </div>
                                                                    }

                                                                    {props.forwardMessage.message.data.caption !== "" &&
                                                                        <p 
                                                                            className='caption-text'
                                                                            dangerouslySetInnerHTML={{__html: HelperChat.lineBreakChatRoom(props.forwardMessage.message.data.caption)}}
                                                                        />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Scrollbars>
                        </div>
                
                        <div className="send-message-button">
                            {!isLoadingWhatsappStatus ?
                                <ButtonWithLoadingOrIcon 
                                    // onClickAction={getWhatsappPriceList} 
                                    // onClickAction={() => onSubmitNewConversation("check")}
                                    onClickAction={() => onSubmitNewConversation("send")}
                                    isDisabled={isDisabledSubmit()}
                                    className="orange-button main-button-48"
                                    text="Send Message"
                                    position="left"
                                    icon={{
                                        type: "svg",
                                        src: FiSend
                                    }}
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    isLoading
                                    loadingColor="gray"
                                    isDisabled
                                    className="orange-button main-button-48"
                                    text="Send Message"
                                    position="left"
                                />
                            }
                        </div>
                        </>
                        // main form
                        :
                        // add phone form
                        <>
                        <div className="new-conversation-wrapper">
                            <div className="new-conversation-container">
                                <BackButton 
                                    text="Back to Forward Message" 
                                    onClick={runToggleAddPhoneForm} 
                                />

                                <div className="new-conversation-title">
                                    <b>Add by Phone Number</b>
                                </div>

                                <div className="form-content-wrapper">
                                    <div className="form-label form-label-phone">
                                        <p><b>Country</b></p>
                                        <p><b>Phone Number</b></p>
                                    </div>
                                </div>

                                <div className="phone-number-form">
                                    <PhoneCodePicker 
                                        countryListProps={countryList}
                                        indexPhoneCodePickerProps={0}
                                        countryProps={conversationData.recipient.country}
                                        onChangeCountryCodeProps={onChangeCountryCode}
                                        isMultipleProps={false}
                                    />
                                                                
                                    <div className="phone-wrapper">
                                        <input 
                                            type="text" 
                                            placeholder="ex: 1234 567 890" 
                                            onChange={(e) => onChangeConversationMessage(e)} 
                                            value={conversationData.recipient.number}
                                            onBlur={() => onBlurAction()}
                                            id="phoneNumber"
                                            maxLength="20"
                                            disabled={isLoadingWhatsappStatus}
                                        />

                                        {errorFieldNumber !== "" &&
                                            <p>
                                                <FiAlertCircle />
                                                <b>{errorFieldNumber}</b>
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="send-message-button">
                            <ButtonWithLoadingOrIcon 
                                isDisabled={disabledAddPhoneNumber()} 
                                onClickAction={() => submitAddPhoneNumber()}
                                className="orange-button main-button-48"
                                text="Add Phone Number"
                                position="left"
                            />
                        </div>
                        </>
                        // add phone form
                    }
                </div>
            </div>
            
            {renderModalImageVideoDetail()}
        </>
    );
}

const mapStateToProps = state => ({
    countryList: state.countryList,
    startConversation: state.startConversation,
    toggleMineCase: state.toggleMineCase,
    activeRoom: state.activeRoom,
    priceList: state.priceList,
    modalStartConversationBalance: state.modalStartConversationBalance,
    showOtherCase: state.showOtherCase,
    forwardMessage: state.forwardMessage,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setActiveCasePanel,
    setStartConversation,
    clearStartConversation,
    clearStartConversationBa,
    setToggleMineCase,
    setModalStartConversationBalance,
    clearModalStartConversationBalance,
    setShowOtherCase,
    setForwardMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomForward);