import React from "react";
import "./Loading.css";

const Loading = ({ color, className = "" }) => (
  <div className={`LoadingRoll_1234fed ${className}`}>
    <div
      className={`
        lds-ring 
        ${color ? `${color}-lds-ring` : ""} 
        centered-lds-ring
      `}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loading;
