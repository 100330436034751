import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./RequestMessageTemplate.scss";
import { Tooltip } from "reactstrap";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IconHelpCircle from "../../../../../assets/img/chatroom/user-info-tab/icon-help-circle.svg";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { FiEye, FiPlus } from "react-icons/fi";

let RequestMessageTemplate = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [showTooltip, setShowTooltip] = useState(false);

  let toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  let [requestMessageTemplates, setRequestMessageTemplates] = useState([]);
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetTemplateData, setIsLoadingGetTemplateData] = useState(
    false
  );

  // let [pageDisplay, setPageDisplay] = useState(1);
  // let [listPage, setListPage] = useState(20);
  // let [listPageVal, setListPageVal] = useState({
  //   label: "20",
  //   value: 20,
  // });

  // let listPageOption = [
  //   {
  //     label: "20",
  //     value: 20,
  //   },
  //   {
  //     label: "50",
  //     value: 50,
  //   },
  //   {
  //     label: "100",
  //     value: 100,
  //   },
  // ];

  // let goToAdd = () => {
  //   history.push(`/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}/create-message-template`)
  // }

  // let onSelectListPage = (val) => {
  //   setListPageVal(val);
  //   setListPage(val.value);
  //   setPageDisplay(1);
  // };

  // let handleChangePage = (page) => {
  //   setPageDisplay(page);
  // };

  let checkID = (match) => {
    let { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
    // let data = [
    //   {
    //     "id": 1,
    //     "name": "greetings_v1",
    //     "categoryCode": "ISSUE_RESOLUTION",
    //     "categoryName": "Issue Resolution",
    //     "templateType": "text",
    //     "status": "new",
    //     "statusText": "New",
    //     "approvedTime": 0,
    //     "createdTime": 1611496973940,
    //     "updatedTime": 0,
    //     "deletedTime": 0
    //   }
    // ];
    // setRequestMessageTemplates(data);
  }, []);

  useEffect(() => {
    if (orgID) {
      getRequestList();
    }
  }, [orgID]);

  let getRequestList = () => {
    setIsLoadingGetTemplateData(true);

    let data = {
      channelID: Number(match.params.id),
      status: "",
    };
    
    IntegrationWhatsappBusinessServices.getRequestList(
      orgID,
      data,
      (response) => {
        let _retrieveDataError = { ...retrieveDataError };

        if (response.dataResult.error.message === "") {
          setRequestMessageTemplates(response.dataResult.data.templates);
        } else {
          _retrieveDataError.message = response.dataResult.error.message;
          _retrieveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retrieveDataError);
        }
        setIsLoadingGetTemplateData(false);
      }
    );
  }

  return (
    <div className="request-message-template-whatsapp sectionWrap">
      <div className="request-message-template-whatsapp-top">
        <b>Message Template Requests</b>
        <NavLink to={`/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}/create-message-template`}>
          <ButtonWithLoadingOrIcon
            icon={{
              type: "svg",
              src: FiPlus,
            }}
            className="no-fill-button request-message-template-whatsapp-top-button main-button-40"
            text="Create Template"
            position="left"
          />
        </NavLink>

      </div>
      {/* </SectionWrap> */}
      {isLoadingGetTemplateData || retrieveDataError.code ? (
        <div className="message-template-whatsapp-loading-wrapper">
          <RetrieveDataLoading
            isLoading={isLoadingGetTemplateData}
            errorMessage={retrieveDataError.message}
          />
        </div>
      ) : (
        <div className="request-message-template-table-wrapper">
          <div className="custom-table">
            <table className="custom-table-content table-request-message-template-list">
              <thead>
                <tr>
                  <td className="col_td_left">Name</td>
                  <td className="col_td_center">Template Type</td>
                  <td className="col_td_center">
                    Status
                    <img
                      src={IconHelpCircle}
                      className="tooltip-icon"
                      id="tooltip-status-request-message-template"
                      alt=""
                    />
                    <Tooltip
                      className="tooltip-content"
                      placement="bottom"
                      isOpen={showTooltip}
                      target="tooltip-status-request-message-template"
                      toggle={toggleTooltip}
                    >
                      Shows what step of the process your request is at e.g New,
                      On Process, Rejected and Resubmit.
                    </Tooltip>
                  </td>
                  <td>
                    {/* <CustomSelectOption
                      optionListProps={listPageOption}
                      valueDropdownProps={listPageVal}
                      onClickDropDownListOptionProps={onSelectListPage}
                    /> */}
                  </td>
                </tr>
              </thead>
              <tbody>
                {requestMessageTemplates.length > 0 ? (
                  requestMessageTemplates.map((item, idx) => {
                    return (
                      <tr key={`cost-${idx}`}>
                        <td className="">
                          <p className="">{item.name}</p>
                        </td>
                        <td className="">
                          <p>
                            {item.templateType.charAt(0).toUpperCase() +
                              item.templateType.slice(1)}
                          </p>
                        </td>
                        <td className="">
                          <p>{item.statusText}</p>
                        </td>
                        <td className="col-view">
                          <NavLink
                            to={{
                              pathname: `/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}/request-message-template/${item.id}`,
                              data: item,
                            }}
                          >
                            <FiEye />
                            View
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="no-data-row">
                    <td
                      colSpan="8"
                      className="no-data-column"
                      style={{ textAlign: "center" }}
                    >
                      <b className="no-result-found">
                        No message template found.
                      </b>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="pagination-wrapper">
            {requestMessageTemplates.length > 0 && (
              <Pagination
                total={requestMessageTemplates.length}
                page={pageDisplay}
                listPerPage={listPage}
                step={1}
                onChangePage={handleChangePage}
              />
            )}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default RequestMessageTemplate;
