import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./CustomSelectOptionWithLabel.scss";
import { FiChevronDown } from "react-icons/fi";
import Loading from "../loading/Loading";

const CustomSelectOptionWithLabel = props => {
    let [isDropdownActive, setIsDropdownActive] = useState(false);
    let isSelectingMultiple = false;

    let toggleDropdownOption = () => {
        if (isSelectingMultiple) {
            isSelectingMultiple = false;
            return;
        }
        setIsDropdownActive(!isDropdownActive);
    }

    let onClickOptionList = (value) => {
        if (!props.isMultipleSelect) {
            toggleDropdownOption();
        }
        else {
            isSelectingMultiple = true;
        }
        props.onClickDropDownListOptionProps(value);
    }

    return (
        <div
            className={`
                ${props.specificClassNameProps} 
                main-custom-select-option-with-label-wrapper 
                ${isDropdownActive ? "active-dropdown" : ""}
                ${props._className}
            `}
        >
            <Dropdown
                isOpen={isDropdownActive}
                toggle={() => {
                    !props.isLoading && toggleDropdownOption();
                }}
            >
                <DropdownToggle
                    className={`${props.dropdownError && props.dropdownError !== "" ? "border-red" : ""} custom-select-option-value-wrapper`} disabled={props.isDisabled || props.isLoading}
                    onClick={() => props.isMultipleSelect && isDropdownActive && setIsDropdownActive(false)}
                >
                    {(props.valueDropdownProps.label === "" && props.valueDropdownProps.badgeText === "") ?
                        <p className="custom-select-option-placeholder">
                            {props.placeholderProps}
                        </p>
                        :
                        <p className="custom-select-option-value">
                            {props.isBold ?
                                <>
                                    {props.valueDropdownProps.logo ?
                                        <img src={props.valueDropdownProps.logo} className="dropdown-logo-val" alt="" />
                                        :
                                        ""
                                    }

                                    <div className={`option-badge ${props.valueDropdownProps.badgeClass}`}>
                                        <b>{props.valueDropdownProps.badgeText}</b>
                                    </div>

                                    <b>{props.valueDropdownProps.label}</b>
                                </>
                                :
                                <>
                                    {props.valueDropdownProps.logo ?
                                        <img src={props.valueDropdownProps.logo} className="dropdown-logo-val" alt="" />
                                        :
                                        ""
                                    }

                                    <div className={`option-badge ${props.valueDropdownProps.badgeClass}`}>
                                        <b>{props.valueDropdownProps.badgeText}</b>
                                    </div>

                                    {props.valueDropdownProps.label}
                                </>
                            }
                        </p>
                    }

                    {props.isLoading ?
                        <Loading className="loading-custom-select" />
                        :
                        <FiChevronDown className={`icon-arrow`} />
                    }
                </DropdownToggle>

                <DropdownMenu className="dropdown-option-wrapper">
                    {props.optionListProps && props.optionListProps.length > 0 ?
                        props.optionListProps.map((val, key) => {
                            if (!props.condition || props.condition(val.id)) {
                                return (
                                    <DropdownItem
                                        className={`
                                        ${((props.valueDropdownProps.array && props.valueDropdownProps.array.includes(val.label)) ||
                                                props.valueDropdownProps.value === val.value) ?
                                                props.isMultipleSelect ? "active-multiple" : "active-value"
                                                :
                                                props.isMultipleSelect ? "inactive-multiple" : ""
                                            }

                                        channel-option-with-badge
                                    `}
                                        onClick={() => {
                                            onClickOptionList(val)
                                        }}
                                        key={`option-list-${props.placeholderProps}-${key}`}
                                    >
                                        {val.logo ?
                                            <img src={val.logo} className="dropdown-logo-option" alt="" />
                                            :
                                            ""
                                        }

                                        <div className={`option-badge ${val.badgeClass}`}>
                                            <b>{val.badgeText}</b>
                                        </div>

                                        <p className={""}>
                                            {val.label}
                                        </p>

                                    </DropdownItem>
                                )
                            }

                            return null;
                        })
                        :
                        <DropdownItem className="no-data-option">
                            No Options
                        </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default CustomSelectOptionWithLabel;