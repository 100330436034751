const INITIAL_STATE = {
  show: false,
  topicID: false,
  lastSubmit: false,
  topicValue: false
};

let reduxReducerActiveHandoverCase = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_HANDOVER_CASE':
        return action.activeHandoverCase;
      case 'CLEAR_ACTIVE_HANDOVER_CASE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerActiveHandoverCase;