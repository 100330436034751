import React, { useEffect } from "react";
import "./IntegrationAndroid.scss";
import AndroidIcon from "../../../assets/img/integration/logo-android.svg";
import IconArrow from "../../../assets/img/integration/top-right.svg";
import Integrations from "../Integrations";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import {
    checkID,
    // doToast
  } from "../../../helper/HelperGeneral";

const IntegrationAndroid = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = React.useState(false);

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };
    
    useEffect(() => {
        doMount();
    }, [])

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <Integrations activeIntegrationChannel="android" parentProps={props} orgIDProps={orgID} />

                <div className="integration-main-container">
                    <img className="icon-integration-content" src={AndroidIcon} alt="" />
                    <br />
                    <b>Please read our Android Integration Documentation</b>

                    <br />
                    
                    <a href="https://docs.taptalk.io/android/get-started-android" target="_blank" rel="noopener noreferrer">
                        <button className="orange-button button-email">
                            <img src={IconArrow} alt="" className="arrow-icon" />
                            Android Integration Documentation
                        </button>
                    </a>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationAndroid;