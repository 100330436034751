import React, { useState, useEffect } from "react";
import "./IntegrationWhatsappAuthenticateNow.scss";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import { checkID, doToast } from "../../../../helper/HelperGeneral";
import "react-toastify/dist/ReactToastify.css";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import { FiRotateCcw, FiAlertTriangle } from "react-icons/fi";
import authenticateIconLight from "../.././../../assets/img/authenticate-icon-light.svg";
import iconWhatsappBrowser from "../.././../../assets/img/icon-whatsapp-browser.svg";
import iconWhatsappSetting from "../.././../../assets/img/icon-whatsapp-more.svg";
import IntegrationWhatsappServices from "../../../../services/integration/IntegrationWhatsappServices";

const IntegrationWhatsappAuthenticateNow = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [qrImage, setQrImage] = useState(null);
    let [isWaitingQrImage, setIsWaitingQrImage] = useState(true);
    let [isReloadQrActive, setIsReloadQrActive] = useState(false);
    let [lastLoadQrImage, setLastLoadQrImage] = useState(null);
    let [timeIntervalReloadDetail, setTimeIntervalReloadDetail] = useState(null);
    let [isIntervalGetDetailExist, setIsIntervalGetDetailExist] = useState(false);

    const back = () => history.push(`/o/${orgID}/integration/whatsapp-sme/detail/${match.params.accountID}`);

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
            getQr(paramID, match.params.accountID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getWhatsappAccountDetail = (orgID, accountID) => {
        IntegrationWhatsappServices.getDetail(orgID, accountID, (response) => {
            if(response.dataResult.status === 200) {
                setTimeout(() => {
                    setTimeIntervalReloadDetail(new Date().valueOf());
                }, 3000);

                if(response.dataResult.data.channel.channelStatus === "authenticated") {
                    history.push(`/o/${orgID}/integration/whatsapp-sme/detail/${accountID}`)
                }else {
                    if((new Date().valueOf() - lastLoadQrImage) > 17999) {
                        setIsReloadQrActive(true);
                    }
                }
            }else {
                doToast(response.dataResult.error.message, "fail"); 
            }
        })
    }

    let getQr = (orgID, accountID) => {
        setIsWaitingQrImage(true);

        IntegrationWhatsappServices.getQR(orgID, accountID, (response) => {
            setIsWaitingQrImage(false);

            if(response.dataResult.status === 200) {
                setLastLoadQrImage(new Date().valueOf());
                setIsReloadQrActive(false);
                setQrImage(`data:image/png;base64, ${response.dataResult.value}`);

                if(!isIntervalGetDetailExist) {
                    setIsIntervalGetDetailExist(true);

                    setTimeout(() => {
                        setTimeIntervalReloadDetail(new Date().valueOf());
                    }, 3000);
                }
            }else {
                setIsReloadQrActive(true);
                doToast("Generating QR Code is failed", "fail"); 
            }
        })
    }
    
    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if(timeIntervalReloadDetail !== null) {
            getWhatsappAccountDetail(orgID, match.params.accountID);
        }
    }, [timeIntervalReloadDetail]);

    return (
        <div className="integration-outer-wrapper"> 
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

                <div className="sectionWrap integration-main-container integration-whatsapp-authenticate-now-account text-left">
                    <BackButton text="Back" onClick={back} />

                    <div className="integration-whatsapp-authenticate-now-account-box">
                        <div className="integration-whatsapp-authenticate-now-account-box-top">
                            <b>Authenticate Now</b>
                        </div>

                        <div className="main-authenticated-now-qr-scan">
                             <div className="qr-code-wrapper">
                                <div className="qr-code-content">
                                    {isWaitingQrImage ?
                                        <div className="lds-ring">
                                            <div /><div /><div /><div />
                                        </div>    
                                        :
                                        <React.Fragment>
                                            {qrImage !== null &&
                                                <img src={qrImage} alt="" className={`qr-image ${isReloadQrActive ? "qr-transparent" : ""}`} />
                                            }

                                            {isReloadQrActive &&
                                                <div className="qr-code-retry-wrapper" onClick={() => getQr(orgID, match.params.accountID)}>
                                                    <FiRotateCcw />
                                                    <br />
                                                    <b>Click to Reload QR Code</b>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                </div>

                                <p className="point-phone-here">
                                    <img src={authenticateIconLight} alt="" />
                                    <b>
                                        Point Your Phone Here
                                    </b>
                                </p>
                             </div>

                             <div className="qr-code-instruction-wrapper">
                                <p className="make-sure-connection">
                                    <FiAlertTriangle />
                                    <b>Make sure your phone is connected to the internet</b>
                                </p>

                                <p className="instruction-title">
                                    <b>To authenticate your WhatsApp Integration:</b>
                                </p>

                                <div className="instruction-list">
                                    <p>
                                        <span>
                                            <b>1</b>
                                        </span>

                                        Open WhatsApp on your phone
                                    </p>

                                    <p>
                                        <span>
                                            <b>2</b>
                                        </span>

                                        Tap <b>Menu</b> <img src={iconWhatsappSetting} alt="" /> or <b>Settings</b> <img src={iconWhatsappBrowser} alt="" /> and select <b>WhatsApp Web</b>
                                    </p>

                                    <p>
                                        <span>
                                            <b>3</b>
                                        </span>

                                        Capture the QR code on the left by pointing your phone here
                                    </p>
                                </div>
                             </div>
                        </div>

                        {/* <div className="authenticate-now-loading">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                            <p>
                                <b>Authenticating...</b>
                            </p>
                        </div> */}

                        {/* <div className="failed-authenticated-now-refresh">
                            <FiRotateCcw />
                            <p>
                                <b>Authentication failed, click here to reload QR Code</b>
                            </p>
                        </div> */}
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationWhatsappAuthenticateNow;