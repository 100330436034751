import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./IntegrationApiKeys.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast } from "../../../helper/HelperGeneral";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { FiPlus, FiEye, FiCopy } from "react-icons/fi";
import IconRemoveRed from "../../../assets/img/icon-remove-red.svg";
// import { IoIosRefresh } from "react-icons/io";
import PopupRemove from "../../reuseableComponent/popupRemove/PopupRemove";
// import Loading from "../../reuseableComponent/loading/Loading";
import NewButton from "../../reuseableComponent/NewButton/NewButton";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ApiKeysServices from "../../../services/newServices/ApiKeysServices";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import HelperDate from "../../../helper/HelperDate";
import mixpanel from "mixpanel-browser";
import { NavLink } from "react-router-dom";

const IntegrationApiKeys = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
    let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [apiKeysList, setApiKeyList] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [showPopupSingleRemove, setShowPopupSingleRemove] = useState(false);
  let [singleRemoveApiKey, setSingleRemoveApiKey] = useState({
    shortcut: "",
    id: ""
  });
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [apiKeyCopy, setApiKeyCopy] = useState(false);
  let [isShowModalViewKey, setIsShowModalViewKey] = useState(false);

  
  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration/api-keys`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let fetchApiKeyList = () => {
    let _retriveDataError = {...retriveDataError};
    
    ApiKeysServices.getApiKey(orgID, (response) => {
      let dataResult = response.dataResult;
      if(dataResult.error.message === "") {
        setApiKeyList(dataResult.data.apiKeys);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if(orgID) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      fetchApiKeyList();
      
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
            "[Page] General API Keys", 
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(props.history.location.state) {
      if(props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  useEffect(() => {
    setIsShowModalViewKey(apiKeyCopy ? true : false)
  }, [apiKeyCopy])

  // let onChangePage = () => {
  //   console.log('change page')
  // }

  let toggleModalRemoveApiKey = () => {
    setShowPopupSingleRemove(!showPopupSingleRemove);
  }

  let deleteApiKey = () => {
    setWaitForResponseDelete(true);
    let _singleRemoveApiKey = {...singleRemoveApiKey};
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    ApiKeysServices.deleteApiKey(orgID, _singleRemoveApiKey, (response) => {
      let dataResult = response.dataResult;
      setWaitForResponseDelete(false);
      toggleModalRemoveApiKey();

      if(dataResult.error.message === "") {
        if(!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, "fail");
        }else {
          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Delete API Keys", 
              {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
              }
            );
          }

          doToast(response.dataResult.data.message);
          fetchApiKeyList();
        }
      }else {
        doToast(response.dataResult.message, 'fail');
      }

    })
  }

  let onClickSingleDelete = (data) => {
    let _data = {
      id: data.id,
      createdTime: data.createdTime
    }

    setSingleRemoveApiKey(_data);
    setShowPopupSingleRemove(true);
  }

  const addBtn = () => {
    const fullUrl = `/o/${orgID}/integration/api-keys/create`;
    
    const onClick = () => history.push(fullUrl);
    const btnProps = { text: "Create API Keys", icon: FiPlus, onClick };

    return <NewButton {...btnProps} />;
  };

  const copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  let modalViewKey = () => {
      return (
          <Modal isOpen={isShowModalViewKey} toggle={() => setApiKeyCopy(false)} className="modal-view-key">
              <ModalBody>
                    <p className="modal-title"> 
                        <FiEye />
                        <b>View API Key</b>
                    </p>

                    <div className="form-content-wrapper">
                        <div className="disabled-form">
                            <input type="text"
                                value={apiKeyCopy}
                                // onClick={copyToClip}
                                id="copy-api-key"
                                readOnly
                            /> 

                            <p>
                              {apiKeyCopy}

                              <FiCopy 
                                onClick={() => {
                                    copyToClip("copy-api-key", "API Key copied")
                                }}
                              />
                            </p>
                        </div>
                    </div>

                    <ButtonWithLoadingOrIcon
                        className="orange-button main-button-48"
                        text="Dismiss"
                        onClickAction={() => setApiKeyCopy(false)}
                    />
                </ModalBody>
          </Modal>
      )
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap api-key-main-wrapper">
            {modalViewKey()}

            <div className="topContent">
                <div className="title">API Keys</div>

                {!waitForResponseGetList && !retriveDataError.code &&
                  <NavLink to={`/o/${orgID}/integration/api-keys/create`}>
                    <div 
                      className="btn"
                      onClick={() => {
                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                        
                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                          mixpanel.track(
                            "[Button] Create API Keys",
                            {
                              userFullName : myAgentData.account.fullName,
                              umixpserID : myAgentData.account.id,
                              organizationID : orgID.split("-")[0]
                            }
                          )
                        }
                      }}
                    >
                      {addBtn()}
                    </div>
                  </NavLink>
                }
            </div>

            <div className="api-key-list-info">
                Create your own API Keys and gain access to OneTalk APIs to send messages.
            </div>

            {waitForResponseGetList || retriveDataError.code ? 
              <RetrieveDataLoading 
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message} 
              />
              :
              <React.Fragment>
                <div className="api-key-list-table-wrapper">
                  <div className="custom-table">
                      <table className="custom-table-content table-api-key-list">
                          <thead>
                              <tr>
                                <td className="col_desc">Description</td>
                                <td className="col_key">API Key</td>
                                <td className="col_expiry">Expiry</td>
                                <td className="col_action"></td>
                              </tr>
                          </thead>
                          
                          {apiKeysList.length < 1 ?
                            <tbody>
                              <tr>
                                <td colSpan="4" className="no-data-column"><b className="no-result-found">No api keys found, start with "+ Create API Keys"</b></td>
                              </tr>
                            </tbody>
                            :
                            <tbody>
                              {apiKeysList.map((value, index) => {
                                return (
                                  <tr key={`api-key-${index}`}>
                                      <td className="col_desc">{value.description}</td>
                                      <td className="col_key">
                                        <span onClick={() => setApiKeyCopy(value.key)}>
                                            <FiEye />
                                            <b>View</b>
                                        </span>
                                      </td>
                                      <td className="col_expiry">
                                        {value.expiryTime === 0 ? "No Expiry" : HelperDate.formatToString(new Date(value.expiryTime), "dd MMM yyyy")}
                                      </td>
                                      <td className={`col_action`}>
                                        <div className="col-action-button-wrapper">
                                            <button 
                                              className="single-remove-button no-background-and-border-button" 
                                              onClick={() => {
                                                let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                                                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                  mixpanel.track(
                                                    "[Button] Delete API Keys",
                                                      {
                                                        userFullName : myAgentData.account.fullName,
                                                        userID : myAgentData.account.id,
                                                        organizationID : orgID.split("-")[0]
                                                      }
                                                  )
                                                }
                                                onClickSingleDelete(value)
                                              }}>
                                              <img className="view-agent" src={IconRemoveRed} alt="" />
                                              <b>Delete</b>
                                            </button>
                                        </div>
                                      </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          }
                      </table>
                  </div>
                    
                  {/* <Pagination total={100} 
                              page={1} 
                              listPerPage={8} 
                              step={3} 
                              onChangePage={onChangePage} 
                  /> */}

                  <PopupRemove removeTitle={`Delete API Keys?`}
                              removeDescription={`This api key will be removed from the list`}
                              onClickCancel={() => toggleModalRemoveApiKey()}
                              onClickRemove={deleteApiKey}
                              waitForResponse={waitForResponseDelete}
                              showModalProps={showPopupSingleRemove}
                              submitText="Delete API Keys"
                  />
                </div>
              </React.Fragment>
            }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default IntegrationApiKeys;
