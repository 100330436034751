import React, { useEffect, useState } from "react";
import "./IntegrationAutoUnassign.scss";
import { FiInfo } from "react-icons/fi";
import { doToast, checkID } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import AutoUnassignServices from "../../../../services/newServices/AutoUnassignServices";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import mixpanel from "mixpanel-browser";
import HelperInput from "../../../../helper/HelperInput";

const IntegrationAutoUnassign = (props) => {
    let [val, setVal] = useState(false);
    let [valOrigin, setValOrigin] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [isWaitingSet, setIsWaitingSet] = useState(false);
    let [isShowModalSave, setIsShowModalSave] = useState(false);
    let [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [errorMessageData, setErrorMessageData] = useState({
        value: "",
        option: ""
    });
    let [orgID, setOrgID] = useState(false);

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Auto Unassign Setting",
                {
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    useStateorganizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if (orgID) {
            getDataAutoUnassign();
        }
    }, [orgID])

    useEffect(() => {
        checkSaveButtonEnabled();
    }, [val, valOrigin])

    useEffect(() => {
    }, [isSaveButtonEnabled])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        }
        else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/auto-unassign-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getDataAutoUnassign = () => {
        let _retriveDataError = { ...retriveDataError };
        setIsWaitingFetch(true);

        AutoUnassignServices.getAutoUnassign(orgID,
            (
                props.medium ?
                    props.medium
                    :
                    props.location.state.medium
            ),
            (
                props.medium === "global" ?
                    {}
                    :
                    {
                        id: parseInt(props.match.params.id ? props.match.params.id : props.match.params.accountID)
                    }
            ),
            (response) => {
                let dataResult = response.dataResult;

                if (dataResult.error.message === "") {
                    setValOrigin(JSON.parse(JSON.stringify(dataResult.data.autoUnassignAgentConfig)));
                    setVal(JSON.parse(JSON.stringify(dataResult.data.autoUnassignAgentConfig)));
                    setErrorMessageData({
                        value: "",
                        option: ""
                    });
                }
                else {
                    _retriveDataError.message = dataResult.error.message;
                    _retriveDataError.code = dataResult.error.code;
                    setRetrieveDataError(_retriveDataError);
                }

                setIsWaitingFetch(false);
            }
        )
    }

    let setDataAutoUnassign = () => {
        let errorCount = 0;
        let _error = { ...errorMessageData };

        if (val.isEnabled && !val.useGlobalConfig) {
            if (val.afterValue === "" || parseInt(val.afterValue) === 0) {
                _error.value = "This field is required";
                errorCount = errorCount + 1;
            }

            if (val.afterType === "") {
                _error.option = "This field is required";
                errorCount = errorCount + 1;
            }
        }

        if (errorCount === 0) {
            setIsWaitingSet(true);

            let data = {
                "isEnabled": val.isEnabled,
                "sendClosingMessage": val.sendClosingMessage,
                "afterValue": parseInt(val.afterValue),
                "afterType": val.afterType
            }

            if (props.medium !== "global") {
                data.id = parseInt(props.match.params.id ? props.match.params.id : props.match.params.accountID);
                data.useGlobalConfig = val.useGlobalConfig;
            }

            AutoUnassignServices.setAutoUnassign(orgID,
                (
                    props.medium ?
                        props.medium
                        :
                        props.location.state.medium
                ),
                data,
                (response) => {
                    let dataResult = response.dataResult;

                    if (dataResult.error.message === "") {
                        if (dataResult.data.success) {
                            doToast(dataResult.data.message);
                        } else {
                            doToast(dataResult.data.message, "fail");
                            setIsWaitingSet(false);
                        }

                        setValOrigin(JSON.parse(JSON.stringify(val)));

                        if (props.medium === "global") {
                            setIsWaitingSet(false);
                            toggleModalSave();
                        } else {
                            setTimeout(() => {
                                props.back();
                            }, 3000)
                        }
                    } else {
                        doToast(dataResult.error.message, "fail")
                        toggleModalSave();
                        setIsWaitingSet(false);
                    }
                }
            )
        } else {
            toggleModalSave();
            setErrorMessageData(_error);
        }
    }

    let toggleConfig = () => {
        let _data = { ...val };
        _data.isEnabled = !_data.isEnabled;
        setVal(_data);
    }

    let toggleConfigGlobal = () => {
        let _data = { ...val };
        _data.useGlobalConfig = !_data.useGlobalConfig;
        setVal(_data);
    }

    let checkSaveButtonEnabled = () => {
        if (val && val.inboxConfigs && valOrigin && valOrigin.inboxConfigs) {
            setIsSaveButtonEnabled(val.inboxConfigs.IntegrationAutoResolveConfigCase !== valOrigin.inboxConfigs.IntegrationAutoResolveConfigCase);
        }
        else {
            setIsSaveButtonEnabled(false);
        }
    }

    let toggleModalSave = () => {
        if (!isWaitingSet) {
            setIsShowModalSave(!isShowModalSave);
        }
    }

    let changeVal = (e) => {
        let _error = { ...errorMessageData };
        let _data = { ...val };

        if ((HelperInput.numberOnly(e.target.value) && e.target.value > 0) || e.target.value === "") {
            _error.value = "";
            _data.afterValue = e.target.value.replace(/\b0+/g, '');
        }

        setErrorMessageData(_error);
        setVal(_data);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={valOrigin}
            dataAfterChange={val}
        >
            {props.backToText !== "" &&
                <BackButton
                    onClick={props.back}
                    text={props.backToText}
                />
            }

            <div className="auto-unassign-settings sectionWrap">
                <React.Fragment>
                    {/* tier blocking */}
                    {retriveDataError.code === "49901" &&
                        <RetrieveDataLoading
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message}
                            errorCode={retriveDataError.code}
                        />
                    }
                    {/* tier blocking */}

                    <div className="autoUnassign-settings-header">
                        <b>Automatic Unassign Agent</b>

                        {!retriveDataError.code &&
                            <ButtonWithLoadingOrIcon
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                onClickAction={toggleModalSave}
                                // isDisabled={!isSaveButtonEnabled}
                                isDisabled={false}
                            />
                        }
                    </div>

                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ?
                        <RetrieveDataLoading
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <>
                            {props.medium !== "global" &&
                                <div className="autoUnassign-settings-form no-padding-top">
                                    <div className="autoUnassign-settings-form-content form-content-wrapper">
                                        <b>Use Global Config</b>
                                        <p>
                                            Use Global Automatic Unassign Agent from setup
                                        </p>

                                        <div className="closing-welcome-switch-wrapper">
                                            <label className="custom-switch-wrapper">
                                                <input type="checkbox" checked={val.useGlobalConfig} onChange={() => toggleConfigGlobal()} />
                                                <span className="custom-switch round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className={`autoUnassign-settings-form no-padding-top ${props.medium !== "global" ? (val.useGlobalConfig ? "force-hide" : "") : ""}`}>
                                <div className="autoUnassign-settings-form-content form-content-wrapper">
                                    <b>Enable Automatic Unassign Agent</b>
                                    <p>
                                        Cases will be automatically unassigned once agent does not reply back within a specific time range.
                                    </p>

                                    <div className="closing-welcome-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input type="checkbox" checked={val.isEnabled} onChange={() => toggleConfig()} />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {val.isEnabled &&
                                <>
                                    <div className={`autoUnassign-settings-form  no-padding-top ${props.medium !== "global" ? (val.useGlobalConfig ? "force-hide" : "") : ""}`}>
                                        <div className="autoUnassign-settings-form-content form-content-wrapper">
                                            <b>Wait Duration</b>
                                            <p>
                                                Agent will be automatically unassigned after
                                                <input type="text" maxLength={3} placeholder="00" value={val.afterValue} onChange={(e) => { changeVal(e) }} className={errorMessageData.value !== "" ? "border-red" : ""} />

                                                <CustomSelectOption
                                                    isDisabled={false}
                                                    optionListProps={[
                                                        {
                                                            label: "hours",
                                                            value: "hours"
                                                        },
                                                        {
                                                            label: "minutes",
                                                            value: "minutes"
                                                        },
                                                    ]}
                                                    valueDropdownProps={
                                                        val.afterType === "" ?
                                                            { label: "", value: "" }
                                                            :
                                                            val.afterType === "minutes" ?
                                                                { label: "minutes", value: "minutes" }
                                                                :
                                                                { label: "hours", value: "hours" }
                                                    }
                                                    placeholderProps={"minutes"}
                                                    onClickDropDownListOptionProps={(e) => {
                                                        let _data = { ...val };
                                                        let _error = { ...errorMessageData };
                                                        _error.option = "";
                                                        _data.afterType = e.value;
                                                        setErrorMessageData(_error);
                                                        setVal(_data);
                                                    }}
                                                    specificClassNameProps="custom-select-time"
                                                    _className={`input-time-select ${errorMessageData.option === "" ? "" : "border-red"}`}
                                                />
                                                from customer's last reply.
                                            </p>

                                            {(errorMessageData.value !== "" || errorMessageData.option !== "") &&
                                                <ErrorOrInfoComp
                                                    text="This field is required"
                                                    icon={<FiInfo />}
                                                    _className="red-text"
                                                />
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </>

                    }
                </React.Fragment>
            </div>

            <PopupConfirmation
                isOpen={isShowModalSave}
                toggle={toggleModalSave}
                title="Save Changes?"
                description="You have changes in your Automatic Unassign Agent, are you sure you want to continue?"
                className="modal-confirmation-save-changes"
                isLoading={isWaitingSet}
            >
                <ButtonWithLoadingOrIcon
                    text="Save Changes"
                    className="main-button-48 orange-button"
                    isLoading={isWaitingSet}
                    isDisabled={isWaitingSet}
                    loadingColor="gray"
                    position="left"
                    onClickAction={setDataAutoUnassign}
                />
            </PopupConfirmation>
        </SectionWrap>
    )
}

export default IntegrationAutoUnassign;
