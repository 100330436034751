import React from "react";
import "./ChatRoomEmailCompInfo.scss";

let ChatRoomEmailCompInfo = (props) => {
    let { singleChatDataProps } = props;

    let generateNameInfo = (data) => {
        let print = "";

        if (data.split(`"`).length === 1) {
            print = data;
        } else {
            print = data.split(`"`)[2];
        }

        return print;
    }

    return (
        <div className="comp-email-wrapper-info">
            {/* <div className="close-email-info" onClick={_toggle}>
                <FiX className="" />
            </div> */}

            <div className="comp-email-line-wrapper">
                <p className="comp-email-line-wrapper-left">
                    from:
                </p>

                <p className="comp-email-line-wrapper-right" title={`${singleChatDataProps.data.from}`}>
                    <b>{singleChatDataProps.data.from.split(`"`)[1]}</b>

                    <span className="grey-text">{generateNameInfo(singleChatDataProps.data.from)}</span>
                </p>
            </div>


            {singleChatDataProps.data.to.map((v, i) => {
                return (
                    <div className="comp-email-line-wrapper">
                        <p className="comp-email-line-wrapper-left" title={`${v}`}>
                            {i === 0 ? "to:" : ""}
                        </p>

                        <p className="comp-email-line-wrapper-right" key={`to-${i}`} title={`${v.split(`"`)[1]} ${v.split(`"`)[2]}`}>
                            <b>{v.split(`"`)[1]}</b>

                            <span className="grey-text">{generateNameInfo(v)}</span>
                        </p>
                    </div>
                )
            })}

            {singleChatDataProps.data.cc &&
                (singleChatDataProps.data.cc.map((v, i) => {
                    return (
                        <div className="comp-email-line-wrapper">
                            <p className="comp-email-line-wrapper-left">
                                {i === 0 ? "cc:" : ""}
                            </p>
                            <p className="comp-email-line-wrapper-right" key={`cc-${i}`} title={`${v.split(`"`)[1]} ${v.split(`"`)[2]}`}>
                                <b>{v.split(`"`)[1]}</b>

                                <span className="grey-text">{generateNameInfo(v)}</span>
                            </p>

                        </div>
                    )
                }))
            }

            <div className="comp-email-line-wrapper">
                <p className="comp-email-line-wrapper-left">
                    Subject:
                </p>
                <p className="comp-email-line-wrapper-right with-overflow" title={singleChatDataProps.data.subject === "" ? "no subject" : singleChatDataProps.data.subject}>
                    <b>{singleChatDataProps.data.subject === "" ? "(no subject)" : singleChatDataProps.data.subject}</b>
                </p>

            </div>
        </div>
    )
}

export default ChatRoomEmailCompInfo;