import React, { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";
import "./AccountSettingDefaultOrganizationEdit.scss";
import { checkID, doToast } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceProfile from "../../../../services/profile/ServiceProfile";
import IconOrganization from "../../../../assets/img/icon-organization.svg";
import mixpanel from "mixpanel-browser";
import Select from "react-select";

let iconDropOrange = "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
let iconDropGrey = "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

const AccountSettingDefaultOrganizationEdit = (props) => {
    let [val, setVal] = useState({
        value: "",
        label: ""
    });
    let [valOrigin, setValOrigin] = useState({
        value: "",
        label: ""
    })
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [isWatingSet, setIsWaitingSet] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [orgID, setOrgID] = React.useState(false);
    let [organizationList, setOrganizationList] = useState([
        {
            label: "",
            value: ""
        }
    ])
    let [errorMessageData, setErrorMessageData] = useState({
        default: ""
    })

    useEffect(() => {
        doMount();
        let myAgentData =HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Account Setting",
                {
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    useStateorganizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if (orgID) {
            getData();
        }
    }, [orgID])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/account-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let styleSelect = () => ({
        indicatorsContainer: provide => ({
            ...provide,
            height: "48px",
        }),
        valueContainer: provide => ({
            ...provide,
            height: "100%"
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused ? iconDropOrange : iconDropGrey,
            transition: "0.25s ease-in-out",
            color: "transparent !important",
            width: "24px",
            height: "24px",
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            width: "355px"
        }),
        control: (provide, { isFocused }) => ({
            ...provide,
            padding: "0 5px",
            // height: "48px",
            borderRadius: "8px",
            border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
            boxShadow: "none !important",
            marginBottom: "0px",
            cursor: "pointer"
        }),
        input: (provide) => ({
            height: "42px"
        }),
        indicatorSeparator: provide => ({ ...provide, display: "none" }),
        menu: provide => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden"
        }),
        option: (provide, state) => {
            const { isSelected } = state;
            const checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)"
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null
            };
        }
    })

    let back = () => {
        props.history.push(`/o/${orgID}/account/settings`)
    }

    let getMemberShip = (id, memberships) => {
        let _findIndex = memberships.findIndex((v) => v.organization.id === id);
        return _findIndex === -1 ? false : memberships[_findIndex];
    }

    let getData = () => {
        let _retriveDataError = { ...retriveDataError };
        setIsWaitingFetch(true);

        ServiceProfile.postGetProfile((response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                let _arr = [];

                dataResult.data.memberships.map((v) => {
                    _arr.push({
                        value: v.organization.id,
                        label: v.organization.name
                    })
                    return null;
                })

                setOrganizationList(_arr);
                setVal({
                    value: dataResult.data.account.defaultOrganizationID === 0 ? "" : getMemberShip(dataResult.data.account.defaultOrganizationID, dataResult.data.memberships).organization.id,
                    label: dataResult.data.account.defaultOrganizationID === 0 ? "" : getMemberShip(dataResult.data.account.defaultOrganizationID, dataResult.data.memberships).organization.name,
                });
                setValOrigin({
                    value: dataResult.data.account.defaultOrganizationID === 0 ? "" : getMemberShip(dataResult.data.account.defaultOrganizationID, dataResult.data.memberships).organization.id,
                    label: dataResult.data.account.defaultOrganizationID === 0 ? "" : getMemberShip(dataResult.data.account.defaultOrganizationID, dataResult.data.memberships).organization.name,
                });
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitingFetch(false);
        })
    }

    let runSet = () => {
        // let errorCount = 0;

        if (val.value === "") {
            // errorCount = 1;
            setErrorMessageData({
                default: "This field is required"
            })
        } else {
            setIsWaitingSet(true);

            ServiceProfile.postEditDefault({ organizationID: val.value }, (response) => {
                let dataResult = response.dataResult;

                if (dataResult.error.message === "") {
                    if (dataResult.data.success) {
                        doToast(dataResult.data.message);
                        setValOrigin(val);

                        setTimeout(() => {
                            back();
                        }, 3000)
                    } else {
                        doToast(dataResult.data.message, "fail");
                        setIsWaitingSet(false);
                    }
                } else {
                    doToast(dataResult.error.message, "fail");
                    setIsWaitingSet(false);
                }
            })
        }
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={JSON.stringify(valOrigin)}
            dataAfterChange={JSON.stringify(val)}
        >
            <BackButton
                text="Back to Settings"
                onClick={back}
            />

            <div className="account-settings sectionWrap">
                {/* tier blocking */}
                {retriveDataError.code === "49901" &&
                    <RetrieveDataLoading
                        isLoading={isWaitingFetch}
                        errorMessage={retriveDataError.message}
                        errorCode={retriveDataError.code}
                    />
                }
                {/* tier blocking */}

                <React.Fragment>
                    <div className="account-settings-header">
                        <b>My Organizations</b>

                        {isWatingSet ?
                            <ButtonWithLoadingOrIcon
                                className="orange-button main-button-40 button-save"
                                text="Save Changes"
                                position="left"
                                isDisabled
                                isLoading
                                loadingColor="gray"
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                className="orange-button main-button-40 button-save"
                                text="Save Changes"
                                position="left"
                                onClickAction={runSet}
                            />
                        }
                    </div>

                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ?
                        <RetrieveDataLoading
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <div className="account-settings-form">
                            <div className="account-settings-form-content form-content-wrapper">
                                <p className="title-default">
                                    <img className="account-setting-icon" src={IconOrganization} alt="" />
                                    <b>Default Selected Organization</b>
                                </p>
                                
                                <div className={`custom-select2-wrapper ${errorMessageData.default === "" ? "" : "border-red"}`}>
                                    <Select 
                                        options={organizationList} 
                                        placeholder="Not Specified" 
                                        onChange={(e) => {
                                            setErrorMessageData({
                                                default: ""
                                            });
                                            setVal(e);
                                        }}
                                        styles={styleSelect()}
                                        classNamePrefix="react-select-custom"
                                        isDisabled={isWatingSet}
                                        value={organizationList.filter(
                                            (obj) => obj.value === val.value
                                        )}
                                        blurInputOnSelect={true}
                                        classNames={`input-organization ${errorMessageData.default !== "" ? "border-red" : ""}`}
                                    />
                                </div>

                                {errorMessageData.default !== "" &&
                                    <ErrorOrInfoComp
                                        _className="red-text"
                                        icon={<FiInfo />}
                                        text={errorMessageData.default}
                                    />
                                }
                            </div>
                        </div>
                    }
                </React.Fragment>
            </div>
        </SectionWrap>
    )
}

export default AccountSettingDefaultOrganizationEdit;