import React, { useState, useEffect } from "react";
import "./PreviewMessage.scss";
import { tapCoreRoomListManager, tapCoreMessageManager, taptalk } from "@taptalk.io/web-sdk";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { FiX, FiEye, FiCheck, FiUpload, FiAlertCircle, FiFile, FiInfo } from "react-icons/fi";
// import Helper from '../../../../../helper/HelperChat';
import HelperUpload from "../../../../../helper/HelperUpload";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import WhatsappPreview from "../../../../reuseableComponent/whatsappPreview/WhatsappPreview";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ModalSendTemplatedMessage from "../../../../reuseableComponent/modalSendTemplatedMessage/ModalSendTemplatedMessage";
import { clearStartConversationBa, setStartConversationBa } from "../../../../../redux/actions/reduxActionStartConversationBa";
import { clearStartConversation } from '../../../../../redux/actions/reduxActionStartConversation';
import { setShowOtherCase } from "../../../../../redux/actions/reduxActionShowOtherCase";
import { WHATSAPP_FILE_SIZE_LIMIT } from "../../../../../constants/taplive";
import { doToast } from "../../../../../helper/HelperGeneral";
import { setActiveCasePanel } from "../../../../../redux/actions/reduxActionActiveCasePanel";
import { setToggleMineCase } from "../../../../../redux/actions/reduxActionToggleMineCase";
import { setCloseCaseNewMessageRecipient } from "../../../../../redux/actions/reduxActionCloseCaseNewMessageRecipient"
import StartConversationServices from "../../../../../services/chat/startConversationServices";
import ChatService from '../../../../../services/chat/caseServices';
import { BROADCAST_HEADER_FORMAT } from "../../../../../constants/message";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-2px",
    width: "5px",
    borderRadius: "8px",
  },
};

let PreviewMessage = (props) => {
  const { dataPreviewMessage } = props;
  const [isLoadingWhatsappStatus, setIsLoadingWhatsappStatus] = useState(false);
  const [data, setData] = useState({ render: false });
  let [percentageUpload, setPercentageUpload] = useState(false);
  let [isUploading, setIsUploading] = useState(false);
  // let [headerText, setHeaderText] = useState("");
  // let [bodyText, setBodyText] = useState("");
  // let [footerText, setFooterText] = useState("");

  let onCloseClick = (toggle = true) => {
    if (!isLoadingWhatsappStatus) {
      resetData();
      props.closeModal();
      props.runSetDataPreviewMessage(false);
      if (toggle) toggleModalConfirmSendMessage();
    }
  };

  // useEffect(() => {
  //   console.log(props.startConversationBa)
  // }, [props.startConversationBa])

  let onSubmitNewConversation = () => {
    setIsLoadingWhatsappStatus(true);

    let _data = { ...data };

    let param = {
      channelID: _data.channel.value,
      phone: _data.recipient.contact.number.replace("+", ""),
      phoneNumberID: _data.phoneVal.value === "" ? undefined : _data.phoneVal.value,
    };

    let runSendMessage = () => {
      if (props.newMessage24h) {
        Object.keys(_data.language.parameters).map((value) => {
          if (_data.language.parameters[value].length > 0) {
            _data.language.parameters[value].map((_value) => {
              delete _value["isPredefined"];
              delete _value["number"];
              delete _value["type"];
              return null;
            })
          }

          return null;
        })

        if (_data.template.category === "AUTHENTICATION") {
          delete _data.language.parameters.buttons;
        }

        let datas = {
          id: props.caseDetailData.case.id,
          createdTime: props.caseDetailData.case.createdTime,
          templateID: _data.template.value,
          languageCode: _data.language.languageCode,
          parameters: _data.language.parameters,
          phoneNumberID: _data.phoneVal.value === "" ? undefined : _data.phoneVal.value,
        };

        datas.parameters.footer.map(v => {
          v.value = v.value.trim();
          return null;
        })

        datas.parameters.body.map(v => {
          v.value = v.value.trim();
          return null;
        })

        datas.parameters.header.map(v => {
          v.value = v.value.trim();
          return null;
        })

        StartConversationServices.postReplyMessageWhatsappBa(props.parentProps.match.params.orgID, datas, (response) => {
          let dataResult = response.dataResult;
          let dataResultData = dataResult.data;

          if (dataResult.status === 200) {
            if (dataResultData.success) {
              onCloseClick();
              doToast("Successfully send the message");
              // tapCoreRoomListManager.getRoomByXcID(dataResultData.case.tapTalkXCRoomID, {
              // onSuccess: (response) => {
              //     props.setToggleMineCase(true);

              //     if(props.activeRoom === null) {
              //       props.onClickRoomListProps(response.room, dataResultData.openCase);
              //     }else {
              //       if(props.activeRoom.roomID !== response.room.roomID) {
              //         props.onClickRoomListProps(response.room, dataResultData.openCase);
              //       }
              //     }

              //     doToast("Successfully send the message");
              //     resetData();
              //     props.toggleModalSendingProps(false);
              // },
              // onError: (errorCode, errorMessage) => {
              //     console.log(errorCode, errorMessage);
              //     doToast(errorMessage, "fail")
              //     props.toggleModalSendingProps(false);
              // }
              // })
            } else {
              doToast(dataResultData.message, "fail");
              props.toggleModalSendingProps(false);
            }

            props.clearStartConversationBa();
          } else {
            doToast(dataResult.error.message, "fail");
            props.toggleModalSendingProps(false);
          }

          setIsLoadingWhatsappStatus(false);
        })
      } else {
        Object.keys(_data.language.parameters).map((value) => {
          if (_data.language.parameters[value].length > 0) {
            _data.language.parameters[value].map((_value) => {
              delete _value["isPredefined"];
              delete _value["number"];
              delete _value["type"];
              return null;
            })
          }

          return null;
        })

        if (_data.template.category === "AUTHENTICATION") {
          delete _data.language.parameters.buttons;
        }

        let dataChat = {
          channelID: _data.channel.value,
          phone: _data.recipient.contact.number.replace("+", ""),
          templateID: _data.template.value,
          languageCode: _data.language.languageCode,
          parameters: _data.language.parameters,
          topicID: parseInt(_data.topic.value),
          phoneNumberID: _data.phoneVal.value === "" ? undefined : _data.phoneVal.value,
        }

        dataChat.parameters.footer.map(v => {
          v.value = v.value.trim();
          return null;
        })

        dataChat.parameters.body.map(v => {
          v.value = v.value.trim();
          return null;
        })

        dataChat.parameters.header.map(v => {
          v.value = v.value.trim();
          return null;
        })

        let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
        _closeCaseNewMessage.lastClick = false;

        props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);

        StartConversationServices.postSendMessageWhatsappBa(props.orgIDProps, dataChat, (response) => {
          let dataResult = response.dataResult;
          let dataResultData = dataResult.data;

          if (dataResult.status === 200) {
            if (dataResultData.success) {
              tapCoreRoomListManager.getRoomByXcID(
                dataResultData.case.tapTalkXCRoomID,
                {
                  onSuccess: (response) => {
                    props.setToggleMineCase(true);

                    if (props.activeRoom === null) {
                      props.onClickRoomListProps(response.room, dataResultData.case);
                    } else {
                      if (props.activeRoom.roomID !== response.room.roomID) {
                        props.onClickRoomListProps(response.room, dataResultData.case);
                      }
                    }

                    doToast("Successfully send the message");
                    resetData();
                    props.toggleModalSendingProps(false);
                  },
                  onError: (errorCode, errorMessage) => {
                    console.log(errorCode, errorMessage);
                    doToast(errorMessage, "fail");
                    props.toggleModalSendingProps(false);
                  },
                }
              );
            } else {
              doToast(dataResultData.message, "fail");
              props.toggleModalSendingProps(false);
            }

            props.clearStartConversationBa();
          } else {
            doToast(dataResult.error.message, "fail");
            props.toggleModalSendingProps(false);
          }

          setIsLoadingWhatsappStatus(false);
        })
      }
    }

    if (props.newMessage24h) {
      props.setStartConversationBa({ ...props.startConversation });
      // onCloseClick();
      // props.toggleModalSendingProps(true);
      runSendMessage();
    } else {
      StartConversationServices.postCheckStatusWhatsappBa(props.orgIDProps, param, (response) => {
        let dataResult = response.dataResult;
        let dataResultData = dataResult.data;
        let _startConversation = { ...props.startConversation };
        let myAgentAccountID = props.myAgentData.account.id;

        if (dataResult.status === 200) {
          if (props.newMessage24h) {
            _startConversation.is24Hour = true
            // _startConversation.conversation["case"] = {id: props.caseDetailData.case.id, createdTime: props.caseDetailData.case.createdTime}
          }
          if (!dataResultData.canSend && !dataResultData.isValidNumber) {
            doToast("Can't send, phone number is not valid", "fail");
            setIsLoadingWhatsappStatus(false);
          }

          props.clearStartConversation();

          if (props.newMessage24h) {
            props.setStartConversationBa(_startConversation);

            if (dataResultData.canSend && dataResultData.isValidNumber) {
              onCloseClick();
              props.toggleModalSendingProps(true);
              runSendMessage();
            }
          } else {
            if (
              dataResultData.openCase.id === 0 &&
              (!props.topicListProps[dataResultData.openCase.topicID] && dataResultData.openCase.topicID !== 0) &&
              dataResultData.canSend
            ) {
              _startConversation.openCase = {
                topicID: _data.topic.id,
                agentAccountID: "",
              };
              _startConversation.conversation = data;
              onCloseClick();
              props.setStartConversationBa(_startConversation);
            }

            let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
            _closeCaseNewMessage.lastClick = false;

            props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);

            if (dataResultData.openCase.id !== 0) {
              // doToast("Recipient has ongoing case", "fail");
              if (!props.topicListProps[dataResultData.openCase.topicID]) {
                _startConversation.openCase = dataResultData.openCase;
                _startConversation.conversation = data;
                props.setStartConversationBa(_startConversation);
                onCloseClick();

                tapCoreRoomListManager.getRoomByXcID(
                  dataResultData.openCase.tapTalkXCRoomID,
                  {
                    onSuccess: (response) => {
                      let goToChatRoom = () => {
                        if (props.activeRoom === null) {
                          props.onClickRoomListProps(response.room, dataResultData.openCase, false);
                        } else {
                          if (props.activeRoom.roomID !== response.room.roomID) {
                            props.onClickRoomListProps(response.room, dataResultData.openCase, false);
                          }
                        }
                      };

                      if (dataResultData.openCase.agentAccountID === 0) {
                        props.setActiveCasePanel("open");
                        props.setToggleMineCase(false);
                        goToChatRoom();
                      } else if (dataResultData.openCase.agentAccountID === myAgentAccountID) {
                        props.setActiveCasePanel("open");
                        props.setToggleMineCase(true);
                        goToChatRoom();
                      } else {
                        if (props.showOtherCase.configShow) {
                          props.setActiveCasePanel("other");
                          goToChatRoom();
                        } else {
                          let _showOther = { ...props.showOtherCase };
                          _showOther.modalWarningHideOther = true;
                          _showOther.case = dataResultData.openCase;
                          props.setShowOtherCase(_showOther);
                        }
                      }



                      onCloseClick();
                      setIsLoadingWhatsappStatus(false);
                    },
                    onError: (errorCode, errorMessage) => {
                      console.log(errorCode, errorMessage);
                      doToast(errorMessage, "fail");
                      setIsLoadingWhatsappStatus(false);
                    },
                  }
                );
              }
            }

            if (dataResultData.openCase.id !== 0) {
              // doToast("Recipient has ongoing case", "fail");
              if (!props.topicListProps[dataResultData.openCase.topicID]) {
                _startConversation.openCase = dataResultData.openCase;
                _startConversation.conversation = data;
                props.setStartConversationBa(_startConversation);
                onCloseClick();
                setIsLoadingWhatsappStatus(false);
              } else {
                _startConversation.conversation = data;
                _startConversation.openCase = dataResultData.openCase;
                props.setStartConversationBa(_startConversation);

                let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
                _closeCaseNewMessage.lastClick = false;

                props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);
                
                tapCoreRoomListManager.getRoomByXcID(dataResultData.openCase.tapTalkXCRoomID, {
                  onSuccess: (response) => {
                    let goToChatRoom = () => {
                      if (props.activeRoom === null) {
                        props.onClickRoomListProps(response.room, dataResultData.openCase, false);
                      } else {
                        if (props.activeRoom.roomID !== response.room.roomID) {
                          props.onClickRoomListProps(response.room, dataResultData.openCase, false);
                        }
                      }
                    };

                    if (dataResultData.openCase.agentAccountID === 0) {
                      props.setActiveCasePanel("open");
                      props.setToggleMineCase(false);
                      goToChatRoom();
                    } else if (dataResultData.openCase.agentAccountID === myAgentAccountID) {
                      props.setActiveCasePanel("open");
                      props.setToggleMineCase(true);
                      goToChatRoom();
                    } else {
                      if (props.showOtherCase.configShow) {
                        props.setActiveCasePanel("other");
                        goToChatRoom();
                      } else {
                        let _showOther = { ...props.showOtherCase };
                        _showOther.modalWarningHideOther = true;
                        _showOther.case = dataResultData.openCase;
                        props.setShowOtherCase(_showOther);
                      }
                    }

                    onCloseClick();
                    setIsLoadingWhatsappStatus(false);
                  },
                  onError: (errorCode, errorMessage) => {
                    console.log(errorCode, errorMessage);
                    doToast(errorMessage, "fail");
                    setIsLoadingWhatsappStatus(false);
                  }
                })
              }
            }

            if (
              props.topicListProps[_data.topic.id] &&
              dataResultData.canSend &&
              dataResultData.isValidNumber &&
              dataResultData.openCase.id === 0
            ) {
              onCloseClick();
              props.toggleModalSendingProps(true);
              runSendMessage();
            }
          }
        } else {
          doToast(dataResult.error.message, "fail");

          setIsLoadingWhatsappStatus(false);
        }
      }
      );
    }
  };

  let isDisabledSubmit = () => {
    let _data = { ...data };

    let disabled = false;

    _data.language.parameters.header.map((val) => {
      if (val.value === "") {
        disabled = true;
      }

      if (val.value && (val.value.includes("    ") || val.value.includes("\t") || val.value.includes("&emsp;") || val.value.includes("\n"))) {
        disabled = true;
      }

      return null;
    });

    _data.language.parameters.body.map((val) => {
      if (val.value === "") {
        disabled = true;
      }

      if (val.value && (val.value.includes("    ") || val.value.includes("\t") || val.value.includes("&emsp;") || val.value.includes("\n"))) {
        disabled = true;
      }

      return null
    });

    _data.language.parameters.footer.map((val) => {
      if (val.value === "") {
        disabled = true;
      }

      if (val.value && (val.value.includes("    ") || val.value.includes("\t") || val.value.includes("&emsp;") || val.value.includes("\n"))) {
        disabled = true;
      }

      return null
    });

    if (_data.isDynamicMedia && _data.language.headerText === "") {
      disabled = true;
    }

    return disabled;
  };

  let resetData = () => {
    // let _data = { ...data };
    // _data.language.headerTextNamed = headerText
    // _data.language.bodyTextNamed = bodyText
    // _data.language.footerTextNamed = footerText
    // _data["render"] = false
    // props.resetDataPreviewMessage(_data)
    setData({ render: false });
  };

  // let [headerParams, setHeaderParams] = useState([])
  // let [bodyParams, setBodyParams] = useState([])

  let confirmSendMessage = () => {
    // setHeaderParams([...getHeaderParams()])
    // setBodyParams([...getBodyParams()])
    toggleModalConfirmSendMessage()
  }

  // let getHeaderParams = () => {
  //   let param = "";
  //   let input;
  //   let _headerParams = [];
  //   for (var i = 1; i <= data.language.parameters.header.length; i++) {
  //     input = "preview-header-" + String(i - 1);
  //     input = document.getElementById(input).value;
  //     param = data.language.parameters.header[i - 1];
  //     _headerParams.push({
  //       name: param,
  //       value: input,
  //     });
  //   }

  //   return _headerParams;
  // };

  // let getBodyParams = () => {
  //   let param = "";
  //   let input;
  //   let _bodyParams = [];
  //   for (var i = 1; i <= data.language.parameters.body.length; i++) {
  //     input = "preview-body-" + String(i - 1);
  //     input = document.getElementById(input).value;
  //     console.log(document.getElementById(input))
  //     param = param = data.language.parameters.body.filter(
  //       (item) => item.number === i
  //     )[0];
  //     _bodyParams.push({
  //       name: param.name,
  //       value: input,
  //     });
  //   }

  //   return _bodyParams;
  // };

  let insertBodyVariable = () => {
    let _data = { ...data };
    let sourceText = _data.language.bodyTextNamedOrigin;
    let replace = "";
    let re, input;

    for (var i = 1; i <= data.language.parameters.body.length; i++) {
      input = "preview-body-" + String(i - 1);
      input = document.getElementById(input).value;
      if (input.includes("    ") || input.includes("\t") || input.includes("&emsp;") || input.includes("\n")) {
        doToast(`Parameter {{${data.language.parameters.body[i - 1].name}}} cannot have new-line/tab characters or 4 consecutive spaces`, "fail");
      }

      let variableName = _data.language.parameters.body[i - 1].name;
      replace = `\\{\\{${variableName}\\}\\}`;
      re = new RegExp(replace, "g");
      _data.language.parameters.body[i - 1].value = input;

      if (input) {
        sourceText = sourceText.replace(re, input);
      } else {
        let param = data.language.parameters.body.filter(
          (item) => item.number === i
        )[0];
        sourceText = sourceText.replace(re, `{{${param.name}}}`);
      }
    }

    _data.language.bodyTextNamed = sourceText;
    setData(_data);
  };

  let insertHeaderVariable = () => {
    let _data = { ...data };
    let sourceText = _data.language.headerTextIndexed;
    let replace = "";
    let re, input;

    for (var i = 1; i <= data.language.parameters.header.length; i++) {
      input = "preview-header";
      input = document.getElementById(input).value;

      if (input.includes("    ") || input.includes("\t") || input.includes("&emsp;") || input.includes("\n")) {
        doToast(`Parameter {{${data.language.parameters.header[i - 1].name}}} cannot have new-line/tab characters or 4 consecutive spaces`, "fail");
      }

      replace = `\\{\\{${i}\\}\\}`;
      re = new RegExp(replace, "g");
      _data.language.parameters.header[i - 1].value = input;

      if (input) {
        sourceText = sourceText.replace(re, input);
      } else {
        let param = data.language.parameters.header.filter(
          (item) => item.number === i
        )[0];
        sourceText = sourceText.replace(re, `{{${param.name}}}`);
      }
    }

    _data.language.headerTextNamed = sourceText;
    setData(_data);
  };

  let insertButtonVariable = () => {
    let _data = { ...data };
    // let sourceText = _data.language.headerTextIndexed;
    // let replace = "";
    let input;

    for (var i = 1; i <= data.language.parameters.buttons.length; i++) {
      input = "preview-buttons-" + String(i - 1);
      input = document.getElementById(input).value;

      if (input.includes("    ") || input.includes("\t") || input.includes("&emsp;") || input.includes("\n")) {
        doToast(`Parameter {{${data.language.parameters.header[i - 1].name}}} cannot have new-line/tab characters or 4 consecutive spaces`, "fail");
      }

      // replace = `\\{\\{${i}\\}\\}`;
      // re = new RegExp(replace, "g");
      _data.language.parameters.buttons[i - 1].value = input;

      // if (input) {
      // sourceText = sourceText.replace(re, input);
      // } else {
      // let param = data.language.parameters.buttons.filter(
      //   (item) => item.number === i
      // )[0];
      // sourceText = sourceText.replace(re, `{{${param.name}}}`);
      // }
    }

    // _data.language.headerTextNamed = sourceText;
    setData(_data);
  };

  useEffect(() => {
    if (dataPreviewMessage) {
      let _dataPreviewMessage = { ...dataPreviewMessage };
      _dataPreviewMessage["render"] = true;
      _dataPreviewMessage.language.bodyTextNamedOrigin = _dataPreviewMessage.language.bodyTextNamed;

      //regenerate variable
      _dataPreviewMessage.language.parameters.header.map((val) => {
        val.value = "";
        return null;
      });

      _dataPreviewMessage.language.parameters.body.map((val) => {
        val.value = "";
        return null;
      });

      _dataPreviewMessage.language.parameters.footer.map((val) => {
        val.value = "";
        return null;
      });
      //regenerate variable

      if (
        _dataPreviewMessage.language.headerFormat !== "text" &&
        _dataPreviewMessage.language.headerFormat !== "" &&
        _dataPreviewMessage.language.headerTextNamed === ""
      ) {
        _dataPreviewMessage.isDynamicMedia = true;
      } else {
        _dataPreviewMessage.isDynamicMedia = false;
      }

      setData(_dataPreviewMessage);
    }
  }, [dataPreviewMessage]);

  let [isShowModalConfirmSendMessage, setIsShowModalConfirmSendMessage] = useState(false);

  let toggleModalConfirmSendMessage = () => {
    if (props.newMessage24h) {
      let doc = document.getElementsByClassName("active-modal-preview-message")
      if (doc && doc.length > 0) {
        if (!isShowModalConfirmSendMessage) doc[0].style.zIndex = 9
        else doc[0].style.zIndex = 9999
      }
    }
    setIsShowModalConfirmSendMessage(!isShowModalConfirmSendMessage);
  }

  const getHeaderValue = (item) => {
    if (item.headerFormat === BROADCAST_HEADER_FORMAT.image) {
      if (item.headerTextNamed) return item.headerTextNamed
      else if (item.parameters.header
        && item.parameters.header[0]
        && item.parameters.header[0].name) return `{{${item.parameters.header[0].name}}}`
      else return ""
    } else {
      if (item.headerTextNamed) return item.headerTextNamed
      else return ""
    }
  }

  let doUpload = (file) => {
    setIsUploading(true);

    let uploadData = {
      file: file,
      caption: "",
      room: ""
    };

    tapCoreMessageManager.uploadChatFile(uploadData, {
      onProgress: (percentage, bytes) => {
        setPercentageUpload(percentage);

        if (percentage === 100) {
          setPercentageUpload(false);
        }
      },

      onSuccess: (response) => {
        if (response) {
          setIsUploading(false);
          let _data = { ...data };
          _data.language.file = file;
          _data.language.headerText = response.fileURL;
          _data.language.parameters.header[0].value = response.fileURL;
          setData(_data)
        }
      },

      onError: (error) => {
        doToast(error, "fail");
        console.log(error);
      }
    })
  }

  let taptalkAuthTicket = (file) => {
    let _data = { ...data };
    _data.language.file = file;
    setData(_data)

    ChatService.postRequestAuthTicket(props.orgIDProps, (response) => {
      if (response.dataResult.error.code === "") {
        taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
          onSuccess: () => {
            doUpload(file);
          },
          onError: (errorCode, errorMessage) => {
            doToast(errorMessage, 'fail')
            console.log(errorCode, errorMessage);
          }
        })
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let runTaptalkRequestAuthTicket = (file) => {
    ChatService.postProjectConfig(props.orgIDProps, (response) => {
      let data = response.dataResult.data.tapTalk;

      if (response.dataResult.error.code === "") {
        taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
        taptalkAuthTicket(file);
      } else {
        doToast(response.dataResult.error.message, "fail");
      }
    });
  }

  let runUpload = (file) => {
    let _data = { ...data };
    _data.language.file = file;
    setData(_data)

    setPercentageUpload(1);

    if (props.withoutRequestAuthTicketProps) {
      doUpload(file);
    } else {
      runTaptalkRequestAuthTicket(file);
    }
  }

  let onChangeFile = (e, sizeLimit) => {
    let files = [];
    files.push(e.target.files[0]);

    HelperUpload.checkFileAllowed(files, {
      onAllowed: () => {
        HelperUpload.checkFileLimit(files, {
          onAllowed: () => {
            HelperUpload.checkFileSizeLimitForMedia([], files, {
              onAllowed: (arrayOfMedia) => {
                let _data = { ...data };
                _data.language.file = arrayOfMedia[0];
                setData(_data)

                runUpload(arrayOfMedia[0])
              },
              onReachLimit: (message) => {
                doToast(message, "fail");
              }
            }, sizeLimit)
          },
          onReachLimit: (message) => {
            doToast(message, "fail");
          }
        })
      },
      onFileTypeDidntMatch: (message) => {
        doToast(message, "fail");
      }
    },
      data.headerFormat ?
        (data.headerFormat === "image" ? "image" : (data.headerFormat === "video" ? "video" : "pdf"))
        :
        (
          data.language.headerFormat === "image" ? "image" : (data.language.headerFormat === "video" ? "video" : "pdf")
        )
    );

    e.target.value = null;
  }

  return (
    <div
      className={`modal-preview-message ${
        props.isShowModalPreviewMessageProps
          ? "active-modal-preview-message"
          : ""
        } `}
    >
      <ModalSendTemplatedMessage
        action={onSubmitNewConversation}
        isLoading={isLoadingWhatsappStatus}
        isShow={isShowModalConfirmSendMessage}
        toggle={toggleModalConfirmSendMessage}
      />

      <div
        className={`modal-body ${
          props.isShowModalPreviewMessageProps
            ? "action-modal-body-preview-message"
            : ""
          }`}
      >
        <div onClick={() => onCloseClick(false)} className="close-modal-start-conversation">
          <FiX />
        </div>

        <div className="preview-message-wrapper">
          <Scrollbars
            autoHideTimeout={500}
            renderThumbVertical={(props) => (
              <div {...props} style={style.scrollStyle} />
            )}
          >
            <div className="preview-message-container">
              <BackButton
                text="Back to New Message"
                onClick={() => {
                  props.toggleModalPreviewMessageProps(false);
                  props.toggleModalStartConversationProps(props.newMessage24h ? false : props.type);
                }}
              />
              <div className="preview-message-title">
                <FiEye />

                <b>Preview Message</b>
                <p>Below is a preview of what your recipient will receive.</p>
              </div>

              {data.render && (
                <>
                  <div className="preview-message-body">
                    <WhatsappPreview
                      header={getHeaderValue(data.language)}
                      body={data.language.bodyTextNamed}
                      footer={data.language.footerTextNamed}
                      template={(data.language.headerFormat === "text" || data.language.headerFormat === "") ? "text" : (data.isDynamicMedia ? "dynamic-media" : "static-media")}
                      language={data.language}
                    />
                  </div>

                  <div className="preview-message-params">
                    {/* header params */}
                    {data.language.parameters.header.length > 0 &&
                      <div className="preview-message-param-wrapper">
                        {(data.isDynamicMedia || data.language.parameters.header[0].type === "text") &&
                          <div className="preview-message-param-title">
                            <b>Header Parameter</b>
                            <span>({data.language.parameters.header.length})</span>
                            <div className="line"></div>
                          </div>
                        }

                        {data.language.parameters.header[0].type === "text" &&
                          <div className="preview-message-param">
                            <label>
                              <b>{`{{${data.language.parameters.header[0].name}}}`}</b>
                            </label>

                            <div className="input-param-wrapper">
                              <input
                                className="preview-message-input"
                                id={"preview-header"}
                                placeholder="E.g John Ham"
                                onChange={insertHeaderVariable}
                                disabled={isLoadingWhatsappStatus}
                                value={data.language.parameters.header[0].value}
                              />
                              <p className="input-info-text">
                                <FiInfo />
                                <b>Cannot have new-line/tab characters or 4 consecutive spaces</b>
                              </p>
                            </div>
                          </div>
                        }

                        {data.isDynamicMedia &&
                          <div className="media-upload-wrapper">
                            <div className="media-upload-wrapper-left">
                              <label>
                                {/* <b>Media</b> */}
                                <b>{`{{${data.language.parameters.header[0].name}}}`}</b>
                              </label>

                              {/* {data.language.file &&
                              <button 
                                className="replace-media light-orange-button" 
                                onClick={() => !isUploading && document.querySelector("#upload-media").click()}
                              >
                                <FiUpload />
                                {!isUploading ?
                                  `Replace ${data.language.headerFormat}`
                                  :
                                  `Uploading ${data.language.headerFormat}`
                                }
                              </button>
                            } */}
                            </div>

                            <div className="media-upload-wrapper-right">
                              <input
                                type="file"
                                id="upload-media"
                                accept={
                                  data.language.headerFormat === "document" ?
                                    "application/pdf"
                                    :
                                    data.language.headerFormat === "image" ?
                                      "image/*"
                                      :
                                      "video/*"
                                }
                                onChange={(e) => onChangeFile(e, data.language.headerFormat === "document" ? WHATSAPP_FILE_SIZE_LIMIT.document(taptalk.getProjectConfigs().core.chatMediaMaxFileSize) : WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))}
                              />

                              {data.language.file ?
                                (data.language.headerFormat === "image" ?
                                  <div className="image-video-wrapper">
                                    <img src={data.language.file.fileSrc} alt="" />

                                    {percentageUpload &&
                                      <div className="progress-wrapper">
                                        <CircularProgressbar value={percentageUpload} />
                                      </div>
                                    }
                                  </div>
                                  :
                                  (data.language.headerFormat === "video" ?
                                    <div className="image-video-wrapper">
                                      <video src={data.language.file.fileSrc} />

                                      {percentageUpload &&
                                        <div className="progress-wrapper">
                                          <CircularProgressbar value={percentageUpload} />
                                        </div>
                                      }
                                    </div>
                                    :
                                    <div className="document-wrapper">
                                      <FiFile />
                                      {data.language.file.name} {percentageUpload ? <b>- {percentageUpload}%</b> : ""}
                                    </div>

                                  )
                                )
                                :
                                <ButtonWithLoadingOrIcon
                                  className="main-button-40 light-orange-button"
                                  text={`Upload ${data.language.headerFormat}`}
                                  position="left"
                                  icon={{
                                    type: "svg",
                                    src: FiUpload
                                  }}
                                  onClickAction={() => document.querySelector("#upload-media").click()}
                                />
                              }

                              <p className="upload-info">
                                {data.language.file &&
                                  <button
                                    className="replace-media light-orange-button"
                                    onClick={() => !isUploading && document.querySelector("#upload-media").click()}
                                  >
                                    <FiUpload />
                                    {!isUploading ?
                                      `Replace ${data.language.headerFormat}`
                                      :
                                      `Uploading ${data.language.headerFormat}`
                                    }
                                  </button>
                                }

                                <FiAlertCircle />
                                {data.language.headerFormat === "image" ?
                                  <b>Allowed file type: image</b>
                                  :
                                  (data.language.headerFormat === "video" ?
                                    <b>Allowed file type: video</b>
                                    :
                                    <b>Allowed file type: pdf</b>
                                  )
                                }
                              </p>
                            </div>
                          </div>
                        }
                      </div>
                    }
                    {/* header params */}

                    {/* body params */}
                    {data.language.parameters.body.length > 0 &&
                      <div className="preview-message-param-wrapper">
                        <div className="preview-message-param-title">
                          <b>Body Parameters</b>
                          <span>({data.language.parameters.body.length})</span>
                          <div className="line"></div>
                        </div>

                        {data.language.parameters.body.map((item, idx) => {
                          return (
                            <div
                              className="preview-message-param"
                              key={`body-param-${idx}`}
                            >
                              <label>
                                <b>{`{{${item.name}}}`}</b>
                              </label>

                              <div className="input-param-wrapper">
                                <input
                                  className="preview-message-input"
                                  id={"preview-body-" + idx}
                                  placeholder="E.g John Ham"
                                  onChange={insertBodyVariable}
                                  disabled={isLoadingWhatsappStatus}
                                  value={data.language.parameters.body[idx].value}
                                />
                                <p className="input-info-text">
                                  <FiInfo />
                                  <b>Cannot have new-line/tab characters or 4 consecutive spaces</b>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    }
                    {/* body params */}

                    {/* button params */}
                    {(data.template.category !== "AUTHENTICATION" && data.language.parameters.buttons.length > 0) &&
                      <div className="preview-message-param-wrapper">
                        <div className="preview-message-param-title">
                          <b>Button Parameters</b>
                          <span>({data.language.parameters.buttons.length})</span>
                          <div className="line"></div>
                        </div>

                        {data.language.parameters.buttons.map((item, idx) => {
                          return (
                            <div
                              className="preview-message-param"
                              key={`buttons-param-${idx}`}
                            >
                              <label>
                                <b>{`{{${item.name}}}`}</b>
                              </label>

                              <div className="input-param-wrapper">
                                <input
                                  className="preview-message-input"
                                  id={"preview-buttons-" + idx}
                                  placeholder="Input value"
                                  onChange={insertButtonVariable}
                                  disabled={isLoadingWhatsappStatus}
                                  value={data.language.parameters.buttons[idx].value}
                                />
                                <p className="input-info-text">
                                  <FiInfo />
                                  <b>Cannot have new-line/tab characters or 4 consecutive spaces</b>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    }
                    {/* button params */}
                  </div>

                  <div className="preview-message-button">
                    <ButtonWithLoadingOrIcon
                      icon={{
                        type: "svg",
                        src: FiCheck
                      }}
                      onClickAction={confirmSendMessage}
                      isDisabled={isDisabledSubmit()}
                      className="orange-button main-button-48"
                      text="Confirm"
                      position="left"
                    />
                  </div>
                </>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  startConversationBa: state.startConversationBa,
  toggleMineCase: state.toggleMineCase,
  caseDetailData: state.caseDetailData,
  activeRoom: state.activeRoom,
  showOtherCase: state.showOtherCase,
  closeCaseNewMessageRecipient: state.closeCaseNewMessageRecipient,
  myAgentData: state.myAgentData
});

const mapDispatchToProps = {
  setActiveCasePanel,
  setStartConversationBa,
  clearStartConversationBa,
  clearStartConversation,
  setToggleMineCase,
  setShowOtherCase,
  setCloseCaseNewMessageRecipient
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewMessage);
