import BaseService from '../BaseService';
import { API_KEY } from '../../config';

class CaseService {
  postProjectConfig(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.GET_PROJECT_CONFIG,
      null,
      headers,
      callback
    );
  }

  postRequestAuthTicket(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REQ_AUTH_TICKET, null, headers, callback);
  }

  postRequestLivechatAuthTicket(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REQ_LIVECHAT_AUTH_TICKET, null, headers, callback);
  }

  postCloseCase(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.CLOSE_CASE,
      data,
      headers,
      callback
    );
  }

  postCloseCaseMulti(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.CLOSE_CASE_MULTI,
      data,
      headers,
      callback
    );
  }

  postAllCaseList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.GET_ALL_CASE,
      data,
      headers,
      callback
    );
  }

  postSolvedCaseList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.SOLVED_CASE_LIST,
      data,
      headers,
      callback
    );
  }

  postOpenCaseList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.OPEN_CASE_LIST, data, headers, callback);
  }

  postTakeCaseAsAgent(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.TAKE_CASE_AS_AGENT,
      data,
      headers,
      callback
    );
  }

  postTakeCaseAsAgentMulti(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.TAKE_CASE_AS_AGENT_MULTI,
      data,
      headers,
      callback
    );
  }

  postReplyToTelegram(body, xcUserID) {
    let header = {
      'Content-Type': 'application/json',
      'Secret-Key': 'dGFwdGFsa2xpdmU6dGVsZWdyYW1jb25uZWN0aW9u',
    };
    let data = {
      data: {
        xcUserID: xcUserID,
        message: body,
      },
    };

    return BaseService.doPostWithoutAccessToken(
      URL_OMNICHANNEL.TELEGRAM,
      data,
      header
    )
      .then((response) => {
        return {
          dataResult: response.data,
        };
      })
      .catch((error) => {
        return BaseService.generateErrorMessage(error);
      });
  }

  postReplyToFbMessenger(body, xcUserID) {
    let header = {
      'Content-Type': 'application/json',
      'Secret-Key': 'dGFwdGFsa2xpdmU6dGVsZWdyYW1jb25uZWN0aW9u',
    };
    let data = {
      data: {
        xcUserID: xcUserID,
        message: body,
      },
    };

    return BaseService.doPostWithoutAccessToken(
      URL_OMNICHANNEL.FBMESSENGER,
      data,
      header
    )
      .then((response) => {
        return {
          dataResult: response.data,
        };
      })
      .catch((error) => {
        return BaseService.generateErrorMessage(error);
      });
  }

  postReplyToTwitter(body, xcUserID) {
    let header = {
      'Content-Type': 'application/json',
      'Secret-Key': 'dGFwdGFsa2xpdmU6dGVsZWdyYW1jb25uZWN0aW9u',
    };
    let data = {
      data: {
        xcUserID: xcUserID,
        message: body,
      },
    };

    return BaseService.doPostWithoutAccessToken(
      URL_OMNICHANNEL.TWITTER,
      data,
      header
    )
      .then((response) => {
        return {
          dataResult: response.data,
        };
      })
      .catch((error) => {
        return BaseService.generateErrorMessage(error);
      });
  }

  postSendMessage(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SEND_MESSAGE, data, headers, callback);
  }

  postAgentList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_AGENT_LIST, data, headers, callback);
  }

  postAssignedAgentList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_ASSIGNED_AGENT_LIST, data, headers, callback);
  }

  postCaseDetail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CASE_DETAIL, data, headers, callback);
  }

  postSetFirstResponse(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.SET_FIRST_RESPONSE,
      data,
      headers,
      callback
    );
  }

  postUpdateUserAlias(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.UPDATE_USER_ALIAS,
      data,
      headers,
      callback
    );
  }

  postUpdateUserRemark(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.UPDATE_USER_REMARK,
      data,
      headers,
      callback
    );
  }

  postUpdateCaseRemark(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(
      API.UPDATE_CASE_REMARK,
      data,
      headers,
      callback
    );
  }

  addLabels(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_LABELS, data, headers, callback);
  }

  removeLabels(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.REMOVE_LABELS, data, headers, callback);
  }

  getBroadcastHistoryList(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_BROADCAST_HISTORY, data, headers, callback);
  }

  updateJunkStatus(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.UPDATE_JUNK_STATUS, data, headers, callback);
  }

  updateJunkStatusMulti(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.UPDATE_JUNK_STATUS_MULTI, data, headers, callback);
  }
  
  handoverCase(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.HANDOVER_CASE, data, headers, callback);
  }

  handoverCaseMulti(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.HANDOVER_CASE_MULTI, data, headers, callback);
  }

  getCaseActionHistory(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CASE_ACTION_HISTORY, data, headers, callback);
  }

  changeTopic(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CHANGE_TOPIC, data, headers, callback);
  }

  postSendMessageEmail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REPLY_EMAIL, data, headers, callback);
  }
}

const API = {
  GET_PROJECT_CONFIG: '/project/configs/get',
  REQ_LIVECHAT_AUTH_TICKET: '/organization/onetalk/request_live_chat_auth_ticket',
  REQ_AUTH_TICKET: '/project/taptalk/request_auth_ticket',
  CLOSE_CASE: '/organization/case/close',
  CLOSE_CASE_MULTI: '/organization/case/close_multiple',
  GET_ALL_CASE: '/organization/case/get_list',
  SOLVED_CASE_LIST: '/organization/case/get_closed_list',
  OPEN_CASE_LIST: '/organization/case/get_open_list/v1_6',
  TAKE_CASE_AS_AGENT: '/organization/case/take_as_agent',
  TAKE_CASE_AS_AGENT_MULTI: '/organization/case/take_as_agent_multiple',
  GET_AGENT_LIST: '/organization/agent/get_list_for_filter',
  GET_ASSIGNED_AGENT_LIST: '/organization/agent/get_list_for_filter',
  GET_CASE_DETAIL: '/organization/case/get_details',
  SET_FIRST_RESPONSE: '/organization/case/set_first_response',
  SEND_MESSAGE: '/organization/case/send_message',
  UPDATE_USER_ALIAS: '/organization/user/update_alias',
  UPDATE_USER_REMARK: '/organization/user/update_agent_remark',
  UPDATE_CASE_REMARK: '/organization/case/update_agent_remark',
  ADD_LABELS: '/organization/case/add_labels',
  REMOVE_LABELS: '/organization/case/remove_labels',
  GET_BROADCAST_HISTORY: "/organization/user/get_broadcast_history",
  UPDATE_JUNK_STATUS: "/organization/case/update_junk_status",
  UPDATE_JUNK_STATUS_MULTI: "/organization/case/update_junk_status_multiple",
  GET_CASE_ACTION_HISTORY: "/organization/case/get_log",
  HANDOVER_CASE: "/organization/case/handover",
  HANDOVER_CASE_MULTI: "/organization/case/handover_multiple",
  CHANGE_TOPIC: "/organization/case/update_topic",
  REPLY_EMAIL: "/organization/case/reply_message_email"
};

const URL_OMNICHANNEL = {
  TELEGRAM: 'https://hook.integromat.com/5tmftitv8sjvbe5ugt5ultb8dotqm32r',
  FBMESSENGER: 'https://hook.integromat.com/ycye8scbgolj13h8hrajcu9efxrircaq',
  TWITTER: 'https://hook.integromat.com/c8xfcxnxwgzhpkpdlwi7mt4itiu87hzj',
};

export default new CaseService();
