import React, { useState } from "react";
import "./Billing.scss";
import { TabContent, Nav, NavItem } from 'reactstrap';
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import Blocking401 from "../../reuseableComponent/blocking401/Blocking401";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import Subscriptions from "./subscriptions/Subscriptions";
// import Transactions from "./transactions/Transactions";
import PaymentSettings from "./paymentSettings/PaymentSettings";
import BillingNotification from "./billingNotification/BillingNotification";
import { connect } from 'react-redux';

let Billing = (props) => {
    const BILLING_TABS = {
        subscriptions: {
            id: "subscriptions",
            tab: "Subscriptions",
            component: <Subscriptions {...props} />
        },
        // transactions: {
        //     id: "transactions",
        //     tab: "Transactions",
        //     component: <Transactions {...props} />
        // },
        paymentSettings: {
            id: "paymentSettings",
            tab: "Payment Settings",
            component: <PaymentSettings {...props} />
        },
        billingNotification: {
            id: "billingNotification",
            tab: "Billing Notification",
            component: <BillingNotification {...props} />
        }
    }

    let [activeTab, setActiveTab] = useState(BILLING_TABS.subscriptions.id);
    let { match, history, location } = props;
    let [orgID, setOrgID] = React.useState(false);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const doMount = async () => {
        const paramID = checkID(match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    React.useEffect(() => {
        doMount();
    }, [location.pathname]);

    React.useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.whichTab) {
                setActiveTab(BILLING_TABS[props.history.location.state.whichTab].id);
            }

            if (props.history.location.state.message) {
                doToast(props.history.location.state.message);
            }

            window.history.pushState(null, '');
        }
    }, [props.history])

    React.useEffect(() => {
        if(props.allOrganizationDetail) {
            if(!props.allOrganizationDetail.paymentSettings.isSet && props.allOrganizationDetail.selectedTier.id === 0) {
                props.history.push(`/o/${props.match.params.orgID}/home`);
            }
        }
    }, [props.allOrganizationDetail])

    return (
        <React.Fragment>
            {process.env.REACT_APP_HIDE_BILLING === "true" ?
                <SectionWrap
                    {...props}
                    orgID={orgID}
                    withHeader
                    withSideMenu
                >
                    <Blocking401 {...props} />
                </SectionWrap>
                :
                <SectionWrap
                    {...props}
                    orgID={orgID}
                    withHeader
                    withSideMenu
                >
                    <div className="billing-page-wrapper">
                        <Nav tabs>
                            {Object.keys(BILLING_TABS).map((value, index) => {
                                return (
                                    <NavItem key={`tab-setup-${index}`} className={`${activeTab === BILLING_TABS[value].id ? 'active-setup-tab' : ''}`}>
                                        <div className="setup-tab-wrapper"
                                            onClick={() => { toggle(BILLING_TABS[value].id); }}
                                        >
                                            <b>{BILLING_TABS[value].tab}</b>
                                        </div>
                                    </NavItem>
                                )
                            })}
                        </Nav>

                        <TabContent>
                            {BILLING_TABS[activeTab].component}
                        </TabContent>
                    </div>
                </SectionWrap>
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    allOrganizationDetail: state.allOrganizationDetail
});
  
export default connect(mapStateToProps, null)(Billing);