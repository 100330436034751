const INITIAL_STATE = false;

let reduxReducerMembership = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_MEMBERSHIP_DATA':
        return action.membershipData;
      case 'CLEAR_MEMBERSHIP_DATA':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerMembership;