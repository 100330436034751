import React, { useEffect, useState } from "react";
import "./PayNowTransfer.scss";
import { FiAlertCircle, FiCopy, FiInfo } from "react-icons/fi";
import { checkID, doToast } from "../../../../../helper/HelperGeneral";
// import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
import Blocking401 from "../../../../reuseableComponent/blocking401/Blocking401";
import SectionWrap from "../../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import iconBca from "../../../../../assets/img/payment-bank/icon-bca.png";
// import iconMandiri from "../../../../../assets/img/payment-bank/icon-mandiri.png";
// import mixpanel from "mixpanel-browser";

const PayNowTransfer = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);

  const back = () => history.push(`/o/${orgID}/organization/billing/pay-now`);

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  const copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(orgID) {
      // getTopic();
    }
  }, [orgID])

  // let getTopic = () => {
    // TopicService.getTopic(orgID, "list", null, (response) => {
    //   if(response.dataResult.error.message ==="") {
    //     setTopicList(response.dataResult.data.topics)
    //   }
    // });
  // }

  // const onSubmitInviteMember = (e) => {
    // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    // e.preventDefault();
    // setIsWaitResponseInviteMember(true);

    // let dataMemberID = {
    //   email: memberEmail,
    //   roleCode: memberRole
    // };

    // MemberService.addMember(orgID, dataMemberID, (response) => {
    //   if (response.dataResult.status === 200) {
    //     if(response.dataResult.data.success) {
          
    //     }else {
    //       doToast(response.dataResult.data.message, 'fail');
    //     }
  
    //     setIsWaitResponseInviteMember(false);
    //   }else {
    //     doToast(response.dataResult.error.message, 'fail');
    //     setIsWaitResponseInviteMember(false);
    //   }
    // });
  // }

  const isButtonSubmitDisabled = () => {
    // return (
    //   (memberEmail.length === 0 || memberRole.length === 0 || !HelperInput.emailFormatValid(memberEmail)) ? true : false
    // )
  }

  // let convertCardMasking = (cardNumber) => {
  //   return cardNumber.replace(/X/g, "<div class='masking-dot'></div>");
  // }

  return (
    <>
      {process.env.REACT_APP_HIDE_BILLING === "true" ?
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >
            <Blocking401 {...props} />
        </SectionWrap>
        :
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >   
            <BackButton text="Back to Pay Now" onClick={back} />
            
            <div className="sectionWrap pay-bank-transfer-wrapper">
                <div className="form-title-wrapper">
                    <b>Pay Transfer</b>
                </div>
                
                <div className="form-content-wrapper">
                    <form onSubmit={(e) => {
                      // onSubmitInviteMember(e);
                    }}>
                        <p className="please-transfer-to">
                          <b>Please Transfer to The Following Account</b>
                        </p>

                        <label><b>PT Tap Talk Teknologi</b></label>
                        <div className="bank-transfer-box bank-account" onClick={() => copyToClip("account-number", "Account Number Copied")}>
                          <input type="text"
                                value={`123456789`}
                                // onClick={copyToClip}
                                className="display-none"
                                id="account-number"
                          /> 

                          <img src={iconBca} alt="" />
                          <b>
                            123456789
                          </b>

                          <FiCopy />
                        </div>

                        <label><b>Amount Due</b></label>
                        <div className="bank-transfer-box" onClick={() => copyToClip("transfer-amount", "Transfer Amount Copied")}>
                          <input type="text"
                                value={`2888888`}
                                // onClick={copyToClip}
                                className="display-none"
                                id="transfer-amount"
                          /> 
                          <b id="transfer-amount">
                            Rp 2.888.8888
                          </b>

                          <FiCopy />
                        </div>

                        <p className="orange-warning">
                          <FiAlertCircle />
                          <b>Make sure you transfer the exact amount.</b>
                        </p>

                        <div className="integration-whatsapp-checkout-box-form">
                          <div className="alert-component alert-blue alert-checkout">
                            <FiInfo />
                            <b>
                              Once you have completed payment please send payment proof to sales@taptalk.io 
                              to confirm. Please allow up to 24 hours for you payment to be verified.
                            </b>
                          </div>
                        </div>
                        
                        <button className="orange-button button-continue" disabled={isButtonSubmitDisabled()}>
                            I Have Completed Payment
                        </button>
                    </form>
                    
                    {/* {isWaitResponseInviteMember &&
                      <button className="orange-button button-send-invitation">
                                <div className="lds-ring">
                                  <div /><div /><div /><div />
                                </div>
                      </button>
                    } */}
                </div>
            </div>
        </SectionWrap>
      }
    </>
  );
};

export default PayNowTransfer;