import React, { useState, useEffect } from 'react';
import './IntegrationKataaiEditConfig.scss';
import { FiInfo } from 'react-icons/fi';
import IntegrationKataaiServices from '../../../../services/integration/IntegrationKataaiServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
// import CustomSelectOption from '../../../reuseableComponent/customSelectOption/CustomSelectOption';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
// import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import HelperInput from '../../../../helper/HelperInput';

let IntegrationKataaiEditConfig = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [editData, setEditData] = useState({
    url: "",
  })
  let [editDataOrigin, setEditDataOrigin] = useState({
    url: "",
  })
  let [errorMessageData, setErrorMessageData] = useState({
    url: "",
  })

  useEffect(() => {
    doMount();
  }, []);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getAccountDetail(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getAccountDetail = (orgID) => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: parseInt(props.match.params.accountID)
    }

    IntegrationKataaiServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setEditData({
          url: response.dataResult.data.configs.kataURL
        });
        setEditDataOrigin({
          url: response.dataResult.data.configs.kataURL
        });
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let editAccount = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    if (editData.url === "") {
      _errorMessageData.url = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-url" }
    }

    if (!HelperInput.validURL(editData.url)) {
      _errorMessageData.url = "Invalid URL format";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-url" }
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      setIsLoadingSubmit(true);

      let data = {
        id: parseInt(props.match.params.accountID),
        kataURL: editData.url
      }

      IntegrationKataaiServices.editURL(
        orgID,
        data,
        (response) => {
          if (response.dataResult.status === 200) {
            if (!response.dataResult.data.success) {
              doToast(response.dataResult.data.message);
              setIsLoadingSubmit(false);
            } else {
              if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Edit URL",
                  {
                    channel: "Kata.ai",
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    organizationID: orgID.split("-")[0]
                  }
                );
              }

              setEditDataOrigin(editData);

              setTimeout(() => {
                history.push(
                  `/o/${orgID}/integration/kataai/detail/${match.params.accountID}`,
                  {
                    state: 'edit-detail',
                    message: response.dataResult.data.message,
                  }
                );
              }, 3000)
              doToast(response.dataResult.data.message);
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setIsLoadingSubmit(false);
          }
        }
      );
    }
  };

  let back = () => { history.push(`/o/${orgID}/integration/kataai/detail/${props.match.params.accountID}`) };

  let onChangeText = (e) => {
    let _editData = { ...editData };
    let _errorMessageData = { ...errorMessageData };

    _editData.url = e.target.value;
    _errorMessageData.url = "";

    setEditData(_editData);
    setErrorMessageData(_errorMessageData);
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify(editDataOrigin)}
        dataAfterChange={JSON.stringify(editData)}
      >
        <div className="sectionWrap integration-main-container integration-kataai-edit-config-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <>
              <BackButton
                text={`Back to Channel Details`}
                onClick={back}
              />

              <div className="integration-kataai-edit-config-account-box">
                <div className="integration-kataai-edit-config-account-box-top">
                  <b>Edit Channel Configs</b>

                  {!isLoadingSubmit ? (
                    <ButtonWithLoadingOrIcon
                      // isDisabled={!isFormValid()}
                      onClickAction={editAccount}
                      className="orange-button main-button-40 minWidth180"
                      text="Save Changes"
                      position="left"
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      className="orange-button main-button-40 minWidth180"
                      text="Save Changes"
                      position="left"
                    />
                  )}
                </div>
                <div className="integration-kataai-edit-config-account-box-form">
                  <div className="form-detail">
                    <label>
                      <b>Kata URL</b>
                    </label>
                    <input type="text" disabled={isLoadingSubmit} value={editData.url} onChange={(e) => { onChangeText(e) }} className={`input-url ${errorMessageData.url === "" ? "" : "border-red"}`} placeholder="Input Kata URL" />

                    {errorMessageData.url !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.url}
                        _className={"font-red"}
                        icon={<FiInfo />}
                      />
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationKataaiEditConfig;
