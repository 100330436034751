import React, { useState, useEffect } from "react";
import "../../../auth/Auth.css";
import "../../QuickReplyAdd.scss";
import { FiInfo, FiUploadCloud, FiFile } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceQuickReply from "../../../../services/newServices/QuickReplyService";
// import HelperInput from "../../../helper/HelperInput";
import HelperQuickReply from "../../../../helper/HelperQuickReply";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

let QuickReplyPersonalEdit = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [lastChangeMediaData, setLastChangeMediaData] = useState({
    lastChange: false,
    data: false,
    mediaURL: "",
    mediaFilename: ""
  });
  let [mediaData, setMediaData] = React.useState(null);
  // let [previousEditQuickReplyData, setPreviousEditQuickReplyData] = useState({
  //   id: "",
  //   message: "",
  //   shortcut: "",
  //   messageType: "",
  //   mediaFileID: null,
  //   mediaURL: ""
  // });
  let [editQuickReplyData, setEditQuickReplyData] = useState({
    id: "",
    message: "",
    shortcut: "",
    messageType: "",
    mediaFileID: null,
    mediaURL: ""
  });
  let [editQuickReplyDataOrigin, setEditQuickReplyDataOrigin] = useState({
    id: "",
    message: "",
    shortcut: "",
    messageType: "",
    mediaFileID: null,
    mediaURL: ""
  });
  let [isWaitResponseEditQuickReply, setIsWaitResponseEditQuickReply] = useState(false);
  let [loadingUpload, setLoadingUpload] = React.useState(false);
  let [errorMessageData, setErrorMessageData] = useState({
    file: "",
    message: "",
    shortcut: ""
  })

  let back = () => {
    history.push(`/o/${orgID}/setup/quick-reply`, {
      tab: "personalQuickReply"
    });
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // let { id, createdTime } = responseData.memberships[0].organization;
          // let urlOrgID = `${id}-${createdTime}`;
          back();
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if(lastChangeMediaData.lastChange) {
      let _editQuickReplyData = {...editQuickReplyData};
      _editQuickReplyData.messageType = lastChangeMediaData.data.messageType;
      _editQuickReplyData.mediaFileID = lastChangeMediaData.data.mediaFileID;
      _editQuickReplyData.mediaURL = lastChangeMediaData.data.mediaURL;
      _editQuickReplyData.mediaFilename = lastChangeMediaData.data.mediaFilename;

      setEditQuickReplyData(_editQuickReplyData);
    }
  }, [lastChangeMediaData])

  useEffect(() => {
    if(props.history.location.state) {
        if(props.history.location.state.message) {
          let _editQuickReplyData = {
            message: props.history.location.state.message,
            shortcut: props.history.location.state.shortcut.replace("/", ""),
            id: props.history.location.state.id,
            ownerType: HelperQuickReply.ownerTypePersonal,
            mediaURL: props.history.location.state.mediaURL,
            mediaFilename: props.history.location.state.mediaFilename,
            messageType: props.history.location.state.messageType
          };

          setEditQuickReplyData(_editQuickReplyData);
          setEditQuickReplyDataOrigin(_editQuickReplyData);
          // setPreviousEditQuickReplyData(_editQuickReplyData);
        }
    }
  }, [props.history])

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  let onChangeAddQuickReplyInput = (e) => {
    let _editQuickReplyData = {...editQuickReplyData};
    let _errorMessageData = {...errorMessageData};
    
    
    _errorMessageData[e.target.id] = "";

    if(e.target.id === "shortcut") {
      _editQuickReplyData[e.target.id] = e.target.value.replaceAll(" ", "");
    }else {
      _editQuickReplyData[e.target.id] = e.target.value;
    }
   
    setEditQuickReplyData(_editQuickReplyData);
    setErrorMessageData(_errorMessageData);
  };

  let onSubmitEditQuickReply = (e) => {
    let _editQuickReplyData = {...editQuickReplyData};
    let errorCount = 0;
    let scrollToError = "";
    let _errorMessageData = {...errorMessageData}

    if(editQuickReplyData.messageType !== "text" && editQuickReplyData.messageType !== "") {
      if(_editQuickReplyData.mediaFileID === null) {
        _errorMessageData.file = "This field is required"
        errorCount = errorCount + 1;

        if(scrollToError === "") { scrollToError = `input-file`}
      }
    }

    if(_editQuickReplyData.message === "") {
      _errorMessageData.message = "This field is required"
      errorCount = errorCount + 1;

      if(scrollToError === "") { scrollToError = `input-message`}
    }

    if(_editQuickReplyData.message.length > 1000) {
      _errorMessageData.message = "Characters exceed limit"
      errorCount = errorCount + 1;

      if(scrollToError === "") { scrollToError = `input-message`}
    }

    if(_editQuickReplyData.shortcut === "") {
      _errorMessageData.shortcut = "This field is required"
      errorCount = errorCount + 1;

      if(scrollToError === "") { scrollToError = `input-shortcut`}
    }

    if(_editQuickReplyData.shortcut.length > 50) {
      _errorMessageData.shortcut = "Characters exceed limit"
      errorCount = errorCount + 1;

      if(scrollToError === "") { scrollToError = `input-shortcut`}
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${scrollToError}`);
    }else {
      // e.preventDefault();
      setIsWaitResponseEditQuickReply(true);

      ServiceQuickReply.editQuickReply(orgID, _editQuickReplyData, (response) => {
        let dataResult = response.dataResult;
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        setIsWaitResponseEditQuickReply(false);

        if(dataResult.error.message === "") {
          if(dataResult.data.success) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
              mixpanel.track(
                "[Action] Edit Quick Reply",
                {
                  type : "Personal",
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
                }
              )
            }
            
            setEditQuickReplyDataOrigin(editQuickReplyData);
            setTimeout(() => {
              history.push({
                pathname: `/o/${orgID}/setup/quick-reply`,
                state: {
                  message: response.dataResult.data.message,
                  success: response.dataResult.data.success,
                  tab: "personalQuickReply"
                }
              })
            }, 0)
          }else {
            doToast(dataResult.data.message, "fail");  
          }
        }else {
          doToast(dataResult.error.message, "fail");
        }
      })
    }
  }

  let isButtonSubmitDisabled = () => {
    // let alphanumbericAndUnderscore =  /^[a-zA-Z0-9_]+$/;
    // let _editQuickReplyData = {...editQuickReplyData};
    // let _previousEditQuickReplyData = {...previousEditQuickReplyData};
    // let isContainSpace = _editQuickReplyData.shortcut.includes(" ");

    // return (
    //   (_editQuickReplyData.message.length === 0 || 
    //    _editQuickReplyData.shortcut.length === 0 || 
    //    _editQuickReplyData.shortcut === "/" ||
    //    !alphanumbericAndUnderscore.test(_editQuickReplyData.shortcut) ||
    //    (_previousEditQuickReplyData.message === _editQuickReplyData.message &&
    //    _previousEditQuickReplyData.shortcut === _editQuickReplyData.shortcut) ||
    //    isContainSpace) ? 
    //    true : false
    // )

    return false;
  }

  let onChangeMedia = (e) => {
    let fileType = e.target.files[0].type.split("/")[0]; 
    let fileExt = e.target.files[0].type.split("/")[1];
    let quickReplyType = editQuickReplyData.messageType;
    let _editQuickReplyData = {...editQuickReplyData};

    if(quickReplyType === "file") {
      if(fileExt === "pdf") {
        setMediaData(URL.createObjectURL(e.target.files[0]));
        _editQuickReplyData.mediaFilename = e.target.files[0].name;
        setEditQuickReplyData(_editQuickReplyData);
        uploadMedia(e.target.files[0], "file");
      }else {
        doToast("Only pdf file are allowed", "fail");
      }
    }else if(editQuickReplyData.messageType === "image") {
        if(fileType === "image") {
          if(fileExt === "gif" || fileExt === "jpeg" || fileExt === "png") {
            setMediaData(URL.createObjectURL(e.target.files[0]));
            _editQuickReplyData.messageType = "image";
            setEditQuickReplyData(_editQuickReplyData);
            uploadMedia(e.target.files[0], "image");
          }else {
            doToast("The following image formats are accepted: image/gif, image/jpeg, image/png", "fail");
          }
        }else {
          doToast("The following image formats are accepted: image/gif, image/jpeg, image/png", "fail");
        }
    }else {
      if(fileType === "video") {
        setMediaData(URL.createObjectURL(e.target.files[0]));
        _editQuickReplyData.messageType = "video";
          setEditQuickReplyData(_editQuickReplyData);
        uploadMedia(e.target.files[0], "video");
      }else {
        doToast("Allowed file types: video", "fail");
      }
    }

    e.target.value = null;
  }

  let uploadMedia = (file, type) => {
    setLoadingUpload(true);

    var formData = new FormData();
    formData.append("fileType", type);
    formData.append("file", file);

    ServiceQuickReply.uploadMedia(orgID, formData, (response) => {
      // let _editQuickReplyData = {...editQuickReplyData};
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
          // _editQuickReplyData.messageType = type;
          // _editQuickReplyData.mediaFileID = dataResult.data.id;
          // _editQuickReplyData.mediaURL = "";
          // _editQuickReplyData.mediaFilename = file.name;
          setLastChangeMediaData({
            lastChange: new Date().valueOf(),
            data: {
              messageType: type,
              mediaFileID: dataResult.data.id,
              mediaURL: "",
              mediaFilename: file.name
            }
          })

          setLoadingUpload(false);
          // setEditQuickReplyData(_editQuickReplyData);
      }else {
        setMediaData(false);
        doToast(dataResult.error.message, "fail");
      }

      setLoadingUpload(false);
    });
  }

  return (
    <React.Fragment>
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={editQuickReplyDataOrigin}
            dataAfterChange={editQuickReplyData}
        >   
            <BackButton text="Back to Personal Quick Replies" onClick={back} />

            <div className="sectionWrap form-add-quick-reply">
                {/* <form onSubmit={(e) => {
                      onSubmitEditQuickReply(e);
                }}> */}
                    <div className="form-title-wrapper">
                        <b>Edit Personal Quick Reply</b>

                        {isWaitResponseEditQuickReply ?
                          <ButtonWithLoadingOrIcon 
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            className="orange-button main-button-40"
                            text="Edit Quick Reply"
                            position="left"
                          />
                          :
                          <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-40"
                            text="Edit Quick Reply"
                            isDisabled={isButtonSubmitDisabled()}
                            onClickAction={onSubmitEditQuickReply}
                          />    
                        }
                    </div>
                
                    <div className="form-content-wrapper">
                      <label>
                        <b>Content Type</b>
                      </label>

                      {editQuickReplyData.messageType === "text" ? "Text" : (editQuickReplyData.messageType === "file" ? "Document" : "Media")}
                      
                      {editQuickReplyData.messageType !== "text" &&
                        <>
                          <label>
                            <b>{editQuickReplyData.messageType === "file" ? "Document" : "Media"}</b>
                          </label>

                          {(!mediaData && editQuickReplyData.mediaURL === "") &&
                            <ButtonWithLoadingOrIcon 
                              className="main-button-40 light-orange-button upload-media-button"
                              position="left"
                              text="Upload Media"
                              icon={{
                                type: "svg",
                                src: FiUploadCloud
                              }}
                              onClickAction={() => {
                                let target = document.querySelector("#quick-reply-file");

                                target.click();
                              }}
                            />
                          }

                          <input type="file" id="quick-reply-file" accept={editQuickReplyData.messageType === "file" ? "application/pdf" : (editQuickReplyData.messageType === "video" ? "video/*" : "image/gif, image/png, image/jpeg")} onChange={(e) => onChangeMedia(e)} />
                          
                          {(mediaData || editQuickReplyData.mediaURL !== "") &&
                            <>
                              <div className="media-action-wrapper">
                                <button 
                                  className="light-orange-button" 
                                  onClick={() => {
                                    let target = document.querySelector("#quick-reply-file");

                                    target.click();
                                  }}
                                  disabled={loadingUpload || isWaitResponseEditQuickReply}
                                >
                                  <FiUploadCloud />
                                  Replace {editQuickReplyData.messageType === "file" ? "Document" : "Media"}
                                </button>

                                {/* <button 
                                  className="red-button" 
                                  disabled={loadingUpload}
                                  onClick={() => {
                                    let _editQuickReplyData = {...editQuickReplyData};
                                    _editQuickReplyData.messageType = "";
                                    _editQuickReplyData.mediaFileID = null;
                                    _editQuickReplyData.mediaURL = "";

                                    setMediaData(null);
                                    setEditQuickReplyData(_editQuickReplyData);
                                  }}
                                >
                                  <FiTrash2 />
                                  Delete Media
                                </button> */}
                              </div>
                              
                              {/* <div className="media-image-wrapper">
                                <img src={editQuickReplyData.mediaURL !== "" ? editQuickReplyData.mediaURL : URL.createObjectURL(mediaData)} alt="" />

                                {loadingUpload &&
                                  <div className="lds-ring-wrapper">
                                    <div className="lds-ring">
                                      <div /><div /><div /><div />
                                    </div>
                                  </div>
                                }
                              </div> */}

                              {editQuickReplyData.messageType === "file" ?
                                  <div className="file-wrapper">
                                    <FiFile />

                                    <p>
                                      {editQuickReplyData.mediaFilename}
                                    </p>
                                    
                                    {loadingUpload &&
                                      <div className="lds-ring">
                                        <div /><div /><div /><div />
                                      </div>
                                    }
                                  </div>
                                  :
                                  <div className="media-image-wrapper">
                                    {editQuickReplyData.messageType === "image" ?
                                      <img src={mediaData ? mediaData : editQuickReplyData.mediaURL} alt="" />
                                      :
                                      <video src={mediaData ? mediaData : editQuickReplyData.mediaURL} />
                                    }

                                    {loadingUpload &&
                                      <div className="lds-ring-wrapper">
                                        <div className="lds-ring">
                                          <div /><div /><div /><div />
                                        </div>
                                      </div>
                                    }
                                  </div>
                                }
                            </>
                          }
                          
                          <ErrorOrInfoComp 
                            text={`Allowed file types: ${editQuickReplyData.messageType === "file" ? "pdf" : (editQuickReplyData.messageType === "image" ? "image (jpeg, png, gif)" : "video")}`}
                            icon={<FiInfo />}
                            _className=""
                          />
                        </>
                      }

                      <label>
                        <b>Message</b>

                        <span className={errorMessageData.message === "Characters exceed limit" ? "red-text" : ""}>{editQuickReplyData.message.length}/1000</span>
                      </label>
                      <textarea disabled={isWaitResponseEditQuickReply} type="text" placeholder="Type Message Here" id="message" className={`input-message ${errorMessageData.message !== "" ? "border-red" : ""}`} value={editQuickReplyData.message} onChange={(e) => {
                          onChangeAddQuickReplyInput(e);
                      }}/>

                      {errorMessageData.message !== "" &&
                        <ErrorOrInfoComp 
                          text={errorMessageData.message}
                          icon={<FiInfo />}
                          _className="red-text margin-top-0"
                        />
                      }

                      <p className="input-info-wrapper">
                          This is the message that will appear after you select a specific shortcut. <b>No blank space are allowed at the beginning and end of a message.</b>
                      </p>

                      <label className="pos-relative">
                        <b>Shortcut</b>
                        <span className={errorMessageData.shortcut === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{editQuickReplyData.shortcut.length}/50 characters</span>
                      </label>
                      <div className="quick-reply-prefix disabled-input">
                        {HelperQuickReply.prefixShortcut}
                      </div>

                      <input disabled={isWaitResponseEditQuickReply} type="text" placeholder="example" id="shortcut" className={`input-shortcut ${errorMessageData.shortcut !== "" ? "border-red" : ""}`} value={editQuickReplyData.shortcut} onChange={(e) => {
                        onChangeAddQuickReplyInput(e);
                      }}/>
                      
                      <div className="quick-reply-suffix disabled-input">
                        {HelperQuickReply.suffixShortcut}
                      </div>

                      {errorMessageData.shortcut !== "" &&
                        <ErrorOrInfoComp 
                          text={errorMessageData.shortcut}
                          icon={<FiInfo />}
                          _className="red-text margin-top-0"
                        />
                      }

                      <p className="input-info-wrapper">
                          This is the message that will appear after you select a specific shortcut.
                          <b> Only alphanumberic and underscore are allowed. </b>
                          Your shortcut will be displayed like this "/shortcut"
                          (Tip: keep it short and simple).
                      </p>

                      {/* {!isWaitResponseEditQuickReply &&
                          <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-40"
                            text="Save Changes"
                            isDisabled={isButtonSubmitDisabled()}
                        />
                      } */}
                  </div>
                {/* </form> */}

                {/* {isWaitResponseEditQuickReply &&
                  <ButtonWithLoadingOrIcon 
                    isLoading
                    loadingWhite
                    className="orange-button main-button-40"
                    text="Save Changes"
                    position="left"
                    style={{width: "300px"}}
                  />
                } */}
            </div>
        </SectionWrap>
    </React.Fragment>
  );
};

export default QuickReplyPersonalEdit;