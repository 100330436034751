import React, { useEffect, useState } from "react";
import "./BroadcastMessageFormFileStartNewMessage.scss"
import { FiX, FiFile } from "react-icons/fi";
// import { runDocumentCompress } from "../../../../helper/HelperGeneral";
import { doToast } from "../../../../helper/HelperGeneral";
import { taptalk, tapCoreMessageManager } from "@taptalk.io/web-sdk";
import ChatService from '../../../../services/chat/caseServices';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

let broadcastMessageFormFileStartNewMessage = (props) => {
    let [filePreview, setFilePreview] = useState([]);
    let [percentageUpload, setPercentageUpload] = useState(false);
    let [finishUpload, setFinishUpload] = useState(false);

    useEffect(() => {
      let _fileData = props.broadCastMessageDataProps.fileData[0];
      
      if(_fileData) {
        let _filePreview = [];
        _filePreview.push(_fileData);
        setFilePreview(_filePreview);
        // runUploadDocument(_filePreview[0]);
      }
    }, [props.broadCastMessageDataProps])

    useEffect(() => {
      if(filePreview.length > 0) {
        runUploadDocument(filePreview[0]);
      }
    }, [JSON.stringify(filePreview)]);

    let doUpload = (file) => {
      let uploadData = {
        file: file,
        caption: "",
        room: ""
      };

      tapCoreMessageManager.uploadChatFile(uploadData, {
        onProgress: (percentage, bytes) => {
          setPercentageUpload(percentage);

          if(percentage === 100) {
            setPercentageUpload(false);
            setFinishUpload(true);
          }
        },

        onSuccess: (response) => {
            if(response) {
                let newFileData = {};
                let arrayFileData = [];
                arrayFileData.push(file);
                newFileData.fileData = arrayFileData;
                newFileData.fileName = file.name;
                newFileData.fileURL = response.fileURL;
                props.onChangeAssetFileProps(newFileData);
            }
        },

        onError: (error) => {
          doToast(error, "fail");
          console.log(error);
        }
      })
    }
    
    let taptalkAuthTicket = (file) => {
      ChatService.postRequestAuthTicket(props.orgIDProps, (response) => {
        if (response.dataResult.error.code === "") {
          taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
            onSuccess: () => {
              doUpload(file);
            },
            onError: (errorCode, errorMessage) => {
              doToast(errorMessage, 'fail')
              console.log(errorCode, errorMessage);
            }
          })
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      })
    }

    let runTaptalkRequestAuthTicket = (file) => {
      ChatService.postProjectConfig(props.orgIDProps, (response) => {
        let data = response.dataResult.data.tapTalk;
  
        if (response.dataResult.error.code === "") {
          taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
          taptalkAuthTicket(file);
        } else {
          doToast(response.dataResult.error.message, "fail");
        }
      });
    }

    let runUploadDocument = (file) => {
      setPercentageUpload(1);
      
      if(props.withoutRequestAuthTicketProps) {
        doUpload(file);
      }else {
        runTaptalkRequestAuthTicket(file);
      }
    }

    let removeFileAsset = () => {
      props.onRemoveFileAssetProps(props._index);
      setFilePreview([]);
    }

    return (
        <div className="form-file-message-wrapper-new-conversation">
            <div className="file-and-caption-wrapper">
                {filePreview.length > 0 &&
                  <>
                    <div className={`file-result-wrapper-outer ${percentageUpload ? "file-result-wrapper-outer-disabled" : ""}`}>
                      <div className="file-result-wrapper">
                        {percentageUpload ? 
                          <div className="progress-wrapper">
                            <CircularProgressbar value={percentageUpload} />
                          </div>
                          :
                          (finishUpload || filePreview[0].body !== "") && <FiFile className="icon-file" />
                        }
                        <FiFile className="icon-file" />

                        {filePreview[0].name && filePreview[0].name} {(finishUpload || filePreview[0].body !== "") && <b className="remove-file" onClick={removeFileAsset}><FiX /> Remove</b>}
                      </div>
                    </div>

                    <textarea 
                      value={props.caption}
                      maxLength="4000"
                      placeholder="Enter caption here..." 
                      id="caption"
                      disabled={percentageUpload}
                      onChange={(e) => props.onChangeBroadcastMessageProps(e)}
                    />
                  </>
                }
            </div>
        </div>
    )
}

export default broadcastMessageFormFileStartNewMessage;