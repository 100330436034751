import React, { useEffect, useState } from "react";
import "./IntegrationAutoAssignChatbot.scss";
import { FiInfo } from "react-icons/fi";
import { doToast, checkID } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ChatbotService from "../../../../services/newServices/ChatbotService";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import mixpanel from "mixpanel-browser";
import HelperInput from "../../../../helper/HelperInput";
import topicServices from "../../../../services/newServices/TopicServices";

const IntegrationAutoAssignChatbot = (props) => {
    let [val, setVal] = useState(false);
    let [valOrigin, setValOrigin] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [isWaitingSet, setIsWaitingSet] = useState(false);
    let [isShowModalSave, setIsShowModalSave] = useState(false);
    let [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [errorMessageData, setErrorMessageData] = useState({
        waitValue: "",
        waitOption: "",
        idleAction: "",
        topic: "",
        closingMessage: ""
    });
    let [orgID, setOrgID] = useState(false);
    let [topicList, setTopicList] = useState([]);

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Auto Assign Chatbot Setting",
                {
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    useStateorganizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if (orgID) {
            getDataAutoAssignChatbot();
            getTopics();
        }
    }, [orgID])

    useEffect(() => {
        checkSaveButtonEnabled();
    }, [val, valOrigin])

    useEffect(() => {
    }, [isSaveButtonEnabled])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        }
        else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/auto-chatbot-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getDataAutoAssignChatbot = () => {
        let _retriveDataError = { ...retriveDataError };
        setIsWaitingFetch(true);

        ChatbotService.getAutoChatbot(orgID, (response) => {
            let dataResult = response.dataResult;

            dataResult.data.config.topic = {
                value: dataResult.data.config.handover.defaultTopicID,
                label: dataResult.data.config.handover.defaultTopicName
            }

            if (dataResult.error.message === "") {
                setValOrigin(JSON.parse(JSON.stringify(dataResult.data.config)));
                setVal(JSON.parse(JSON.stringify(dataResult.data.config)));
                setErrorMessageData({
                    waitValue: "",
                    waitOption: "",
                    idleAction: "",
                    topic: "",
                    closingMessage: ""
                });
            }
            else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitingFetch(false);
        }
        )
    }

    let setDataAutoChatbot = () => {
        let errorCount = 0;
        let _error = { ...errorMessageData };

        if (val.isEnabled && !val.useGlobalConfig) {
            if (val.afterValue === "" || val.afterValue === 0) {
                _error.waitValue = "This field is required";
                errorCount = errorCount + 1;
            }

            if (val.afterType === "") {
                _error.waitOption = "This field is required";
                errorCount = errorCount + 1;
            }

            if (val.idleAction === "") {
                _error.idleAction = "This field is required";
                errorCount = errorCount + 1;
            }

            if (val.idleAction === "handover" && val.topic.value === 0) {
                _error.topic = "This field is required";
                errorCount = errorCount + 1;
            }

            if (val.idleAction === "close_case") {
                if (val.closeCase.sendClosingMessage && val.closeCase.closingMessage === "") {
                    _error.closingMessage = "This field is required";
                    errorCount = errorCount + 1;
                }

                if (val.closeCase.sendClosingMessage && val.closeCase.closingMessage.length > 1000) {
                    _error.closingMessage = "Characters exceed limit";
                    errorCount = errorCount + 1;
                }
            }
        }

        if (errorCount === 0) {
            setIsWaitingSet(true);

            let data = null;

            if (props.medium !== "global") {
                data.id = parseInt(props.match.params.id ? props.match.params.id : props.match.params.accountID);
                data.useGlobalConfig = val.useGlobalConfig;
            }

            if (val.idleAction === "handover") {
                data = {
                    "isEnabled": val.isEnabled,
                    "afterValue": parseInt(val.afterValue),
                    "afterType": val.afterType,
                    "idleAction": val.idleAction,
                    "handover": {
                        "defaultTopicID": val.topic.value
                    }
                }
            } else {
                data = {
                    "isEnabled": val.isEnabled,
                    "afterValue": parseInt(val.afterValue),
                    "afterType": val.afterType,
                    "idleAction": val.idleAction,
                    "closeCase": {
                        "sendClosingMessage": val.closeCase.sendClosingMessage,
                        "closingMessage": val.closeCase.closingMessage
                    }
                }
            }

            ChatbotService.setAutoChatbot(orgID, data, (response) => {
                let dataResult = response.dataResult;

                if (dataResult.error.message === "") {
                    if (dataResult.data.success) {
                        doToast(dataResult.data.message);
                    } else {
                        doToast(dataResult.data.message, "fail");
                        setIsWaitingSet(false);
                    }

                    setValOrigin(JSON.parse(JSON.stringify(val)));

                    if (props.medium === "global") {
                        setIsWaitingSet(false);
                        toggleModalSave();
                    } else {
                        setTimeout(() => {
                            props.back();
                        }, 3000)
                    }
                } else {
                    doToast(dataResult.error.message, "fail")
                    toggleModalSave();
                    setIsWaitingSet(false);
                }
            }
            )
        } else {
            toggleModalSave();
            setErrorMessageData(_error);
        }
    }

    let getTopics = async () => {
        topicServices.getTopic(orgID, "listss", null, (response) => {
            let dataResult = response.dataResult;

            if (response.dataResult.status === 200) {
                const data = response.dataResult.data.topics;
                const createList = () => {
                    let newData = [];
                    data.map(val => {
                        let _data = {};
                        _data.value = val.id;
                        _data.label = val.name;
                        newData.push(_data);
                        return null;
                    });
                    setTopicList(newData);
                };

                createList();
            } else {
                doToast(dataResult.error.message, "fail");
            }

        });
    }

    let toggleConfig = () => {
        let _data = { ...val };
        _data.isEnabled = !_data.isEnabled;
        setVal(_data);
    }

    let toggleConfigClosing = () => {
        let _data = { ...val };
        _data.closeCase.sendClosingMessage = !_data.closeCase.sendClosingMessage;
        setVal(_data);
    }

    let checkSaveButtonEnabled = () => {
        if (val && val.inboxConfigs && valOrigin && valOrigin.inboxConfigs) {
            setIsSaveButtonEnabled(val.inboxConfigs.IntegrationAutoResolveConfigCase !== valOrigin.inboxConfigs.IntegrationAutoResolveConfigCase);
        }
        else {
            setIsSaveButtonEnabled(false);
        }
    }

    let toggleModalSave = () => {
        if (!isWaitingSet) {
            setIsShowModalSave(!isShowModalSave);
        }
    }

    let changeVal = (e) => {
        let _error = { ...errorMessageData };
        let _data = { ...val };

        if ((HelperInput.numberOnly(e.target.value) && e.target.value > 0) || e.target.value === "") {
            _error.waitValue = "";
            _data.afterValue = e.target.value.replace(/\b0+/g, '');
        }

        setErrorMessageData(_error);
        setVal(_data);
    }

    let changeCloseMessage = (e) => {
        let _error = { ...errorMessageData };
        let _data = { ...val };
        if (e.target.value.length > 1000) {
            _error.closingMessage = "Characters exceed limit";
        } else {
            _error.closingMessage = "";
        }

        _data.closeCase.closingMessage = e.target.value;

        setErrorMessageData(_error);
        setVal(_data);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={valOrigin}
            dataAfterChange={val}
        >
            {props.backToText !== "" &&
                <BackButton
                    onClick={props.back}
                    text={props.backToText}
                />
            }

            <div className="auto-chatbot-settings sectionWrap">
                <React.Fragment>
                    {/* tier blocking */}
                    {retriveDataError.code === "49901" &&
                        <RetrieveDataLoading
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message}
                            errorCode={retriveDataError.code}
                        />
                    }
                    {/* tier blocking */}

                    <div className="autoChatbot-settings-header">
                        <b>Case Assigned to Chatbot</b>

                        {!retriveDataError.code &&
                            <ButtonWithLoadingOrIcon
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                onClickAction={toggleModalSave}
                                // isDisabled={!isSaveButtonEnabled}
                                isDisabled={false}
                            />
                        }
                    </div>

                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ?
                        <RetrieveDataLoading
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <>
                            {/* {props.medium !== "global" &&
                                <div className="autoChatbot-settings-form no-padding-top">
                                    <div className="autoChatbot-settings-form-content form-content-wrapper">
                                        <b>Use Global Config</b>
                                        <p>
                                            Use Global Automatic Unassign Agent from setup
                                        </p>

                                        <div className="closing-welcome-switch-wrapper">
                                            <label className="custom-switch-wrapper">
                                                <input type="checkbox" checked={val.useGlobalConfig} onChange={() => toggleConfigGlobal()} />
                                                <span className="custom-switch round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            } */}

                            <div className={`autoChatbot-settings-form no-padding-top ${props.medium !== "global" ? (val.useGlobalConfig ? "force-hide" : "") : ""}`}>
                                <div className="autoChatbot-settings-form-content form-content-wrapper">
                                    <b>Handle idle case assigned to Chatbot</b>
                                    <p>
                                        Cases will be automatically
                                        <CustomSelectOption
                                            isDisabled={false}
                                            optionListProps={[
                                                {
                                                    label: "resolved",
                                                    value: "close_case"
                                                },
                                                {
                                                    label: "handed over to agent",
                                                    value: "handover"
                                                },
                                            ]}
                                            valueDropdownProps={
                                                val.idleAction === "" ?
                                                    { label: "", value: "" }
                                                    :
                                                    val.idleAction === "close_case" ?
                                                        { label: "resolved", value: "close_case" }
                                                        :
                                                        { label: "handed over to agent", value: "handover" }
                                            }
                                            placeholderProps={"choose action"}
                                            placeholderBold
                                            onClickDropDownListOptionProps={(e) => {
                                                if(e.value !== val.idleAction) {
                                                    let _data = { ...val };
                                                    let _dataOrigin = JSON.parse(JSON.stringify(valOrigin));
                                                    let _error = { ...errorMessageData };
                                                    _error.idleAction = "";
                                                    _error.topic = "";
                                                    _error.closingMessage = "";
                                                    _data.idleAction = e.value;
                                                    _data.closeCase = _dataOrigin.closeCase;
                                                    _data.topic = _dataOrigin.topic;
                                                    setErrorMessageData(_error);
                                                    setVal(_data);
                                                }
                                            }}
                                            specificClassNameProps="custom-select-time"
                                            _className={`input-time-select input-time-first ${errorMessageData.idleAction === "" ? "" : "border-red"}`}
                                        />
                                        once there are no active conversation within a specific time range.
                                    </p>

                                    <div className="closing-welcome-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input type="checkbox" checked={val.isEnabled} onChange={() => toggleConfig()} />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>

                                    {(errorMessageData.idleAction !== "") &&
                                        <ErrorOrInfoComp
                                            text="This field is required"
                                            icon={<FiInfo />}
                                            _className="red-text"
                                        />
                                    }
                                </div>
                            </div>

                            {val.isEnabled &&
                                <>
                                    <div className={`autoChatbot-settings-form  no-padding-top ${props.medium !== "global" ? (val.useGlobalConfig ? "force-hide" : "") : ""}`}>
                                        <div className="autoChatbot-settings-form-content form-content-wrapper">
                                            <b>Wait Duration</b>
                                            <p>
                                                Chatbot’s idle case will be automatically handled after
                                                <input type="text" maxLength={3} placeholder="00" value={val.afterValue} onChange={(e) => { changeVal(e) }} className={errorMessageData.waitValue !== "" ? "border-red" : ""} />

                                                <CustomSelectOption
                                                    isDisabled={false}
                                                    optionListProps={[
                                                        {
                                                            label: "hours",
                                                            value: "hours"
                                                        },
                                                        {
                                                            label: "minutes",
                                                            value: "minutes"
                                                        },
                                                    ]}
                                                    valueDropdownProps={
                                                        val.afterType === "" ?
                                                            { label: "", value: "" }
                                                            :
                                                            val.afterType === "minutes" ?
                                                                { label: "minutes", value: "minutes" }
                                                                :
                                                                { label: "hours", value: "hours" }
                                                    }
                                                    placeholderProps={"minutes"}
                                                    onClickDropDownListOptionProps={(e) => {
                                                        let _data = { ...val };
                                                        let _error = { ...errorMessageData };
                                                        _error.waitOption = "";
                                                        _data.afterType = e.value;
                                                        setErrorMessageData(_error);
                                                        setVal(_data);
                                                    }}
                                                    specificClassNameProps="custom-select-time"
                                                    _className={`input-time-select ${errorMessageData.waitOption === "" ? "" : "border-red"}`}
                                                />
                                                from the last conversation sent or received.
                                            </p>

                                            {(errorMessageData.waitOption !== "" || errorMessageData.waitValue !== "") &&
                                                <ErrorOrInfoComp
                                                    text="This field is required"
                                                    icon={<FiInfo />}
                                                    _className="red-text"
                                                />
                                            }
                                        </div>

                                        {val.idleAction === "handover" &&
                                            <div className="autoChatbot-settings-form-content form-content-wrapper">
                                                <b>Default Topic</b>

                                                {/* //topic */}
                                                <CustomSelectOption
                                                    isDisabled={false}
                                                    optionListProps={topicList}
                                                    valueDropdownProps={val.topic}
                                                    placeholderProps={"Select default topic here"}
                                                    onClickDropDownListOptionProps={(e) => {
                                                        let _data = { ...val };
                                                        let _error = { ...errorMessageData };
                                                        _error.topic = "";
                                                        _data.topic = e;
                                                        setErrorMessageData(_error);
                                                        setVal(_data);
                                                    }}
                                                    specificClassNameProps="default-topic-here"
                                                    _className={`${errorMessageData.topic === "" ? "" : "border-red"}`}
                                                />

                                                {(errorMessageData.topic !== "") &&
                                                    <ErrorOrInfoComp
                                                        text="This field is required"
                                                        icon={<FiInfo />}
                                                        _className="red-text"
                                                    />
                                                }
                                            </div>
                                        }

                                        {val.idleAction === "close_case" &&
                                            <>
                                                <div className="autoChatbot-settings-form-content form-content-wrapper">
                                                    <b>Send Closing Message </b>
                                                    <p>
                                                        Send closing message when a case is automatically resolved, only if closing message is enabled for the channel.
                                                    </p>

                                                    <div className="closing-welcome-switch-wrapper">
                                                        <label className="custom-switch-wrapper">
                                                            <input type="checkbox" checked={val.closeCase.sendClosingMessage} onChange={() => toggleConfigClosing()} />
                                                            <span className="custom-switch round"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                                {val.closeCase.sendClosingMessage &&
                                                    <div className="autoChatbot-settings-form-content form-content-wrapper">
                                                        <b>Closing Message </b>
                                                        <textarea placeholder="Insert closing message here" value={val.closeCase.closingMessage} rows="3" onChange={(e) => changeCloseMessage(e)} className={errorMessageData.closingMessage === "" ? "" : "border-red"} />

                                                        {(errorMessageData.closingMessage === "" || errorMessageData.closingMessage === "Characters exceed limit") &&
                                                            <span className={errorMessageData.closingMessage === "Characters exceed limit" ? "red-text" : "grey-text"}>({val.closeCase.closingMessage.length}/1000)</span>
                                                        }

                                                        {(errorMessageData.closingMessage !== "") &&
                                                            <ErrorOrInfoComp
                                                                text={errorMessageData.closingMessage}
                                                                icon={<FiInfo />}
                                                                _className="red-text"
                                                            />
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </>
                            }
                        </>

                    }
                </React.Fragment>
            </div>

            <PopupConfirmation
                isOpen={isShowModalSave}
                toggle={toggleModalSave}
                title="Save Changes?"
                description="You have changes in your Case Assigned to Chatbot configs, are you sure you want to continue?"
                className="modal-confirmation-save-changes"
                isLoading={isWaitingSet}
            >
                <ButtonWithLoadingOrIcon
                    text="Save Changes"
                    className="main-button-48 orange-button"
                    isLoading={isWaitingSet}
                    isDisabled={isWaitingSet}
                    loadingColor="gray"
                    position="left"
                    onClickAction={setDataAutoChatbot}
                />
            </PopupConfirmation>
        </SectionWrap>
    )
}

export default IntegrationAutoAssignChatbot;
