const INITIAL_STATE = null;

let reducerCaseDetailData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_CASE_DETAIL_DATA':
        return action.caseDetailData;
      case 'CLEAR_CASE_DETAIL_DATA':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerCaseDetailData;