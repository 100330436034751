const INITIAL_STATE = false;

let reduxReducerMedium = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_MEDIUM_DATA':
        return action.mediumData;
      case 'CLEAR_MEDIUM_DATA':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerMedium;