const EnTranslation = {
  pageWaba: {
    text1: "Questionnaire",
    text2: "Company Details",
    text3: "Requester's Info",
    form1: {
      textForm1: "Is your company incorporated?",
      textForm2: "Yes",
      textForm3: "No",
      textForm4: "More",
      textForm5: "Verified",
      textForm6: "Not verified",
      textForm6a: "3 or more",
      textForm7: "Do you have a Facebook Manager ID?",
      textForm8: "What is your Facebook Manager ID status?",
      textForm9: "Are you able to provide legal documents from the company?",
      textForm10: "Is the company’s industry you’re registering for",
      textForm11: "listed here",
      textForm12: "How many channels are you looking to register for?",
      textForm13: "Continue",
      textFormTooltip1: "A registered company that is considered a legal entity.",
      textFormTooltip2: "Find your Facebook Manager ID",
      textFormTooltip3: "About Facebook business verification",
      textFormTooltip4: "One WhatsApp account/number equals to one channel.",
      textListedHere1: "List of businesses and industries that won’t be approved",
      textListedHere2: "Currency, trademarks and illegal products",
      textListedHere3: "Medical and healthcare products",
      textListedHere4: "Misleading, deceptive, fraudulent or offensive offers and products",
      textListedHere5: "Other",
      textListedHere6: "Real, virtual and fake currency",
      textListedHere7: "3rd-party infringement",
      textListedHere8: "Illegal products and services",
      textListedHere9: "Medical devices",
      textListedHere10: "Medical healthcare and services",
      textListedHere11: "Drugs and pharmaceuticals",
      textListedHere12: "Bodily fluids",
      textListedHere13: "Misleading financial categories: P2P lending, debt collection, short term loans",
      textListedHere14: "Animals, weapons, gambling, dating, supplements, tabacco, adult products and services, alcohol",
    },
    form2: {
      textForm1: "Company Name",
      textForm2: "Enter Company Name",
      textForm3: "Country of Incorporation",
      textForm3a: "Select Country",
      textForm4: "Website",
      textForm5: "Address",
      textForm6: "Enter full address",
      textForm7: "Company's Phone Number",
      textForm8: "Company's Email",
      textForm8a: "Input email",
      textForm9: "Industry",
      textForm10: "Select industry",
      textForm11: "e.g Financial Services/ Information Technology",
      textForm12: "Go Back",
      textForm13: "Continue",
      textTooltip1: "The country where the company is acknowledged as a legal entity.",
      textTooltip2: "Make sure this is a phone number that has been registered legally by your company.",
      textTooltip3: "Make sure this is your company’s email address.",
      textWarning1: "Make sure it is a valid URL",
      textWarning2: "Make sure it is a valid email address",
      textWarning3: "The combined country code and phone number must be between 7-15 digits"
    },
    form3: {
      textForm1: "Full name",
      textForm2: "Enter full name",
      textForm3: "Email",
      textForm3a: "Input email",
      textForm4: "Phone Number",
      textForm4a: "Phone number",
      textForm5: "Select Country First",
      textForm6: "Job Title In Company",
      textForm7: "Enter Title",
      textForm8: "e.g Customer Support Manager",
      textForm9: "Go Back",
      textForm10: "Submit",
      textWarning1: "Make sure it is a valid email address"
    }
  }
}

export default EnTranslation;