import React, { useEffect, useState } from 'react';
import './ModalHasOpenCase.scss';
import { connect } from 'react-redux';
import { taptalk } from '@taptalk.io/web-sdk';
// import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
// import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
import HelperChat from '../../../../../helper/HelperChat';
import { clearStartConversation } from '../../../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../../../redux/actions/reduxActionStartConversationBa';
import { Modal, ModalBody } from "reactstrap";
import { FiAlertTriangle } from "react-icons/fi";

let ModalHasOpenCase = (props) => {
    let [modalHasOpenCase, setModalHasOpenCase] = useState(false); 
    let [otherAgent, setOtherAgent] = useState(false);
    // let [topicNotAssigned, setTopicNotAssigned] = useState(false);

    useEffect(() => {
        if(props.startConversation.openCase) {
            let _startConversation = {...props.startConversation};
            // let myAgentID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
            
            if(!props.topicListProps[_startConversation.openCase.topicID] && _startConversation.openCase.topicID !== 0) {
                // if(!props.topicListProps[_startConversation.conversation.channel.topicID]) {
                //     setTopicNotAssigned(true);
                // }

                setModalHasOpenCase(true);
    
                if(_startConversation.openCase.agentAccountID !== 0) {
                    setOtherAgent(_startConversation.openCase.agentFullName);   
                }
            }
            // else {
            //     setTopicNotAssigned(false);
            // }
        }else {
            setModalHasOpenCase(false);
            setOtherAgent(false);
        }
    }, [props.startConversation.openCase])

    useEffect(() => {
        if(props.startConversationBa.openCase) {
            let _startConversation = {...props.startConversationBa};
            // let myAgentID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
            if(!props.topicListProps[_startConversation.openCase.topicID] && _startConversation.openCase.topicID !== 0) {
                // if(!props.topicListProps[_startConversation.conversation.channel.topicID]) {
                //     setTopicNotAssigned(true);
                // }

                setModalHasOpenCase(true);
    
                if(_startConversation.openCase.agentAccountID !== 0) {
                    setOtherAgent(_startConversation.openCase.agentFullName);   
                }
            }
            // else {
            //     setTopicNotAssigned(false);
            // }
        }else {
            setModalHasOpenCase(false);
            setOtherAgent(false);
        }
    }, [props.startConversationBa.openCase])

    let toggleModalHasOpenCase = () => {
        props.clearStartConversation();
        props.clearStartConversationBa();
    }

    return (
        <Modal className="modal-has-open-case" isOpen={modalHasOpenCase} toggle={toggleModalHasOpenCase}>
            <ModalBody>
                <p className="has-open-case-title">
                    <FiAlertTriangle />
                    {/* {topicNotAssigned ? */}
                        <b>You Are Not Assigned to This Topic</b>
                        {/* : */}
                        {/* <b>Recipient Has Ongoing Case</b> */}
                    {/* } */}
                </p>

                <p className="has-open-case-description">
                    {(props.startConversation.openCase.id !== 0 && props.startConversation.openCase.id) ?
                        <div>
                            The selected recipient has an ongoing case in a topic that is not assigned to you. {otherAgent && <b>Please contact the agent below if you wish to continue to send a message to the recipient.</b>}
                        </div>
                        :
                        `You can ask your admin to assign you to this channel topic`
                    }
                </p>

                {otherAgent &&
                    <div className="other-agent-wrapper">
                        <div className="agent-avatar"  style={{background: taptalk.getRandomColor(otherAgent)}}>
                            <b>{HelperChat.renderUserAvatarWord(otherAgent, false)}</b>
                        </div>

                        <b>{otherAgent}</b>
                    </div>
                }

                <button className="orange-button dismiss" onClick={toggleModalHasOpenCase}>
                    <b>Dismiss</b>
                </button>
            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = state => ({
    startConversation: state.startConversation,
    startConversationBa: state.startConversationBa
});

const mapDispatchToProps = {
    clearStartConversation,
    clearStartConversationBa
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalHasOpenCase);