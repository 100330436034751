import React, { useState, useEffect } from "react";
import "./Faq.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiPlus, FiEye, FiInfo } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { FiX, FiMessageSquare, FiUser } from "react-icons/fi";
import { Modal, ModalBody } from 'reactstrap';
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupRemove from '../../reuseableComponent/popupRemove/PopupRemove';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import SelectTopic from "../../reuseableComponent/selectTopic/SelectTopic";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from '../../../services/organization/ServiceOrganization'
import topicServices from "../../../services/newServices/TopicServices";
import FaqServices from '../../../services/newServices/FaqServices'
import { checkID, doToast, autoFocusField, scrollToClass } from '../../../helper/HelperGeneral';
// import Helper from '../../../helper/HelperChat';
import { TAPLIVE_FAQ_TYPE } from '../../../constants/taplive';

const Faq = props => {
  const DEFAULT_PATH_VAL = {
    title: "",
    content: "",
    type: "",
    topicIDs: []
  }
  const { match } = props;
  const [orgID, setOrgID] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [topicListOrigin, setTopicListOrigin] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [searchTopic, setSearchTopic] = useState("");
  const [showModalCreatePath, setShowModalCreatePath] = useState(false)
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [isLoadingFaq, setIsLoadingFaq] = useState(true)
  const [isLoadingCreate, setIsLoadingCreate] = useState(false)
  const [dataFaq, setDataFaq] = useState([])
  const [isShowModalSaveChanges, setIsShowModalSaveChanges] = useState(false)
  // const [waitForResponseSaveChanges, setWaitForResponseSaveChanges] = useState(false)
  const [isShowModalDiscard, setIsShowModalDiscard] = useState(false)
  const [createVal, setCreateVal] = useState(DEFAULT_PATH_VAL)
  const [errorMessageData, setErrorMessageData] = useState({
    title: "",
    content: "",
    type: "",
    topic: ""
  })

  useEffect(() => {
    if(showModalCreatePath) {
      setTimeout(() => {
        autoFocusField("create-qna-first-input");
      }, 500)
    }
  }, [showModalCreatePath])

  useEffect(() => {
    if(orgID) {
      getTopic();
      getFaqList();
    }
  }, [orgID])

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      // getFaqList(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/setup/scf`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const getFaqList = () => {
    let _retriveDataError = { ...retriveDataError };
    setIsLoadingFaq(true);
    
    FaqServices.getList(orgID, (response) => {
      if (response.dataResult.status === 200) {
        setDataFaq(response.dataResult.data.paths);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingFaq(false);
    });
  };

  useEffect(() => {
    if (props.history.location.state) {
      if(props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.topic = "";
    setErrorMessageData(_errorMessageData);
  }, [JSON.stringify(selectedTopic)])

  const closeModalCreatePath = () => {
    if(!isLoadingCreate) {
      setCreateVal(DEFAULT_PATH_VAL);
      setSearchTopic("");
      setTopicList(topicListOrigin);
      setIsCheckAll(false);
      setSelectedTopic([]);
      setShowModalCreatePath(false);
    }
  }

  const getTopic = () => {
    topicServices.getTopic(orgID, "list", null, (response) => {
      const success = response.dataResult.status === 200;
      const hasError = response.dataResult.error && response.dataResult.error.code && response.dataResult.error.message;
      if (success) {
        setTopicList(response.dataResult.data.topics)
        setTopicListOrigin(response.dataResult.data.topics)
      }else if(hasError) {
        // doToast(response.dataResult.error.message, "fail");
      }
    });
  };

  const createPath = async () => {
    let _createVal = {...createVal};
    let _errorMessageData = {...errorMessageData};
    let errorCount = 0;
    let errorClass = "";

    if(_createVal.title === "") {
      _errorMessageData.title = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    if(_createVal.title.length > 200) {
      _errorMessageData.title = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    if(_createVal.content === "") {
      _errorMessageData.content = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-content" }
    }

    if(_createVal.content.length > 1000) {
      _errorMessageData.content = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-content" }
    }

    if(_createVal.type === "") {
      _errorMessageData.type = "Please select type";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-type" }
    }

    if(_createVal.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val) {
      if(selectedTopic.length === 0) {
        _errorMessageData.topic = "Please Choose Related Departments";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-topic" }
      }
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      // let _retriveDataError = {...retriveDataError}
      setIsLoadingCreate(true)
      
      // createVal.title = Helper.removeHTMLTag(createVal.title);
      // createVal.content =  Helper.removeHTMLTag(createVal.content);
      _createVal.topicIDs = [];
      selectedTopic.map((val) => {
        _createVal.topicIDs.push(val.id);
        return null;
      })
  
      FaqServices.add(orgID, _createVal, (response) => {
        if (response.dataResult.status === 200) {
          if(response.dataResult.data.success) {
            let data = response.dataResult.data;
            doToast(response.dataResult.data.message);
            setTimeout(() => {
              closeModalCreatePath();
              goToDetail(data.path.id, data.path.rootItemID);
            }, 2000)
            // props.history.push({
            //   pathname: `/o/${orgID}/setup/qna/detail/${data.path.id}/${data.path.rootItemID}`,
            //   state: {
            //     message: response.dataResult.data.message
            //   }
            // })
            // getFaqList();
          }
        } else {
          // _retriveDataError.message = response.dataResult.error.message;
          // _retriveDataError.code = response.dataResult.error.code;
          // setRetrieveDataError(_retriveDataError);
          doToast(response.dataResult.error.message, 'fail');
          setIsLoadingCreate(false);
        }
  
      });
    }
  };

  const disableCreatePath = () => {
    let dis = false;
    // if(createVal.title === ""  || createVal.type === "") {
    //   dis = true;
    // }

    // if(createVal.type === TAPLIVE_FAQ_TYPE["talk_to_agent"].val) {
    //   if(createVal.topicIDs.length === 0) {
    //     dis = true;
    //   }
    // }

    return dis;
  }

  const onChange = (e) => {
    let _errorMessageData = {...errorMessageData};
    let id = e.target.id;
    let val = e.target.value;
    let _createVal = {...createVal};
    _errorMessageData[id] = "";
    _createVal[id] = val;
    setErrorMessageData(_errorMessageData);
    setCreateVal(_createVal);
  }

  const onChangeType = (val) => {
    if(!isLoadingCreate) {
      let _errorMessageData = {...errorMessageData};
      let _createVal = {...createVal};
      _createVal.type = val;
      _createVal.topic = [];
      _errorMessageData.type = "";
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
      setCreateVal(_createVal);
    }
  }

  // const onChangeTopic = (e, id) => {
  //   let _createVal = {...createVal};
  //   let _index = findIndexTopic(id);

  //   if(_index === -1) {
  //     _createVal.topicIDs.push(id);
  //   }else {
  //     _createVal.topicIDs.splice(_index, 1);
  //   }
  //   // _createVal.topicIDs = [id];
  //   setCreateVal(_createVal);
  // }

  // const findIndexTopic = (id) => {
  //   let _idx = createVal.topicIDs.findIndex(val => val === id);
  //   return _idx;
  // }

  // const isAllChecked = () => {
  //   let _createVal = {...createVal};

  //   return _createVal.topicIDs.length === topicList.length;
  // }

  // const onSelectAll = (e) => {
  //   let _checked = e.target.checked;
  //   let _createVal = {...createVal};

  //   if(_checked) {
  //     topicList.map((val) => {
  //       if(findIndexTopic(val.id) === -1) {
  //         _createVal.topicIDs.push(val.id);
  //       }

  //       return null;
  //     })
  //   }else {
  //     _createVal.topicIDs = [];
  //   }

  //   setCreateVal(_createVal);
  // }

  let onChangeSingleSelect = (e, val) => {
    let _selectedTopic = [...selectedTopic]

    if (e.target.checked) {
      _selectedTopic = [..._selectedTopic, val]
    } else {
      const _dataSelectedTopic = [..._selectedTopic]
      _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
      setIsCheckAll(false);
    }

    setSelectedTopic(_selectedTopic);
  }

  const onClearAll = () => {
    setIsCheckAll(false);
    setSelectedTopic([]);
  }

  let onCheckAll = (e) => {
    if (e.target.checked) {
      setSelectedTopic(topicList);
    } else {
      setSelectedTopic([]);
    }

    setIsCheckAll(e.target.checked);
  }

  let isChecked = (id) => {
    const _data = [...selectedTopic]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let removeSingleTopic = (val) => {
    setIsCheckAll(false);
    let _selectedTopic = [...selectedTopic];
    const _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    setSelectedTopic(_selectedTopic);
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
      for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
          if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
          }
        }
      }
    }

    setTopicList(result);
  }

  const renderModalCreatePath = () => {
    return (
      <Modal isOpen={showModalCreatePath} toggle={closeModalCreatePath} className={"add-path-modal"}>
        <ModalBody>
          <div className="create-path-wrapper">
            <b className="create-path-title">Create QnA Path</b>

            <FiX onClick={closeModalCreatePath} className="close-modal-create-path" />
            
            <div className="form-content-wrapper">
              <label><b>Title</b> <span className={`input-counter grey-font ${errorMessageData.title === "Characters exceed limit" ? "red-text" : ""}`}>{createVal.title.length}/200</span></label>
              <input disabled={isLoadingCreate} type="text" id="title" onChange={(e) => onChange(e)} value={createVal.title} placeholder="Insert title" className={`create-qna-first-input input-title ${errorMessageData.title !== "" ? "border-red" : ""}`} />
              {errorMessageData.title !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageData.title}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }

              <label><b>Content</b><span className={`input-counter grey-font ${errorMessageData.content === "Characters exceed limit" ? "red-text" : ""}`}>{createVal.content.length}/1000</span></label>
              <textarea disabled={isLoadingCreate} rows={4} id="content"  onChange={(e) => onChange(e)} value={createVal.content} placeholder={`Insert your content here input-content`} className={`${errorMessageData.content !== "" ? "border-red" : ""}`} />
              {errorMessageData.content !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageData.content}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }

              <label className={`input-type`}><b>Type</b></label>
              <div className="create-path-type-wrapper">
                <div className={`create-path-type-select ${createVal.type === TAPLIVE_FAQ_TYPE.qna.val ? "create-path-type-active" : ""}`} onClick={() => onChangeType(TAPLIVE_FAQ_TYPE.qna.val)}>
                  <FiMessageSquare />
                  {TAPLIVE_FAQ_TYPE.qna.text}
                </div>

                <div className={`create-path-type-select ${createVal.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val ? "create-path-type-active" : ""}`} onClick={() => onChangeType(TAPLIVE_FAQ_TYPE.talk_to_agent.val)}>
                  <FiUser />
                  {TAPLIVE_FAQ_TYPE.talk_to_agent.text}
                </div>  
              </div>
              {errorMessageData.type !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageData.type}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }
              
              {createVal.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val &&
                <>
                  {/* <label>
                    <b>Choose Related Departments</b>
                    {createVal.topicIDs.length > 0 &&
                      <b className="clear-all" onClick={() => onClearAll()}>Clear all</b>
                    }
                  </label>
                  <div className="choose-related-department-wrapper">
                    <div className={`choose-related-department-select custom-checkbox ${isAllChecked() ? "choose-related-department-select-active" : ""}`}> */}
                        {/* <div className="custom-checkbox"> */}
                          {/* <input type="checkbox" id={`select-all`} checked={isAllChecked()} onClick={(e) => onSelectAll(e)}/>
                          <label htmlFor={`select-all`}>Select All</label> */}
                      {/* </div> */}
                    {/* </div> */}

                    {/* {topicList.map((val, idx) => {
                      return (
                        <div className={`choose-related-department-select custom-checkbox ${findIndexTopic(val.id) !== -1 ? "choose-related-department-select-active" : ""}`} key={`select-${idx}`}> */}
                          {/* <div className="custom-checkbox"> */}
                              {/* <input type="checkbox" id={`select-${val.id}`} checked={findIndexTopic(val.id) !== -1} onClick={(e) => onChangeTopic(e, val.id)}/>
                              <label htmlFor={`select-${val.id}`}>{val.name}</label> */}
                          {/* </div> */}
                        {/* </div> */}
                      {/* ) */}
                    {/* })} */}
                  {/* </div> */}
                  <SelectTopic 
                    onChangeSearchTopic={onChangeSearchTopic}
                    onCheckAll={onCheckAll}
                    onClearAll={onClearAll}
                    topicList={topicList}
                    onChangeSingleSelect={onChangeSingleSelect}
                    isChecked={isChecked}
                    isCheckAll={isCheckAll}
                    searchTopic={searchTopic}
                    title={"Choose Related Departments"}
                    selectedTopic={selectedTopic}
                    removeSingleTopic={removeSingleTopic}
                    _className={"input-topic"}
                    isDisabled={isLoadingCreate}
                  />

                  {errorMessageData.topic !== "" &&
                      <ErrorOrInfoComp
                      text={errorMessageData.topic}
                      _className={"font-red"}
                      icon={<FiInfo />}
                      />
                  }

                </>
              }
            </div>
            
            {!isLoadingCreate ?
              <ButtonWithLoadingOrIcon 
                className="orange-button main-button-48 button-create-path"
                text="Create"
                isDisabled={disableCreatePath()}
                onClickAction={createPath}
              />
              :
              <ButtonWithLoadingOrIcon 
                className="orange-button main-button-48 button-create-path"
                text="Create"
                isLoading
                loadingColor="gray"
                isDisabled
                position="left"
              />
            }
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const toggleModalSaveChanges = () => {
    setIsShowModalSaveChanges(!isShowModalSaveChanges)
  }

  const saveChanges = () => {
    // console.log('save changes')
  }

  const toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  const discardProgress = () => {
    // console.log('discard progress')
  }

  const goToDetail = (id, rootItemID) => {
    props.history.push({
      pathname: `/o/${orgID}/setup/qna/detail/${id}/${rootItemID}`
    })
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="sectionWrap scf-path-main-wrapper">
        {renderModalCreatePath()}

        {/* tier blocking */}
        {retriveDataError.code  === "49901" &&
            <RetrieveDataLoading 
                isLoading={isLoadingFaq}
                errorMessage={retriveDataError.message}
                errorCode={retriveDataError.code}
            />
        }
        {/* tier blocking */}

        <div className="topContent scf-path-header">
          <p className="scf-path-title"><b>QnA Path</b></p>
          
          <p className="scf-path-description">
            QnA Path can be used to show FAQ (Frequently Asked Questions) in live chat or as static conversation bot in other channels.
          </p>

          {!retriveDataError.code &&
            <div className="topContent-button-wrapper">
              {!isLoadingFaq &&
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40"
                  text="Create QnA Path"
                  position="left"
                  onClickAction={() => {props.history.push(`/o/${orgID}/setup/qna/add`)}}
                  icon={{
                    type: "svg",
                    src: FiPlus
                  }}
                />
              }
            </div>
          }
        </div>

        {
          (isLoadingFaq || retriveDataError.code) ?
            <RetrieveDataLoading
              isLoading={isLoadingFaq}
              errorMessage={retriveDataError.message}
            />
            :
            <>
              <div className="scf-path-list-table-wrapper">
                <div className="custom-table">
                  <table className="custom-table-content faq-list">
                    <thead>
                      <tr>
                        <td className="col_id">ID</td>
                        <td className="col_title">Title</td>
                        {/* <td className="col_content">Description</td> */}
                        <td className="col_type">Type</td>
                        <td className="col_action"></td>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        dataFaq.length > 0 ?
                          dataFaq.map(item => {
                            return (
                              <tr key={`user-contact-${item.id}`}>
                                <td className="col_id">
                                  {item.id}
                                </td>
                                <td className="col_title">
                                  {item.title}
                                </td>
                                {/* <td className="col_content">
                                  {item.content}
                                </td> */}
                                <td className="col_type">
                                  {TAPLIVE_FAQ_TYPE[item.type] ? TAPLIVE_FAQ_TYPE[item.type].text : "-"}
                                </td>
                                <td className="col_action">
                                  <NavLink to={`/o/${orgID}/setup/qna/detail/${item.id}/${item.rootItemID}`}>
                                    <b className="orange-link-text">
                                      <FiEye />
                                      View
                                    </b>
                                  </NavLink>
                                </td>
                              </tr>
                            )
                          })
                          :
                          <tr className="no-hover">
                            <td colSpan="4" className="no-data-column"><b className="no-result-found">No path found.</b></td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </>
        }
        <PopupRemove
          removeTitle={`This Contact Has Open Cases from WhatsApp`}
          removeDescription={`To save changes to this contact’s phone number, you must resolve all ongoing cases from WhatsApp.`}
          onClickCancel={toggleModalSaveChanges}
          onClickRemove={saveChanges}
          // waitForResponse={waitForResponseSaveChanges}
          showModalProps={isShowModalSaveChanges}
          submitText="Resolve and Save Changes"
        // colorButton="red-button"
        />
        <PopupRemove
          removeTitle={`Discard Progress?`}
          removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
          onClickCancel={toggleModalDiscard}
          onClickRemove={discardProgress}
          // waitForResponse={waitForResponseDiscard}
          showModalProps={isShowModalDiscard}
          submitText="Resolve and Save Changes"
        // colorButton="red-button"
        />
      </div>
    </SectionWrap >
  );
};

export default Faq;