import React, { useState, useEffect } from "react";
import "./ChatRoomInputEmailAttachment.scss";
import { tapCoreMessageManager } from "@taptalk.io/web-sdk";
import { FiX, FiUpload, FiPaperclip, FiTrash2, FiPlus, FiUploadCloud } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import HelperChat from "../../../../../helper/HelperChat";
import { doToast } from "../../../../../helper/HelperGeneral";
import { setEmailAttachments } from "../../../../../redux/actions/reduxActionEmailAttachments";
import { connect } from "react-redux"

let ChatRoomInputEmailAttachment = (props) => {
    let { showModal, onCloseClick } = props;
    let [waitUpload, setWaitUpload] = useState(false);

    useEffect(() => {
        if (props.emailAttachments.length > 0) {
            if (!waitUpload) {
                props.emailAttachments.map((v, i) => {
                    if (!v.url) {
                        setWaitUpload(true);
                        doUpload(v);
                    }

                    return null;
                })
            }
        }
    }, [JSON.stringify(props.emailAttachments)])

    let doUpload = (file) => {
        let _emailAttachment = props.emailAttachments.slice();

        let uploadData = {
            file: file,
            caption: "",
            room: ""
        };

        tapCoreMessageManager.uploadChatFile(uploadData, {
            onProgress: (percentage, bytes) => {
                // setPercentageUpload(percentage);

                if (percentage === 100) {
                    //   setPercentageUpload(false);
                    //   setFinishUpload(true);
                }
            },

            onSuccess: (response) => {
                if (response) {
                    let _findIdx = _emailAttachment.findIndex(v => v.fileID === file.fileID);

                    if (_findIdx !== -1) {
                        _emailAttachment[_findIdx].url = response.url
                    }

                    setWaitUpload(false);
                    props.setEmailAttachments(_emailAttachment);

                    //   let newFileData = {};
                    //   let arrayFileData = [];
                    //   arrayFileData.push(file);
                    // newFileData.fileData = arrayFileData;
                    // newFileData.fileName = file.name;
                    // newFileData.fileURL = response.fileURL;
                    //   props.onChangeAssetFileProps(newFileData, props._index, response.fileURL);
                }
            },

            onError: (error) => {
                let _findIdx = _emailAttachment.findIndex(v => v.fileID === file.fileID);
                doToast(`Failed to upload ${_emailAttachment[_findIdx].name}`, "fail");

                if (_findIdx !== -1) {
                    _emailAttachment.splice(_findIdx, 1);
                }

                setWaitUpload(false);
                props.setEmailAttachments(_emailAttachment);
                console.log(error);
            }
        })
    }

    let removeFileAttachment = (i) => {
        let _emailAttachment = props.emailAttachments.slice();

        _emailAttachment.splice(i, 1);

        props.setEmailAttachments(_emailAttachment);
    }

    let renderList = (data, i) => {
        return (
            <div className="chat-room-input-email-attachment-value-list" key={`selected-attachment-${i}`}>
                <b>
                    {/* orange text filename pas kelar download */}
                    <p className={`filename`}>
                        {data.name}
                    </p>

                    <p className={`filesize`}>
                        ({HelperChat.bytesToSize(data.size)})
                    </p>
                </b>

                {!data.url ?
                    <div className="lds-ring">
                        <div /><div /><div /><div />
                    </div>
                    :
                    <FiTrash2 className="cursor-pointer" onClick={() => {
                        removeFileAttachment(i)
                    }} />
                }
            </div>
        )
    }

    let handleDropFile = (e) => {
        e.preventDefault();
        let counter = 10;
        let files = e.dataTransfer.files;
        let newFileArr = props.emailAttachments.slice();
        let currentSize = 0;
        let totalSize = 0;

        if (props.emailAttachments.length > 0) {
            props.emailAttachments.map((v) => {
                currentSize = currentSize + v.size;
                return null;
            })
        }

        for (let i = 0; i < files.length; i++) {
            let file = files.item(i)

            totalSize = totalSize + file.size;
        }

        if ((totalSize + currentSize) > 26214400) {
            doToast("Total attachments file size cannot exceed 25MB", "fail")
        } else {
            for (let i = 0; i < files.length; i++) {
                let file = files.item(i)

                counter = counter + 10;
                file.fileID = `file-${new Date().valueOf() + counter}`;
                newFileArr.push(file);

                if (i === (files.length - 1)) {
                    props.setEmailAttachments(newFileArr);
                }
            }
        }
    }

    let onChangeEmailAttach = (e) => {
        let targetFiles = e.target.files[0];
        let fileSize = targetFiles.size;
        let currentSize = 0;
        let totalSize = 0;

        if (props.emailAttachments.length > 0) {
            props.emailAttachments.map((v) => {
                currentSize = currentSize + v.size;
                return null;
            })
        }

        totalSize = fileSize + currentSize;

        if (totalSize > 26214400) {
            doToast("Total attachments file size cannot exceed 25MB", "fail")
        } else {
            let _emailAttachment = props.emailAttachments.slice();
            targetFiles.fileID = `file-${new Date().valueOf()}`;
            _emailAttachment.push(targetFiles);
            props.setEmailAttachments(_emailAttachment);
        }

        e.target.value = null;
    }

    return (
        <div
            className={`chat-room-input-email-attachment ${showModal ? "active-chat-room-input-email-attachment" : ""}`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => { handleDropFile(e) }}
        >
            <input type="file" className="force-hide" id="email-attachment" onChange={(e) => onChangeEmailAttach(e)} />

            <div className={`modal-body ${showModal ? "active-modal-body-chat-room-input-email-attachment" : ""}`}>
                <div onClick={onCloseClick} className="close-modal-chat-room-input-email-attachment">
                    <FiX />
                </div>

                <div className="chat-room-input-email-attachment-head">
                    <FiPaperclip />

                    <b>Insert Attachments</b>

                    <p>
                        Insert multiple attachments on your mail.
                    </p>
                </div>

                {/* <div className="chat-room-input-email-attachment-neck">
                    <div className="chat-room-input-email-attachment-neck-part">
                        <p>
                            <b>To:</b>
                        </p>
                    </div>

                    <div className="chat-room-input-email-attachment-neck-part">
                        <p>
                            <b>CC:</b>
                        </p>
                    </div>

                    <div className="chat-room-input-email-attachment-neck-part">
                        <p>
                            <b>Subject:</b>
                        </p>
                    </div>
                </div> */}

                <div className="chat-room-input-email-attachment-body">
                    {props.emailAttachments.length === 0 ?
                        <div className="chat-room-input-email-attachment-body-placeholder-wrapper">
                            <FiUploadCloud className="icon-cloud" />

                            <br />

                            <span className="chat-room-input-email-placeholder">
                                Drop your attachments here or press Select Attachments button below
                            </span>

                            <ButtonWithLoadingOrIcon
                                className="no-fill-button main-button-40"
                                position="left"
                                icon={{
                                    type: "svg",
                                    src: FiUpload
                                }}
                                text="Select Attachments"
                                onClickAction={() => {
                                    let el = document.querySelector("#email-attachment");

                                    if (el) {
                                        el.click();
                                    }
                                }}
                            />
                        </div>
                        :
                        <div className="chat-room-input-email-attachment-value-wrapper">
                            {props.emailAttachments.map((v, i) => {
                                return (
                                    renderList(v, i)
                                )
                            })}

                            <b
                                className="button-add-attachment"
                                htmlFor="email-attachment"
                                onClick={() => {
                                    let el = document.querySelector("#email-attachment");

                                    if (el) {
                                        el.click();
                                    }
                                }}
                            >
                                <FiPlus />
                                Add Attachments
                            </b>
                        </div>
                    }

                </div>

                <div className="chat-room-input-email-attachment-button-footer">
                    <ButtonWithLoadingOrIcon
                        className="orange-button main-button-48"
                        text="Confirm"
                        onClickAction={() => {
                            onCloseClick();
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    emailAttachments: state.emailAttachments
});

const mapDispatchToProps = {
    setEmailAttachments
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomInputEmailAttachment);