import React, { useState, useEffect } from "react";
import "./IntegrationWebhook2.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import IntegrationWebhookServices from "../../../services/integration/IntegrationWebhookServices";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CopyBox from "../../reuseableComponent/copyBox/CopyBox";
import DisabledEnabled from "../../reuseableComponent/disabledEnabled/DisabledEnabled";
import { NavLink } from "react-router-dom";
import { FiEdit, FiCheck, FiX } from "react-icons/fi";

const IntegrationWebhook2 = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [data, setData] = useState(false);
  let [dataList, setDataList] = useState(false);
  let [waitForResponseFetch, setWaitForResponseFetch] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration/webhooks`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let fetchData = () => {
    let _retriveDataError = {...retriveDataError};
    
    IntegrationWebhookServices.getWebhook(orgID, (response) => {
      let dataResult = response.dataResult;
      if(dataResult.error.message === "") {
        setData(dataResult.data.webhookConfigs);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseFetch(false);
    })
  }

  let fetchDataList = () => {
    let _retriveDataError = {...retriveDataError};
    
    IntegrationWebhookServices.getWebhookEvent(orgID, (response) => {
      let dataResult = response.dataResult;
      if(dataResult.error.message === "") {
        setDataList(dataResult.data.eventTypes);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        setWaitForResponseFetch(false);
      }
    })
  }

  useEffect(() => {
    if(dataList) {
      fetchData();
    }
  }, [dataList])

  useEffect(() => {
    if(orgID) {
      // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      fetchDataList();
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(props.history.location.state) {
      if(props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  let findIndexEvent = (text) => {
    let idx = data.subscribedEventTypes.findIndex((v) => v === text);

    return idx;
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap webhook2-main-wrapper">
            {/* tier blocking */}
            {retriveDataError.code  === "49901" &&
              <RetrieveDataLoading 
                isLoading={waitForResponseFetch}
                errorMessage={retriveDataError.message}
                errorCode={retriveDataError.code}
              />
            }
            {/* tier blocking */}
            <div className="webhook2-main-wrapper-title-wrapper">
              <div className="title"><b>Webhooks</b></div>

              {(!waitForResponseFetch && !retriveDataError.code) &&
                <NavLink to={`/o/${orgID}/integration/webhooks/setup`}>
                  <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40"
                    text="Set"
                    position="left"
                    icon={{
                      type: "svg",
                      src: FiEdit
                    }}
                  />
                </NavLink>
              }
            </div>

            <div className="webhook2-list-info">
              For more information, <a className="orange-text" rel="noopener noreferrer" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/webhook" target="_blank">please read this documentation.</a>
            </div>

            {waitForResponseFetch || retriveDataError.code ? 
              <RetrieveDataLoading 
                isLoading={waitForResponseFetch}
                errorMessage={retriveDataError.message} 
              />
              :
              <React.Fragment>
                <div className="webhook2-wrapper">
                  <p className="label-title">
                    <b>Status</b>
                  </p>
                  <DisabledEnabled status={data.isEnabled ? "enabled" : "disabled"} />

                  {data.isEnabled &&
                    <>
                      <p className="label-title">
                        <b>Webhook URL</b>
                      </p>
                      <CopyBox 
                        id={`webhook-url`}
                        val={data.webhookURL}
                        copyText="Webhook URL Copied"
                      />

                      <p className="label-title">
                        <b>Secret Key</b>
                      </p>
                      <CopyBox 
                        id={`secret-key`}
                        val={data.secretKey}
                        copyText="Secret Key Copied"
                      />

                      <p className="label-title">
                        <b>Subscribed Events</b>
                      </p>

                      {dataList.map((v, i) => {
                        return (
                          <div key={`event-${i}`} className="event-wrap">
                            {findIndexEvent(v.type) === -1 ?
                              <FiX className="icon-x" />
                              :
                              <FiCheck className="icon-check" />
                            }

                            <span>
                              {v.name}
                            </span>

                            <p>
                              {v.description}
                            </p>
                          </div>
                        )
                      })}
                    </>
                  }
                </div>
              </React.Fragment>
            }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default IntegrationWebhook2;
