import React, { useState, useEffect } from 'react';
import './ChatRoomBroadcastHistoryDetailTab.scss';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { IoIosClose } from "react-icons/io";
import { FiFileText } from "react-icons/fi";
// import { FiImage } from 'react-icons/fi';
import IconCommendChecked from '../../../../../assets/img/chatroom/user-info-tab/comment-checked.svg';
// import IconNote from '../../../../../assets/img/chatroom/user-info-tab/note.svg';
import IconMessage from '../../../../../assets/img/chatroom/user-info-tab/message.svg';
// import IconText from '../../../../../assets/img/chatroom/user-info-tab/icon-text.svg';
import IconBroadcast from '../../../../../assets/img/chatroom/user-info-tab/icon-broadcast.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import { doToast } from "../../../../../helper/HelperGeneral";
import HelperDate from "../../../../../helper/HelperDate";
import Helper from "../../../../../helper/HelperChat";
import { TAPLIVE_MEDIUM_LOGO } from "../../../../../constants/taplive";
import BroadcastMessageServices from "../../../../../services/newServices/BroadcastMessageServices";
import Loading from '../../../../reuseableComponent/loading/Loading';
import ErrorComponent from '../../../../reuseableComponent/errorComponent/ErrorComponent';

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};

var ChatRoomBroadcastHistoryDetailTab = (props) => {
  let [modalMedia, setModalMedia] = useState({
    show: false,
    data: false
  });
  let [isLoadingGetBroadcastData, setIsLoadingGetBroadcastData] = useState(true);
  let [broadcastDetail, setBroadcastDetail] = useState(false);
  let [errorResponse, setErrorResponse] = useState("");

  useEffect(() => {
    setIsLoadingGetBroadcastData(true)
    fetchBroadcastMessageDetail()
  }, [])

  let fetchBroadcastMessageDetail = () => {
    let data = {
      pageSize: 20,
      pageNumber: 1,
      id: props.broadcastHistoryPopupDataProps.broadcast.id
    }

    setErrorResponse("");
    setIsLoadingGetBroadcastData(true);
    
    BroadcastMessageServices.getBroadcastDetails(props.parentProps.match.params.orgID, data, (response) => {
      if (response.dataResult.error.message === "") {
        setBroadcastDetail(response.dataResult.data);
      } else {
        setErrorResponse(response.dataResult.error.message);
        doToast(response.dataResult.error.message, "fail");
      }

      setIsLoadingGetBroadcastData(false);
    });
  };

  // let toCapitalize = (word) => {
  //   return word.substring(0, 1).toUpperCase() + word.split("").splice(1).join("");
  // }

  let generateIconOmnichannel = (medium) => {
    return TAPLIVE_MEDIUM_LOGO[medium] ? TAPLIVE_MEDIUM_LOGO[medium] : "";
  }

  let toggleModalMedia = (data = false) => {
    let _modalMedia = {...modalMedia};
    _modalMedia.show = !_modalMedia.show;
    _modalMedia.data = data;

    setModalMedia(_modalMedia);
  }

  let generateModalMedia = () => {
    let zoomImage = (e) => {
        var zoomer = e.currentTarget;
        zoomer.style.backgroundSize = '200%';
        var offsetX = e.nativeEvent.offsetX;
        var offsetY = e.nativeEvent.offsetY;
        
        var x = offsetX/zoomer.offsetWidth*100
        var y = offsetY/zoomer.offsetHeight*100
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
    }

    let zoomImageOut = (e) => {
        var zoomer = e.currentTarget;
        zoomer.style.backgroundSize = '0%';
    }
    
    return (
        <div>
          <Modal isOpen={modalMedia.show} className={'modal-media-broadcast'} toggle={toggleModalMedia}>
            <ModalBody>
                <IoIosClose className="close-modal-media-broadcast" onClick={() => toggleModalMedia()} />

                {modalMedia.data.messageType === "image" &&
                  <div className="image-wrapper">
                      <figure 
                          className="zoom jum" 
                          style={{"backgroundImage": `url(${modalMedia.data.contentBody})`}} 
                          onMouseMove={(e) => zoomImage(e, modalMedia.data.contentBody)}
                          onMouseLeave={(e) => zoomImageOut(e)}
                      >
                          <img 
                              src={modalMedia.data.contentBody} 
                              alt="" 
                              className="image-preview-val"
                          />
                      </figure>
                  </div>
                }

                {modalMedia.data.messageType === "video" &&
                  <div className='video-wrapper'>
                    <video
                      src={modalMedia.data.contentBody} 
                      controls
                    />
                  </div>
                }
            </ModalBody>
          </Modal>
        </div>
    );
  }

  let generateMessageContent = (data, index) => {
    return (
      <div className="user-info-list-wrapper">
        <label><b>Message #{index + 1}</b></label>
        <div className={`user-info-box-wrapper broadcast-content-box ${props.broadcastHistoryPopupDataProps.broadcast.messageType === "image" ? "broadcast-content-box-image" : ""}`}>
          <img src={IconMessage} alt="" className="icon-info" />

          <div className="broadcast-content">
            {/* {props.broadcastHistoryPopupDataProps.broadcast.messageType === "image" &&
              <img src={props.broadcastHistoryPopupDataProps.broadcast.contentBody} alt="" className="broadcast-image" onClick={toggleModalMedia} />
            } */}

            {data.messageType === "text" && 
              <p dangerouslySetInnerHTML={ {__html: Helper.lineBreakChatRoom(data.contentBody)} } className="broadcast-text" />
            }

            {data.messageType === "image" && 
              <img src={data.contentBody} alt="" className='broadcast-image' onClick={() => toggleModalMedia(data)} />
            }

            {data.messageType === "video" && 
              <video src={data.contentBody} className='broadcast-video' onClick={() => toggleModalMedia(data)} />
            }

            {data.messageType === "file" &&
              <div className='file-content-wrapper'>
                <div className='file-content-left'>
                  <FiFileText />
                </div>

                <a target="_blank" href={data.contentBody} download rel="noopener noreferrer">
                  <div className='file-content-right'>
                    <p className='file-name'>
                      {data.contentFileName}
                    </p>

                    {/* <p className='file-size'>
                      458 KB
                    </p> */}
                  </div>  
                </a>
              </div>
            }

            {data.contentCaption !== "" &&
              <p className='caption-broadcast'>
                {data.contentCaption}
              </p>
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <Scrollbars autoHideTimeout={500}
                renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
    >
      <div className="chat-room-detail-tab-wrapper main-user-info-wrapper broadcast-message-detail-wrapper">
          {/* {props.broadcastHistoryPopupDataProps.broadcast.messageType === "image" && generateModalMedia()} */}
          {generateModalMedia()}
          {(isLoadingGetBroadcastData) ?
            <Loading />
            :
            (errorResponse !== "" ?
              <ErrorComponent 
                text={errorResponse}
              />
              :
              <>
                <div className="user-info-list-wrapper">
                  <label><b>Broadcast Name</b></label>
                  <div className="user-info-box-wrapper">
                    <img src={IconBroadcast} alt="" />
                    {broadcastDetail.broadcast.name}
                  </div>
                </div>

                <div className="user-info-list-wrapper">
                  <label><b>Channel</b></label>
                  <div className="user-info-box-wrapper">
                    {/* <img src={IconList} alt="" /> */}
                    <img src={generateIconOmnichannel(broadcastDetail.broadcast.medium)} alt="" className="channel-icon-detail" />
                    {/* {TAPLIVE_MEDIUM_TEXT[broadcastDetail.broadcast.medium]} */}
                    {broadcastDetail.broadcast.mediumChannelName}
                  </div>
                </div>

                {/* <div className="user-info-list-wrapper">
                  <label><b>Message Type</b></label>
                  <div className="user-info-box-wrapper">
                    {props.broadcastHistoryPopupDataProps.broadcast.messageType === "text" ?
                      <img src={IconText} alt="" />
                      :
                      <FiImage />
                    }
                    
                    {toCapitalize(props.broadcastHistoryPopupDataProps.broadcast.messageType)}
                  </div>
                </div> */}

                <div className="user-info-list-wrapper">
                  <label><b>Sent</b></label>
                  <div className="user-info-box-wrapper">
                    <img src={IconCommendChecked} alt="" />
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.createdTime), "dd/MM/yyyy")} <div className="broadcast-dot" /> {HelperDate.formatToString(new Date(props.broadcastHistoryPopupDataProps.broadcast.createdTime), "HH:mm")}
                  </div>
                </div>  

                {broadcastDetail.messages.map((v, i) => {
                  return (
                    generateMessageContent(v, i)
                  )
                })}

                {/* <div className="user-info-list-wrapper">
                  <label><b>Message</b></label>
                  <div className={`user-info-box-wrapper broadcast-content-box ${props.broadcastHistoryPopupDataProps.broadcast.messageType === "image" ? "broadcast-content-box-image" : ""}`}>
                    <img src={IconNote} alt="" className="icon-info" />

                    <div className="broadcast-content">
                      {props.broadcastHistoryPopupDataProps.broadcast.messageType === "image" &&
                        <img src={props.broadcastHistoryPopupDataProps.broadcast.contentBody} alt="" className="broadcast-image" onClick={toggleModalMedia} />
                      }

                      {props.broadcastHistoryPopupDataProps.broadcast.messageType === "image" ? 
                        <p dangerouslySetInnerHTML={ {__html: Helper.lineBreakChatRoom(props.broadcastHistoryPopupDataProps.broadcast.contentCaption)} } />
                        :
                        <p dangerouslySetInnerHTML={ {__html: Helper.lineBreakChatRoom(props.broadcastHistoryPopupDataProps.broadcast.contentBody)} } />
                      }
                    </div>
                  </div>
                </div>*/}
              </>
            )
          }
      </div>
    </Scrollbars>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomBroadcastHistoryDetailTab);
