import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationGoogleBusinessProfileServices = {
    getList(idOrTime, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.GET_LIST, {}, headers, callback);
    },

    getGoogleApp(idOrTime, callback) {
        let headers = {
            'API-Key': API_KEY(),
            'Device-Platform': 'web',
            'Organization-ID': idOrTime,
        };
    
        BaseService.doPostAccessToken(API.GET_GOOGLE_APP, {}, headers, callback);
    },

    createAccount(idOrTime, data, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.CREATE, data, headers, callback);
    },

    editAccount(idOrTime, data, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.EDIT, data, headers, callback);
    },

    deleteAccount(idOrTime, data, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.DELETE, data, headers, callback);
    },
    
    reconnectAccount(idOrTime, data, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.RECONNECT, data, headers, callback);
    },

    getDetail(idOrTime, data, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);
    },
    
    getAccount(idOrTime, data, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.GET_ACCOUNT, data, headers, callback);
    },
    
    getLocation(idOrTime, data, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.GET_LOCATION, data, headers, callback);
    },
    
    checkLocationExist(idOrTime, data, callback) {
        let headers = {
        'API-Key': API_KEY(),
        'Device-Platform': 'web',
        'Organization-ID': idOrTime,
        };

        BaseService.doPostAccessToken(API.LOCATION_EXIST, data, headers, callback);
    },
    
};

const API = {
  GET_LIST: '/organization/channel/google_business_profile/get_list',
  CREATE: '/organization/channel/google_business_profile/create',
  EDIT: '/organization/channel/google_business_profile/edit_channel',
  DELETE: '/organization/channel/google_business_profile/delete',
  RECONNECT: '/organization/channel/google_business_profile/reconnect',
  GET_GOOGLE_APP: '/organization/channel/get_google_app',
  GET_DETAIL: '/organization/channel/google_business_profile/get_details',
  GET_ACCOUNT: '/organization/channel/google_business_profile/get_account_list',
  GET_LOCATION: '/organization/channel/google_business_profile/get_location_list',
  LOCATION_EXIST: '/organization/channel/google_business_profile/check_location_exists',
};

export default IntegrationGoogleBusinessProfileServices;
