import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./IntegrationWhatsapp.scss";
import { connect } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { FiArrowUpRight, FiPlus } from "react-icons/fi";
// import IconAdd from "../../../assets/img/icon-add.svg";
import WhatsApp32 from "../../../assets/img/integration/logo-whatsapp-32.svg";
import WhatsappLightGreen from "../../../assets/img/integration/logo-whatsapp-lightgreen-small.svg";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import FilterStatusDropdown from "../../reuseableComponent/FilterStatusDropdown/FilterStatusDropdown";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SearchBox from "../../reuseableComponent/searchBox/SearchBox";
import ModalComparison from "./integrationWhatsappModalComparison/IntegrationWhatsappModalComparison";
import { checkID } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import IntegrationWhatsappServices from "../../../services/integration/IntegrationWhatsappServices";
// import Integrations from "../Integrations";
import mixpanel from "mixpanel-browser";

const IntegrationWhatsapp = props => {
    const FILTER_STATUS = [
        {
            value: "got_qr_code",
            label: "Got QR Code"
        },
        {
            value: "authenticated",
            label: "Authenticated"
        },
        {
            value: "not_paid",
            label: "Not Paid"
        },
        {
            value: "waiting_setup",
            label: "Waiting Setup"
        },
        {
            value: "loading",
            label: "Loading"
        },
        {
            value: "error",
            label: "Error"
        }
    ]

    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingWhatsappAccount, setIsLoadingWhatsappAccount] = useState(true);
    let [whatsappAccountData, setWhatsappAccountData] = useState([]);
    let [showPopupWarning, setShowPopupWarning] = useState(false);
    let [showModalComparison, setShowModalComparison] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [query, setQuery] = useState("");
    let [showFilterStatus, setShowFilterStatus] = useState(false);
    let [filterVal, setFilterVal] = useState({ value: "all", label: "All Status" });
    let [isShowResultFound, setIsShowResultFound] = useState(false);

    let toggleFilterStatus = () => {
        setShowFilterStatus(!showFilterStatus);
    }

    let toggleModalComparison = () => {
        setShowModalComparison(!showModalComparison);
    }

    let toggleShowWarning = () => {
        setShowPopupWarning(!showPopupWarning);
    }

    let getWhatsappAccount = (orgID, _data = false) => {
        setWhatsappAccountData([]);
        let _retriveDataError = { ...retriveDataError };
        setIsLoadingWhatsappAccount(true);

        let data = {
            value: "",
            label: ""
        }

        if (_data) {
            data = _data;
        } else {
            data = {
                search: query,
                channelStatus: filterVal.value
            }
        }

        data.channelStatus = data.channelStatus === "all" ? "" : data.channelStatus;

        if(data.channelStatus === "" && data.search === "") {
            setIsShowResultFound(false);
        }else {
            setIsShowResultFound(true);
        }

        IntegrationWhatsappServices.getList(orgID, data, (response) => {
            let dataResult = response.dataResult;

            if (response.dataResult.status === 200) {
                setWhatsappAccountData(response.dataResult.data.channels);
            } else {
                setWhatsappAccountData([]);
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsLoadingWhatsappAccount(false);
        })
    }

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
            getWhatsappAccount(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (orgID) {
            if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Page] WhatsApp SME Integration",
                    {
                        userFullName: myAgentData.account.fullName,
                        userID: myAgentData.account.id,
                        organizationID: orgID.split("-")[0]
                    }
                );
            }
        }
    }, [orgID])

    let goToAdd = () => {
        // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        // if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        //     mixpanel.track(
        //         "[Button] Add Channel",
        //         {
        //             channel: "WhatsApp SME",
        //             userFullName : myAgentData.account.fullName,
        //             userID : myAgentData.account.id,
        //             organizationID : orgID.split("-")[0]
        //         }
        //     );
        // }

        if (!props.mediumData) {
            toggleShowWarning();
        } else {
            if (props.mediumData.numberOfItems.maximum !== -1 && props.mediumData.numberOfItems.current >= props.mediumData.numberOfItems.maximum) {
                toggleShowWarning();
            } else {
                history.push(`/o/${orgID}/integration/whatsapp-sme/add`);
            }
        }
    };

    let onChangeSearch = (e) => {
        setQuery(e)
    }

    let onSubmitSearch = (e) => {
        getWhatsappAccount(orgID, { search: query, channelStatus: filterVal.value });
    }

    let onClearSearch = () => {
        getWhatsappAccount(orgID, { search: "", channelStatus: filterVal.value });
    }

    let changeFilterStatus = (v) => {
        setFilterVal(v);
        toggleFilterStatus();

        getWhatsappAccount(orgID, { search: query, channelStatus: v.value });
    }

    return (
        <div className="integration-outer-wrapper">
            <ModalComparison
                toggle={toggleModalComparison}
                isShow={showModalComparison}
            />

            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}
                <PopupWarning
                    isShow={showPopupWarning}
                    toggle={toggleShowWarning}
                    text="Maximum number of allowed channels has been reached"
                />

                <div className="sectionWrap integration-main-container integration-whatsapp-list text-left">
                    <React.Fragment>
                        <div className="integration-list-header">
                            <img src={WhatsApp32} alt="" className="integration-list-header-icon" />

                            <div className="integration-list-header-text-wrapper">
                                <b>WhatsApp for SME</b>
                                {/* <p>
                                    To Whatsapp, <a href="#">please read the documentation.</a>
                                </p> */}
                            </div>
                            
                            {(!isLoadingWhatsappAccount && !retriveDataError.code) &&
                                <ButtonWithLoadingOrIcon
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    className="no-fill-button integration-list-header-button main-button-40"
                                    text="Add Channel"
                                    position="left"
                                    onClickAction={goToAdd}
                                />
                            }
                        </div>
                        
                        {!retriveDataError.code &&
                            <>
                                <div className="alert-component-whatsapp">
                                    <img src={WhatsApp32} alt="" />

                                    <b>
                                        WhatsApp for SME and
                                    </b>

                                    <img src={WhatsappLightGreen} alt="" />

                                    <b>
                                        WhatsApp Business API
                                    </b>

                                    <button onClick={toggleModalComparison}>
                                        <b>Compare Now</b>
                                    </button>
                                </div>
                                
                                <div className="search-and-filter-wrapper">
                                    <SearchBox
                                        onSubmitSearch={onSubmitSearch}
                                        onChangeInputSearch={onChangeSearch}
                                        searchValue={query}
                                        placeholder="Search for a channel name or channel ID"
                                        isWaitResponse={isLoadingWhatsappAccount}
                                        onClearSearch={onClearSearch}
                                    />

                                    <FilterStatusDropdown
                                        isOpen={showFilterStatus}
                                        toggle={toggleFilterStatus}
                                        isLoading={isLoadingWhatsappAccount}
                                        filterList={FILTER_STATUS}
                                        changeFilter={(v) => {
                                            changeFilterStatus(v);
                                        }}
                                        value={filterVal}
                                    />
                                </div>
                            </>
                        }

                        

                        {(
                            whatsappAccountData.length > 0 &&
                            isShowResultFound
                        ) &&
                            <p className="filter-result-found">
                                <b>{whatsappAccountData.length} result{whatsappAccountData.length > 1 ? "s" : ""} found</b>
                            </p>
                        }

                        {isLoadingWhatsappAccount || retriveDataError.code ?
                            <RetrieveDataLoading
                                isLoading={isLoadingWhatsappAccount}
                                errorMessage={retriveDataError.message}
                            />
                            :
                            whatsappAccountData.length > 0 ?
                                <>
                                    <div className="integration-list-outer-wrapper">
                                        {whatsappAccountData.map((value, index) => {
                                            return (
                                                <Link key={`whatsapp-account-${index}`}
                                                    to={`/o/${orgID}/integration/whatsapp-sme/detail/${value.id}`}
                                                    className="link-whatsapp-account-list"
                                                >
                                                    <div className="integration-list-inner-wrapper">
                                                        <FiArrowUpRight />
                                                        <p>
                                                            <b>{value.accountName}</b>
                                                        </p>

                                                        <span>{value.stringID}</span>

                                                        <p className="font-root-medium">
                                                            {value.channelStatusText}
                                                        </p>

                                                        <p className={`font-root-medium ${value.isChannelLinkEnabled ? "font-green" : "font-grey"}`}>
                                                            Channel Button for Live Chat is {!value.isChannelLinkEnabled ? "disabled" : "enabled"}
                                                        </p>
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                (query !== "" || filterVal.value !== "all" ?
                                    <div className="integration-list-no-data">
                                        <b className="grey-font">No channels found</b>
                                    </div>
                                    :
                                    <div className="integration-list-no-data">
                                        <b>No Channel</b>
                                        <p>
                                            To add a channel, simply click the "+ Add Channel" button on the top right.
                                        </p>
                                    </div>
                                )
                        }
                    </React.Fragment>
                </div>
            </SectionWrap >
        </div >
    )
}

const mapStateToProps = state => ({
    mediumData: state.mediumData
});

export default connect(mapStateToProps, null)(IntegrationWhatsapp);
