import React, { useState, useEffect } from 'react';
import './IntegrationKataaiDetail.scss';
import { NavLink } from "react-router-dom";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import AutoResolveServices from '../../../../services/newServices/AutoResolveServices';
import IntegrationKataaiServices from '../../../../services/integration/IntegrationKataaiServices';
import AutoUnassignServices from '../../../../services/newServices/AutoUnassignServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupRemove from '../../../reuseableComponent/popupRemove/PopupRemove';
import IntegrationAutoMessageDetail from "../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import { FiCopy, FiEdit } from 'react-icons/fi';
import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
import iconXgrey from '../../../../assets/img/integration/icon-x-grey.svg';
// import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import { connect } from 'react-redux';

let IntegrationKataaiDetail = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [accountDetail, setAccountDetail] = useState(null);
  let [chatbot, setChatbot] = useState(false);
  // let [isShowModalDeleteChannel, setIsShowModalDeleteChannel] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [isShowModalRemoveChannel, setIsShowModalRemoveChannel] = useState(false);
  let [waitForResponseRemoveChannel, setWaitForResponseRemoveChannel] = useState(false);
  let [isShowModalRemoveChatbot, setIsShowModalRemoveChatbot] = useState(false);
  let [waitForResponseRemoveChatbot, setWaitForResponseRemoveChatbot] = useState(false);
  let [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(false);
  let [welcomeMessageData, setWelcomeMessageData] = useState(false);
  let [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
  let [awayMessageData, setAwayMessageData] = useState(false);
  let [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
  let [closingMessageData, setClosingMessageData] = useState(false);
  let [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
  let [ratingMessageData, setRatingMessageData] = useState(false);
  let [loadingAutoResolve, setLoadingAutoResolve] = useState(false);
  let [autoResolveData, setAutoResolveData] = useState(false);
  let [errorWelcome, setErrorWelcome] = useState(false);
  let [errorAway, setErrorAway] = useState(false);
  let [errorClosing, setErrorClosing] = useState(false);
  let [errorRating, setErrorRating] = useState(false);
  let [errorChatbot, setErrorChatbot] = useState(false);
  let [errorAutoResolve, setErrorAutoResolve] = useState(false);
  let [loadingAutoUnassign, setLoadingAutoUnassign] = useState(false);
  let [autoUnassignData, setAutoUnassignData] = useState(false);
  let [errorAutoUnassign, setErrorAutoUnassign] = useState(false);

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (accountDetail !== null) {
      getChatBot();
      runGetWelcomeMessage();
      runGetAwayMessage();
      runGetClosingMessage();
      runGetRatingMessage();
      runGetAutoResolve();
      runGetAutoUnassign();
    }
  }, [accountDetail])

  let toggleModalRemoveChannel = () => {
    if(!waitForResponseRemoveChannel) {
      setIsShowModalRemoveChannel(!isShowModalRemoveChannel);
    }
  };

  let toggleModalRemoveChatbot = () => {
    if(!waitForResponseRemoveChatbot) {
      setIsShowModalRemoveChatbot(!isShowModalRemoveChatbot);
    }
  };

  let getChatBot = () => {
    setIsLoadingGetDetailData(true);
    setIsShowModalRemoveChatbot();

    IntegrationKataaiServices.getChatBot(orgID, { channelID: parseInt(match.params.accountID) }, (response) => {
      if (response.dataResult.error.message === "") {
        setChatbot(response.dataResult.data);
      } else {
        setErrorChatbot({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        })

        setIsLoadingGetDetailData(false);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getAccountDetail(paramID, match.params.accountID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  let getAccountDetail = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);
    let data = {
      id: parseInt(accountID)
    }

    IntegrationKataaiServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setAccountDetail(response.dataResult.data);
      } else {
        setAccountDetail(null);
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        setIsLoadingGetDetailData(false);
      }
    });
  };

  let runGetWelcomeMessage = () => {
    setLoadingGetWelcomeMessage(true);

    IntegrationKataaiServices.getWelcomeMessage(orgID, match.params.accountID, (response) => {
      if (response.dataResult.error.message === "") {
        setWelcomeMessageData(response.dataResult.data.welcomeMessage);
      } else {
        setErrorWelcome({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
      }

      setLoadingGetWelcomeMessage(false);
    });
  }

  let runGetAwayMessage = () => {
    setLoadingGetAwayMessage(true);

    IntegrationKataaiServices.getAwayMessage(orgID, match.params.accountID, (response) => {
      if (response.dataResult.error.message === "") {
        setAwayMessageData(response.dataResult.data.awayMessage);
      } else {
        setErrorAway({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
      }

      setLoadingGetAwayMessage(false);
    });
  }

  let runGetClosingMessage = () => {
    setLoadingGetClosingMessage(true);

    IntegrationKataaiServices.getClosingMessage(orgID, match.params.accountID, (response) => {
      if (response.dataResult.error.message === "") {
        setClosingMessageData(response.dataResult.data.closingMessage);
      } else {
        setErrorClosing({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
      }

      setLoadingGetClosingMessage(false);
    });
  }

  let runGetRatingMessage = () => {
    setLoadingGetRatingMessage(true);

    IntegrationKataaiServices.getRatingMessage(orgID, match.params.accountID, (response) => {
      if (response.dataResult.error.message === "") {
        setRatingMessageData(response.dataResult.data.userRating);
      } else {
        setErrorRating({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
      }

      setLoadingGetRatingMessage(false);
    });
  }

  let removeChannel = () => {
    setWaitForResponseRemoveChannel(true);

    let data = {
      id: parseInt(match.params.accountID),
      createdTime: accountDetail.channel.createdTime
    };

    IntegrationKataaiServices.delete(
      orgID,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message, "fail");
            setWaitForResponseRemoveChannel(false);
          } else {
            doToast(response.dataResult.data.message);
            
            setTimeout(() => {
              back();
            }, 3000)
          }
        } else {
          doToast(response.dataResult.data.message, 'fail');
          setWaitForResponseRemoveChatbot(false);
        }
      }
    );
  }

  let removeChatbot = () => {
    setWaitForResponseRemoveChatbot(true);

    let data = {
      connectionID: chatbot.item.id,
      channelID: parseInt(match.params.accountID),
      chatbotID: chatbot.item.chatbotID,
    };

    IntegrationKataaiServices.removeChatBot(
      orgID,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message);
            setWaitForResponseRemoveChatbot(false);
          } else {
            doToast(response.dataResult.data.message);
            // toggleModalRemoveChatbot();
            // setChatbot(null);
            setIsShowModalRemoveChatbot(false);
            getChatBot();
            getAccountDetail(orgID, match.params.accountID);
          }
        } else {
          doToast(response.dataResult.data.message, 'fail');
          setWaitForResponseRemoveChatbot(false);
        }
      }
    );
  }

  let back = () => history.push(`/o/${orgID}/integration/kataai`);

  let renderTopics = () => {
    let text = "";

    accountDetail.channel.topics.map((v, i) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  let runGetAutoResolve = () => {
    setLoadingAutoResolve(true);

    AutoResolveServices.getAutoResolve(orgID, "kataai", { id: parseInt(match.params.accountID) }, (response) => {
      if (response.dataResult.error.message === "") {
        setAutoResolveData(response.dataResult.data.autoResolveConfig);
      } else {
        setErrorAutoResolve({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
      }

      setLoadingAutoResolve(false);
    });
  }

  let runGetAutoUnassign = () => {
    setLoadingAutoUnassign(true);

    AutoUnassignServices.getAutoUnassign(orgID, "kataai", { id: parseInt(match.params.accountID) }, (response) => {
      if (response.dataResult.error.message === "") {
        setAutoUnassignData(response.dataResult.data.autoUnassignAgentConfig);
      } else {
        setErrorAutoUnassign({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
      }

      setLoadingAutoUnassign(false);
    });
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-kataai-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <>
              <BackButton text="Back to Kata.ai Channel" onClick={back} />

              <div className="integration-kataai-detail-account-box-1">
                <div className="integration-kataai-detail-account-box-1-top integration-kataai-detail-account-box-1-top-1-line">
                  <b className='box-title'>Channel Details</b>
                  
                  <div className="integration-kataai-detail-account-box-1-top-button">
                    <ButtonWithLoadingOrIcon
                      icon={{
                        type: "img",
                        src: iconDelete
                      }}
                      className="red-button main-button-40 minWidth180"
                      text="Delete"
                      position="left"
                      onClickAction={toggleModalRemoveChannel}
                    />

                    <NavLink to={`/o/${orgID}/integration/kataai/edit-detail/${props.match.params.accountID}`}>
                      <ButtonWithLoadingOrIcon
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40 minWidth180"
                        text="Edit"
                        position="left"
                      />
                    </NavLink>
                  </div>
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Channel ID</b>
                  </label>
                  <div className="disabled-form">
                    <input type="text"
                      value={accountDetail.channel.stringID}
                      // onClick={copyToClip}
                      id="copy-string-id"
                      readOnly
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("copy-string-id", "Channel ID copied")
                      }}
                    />
                  </div>
                </div>

                <div className="detail-topic">
                  <div className="topic-wrapper">
                    <b className="detail-title">Channel Name</b>
                    {accountDetail.channel.channelName}
                  </div>
                </div>

                {accountDetail.channel.topics.length > 0 &&
                  <div className="detail-topic">
                    <div className="topic-wrapper">
                      <b className="detail-title">Topic</b>
                      <p className="topic-val">
                        <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                      </p>
                    </div>
                  </div>
                }
              </div>

              <div className="integration-kataai-detail-account-box-1 channel-config-top">
                <div className="integration-kataai-detail-account-box-1-top integration-kataai-detail-account-box-1-top-1-line">
                  <b className='box-title'>Channel Configs</b>
                  
                  <div className="integration-kataai-detail-account-box-1-top-button">
                    <NavLink to={`/o/${orgID}/integration/kataai/edit-config/${props.match.params.accountID}`}>
                      <ButtonWithLoadingOrIcon
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40 minWidth180"
                        text="Edit"
                        position="left"
                      />
                    </NavLink>
                  </div>
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Kata URL</b>
                  </label>
                  <div className="disabled-form">
                    <input type="text"
                      value={accountDetail.configs.kataURL}
                      // onClick={copyToClip}
                      id="copy-kata-url"
                      readOnly
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("copy-kata-url", "Kata URL copied")
                      }}
                    />
                  </div>

                  <label>
                    <b>OneTalk URL</b>
                  </label>
                  <div className="disabled-form">
                    <input type="text"
                      value={accountDetail.configs.oneTalkURL}
                      // onClick={copyToClip}
                      id="copy-onetalk-url"
                      readOnly
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("copy-onetalk-url", "Onetalk URL copied")
                      }}
                    />
                  </div>

                  <label>
                    <b>Secret Key</b>
                  </label>
                  <div className="disabled-form">
                    <input type="text"
                      value={accountDetail.configs.secretKey}
                      // onClick={copyToClip}
                      id="secret-key"
                      readOnly
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("secret-key", "Secret Key copied")
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* chatbot */}
              <div className="integration-kataai-detail-account-box-1 box-channel-button">
                <div className="integration-kataai-detail-account-box-1-top integration-kataai-detail-account-box-1-top-1-line">
                  <b className='box-title'>Chatbot</b>
                  
                  {!errorChatbot &&
                    <div className="integration-kataai-detail-account-box-1-top-button">
                      {chatbot.item.id !== 0 &&
                        <ButtonWithLoadingOrIcon
                          icon={{
                            type: "img",
                            src: iconDelete
                          }}
                          className="red-button main-button-40 button-channel-detail"
                          text="Remove Chatbot"
                          position="left"
                          onClickAction={() => toggleModalRemoveChatbot()}
                        />
                      }

                      <ButtonWithLoadingOrIcon
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40 button-channel-detail minWidth180"
                        text="Set Chatbot"
                        position="left"
                        onClickAction={() => {
                          history.push({
                            pathname: `/o/${orgID}/integration/kataai/edit-chatbot/${match.params.accountID}`,
                            state: {
                              accountDetail: accountDetail,
                              chatbot: chatbot.item
                            }
                          })
                        }}
                      />
                    </div>
                  }
                </div>

                {errorChatbot ?
                  <RetrieveDataLoading
                    isLoading={false}
                    errorCode={errorChatbot ? errorChatbot.errorCode : false}
                    errorMessage={errorChatbot ? errorChatbot.errorMessage : false}
                  />
                  :
                  <div className="kataai-detail-topic">
                    <div className="channel-button-wrapper margin-top24">
                      <b className="channel-button-title">Chatbot Name</b>
                      <br />
                      {chatbot.item.id === 0 ? "Not Set" : chatbot.item.chatbotName}
                    </div>

                    <div className="channel-button-wrapper margin-top24">
                      <b className="channel-button-title">Chatbot Type</b>
                      <br />
                      {chatbot.item.id === 0 ? "Not Set" : chatbot.item.chatbotTypeName}
                    </div>

                    <div className="channel-button-wrapper status-wrapper margin-top24">
                      <b className="detail-title">Status</b>
                      <br />

                      {chatbot.item.id !== 0 ?
                        <>
                          <img src={statusConnected} alt="" />
                          <span className="status-value text-green">Enabled</span>
                        </>
                        :
                        <>
                          <img src={iconXgrey} alt="" />
                          <span className="status-value">Disabled</span>
                        </>
                      }
                    </div>

                    {/* <ButtonWithLoadingOrIcon

                    /> */}
                  </div>
                }

                <PopupRemove
                  removeTitle={`Remove Chatbot?`}
                  removeDescription={`This chatbot will be removed from this channel.`}
                  onClickCancel={toggleModalRemoveChatbot}
                  onClickRemove={removeChatbot}
                  waitForResponse={waitForResponseRemoveChatbot}
                  showModalProps={isShowModalRemoveChatbot}
                  submitText="Delete"
                  colorButton="red-button"
                />

                <PopupRemove
                  removeTitle={`Delete Channel?`}
                  removeDescription={`Once deleted, you will not be able to receive and send messages from this channel anymore.`}
                  onClickCancel={toggleModalRemoveChannel}
                  onClickRemove={removeChannel}
                  waitForResponse={waitForResponseRemoveChannel}
                  showModalProps={isShowModalRemoveChannel}
                  submitText="Delete"
                  colorButton="red-button"
                />
              </div>
              {/* chatbot */}

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/kataai/auto-message/setup/${match.params.accountID}`,
                    state: {
                      type: type
                    }
                  })
                }}
                data={welcomeMessageData}
                isLoading={loadingGetWelcomeMessage}
                type="welcome"
                error={errorWelcome}
              />

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/kataai/auto-message/setup/${match.params.accountID}`,
                    state: {
                      type: type
                    }
                  })
                }}
                data={awayMessageData}
                isLoading={loadingGetAwayMessage}
                type="away"
                error={errorAway}
              />

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/kataai/auto-message/setup/${match.params.accountID}`,
                    state: {
                      type: type
                    }
                  })
                }}
                data={closingMessageData}
                isLoading={loadingGetClosingMessage}
                type="closing"
                error={errorClosing}
              />

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/kataai/auto-message/setup/${match.params.accountID}`,
                    state: {
                      type: type
                    }
                  })
                }}
                data={ratingMessageData}
                isLoading={loadingGetRatingMessage}
                type="rating"
                error={errorRating}
              />

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/kataai/auto-resolve-setup/${match.params.accountID}`,
                    state: {
                      type: type,
                      medium: "kataai",
                      backToLink: `/o/${orgID}/integration/kataai/detail/${match.params.accountID}`
                    }
                  })
                }}
                data={autoResolveData}
                isLoading={loadingAutoResolve}
                type="autoResolve"
                error={errorAutoResolve}
              />

              <IntegrationAutoMessageDetail
                withMarginTop
                onClickEdit={(type) => {
                  props.history.push({
                    pathname: `/o/${orgID}/integration/kataai/auto-unassign-setup/${match.params.accountID}`,
                    state: {
                      type: type,
                      medium: "kataai",
                      backToLink: `/o/${orgID}/integration/kataai/detail/${match.params.accountID}`
                    }
                  })
                }}
                data={autoUnassignData}
                isLoading={loadingAutoUnassign}
                type="autoUnassign"
                error={errorAutoUnassign}
              />
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

let mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

let mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationKataaiDetail);
