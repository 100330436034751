const INITIAL_STATE = false;

let reduxReducerMediumLastRun = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_MEDIUM_LAST_RUN':
        return action.mediumLastRun;
      case 'CLEAR_MEDIUM_LAST_RUN':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerMediumLastRun;