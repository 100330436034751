import React, { useState } from "react";
import { FiEye, FiX } from "react-icons/fi";
import { Modal, ModalBody } from "reactstrap";
import "./ImageUpload.scss";
import Loading from "../loading/Loading";

const ImageUpload = (props) => {
  const { withoutRemove = false } = props;
  let [showModalImage, setShowModalImage] = useState(false);

  function isDescendant(parent, child) {
    var node = child.parentNode;
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  let toggleModalImage = () => {
    setShowModalImage(!showModalImage);
  }

  let ModalImage = () => (
    <Modal isOpen={showModalImage} toggle={toggleModalImage} className="modal-preview-image-broadcast-detail">
      <ModalBody>
        <FiX onClick={toggleModalImage} />
        <img src={props.imageSrc} alt="" />
      </ModalBody>
    </Modal>
  );
  
  return (
    <React.Fragment>
      <ModalImage />

      <div
        className={"img-overlay" + (props.isLoading ? " loading-img-overlay" : "")}
        onClick={(e) => {
          let descendant = isDescendant(
            document.getElementsByClassName("x-button")[0],
            e.target
          );
          if (
            !e.target.classList.contains("x-button") &&
            !descendant &&
            !props.isLoading
          ) {
            props.onClick();
          }
        }}
        onMouseOver={(e) => {
          let descendant = isDescendant(
            document.getElementsByClassName("x-button")[0],
            e.target
          );
          if (
            !e.target.classList.contains("x-button") &&
            !descendant &&
            !props.isLoading
          ) {
            // document.getElementsByClassName("eye-icon")[0].style.display = "block";
            if(!withoutRemove) document.getElementsByClassName("x-button")[0].style.display = "none";
          }
        }}
        onMouseOut={() => {
          if (!props.isLoading) {
            // document.getElementsByClassName("eye-icon")[0].style.display = "none";
            if(!withoutRemove) document.getElementsByClassName("x-button")[0].style.display = "block";
          }
        }}
      >
        {props.isLoading ? (
          ""
        ) : (
          !withoutRemove && <div className="x-button" onClick={props.removePhoto}>
            <FiX />
          </div>
        )}
        {props.isLoading ? (
          <Loading color="white" />
        ) : (
          <div className="eye-icon" onClick={toggleModalImage}>
            <FiEye />
          </div>
        )}
        <img src={props.imageSrc} alt="" />
      </div>
    </React.Fragment>
  );
};

export default ImageUpload;
