import React, { useState } from 'react';
import './CalendarPicker.scss';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import 'react-day-picker/lib/style.css';
import { FiCalendar, FiX } from 'react-icons/fi';

const CalendarPicker = (props) => {
    let { from, to } = props.filterDate;
    let [calendarOne, setCalendarOne] = useState(new Date(new Date().getFullYear(), new Date().getMonth() - 1));
    let [calendarTwo, setCalendarTwo] = useState(new Date(new Date().getFullYear(), new Date().getMonth()));

    let modifiers = { start: from, end: to };

    let clearFilterDateValue = () => {
        if(!props.isFilterDateActive) {
            props.handleDateFilterClick({
                from: undefined,
                to: undefined,
            });
        }

        if(props.cancelDatePicker) {
            props.cancelDatePicker()
        }
    }

    let _handleDateFilterClick = (day, {disabled}) => {
        if(!disabled) {
            let range = DateUtils.addDayToRange(day, props.filterDate);
            
            props.handleDateFilterClick(range);
        }
    }

    let onClickClear = () => {
        props.clearDatePicker();
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="case-modal-calendar">
            <ModalBody>
                <p className="modal-date-filter-title"><b><FiCalendar />Date Range</b></p>

                <div id="date-filter-wrapper" className="custom-date-picker">
                    {/* <DayPicker numberOfMonths={2}
                            //    selected={from}
                                selectedDays={[from, { from, to }]}
                                modifiers={modifiers}
                                onDayClick={_handleDateFilterClick}
                                className="Selectable"
                    /> */}

                    <DayPicker
                        numberOfMonths={1}
                        //    selected={from}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={_handleDateFilterClick}
                        className={
                            "Selectable" +
                            (String(props.filterDate.from) === String(props.filterDate.to)
                            ? " today-selected"
                            : "")
                        }
                        // toMonth={new Date(2018, 11)}
                        month={calendarOne}
                        onMonthChange={(date) => setCalendarOne(date)}
                        toMonth={new Date(calendarTwo.getFullYear(), calendarTwo.getMonth() -1)}
                        disabledDays={props.disabledDays}
                    />

                    <DayPicker
                        numberOfMonths={1}
                        //    selected={from}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={_handleDateFilterClick}
                        className={
                            "Selectable" +
                            (String(props.filterDate.from) === String(props.filterDate.to)
                            ? " today-selected"
                            : "")
                        }
                        month={calendarTwo}
                        onMonthChange={(date) => setCalendarTwo(date)}
                        fromMonth={new Date(calendarOne.getFullYear(), calendarOne.getMonth())}
                        disabledDays={props.disabledDays}
                    />
                </div>
            </ModalBody>
            
            <ModalFooter>
                {props.filterDate.from &&
                    <button className="button-clear" onClick={onClickClear}>
                        <FiX />
                        <b>Clear Selection</b>
                    </button>
                }

                <button 
                    className="no-fill-button" 
                    onClick={() => {
                        clearFilterDateValue();
                        props.toggle();
                    }}
                >
                    Cancel
                </button>

                {(!props.filterDate.from || !props.filterDate.to) ?
                    <button className="orange-button" disabled>
                        Apply Dates
                    </button>
                    :
                    <button className="orange-button" onClick={() => props.submitDateFilter()}>
                        Apply Dates
                    </button>
                }
            </ModalFooter>
        </Modal>
    );
};

export default CalendarPicker;
