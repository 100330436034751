import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationFacebookServices = {
  getList(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LIST, {}, headers, callback);
  },

  getFB(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_FB, data, headers, callback);
  },

  getDetail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);
  },

  create(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.CREATE, data, headers, callback);
  },

  recon(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.RECON, data, headers, callback);
  },

  // edit(idOrTime, id, topicID, accountName, callback) {
  //   let headers = {
  //     'API-Key': API_KEY(),
  //     'Device-Platform': 'web',
  //     'Organization-ID': idOrTime,
  //   };

  //   let data = {
  //     id: parseInt(id),
  //     accountName: accountName,
  //     topicID: parseInt(topicID),
  //   };

  //   BaseService.doPostAccessToken(API.EDIT, data, headers, callback);
  // },
  getChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHANNEL_LINK, data, headers, callback);
  },
  setChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHANNEL_LINK, data, headers, callback);
  },
  deleteChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.DELETE_CHANNEL_LINK, data, headers, callback);
  },
  editTopic(idOrTime, id, topicID, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id),
      topicIDs: topicID,
    };

    BaseService.doPostAccessToken(API.EDIT_TOPIC, data, headers, callback);
  },
  getChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHAT_BOT, data, headers, callback);
  },
  setChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHAT_BOT, data, headers, callback);
  },
  removeChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REMOVE_CHAT_BOT, data, headers, callback);
  },
  getWelcomeMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_WELCOME, data, headers, callback);
  },
  getAwayMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_AWAY, data, headers, callback);
  },
  getClosingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_CLOSING, data, headers, callback);
  },
  getRatingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_USER_RATING, data, headers, callback);
  },
  setWelcomeMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);
  },
  setAwayMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);
  },
  setClosingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);
  },
  setRatingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_USER_RATING, data, headers, callback);
  },
};

const API = {
  GET_LIST: '/organization/channel/facebook/get_list',
  GET_DETAIL: '/organization/channel/facebook/get_details',
  CREATE: '/organization/channel/facebook/create',
  // EDIT: '/organization/channel/whatsapp/edit',
  GET_FB: "/organization/channel/get_facebook_app",
  RECON: "/organization/channel/facebook/reconnect",
  GET_CHANNEL_LINK: '/organization/channel/facebook/get_channel_link',
  SET_CHANNEL_LINK: '/organization/channel/facebook/set_channel_link',
  DELETE_CHANNEL_LINK: '/organization/channel/facebook/delete_channel_link',
  EDIT_TOPIC: '/organization/channel/facebook/edit_topic',
  GET_CHAT_BOT: '/organization/channel/facebook/get_chatbot',
  SET_CHAT_BOT: '/organization/channel/facebook/set_chatbot',
  REMOVE_CHAT_BOT: '/organization/channel/facebook/remove_chatbot',
  GET_WELCOME: "/organization/channel/facebook/get_welcome_message",
  SET_WELCOME: "/organization/channel/facebook/set_welcome_message",
  GET_AWAY: "/organization/channel/facebook/get_away_message",
  SET_AWAY: "/organization/channel/facebook/set_away_message",
  GET_CLOSING: "/organization/channel/facebook/get_closing_message",
  SET_CLOSING: "/organization/channel/facebook/set_closing_message",
  GET_USER_RATING: "/organization/channel/facebook/get_user_rating",
  SET_USER_RATING: "/organization/channel/facebook/set_user_rating"
};

export default IntegrationFacebookServices;
