import React, { useState, useRef, useEffect } from 'react';
import './ChatRoomInputMessageEmail.scss';
import { FiPaperclip, FiX, FiSmile, FiFile, FiVideo, FiImage } from 'react-icons/fi';
import { tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { connect } from 'react-redux';
import iconCheckBlack from "../../../../assets/img/icon-check-black.svg";
import Airplane from '../../../../assets/img/icon-airplane.svg';
import CaseService from "../../../../services/chat/caseServices";
import { doToast } from "../../../../helper/HelperGeneral";
import Helper from '../../../../helper/HelperChat';
import HelperQuickReply from "../../../../helper/HelperQuickReply";
import { setPreviewImageOrVideo } from "../../../../redux/actions/reduxActionPreviewImageOrVideo";
import { setToggleMineCase } from "../../../../redux/actions/reduxActionToggleMineCase";
import { setActiveCasePanel } from '../../../../redux/actions/reduxActionActiveCasePanel';
import { clearReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { setQuickReplyDocument } from '../../../../redux/actions/reduxActionQuickReplyDocument';
import { setEmailAttachments } from "../../../../redux/actions/reduxActionEmailAttachments";
import { Picker } from 'emoji-mart';
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import ButtonWithLoadingOrIcon from '../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import ChatRoomInputEmailAttachment from "./chatRoomInputEmailAttachment/ChatRoomInputEmailAttachment";
import { TAPLIVE_MEDIUM } from "../../../../constants/taplive";
import mixpanel from "mixpanel-browser";
import 'emoji-mart/css/emoji-mart.css';
import ChatRoomReplyMessage from "./chatRoomReplyMessage/ChatRoomReplyMessage";
import ChatRoomReplyInputEmail from './chatRoomReplyInputEmail/ChatRoomReplyInputEmail';

var ChatRoomInputMessage = (props) => {
  let [inputFileShow, setInputFileShow] = useState(false);
  let [isLoadingCloseCase, setisLoadingCloseCase] = useState(false);
  let chatRoomMesageInputRef = useRef("messageInput");
  let [isActiveButtonSend, setIsActiveButtonSend] = useState(false);
  let [isTyping, setIsTyping] = useState(false);
  let [typingTimeoutID, setTypingTimeoutID] = useState(0);

  let [arrayOfFileUploadValue, setArrayOfFileUploadValue] = useState([]);

  // let [arrayOfMediaUploadValue, setArrayOfMediaUploadValue] = useState([]);
  // let [currentActiveSelectedFile, setCurrentActiveSelectedFile] = useState(null);

  let [shorcutListToBeShow, setShortcutListToBeShow] = useState([]);
  let [currentActiveSelectedShortcut, setCurrentActiveSelectedShortcut] = useState(false);
  // let [mediaFile, setMediaFile] = useState('');
  let [showInputEmailAttach, setShowInputEmailAttach] = useState(false);

  let toggleModalInputEmail = () => {
    setShowInputEmailAttach(!showInputEmailAttach);
  }

  let toggleModalSendAsEmail = () => {
    if (!props.loadingSendEmail) {
      props.setShowModalSendAsEmail(!props.showModalSendAsEmail);
    }
  }

  useEffect(() => {
    if (currentActiveSelectedShortcut) {
      // let maxOffsetTop = 257;
      let _shorcutListToBeShow = shorcutListToBeShow.slice();
      let shorcutListToBeShowLastIndex = shorcutListToBeShow.length - 1;
      let _currentActiveSelectedShortcut = { ...currentActiveSelectedShortcut };
      let currentIndex = _currentActiveSelectedShortcut.index;

      let onKeydownShortcut = (e) => {
        let el = document.querySelectorAll(".quick-reply-inner-wrapper")[0];

        if (e.keyCode === 40) {
          // arrow down
          if (currentIndex === shorcutListToBeShowLastIndex) {
            _currentActiveSelectedShortcut.index = 0;
            _currentActiveSelectedShortcut.shortcut = _shorcutListToBeShow[0];
            el.scrollTop = 0;
          } else {
            _currentActiveSelectedShortcut.index = currentIndex + 1;
            _currentActiveSelectedShortcut.shortcut = _shorcutListToBeShow[currentIndex + 1];

            if ((currentIndex + 1) > 5) {
              el.scrollTop = el.scrollTop + 37;
            }
          }

          setCurrentActiveSelectedShortcut(_currentActiveSelectedShortcut);
        }

        if (e.keyCode === 38) {
          // arrow up
          if (currentIndex === 0) {
            _currentActiveSelectedShortcut.index = shorcutListToBeShowLastIndex;
            _currentActiveSelectedShortcut.shortcut = _shorcutListToBeShow[shorcutListToBeShowLastIndex];
            el.scrollTop = el.scrollHeight;
          } else {
            _currentActiveSelectedShortcut.index = currentIndex - 1;
            _currentActiveSelectedShortcut.shortcut = _shorcutListToBeShow[currentIndex - 1];

            // if((currentIndex % 2) === 0 ) {
            el.scrollTop = el.scrollTop - 37;
            // }
          }

          setCurrentActiveSelectedShortcut(_currentActiveSelectedShortcut);
        }
      }

      window.addEventListener('keydown', onKeydownShortcut);

      return () => {
        window.removeEventListener('keydown', onKeydownShortcut);
      }
    }
  }, [currentActiveSelectedShortcut])

  useEffect(() => {
    if(props.emailAttachments.length > 0) {
      setIsActiveButtonSend(true);
    }
  }, [JSON.stringify(props.emailAttachments)])

  useEffect(() => {
    props.setEmailAttachments([]);
  }, [])

  useEffect(() => {
    setIsActiveButtonSend(false);
    setShortcutListToBeShow([]);
    setCurrentActiveSelectedShortcut(false);

    if (props.activeRoom !== null) {
      let selectorInputText = document.querySelectorAll(".main-textarea-input")[0];
      selectorInputText.focus();
      props.setEmailAttachments([]);
    }
  }, [props.activeRoom])

  useEffect(() => {
    if (arrayOfFileUploadValue.length > 0) {
      props.runningFileMessageProps(arrayOfFileUploadValue);
      setArrayOfFileUploadValue([]);
    }
  }, [arrayOfFileUploadValue]);

  useEffect(() => {
    bodyClickListener();
    // listenerDocumentInputChange();
    // listenerMediaInputChange();
    let elTextInput = document.getElementsByClassName("main-textarea-input")[0];

    if (props.replyMessage.message) {
      elTextInput.focus();
    }

    // input growing
    let elChatRoomSolve = document.getElementsByClassName("onetalk-main-chat-room-solve-wrapper")[0];
    let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
    let maxHeightMarkAsSolvedInactive = 102; // 158
    let maxHeightMarkAsSolvedActive = 142; // 198

    let setChatRoomHeight = (height) => {
      elChatRoomMain.style.setProperty("max-height", "calc(100vh - " + height + "px)", "important");
    };

    let onInputMessageListener = (e) => {
      let defaultTextAreaHeight = 42;
      let maxTextareaHeight = 138; // 5 rows
      let replyMessagaHeight = 68;

      if (e.target.value === "") {
        elTextInput.removeAttribute("style");
        if (elChatRoomSolve.offsetHeight === 40) {
          elChatRoomMain.style.setProperty("max-height", "calc(100vh - " + maxHeightMarkAsSolvedActive + "px)", "important");
        } else {
          if (props.replyMessage.message) {
            elChatRoomMain.style.setProperty("max-height", "calc(100vh - 169px)", "important");
          } else {
            elChatRoomMain.removeAttribute("style");
          }
        }
      }

      let textAreaHeightBefore = e.target.style.height === "" ? 42 : parseInt(e.target.style.height.replace("px", ""));

      elTextInput.style.height = "";
      elTextInput.style.height = elTextInput.scrollHeight + "px";

      let textareaCurrentHeight = parseInt(e.target.style.height.replace("px", ""));
      let textareaActualCurrentHeight = elTextInput.offsetHeight;

      if (textAreaHeightBefore !== textareaCurrentHeight) {
        if (textareaActualCurrentHeight <= maxTextareaHeight) {
          if (textAreaHeightBefore < textareaCurrentHeight) {
            let chatRoomContainerMaxHeightVal = (elChatRoomSolve.offsetHeight !== 40 ? maxHeightMarkAsSolvedInactive : maxHeightMarkAsSolvedActive) + (textareaActualCurrentHeight - defaultTextAreaHeight);
            setChatRoomHeight(props.replyMessage.message ? chatRoomContainerMaxHeightVal + replyMessagaHeight : chatRoomContainerMaxHeightVal);
          }

          if (textAreaHeightBefore > textareaCurrentHeight) {
            let chatRoomContainerMaxHeightVal = (elChatRoomSolve.offsetHeight !== 40 ? maxHeightMarkAsSolvedInactive : maxHeightMarkAsSolvedActive) - (defaultTextAreaHeight - textareaActualCurrentHeight);
            setChatRoomHeight(props.replyMessage.message ? chatRoomContainerMaxHeightVal + replyMessagaHeight : chatRoomContainerMaxHeightVal);
          }
        }
      }
    }

    elTextInput.addEventListener("input", onInputMessageListener);

    //input growing

    return () => {
      elTextInput.removeEventListener("input", onInputMessageListener)
    }
  }, [props.replyMessage]);

  let actionCaseClose = () => {
    setisLoadingCloseCase(true);
    let caseID = props.activeRoom.xcRoomID.split(':')[1];
    let data = {
      id: parseInt(caseID),
    }
    CaseService.postCloseCase(props.parentProps.match.params.orgID, data, (response) => {
      setisLoadingCloseCase(false);
      if (response.dataResult.error.code === "") {
        Helper.resetChatRoomHeightAndInputText();
        props.toggleMarkAsSolvedPanelProps(false);
        doToast(response.dataResult.data.message);
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  }

  let bodyClickListener = () => {
    let target = document.querySelector('body');

    target.addEventListener('click', function () {
      setInputFileShow(false)
    })
  }

  let startTyping = () => {
    if (isTyping) {
      return;
    }

    setIsTyping(true);

    tapCoreChatRoomManager.sendStartTypingEmit(props.activeRoom.roomID);
  }

  let stopTyping = () => {
    if (!isTyping) {
      return;
    }

    clearTimeout(typingTimeoutID);

    setIsTyping(false);
    setTypingTimeoutID(0);

    tapCoreChatRoomManager.sendStopTypingEmit(props.activeRoom.roomID);
  }

  let onChangeInputMessage = (e) => {
    if (e.target.value.length > 0) {
      setIsActiveButtonSend(true);
    } else {
      if(props.emailAttachments.length === 0) {
        setIsActiveButtonSend(false);
      }
    }
  }

  let keyPressInputMessage = async (e) => {
    if (e.which === 13 && !e.shiftKey) {
      if(isActiveButtonSend) {
        actionSubmitMessage(e, true);
      }
    }

    if (e.target.value.length > 0) {
      setIsActiveButtonSend(true);
    } else {
      if(props.emailAttachments.length === 0) {
        setIsActiveButtonSend(false);
      }
    }
  }

  let actionSubmitMessage = async (e = false, showModalConfirm = false) => {
    if (showModalConfirm) {
      toggleModalSendAsEmail();

      if(e) {
        e.preventDefault();
      }
    } else {
      setIsActiveButtonSend(false);

      if (props.quickReplyDocument) {
        props.runQuickReplyFile(props.quickReplyDocument);
      } else {
        setShortcutListToBeShow([]);
        if (currentActiveSelectedShortcut.shortcut) {
          clickShortCut(currentActiveSelectedShortcut.shortcut);
        } else {
          setCurrentActiveSelectedShortcut(false);

          let isAllow = true;

          if(props.emailAttachments.length > 0) {
            props.emailAttachments.map((v) => {
              if(!v.url) {
                isAllow = false;
              }
      
              return null;
            })
          }
      
          if(isAllow) {
            props.onInputNewMessageProps({ text: chatRoomMesageInputRef.current.value }, props.caseDetailData && props.caseDetailData.case.medium === TAPLIVE_MEDIUM.gb_profile);
            // chatRoomMesageInputRef.current.value = "";
  
            if (props.caseDetailData && props.caseDetailData.case.medium !== TAPLIVE_MEDIUM.gb_profile) {
              props.clearReplyMessage();
              Helper.resetChatRoomHeightAndInputText();
            }
          }else {
            doToast("Media is still uploading, try again in a while", "fail")
          }
        }
      }
    }
  }

  let clickShortCut = (shortcutMessage) => {
    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Action] Use Quick Reply",
        {
          userFullName: props.myAgentData.account.fullName,
          userID: props.myAgentData.account.id,
          organizationID: props.parentProps.match.params.orgID.split("-")[0]
        }
      );
    }

    setCurrentActiveSelectedShortcut(false);
    if (shortcutMessage.isWhatsAppBAInteractive) {
      let elTextInput = document.getElementsByClassName("main-textarea-input")[0];
      elTextInput.value = "";
      setShortcutListToBeShow([]);
    } else {
      if (shortcutMessage.messageType === "file") {
        let elTextInput = document.getElementsByClassName("main-textarea-input")[0];
        elTextInput.value = shortcutMessage.message;
        setShortcutListToBeShow([]);
        elTextInput.focus();
        elTextInput.dispatchEvent(new Event('input', { bubbles: true }));
        props.setQuickReplyDocument(shortcutMessage);
      } else if (shortcutMessage.messageType === "image" || shortcutMessage.messageType === "video") {
        // let _data = {
        //   caption: shortcutMessage.message,
        //   fileSrc: shortcutMessage.mediaURL,
        //   size: 0,
        //   type: shortcutMessage.messageType,
        //   name: shortcutMessage.mediaFilename,
        //   isShortcut: true
        // };

        // setArrayOfMediaUploadValue([_data]);

        // setCurrentActiveSelectedFile({
        //   file: _data,
        //   index: 0
        // });

        setShortcutListToBeShow([]);
      } else {
        let elTextInput = document.getElementsByClassName("main-textarea-input")[0];
        elTextInput.value = shortcutMessage.message;
        setShortcutListToBeShow([]);
        elTextInput.focus();
        elTextInput.dispatchEvent(new Event('input', { bubbles: true }));
      }
    }
  }

  let onSelectedShortcut = (shortcut, index) => {
    setCurrentActiveSelectedShortcut({
      shortcut: shortcut,
      index: index
    });
  }

  let printQuickReply = () => {
    return (
      <div className="quick-reply-outer-wrapper">
        <div className="quick-reply-inner-wrapper">
          <div className="quick-reply-title-wrapper">
            <b>Quick Replies</b>
          </div>

          {shorcutListToBeShow.map((value, index) => {
            return (
              <div className={`
                    quick-reply-list-option-wrapper

                    ${value.messageType !== "text" ?
                  "quick-reply-list-option-wrapper-with-media"
                  :
                  ""
                }

                    ${!currentActiveSelectedShortcut ?
                  ""
                  :
                  currentActiveSelectedShortcut.index === index ?
                    "active-quick-reply-list-option-wrapper"
                    :
                    ""
                }
                  `}
                title={value.message}
                key={`shortcut-${index}`}
                onClick={() => clickShortCut(value)}
                onMouseEnter={() => onSelectedShortcut(value, index)}
              >
                <span>{HelperQuickReply.prefixShortcut}{value.shortcut}{value.ownerType === HelperQuickReply.ownerTypePersonal ? HelperQuickReply.suffixShortcut : ""} </span>
                {value.message}

                {value.messageType !== "text" &&
                  <div className='quick-reply-media-wrapper'>
                    {value.messageType === "image" &&
                      <>
                        <img src={value.mediaURL} alt="" />

                        <div className='quick-reply-video-wrapper'>
                          <FiImage />
                        </div>
                      </>
                    }

                    {value.messageType === "video" &&
                      <>
                        <video src={value.mediaURL} />

                        <div className='quick-reply-video-wrapper'>
                          <FiVideo />
                        </div>
                      </>
                    }

                    {value.messageType === "file" &&
                      <>
                        <img src={value.mediaURL} alt="" />

                        <div className='quick-reply-video-wrapper quick-reply-video-wrapper-orange'>
                          <FiFile />
                        </div>
                      </>
                    }
                  </div>
                }
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  let filterQuickReply = (shortcut) => {
    // let _quickReplyList = props.quickReplyList.slice();

    // if (props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba) {
    //   setShortcutListToBeShow(_quickReplyList.filter(value => value.shortcut.startsWith(shortcut)));
    // } else {
    //   setShortcutListToBeShow(_quickReplyList.filter(value => value.shortcut.startsWith(shortcut) && !value.isWhatsAppBAInteractive));
    // }

    // setCurrentActiveSelectedShortcut({
    //   shortcut: _quickReplyList.filter(value =>
    //     props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba ?
    //       value.shortcut.startsWith(shortcut)
    //       :
    //       value.shortcut.startsWith(shortcut) && !value.isWhatsAppBAInteractive
    //   )[0],
    //   index: 0
    // })
  }

  let checkShortcutString = (e) => {
    let targetVal = e.target.value;
    if (targetVal[0] === "/") {
      filterQuickReply(targetVal.replace("/", ""));
    } else {
      setShortcutListToBeShow([]);
    }
  }

  let onSelectEmoji = (e) => {
    let target = document.querySelectorAll('.main-textarea-input')[0];
    target.value = target.value + e.native;
    setIsActiveButtonSend(true);
  }

  let runOnChange = async (e) => {
    onChangeInputMessage(e);
  }

  let runOnKeyUp = async (e) => {
    //remove shortcut val
    if (e.target.value === "") {
      setCurrentActiveSelectedShortcut(false);
    }
    //remove shortcut val

    if (e.keyCode !== 40 && e.keyCode !== 38) {
      checkShortcutString(e)
    }

    keyPressInputMessage(e)

    if (isTyping) {
      if (typingTimeoutID) {
        clearTimeout(typingTimeoutID);
      }

      setTypingTimeoutID(setTimeout(() => {
        stopTyping();
      }, 7000));
    }
  }

  let runOnKeyDown = async (e) => {
    if ((e.keyCode === 38 || e.keyCode === 40) && shorcutListToBeShow.length > 0) {
      e.preventDefault();
    }

    if (e.which === 13) {
      if (!e.shiftKey) {
        e.preventDefault();
      }
    }

    startTyping()
  }

  return (
    <>
      <PopupConfirmation
        isOpen={props.showModalSendAsEmail}
        toggle={toggleModalSendAsEmail}
        title="Send as Email?"
        description="The message you are sending will be sent as an email, please make sure you don’t send multiple messages at once so you won’t be detected as spam."
        className="modal-confirmation-send-as-email"
      >
        {!props.loadingSendEmail ?
          <ButtonWithLoadingOrIcon
            text="Send As Email"
            className="main-button-48 orange-button"
            onClickAction={() => {
              actionSubmitMessage(false, false);
            }}
            icon={{
              type: "img",
              src: Airplane
            }}
            position="left"
          />
          :
          <ButtonWithLoadingOrIcon
            text="Send As Email"
            className="main-button-48 orange-button"
            isLoading
            isDisabled
            loadingColor="gray"
            position="left"
          />
        }
      </PopupConfirmation>

      <ChatRoomInputEmailAttachment showModal={showInputEmailAttach} onCloseClick={toggleModalInputEmail} />

      <div className={`chat-room-input-message-wrapper`}>
        {props.quickReplyDocument &&
          <div className='quick-reply-document-wrapper'>
            <FiX className='clear-quick-reply-document' onClick={() => props.setQuickReplyDocument(false)} />

            <div className='quick-reply-document-icon'>
              <FiFile />
            </div>

            <p>
              <b>{props.quickReplyDocument.mediaFilename}</b>
            </p>
          </div>
        }

        {shorcutListToBeShow.length > 0 && printQuickReply()}

        {props.replyMessage.message && <ChatRoomReplyMessage />}

        {props.emailAttachments.length > 0 &&
          <ChatRoomReplyInputEmail 
             setIsActiveButtonSend={setIsActiveButtonSend}
            _setShowInputEmailAttach={setShowInputEmailAttach}
          />
        }

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if(isActiveButtonSend) {
              actionSubmitMessage(e, true)
            }
          }}
          className={``}
        >
          <div className="chat-room-textarea-wrapper">
            <textarea
              rows={1}
              className={`main-textarea-input`}
              placeholder="Send Message..."
              onBlur={() => stopTyping()}
              ref={chatRoomMesageInputRef}
              onChange={(e) => {
                runOnChange(e);
              }}
              onKeyUp={(e) => {
                runOnKeyUp(e)
              }}
              onKeyDown={(e) => {
                runOnKeyDown(e)
              }}
            />

            <div
              className={`
                      emoji-picker-outer-wrapper 
                      ${!props.caseDetailData ? "emoji-picker-outer-wrapper-16" : ""}
                      ${props.caseDetailData && (props.caseDetailData.case.medium === TAPLIVE_MEDIUM.gb_profile || props.caseDetailData.case.medium === TAPLIVE_MEDIUM.tokopedia) ? "emoji-picker-outer-wrapper-16" : ""}
                    `}
            >
              <FiSmile
                className="emoji-toggle-button"
              />

              <div className="emoji-picker-main-wrapper">
                <Picker
                  onSelect={(e) => onSelectEmoji(e)}
                  showPreview={false}
                />
              </div>
            </div>

            <FiPaperclip
              onClick={() => setShowInputEmailAttach(true)}
              className={inputFileShow ? 'active-paperclip' : ''}
            />
          </div>

          {(isActiveButtonSend || props.quickReplyDocument || props.emailAttachments.length > 0) ?
            <button className="chat-send-wrapper">
              <img src={Airplane} alt="" />
            </button>
            :
            <button className="chat-send-wrapper" disabled>
              <img src={Airplane} alt="" />
            </button>
          }
        </form>

        <div className={`onetalk-main-chat-room-solve-wrapper ${!props.markAsSolvedPanelShowProps ? 'onetalk-main-chat-room-solve-wrapper-hide' : ''}`}
          id="taplive-chat-room-mark-as-solved-button"
          onClick={() => actionCaseClose()}
        >
          {!isLoadingCloseCase ?
            <React.Fragment>
              <img src={iconCheckBlack} alt="" />
              <b>Mark as resolved</b>
            </React.Fragment>
            :
            <div className="lds-ring">
              <div /><div /><div /><div />
            </div>
          }
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  quickReplyList: state.quickReplyList,
  toggleMineCase: state.toggleMineCase,
  replyMessage: state.replyMessage,
  caseDetailData: state.caseDetailData,
  draftMessage: state.draftMessage,
  quickReplyDocument: state.quickReplyDocument,
  emailAttachments: state.emailAttachments,
  myAgentData: state.myAgentData
});

const mapDispatchToProps = {
  setPreviewImageOrVideo,
  setToggleMineCase,
  setActiveCasePanel,
  clearReplyMessage,
  setQuickReplyDocument,
  setEmailAttachments
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomInputMessage);
