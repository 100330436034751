import React, { useEffect, useState } from "react";
// import "../../auth/Auth.css";
import "./PayNow.scss";
import { FiChevronDown } from "react-icons/fi";
import { checkID } from "../../../../../helper/HelperGeneral";
// import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
import Blocking401 from "../../../../reuseableComponent/blocking401/Blocking401";
import SectionWrap from "../../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import iconVisa from "../../../../../assets/img/payment-card/visa.png";
import iconJsb from "../../../../../assets/img/payment-card/jcb.png";
import iconMastercard from "../../../../../assets/img/payment-card/mastercard.png";
import iconAmex from "../../../../../assets/img/payment-card/amex.png";
import iconBca from "../../../../../assets/img/payment-bank/icon-bca.png";
import iconMandiri from "../../../../../assets/img/payment-bank/icon-mandiri.png";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import mixpanel from "mixpanel-browser";

const CARD_BRAND = {
  visa: {
      label: "Visa",
      img: iconVisa
  },
  mastercard: {
      label: "Mastercard",
      img: iconMastercard
  },
  jcb: {
      label: "JCB",
      img: iconJsb
  },
  american_express: {
      label: "American Express",
      img: iconAmex
  },
}

const PayNow = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [isShowDropdownSelectCard, setIsShowDropdownSelectCard] = useState(false);

  let toggleDropdownSelectCard =() => {
    setIsShowDropdownSelectCard(!isShowDropdownSelectCard);
  }

  const back = () => history.push(`/o/${orgID}/organization/billing`);

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(orgID) {
      // getTopic();
    }
  }, [orgID])

  // let getTopic = () => {
    // TopicService.getTopic(orgID, "list", null, (response) => {
    //   if(response.dataResult.error.message ==="") {
    //     setTopicList(response.dataResult.data.topics)
    //   }
    // });
  // }

  // const onSubmitInviteMember = (e) => {
    // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    // e.preventDefault();
    // setIsWaitResponseInviteMember(true);

    // let dataMemberID = {
    //   email: memberEmail,
    //   roleCode: memberRole
    // };

    // MemberService.addMember(orgID, dataMemberID, (response) => {
    //   if (response.dataResult.status === 200) {
    //     if(response.dataResult.data.success) {
          
    //     }else {
    //       doToast(response.dataResult.data.message, 'fail');
    //     }
  
    //     setIsWaitResponseInviteMember(false);
    //   }else {
    //     doToast(response.dataResult.error.message, 'fail');
    //     setIsWaitResponseInviteMember(false);
    //   }
    // });
  // }

  const isButtonSubmitDisabled = () => {
    // return (
    //   (memberEmail.length === 0 || memberRole.length === 0 || !HelperInput.emailFormatValid(memberEmail)) ? true : false
    // )
  }

  let convertCardMasking = (cardNumber) => {
    return cardNumber.replace(/X/g, "<div class='masking-dot'></div>");
  }

  return (
    <>
      {process.env.REACT_APP_HIDE_BILLING === "true" ?
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >
            <Blocking401 {...props} />
        </SectionWrap>
        :
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >   
            <BackButton text="Back to Subscriptions" onClick={back} />
            
            <div className="sectionWrap pay-now-wrapper">
                <div className="form-title-wrapper">
                    <b>Pay Now</b>
                </div>
                
                <div className="form-content-wrapper">
                    <form onSubmit={(e) => {
                      // onSubmitInviteMember(e);
                    }}>
                        <label><b>Credit or Debit Card</b></label>
                        <div className="auto-payment-card-select-wrapper selected-card">
                            {/* {cardList.length === 0 ? */}
                                {/* <NavLink 
                                    to={`/o/${props.match.params.orgID}/organization/billing/add-card`}
                                    onClick={() => {
                                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                        
                                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                                            mixpanel.track(
                                                "[Button] Add Card",
                                                {
                                                    userFullName : myAgentData.account.fullName,
                                                    userID : myAgentData.account.id,
                                                    organizationID : props.match.params.orgID.split("-")[0]
                                                }
                                            )
                                        }
                                    }}
                                >
                                    <p className="auto-payment-add-card">
                                        <FiPlus />
                                        <b>Add Card</b>
                                    </p>
                                </NavLink> */}
                                <div className="custom-radio">
                                  <input type="radio" 
                                      name="payment-option" 
                                      id="credit-card" 
                                      value="credit-card" 
                                      onChange={(e) => console.log(e)}
                                  />
                                  <label htmlFor="credit-card">
                                    <Dropdown isOpen={isShowDropdownSelectCard} toggle={toggleDropdownSelectCard}>
                                      {/* <DropdownToggle className="please-select-card">
                                          <b>Please select your card</b>
                                          <FiChevronDown className={isShowDropdownSelectCard ? "active-dropdown-card" : ""} />
                                      </DropdownToggle> */}
                                      
                                      <DropdownToggle>
                                          <img src={CARD_BRAND["visa"].img} alt="" className="card-icon" />
                                          <p className="card-number">
                                              <b dangerouslySetInnerHTML={{__html: convertCardMasking("40xxx09")}} />
                                          </p>

                                          <p className="card-expiry-date">
                                              <b>Expires 11/20</b>
                                          </p>
                                          <FiChevronDown className={isShowDropdownSelectCard ? "active-dropdown-card" : ""} />
                                      </DropdownToggle>
                                      
                                      <DropdownMenu>
                                          <DropdownItem  onClick={() => {
                                              // setActiveCard(value);
                                          }}>
                                              <div className="card-list-content">
                                                  <img src={CARD_BRAND["visa"].img} alt="" className="card-icon" />
                                                  <p className="card-number">
                                                      <b dangerouslySetInnerHTML={{__html: convertCardMasking("40xxx09")}} />
                                                  </p>

                                                  <p className="card-expiry-date">
                                                      <b>Expires 11/20</b>
                                                  </p>
                                              </div>
                                          </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>  
                                  </label>
                                </div>
                            {/* } */}
                        </div>

                        <label><b>Bank Transfer</b></label>
                        <div className="custom-radio">
                          <div className="option-box-wrapper">
                            <input type="radio" 
                                name="payment-option" 
                                id="bank-transfer-bca" 
                                value="finance" 
                                onChange={(e) => console.log(e)}
                            />
                            <label htmlFor="bank-transfer-bca">
                              <b>BCA</b>
                              <img src={iconBca} alt="" />
                            </label>
                          </div>
                          
                          <div className="option-box-wrapper">
                            <input type="radio" 
                                name="payment-option" 
                                id="bank-transfer-mandiri" 
                                value="finance" 
                                onChange={(e) => console.log(e)}
                            />
                            <label htmlFor="bank-transfer-mandiri">
                              <b>MANDIRI</b>
                              <img src={iconMandiri} alt="" />
                            </label>
                          </div>
                        </div>

                        <label><b>Virtual Account</b></label>
                        <div className="custom-radio">
                          <div className="option-box-wrapper">
                            <input type="radio" 
                                name="payment-option" 
                                id="bank-virtual-bca" 
                                value="finance" 
                                onChange={(e) => console.log(e)}
                            />
                            <label htmlFor="bank-virtual-bca">
                              <b>BCA</b>
                              <img src={iconBca} alt="" />
                            </label>
                          </div>
                          
                          <div className="option-box-wrapper">
                            <input type="radio" 
                                name="bank-virtual" 
                                id="bank-virtual-mandiri" 
                                value="finance" 
                                onChange={(e) => console.log(e)}
                            />
                            <label htmlFor="bank-virtual-mandiri">
                              <b>MANDIRI</b>
                              <img src={iconMandiri} alt="" />
                            </label>
                          </div>
                        </div>

                        
                        <button className="orange-button button-continue" disabled={isButtonSubmitDisabled()} onClick={() => {
                          history.push(`/o/${orgID}/organization/billing/pay-now/transfer`);
                        }}>
                            Continue
                        </button>
                    </form>
                    
                    {/* {isWaitResponseInviteMember &&
                      <button className="orange-button button-send-invitation">
                                <div className="lds-ring">
                                  <div /><div /><div /><div />
                                </div>
                      </button>
                    } */}
                </div>
            </div>
        </SectionWrap>
      }
    </>
  );
};

export default PayNow;