import React, { useEffect, useState, useRef } from 'react';
import './DailyOverviewReport.scss';
import { Tooltip } from "reactstrap";
import ReportService from '../../../services/newServices/ReportService';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { doToast, checkID } from '../../../helper/HelperGeneral';
import HelperDate from '../../../helper/HelperDate';
import NoReportFound from "../../../assets/img/no-report-found.svg";
import IconChatList from '../../../assets/img/chatroom/user-info-tab/chat-list.svg';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import CalendarPickerSingle from '../../reuseableComponent/calendarPickerSingle/CalendarPickerSingle';
import OverviewTopicReportNoData from '../overview/overviewTopicReportNoData/OverviewTopicReportNoData';
import mixpanel from "mixpanel-browser";
import CaseReport1 from "../../../assets/img/report/overview/case-report-1.svg";
import CaseReport2 from "../../../assets/img/report/overview/case-report-2.svg";
import CaseReport6 from "../../../assets/img/report/overview/case-report-6.svg";
import CaseReport8 from "../../../assets/img/report/overview/case-report-8.svg";
import CaseReport7 from "../../../assets/img/report/overview/case-report-7.svg";
import IconUsers from '../../../assets/img/fi-user-circle.svg';
import IconQuestion from '../../../assets/img/fi-question.svg';
import IconBot from '../../../assets/img/fi-bot.svg';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import { FiRefreshCw, FiHelpCircle, FiCalendar } from 'react-icons/fi';
import Chart from 'chart.js';

let DailyOverviewReport = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchOverview, setIsWaitingFetchOverview] = useState(false);
  let [printedOverviewData, setPrintedOverviewData] = useState([]);
  let [retriveDataError, setRetrieveDataError] = React.useState({
    code: false,
    message: false
  });
  let [lastUpdate, setLastUpdate] = useState(new Date().valueOf());
  let [showTooltipCase, setShowTooltipCase] = useState(false);
  let [showTooltipAuto, setShowTooltipAuto] = useState(false);
  let [isOpenModalCalendar, setOpenModalCalendar] = useState(false);
  let [filterDate, setFilterDate] = useState(new Date());
  let [filterDateForCalender, setFilterDateForCalender] = useState(new Date());
  let [autoRefresh, setAutoRefresh] = useState(false);
  let autoRefreshRef = useRef(null);

  let timerAutoRefresh = () => {
    autoRefreshRef.current = setInterval(() => {
      getOverview(new Date());
    }, 600000)
  }

  let toggleModalCalendar = () => {
    setOpenModalCalendar(!isOpenModalCalendar);
    setFilterDateForCalender(filterDate);
  }

  let handleDateFilterClick = (date) => {
    setFilterDateForCalender(date);
  }

  let handleDateFilterCancel = () => {
    setFilterDateForCalender(filterDate)
  }

  let toggleTooltipCase = () => {
    setShowTooltipCase(!showTooltipCase);
  }

  let toggleTooltipAuto = () => {
    setShowTooltipAuto(!showTooltipAuto);
  }

  useEffect(() => {
    if (orgID) {
      getOverview();

      let _autoRefreshOverview = localStorage.getItem("autoRefreshOverview");
      let _orgID = orgID.split("-")[0];

      if (_autoRefreshOverview !== null && _autoRefreshOverview !== "") {
        setAutoRefresh(JSON.parse(_autoRefreshOverview)[_orgID]);

        if (JSON.parse(_autoRefreshOverview)[_orgID]) {
          timerAutoRefresh();
        }
      }
    }
  }, [orgID])

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Daily Overview Report",
        {
          userFullName: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  let doMount = async () => {
    let paramID = checkID(props.match);

    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/members`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let getOverview = (date = false) => {
    if (date) {
      setFilterDate(date);
    }

    setIsWaitingFetchOverview(true);

    let _retriveDataError = { ...retriveDataError };
    let data = {
      date: HelperDate.formatToString(date ? date : filterDate, "yyyy-MM-dd")
    }

    ReportService.getOverviewDaily(props.match.params.orgID, data, (response) => {
      let dataResult = response.dataResult;

      if (dataResult.error.message === '') {
        setPrintedOverviewData(dataResult.data);
        setLastUpdate(new Date().valueOf());
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsWaitingFetchOverview(false);
    });
  };

  let printTime = (val) => {
    let time = "";
    let split = val.split(":");
    let h = split[0];
    let m = split[1];
    let s = split[2];

    if (m === "00") {
      time = s + "s";
    } else if (h === "00") {
      time = m + "m " + s + "s";
    } else {
      time = h + "h " + m + "m " + s + "s";
    }

    return time;
  }

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      } else {
        doToast(props.history.location.state.message, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history]);

  useEffect(() => {
    //chart plugin
    Chart.plugins.register({
      beforeRender: function(chart) {
        if (chart.config.options.showAllTooltips) {
          // create an array of tooltips, 
          // we can't use the chart tooltip because there is only one tooltip per chart
          chart.pluginTooltips = [];
          chart.config.data.datasets.forEach(function(dataset, i) {
            chart.getDatasetMeta(i).data.forEach(function(sector, j) {
              chart.pluginTooltips.push(new Chart.Tooltip({
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector]
              }, chart));
            });
          });      
          chart.options.tooltips.enabled = false; // turn off normal tooltips
        }
      },
      afterDraw: function(chart, easing) {
        if (chart.config.options.showAllTooltips) {
          if (!chart.allTooltipsOnce) {
            if (easing !== 1) {
              return;
            }
            chart.allTooltipsOnce = true;
          }
          chart.options.tooltips.enabled = true;
          Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
            tooltip.initialize();
            tooltip.update();
            tooltip.pivot();
            tooltip.transition(easing).draw();
          });
          chart.options.tooltips.enabled = false;
        }
      }
    });
    //chart plugin
    
    const xValues = [];
    const yValues = [];

    if (printedOverviewData.length !== 0) {
      if (printedOverviewData.casesPerHour) {
        printedOverviewData.casesPerHour.map((v) => {
          xValues.push(v.label);
          yValues.push(v.totalCases);

          return null;
        })

        setTimeout(() => {
          new Chart("myChartTotalCasePerHour", {
            type: "line",
            data: {
              labels: xValues,
              datasets: [{
                data: yValues,
                borderColor: "#4138BC",
                fill: "#4138BC"
              }]
            },
            options: {
              showAllTooltips: true,
              legend: { display: false },
              tooltips: {
                callbacks: {
                  beforeFooter: function () {
                    return ""
                  },
                  title: function () {
                    return ""
                  },
                  label: function (tooltipItems, data) {
                    return `${tooltipItems.yLabel}`;
                  },
                },
                filter: function (tooltipItem, data) {
                  // data contains the charts data, make sure you select the right 
                  // value. 
                  var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  if (value === 0) {
                      return false;
                  } else {
                      return true;
                  }
              },
                backgroundColor: '#6B6B6B',
                displayColors: false,
              },
            }
          })
        }, 0)
      }
    }
  }, [printedOverviewData])

  let submitDateFilter = () => {
    setFilterDate(filterDateForCalender);
    toggleModalCalendar();
    getOverview(filterDateForCalender);

    if (HelperDate.formatToString(new Date(), "yyyy-MM-dd") !== HelperDate.formatToString(new Date(filterDateForCalender), "yyyy-MM-dd")) {
      clearInterval(autoRefreshRef.current);
    } else {
      if (autoRefresh) {
        timerAutoRefresh();
      }
    }
  }

  let onClearDatePicker = () => {
    let _date = {
      from: undefined,
      to: undefined
    }

    setFilterDateForCalender(_date);
  }

  let isToday = () => {
    let yesToday = true;

    if (HelperDate.formatToString(new Date(), "yyyy-MM-dd") !== HelperDate.formatToString(new Date(filterDate), "yyyy-MM-dd")) {
      yesToday = false;
    }

    return yesToday;
  }

  let printDate = () => {
    let text = "";
    let today = HelperDate.formatToString(new Date(), "yyyy-MM-dd");

    if (HelperDate.formatToString(filterDate, "yyyy-MM-dd") === today) {
      text = `Today (${HelperDate.formatToString(filterDate, "d MMM yyyy")})`;
    } else {
      text = `${HelperDate.formatToString(filterDate, "d MMM yyyy")}`;
    }

    return text;
  }

  let onClickToggleAutoRefresh = (e) => {
    let _autoRefreshOverview = localStorage.getItem("autoRefreshOverview");
    let _orgID = orgID.split("-")[0];

    if (e.target.checked) {
      if (_autoRefreshOverview === null || _autoRefreshOverview === "") {
        localStorage.setItem("autoRefreshOverview", JSON.stringify({ [_orgID]: true }));
      } else {
        localStorage.setItem("autoRefreshOverview", JSON.stringify(Object.assign(JSON.parse(_autoRefreshOverview), { [_orgID]: true })));
      }

      timerAutoRefresh();
    } else {
      if (JSON.parse(_autoRefreshOverview)[_orgID]) {
        let _obj = JSON.parse(_autoRefreshOverview);

        delete _obj[_orgID];
        localStorage.setItem("autoRefreshOverview", JSON.stringify(_obj));

        clearInterval(autoRefreshRef.current);
      }
    }
    setAutoRefresh(!autoRefresh);
  }

  let isDataTotalCasePerHourEmpty = (data) => {
    let isEmpty = true;

    data.map((v, i) => {
      if (v.totalCases !== 0) {
        isEmpty = false;
      }

      return null;
    })

    return isEmpty;
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="overview-daily-report-wrapper sectionWrap">
        {/* tier blocking */}
        {retriveDataError.code === "49901" &&
          <RetrieveDataLoading
            isLoading={isWaitingFetchOverview}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="overview-daily-report-title">
            <b>Daily Overview</b>

            {isToday() &&
              <div className="daily-overview-top-right">
                {!retriveDataError.code &&
                  <>
                    <span className="grey-text">
                      Last updated {HelperDate.formatToString(new Date(lastUpdate), "dd/MM/yyyy, HH:mm")}
                    </span>
                    
                    <ButtonWithLoadingOrIcon
                      text="Refresh"
                      className="main-button-40 no-fill-button"
                      icon={{
                        type: "svg",
                        src: FiRefreshCw
                      }}
                      position="left"
                      onClickAction={() => getOverview()}
                      isDisabled={isWaitingFetchOverview}
                    />
                  </>
                }

              </div>
            }
          </p>

          <div className='daily-overview-date-wrapper-top'>
            {!retriveDataError.code &&
              <>
                <CalendarPickerSingle
                  {...props}
                  hideClear
                  isOpen={isOpenModalCalendar}
                  toggle={toggleModalCalendar}
                  date={filterDateForCalender}
                  handleDateFilterClick={handleDateFilterClick}
                  submitDateFilter={submitDateFilter}
                  cancelDatePicker={handleDateFilterCancel}
                  clearDatePicker={onClearDatePicker}
                  disabledDays={{
                    after: new Date()
                  }}
                />
                <div
                  className={`date-wrapper ${isWaitingFetchOverview ? "grey-box" : ""}`}
                  onClick={() => {
                    if (!isWaitingFetchOverview) {
                      toggleModalCalendar();
                    }
                  }}
                >
                  <span>{printDate()}</span>
                  <FiCalendar />
                </div>
              </>
            }


            {/* <div className="daily-overview-top-right">
              <span className="grey-text">
                Last updated {HelperDate.formatToString(new Date(lastUpdate), "dd/MM/yyyy, HH:mm")}
              </span>

              <ButtonWithLoadingOrIcon 
                text="Refresh"
                className="main-button-40 no-fill-button"
                icon={{
                  type: "svg",
                  src: FiRefreshCw
                }}
                position="left"
                onClickAction={getOverview}
                isDisabled={isWaitingFetchOverview}
              />
            </div> */}

            {isToday() &&
              (!retriveDataError.code &&
                <div className='toggle-auto-refresh'>
                  <label className="custom-switch-wrapper">
                    <input disabled={isWaitingFetchOverview} type="checkbox" checked={autoRefresh} onChange={(e) => onClickToggleAutoRefresh(e)} />
                    <span className="custom-switch round"></span>
                  </label>
                  <b>
                    Auto Refresh
                  </b>
                  <FiHelpCircle id="TooltipAuto" />

                  <Tooltip
                    isOpen={showTooltipAuto}
                    target="TooltipAuto"
                    toggle={toggleTooltipAuto}
                    placement="bottom"
                  >
                    Turning on toggle will refresh Daily Overview page every 10 minutes.
                  </Tooltip>
                </div>
              )
            }
          </div>

          {(isWaitingFetchOverview || (retriveDataError.code && retriveDataError.code !== "49901")) ?
            <RetrieveDataLoading
              isLoading={isWaitingFetchOverview}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
            :
            <div className="overview-daily-report-data-outer-wrapper">
              <div className="overview-daily-report-data-wrapper">

                {printedOverviewData.length === 0 ?
                  <div className="no-overview-daily-report">
                    <img src={NoReportFound} alt="No Report Found" />

                    <p>
                      <b>No report found.</b>
                    </p>
                  </div>
                  :
                  <>
                    {printedOverviewData.caseStatus.isRealTime &&
                      <div className="overview-daily-box-wrapper">
                        <p className="overview-daily-box-title">
                          <b>Real-time Status</b>
                        </p>

                        <div className="real-time-status-wrapper">
                          <div className="real-time-status-left-wrapper">
                            <p className="real-time-status-title">
                              <img src={IconUsers} alt="" className="value-title-icon" />
                              <b>Agent Status</b>
                            </p>

                            <div className="status-value-wrapper">
                              <p>
                                <div className="activity-circle online" />
                                Online (Connected)

                                <span>{printedOverviewData.agentStatus.totalOnlineConnected}</span>
                              </p>

                              <p>
                                <div className="activity-circle online-not" />
                                Online (Not Connected)

                                <span>{printedOverviewData.agentStatus.totalOnlineNotConnected}</span>
                              </p>

                              <p>
                                <div className="activity-circle away" />
                                Away

                                <span>{printedOverviewData.agentStatus.totalAway}</span>
                              </p>

                              <p>
                                <div className="activity-circle offline" />
                                Offline

                                <span>{printedOverviewData.agentStatus.totalOffline}</span>
                              </p>
                            </div>
                          </div>

                          <div className={`real-time-status-right-wrapper`}>
                            <p className="real-time-status-title">
                              <img src={IconChatList} alt="" className="value-title-icon" />
                              <b>Case Status</b>
                            </p>

                            <div className="status-value-wrapper">
                              <p>
                                <img src={IconQuestion} alt="" className="value-icon" />
                                Unassigned

                                <span>{printedOverviewData.caseStatus.totalUnassigned}</span>
                              </p>

                              <p>
                                <img src={IconUsers} alt="" className="value-icon" />
                                Assigned to agent

                                <span>{printedOverviewData.caseStatus.totalAssignedToAgent}</span>
                              </p>

                              <p>
                                <img src={IconBot} alt="" className="value-icon" />
                                Assigned to chatbot

                                <span>{printedOverviewData.caseStatus.totalAssignedToChatbot}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    <div className="overview-daily-box-wrapper">
                      <p className="overview-daily-box-title">
                        <b>Daily Report</b>
                      </p>

                      {(printedOverviewData.caseStatus.showReport === true && printedOverviewData.caseStatus.isRealTime === false) &&
                        <div className="real-time-status-wrapper real-time-status-wrapper-bottom">
                          <div className={`real-time-status-right-wrapper ${!printedOverviewData.caseStatus.isRealTime ? "no-margin real-time-status-wrapper-60" : ""}`}>
                            <p className="real-time-status-title">
                              <img src={IconChatList} alt="" className="value-title-icon" />
                              <b>Case Status</b>
                            </p>

                            <div className="status-value-wrapper">
                              <p>
                                <img src={IconQuestion} alt="" className="value-icon" />
                                Unassigned

                                <span>{printedOverviewData.caseStatus.totalUnassigned}</span>
                              </p>

                              <p>
                                <img src={IconUsers} alt="" className="value-icon" />
                                Assigned to agent

                                <span>{printedOverviewData.caseStatus.totalAssignedToAgent}</span>
                              </p>

                              <p>
                                <img src={IconBot} alt="" className="value-icon" />
                                Assigned to chatbot

                                <span>{printedOverviewData.caseStatus.totalAssignedToChatbot}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      }

                      {/* daily report */}
                      <div>
                        <div className="case-report-box">
                          <img src={CaseReport1} alt="" />
                          <p className="case-report-value"><b>{printedOverviewData.caseSummary.totalCasesCreated}</b></p>
                          <p className="case-report-title"><b>Cases Created</b></p>
                        </div>

                        <div className="case-report-box">
                          <img src={CaseReport2} alt="" />
                          <p className="case-report-value"><b>{printedOverviewData.caseSummary.totalCasesClosed}</b></p>
                          <p className="case-report-title"><b>Cases Being Resolved</b></p>
                        </div>

                        <div className="case-report-box">
                          <img src={CaseReport8} alt="" />
                          <p className="case-report-value">
                            <b>{
                              printedOverviewData.caseSummary.totalCasesClosed +
                              printedOverviewData.caseStatus.totalAssignedToAgent +
                              printedOverviewData.caseStatus.totalAssignedToChatbot +
                              printedOverviewData.caseStatus.totalUnassigned
                            }</b>
                          </p>
                          <p className="case-report-title">
                            <b>
                              Total Cases
                              <FiHelpCircle id="TooltipCase" />

                              <Tooltip
                                isOpen={showTooltipCase}
                                target="TooltipCase"
                                toggle={toggleTooltipCase}
                                placement="bottom"
                              >
                                Total of unassigned, assigned, and resolved cases on selected date
                              </Tooltip>
                            </b>
                          </p>
                        </div>

                        <div className="case-report-box">
                          <img src={CaseReport6} alt="" />
                          <p className="case-report-value"><b>{printTime(printedOverviewData.caseSummary.averageFirstResponseWaitDuration)}</b></p>
                          <p className="case-report-title"><b>Average First Response Wait Duration</b></p>
                        </div>

                        <div className="case-report-box">
                          <img src={CaseReport7} alt="" />
                          <p className="case-report-value"><b>{printTime(printedOverviewData.caseSummary.averageCaseDuration)}</b></p>
                          <p className="case-report-title"><b>Average Case Duration</b></p>
                        </div>
                      </div>
                      {/* daily report */}
                    </div>

                    {/* <div>
                      <p className="overview-daily-box-title">
                        <b>Total Cases per Hour</b>
                      </p>

                      <canvas className={isDataTotalCasePerHourEmpty() ? "force-hide" : ""} id="myChartTotalCasePerHour" width="100" height="35"></canvas>
                    </div> */}
                  </>
                }

                <div className='overview-daily-box-wrapper total-cases-per-hour'>
                  <p className="overview-daily-box-title">
                    <b>Total Cases per Hour</b>
                  </p>

                  <div className="overview-box-wrapper overview-topic-report-wrapper">
                    <div className='overview-topic-report-inner-wrapper'>
                      {printedOverviewData.length === 0 ?
                        ""
                        :
                        <>
                          {isDataTotalCasePerHourEmpty(printedOverviewData.casesPerHour) ?
                            <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper-no-margin" />

                            :

                            <canvas id="myChartTotalCasePerHour" width="100" height="35"></canvas>
                          }
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default DailyOverviewReport;
