import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationWhatsappServices = {
  getList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LIST, data, headers, callback);
  },

  getDetail(idOrTime, id, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    let data = {
      id: parseInt(id),
    };

    BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);
  },

  create(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.CREATE, data, headers, callback);
  },

  edit(idOrTime, id, topicID, accountName, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    let data = {
      id: parseInt(id),
      accountName: accountName,
      topicIDs: topicID,
    };

    BaseService.doPostAccessToken(API.EDIT, data, headers, callback);
  },

  getQR(idOrTime, id, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    let data = {
      id: parseInt(id),
    };

    BaseService.doPostAccessTokenNoWebWorker(
      API.GET_QR,
      data,
      headers,
      callback,
      'arraybuffer'
    );
  },

  logout(idOrTime, id, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    let data = {
      id: parseInt(id),
    };

    BaseService.doPostAccessToken(API.LOGOUT, data, headers, callback);
  },

  reboot(instanceId, token) {
    let headers = {};
    let param = {};
    return BaseService.doGetWithoutAccessToken(
      `https://api.chat-api.com/instance${instanceId}/reboot?token=${token}`,
      param,
      headers
    )
      .then((response) => {
        return {
          dataResult: response.data,
        };
      })
      .catch((error) => {
        return BaseService.generateErrorMessage(error);
      });
  },

  getPrice(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    let data = {};

    BaseService.doPostAccessToken(API.GET_PRICE, data, headers, callback);
  },
  
  getPaymentSetting(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    let data = {};

    BaseService.doPostAccessToken(API.GET_PAYMENT_SETTING, data, headers, callback);
  },

  rebootInstance(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REBOOT_INSTANCE, data, headers, callback);
  },

  getScreen(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessTokenNoWebWorker(
      API.GET_SCREEN,
      data,
      headers,
      callback,
      'arraybuffer'
    );
  },
  getChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHANNEL_LINK, data, headers, callback);
  },
  setChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHANNEL_LINK, data, headers, callback);
  },
  deleteChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.DELETE_CHANNEL_LINK, data, headers, callback);
  },
  getChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHAT_BOT, data, headers, callback);
  },
  setChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHAT_BOT, data, headers, callback);
  },
  removeChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REMOVE_CHAT_BOT, data, headers, callback);
  },
  getWelcomeMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_WELCOME, data, headers, callback);
  },
  getAwayMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_AWAY, data, headers, callback);
  },
  getClosingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_CLOSING, data, headers, callback);
  },
  getRatingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_USER_RATING, data, headers, callback);
  },
  setWelcomeMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);
  },
  setAwayMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);
  },
  setClosingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);
  },
  setRatingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_USER_RATING, data, headers, callback);
  },
};

const API = {
  GET_LIST: '/organization/channel/whatsapp/get_list',
  GET_DETAIL: '/organization/channel/whatsapp/get_details',
  // CREATE: '/organization/channel/whatsapp/chatapicom/create',
  CREATE: '/organization/channel/whatsapp/create_subscription',
  EDIT: '/organization/channel/whatsapp/edit',
  // DELETE: '/organization/channel/whatsapp/delete',
  LOGOUT: '/organization/channel/whatsapp/logout',
  GET_QR: '/organization/channel/whatsapp/get_qr_code',
  GET_PRICE: '/organization/channel/whatsapp/get_price',
  GET_PAYMENT_SETTING: '/organization/billing/payment_settings/get',
  REBOOT_INSTANCE: '/organization/channel/whatsapp/reboot_instance',
  GET_SCREEN: '/organization/channel/whatsapp/get_screen',
  GET_CHANNEL_LINK: '/organization/channel/whatsapp/get_channel_link',
  SET_CHANNEL_LINK: '/organization/channel/whatsapp/set_channel_link',
  DELETE_CHANNEL_LINK: '/organization/channel/whatsapp/delete_channel_link',
  GET_CHAT_BOT: '/organization/channel/whatsapp/get_chatbot',
  SET_CHAT_BOT: '/organization/channel/whatsapp/set_chatbot',
  REMOVE_CHAT_BOT: '/organization/channel/whatsapp/remove_chatbot',
  GET_WELCOME: "/organization/channel/whatsapp/get_welcome_message",
  SET_WELCOME:  "/organization/channel/whatsapp/set_welcome_message",
  GET_AWAY: "/organization/channel/whatsapp/get_away_message",
  SET_AWAY: "/organization/channel/whatsapp/set_away_message",
  GET_CLOSING: "/organization/channel/whatsapp/get_closing_message",
  SET_CLOSING: "/organization/channel/whatsapp/set_closing_message",
  GET_USER_RATING: "/organization/channel/whatsapp/get_user_rating",
  SET_USER_RATING: "/organization/channel/whatsapp/set_user_rating"
};

export default IntegrationWhatsappServices;
