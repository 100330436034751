import BaseService from "../BaseService";
import { API_KEY_BILLING, API_KEY, CONFIG } from "../../config";

let headers = {
  "API-Key": API_KEY_BILLING(),
  "App-Identifier": CONFIG.appIdentifier,
  "Device-Platform": "web",
  "Customer-ID": "",
  "Customer-Key": ""
};

const BillingServices = {
  getCustomerCredential(idOrTime, callbackComponent, callbackService) {
    let _headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CREDENTIALS, {}, _headers, (res) => {
      let _dataResult = res.dataResult;

      if (_dataResult.error.message === "") {
        headers["Customer-ID"] = _dataResult.data.customerID;
        headers["Customer-Key"] = _dataResult.data.publicKey;

        callbackService();
      } else {
        callbackComponent.onFailCredential(_dataResult.error.message, _dataResult.error.code);
      }
    });
  },

  getPaymentSetting(idOrTime, callback) {
    let _headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_PAYMENT_SETTING, {}, _headers, callback);
  },

  setPaymentSetting(idOrTime, data, callback) {
    let _headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_PAYMENT_SETTING, data, _headers, callback);
  },

  getBillingConfig(idOrTime, callback) {
    this.getCustomerCredential(idOrTime, callback, () => {
      BaseService.doPostWithoutAccessToken(API.GET_BILLING_CONFIG, {}, headers).then((response) => {
        callback.onSuccessCredential(response.data);
      })
        .catch((error) => {
          console.log(BaseService.generateErrorMessage(error));
        });
    })
  },

  getCustomerDetail(callback) {
    // BaseService.doPostWithoutAccessToken(API.GET_CUSTOMER_DETAIL, {}, headers, callback);  
  },

  getCardList(idOrTime, callback) {
    this.getCustomerCredential(idOrTime, callback, () => {
      BaseService.doPostWithoutAccessToken(API.GET_CARD_LIST, {}, headers).then((response) => {
        callback.onSuccessCredential(response.data);
      })
        .catch((error) => {
          console.log(BaseService.generateErrorMessage(error));
        });
    })
  },

  addCard(idOrTime, data, callback) {
    this.getCustomerCredential(idOrTime, callback, () => {
      BaseService.doPostWithoutAccessToken(API.ADD_CARD, data, headers).then((response) => {
        callback.onSuccessCredential(response.data);
      })
        .catch((error) => {
          console.log(BaseService.generateErrorMessage(error));
        });
    })
  },

  deleteCard(idOrTime, data, callback) {
    this.getCustomerCredential(idOrTime, callback, () => {
      BaseService.doPostWithoutAccessToken(API.DELETE_CARD, data, headers).then((response) => {
        callback.onSuccessCredential(response.data);
      })
        .catch((error) => {
          console.log(BaseService.generateErrorMessage(error));
        });
    })
  },

  detailCard(idOrTime, data, callback) {
    this.getCustomerCredential(idOrTime, callback, () => {
      BaseService.doPostWithoutAccessToken(API.CARD_DETAIL, data, headers).then((response) => {
        callback.onSuccessCredential(response.data);
      })
        .catch((error) => {
          console.log(BaseService.generateErrorMessage(error));
        });
    })
  },

  getSubscriptionList(idOrTime, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_SUBSCRIPTION_LIST, {}, headers, callback);
  },

  getUnpaidList(idOrTime, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_UNPAID_LIST, {}, headers, callback);
  },

  getInvoiceDetail(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_INVOICE_DETAIL, data, headers, callback);
  },

  retryPayment(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.RETRY_PAYMENT, data, headers, callback);
  },

  postGetTopUpHistory(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_TOPUP_HISTORY, data, headers, callback);
  },

  postGetMutation(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_MUTATION, data, headers, callback);
  },

  postGetLowBalance(idOrTime, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LOW_BALANCE, {}, headers, callback);
  },

  postDeleteLowBalance(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.DELETE_LOW_BALANCE, data, headers, callback);
  },

  postAddLowBalance(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.ADD_LOW_BALANCE, data, headers, callback);
  },

  postEditLowBalance(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_LOW_BALANCE, data, headers, callback);
  },

  getBillingNotif(idOrTime, callback) {
    let _headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_BILLING_NOTIF, {}, _headers, callback);
  },

  setBillingNotif(idOrTime, data, callback) {
    let _headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_BILLING_NOTIF, data, _headers, callback);
  },

  deleteBillingNotif(idOrTime, data, callback) {
    let _headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_BILLING_NOTIF, data, _headers, callback);
  },
};


const API = {
  GET_CREDENTIALS: "/organization/billing/tapbill/get_credentials",
  GET_PAYMENT_SETTING: "/organization/billing/payment_settings/get",
  SET_PAYMENT_SETTING: "/organization/billing/payment_settings/set",
  GET_CUSTOMER_DETAIL: CONFIG.requestURLBilling + "/service/customer/get_details",
  GET_CARD_LIST: CONFIG.requestURLBilling + "/client/payment_method/card/get_list",
  ADD_CARD: CONFIG.requestURLBilling + "/client/payment_method/card/add/xendit",
  DELETE_CARD: CONFIG.requestURLBilling + "/client/payment_method/card/delete",
  CARD_DETAIL: CONFIG.requestURLBilling + "/client/payment_method/card/get_details",
  GET_BILLING_CONFIG: CONFIG.requestURLBilling + "/client/payment_method/get_configs",
  GET_SUBSCRIPTION_LIST: '/organization/billing/subscription/get_list',
  GET_UNPAID_LIST: '/organization/billing/invoice/get_unpaid_list',
  GET_INVOICE_DETAIL: '/organization/billing/invoice/get_details',
  RETRY_PAYMENT: '/organization/billing/invoice/retry_payment_primary',
  GET_TOPUP_HISTORY: '/organization/billing/balance/get_topup_history',
  GET_MUTATION: '/organization/billing/balance/get_mutation_history',
  GET_LOW_BALANCE: '/organization/billing/balance/get_low_balance_reminders',
  ADD_LOW_BALANCE: '/organization/billing/balance/add_low_balance_reminder',
  EDIT_LOW_BALANCE: '/organization/billing/balance/edit_low_balance_reminder',
  DELETE_LOW_BALANCE: '/organization/billing/balance/delete_low_balance_reminder',
  GET_BILLING_NOTIF: '/organization/billing/notification/get_recipients',
  ADD_BILLING_NOTIF: '/organization/billing/notification/add_email_recipients',
  DELETE_BILLING_NOTIF: '/organization/billing/notification/delete_email_recipients',
}

export default BillingServices;
