import React, { useEffect, useState } from "react";
import ModalStartConversationNew from "./modalStartConversation/ModalStartConversationNew";
// import ModalStartConversation from "./modalStartConversation/ModalStartConversation";
// import ModalStartConversationWABA from "./modalStartConversation/ModalStartConversationWABA";
import ModalSending from "./modalSending/ModalSending";
import ModalOngoingCase from "./modalOngoingCase/ModalOngoingCase";
import ModalHasOpenCase from "./modalHasOpenCase/ModalHasOpenCase";
import ModalChooseChannel from "./modalChooseChannel/ModalChooseChannel";
import PreviewMessage from "./previewMessage/PreviewMessage";
import ModalIsHideOther from "./modalIsHideOther/ModalIsHideOther";
import { TAPLIVE_MEDIUM } from "../../../../constants/taplive";
import { setCloseCaseNewMessageRecipient } from "../../../../redux/actions/reduxActionCloseCaseNewMessageRecipient";
import { connect } from 'react-redux';
// import { taptalk } from '@taptalk.io/web-sdk';

let StartConversation = (props) => {
  let [isShowModalStartConversation, setIsShowModalStartConversation] = useState(false);
  let [isShowModalStartConversationWABA, setIsShowModalStartConversationWABA] = useState(false);
  let [isShowModalSending, setIsShowModalSending] = useState(false);
  let [isShowModalPreviewMessage, setIsShowModalPreviewMessage] = useState(
    false
  );
  let [isShowModalOnGoingCase, setIsShowModalOngoingCase] = useState(false);
  let [onGoingCaseData, setOnGoingCaseData] = useState(false);
  let [dataPreviewMessage, setDataPreviewMessage] = useState(false);
  let [type, setType] = useState("sme");
  let [reset, setReset] = useState(false);
  let [lastCloseModalStartConversation, setLastCloseModalStartConversation] = useState(false);

  // useEffect(() => {
  //   if(props.history.location.state) {
  //     if(props.history.location.state.startConversation) {
  //       if(props.history.location.state.startConversation.medium === TAPLIVE_MEDIUM.whatsapp) {
  //         setIsShowModalStartConversation(true);
  //       }else {
  //         setIsShowModalStartConversationWABA(true);
  //       }
  //     }
  //   }
  // }, [props.history])

  useEffect(() => {
    let _localnm = localStorage.getItem("nm");

    if (_localnm) {
      let _startConversation = JSON.parse(_localnm);
      let _startConversation2 = { ..._startConversation };

      if (_startConversation2) {
        if (_startConversation2.medium === TAPLIVE_MEDIUM.whatsapp) {
          setIsShowModalStartConversation(true);
        } else {
          setIsShowModalStartConversationWABA(true);
        }
      }
    }
  }, [localStorage])

  useEffect(() => {
    // let _localnm = localStorage.getItem("nm");

    // if (_localnm) {
    //   let _startConversation = JSON.parse(_localnm);
    //   let _startConversation2 = { ..._startConversation };

    //   if (_startConversation2) {
    //     if (_startConversation2.medium === TAPLIVE_MEDIUM.whatsapp) {
    //       setIsShowModalStartConversation(true);
    //     } else {
    //       setIsShowModalStartConversationWABA(true);
    //     }
    //   }
    // }
    
    if (props.closeCaseNewMessageRecipient.lastClick) {
      let _startConversation2 = { ...props.closeCaseNewMessageRecipient };

      if (_startConversation2.medium === TAPLIVE_MEDIUM.whatsapp) {
        setIsShowModalStartConversation(true);
      } else {
        setIsShowModalStartConversationWABA(true);
      }

      // let _closeCaseNewMessage = {...props.closeCaseNewMessageRecipient};
      // _closeCaseNewMessage.lastClick = false;

      // props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);
    }
  }, [props.closeCaseNewMessageRecipient])

  let toggleModalStartConversation = (type = "sme", isClose = false) => {
    setType(type);
    if (type === "sme") {
      setIsShowModalStartConversation(!isShowModalStartConversation);
    }
    if (type === "waba") {
      setIsShowModalStartConversationWABA(!isShowModalStartConversationWABA);
    }

    if (isClose) {
      setLastCloseModalStartConversation(new Date().valueOf());
    }
  };

  let toggleModalPreviewMessage = (isShow) => {
    setIsShowModalPreviewMessage(isShow);
  };

  let close = () => {
    toggleModalPreviewMessage(false);
    setReset(!reset)
    setIsShowModalStartConversation(false);
    setIsShowModalStartConversationWABA(false);
    setLastCloseModalStartConversation(new Date().valueOf());
  };

  let toggleModalSending = (isShow) => {
    setIsShowModalSending(isShow);
  };

  let toggleModalOngoingCase = () => {
    setIsShowModalOngoingCase(!isShowModalOnGoingCase);
  };

  let runActionCaseIsOnGoing = (caseData) => {
    setOnGoingCaseData(caseData);
    toggleModalOngoingCase();
  };

  let resetDataPreviewMessage = (data = null) => {
    setDataPreviewMessage(data)
  }

  let changeToMedium = (medium) => {
    if (medium === "whatsapp") {
      setIsShowModalStartConversation(true);
      setIsShowModalStartConversationWABA(false);
    } else {
      setIsShowModalStartConversation(false);
      setIsShowModalStartConversationWABA(true);
    }
  }

  return (
    <>
      <ModalChooseChannel
        isShowModalChooseChannelProps={props.isShowModalStartConversationProps}
        toggleModalChooseChannelProps={props.toggleModalStartConversationProps}
        toggleModalStartConversation={toggleModalStartConversation}
        clearPreviewMessageData={() => {
          setDataPreviewMessage(false);
        }}
      />

      <ModalStartConversationNew
        {...props}
        medium={isShowModalStartConversation ? "sme" : (isShowModalStartConversationWABA ? "waba" : "")}
        changeToMedium={changeToMedium}
        isShowModalStartConversationProps={isShowModalStartConversation || isShowModalStartConversationWABA}
        toggleModalStartConversationProps={toggleModalStartConversation}
        orgIDProps={props.orgIDProps}
        topicListProps={props.topicListProps}
        runActionCaseIsOnGoingProps={runActionCaseIsOnGoing}
        toggleModalSendingProps={toggleModalSending}
        toggleSwitchMineCaseProps={props.toggleSwitchMineCaseProps}
        onClickRoomListProps={props.onClickRoomListProps}
        parentProps={props.parentProps}

        toggleModalPreviewMessageProps={toggleModalPreviewMessage}
        dataPreviewMessage={dataPreviewMessage}
        runSetDataPreviewMessage={(data) => setDataPreviewMessage(typeof data === "object" ? { ...data } : false)}
        isShowModalStartConversation={isShowModalStartConversation}
        isShowModalStartConversationWABA={isShowModalStartConversationWABA}
        lastCloseModalStartConversation={lastCloseModalStartConversation}
      />

      {/* <ModalStartConversation
        {...props}
        isShowModalStartConversationProps={isShowModalStartConversation}
        toggleModalStartConversationProps={toggleModalStartConversation}
        orgIDProps={props.orgIDProps}
        topicListProps={props.topicListProps}
        runActionCaseIsOnGoingProps={runActionCaseIsOnGoing}
        toggleModalSendingProps={toggleModalSending}
        toggleSwitchMineCaseProps={props.toggleSwitchMineCaseProps}
        onClickRoomListProps={props.onClickRoomListProps}
        parentProps={props.parentProps}
      />
      
      <ModalStartConversationWABA
        {...props}
        isShowModalStartConversationProps={isShowModalStartConversationWABA}
        toggleModalStartConversationProps={toggleModalStartConversation}
        orgIDProps={props.orgIDProps}
        topicListProps={props.topicListProps}
        runActionCaseIsOnGoingProps={runActionCaseIsOnGoing}
        toggleModalSendingProps={toggleModalSending}
        toggleSwitchMineCaseProps={props.toggleSwitchMineCaseProps}
        toggleReset={reset}
        onClickRoomListProps={props.onClickRoomListProps}
        parentProps={props.parentProps}
        
        toggleModalPreviewMessageProps={toggleModalPreviewMessage}
        dataPreviewMessage={dataPreviewMessage}
        runSetDataPreviewMessage={(data) => setDataPreviewMessage(typeof data === "object" ? { ...data } : false)}
      /> */}

      <PreviewMessage
        {...props}
        type={type}
        resetDataPreviewMessage={resetDataPreviewMessage}
        isShowModalPreviewMessageProps={isShowModalPreviewMessage}
        dataPreviewMessage={dataPreviewMessage}
        runSetDataPreviewMessage={(data) => setDataPreviewMessage(typeof data === "object" ? { ...data } : false)}
        topicListProps={props.topicListProps}
        toggleModalSendingProps={toggleModalSending}
        runActionCaseIsOnGoingProps={runActionCaseIsOnGoing}
        onClickRoomListProps={props.onClickRoomListProps}
        toggleModalStartConversationProps={toggleModalStartConversation}
        toggleModalPreviewMessageProps={toggleModalPreviewMessage}
        closeModal={close}
      />

      <ModalSending
        isShowModalSendingProps={isShowModalSending}
        toggleModalSendingProps={toggleModalSending}
      />

      <ModalOngoingCase
        isShowModalOngoingProps={isShowModalOnGoingCase}
        toggleModalOngoingCaseProps={toggleModalOngoingCase}
        onGoingCaseDataProps={onGoingCaseData}
        onClickRoomListProps={props.onClickRoomListProps}
      />

      <ModalHasOpenCase topicListProps={props.topicListProps} />

      <ModalIsHideOther {...props} />
    </>
  );
};

const mapStateToProps = state => ({
  closeCaseNewMessageRecipient: state.closeCaseNewMessageRecipient
});

const mapDispatchToProps = {
  // setActiveRoom,
  setCloseCaseNewMessageRecipient
};

export default connect(mapStateToProps, mapDispatchToProps)(StartConversation);
