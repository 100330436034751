import React, { useEffect, useState } from 'react';
import './MessageApiHistoryShow.scss';
import { Tooltip } from 'reactstrap';
import ReportService from '../../../../services/newServices/ReportService';
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import { FiDownload, FiInfo } from 'react-icons/fi';
import HelperDate from '../../../../helper/HelperDate';
import HelperInput from '../../../../helper/HelperInput';
import { doToast, checkID } from '../../../../helper/HelperGeneral';
import AdvancedDateFilter from '../../../reuseableComponent/advancedDateFilter/AdvancedDateFilter';
import NumberDropdown from '../../../reuseableComponent/numberDropdown/NumberDropdown';
import MessageHistoryFilter from '../../../reuseableComponent/messageHistoryFilter/MessageHistoryFilter';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import Pagination from "../../../reuseableComponent/pagination/Pagination";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";
import iconWSME from '../../../../assets/img/integration/launcher/icon-whatsapp.svg';
import iconWABA from '../../../../assets/img/integration/launcher/icon-whatsapp-ba.svg';
import iconEyeOrange from "../../../../assets/img/icon-eye-orange.svg";
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white-real.svg';
import CheckMarkWhite from '../../../../assets/img/chatroom/icon-single-white.svg';
import MessageDetailsModal from '../../../reuseableComponent/messageDetailsModal/MessageDetailsModal';

const MessageApiHistoryShow = (props) => {
    let [orgID, setOrgID] = React.useState(false);
    let [isWaitingFetchMessageHistory, setIsWaitingFetchMessageHistory] = useState(true);
    let [printedMessageHistory, setPrintedMessageHistory] = useState([]);
    let [isLoadingExportReport, setIsLoadingExportReport] = useState(false);
    let [retrieveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [filterDataMessageHistory, setFilterDataMessageHistory] = useState({
        messageTypes: [],
        channelTypes: [],
        status: "",
        contactPhone: "",
    });
    let [dateVal, setDateVal] = useState({
        from:  new Date(new Date().setDate(new Date().getDate() - 30)),
        to: new Date()
    });
    let [filterDateForCalender, setFilterDateForCalender] = useState({
        from: undefined,
        to: undefined
    });
    let [filterBy, setFilterBy] = useState({
        text: "Last 30 Days",
        val: "last_30"
    });
    // let [sort, setSort] = useState({
    //     by: "fullName",
    //     order: "ASC"
    // })
    const pageSizeSelectionArray = [20, 50, 100];
    let [pageSize, setPageSize] = useState(50);
    const [pageDisplay, setPageDisplay] = useState(1);
    let [totalItems, setTotalItems] = useState(0);
    let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);
    let [isShowDropdownPageSize, setIsShowDropdownPageSize] = useState(false);
    let [showTooltip, setShowTooltip] = useState([]);

    let toggleTooltip = (idx) => {
        let _show = showTooltip.slice();
        _show[idx] = !_show[idx];
        setShowTooltip(_show);
    }

    let onMessageHistoryFilterUpdated = (val) => {
        setFilterDataMessageHistory(val);
    }

    let [selectedMessage, setSelectedMessage] = useState(false);
    let [isOpenMessageDetailsModal, setIsOpenMessageDetailsModal] = useState(false);
    let toggleMessageDetailsModal = () => {
        setIsOpenMessageDetailsModal(!isOpenMessageDetailsModal);
    }

    const LABEL_TEXT = {
        read: "The recipient has read the message.",
        delivered: `The message was successfully delivered to the recipient's device. For WhatsApp, this is equivalent to two gray check marks <img src=${CheckMarkDoubleWhite} alt="" />`,  
        acknowledged: `The message was received by the messaging server, but not delivered to the recipient yet. The recipient might be offline or inactive. For WhatsApp, this is equivalent to a single check mark <img src=${CheckMarkWhite} alt="" />`,
        sent: "The message was sent by OneTalk to the handling server, e.g.: WhatsApp Web, WhatsApp Server. The handling server may have its own queue.",
        failed: "Failed to send the message. The reason may be invalid recipient, inactive channel, or other reasons.",
        pending: "The message is pending and waiting to be sent."
    }

    const TABLE_HEADER = [
        {
            title: "Date and Time",
            value: "createdTime",
            class: "col_time " 
        },
        {
            title: "Full Name",
            value: "contactFullName",
            class: "col_name " 
        },
        {
            title: "Alias",
            value: "contactAlias",
            class: "col_alias " 
        },
        {
            title: "Phone Number",
            value: "contactPhone",
            class: "col_phone " 
        },
        {
            title: "Channel",
            value: "channel",
            class: "col_channel " 
        },
        {
            title: "Message",
            value: "message",
            class: "col_message " 
        },
        {
            title: "Type",
            value: "messageType",
            class: "col_type " 
        },
        {
            title: "Status",
            value: "status",
            class: "col_status " 
        },
    ]

    useEffect(() => {
        if (orgID) {
            getMessageApiHistory();
        }
    }, [orgID, filterDataMessageHistory, dateVal])

    useEffect(() => {
        if (printedMessageHistory && printedMessageHistory.length > 0) {
            setPageDisplay(1);
            handleChangePage(1);
        }
    }, [pageSize])

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Message API History Showing", 
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, []);

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.success) {
                doToast(props.history.location.state.message);
            }
            else {
                doToast(props.history.location.state.message, 'fail');
            }

            window.history.pushState(null, '');
        }
    }, [props.history]);

    const doMount = async () => {
        //filter
        setDateVal(props.filterData.dateVal);
        setFilterDateForCalender(props.filterData.filterDateForCalender);
        setFilterBy(props.filterData.filterBy);
        setFilterDataMessageHistory(props.filterData.filterDataMessageHistory);
        setPageSize(props.filterData.pageSize);
        //filter

        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        }
        else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/organization/members`);
                }
                else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getMessageApiHistory = (_sort = false, page = false) => {
        setIsWaitingFetchMessageHistory(true);
        let _retrieveDataError = {...retrieveDataError};
        let data = {
            startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
            endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
            pageNumber: !page ? pageDisplay : page,
            pageSize: pageSize,
            messageTypes: filterDataMessageHistory.messageTypes,
            channelTypes: filterDataMessageHistory.channelTypes,
            status: filterDataMessageHistory.status,
            contactPhone: filterDataMessageHistory.contactPhone,
        }

        ReportService.getMessageApiHistory(props.match.params.orgID, data, (response) => {
            setIsWaitingFetchMessageHistory(false);
            let dataResult = response.dataResult;

            if (dataResult.error.message === '') {
                let showArr = [];

                dataResult.data.messages.map((v, i) => {
                    showArr.push(false);
                    return null;
                })

                setShowTooltip(showArr);
                setPrintedMessageHistory(dataResult.data.messages);
                setTotalItems(dataResult.data.totalItems);
            }
            else {
                _retrieveDataError.message = dataResult.error.message;
                _retrieveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retrieveDataError);
            }
        });
    };

    let handleChangePage = (page) => {
        setPageDisplay(page)
        getMessageApiHistory(false, page);
    }

    let printText = (val) => {
        return val !== "" ? val : "-";
    }

    // let onSort = (_sort) => {
    //     let _s = {
    //     by: _sort,
    //     order: _sort === sort.by ? (sort.order === "ASC" ? "DESC" : "ASC") : "ASC"
    //     }
        
    //     setSort(_s);
    //     getMessageApiHistory(_s, false);
    // }

    let onNumberSelected = (val) => {
        if (pageSize !== val) {
            setPageSize(val);
        }
    }

    const exportData = () => {
        let date = {
            startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
            endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : ""
        };

        let data = {
            ...date,
            ...filterDataMessageHistory
        };
    
        setIsLoadingExportReport(true);
        
        ReportService.exportMessageHistoryReport(orgID, data,
            (response) => {
                setIsLoadingExportReport(false);
                if (!response) {
                    // setShowModalFailed(true);
                }
                else {
                    downloadReport(response.dataResult)
                }
            }
        )
    }

    const downloadReport = (data) => {
        // const id = orgID.split('-');
        // const timestamp = + new Date();
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `message-history-report-data-from-${HelperDate.formatToString(new Date(dateVal.from), "dd-MM-yyyy")}-to-${HelperDate.formatToString(new Date(dateVal.to), "dd-MM-yyyy")}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >
            <div className="message-history-report-wrapper sectionWrap">
                {/* tier blocking */}
                {
                    retrieveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                        isLoading={isWaitingFetchMessageHistory}
                        errorMessage={retrieveDataError.message}
                        errorCode={retrieveDataError.code}
                        loadingText={"Loading Report"}
                    />
                }
                {/* tier blocking */}

                <React.Fragment>
                    <p className="message-history-report-title">
                        <b>Message API History</b>
                    </p>

                    {!retrieveDataError.code &&
                        <>
                            {isLoadingExportReport ?
                                <ButtonWithLoadingOrIcon 
                                    className="orange-button main-button-40 download-report-button"
                                    text="Download Report"
                                    position="left"
                                    isLoading
                                    isDisabled
                                    loadingColor="gray"
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    className="orange-button main-button-40 download-report-button"
                                    onClickAction={exportData}
                                    text="Download Report"
                                    position="left"
                                    icon={{
                                        type: "svg",
                                        src: FiDownload
                                    }}
                                />
                            }
                        </>
                    }

                    <div className="message-history-report-data-outer-wrapper">
                        {!retrieveDataError.code &&
                            <>
                                <AdvancedDateFilter 
                                    toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
                                    isOpenDropdown={isShowDropdownDate}
                                    dateVal={dateVal}
                                    filterDateForCalender={filterDateForCalender}
                                    filterBy={filterBy}
                                    onClickDate={(date) => setDateVal(date)}
                                    onClickFilterBy={(filter) => setFilterBy(filter)}
                                    onClickCalendar={(date) => setFilterDateForCalender(date)}
                                    ignoreAllTime
                                    isDisabled={isWaitingFetchMessageHistory || isLoadingExportReport}
                                    disabledDays={{ after: new Date() }}
                                    maxRange={365}
                                />
                                
                                <MessageHistoryFilter 
                                    currentFilter={filterDataMessageHistory}
                                    onFilterUpdated={onMessageHistoryFilterUpdated}
                                    isDisabled={isWaitingFetchMessageHistory || isLoadingExportReport}
                                />

                                <NumberDropdown 
                                    isOpenDropdown={isShowDropdownPageSize}
                                    toggleDropdown={() => setIsShowDropdownPageSize(!isShowDropdownPageSize)}
                                    numbersArray={pageSizeSelectionArray}
                                    selectedNumber={pageSize}
                                    onNumberSelected={onNumberSelected}
                                    isDisabled={isWaitingFetchMessageHistory || isLoadingExportReport}
                                />
                            </>
                        }
                        
                        {
                            (isWaitingFetchMessageHistory ||
                            (retrieveDataError.code && retrieveDataError.code !== "49901")
                        ) ? 
                            <RetrieveDataLoading 
                                isLoading={isWaitingFetchMessageHistory}
                                errorMessage={retrieveDataError.message}
                                errorCode={retrieveDataError.code}
                            />
                            :
                            <React.Fragment>
                                <div className="message-history-report-data-wrapper">
                                    {
                                        printedMessageHistory.length === 0 ? 
                                        <div className="no-message-history-report">
                                            <p>
                                                <b>No results found, please try another date range or filters.</b>
                                            </p>
                                        </div>
                                        :
                                        <table>
                                            <thead>
                                                <tr>
                                                    {TABLE_HEADER.map((val, idx) => {
                                                        return (
                                                        <React.Fragment key={`table-head-${idx}`}>
                                                            {/* <AscendingDescendingTableHeader 
                                                                className={val.class}
                                                                fieldName={val.value}
                                                                title={val.title}
                                                                // onClick={() => onSort(val.value)}
                                                                sortByVal={{
                                                                    by: "createdTime",
                                                                    order: "ASC"
                                                                }}
                                                            /> */}
                                                            <td className={val.class}><b>{val.title}</b></td>
                                                        </React.Fragment>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    printedMessageHistory.map((value, key) => {
                                                        return (
                                                            <tr key={`message-history-report-row-${key}`}>
                                                                <td className="col_time">
                                                                    {HelperDate.formatToString(new Date(value.createdTime), "dd/MM/yyyy HH:mm")}
                                                                </td>
                                                                <td className="col_name">
                                                                    {printText(value.contactFullName)}
                                                                </td>
                                                                <td className="col_alias">
                                                                    {printText(value.contactAlias)}
                                                                </td>
                                                                <td className="col_phone">
                                                                    {value.contactPhone ? "+" + HelperInput.phoneBeautify(value.contactPhone) : "-"}
                                                                </td>
                                                                <td className="col_channel">
                                                                    {
                                                                        value.channelType === "whatsapp"
                                                                        ?
                                                                        <img alt="" src={iconWSME} />
                                                                        :
                                                                        value.channelType === "whatsappba"
                                                                        ?
                                                                        <img alt="" src={iconWABA} />
                                                                        :
                                                                        ""
                                                                    }
                                                                    {printText(value.channelName)}
                                                                </td>
                                                                <td 
                                                                    className="col_message"
                                                                    onClick={() => {
                                                                        setSelectedMessage(value);
                                                                        setIsOpenMessageDetailsModal(true);
                                                                    }}
                                                                >
                                                                    <img alt="" src={iconEyeOrange} />
                                                                    <p>View</p>
                                                                </td>
                                                                <td className="col_type">
                                                                    {printText(value.messageType)}
                                                                </td>
                                                                <td className="col_status">
                                                                    <p className={`label ${value.status ? value.status : ""}`}>
                                                                        {printText(value.statusText)}

                                                                        <FiInfo className="tooltip-this" id={`TooltipStatus-${key}`} />

                                                                        {/* <div className='info-box-hover'>
                                                                            {LABEL_TEXT[value.status]}
                                                                        </div> */}

                                                                        <Tooltip
                                                                            isOpen={showTooltip[key]}
                                                                            target={`TooltipStatus-${key}`}
                                                                            toggle={() => toggleTooltip(key)}
                                                                            className="info-box-hover"
                                                                            placement="bottom"
                                                                            // placementPrefix="bs-tooltip"
                                                                        >
                                                                            <span dangerouslySetInnerHTML={{__html: LABEL_TEXT[value.status]}} />
                                                                        </Tooltip>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>

                                <div className="pagination-wrapper">
                                    {
                                        totalItems > 0 &&
                                        <Pagination
                                            total={totalItems}
                                            page={pageDisplay}
                                            listPerPage={pageSize}
                                            step={1}
                                            onChangePage={handleChangePage}
                                        />
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </div>   
                </React.Fragment>
            </div>
            
            <MessageDetailsModal 
                isOpen={isOpenMessageDetailsModal}
                toggle={toggleMessageDetailsModal}
                message={selectedMessage}
            />

        </SectionWrap>
    );
};

export default MessageApiHistoryShow;
