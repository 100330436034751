import React, { useState } from "react";
import "./TemplateUpload.scss";
import { Tooltip } from 'reactstrap';
import { taptalk } from "@taptalk.io/web-sdk";
import { FiFile, FiInfo, FiUploadCloud, FiHelpCircle } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
// import ChatService from '../../../../../../../services/chat/caseServices';
import { doToast } from '../../../../../../../helper/HelperGeneral';
// import { runImageCompress } from "../../../../../../../helper/HelperGeneral";
import HelperUpload from "../../../../../../../helper/HelperUpload";
import { WHATSAPP_FILE_SIZE_LIMIT } from "../../../../../../../constants/taplive";
import IntegrationWhatsappBusinessServices from "../../../../../../../services/integration/IntegrationWhatsappBusinessServices";
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


let TemplateUpload = (props) => {
    let { createTemplateMessageVal, orgID, index, runSetTemplate, templateType, fileType, isLoading } = props;
    let [tooltipOpen, setTooltipOpen] = useState(false);
    // let [isDisabledUpload, setIsDisabledUpload] = useState(false)
    // let [percentageUpload, setPercentageUpload] = useState(false);
    let [loadingUpload, setLoadingUpload] = useState(false);

    // useEffect(() => {
    //   setIsDisabledUpload(false)
    // }, [language])
    let toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    let doUpload = (file) => {
        setLoadingUpload(true);
        // setIsDisabledUpload(true);
        
        let _data = new FormData();

        _data.append("fileType", fileType === "document" ? "file" : fileType);
        _data.append("file", file);

        IntegrationWhatsappBusinessServices.uploadFile(orgID, _data, (res) => {
          let dataResult = res.dataResult;

          if(dataResult.error.code !== "") {
            doToast(dataResult.error.message, "fail");
          }else {
            let _createTemplateMessageVal = {...createTemplateMessageVal};
            _createTemplateMessageVal.file = file;
            _createTemplateMessageVal.headerText = dataResult.data.fileURL;
            runSetTemplate(_createTemplateMessageVal, index);
          }

          setLoadingUpload(false);
        })
        
        // let uploadData = {
        //   file: file,
        //   caption: "",
        //   room: ""
        // };

        // tapCoreMessageManager.uploadChatFile(uploadData, {
        //   onProgress: (percentage, bytes) => {
        //     setPercentageUpload(percentage);
  
        //     if(percentage === 100) {
        //       setPercentageUpload(false);
        //     }
        //   },
  
        //   onSuccess: (response) => {
        //       if(response) {
        //           // setIsDisabledUpload(false);
        //           // let newFileData = {};
        //           // let arrayFileData = [];
        //           let _createTemplateMessageVal = {...createTemplateMessageVal};
        //           _createTemplateMessageVal.file = file;
        //           _createTemplateMessageVal.headerText = response.fileURL;
        //           runSetTemplate(_createTemplateMessageVal, index);
        //       }
        //   },
  
        //   onError: (error) => {
        //     // setIsDisabledUpload(false);
        //     doToast(error, "fail");
        //     console.log(error);
        //   }
        // })
    }

    // let taptalkAuthTicket = (file) => {
    //     let _createTemplateMessageVal = {...createTemplateMessageVal};
    //     _createTemplateMessageVal.file = file;
    //     runSetTemplate(_createTemplateMessageVal, index);

    //     ChatService.postRequestAuthTicket(orgID, (response) => {
    //       if (response.dataResult.error.code === "") {
    //         taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
    //           onSuccess: () => {
    //             doUpload(file);
    //           },
    //           onError: (errorCode, errorMessage) => {
    //             doToast(errorMessage, 'fail')
    //             console.log(errorCode, errorMessage);
    //           }
    //         })
    //       } else {
    //         doToast(response.dataResult.error.message, 'fail');
    //       }
    //     })
    // }
    
    // let runTaptalkRequestAuthTicket = (file) => {
    //     ChatService.postProjectConfig(orgID, (response) => {
    //       let data = response.dataResult.data.tapTalk;
    
    //       if (response.dataResult.error.code === "") {
    //         taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
    //         taptalkAuthTicket(file);
    //       } else {
    //         doToast(response.dataResult.error.message, "fail");
    //       }
    //     });
    // }

    let runUpload = (file) => {
        // setPercentageUpload(1);
        doUpload(file);
  
        // if(props.withoutRequestAuthTicketProps) {
        //   doUpload(file);
        // }else {
        //   runTaptalkRequestAuthTicket(file);
        // }
      }

    let onChangeFile = (e, sizeLimit) => {
        let files = [];
        files.push(e.target.files[0]);

        HelperUpload.checkFileAllowed(files, {
            onAllowed: () => {
              HelperUpload.checkFileLimit(files, {
                onAllowed: () => {
                  HelperUpload.checkFileSizeLimitForMedia([], files, {
                      onAllowed: (arrayOfMedia) => {
                        let _createTemplateMessageVal = {...createTemplateMessageVal};
                        _createTemplateMessageVal.file = arrayOfMedia[0];
                        runSetTemplate(_createTemplateMessageVal, index);
                        
                        runUpload(arrayOfMedia[0])
                    },
                    onReachLimit: (message) => {
                      doToast(message, "fail");
                    }
                  }, sizeLimit)
                },
                onReachLimit: (message) => {
                  doToast(message, "fail");
                }
              })
            },
            onFileTypeDidntMatch: (message) => {
              doToast(message, "fail");
            }
        }, createTemplateMessageVal.headerFormat === "image" ? "image" : ( createTemplateMessageVal.headerFormat === "video" ? "video" : "pdf"))
    
        e.target.value = null;
    }

    return (
        <div className="template-message-upload">
            <p className="media-content-title">
                <span className="grey-text">
                  {templateType === "static-media" ? "Media Content" : "Sample Media Content"}

                  {templateType === "dynamic-media" &&
                    <>
                      <FiHelpCircle
                        id="TooltipMedia"
                        className="fi-help"
                      />

                      <Tooltip
                        placement={"bottom"}
                        isOpen={tooltipOpen}
                        target="TooltipMedia"
                        toggle={toggleTooltip}
                      >
                        The media uploaded for the Header will only be a sample media. You can change it later when creating a broadcast message.
                      </Tooltip>
                    </>
                  }
          

                </span>

                {createTemplateMessageVal.file &&
                    <label 
                        onClick={() => {
                          // if(!isDisabledUpload) {
                            document.querySelector(`#upload-media-${index}`).click()
                          // } 
                        }}
                        className="replace-media"
                    >
                        <button className="light-orange-button" disabled={isLoading}>
                            <FiUploadCloud />
                            Replace Media
                        </button>
                    </label>
                }
            </p>

            {!createTemplateMessageVal.file &&
                <label
                    onClick={() => {
                      // if(!isDisabledUpload) {
                        document.querySelector(`#upload-media-${index}`).click()
                      // }
                    }}  
                    className="button-upload-media" style={{paddingTop: "10px"}}
                >
                    <ButtonWithLoadingOrIcon
                        text={templateType === "static-media" ? "Upload Media" : "Upload Sample Media"}
                        className="main-button-40 light-orange-button"
                        icon={{
                            type: "svg",
                            src: FiUploadCloud
                        }}
                        position="left"
                    />
                </label>
            }

            <input 
                type="file" 
                id={`upload-media-${index}`}
                accept={
                    createTemplateMessageVal.headerFormat === "document" ? 
                        "application/pdf"
                        :
                        createTemplateMessageVal.headerFormat === "image" ? 
                            "image/*"
                            :
                            "video/*"
                }
                // onChange={(e) => onChangeFile(e, createTemplateMessageVal.headerFormat === "document" ? WHATSAPP_FILE_SIZE_LIMIT.document(taptalk.getProjectConfigs().core.chatMediaMaxFileSize) : WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))} 

                onChange={(e) => onChangeFile(e, createTemplateMessageVal.headerFormat === "document" ? WHATSAPP_FILE_SIZE_LIMIT.document(taptalk.getProjectConfigs().core.chatMediaMaxFileSize) : 5242880)} 
            />

            {createTemplateMessageVal.file &&
                    <div className="template-message-upload-preview">
                        {createTemplateMessageVal.headerFormat === "document" ? 
                            // <span className="preview-document"><FiFile/> {createTemplateMessageVal.file.name} {percentageUpload ? <b> - {percentageUpload}%</b> : ""}</span>
                            <span className="preview-document"><FiFile/> {createTemplateMessageVal.file.name} {loadingUpload && <div className="lds-ring"><div /><div /><div /><div /></div>}</span>
                            :
                            createTemplateMessageVal.headerFormat === "image" ? 
                                <div className="image-video-wrapper">
                                    <img src={createTemplateMessageVal.file.fileSrc} alt="" />

                                    {loadingUpload &&
                                        <div className="progress-wrapper">
                                          {/* <CircularProgressbar value={percentageUpload} /> */}
                                          <div className="lds-ring">
                                            <div /><div /><div /><div />
                                          </div>
                                        </div>
                                    }
                                </div>
                                :
                                <div className="image-video-wrapper">
                                    <video src={createTemplateMessageVal.file.fileSrc} />
                                    
                                    {loadingUpload &&
                                        <div className="progress-wrapper">
                                          {/* <CircularProgressbar value={percentageUpload} /> */}
                                          <div className="lds-ring">
                                            <div /><div /><div /><div />
                                          </div>
                                        </div>
                                    }
                                </div>
                        }
                    </div>
            }

            <div className="form-content-wrapper">
                <p className="input-info-wrapper input-info-template-name">
                    <FiInfo />
                    <b>
                        {createTemplateMessageVal.headerFormat === "" ? 
                            "Allowed file types: image, video, pdf"
                            :
                            createTemplateMessageVal.headerFormat === "document" ? 
                                "Allowed file type: pdf"
                                :
                                createTemplateMessageVal.headerFormat === "image" ? 
                                    "Allowed file type: image"
                                    :
                                    "Allowed file type: video"
                        }
                    </b>
                </p>
            </div>
        </div>
    )
}

export default TemplateUpload;