import React, { useState, useEffect } from 'react';
import '../Auth.css';
import './ForgotPassword.scss'
import Logo from "../../../assets/img/logo-taptalk-white.svg";
import PaperPlaneLeft from "../../../assets/img/auth/img-paper-plane-left.svg";
import PaperPlaneRight from "../../../assets/img/auth/img-paper-plane-right.svg";
import IconFacebookMessenger from "../../../assets/img/auth/icon-fb-messenger-circle.svg";
import IconGoogle from "../../../assets/img/auth/icon-google-circle.svg";
import IconGoogleBusinessProfile from "../../../assets/img/auth/icon-gb-profile-circle.svg";
import IconInstagram from "../../../assets/img/auth/icon-instagram-circle.svg";
import IconMail from "../../../assets/img/auth/icon-mail-circle.svg";
import IconTelegram from "../../../assets/img/auth/icon-telegram-circle.svg";
import IconTokopedia from "../../../assets/img/auth/icon-tokopedia-circle.svg";
import IconWhatsAppSME from "../../../assets/img/auth/icon-whatsapp-circle.svg";
import IconWhatsAppBA from "../../../assets/img/auth/icon-whatsapp-ba-circle.svg";
import { Link, useHistory } from "react-router-dom";
// import { Modal, ModalBody, ModalFooter } from 'reactstrap';
// import { FaCheckCircle } from 'react-icons/fa'
import { FiAlertCircle, FiArrowLeft } from 'react-icons/fi'
import ServiceResetPassword from '../../../services/auth/ServiceResetPassword';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

const style = {
  boldOrange: {
    fontWeight: "bold",
    color: "#ff7e00",
    cursor: "pointer"
  },
  chevronLeft: {
    position: "relative",
    marginRight: '2px',
    top: '-1px'
  }
};

const   ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [failStateMessage, setFailStateMessage] = useState("")
  // const [isModalEmailSent, setIsModalEmailSent] = useState(false)
  const history = useHistory();
  const [isSendEmail, setIsSendEmail] = useState(false)

  const handleChange = (event) => {
    setEmail(event.target.value)
  }
  
  useEffect(() => {
    checkAndShowEmailError();
  }, [email])

  const sendEmailClick = (e) => {
    e.preventDefault();

    if (getEmailFormErrorMessage()) {
      setFailStateMessage(getEmailFormErrorMessage);
      return;
    }

    setIsSendEmail(true);

    ServiceResetPassword.requestToken(email).then(response => {
      setIsSendEmail(false);

      if (response.dataResult.status === 200 && response.dataResult.data.success) {
        showEmailSentSuccess();
      }
      else {
        // setFailStateMessage(response.dataResult.error.message)
        showEmailSentError();
      }
    })
  }

  const getEmailFormErrorMessage = () => {
    if (!email || email.length <= 0) {
      return "This field is required";
    }
    else if (!email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return "Invalid email address. Please try another email";
    }
    return "";
  }

  const checkAndShowEmailError = () => {
    if (failStateMessage) {
      setFailStateMessage(getEmailFormErrorMessage);
    }
  }

  // const backToLogin = () => {
  //   history.push('/')
  // }

  const showEmailSentSuccess = () => {
    history.push({
      pathname: '/verify/forgot-password',
      state: {}
    });
  }

  const showEmailSentError = () => {
    history.push({
      pathname: '/verify/forgot-password',
      state: {
        isError: true
      }
    });
  }

  // const modalResetPasswordEmailSent = () => {
  //   return (
  //     <Modal isOpen={isModalEmailSent} className="modal-reset-password-email-sent">
  //       <ModalBody>
  //         <p>
  //           <FaCheckCircle />
  //           Instructions Sent to Email
  //         </p>
  //         <p>
  //           Please follow the instructions that have been sent to the registered account  <b>{email}</b>.
  //         </p>
  //       </ModalBody>
  //       <ModalFooter>
  //         <button className="orange-button" onClick={backToLogin}>
  //           <FiArrowLeft />
  //           <b>Back to Log In</b>
  //         </button>
  //       </ModalFooter>
  //     </Modal>
  //   )
  // }

  return (
    <div id="outer-container">
      <img alt="" src={PaperPlaneLeft} className="bg-img-paper-plane-left" />
      <img alt="" src={PaperPlaneRight} className="bg-img-paper-plane-right" />
      <img alt="" src={IconWhatsAppSME} className="bg-img-whatsapp-sme" />
      <img alt="" src={IconTelegram} className="bg-img-telegram" />
      <img alt="" src={IconGoogleBusinessProfile} className="bg-img-gb-profile" />
      <img alt="" src={IconGoogle} className="bg-img-google" />
      <img alt="" src={IconInstagram} className="bg-img-instagram" />
      <img alt="" src={IconFacebookMessenger} className="bg-img-fb-messenger" />
      <img alt="" src={IconMail} className="bg-img-mail" />
      <img alt="" src={IconTokopedia} className="bg-img-tokopedia" />
      <img alt="" src={IconWhatsAppBA} className="bg-img-whatsapp-ba" />
    
      <div className="wrapper-auth-v2">
        <img src={Logo} alt="TapTalk Logo" className="logo-image" />
        <p className="subtitle">Omnichannel Chat Platform</p>
        <div className="wrapper-form-center">
          <div className="content-wrapper">

            {/* {modalResetPasswordEmailSent()} */}

            <div className="header-centered-content">
              <div className="content-title">
                  <b>Forgot Password</b>
              </div>

              <div className="content-subtitle">
                Please enter your registered email and we'll send you a link to reset your password.
              </div>
            </div>

            <form onSubmit={sendEmailClick}>
              <div className={`input-wrapper${isSendEmail ? `-loading`: ``}`}>
                <label><b>Email</b></label>
                <input
                  type="text"
                  id="email"
                  onChange={handleChange}
                  placeholder="your@email.com"
                  autoComplete="false"
                  value={email}
                  className={failStateMessage ? 'fail-state-input' : ''}
                  disabled={isSendEmail}
                />
                {
                  failStateMessage &&
                  <div className="input-error-info">
                    <FiAlertCircle />
                    <b>{failStateMessage}</b> 
                  </div>
                }
              </div>
              
              {
                !isSendEmail ?
                <ButtonWithLoadingOrIcon
                  onClick={(e) => sendEmailClick(e)}
                  className="orange-button main-button-48 width-100"
                  text="Send Email"
                />
                :
                <ButtonWithLoadingOrIcon 
                  isLoading
                  loadingGray
                  className="gray-button-disabled main-button-48 width-100"
                  text="Send Email"
                  position="left"
                  disabled={true}
                />
              }
            </form>

            {
                isSendEmail ?
                <div id="back-to-log-in-loading">
                    <FiArrowLeft style={style.chevronLeft} /> Back to Log in
                </div>
                :
                <div id="back-to-log-in">
                  <Link to={`/login${window.location.search}`} style={style.boldOrange}>
                    <FiArrowLeft style={style.chevronLeft} /> Back to Log In
                  </Link>
                </div>
            }

          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
