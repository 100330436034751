import React, { useState, useEffect, useRef } from 'react';
import './ChatRoomProductDetailModal.scss';
import { FiX, FiCopy } from "react-icons/fi";
import { setSalestalkProductDetail } from "../../../redux/actions/reduxActionSalestalkProductDetail";
import { setSalestalkSelectedProduct } from "../../../redux/actions/reduxActionSalestalkSelectedProduct";
import { Scrollbars } from 'react-custom-scrollbars';
import { rupiahFormat, isEmptyObject, doToast } from '../../../helper/HelperGeneral';
import Helper from "../../../helper/HelperChat";
import { connect } from 'react-redux';

var ChatRoomProductDetailModal = (props) => {
    let [byPassReadMore, setByPassReadMore] = useState(false);
    let [lineCounter, setLineCounter] = useState(0);
    let descRef = useRef(null)

    useEffect(() => {
        setByPassReadMore(false);

        if(props.salesTalkProductDetail.isShow) {
            setTimeout(() => {
                countLines()
            }, 0)
            
        }
    }, [props.salesTalkProductDetail.isShow])

    let onClickReadMore = () => {
        setByPassReadMore(true);
    }

    let closeProductDetail = () => {
        let _salesTalkProductDetail = {...props.salesTalkProductDetail};
        _salesTalkProductDetail.isShow = false;
        _salesTalkProductDetail.data = {};

        props.setSalestalkProductDetail(_salesTalkProductDetail);

        if(props.salesTalkSelectedProduct.isFromSelectedModal) {
            let _salesTalkSelectedProduct = {...props.salesTalkSelectedProduct};
            _salesTalkSelectedProduct.isShow = true;
            _salesTalkSelectedProduct.isFromSelectedModal = false;
            props.setSalestalkSelectedProduct(_salesTalkSelectedProduct); 
        }
    }

    let style = {
        scrollStyle: {
            position: "relative",
            backgroundColor: "#ff7d00",
            right: "-4px",
            borderRadius: "8px",
            width: "4px"
        }
    };

    let copyToClip = (selector, text) => {
        document.getElementById(selector).select();
        document.execCommand("copy");
        doToast(text);
    };

    let countLines = () => {
        var el2 = document.getElementById('product-description-wrapper');
        var divHeight = el2.offsetHeight
        if(divHeight) {
            var lineHeight = parseInt(el2.style.lineHeight);
            var lines = divHeight / lineHeight;
            setLineCounter(lines);
        }
    }
  
    return (
      <div className={`chat-room-product-detail-modal-wrapper ${props.salesTalkProductDetail.isShow ? 'active-chat-room-product-detail-modal-wrapper' : ''}`}>
          <div className={`chat-room-product-detail-modal-content ${props.salesTalkProductDetail.isShow ? 'active-chat-room-product-detail-modal-content' : ''}`}>
            <div 
                className='close-modal-product-detail-wrapper' 
                onClick={() => {
                    closeProductDetail();
                }}
            >
                <FiX />
            </div>

            <div className='product-detail-modal-inner-wrapper'>
                {!isEmptyObject(props.salesTalkProductDetail.data) &&
                    <>
                        <div className='product-title'>
                            <p>
                                <b>{props.salesTalkProductDetail.data.name}</b>
                            </p>
                        </div>

                        <div className='product-detail'>
                            <Scrollbars 
                                autoHide 
                                autoHideTimeout={500}
                                renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
                            >   
                                <div className='product-detail-inner'>
                                    <div className='image-wrapper'>
                                        <img src={props.salesTalkProductDetail.data.imageURL} alt="" />
                                    </div>

                                    {/* product */}
                                    <div className='main-product-detail-wrapper'>
                                        <label>
                                            <b>Price</b>
                                        </label>

                                        <div className='value-wrapper'>
                                            {rupiahFormat(props.salesTalkProductDetail.data.prices[0].unitPrice)}
                                        </div>

                                        <label>
                                            <b>Description</b>
                                        </label>

                                        <div 
                                            className={`
                                                value-wrapper 
                                                ${(lineCounter > 5.5 && !byPassReadMore) ? "read-more" : ""}
                                            `} 
                                            id="product-description-wrapper" 
                                            ref={descRef}
                                            style={{lineHeight: "16px"}}
                                        >   
                                            <p className="no-margin" dangerouslySetInnerHTML={{__html: Helper.lineBreakChatRoom(props.salesTalkProductDetail.data.description)}} />                                        
                                            {(lineCounter > 5.5 && !byPassReadMore) &&
                                                <div className="read-more-wrapper" onClick={onClickReadMore}>
                                                    <b>Read More</b>
                                                </div>
                                            }
                                        </div>

                                        <label>
                                            <b>Stock Available</b>
                                        </label>

                                        <div className='value-wrapper'>
                                            {props.salesTalkProductDetail.data.stock}
                                        </div>

                                        <label>
                                            <b>Category</b>
                                        </label>

                                        <div className='value-wrapper'>
                                            {props.salesTalkProductDetail.data.category.name === "" ? "-" : props.salesTalkProductDetail.data.category.name}
                                        </div>

                                        <label>
                                            <b>Product URL</b>
                                        </label>

                                        <div className='value-wrapper product-url'>
                                            <input type="text"
                                                value={props.salesTalkProductDetail.data.productURL}
                                                // onClick={copyToClip}
                                                id="copy-product-url"
                                                readOnly
                                            /> 

                                            <span className='copy-url'  onClick={() => {copyToClip("copy-product-url", "Product URL copied")}}>
                                                <FiCopy />
                                                <b>Copy</b>
                                            </span>
                                        </div>
                                    </div>
                                    {/* product */}
                                </div>
                            </Scrollbars>
                        </div>
                    </>
                }
            </div>
          </div>
      </div>
    );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  salesTalkProductDetail: state.salesTalkProductDetail,
  salesTalkSelectedProduct: state.salesTalkSelectedProduct,
});

const mapDispatchToProps = {
    setSalestalkProductDetail,
    setSalestalkSelectedProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomProductDetailModal);
