const INITIAL_STATE = {};

let reduxReducerPriceList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_PRICE_LIST':
        return action.priceList;
      case 'CLEAR_PRICE_LIST':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerPriceList;