export const MESSAGE_TYPE = {
  text: "text",
  image: "image",
  file: "file",
};

export const MESSAGE_STATUS = {
  not_sent: {
    value: "not_sent",
    color: "#FF3F57",
  },
  sent: {
    value: "sent",
    color: "#19C700",
  },
  pending: {
    value: "pending",
    color: "#FF9E40",
  },
  read: {
    value: "read",
    color: "#3BAEFF",
  },
  delivered: {
    value: "delivered",
    color: "#FF7E00;",
  },
  replied: {
    value: "replied",
    color: "#FF7E00;",
  },
};

export const BROADCAST_HEADER_FORMAT = {
  text: "text",
  image: "image"
}

export const TAPLIVE_MEDIUM = {
  facebook: "facebook",
  launcher: "launcher",
  line: "line",
  telegram: "telegram",
  twitter: "twitter",
  whatsapp: "whatsapp",
  whatsappba: "whatsappba",
}

export const FAIL_SEND_MESSAGE_REASON = {
  invalid_recipient: "invalid_recipient",
  insufficient_balance: "insufficient_balance"
}

