import React, { useEffect, useState } from "react";
import "./BroadcastMessageFormFileMessageNew.scss"
import { Modal, ModalBody } from "reactstrap";
import { FiX, FiPlus, FiFile } from "react-icons/fi";
// import { runImageCompress } from "../../../../helper/HelperGeneral";
import HelperUpload from "../../../../helper/HelperUpload";
import { doToast } from "../../../../helper/HelperGeneral";
import { WHATSAPP_FILE_SIZE_LIMIT } from "../../../../constants/taplive";
import { taptalk, tapCoreMessageManager } from "@taptalk.io/web-sdk";
import ChatService from '../../../../services/chat/caseServices';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

let BroadcastMessageFormFileMessageNew = (props) => {
    let [filePreview, setFilePreview] = useState([]);
    let [percentageUpload, setPercentageUpload] = useState(false);
    let [showModalImagePreview, setShowModalImagePreview] = useState(false);
    let [finishUpload, setFinishUpload] = useState(false);

    useEffect(() => {
      let _fileData = props.broadCastMessageDataProps.messages[props._index].fileData;
      
      if(_fileData) {
        let _filePreview = [];
        _filePreview.push(_fileData);
        setFilePreview(_filePreview);
      }
    }, [props.broadCastMessageDataProps])

    let toggleModalImagePreview = () => {
      setShowModalImagePreview(!showModalImagePreview);
    }

    let doUpload = (file) => {
      let uploadData = {
        file: file,
        caption: props.broadCastMessageDataProps.messages[0].caption,
        room: ""
      };

      tapCoreMessageManager.uploadChatFile(uploadData, {
        onProgress: (percentage, bytes) => {
          setPercentageUpload(percentage);

          if(percentage === 100) {
            setPercentageUpload(false);
            setFinishUpload(true);
          }
        },

        onSuccess: (response) => {
            if(response) {
                let newFileData = {};
                let arrayFileData = [];
                arrayFileData.push(file);
                // newFileData.fileData = arrayFileData;
                // newFileData.fileName = file.name;
                // newFileData.fileURL = response.fileURL;
                props.onChangeAssetFileProps(newFileData, props._index, response.fileURL);
            }
        },

        onError: (error) => {
          doToast(error, "fail");
          console.log(error);
        }
      })
    }
    
    let taptalkAuthTicket = (file) => {
      ChatService.postRequestAuthTicket(props.orgIDProps, (response) => {
        if (response.dataResult.error.code === "") {
          taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
            onSuccess: () => {
              doUpload(file);
            },
            onError: (errorCode, errorMessage) => {
              doToast(errorMessage, 'fail')
              console.log(errorCode, errorMessage);
            }
          })
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      })
    }

    let runTaptalkRequestAuthTicket = (file) => {
      ChatService.postProjectConfig(props.orgIDProps, (response) => {
        let data = response.dataResult.data.tapTalk;
  
        if (response.dataResult.error.code === "") {
          taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
          taptalkAuthTicket(file);
        } else {
          doToast(response.dataResult.error.message, "fail");
        }
      });
    }

    let runUploadImage = (file) => {
      setPercentageUpload(1);
      
      if(props.withoutRequestAuthTicketProps) {
        doUpload(file);
      }else {
        runTaptalkRequestAuthTicket(file);
      }
    }

    let onChangeAssetFile = (e) => {
        setFinishUpload(false);
        let files = [];
        files.push(e.target.files[0]);

        HelperUpload.checkFileAllowed(files, {
            onAllowed: () => {
              HelperUpload.checkFileLimit(files, {
                onAllowed: () => {
                  HelperUpload.checkFileSizeLimitForMedia([], files, {
                    onAllowed: (arrayOfMedia) => {
                        // setFilePreview(arrayOfMedia);
                        props.onChangeAssetFileProps({
                          fileData: arrayOfMedia[0],
                          body: "",
                          type: "file",
                          filename: arrayOfMedia[0].name,
                          caption: ""
                        }, props._index);

                        // runImageCompress(arrayOfMedia[0], (res) => {
                        //    runUploadImage(res);
                        // })
                        runUploadImage(arrayOfMedia[0])
                    },
                    onReachLimit: (message) => {
                      doToast(message, "fail");
                    }
                  }, WHATSAPP_FILE_SIZE_LIMIT.document(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))
                },
                onReachLimit: (message) => {
                  doToast(message, "fail");
                }
              })
            },
            onFileTypeDidntMatch: (message) => {
              doToast(message, "fail");
            }
        }, "all")
    
        e.target.value = null;
    }

    let renderModalImageDetail = () => {
      return (
        <Modal className="modal-file-preview" toggle={toggleModalImagePreview} isOpen={showModalImagePreview}>
          <ModalBody>
            <FiX onClick={toggleModalImagePreview} />
            {filePreview.length > 0 && <img src={filePreview[0].src} alt="" />}
          </ModalBody>
        </Modal>
      )
    }

    let removeFileAsset = () => {
      props.onRemoveFileAssetProps(props._index);
      setFilePreview([]);
    }

    return (
        <div className="form-file-message-wrapper-new">
            {renderModalImageDetail()}
            <label><b>File</b></label>

            <br />

            <div className="file-and-caption-wrapper">
                {/* {filePreview.length > 0 &&
                  <div className="file-preview-wrapper">
                      {filePreview.length === 0 ?
                          <FiFile />
                          :
                          <div className="file-preview-frame">
                              <div className="file-progress-upload-wrapper">
                                {percentageUpload ?
                                  <div className="progress-wrapper">
                                    <CircularProgressbar value={percentageUpload} />
                                  </div>
                                  :
                                  finishUpload && <FiFile />
                                }

                                {filePreview[0].name && filePreview[0].name}
                                
                                {finishUpload && <FiX className="remove-file" onClick={removeFileAsset} />}
                              </div>
                          </div>
                      }
                  </div>
                } */}

                {/* <textarea 
                  value={props.broadCastMessageDataProps.fileAsset.caption}
                  maxLength="4000"
                  placeholder="Enter caption here..." 
                  id="caption"
                  onChange={(e) => props.onChangeBroadcastMessageProps(e)}
                /> */}
                {/* <div className="placeholder-file">
                  Select your file
                </div>

                <div className="broadcast-message-action-wrapper">
                  <label htmlFor={`upload-file-${props._index}`} className="button-upload-file">
                    <FiFile />
                    <b>Upload File</b>
                  </label>
                  <input type="file" id={`upload-file-${props._index}`} onChange={(e) => onChangeAssetFile(e)} />
                </div> */}
                {filePreview.length === 0 ?
                  <>
                    <label className="label-upload-file" htmlFor={`upload-file-${props._index}`}>
                      <FiPlus />
                      <b>Upload File</b>
                    </label>

                    <input type="file" id={`upload-file-${props._index}`} onChange={(e) => onChangeAssetFile(e)} />
                  </>
                  :
                  <div className="file-result-wrapper">
                    {percentageUpload ? 
                      <div className="progress-wrapper">
                        <CircularProgressbar value={percentageUpload} />
                      </div>
                      :
                      (finishUpload || filePreview[0].body !== "") && <FiFile className="icon-file" />
                    }

                    {filePreview[0].name && filePreview[0].name} {(finishUpload || filePreview[0].body !== "") && <b className="remove-file" onClick={removeFileAsset}><FiX /> Remove</b>}
                  </div>
                }
            </div>
        </div>
    )
}

export default BroadcastMessageFormFileMessageNew;