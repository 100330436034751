export const CHATBOT = {
  "taptalk/scf": {
    value: "taptalk/scf",
    label: "QnA Chatbot"
  },
  "taptalk/custom": {
    value: "taptalk/custom",
    label: "Custom Chatbot"
  }
}

export const CHATBOT_TYPE = [
  {
    value: CHATBOT["taptalk/scf"].value,
    label: CHATBOT["taptalk/scf"].label
  },
  {
    value: CHATBOT["taptalk/custom"].value,
    label: CHATBOT["taptalk/custom"].label
  },
];