const INITIAL_STATE = {
    isShow: false,
};
  
let ReduxReducerSalesTalkInbox = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SET_SALES_TALK_INBOX':
        return action.salesTalkInbox;
    case 'CLEAR_SALES_TALK_INBOX':
        return INITIAL_STATE;
    default:
        return state;
    }
};
  
export default ReduxReducerSalesTalkInbox;