import React, { useState, useEffect } from 'react';
import './AddUserContact.scss'
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from '../../reuseableComponent/BackButton/BackButton';
import { FiUser, FiInfo, FiAlertCircle, FiPlus, FiCalendar } from 'react-icons/fi'
import ServiceOrganization from '../../../services/organization/ServiceOrganization'
import { checkID, doToast, scrollToClass } from '../../../helper/HelperGeneral';
import HelperInput from '../../../helper/HelperInput';
import HelperDate from '../../../helper/HelperDate';
import UserContactServices from '../../../services/newServices/UserContactServices'
import ContactCustomFieldServices from '../../../services/newServices/ContactCustomFieldServices';
import PopupRemove from '../../reuseableComponent/popupRemove/PopupRemove';
import BlockingDiscardChanges from '../../reuseableComponent/blockingDiscardChanges/BlockingDiscardChanges'
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import CustomSelectOption from '../../reuseableComponent/customSelectOption/CustomSelectOption';
import CalendarPickerSingle from '../../reuseableComponent/calendarPickerSingle/CalendarPickerSingle';
import { 
  FIELD_TYPE_SINGLE_LINE_TEXT,
  FIELD_TYPE_MULTILINE_TEXT,
  FIELD_TYPE_DROPDOWN,
  FIELD_TYPE_MULTIPLE_SELECT,
  FIELD_TYPE_DATE,
  FIELD_TYPE_NUMBER,
  VALIDATION_FREE_TEXT,
  VALIDATION_NUMBER,
  VALIDATION_NUMERIC,
  VALIDATION_ALPHANUMERIC,
  VALIDATION_TODAY_OR_LATER_DATES,
  VALIDATION_TODAY_OR_EARLIER_DATES,
  ERROR_MESSAGE_FIELD_REQUIRED,
  ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT,
} from '../../setup/contactInfoCustomField/ContactInfoCustomField';


const AddUserContact = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false)
  const [errorEmailPhone, setErrorEmailPhone] = useState(false)
  const [isLoadingAddContact, setIsLoadingAddContact] = useState(false)
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [fullname, setFullname] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [alias, setAlias] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [fullnameOrigin, setFullnameOrigin] = useState('')
  const [phoneNumberOrigin, setPhoneNumberOrigin] = useState('')
  const [aliasOrigin, setAliasOrigin] = useState('')
  const [emailOrigin, setEmailOrigin] = useState('')
  // const [companyNameOrigin, setCompanyNameOrigin] = useState('')
  // const [jobTitleOrigin, setJobTitleOrigin] = useState('')
  const [isShowModalDiscard, setIsShowModalDiscard] = useState(false)
  const [errorMessageData, setErrorMessageData] = useState({
    fullname: "",
    alias: "",
    company: "",
    job: "",
    email: "",
    phone: ""
  }) 
  const [customFields, setCustomFields] = useState([]);
  const [customFieldValues, setCustomFieldValues] = useState({});
  const [customFieldValuesOrigin, setCustomFieldValuesOrigin] = useState({});
  const [customFieldErrors, setCustomFieldErrors] = useState({});
  const [currentDateSelections, setCurrentDateSelections] = useState({});
  const [showingDatePickerFieldCode, setShowingDatePickerFieldCode] = useState(false);
  const [datePickerDisabledDays, setDatePickerDisabledDays] = useState({});

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount();

    ContactCustomFieldServices.getContactCustomFieldList(orgID, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.error.message === '') {
        setCustomFields(dataResult.data.customFields);
      }
    });
  }, []);

  useEffect(() => {
    if (props.history.location.state && props.history.location.state.length > 0) {
      setCustomFields(props.history.location.state);
    }
  }, [props.history]);

  useEffect(() => {
    if (customFields && customFields.length > 0) {
      let _customFieldValues = {...customFieldValues};
      let _customFieldErrors = {...customFieldErrors};
      customFields.map((customField) => {
        if (!_customFieldValues[customField.fieldCode]) {
          if (customField.fieldType === FIELD_TYPE_DROPDOWN || customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT) {
            _customFieldValues[customField.fieldCode] = {
              value: "",
              label: "",
              customField: customField,
            };
          }
          else {
            _customFieldValues[customField.fieldCode] = "";
          }
        }
        if (!_customFieldErrors[customField.fieldCode]) {
          _customFieldErrors[customField.fieldCode] = "";
        }
        return null;
      });
      setCustomFieldValues(_customFieldValues);
      setCustomFieldValuesOrigin(JSON.parse(JSON.stringify(_customFieldValues)));
      setCustomFieldErrors(_customFieldErrors);
    }
  }, [customFields]);

  let toggleDatePicker = () => {
    let _currentDateSelections = {...currentDateSelections};
    _currentDateSelections[showingDatePickerFieldCode] = customFieldValues[showingDatePickerFieldCode];
    setShowingDatePickerFieldCode(false);
  }

  let handleDateFilterClick = (d, {disabled}) => {
    if (disabled || !showingDatePickerFieldCode) {
      return;
    }

    let _currentDateSelections = {...currentDateSelections};
    _currentDateSelections[showingDatePickerFieldCode] = d;
    setCurrentDateSelections(_currentDateSelections);
  }

  let submitDateFilter = () => {
    if (!showingDatePickerFieldCode) {
      return;
    }

    let _customFieldValues = {...customFieldValues};
    _customFieldValues[showingDatePickerFieldCode] = currentDateSelections[showingDatePickerFieldCode];
    setCustomFieldValues(_customFieldValues);

    let _customFieldErrors = {...customFieldErrors};
    _customFieldErrors[showingDatePickerFieldCode] = "";
    setCustomFieldErrors(_customFieldErrors);

    toggleDatePicker(false);
  }
  
  let clearDatePicker = () => {
    if (!showingDatePickerFieldCode) {
      return;
    }

    let _customFieldValues = {...customFieldValues};
    _customFieldValues[showingDatePickerFieldCode] = "";
    setCustomFieldValues(_customFieldValues);

    let _currentDateSelections = {...currentDateSelections};
    _currentDateSelections[showingDatePickerFieldCode] = "";
    setCurrentDateSelections(_currentDateSelections);

    setShowingDatePickerFieldCode(false);
  }

  const createContactUser = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(fullname === "") {
      _errorMessageData.fullname = "This field is required";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-fullname" }
    }

    if(fullname.length > 200) {
      _errorMessageData.fullname = "Characters exceed limit";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-fullname" }
    }

    if(alias.length > 100) {
      _errorMessageData.alias = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-alias" }
    }

    if(companyName.length > 200) {
      _errorMessageData.company = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-company" }
    }

    if(jobTitle.length > 50) {
      _errorMessageData.job = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-job" }
    }

    if(email.length > 250) {
      _errorMessageData.email = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-email" }
    }

    if(phoneNumber.length === 0) {
      _errorMessageData.phone = "This field is required";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-phone" }
    }

    if(phoneNumber.replaceAll(" ", "").length > 15) {
      _errorMessageData.phone = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-phone" }
    }

    let customFieldRequest = [];
    if (customFields && customFields.length > 0) {  
      let _customFieldErrors = {...customFieldErrors};
      customFields.map((customField, index) => {
        let multipleSelectValue = "[]";
        if (customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT &&
            customFieldValues[customField.fieldCode] &&
            customFieldValues[customField.fieldCode].array &&
            customFieldValues[customField.fieldCode].array.length > 0
        ) {
          customFieldValues[customField.fieldCode].array.map((v, i) => {
            multipleSelectValue = multipleSelectValue.slice(0, multipleSelectValue.length - 1) + (i === 0 ? `"` : `,"`) + v + `"` + multipleSelectValue.slice(multipleSelectValue.length - 1);
            return null;
          });
        }
        customFieldRequest.push({
          fieldID: customField.id,
          value:
            customField.fieldType === FIELD_TYPE_DATE
            ?
            customFieldValues[customField.fieldCode] ? HelperDate.formatToString(new Date(customFieldValues[customField.fieldCode]), "yyyy-MM-dd") : ""
            :
            customField.fieldType === FIELD_TYPE_DROPDOWN
            ?
            (customFieldValues[customField.fieldCode] && customFieldValues[customField.fieldCode].value) ? customFieldValues[customField.fieldCode].value : ""
            :
            customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT
            ?
            multipleSelectValue
            //(customFieldValues[customField.fieldCode] && customFieldValues[customField.fieldCode].array) ? ('' + customFieldValues[customField.fieldCode].array) : "[]"
            :
            '' + customFieldValues[customField.fieldCode]
        });
        let customFieldErrorMessage = checkCustomFieldError(customField, customFieldValues[customField.fieldCode], true);
        _customFieldErrors[customField.fieldCode] = customFieldErrorMessage;
        if (customFieldErrorMessage) {
          errorCount = errorCount + 1;
          if (errorClass === "") { 
            errorClass = `custom-field-input-${index}`;
          }
        }
        return null;
      });
      setCustomFieldErrors(_customFieldErrors);
    }

    if (errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }
    else {
      setIsLoadingAddContact(true);
      setCondition(false)
      const dataUser = {
        fullName: fullname,
        alias: alias,
        email: email,
        phone: phoneNumber.replaceAll(" ", ""),
        companyName: companyName,
        jobTitle: jobTitle,
        customFields: customFieldRequest
      }

      UserContactServices.createContact(orgID, dataUser, (response) => {
        setIsLoadingAddContact(false);

        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message, "fail");
          }
          else {
            setFullnameOrigin(fullname);
            setEmailOrigin(email);
            setPhoneNumberOrigin(phoneNumber); 
            setAliasOrigin(alias);
            // setCompanyNameOrigin(companyName);
            // setJobTitleOrigin(jobTitle);
            setCustomFieldValuesOrigin(customFieldValues);
            setTimeout(() => {
              history.push(
                `/o/${orgID}/operational/user-contact/${response.dataResult.data.user.userID}`
              );
            }, 0);
            doToast(response.dataResult.data.message);
          }

        } else {
          doToast(response.dataResult.error.message, 'fail');
          if (response.dataResult.error.field.includes("customFields")) {
            const errorField = response.dataResult.error.field.replaceAll("customFields.", "");
            let _customFieldErrors = {...customFieldErrors};
            _customFieldErrors[errorField] = response.dataResult.error.message;
            setCustomFieldErrors(_customFieldErrors);
          }
        }
      });
    }
  };

  const checkCustomFieldError = (customField, value, checkRequired) => {
    let errorMessage = "";
    if (checkRequired &&
        customField.isRequired &&
        (!value || ((customField.fieldType === FIELD_TYPE_DROPDOWN || customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT) && !value.value))
    ) {
      errorMessage = ERROR_MESSAGE_FIELD_REQUIRED;
    }
    else if (customField.validation === VALIDATION_FREE_TEXT && value && /\p{Extended_Pictographic}/u.test(value)) {
      errorMessage = "Text may not contain emoji";
    }
    else if (customField.validation === VALIDATION_NUMBER && value && (/[^\d-]/g.test(value) || value.lastIndexOf('-') > 0 || (checkRequired && value === '-'))) {
      errorMessage = "You can only fill this field with positive or negative number";
    }
    else if (customField.validation === VALIDATION_NUMERIC && value && /[^\d]/g.test(value)) {
      errorMessage = "You can only fill this field with numbers";
    }
    else if (customField.validation === VALIDATION_ALPHANUMERIC && value && !(/^[a-zA-Z0-9]*$/).test(value)) {
      errorMessage = "You can only fill this field with alphabets and numbers with no space";
    }
    else if (customField.maxCharacters > 0 && value && value.length > customField.maxCharacters) {
      errorMessage = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
    return errorMessage;
  }

  const back = () =>
    history.push(`/o/${orgID}/operational/user-contact`);

  const [borderPhone, setBorderPhone] = useState('1px solid #dcdcdc')

  const handlePhoneFocus = () => {
    setPhoneFocus(true);
  }

  const handleBlurPhone = () => {
    setPhoneFocus(false);
  }

  useEffect(() => {
    if (phoneFocus) {
      if (errorPhoneNumber || errorEmailPhone) {
        setBorderPhone('1px solid #FF3F57')
      } else {
        setBorderPhone('1px solid #ff7e00')
      }
    } else {
      setBorderPhone('1px solid #dcdcdc')
    }
  }, [phoneFocus, errorPhoneNumber, errorEmailPhone])

  const validateEmail = (e) => {
    // setErrorEmail(true);
    setEmail(e.target.value)
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (e.target.value === '') {
      if (phoneNumber === '') {
        setErrorEmailPhone(true)
        setErrorEmail(false)
      } else { // phoneNumber !== ''
        setErrorEmailPhone(false);
        setErrorEmail(false)
      }
    } else { // e.target.value !== ''
      setErrorEmailPhone(false);
      if (re.test(String(e.target.value).toLowerCase())) {
        setErrorEmail(false)
      } else {
        setErrorEmail(true)
      }
    }

    let _errorMessageData = {...errorMessageData};
    if (e.target.value && String(e.target.value).length > 250) {
      _errorMessageData.email = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
    else {
      _errorMessageData.email = "";
    }
    setErrorMessageData(_errorMessageData);
  }

  const validatePhoneNumber = (e) => {
    // setErrorPhoneNumber(true);

    if(HelperInput.numberOnly(e.target.value.replaceAll(" ", ""))) {
      setPhoneNumber(HelperInput.phoneBeautify(e.target.value.replaceAll(" ", "")));
    }
    
    const val = /^[0-9]*$/

    if (e.target.value === '' && email === '') {
      setErrorEmailPhone(true)
      setErrorPhoneNumber(false)
    } else {
      setErrorEmailPhone(false)
      if (val.test(e.target.value.replaceAll(" ", ""))) {
        setErrorPhoneNumber(false)
      } else {
        setErrorPhoneNumber(true)
      }
    }

    let _errorMessageData = {...errorMessageData};
    if (e.target.value && e.target.value.replaceAll(" ", "").length > 15) {
      _errorMessageData.phone = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
    else {
      _errorMessageData.phone = "";
    }
    setErrorMessageData(_errorMessageData);
  }

  const handleFullname = (e) => {
    let _errorMessageData = {...errorMessageData};
    if (e.target.value && e.target.value.length > 200) {
      _errorMessageData.fullname = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
    else {
      _errorMessageData.fullname = "";
    }
    setErrorMessageData(_errorMessageData);
    setFullname(e.target.value)
  }

  const handleAlias = (e) => {
    let _errorMessageData = {...errorMessageData};
    if (e.target.value && e.target.value.length > 100) {
      _errorMessageData.alias = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
    else {
      _errorMessageData.alias = "";
    }
    setErrorMessageData(_errorMessageData);
    setAlias(e.target.value)
  }

  const handleCompanyName = (e) => {
    let _errorMessageData = {...errorMessageData};
    if (e.target.value && e.target.value.length > 200) {
      _errorMessageData.company = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
    else {
      _errorMessageData.company = "";
    }
    setErrorMessageData(_errorMessageData);
    setCompanyName(e.target.value)
  }

  const handleJobTitle = (e) => {
    let _errorMessageData = {...errorMessageData};
    if (e.target.value && e.target.value.length > 50) {
      _errorMessageData.job = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
    }
    else {
      _errorMessageData.job = "";
    }
    setErrorMessageData(_errorMessageData);
    setJobTitle(e.target.value)
  }

  // useEffect(() => {
  //   if (fullname === "" || email === "" || phoneNumber === "" || errorEmail || errorPhoneNumber) {
  //     setIsButtonDisabled(true)
  //   } else {
  //     setIsButtonDisabled(false)
  //   }
  // }, [fullname, email, phoneNumber, errorEmail, errorPhoneNumber])

  const onCustomFieldTextChanged = (e, customField) => {
    let _customFieldValues = {...customFieldValues};
    _customFieldValues[customField.fieldCode] = e.target.value;
    setCustomFieldValues(_customFieldValues);

    let _customFieldErrors = {...customFieldErrors};
    _customFieldErrors[customField.fieldCode] = checkCustomFieldError(customField, e.target.value);
    setCustomFieldErrors(_customFieldErrors);
  }

  const onCustomFieldDropdownChanged = (option) => {
    let _customFieldValues = {...customFieldValues};
    let _customFieldErrors = {...customFieldErrors};
    if (!option.customField.fieldCode ||
        (customFieldValues[option.customField.fieldCode] && 
         customFieldValues[option.customField.fieldCode].value === option.value)
    ) {
      let deselectOption = {
        value: "",
        label: "",
        customField: option.customField
      };
      _customFieldValues[option.customField.fieldCode] = deselectOption;
    }
    else {
      let selectedOption = {
        value: option.value,
        label: option.label,
        customField: option.customField
      };
      _customFieldValues[option.customField.fieldCode] = selectedOption;
      _customFieldErrors[option.customField.fieldCode] = "";
    }
    setCustomFieldValues(_customFieldValues);
    setCustomFieldErrors(_customFieldErrors);
  }

  const onCustomFieldMultipleSelectChanged = (option) => {
    if (!option.customField.fieldCode) {
      return;
    }

    let _customFieldValues = {...customFieldValues};
    
    let selectedOption = {
      value: option.value,
      label: option.label,
      array: [],
      customField: option.customField
    };
    let currentArray = _customFieldValues[option.customField.fieldCode].array;
    if (!currentArray) {
      currentArray = [];
    }
    if (currentArray.includes(option.label)) {
      // Remove selected option
      const currentIndex = currentArray.indexOf(option.label);
      currentArray.splice(currentIndex, 1);
    }
    else {
      // Add selected option
      currentArray.push(option.label);
    }
    selectedOption.array = currentArray;
    const joinedLabel = currentArray.map( (e, i) => (e)).join(", ");
    selectedOption.label = joinedLabel;
    selectedOption.value = joinedLabel;
    _customFieldValues[option.customField.fieldCode] = selectedOption;
    setCustomFieldValues(_customFieldValues);

    let _customFieldErrors = {...customFieldErrors};
    _customFieldErrors[option.customField.fieldCode] = "";
    setCustomFieldErrors(_customFieldErrors);
  }

  const toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  const discardProgress = () => {
    setIsShowModalDiscard(false)
    setConfirmedNavigation(true)
  }

  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [condition, setCondition] = useState(false)

  const handleOpenModal = (isOpen) => {
    setIsShowModalDiscard(isOpen)
  }

  useEffect(() => {
    if (fullname !== "" || alias !== "" || email !== "" || phoneNumber !== "") {
      setCondition(true)
    } else {
      setCondition(false)
    }
  }, [fullname, alias, email, phoneNumber])

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
      dataOrigin={{name: fullnameOrigin, email: emailOrigin, phone: phoneNumberOrigin, alias: aliasOrigin, customFields: customFieldValuesOrigin}}
      dataAfterChange={{name: fullname, email: email, phone: phoneNumber, alias: alias, customFields: customFieldValues}}
    >
      <BlockingDiscardChanges
        openModal={handleOpenModal}
        condition={condition}
        confirmedNavigation={confirmedNavigation}
      />
      <div className="sectionWrap integration-main-container add-user-contact-container text-left">
        <>
          <BackButton
            text={`Back to Contacts`}
            onClick={() => back()}
          />
          <div className="add-user-contact-box">
            <div className="add-user-contact-box-top">
              <div className="title-wrapper">
                <FiUser />
                <b>Add New Contact</b>
              </div>
              <p>Create a new user by inputting name and phone number.</p>
            </div>
          
            {
              isLoadingAddContact ?
              <ButtonWithLoadingOrIcon
                isLoading
                loadingColor="gray"
                isDisabled
                className="orange-button main-button-40 add-contact-button button-right"
                text="Add New Contact"
                position="left"
              />
              :
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40 add-contact-button button-right"
                text="Add New Contact"
                position="left"
                icon={{
                  type: "svg",
                  src: FiPlus
                }}
                onClickAction={createContactUser}
                // isDisabled={isButtonDisabled}
              />
            }

            <div className="add-user-contact-box-form">
              {
                errorEmailPhone &&
                <div className="alert-component alert-email-phone">
                  <FiInfo />
                  <b>
                    Either email address or phone number must be filled
                  </b>
                </div>
              }
              <div className="form-contact-fullname">
                <label className='pos-relative'>
                  <b>Full Name</b>

                  <span className={fullname.length > 200 ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{fullname.length}/200 characters</span>
                </label>
                <input
                  type="text"
                  value={fullname}
                  placeholder="Type full name here"
                  onChange={handleFullname}
                  disabled={isLoadingAddContact}
                  className={`form-fullname input-fullname ${errorMessageData.fullname !== "" ? "border-red" : ""}`}
                />
              </div>
              {errorMessageData.fullname !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.fullname}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-alias">
                <label className='pos-relative'>
                  <b>Alias<span> (optional)</span></b>

                  <span className={alias.length > 100 ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{alias.length}/100 characters</span>
                </label>
                <input
                  type="text"
                  value={alias}
                  onChange={handleAlias}
                  placeholder="Type alias name here"
                  className={`form-alias input-alias ${errorMessageData.alias !== "" ? "border-red" : ""}`}
                  disabled={isLoadingAddContact}
                />
              </div>
              {errorMessageData.alias !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.alias}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-alias">
                <label className='pos-relative'>
                  <b>Company<span> (optional)</span></b>

                  <span className={companyName.length > 200 ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{companyName.length}/200 characters</span>
                </label>
                <input
                  type="text"
                  value={companyName}
                  onChange={handleCompanyName}
                  placeholder="Type company name here"
                  className={`form-alias input-company ${errorMessageData.company !== "" ? "border-red" : ""}`}
                  disabled={isLoadingAddContact}
                />
              </div>
              {errorMessageData.company !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.company}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-alias">
                <label className='pos-relative'>
                  <b>Job Title<span> (optional)</span></b>

                  <span className={jobTitle.length > 50 ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{jobTitle.length}/50 characters</span>
                </label>
                <input
                  type="text"
                  value={jobTitle}
                  onChange={handleJobTitle}
                  placeholder="Type job title here"
                  className={`form-alias input-alias ${errorMessageData.job !== "" ? "border-red" : ""}`}
                  disabled={isLoadingAddContact}
                />
              </div>
              {errorMessageData.job !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.job}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-email">
                <label className='pos-relative'>
                  <b>Email</b>
                  <span className={email.length > 250 ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{email.length}/250 characters</span>
                </label>
                <input
                  type="text"
                  value={email}
                  placeholder="Type email address here"
                  className={`form-email ${errorEmail ? 'error-email' : ''} input-email ${errorMessageData.email !== "" ? "border-red" : ""}`}
                  onChange={validateEmail}
                  disabled={isLoadingAddContact}
                />
                {(errorMessageData.phone === "" && errorEmailPhone) &&
                  <div className="warning-error-red">
                    <FiAlertCircle />
                    <b>You cannot leave this field empty if “Phone Number” is also empty</b>
                  </div>
                }
                
                {(errorMessageData.phone === "" && errorEmail) &&
                  <div className="warning-error-red">
                    <FiAlertCircle />
                    <b>Invalid email address</b>
                  </div>
                }
              </div>
              {errorMessageData.email !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.email}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-phonenumber">
                <label className='pos-relative'>
                  <b>Phone Number</b>

                  <span className={phoneNumber.replaceAll(" ", "").length > 15 ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{phoneNumber.replaceAll(" ", "").length}/15 characters</span>
                </label>
                <div className={`input-phonenumber-container ${isLoadingAddContact ? "grey-box" : ""} ${isLoadingAddContact ? 'phone-disabled' : ''} ${errorMessageData.phone !== "" ? "border-red" : ""}`} style={{ border: borderPhone }}>
                  <label>+</label>
                  <input
                    type="text"
                    value={phoneNumber}
                    placeholder="62 8XXX XXX XXX"
                    className={`form-phonenumber input-phonenumber input-phone`}
                    onFocus={handlePhoneFocus}
                    onBlur={handleBlurPhone}
                    onChange={validatePhoneNumber}
                    disabled={isLoadingAddContact}
                  />
                </div>
                {
                  errorEmailPhone &&
                  <div className="warning-error-red">
                    <FiAlertCircle />
                    <b>You cannot leave this field empty if “Email” is also empty</b>
                  </div>
                }
                {
                  errorPhoneNumber &&
                  <div className="warning-error-red">
                    <FiAlertCircle />
                    <b>Invalid Number</b>
                  </div>
                }
                
                <ErrorOrInfoComp
                    text={(errorMessageData.phone && errorMessageData.phone !== ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT) ? errorMessageData.phone : "Always start the phone number with the country code without the “+” sign e.g. “628XXXXXXXXX”."}
                    _className={(errorMessageData.phone && errorMessageData.phone !== ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT) ? "font-red" : ""}
                    icon={<FiInfo />}
                />
              </div>

              {
                customFields &&
                customFields.length > 0 &&
                <>
                  <div className="contact-custom-field-separator" />
                  {
                    customFields.map((customField, index) => {
                      let mappedOptions = [];
                      if (customField.options && customField.options.length > 0) {
                        customField.options.map((option, index) => {
                          mappedOptions.push({
                            value: option,
                            label: option,
                            customField: customField,
                          });
                          return null;
                        });
                        if (!customFieldValues[customField.fieldCode]) {
                          customFieldValues[customField.fieldCode] = {
                            value: "",
                            label: "",
                            customField: customField
                          }
                        }
                      }

                      return (
                        <>
                          <div 
                            id={`custom-field-input-${index}`}
                            className={`form-contact-custom-field custom-field-input-${index}`}
                            key={`custom-field-wrapper-${index}`}
                          >
                            <label className='pos-relative'>
                              <b>{customField.fieldName}</b>
                              {
                                !customField.isRequired &&
                                <b><span> (optional)</span></b>
                              }
                              {
                                (customField.maxCharacters && customField.maxCharacters > 0) ?
                                <span className={
                                  (customFieldValues[customField.fieldCode] &&
                                  customFieldValues[customField.fieldCode].length > customField.maxCharacters) ?
                                  "red-text absolute-right-0"
                                  :
                                  "grey-font absolute-right-0"}
                                >
                                  {
                                    customFieldValues[customField.fieldCode] ?
                                    customFieldValues[customField.fieldCode].length :
                                    0
                                  }/{customField.maxCharacters} characters
                                </span>
                                :
                                <></>
                              }
                            </label>
                            {
                              (customField.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT || customField.fieldType === FIELD_TYPE_NUMBER) ?
                              <input
                                className={`${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                type="text"
                                value={customFieldValues[customField.fieldCode]}
                                onChange={(e) => onCustomFieldTextChanged(e, customField)}
                                placeholder={
                                  customField.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ? "Input single line text" :
                                  customField.fieldType === FIELD_TYPE_NUMBER ? "Input number" :
                                  "Input custom field"
                                }
                                disabled={isLoadingAddContact}
                              />
                              :
                              customField.fieldType === FIELD_TYPE_MULTILINE_TEXT ?
                              <textarea
                                className={`${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                rows="4"
                                value={customFieldValues[customField.fieldCode]}
                                placeholder="Input multiline text"
                                onChange={(e) => onCustomFieldTextChanged(e, customField)}
                                disabled={isLoadingAddContact}
                              />
                              :
                              (customField.fieldType === FIELD_TYPE_DROPDOWN && mappedOptions.length > 0) ?
                              <CustomSelectOption
                                optionListProps={mappedOptions}
                                valueDropdownProps={customFieldValues[customField.fieldCode]}
                                placeholderProps={"Select Option"}
                                onClickDropDownListOptionProps={onCustomFieldDropdownChanged}
                                specificClassNameProps={`custom-select-whatsapp-topic" ${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                isDisabled={isLoadingAddContact}
                                _className={`${errorMessageData.validation ? "border-red" : ""}`}
                              />
                              :
                              (customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT && mappedOptions.length > 0) ?
                              <CustomSelectOption
                                optionListProps={mappedOptions}
                                valueDropdownProps={customFieldValues[customField.fieldCode]}
                                placeholderProps={"Select Options"}
                                onClickDropDownListOptionProps={onCustomFieldMultipleSelectChanged}
                                specificClassNameProps={`custom-select-whatsapp-topic" ${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                isDisabled={isLoadingAddContact}
                                _className={`${errorMessageData.validation ? "border-red" : ""}`}
                                isMultipleSelect={true}
                              />
                              :
                              customField.fieldType === FIELD_TYPE_DATE ?
                              <div
                                className={`contact-date-value-wrapper ${customFieldErrors[customField.fieldCode] ? "border-red" : ""} ${isLoadingAddContact ? "disabled" : ""}`}
                                onClick={() => {
                                  if (!isLoadingAddContact) {
                                    if (customFieldValues[customField.fieldCode]) {
                                      let _currentDateSelections = {...currentDateSelections};
                                      _currentDateSelections[customField.fieldCode] = customFieldValues[customField.fieldCode];
                                      setCurrentDateSelections(_currentDateSelections);
                                    }
                                    setDatePickerDisabledDays(
                                      (customField.validation === VALIDATION_TODAY_OR_LATER_DATES) ?
                                      {before: new Date()}
                                      :
                                      (customField.validation === VALIDATION_TODAY_OR_EARLIER_DATES) ?
                                      {after: new Date()}
                                      :
                                      {}
                                    );
                                    setShowingDatePickerFieldCode(customField.fieldCode);
                                  }
                                }}
                              >
                                <FiCalendar />
                                {customFieldValues[customField.fieldCode] ?
                                  HelperDate.formatToString(new Date(customFieldValues[customField.fieldCode]), "d MMM yyyy")
                                  :  
                                  <span className="grey-font">
                                    Select date
                                  </span>
                                }
                              </div>
                              :
                              <></>
                            }
                          </div>
                          {
                            customFieldErrors[customField.fieldCode] &&
                            customFieldErrors[customField.fieldCode] !== ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT &&
                            <ErrorOrInfoComp
                              text={customFieldErrors[customField.fieldCode]}
                              _className={"font-red"}
                              icon={<FiInfo />}
                            />
                          }
                        </>
                      )
                    })
                  }
                </>
              }
            </div>
            <PopupRemove
              removeTitle={`Discard Progress?`}
              removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
              onClickCancel={toggleModalDiscard}
              onClickRemove={discardProgress}
              // waitForResponse={waitForResponseDiscard}
              showModalProps={isShowModalDiscard}
              submitText="Discard Changes"
              colorButton="red-button"
            />
          </div>
        </>
      </div>
            
      <CalendarPickerSingle
        isOpen={showingDatePickerFieldCode}
        toggle={toggleDatePicker}
        date={currentDateSelections[showingDatePickerFieldCode]}
        handleDateFilterClick={handleDateFilterClick}
        submitDateFilter={submitDateFilter}
        clearDatePicker={clearDatePicker}
        disabledDays={datePickerDisabledDays}
        allowSubmitEmptyDate
        {...props}
      />
    </SectionWrap >
  );
};

export default AddUserContact;