// {
//   "medium": "whatsappba",
//   "recipient": {
//       "country": {
//           "id": "0",
//           "countryCodeNumber": "62",
//           "countryCode": "IDN",
//           "countryName": "Indonesia",
//           "countryFlag": "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
//       },
//       "number": "",
//       "contact": {
//           "name": "+62 831-3355-3212",
//           "number": "+6283133553212"
//       }
//   }
// }

const INITIAL_STATE = {
  "medium": "whatsappba",
  "recipient": {
      // "country": {
      //     "id": "0",
      //     "countryCodeNumber": "62",
      //     "countryCode": "IDN",
      //     "countryName": "Indonesia",
      //     "countryFlag": "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
      // },
      "number": "",
      "contact": {
          "name": "",
          "number": ""
      }
    },
    "lastClick": false
};

let reduxReducerCloseCaseNewMessageRecipient = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_CLOSE_CASE_NEW_MESSAGE_RECIPIENT':
        return action.closeCaseNewMessageRecipient;
      case 'CLEAR_CLOSE_CASE_NEW_MESSAGE_RECIPIENT':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerCloseCaseNewMessageRecipient;