const INITIAL_STATE = {};

let reduxReducerDraftMessage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_DRAFT_MESSAGE':
        return action.draftMessage;
      case 'CLEAR_DRAFT_MESSAGE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerDraftMessage;