const INITIAL_STATE = {
  state: false,
  action: '',
  labelIDs: [],
  id: 0,
  roomID: 0
};

let reducerUpdateCaseLabel = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_UPDATE_CASE_LABEL':
      return action.updateCaseLabel;
    case 'CLEAR_UPDATE_CASE_LABEL':
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducerUpdateCaseLabel;