const INITIAL_STATE = false;

let reduxReducerQuickReplyDocument = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_QUICK_REPLY_DOCUMENT':
        return action.quickReplyDocument;
      case 'CLEAR_QUICK_REPLY_DOCUMENT':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerQuickReplyDocument;