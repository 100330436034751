const INITIAL_STATE = {
    openCase: false,
    conversation: false,
    is24Hour: false,
};

let reduxReducerStartConversationBa = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_START_CONVERSATION_BA':
        return action.startConversationBa;
      case 'CLEAR_START_CONVERSATION_BA':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerStartConversationBa;