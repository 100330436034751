import React, { useEffect, useState } from "react";
import "./IntegrationGoogleBusinessEditChatbot.scss";
import "react-toastify/dist/ReactToastify.css";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { doToast, checkID, scrollToClass } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ChatbotService from "../../../../services/newServices/ChatbotService";
import IntegrationGoogleServices from '../../../../services/integration/IntegrationGoogleServices';
import { FiChevronRight, FiInfo } from "react-icons/fi";

const IntegrationGoogleBusinessEditChatbot = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [chatbot, setChatbotList] = useState(false);
    let [chatbotPathVal, setChatbotPathVal] = useState({
        label: "",
        value: "",
        type: ""
    });
    let [chatbotPathValOrigin, setChatbotPathValOrigin] = useState({
        label: "",
        value: "",
        type: ""
    });
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [accountDetail, setAccountDetail] = useState(null);
    let [errorMessageData, setErrorMessageData] = useState({
        path: ""
    })

    useEffect(() => {
        if (props.history.location.state) {
            // if(props.history.location.state.whatsappVal) {
            //     setChatbotPathVal(props.history.location.state.whatsappVal.path)
            // }

            if (props.history.location.state.accountDetail) {
                setAccountDetail(props.history.location.state.accountDetail)
            }

            if (props.history.location.state.chatbot) {
                setChatbotPathVal({
                    label: props.history.location.state.chatbot.chatbotName,
                    value: props.history.location.state.chatbot.id,
                    type: props.history.location.state.chatbot.chatbotTypeName
                })

                setChatbotPathValOrigin({
                    label: props.history.location.state.chatbot.chatbotName,
                    value: props.history.location.state.chatbot.id,
                    type: props.history.location.state.chatbot.chatbotTypeName
                })
            }
        }
    }, [props.history])

    const back = () => history.push(`/o/${orgID}/integration/google-business-messages/detail/${match.params.accountID}`);

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getPaths = async (orgID) => {
        ChatbotService.getChatbot(orgID, (response) => {
            const data = response.dataResult.data.chatbots;

            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.type = val.typeName;
                    _data.value = val.id;
                    _data.label = val.name;
                    newData.push(_data);
                    return null;
                });
                setChatbotList(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if (orgID) {
            getPaths(orgID);
        }
    }, [orgID])

    let onSelectChatbot = (value) => {
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.path = "";
        setErrorMessageData(_errorMessageData);
        setChatbotPathVal(value)
    }

    // let isFormValid = () => {

    //     let dis = false;
    //     let _chatbotPathVal = chatbotPathVal.value;
    //     let _chatbotPathValOri = chatbotPathValOrigin.value;

    //     if(
    //         _chatbotPathVal !== "" && 
    //         _chatbotPathVal !== 0 && 
    //         _chatbotPathVal !== _chatbotPathValOri
    //     ) {
    //         dis = true;
    //     }

    //     return dis;
    // }

    let onSubmitAction = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = { ...errorMessageData };

        if (chatbotPathVal.value === 0) {
            _errorMessageData.path = "Please select path";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-path" }
        }

        if (errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        } else {
            setIsLoadingSubmit(true);

            let data = {
                channelID: parseInt(match.params.accountID),
                chatbotID: chatbotPathVal.value
            }

            IntegrationGoogleServices.setChatBot(props.match.params.orgID, data, (response) => {
                let dataResult = response.dataResult;

                if (dataResult.error.message === "") {
                    if (dataResult.data.success) {
                        setChatbotPathValOrigin(chatbotPathVal);

                        doToast(dataResult.data.message);
                        if (dataResult.data.success) {
                            setTimeout(() => {
                                back();
                            }, 2000)
                        } else {
                            setIsLoadingSubmit(false)
                        }
                    } else {
                        setIsLoadingSubmit(false)
                        doToast(dataResult.data.message);
                    }
                } else {
                    setIsLoadingSubmit(false)
                    doToast(dataResult.error.message, "fail");
                }
            })
        }
    }



    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={{
                    path: chatbotPathValOrigin
                }}
                dataAfterChange={{
                    path: chatbotPathVal
                }}
            >
                {accountDetail &&
                    <div className="sectionWrap integration-main-container chatbot-add-chatbot text-left">
                        <BackButton text={`Back to ${accountDetail.channel.displayName}`} onClick={back} />

                        <div className="chatbot-add-chatbot-box">
                            <div className="chatbot-add-chatbot-box-top">
                                <b>Edit Chatbot</b>

                                <div className="chat-bot-chatbot-button-wrapper">
                                    {isLoadingSubmit ?
                                        <ButtonWithLoadingOrIcon
                                            isLoading
                                            loadingColor="gray"
                                            isDisabled
                                            position="left"
                                            text="Save Changes"
                                            className="main-button-40 orange-button"
                                        />
                                        :
                                        <ButtonWithLoadingOrIcon
                                            text="Save Changes"
                                            className="main-button-40 orange-button"
                                            onClickAction={onSubmitAction}
                                        // isDisabled={!isFormValid()}
                                        />
                                    }
                                </div>
                            </div>

                            {(chatbot && chatbot.length) === 0 &&
                                <div className="alert-component-whatsapp">
                                    <FiInfo className="alert-component-left-icon" />
                                    <b>
                                        To use chatbot, you have to create a chatbot first
                                    </b>

                                    <button onClick={() => {
                                        props.history.push({
                                            pathname: `/o/${orgID}/integration/chatbots/create`,
                                            state: {
                                                pathname: props.location.pathname,
                                                accountDetail: props.location.state.accountDetail,
                                                chatbot: props.location.state.chatbot
                                            }
                                        })
                                    }}>
                                        Create Chatbot

                                        <FiChevronRight />
                                    </button>
                                </div>
                            }

                            <div className="chatbot-add-chatbot-box-form">
                                <label><b>Chatbot Name</b></label>
                                <CustomSelectOption
                                    optionListProps={chatbot}
                                    valueDropdownProps={chatbotPathVal}
                                    placeholderProps={"Select chatbot"}
                                    onClickDropDownListOptionProps={onSelectChatbot}
                                    specificClassNameProps="custom-select-path"
                                    isDisabled={isLoadingSubmit}
                                    _className={`input-path ${errorMessageData.path !== "" ? "border-red" : ""}`}
                                />

                                {errorMessageData.path !== "" &&
                                    <ErrorOrInfoComp
                                        text={errorMessageData.path}
                                        _className={"font-red margin-top-0"}
                                        icon={<FiInfo />}
                                    />
                                }
                            </div>

                            <div className="chatbot-add-chatbot-box-form">
                                <label><b>Chatbot Type</b></label>
                                <input type="text" disabled value={chatbotPathVal.type === "" ? "Chatbot type will appear here" : chatbotPathVal.type} />
                            </div>
                        </div>
                    </div>
                }
            </SectionWrap>
        </div>
    )
}

export default IntegrationGoogleBusinessEditChatbot;