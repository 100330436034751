import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const AwayWelcomeMessageServicesServices = {
  getAwayMessage(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_AWAY, {}, headers, callback);  
  },

  setAwayMessage(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);  
  },

  getWelcomeMessage(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_WELCOME, {}, headers, callback);  
  },

  setWelcomeMessage(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);  
  },
};

const API = {
  SET_AWAY: "/organization/setup/away_message/set",
  GET_AWAY: "/organization/setup/away_message/get",
  SET_WELCOME: "/organization/setup/welcome_message/set",
  GET_WELCOME: "/organization/setup/welcome_message/get",
}

export default AwayWelcomeMessageServicesServices;
