import React, { useState, useEffect } from "react";
import "./IntegrationChatbot.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast } from "../../../helper/HelperGeneral";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { FiPlus, FiEye } from "react-icons/fi";
import NewButton from "../../reuseableComponent/NewButton/NewButton";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import ChatbotServices from "../../../services/newServices/ChatbotService";
import { NavLink } from "react-router-dom";

const IntegrationChatbot = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [apiKeysList, setChatbotList] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  
  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration/chatbots`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let fetchChatbotList = () => {
    let _retriveDataError = {...retriveDataError};
    
    ChatbotServices.getChatbot(orgID, (response) => {
      let dataResult = response.dataResult;
      if(dataResult.error.message === "") {
        setChatbotList(dataResult.data.chatbots);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if(orgID) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      fetchChatbotList();
      
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
            "[Page] Chatbot", 
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(props.history.location.state) {
      if(props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  // let onChangePage = () => {
  //   console.log('change page')
  // }

  const addBtn = () => {
    const fullUrl = `/o/${orgID}/integration/chatbots/create`;
    
    const onClick = () => history.push(fullUrl);
    const btnProps = { text: "Create Chatbot", icon: FiPlus, onClick };

    return <NewButton {...btnProps} />;
  };

  // const goToDetail = (id) => {
  //   props.history.push({
  //     pathname: `/o/${orgID}/integration/chatbots/detail/${id}`
  //   })
  // }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap chatbot-main-wrapper">
            {/* tier blocking */}
            {retriveDataError.code  === "49901" &&
              <RetrieveDataLoading 
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message}
                errorCode={retriveDataError.code}
              />
            }
            {/* tier blocking */}
            
            <div className="topContent">
                <div className="title">Chatbot</div>

                {(!waitForResponseGetList && !retriveDataError.code) &&
                  <NavLink to={`/o/${orgID}/integration/chatbots/create`}>
                    <div 
                      className="btn"
                      onClick={() => {
                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                        
                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                          mixpanel.track(
                            "[Button] Create Chatbot",
                            {
                              userFullName : myAgentData.account.fullName,
                              umixpserID : myAgentData.account.id,
                              organizationID : orgID.split("-")[0]
                            }
                          )
                        }
                      }}
                    >
                      {addBtn()}
                    </div>
                  </NavLink>
                }
            </div>

            <div className="chatbot-list-info">
               Create automated messages to answer repetitive questions from your customers.
            </div>

            {waitForResponseGetList || retriveDataError.code ? 
              <RetrieveDataLoading 
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message} 
              />
              :
              <React.Fragment>
                <div className="chatbot-list-table-wrapper">
                  <div className="custom-table">
                      <table className="custom-table-content table-chatbot-list">
                          <thead>
                              <tr>
                                <td className="col_chatbot_name">Chatbot Name</td>
                                <td className="col_path">Chatbot Type</td>
                                <td className="col_action"></td>
                              </tr>
                          </thead>
                          
                          {apiKeysList.length < 1 ?
                            <tbody>
                              <tr>
                                <td colSpan="2" className="no-data-column"><b className="no-result-found">No chatbot found, start with "+ Create Chatbot"</b></td>
                              </tr>
                            </tbody>
                            :
                            <tbody>
                              {apiKeysList.map((value, index) => {
                                return (
                                  <tr key={`chatbot-${index}`}>
                                      <td className="col_chatbot_name">{value.name}</td>
                                      <td className="col_path">
                                        {value.typeName}
                                      </td>
                                      <td className={`col_action`}>
                                        <div className="col-action-button-wrapper">
                                          <NavLink to={`/o/${orgID}/integration/chatbots/detail/${value.id}`}>
                                            <b className="orange-link-text">
                                              <FiEye />
                                              View
                                            </b>
                                          </NavLink>
                                        </div>
                                      </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          }
                      </table>
                  </div>
                    
                  {/* <Pagination total={100} 
                              page={1} 
                              listPerPage={8} 
                              step={3} 
                              onChangePage={onChangePage} 
                  /> */}
                </div>
              </React.Fragment>
            }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default IntegrationChatbot;
