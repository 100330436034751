import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Tooltip } from 'reactstrap';
import "./ContactInfoCustomFieldAdd.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo, FiAlertCircle, FiTrash2, FiPlus } from "react-icons/fi";
import IconHelpCircle from '../../../../assets/img/chatroom/user-info-tab/icon-help-circle.svg';
import IconDragDrop from '../../../../assets/img/icon-menu-dragdrop.svg';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ButtonWithLoadingOrIcon from '../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization'
import ContactCustomFieldServices from "../../../../services/newServices/ContactCustomFieldServices";
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import { 
  FIELD_TYPE_SINGLE_LINE_TEXT,
  FIELD_TYPE_MULTILINE_TEXT,
  FIELD_TYPE_DROPDOWN,
  FIELD_TYPE_MULTIPLE_SELECT,
  FIELD_TYPE_DATE,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_LABELS,
  VALIDATION_FREE_TEXT,
  VALIDATION_NUMBER,
  VALIDATION_NUMERIC,
  VALIDATION_ALPHANUMERIC,
  VALIDATION_ALL_DATES,
  VALIDATION_TODAY_OR_LATER_DATES,
  VALIDATION_TODAY_OR_EARLIER_DATES,
  VALIDATION_LABELS,
  FIELD_NAME_MAX_LENGTH,
  FIELD_CODE_MAX_LENGTH,
  MULTIPLE_OPTION_MAX_LENGTH,
  MULTIPLE_OPTION_LIMIT,
  ERROR_MESSAGE_FIELD_REQUIRED,
  ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT,
  ERROR_MESSAGE_INVALID_NAME,
  ERROR_MESSAGE_INVALID_CODE,
  ERROR_MESSAGE_DUPLICATE_OPTIONS,
  DEFAULT_CUSTOM_FIELD_VAL,
  ERROR_MESSAGE_CODE_START_WITH_ALPHABET,
} from "../ContactInfoCustomField";

let ContactInfoCustomFieldAdd = props => {

  const FIELD_TYPE_ARRAY = [
    {
        value: FIELD_TYPE_SINGLE_LINE_TEXT,
        label: FIELD_TYPE_LABELS[FIELD_TYPE_SINGLE_LINE_TEXT]
    },
    {
        value: FIELD_TYPE_MULTILINE_TEXT,
        label: FIELD_TYPE_LABELS[FIELD_TYPE_MULTILINE_TEXT]
    },
    {
        value: FIELD_TYPE_DROPDOWN,
        label: FIELD_TYPE_LABELS[FIELD_TYPE_DROPDOWN]
    },
    {
        value: FIELD_TYPE_MULTIPLE_SELECT,
        label: FIELD_TYPE_LABELS[FIELD_TYPE_MULTIPLE_SELECT]
    },
    {
        value: FIELD_TYPE_DATE,
        label: FIELD_TYPE_LABELS[FIELD_TYPE_DATE]
    },
    {
        value: FIELD_TYPE_NUMBER,
        label: FIELD_TYPE_LABELS[FIELD_TYPE_NUMBER]
    },
  ];

  const VALIDATION_TEXT_ARRAY = [
    {
        value: VALIDATION_FREE_TEXT,
        label: VALIDATION_LABELS[VALIDATION_FREE_TEXT]
    },
    {
        value: VALIDATION_NUMERIC,
        label: VALIDATION_LABELS[VALIDATION_NUMERIC]
    },
    {
        value: VALIDATION_ALPHANUMERIC,
        label: VALIDATION_LABELS[VALIDATION_ALPHANUMERIC]
    },
  ];

  const VALIDATION_DATE_ARRAY = [
    {
        value: VALIDATION_ALL_DATES,
        label: VALIDATION_LABELS[VALIDATION_ALL_DATES]
    },
    {
        value: VALIDATION_TODAY_OR_LATER_DATES,
        label: VALIDATION_LABELS[VALIDATION_TODAY_OR_LATER_DATES]
    },
    {
        value: VALIDATION_TODAY_OR_EARLIER_DATES,
        label: VALIDATION_LABELS[VALIDATION_TODAY_OR_EARLIER_DATES]
    },
  ];

  let { match } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingCreate, setIsLoadingCreate] = useState(false);
  let [customFieldVal, setCustomFieldVal] = useState(DEFAULT_CUSTOM_FIELD_VAL);
  let [customFieldValOrigin, setCustomFieldValOrigin] = useState(DEFAULT_CUSTOM_FIELD_VAL);
  let [errorMessageData, setErrorMessageData] = useState({
    fieldName: "",
    fieldCode: "",
    fieldType: "",
    validation: "",
    options: []
  });
  let [fieldTypeVal, setFieldTypeVal] = useState({
      value: "",
      label: ""
  });
  let [validationVal, setValidationVal] = useState({
      value: "",
      label: ""
  });
  let [showTooltipRequired, setShowTooltipRequired] = useState(false);
  let [showTooltipCharacterLimit, setShowTooltipCharacterLimit] = useState(false);
  let [draggingItem, setDraggingItem] = useState(null);
  let [isShowCreateFieldModal, setIsShowCreateFieldModal] = useState(false);

  let toggleTooltipRequired = () => {
    setShowTooltipRequired(!showTooltipRequired);
  }
  
  let toggleTooltipCharacterLimit = () => {
    setShowTooltipCharacterLimit(!showTooltipCharacterLimit);
  }
  
  let toggleShowCreateFieldModal = () => {
    setIsShowCreateFieldModal(!isShowCreateFieldModal);
  }

  let back = () => {
    backToPreviousPage();
  }

  let backToPreviousPage = () => {
    props.history.push(`/o/${orgID}/setup/contact-info-custom-field`);
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    }
    else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/setup/contact-info-custom-field/add`);
        }
        else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history]);

  useEffect(() => {
    if (customFieldVal.fieldType !== FIELD_TYPE_DROPDOWN && customFieldVal.fieldType !== FIELD_TYPE_MULTIPLE_SELECT) {
      return;
    }
    // Check duplicate options
    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};
    let dupeArray = [];
    let duplicateVal = checkDuplicateInArray(_customFieldVal.options);

    _customFieldVal.options.map((v, i) => {
      if (v) {
        duplicateVal.map((dupe) => {
          if (dupe && v === dupe) {
            dupeArray.push(i);
          }
          
          return null;
        });
      }

      return null;
    })

    _errorMessageData.options = [];
    _customFieldVal.options.map((v, i) => {
      if (v.length > MULTIPLE_OPTION_MAX_LENGTH) {
        _errorMessageData.options.push(ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT);
      }
      else {
        _errorMessageData.options.push("");
      }

      return null;
    })
    
    if (dupeArray.length > 0) {
      dupeArray.map((v) => {
        _errorMessageData.options[v] = ERROR_MESSAGE_DUPLICATE_OPTIONS;

        return null;
      })
    }

    setErrorMessageData(_errorMessageData);
  }, [customFieldVal]);

  let onChange = (e) => {
    let id = e.target.id;
    let val = e.target.value;
    
    let _customFieldVal = {...customFieldVal};
    if (id === "maxCharacters") {
      let maxCharsString = val.replace(/\D/g,'');
      _customFieldVal[id] = maxCharsString ? parseInt(maxCharsString) : 0;
    }
    else {
      _customFieldVal[id] = val;
    }
    setCustomFieldVal(_customFieldVal);

    let _errorMessageData = {...errorMessageData};
    _errorMessageData[id] = "";
    setErrorMessageData(_errorMessageData);

    if (id === "fieldName") {
      if (/\p{Extended_Pictographic}/u.test(val)) {
        _errorMessageData[id] = ERROR_MESSAGE_INVALID_NAME;
      }
      else if (val.length > FIELD_NAME_MAX_LENGTH) {
        _errorMessageData[id] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
      }
    }
    if (id === "fieldCode") {
      if (!(/^[a-z0-9_]*$/).test(val)) {
        _errorMessageData[id] = ERROR_MESSAGE_INVALID_CODE;
      }
      else if (val && !val[0].match(/[a-z]/i)) {
        _errorMessageData[id] = ERROR_MESSAGE_CODE_START_WITH_ALPHABET;
      }
      else if (val.length > FIELD_CODE_MAX_LENGTH) {
        _errorMessageData[id] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
      }
    }
  }

  let onKeyDown = async (e) => {
    const code = (e.keyCode ? e.keyCode : e.which);
    if (code === 9 || code === 13) {
      // Generate field code when user presses Enter or Tab key
      checkAndGenerateFieldCode();
    }
  }

  let onBlur = () => {
    checkAndGenerateFieldCode();
  }

  let checkAndGenerateFieldCode = () => {
    if (customFieldVal.fieldCode || !customFieldVal.fieldName) {
      return;
    }
    let generatedFieldCode = customFieldVal.fieldName.toLowerCase().replaceAll(" ", "_").replace(/\W/g, '');
    if (generatedFieldCode) {
      let _customFieldVal = {...customFieldVal};
      _customFieldVal.fieldCode = generatedFieldCode;
      setCustomFieldVal(_customFieldVal);

      let _errorMessageData = {...errorMessageData};
      if (!(/^[a-z0-9_]*$/).test(generatedFieldCode)) {
        _errorMessageData.fieldCode = ERROR_MESSAGE_INVALID_CODE;
      }
      else if (!generatedFieldCode[0].match(/[a-z]/i)) {
        _errorMessageData.fieldCode = ERROR_MESSAGE_CODE_START_WITH_ALPHABET;
      }
      else if (generatedFieldCode.length > FIELD_CODE_MAX_LENGTH) {
        _errorMessageData.fieldCode = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
      }
      else {
        _errorMessageData.fieldCode = "";
      }
      setErrorMessageData(_errorMessageData);
    }
  }

  let onChangeRequired = (e) => {
    if (isLoadingCreate) {
      return;
    }
    let _customFieldVal = {...customFieldVal};
    _customFieldVal.isRequired = e.target.checked;
    setCustomFieldVal(_customFieldVal);
  }

  let onSelectFieldType = (fieldType) => {
    if (fieldTypeVal.value === fieldType.value) {
      return;
    }

    setFieldTypeVal(fieldType);

    let _validation = {
      value: "",
      label: ""
    }
    if (fieldType.value === FIELD_TYPE_MULTILINE_TEXT) {
      _validation = VALIDATION_TEXT_ARRAY[0]; // Free text
    }
    else if (fieldType.value === FIELD_TYPE_NUMBER) {
      _validation = {
        value: VALIDATION_NUMBER,
        label: VALIDATION_LABELS[VALIDATION_NUMBER]
      }
    }
    setValidationVal(_validation);

    let _customFieldVal = {...customFieldVal};
    _customFieldVal.fieldType = fieldType.value;
    _customFieldVal.validation = _validation.value;
    _customFieldVal.maxCharacters = 0;
    _customFieldVal.options = [];
    _customFieldVal.mappedOptions = [];
    if (fieldType.value === FIELD_TYPE_DROPDOWN || fieldType.value === FIELD_TYPE_MULTIPLE_SELECT) {
      _customFieldVal.options.push("");
      _customFieldVal.mappedOptions.push({
        id: Date.now(), // ID used to differentiate index when rearranging (text might contain duplicate)
        text: ""
      });
    }
    setCustomFieldVal(_customFieldVal);

    let _errorMessageData = {...errorMessageData};
    _errorMessageData.fieldType = "";
    _errorMessageData.validation = "";
    _errorMessageData.options = [];
    if (fieldType.value === FIELD_TYPE_DROPDOWN || fieldType.value === FIELD_TYPE_MULTIPLE_SELECT) {
      _errorMessageData.options.push("");
    }
    setErrorMessageData(_errorMessageData);
  }

  let onSelectValidation = (validation) => {
    if (validationVal.value === validation.value) {
      return;
    }

    setValidationVal(validation);
    
    let _customFieldVal = {...customFieldVal};
    _customFieldVal.validation = validation.value;
    setCustomFieldVal(_customFieldVal);

    let _errorMessageData = {...errorMessageData};
    _errorMessageData.validation = "";
    setErrorMessageData(_errorMessageData);
  }

  let checkDuplicateInArray = (inputArray) => {
    let duplicates = inputArray.filter((item, index) => inputArray.indexOf(item) !== index);
    return Array.from(new Set(duplicates));
  }

  let onChangeOption = (val, idx) => {
    let _customFieldVal = {...customFieldVal};
    _customFieldVal.options[idx] = val;
    _customFieldVal.mappedOptions[idx].text = val;

    setCustomFieldVal(_customFieldVal);
  }

  let addOption = () => {
    if (isLoadingCreate) {
      return;
    }
    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};

    if (_customFieldVal.options.length < MULTIPLE_OPTION_LIMIT) {
      _customFieldVal.options.push("");
      _customFieldVal.mappedOptions.push({
        id: Date.now(),
        text: ""
      });
      _errorMessageData.options.push("");
    }

    setCustomFieldVal(_customFieldVal);
    setErrorMessageData(_errorMessageData);
    
    setTimeout(() => {
      const className = `multiple-option-${_customFieldVal.options.length - 1}`;
      scrollToClass(`.${className}`);

      const elements = document.getElementsByClassName(className);
      if (elements && elements.length > 0) {
        elements[0].focus();
      }
    }, 100);
  }

  let removeOption = (idx) => {
    if (isLoadingCreate) {
      return;
    }
    let _customFieldVal = {...customFieldVal};
    _customFieldVal.options.splice(idx, 1);
    _customFieldVal.mappedOptions.splice(idx, 1);

    setCustomFieldVal(_customFieldVal);
  }

  let onOptionDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', ''); 
  }

  let onOptionDragEnd = (e) => {
    setDraggingItem(null);
  }

  let onOptionDragOver = (e) => {
    e.preventDefault(); 
  }

  let onOptionDrop = (e, item) => {
    if (!draggingItem) {
      return; 
    }

    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};
    const currentIndex = _customFieldVal.mappedOptions.indexOf(draggingItem);
    const targetIndex = _customFieldVal.mappedOptions.indexOf(item);

    if (currentIndex !== -1 && targetIndex !== -1) {
      _customFieldVal.options.splice(currentIndex, 1);
      _customFieldVal.options.splice(targetIndex, 0, draggingItem.text);
      _customFieldVal.mappedOptions.splice(currentIndex, 1);
      _customFieldVal.mappedOptions.splice(targetIndex, 0, draggingItem);
      const draggedErrorMessage = _errorMessageData.options[currentIndex];
      _errorMessageData.options.splice(currentIndex, 1);
      _errorMessageData.options.splice(targetIndex, 0, draggedErrorMessage);
      setCustomFieldVal(_customFieldVal);
    }
  }

  let validateCustomFieldForm = async () => {
    let _customFieldVal = {...customFieldVal};
    let _errorMessageData = {...errorMessageData};
    let errorCount = 0;
    let errorClass = "";

    if (_customFieldVal.fieldName === "") {
      _errorMessageData.fieldName = ERROR_MESSAGE_FIELD_REQUIRED;
      errorCount = errorCount + 1;
      if (errorClass === "") { 
        errorClass = "input-field-name"
      }
    }
    else if (/\p{Extended_Pictographic}/u.test(_customFieldVal.fieldName)) {
      _errorMessageData.fieldName = ERROR_MESSAGE_INVALID_NAME;
      errorCount = errorCount + 1;
      if (errorClass === "") { 
        errorClass = "input-field-name"
      }
    }
    else if (_customFieldVal.fieldName.length > FIELD_NAME_MAX_LENGTH) {
      _errorMessageData.fieldName = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
      errorCount = errorCount + 1;
      if (errorClass === "") { 
        errorClass = "input-field-name"
      }
    }

    if (_customFieldVal.fieldCode === "") {
      _errorMessageData.fieldCode = ERROR_MESSAGE_FIELD_REQUIRED;
      errorCount = errorCount + 1;
      if (errorClass === "") { 
        errorClass = "input-field-code"
      }
    }
    else if (!(/^[a-zA-Z0-9_]*$/).test(_customFieldVal.fieldCode)) {
      _errorMessageData.fieldCode = ERROR_MESSAGE_INVALID_CODE;
      errorCount = errorCount + 1;
      if (errorClass === "") { 
        errorClass = "input-field-code"
      }
    }
    else if (_customFieldVal.fieldCode.length > FIELD_CODE_MAX_LENGTH) {
      _errorMessageData.fieldCode = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
      errorCount = errorCount + 1;
      if (errorClass === "") { 
        errorClass = "input-field-code"
      }
    }

    if (_customFieldVal.fieldType === "") {
      _errorMessageData.fieldType = ERROR_MESSAGE_FIELD_REQUIRED;
      errorCount = errorCount + 1;
      if (errorClass === "") { 
        errorClass = "dropdown-field-type"
      }
    }

    if (
      (_customFieldVal.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ||
       _customFieldVal.fieldType === FIELD_TYPE_MULTILINE_TEXT ||
       _customFieldVal.fieldType === FIELD_TYPE_DATE ||
       _customFieldVal.fieldType === FIELD_TYPE_NUMBER) &&
      _customFieldVal.validation === ""
    ) {
      _errorMessageData.validation = ERROR_MESSAGE_FIELD_REQUIRED;
      errorCount = errorCount + 1;
      if (errorClass === "") { 
        errorClass = "dropdown-validation"
      }
    }

    if (_customFieldVal.fieldType === FIELD_TYPE_DROPDOWN || _customFieldVal.fieldType === FIELD_TYPE_MULTIPLE_SELECT) {
      let dupeArray = [];
      let duplicateVal = checkDuplicateInArray(_customFieldVal.options);

      _customFieldVal.options.map((v, i) => {
        if (v) {
          duplicateVal.map((dupe) => {
            if (dupe && v === dupe) {
              dupeArray.push(i);
            }

            return null;
          });
        }
  
        return null;
      })
      
      if (dupeArray.length > 0) {
        dupeArray.map((v) => {
          if (_customFieldVal.options[v]) {
            _errorMessageData.options[v] = ERROR_MESSAGE_DUPLICATE_OPTIONS;
            errorCount = errorCount + 1;
            if (errorClass === "") { 
              errorClass = `input-option-${v}`
            }
          }

          return null;
        })
      }

      _customFieldVal.options.map((option, index) => {
        if (_errorMessageData.options[index] !== ERROR_MESSAGE_DUPLICATE_OPTIONS) {
          if (!option) {
            _errorMessageData.options[index] = ERROR_MESSAGE_FIELD_REQUIRED;
            errorCount = errorCount + 1;
            if (errorClass === "") { 
              errorClass = `input-option-${index}`
            }
          }
          else if (option.length > MULTIPLE_OPTION_MAX_LENGTH) {
            _errorMessageData.options[index] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
            errorCount = errorCount + 1;
            if (errorClass === "") { 
              errorClass = `input-option-${index}`
            }
          }
        }

        return null;
      });
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }
    else {
      setIsShowCreateFieldModal(true);
    }
  };

  let createCustomField = () => {
    setIsLoadingCreate(true);
    let _customFieldVal = {...customFieldVal};
    delete _customFieldVal.id;
    delete _customFieldVal.mappedOptions;
    ContactCustomFieldServices.createContactCustomField(orgID, _customFieldVal, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {          
          setCustomFieldVal(_customFieldVal);
          setCustomFieldValOrigin(_customFieldVal);
          setIsLoadingCreate(false);
          setIsShowCreateFieldModal(false);
          backToPreviousPage();
          setTimeout(() => {
            doToast(response.dataResult.data.message);
          }, 200);
        }
        else {
          setIsShowCreateFieldModal(false);
          setIsLoadingCreate(false);
          doToast(response.dataResult.data.message, "fail");
        }
      }
      else {
        setIsShowCreateFieldModal(false);
        setIsLoadingCreate(false);
        doToast(response.dataResult.error.message, 'fail');

        if (response.dataResult.error.code === "40002") {
          let _errorMessageData = {...errorMessageData};
          if (response.dataResult.error.field === "fieldName") {
            _errorMessageData.fieldName = response.dataResult.error.message;
          }
          if (response.dataResult.error.field === "fieldCode") {
            _errorMessageData.fieldCode = response.dataResult.error.message;
          }
          setErrorMessageData(_errorMessageData);
        }
      }
    });
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
      dataOrigin={JSON.stringify(customFieldValOrigin)}
      dataAfterChange={JSON.stringify(customFieldVal)}
    >
      <BackButton text="Back to Contact Info Custom Field" onClick={back} />

      <div className="sectionWrap create-custom-field-main-wrapper">
        <div className="topContent create-custom-field-header">
          <p className="create-custom-field-title"><b>Create Custom Field</b></p>

          <div className="topContent-button-wrapper">
            {(isLoadingCreate) ?
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                text="Create Custom Field"
                position="left"
                loadingColor="gray"
                isLoading
                isDisabled
              />
              :
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                text="Create Custom Field"
                onClickAction={validateCustomFieldForm}
              />
            }
          </div>
        </div>

        <>
          <div className="create-custom-field-add-wrapper">

            <div className="form-content-wrapper">
              
              <label>
                <b>Field Name</b>
                <span className={`input-counter grey-font ${customFieldVal.fieldName.length > FIELD_NAME_MAX_LENGTH ? "red-text" : ""}`}>
                  {customFieldVal.fieldName.length}/{FIELD_NAME_MAX_LENGTH} characters
                </span>
              </label>
              <input
                disabled={isLoadingCreate}
                type="text"
                id="fieldName"
                onChange={(e) => onChange(e)}
                onKeyDown={(e) => onKeyDown(e)}
                onBlur={onBlur}
                value={customFieldVal.fieldName}
                placeholder="Input field name"
                className={`input-field-name ${errorMessageData.fieldName ? "border-red" : ""}`}
              />
              {
                errorMessageData.fieldName &&
                <ErrorOrInfoComp
                  text={errorMessageData.fieldName}
                  _className={"font-red"}
                  icon={<FiAlertCircle />}
                />
              }

              <label className="label-left">
                <b>Required</b>
                <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-required" alt="" />
                <div>
                  <Tooltip 
                    // className="tooltip-content"
                    className="tooltip-required"
                    placement="bottom"
                    isOpen={showTooltipRequired}
                    target="tooltip-required"
                    toggle={toggleTooltipRequired}
                    opacity={1.0}
                  >
                    If Required is on, this field should always be filled when edited in contact’s info
                  </Tooltip>
                </div>

                <span>
                  <label className="custom-switch-wrapper">
                    <input 
                      type="checkbox" 
                      checked={customFieldVal.isRequired}
                      onChange={(e) => onChangeRequired(e)}
                    />
                    <span className="custom-switch round"></span>
                  </label>
                  <p className="required-value">This field is {customFieldVal.isRequired ? "required" : "optional"}</p>
                </span>
              </label>

              <label>
                <b>Field Code</b>
                <span className={`input-counter grey-font ${customFieldVal.fieldCode.length > FIELD_CODE_MAX_LENGTH ? "red-text" : ""}`}>
                  {customFieldVal.fieldCode.length}/{FIELD_CODE_MAX_LENGTH} characters
                </span>
              </label>
              <input
                disabled={isLoadingCreate}
                type="text"
                id="fieldCode"
                onChange={(e) => onChange(e)}
                value={customFieldVal.fieldCode}
                placeholder="Input field code"
                className={`input-field-code ${errorMessageData.fieldCode ? "border-red" : ""}`} 
              />
              {
                errorMessageData.fieldCode &&
                <ErrorOrInfoComp
                  text={errorMessageData.fieldCode}
                  _className={"font-red"}
                  icon={<FiAlertCircle />}
                />
              }

              <div className="form-content-wrapper dropdown-field-type">
                <label><b>Field Type</b></label>
                <CustomSelectOption
                    optionListProps={FIELD_TYPE_ARRAY}
                    valueDropdownProps={fieldTypeVal}
                    placeholderProps={"Please select"}
                    onClickDropDownListOptionProps={onSelectFieldType}
                    specificClassNameProps={`custom-select-whatsapp-topic" ${errorMessageData.fieldType ? "border-red" : ""}`}
                    isDisabled={isLoadingCreate}
                    _className={`${errorMessageData.fieldType ? "border-red" : ""}`}
                />
              </div>
              {
                errorMessageData.fieldType &&
                <ErrorOrInfoComp
                  text={errorMessageData.fieldType}
                  _className={"font-red"}
                  icon={<FiAlertCircle />}
                />
              }

              {
                (customFieldVal.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ||
                 customFieldVal.fieldType === FIELD_TYPE_MULTILINE_TEXT ||
                 customFieldVal.fieldType === FIELD_TYPE_DATE ||
                 customFieldVal.fieldType === FIELD_TYPE_NUMBER)
                &&
                <>
                  <div className="form-content-wrapper dropdown-validation">
                    <label><b>Validation</b></label>
                    {
                      customFieldVal.fieldType === FIELD_TYPE_NUMBER ?
                      <div className="view-only-input">
                        {VALIDATION_LABELS[VALIDATION_NUMBER]}
                      </div>
                      :
                      <CustomSelectOption
                          optionListProps={customFieldVal.fieldType === FIELD_TYPE_DATE ? VALIDATION_DATE_ARRAY : VALIDATION_TEXT_ARRAY}
                          valueDropdownProps={validationVal}
                          placeholderProps={"Please select"}
                          onClickDropDownListOptionProps={onSelectValidation}
                          specificClassNameProps={`custom-select-whatsapp-topic" ${errorMessageData.validation ? "border-red" : ""}`}
                          isDisabled={isLoadingCreate || (customFieldVal.fieldType !== FIELD_TYPE_SINGLE_LINE_TEXT && customFieldVal.fieldType !== FIELD_TYPE_DATE)}
                          _className={`${errorMessageData.validation ? "border-red" : ""}`}
                      />
                    }
                  </div>
                  {
                    errorMessageData.validation &&
                    <ErrorOrInfoComp
                      text={errorMessageData.validation}
                      _className={"font-red"}
                      icon={<FiAlertCircle />}
                    />
                  }
                  {
                    (customFieldVal.validation === VALIDATION_FREE_TEXT ||
                     customFieldVal.validation === VALIDATION_NUMERIC ||
                     customFieldVal.validation === VALIDATION_NUMBER ||
                     customFieldVal.validation === VALIDATION_ALPHANUMERIC)
                    &&
                    <ErrorOrInfoComp
                      text={
                        customFieldVal.validation === VALIDATION_FREE_TEXT ? "Field can only be filled with alphabets, numbers, and special characters" :
                        customFieldVal.validation === VALIDATION_NUMERIC ? "Field can only be filled with numbers" :
                        customFieldVal.validation === VALIDATION_NUMBER ? "Field can only be filled with positive or negative number" :
                        customFieldVal.validation === VALIDATION_ALPHANUMERIC ? "Field can only be filled with alphabets and numbers with no space" :
                        ""
                      }
                      _className={"font-black"}
                      icon={<FiInfo />}
                    />
                  }
                </>
              }

              {
                (customFieldVal.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ||
                 customFieldVal.fieldType === FIELD_TYPE_MULTILINE_TEXT)
                &&
                <>
                  <label className="label-left">
                    <b>Limit Maximum Character</b>
                    <span className="optional"> (Optional)</span>
                    <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-char-limit" alt="" />
                    <div>
                      <Tooltip 
                        className="tooltip-char-limit"
                        placement="right"
                        isOpen={showTooltipCharacterLimit}
                        target="tooltip-char-limit"
                        toggle={toggleTooltipCharacterLimit}
                        opacity={1.0}
                      >
                        When filled, this limits how many characters agents can input when editing customer’s info.
                      </Tooltip>
                    </div>
                  </label>
                  <input
                    disabled={isLoadingCreate}
                    type="text"
                    id="maxCharacters"
                    onChange={(e) => onChange(e)}
                    value={customFieldVal.maxCharacters ? '' + customFieldVal.maxCharacters : ''}
                    maxLength={3}
                    placeholder="000"
                    className="input-char-limit"
                  />
                  <span className="characters-label">Characters</span>
                </>
              }
              
              {
                (customFieldVal.fieldType === FIELD_TYPE_DROPDOWN || customFieldVal.fieldType === FIELD_TYPE_MULTIPLE_SELECT) &&
                <>
                  <label><b>Manage Options</b></label>
                  <p className="label-description">{`${fieldTypeVal.label} requires 1 option and you can add up to ${MULTIPLE_OPTION_LIMIT} options.`}</p>
                  {
                    customFieldVal.mappedOptions &&
                    customFieldVal.mappedOptions.map((v, i) => {
                      return (
                        <div 
                          key={`multiple-option-${i}`}
                          draggable={customFieldVal.options.length > 1 && !isLoadingCreate}
                          onDragStart={(e) => onOptionDragStart(e, v)}
                          onDragEnd={(e) => onOptionDragEnd(e)}
                          onDragOver={(e) => onOptionDragOver(e)}
                          onDrop={(e) => onOptionDrop(e, v)}
                        >
                          <label className={`multiple-option-count input-option-${i}`}>
                            <span className={`grey-font ${v.text.length > MULTIPLE_OPTION_MAX_LENGTH ? "red-text" : ""}`}>
                              {v.text.length}/{MULTIPLE_OPTION_MAX_LENGTH} characters
                              {
                                customFieldVal.options.length > 1 &&
                                <FiTrash2 onClick={() => removeOption(i)} className="remove-multiple-option" />
                              }
                            </span>
                          </label>
                          <input
                            className={errorMessageData.options[i] ? `border-red multiple-option-${i} ${customFieldVal.options.length > 1 ? " draggable" : ""}` : `multiple-option-${i} ${customFieldVal.options.length > 1 ? " draggable" : ""}`}
                            type="text"
                            value={v.text}
                            placeholder={`Option ${i + 1}`}
                            disabled={isLoadingCreate}
                            onChange={(e) => onChangeOption(e.target.value, i)}
                          />
                          {
                            customFieldVal.options.length > 1 &&
                            <img
                              src={IconDragDrop}
                              className="drag-indicator"
                              alt=""
                              draggable={false}
                            />
                          }
                          {
                            errorMessageData.options[i] &&
                            <ErrorOrInfoComp 
                              text={errorMessageData.options[i]}
                              icon={<FiInfo />}
                              _className="red-text"
                            />
                          }
                        </div>
                      )
                    })
                  }
                  {
                    customFieldVal.options.length < MULTIPLE_OPTION_LIMIT &&
                    <p className="add-option" onClick={addOption}>
                      <b><FiPlus /> Add Option</b>
                    </p>
                  }
                </>
              }
            </div>
          </div>
        </>
      </div>
      
      <Modal 
        isOpen={isShowCreateFieldModal} 
        className="modal-replace-data" 
        toggle={toggleShowCreateFieldModal}
      >
        <ModalBody>
          <b className="modal-title">
            {`Create ${customFieldVal.isRequired ? "Required" : "Optional"} Custom Field?`}
          </b>

          <p className="modal-desc">
              {
                customFieldVal.isRequired ?
                "You are creating a required custom field, agents will have to fill in information details for this custom field when editing customer contact’s info. Are you sure you want to continue?"
                :
                "Turning off the required toggle will allow agents to not fill in this custom field when editing customer contact’s info. Are you sure you want to continue?"
              }
          </p>

          {
            isLoadingCreate ?
            <>
              <ButtonWithLoadingOrIcon 
                text="Cancel"
                className="main-button-48 no-fill-button-grey"
                onClickAction={() => {}}
              />
              <ButtonWithLoadingOrIcon 
                text="Create"
                className="main-button-48 orange-button"
                loadingColor="gray"
                isDisabled
                isLoading
                position="left"
              />
            </>
            :
            <>
              <ButtonWithLoadingOrIcon 
                text="Cancel"
                className="main-button-48 no-fill-button"
                onClickAction={toggleShowCreateFieldModal}
              />
              <ButtonWithLoadingOrIcon 
                text="Create"
                className="main-button-48 orange-button"
                onClickAction={createCustomField}
              />
            </>
          }
        </ModalBody>
      </Modal>
    </SectionWrap >
  );
};

export default ContactInfoCustomFieldAdd;
