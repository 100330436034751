import React from 'react';
import './ModalIsHideOther.scss';
import { connect } from 'react-redux';
import { taptalk } from '@taptalk.io/web-sdk';
import { FiAlertTriangle } from 'react-icons/fi';
import { setShowOtherCase, clearShowOtherCase } from "../../../../../redux/actions/reduxActionShowOtherCase";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import HelperChat from '../../../../../helper/HelperChat';
import { Modal, ModalBody } from "reactstrap";

let ModalIsHideOther = (props) => {
    return (
        <Modal className="modal-hide-other-case" isOpen={props.showOtherCase.modalWarningHideOther} toggle={() => props.clearShowOtherCase()}>
            <ModalBody> 
                <p className="hide-other-title">
                    <FiAlertTriangle />
                    <b>You Are Not Allowed to Send Message</b>
                </p>

                <p className="hide-other-description"> 
                    You can not send message to the recipient that has on going case that are being handled by other agent. <b>Please contact the agent below if you wish to continue to send a message to the recipient.</b>
                </p>

                {props.showOtherCase.case &&
                    <div className="other-agent-wrapper">
                        <div className="agent-avatar"  style={{background: taptalk.getRandomColor(props.showOtherCase.case.agentFullName)}}>
                            <b>{HelperChat.renderUserAvatarWord(props.showOtherCase.case.agentFullName, false)}</b>
                        </div>

                        <b>{props.showOtherCase.case.agentFullName}</b>
                    </div>
                }

                <div className="hide-other-button-wrapper">
                    <ButtonWithLoadingOrIcon 
                        className="orange-button main-button-48 width-100" 
                        onClickAction={() => props.clearShowOtherCase()}
                        text={"Dismiss"}
                    />
                </div>
            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = state => ({
    showOtherCase: state.showOtherCase
  });

const mapDispatchToProps = {
    setShowOtherCase,
    clearShowOtherCase
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalIsHideOther);

