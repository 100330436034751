import React, { useState, useEffect, useRef } from 'react';
import './IntegrationTelegramEditBotTokenAccount.scss';
import IntegrationTelegramServices from '../../../../services/integration/IntegrationTelegramServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import Tooltip from '../../../reuseableComponent/Tooltip/Tooltip';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';

const IntegrationTelegramEditBotTokenAccount = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingEditBotTokenAccount, setIsLoadingEditBotTokenAccount] = useState(false);
  const [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  const [accountDetail, setAccountDetail] = useState(null);
  const [isValidate, setIsValidate] = useState('validate');
  const [inputWidth, setInputWidth] = useState('721px');
  const [tokenInputBorderColor, setTokenInputBorderColor] = useState('solid 1px #dcdcdc');
  const [validateColor, setValidateColor] = useState('rgba(25, 25, 25, 0.2)');
  const [isTokenInputDisabled, setIsTokenInputDisabled] = useState(false);
  const [inputBotTokenValue, setInputBotTokenValue] = useState('');
  const [inputBotTokenValueOrigin, setInputBotTokenValueOrigin] = useState('');
  const botTokenInput = useRef(null);
  const [botName, setBotName] = useState('');
  const [botUsername, setBotUsername] = useState('');
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  const [errorMessageData, setErrorMessageData] = useState({
    token: ""
  }) 

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (botTokenInput.current !== null) {
      const width = `${botTokenInput.current.offsetWidth -
        botTokenInput.current.lastElementChild.offsetWidth}px`;
      setInputWidth(width);
    }
  }, [isValidate, isLoadingGetDetailData]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (botTokenInput.current.firstChild.contains(event.target)) {
        if (isValidate === 'validate') {
          setTokenInputBorderColor('1px solid #FF7E00');
          setValidateColor('#FF7E00');
        }
        return;
      }

      setTokenInputBorderColor('solid 1px #dcdcdc');

      if (inputBotTokenValue !== '') {
        setValidateColor('#FF7E00');
      } else {
        setValidateColor('rgba(25, 25, 25, 0.2)');
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, [isValidate, inputBotTokenValue]);

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getTelegramAccountDetail(paramID, match.params.accountID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const getTelegramAccountDetail = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);

    IntegrationTelegramServices.getDetail(orgID, accountID, (response) => {
      if (response.dataResult.error.message === "") {
        setAccountDetail(response.dataResult.data.channel);
        setBotName(response.dataResult.data.channel.botName);
        setBotUsername(response.dataResult.data.channel.botUsername);
      } else {
        let _retriveDataError = {...retriveDataError};
        
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetailData(false);
    });
  };

  const editBotToken = () => {
    alert("Asd")
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};
    console.log(inputBotTokenValue)
    if(inputBotTokenValue === "") {
        _errorMessageData.token = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-token" }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      setIsLoadingEditBotTokenAccount(true);
      setIsTokenInputDisabled(true);

      IntegrationTelegramServices.editBotToken(
        orgID,
        match.params.accountID,
        inputBotTokenValue,
        (response) => {
          setIsLoadingEditBotTokenAccount(false);
          setIsTokenInputDisabled(false);

          if (response.dataResult.status === 200) {
            if (!response.dataResult.data.success) {
              doToast(response.dataResult.data.message);
            } else {
              setInputBotTokenValueOrigin(inputBotTokenValue);
              setTimeout(() => {
                history.push(
                  `/o/${orgID}/integration/telegram/detail/${match.params.accountID}`,
                  {
                    state: 'edit-bot-token',
                    message: response.dataResult.data.message,
                  }
                );
                doToast(response.dataResult.error.message, "fail");
              }, 3000);
            }
          } else {
            setIsValidate('validate');
            doToast(response.dataResult.error.message, 'fail');
          }
        }
      );
    }
  };

  const onValidate = () => {
    if (inputBotTokenValue === '') return;
    if (isValidate === 'valid') return;
    setIsValidate('validating');
    setIsTokenInputDisabled(true);

    IntegrationTelegramServices.validateBotToken(
      orgID,
      inputBotTokenValue,
      (response) => {
        setIsTokenInputDisabled(false);
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
            setIsValidate('validate');
          } else {
            doToast(response.dataResult.data.message);
            setIsValidate('valid');
            setBotName(response.dataResult.data.botName);
            setBotUsername(response.dataResult.data.botUsername);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setIsValidate('validate');
        }
      }
    );
  };

  const validateStyled = () => {
    if (isValidate === 'validate') {
      return <div>Validate</div>;
    } else if (isValidate === 'validating') {
      return (
        <div className="validating">
          <div className="lds-ring grey-lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div>Validating</div>
        </div>
      );
    } else if (isValidate === 'valid') {
      return (
        <div className="valid">
          <div
            className={`${isTokenInputDisabled ? 'valid-grey' : 'valid-green'}`}
          ></div>
          <div
            className={`${isTokenInputDisabled ? 'text-grey' : 'text-green'}`}
          >
            Valid
          </div>
        </div>
      );
    }
  };

  const onChangeInputBotToken = (event) => {
    const prevInput = inputBotTokenValue;
    if (event.target.value !== prevInput) {
      setIsValidate('validate');
    }

    let _errorMessageData = {...errorMessageData};
    _errorMessageData.token = "";
    setErrorMessageData(_errorMessageData);
    setInputBotTokenValue(event.target.value);
  };

  // const isFormValid = () => {
  //   return isValidate === 'valid';
  // };

  const back = (id) =>
    history.push(`/o/${orgID}/integration/telegram/detail/${id}`);

  const labelWebPlatform = (
    <div>
      <FaRegQuestionCircle />
    </div>
  );

  const titleTooltip = (
    <div style={{ padding: '8px 12px' }}>
      <p style={{ margin: 0 }}>To obtain Telegram Bot Token please</p>
      <a
        href="https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/telegram-integration"
        className="orange"
        target="_blank"
        rel="noopener noreferrer"
      >
        check out the step-by-step guide
      </a>
    </div>
  );

  const propsTip = {
    content: titleTooltip,
    children: labelWebPlatform,
  };

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID}
        withHeader 
        withSideMenu
        dataOrigin={inputBotTokenValueOrigin}
        dataAfterChange={inputBotTokenValue}
      >
        <div className="sectionWrap integration-main-container integration-telegram-edit-bot-token-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading 
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message} 
              />
            </div>
          ) : (
            <>
              <BackButton
                text={`Back to ${accountDetail.botName}`}
                onClick={() => back(accountDetail.id)}
              />
              <div className="integration-telegram-edit-bot-token-account-box">
                <div className="integration-telegram-edit-bot-token-account-box-top">
                  <b>Edit Bot Token</b>

                  {!isLoadingEditBotTokenAccount ? (
                    <ButtonWithLoadingOrIcon 
                        // isDisabled={!isFormValid()}
                        onClickAction={editBotToken}
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon 
                        isLoading
                        loadingColor="gray"
                        isDisabled
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                    />
                  )}
                </div>

                <div className="integration-telegram-edit-bot-token-account-box-form">
                  <div className="alert-component alert-blue alert-edit-token">
                    <FiInfo />
                    <b>
                      Only edit bot token if the previous bot token has been
                      revoked.
                    </b>
                    <br></br>
                    <b>
                      If you’d like to change bots, create a new channel
                      instead.
                    </b>
                  </div>

                  <div className="form-bot-token">
                    <label>
                      <b className="bot-token-title">Bot Token</b>
                      <Tooltip {...propsTip} interactive />
                    </label>
                    <div className={`bot-token-wrapper input-token ${errorMessageData.token !== "" ? "border-red" : ""}`} ref={botTokenInput}>
                      <input
                        type="text"
                        placeholder="Insert your Bot Token"
                        style={{
                          width: inputWidth,
                          border: tokenInputBorderColor,
                        }}
                        disabled={isTokenInputDisabled || isLoadingEditBotTokenAccount}
                        onChange={onChangeInputBotToken}
                        value={inputBotTokenValue}
                      />
                      <button
                        onClick={onValidate}
                        style={{
                          border: tokenInputBorderColor,
                          color: validateColor,
                        }}
                        disabled={isTokenInputDisabled}
                      >
                        {validateStyled()}
                      </button>
                    </div>
                    {errorMessageData.token !== "" &&
                      <ErrorOrInfoComp
                          text={errorMessageData.token}
                          _className={"font-red"}
                          icon={<FiInfo />}
                      />
                    }
                  </div>

                  <div className="form-bot-username">
                    <label>
                      <b>Bot Username</b>
                    </label>
                    <input
                      type="text"
                      value={botUsername}
                      disabled
                      className="form-disabled"
                    />
                  </div>

                  <div className="form-bot-name">
                    <label>
                      <b>Bot Name</b>
                    </label>
                    <input type="text" value={botName} disabled />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationTelegramEditBotTokenAccount;
