import React, { useState } from "react";
import "./IntegrationGoogleCreate.scss";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import StepComp from '../../../reuseableComponent/stepComp/StepComp';
import IntegrationGoogleCreateTopic from './IntegrationGoogleCreateTopic/IntegrationGoogleCreateTopic';
import IntegrationGoogleCreateChannel from './IntegrationGoogleCreateChannel/IntegrationGoogleCreateChannel';
import IntegrationGoogleCreateGreeting from './IntegrationGoogleCreateGreeting/IntegrationGoogleCreateGreeting';
import IntegrationGoogleCreateEntry from "./IntegrationGoogleCreateEntry/IntegrationGoogleCreateEntry";
import { connect } from "react-redux";

let IntegrationGoogleCreate = (props) => {
    let [activeForm, setActiveForm] = useState(0);

    let back = () => {        
        props.history.push(`/o/${props.match.params.orgID}/integration/google-business-messages`)
    }

    const FORM_STEP = [
        {
            label: "Select Topic",
        },
        {
            label: "Channel Profile"
        },
        {
            label: "Greeting Message"
        },
        {
            label: "Entry Points"
        }
    ];

    return (
        <div className="main-integration-google-create">
            <SectionWrap 
                {...props} 
                orgID={props.match.params.orgID} 
                withHeader 
                withSideMenu
                dataOrigin={JSON.stringify(props.createGoogle.origin)}
                dataAfterChange={JSON.stringify(props.createGoogle.data)}
            >
                <div className="sectionWrap integration-main-container integration-google-add-account text-left">
                    <BackButton text="Back to Google Business Messages" onClick={back} />

                    <div className="integration-google-add-box">
                        <p className="integration-goggle-add-title">
                            <b>Create Channel</b>
                        </p>

                        <StepComp 
                            steps={FORM_STEP}
                            activeForm={activeForm}
                        />

                        <div className="integration-google-add-account-main">
                            {activeForm === 0 &&
                                <IntegrationGoogleCreateTopic 
                                    {...props}
                                    _setActiveForm={setActiveForm}
                                />
                            }

                            {activeForm === 1 &&
                                <IntegrationGoogleCreateChannel
                                    {...props}
                                    _setActiveForm={setActiveForm}
                                />
                            }

                            {activeForm === 2 &&
                                <IntegrationGoogleCreateGreeting
                                    {...props}
                                    _setActiveForm={setActiveForm}
                                />
                            }

                            {activeForm === 3 &&
                                <IntegrationGoogleCreateEntry
                                    {...props}
                                    _setActiveForm={setActiveForm}
                                />
                            }
                        </div>
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

const mapStateToProps = state => ({
    createGoogle: state.createGoogle
});
  
const mapDispatchToProps = {
};
  
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleCreate);
