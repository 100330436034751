import React from "react";
import "./PopupWarning.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiAlertTriangle } from "react-icons/fi";

let PopupWarning = (props) => {
    return (
        <Modal isOpen={props.isShow} toggle={props.toggle} className="modal-warning">
            <ModalBody>
                <FiAlertTriangle />

                <p>
                    {props.text}
                </p>

                <button className="orange-button" onClick={props.toggle}>
                    <b>Dismiss</b>
                </button>
            </ModalBody>
        </Modal>
    )
}

export default PopupWarning;