import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// import "../auth/Auth.css";
import './Integrations.scss';
import 'react-toastify/dist/ReactToastify.css';
// import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
// import {
//   checkID,
//   // doToast
// } from "../../helper/HelperGeneral";
import TelegramSideMenu from '../../assets/img/integration/logo-telegram-side-menu.svg';
import FacebookSideMenu from '../../assets/img/integration/logo-facebook-side-menu.svg';
import LineSideMenu from '../../assets/img/integration/logo-line-side-menu.svg';
import TwitterSideMenu from '../../assets/img/integration/logo-twitter-side-menu.svg';
import WhatsappSideMenu from '../../assets/img/integration/logo-whatsapp-side-menu.svg';
import TaptalkSideMenu from '../../assets/img/integration/logo-taptalk-side-menu.svg';
// import AndroidSideMenu from "../../assets/img/integration/logo-android-side-menu.svg";
// import IosSideMenu from "../../assets/img/integration/logo-iOS-side-menu.svg";

// import IntegrationLine from "./integrationLine/IntegrationLine";
// import IntegrationWhatsapp from "./integrationWhatsapp/IntegrationWhatsapp";
// import IntegrationTelegram from "./integrationTelegram/IntegrationTelegram";
// import IntegrationTwitter from "./integrationTwitter/IntegrationTwitter";
// import IntegrationFacebook from "./integrationFacebookMessanger/IntegrationFacebookMessanger";
// import IntegrationTaptalk from "./integrationTaptalk/IntegrationTaptalk";
// import IntegrationAndroid from "./integrationAndroid/IntegrationAndroid";
// import IntegrationIOS from "./integrationIOS/IntegrationIOS";

// import ServiceOrganization from "../../services/organization/ServiceOrganization";

import { Scrollbars } from 'react-custom-scrollbars';

const VALUE_INTEGRATION_CHANNEL = {
  TELEGRAM: 1,
  LINE: 2,
  WHATSAPP: 3,
  TWITTER: 4,
  FACEBOOK: 5,
  TAPTALK: 6,
  IOS: 7,
  ANDROID: 8,
};

const INTEGRATION_CHANNEL = {
  launcher: {
    name: 'Live Chat',
    image: TaptalkSideMenu,
    value: VALUE_INTEGRATION_CHANNEL.TAPTALK,
    text: 'launcher',
  },
  telegram: {
    name: 'Telegram',
    image: TelegramSideMenu,
    value: VALUE_INTEGRATION_CHANNEL.TELEGRAM,
    text: 'telegram',
  },
  whatsapp: {
    name: 'WhatsApp',
    image: WhatsappSideMenu,
    value: VALUE_INTEGRATION_CHANNEL.WHATSAPP,
    text: 'whatsapp',
  },
  twitter: {
    name: 'Twitter DM',
    image: TwitterSideMenu,
    value: VALUE_INTEGRATION_CHANNEL.TWITTER,
    text: 'twitter',
  },
  line: {
    name: 'LINE',
    image: LineSideMenu,
    value: VALUE_INTEGRATION_CHANNEL.LINE,
    text: 'line',
  },
  messenger: {
    name: 'Facebook Messenger',
    image: FacebookSideMenu,
    value: VALUE_INTEGRATION_CHANNEL.FACEBOOK,
    text: 'messenger',
  },
  // ios: {
  //   name: "iOS",
  //   image: IosSideMenu,
  //   value: VALUE_INTEGRATION_CHANNEL.IOS,
  //   text: "ios"
  // },
  // android: {
  //   name: "Android",
  //   image: AndroidSideMenu,
  //   value: VALUE_INTEGRATION_CHANNEL.ANDROID,
  //   text: "android"
  // },
};

const STYLE = {
  SCROLL_STYLE: {
    position: 'relative',
    backgroundColor: '#ff7d00',
    right: '-5px',
    width: '3px',
    borderRadius: '8px',
  },
};

const Integrations = (props) => {
  let [activeIntegrationMenu, setActiveIntegrationMenu] = useState('launcher');
  // const doMount = () => {
  //   const paramID = checkID(match);
  //   if (paramID) {
  //     setOrgID(paramID);
  //   } else {
  //     ServiceOrganization.postGetOrganization((response) => {
  //       if(response.dataResult.error !== "") {
  //         let responseData = response.dataResult.data;
  //         if (responseData.memberships.length < 1) window.location.href = "/organization/new";
  //         const { id, createdTime } = responseData.memberships[0].organization;
  //         const urlOrgID = `${id}-${createdTime}`;
  //         history.push(`/o/${urlOrgID}/integration`);
  //       }else {
  //         console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
  //       }
  //     });
  //   }
  // };

  // let getChannel = (url) => {
  //   return url.split("/");
  // }

  // React.useEffect(() => {
  //   doMount();
  //   if(!INTEGRATION_CHANNEL[getChannel(location.pathname)[4]]) {
  //     window.location.href = location.pathname+"/launcher";
  //   }
  // }, [location.pathname]);

  React.useEffect(() => {
    // props.orgIDProps !== false
    if (props.activeIntegrationChannel) {
      setActiveIntegrationMenu(props.activeIntegrationChannel);
    }
  }, []);

  return (
    <React.Fragment>
      {/* <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      > */}
      <div className="sectionWrap integration-container">
        <div className="integration-side-menu-wrapper">
          <Scrollbars
            autoHide
            autoHideTimeout={500}
            renderThumbVertical={(props) => (
              <div {...props} style={STYLE.SCROLL_STYLE} />
            )}
          >
            {Object.keys(INTEGRATION_CHANNEL).map((value, index) => {
              return (
                <NavLink
                  to={`/o/${props.orgIDProps}/integration/${INTEGRATION_CHANNEL[value].text}`}
                  key={`integration-${index}`}
                >
                  <div
                    className={`integration-side-menu-option ${
                      activeIntegrationMenu === INTEGRATION_CHANNEL[value].text
                        ? 'active-integration-side-menu'
                        : ''
                    }`}
                  >
                    <img src={INTEGRATION_CHANNEL[value].image} alt="" />
                    <b>{INTEGRATION_CHANNEL[value].name}</b>
                  </div>
                </NavLink>
              );
            })}
          </Scrollbars>
        </div>

        {/* <div className="integration-main-content"> */}
        {/* {activeIntegrationMenu === VALUE_INTEGRATION_CHANNEL.TELEGRAM &&
                    <IntegrationTelegram {...props} />
                }

                {activeIntegrationMenu === VALUE_INTEGRATION_CHANNEL.LINE &&
                    <IntegrationLine {...props} />
                }

                {activeIntegrationMenu === VALUE_INTEGRATION_CHANNEL.WHATSAPP &&
                    <IntegrationWhatsapp {...props} />
                }

                {activeIntegrationMenu === VALUE_INTEGRATION_CHANNEL.TWITTER &&
                    <IntegrationTwitter {...props} />
                }
 
                {activeIntegrationMenu === VALUE_INTEGRATION_CHANNEL.FACEBOOK &&
                    <IntegrationFacebook {...props} />
                }

                {activeIntegrationMenu === VALUE_INTEGRATION_CHANNEL.TAPTALK &&
                    <IntegrationTaptalk {...props} />
                }

                {activeIntegrationMenu === VALUE_INTEGRATION_CHANNEL.IOS &&
                    <IntegrationIOS {...props} />
                }

                {activeIntegrationMenu === VALUE_INTEGRATION_CHANNEL.ANDROID &&
                    <IntegrationAndroid {...props} />
                } */}
        {/* </div> */}
      </div>
      {/* </SectionWrap> */}
      <ToastContainer className="ToastContainer" />
    </React.Fragment>
  );
};

export default Integrations;
