import React from "react";
import "./ModalPreviewImageOrVideo.scss";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { setPreviewImageOrVideo, clearPreviewImageOrVideo } from "../../../redux/actions/reduxActionPreviewImageOrVideo";
import { IoIosClose } from "react-icons/io";

let ModalPreviewImageOrVideo = (props) => {
    return (
        <Modal isOpen={props.previewImageOrVideo.fileURL ? true : false} toggle={props.clearPreviewImageOrVideo} className={'modal-preview-image-or-video-reuseable'}>
            <ModalBody>
                <IoIosClose className="close-modal-image-video" onClick={props.clearPreviewImageOrVideo} />

                {props.previewImageOrVideo.fileType === "image" &&
                    <div className="image-wrapper">
                        <img src={props.previewImageOrVideo.fileURL} alt="" />
                    </div>
                }

                {props.previewImageOrVideo.fileType === "video" &&
                    <div className="video-wrapper">
                        <video controls>
                            <source src={props.previewImageOrVideo.fileURL} type="video/mp4" />
                            <source src={props.previewImageOrVideo.fileURL} type="video/ogg" />
                        </video>
                    </div>
                }
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = state => ({
    previewImageOrVideo: state.previewImageOrVideo
});
  
const mapDispatchToProps = {
    setPreviewImageOrVideo,
    clearPreviewImageOrVideo
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ModalPreviewImageOrVideo);