import React from 'react';
import './ModalSending.scss';
import { connect } from 'react-redux';
import { Modal, ModalBody } from "reactstrap";

let ModalSending = (props) => {
    return (
        <Modal className="modal-sending" isOpen={props.isShowModalSendingProps}>
            <ModalBody> 
                <div className="lds-ring">
                    <div /><div /><div /><div />
                </div>

                <p>
                    Sending... Please make sure you have a stable connection.
                </p>
            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = state => ({
    changeContactInfo: state.changeContactInfo,
    caseSortBy: state.caseSortBy,
    countryList: state.countryList
});

export default connect(mapStateToProps, null)(ModalSending);
