import React, { useEffect, useState } from "react";
import "./IntegrationTaptalkEditAccount.scss";
// import Integrations from "../../Integrations";
import { FiInfo } from "react-icons/fi";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import "react-toastify/dist/ReactToastify.css";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";
import IntegrationTaptalkServices from '../../../../services/integration/IntegrationTaptalkServices';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const IntegrationTaptalkEditAccount = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingDetailVal, setIsLoadingDetailVal] = useState(true);
    let [isLoadingEditAccount, setIsLoadingEditAccount] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [topicList, setTopicList] = useState([]);
    let [topicListOrigin, setTopicListOrigin] = useState([]);
    let [isCheckAll, setIsCheckAll] = useState(false);
    let [selectedTopic, setSelectedTopic] = useState([]);
    let [selectedTopicOrigin, setSelectedTopicOrigin] = useState([]);
    let [searchTopic, setSearchTopic] = useState("");
    let [errorMessageData, setErrorMessageData] = useState({
        topic: ""
    }) 
    let [identifierName, setIdentiferName] = useState("");

    const back = (id) => {
        if(!isLoadingEditAccount) {
            history.push(`/o/${orgID}/integration/launcher/detail/${id}`)
        }
    };

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
            getAccountDetail(paramID, match.params.accountID)
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        topicServices.getTopic(orgID, "list", null, (response) => {
            const data = response.dataResult.data.topics;
            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.id = val.id;
                    _data.name = val.name;
                    newData.push(_data);
                    return null;
                });
                setTopicList(newData);
                setTopicListOrigin(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if (orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    let getAccountDetail = (orgID, accountID) => {
        setIsLoadingDetailVal(true);

        IntegrationTaptalkServices.getConfig(orgID, accountID, (response) => {
            let _retriveDataError = { ...retriveDataError };

            if (response.dataResult.error.message === "") {
                if(response.dataResult.data.topics.length > 0) {
                    response.dataResult.data.topics.map((v) => {
                        v.value = v.id;
                        return null;
                    });

                    setSelectedTopic(response.dataResult.data.topics);
                    setSelectedTopicOrigin(response.dataResult.data.topics);
                }

                setIdentiferName(response.dataResult.data.secretKey.identifier);
            } else {
                _retriveDataError.message = response.dataResult.error.message;
                _retriveDataError.code = response.dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsLoadingDetailVal(false);
        })
    }

    let editAccount = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = {...errorMessageData};

        if(selectedTopic.length === 0) {
            _errorMessageData.topic = "Please select topic";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-topic" }
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

            let topics = [];

            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Button] Edit Channel",
                    {
                        channel: "WhatsApp SME",
                        userFullName: myAgentData.account.fullName,
                        userID: myAgentData.account.id,
                        organizationID: orgID.split("-")[0]
                    }
                );
            }

            setIsLoadingEditAccount(true);

            selectedTopic.map((v) => {
                topics.push(v.id);
                return null;
            })

            let data = {
                id: parseInt(props.match.params.accountID),
                topicIDs: topics
            }

            IntegrationTaptalkServices.editTopic(orgID, data, (response) => {
                if (response.dataResult.error.message === "") {
                    if (!response.dataResult.data.success) {
                        doToast(response.dataResult.data.message);
                    } else {
                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                            mixpanel.track(
                                "[Action] Edit Topic",
                                {
                                    channel: "Launcher",
                                    userFullName: myAgentData.account.fullName,
                                    userID: myAgentData.account.id,
                                    organizationID: orgID.split("-")[0]
                                }
                            );
                        }

                        setSelectedTopicOrigin(selectedTopic);
                        doToast(response.dataResult.data.message);
                        
                        setTimeout(() => {
                            back(props.match.params.accountID);
                        }, 3000)
                    }
                } else {
                    setIsLoadingEditAccount(false);
                    doToast(response.dataResult.error.message, "fail");
                }

            })
        }
    };

    let onChangeSingleSelect = (e, val) => {
        if(!isLoadingEditAccount) {
            let _errorMessageData = {...errorMessageData};
            _errorMessageData.topic = "";
            setErrorMessageData(_errorMessageData);
            let _selectedTopic = [...selectedTopic]
            if (e.target.checked) {
                _selectedTopic = [..._selectedTopic, val]
            } else {
                const _dataSelectedTopic = [..._selectedTopic]
                _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
                setIsCheckAll(false);
            }
    
            setSelectedTopic(_selectedTopic);
        }
    }

    const onClearAll = () => {
        if(!isLoadingEditAccount) {
            setIsCheckAll(false);
            setSelectedTopic([]);
        }
    }

    let onCheckAll = (e) => {
        if(!isLoadingEditAccount) {
            let _errorMessageData = {...errorMessageData};
            _errorMessageData.topic= "";
            setErrorMessageData(_errorMessageData);
            if (e.target.checked) {
                setSelectedTopic(topicList);
            } else {
                setSelectedTopic([]);
            }
    
            setIsCheckAll(e.target.checked);
        }
    }

    let isChecked = (id) => {
        const _data = [...selectedTopic];
        return _data.findIndex(val => val.id === id) !== -1;
    }

    let removeSingleTopic = (val) => {
        setIsCheckAll(false);
        let _selectedTopic = [...selectedTopic];
        const _dataSelectedTopic = [..._selectedTopic]

        _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

        setSelectedTopic(_selectedTopic);
    }

    let onChangeSearchTopic = (e) => {
        let _topicOrigin = topicListOrigin.slice();
        let result = [];
        setSearchTopic(e.target.value);

        if (_topicOrigin.length > 0) {
            for (let i in _topicOrigin) {
            let objectKey = Object.keys(_topicOrigin[i]);

            for (let j in objectKey) {
                let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
                if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
                result.push(_topicOrigin[i])
                break;
                }
            }
            }
        }

        setTopicList(result);
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={JSON.stringify(selectedTopicOrigin)}
                dataAfterChange={JSON.stringify(selectedTopic)}
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

                <div className="sectionWrap integration-main-container integration-taptalk-edit-account text-left">
                    {isLoadingDetailVal || retriveDataError.code ?
                        <div className="integration-detail-loading-wrapper">
                            {/* <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div> */}

                            <RetrieveDataLoading
                                isLoading={isLoadingDetailVal}
                                errorMessage={retriveDataError.message}
                            />
                        </div>
                        :
                        <React.Fragment>
                            <BackButton text={`Back to ${identifierName}`} onClick={() => back(props.match.params.accountID)} />

                            <div className="integration-taptalk-edit-account-box">
                                <div className="integration-taptalk-edit-account-box-top">
                                    <b>Edit Topic</b>

                                    {!isLoadingEditAccount ?
                                        <ButtonWithLoadingOrIcon
                                            className="orange-button main-button-40"
                                            onClickAction={() => editAccount()}
                                            text="Save Changes"
                                            // isDisabled={!isFormValid()}
                                        />
                                        :
                                        <ButtonWithLoadingOrIcon
                                            isLoading
                                            loadingColor="gray"
                                            isDisabled
                                            className="orange-button main-button-40"
                                            text="Save Changes"
                                            position="left"
                                        />
                                    }
                                </div>

                                <div className="integration-taptalk-edit-account-box-form">
                                    <SelectTopic 
                                        onChangeSearchTopic={onChangeSearchTopic}
                                        onCheckAll={onCheckAll}
                                        onClearAll={onClearAll}
                                        topicList={topicList}
                                        onChangeSingleSelect={onChangeSingleSelect}
                                        isChecked={isChecked}
                                        isCheckAll={isCheckAll}
                                        searchTopic={searchTopic}
                                        title={"Topic"}
                                        selectedTopic={selectedTopic}
                                        removeSingleTopic={removeSingleTopic}
                                        isDisabled={isLoadingEditAccount}
                                        _className={errorMessageData.topic !== "" ? "input-topic border-red" : "input-topic"}
                                    />

                                    {errorMessageData.topic !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.topic === "" ? "Customer will be able to choose if you select more than one topics." : errorMessageData.topic}
                                            _className={errorMessageData.topic !== "" ? "font-red" : ""}
                                            icon={<FiInfo />}
                                        />
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationTaptalkEditAccount;