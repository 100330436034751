const INITIAL_STATE = {
  openCase: false,
  conversation: false,
  isForward: false
};

let reduxReducerStartConversation = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_START_CONVERSATION':
        return action.startConversation;
      case 'CLEAR_START_CONVERSATION':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerStartConversation;