import React, { useState } from "react";
import { FiX, FiHelpCircle } from "react-icons/fi";
import "./BroadcastMessageFormTextMessage.scss";
import { Tooltip } from 'reactstrap';

let BroadcastMessageFormTextMessage = (props) => {
    let [isShowModalAddVariable, setIsShowModalAddVariable] = useState(false);
    let [tooltipOpen, setTooltipOpen] = useState(false);

    let toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    let onClickVariable = (variable) => {
        let generateNewString = () => {
            let val = props.broadCastMessageDataProps.messages[props._index].body;
            let target = document.querySelectorAll(`.message-${props._index}`)[0];
            let lastCursor = target.selectionStart;
            return val.substring(0, lastCursor)+variable+val.substring(lastCursor, val.length);
        }

        props.onChangeVariableMessageProps(generateNewString(), "message", props._index)
        setIsShowModalAddVariable(false);
    }

    return (
        <>
            <label><b>Message</b></label>

            <div className="bc-textarea-wrapper">
                <textarea 
                    maxLength="4000"
                    className={`message-${props._index}`}
                    id="body" 
                    onChange={(e) => props.onChangeBroadcastMessageProps(e, props._index)} 
                    value={props.broadCastMessageDataProps.messages[props._index].body} 
                    placeholder="Enter message here..."
                />

                <div className="broadcast-message-action-wrapper">
                    <div className="broadcast-message-action-inner-wrapper">
                        <b className="add-variable-title">Add Variable <FiHelpCircle href="#" id="TooltipVariable" /></b>
                        <Tooltip placement="right" autohide={false}  isOpen={tooltipOpen} target="TooltipVariable" toggle={toggleTooltip} className="reactstrap-tooltip">
                            Variable can be used to send a dynamic word and replacing the word with recipient's data (ex: name or phone number)
                        </Tooltip>
                        
                        <label className="button-add-var" onClick={() => onClickVariable("{{contact.full_name}}")}>
                            <b>Full Name</b>
                        </label>

                        <label className="button-add-var" onClick={() => onClickVariable("{{contact.alias}}")}>
                            <b>Alias</b>
                        </label>

                        <label className="button-add-var" onClick={() => onClickVariable("{{contact.email}}")}>
                            <b>Email</b>
                        </label>

                        <label className="button-add-var" onClick={() => onClickVariable("{{contact.phone}}")}>
                            <b>Phone Number</b>
                        </label>
                    </div>

                    {isShowModalAddVariable &&
                        <div className="add-variable-panel-wrapper form-content-wrapper">
                            <form 
                                onSubmit={(e) => {
                                    // onSubmitVariable(e, index, variableVal);
                                    setIsShowModalAddVariable(false);
                                }}
                            >
                                <b className="add-variable-panel-title">Add Variable</b>
                                <FiX className="button-close" onClick={() =>  setIsShowModalAddVariable(false)} />

                                {/* <div className="input-info-wrapper">
                                    <FiAlertCircle />
                                    <b>Lowercase characters and number only</b>
                                </div> */}

                                <br />

                                <button className="button-variable" onClick={() => onClickVariable("{{contact.full_name}}")}>
                                <b>Full Name</b>
                                </button>

                                <button className="button-variable" onClick={() => onClickVariable("{{contact.alias}}")}>
                                <b>Alias</b>
                                </button>

                                <button className="button-variable" onClick={() => onClickVariable("{{contact.email}}")}>
                                <b>Email</b>
                                </button>

                                <button className="button-variable" onClick={() => onClickVariable("{{contact.phone}}")}>
                                <b>Phone Number</b>
                                </button>

                                {/* <ul>
                                    <li>
                                        <b>Letters: </b>lowercase only
                                    </li>
                                    <li>
                                        <b>Numbers: </b>allowed
                                    </li>
                                    <li>
                                        <b>Special characters: </b>not allowed
                                    </li>
                                    <li>
                                        <b>Spaces: </b>not allowed
                                    </li>
                                </ul> */}
                            </form>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default BroadcastMessageFormTextMessage;