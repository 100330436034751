import React from "react";
import { withFormik } from "formik";
import NewButton from "../reuseableComponent/NewButton/NewButton";
import Loading from "../reuseableComponent/loading/Loading";

const assignList = [
  {
    value: "unassigned",
    desc: "New cases will not be automatically assigned to agents."
  },
  {
    value: "round robin",
    desc:
      "New cases will be evenly distributed and assigned to each available agent in alphabetical order."
  },
  {
    value: "balanced",
    desc:
      "New cases will be assigned to agents with the fewest open cases first."
  }
];

const OptionAssign = ({ onChange, assignValue: assign }) => {
  const checked = value => (assign === value ? true : false);
  return assignList.map(item => {
    const { value, desc } = item;
    return (
      <div className="custom-radio checkArea" key={value}>
        <div className="input-wrapper">
          <input
            type="radio"
            name="assign"
            id={value}
            value={value}
            onChange={onChange}
            checked={checked(value)}
            disabled
          />
          <label htmlFor={value} />
        </div>
        <div>
          <label htmlFor={value} className="value">
            {value}
          </label>
          <br />
          {desc}
        </div>
      </div>
    );
  });
};

const AssignRulesView = props => {
  const { handleChange, handleSubmit, values } = props;
  const { assign, loading } = values;
  const textBtn = loading ? <Loading /> : "Save Changes";

  return (
    <form onSubmit={handleSubmit}>
      <OptionAssign onChange={handleChange} assignValue={assign} />
      <br />
      <p>
        <NewButton
          type="submit"
          className="orange-button wide-button"
          text={textBtn}
          disabled={true}
        />
      </p>
    </form>
  );
};

const AssignRulesForm = withFormik({
  mapPropsToValues: () => ({ assign: "unassigned", loading: false }),
  handleSubmit: (values, { setFieldValue }) => {
    setFieldValue("loading", true);
    setTimeout(() => setFieldValue("loading", false), 5500);
  }
})(AssignRulesView);

export default AssignRulesForm;
