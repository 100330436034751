import React, { Component } from 'react';
import './NewOrganizationHeader.css';
import Logo from '../../../assets/img/taptalk-logo.png';
import { connect } from 'react-redux';
import ServiceAuthAccessToken from "../../../services/auth/ServiceAuthAccessToken";
import { taptalk } from "@taptalk.io/web-sdk";
import { setLogout } from "../../../redux/actions/reduxLogout";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";

const style = {
  logo: {
    width: "145px",
    position: "relative",
    top: "3px"
  },
  faCog: {
    position: "absolute",
    top: "25px",
    right: "20px"
  }
};

class NewOrganizationHeader extends Component {
  constructor(props) {
    super(props);

    this.toggleProfileIcon = this.toggleProfileIcon.bind(this);

    this.state = {
      dropdownProfileIconOpen: false,
    };
  }

  toggleProfileIcon() {
    this.setState(prevState => ({
      dropdownProfileIconOpen: !prevState.dropdownProfileIconOpen
    }));
  }

  onClickLogout = () => {
    this.setState({
      isWaitForResponseLogout: true
    });

    let userData = HelperGetLocalStorage.getLocalStorageData("user");

    if(userData) {
      if(taptalk.isAuthenticated()) {
        taptalk.logoutAndClearAllTapTalkData({
          onSuccess: (response) => {
              console.log(response)
          }
        });
      }

      let accessToken = HelperCustomEncryptor.doDecrypt(userData.data).accessToken;
      ServiceAuthAccessToken.postLogout(accessToken).then(response => { 
        if(response.dataResult.error.code !== "") {
          this.setState({
            isWaitForResponseLogout: false
          });
        }else {
          console.log(response.dataResult.error.message);
        }
      });

      this.props.setLogout();

      setTimeout(() => {
        window.location.href = window.location.origin + "/";
      }, 250);
    }else { 
      window.location.href = window.location.origin + "/";
    }
  }

  render() {
    return (
        <div>
          <div id="new-organization-header">
              <div id="new-organization-header-wrapper">
                  <div id="new-organization-header-content">
                      <img src={Logo} alt="Tap Talk Logo" style={style.logo} />

                      <div id="new-organization-title">
                        {this.props.titleText ? this.props.titleText : "Get Started"}
                      </div>

                      <b className="logout-organization" onClick={() => this.onClickLogout()}>
                        Logout
                      </b>
                  </div>
              </div>
          </div>
        </div>
    );
  }
}

const mapDispatchToProps = {
  setLogout
};

export default connect(null, mapDispatchToProps)(NewOrganizationHeader)
