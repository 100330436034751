import React, { useEffect } from "react";
import "./ButtonTooltipDropdown.scss";
import ButtonWithLoadingOrIcon from "../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

const ButtonTooltipDropdown = ({
  isLoading,
  icon,
  children,
  mainStyles = {},
  buttonText,
  mainProps,
  isShow,
  toggle,
  _className
}) => {
  useEffect(() => {
    if(mainProps.activeRoom) {
      toggle(false)
    }
  }, [mainProps.activeRoom])

  return (
    <div className={`tooltip-dropdown-button-wrapper ${_className}`} style={mainStyles}>
      <div
        className="tooltip-dropdown-button"
        onClick={toggle}
        id="tooltip-mark-case"
      >
        {!isLoading ? (
          <ButtonWithLoadingOrIcon
            className="orange-button main-button-40"
            icon={icon}
            text={buttonText}
            position="right"
          />
        ) : (
          <ButtonWithLoadingOrIcon
            className="orange-button main-button-40"
            text={buttonText}
            position="left"
            isDisabled
            isLoading
            loadingColor="gray"
          />
        )}
      </div>
      {isShow && <div className="tooltip-dropdown">{children}</div>}
      {/* <Tooltip 
      className="tooltip-content" 
      placement="bottom-end" 
      isOpen={showTooltipMarkCase} 
      target="tooltip-mark-case" 
      toggle={toggleTooltip}
      trigger="click focus"
      autohide={false}
    >
      Alias will be added as a prefix to the user’s name e.g Alias (Full Name)
    </Tooltip> */}
    </div>
  );
};

export default ButtonTooltipDropdown;
