const INITIAL_STATE = false;

let reduxReducerAllowReplyOnAway = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ALLOW_REPLY_ON_AWAY':
        return action.allowReplyOnAway;
      case 'CLEAR_ALLOW_REPLY_ON_AWAY':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerAllowReplyOnAway;