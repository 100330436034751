const INITIAL_STATE = "";

let reduxReducerSalesTalkErrorServer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_SALESTALK_ERROR_SERVER':
        return action.salesTalkErrorServer;
      case 'CLEAR_SALESTALK_ERROR_SERVER':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerSalesTalkErrorServer;