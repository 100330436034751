import React, { Component } from "react";
import "./AccountSettingMyProfile.scss";
import dummyUserAvatar from "../../../assets/img/dummy-user-avatar.svg";
import ServiceProfile from "../../../services/profile/ServiceProfile";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import { checkID } from "../../../helper/HelperGeneral";
import HelperInput from "../../../helper/HelperInput";
import "react-toastify/dist/ReactToastify.css";
import iconEditOrange from "../../../assets/img/icon-edit.svg";
import { NavLink } from "react-router-dom";

const style = {
  keyStyle: {
    position: "relative",
    top: "-3px"
  },
  lockStyle: {
    position: "relative",
    top: "-2px"
  },
  marginBottom: {
    marginBottom: 0
  },
  divTwoFactor: {
    position: "relative",
    width: "50%",
    display: "inline-block"
  },
  lastWrapper: {
    borderBottom: 0
  }
};

class AccountSettingMyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
        userImage: dummyUserAvatar,
        name: "",
        email: "",
        phone: "",
        orgData: "",
        contactNumber: "",
        isLoading: true,
        retrieveDataStatus: {
          code: false,
          message: false
        },
        orgID: "",
    };
  }

  onChangeInput = (e) => {
    this.setState({
      [e.target.id] : [e.target.value]
    })
  }

  getUserProfile = () => {
    ServiceProfile.postGetProfile((response) => {
      if(response.dataResult.error.message === "") {
        let accountData = response.dataResult.data.account;
        let indexOrg = response.dataResult.data.memberships.findIndex(val => val.organization.id === parseInt(this.state.orgID));
        this.setState({
          orgData: response.dataResult.data.memberships[indexOrg],
          retrieveDataStatus: {
            code: false,
            message: false
          },
          isLoading: false,
          name: accountData.fullName,
          email: accountData.email,
          phone: accountData.phoneWithCode,
          userImage: accountData.imageURL.thumbnail !== "" ? accountData.imageURL.thumbnail : dummyUserAvatar
        })
      }else {
        this.setState({
          isLoading: false,
          retrieveDataStatus: {
            code: response.dataResult.error.code,
            message: response.dataResult.error.message
          }
        })

        console.log(response.dataResult.error.message);
      }
    });
  }

  doMount() {
    // setUserData(HelperCustomEncryptor.doDecrypt(props.user.data));
    const paramID = checkID(this.props.match);
    if (paramID) {
      this.setState({
        orgID: paramID
      });
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          this.props.history.push(`/o/${urlOrgID}/account/security`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  
  componentDidMount() {
    this.doMount();
    this.getUserProfile();
  }

  isActiveOrganizationCheck(orgID) {
    let orgName = "";
    if (this.state.orgID) {
      let matchParam = this.state.orgID;
      
      return matchParam.split("-")[0] === orgID.toString();
    }
    
    return orgName;
  }

  
  render() {
    return (
      <React.Fragment>
        <SectionWrap
          {...this.props}
          orgID={this.state.orgID}
          withHeader
          withSideMenu
        >
            <div className="sectionWrap">
              <div id="page-account-my-profile">
                
                <React.Fragment>
                  <div className="account-setting-card">
                    <div className="account-setting-card-title">
                      <b>Profile</b>

                      <div className="my-profile-input account-setting-save-wrapper" style={style.lastWrapper}>
                        <NavLink to={`${this.props.location.pathname}/edit`}>
                          <button className="no-fill-button">
                            <img src={iconEditOrange} alt="" />
                            Edit
                          </button>
                        </NavLink>
                      </div>
                    </div>

                    {(this.state.isLoading || (this.state.retrieveDataStatus.code && this.state.retrieveDataStatus.code !== "49901")) ?
                      <RetrieveDataLoading 
                        isLoading={this.state.isLoading}
                        errorMessage={this.state.retrieveDataStatus.message} 
                      />
                      :
                      <div className="my-profile-input" style={style.lastWrapper}>
                          {/* <div className="profile-avatar-wrapper">
                              <img src={this.state.userImage} alt="" />

                              <input type="file" id="profile-avatar-file" />
                              
                              <label htmlFor="profile-avatar-file" className="no-fill-button">
                                <img src={iconEditOrange} alt="" />
                                <b>
                                  Change Photo
                                </b>
                              </label>
                          </div> */}

                          {/* <div className="my-profile-input form-content-wrapper">
                              <label><b>Organization Name</b></label>
                              <b>{this.state.orgData.organization.name}</b>
                          </div> */}
                        
                          <div className="my-profile-input form-content-wrapper">
                              <label><b>Account Name</b></label>
                              <b>{this.state.name}</b>
                          </div>
                          
                          <div className="my-profile-input form-content-wrapper">
                              <label><b>Email</b></label>
                              <b>{this.state.email}</b>
                          </div>

                          <div className="my-profile-input form-content-wrapper">
                              <label><b>Phone Number</b></label>
                              <b>{this.state.phone === "" ? "-" : "+"+HelperInput.phoneBeautify(this.state.phone)}</b>
                          </div>
                        </div>
                      }
                  </div>
                </React.Fragment>
              </div>
            </div>
        </SectionWrap>
      </React.Fragment>
    );
  }
}

export default AccountSettingMyProfile;
