import React from "react";
import "./CountryPicker.scss";
import Select, { components } from "react-select";
import flagGlobe from "../../../assets/img/flag-globe.svg";
import { FiGlobe } from "react-icons/fi";

const iconDropOrange =
  "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
const iconDropGrey =
  "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

let CountryPicker = (props) => {
    let {
        list,
        onChange,
        placeholder,
        selectedID = "",
        selectedCountryLabel = "",
        isDisabled
    } = props;

    let styleSelect = () => ({
        indicatorsContainer: provide => ({
            ...provide,
            height: "48px",
        }),
        valueContainer: provide => ({
            ...provide,
            height: "100%",
            padding: "0",
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused ? iconDropOrange : iconDropGrey,
            transition: "0.25s ease-in-out",
            color: "transparent !important",
            width: "24px",
            height: "24px",
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            width: "fit-content"
        }),
        control: (provide, { isFocused }) => ({
            ...provide,
            padding: "0 16px",
            // height: "48px",
            // borderRadius: "8px",
            // border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
            boxShadow: "none !important",
            // marginBottom: "24px",
            cursor: "pointer",
            border: "none",
            background: "transparent"
            // borderRight: "none !important",
            // borderRadius: "8px 0 0 8px"
        }),
        indicatorSeparator: provide => ({ ...provide, display: "none" }),
            menu: provide => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
            width: "300px"
        }),
        // placeholder: (provided) => ({
        //     ...provided,
        //     display: "none"
        // }),
        input: (provided) => ({
            ...provided,
            width: "40px"
        }),
        option: (provide, state) => {
            const { isSelected } = state;
            const checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)"
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            };
        }
    })

    let { Option, SingleValue, Placeholder } = components;

    let iconOption = props => {
        return (
            <Option {...props}>
                <img
                    src={props.data.countryFlag}
                    style={{ width: 36 }}
                    alt={props.data.countryLabel}
                    onError={(e) => {e.target.onerror = null; e.target.src = flagGlobe;}}
                    className="icon-image-select"
                />
                {props.data.countryLabel}
            </Option>
        )
    };

    let placeholderComponent = props => {
        return (
            <Placeholder {...props}>
                <FiGlobe />
            </Placeholder>
        )
    }

    let singleValue = props => {
        return (
            <SingleValue {...props}>
                <img
                     src={props.data.countryFlag}
                     style={{ width: 36 }}
                     alt={props.data.countryLabel}
                     onError={(e) => {e.target.onerror = null; e.target.src = flagGlobe;}}
                     className="icon-image-select"
                />
                {/* {props.data.countryLabel} */}
            </SingleValue>
        )
    };

    return (
        <div className={`country-picker-wrapper ${isDisabled ? "country-picker-wrapper-disabled" : ""}`}>
            <div className="custom-select2-wrapper">
                <Select 
                    classNamePrefix="react-select-country-picker"
                    options={list} 
                    // placeholder={placeholder}
                    onChange={(e) => onChange("countryID", e)}
                    styles={styleSelect()}
                    components={{ 
                        Option: iconOption,
                        SingleValue: singleValue,
                        Placeholder: placeholderComponent
                    }}
                    openOnFocus={true}
                    autofocus={true}
                    value={list ? list.filter((obj) => obj.value === selectedID)[0] : ""}
                    isDisabled={isDisabled}
                />
            </div>
            {/* <p>{list ? list.filter((obj) => obj.value === selectedID).countryLabel : placeholder}</p> */}
            <div className="country-picker-label">
                {/* {list ? 
                    list.filter((obj) => obj.value === selectedID)[0].countryLabel : placeholder} */}
                {selectedCountryLabel ? 
                <p>{selectedCountryLabel}</p>
                : 
                <p className="placeholder">{placeholder}</p>}
            </div>
        </div>
    )
}

export default CountryPicker;