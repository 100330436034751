import React, { useState } from "react";
import NewButton from "../reuseableComponent/NewButton/NewButton";
import { FaTrashAlt } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import topicServices from "../../services/newServices/TopicServices";
import Loading from "../reuseableComponent/loading/Loading";
import { doToast } from "../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import PopupRemove from '../reuseableComponent/popupRemove/PopupRemove'

const getBtn = props => {
  const className = props.className || "no-fill-button";
  const icon = props.icon || "";
  const text = props.text || "Agemt Action";
  const onClick = props.onClick || false;
  const propsBtn = { className, icon, text, onClick };
  return <NewButton {...propsBtn} />;
};

const delAgent = async props => {
  const { orgID, idTopic, reloadAPI } = props;
  const { agentSelected, setAgentSelected } = props;
  const { setLoading } = props;
  const numIdTopic = parseInt(idTopic, 10);

  const doSucces = () => {
    reloadAPI("Agent successfully removed");
  };

  const doDel = async () => {
    setLoading(true);
    const _data = [...agentSelected]

    const agentsEmailToBeRemoved = _data.filter(item => item.accountID === 0)
    const listEmailToBeRemoved = agentsEmailToBeRemoved.map(item => item.accountEmail)

    const agentsIdToBeRemoved = _data.filter(item => item.accountID !== 0)
    const listIdToBeRemoved = agentsIdToBeRemoved.map(item => item.accountID)

    const body = { id: numIdTopic, accountIDs: listIdToBeRemoved, accountEmails: listEmailToBeRemoved };

    topicServices.delAgent(body, orgID, (response) => {
      if (response.dataResult.status === 200) {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
          mixpanel.track(
            "[Action] Remove Agent from Topic",
            {
              userFullName: myAgentData.account.fullName,
              userID: myAgentData.account.id,
              organizationID: orgID.split("-")[0]
            }
          );
        }

        doSucces();
      }
      else alert(response.dataResult.error.message);
      setAgentSelected([]);
      setLoading(false);
    });
  };

  const waitAPI = setTimeout(() => {
    setLoading(false);
    doToast("Failed to delete agent");
  }, 5500);

  await doDel();
  clearTimeout(waitAPI);
};

const propsRmv = (loading, onClick, agentSelectedVal) => {
  const text = loading ? <Loading color="white" /> : `Remove ${agentSelectedVal.length} selected Agent${agentSelectedVal.length > 1 ? "(s)" : ""}`;
  const icon = loading ? "" : FaTrashAlt;
  const className = "red-button solid";
  return { text, icon, onClick, className };
};

const ActionAgent = props => {
  const [loading, setLoading] = useState(false);
  const [isShowModalRemoveAgent, setIsShowModalRemoveAgent] = useState(false)
  const { agentSelected, setAgentSelected, setPopAssign } = props;
  const noData = !agentSelected || agentSelected.length === 0;

  const passProps = { loading, setLoading, ...props };

  const open = () => setPopAssign(true);
  const clearSelection = () => setAgentSelected([]);

  const clickDel = () => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
      mixpanel.track(
        "[Button] Remove Agent from Topic",
        {
          userFullName: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: props.orgID.split("-")[0]
        }
      );
    }

    delAgent(passProps);
  }

  const toggleModalRemoveAgent = () => {
    setIsShowModalRemoveAgent(!isShowModalRemoveAgent)
  }

  const removeAgent = () => {
    clickDel()
  }

  const btnAdd = { icon: FiPlus, text: "Assign Agent", onClick: open };
  const btnRemv = { ...propsRmv(loading, toggleModalRemoveAgent, agentSelected), disabled: loading };
  const btnCancel = { text: "Cancel", onClick: clearSelection };


  if (noData) return getBtn({ ...btnAdd });
  else
    return (
      <React.Fragment>
        <PopupRemove
          removeTitle={`Remove ${agentSelected.length > 1 ? `${agentSelected.length} Agents` : 'this Agent'}`}
          removeDescription={`Are you sure you want to remove ${agentSelected.length > 1 ? `${agentSelected.length} Agents` : 'this Agent'}?`}
          onClickCancel={toggleModalRemoveAgent}
          onClickRemove={removeAgent}
          waitForResponse={loading}
          showModalProps={isShowModalRemoveAgent}
          submitText={`Remove ${agentSelected.length > 1 ? 'Agents' : 'Agent'}`}
          colorButton="red-button"
        />
        {getBtn(btnRemv)}&nbsp; &nbsp;{getBtn(btnCancel)}
      </React.Fragment>
    );
};

export default ActionAgent;
