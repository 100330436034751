const INITIAL_STATE = {
  search: {
    active: false,
    medium: {},
    agent: 0,
    topic: 0
  },
  bot: {
    active: false,
    medium: {},
    agent: 0,
    topic: 0
  },
  open: {
    active: false,
    medium: {},
    topic: 0
  },
  other: {
    active: false,
    medium: {},
    agent: 0,
    topic: ""
  },
  resolved: {
    active: false,
    medium: {},
    agent: 0,
    topic: 0
  }
};

let reduxReducerCaseFilter = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_CASE_FILTER':
        return action.caseFilter;
      case 'CLEAR_CASE_FILTER':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerCaseFilter;