import React from 'react';
import "./ChatRoomReplyInputEmail.scss";
import { connect } from 'react-redux';
import { FiX, FiPaperclip } from 'react-icons/fi';
import { setReplyMessage, clearReplyMessage } from '../../../../../redux/actions/reduxActionReplyMessage';
import { setEmailAttachments } from "../../../../../redux/actions/reduxActionEmailAttachments";
// import HelperChat from '../../../../../helper/HelperChat';

let ChatRoomReplyInputEmail = (props) => {
    // let [myAgentData, setMyAgentAccountData] = useState(false);

    // useEffect(() => {
    //     if(props.replyMessage.message) {  
    //         let myAgentData = taptalk.getTaptalkActiveUser();
    //         setMyAgentAccountData(myAgentData)
    //     }else {
    //         setMyAgentAccountData(false);
    //     }
    // }, [props.replyMessage])

    return (
        <div className="chat-room-reply-email-message-wrapper">
            <div className="chat-room-reply-email-message-wrapper-inner">
                <FiX
                    className="cancel-reply-email-button"
                    onClick={() => {
                        // HelperChat.resetHeightClearReply(props.replyMessage);
                        props.setIsActiveButtonSend(false);
                        // HelperChat.resetChatRoomHeightAndInputText();
                        props.setEmailAttachments([]);
                    }}
                />

                <div className={`reply-email-content-outer-wrapper cursor-pointer`} onClick={() => props._setShowInputEmailAttach(true)}>
                    <div className="reply-email-file-media-wrapper">
                        <div className="reply-email-message-file">
                            <FiPaperclip />
                        </div>
                    </div>

                    <div className="reply-email-name-text-wrapper with-image-or-video-or-file">
                        <p className="reply-email-name">
                            <b>
                                Attachments
                            </b>
                        </p>

                        <p className="reply-email-text">
                            {props.emailAttachments.map((v, i) => {
                                return `${v.name}${i < (props.emailAttachments.length - 1) ? ", " : ""}`
                            })}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    activeRoom: state.activeRoom,
    emailAttachments: state.emailAttachments
});

const mapDispatchToProps = {
    setEmailAttachments,
    setReplyMessage,
    clearReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomReplyInputEmail);