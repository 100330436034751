import React, { useEffect, useState } from "react";
import "./OnBoardingInviteMember.scss";
import { FiInfo, FiExternalLink, FiCheck, FiXCircle } from "react-icons/fi";
import IconInviteMember from "../../../../assets/img/onboarding/icon-invite-member.svg";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import OnboardingServices from "../../../../services/onboarding/OnboardingServices";
import { doToast } from "../../../../helper/HelperGeneral";

// const ON_BOARDING = "inviteMember";

let OnBoardingInviteMember = (props) => {
    let [onBoardingInviteMemberDone, setOnBoardingInviteMemberDone] = useState(false);
    let [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false)

    useEffect(() => {
        setOnBoardingInviteMemberDone(props.status)
    }, [props.status])

    let onClickMarkAsDone = () => {
        let _orgID = props.match.params.orgID.split("-")[0];

        let data = {
            itemID: props.id,
            isDone: true
        }
        updateStatus(data, _orgID)
    }

    let onClickMarkAsUndone = () => {
        let _orgID = props.match.params.orgID.split("-")[0];

        let data = {
            itemID: props.id,
            isDone: false
        }
        updateStatus(data, _orgID)
    }

    let updateStatus = (data, orgID) => {
        setLoadingUpdateStatus(true);
        OnboardingServices.postUpdateStatus(orgID, data, (response) => {
            let dataResult = response.dataResult;

            if(dataResult.error.code === "") {
                setOnBoardingInviteMemberDone(data.isDone);
                props.runLastChangeOnBoarding();
                if(data.isDone) props.completeCount + 1 === 5 && props.handleComplete()
            } else {
                doToast(dataResult.error.message, 'fail');
            }
            setLoadingUpdateStatus(false);
        })
    }

    const onClickReadGuide = () => {
        window.open("https://docs.taptalk.io/onetalk-omnichannel-documentation/getting-started/team-members", "_blank", "noopener noreferrer")
    }

    return (
        <div className={`onboarding-child-wrapper onboarding-invite-member`}>
            <div className="alert-component alert-blue">
                <FiInfo />
                <b className="text-alert">
                    Follow the steps that are in the documentation page by clicking “Read Guide”.
                    Once done, click on “Mark as Done”
                </b>
            </div>

            <img src={IconInviteMember} alt="Onetalk invite member" className="onboarding-child-image" />

            <p className="onboarding-description">
                Invite a team member to you organization to get started. Depending on your tier,
                you can have up to 10 members in one organization (Diamond tier) to help reply all
                incoming messages under a single company.
            </p>

            <div className="button-wrapper">
                <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    icon={{
                        type: "svg",
                        src: FiExternalLink
                    }}
                    text="Read Guide"
                    position="left"
                    onClickAction={onClickReadGuide}
                />

                { loadingUpdateStatus ? 
                 <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40 mark-as-undone"
                    text={onBoardingInviteMemberDone ? "Unmark as Done" : "Mark as Done"}
                    position="left"
                    isLoading
                    loadingColor="orange"
                />
                :
                 (onBoardingInviteMemberDone ?
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40 mark-as-undone"
                        icon={{
                            type: "svg",
                            src: FiXCircle
                        }}
                        text="Unmark as Done"
                        position="left"
                        onClickAction={onClickMarkAsUndone}
                        loadingColor="gray"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40"
                        icon={{
                            type: "svg",
                            src: FiCheck
                        }}
                        text="Mark as Done"
                        position="left"
                        onClickAction={onClickMarkAsDone}
                        loadingColor="gray"
                    />)
                }
            </div>
        </div>
    )
}

export default OnBoardingInviteMember;