export class HelperInput {
    withEmoji(text) {
        let withEmojis = /\p{Extended_Pictographic}/ug;

        return withEmojis.test(text);
    }
    emailFormatValid(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email); 
    }

    hourMinuteFormat(string) {
        let re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return re.test(string);
    }

    numberOnly(value) {
        let re = /^[0-9]*$/;
        return re.test(value);
    }

    validURL(value) {
        let isValid = true;
        
        let expression = /^(?:(?:(?:https?|ftp):)?\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
        let regex = new RegExp(expression);
        let t = value;

        if(t.match(regex) === null) {
            isValid = false;
        }

        return isValid;
    }

    validURLNoProtocol(value) {
        let regExp = /^(?:(ftp|http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/gi;

        return regExp.test(value);
    }

    alphanumericAndUnderscoreOnly(value) {
        let regExp = /^\w*$/;
        return regExp.test(value.toLowerCase());
    }

    alphanumericAndHypen(value) {
        let regExp = /^[a-zA-Z0-9-]+$/;
        return regExp.test(value.toLowerCase());
    }

    alphanumericOnly(value) {
        let regExp = /^[a-zA-Z0-9 ]*$/;
        return regExp.test(value.toLowerCase());
    }

    alphanumericAndSpaceOnly(value) {
        let regExp = /^[\w\-\s]+$/;
        return regExp.test(value);
    }

    noSpace(value) {
        var regexp = /^\S*$/;
        return regexp.test(value);
    }

    valid24HourFormat(value) {
        let regExp= /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/;
        return regExp.test(value);
    }

    isContainUppercase(text) {
        return /\p{Lu}/u.test(text);
    }

    phoneBeautify(_phone, ignoreCountryCode = false) {
        if (_phone !== "") {
            _phone = _phone.replaceAll("-", "").replaceAll("+", "");
            let result = "";
            // let countryCode = phone.substr(0, 3) === "+62" ? "62" : (phone.substr(0, 2) === "62" ? "62" : "");
            // let _phone = phone.substr(0, 3) === "+62" ? phone.replace("+62", "") : (phone.substr(0, 2) === "62" ? phone.replace("62", "") : phone);
            let _phoneLength = _phone.length;
            
            if(_phoneLength > 5) {
                if(_phoneLength <= 6) {
                    result = _phone.substr(0);
                }
        
                // if(_phoneLength < 8 && _phoneLength > 5) {
                //     result = _phone.substr(0, 4)+" "+_phone.substr(4);
                // }
        
                // if(_phoneLength === 8) {
                //     result = _phone.substr(0, 4)+" "+_phone.substr(4);
                // }
            
                // if(_phoneLength === 9) {
                //     result = _phone.substr(0, 3)+" "+_phone.substr(3, 3)+" "+_phone.substr(6, 3);
                // }
            
                // if(_phoneLength === 10) {
                //     result = _phone.substr(0, 4)+" "+_phone.substr(4, 3)+" "+_phone.substr(7, 3);
                // }
                if(_phoneLength > 6 && _phoneLength < 11) {
                    result = _phone.substr(0, 2)+" "+_phone.substr(2, 4)+" "+_phone.substr(6);
                }                

                if(_phoneLength === 11) {
                    result = _phone.substr(0, 2)+" "+_phone.substr(2, 3)+" "+_phone.substr(5, 3)+" "+_phone.substr(8);
                }
            
                if(_phoneLength > 11) {
                    result = _phone.substr(0, 2)+" "+_phone.substr(2, 3)+" "+_phone.substr(5, 4)+" "+_phone.substr(9);
                }
            
                // return countryCode !== "" ? (ignoreCountryCode ? result: (countryCode+" "+result)).replace("+", "") : result.replace("+", "");
                return result;
            }else {
                return _phone;
            }
        }else {
            return "";
        }
    }

    removeSpace(text) {
        let result = text.replaceAll(" ", "");
        return result;
    }
}

export default new HelperInput();

