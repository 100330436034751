import React, { useEffect, useState } from 'react';
import './ContactInfoCustomField.scss';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { doToast, checkID } from '../../../helper/HelperGeneral';
import { FiPlus } from 'react-icons/fi';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";
import { NavLink } from 'react-router-dom';
import IconCircleCheckGreen from '../../../assets/img/sidemenu-icon/new/sub/icon-circle-check-green.svg';
import IconCircleXRed from '../../../assets/img/sidemenu-icon/new/sub/icon-circle-x-red.svg';
import ContactCustomFieldServices from '../../../services/newServices/ContactCustomFieldServices';

export const FIELD_TYPE_SINGLE_LINE_TEXT = "single_line";
export const FIELD_TYPE_MULTILINE_TEXT = "multiple_line";
export const FIELD_TYPE_DROPDOWN = "dropdown";
export const FIELD_TYPE_MULTIPLE_SELECT = "multiple_select";
export const FIELD_TYPE_DATE = "date";
export const FIELD_TYPE_NUMBER = "number";
export const FIELD_TYPE_LABELS = {
  single_line: "Single Line Text",
  multiple_line: "Multiline Text",
  dropdown: "Dropdown",
  multiple_select: "Multiple Select",
  date: "Date",
  number: "Number"
};

export const VALIDATION_FREE_TEXT = "free_text";
export const VALIDATION_NUMERIC = "numeric";
export const VALIDATION_NUMBER = "number";
export const VALIDATION_ALPHANUMERIC = "alphanumeric_no_whitespace";
export const VALIDATION_ALL_DATES = "all_dates";
export const VALIDATION_TODAY_OR_LATER_DATES = "today_or_later_dates";
export const VALIDATION_TODAY_OR_EARLIER_DATES = "today_or_earlier_dates";
export const VALIDATION_LABELS = {
  free_text: "Free Text",
  numeric: "Numeric",
  number: "Number",
  alphanumeric_no_whitespace: "Alphanumeric (without whitespace)",
  all_dates: "All Dates",
  today_or_later_dates: "Today or later dates",
  today_or_earlier_dates: "Today or earlier dates",
};

export const FIELD_NAME_MAX_LENGTH = 25;
export const FIELD_CODE_MAX_LENGTH = 25;
export const MULTIPLE_OPTION_MAX_LENGTH = 32;
export const MULTIPLE_OPTION_LIMIT = 10;
 
export const ERROR_MESSAGE_FIELD_REQUIRED = "This field is required";
export const ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT = "Characters exceed limit";
export const ERROR_MESSAGE_INVALID_NAME = "Field name may not contain emoji";
export const ERROR_MESSAGE_INVALID_CODE = "Field code may only contain lowercase, numbers, and underscore (_)";
export const ERROR_MESSAGE_CODE_START_WITH_ALPHABET = "Field code may only start with lowercase alphabet";
export const ERROR_MESSAGE_DUPLICATE_OPTIONS = "You can't enter the same text for multiple options";

export const DEFAULT_CUSTOM_FIELD_VAL = {
  id: 0,
  fieldCode: "",
  fieldName: "",
  fieldType: "",
  isRequired: true,
  validation: "",
  maxCharacters: 0,
  options: [],
  mappedOptions: [],
};

const ContactInfoCustomField = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchCustomFields, setIsWaitingFetchCustomFields] = useState(true);
  let [printedCustomFieldData, setPrintedCustomFieldData] = useState([]);
  let [retrieveDataError, setRetrieveDataError] = React.useState({
      code: false,
      message: false
  });

  useEffect(() => {
    if (orgID) {
      getContactCustomFieldList();
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    getContactCustomFieldList();
    
    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Contact Info Custom Field", 
        {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  const doMount = async () => {
    const paramID = checkID(props.match);

    if (paramID) {
      setOrgID(paramID);
    }
    else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/setup/contact-info-custom-field`);
        }
        else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let getContactCustomFieldList = () => {
    if (!orgID) {
      return;
    }
    // setIsWaitingFetchCustomFields(true);
    let _retrieveDataError = {...retrieveDataError};

    ContactCustomFieldServices.getContactCustomFieldList(orgID, (response) => {
      setIsWaitingFetchCustomFields(false);
      let dataResult = response.dataResult;

      if (dataResult.error.message === '') {
        setPrintedCustomFieldData(dataResult.data.customFields);
      }
      else {
        _retrieveDataError.message = dataResult.error.message;
        _retrieveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
      }
    });
  };

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      }
      else {
        doToast(props.history.location.state.message, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history]);

  let viewCustomFieldDetails = (customField) => {
    props.history.push({
      pathname: `/o/${orgID}/setup/contact-info-custom-field/detail/${customField.id}`,
      state: customField
    });
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="contact-info-custom-field-wrapper sectionWrap">
        {/* tier blocking */}
        {
          retrieveDataError.code  === "49901" &&
          <RetrieveDataLoading 
            isLoading={isWaitingFetchCustomFields}
            errorMessage={retrieveDataError.message}
            errorCode={retrieveDataError.code}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="contact-info-custom-field-title">
            <b>Contact Info Custom Field</b>
          </p>

          <p className="contact-info-custom-field-description">
            Customize your contacts with additional informations,<br />
            and your custom fields will appear in the each contact’s info.
          </p>

          {(!isWaitingFetchCustomFields && !retrieveDataError.code) &&
            <NavLink to={`/o/${props.match.params.orgID}/setup/contact-info-custom-field/add`}>
              <ButtonWithLoadingOrIcon 
                className="orange-button main-button-40 add-contact-info-custom-field-button"
                text="Add Custom Field"
                position="left"
                icon={{
                  type: "svg",
                  src: FiPlus
                }}
              />
            </NavLink>
          }

          {(isWaitingFetchCustomFields || (retrieveDataError.code && retrieveDataError.code !== "49901")) ? 
            <RetrieveDataLoading 
              isLoading={isWaitingFetchCustomFields}
              errorMessage={retrieveDataError.message}
              errorCode={retrieveDataError.code}
            />
            :
            <div className="contact-info-custom-field-data-wrapper">
              <table>
                <thead>
                  <tr>
                    <td className="col-field-name">
                      <b>Field Name</b>
                    </td>
                    <td className="col-field-code">
                      <b>Field Code</b>
                    </td>
                    <td className="col-field-type">
                      <b>Field Type</b>
                    </td>
                    <td className="col-required">
                      <b>Required</b>
                    </td>
                  </tr>
                </thead>

                {
                  printedCustomFieldData.length === 0 ? 
                  <tbody className="no-contact-info-custom-field" colSpan="4">
                    <tr className="no-contact-info-custom-field-tr">
                      <td colSpan="4">
                        <b>
                          No custom field found, start with ‘+ Add Custom Field’
                        </b>
                      </td>
                    </tr>
                  </tbody>
                  :
                  <tbody className="contact-info-custom-field-table-body">
                    {
                      printedCustomFieldData.map((value, key) => {
                        return (
                          <tr
                            className="contact-info-custom-field-list"
                            key={`contact-info-custom-field-row-${key}`}
                            onClick={() => viewCustomFieldDetails(value)}
                          >
                            <td className="col-field-name">{value.fieldName}</td>
                            <td className="col-field-code">{value.fieldCode}</td>
                            <td className="col-field-type">{FIELD_TYPE_LABELS[value.fieldType] ? FIELD_TYPE_LABELS[value.fieldType] : value.fieldType}</td>
                            <td className="col-required">{
                              value.isRequired ?
                              <>
                                <img src={IconCircleCheckGreen} alt="" />
                                Yes
                              </>
                              :
                              <>
                                <img src={IconCircleXRed} alt="" />
                                No
                              </>
                            }</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                  }
              </table>
            </div>
          }
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default ContactInfoCustomField;
