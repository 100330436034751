const INITIAL_STATE = {
  configShow: false,
  modalWarningHideOther: false,
  case: false
};

let reduxReducerShowOtherCase = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_SHOW_OTHER_CASE':
        return action.showOtherCase;
      case 'CLEAR_SHOW_OTHER_CASE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerShowOtherCase;