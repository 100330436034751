import BaseService from "../BaseService";
import { API_KEY } from "../../config";

class ServiceGroupRole {
    postGetModule(orgID, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };
        
        BaseService.doPostAccessToken(API.GET_MODULE, {}, headers, callback);
    }

    postGetGroupRole(orgID, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };
        
        BaseService.doPostAccessToken(API.GET_GROUP_ROLE, {}, headers, callback);
    }

    postNewGroupRole(orgID, data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };
        
        BaseService.doPostAccessToken(API.NEW_GROUP_ROLE, data, headers, callback);
    }

    postGetGroupRoleDetail(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.GET_GROUP_ROLE_DETAIL, data, headers, callback);   
    }

    postDeleteRole(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.DELETE_ROLE, data, headers, callback);   
    }

    postEditGroupRole(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.EDIT_ROLE, data, headers, callback);
    }

    postGetGroupRoleMember(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.GET_ROLE_MEMBER, data, headers, callback);
    }

    postCreateGroupRoleMember(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.CREATE_ROLE_MEMBER, data, headers, callback);
    }

    postDeleteGroupRoleMember(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.DELETE_ROLE_MEMBER, data, headers, callback);
    }
}

const API = {
    GET_GROUP_ROLE: "/organization/role/get_list",
    NEW_GROUP_ROLE: "/organization/role/create",
    GET_MODULE: "/organization/role/get_module_options",
    GET_GROUP_ROLE_DETAIL: "/organization/role/get_details",
    DELETE_ROLE: "/organization/role/delete",
    GET_ROLE_MEMBER: "/organization/role/get_member_list",
    CREATE_ROLE_MEMBER: "/organization/role/add_members",
    DELETE_ROLE_MEMBER: "/organization/role/remove_members",
    EDIT_ROLE: "/organization/role/edit"
}

export default new ServiceGroupRole()