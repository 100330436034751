import React from 'react';
import './PopupInfo.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonWithLoadingOrIcon from '../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

let PopupInfo = (props) => {
  let {
    isOpen,
    toggle,
    className,
    title,
    titleImage,
    description,
    textButton,
    actionButton
  } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={`modal-info ${className}`}
      >
        <ModalBody>
          <img src={titleImage} alt="modal-info" className="modal-info-title-image" />

          <br />
          <b className="info-title"> {title}</b>
          <p className="info-description" dangerouslySetInnerHTML={{__html: description}} />
        </ModalBody>

        <ModalFooter>
          <ButtonWithLoadingOrIcon 
            text={textButton}
            className="orange-button cancel-button main-button-48"
            onClickAction={actionButton}
          />
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default PopupInfo;
