// import axios from "axios";
// import getHeaderServices from "./headerServices";
import BaseService from "../BaseService";
import { API_KEY } from "../../config";

// const setUrl = `${process.env.REACT_APP_ENVIRONMENT_API}/organization/member/`;
// const getHeader = getHeaderServices;

const MemberServices = {
  getRegisteredList(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_REGISTERED_LIST, {}, headers, callback);
  },

  getList(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_LIST, {}, headers, callback);
  },

  getDetail(idOrTime, body, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_DETAIL, body, headers, callback);
  },

  addMember(idOrTime, body, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_MEMBER, body, headers, callback);
  },

  resendInvitation(idOrTime, body, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.RESEND_INVITATION, body, headers, callback);
  },

  deleteMember(idOrTime, body, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_MEMBER, body, headers, callback);
  },

  changeRoleMember(idOrTime, body, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CHANGE_ROLE, body, headers, callback);
  },

  editAlias(idOrTime, body, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_ALIAS, body, headers, callback);
  },

  getClockStatus(idOrTime, body, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CLOCK_STATUS, body, headers, callback);
  },

  editMemberTopic(idOrTime, body, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_TOPIC, body, headers, callback);
  },

  getAssTopic(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };
    
    BaseService.doPostAccessToken(API.GET_ASS_TOPIC, {}, headers, callback);
  }
};

const API = {
  GET_REGISTERED_LIST: "/organization/member/get_registered_list",
  GET_LIST: "/organization/member/get_list",
  GET_DETAIL: "/organization/member/get_details",
  ADD_MEMBER: "/organization/member/invite",
  RESEND_INVITATION: "/organization/member/resend_invitation",
  DELETE_MEMBER: "/organization/member/remove",
  CHANGE_ROLE: "/organization/member/change_role",
  EDIT_ALIAS: "/organization/member/edit_alias",
  CLOCK_STATUS: "/organization/member/get_clock_state_list",
  SET_TOPIC: "/organization/member/set_topics",
  GET_ASS_TOPIC: "/organization/topic/get_assigned_list"
}

export default MemberServices;
