import BaseService from "../BaseService";

class ServiceAuthRegister {
    postRegisterAccount(param) {
        return (
            BaseService.doPost(API.REGISTER_ACCOUNT, param)
                .then(response => {
                    return {
                        dataResult:  response.data,
                    }
                })
                .catch(error => {
                    return BaseService.generateErrorMessage(error);
                })
        );
    }

    postResendEmailVerification(param) {
        return (
            BaseService.doPost(API.RESEND_VERIFICATION, param)
                .then(response => {
                    return {
                        dataResult:  response.data,
                    }
                })
                .catch(error => {
                    return BaseService.generateErrorMessage(error);
                })
        );
    }

    postEmailVerification(param) {
        return (
            BaseService.doPost(API.EMAIL_VERIFICATION, param)
                .then(response => {
                    return {
                        dataResult:  response.data,
                    }
                })
                .catch(error => {
                    return BaseService.generateErrorMessage(error);
                })
        );
    }
}

const API = {
    REGISTER_ACCOUNT: "/client/register",
    RESEND_VERIFICATION: "/client/account_verification/resend_email",
    EMAIL_VERIFICATION: "/client/account_verification/submit"
}

export default new ServiceAuthRegister()