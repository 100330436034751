import React, { useEffect } from 'react';
import './OverviewTopicReport.scss';
import Chart from 'chart.js';
import { PieChart } from 'react-minimal-pie-chart';
import OverviewTopicReportNoData from "../overviewTopicReportNoData/OverviewTopicReportNoData";

const OverviewTopicReport = 
(props) => {
    let PIE_CHART_COLOR = [
        "#f99080",
        "#F4C12A",
        "#9C2EEF",
        "#3BAEff",
        "#51E683",
        "#007A8A",
        "#FF3F57",
        "#DCDCDC"
    ];

    let BAR_CHART_COLOR = [
        {
            light: '#51E683',
            dark: '#2EC360'
        },
        {
            light: '#9C2EEF',
            dark: '#7C0ECF',
        },
        {
            light: '#3BAEFF',
            dark: '#2194E5',
        },
        {
            light: '#F99080',
            dark: '#DE7565',
        },
        {
            light: '#008394',
            dark: '#005F70',
        }
    ];

    let generateTotalCasesData = () => {
        //max 8 data (7 + 1 other)
        let _newArr = [];
        let _arr = props.printedOverviewData.topics;

        let sumArray = (arr) => {
            let count = 0;

            arr.map((v) => {
                count += v.selectedPeriod.totalCasesCreated;
                return null;
            })

            return count;
        }
        
        _arr.map((v, i) => {
            if(i < 7) {
                if(v.selectedPeriod.totalCasesCreated > 0) {
                    _newArr.push({ 
                        title: v.topicName, 
                        value: v.selectedPeriod.totalCasesCreated, 
                        color: PIE_CHART_COLOR[_newArr.length],
                        _className: `stars-${_newArr.length}`
                    })
                }
            }else {
                if(_newArr.length === 7) {
                    if(_arr.length === 8) {
                        if(v.selectedPeriod.totalCasesCreated > 0) {
                            _newArr.push({ 
                                title: v.topicName, 
                                value: v.selectedPeriod.totalCasesCreated, 
                                color: PIE_CHART_COLOR[_newArr.length],
                                _className: `stars-${_newArr.length}`
                            })
                        }
                    }else {
                        _newArr.push({ 
                            title: "Others", 
                            value: sumArray(_arr.slice().splice(7)), 
                            color: PIE_CHART_COLOR[7],
                            _className: `others`
                        })
                    }
                }
            }

            return null;
        })

        return _newArr;
    }

    let generateCaseComparisonData = () => {
        //max 5
        let data = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                    borderRadius: 10
                    // borderWidth: 1
                },
                {
                    data: [],
                    backgroundColor: [],
                    borderRadius: 10
                    // borderWidth: 1
                }
            ]
        }

        let _arr = props.printedOverviewData.topics;
        
        _arr.map((v, i) => {
            if(i < 5) {
                data.labels.push(v.topicName);
                data.datasets[0].data.push(v.selectedPeriod.totalCasesCreated);
                data.datasets[0].label = "Selected Period";
                data.datasets[1].data.push(v.previousPeriod.totalCasesCreated);
                data.datasets[1].label = "Previous Period";
                data.datasets[0].backgroundColor.push(BAR_CHART_COLOR[i].light);
                data.datasets[1].backgroundColor.push(BAR_CHART_COLOR[i].dark);
            }

            return null;
        })

        return data;
    }
    
    useEffect(() => {
      const ctx = document.getElementById('myChart');
      const myChart = new Chart(ctx, {
          type: 'horizontalBar',
          data: generateCaseComparisonData(),
          options: {
            legend: {
                display: false
            },
            events: ['click'],
            onClick: function(c,i) {
                // let e = i[0];
                // var x_value = this.data.labels[e._index];
                // var y_value = this.data.datasets[0].data[e._index];
                // var z_value = this.data.datasets[1].data[e._index];
            },
          }
      });
    }, [props.printedOverviewData]);

    return (
        <div className="overview-box-wrapper overview-topic-report-wrapper">
            <p className="overview-box-title">
                <b>Topic Report</b>
            </p>

            <div className='overview-topic-report-inner-wrapper'>
                <p className='overview-topic-report-title'>
                    <b>Total Cases</b>
                </p>
                
                {generateTotalCasesData().length === 0 ?
                    <OverviewTopicReportNoData noTitle />
                    :
                    <>
                        <div className="overview-case-rating-left">
                            <PieChart
                                data={generateTotalCasesData()}
                                startAngle={0}
                            />

                            <div className="total-rating-is">
                                <div className="total-rating-is-text">
                                    <b>
                                        {props.printedOverviewData.totalCasesCreated}
                                    </b>

                                    <p>
                                        Total Cases
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="overview-case-rating-right">
                            <table className="table-overview">
                                <thead>
                                    <tr>
                                    <td><b>Topic</b></td>
                                    <td><b># of Cases</b></td>                                
                                    </tr>
                                </thead>
                                <tbody>
                                    {generateTotalCasesData().map((v, i) => {
                                        return (
                                            <tr>
                                                <td className={`stars-${i + 1} no-hover`}><div /><b>{v.title}</b></td>
                                                <td><b>{v.value}</b></td>                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                
            </div>

            <div className='overview-topic-report-inner-wrapper'>
                <p className='overview-topic-report-title'>
                    <b>Cases Comparisons</b>
                </p>

                <canvas id="myChart" width="100" height="35"></canvas>
            </div>
        </div>
    );
};

export default OverviewTopicReport;
