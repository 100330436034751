import React, { useEffect } from 'react';
import './OverviewCaseDuration.scss';
import Chart from 'chart.js';
import OverviewTopicReportNoData from '../overviewTopicReportNoData/OverviewTopicReportNoData';

const OverviewCaseDuration = 
(props) => {
    let BAR_CHART_COLOR = [
        "#008394",
        "#857700",
        "#FF9E40",
        "#F4C12A",
        "#4138BC",
        "#9C88F1",
        "#E6057D",
        "#9E6900",
        "#E02E2E"
    ];

    let generateCaseComparisonData = () => {
        //max 5
        // let data = {
        //     labels: [],
        //     datasets: [
        //         // {
        //         //     data: [],
        //         //     backgroundColor: [],
        //         //     borderWidth: 1,
        //         //     borderRadis: 10
        //         // },
        //         // {
        //         //     data: [],
        //         //     backgroundColor: [],
        //         //     borderWidth: 1
        //         // }
        //     ]
        // }

        let _arr = props.printedOverviewData.caseDurationPivotItems;
        let _labels = [];
        let _value = [];

        _arr.map((v, i) => {
            _labels.push(v.label);
            _value.push(v.totalCases);
            
            return null;
        })

        return {
            labels: _labels,
            datasets: [{
              data: _value,
              backgroundColor: BAR_CHART_COLOR
            }],
        };
    }
    
    useEffect(() => {
      const ctx = document.getElementById('myChartCaseDuration');
      const myChartCaseDuration = new Chart(ctx, {
          type: 'horizontalBar',
          data: generateCaseComparisonData(),
          options: {
            legend: {
                display: false
            },
            events: ['click'],
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: function(tooltipItems, data) { 
                        return tooltipItems.xLabel + ' Cases';
                    }
                }
            },
          }
      });
    }, [props.printedOverviewData]);

    let isDataEmpty = () => {
        let isEmpty = true;

        props.printedOverviewData.caseDurationPivotItems.map((v) => {
            if(v.totalCases > 0) {
                isEmpty = false;
            }

            return null;
        })

        return isEmpty;
    }

    return (
        <div className="overview-box-wrapper overview-topic-report-wrapper">
            <div className='overview-topic-report-inner-wrapper'>
                {isDataEmpty() && <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper-no-margin" />}
                
                <canvas className={isDataEmpty() ? "force-hide" : ""} id="myChartCaseDuration" width="100" height="35"></canvas>
            </div>
        </div>
    );
};

export default OverviewCaseDuration;
