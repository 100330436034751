const INITIAL_STATE = null;

let reduxReducerClockState = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_CLOCK_STATE':
        return action.clockState;
      case 'CLEAR_CLOCK_STATE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerClockState;