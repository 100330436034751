import React, { useState, useEffect } from "react";
import "./IntegrationGoogleCreateEntry.scss";
import { connect } from "react-redux";
import { doToast, scrollToClass } from "../../../../../helper/HelperGeneral";
import HelperInput from "../../../../../helper/HelperInput";
import { FiArrowLeft, FiPlus, FiTrash2, FiInfo } from "react-icons/fi"; 
import { setCreateGoogle } from "../../../../../redux/actions/reduxActionCreateGoogle";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from '../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import IntegrationGoogleServices from '../../../../../services/integration/IntegrationGoogleServices';
import SelectBulk from "../../../../reuseableComponent/selectBulk/SelectBulk";
import ColoredInfoBox from "../../../../reuseableComponent/coloredInfoBox/ColoredInfoBox";
import IconRegion from "../../../../../assets/img/icon-region.png";

let IntegrationGoogleCreateEntry = (props) => {
    let [errorPleaseSelectOne, setErrorPleaseSelectOne] = useState("");
    let [loadingCreateChannel, setLoadingCreateChannel] = useState(false);
    let [searchBulk, setSearchBulk] = useState("");
    let [bulkListOrigin, setBulkListOrigin] = useState([]);
    let [bulkList, setBulkList] = useState([]);

    useEffect(() => {
        getRegionList();
    }, [])

    let getRegionList = () => {
        IntegrationGoogleServices.getRegionList(props.match.params.orgID, (response) => {
          if (response.dataResult.error.message === "") {
            let arr = [];
            response.dataResult.data.regions.map(v => {
              arr.push({
                name: v.name,
                id: v.code
              })
    
              return null;
            })
    
            setBulkList(arr);
            setBulkListOrigin(arr);
          } else {
            doToast(response.dataResult.error.message, "fail");
          }
        });
    }

    let onCreateChannel = () => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);
        let errorCount = 0;
        let scrollToError = "";
        let _entry = _parse.data.entry;

        if(!_entry.local && !_entry.nonLocal.isEnabled) {
            setErrorPleaseSelectOne("Please select at least one entry point");
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `form-step-content`}
        }

        if(_entry.nonLocal.isEnabled) {
            //domain
            _entry.nonLocal.domain.map((v, i) => {
                if(!HelperInput.validURL(v.value)) {
                    v.error = "Invalid URL format";

                    if(scrollToError === "") { scrollToError = `domain-${i}`}
                }

                if(v.value === "") {
                    v.error = "This field is required";

                    if(scrollToError === "") { scrollToError = `domain-${i}`}
                }

                if(i > 0) {
                    _entry.nonLocal.domain.map((_v, _i) => {
                        if(_v.value === v.value && _i !== i) {
                            v.error = "Domain is duplicate";
                            if(scrollToError === "") { scrollToError = `domain-${i}`}
                        }

                        return null;
                    })
                }

                return null;
            })

            if(_entry.nonLocal.region.length === 0) {
                _entry.nonLocal.regionError = "Please select at  least one region";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `custom-switch-region`}
            }

            //region
            if(_entry.nonLocal.region.length === 0) {
                _entry.nonLocal.regionError = "Please select at  least one region";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `custom-switch-region`}
            }

            //phone
            if(_entry.nonLocal.phoneNumber.replaceAll(" ", "").length < 4) {
                _entry.nonLocal.phoneNumberError = "Invalid phone number";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `input-phone`}
            }

            if(_entry.nonLocal.phoneNumber === "") {
                _entry.nonLocal.phoneNumberError = "This field is required";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `input-phone`}
            }

            if(_entry.nonLocal.phoneNumber.replaceAll(" ", "").length > 15) {
                _entry.nonLocal.phoneNumberError = "Characters exceed limit";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `input-phone`}
            }

            //contact
            if(!HelperInput.validURL(_entry.nonLocal.contactURL)) {
                _entry.nonLocal.contactURLError = "Invalid URL format";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `input-contact-url`}
            }

            if(_entry.nonLocal.contactURL    === "") {
                _entry.nonLocal.contactURLError = "This field is required";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `input-contact-url`}
            }

            //source
            if(_entry.nonLocal.contactOptions.length === 0) {
                _entry.nonLocal.contactOptionsError = "Please select at least one contact type";
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `checkbox-source`}
            }
        }

        if(errorCount === 0) {
            setLoadingCreateChannel(true);
            
            let _createGoogle = {...props.createGoogle};
            let _defaultLocale = "";
            let entryPoin = [];
            let domain = [];
    
            if( _createGoogle.data.entry.local) {
                entryPoin.push("LOCATION");
            }
    
            if( _createGoogle.data.entry.nonLocal.isEnabled) {
                entryPoin.push("NON_LOCAL");
            }
    
            let _conversations = [];
    
            _createGoogle.data.conversations.map((v) => {
                _conversations.push({
                    "languageCode": v.languageCode.value,
                    "welcomeMessage": v.welcomeMessage,
                    "privacyPolicyURL": v.privacyPolicyURL
                })
    
                if(v.defaultLocale) {
                    _defaultLocale = v.languageCode.value;
                }
    
                return null;
            })

            _createGoogle.data.entry.nonLocal.domain.map((v) => {
                domain.push(v.value);

                return null;
            })

            let _regionCode = [];

            _createGoogle.data.entry.nonLocal.region.map(v => {
                _regionCode.push(v.id);
                return null;
            })
    
            let data = {
                "topicID": _createGoogle.data.topic.id,
                "agentName": _createGoogle.data.channel.displayName,
                "logoURL": _createGoogle.data.channel.logoURL,
                "defaultLocale": _defaultLocale,
                "entryPoints": entryPoin,
                "conversations": _conversations,
                "nonLocal": {
                    "phoneNumber": `+${_createGoogle.data.entry.nonLocal.phoneNumber}`,
                    "contactURL": _createGoogle.data.entry.nonLocal.contactURL,
                    "contactOptions": _createGoogle.data.entry.nonLocal.contactOptions,
                    "regionCodes": _regionCode,
                    "sitelinkDomains": domain,
                }
            };
    
            IntegrationGoogleServices.create(props.match.params.orgID, data, (response) => {
                let dataResult = response.dataResult;
    
                if(dataResult.status !== 200) {
                    doToast(dataResult.error.message, "fail");
                    setLoadingCreateChannel(false);
                }else {
                    if(!dataResult.data.success) {
                        doToast(dataResult.data.message, "fail");
                        setLoadingCreateChannel(false);
                    }else {
                        doToast(dataResult.data.message);
    
                        _createGoogle.origin = _createGoogle.data;
                        props.setCreateGoogle(_createGoogle);                    
    
                        setTimeout(() => {
                            props.history.push(`/o/${props.match.params.orgID}/integration/google-business-messages`)
                        }, 3000);
                    }
                }
            })
        }else {
            scrollToClass(`.${scrollToError}`);
            props.setCreateGoogle(_parse);
        }
    }

    let onChangeSwitchLocal = (e, local) => {
        setErrorPleaseSelectOne("");
        let _check = e.target.checked;

        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        if(local) {
            _parse.data.entry.local = _check;
            // _parse.data.entry.nonLocal.isEnabled = !_parse.data.entry.local;
        }else {
            _parse.data.entry.nonLocal.isEnabled = _check;
            // _parse.data.entry.local = !_parse.data.entry.nonLocal.isEnabled;
        }

        props.setCreateGoogle(_parse);
    }

    let addMoreDomain = () => {
        if(!loadingCreateChannel) {
            let _createGoogle = JSON.stringify(props.createGoogle);
            let _parse = JSON.parse(_createGoogle);
            _parse.data.entry.nonLocal.domain.push({
                value: "",
                error: ""
            })
            props.setCreateGoogle(_parse);
        }
    }

    let onChangeDomain = (e, i) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);
        _parse.data.entry.nonLocal.domain[i].value = e.target.value;
        _parse.data.entry.nonLocal.domain[i].error = "";
        props.setCreateGoogle(_parse);
    }

    let onClickRemoveDomain = (i) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);
        _parse.data.entry.nonLocal.domain.splice(i, 1);
        props.setCreateGoogle(_parse);
    }

    let printDomain = (v, i) => {
        return (
            <>
                <label className={`label-domain domain-${i}`}>
                    <b>Domain{i > 0 ? `#${i+1}` : ""}</b>
                </label>

                <div className="domain-input-wrapper">
                    <input 
                        type="text" 
                        onChange={(e) => onChangeDomain(e, i)} 
                        value={v.value} 
                        placeholder="Type your domain here"
                        className={v.error === "" ? "" : "border-red"}
                        disabled={loadingCreateChannel}
                    />

                    {v.error !== "" &&
                        <ErrorOrInfoComp
                            text={v.error}
                            _className={"font-red"}
                            icon={<FiInfo />}
                        />
                    }

                    {i > 0 &&
                        <FiTrash2 
                            className="remove-domain-icon"
                            onClick={() => onClickRemoveDomain(i)}
                        />
                    }
                </div>
            </>
        )
    }

    let onChangePhone = (e) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);
        if(HelperInput.numberOnly(e.target.value.replaceAll(" ", ""))) {
            _parse.data.entry.nonLocal.phoneNumber = e.target.value;
            _parse.data.entry.nonLocal.phoneNumberError = "";
        }
        props.setCreateGoogle(_parse);
    }

    let onChangeContactURL = (e) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        _parse.data.entry.nonLocal.contactURL = e.target.value;
        _parse.data.entry.nonLocal.contactURLError = "";

        props.setCreateGoogle(_parse);
    }

    let onClickCheckBoxSource = (e) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);
        props.setCreateGoogle(_parse);
    }

    let checkBoxList = [
        {
            label: "Email",
            value: "EMAIL",
            onClick: (e) => onClickCheckBoxSource(e) 
        },
        {
            label: "Phone",
            value: "PHONE",
            onClick: (e) => onClickCheckBoxSource(e) 
        },
        {
            label: "FAQs",
            value: "FAQS",
            onClick: (e) => onClickCheckBoxSource(e) 
        },
        {
            label: "WhatsApp",
            value: "WHATSAPP",
            onClick: (e) => onClickCheckBoxSource(e) 
        },
        {
            label: "Twitter",
            value: "TWITTER",
            onClick: (e) => onClickCheckBoxSource(e) 
        },
        {
            label: "Web Chat",
            value: "WEB_CHAT",
            onClick: (e) => onClickCheckBoxSource(e) 
        }
    ];

    let onChangeSearchBulk = (e) => {
        let _bulkOrigin = bulkListOrigin.slice();
        let result = [];
        setSearchBulk(e.target.value);
    
        if (_bulkOrigin.length > 0) {
          for (let i in _bulkOrigin) {
            let objectKey = Object.keys(_bulkOrigin[i]);
    
            for (let j in objectKey) {
              let isString = typeof _bulkOrigin[i][objectKey[j]] === "string";
              if (_bulkOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
                result.push(_bulkOrigin[i])
                break;
              }
            }
          }
        }
    
        setBulkList(result);
    }

    let onChangeSingleSelect = (e, val) => {
        if(!loadingCreateChannel) {
            let _createGoogle = JSON.stringify(props.createGoogle);
            let _parse = JSON.parse(_createGoogle);
    
            let _selected = _parse.data.entry.nonLocal.region;
    
            if (e.target.checked) {
                _selected = [..._selected, val];
            } else {
                let _dataSelected = [..._selected];
                _selected = _dataSelected.filter(el => el.id !== val.id)
            }
    
            _parse.data.entry.nonLocal.region = _selected;
            _parse.data.entry.nonLocal.regionError = "";
            props.setCreateGoogle(_parse);
        }
    }

    let removeSingleBulk = (val) => {
        if(!loadingCreateChannel) {
            let _createGoogle = JSON.stringify(props.createGoogle);
            let _parse = JSON.parse(_createGoogle);
            let _selected = _parse.data.entry.nonLocal.region;
            let _dataSelected = [..._selected]
    
            _selected = _dataSelected.filter(el => el.id !== val.id)
            _parse.data.entry.nonLocal.region = _selected;
            props.setCreateGoogle(_parse);
        }
    }

    let isChecked = (id) => {
        const _data = [...props.createGoogle.data.entry.nonLocal.region];
        return _data.findIndex(val => val.id === id) !== -1;
    }

    let onClearAll = () => {
        if(!loadingCreateChannel) {
            let _createGoogle = JSON.stringify(props.createGoogle);
            let _parse = JSON.parse(_createGoogle);
            _parse.data.entry.nonLocal.region = [];
            props.setCreateGoogle(_parse);
        }
    }

    let onChangeSwitchAllRegion = (e) => {
        if(!loadingCreateChannel) {
            let _createGoogle = JSON.stringify(props.createGoogle);
            let _parse = JSON.parse(_createGoogle);
            _parse.data.entry.nonLocal.isAllRegion = e.target.checked;
            _parse.data.entry.nonLocal.region = bulkList;
            _parse.data.entry.nonLocal.regionError = "";
            props.setCreateGoogle(_parse);
        }
    }

    return (
        <div className="main-integration-google-create-entry">
            {errorPleaseSelectOne !== "" &&
                <ColoredInfoBox 
                    text={errorPleaseSelectOne}
                    color={"red"}
                    icon={<FiInfo />}
                />
            }

            <div className="local-wrapper">
                <label>
                    <b>Local</b>

                    <p>
                        Local entry point group encompasses entry points that rely on locations. You can add locations after create this channel.
                    </p>
                </label>

                <label className="custom-switch-wrapper">
                    <input disabled={loadingCreateChannel} type="checkbox" onChange={(e) => onChangeSwitchLocal(e, true)} checked={props.createGoogle.data.entry.local} />
                    <span className="custom-switch round"></span>
                </label>
            </div>

            <div className="non-local-wrapper">
                <label>
                    <b>Non-Local</b>

                    <p>
                        Non-local entry point group includes all entry points that don't rely on locations
                    </p>
                </label>

                <label className="custom-switch-wrapper">
                    <input disabled={loadingCreateChannel} type="checkbox" onChange={(e) => onChangeSwitchLocal(e, false)} checked={props.createGoogle.data.entry.nonLocal.isEnabled} />
                    <span className="custom-switch round"></span>
                </label>
            </div>

            {/* non local */}   
            {props.createGoogle.data.entry.nonLocal.isEnabled &&
                <div className="non-local-form-wrapper">
                    {props.createGoogle.data.entry.nonLocal.domain.map((v, i) => {
                        return (
                            printDomain(v, i)
                        )
                    })}

                    <p className="add-more-domain" onClick={addMoreDomain}>
                        <b>
                            <FiPlus />
                            Add More Domain
                        </b>
                    </p>

                    <label className="custom-switch-wrapper  custom-switch-region region-create">
                        <input disabled={loadingCreateChannel} type="checkbox" onChange={(e) => onChangeSwitchAllRegion(e)} checked={props.createGoogle.data.entry.nonLocal.isAllRegion} />
                        <span className="custom-switch round"></span>
                        <b>All Region</b>
                    </label>

                    {!props.createGoogle.data.entry.nonLocal.isAllRegion &&
                        <SelectBulk 
                            onChangeSearchBulk={onChangeSearchBulk}
                            onClearAll={onClearAll}
                            bulkList={bulkList}
                            onChangeSingleSelect={onChangeSingleSelect}
                            isChecked={isChecked}
                            isCheckAll={props.createGoogle.data.entry.nonLocal.isAllRegion}
                            searchBulk={searchBulk}
                            title={"Select Region"}
                            selectedBulk={props.createGoogle.data.entry.nonLocal.region}
                            removeSingleBulk={removeSingleBulk}
                            withOutSelectAll
                            selectedTitle={"Selected region will be displayed here."}
                            icon={IconRegion}
                            searchTitle={"Search for region"}
                            errorText={props.createGoogle.data.entry.nonLocal.regionError}
                            isDisabled={loadingCreateChannel}
                        />
                    }
                    
                    <label className="margin-top-24 input-phone pos-relative">
                        <b>Phone Number</b>

                        <span className={ props.createGoogle.data.entry.nonLocal.phoneNumberError === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{props.createGoogle.data.entry.nonLocal.phoneNumber.replaceAll(" ", "").length}/15</span>
                    </label>

                    <div className="input-phone-wrapper width-400">
                        <input 
                            type="text" 
                            onChange={(e) => onChangePhone(e)} 
                            value={HelperInput.phoneBeautify(props.createGoogle.data.entry.nonLocal.phoneNumber.replaceAll(" ", ""))} 
                            placeholder="62 8XXX XXX XXX"
                            className={props.createGoogle.data.entry.nonLocal.phoneNumberError === "" ? "" : "border-red"}
                            disabled={loadingCreateChannel}
                        />

                        <FiPlus className="icon-plus" />

                        <ErrorOrInfoComp
                            text={
                                props.createGoogle.data.entry.nonLocal.phoneNumberError === "" ? 
                                    "Always start the phone number with the country code without the “+” sign e.g. “628XXXXXXXXX”."
                                    :
                                    props.createGoogle.data.entry.nonLocal.phoneNumberError
                            }
                            _className={props.createGoogle.data.entry.nonLocal.phoneNumberError === "" ? "" : "red-text"}
                            icon={<FiInfo />}
                        />
                    </div>

                    <label className="margin-top-24 input-contact-url">
                        <b>Contact Us URL</b>
                    </label>
                    <div className="width-400">
                        <input 
                            type="text" 
                            onChange={(e) => onChangeContactURL(e)} 
                            value={props.createGoogle.data.entry.nonLocal.contactURL} 
                            placeholder="Type your URL here"
                            className={props.createGoogle.data.entry.nonLocal.contactURLError === "" ? "" : "border-red"}
                            disabled={loadingCreateChannel}
                        />

                        {props.createGoogle.data.entry.nonLocal.contactURLError !== "" &&
                            <ErrorOrInfoComp
                                text={props.createGoogle.data.entry.nonLocal.contactURLError}
                                _className={"font-red"}
                                icon={<FiInfo />}
                            />
                        }
                    </div>

                    <div className="checkbox-wrapper checkbox-source">
                        {checkBoxList.map((v, i) => {
                            return (
                                <div className="custom-checkbox" key={`checkbox-source-${i}`}>
                                    <input 
                                        type="checkbox" 
                                        id={`checkbox-source-${i}`}
                                        onChange={(e) => {
                                            if(!loadingCreateChannel) {
                                                let _createGoogle = JSON.stringify(props.createGoogle);
                                                let _parse = JSON.parse(_createGoogle);
                                                let _arr = _parse.data.entry.nonLocal.contactOptions.slice();
                                                
                                                if(props.createGoogle.data.entry.nonLocal.contactOptions.findIndex(_v => _v === v.value) === -1) {
                                                    _arr.push(v.value);
                                                }else {
                                                    _arr.splice(props.createGoogle.data.entry.nonLocal.contactOptions.findIndex(_v => _v === v.value), 1);
                                                }
                                                
                                                _parse.data.entry.nonLocal.contactOptionsError = "";
                                                _parse.data.entry.nonLocal.contactOptions = _arr;
                                                
                                                props.setCreateGoogle(_parse);
                                            }
                                        }}
                                        checked={props.createGoogle.data.entry.nonLocal.contactOptions.findIndex(_v => _v === v.value) !== -1}
                                    />
                                    <label htmlFor={`checkbox-source-${i}`}>{v.label}</label>
                                </div>
                            )
                        })}
                    </div>

                    {props.createGoogle.data.entry.nonLocal.contactOptionsError !== "" &&
                        <ErrorOrInfoComp
                            text={props.createGoogle.data.entry.nonLocal.contactOptionsError}
                            _className={"font-red no-margin"}
                            icon={<FiInfo />}
                        />
                    }
                </div>
            }
            {/* non local */}

            <ButtonWithLoadingOrIcon 
                className="no-fill-button main-button-40 button-submit"
                onClickAction={() => props._setActiveForm(2)}
                text="Go Back"
                icon={{
                    src: FiArrowLeft,
                    type: "svg"
                }}
                position="left"
            />
            {loadingCreateChannel ?
                <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-40 button-submit"
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    position="left"
                    text="Create Channel"
                />
                :
                <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-40 button-submit"
                    onClickAction={onCreateChannel}
                    text="Create Channel"
                />
            }
        </div>
    )
}

const mapStateToProps = state => ({
    createGoogle: state.createGoogle
});
  
const mapDispatchToProps = {
    setCreateGoogle
};
  
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleCreateEntry);