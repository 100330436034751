import React, { useState } from 'react';
import './ChatRoomBroadcastHistoryBroadcastDetail.scss';
// import ChatRoomUserInfo from './chatRoomMain/chatRoomUserInfo/ChatRoomUserInfo';
// import { MdFiberManualRecord } from 'react-icons/md';
import { FiInfo, FiUser, FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import ChatRoomBroadcastDetailTab from './chatRoomBroadcastHistoryDetailTab/ChatRoomBroadcastHistoryDetailTab';
import ChatRoomUserInfoTab from './chatRoomBroadcastHistoryUserInfoTab/ChatRoomBroadcastHistoryUserInfoTab';
import { taptalk } from '@taptalk.io/web-sdk';
import Helper from '../../../../helper/HelperChat';
// import { TAPLIVE_MEDIUM} from "../../../../constants/taplive";
// import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";

const USER_INFO_TAB = {
    caseDetail: 1,
    userInfo: 2,
}

var ChatRoomBroadcastHistoryBroadcastDetail = (props) => {
  let [activeUserInfoTab, setActiveUserInfoTab] = useState(USER_INFO_TAB.caseDetail);
//   let [userAgentData, setUserAgentData] = useState(null);

//   useEffect(() => {
//     if(props.activeRoom !== null) {
//         let _userAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).account;
//         setUserAgentData(_userAgentData);
//     }
//   }, [props.activeRoom])

  let onClickUserInfoTab = (tab) => {
    setActiveUserInfoTab(tab)
  }

  return (
    props.broadcastHistoryPopupDataProps &&
        <div className={`chat-room-broadcast-user-info-wrapper active-chat-room-broadcast-user-info-wrapper`}>
            <div className="chat-room-case-history-close" onClick={() => props.toggleBroadcastModalHistoryProps()}>
                <FiX />
            </div>

            <div className="user-info-name-avatar-wrapper">
                {/* {props.broadcastHistoryPopupDataProps.user.imageURL.thumbnail === "" ? */}
                <div className="user-info-avatar-wrapper user-avatar-name" style={{background: taptalk.getRandomColor(props.broadcastHistoryPopupDataProps.user.fullName)}}>
                        <b>{Helper.renderUserAvatarWord(props.broadcastHistoryPopupDataProps.user.fullName)}</b>
                    </div>
                    {/* : */}
                    {/* <img className="user-avatar-name" src={userData.imageURL.thumbnail} alt="" /> */}
                {/* } */}

                <div className="user-info-name-connectivity-modal">
                    <p>
                        <b>{props.broadcastHistoryPopupDataProps.user.alias ?
                                props.broadcastHistoryPopupDataProps.user.alias !== "" ?
                                    `${props.broadcastHistoryPopupDataProps.user.alias} (${props.broadcastHistoryPopupDataProps.user.fullName})`
                                    :
                                    props.broadcastHistoryPopupDataProps.user.fullName
                                :
                                props.broadcastHistoryPopupDataProps.user.fullName
                            }
                        </b>
                    </p>
                    
                    {/* {props.broadcastHistoryPopupDataProps.case.medium === TAPLIVE_MEDIUM.launcher &&
                        ((!props.broadcastHistoryPopupDataProps.user.isOnline && userAgentData !== null) ?
                            !props.broadcastHistoryPopupDataProps.case.isClosed && props.broadcastHistoryPopupDataProps.case.agentAccountID === userAgentData.id && props.broadcastHistoryPopupDataProps.case.userEmail !== "" ?
                                <a href={`mailto:${props.broadcastHistoryPopupDataProps.case.userEmail}`}>
                                    <div className="user-info-offline"><FiMail />Send Email</div>
                                </a>
                                :
                                <span className="user-info-offline-dot"><MdFiberManualRecord />OFFLINE</span>
                            :
                            <span className="user-info-online"><MdFiberManualRecord />ONLINE</span>
                        )
                    } */}
                </div>
            </div>

            <div className="user-info-tab-wrapper">
                <div className={`user-info-tab-content ${activeUserInfoTab === USER_INFO_TAB.caseDetail ? 'active-room-broadcast-list-tab' : ''}`} 
                        onClick={() => onClickUserInfoTab(USER_INFO_TAB.caseDetail)}
                >
                    <FiInfo />
                    <b>Message Details</b>
                </div>

                <div className={`user-info-tab-content ${activeUserInfoTab === USER_INFO_TAB.userInfo ? 'active-room-broadcast-list-tab' : ''}`} 
                        onClick={() => onClickUserInfoTab(USER_INFO_TAB.userInfo)}
                >
                    <FiUser />
                    <b>Contact Info</b>
                </div>
            </div>

            <div className="user-info-tab user-info-tab-modal">
                {activeUserInfoTab === USER_INFO_TAB.caseDetail &&
                    <ChatRoomBroadcastDetailTab 
                        {...props}
                        broadcastHistoryPopupDataProps={props.broadcastHistoryPopupDataProps}
                        messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                        messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                    />
                }

                {activeUserInfoTab === USER_INFO_TAB.userInfo &&
                    <ChatRoomUserInfoTab broadcastHistoryPopupDataProps={props.broadcastHistoryPopupDataProps} />
                }
            </div>
        </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomBroadcastHistoryBroadcastDetail);
