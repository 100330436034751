// import axios from "axios";
// import getHeaderServices from "./headerServices";
import BaseService from "../BaseService";
import { API_KEY } from "../../config";

// const setUrl = `${process.env.REACT_APP_ENVIRONMENT_API}/project/secret_key/`;
// const getHeader = getHeaderServices;

const SecretKeyServices = {
  create(body, idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CREATE, body, headers, callback);
  },

  get(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET, {}, headers, callback);
  },

  delete(body, idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE, body, headers, callback);
  }
};

const API = {
  CREATE: "/project/secret_key/create",
  GET: "/project/secret_key/get_list",
  DELETE: "/project/secret_key/delete"
}

export default SecretKeyServices;
