import React, { useState, useEffect } from "react";
import AssignAgent from "./AssignAgent";
import MemberServices from "../../services/newServices/MemberServices";
import TDContent from "./TDContent";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";

// const dataFake = [
//   { accountID: 1, accountName: "ababab", accountEmail: "fghi@jkl.mn" },
//   { accountID: 12, accountName: "ffffff", accountEmail: "fghi@jkl.mn" },
//   { accountID: 13, accountName: "xxxxxxx", accountEmail: "fghi@jkl.mn" },
//   { accountID: 14, accountName: "zzzzz", accountEmail: "fghi@jkl.mn" }
// ];

const TDAgent = props => {
  const { orgID, detailsData } = props;
  const [popAssign, setPopAssign] = useState(false);
  const [agentData, setAgentData] = useState(false);
  const [copyAgentData, setCopyAgentData] = useState(false);
  const [agentMember, setAgentMember] = useState(false);
  const [agentSelected, setAgentSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })

  const getRegMember = async () => {
    setIsLoading(true);
    let _retriveDataError = {...retriveDataError};

    // const { data } = await MemberServices.getRegisteredList(orgID);
    MemberServices.getRegisteredList(orgID, (response) => {
      if(response.dataResult.error.message === "") {
        setAgentMember(response.dataResult.data.members);
      }else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoading(false);
    })
  };

  useEffect(() => {
    if (orgID) getRegMember();
    if (detailsData) setAgentData(detailsData.agents);
  }, [orgID, detailsData]);

  const agentProps = {
    ...props,
    agentData,
    agentMember,
    agentSelected,
    copyAgentData,
    setPopAssign,
    setAgentMember,
    setAgentSelected,
    setCopyAgentData
  };

  return (
    <React.Fragment>
      <div className="topic sectionWrap">
        <div className="topContent bordered">
          <div className="title">Assigned Agents</div>
        </div>

        {isLoading || retriveDataError.code ?
          <RetrieveDataLoading 
              isLoading={isLoading}
              errorMessage={retriveDataError.message} 
          />
          :
          <TDContent {...agentProps} />
        }
      </div>
      <AssignAgent isOpen={popAssign} {...agentProps} />
    </React.Fragment>
  );
};

export default TDAgent;
