const INITIAL_STATE = [];

let reduxReducerEmailAttachments = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_EMAIL_ATTACHMENTS':
        return action.emailAttachments;
      case 'CLEAR_EMAIL_ATTACHMENTS':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerEmailAttachments;
