import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ChatRoomMessageInBroadcastVideo.scss';
import { FaReply } from 'react-icons/fa';
import { FaPlay } from 'react-icons/fa';
// import { MdClose } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';
import { IoIosClose } from "react-icons/io";
import Helper from '../../../../helper/HelperChat';
import { taptalk, tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import iconAgent from '../../../../assets/img/chatroom/icon-agent.svg';
import iconChatbot from '../../../../assets/img/chatroom/icon-chatbot.svg';
import BroadcastGrey from "../../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg";
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import { connect } from 'react-redux';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageInBroadcastVideo = (props) => {
    let { 
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        // activeRoom
    } = props;

    let [percentageDownload, setPercentageDownload] = useState(0);
    let [bytesDownload, setBytesDownload] = useState(0);
    let [videoSrc, setVideoSrc] = useState('');
    let [isVideoExistInDB, setIsVideoExistInDB] = useState(false);
    let [onDownloadVideoProgress, setOnVideoDownloadProgress] = useState(false);
    let [videoFromUrl, setVideoFromUrl] = useState(false);
    let [isShowModalVideo, setIsShowModalVideo] = useState(false);

    useEffect(() => {
        let isUnmounted = false;
        let fetchFromDB = () => {
            if(singleChatDataProps.data.fileID) {
                tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
                    if(data) {
                        if(!isUnmounted) {
                            setVideoSrc(data);
                            setIsVideoExistInDB(true);
                        }
                        
                    }else {
                        if(!isUnmounted) {
                            setVideoSrc(singleChatDataProps.data.thumbnail);
                            setIsVideoExistInDB(false);
                        }
                    }
                })
            }
        }

        if(singleChatDataProps.data.url) {
            setVideoFromUrl(true);
            setVideoSrc(singleChatDataProps.data.url);
        }else if(singleChatDataProps.data.fileURL) {
            if(singleChatDataProps.data.fileURL !== "") {
                setVideoFromUrl(true);
                setVideoSrc(singleChatDataProps.data.fileURL);
            }else {
                setVideoFromUrl(false);
                fetchFromDB();
            }
        }else {
            fetchFromDB();
        }

        return () => {
            isUnmounted = true;
        }
    }, [singleChatDataProps]);

    let downloadFile = () => {
        setOnVideoDownloadProgress(true);
        
        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnVideoDownloadProgress(false);
                getVideoBase64();
                setIsVideoExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
                setBytesDownload(Helper.bytesToSize(bytes));
            },

            onError: (errorCode, errorMessage) => {
                setOnVideoDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    let getVideoBase64 = () => {
        if(singleChatDataProps.data.fileID) {
            tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
                if(data) {
                    setVideoSrc(data);
                    setIsVideoExistInDB(true);
                }else {
                    setVideoSrc(singleChatDataProps.data.thumbnail);
                    setIsVideoExistInDB(false);
                }
            })
        }
    }

    let toggleModalVideo = () => {
        if(isVideoExistInDB || videoFromUrl) {
            setIsShowModalVideo(!isShowModalVideo);
        } 
    }
    
    let generateModalVideo = () => {
        return (
            <div>
              <Modal isOpen={isShowModalVideo} className={'modal-video'} toggle={toggleModalVideo}>
                <ModalBody onClick={() => toggleModalVideo()}>
                    <IoIosClose className="close-modal-video" onClick={() => toggleModalVideo()} />
                    <div className="video-wrapper">
                        {videoFromUrl ?
                            <video controls>
                                <source src={videoSrc} type="video/mp4" />
                                <source src={videoSrc} type="video/ogg" />
                            </video>
                            :
                            <video controls>
                                <source src={`data:${videoSrc.type};base64, ${videoSrc.file}`} type="video/mp4" />
                                <source src={`data:${videoSrc.type};base64, ${videoSrc.file}`} type="video/ogg" />
                            </video>
                        }
                    </div>
                    
                </ModalBody>
              </Modal>
            </div>
        );
    }

    let messageActionView = (message) => {
        let onClickReply = () => {
            onReplyMessage(message)
        }

        return (
            <div 
                className={`message-action-wrapper message-action-right`}
            >
                <div className="message-action-button-wrapper reply-button" title="Reply" onClick={() => onClickReply()}>
                    <FaReply />
                </div>
            </div>
        )
    }

    return (
        <div className="chat-room-message-video-in-wrapper" id={`message-${singleChatDataProps.localID}`}>
            {/* {singleChatDataProps.room.type === 2 && */}
                <div className="group-sender-avatar-wrapper"
                        style={{background: taptalk.getRandomColor(singleChatDataProps.user.fullname)}}
                >
                    {singleChatDataProps.user.imageURL.thumbnail !== "" ? 
                        <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" onError={(e) => {e.target.onerror = null; e.target.src = iconReplaceAvatar;}} />
                        :
                        <b>{Helper.renderUserAvatarWord(singleChatDataProps.user.fullname)}</b>
                    }

                    {(singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value ||
                      singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value
                    ) &&
                        <img src={iconAgent} alt="" className="avatar-icon-badge" />
                    }

                    {(singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value) &&
                        <img src={iconChatbot} alt="" className="avatar-icon-badge" />
                    }
                </div>
            {/* } */}

            <div className={`message-in-bubble-broadcast-video ${props.status !== 'downloaded' ? 'not-sent-message-bubble' : ''}`}>
                <div className="message-title">
                    <img src={BroadcastGrey} alt="" />
                    <b>Broadcasted Message</b>
                </div>
                {/* {singleChatDataProps.replyTo.localID !== "" &&
                    <div className="reply-message reply-file">
                        {singleChatDataProps.quote.fileType !== "" &&  
                            <div className="reply-file-thumbnail">
                                {singleChatDataProps.quote.fileType === "file" ? 
                                    <MdInsertDriveFile />
                                    :
                                    <img src={''} alt="" />
                                }
                            </div>
                        }

                        <div className="reply-text-wrapper">
                            <p className="reply-from">
                                <b> 
                                    {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                        "You"
                                        :
                                        singleChatDataProps.replyTo.fullname
                                    }
                                </b>
                            </p>
                            <p className="reply-text">{singleChatDataProps.quote.content}</p>
                        </div>
                    </div>
                }   */}

                {/* ${singleChatDataProps.data.caption !== "" && singleChatDataProps.replyTo.localID !== "" ? "with-reply-caption" : ""}
                ${singleChatDataProps.data.caption === "" && singleChatDataProps.replyTo.localID !== "" ? "with-reply-no-caption" : ""}
                ${singleChatDataProps.data.caption === "" ? "bubble-wrapper-without-caption" : ""} */}
                <div
                    className={`
                        bubble-image-wrapper 
                        ${singleChatDataProps.data.caption !== "" ? "bubble-wrapper-with-caption" : ""}
                    `}
                >
                    <p className="group-sender-name-wrapper">
                        <b>
                            {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                            }

                            {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                            }
                            
                            {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                            }
                            
                            {singleChatDataProps.user.fullname}
                        </b>
                    </p>

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyInMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }
                    
                    <div className={`video-inner-wrapper`}>
                        {!videoFromUrl &&
                            <span className="timer-filesize-wrapper">
                                {(isVideoExistInDB ?
                                        Helper.msToTime(singleChatDataProps.data.duration)
                                        :
                                        !onDownloadVideoProgress ?
                                            Helper.bytesToSize(singleChatDataProps.data.size) + " - " + Helper.msToTime(singleChatDataProps.data.duration)
                                            :
                                            bytesDownload + " / " + Helper.bytesToSize(singleChatDataProps.data.size)
                                )} 
                            </span>
                        }

                        {videoFromUrl ?
                            <video src={videoSrc} className="video-thumbnail" />
                            :
                            isVideoExistInDB ?
                                <video src={`data:${videoSrc.type};base64, ${videoSrc.file}`} className="video-thumbnail" />
                                :
                                <img src={'data:image/png;base64, '+singleChatDataProps.data.thumbnail} alt="" className="main-image-message" />
                        }

                        {(!isVideoExistInDB && !onDownloadVideoProgress && !videoFromUrl) &&
                            <div className="icon-status-wrapper">
                                <FiDownload onClick={() => downloadFile()} />
                            </div>
                        }
                            
                        {onDownloadVideoProgress && 
                            <div className="icon-status-wrapper">
                                <div className="onprocess-wrapper">
                                    <CircularProgressbar value={percentageDownload} />
                                    {/* <MdClose onClick={() => console.log('cancel')} /> */}
                                    <FiDownload />
                                </div>
                            </div>
                        }

                        {(isVideoExistInDB || videoFromUrl) &&
                            <div className="icon-status-wrapper">
                                <FaPlay onClick={() => toggleModalVideo()} />
                            </div>
                        }
                    </div>
                </div>
                
                {singleChatDataProps.data.caption !== '' && 
                    <p className="caption-text" dangerouslySetInnerHTML={ {__html: Helper.lineBreakChatRoom(singleChatDataProps.data.caption)} } />
                }

                <p className={`message-info ${singleChatDataProps.data.caption === '' ? "message-info-dark" : ""}`}>
                    {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}
                </p>

                {messageActionView(singleChatDataProps)}
            </div>


            {(isVideoExistInDB || videoFromUrl) && generateModalVideo()}
        </div>
    );
}

const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    activeRoom: state.activeRoom
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInBroadcastVideo);
