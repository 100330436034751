import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const ImportContactServices = {
  downloadTemplate(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessTokenNoWebWorker(API.DOWNLOAD_TEMPLATE, {}, headers, callback, "blob");
  },

  importContact(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime,
      'Content-Type': 'multipart/form-data',
    };

    BaseService.doPostAccessTokenNoWebWorker(API.CREATE_TEMPLATE, data, headers, callback);
  },

  getList(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LIST, data, headers, callback);
  },

  getDetail(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);
  },

  getRow(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_ROW, data, headers, callback);
  },
};

const API = {
  DOWNLOAD_TEMPLATE: "/organization/import_contacts/download_template",
  CREATE_TEMPLATE: "/organization/import_contacts/create_csv",
  GET_LIST: "/organization/import_contacts/get_list",
  GET_DETAIL: "/organization/import_contacts/get_details",
  GET_ROW: "/organization/import_contacts/get_rows"
}

export default ImportContactServices;
