import React, { useState, useEffect } from "react";
import "../../auth/Auth.css";
import "./RolesDetail.scss";
import { FiTrash2, FiCheck, FiEdit, FiPlus } from "react-icons/fi";
import PopupRemove from "../../reuseableComponent/popupRemove/PopupRemove";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSearchBox from "../../reuseableComponent/customSearchBox/CustomSearchBox";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ServiceGroupRole from "../../../services/organization/ServiceGroupRole";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import CloseRedIcon from "../../../assets/img/close-red-icon.svg";
import mixpanel from "mixpanel-browser";

let RolesDetail = props => {
  let { match, history, location } = props;
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [showPopupRemove, setShowPopupRemove] = useState(false);
  let [showPopupRemoveMember, setShowPopupRemoveMember] = useState(false);
  let [orgID, setOrgID] = useState(false);
  let [waitForResponseDetailMember, setWaitForResponseDetailMember] = useState(true);
  let [roleDetail, setRolesDetail] = useState(null);
  let [searchVal, setSearchVal] = useState("");
  let [memberList, setMemberList] = useState([]);
  let [memberListOrigin, setMemberListOrigin] = useState([]);
  let [waitForResponseDeleteMember, setWaitForResponseDeleteMember] = useState(false);
  let [loadingGetMember, setLoadingGetMember] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [retriveDataErrorMember, setRetrieveDataErrorMember] = useState({
    code: false,
    message: false
  });
  let [singleRemoveMember, setSingleRemoveMember] = useState(false);
  let [showModalRemoveMemberSingle, setShowModalRemoveMemberSingle] = useState(false);
  let [dataToBeRemoved, setDataToBeRemoved] = useState([]);
  // let [listPageVal, setListPageVal] = useState({
  //   label: '20',
  //   value: 20,
  // })

  // let listPageOption = [
  //   {
  //     label: '20',
  //     value: 20,
  //   },
  //   {
  //     label: '50',
  //     value: 50,
  //   },
  //   {
  //     label: '100',
  //     value: 100,
  //   },
  // ]
  let toggleModalRemoveMemberSingle = () => {
    if (!waitForResponseDeleteMember) {
      setShowModalRemoveMemberSingle(!showModalRemoveMemberSingle);
    }
  }

  let toggleModalRemoveRole = () => {
    if (!waitForResponseDelete) {
      setShowPopupRemove(!showPopupRemove);
    }
  }

  let toggleModalRemoveMember = () => {
    if (!waitForResponseDeleteMember) {
      setShowPopupRemoveMember(!showPopupRemoveMember);
    }
  }

  let back = () => history.push(`/o/${orgID}/organization/roles`);

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (orgID) {
      getRolesDetail();
      getMemberList();
    }
  }, [orgID])

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (orgID) {
      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Role Details",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        );
      }
    }
  }, [orgID])

  let getRolesDetail = () => {
    setWaitForResponseDetailMember(true);

    let data = {
      id: parseInt(props.match.params.roleID)
    };

    ServiceGroupRole.postGetGroupRoleDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setRolesDetail(response.dataResult.data);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        // doToast(response.dataResult.error.message, "fail");
      }

      setWaitForResponseDetailMember(false);
    })
  }

  let getMemberList = () => {
    setDataToBeRemoved([]);
    setLoadingGetMember(true);

    let data = {
      id: parseInt(props.match.params.roleID)
    };

    ServiceGroupRole.postGetGroupRoleMember(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataErrorMember };

      if (response.dataResult.error.message === "") {
        setMemberList(response.dataResult.data.members);
        setMemberListOrigin(response.dataResult.data.members);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataErrorMember(_retriveDataError);
        // doToast(response.dataResult.error.message, "fail");
      }

      setLoadingGetMember(false);
    })
  }

  let deleteRole = () => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    setWaitForResponseDelete(true);

    let data = {
      "id": parseInt(props.match.params.roleID),
      "createdTime": roleDetail.role.createdTime
    }

    ServiceGroupRole.postDeleteRole(orgID, data, (response) => {
      if (response.dataResult.error.message === "") {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Remove Role",
            {
              userFullName: myAgentData.account.fullName,
              userID: myAgentData.account.id,
              organizationID: orgID.split("-")[0]
            }
          );
        }

        if (response.dataResult.data.success) {
          doToast(response.dataResult.data.message);

          setTimeout(() => {
            back()
          }, 3000)
        } else {
          setWaitForResponseDelete(false);
          doToast(response.dataResult.data.message, "fail");
        }
      } else {
        doToast(response.dataResult.error.message, "fail");
        setWaitForResponseDelete(false);
      }
    })
  }

  // let onSelectListPage = (val) => {
  //   setListPageVal(val)
  // }
  let onClickCheckAll = (e) => {
    let checked = e.target.checked;
    let data = [];

    if (checked) {
      memberList.map((v) => {
        data.push(v.memberID);

        return null;
      })
    }

    setDataToBeRemoved(data);
  }

  let onClickSingleCheck = (e, id) => {
    let checked = e.target.checked;
    let _dataToBeRemoved = dataToBeRemoved.slice();

    let _findIndex = _dataToBeRemoved.findIndex(v => v === id);

    if (checked) {
      _dataToBeRemoved.push(id);
    } else {
      _dataToBeRemoved.splice(_findIndex, 1);
    }

    setDataToBeRemoved(_dataToBeRemoved);
  }

  let onChangeSearch = (e) => {
    let val = e.target.value.toLowerCase();
    setSearchVal(val);

    if (val === "") {
      setMemberList(memberListOrigin)
    } else {
      let result = [];
      for (let i in memberListOrigin) {
        let objectKey = Object.keys(memberListOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof memberListOrigin[i][objectKey[j]] === "string";
          if (memberListOrigin[i][objectKey[j]].toString().toLowerCase().includes(val) && isString) {
            result.push(memberListOrigin[i])
            break;
          }
        }
      }

      setMemberList(result);
    }
  }

  let deleteMember = () => {
    setWaitForResponseDeleteMember(true);

    let data = {
      id: parseInt(props.match.params.roleID),
      memberIDs: dataToBeRemoved
    }

    if(singleRemoveMember) {
      data.memberIDs = [singleRemoveMember.memberID];
    }

    ServiceGroupRole.postDeleteGroupRoleMember(props.match.params.orgID, data, (res) => {
      if (res.dataResult.error.message === "") {
        if (res.dataResult.data.success) {
          doToast(res.dataResult.data.message);
          setShowPopupRemoveMember(false);
          setShowModalRemoveMemberSingle(false);
          getMemberList();
        } else {
          doToast(res.dataResult.data.message, "fail");
        }
      } else {
        doToast(res.dataResult.error.message, "fail");
      }

      setWaitForResponseDeleteMember(false);
    })
  }

  let onClickSingleRemoveMember = (data) => {
    toggleModalRemoveMemberSingle();
    setSingleRemoveMember(data);
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <PopupRemove
          removeTitle={`Remove 1 member from this role?`}
          removeDescription={`You will be removing a member from this role and this action cannot be undone. Are you sure you want to continue?`}
          onClickCancel={() => setShowModalRemoveMemberSingle(false)}
          onClickRemove={() => deleteMember()}
          waitForResponse={waitForResponseDeleteMember}
          showModalProps={showModalRemoveMemberSingle}
          colorButton="red-button"
          waitForResponseDelete={waitForResponseDeleteMember}
        />

        <PopupRemove
          removeTitle={`Delete roles?`}
          removeDescription={`This role will be removed from this organization`}
          onClickCancel={() => toggleModalRemoveRole()}
          onClickRemove={() => deleteRole()}
          waitForResponse={waitForResponseDelete}
          showModalProps={showPopupRemove}
          colorButton="red-button"
          waitForResponseDelete={waitForResponseDelete}
        />

        <PopupRemove
          removeTitle={`Remove ${dataToBeRemoved.length} member${dataToBeRemoved.length > 1 ? "s" : ""} from this role?`}
          removeDescription={`You will be removing ${dataToBeRemoved.length} member${dataToBeRemoved.legnth > 1 ? "s" : ""} from this role and this action cannot be undone. Are you sure you want to continue?`}
          onClickCancel={() => toggleModalRemoveMember()}
          onClickRemove={() => deleteMember()}
          waitForResponse={waitForResponseDeleteMember}
          showModalProps={showPopupRemoveMember}
          colorButton="red-button"
          waitForResponseDelete={waitForResponseDeleteMember}
        />

        <BackButton text="Back to Roles" onClick={back} />

        <div className="sectionWrap sectionWrapTop-12">
          <div id="role-detail-wrapper">
            <React.Fragment>
              <p className="role-detail-title">
                <b className="detail-title-text">Role Details</b>

                {!waitForResponseDetailMember &&
                  <>
                    <ButtonWithLoadingOrIcon
                      text="Delete Role"
                      position="left"
                      className="red-button main-button-40 delete-role-button"
                      icon={{
                        src: FiTrash2,
                        type: "svg"
                      }}
                      onClickAction={toggleModalRemoveRole}
                    />

                    <ButtonWithLoadingOrIcon
                      text="Edit Role"
                      position="left"
                      className="orange-button main-button-40"
                      icon={{
                        src: FiEdit,
                        type: "svg"
                      }}
                      onClickAction={() => {
                        history.push(`/o/${orgID}/organization/roles/${match.params.roleID}/edit`)
                      }}
                    />
                  </>
                }
              </p>

              {/* <img src={dummyUserAvatar} alt="" className="role-detail-avatar" /> */}

              {waitForResponseDetailMember || retriveDataError.code ?
                <RetrieveDataLoading
                  noPaddingTop={true}
                  isLoading={waitForResponseDetailMember}
                  errorMessage={retriveDataError.message}
                />
                :
                <div id="role-value-wrapper">
                  <div className="role-value-content">
                    <div className="role-info-wrapper">
                      <label><b>Name</b></label>
                      <p className="role-detail-value">{roleDetail.role.name}</p>
                    </div>
                  </div>

                  <div className="role-value-content">
                    <div className="role-info-wrapper">
                      <label>
                        <b>Description</b>
                      </label>
                      <p className="role-detail-value">{roleDetail.role.description}</p>
                    </div>
                  </div>

                  <div className="role-value-content">
                    <div className="role-info-wrapper">
                      <label><b>Permissions</b></label>

                      <div className="permission-wrapper">
                        {roleDetail.grantedModules.map((v, i) => {
                          return (
                            <p key={`module-${i}`}>
                              <FiCheck />
                              {v.title.replace(":", " - ")}
                            </p>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </React.Fragment>
          </div>
        </div>

        <div className="sectionWrap">
          <>
            <div className="role-detail-title role-member-bottom">
              <b className="detail-title-text">Role Members</b>

              {(!loadingGetMember && ! retriveDataErrorMember.code) &&
                (dataToBeRemoved.length === 0 ?
                  <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40 button-add-member"
                    icon={{
                      type: 'svg',
                      src: FiPlus
                    }}
                    position='left'
                    text='Add Members'
                    onClickAction={() => {
                      props.history.push({
                        pathname: `/o/${props.match.params.orgID}/organization/roles/detail/${props.match.params.roleID}/add-members`,
                        state: {
                          roleTitle: roleDetail.role.name
                        }
                      })
                    }}
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    onClickAction={() => toggleModalRemoveMember()}
                    className="red-button main-button-40 button-add-role"
                    text={`Remove ${dataToBeRemoved.length} Member${dataToBeRemoved.length > 1 ? "s" : ""}`}
                    icon={{
                      type: "svg",
                      src: FiTrash2
                    }}
                    position="left"
                  />
                )
              }
            </div>

            {loadingGetMember || retriveDataErrorMember.code ?
              <RetrieveDataLoading
                noPaddingTop={true}
                isLoading={loadingGetMember}
                errorMessage={retriveDataErrorMember.message}
              />
              :
              <div className="role-member-bottom-2">
                <CustomSearchBox
                  placeholder={"Search for members"}
                  onChangeSearchSpecial={onChangeSearch}
                  style={{ width: '343px' }}
                  dataToSearch={memberList}
                  onClearSearch={() => {
                    let valSearch = document.querySelector(`#search-box-input-val`);
                    valSearch.value = "";
                    setMemberList(memberListOrigin);
                    setSearchVal("");
                  }}
                  search={searchVal}
                />

                <div className="">
                  <table className="custom-table-content table-roles-list table-roles-list-member-detail">
                    <thead>
                      <tr>
                        <td className="col_full_name">
                          {memberList.length > 0 &&
                            <div className={dataToBeRemoved.length === memberList.length ? "custom-checkbox" : "custom-checkbox-stripe"}>
                              <input
                                type="checkbox"
                                id="select-all"
                                onClick={(e) => onClickCheckAll(e)}
                                checked={dataToBeRemoved.length > 0}
                              />
                              <label htmlFor="select-all"></label>
                            </div>
                          }
                          Full Name
                        </td>
                        <td className="col_alias">Alias</td>
                        <td className="col_email">Email</td>
                        <td className="col_remove">

                        </td>
                        {/* <td className="col_role">
                          <CustomSelectOption
                            optionListProps={listPageOption}
                            valueDropdownProps={listPageVal}
                            onClickDropDownListOptionProps={(e) => onSelectListPage}
                            isDisabled={false}
                            isBold
                            _className="dropdown-list-item-per-page"
                          />
                        </td> */}
                      </tr>
                    </thead>

                    {memberList.length === 0 ?
                      <tbody>
                        <tr className="no-data-tr">
                          <td colSpan="4" className="no-data-column">
                            <b className="no-result-found">
                              {searchVal === "" ?
                                `No members found. Start by clicking "+Add Members".`
                                :
                                `No data found`
                              }
                            </b>
                          </td>
                        </tr>
                      </tbody>
                      :
                      <tbody>
                        {memberList.map((value, index) => {
                          return (
                            <tr key={`roles-${index}`}>
                              <td className="col_full_name_body">
                                <div className="custom-checkbox">
                                  <input type="checkbox"
                                    id={`select-roles-${value.memberID}`}
                                    onClick={(e) => onClickSingleCheck(e, value.memberID)}
                                    checked={dataToBeRemoved.findIndex(v => v === value.memberID) !== -1}
                                  />
                                  <label htmlFor={`select-roles-${value.memberID}`}></label>
                                </div>
                                {value.accountName === "" ? "-" : value.accountName}
                              </td>
                              <td className="col_alias">{value.memberAlias === "" ? "-" : value.memberAlias}</td>
                              <td className="col_email">{value.accountEmail}</td>
                              <td className={`col_remove`} onClick={() => onClickSingleRemoveMember(value)}>
                                <img src={CloseRedIcon} alt="" />
                                <b className="red-text">Remove</b>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            }
          </>
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default RolesDetail;