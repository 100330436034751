const INITIAL_STATE = false;

let reduxReducerReconnectRoomList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_RECONNECT_ROOMLIST':
        return action.reconnectRoomList;
      case 'CLEAR_RECONNECT_ROOMLIST':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerReconnectRoomList;