import React, { useState, useEffect, useRef } from "react";
import "./LabelAdd.scss";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast, scrollToClass } from "../../../helper/HelperGeneral";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
// import iconBrushBlack from "../../../assets/img/icon-brush-black.svg";
// import { SketchPicker } from 'react-color';
import { FiCheck, FiInfo } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CaseLabelService from "../../../services/newServices/CaseLabelService";
import PopupRemove from "../../reuseableComponent/popupRemove/PopupRemove";
import BlockingDiscardChanges from "../../reuseableComponent/blockingDiscardChanges/BlockingDiscardChanges";
// import ServiceLabel from "../../../services/newServices/LabelService";
// import HelperInput from "../../../helper/HelperInput";

let LabelAdd = props => {
  let { match, history, location } = props;
  let colorPickerRef = useRef(null);
  let [orgID, setOrgID] = useState(false);
  let [isWaitResponseAddLabel, setIsWaitResponseAddLabel] = useState(false);
  let [isShowLabelColorPicker, setIsShowLabelColorPicker] = useState(false);
  let [labelName, setLabelName] = useState("")
  let [labelDescription, setLabelDescription] = useState("")
  let [labelColor, setLabelColor] = useState("");
  // let [isButtonAddDisabled, setIsButtonAddDisabled] = useState(true)
  let [colorList, setColorList] = useState([
    {
      id: 1,
      color: '#E02D2D',
      selected: false
    },
    {
      id: 2,
      color: '#E5057E',
      selected: false
    },
    {
      id: 3,
      color: '#CC1FCF',
      selected: false
    },
    {
      id: 4,
      color: '#9B30EF',
      selected: false
    },
    {
      id: 5,
      color: '#6C2FEC',
      selected: false
    },
    {
      id: 6,
      color: '#4131F5',
      selected: false
    },
    {
      id: 7,
      color: '#056DFF',
      selected: false
    },
    {
      id: 8,
      color: '#008496',
      selected: false
    },
    {
      id: 9,
      color: '#00873F',
      selected: false
    },
    {
      id: 10,
      color: '#458500',
      selected: false
    },
    {
      id: 11,
      color: '#857700',
      selected: false
    },
    {
      id: 12,
      color: '#A16B00',
      selected: false
    },
    {
      id: 13,
      color: '#CC4B14',
      selected: false
    }
  ])
  let [errorMessageData, setErrorMessageData] = useState({
    label: "",
    color: ""
  }) 

  let back = () => history.push(`/o/${orgID}/organization/labels`);

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/labels`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  let onChangeLabelName = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.label = "";
    setErrorMessageData(_errorMessageData);
    setLabelName(e.target.value)
  };

  let onChangeLabelDescription = (e) => {
    setLabelDescription(e.target.value)
  };

  let createLabel = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(labelName === "") {
        _errorMessageData.label = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-name" }
    }

    if(labelName.length > 50) {
      _errorMessageData.label = "Characters exceed limit";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-name" }
    }

    if(labelColor === "") {
        _errorMessageData.color = "Please select color";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-customize" }
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      setIsWaitResponseAddLabel(true);
      setCondition(false)

      let dataLabel = {
        name: labelName,
        description: labelDescription,
        backgroundColor: labelColor
      }

      CaseLabelService.createCaseLabel(orgID, dataLabel, (response) => {
        let dataResult = response.dataResult;
        setIsWaitResponseAddLabel(false);

        if (dataResult.status === 200) {
          if (dataResult.data.success) {
            history.push(`/o/${orgID}/organization/labels`)
            // resetSelectedColor()
            doToast(dataResult.data.message);
          } else {
            doToast(dataResult.data.message, "fail");
            setCondition(true)
          }
        } else {
          doToast(dataResult.error.message, "fail");
        }
      });
    }
  }

  // useEffect(() => {
  //   if (labelName === "" || labelColor === "") {
  //     setIsButtonAddDisabled(true)
  //   } else {
  //     if (!validateHex()) {
  //       setIsButtonAddDisabled(true)
  //     } else {
  //       setIsButtonAddDisabled(false)
  //     }
  //   }
  // }, [labelName, labelColor])

  // let validateHex = () => {
  //   return /^#[0-9A-F]{6}$/i.test(labelColor)
  // }

  useEffect(() => {
    let onBodyClick = (event) => {
      if (isShowLabelColorPicker) {
        if (!colorPickerRef.current.contains(event.target) && !colorPickerRef.current.previousSibling.contains(event.target)) {
          setIsShowLabelColorPicker(false);
        }
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, [isShowLabelColorPicker]);

  // let changeColorPicker = (color) => {
  //   setLabelColor(color.hex)
  // }

  // let toggleColorPicker = () => {
  //   setIsShowLabelColorPicker(!isShowLabelColorPicker);
  // }

  // let hexToRgb = (hex) => {
  //   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //   return result ? {
  //     r: parseInt(result[1], 16),
  //     g: parseInt(result[2], 16),
  //     b: parseInt(result[3], 16)
  //   } : null;
  // }

  // let handleChangeColor = (e) => {
  //   if (e.target.value === '') {
  //     setLabelColor(e.target.value);
  //   } else {
  //     setLabelColor(getHexWithHashTag(e.target.value));
  //   }
  // }

  // let getHexWithHashTag = (val) => {
  //   return `#${val}`
  // }

  // let getHexWithoutHashTag = (val) => {
  //   return val.substring(1, 7)
  // }

  // for blocking page
  let toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  let discardProgress = () => {
    setIsShowModalDiscard(false)
    setConfirmedNavigation(true)
  }

  let [confirmedNavigation, setConfirmedNavigation] = useState(false)
  let [condition, setCondition] = useState(false)
  let [isShowModalDiscard, setIsShowModalDiscard] = useState(false)

  useEffect(() => {
    if (labelName !== '' || labelDescription !== '' || labelColor !== '') {
      setCondition(true)
    } else {
      setCondition(false)
    }
  }, [labelName, labelDescription, labelColor])

  let handleOpenModal = (isOpen) => {
    setIsShowModalDiscard(isOpen)
  }

  let handleSelectColor = (item) => {
    if(!isWaitResponseAddLabel) {
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.color = "";
      let _colorList = [...colorList]
      _colorList.forEach(item => {
        item.selected = false
      })
      setErrorMessageData(_errorMessageData);
      setLabelColor(item.color)
      _colorList[item.id - 1].selected = true
  
      setColorList(_colorList)
    }
  }

  // let resetSelectedColor = () => {
  //   let _colorList = [...colorList]
  //   _colorList.forEach(item => {
  //     item.selected = false
  //   })
  //   setColorList(_colorList)
  // }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <BlockingDiscardChanges
          openModal={handleOpenModal}
          condition={condition}
          confirmedNavigation={confirmedNavigation}
        />
        <BackButton text="Back to Case Labels" onClick={back} />

        <div className="sectionWrap form-add-label">
          <div>
            <div className="form-title-wrapper">
              <b>Create Label</b>
              {isWaitResponseAddLabel ?
                <ButtonWithLoadingOrIcon
                  isLoading
                  loadingColor="gray"
                  isDisabled
                  className="orange-button main-button-40"
                  text="Create Label"
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40"
                  text="Create Label"
                  onClickAction={(e) => createLabel(e)}
                  // isDisabled={isButtonAddDisabled}
                />
              }
            </div>

            <div className="form-content-wrapper">
              <label className="pos-relative">
                <b>Name</b>

                <span className={errorMessageData.label === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{labelName.length}/50</span>
              </label>
              <input disabled={isWaitResponseAddLabel} type="text" placeholder="Type label name here" id="label" onChange={(e) => onChangeLabelName(e)} value={labelName} className={`input-name ${errorMessageData.label !== "" ? "border-red" : ""}`} />
              <ErrorOrInfoComp
                  text={errorMessageData.label !== "" ? errorMessageData.label : "This label will be searchable in OneTalk's inbox."}
                  _className={errorMessageData.label !== "" ? "font-red margin-top-0" : "margin-top-0"}
                  icon={<FiInfo />}
              />
              {/* 
              <p className="input-info-wrapper">
                This label will be searchable in OneTalk's inbox.
              </p> */}

              <label><b>Description</b><span className="input-label-optional"> (optional)</span></label>
              <textarea disabled={isWaitResponseAddLabel} placeholder="Type description here" id="description" onChange={(e) => onChangeLabelDescription(e)} value={labelDescription} />

              {/* <label><b>Color</b></label> */}

              <div className="input-customize">
                <div className="input-color">
                  <p>
                    <b>Select Color</b>
                  </p>
                  <div className={`color-select-wrapper ${errorMessageData.color === "" ? "" : "border-red"} ${isWaitResponseAddLabel ? "grey-box" : ""}`}>
                    {
                      colorList.map(item => {
                        return (
                          <div className="color-item" key={`color-${item.id}`} style={{ backgroundColor: item.color }} onClick={() => { if(!isWaitResponseAddLabel) { handleSelectColor(item) }}}>
                            {
                              item.selected && <FiCheck />
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                  {/* <div className="color-input-wrapper">
                    <div
                      className="color-input-container"
                      onClick={() => {
                        toggleColorPicker();
                      }}
                    >
                      <>
                        {labelColor === '' ?
                          <img src={iconBrushBlack} alt="" className="brush-icon" />
                          :
                          <div className="color-value" style={{ background: labelColor }} />
                        }
                        <label className="color-tag">#</label>
                        <input className="color-input" type="text" onChange={handleChangeColor} value={getHexWithoutHashTag(labelColor)} placeholder="Select Color" maxLength="6" />
                      </>
                    </div>

                    {isShowLabelColorPicker && (
                      <div className="color-picker-container" ref={colorPickerRef}>
                        <FiX
                          onClick={toggleColorPicker}
                          className="close-color-picker"
                        />
                        <SketchPicker
                          color={labelColor}
                          onChange={changeColorPicker}
                        />
                      </div>
                    )}
                  </div> */}
                </div>
              </div>

              {errorMessageData.color !== "" &&
                <ErrorOrInfoComp
                  text={errorMessageData.color}
                  _className={"font-red"}
                  icon={<FiInfo />}
                />
              }
            </div>
          </div>

          <PopupRemove
            removeTitle={`Discard Progress?`}
            removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
            onClickCancel={toggleModalDiscard}
            onClickRemove={discardProgress}
            // waitForResponse={waitForResponseDiscard}
            showModalProps={isShowModalDiscard}
            submitText="Discard Changes"
            colorButton="red-button"
          />
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default LabelAdd;