const INITIAL_STATE = {
    isShow: false,
    data: {},
    lastSend: false,
    isFromSelectedModal: false
  };
  
  let reduxReducerSalestalkSelectedProduct = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case 'SET_SALESTALK_SELECTED_PRODUCT':
          return action.salesTalkSelectedProduct;
        case 'CLEAR_SALESTALK_SELECTED_PRODUCT':
          return INITIAL_STATE;
        default:
          return state;
      }
  };
  
  export default reduxReducerSalestalkSelectedProduct;