const SORT_MESSAGE_BY = {
    last_message: "last_message",
    created_time: "created_time"
}
const INITIAL_STATE = {
    unassigned: SORT_MESSAGE_BY.created_time,
    mine: SORT_MESSAGE_BY.last_message,
    other: SORT_MESSAGE_BY.last_message
};

let reducerCaseSortBy = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_CASE_SORT_BY':
        return action.caseSortBy;
      case 'CLEAR_CASE_SORT_BY':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerCaseSortBy;