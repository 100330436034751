const INITIAL_STATE = {};

let reducerSideMenu = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_PARENT_MENU_BLOCK_STATE':
        return action.parentMenuBlock;
      case 'CLEAR_ACTIVE_PARENT_MENU_BLOCK_STATE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerSideMenu;