import React from "react";
import TDATableHead from "./TDATableHead";

const RowAgent = ({ name, email, id, select, selected, alias }) => {
  const isSelected = selected && selected.filter(item => {
    if (item.accountID !== 0) {
      return item.accountID === id
    } else {
      return item.accountEmail === email
    }
  });
  const checked = isSelected && isSelected.length > 0 ? true : false;
  const idList = `checkAgent_${id}_${email}`;

  const selecting = () => {
    const _data = {
      accountID: id,
      accountEmail: email
    }

    if (!checked) {
      const _dataSelect = [...selected, _data]
      select(_dataSelect)

    } else {
      const _dataSelected = [...selected]
      let _dataUncheck = []

      if (_data.accountID !== 0) {
        _dataUncheck = _dataSelected.filter(item => item.accountID !== _data.accountID)
      } else {
        _dataUncheck = _dataSelected.filter(item => item.accountEmail !== _data.accountEmail)
      }

      select(_dataUncheck);
    }
  };

  const ListCheck = () => (
    <React.Fragment>
      <input
        type="checkbox"
        id={idList}
        onChange={selecting}
        checked={checked}
      />
      <label htmlFor={idList}></label>
    </React.Fragment>
  );

  return (
    <tbody>
      <tr className="tcRow">
        <td width="10%" style={{padding: "8px 20px 14px 20px"}} className="custom-checkbox tcColCheck">
          <ListCheck />
        </td>
        <td width="20%" className="tcCol">
          <label>{name}</label>
        </td>
        <td width="20%" className="tcCol">
          <label>{alias === "" ? "-" : alias}</label>
        </td>
        <td width="50%" className="tcCol">
          <label>{email}</label>
        </td>
      </tr>
    </tbody>
  );
};

const ListAgent = ({ data, select, selected }) =>
  data.map(item => {
    const { accountID: id, accountName: name, accountEmail: email, accountAlias: alias } = item;
    const itemProps = { name, email, key: `${id}_${email}`, id, select, selected, alias };
    return <RowAgent {...itemProps} />;
  });

const NoAgent = () => (
  <table width="100%" className="tableContent">
    <tbody>
      <tr>
        <td colSpan="3">
          <div className="textInfo center grey">
            <p>No agent assigned</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
);

const TDATableAgent = props => {
  const { agentData, copyAgentData, agentSelected: selected } = props;
  const { setAgentSelected: select } = props;
  const data = copyAgentData || agentData;
  const listProps = { data, select, selected };
  
  return (
    <table className="tableContent" width="100%">
      <TDATableHead {...listProps} />

      {data.length === 0 ?
        <NoAgent />
        :
        <ListAgent {...listProps} />
      }
    </table>
  );
};

export default TDATableAgent;
