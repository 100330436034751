import React, { useState } from "react";
import "./ChatRoomEmailCompDocs.scss";
import { Modal, ModalBody } from "reactstrap";
import { IoIosClose } from "react-icons/io";
import IconDownload from "../../../../assets/img/icon-download.svg";
import { FiPlay, FiImage, FiFile } from "react-icons/fi";

let ChatRoomEmailCompDocs = (props) => {
    let { singleChatDataProps } = props;

    let [videoSrc, setVideoSrc] = useState("");
    let [imageSrc, setImageSrc] = useState("");

    let [isShowModalVideo, setIsShowModalVideo] = useState(false);
    let [isShowModalImage, setIsShowModalImage] = useState(false);

    let toggleModalVideo = () => {
        setIsShowModalVideo(!isShowModalVideo);
    }

    let toggleModalImage = () => {
        setIsShowModalImage(!isShowModalImage);
    }

    let generateModalVideo = () => {
        return (
            <div>
                <Modal isOpen={isShowModalVideo} className={'modal-video'} toggle={toggleModalVideo}>
                    <ModalBody onClick={() => toggleModalVideo()}>
                        <IoIosClose className="close-modal-video" onClick={() => toggleModalVideo()} />
                        <div className="video-wrapper">
                            <video controls>
                                <source src={videoSrc} type="video/mp4" />
                                <source src={videoSrc} type="video/ogg" />
                            </video>
                        </div>

                    </ModalBody>
                </Modal>
            </div>
        );
    }

    let generateModalImage = () => {
        let zoomImage = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '200%';
            var offsetX = e.nativeEvent.offsetX;
            var offsetY = e.nativeEvent.offsetY;

            var x = offsetX / zoomer.offsetWidth * 100
            var y = offsetY / zoomer.offsetHeight * 100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        }

        let zoomImageOut = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '0%';
        }

        return (
            <div>
                <Modal isOpen={isShowModalImage} className={'modal-image'} toggle={toggleModalImage}>
                    <ModalBody onClick={() => toggleModalImage()}>
                        <IoIosClose className="close-modal-image" onClick={() => toggleModalImage()} />

                        <div className="image-wrapper">
                            <figure
                                className="zoom"
                                style={{ "backgroundImage": `url(${imageSrc})` }}
                                onMouseMove={(e) => zoomImage(e, imageSrc)}
                                onMouseLeave={(e) => zoomImageOut(e)}
                            >
                                <img
                                    src={imageSrc}
                                    alt=""
                                    className="image-preview-val"
                                />
                            </figure>
                        </div>

                    </ModalBody>
                </Modal>
            </div>
        );
    }

    let renderDocsList = (data, key) => {
        let type = data.mediaType.split("/")[0];
        let fileName = data.fileName;
        let url = data.url;

        return (
            <div className="comp-email-wrapper-docs-box" key={`${singleChatDataProps.localID}-attachment-${key}`}>
                <div className="comp-email-wrapper-docs-box-top">
                    <div className="comp-email-wrapper-docs-box-top-icon">
                        {type === "video" ?
                            <FiPlay
                                onClick={() => {
                                    toggleModalVideo();
                                    setVideoSrc(url);
                                }}
                                className="cursor-pointer"
                            />
                            :
                            (type === "image" ?
                                <FiImage
                                    onClick={() => {
                                        toggleModalImage();
                                        setImageSrc(url)
                                    }}
                                    className="cursor-pointer"
                                />
                                :
                                
                                <FiFile />
                            )
                        }
                    </div>
                </div>

                <p className="comp-email-wrapper-docs-box-bottom">
                    <span title={fileName}>{fileName}</span>
                    
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <img src={IconDownload} className="comp-email-wrapper-docs-download" alt="" />
                    </a>
                </p>
            </div>
        )
    }

    return (
        <div className="comp-email-wrapper-docs">
            {generateModalImage()}
            {generateModalVideo()}

            <div className="comp-email-wrapper-docs-inner">
                {singleChatDataProps.data.attachments.map((v, i) => {
                    return renderDocsList(v, i)
                })}
            </div>
        </div>
    )
}

export default ChatRoomEmailCompDocs;