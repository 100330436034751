import { withFormik } from "formik";
import { doToast } from "../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import AssignAgentView from "./AssignAgentView";
import topicServices from "../../services/newServices/TopicServices";
import mixpanel from "mixpanel-browser";

const AssignAgent = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const { idTopic: id } = props;
    const api = { loadingAPI: false, errorAPI: "", failedAPI: false };
    return { agentList: [], id, ...api };
  },

  handleSubmit: async (values, { resetForm, setFieldValue, props }) => {
    const { orgID, setPopAssign, reloadAPI } = props;
    const { agentList, id } = values;
    const valID = parseInt(id, 10);

    const countAPI = setTimeout(() => {
      // doToast("Failed to add new agent");
      setFieldValue("loadingAPI", false);
    }, 5500);

    setFieldValue("errorAPI", "");
    setFieldValue("failedAPI", false);
    setFieldValue("loadingAPI", true);
    const body = { id: valID, accountIDs: agentList };

    const doSuccess = () => {
      clearTimeout(countAPI);
      resetForm();
      setPopAssign(false);
      reloadAPI("New agent added");
    };

    // const doError = data => {
    //   clearTimeout(countAPI);
    //   setFieldValue("errorAPI", data.error.message);
    // };

    const addAgent = async () => {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
        mixpanel.track(
          "[Button] Assign Agent to Topic", 
          {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : orgID.split("-")[0]
          }
        );
      }
    
      topicServices.addAgent(body, orgID, (response) => {
        if (response.dataResult.status === 200) {
          doSuccess();

          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
            mixpanel.track(
              "[Action] Assign Agent to Topic", 
              {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
              }
            );
          }
        }
        else doToast(response.dataResult.error.message, "fail");
        
        setFieldValue("loadingAPI", false);
      });
      // console.log(data);
    };

    await addAgent();
    // console.log({ values }, { props });
  }
})(AssignAgentView);

export default AssignAgent;
